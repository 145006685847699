import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import axios from "axios";

import {CalendarGroup, CCol, CContainer, CIconBtn, CInputDoubleGroup, CInputGroup, CSelectGroup, Subject} from "../../components/CustomContainer";
import {AppStore} from "../../store/AppStore";
import ProductListSearch from "../../components/searchModal/ProductListSearch";
import AgGridContainer from "../../components/AgGridContainer";
import {callConfirm, getLocalStorage} from "../../utils";
import {showToast} from "../../common/utils";
import { StorageConsumOptionStore } from "../../store/storage/StorageConsumOptionStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import { USER_INFO } from "../../common/constants";

const StorageConsumOption =()=> {
    const store = StorageConsumOptionStore;
    const gridRef = useRef();
    const [qty, setQty] = useState(1);
    const [addOption, setAddOption] = useState('serial');
    const [totalCount, setTotalCount] = useState(0);
    const [apronList, setApronList] = useState([]);

    useEffect(() => {
        resetStorageSeq();
        getCount();

        getApronList();
        const apronSeq = getLocalStorage(USER_INFO).apronSeq;
        store.data.apronSeq = apronSeq;
    }, [store.targetList.length]);

    const resetStorageSeq = () => {
        store.targetList = store.targetList.map((item, index) => ({...item, storageSeq: index + 1}));
    };

    const getCount = () => {
        const count = store.targetList.reduce((total, v) => Number(total) + Number(v.qty), 0);
        setTotalCount(count);
    }

    /* 제품 추가 callback */
    const callbackProductInfo = (info) => {
        store.data.lgCate = info.lg;
        store.data.productSeq = info.productSeq;
        store.data.productName = info.productName;
        store.data.model = info.model;
        store.data.spec = info.spec;
        store.data.powerTransmission = info.powerTransmission;
        store.data.drivingSpeed = info.drivingSpeed;
        store.data.drivingMethod = info.drivingMethod;
        store.data.loadCapacity = info.loadCapacity;
        store.data.workingMaximumHeight = info.workingMaximumHeight;
        store.data.isConsumable = info.isConsumable;
        store.data.isOption = info.isOption;
    }

    const addRow =()=> {
        if(!store.data.productName){
            showToast('제품을 먼저 선택해주세요.');
            return;
        }
        if(!store.data.buyCustomerNo){
            showToast('구매처를 입력해주세요.');
            return;
        }
        if(!store.data.storageDate){
            showToast('입고일자를 입력해주세요.');
            return;
        }
        if(!store.data.amount){
            showToast('단가를 입력해주세요.');
            return;
        }
        let addIndex = store.targetList.filter(v => v.isCreated).length || 0;
        if(addOption === 'serial') {
            for (let i = 0; i < qty; i++) {
                store.pushTargetList(Object.assign({isCreated: true, addRowId: addIndex++, storageSeq: addIndex, qty: 1, division: store.data.isConsumable ? '소모품' : store.data.isOption ? '옵션' : !store.data.productSeq ? '' : '상품'}, {...store.data, apronName: getApronName(store.data.apronSeq)}));
            }
        }else {
            store.pushTargetList(Object.assign({isCreated: true, addRowId: addIndex++, storageSeq: addIndex}, {...store.data, apronName: getApronName(store.data.apronSeq)}, {qty: qty}));
        }

        //초기화
        setTimeout(()=> {
            store.init();
            setAddOption('serial');
            setQty(1);
        }, 200)
    }

    const hasDuplicate = (list, key) => {
        const values = list.filter(item => item.qty === 1).map(item => item[key]);
        return new Set(values).size !== values.length;
    };

    /* 상품 저장 */
    const onSaveStorageAssetAcqu = async () => {
        // 필수값 확인
        for(let i = 0; i < store.targetList.length; i++) {
            const target = store.targetList[i];
            if(target.qty < 1) {
                showToast('수량을 1 이상 입력해주세요.');
                return false;
            }else if (target.qty > 1 && target.serialNo) {
                showToast('수량이 1보다 큰 경우 시리얼번호를 지워주세요.');
                return false;
            }else if (target.qty === 1 && !target.serialNo) {
                showToast('수량이 1인 경우 시리얼번호가 필수입니다.');
                return false;
            }
        }

        // 중복 확인
        const isDuplicateSerialNo = hasDuplicate(store.targetList, 'serialNo');
        if(isDuplicateSerialNo) {
            showToast('시리얼번호에 중복이 있습니다.');
            return;
        }

        if (!await callConfirm('입고하시겠습니까?')) {
            return false;
        }

        await axios.post('/storage/consum/option', store.targetList);
        store.targetList = [];

        showToast('입고 되었습니다.');
    }

    /* 고객 조회 팝업 */
    const searchCustomerPopup = () => {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 조회 콜백 */
    const searchCustomerInfo =(data)=> {
        if(data){
            store.data.buyCustomerNo = data.customerNo;
            store.data.buyCustomerName = data.customerName;
        }
    }

    const onCellValueChanged = (event) => {
        const { colDef, data } = event;
        if (colDef.field === 'qty' && Number(data.qty) > 1) {
            data.serialNo = '';
            getCount();
        }else if (colDef.field === 'serialNo' && Number(data.qty) > 1) {
            showToast('수량이 1보다 큰 경우 시리얼 번호를 입력할 수 없습니다.');
            data.serialNo = '';
        }
        gridRef.current.api.redrawRows();
    };

    const inputBoxContent = () => {
        return (
            <CCol lg={2}>
                <InputGroup size={'sm'}>
                    <InputGroup.Text id={'totalCount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>총 입고 수량</InputGroup.Text>
                    <Form.Control
                        style={{flex: 1, textAlign: 'right'}}
                        max={2}
                        aria-describedby={'totalCount'}
                        value={totalCount}
                        readOnly={true}
                    />
                    <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>개</InputGroup.Text>
                </InputGroup>
            </CCol>
        )
    }

    const getApronList = async() => {
        const result = await axios.get("/user/apron");
        const resultList = result.map(v => {
            v.name = v.apronName;
            v.value = v.apronSeq;
            return v;
        })
        setApronList(resultList);
    }

    const getApronName = (value) => {
        if(value){
            return apronList.find(v => v.value === Number(value)).name;
        }
    }

    return (
        <>
            <CContainer search>
                입고할 제품군을 선택해주세요.
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'제품명'}
                            labelId={'productName'}
                            value={store.data.productName}
                            onCallbackBtn={()=> AppStore.toggleProductListSearchModal()}
                            disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'모델'}
                            labelId={'model'}
                            value={store.data.model}
                            disabled={true}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup
                            label={'스펙'}
                            labelId={'spec'}
                            value={store.data.spec}
                            disabled={true}
                        />
                    </CCol>
                </Row>
                {store.data.productSeq && store.data.isConsumable != 1 && store.data.isOption != 1 &&
                    <Row>
                        <CCol lg={2}>
                            <CInputGroup
                                label={'동력전달방식'}
                                labelId={'powerTransmission'}
                                value={store.data.powerTransmission}
                                disabled={true}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <CInputGroup
                                label={'운행속도'}
                                backLabel={'km/h'}
                                labelId={'drivingSpeed'}
                                value={store.data.drivingSpeed}
                                disabled={true}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <CInputGroup
                                label={'작업최대높이'}
                                backLabel={'m'}
                                labelId={'workingMaximumHeight'}
                                value={store.data.workingMaximumHeight}
                                disabled={true}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <CInputGroup
                                label={'적재용량'}
                                backLabel={'kg'}
                                labelId={'loadCapacity'}
                                value={store.data.loadCapacity}
                                disabled={true}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <CInputGroup
                                label={'운전방식'}
                                labelId={'drivingMethod'}
                                value={store.data.drivingMethod}
                                disabled={true}
                            />
                        </CCol>
                    </Row>
                }
                <Subject>상품 / 소모품 / 옵션 정보</Subject>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'입고 분류'}
                            labelId={'division'}
                            value={store.data.isConsumable ? '소모품' : store.data.isOption ? '옵션' : !store.data.productSeq ? '' : '상품'}
                            disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputDoubleGroup label={'구매처'} labelId={'buyCustomerNo'} labelId2={'buyCustomerName'}
                                       placeholder={'구매처코드'} placeholder2={'구매처'}
                                       disabled={true} disabled2={true}
                                       onCallbackBtn={() => searchCustomerPopup('buyCustomer')}
                                       value={store.data.buyCustomerNo}
                                       value2={store.data.buyCustomerName}
                                       labelClassName='input-required'
                        />
                    </CCol>

                    <CCol log={2}>
                        <CalendarGroup
                            isValid={true}
                            asSingle={true}
                            label={'입고일자'}
                            value={{
                                startDate: store.data.storageDate,
                                endDate: store.data.storageDate
                            }}                            
                            onChange={e=> {store.data.storageDate = e.startDate}}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            isValid={true}
                            label={'단가'}
                            backLabel={'원'}
                            labelId={'amount'}
                            align={'right'}
                            type={'number'}
                            value={store.data.amount || ''}
                            onChange={(e)=> store.data.amount = e.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup
                            isValid={true}
                            label={'주기장'}
                            labelId={'apron'}
                            options={apronList}
                            value={store.data.apronSeq || ''}
                            onChange={(e)=> store.data.apronSeq = e.target.value}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={4}>
                        <CInputGroup
                            label={'비고사항'}
                            labelId={'remark'}
                            value={store.data.remark}
                            onChange={(e)=> store.data.remark = e.target.value}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CIconBtn
                            icon={'fi-ss-plus-small'}
                            onClick={addRow}
                            style={{width: 100}}
                            title={'추가'}
                        />
                        <CCol lg={2} className="ps-2">
                            <CInputGroup label={'개수'} labelId={'qty'}
                                        value={qty}
                                        onChange={v => {
                                            if(isNaN(v.target.value)) {
                                                showToast('숫자만 입력해야 합니다.');
                                                return false;
                                            }
                                            // if(v.target.value < 1) {
                                            //     showToast('1보다 큰 숫자만 입력할 수 있습니다.');
                                            //     return false;
                                            // }
                                            setQty(v.target.value);
                                        }}
                                        type={'number'}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <CSelectGroup
                                label={'추가선택'}
                                labelId={'addOption'}
                                options={[
                                    {name: '시리얼로 관리', value: 'serial'},
                                    {name: '수량으로 관리', value: 'quantity'}
                                ]}
                                value={addOption}
                                onChange={(e) => setAddOption(e.target.value)}
                            />
                        </CCol>
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridTitle='입고 대상 리스트'
                    gridRef={gridRef}
                    height={40}
                    rowData={store.targetList}
                    columnDefs={[
                        {field: "storageSeq", headerName: "순번", width: 90 },
                        {field: "division", headerName: "입고 분류", width: 120},
                        {field: "productName", headerName: "제품명", width: 140},
                        {field: "model", headerName: "모델명", width: 140},
                        {field: "spec", headerName: "스펙", width: 200},
                        {field: "apronName", headerName: "주기장", width: 110},
                        {field: "apronSeq", headerName: "주기장", width: 200, hide: true},
                        {field: "qty", headerName: "수량", width: 100, headerClass: 'grid-column-editable', editable: true, onCellValueChanged: onCellValueChanged},
                        {field: "serialNo", headerName: "시리얼번호", width: 140, headerClass: 'grid-column-editable', editable: true, onCellValueChanged: onCellValueChanged},
                        {field: "storageDate", headerName: "입고일자", width: 120, headerClass: 'grid-column-editable', editable: true},
                        {field: "amount", headerName: "단가", valueFormatter: v => v.value.toLocaleString(), cellClass: 'ag-grid-money-align', width: 130, headerClass: 'grid-column-editable', editable: true},
                        {field: "buyCustomerName", headerName: "구매처", width: 140},
                        {field: "remark", headerName: "비고사항", width: 250, headerClass: 'grid-column-editable', editable: true},
                    ]}
                    seqColumn={'storageSeq'}
                    isCheckBox={true}
                    useUpdated={true}
                    callBackGridData={onSaveStorageAssetAcqu}
                    inputBox={true}
                    inputBoxContent={inputBoxContent()}
                />
            </CContainer>
            <ProductListSearch callbackFn={callbackProductInfo} />
            <CustomerSearch callbackFn={searchCustomerInfo} />
        </>
    )
}

export default observer(StorageConsumOption);
