import { observer } from "mobx-react-lite"
import { AppStore } from "../../../store/AppStore";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "../../../components/Modal";
import { CCol, CContainer, CIconBtn } from "../../../components/CustomContainer";

const CheckPreSendBillItemPopup = ({checkedList, setCheckedList, callbackFn}) => {

    const checkedHandler = (value, isChecked) => {
        if (isChecked) {
            setCheckedList((prev) => [...prev, value]);
            return;
        }

        if (!isChecked && checkedList.includes(value)) {
            setCheckedList(checkedList.filter((item) => item !== value));
            return;
        }
    }

    const onClickFn = () => {
        callbackFn();
        AppStore.toggleCheckPreSendBillItemPopup();
    }
    
    return (
        <>
            <Modal title={'품목설정'}
                    show={AppStore.isOpenCheckPreSendBillItemPopup}
                    onHide={() => AppStore.toggleCheckPreSendBillItemPopup()}
                    toggleValue={AppStore.isOpenCheckPreSendBillItemPopup}
                    size={'sm'}
                    onExit={() => {
                    }}
            >
                <div className="m-3 text-center" >
                    <span style={{fontSize: 15, textAlign: 'center'}}>
                        계산서 발행에 포함시킬 항목을<br/>모두 선택해주세요.
                    </span>
                </div>
                <CContainer search>
                    <Row className="text-center">
                        <CCol lg={12}>
                            <Form.Check
                                inline
                                label="운반비"
                                name="delivery"
                                type={'checkbox'}
                                id="delivery"
                                value={'delivery'}
                                checked={checkedList.includes('delivery')}
                                onChange={v => checkedHandler('delivery', v.target.checked)}
                            />
                        </CCol>
                        <CCol lg={12}>
                            <Form.Check
                                inline
                                label="설치비"
                                name="install"
                                type={'checkbox'}
                                id='install'
                                value={'install'}
                                checked={checkedList.includes('install')}
                                onChange={v => checkedHandler('install', v.target.checked)}
                            />
                        </CCol>
                        <CCol lg={12}>    
                            <Form.Check
                                inline
                                label="옵션"
                                name="option"
                                type={'checkbox'}
                                id='option'
                                value={'option'}
                                checked={checkedList.includes('option')}
                                onChange={v => checkedHandler('option', v.target.checked)}
                            />
                        </CCol>
                    </Row>
                </CContainer>
                <Row>
                    <Col lg={12} className="d-flex justify-content-center">
                    {/* <Col lg={12} className="d-flex flex-row-reverse"> */}
                        <CIconBtn style={{width: 100, height: 30}}
                                title={'확인'}
                                onClick={onClickFn}
                                variant={'primary'}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default observer(CheckPreSendBillItemPopup);