import { observable } from "mobx";

export const AssetMaintenanceListStore = observable({
    assetList: [],
    detailList: [],
    search : {
        customerNo: '',
        customerName: '',
        contractNo: '',
        assetNo: '',
        managementNo: '',
        serialNo: '',
        productName: '',
        model: '',
    },

    setAssetList(v){
        this.assetList = v;
    },
    setDetailList(v){
        this.detailList = v;
    }
})