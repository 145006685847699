import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { CContainer, CCol, CSelectGroup, CalendarGroup, CIconBtn, CSearchBtn } from "../../components/CustomContainer";
import { Row, InputGroup, Form, Col } from "react-bootstrap";
import { SalaryManagerStore } from "../../store/human-resources/SalaryManagerStore";
import { getCommcode } from "../../utils/commcode";
import { getYear, getMonthOfToday, getPaymentDate } from "../../utils/dateUtils";
import { AppStore } from "../../store/AppStore";
import axios from "axios";
import { showToast } from "../../common/utils";
import SendPayStubPopup from "./popup/SendPayStubPopup";
import CalculationFormulaPopup from "./popup/CalculationFormulaPopup";
import { callConfirm } from "../../utils";

const SalaryManagerSearch = ({getUserSalaryList}) => {
    const [formulaList, setFormulaList] = useState([]);
    const [monthList, setMonthList] = useState([{name: '1', value: '01'}
                                                , {name: '2', value: '02'}
                                                , {name: '3', value: '03'}
                                                , {name: '4', value: '04'}
                                                , {name: '5', value: '05'}
                                                , {name: '6', value: '06'}
                                                , {name: '7', value: '07'}
                                                , {name: '8', value: '08'}
                                                , {name: '9', value: '09'}
                                                , {name: '10', value: '10'}
                                                , {name: '11', value: '11'}
                                                , {name: '12', value: '12'}]);

    /* 급여 내역 불러오기 */
    const openSalaryHistory = async () => {

        // 필수값 확인
        let requiredParams = ['salaryType'];
        if (!requiredParams.every(param => SalaryManagerStore.search[param])) {
            showToast('급여구분을 선택해주세요.');
            return;
        }

        if (SalaryManagerStore.userSalaryList.length === 0) {
            showToast('급여 내역 생성 후 불러오기가 가능합니다.');
            return;
        }

        const validClose = SalaryManagerStore.userSalaryList.every(v => v.isClose !== '1')
        if (!validClose) {
            showToast('마감된 급여 내역이 존재합니다.');
            return;
        }

        // 급여 내역 리스트 가져오기
        const result = await axios.get('/salary/history', {params: SalaryManagerStore.search});
        SalaryManagerStore.salaryHistoryList = result;

        AppStore.toggleModal();
    }

    /* 급여 마감 처리 */
    const closeSalary = async () => {
        const validUpdate = SalaryManagerStore.userSalaryList.every(v => !v.isUpdated);
        if (!validUpdate) {
            showToast('수정된 급여가 존재합니다. 저장 후 진행해주세요.');
            return;
        }

        const validSelect = SalaryManagerStore.userSalaryList.every(v => !v.isSelected);
        if (validSelect) {
            showToast('마감처리할 급여내역을 선택해주세요.');
            return;
        }

        if (!await callConfirm('마감 처리 하시겠습니까?')) {
            return;
        }

        const selectedList = SalaryManagerStore.userSalaryList.filter(v => {return v.isSelected});
        await axios.put("/salary/close", selectedList);
        showToast('마감처리가 완료되었습니다.');
        getUserSalaryList();
    }

    /* 명세서 전송 팝업 오픈 */
    const openSendPayStubPopup = () => {

        const validUpdate = SalaryManagerStore.userSalaryList.every(v => !v.isUpdated);
        if (!validUpdate) {
            showToast('먼저 급여를 저장해주세요.');
            return;
        }

        SalaryManagerStore.selectUserList = SalaryManagerStore.userSalaryList.filter(v => v.isSelected);
        if (SalaryManagerStore.selectUserList.length === 0) {
            showToast('사원을 선택해주세요.');
            return;
        }

        const validClose = SalaryManagerStore.selectUserList.every(v => v.isClose === '1')
        if (!validClose) {
            showToast('급여내역 마감처리 후 명세서 전송이 가능합니다.');
            return;
        }

        AppStore.toggleSendPayStubPopup();
    }

    /* 공통계산식 팝업 오픈 */
    const openCalculationFormulaPopup = async () => {
        const result = await axios.get("/salary/calculationFormula");
        setFormulaList(result);
        AppStore.toggleCalculationFormulaPopup();
    }

    return (
        <>
            <CContainer search>
                <Row>
                    {/* <CCol lg={3}>
                        <CSelectGroup label={'귀속연월'} labelId={'yearMonth'}
                                        options={yearList}
                                        value={SalaryManagerStore.search.year}
                                        onChange={v => SalaryManagerStore.search.year = v.target.value}
                        />
                    </CCol> */}
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>귀속년월</InputGroup.Text>
                            <Form.Control
                                style={{flex: 1, textAlign: 'right'}}
                                max={2}
                                aria-describedby={'year'}
                                value={SalaryManagerStore.search.year}
                                onChange={v => SalaryManagerStore.search.year = v.target.value}
                            />
                            {/* <Form.Select
                                style={{flex: 1, textAlign: 'right'}}
                                aria-label={'year'}
                                value={SalaryManagerStore.search.year}
                                onChange={v => SalaryManagerStore.search.year = v.target.value}
                            >
                                {yearList.map((option, idx)=> {
                                    return(
                                        <option key={idx} value={option}>{option}</option>
                                    );
                                })}
                            </Form.Select> */}
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>년</InputGroup.Text>
                            <Form.Select
                                style={{flex: 1, textAlign: 'right'}}
                                aria-label={'month'}
                                value={SalaryManagerStore.search.month}
                                onChange={v => {SalaryManagerStore.search.month = v.target.value; 
                                                SalaryManagerStore.search.paymentDate = getPaymentDate(SalaryManagerStore.search.year+'-'+SalaryManagerStore.search.month+'-01');}}
                            >
                                {monthList.map((option, idx)=> {
                                    return(
                                        <option key={idx} value={option.value}>{option.name}</option>
                                    );
                                })}
                            </Form.Select>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>월</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'급여구분'} labelId={'salaryType'}
                                        options={getCommcode('48')}
                                        value={SalaryManagerStore.search.salaryType}
                                        onChange={v => SalaryManagerStore.search.salaryType = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup
                                asSingle={true}
                                label={'지급일자'}
                                value={{startDate: SalaryManagerStore.search.paymentDate, endDate: SalaryManagerStore.search.paymentDate}}
                                onChange={v => SalaryManagerStore.search.paymentDate = v.startDate}
                        />
                    </CCol>
                    <CIconBtn style={{width: 100, height: 30}}
                                title={'공통계산식'}
                                variant={'outline-dark'}
                                onClick={openCalculationFormulaPopup}
                        />
                </Row>
                <Row>
                    <Col className="d-flex flex-row-reverse">
                        <CIconBtn style={{width: 100, height: 30}}
                                title={'명세서 전송'}
                                icon={'fi-rs-paper-plane'}
                                onClick={openSendPayStubPopup}
                                variant={'success'}
                        />
                        <CIconBtn style={{width: 100, height: 30}}
                                title={'마감'}
                                icon={'fi-rr-assept-document'}
                                onClick={closeSalary}
                                variant={'warning'}
                        />
                        <CIconBtn style={{width: 100, height: 30}}
                                title={'불러오기'}
                                icon={'fi-rr-folder-open'}
                                onClick={openSalaryHistory}
                        />
                        <CSearchBtn style={{width: 100, height: 30}}
                                title={'조회'}
                                onClick={getUserSalaryList}
                        />
                    </Col>
                </Row>
            </CContainer>
            <SendPayStubPopup/>
            <CalculationFormulaPopup formulaList={formulaList}/>
        </>
    );

}

export default observer(SalaryManagerSearch);