import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Col } from "react-bootstrap";

import AgGridContainer from "../../components/AgGridContainer";
import { CContainer } from "../../components/CustomContainer";
import { AccountLedgerStore } from "../../store/accounting/AccountLedgerStore";
import { AppStore } from "../../store/AppStore";
import GeneralStatementDetail from "./GeneralStatementDetail";
import Modal from "../../components/Modal";

const AccountLedgerContainer = ({reSearch}) => {
    const store = AccountLedgerStore;
    const gridRef = useRef();
    
    const contentColumnDefs = [
        {field: "statementNo", headerName: "전표번호", hide: true},
        {field: "postingDate", headerName: "기표일자", minWidth: 120},
        {field: "summary", headerName: "적요", minWidth: 350},
        {field: "customerName", headerName: "고객명", minWidth: 160},
        {field: "businessNumber", headerName: "사업자번호", minWidth: 140},
        {field: "debitAmount", headerName: "차변", minWidth: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
        {field: "creditAmount", headerName: "대변", minWidth: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
        {
            field: "amount", 
            headerName: "잔액", 
            minWidth: 120, 
            cellClass: 'ag-grid-money-align', 
            valueFormatter: v => (v.data.summary === '[월계]' || v.data.summary === '[누계]' || v.data.summary === '[전일이월]') ? '' : ((v.data.debitAmount || 0) - (v.data.creditAmount || 0)).toLocaleString(),
            flex: 1
        },
    ];

    /* 전표 상세 페이지 연동 */
    const onRowDoubleClicked = (e) => {
        store.statementNo = e.data.statementNo;
        AppStore.toggleModal();
    }

    /* 전표 상세 페이지 닫기 */
    const closedModal = () => {
        store.initStatementNo();
        reSearch();
    }

    /* 행 고정 */
    const pinnedTopRowData = [{
        debitAmount: store.accountLedgerList.filter(v => v.summary !== '[월계]' && v.summary !== '[누계]' && v.summary !== '[전일이월]').reduce((total, v) => total + v.debitAmount, 0),
        creditAmount: store.accountLedgerList.filter(v => v.summary !== '[월계]' && v.summary !== '[누계]' && v.summary !== '[전일이월]').reduce((total, v) => total + v.creditAmount, 0),
        amount: store.accountLedgerList.filter(v => v.summary !== '[월계]' && v.summary !== '[누계]' && v.summary !== '[전일이월]').reduce((total, v) => total + v.amount, 0),
    }];

    return (
        <>
            <Col lg={9}>
                <CContainer>
                    <AgGridContainer
                        gridRef={gridRef}
                        gridTitle={''}
                        height={80}
                        rowData={store.accountLedgerList}
                        columnDefs={contentColumnDefs}
                        useCsvDownload={true}
                        rowDoubleClickCallback={e => onRowDoubleClicked(e)}
                        getRowStyle={(params) => {
                            if (params.data.summary === '[월계]' || params.data.summary === '[누계]' || params.data.summary === '[전일이월]') {
                                return { pointerEvents: 'none', background: '#bababa' };
                            }
                            return null;
                        }}
                        pinnedTopRowData={pinnedTopRowData}
                    />
                </CContainer>
                <Modal title={'전표상세'} onExit={closedModal}>
                    <GeneralStatementDetail />
                </Modal>
            </Col>
        </>
    )
}

export default observer(AccountLedgerContainer);
