import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import Modal from '../../../components/Modal'
import {Row, Form, InputGroup, Col} from "react-bootstrap";
import { CContainer, CInputGroup, CCol, CSaveBtn, CSelectGroup } from '../../../components/CustomContainer';
import LUtils from '../../../utils/lodashUtils';
import { getCommcode } from '../../../utils/commcode';
import { ProductStore } from '../../../store/product/ProductStore';
import { MULTIPART_HEADER } from '../../../common/constants';
import { AppStore } from '../../../store/AppStore';
import {insertComma, removeComma, showToast} from "../../../common/utils";
import {callConfirm} from "../../../utils";


const InsertProduct = ( {getProductLst, productSeq} ) => {
    const fileRef1 = useRef();
    const fileRef2 = useRef();
    const fileRef3 = useRef();
    // const [ccSM, setCcSM] = useState('');
    // const [deleted, setDeleted] = useState(0);
    const [lgCode, setLGCode] = useState([]);
    const [mdCode, setMDCode] = useState([]);
    const [smCode, setSMCode] = useState([]);
    const [xsCode, setXSCode] = useState([]);
    const [selectedLGCode, setSelectedLGCode] = useState('');
    const [selectedMDCode, setSelectedMDCode] = useState('');
    const [selectedSMCode, setSelectedSMCode] = useState('');
    const [selectedXSCode, setSelectedXSCode] = useState('');
    const [productGroupList, setProductGroupList] = useState([]);
    // const [productName, setProductName] = useState('');
    // const [model, setModel] = useState('');
    // const [spec, setSpec] = useState('');
    // const [remark, setRemark] = useState('');
    // const [powerTransmission, setPowerTransmission] = useState('');
    // const [drivingSpeed, setDrivingSpeed] = useState(0);
    // const [workingMaximumHeight, setWorkingMaximumHeight] = useState(0);
    // const [loadCapacity, setLoadCapacity] = useState(0);
    // const [drivingMethod, setDrivingMethod] = useState('');
    // const [insurancePolicyUrl, setInsurancePolicyUrl] = useState('');
    // const [insurancePolicyFileName, setInsurancePolicyFileName] = useState('');
    // const [specificationTableUrl, setSpecificationTableUrl] = useState('');
    // const [specificationTableFileName, setSpecificationTableFileName] = useState('');
    // const [safetyCertificateUrl, setSafetyCertificateUrl] = useState('');
    // const [safetyCertificateFileName, setSafetyCertificateFileName] = useState('');
    // const [workingMethodUrl, setWorkingMethodUrl] = useState('');
    // const [workingMethodFileName, setWorkingMethodFileName] = useState('');
    const [isOption, setIsOption] = useState(false);
    const [isConsum, setIsConsum] = useState(false);
    const [productGroupName, setProductGroupName] = useState('');

    useEffect(()=> {
        getProductGroupList();
    }, [])

    useEffect(() => {

        if(productSeq) {
            const list = ProductStore.productList.find(v => v.productSeq === productSeq);
            setMDCode(productGroupList.filter(v => v.parentSeq === list.lgSeq));
            setSMCode(productGroupList.filter(v => v.parentSeq === list.mdSeq));
            setXSCode(productGroupList.filter(v => v.parentSeq === list.smSeq));
            const md = productGroupList.find(v => v.productGroupSeq == list.mdSeq)
            const sm = productGroupList.find(v => v.productGroupSeq == list.smSeq)
            const xs = productGroupList.find(v => v.productGroupSeq == list.xsSeq)
            setSelectedLGCode(String(list.lgSeq));
            setSelectedMDCode(md.productGroupSeq);
            if(sm){
                setSelectedSMCode(sm.productGroupSeq);
            }
            if(xs){
                setSelectedXSCode(xs.productGroupSeq);
            }

            ProductStore.setData({...list, ccSM: list.brandCode});

            // setProductName(list.productName);
            // setModel(list.model);
            // setCcSM(list.brandCode);
            // setPowerTransmission(list.powerTransmission);
            // setDrivingSpeed(list.drivingSpeed);
            // setWorkingMaximumHeight(list.workingMaximumHeight);
            // setLoadCapacity(list.loadCapacity);
            // setDrivingMethod(list.drivingMethod);
            // setSpec(list.spec);
            // setDeleted(list.isDeleted);
            // setInsurancePolicyUrl(list.insurancePolicyUrl);
            // setInsurancePolicyFileName(list.insurancePolicyFileName);
            // setSpecificationTableUrl(list.specificationTableUrl);
            // setSpecificationTableFileName(list.specificationTableFileName);
            // setSafetyCertificateUrl(list.safetyCertificateUrl);
            // setSafetyCertificateFileName(list.safetyCertificateFileName);
            // setWorkingMethodUrl(list.workingMethodUrl);
            // setWorkingMethodFileName(list.workingMethodFileName);
            ProductStore.productFiles.insurancePolicyUrl = list.insurancePolicyUrl;
            ProductStore.productFiles.specificationTableUrl = list.specificationTableUrl;
            ProductStore.productFiles.safetyCertificateUrl = list.safetyCertificateUrl;
            ProductStore.productFiles.workingMethodUrl = list.workingMethodUrl;
            ProductStore.productFiles.emergencyDownUrl = list.emergencyDownUrl;
            ProductStore.productFiles.insurancePolicyName = list.insurancePolicyFileName;
            ProductStore.productFiles.specificationTableName = list.specificationTableFileName;
            ProductStore.productFiles.safetyCertificateName = list.safetyCertificateFileName;
            ProductStore.productFiles.workingMethodName = list.workingMethodFileName;
            ProductStore.productFiles.emergencyDownFileName = list.emergencyDownFileName;
            setIsOption(list.isOption === 1);
            setIsConsum(list.isConsumable === 1);

            setProductGroupName(ProductStore.data.lg);

        }


    }, [productSeq]);

    /* product group 조회 -> 대분류 배열에 담아주기 */
    const getProductGroupList = async () => {
        const result = await axios.get('/product/group/all', { params: { isUsed: 1 } });
        setProductGroupList(result);
        const lgCode = result.filter(v => v.parentSeq === null);
        setLGCode(lgCode);
    }

    /* 대분류 클릭 시 중분류 담아주기 */
    const onChangeLGCode = (e) => {
        const name = lgCode.filter(v => v.productGroupSeq === Number(e.target.value)).map(v => v.productGroupName)[0] || '';
        setSelectedLGCode(e.target.value);
        setProductGroupName(name);
        const mdCode = productGroupList.filter(v => v.parentSeq === Number(e.target.value));
        setMDCode(mdCode);
        setSMCode([]);
        setXSCode([]);
    }

    /* 중분류 클릭 시 소분류 담아주기 */
    const onChangeMDCode = (e) => {
        setSelectedMDCode(e.target.value);
        const smCode = productGroupList.filter(v => v.parentSeq === Number(e.target.value));
        setSMCode(smCode);
    }

    /* 소분류(제품명) 클릭 이벤트 */
    const onChangeSMCode = (e) => {
        setSelectedSMCode(e.target.value);
        const xsCode = productGroupList.filter(v => v.parentSeq === Number(e.target.value));
        setXSCode(xsCode);
    }

    /* 세분류 클릭 이벤트 */
    const onChangeXSCode = (e) => {
        setSelectedXSCode(e.target.value);
    }

    /* 공통코드 소분류 담아주기 */
    const onChangeCcSM = (v) => {
        ProductStore.data.ccSM = v.target.value;
    }

    // 사용여부 스위치 클릭 이벤트
    const handleSwitchChange = (e) => {
        const { checked } = e.target;
        ProductStore.data.isDeleted = checked ?  0 : 1;
    };

    /* 제품 등록 */
    const createProduct = async () => {
        // 필수값 확인
        if (!selectedLGCode || !ProductStore.data.productName || !ProductStore.data.model || !ProductStore.data.ccSM || !ProductStore.data.spec) {
            showToast('필수값을 모두 입력해야 합니다.');
            return;
        }

        if(productGroupName === '고소장비' && (!selectedMDCode || !selectedSMCode)){
            showToast('대/중/소 분류를 모두 선택해 주세요.');
            return;
        }

        if(!await callConfirm('저장하시겠습니까?')) return;

        const xsProductGroupCodeList = xsCode.filter(v => v.productGroupSeq === Number(selectedXSCode)).map(v => v.productGroupCode);

        const productCode = lgCode
            .filter(v => v.productGroupSeq === Number(selectedLGCode))
            .map(v => v.productGroupCode)
            .concat(mdCode.filter(v => v.productGroupSeq === Number(selectedMDCode)).map(v => v.productGroupCode))
            .concat(smCode.filter(v => v.productGroupSeq === Number(selectedSMCode)).map(v => v.productGroupCode))
            .concat(LUtils.isEmpty(xsProductGroupCodeList) ? [0] : xsProductGroupCodeList)
            .join('');

        const params = {
            ...ProductStore.data,
            productGroupName: productGroupName,
            lgSeq: selectedLGCode,
            mdSeq: selectedMDCode,
            smSeq: selectedSMCode,
            xsSeq: selectedXSCode,
            brandCode: ProductStore.data.ccSM,
            productCode,
            isOption: isOption ? 1 : 0,
            isConsum: isConsum ? 1 : 0,
            monthUnitPrice: removeComma(ProductStore.data.monthUnitPrice)
        }

        if(productSeq) {
            await axios.put('/product', params);

        }else {
            await axios.post('/product', params);
        }

        showToast('저장되었습니다.');
        //초기화
        init();

        getProductLst && getProductLst();
        AppStore.toggleInsertProduct();
    }

    /* 파일 업로드 하고 url 가져오기 */
    const fileUploadForS3 = async (fileData, type) => {
        if(LUtils.isEmpty(fileData.target.files)) {
            return false;
        }
        // let result = '';
        const typeCheck = fileData.target.files[0].type  === 'application/pdf';
        ProductStore.fileName = fileData.target.files[0].name;

        let result ='';
        const file = fileData.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('typeCheck', typeCheck);
        formData.append('column', type);

        if(productSeq) {
            formData.append('productSeq', productSeq);
            result = await axios.post('/product/file/upload', formData, { headers: MULTIPART_HEADER });
        }else {
            if(type !== 'workingMethod' && typeCheck) {
                result = await axios.post('/file/upload/s3/pdf', formData, { headers: MULTIPART_HEADER });
            }else {
                result = await axios.post('/file/upload/s3', formData, { headers: MULTIPART_HEADER });
            }
        }

        getProductLst();
        if(type === 'insurancePolicy') {
            ProductStore.data.insurancePolicyUrl = result;
            ProductStore.data.insurancePolicyFileName = file.name;
            ProductStore.productFiles.insurancePolicyUrl = result;
            ProductStore.productFiles.insurancePolicyName = file.name;
        }else if(type === 'specificationTable') {
            ProductStore.data.specificationTableUrl = result;
            ProductStore.data.specificationTableFileName = file.name;
            ProductStore.productFiles.specificationTableUrl = result;
            ProductStore.productFiles.specificationTableName = file.name;
        }else if(type === 'safetyCertificate') {
            ProductStore.data.safetyCertificateUrl = result;
            ProductStore.data.safetyCertificateFileName = file.name;
            ProductStore.productFiles.safetyCertificateUrl = result;
            ProductStore.productFiles.safetyCertificateName = file.name;
        }else if(type === 'workingMethod') {
            ProductStore.data.workingMethodUrl = result;
            ProductStore.data.workingMethodFileName = file.name;
            ProductStore.productFiles.workingMethodUrl = result;
            ProductStore.productFiles.workingMethodName = file.name;
        }else if(type === 'emergencyDown') {
             ProductStore.data.emergencyDownUrl = result;
             ProductStore.data.emergencyDownFileName = file.name;
             ProductStore.productFiles.emergencyDownUrl = result;
             ProductStore.productFiles.emergencyDownFileName = file.name;
         }
    }

    const init =()=> {
        ProductStore.data.productSeq = 0;
        // 모든 state 초기화
        /*setLGCode([]);
        setMDCode([]);
        setSMCode([]);
        setXSCode([]);*/
        setSelectedLGCode('');
        setSelectedMDCode('');
        setSelectedSMCode('');
        setSelectedXSCode('');
    }

    return (
        <Modal
            title={'제품등록'}
            show={AppStore.isOpenInsertProduct}
            onHide={() => AppStore.toggleInsertProduct()}
            toggleValue={AppStore.isOpenInsertProduct}
            onExit={init}
        >
            {/* body */}
            <CContainer fluid>
                <Row>
                    <CCol lg={3}>
                        <CSelectGroup
                            label={'대분류'}
                            value={selectedLGCode}
                            onChange={onChangeLGCode}
                            options={[{value:0, name:'- 대분류 -'}].concat(lgCode.map(v => ({value: v.productGroupSeq, name: v.productGroupName})))}
                            labelClassName='input-required'
                            disabled={productSeq}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup
                            label={'중분류'}
                            value={selectedMDCode}
                            onChange={onChangeMDCode}
                            options={[{value:0, name:'- 중분류 -'}].concat(mdCode.map(v => ({value: v.productGroupSeq, name: v.productGroupName})))}
                            labelClassName='input-required'
                            disabled={productSeq}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup
                            label={'소분류'}
                            value={selectedSMCode}
                            onChange={onChangeSMCode}
                            options={[{value:0, name:'- 소분류 -'}].concat(smCode.map(v => ({value: v.productGroupSeq, name: v.productGroupName})))}
                            labelClassName={productGroupName === '고소장비' && 'input-required'}
                            disabled={productSeq}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup
                            label={'세분류'}
                            value={selectedXSCode}
                            onChange={onChangeXSCode}
                            options={[{value:0, name:'- 세분류 -'}].concat(xsCode.map(v => ({value: v.productGroupSeq, name: v.productGroupName})))}
                            disabled={productSeq}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'제품명'} labelId={'productName'} placeholder={'제품명'} value={ProductStore.data.productName} onChange={(e) => ProductStore.data.productName = e.target.value} labelClassName='input-required' />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'모델명'} labelId={'model'} placeholder={'모델명'} value={ProductStore.data.model} onChange={(e) => ProductStore.data.model = e.target.value} labelClassName='input-required' />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup
                            label={'제조사'}
                            value={ProductStore.data.ccSM}
                            onChange={onChangeCcSM}
                            options={[{value:0, name:'- 제조사 -'}].concat(getCommcode('18'))}
                            labelClassName='input-required'
                            disabled={productSeq}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'표준 월단가'}
                            backLabel={'원'}
                            value={insertComma(ProductStore.data.monthUnitPrice) || 0}
                            onChange={(e) => ProductStore.data.monthUnitPrice = e.target.value}
                        />
                    </CCol>
                </Row>
                {productGroupName === '고소장비' && (
                    <>
                        <Row>
                            <CCol lg={3}>
                                <CInputGroup
                                    label={'동력전달방식'}
                                    value={ProductStore.data.powerTransmission}
                                    onChange={(e) => ProductStore.data.powerTransmission = e.target.value}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CInputGroup
                                    label={'운행속도'}
                                    backLabel={'km/h'}
                                    value={ProductStore.data.drivingSpeed}
                                    onChange={(e) => ProductStore.data.drivingSpeed = e.target.value}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CInputGroup
                                    label={'작업최대높이'}
                                    backLabel={'m'}
                                    value={ProductStore.data.workingMaximumHeight}
                                    onChange={(e) => ProductStore.data.workingMaximumHeight = e.target.value}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CInputGroup
                                    label={'적재용량'}
                                    backLabel={'kg'}
                                    value={ProductStore.data.loadCapacity}
                                    onChange={(e) => ProductStore.data.loadCapacity = e.target.value}
                                />
                            </CCol>
                        </Row>
                        <Row className='mb-3'>
                            <CCol lg={3}>
                                <CInputGroup
                                    label={'운전방식'}
                                    value={ProductStore.data.drivingMethod}
                                    onChange={(e) => ProductStore.data.drivingMethod = e.target.value}
                                />
                            </CCol>
                        </Row>
                    </>
                )}
                {productGroupName === '지게차' && (
                    <>
                        <Row>
                            <CCol lg={3}>
                                <CInputGroup
                                    label={'톤'}
                                    value={ProductStore.data.ton}
                                    onChange={(e) => ProductStore.data.ton = e.target.value}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CInputGroup
                                    label={'마스트 단수'}
                                    backLabel={'단'}
                                    value={ProductStore.data.mastCount}
                                    onChange={(e) => ProductStore.data.mastCount = e.target.value}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CInputGroup
                                    label={'마스트 높이'}
                                    backLabel={'m'}
                                    value={ProductStore.data.mastHeight}
                                    onChange={(e) => ProductStore.data.mastHeight = e.target.value}
                                />
                            </CCol>
                        </Row>
                    </>
                )}
                <Row>
                    <CCol lg={12}>
                        <CInputGroup label={'스펙'} labelId={'spec'} value={ProductStore.data.spec} onChange={(e) => ProductStore.data.spec = e.target.value} placeholder={'/ 로 구분하여 입력해주세요'} labelClassName='input-required'/>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6} style={{display: 'flex'}}>
                        <Form.Check
                            type="switch"
                            id="option-switch"
                            label="옵션"
                            checked={isOption}
                            onChange={()=> {
                                setIsOption(!isOption);
                                setIsConsum(false);
                            }}
                            disabled={productSeq}
                        />
                        <Form.Check
                            type="switch"
                            id="consum-switch"
                            label="소모품"
                            checked={isConsum}
                            onChange={()=> {
                                setIsConsum(!isConsum);
                                setIsOption(false);
                            }}
                            style={{marginLeft: 30}}
                            disabled={productSeq}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        <CInputGroup label={'비고'} labelId={'remark'} value={ProductStore.data.remark} onChange={(e) => ProductStore.data.remark = e.target.value} />
                    </CCol>
                    <CCol lg={3}>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="사용여부"
                            checked={ProductStore.data.isDeleted===0?true:false}
                            onChange={handleSwitchChange}
                        />
                    </CCol>
                </Row>

                <Row>
                    <CCol lg={6}>
                        <InputGroup>
                            <label style={{paddingTop: 5, marginRight: 10}}>
                                {ProductStore.productFiles.insurancePolicyUrl ?
                                    <>
                                        보험증권 :
                                        <a target={'_blank'} style={{paddingLeft: 4}} href={ProductStore.productFiles.insurancePolicyUrl}>
                                            {ProductStore.productFiles.insurancePolicyName}
                                        </a>
                                    </>
                                : '보험증권 업로드'}
                            </label>
                            {ProductStore.productFiles.insurancePolicyName ?
                                <i className={'fi fi-rr-search'} style={{paddingTop:8, cursor: 'pointer'}} onClick={()=> {ProductStore.productFiles.insurancePolicyName = '', ProductStore.data.insurancePolicyFileName = ''}} />
                            :
                                <Form.Control
                                    type="file"
                                    id="profileImg1"
                                    size="sm"
                                    placeholder={'보험증권 업로드'}
                                    onChange={(v) => fileUploadForS3(v, 'insurancePolicy')}
                                    ref={fileRef1}
                                />
                            }
                        </InputGroup>
                    </CCol>
                    <CCol lg={6}>
                        <InputGroup>
                            <label style={{paddingTop: 5, marginRight: 10}}>
                                {ProductStore.productFiles.specificationTableUrl ?
                                    <>
                                        제원표 :
                                        <a target={'_blank'} style={{paddingLeft: 4}} href={ProductStore.productFiles.specificationTableUrl}>
                                            {ProductStore.productFiles.specificationTableName}
                                        </a>
                                    </>
                                : '제원표 업로드'}
                            </label>
                            {ProductStore.productFiles.specificationTableName ?
                                <i className={'fi fi-rr-search'} style={{paddingTop:8, cursor: 'pointer'}} onClick={()=> {ProductStore.productFiles.specificationTableName = '', ProductStore.data.specificationTableFileName = ''}} />
                            :
                                <Form.Control
                                    type="file"
                                    id="profileImg2"
                                    size="sm"
                                    placeholder={'제원표 업로드'}
                                    onChange={(v) => fileUploadForS3(v, 'specificationTable')}
                                    ref={fileRef2}
                                />
                            }
                        </InputGroup>
                    </CCol>
                    <CCol lg={6}>
                        <InputGroup>
                            <label style={{paddingTop: 5, marginRight: 10}}>
                                {ProductStore.productFiles.safetyCertificateUrl ?
                                    <>
                                        안전인증서 :
                                        <a target={'_blank'} style={{paddingLeft: 4}} href={ProductStore.productFiles.safetyCertificateUrl}>
                                            {ProductStore.productFiles.safetyCertificateName}
                                        </a>
                                    </>
                                : '안전인증서 업로드'}
                            </label>
                            {ProductStore.productFiles.safetyCertificateName ?
                                <i className={'fi fi-rr-search'} style={{paddingTop:8, cursor: 'pointer'}} onClick={()=> {ProductStore.productFiles.safetyCertificateName = '', ProductStore.data.safetyCertificateFileName = ''}} />
                            :
                                <Form.Control
                                    type="file"
                                    id="profileImg3"
                                    size="sm"
                                    placeholder={'안전인증서 업로드'}
                                    onChange={(v) => fileUploadForS3(v, 'safetyCertificate')}
                                    ref={fileRef3}
                                />
                            }
                        </InputGroup>
                    </CCol>
                    <CCol lg={6}>
                        <InputGroup>
                            <label style={{paddingTop: 5, marginRight: 10}}>
                                {ProductStore.productFiles.workingMethodUrl ?
                                    <>
                                        작동방법 설명서 :
                                        <a target={'_blank'} style={{paddingLeft: 4}} href={ProductStore.productFiles.workingMethodUrl}>
                                            {ProductStore.productFiles.workingMethodName}
                                        </a>
                                    </>
                                : '작동방법 설명서'}
                            </label>
                            {ProductStore.productFiles.workingMethodName ?
                                <i className={'fi fi-rr-search'} style={{paddingTop:8, cursor: 'pointer'}} onClick={()=> {ProductStore.productFiles.workingMethodName = '', ProductStore.data.workingMethodFileName = ''}} />
                            :
                                <Form.Control
                                    type="file"
                                    id="profileImg4"
                                    size="sm"
                                    placeholder={'작동방법 설명서'}
                                    onChange={(v) => fileUploadForS3(v, 'workingMethod')}
                                    ref={fileRef3}
                                />
                            }
                        </InputGroup>
                    </CCol>
                    <CCol lg={6}>
                        <InputGroup>
                            <label style={{paddingTop: 5, marginRight: 10}}>
                                {ProductStore.productFiles.emergencyDownUrl ?
                                    <>
                                        비상하강 :
                                        <a target={'_blank'} style={{paddingLeft: 4}} href={ProductStore.productFiles.emergencyDownUrl}>
                                            {ProductStore.productFiles.emergencyDownFileName}
                                        </a>
                                    </>
                                : '비상하강'}
                            </label>
                            {ProductStore.productFiles.emergencyDownFileName ?
                                <i className={'fi fi-rr-search'} style={{paddingTop:8, cursor: 'pointer'}} onClick={()=> {ProductStore.productFiles.emergencyDownFileName = '', ProductStore.data.emergencyDownFileName = ''}} />
                            :
                                <Form.Control
                                    type="file"
                                    id="profileImg5"
                                    size="sm"
                                    placeholder={'비상하강'}
                                    onChange={(v) => fileUploadForS3(v, 'emergencyDown')}
                                    ref={fileRef3}
                                />
                            }
                        </InputGroup>
                    </CCol>
                </Row>

                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSaveBtn onClick={createProduct} />
                    </Col>
                </Row>
            </CContainer>
        </Modal>
    )
}

export default observer(InsertProduct);
