import {useEffect, useRef, useState} from "react";
import { observer } from "mobx-react-lite";
import {CContainer} from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import {showToast} from "../../common/utils";
import {getSessionUserSeq, getUserAuthGroup, getUserName} from "../../utils/userUtils";
import WorkLogSearch from "./WorkLogSearch";
import WorkLogPopup from "./popup/WorkLogPopup";
import {AppStore} from "../../store/AppStore";
import {ICON_TRASH} from "../../common/constants";
import {WorkLogStore} from "../../store/customer/WorkLogStore";
import axios from "axios";
import {callConfirm} from "../../utils";
import { findCommKrnm } from "../../utils/commcode";

const WorkLog = () => {
    const gridRef = useRef();
    const [logSeq, setLogSeq] = useState('');
    const [contents, setContents] = useState('');
    const [customerNo, setCustomerNo] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [workType, setWorkType] = useState('');
    const [isComplete, setIsComplete] = useState('');

    /* 조회 */
    const getWorkLogList = async () => {
        const params = {
            startDate: WorkLogStore.startDate.startDate,
            endDate: WorkLogStore.endDate.startDate,
            contents: WorkLogStore.contents,
            userSeq: WorkLogStore.userSeq,
            userName: WorkLogStore.userName,
            workType: WorkLogStore.workType,
            isComplete: WorkLogStore.isComplete
        };
        const result = await axios.get('/workLog/list', { params });
        WorkLogStore.setWorkLogList(result);
    }

    /* 삭제 */
    const deleteWorkLog =async()=> {
        // 선택한 row
        const logList = WorkLogStore.workLogList.filter(v => v.isSelected);

        if(logList.length === 0){
            showToast('삭제건을 선택해 주세요');
            return;
        }

        if(!await callConfirm(`삭제하시겠습니까?`)) return;

        const result = await axios.put(`/workLog`, logList);
        showToast('삭제되었습니다.');

        await getWorkLogList();
    }

    const customButtonInfo = [
        {
            isUsed: true,
            callbackFn:() => deleteWorkLog(),
            variant: 'danger',
            icon: ICON_TRASH,
            title: '삭제',
        }
    ];

    const workLogPopupCallback =async()=> {
        setLogSeq('');
        AppStore.toggleWorkLogPopup();
        await getWorkLogList();
    }

    const rowClickHandler =(param)=> {
        setLogSeq(param.data.logSeq);
        setContents(param.data.contents);
        setCustomerNo(param.data.customerNo);
        setCustomerName(param.data.customerName);
        setWorkType(param.data.workType);
        setIsComplete(param.data.isComplete);
        AppStore.toggleWorkLogPopup();
    }

    return(
        <>
            <WorkLogSearch getWorkLogList={getWorkLogList} setLogSeq={setLogSeq} />
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    gridTitle={'업무 일지 목록'}
                    height={60}
                    rowData={WorkLogStore.workLogList}
                    columnDefs={[
                        {field: "logSeq", headerName: "번호", width: 90},
                        {field: "workType", headerName: "업무타입", valueFormatter: v => findCommKrnm(v.value, '63'), width: 140},
                        {field: "isComplete", headerName: "완료여부", valueFormatter: v => v.value === 1 ? '완료' : '미완료', cellClass: v => v.value === 1 ? 'ag-grid-column-complete' : 'ag-grid-column-cancel', width: 140},
                        {field: "customerNo", headerName: "고객코드", width: 100, hide: true},
                        {field: "customerName", headerName: "고객명", width: 160},
                        {field: "creator", headerName: "작성자", width: 100, valueFormatter: v => getUserName(v.value)},
                        {field: "createdDate", headerName: "작성일", width: 180},
                        {field: "contents", headerName: "업무내용", width: 650},
                        {field: "updater", headerName: "수정자", width: 120, valueFormatter: v => getUserName(v.value)},
                        {field: "updatedDate", headerName: "수정일자", width: 180},
                    ]}
                    isCheckBox={true}
                    seqColumn={'logSeq'}
                    useCsvDownload={true}
                    rowDoubleClickCallback={rowClickHandler}
                    customBtnInfo={customButtonInfo}
                />
            </CContainer>
            <WorkLogPopup callbackFn={workLogPopupCallback}
                        logSeq={logSeq}
                        contents={contents}
                        customerNo={customerNo}
                        customerName={customerName}
                        workType={workType}
                        isComplete={isComplete}
            />
        </>
    );
}

export default observer(WorkLog);
