import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { CCol, CContainer, CIconBtn, CSelectGroup } from "../../../components/CustomContainer";
import { Col, Row } from "react-bootstrap";
import { AsStore } from "../../../store/asset/AsStore";
import { getCommcode } from "../../../utils/commcode";

const SelectMaintenanceCodePopup = ({callBackFn=null, maintenanceCodeList, productType, setProductType, productTypeName, setProductTypeName, lgValue, setLgValue, lgName, setLgName, mdValue, setMdValue, mdName, setMdName, smValue, setSmValue, smName, setSmName}) => {
    // const [productType, setProductType] = useState('');
    // const [productTypeName, setProductTypeName] = useState('');
    const [lgCodeList, setLgCodeList] = useState([]);
    const [mdCodeList, setMdCodeList] = useState([]);
    const [smCodeList, setSmCodeList] = useState([]);
    // const [lgName, setLgName] = useState('');
    // const [mdName, setMdName] = useState('');
    // const [smName, setSmName] = useState('');

    useEffect(() => {
        if (productType === '1') {
            setLgCodeList(getSelectOptionValue('lg', productType));
            setMdCodeList(getSelectOptionValue('md', productType));
            setSmCodeList(getSelectOptionValue('sm', productType));
        } else if (productType === '2') {
            setLgCodeList(getSelectOptionValue(null, productType));
            setMdCodeList(getSelectOptionValue(lgValue, productType));
            setSmCodeList(getSelectOptionValue(mdValue, productType));
        }
    }, [])

    const callBackMaintenenaceCode = () => {
        const data = {
            productType: productType,
            productTypeName: productTypeName,
            lgValue: lgValue,
            lgName: lgName,
            mdValue: mdValue,
            mdName: mdName,
            smValue: smValue,
            smName: smName,
        }

        callBackFn && callBackFn(data);
    }
    
    const getSelectOptionValue = (value, type='') => {
        let codeList = [];
        if (type === '1') {
            codeList = maintenanceCodeList.filter(v => v.division === value).map(v => {return {name: v.maintenanceCodeName, value: v.maintenanceCodeSeq}})
        } else if (type === '2') {
            if (value) {
                codeList = maintenanceCodeList.filter(v => v.parentSeq === Number(value)).map(v => {return {name: v.maintenanceCodeName, value: v.maintenanceCodeSeq}})
            } else {
                codeList = maintenanceCodeList.filter(v => v.productType === Number(type)).map(v => {return {name: v.maintenanceCodeName, value: v.maintenanceCodeSeq}})
            }
        }

        return codeList;
    }
    

    return(
        <>
            <CContainer search>
                <Row>
                    <CCol lg={4}>
                        <CSelectGroup label={'장비구분'} labelId={'productType'}
                                    options={[{name: '- 장비구분 -', value: ''}].concat(getCommcode('68'))}
                                    value={productType}
                                    onChange={(v) => {
                                        setProductType(v.target.value);
                                        setProductTypeName(v.target.options[v.target.selectedIndex].text);
                                        setLgCodeList([]);
                                        setMdCodeList([]);
                                        setSmCodeList([]);
                                        setLgValue('');
                                        setLgName('');
                                        setMdValue('');
                                        setMdName('');
                                        setSmValue('');
                                        setSmName('');
                                        
                                        if (v.target.value === '1') {
                                            setLgCodeList(getSelectOptionValue('lg', v.target.value));
                                            setMdCodeList(getSelectOptionValue('md', v.target.value));
                                            setSmCodeList(getSelectOptionValue('sm', v.target.value));
                                        } else {
                                            setLgCodeList(getSelectOptionValue(null, v.target.value));
                                            setMdCodeList([]);
                                            setSmCodeList([]);
                                        }

                                        // setMaintenanceContent((v.target.options[v.target.selectedIndex].text || '미선택') + " / " + (lgName || '미선택') + " / " + (mdName || '미선택') + " / " + (smName || '미선택'));
                                    }}
                                    disabled={!['1','2','3','4'].includes(AsStore.data.asStatus)}
                                    useLabel={false}
                        />
                    </CCol>
                </Row>        
                <Row>
                    <CCol lg={4}>
                        <CSelectGroup label={'대분류'} labelId={'part'}
                                    options={[{name: '- 대분류 -', value: ''}].concat(lgCodeList)}
                                    value={lgValue}
                                    onChange={v => {
                                        setLgValue(v.target.value);
                                        setLgName(v.target.options[v.target.selectedIndex].text);
                                        if (productType === '2') {
                                            setMdValue('');
                                            setMdName('');
                                            setSmValue('');
                                            setSmName('');
                                            setMdCodeList(getSelectOptionValue(v.target.value, productType));
                                            setSmCodeList([]);
                                        }
                                    }}
                                    disabled={!['1','2','3','4'].includes(AsStore.data.asStatus)}
                                    useLabel={false}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CSelectGroup label={'중분류'} labelId={'component'}
                                    options={[{name: '- 중분류 -', value: ''}].concat(mdCodeList)}
                                    value={mdValue}
                                    onChange={v => {
                                        setMdValue(v.target.value);
                                        setMdName(v.target.options[v.target.selectedIndex].text);
                                        if (productType === '2') {
                                            setSmValue('');
                                            setSmName('');
                                            setSmCodeList(getSelectOptionValue(v.target.value, productType));
                                        }
                                    }}
                                    disabled={!['1','2','3','4'].includes(AsStore.data.asStatus)}
                                    useLabel={false}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CSelectGroup label={'소분류'} labelId={'defect'}
                                    options={[{name: '- 소분류 -', value: ''}].concat(smCodeList)}
                                    value={smValue}
                                    onChange={v => {
                                        setSmValue(v.target.value);
                                        setSmName(v.target.options[v.target.selectedIndex].text);                                                 
                                    }}
                                    disabled={!['1','2','3','4'].includes(AsStore.data.asStatus)}
                                    useLabel={false}
                        />
                    </CCol>
                </Row>
            </CContainer>
            <Row>
                <Col className='d-flex justify-content-center'>
                    <CIconBtn style={{width: '30%'}}
                        title={'확인'}
                        onClick={callBackMaintenenaceCode}
                        variant={'primary'}
                    />
                </Col>
            </Row>
        </>
    )
}

export default observer(SelectMaintenanceCodePopup);