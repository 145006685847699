import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { Row, Form, InputGroup, Col, Table } from "react-bootstrap";
import { CCol, CContainer, Subject, CInputGroup, CIconBtn, CCancelBtn } from "../../components/CustomContainer";
import { BoardStore } from "../../store/board/BoardStore";
import { today, todayForDateTimes } from '../../utils/dateUtils';
import { MULTIPART_HEADER } from "../../common/constants";
import LUtils from "../../utils/lodashUtils";
import { callAlert, callConfirm, getLocalStorage } from "../../utils";
import { USER_INFO } from "../../common/constants";
import { AppStore } from "../../store/AppStore";
import {showToast} from "../../common/utils";

const BoardDetail = (props, getBoardList) => {
    const store = BoardStore;
    const [replyListCount, setReplyListCount] = useState(0);
    const [replyViewMode, setReplyViewMode] = useState(false);
    const [boardReplySeq, setBoardReplySeq] = useState(0);
    const [isReadOnly, setIsReadOnly] = useState(true);

    const userInfo = getLocalStorage(USER_INFO);
    const fileRef = useRef();

    useEffect(() => {
        /* 등록일 경우 */
        if(!props.boardSeq) {
            // 로그인 유저 정보
            store.data.creatorName = userInfo.name;
            store.data.creator = userInfo.userSeq;
            // 날짜 세팅
            store.data.createdDate = todayForDateTimes();
        } else {    /* 상세보기일 경우 */
            getBoardDetail(props.boardSeq);
            getReplyList(props.boardSeq);
        }
    }, [props.boardSeq]);

    /* 게시글 상세 */
    const getBoardDetail = async (boardSeq) => {
        const result = await axios.get('/board', {params: {boardSeq: boardSeq}});
        const likeCount = await axios.get('/board/like', {params: {boardSeq: boardSeq}});
        store.data = result;
        store.data.likeCount = likeCount;
        // 댓글 입력창 비우기
        store.replyInit();
        // 조회수 높이기
        await axios.put('/board/readCount',  {boardSeq: boardSeq});
        store.data.readCount++;
        // viewMode : true 로 지정
        setIsReadOnly(false);
    }

    /* 파일 업로드 하고 url 가져오기 */
    const fileUploadForS3 = async (fileData) => {

        if (LUtils.isEmpty(fileData.target.files)) {
            store.data.attachFileUrl = '';
            return false;
        }

        const file = fileData.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        const result = await axios.post('/file/upload/s3', formData, { headers: MULTIPART_HEADER });

        store.data.attachFileUrl = result;
        store.data.attachFileName = file.name;
    }

    /* 첨부파일 다운로드 */
    const handleDownload = async () => {

        //console.log("파일 url : ", store.data.attachFileUrl);
        //console.log("파일명 : ", store.data.attachFileName);

        fetch(`${store.data.attachFileUrl}`, {
            method: 'GET',
        })
        .then((response) => response.blob())
        .then((blob) => {
            // 블롭(Blob) 객체 생성하고 URL을 생성
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');

            // 생성한 URL과 다운로드할 파일명 설정
            link.setAttribute('href', url);
            link.setAttribute('download', store.data.attachFileName);

            // 링크를 문서(body)에 추가
            document.body.appendChild(link);

            // 링크 클릭 => 파일 다운로드
            link.click();

            // 다운로드 후 링크와 URL을 정리
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url)
        });
    };

    /* 좋아요 버튼 눌렀을 때  */
    const clickLikeBtn = async(type, seq, likeSeq) => {

        // 게시글 좋아요
        if(type === 'likeBoard') {
            if(store.data.boardLikeSeq === 0) {
                const resultSeq = await axios.post('/board/like', { boardSeq: seq, userSeq: userInfo.userSeq })
                store.data.boardLikeSeq = resultSeq;
                store.data.likeCount++;
            } else {
                await axios.delete('/board/like', { params: {boardLikeSeq: seq} });
                store.data.boardLikeSeq = 0;
                store.data.likeCount--;
            }
        } else if(type === 'likeReply') {   // 댓글 좋아요
            if(likeSeq === null) {
                await axios.post('/board/reply/like', {boardReplySeq: seq, userSeq: userInfo.userSeq })
            } else {
                await axios.delete('/board/reply/like', { params: {boardReplyLikeSeq: likeSeq}})
            }
            await getReplyList(store.data.boardSeq);
        }
    }

    /* 버튼 클릭 시 */
    const clickBtn = async(btnText) => {
        store.data.boardType = props.boardType;

        if(btnText === 'create') {  // 등록
            if(!await callConfirm('등록하시겠습니까?')) return;

            const result = await axios.post('/board', store.data);
            store.data.boardSeq = result;
            store.replyInit();
            store.boardReplyList = [];
            showToast('등록이 완료되었습니다.');
        } else if(btnText === 'update') {    // 수정
            if(!await callConfirm("수정하시겠습니까?")) {setReplyViewMode(true); return;}
            await axios.put('/board', store.data);
            showToast('수정이 완료되었습니다.');
        } else if(btnText === 'delete') {   // 삭제
            if(!await callConfirm("삭제하시겠습니까?")) return;
            await axios.delete('/board', {params: { boardSeq : store.data.boardSeq }});

            AppStore.toggleModal();
            showToast('삭제되었습니다.');
        }

        setIsReadOnly(false);
    }

    /* 댓글 작성 */
    const createReply = async() => {
        if(!await callConfirm('등록하시겠습니까?')) return;

        store.reply.boardSeq = store.data.boardSeq;
        store.reply.creator = userInfo.userSeq;
        const result = await axios.post('/board/reply', store.reply);

        // 댓글 리스트 재호출
        await getReplyList(store.reply.boardSeq);
        store.replyInit();
    }

    /* 댓글 리스트 불러오기 */
    const getReplyList = async(boardSeq) => {
        const replyList = await axios.get('/board/reply', {params: {boardSeq: boardSeq}});
        store.boardReplyList = replyList;
        setReplyListCount(replyList.length);
    }

    /* 댓글 수정 */
    const modifyReply = async(comment, boardReplySeq) => {
        if(!await callConfirm("수정하시겠습니까?")) {setReplyViewMode(true); return;}
        await axios.put('/board/reply', {boardSeq: store.data.boardSeq, boardReplySeq: boardReplySeq, comment: comment})
        setReplyViewMode(false);
    }

    /* 댓글 삭제 */
    const clickReplyDeleteBtn = async(boardReplySeq) => {
        if(!await callConfirm("삭제하시겠습니까?")) {return;}
        await axios.delete('/board/reply', {params: {boardReplySeq: boardReplySeq}});
        showToast('삭제되었습니다.');
        await getReplyList(store.data.boardSeq);
    }

    const replayNewLine =(comment)=> {

    }

    return(
        <>
        <CContainer search>

            <Row>
                <CInputGroup labelId={'creator'}
                            type={'hidden'}
                            value={store.data.creator}
                />
                <CInputGroup labelId={'boardSeq'}
                            disabled={true}
                            value={store.data.boardSeq}
                            type={'hidden'}
                />
                <CInputGroup labelId={'boardType'}
                            disabled={true}
                            value={"1"}     // boardType : 1 (공지사항)
                            type={'hidden'}
                />
                <CCol lg={3}>
                    <span>작성자 : {store.data.creatorName}</span>
                </CCol>
                <CCol lg={4}>
                    <span>작성일자 : {store.data.createdDate}</span>
                </CCol>
                <CCol lg={2}>
                    <span>조회수 : {store.data.readCount}</span>
                </CCol>
                <CCol lg={2}>
                    <span>좋아요 : {store.data.likeCount}</span>
                </CCol>
            </Row>
            <Row>
                <CCol lg={12}>
                    <CInputGroup label={'제목'}
                                labelId={'subject'}
                                value={store.data.subject}
                                onChange={v => store.data.subject = v.target.value}
                                readOnly={!isReadOnly}
                    />
                </CCol>
            </Row>
            <Row>
                <CCol lg={12}>
                    <textarea
                         style={{height: 300, width: '100%', padding: 15, borderRadius: 5, borderWidth: 1}}
                         value={store.data.contents}
                         readOnly={!isReadOnly}
                         onChange={v => store.data.contents = v.target.value}
                    />
                </CCol>
            </Row>
            <Row>
                <CCol lg={12}>
                    { !isReadOnly &&
                        <CInputGroup
                            label={'첨부파일'}
                            value={store.data.attachFileName}
                            onCallbackBtn={handleDownload}
                            buttonLabel={'내려받기'}
                            disabled={!isReadOnly}
                        />
                    }
                    { isReadOnly &&
                        <>
                            <CInputGroup label={'첨부파일'}
                                 labelId={'attachFile'}
                                 value={store.data.attachFileName}
                                 disabled={isReadOnly}
                                 style={{marginBottom: 12}}
                            />
                            <InputGroup>
                            <Form.Control
                                type="file"
                                id="attachFile"
                                size="sm"
                                onChange={fileUploadForS3}
                                ref={fileRef}
                            />
                            </InputGroup>
                        </>
                    }
                </CCol>
            </Row>
            <Row>
                <Col className='d-flex flex-row-reverse'>
                    { isReadOnly &&
                        <>
                            <CIconBtn style={{width: 80}}
                                    color={true}
                                    title={store.data.boardSeq ? '저장' : '등록'}
                                    icon={'fi-rr-file-signature'}
                                    onClick={() => store.data.boardSeq ? clickBtn('update'):clickBtn('create')}
                            />
                            {(store.data.boardSeq && isReadOnly) &&
                                <CIconBtn style={{width: 80}}
                                          color={true}
                                          title={'수정취소'}
                                          icon={'fi-rs-eraser'}
                                          onClick={() => setIsReadOnly(false)}
                                />
                            }
                        </>
                    }
                    {/* 자기가 쓴 글에만 표시 */}
                    { (!isReadOnly && store.data.boardSeq !== null && store.data.creator === userInfo.userSeq) &&
                        (<>
                            <CIconBtn style={{width: 80}}
                                    color={true}
                                    title={'수정'}
                                    icon={'fi-rs-eraser'}
                                    onClick={() => setIsReadOnly(true)}/>
                            <CCancelBtn style={{width: 80}}
                                    title={'삭제'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => clickBtn('delete')}
                            />
                        </>)
                    }
                    { !isReadOnly && store.data.boardLikeSeq === 0 ?
                            <a style={{cursor: 'pointer', alignSelf: 'center', marginRight: 10}} onClick={(e) => clickLikeBtn('likeBoard', store.data.boardSeq)}>
                                <i style={{fontSize: 23}} className="fi fi-rr-social-network" />
                            </a>
                        : store.data.boardSeq !== null ?
                            <a style={{cursor: 'pointer', alignSelf: 'center', marginRight: 10}} onClick={(e) => clickLikeBtn('likeBoard',store.data.boardLikeSeq)}>
                                <i style={{fontSize: 23}} className="fi fi-sr-thumbs-up" />
                            </a> : ''
                    }
                </Col>
            </Row>
        </CContainer>
        {/* 댓글은 글 수정/작성 모드에서는 보이지 않도록 */}
        { !isReadOnly === true &&
            <CContainer>
                <Subject>댓글 &nbsp;{replyListCount} 건</Subject>
                <Row className="mb-3">
                    { replyListCount > 0 &&
                        BoardStore.boardReplyList.map((reply, idx) => (
                            <div key={idx} style={{flexDirection: 'row', marginBottom: 20}}>
                                <div style={{marginBottom: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <div>
                                        <span style={{fontSize: 14, fontWeight: '600'}}>{reply.creatorName}</span><span style={{marginLeft: 15}}>{reply.createdDate}</span>
                                    </div>
                                    <div>
                                        {(reply.creator === userInfo.userSeq && !replyViewMode) &&
                                            <>
                                                <CIconBtn style={{minWidth: 40, paddingLeft: 4}}
                                                          color={true}
                                                          title={'수정'}
                                                          onClick={() => {
                                                              setReplyViewMode(true), setBoardReplySeq(reply.boardReplySeq)
                                                          }}/>
                                                <CCancelBtn style={{minWidth: 40}}
                                                            color={true}
                                                            noIcon={true}
                                                            title={'삭제'}
                                                            onClick={() => clickReplyDeleteBtn(reply.boardReplySeq)}/>
                                            </>
                                        }
                                        {(store.data.creator === userInfo.userSeq && replyViewMode) &&
                                            <>
                                                <CIconBtn style={{minWidth: 40, paddingLeft: 4}}
                                                          color={true}
                                                          title={'저장'}
                                                          onClick={() => {modifyReply(reply.comment, reply.boardReplySeq), setBoardReplySeq(reply.boardReplySeq)}}/>
                                                <CIconBtn style={{minWidth: 40, paddingLeft: 4}}
                                                          color={true}
                                                          title={'취소'}
                                                          onClick={() => {setReplyViewMode(false), setBoardReplySeq(reply.boardReplySeq), getReplyList(reply.boardSeq)}}/>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="card" style={{borderWidth: 0, backgroundColor: 'rgba( 255, 255, 255, 0.0)', flexDirection: 'row' }}>
                                    <div className="card-body" style={{whiteSpace: 'pre-wrap'}}>
                                        { (replyViewMode && reply.boardReplySeq === boardReplySeq) ?
                                                <textarea style={{height: 60, width: '100%', padding: 10, borderRadius: 5, borderWidth: 1}}
                                                          value={reply.comment}
                                                          onChange={v => reply.comment = v.target.value}
                                                />
                                            :
                                                reply.comment
                                        }
                                    </div>
                                    { !isReadOnly && reply.boardReplyLikeSeq === null ?
                                        <a style={{cursor: 'pointer', alignSelf: 'center'}} onClick={() => clickLikeBtn('likeReply', reply.boardReplySeq, reply.boardReplyLikeSeq)}>
                                            <i style={{fontSize: 18}} className="fi fi-rr-social-network" />
                                        </a>
                                        :
                                        <a style={{cursor: 'pointer', alignSelf: 'center'}} onClick={() => clickLikeBtn('likeReply', reply.boardReplySeq, reply.boardReplyLikeSeq)}>
                                            <i style={{fontSize: 18}} className="fi fi-sr-thumbs-up" />
                                        </a>
                                    }
                                    <span style={{cursor: 'pointer', alignSelf: 'center', marginLeft: 10, marginRight: 10}}>{reply.boardReplyLikeCount}</span>
                                </div>
                                <hr style={{color: '#BDBDBD'}}/>
                            </div>
                        ))
                    }


                    {/*
                    <Table hover className="m-auto" style={{tableLayout: 'fixed'}}>
                        <colgroup>
                            <col width={12}/>
                            <col width={49}/>
                            <col width={12}/>
                            <col width={17}/>
                            <col width={10}/>
                        </colgroup>
                        <tbody>
                        { store.boardReplyList.map((reply) => (
                            <tr key={reply.boardReplySeq}>
                                <td style={{textAlign:'center'}}>
                                    <span style={{paddingTop: 20}}>{reply.creatorName}</span>
                                </td>
                                <td>
                                    { (replyViewMode && reply.boardReplySeq === boardReplySeq) ?
                                        <Form.Control
                                            id="comment"
                                            value={reply.comment}
                                            onChange={(v) => reply.comment = v.target.value}
                                        />
                                        : reply.comment }
                                </td>
                                <td>
                                    {reply.createdDate}
                                </td>
                                <td>
                                    { (reply.creator === userInfo.userSeq && !replyViewMode ) ?
                                    <>
                                        <CIconBtn style={{minWidth: 40, paddingLeft: 4}}
                                                color={true}
                                                title={'수정'}
                                                onClick={() => {setReplyViewMode(true), setBoardReplySeq(reply.boardReplySeq)}}/>
                                        <CCancelBtn style={{minWidth: 40, paddingLeft: 6}}
                                                color={true}
                                                noIcon={true}
                                                title={'삭제'}
                                                onClick={() => clickReplyDeleteBtn(reply.boardReplySeq)}/>
                                    </> : (store.data.creator === userInfo.userSeq && replyViewMode ) ?
                                            <>
                                                <CIconBtn style={{minWidth: 40, paddingLeft: 4}}
                                                        color={true}
                                                        title={'저장'}
                                                        onClick={() => {modifyReply(reply.comment, reply.boardReplySeq), setBoardReplySeq(reply.boardReplySeq)}}/>
                                                <CIconBtn style={{minWidth: 40, paddingLeft: 4}}
                                                        color={true}
                                                        title={'취소'}
                                                        onClick={() => {setReplyViewMode(false), setBoardReplySeq(reply.boardReplySeq), getReplyList(reply.boardSeq)}}/>
                                            </> : ''
                                    }
                                </td>
                                <td style={{textAlign:'left'}}>
                                    {/* 댓글 좋아요 버튼 }
                                    { !isReadOnly && reply.boardReplyLikeSeq === null ?
                                            <a onClick={() => clickLikeBtn('likeReply', reply.boardReplySeq, reply.boardReplyLikeSeq)}>
                                                <i style={{fontSize: 17}} className="fi fi-rr-social-network" />
                                            </a>
                                        :
                                            <a onClick={() => clickLikeBtn('likeReply', reply.boardReplySeq, reply.boardReplyLikeSeq)}>
                                                <i style={{fontSize: 17}} className="fi fi-sr-thumbs-up" />
                                            </a>
                                    }
                                    <span style={{cursor: 'pointer', alignSelf: 'center', marginLeft: 10}}>{reply.boardReplyLikeCount}</span>
                                </td>
                            </tr>
                        )) }
                        </tbody>
                    </Table>
                    */}
                </Row>
                <Row>
                    <CCol lg={12}>
                        <Subject>댓글작성</Subject>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <textarea style={{height: 60, width: '100%', padding: 10, borderRadius: 5, borderWidth: 1}}
                                      value={store.reply.comment}
                                      onChange={v => store.reply.comment = v.target.value}
                            />
                            <CIconBtn style={{width: 60, height: 60, paddingLeft: 4}}
                                      color={true}
                                      title={'저장'}
                                      variant={'success'}
                                      onClick={() => createReply()}
                            />
                        </div>
                    </CCol>
                </Row>
            </CContainer>
        }
        </>
    );
}

export default observer(BoardDetail);
