import React, { useRef, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from 'axios';
import { Row, Col } from "react-bootstrap";

import AgGridContainer from "../../components/AgGridContainer";
import { CContainer } from "../../components/CustomContainer";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import AccountingCodeSearch from "../../components/searchModal/AccountingCodeSearch";
import { showToast } from "../../common/utils";
import { today , oneMonthAfter } from "../../utils/dateUtils";
import AccountLedgerListSearch from "./AccountLedgerListSearch";
import { AccountLedgerStore } from "../../store/accounting/AccountLedgerStore";
import AccountLedgerContainer from "./AccountLedgerContainer";

const AccountLedgerList = () => {
    const store = AccountLedgerStore;
    const gridRef = useRef();
    const [type, setType] = useState('');

    useEffect(() => {
        // 한달 날짜 세팅
        store.search.startDate = today();
        store.search.endDate = oneMonthAfter(today());
    }, []);

    const columnDefs = [
        {field: "code", headerName: "계정코드", minWidth: 110},
        {field: "name", headerName: "계정과목명", minWidth: 160, flex: 1},
    ];

    /* 조회 */
    const getAccountLedgerList = async () => {
        // 조회 필수값 확인
        if(!store.search.startDate && !store.search.endDate) return showToast('시작일, 종료일을 설정해주세요.');
        if(!store.search.firstAccountCode || !store.search.lastAccountCode) return showToast('계정코드를 입력해주세요.');

        // 계정 목록 조회
        const accountList = await axios.get("statement/account/list", {params: {firstAccountCode: store.search.firstAccountCode, lastAccountCode: store.search.lastAccountCode, lastAccountCode: store.search.lastAccountCode}});
        store.accountList = accountList;
        if(accountList.length === 0) return showToast('조회된 데이터가 없습니다.');

        // 계정별 원장 내용
        const list = store.accountList.map(v => {return v.code});
        store.search.accountList = list.join(',');

        const result = await axios.get("statement/account-ledger/list", {params: store.search});
        store.orgAccountLedgerList = result;

        if(result.length === 0) return showToast('조회된 데이터가 없습니다.');
        groupingAccount(result);
    }

    /* 전일이월 조회 */
    const getPreSum = async () => { 
        const accountList = store.accountList.filter(v => v.isSelected).map(item => item.code);
        store.search.accountSelectedList = accountList.length > 0 ? accountList.join(',') : store.accountList.map(item => item.code).join(',');
        const result = await axios.get("statement/account-ledger/pre", {params: store.search});
        return result;
    }

    /* 월계, 누계 조회 */
    const groupingAccount = (result) => {
        if(result.length > 0) {
            const finalResultList = [];

            getPreSum().then(preSum => {
                const preSumResult = preSum; // 비동기 작업이 완료되면 결과를 얻을 수 있음
                finalResultList.push(preSumResult);
                
                // result를 월별로 그룹화하여 합계를 계산
                const groupedData = _.groupBy(result, (item) => item.postingDate.substring(0, 7));
                const monthlySummaries = Object.entries(groupedData).map(([month, data]) => ({
                    postingDate: month,
                    debitAmount: _.sumBy(data, 'debitAmount'),
                    creditAmount: _.sumBy(data, 'creditAmount'),
                    summary: '[월계]'
                }));
                
                let currentMonth = '';
                let preMonth = result.slice(0, 1).map((v) => v.postingDate.substring(0, 7));
                let debitTotalSum = preSumResult.debitAmount; // 누계 합계를 저장하는 변수
                let creditTotalSum = preSumResult.creditAmount; // 누계 합계를 저장하는 변수

                result.forEach((item) => {
                    const month = item.postingDate.substring(0, 7);
                    if (currentMonth !== '' && month !== currentMonth) {
                        // 월이 변경되면 해당 월의 합계 데이터 추가
                        const sumMonthRow = monthlySummaries.find((monthItem) => monthItem.postingDate.startsWith(preMonth));
                        if (sumMonthRow) {
                            finalResultList.push(sumMonthRow);
                        }
        
                        // 누계 행 추가    
                        debitTotalSum += sumMonthRow ? sumMonthRow.debitAmount : 0;
                        creditTotalSum += sumMonthRow ? sumMonthRow.creditAmount : 0;
                        finalResultList.push({
                            postingDate: `${preMonth}`,
                            debitAmount: debitTotalSum,
                            creditAmount: creditTotalSum,
                            summary: '[누계]'
                        });
                        preMonth = month;
                    }
                    currentMonth = month;
                    finalResultList.push(item);
                });
        
                const sumMonthRow = monthlySummaries.find((monthItem) => monthItem.postingDate.startsWith(preMonth));
                finalResultList.push(sumMonthRow);
        
                // 마지막으로 마지막 월의 누계 행을 추가
                debitTotalSum += sumMonthRow ? sumMonthRow.debitAmount : 0;
                creditTotalSum += sumMonthRow ? sumMonthRow.creditAmount : 0;
                finalResultList.push({
                    postingDate: `${preMonth}`,
                    debitAmount: debitTotalSum,
                    creditAmount: creditTotalSum,
                    summary: '[누계]'
                });
                store.accountLedgerList = finalResultList;
            });
        }else {
            store.accountLedgerList = result;
        }
    }

    /* 재조회 */
    const reSearch = async () => {
        const list = store.accountList.map(v => {return v.code});
        store.search.accountList = list.join(',');

        const result = await axios.get("statement/account-ledger/list", {params: store.search});
        store.orgAccountLedgerList = result;

        if(result.length === 0) return showToast('조회된 데이터가 없습니다.');
        groupingAccount(result);
    }

    /* 선택 조회 */
    const getCheckboxClickEvent = (isSelected, e) => {
        const selectedAccounts = store.accountList.filter(v => v.isSelected).map(v => v.code);
        const allAccounts = selectedAccounts.length > 0 ? selectedAccounts : store.accountList.map(v => v.code);
        const accountList = new Set(allAccounts);
        const result = store.orgAccountLedgerList.filter(v => accountList.has(v.accountCode));
        
        groupingAccount(result);
    }
    
    /* 고객 팝업 callback */
    const searchCustomerInfo =(data)=> {
        store.search.customerNo = data.customerNo;
        store.search.customerName = data.customerName;
    }

    /* 계정 과목 팝업 callback */
    const searchAccountingCode = (data) => {
        if(type === 'first') {
            store.search.firstAccountCode = data.code;
            store.search.firstAccountName = data.name;
            store.search.lastAccountCode = data.code;
            store.search.lastAccountName = data.name;
        }else {
            store.search.lastAccountCode = data.code;
            store.search.lastAccountName = data.name;
        }
    }

    return (
        <>
            <CustomerSearch callbackFn={searchCustomerInfo} />
            <AccountingCodeSearch callbackFn={searchAccountingCode} inputCode={null} />
            <AccountLedgerListSearch getAccountLedgerList={getAccountLedgerList} setType={setType} />
            <Row>
                <Col lg={3}>
                    <CContainer>
                        <AgGridContainer
                                gridRef={gridRef}
                                gridTitle={'계정별원장'}
                                height={80}
                                rowData={store.accountList}
                                columnDefs={columnDefs}
                                isCheckBox={true}
                                getCheckboxClickEvent={getCheckboxClickEvent}
                                gridMarginTop={28}
                            />
                    </CContainer>
                </Col>
                <AccountLedgerContainer reSearch={reSearch} />
            </Row>
        </>
    )
}

export default observer(AccountLedgerList);
