import React, {useRef, useState, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {CalendarGroup, CCol, CContainer, CInputGroup, CSearchBtn, CSelectGroup} from "../../components/CustomContainer";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import {getCommcode} from "../../utils/commcode";
import axios from "axios";
import {ProductStore} from "../../store/product/ProductStore";
import {CommcodeSMOptions} from "../../components";
import {StockStore} from "../../store/asset/StockStore";
import ProductListSearch from "../../components/searchModal/ProductListSearch";
import {AppStore} from "../../store/AppStore";
import {showToast} from "../../common/utils";
import { useLocation } from "react-router-dom";
import LUtils from "../../utils/lodashUtils";
import { getLocalStorage } from "../../utils";
import { USER_INFO } from "../../common/constants";

const StockListSearch =({selSearchItem, setSelSearchItem})=> {
    const [apronList, setApronList] = useState([]);

    const location = useLocation();

    useEffect(() => {
        getProductGroupList();
        getApronList();

        const fromPage = LUtils.get(location.state, 'from', null) ;
        if(fromPage === 'dashboard'){
            getStockList()
        }

        StockStore.setApronSeq(getLocalStorage(USER_INFO).apronSeq);

        return ()=> {
            StockStore.setLGCode('');
            StockStore.setMDCode('');
            StockStore.setSMCode('');
        }
    }, []);

    const getApronList = async() => {
        const result = await axios.get("/user/apron");
        const resultList = result.map(v => {
            v.name = v.apronName;
            v.value = v.apronSeq;
            return v;
        })
        setApronList(resultList);
    }

    const getStockList =async ()=> {
        let params = {
            lgSeq: StockStore.selectedLGCode,
            mdSeq: StockStore.selectedMDCode,
            smSeq: StockStore.selectedSMCode,
            productSeq: StockStore.productSeq,
            productName: !StockStore.productSeq ? StockStore.productName : null,
            model: StockStore.model,
            brandCode: StockStore.brandCode,
            spec: StockStore.spec,
            startDate: StockStore.startDate,
            endDate: StockStore.endDate,
            isAvailableStorage: StockStore.isAvailableStorage,
            apronSeq: StockStore.apronSeq,
        }

        let url = [];
        if(selSearchItem != 'consumable'){
            url = [await axios.get(`/stock/${selSearchItem}`, {params})]
        }else{
            url = [await axios.get(`/stock/option`, {params}), await axios.get(`/stock/consumable`, {params})]
        }
        Promise.all(url)
        .then(r => {
            const list = [];

            for(let i in r){
                for(const data of r[i]){
                    list.push(data);
                }
            }
            StockStore.setStockList(list);

            if(list.length === 0){
                showToast('조회된 데이터가 없습니다.');
            }
        });
    }

    /* product group 조회 -> 대분류 배열에 담아주기 */
    const getProductGroupList = async () => {
        const result = await axios.get('/product/group/all');
        if(result.length > 0){
            StockStore.setProductGroupList(result);
            const lgCode = result.filter(v => v.parentSeq === null);
            StockStore.setLGCode(lgCode);
        }
    }


    /* 대분류 클릭 시 중분류 담아주기 */
    const onChangeLGCode = (e) => {
        StockStore.setSelectedLgCode(e.target.value);
        const mdCode = StockStore.productGroupList.filter(v => v.parentSeq === Number(e.target.value));
        StockStore.setMDCode(mdCode);
        ProductStore.setProductSearchCondition({
            lgSeq: e.target.value,
            mdSeq: '',
            smSeq: '',
        });
    }

    /* 중분류 클릭 시 소분류 담아주기 */
    const onChangeMDCode = (e) => {
        StockStore.setSelectedMDCode(e.target.value);
        const smCode = StockStore.productGroupList.filter(v => v.parentSeq === Number(e.target.value));
        StockStore.setSMCode(smCode);
        ProductStore.setProductSearchCondition({
            lgSeq: '',
            mdSeq: e.target.value,
            smSeq: '',
        });
    }

    /* 소분류(모델명) 클릭 이벤트 */
    const onChangeSMCode = (e) => {
        StockStore.setSelectedSMCode(e.target.value);
        ProductStore.setProductSearchCondition({
            lgSeq: '',
            mdSeq: '',
            smSeq: e.target.value,
        });
    }

    const changeSearchItem =(e)=> {
        StockStore.setStockList([]);
        setSelSearchItem(e.target.value);
    }

    const productSearchEndFn =(v)=> {
        StockStore.setProductSeq(v.productSeq);
        StockStore.setProductName(v.productName);
    }

    return(
        <>
            {/* 검색조건 */}
            <CContainer search>
                <Row>
                    <CCol lg={6} style={{marginTop: 5}}>
                        <Form.Check
                            inline
                            label="자산재고"
                            name="selSearchItem"
                            type={'radio'}
                            value={'asset'}
                            id={`asset`}
                            checked={selSearchItem === 'asset'}
                            onChange={changeSearchItem}
                        />
                        <Form.Check
                            inline
                            label="상품재고"
                            name="selSearchItem"
                            type={'radio'}
                            id={`goods`}
                            value={'goods'}
                            checked={selSearchItem === 'goods'}
                            onChange={changeSearchItem}
                        />
                        <Form.Check
                            inline
                            label="옵션(소모품)"
                            name="selSearchItem"
                            type={'radio'}
                            value={'consumable'}
                            id={`consumable`}
                            checked={selSearchItem === 'consumable'}
                            onChange={changeSearchItem}
                        />
                    </CCol>
                </Row>
                {selSearchItem !== 'consumable' &&
                    <Row>
                        <CCol lg={2}>
                            <InputGroup size={'sm'}>
                                <Form.Select value={StockStore.selectedLGCode || ''} onChange={onChangeLGCode}>
                                    <option value={''}>- 대분류 -</option>
                                    {StockStore.lgCode.length > 0 && StockStore.lgCode.map((lg, idx) => (
                                        <option key={idx} value={lg.productGroupSeq}>{lg.productGroupName}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </CCol>
                        <CCol lg={2}>
                            <InputGroup size={'sm'}>
                                <Form.Select value={StockStore.selectedMDCode || ''} onChange={onChangeMDCode}>
                                    <option value={''}>- 중분류 -</option>
                                    {StockStore.mdCode.length > 0 && StockStore.mdCode.map((md,idx) => (
                                        <option key={idx} value={md.productGroupSeq}>{md.productGroupName}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </CCol>
                        <CCol lg={2}>
                            <InputGroup size={'sm'}>
                                <Form.Select value={StockStore.selectedSMCode || ''} onChange={onChangeSMCode}>
                                    <option value={''}>- 소분류 -</option>
                                    {StockStore.smCode.length > 0 && StockStore.smCode.map((sm,idx) => (
                                        <option key={idx} value={sm.productGroupSeq}>{sm.productGroupName}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </CCol>
                        {selSearchItem === 'asset' &&
                            <CCol lg={4}>
                                <CalendarGroup label={'재고기준일'}
                                               value={{startDate: StockStore.startDate, endDate: StockStore.endDate}}
                                               onChange={(v) => {
                                                   StockStore.setStartDate(v.startDate);
                                                   StockStore.setEndDate(v.endDate);
                                               }}
                                />
                            </CCol>
                        }
                    </Row>
                }
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'제품명'}
                                     value={StockStore.productName}
                                     onCallbackBtn={()=> AppStore.toggleProductListSearchModal()}
                                     onChange={(e)=> StockStore.setProductName(e.target.value)}
                                     enterKeyHint={getStockList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'모델명'}
                            labelId={'model'}
                            value={StockStore.model}
                            onChange={(e)=> StockStore.setModel(e.target.value)}
                            enterKeyHint={getStockList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'스펙'}
                            labelId={'spec'}
                            value={StockStore.spec}
                            onChange={(e)=> StockStore.setSpec(e.target.value)}
                            enterKeyHint={getStockList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup
                            label={'제조사'} labelId={'brandCode'}
                            options={[{ value: '', name: '전체' }].concat(getCommcode('18'))}
                            value={StockStore.brandCode}
                            onChange={v => StockStore.setBrandCode(v.target.value)} />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup
                            label={'주기장'} labelId={'apron'}
                            options={[{ value: '', name: '전체' }].concat(apronList)}
                            value={StockStore.apronSeq || ''}
                            onChange={v => StockStore.setApronSeq(v.target.value)} />
                    </CCol>
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isAvailableStorage"
                            label="재고 보유분만"
                            checked={StockStore.isAvailableStorage}
                            style={{marginTop: 4}}
                            onChange={() => StockStore.setIsAvailableStorage(!StockStore.isAvailableStorage)}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getStockList} />
                    </Col>
                </Row>
            </CContainer>
            <ProductListSearch callbackFn={productSearchEndFn}/>
        </>
    );
}

export default observer(StockListSearch);
