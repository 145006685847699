import { observer } from "mobx-react-lite";
import { Row, Col, Form } from "react-bootstrap";

import { CContainer, CCol, CalendarGroup, CInputGroup, CInputDoubleGroup, CSelectGroup, CSearchBtn } from "../../components/CustomContainer";
import { getCommcode } from "../../utils/commcode";
import { ShipStore } from "../../store/ship/ShipStore";
import { AppStore } from "../../store/AppStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import ContractSearch from '../../components/searchModal/ContractSearch';
import UserSearch from "../../components/searchModal/UserSearch";
import LUtils from "../../utils/lodashUtils";
import { showToast } from "../../common/utils";

const ShipListSearch = ({getShipList, userKind, setUserKind, gridData}) => {

    const searchCustomerInfo =(data)=> {
        if(data){
            ShipStore.search.customerNo = data.customerNo;
            ShipStore.search.customerName = data.customerName;
        }
    }

    /* 계약조회 팝업 콜백 */
    const callbackContractInfo = (data) => {
        ShipStore.search.contractNo = data.contractNo;
    }

    /* 계약자 조회, 배송담당자 조회, 배송담당자 할당 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        if(userKind === 'saleSearch') {
            ShipStore.search.saleUserSeq = userInfo.userSeq;
            ShipStore.search.saleUserName = userInfo.name;
        }else if(userKind === 'deliverySearch'){
            ShipStore.search.deliveryUserSeq = userInfo.userSeq;
            ShipStore.search.deliveryUserName = userInfo.name;
        }else if(userKind === 'delivery') {
            const result = ShipStore.shipList.map(v => {
                if(v.shipSeq === gridData.data.shipSeq) {
                    v.deliveryUserSeq = userInfo.userSeq;
                    v.deliveryUserName = userInfo.name;
                    LUtils.assign(v, {isUpdated: true });
                }
                return v;
            });
            ShipStore.setShipList(result);
        }
    }

    const searchShipList =()=> {
        getShipList();
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'배송일'}
                            value={{startDate: ShipStore.search.startDate, endDate: null}}
                            onChange={v => ShipStore.search.startDate = v.startDate}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'배송일'}
                            value={{startDate: ShipStore.search.endDate, endDate: null}}
                            onChange={v => ShipStore.search.endDate = v.startDate}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'계약번호'} labelId={'contractNo'}
                                     value={ShipStore.search.contractNo}
                                     onCallbackBtn={() => AppStore.toggleContractSearchModal()}
                                     onChange={v => ShipStore.search.contractNo = v.target.value}
                                     enterKeyHint={searchShipList}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputDoubleGroup label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                                           placeholder={'고객코드'} placeholder2={'고객명'}
                                           disabled={false} disabled2={true}
                                           onCallbackBtn={() => AppStore.toggleCustomerSearchModal()}
                                           value={ShipStore.search.customerNo}
                                           value2={ShipStore.search.customerName}
                                           onChange={v => ShipStore.search.customerNo = v.target.value}
                                           onChange2={v => ShipStore.search.customerName = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'배송상태'} labelId={'shipStatus'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('27'))}
                                      value={ShipStore.search.shipStatus}
                                      onChange={v => ShipStore.search.shipStatus = v.target.value}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'계약자'} labelId={'saleUserSeq'}
                                     value={ShipStore.search.saleUserName}
                                     onCallbackBtn={() => {AppStore.toggleUserSearchModal(), setUserKind('saleSearch')}}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'배송방법'} labelId={'shipType'}
                                       placeholder={'배송방법'}
                                       options={[{name: '전체', value: ''}].concat(getCommcode('4'))}
                                       value={ShipStore.search.shipType || ''}
                                       onChange={v => ShipStore.search.shipType = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'출고의뢰상태'} labelId={'releaseAskStatus'}
                                        placeholder={'출고의뢰상태'}
                                        options={[{name: '전체', value: ''}].concat(getCommcode('5'))}
                                        value={ShipStore.search.releaseAskStatus || ''}
                                        onChange={v => ShipStore.search.releaseAskStatus = v.target.value}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'배송비고'} labelId={'shipRemark'}
                                    value={ShipStore.search.shipRemark}
                                    onChange={v => ShipStore.search.shipRemark = v.target.value}
                                    enterKeyHint={searchShipList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'배송담당자'} labelId={'deliveryUserSeq'}
                                     value={ShipStore.search.deliveryUserName}
                                     onCallbackBtn={() => {AppStore.toggleUserSearchModal(), setUserKind('deliverySearch')}}
                                     disabled={true}
                        />
                    </CCol>
                    {/*
                    <CCol lg={2}>
                        <CSelectGroup label={'권역설정'} labelId={'areaCode'}
                                        options={[{name: '전체', value: ''}].concat(getCommcode('28'))}
                                        value={ShipStore.search.areaCode}
                                        onChange={v => ShipStore.search.areaCode = v.target.value}
                        />
                    </CCol>
                    */}
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CSelectGroup label={'시간'} labelId={'shipHopeTime'}
                                        options={[{name: '전체', value: ''}].concat(getCommcode('29'))}
                                        value={ShipStore.search.shipHopeTime}
                                        onChange={v => ShipStore.search.shipHopeTime = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'운송장번호'} labelId={'invoice'}
                                value={ShipStore.search.invoice}
                                onChange={v => {
                                    if (isNaN(v.target.value)) {
                                        showToast('숫자만 입력해야 합니다.');
                                        return false;
                                    }
                                    ShipStore.search.shipSeq = v.target.value;
                                }}
                                enterKeyHint={searchShipList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'출고의뢰번호'} labelId={'releaseAskSeq'}
                                     value={ShipStore.search.releaseAskSeq}
                                     onChange={v => ShipStore.search.releaseAskSeq = v.target.value}
                                     enterKeyHint={searchShipList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'외주 운반사'} labelId={'outsourcingCompany'}
                                    value={ShipStore.search.outsourcingCompany}
                                    onChange={v => ShipStore.search.outsourcingCompany= v.target.value}
                                    enterKeyHint={searchShipList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'외주 운전자명'} labelId={'outsourcingDriver'}
                                    value={ShipStore.search.outsourcingDriver}
                                    onChange={v => ShipStore.search.outsourcingDriver= v.target.value}
                                    enterKeyHint={searchShipList}
                        />
                    </CCol>
                </Row>
                <Row className="align-items-center">
                    <CCol lg={4} >
                        <Form.Check
                            inline
                            label="전체"
                            name="all"
                            type={'radio'}
                            id='all'
                            value={''}
                            checked={ShipStore.search.division === ''}
                            onChange={(e)=> ShipStore.search.division = e.target.value}
                        />
                        <Form.Check
                            inline
                            label="출고"
                            name="releaseAsk"
                            type={'radio'}
                            id='releaseAsk'
                            value={'1'}
                            checked={ShipStore.search.division === '1'}
                            onChange={(e)=> ShipStore.search.division = e.target.value}
                        />
                        <Form.Check
                            inline
                            label="회수"
                            name="returnAsk"
                            type={'radio'}
                            id='returnAsk'
                            value={'2'}
                            checked={ShipStore.search.division === '2'}
                            onChange={(e)=> ShipStore.search.division = e.target.value}
                        />
                        <Form.Check
                            inline
                            label="출고/회수"
                            name="relaseReturn"
                            type={'radio'}
                            id='relaseReturn'
                            value={'3'}
                            checked={ShipStore.search.division === '3'}
                            onChange={(e)=> ShipStore.search.division = e.target.value}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getShipList} />
                    </Col>
                </Row>
            </CContainer>
            <CustomerSearch callbackFn={searchCustomerInfo}/>
            <ContractSearch callbackFn={callbackContractInfo}/>
            <UserSearch callbackFn={getUserInfo} search={userKind === 'deliverySearch' || userKind === 'delivery' ? 'ship' : ''}/>
        </>
    );

}

export default observer(ShipListSearch);
