import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const SiteInfoStore = observable({
    startDate: { startDate:'', endDate: '' },
    endDate: { startDate:'', endDate: '' },
    searchCondition: {
        siteName: '',
        managerName: '',
        managerPhoneNumber: '',
        userSeq: '',
        userName: '',
        isDeleted: 1,
    },
    detail: {
        siteSeq: '',
        siteName: '',
        siteStatus: '',
        managerName: '',
        managerPhoneNumber: '',
        managerEmail: '',
        isAutoMailSend: 0,
        postCode: '',
        address: '',
        addressDetail: '',
        characterRemark: '',
        customerNo: '',
        customerName: ''
    },
    siteList: [],
    siteOptionList: [],
    siteManagerList: [],

    setStartDate(v){
        this.startDate = v;
    },
    setEndDate(v){
        this.endDate = v;
    },
    setSiteList(v){
        this.siteList = v;
    },
    setSearchCondition(v, target){
        this.searchCondition[target] = v;
    },
    setDetail(v, target){
        this.detail[target] = v;
    },
    pushSiteOption(v){
        this.siteOptionList.push(v);
    },
    setSiteDetail(v) {
        LUtils.assign(this.detail, v);
    },
    setSiteOptionList(v){
        this.siteOptionList = v;
    },
    setSiteManagerList(v){
        this.siteManagerList = v;
    },
    pushSiteManager(v){
        this.siteManagerList.push(v);
    },

    resetSiteDetail(){
        this.detail.siteSeq = '';
        this.detail.siteName = '';
        this.detail.siteStatus = '';
        this.detail.managerName = '';
        this.detail.managerPhoneNumber = '';
        this.detail.managerEmail = '';
        this.detail.postCode = '';
        this.detail.address = '';
        this.detail.addressDetail = '';
        this.detail.characterRemark = '';
        this.siteManagerList = [];
        this.detail.customerNo = '';
        this.detail.customerName = '';
    }
});
