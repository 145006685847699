import axios from "axios";
import * as Utils from "."
import {USER_INFO, USER_LIST} from "../common/constants";
import LUtils from "./lodashUtils";
import {callAlert} from ".";
import {findCommKrnm, getCommcode} from "./commcode";

/* user 목록 조회해서 localStorage에 저장 */
const refreshUserInfo = async () => {
    try {
        const companyCode = getSessionUserCompanyCode();
        const result = await axios.get('/user/list', {params: {companyCode}});
        Utils.setLocalStorage(USER_LIST, LUtils.map(result, v => ({userSeq: v.userSeq, id: v.id, name: v.name, authorityGroupSeq: v.authorityGroupSeq})));
    } catch (error) {
        console.log('[user utils error] ', error);
    }
}

/* userSeq로 이름 조회 */
const getUserName = (userSeq) => {
    if (userSeq !== '' && userSeq !== null) {
        const userList = Utils.getLocalStorage(USER_LIST);
        if (userList && userList.length > 0 ) {
            const user = userList.find(v => Number(v.userSeq) === Number(userSeq)); // TODO: 조회된 값이 없을 경우 처리로직 추가되어야 함
            if(user){
                return user.name || '-';
            }
        } else {
            refreshUserInfo();
            return '';
        }
    }
}

const getSessionUserSeq =()=> {
    const userInfo = Utils.getLocalStorage(USER_INFO);
    if(!userInfo){
        callAlert('사용자 정보가 존재하지 않습니다.');
    }
    return userInfo.userSeq;
}

const getSessionUserCompanyCode =()=> {
    const userInfo = Utils.getLocalStorage(USER_INFO);
    return userInfo ? userInfo.companyCode : null;
}

const getUserImageUrl =()=> {
    const userInfo = Utils.getLocalStorage(USER_INFO);
    return userInfo.imageUrl;
}

const getUserAuthGroup =()=> {
    const userList = Utils.getLocalStorage(USER_LIST);
    if (userList.length > 0 ) {
        const { authorityGroupSeq } = userList.find(v => Number(v.userSeq) === Number(getSessionUserSeq()));
        return authorityGroupSeq || '';
    }
}

export {
    refreshUserInfo,
    getUserName,
    getSessionUserSeq,
    getSessionUserCompanyCode,
    getUserImageUrl,
    getUserAuthGroup
}
