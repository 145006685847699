import axios from "axios";
import { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite"
import { Row, Col } from "react-bootstrap";

import { CCol, CContainer, CalendarGroup, CSearchBtn, CInputDoubleGroup, CIconBtn } from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import { showToast } from "../../common/utils";
import { AppStore } from "../../store/AppStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import AccountingCodeSearch from "../../components/searchModal/AccountingCodeSearch";
import { CustomerLedgerStore } from "../../store/accounting/CustomerLedgerStore";
import { startDateForThisYear, endDateForThisYear, startDateForThisMonth, endDateForThisMonth, startDateForLastYear, endDateForLastYear, startDateForNextYear, endDateForNextYear, startDateForLastMonth, endDateForLastMonth, startDateForNextMonth, endDateForNextMonth, getMonth } from "../../utils/dateUtils";
import Modal from "../../components/Modal";
import GeneralStatementDetail from "./GeneralStatementDetail";

const CustomerLedgerDetail = ({getCustomerLedgerList}) => { 
    const store = CustomerLedgerStore;
    const gridRef = useRef();

    useEffect(() => {
        if(store.openDetail){
            getCustomerLedgerDetail();
        }
    }, [store.openDetail])

    /* 금년 */
    const thisYearBtn = () => {
        store.detailSearch.startDate = startDateForThisYear();
        store.detailSearch.endDate = endDateForThisYear();
    }

    /* 전년 */
    const prevYearBtn = () => {
        store.detailSearch.startDate = startDateForLastYear(store.detailSearch.startDate);
        store.detailSearch.endDate = endDateForLastYear(store.detailSearch.endDate);
    }

    /* 후년 */
    const nextYearBtn = () => {
        store.detailSearch.startDate = startDateForNextYear(store.detailSearch.startDate);
        store.detailSearch.endDate = endDateForNextYear(store.detailSearch.endDate);
    }

    /* 금월 */
    const thisMonthBtn = () => {
        store.detailSearch.startDate = startDateForThisMonth();
        store.detailSearch.endDate = endDateForThisMonth();
    }

    /* 전월 */
    const prevMonthBtn = () => {
        store.detailSearch.startDate = startDateForLastMonth(store.detailSearch.startDate);
        store.detailSearch.endDate = endDateForLastMonth(store.detailSearch.endDate);
    }

    /* 후월 */
    const nextMonthBtn = () => {
        store.detailSearch.startDate = startDateForNextMonth(store.detailSearch.startDate);
        store.detailSearch.endDate = endDateForNextMonth(store.detailSearch.endDate);
    }

    /* 고객 팝업 callback */
    const searchCustomerInfo =(data)=> {
        store.detailSearch.customerNo = data.customerNo;
        store.detailSearch.customerName = data.customerName;
    }

    /* 계정 과목 팝업 callback */
    const searchAccountingCode = (data) => {
        store.detailSearch.accountCode = data.code;
        store.detailSearch.accountName = data.name;
    }

    const columnDefs = [
        {field: "statementNo", headerName: "전표번호", hide: true},
        {field: "postingDate", headerName: "기표일자", minWidth: 120, valueFormatter: v=> v.data.summary === '월계' ? getMonth(v.value) : v.value},
        {field: "summary", headerName: "적요", minWidth: 160},
        {field: "increment", headerName: "증가", minWidth: 100, valueFormatter: v => v.value?.toLocaleString()},
        {field: "decrement", headerName: "감소", minWidth: 100, valueFormatter: v => v.value?.toLocaleString()},
        {field: "difference", headerName: "잔액", minWidth: 100, valueFormatter: v => v.value?.toLocaleString()},
        {field: "customerName", headerName: "고객명", minWidth: 140},
        {field: "accountName", headerName: "계정과목", minWidth: 100},
    ];

    /* 거래처 원장 상세 조회 */
    const getCustomerLedgerDetail = async() => {
        if(!store.detailSearch.startDate && !!store.search.endDate) return showToast('시작일, 종료일을 설정해주세요.');
        if(!store.detailSearch.accountCode) return showToast('계정코드를 입력해주세요.');
        if(!store.detailSearch.customerNo) return showToast('고객을 입력해주세요.');

        const result = await axios.get("statement/ledger/detail", {params: store.detailSearch});
        store.detailList = result;

        if(result.length === 0) showToast('조회된 데이터가 없습니다.');   
        groupingResult(result);
    }

     /* 월계, 누계 조회 */
     const groupingResult = (result) => {
        if(result.length > 0) {
            const finalResultList = [];

                // result를 월별로 그룹화하여 합계를 계산
                const groupedData = _.groupBy(result, (item) => item.postingDate.substring(0, 7));
                const monthlySummaries = Object.entries(groupedData).map(([month, data]) => ({
                    postingDate: month,
                    increment: _.sumBy(data, 'increment'),
                    decrement: _.sumBy(data, 'decrement'),
                    summary: '[월계]'
                }));

                let currentMonth = '';
                let preMonth = result.slice(0, 1).map((v) => v.postingDate.substring(0, 7));
                let incrementTotalSum = 0; // 누계 합계를 저장하는 변수
                let decrementTotalSum = 0; // 누계 합계를 저장하는 변수

                result.forEach((item) => {
                    const month = item.postingDate.substring(0, 7);
                    if (currentMonth !== '' && month !== currentMonth) {
                        // 월이 변경되면 해당 월의 합계 데이터 추가
                        const sumMonthRow = monthlySummaries.find((monthItem) => monthItem.postingDate.startsWith(preMonth));
                        if (sumMonthRow) {
                            finalResultList.push(sumMonthRow);
                        }

                        // 누계 행 추가
                        incrementTotalSum += sumMonthRow ? sumMonthRow.increment : 0;
                        decrementTotalSum += sumMonthRow ? sumMonthRow.decrement : 0;
                        finalResultList.push({
                            postingDate: `${preMonth}`,
                            increment: incrementTotalSum,
                            decrement: decrementTotalSum,
                            summary: '[누계]'
                        });
                        preMonth = month;
                    }
                    currentMonth = month;
                    finalResultList.push(item);
                });

                const sumMonthRow = monthlySummaries.find((monthItem) => monthItem.postingDate.startsWith(preMonth));
                finalResultList.push(sumMonthRow);

                // 마지막으로 마지막 월의 누계 행을 추가
                incrementTotalSum += sumMonthRow ? sumMonthRow.increment : 0;
                decrementTotalSum += sumMonthRow ? sumMonthRow.decrement : 0;
                finalResultList.push({
                    postingDate: `${preMonth}`,
                    increment: incrementTotalSum,
                    decrement: decrementTotalSum,
                    summary: '[누계]'
                });
                store.detailList = finalResultList;
        }else {
            store.detailList = result;
        }
    }

    /* 행 고정 */
    const pinnedTopRowData = [{
        summary: '[누계]',
        increment: store.detailList.reduce((total, v) => {
            if (v && v.summary !== '[월계]') {
                return total + v.increment;
            }
            return total;
        }, 0),
        decrement: store.detailList.reduce((total, v) => {
            if (v && v.summary !== '[월계]') {
                return total + v.decrement;
            }
            return total;
        }, 0),
    }];

    /* 전표 상세 페이지 연동 */
    const onRowDoubleClicked = (e) => {
        store.statementNo = e.data.statementNo;
        AppStore.toggleModal();
    }

    /* 전표 상세 페이지 닫기 */
    const closedModal = () => {
        store.initStatementNo();
        getCustomerLedgerDetail();
    }

    /* 초기화 */
    const refresh = () => {
        store.initDetail();
        getCustomerLedgerList();
    }

    return (
        <Modal
            title={'거래처원장 상세'}
            show={AppStore.isOpenLedgerDetail}
            onHide={() => AppStore.toggleLedgerDetail()}
            toggleValue={AppStore.isOpenLedgerDetail}
            onExit={refresh}
        >
            <Row>
                <Col lg={4} className="mt-4">
                    <Modal title={'전표상세'} onExit={closedModal}>
                        <GeneralStatementDetail />
                    </Modal>
                    <CContainer search>
                        <Row>
                            <CCol lg={12}>
                                <CIconBtn onClick={prevYearBtn} title={'<'} style={{minWidth: '10px'}} className={'me-0'} />
                                <CIconBtn onClick={thisYearBtn} title={'금년'} className={'ms-1 me-1'} />
                                <CIconBtn onClick={nextYearBtn} title={'>'} style={{minWidth: '10px'}} className={'ms-0 me-2'} />
                                <CIconBtn onClick={prevMonthBtn} title={'<'} style={{minWidth: '10px'}} className={'me-0'} />
                                <CIconBtn onClick={thisMonthBtn} title={'금월'} className={'ms-1 me-1'} />
                                <CIconBtn onClick={nextMonthBtn} title={'>'} style={{minWidth: '10px'}} className={'ms-0'} />
                            </CCol>
                            <CCol lg={12}>
                                <CalendarGroup label={'시작일'}
                                                asSingle={true} 
                                                value={{
                                                    startDate: store.detailSearch.startDate,
                                                    endDate: store.detailSearch.startDate
                                                }}
                                                onChange={v => {store.detailSearch.startDate = v.startDate}}
                                />
                            </CCol>
                            <CCol lg={12}>
                                <CalendarGroup label={'종료일'}
                                                asSingle={true} 
                                                value={{
                                                    startDate: store.detailSearch.endDate,
                                                    endDate: store.detailSearch.endDate
                                                }}
                                                onChange={v => {store.detailSearch.endDate = v.endDate}}
                                />
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={12}>
                                <CInputDoubleGroup label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                                                    placeholder={'고객코드'} placeholder2={'고객명'}
                                                    onCallbackBtn={() => AppStore.toggleCustomerSearchModal()}
                                                    value={store.detailSearch.customerNo} value2={store.detailSearch.customerName}
                                                    onChange={v => {store.detailSearch.customerNo = v.target.value, store.detailSearch.customerName = ''}}
                                                    onChange2={v => store.detailSearch.customerName = v.target.value}
                                                    disabled2={true}
                                                    enterKeyHint1={()=> getCustomerLedgerDetail()}
                                                    enterKeyHint2={()=> getCustomerLedgerDetail()}
                                />
                            </CCol>
                            <CCol lg={12}>
                                    <CInputDoubleGroup label={'계정과목'} labelId={'accountCode'} labelId2={'accountName'}
                                                        placeholder={'계정코드'} placeholder2={'계정과목명'}
                                                        onCallbackBtn={() => AppStore.toggleAccountingCodePopup()}
                                                        value={store.detailSearch.accountCode} value2={store.detailSearch.accountName}
                                                        onChange={v => store.detailSearch.accountCode = v.target.value}
                                                        onChange2={v => store.detailSearch.accountName = v.target.value}
                                                        disabled2={true}
                                                        enterKeyHint1={()=> getCustomerLedgerDetail()}
                                                        enterKeyHint2={()=> getCustomerLedgerDetail()}
                                    />
                            </CCol>
                        </Row>
                        <Row>
                            <Col className='d-flex flex-row-reverse'>
                                <CSearchBtn onClick={getCustomerLedgerDetail} />
                            </Col>
                        </Row>  
                    </CContainer>
                </Col>
                <Col lg={8}>   
                    <CContainer>
                        <AgGridContainer
                            gridRef={gridRef}
                            gridTitle={'거래처원장'}
                            height={70}
                            rowData={store.detailList}
                            columnDefs={columnDefs}
                            useCsvDownload={true}
                            // pinnedTopRowData={pinnedTopRowData}
                            getRowStyle={(params) => {
                                if (params.data.summary === '[월계]' ) {
                                    return { pointerEvents: 'none', background: '#bababa' };
                                }else if(params.data.summary === '[누계]') {
                                    return { pointerEvents: 'none', background: '#bababa' };
                                }
                                return null;
                            }}
                            rowDoubleClickCallback={e => onRowDoubleClicked(e)}
                        />
                    </CContainer>
                    <CustomerSearch callbackFn={searchCustomerInfo} />
                    <AccountingCodeSearch callbackFn={searchAccountingCode} inputCode={null} />
                </Col>
            </Row>
        </Modal>
    );
}

export default observer(CustomerLedgerDetail);
