import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react";
import { CContainer, CCol, CIconBtn, CInputGroup } from "../../../components/CustomContainer";
import { Row, Form, Col } from "react-bootstrap";
import { SalaryManagerStore } from "../../../store/human-resources/SalaryManagerStore";
import AgGridContainer from "../../../components/AgGridContainer";
import Modal from "../../../components/Modal";
import { AppStore } from "../../../store/AppStore";
import { getUserName } from "../../../utils/userUtils";
import { dateFormat } from "../../../utils/dateUtils";
import { findCommKrnm } from "../../../utils/commcode";
import axios from "axios";
import { MULTIPART_HEADER } from "../../../common/constants";
import { showToast } from "../../../common/utils";

const PayStubPopup = () => {
    const gridRef = useRef();
    const [subject, setSubject] = useState('');
    const [contents, setContents] = useState('');

    useEffect(() => {
        setSubject(SalaryManagerStore.search.year+'년 '+SalaryManagerStore.search.month+'월 급여명세서입니다.');
        setContents(SalaryManagerStore.search.year+'년 '+SalaryManagerStore.search.month+'월 한달동안 수고 많으셨습니다.');
    }, [SalaryManagerStore.search.year, SalaryManagerStore.search.month]);


    const sendPayStubEmail = async() => {
        let paramList = [];
        let i = 0;
        let formData = new FormData();
        let formulaHtml = '';

        // 계산식 불러와서 붙이기
        const formulaList = await axios.get('/salary/calculationFormula');
        if (formulaList.length > 0) {
            for (const formula of formulaList) {
                formulaHtml += `<tr>
                                    <td style="width: 35%;" class="text-left">` + formula.allowanceDeduction + `</td>
                                    <td style="width: 65%;" class="text-left" >` + formula.calculationFormula + `</td>
                                </tr>`;
            }
        }

        for (const user of SalaryManagerStore.selectUserList) {

            // let workHour = 0;
            // let workMin = 0;

            // user.normalHour = user.normalHour ? user.normalHour : "0:00";
            // user.nightHour = user.nightHour ? user.nightHour : "0:00";
            // user.holidayHour = user.holidayHour ? user.holidayHour : "0:00";
            // user.extensionHour = user.extensionHour ? user.extensionHour : "0:00";

            // let normalHourArr = user.normalHour.split(":");
            // let nightHourArr = user.nightHour.split(":");
            // let holidayHourArr = user.holidayHour.split(":");
            // let extensionHourArr = user.extensionHour.split(":");

            // workHour = Number(normalHourArr[0]) + Number(nightHourArr[0]) + Number(holidayHourArr[0]) + Number(extensionHourArr[0]);
            // workMin = Number(normalHourArr[1]) + Number(nightHourArr[1]) + Number(holidayHourArr[1]) + Number(extensionHourArr[1]);

            // if (workMin > 59) {
            //     const hour = Math.floor(workMin / 60);
            //     const min = workMin % 60;

            //     workHour = workHour + hour;
            //     workMin = min;
            // }

            const paystubContent = `<!DOCTYPE html>
                                    <html lang="en">
                                        <head>
                                            <meta charset="UTF-8">
                                            <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                            <title>Document</title>
                                            <style>
                                                table {
                                                    border: 1px solid black;
                                                    border-collapse: collapse;
                                                    width:80%; 
                                                    margin: 0 auto;
                                                    margin-bottom: 20px;
                                                    /* margin-top: 10px; */
                                                }
                                                th, td{
                                                    border: 1px solid #000;
                                                    height: 30px;
                                                    /* margin-left: 35px; */
                                                }
                                                .border {
                                                    border: 1px solid black;
                                                }
                                                
                                                .text-center {
                                                    text-align: center;
                                                }
                                                .text-left {
                                                    text-align: left;
                                                    padding-left: 5px;
                                                }
                                                .text-right {
                                                    text-align: right;
                                                    padding-right: 5px;
                                                }
                                                .no-border-bottom {
                                                    border-bottom-style: hidden !important;
                                                 }
                                                 .no-border-right {
                                                    border-right-style: hidden !important;
                                                 }
                                            </style>
                                            <script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
                                        </head>
                                        <body>
                                            <header>
                                                <h1 style="text-align: center;">` + SalaryManagerStore.search.year + `년 ` + SalaryManagerStore.search.month + `월` + ` 급여명세서</h1>
                                            </header>
                                            <content >
                                                <table>
                                                    <tr style="border: 1px black; border-style: solid solid none solid;">
                                                        <td style="width: 35%;" class="text-left no-border-bottom no-border-right">사원번호 : ` + (user.userSeq || '') + `</td>
                                                        <td style="width: 35%;" class="text-left no-border-bottom no-border-right">사&nbsp;&nbsp;원&nbsp;&nbsp;명 : ` + (getUserName(user.userSeq) || '') + `</td>
                                                        <td style="width: 30%;" class="text-left no-border-bottom">입&nbsp;&nbsp;사&nbsp;&nbsp;일 : ` + (dateFormat(user.joinDate) || '') + `</td>
                                                    </tr>
                                                    <tr style="border: 1px black; border-style: none solid solid solid;">
                                                        <td class="text-left no-border-right">부&nbsp;&nbsp;서&nbsp;&nbsp;명 : ` + (findCommKrnm(String(user.partSeq), '32') || '') + `</td>
                                                        <td class="text-left no-border-right">직&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;책 :  `+ (findCommKrnm(String(user.positionSeq), '31') || '') + `</td>
                                                        <td class="text-left">지&nbsp;&nbsp;급&nbsp;&nbsp;일 : ` + (SalaryManagerStore.search.paymentDate || '') + `</td>
                                                    </tr>
                                                </table>
                                                <table>
                                                    <tr>
                                                        <td colspan="8" class="text-center" style="border: 1px black; border-style: none solid solid solid; background-color: lightgray;">세부내역</td>
                                                    </tr>
                                                    <tr style="border: 1px solid black;">
                                                        <td style="border: 1px solid black;" colspan="4" class="text-center">지급</td>
                                                        <td style="border: 1px solid black;" colspan="4" class="text-center">공제</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" style="width: 25%;" class="text-left no-border-bottom">기본급</td>
                                                        <td colspan="2" style="width: 25%;" class="text-right no-border-bottom">` + (user.basicSalary || '')?.toLocaleString() + `</td>
                                                        <td colspan="2" style="width: 25%;" class="text-left no-border-bottom">국민연금</td>
                                                        <td colspan="2" style="width: 25%;" class="text-right no-border-bottom">` + (user.nationalPension || '')?.toLocaleString() + `</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left no-border-bottom">상여금</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.bonus || '')?.toLocaleString() + `</td>
                                                        <td colspan="2" class="text-left no-border-bottom">건강보험</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.healthInsurance || '')?.toLocaleString() + `</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left no-border-bottom">식대</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.mealAllowance || '')?.toLocaleString() + `</td>
                                                        <td colspan="2" class="text-left no-border-bottom">요양보험</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.careInsurance || '')?.toLocaleString() + `</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left no-border-bottom">연장근로수당</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.overtimeAllowance || '')?.toLocaleString() + `</td>
                                                        <td colspan="2" class="text-left no-border-bottom">고용보험</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.employmentInsurance || '')?.toLocaleString() + `</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left no-border-bottom">자가운전보조금</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.selfDrivingSubsidy || '')?.toLocaleString() + `</td>
                                                        <td colspan="2" class="text-left no-border-bottom">소득세</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.incomeTax || '')?.toLocaleString() + `</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left no-border-bottom">통신비</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.cellPhoneAllowance || '')?.toLocaleString() + `</td>
                                                        <td colspan="2" class="text-left no-border-bottom">지방소득세</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.inhabitantsTax || '')?.toLocaleString() + `</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left no-border-bottom">기타수당</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.otherAllowance || '')?.toLocaleString() + `</td>
                                                        <td colspan="2" class="text-left no-border-bottom">건강보험정산</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.healthInsuranceSettlement || '')?.toLocaleString() + `</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left no-border-bottom">연차수당</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.annualAllowance || '')?.toLocaleString() + `</td>
                                                        <td colspan="2" class="text-left no-border-bottom">장기요양보험정산</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.careInsuranceSettlement || '')?.toLocaleString() + `</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left no-border-bottom">팀장수당</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.teamLeaderAllowance || '')?.toLocaleString() + `</td>
                                                        <td colspan="2" class="text-left no-border-bottom">국민연금정산</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.nationalPensionSettlement || '')?.toLocaleString() + `</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left no-border-bottom">근속수당</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.seniorityAllowance || '')?.toLocaleString() + `</td>
                                                        <td colspan="2" class="text-left no-border-bottom">연말정산소득세</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.yearEndIncomeTax || '')?.toLocaleString() + `</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left no-border-bottom">교통비외수당</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.extraAllowance || '')?.toLocaleString() + `</td>
                                                        <td colspan="2" class="text-left no-border-bottom">연말정산주민세</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.yearEndResidentTax || '')?.toLocaleString() + `</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left no-border-bottom"></td>
                                                        <td colspan="2" class="text-right no-border-bottom"></td>
                                                        <td colspan="2" class="text-left no-border-bottom">기타정산분</td>
                                                        <td colspan="2" class="text-right no-border-bottom">` + (user.otherSettlements || '')?.toLocaleString() + `</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left no-border-bottom"></td>
                                                        <td colspan="2" class="text-right no-border-bottom"></td>
                                                        <td colspan="2" class="text-left no-border-bottom"></td>
                                                        <td colspan="2" class="text-right no-border-bottom"></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left no-border-bottom"></td>
                                                        <td colspan="2" class="text-right no-border-bottom"></td>
                                                        <td colspan="2" class="text-left no-border-bottom"></td>
                                                        <td colspan="2" class="text-right no-border-bottom"></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left"></td>
                                                        <td colspan="2" class="text-right"></td>
                                                        <td colspan="2" class="text-left"></td>
                                                        <td colspan="2" class="text-right"></td>
                                                    </tr>
                                                    <tr style="background-color: lightgray;">
                                                    <td colspan="2" class="text-center">지급액계</td>
                                                        <td colspan="2" class="text-right">` + (user.grossSalary || '')?.toLocaleString() + `</td>
                                                        <td colspan="2" class="text-center">공제액계</td>
                                                        <td colspan="2" class="text-right">` + (user.totalDeduction || '')?.toLocaleString() + `</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" class="text-left" style="border-color: white"></td>
                                                        <td colspan="2" class="text-right" style="border-color: white black white white"></td>
                                                        <td colspan="2" class="text-center" style="background-color: lightgray;">실수령액</td>
                                                        <td colspan="2" class="text-right" style="background-color: lightgray;">` + (user.netPay || '')?.toLocaleString() + `</td>
                                                    </tr>
                                                </table>
                                        
                                                <table>
                                                    <tr style="background-color: lightgray;">
                                                        <td style="width: 20%" class="text-center">근로일수</td>
                                                        <td style="width: 20%" class="text-center">총 근로시간수</td>
                                                        <td style="width: 20%" class="text-center">야간근로시간수</td>
                                                        <td style="width: 20%" class="text-center">휴일근로시간수</td>
                                                        <td style="width: 20%" class="text-center">연장근로시간수</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-center">` + (user.normalDay || 0) + `</td>
                                                        <td class="text-center">` + 
                                                            // workHour + ":" + workMin  
                                                            (user.normalHour || 0)
                                                        + `</td>
                                                        <td class="text-center">` + (user.nightHour || 0) + `</td>
                                                        <td class="text-center">` + (user.holidayHour || 0) + `</td>
                                                        <td class="text-center">` + (user.extensionHour || 0) + `</td>
                                                    </tr>
                                                </table>
                                        
                                                <table>
                                                    <tr style="background-color: lightgray;">
                                                        <td colspan="5" class="text-center">계산방법</td>
                                                    </tr>
                                                    <tr style="background-color: lightgray;">
                                                        <td style="width: 30%;" class="text-center">지급/공제항목</td>
                                                        <td style="width: 70%;" colspan="4" class="text-center">산출식 또는 산출방법</td>
                                                    </tr>` + formulaHtml +
                                                `</table>
                                            </content>
                                        </body>
                                    </html>`;

          const blob = new Blob([paystubContent], {type: 'text/html'});
          const file = new File([blob], getUserName(user.userSeq)+"_"+SalaryManagerStore.search.year+"년"+SalaryManagerStore.search.month+"월"+"_급여명세서.html");

          paramList.push({userSeq: user.userSeq, userName: getUserName(user.userSeq), receiverEmail: user.email, file: file});
          formData.append("userList["+i+"].userSeq", user.userSeq);
          formData.append("userList["+i+"].userName", getUserName(user.userSeq));
          formData.append("userList["+i+"].receiverEmail", user.email);
          formData.append("userList["+i+"].file", file);

          i += 1;
        }
        formData.append("subject", subject);
        formData.append("contents", contents);

        await axios.post('/salary/sendPaystub', formData, { headers: MULTIPART_HEADER });
        showToast('전송이 완료되었습니다.');
        AppStore.toggleSendPayStubPopup();
    }

    return (
        <>
        <Modal title={'명세서 메일 전송'}
            onHide={() => AppStore.toggleSendPayStubPopup()}
            toggleValue={AppStore.isOpenSendPayStubPopup}
            className={'search-modal-h6'}
        >
            <CContainer>
                <Row>
                    <AgGridContainer
                        gridRef={gridRef}
                        gridTitle={'회원목록'}
                        height={23}
                        rowData={SalaryManagerStore.selectUserList}
                        columnDefs={
                            [
                                {field: "userSeq", headerName: '사원번호', width: 120},
                                {field: "userName", headerName: "사원명", valueFormatter: v => {return getUserName(v.data.userSeq)}, width: 120},
                                {field: "email", headerName: '이메일', flex: 1},
                            ]
                        }
                        />
                </Row>
                {/* <hr /> */}
                <Row className="mt-3">
                <CCol lg={12}>
                    <CInputGroup label={'제목'}
                                labelId={'subject'}
                                value={subject}
                                onChange={v => setSubject(v.target.value)}
                    />
                </CCol>
                  <CCol lg={12}>
                    <textarea className="form-control" rows="6"
                        value={contents}
                        placeholder={'내용을 자유롭게 작성해주세요.'}
                        readOnly={false}
                        onChange={v => setContents(v.target.value)}
                    />
                  </CCol>
                </Row>
                <Row>
                    <Col className="d-flex flex-row-reverse">
                    <CIconBtn style={{width: 100, height: 30}}
                                title={'메일 전송'}
                                icon={'fi-rr-file-invoice-dollar'}
                                onClick={sendPayStubEmail}
                                variant={'success'}
                        />
                    </Col>
                </Row>
            </CContainer>
        </Modal>
        </>
    );
}

export default observer(PayStubPopup);