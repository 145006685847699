import { useRef, useState } from "react";
import { observer } from "mobx-react-lite"
import { CContainer, CCol, CInputGroup } from "../../../components/CustomContainer";
import { Row } from "react-bootstrap";
import AgGridContainer from "../../../components/AgGridContainer";
import { SalaryManagerStore } from "../../../store/human-resources/SalaryManagerStore";
import axios from "axios";
import { findCommKrnm } from "../../../utils/commcode";
import { AppStore } from "../../../store/AppStore";

const SalarySelectPopup = ({}) => {
    const salaryListRef = useRef();
    const salaryDetailRef = useRef();

    const onRowClickedEvent = async (e) => {
        const result = await axios.get('/salary/history/detail', {params : {companyCode: e.data.companyCode
                                                                , salaryType: e.data.salaryType     
                                                                ,  imputedDate: e.data.imputedDate}});
        SalaryManagerStore.salaryHistoryDetailList = result;
    }


    const callbackFn = async (e) => {
        const result = await axios.get('/salary', {params: e.data});
        const resultList = result.map(v => { return {...v, imputedDate: SalaryManagerStore.search.year + "-" + SalaryManagerStore.search.month, isUpdated: true}});
        SalaryManagerStore.userSalaryList = resultList;

        AppStore.toggleModal();
    }

    return (
        <>
        <CContainer>
            <Row>
                <CCol lg={3}>
                    <CInputGroup label={'귀속연도'}
                                labelId={'imputedYear'}
                                value={SalaryManagerStore.search.paymentDate.substring(0,4)}
                                readOnly={true}/>
                </CCol>
                <CCol lg={1}>
                </CCol>
                <CCol lg={8}>
                    <div style={{fontSize: '1.2em'}}> • 급여구분이 같은 이전 급여자료만 복사가 가능합니다. <br /> • 복사하실 자료를 <span style={{fontSize: '1em', color: 'red'}}>더블 클릭</span>하시면 급여 내역이 복사됩니다. </div>
                </CCol>
            </Row>
            <Row>
                <CCol lg={4}>
                    <AgGridContainer
                        gridRef={salaryListRef}
                        height={58}
                        rowData={SalaryManagerStore.salaryHistoryList}
                        columnDefs={
                            [
                                {field: "companyCode", headerName: '회사', hide: true},
                                {field: "salaryType", headerName: '급여구분', hide: true},
                                {field: "imputedDate", headerName: '귀속월'},
                                {field: "totalPeopleNumber", headerName: '순인원'},
                                {field: "totalPayment", headerName: '총지급액', flex:1, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                            ]
                        }
                        onRowClicked={e => onRowClickedEvent(e)}/>
                </CCol>
                <CCol lg={8}>
                    <AgGridContainer 
                    gridRef={salaryDetailRef}
                    height={58}
                    rowData={SalaryManagerStore.salaryHistoryDetailList}
                    columnDefs={
                        [
                            {field: "seq", headerName: 'No', valueGetter: v => v.node.rowIndex + 1, width: 55},
                            {field: "salaryType", headerName: '급여구분', valueGetter: v => findCommKrnm(v.data.salaryType, '48'), width: 110},
                            {field: "companyCode", headerName: '회사구분', hide: true},
                            {field: "paymentDate", headerName: '지급일자', width: 120},
                            {field: "totalPeopleNumber", headerName: '인원', width: 80},
                            {field: "totalPayment", headerName: '총지급액', width: 140, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                            {field: "netPayment", headerName: '순지급액', width: 130, flex: 1, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                        ]
                    }
                    rowDoubleClickCallback={callbackFn}/>
                </CCol>
            </Row>
        </CContainer>
        </>
    );
}

export default observer(SalarySelectPopup);