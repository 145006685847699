import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {AppStore} from "../store/AppStore";
import {observer} from "mobx-react-lite";
import {Form} from "react-bootstrap";
import {CCol} from "./CustomContainer";
import axios from "axios";

const AgreeModal =({callbackFn, gridTitle})=> {
    const [selType, setSelType] = useState(1);

    const agreeHandler =async()=> {
        const params = {
            agreeType: 1, //엑셀다운로드
            agreeUsage: selType,
            agreeRemark: '',
            fileName: gridTitle
        }
        //동의 시점 저장
        await axios.post('/filedownload/agree', params);
        callbackFn();
    }

    return (
        <>
            <Modal show={AppStore.isOpenAgreePopup}
                   onHide={()=> AppStore.toggleAgreePopup()}>
                <Modal.Header closeButton>
                    <Modal.Title>동의</Modal.Title>
                </Modal.Header>
                <Modal.Body>사내 데이터는 대외비 자료로서 외부 유출시 법적 책임은 본인에게 있음을 동의합니다.</Modal.Body>
                <Modal.Body>
                    <CCol lg={12}>
                        <Form.Check
                            inline
                            label="영업활용"
                            name="selSearchItem"
                            type={'radio'}
                            value={1}
                            id={'selType1'}
                            checked={selType === 1}
                            onChange={()=> setSelType(1)}
                        />
                        <Form.Check
                            inline
                            label="보고자료"
                            name="selSearchItem"
                            type={'radio'}
                            id={'selType2'}
                            value={2}
                            checked={selType === 2}
                            onChange={()=> setSelType(2)}
                        />
                        <Form.Check
                            inline
                            label="고객요청"
                            name="selSearchItem"
                            type={'radio'}
                            value={3}
                            id={'selType3'}
                            checked={selType === 3}
                            onChange={()=> setSelType(3)}
                        />
                        <Form.Check
                            inline
                            label="기타"
                            name="selSearchItem"
                            type={'radio'}
                            value={4}
                            id={'selType4'}
                            checked={selType === 4}
                            onChange={()=> setSelType(4)}
                        />
                    </CCol>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=> AppStore.toggleAgreePopup()}>
                        취소
                    </Button>
                    <Button variant="primary" onClick={agreeHandler}>
                        동의 후 다운로드
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default observer(AgreeModal);
