import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {Form, Image, InputGroup, Row} from "react-bootstrap";
import axios from 'axios';
import { read, utils } from 'xlsx';

import {CCol, CContainer} from "../../components/CustomContainer";
import { getCommcode, findCommKrnm} from "../../utils/commcode";
import AgGridContainer from "../../components/AgGridContainer";
import { AccountStore } from "../../store/billing/AccountStore";
import { AppStore } from "../../store/AppStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import { IconBtn } from '../../components/CustomContainer';
import LUtils from '../../utils/lodashUtils';
import {callConfirm} from '../../utils';
import {showToast} from "../../common/utils";
import AccountListSearch from "./AccountListSearch";

const AccountList =()=> {
    const gridRef = useRef();

    const [totalInputAmount, setTotalInputAmount] = useState(0);
    const [totalReceiveAmount, setTotalReceiveAmount] = useState(0);
    const [possibleAmount, setPossibleAmount] = useState(0);
    const [customerKind, setCustomerKind] = useState('');
    const [gridData, setGridData] = useState([]);
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        getAccountNumberList();
    }, []);

    const getAccountNumberList = async () => {
        const result = await axios.get('account/number/list');
        AccountStore.accountNumberList = result;
    }

    const getAccountList = async ()=> {
        const result = await axios.get("account/list", {params: AccountStore.search});
        AccountStore.accountList= result;
        getAccountGroupList();
        if(result.length === 0) showToast('조회된 데이터가 없습니다.');
        AccountStore.isMiscProfitBtnText = AccountStore.search.isMiscProfit === 1 ? '취소' : '처리';
    }

    /* 통장별 정보 list */
    const getAccountGroupList = async () => {
        const result = await axios.get("account/list/group", {params: AccountStore.search});
        AccountStore.accountGroupList = result;
    }

    const searchCustomerInfo =(data)=> {
        if(data && customerKind === 'grid') {
            const result = AccountStore.accountList.map(v => {
                if((v.addRowId !== undefined && (v.addRowId === gridData.data.addRowId)) || (v.addRowId === undefined && (v.accountSeq === gridData.data.accountSeq))){
                    v.customerNo = data.customerNo;
                    v.customerName = data.customerName;
                    LUtils.assign(v, {isUpdated: true });
                }
                return v;
            });
            AccountStore.setAccountList(result);
        }else if(data && customerKind === 'search'){
            AccountStore.search.customerNo = data.customerNo;
            AccountStore.search.customerName = data.customerName;
        }
    }

    // 총 입금 금액, 총 수납 금액, 수납 가능 금액
    const onSelectionChanged = ()=> {
        const filteredAccount = AccountStore.accountList.filter(v => (v.isSelected || v.isUpdated) && !v.isCreated);
        const sumOfInputAmount = filteredAccount.reduce((total, account) => total + account.inputAmount, 0);
        const sumOfReceiveAmount = filteredAccount.reduce((total, receive) => total + receive.receiveAmount, 0);

        setTotalInputAmount(sumOfInputAmount.toLocaleString());
        setTotalReceiveAmount(sumOfReceiveAmount.toLocaleString());
        setPossibleAmount((sumOfInputAmount - sumOfReceiveAmount).toLocaleString());
    }

    /* 고객 팝업 UI */
    const popupCustomerInfo = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.customerName}</div>
                <IconBtn style={{paddingTop: 6, color:'darkblue', textAlign: 'right'}} key={e.rowIndex} onClick={() => {setGridData(e), setCustomerKind('grid'), AppStore.toggleCustomerSearchModal()}} />
            </div>
        )
    }

    /* 엑셀 업로더 */
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        let addRowList = [];

        if (file) {
            reader.onload = (e) => {
                const addIndex = AccountStore.accountList.filter(v => v.isCreated).length || 0;
                const data = new Uint8Array(e.target.result);
                const workbook = read(data, { type: 'array' });

                // 첫 번째 시트를 가져옴
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];

                // 셀 데이터를 파싱하여 출력
                const jsonData = utils.sheet_to_json(worksheet, { header: 1, raw: false });     // raw: false는 날짜 타입 그대로 가져오기 위함
                addRowList = jsonData.slice(1).map((row, index) => ({                           // slice(1) 헤더 제외하고 가져오기
                    accountDate: row[0],
                    bankCode: row[1],
                    accountNumber: row[2],
                    inputAmount: row[3],
                    summary: row[4],
                    customerNo: row[5],
                    isCreated: true,
                    addRowId: addIndex + index
                }));

                addRowList.forEach((addRowData) => {
                    AccountStore.setAccountList([...AccountStore.accountList, addRowData]);
                });
            };
            reader.readAsArrayBuffer(file);
            setFileName(file.name);
        }
    };

    /* 엑셀 다운로드 */
    const handleDownload = async () => {
        fetch('https://eliterentalbucket.s3.ap-northeast-2.amazonaws.com/erp/sample.xlsx', {
            method: 'GET',
        })
        .then((response) => response.blob())
        .then((blob) => {
            // 블롭(Blob) 객체 생성하고 URL을 생성
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');

            // 생성한 URL과 다운로드할 파일명 설정
            link.setAttribute('href', url);
            link.setAttribute('download', 'sample.xlsx');

            // 링크를 문서(body)에 추가
            document.body.appendChild(link);

            // 링크 클릭 => 파일 다운로드
            link.click();

            // 다운로드 후 링크와 URL을 정리
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url)
        });
    };

    /* 저장버튼 클릭 이벤트 */
    const callBackGridData = async (gridData) => {
        // 저장 확인
        if (!await callConfirm('저장 하시겠습니까?')) return;

        // 등록
        if(gridData.createdList.length > 0) {
            await axios.post('account', {accountList: gridData.createdList});
        }

        // 수정
        if(gridData.updatedList.length > 0) {
            const updatedList = gridData.updatedList.filter(v => v.accountSeq);
            await axios.put('account', {accountList: updatedList});
        }

        showToast('저장되었습니다.');
        await getAccountList();
    }

    const uploadBtn = () => {
        return (
            <div className="file-upload">
                <label htmlFor="file-input" className="custom-file-upload">
                    거래 내역 업로드
                </label>
                <span className="file-name">{fileName}</span>
                <input
                    id="file-input"
                    type="file"
                    onChange={handleFileUpload}
                    style={{ display: 'none' }}
                />
            </div>
          );
    }

    const setDeposit =async(gridData)=> {
        // const selectedList = gridData.selectedList;
        const selectedList = AccountStore.accountList.filter(v=>v.isSelected);
        if(!selectedList){
            showToast('선택된 입금내역이 없습니다.');
            return;
        }

        if(selectedList.findIndex(v => v.inputAmount === v.receiveAmount) > -1){
            showToast('입금액이 수납액보다 커야 합니다.');
            return;
        }

        if (!await callConfirm('설정 하시겠습니까?')) return;
        const accountList = selectedList.map(v => {
            return v.accountSeq;
        })
        await axios.put('account/deposit', {accountList: accountList});
        showToast('설정되었습니다.');
        //재조회
        await getAccountList();
    }
    /* 잡이익 처리 */
    const setMiscProfit = async() => {
        const selectedList = AccountStore.accountList.filter(v=>v.isSelected);
        if(!selectedList){
            showToast('선택된 입금내역이 없습니다.');
            return;
        }

        if (!await callConfirm(`잡이익 ${AccountStore.isMiscProfitBtnText}하시겠습니까?`)) return;

        const accountList = selectedList.map(v => {
            return v.accountSeq;
        })
        
        await axios.put('account/miscProfit', {accountList: accountList, isMiscProfit: AccountStore.isMiscProfitBtnText === '취소' ? 0 : 1});
        showToast(`잡이익 ${AccountStore.isMiscProfitBtnText}되었습니다.`);

        //재조회
        await getAccountList();
    }


    const setDepositBtn = {
        isUsed: true,
        title: '적립금 설정',
        icon: 'fi-ss-coins',
        callbackFn: e => setDeposit(e),
        width: 120
    }

    const miscProfitBtn = {
        isUsed: true,
        title: '잡이익 ' + AccountStore.isMiscProfitBtnText,
        icon: 'fi-sr-sack-dollar',
        callbackFn: e => setMiscProfit(),
        variant: AccountStore.isMiscProfitBtnText === '취소' ? 'danger' : '',
        width: 120,
    }
    
    return(
        <>
            {/* 검색조건 */}
            <AccountListSearch getAccountList={getAccountList} setCustomerKind={setCustomerKind}/>
            <CContainer>
                <div className="row">
                    {AccountStore.accountGroupList.length > 0 && AccountStore.accountGroupList.map((v, index) => (
                        <CCol key={index} lg={2}>
                            <div className="dash-status-box" style={{boxShadow: '0 0 0.875rem 0 rgb(41 48 66 / 10%)', height: '100%'}}>
                                <div className="card-body py-4">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h6 style={{color: '#086A87'}}>{v.bankName+'('+v.accountNumber.slice(-4)+')'}</h6>
                                            <p className="mb-2" style={{fontSize: 12, color: '#9d9c9c'}}>{v.accountNumber}</p>
                                            <h5 style={{color: '#086A87', textAlign: 'right'}}>{v.balance.toLocaleString()} 원</h5>
                                            <hr />
                                            <p className="mb-2" style={{ color: '#495057', display: 'flex' }}>
                                                <span style={{ flex: '0.5', textAlign: 'left' }}>입금</span>
                                                <span style={{ flex: '0.5', textAlign: 'center' }}>{v.inputCount} 건</span>
                                                <span style={{ flex: '1', textAlign: 'right' }}>{v.inputAmount.toLocaleString()} 원</span>
                                            </p>
                                            <p className="mb-2" style={{ color: '#495057', display: 'flex' }}>
                                                <span style={{ flex: '0.5', textAlign: 'left' }}>출금</span>
                                                <span style={{ flex: '0.5', textAlign: 'center' }}>{v.outputCount} 건</span>
                                                <span style={{ flex: '1', textAlign: 'right' }}>{v.outputAmount.toLocaleString()} 원</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    ))}
                </div>
                <Row>
                    <Row style={{paddingLeft: 12, paddingTop: 12, paddingRight: 12}}>
                        <CCol lg={2}>
                            <InputGroup size={'sm'}>
                                <InputGroup.Text id={'totalInputAmount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>총 입금 금액</InputGroup.Text>
                                <Form.Control
                                    style={{flex: 1, textAlign: 'right'}}
                                    max={2}
                                    aria-describedby={'totalInputAmount'}
                                    value={totalInputAmount}
                                    readOnly={true}
                                />
                                <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                            </InputGroup>
                        </CCol>
                        <CCol lg={2}>
                            <InputGroup size={'sm'}>
                                <InputGroup.Text id={'totalReceiveAmount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>총 수납 금액</InputGroup.Text>
                                <Form.Control
                                    style={{flex: 1, textAlign: 'right'}}
                                    max={2}
                                    aria-describedby={'totalReceiveAmount'}
                                    value={totalReceiveAmount}
                                    readOnly={true}
                                />
                                <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                            </InputGroup>
                        </CCol>
                        <CCol lg={2}>
                            <InputGroup size={'sm'}>
                                <InputGroup.Text id={'possibleAmount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>수납 가능 금액</InputGroup.Text>
                                <Form.Control
                                    style={{flex: 1, textAlign: 'right'}}
                                    max={2}
                                    aria-describedby={'possibleAmount'}
                                    value={possibleAmount}
                                    readOnly={true}
                                />
                                <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                            </InputGroup>
                        </CCol>
                        {/* <CCol lg={1}  style={{textAlign: 'right'}}>
                            <Button size={'sm'} onClick={handleDownload}>샘플받기</Button>
                        </CCol>
                        <CCol lg={3}>
                            {uploadBtn()}
                        </CCol> */}
                    </Row>
                    <CustomerSearch callbackFn={searchCustomerInfo}/>

                    <AgGridContainer
                        gridRef={gridRef}
                        gridTitle={'거래 내역'}
                        height={55}
                        rowData={AccountStore.accountList}
                        columnDefs={[
                            {field: "companyName", headerName: "회사", width: 140},
                            {field: "accountSeq", headerName: "거래번호", width: 110},
                            {field: "accountDate", headerName: "거래일자", width: 120},
                            {field: "accountTime", headerName: "거래시간", width: 120, valueFormatter: v => `${v.value.substring(0, 2)}:${v.value.substring(2, 4)}:${v.value.substring(4, 6)}`},
                            {
                                field: "bankCode",
                                headerName: "은행",
                                valueGetter: v => findCommKrnm(v.data.bankCode, '24'),
                                cellEditorParams: {
                                    values: getCommcode('24').map(v => v.value),
                                },
                                minWidth: 120
                            },
                            {field: "accountNumber", headerName: "계좌번호", minWidth: 160},
                            {field: "inputAmount", headerName: "입금금액", minWidth: 130, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
                            {field: "outputAmount", headerName: "출금금액", minWidth: 130, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
                            {field: "receiveAmount", headerName: "수납금액", minWidth: 130, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
                            {field: "summary", headerName: "적요", minWidth: 200, width: 200},
                            {field: "customerName", headerName: "고객명", minWidth: 160, headerClass: 'grid-column-editable', cellRenderer: e => popupCustomerInfo(e)},
                            {field: "customerNo", headerName: "고객코드", headerClass: 'grid-column-editable', hide: true, minWidth: 120},
                            {field: "isDeposit", headerName: "적립금", width: 100, valueFormatter: v => v.value === 1 ? 'Y' : ''},
                            {field: "remark", headerName: "비고", headerClass: 'grid-column-editable', editable: true, minWidth: 200, flex: 1},
                        ]}
                        isCheckBox={true}
                        useUpdated={true}
                        callBackGridData={callBackGridData}
                        useCsvDownload={true}
                        onSelectionChanged={onSelectionChanged}
                        customBtnInfo={[setDepositBtn, miscProfitBtn]}
                        getRowStyle={(params) => {
                            if (params.data.referenceKey) {
                                return { pointerEvents: 'none' };
                            }
                            return null;
                        }}
                    />
                </Row>
            </CContainer>
        </>
    );
}

export default observer(AccountList);
