import { observable } from "mobx";
import {addDay, addDayDate, getHour, oneMonthAgo, today} from "../../utils/dateUtils";

export const AssetReservationStore = observable({
    reservationList: [],
    search : {
        startDate: oneMonthAgo(),
        endDate: addDayDate(today(), 30),
        userSeq: '',
        reservationStatus: '1',    //기본 예약중
        userName: '',
        customerNo: '',
        customerName: '',
        productSeq: '',
        productName: '',
        model: '',
        spec: '',
        assetNo: '',
    },
    data : {
        startDate: new Date(today()),
        endDate: '',
        startHour: getHour(),
        startMinute: '00',
        endHour: getHour(),
        endMinute: '00',
        customerNo: '',
        customerName: '',
        billingAddressSeq: '',
        billingAddressName: '',
        managerName: '',
        telNo: '',
        remark: '',
        assetList: [],
    },

    setReservationList(v){
        this.reservationList = v;
    },

    init(){
        this.data.startDate = new Date();
        this.data.endDate = '';
        this.data.startHour = getHour();
        this.data.startMinute = '00';
        this.data.endHour = getHour();
        this.data.endMinute = '00';
        this.data.customerNo = '';
        this.data.customerName = '';
        this.data.billingAddressSeq = '';
        this.data.billingAddressName = '';
        this.data.managerName = '';
        this.data.telNo = '';
        this.data.remark = '';
        this.data.assetList = [];
    }
});
