import { observer } from "mobx-react-lite";
import SignatureCanvas from 'react-signature-canvas';
import { Row, Col } from "react-bootstrap";

import { CContainer, CSaveBtn, CCancelBtn } from '../../../components/CustomContainer';
import { AppStore } from "../../../store/AppStore";

const ContractSignaturePopup = ({ signatureRef }) => {   
    const clearSignature = () => {
        signatureRef.current.clear();
    }

    return (
        <>
            <CContainer>
                <div className="popup">
                    <p style={{color: 'blue'}}>* 이름을 정자로 입력해주세요.</p>
                    <Row style={{ border: '1px solid black', backgroundColor: '#fff' }}>
                    <div className="signatureContainer">
                        <div className="canvasContainer">
                        <SignatureCanvas
                            ref={signatureRef}
                            canvasProps={{className: 'sigCanvas canvasStyle'}}
                            clearOnResize={false}
                            throttle={10}
                            minWidth={6}
                        />
                        </div>
                    </div>
                    </Row>
                    <Row>
                    <Col className='d-flex flex-row-reverse mt-4'>
                        <CSaveBtn onClick={() => AppStore.toggleConfirmMailPopup()} />
                        <CCancelBtn onClick={clearSignature} title={'초기화'}/>
                    </Col>
                    </Row>
                </div>
            </CContainer>
        </>
    );
};

export default observer(ContractSignaturePopup);
