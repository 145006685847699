import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const contractColumns = {
    contractNo: '',         // 계약번호
    contractStatus: 0,     // 계약상태 (0:작성중, 1:완료(작성,출고의뢰))
    contractSeq: 1,         // 계약회차
    contractType: '1',       // 계약구분 (반납형, 양도형, 유통, 매각)
    customerNo: '',         // 고객번호
    customerName: '',       // 고객명
    customerManagerSeq: '', // 고객담당자 시퀀스
    managerName: '',        // 고객담당자명
    siteSeq: '',            // 현장번호
    siteName: '',           // 현장명
    saleUserSeq: '',        // 영업담당자 seq
    saleUserName: '',       // 영업담당자명
    telephone: '',          // 영업담당자 전화번호
    phoneNumber: '',        // 영업담당자 휴대폰번호
    supportUserSeq: '',     // 지원담당자 seq
    supportUserName: '',    // 지원담당자명
    rentMonth: 0,          // 계약기간(월)
    rentDays: 0,           // 계약기간(일)
    contractDate: '',       // 계약일자
    contractStartDate: '',  // 계약시작일
    contractEndDate: '',    // 계약종료일
    contractDocType: '1',    // 계약서 구분 (수기, 전자)
    contractStartType: '1',  // 계약발생경로
    installPlace: '',       // 설치장소
    isPenaltyFee: 0,        // 위약금청구여부
    penaltyPercent: 0,      // 위약금율
    isExtendRate: 0,        // 연장할인여부
    extendFirstRate: 0,     // 1차연장할인율
    extendSecondRate: 0,    // 2차연장할인율
    isDeliveryPrice: 0,     // 운반비청구여부
    deliveryPrice: 0,       // 운반비
    chargeDelivery: '',     // 운반비 부담
    deliveryKind: '',       // 운반구분
    isRepairCost: 0,        // 수리비청구여부
    isInstallPrice: 0,      // 설치비청구여부
    installPrice: 0,        // 설치비
    specialRemark: '',      // 특이사항
    billingType: '1',       // 청구조건
    billingDay: 0,       // 청구일자
    dueDateType: '2',       // 납기조건
    dueDateDay: 0,        // 납기일자
    paymentType: '1',       // 결제방식 (현금,카드,어음등)
    deposit: 0,             // 보증금
    endDate: 1,           // 마감일자
    isReverseBill: 0,       // 계산서역발행
    isFreeAssignment: 1,      // 무상양도여부
    assignmentAmount: 0,      // 양도금액
    isVirtualRelease: 0,    // 가상출고여부
    contractDocUrl: '',     // 계약서URL
    contractDocFileName: '',     //  업로드 파일이름
    billingAddressSeq: '',  // 청구지 seq
    billingAddressName: '', // 청구지 명
    returnExpectDate: '', //회수예정일
    isFix: 0,
    releaseAskSeq: '',
    releaseAskStatus: '',   //출고의뢰상태
    isResident: 0,  //상주여부
    sellType: '',   //매각구분
    sellDate: null, //매각일자
    updater: '',
    estimateNo: '', //견적번호
    businessNumber: '',    //사업자번호
    customerAddress: '',    //고객주소
    companyName: '', // 회사명
    receiverEmail: '', // 고객 이메일
    firstBillingType: '2',   // 첫 회차 청구 기준
    truncationUnit: 10,     // 일할 계산 시 절사 단위
    dayOfCalculation: 30,     // 일할 계산 일자
    owner: '',
    returnAddress: '',
    options: '',
    optionAmount: 0,
    monthUnitPrice: '', //표준 월 단가
    productName: '',
    companySignatureUrl: '',
    logoImageUrl: '',
    isAutoSync: 0,
    apronSeq: null,
}

export const ContractDetailStore = observable({
    selectItemAgId: null,
    contractDetail: {},
    contractProductList: [],
    originContractProductList: [],
    contractProductGroupList: [],
    productOptionList: [],
    originProductConsumList: [],
    productConsumList: [],
    originProductOptionList: [],
    contractSiteOptionList: [],
    gridStatus: '',
    isFail: false,
    isReleaseAskBtn: true,  //출고의뢰버튼 조회모드
    ...contractColumns,
    subject: '',
    contractManager: '',
    content: '',
    billingAddressName: '',
    receiverEmail: '',
    phoneNumber: '',
    sendContractList: [],
    estimateList: [],
    tabKey : 'mail',

    /* 계약상세 UI 컨트롤 변수 */
    detailMode: 'read', // create, update, read
    isContainContractNo: false,     // 계약번호 가지고 화면에 진입 여부
    isSearch: false,    // 조회 성공 여부
    simpleView: true,   //그리드 심플하게 보기

    /* action */
    setContractDetail(value, target){
        this[target] = value;
    },
    setContractProductList(v) {
        this.contractProductList = v;
    },
    pushContractProductList(v){
        this.contractProductList.push(v);
    },
    setOriginContractProductList(v) {
        this.originContractProductList = v;
    },
    setContractProductGroupList(v) {
        this.contractProductGroupList = v;
    },
    setContractDetailMode(v) {
        this.detailMode = v;
    },
    setContainContractNo(v) {
        this.isContainContractNo = v;
    },
    setIsSearch(v) {
        this.isSearch = v;
    },
    setOriginProductConsumList(v){
        this.originProductConsumList = v;
    },
    setProductConsumList(v){
        this.productConsumList = v;
    },
    setProductOptionList(v){
        this.productOptionList = v;
    },
    pushProductOptionList(v){
        this.productOptionList.push(v);
    },
    setOriginProductOptionList(v){
        this.originProductOptionList = v;
    },
    setSimpleView(v){
        this.simpleView = v;
    },
    setContractSiteOptionList(v){
        this.contractSiteOptionList = v;
    },
    setApronList(v) {
        this.apronList = v;
    },

    /* unmount */
    init() {
        LUtils.forOwn(contractColumns, (v, k) => {
            this[k] = v;
        })
    },
    sendEmailInit() {
        this.subject = '',
        this.content = '',
        this.receiverEmail = ''
    }
});
