import { useState, useEffect } from "react";
import axios from "axios";
import { Row, InputGroup, Col, Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { AppStore } from "../../store/AppStore";
import { CContainer, CCol, CSearchBtn, CInputGroup } from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";
import Modal from "../Modal";
import { SiteSearchStore } from "../../store/search/SiteSearchStore";
import {autoHyphen, decrypt} from "../../common/utils";

const SiteManagerSearch = ({callbackFn=null, ...props}) => {
    const [siteManagerList, setSiteManagerList] = useState([]);
    const [managerName, setManagerName] = useState('');
    const {siteSeq} = props;

    useEffect(() => {
        if(siteSeq) {
            getSiteManager();
        }
    }, [siteSeq]);

    const getSiteManager = async () => {
        const result = await axios.get('/site/manager/popup', {params: {siteSeq: siteSeq, managerName: managerName}});
        result.map(v => {
            if(v.phoneNumber) v.phoneNumber = autoHyphen(decrypt(v.phoneNumber));
            if(v.email) v.email = decrypt(v.email);

            return v;
        })
        setSiteManagerList(result);
    }

    const selectedRow = (e) => {
        callbackFn && callbackFn(e.data);
        AppStore.toggleSiteManagerSearchPopup();
    }

    const refresh = () => {
        // SiteSearchStore.init();
        // getSiteManager();
    }

    return (
        <>
            <Modal
                title={'현장 담당자 조회'}
                onHide={() => AppStore.toggleSiteManagerSearchPopup()}
                toggleValue={AppStore.isOpenSiteManagerSearchPopup}
                size={'lg'}
                onExit={refresh}
            >
                <CContainer search>
                    <Row>
                        <CCol lg={4}>
                            <CInputGroup
                                label={'담당자명'}
                                value={SiteSearchStore.search.managerName}
                                onChange={v => SiteSearchStore.search.managerName = v.target.value}
                                enterKeyHint={getSiteManager}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <Col className='d-flex flex-row-reverse'>
                            <CSearchBtn onClick={getSiteManager} style={{width:100}} />
                        </Col>
                    </Row>
                </CContainer>
                <CContainer>
                    <AgGridContainer
                        height={26}
                        rowData={siteManagerList}
                        columnDefs={[
                                {field: "managerName", headerName: "담당자명", width: 180},
                                {field: "phoneNumber", headerName: "핸드폰", width: 200},
                                {field: "email", headerName: "이메일", width: 240, flex: 1},
                            ]}
                        enableCellTextSelection={true}
                        rowDoubleClickCallback={selectedRow}
                    />
                </CContainer>
            </Modal>
        </>
    );

}

export default observer(SiteManagerSearch);
