import { observer } from "mobx-react-lite";
import {CContainer, CCol, CInputGroup, CSearchBtn, CalendarGroup, CIconBtn, CSelectGroup} from "../../components/CustomContainer";
import { Row, Col, Form } from "react-bootstrap";
import { AppStore } from "../../store/AppStore";
import UserSearch from "../../components/searchModal/UserSearch";
import {useEffect, useState} from "react";
import {endDateForThisMonth, startDateForThisMonth} from "../../utils/dateUtils";
import {WorkLogStore} from "../../store/customer/WorkLogStore";
import {getUserAuthGroup, getUserName} from "../../utils/userUtils";
import {ICON_ADD} from "../../common/constants";
import { getCommcode } from "../../utils/commcode";

const WorkLogSearch = ({getWorkLogList, setLogSeq}) => {
    const [isAuth, setIsAuth] = useState(false);

    useEffect(()=> {
        if(getUserAuthGroup() <= 4){
            setIsAuth(true);
        }
        WorkLogStore.setStartDate({startDate: startDateForThisMonth()})
        WorkLogStore.setEndDate({startDate: endDateForThisMonth()})
        WorkLogStore.setUserName(getUserName());
    }, [])

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = () => {
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        if (!userInfo) {
            return false;
        }

        WorkLogStore.setUserName(userInfo.name);
        WorkLogStore.setUserSeq(userInfo.userSeq);
    }
    
    const handleWorkLogWrite =()=> {
        setLogSeq('');
        AppStore.toggleWorkLogPopup();
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'작성일'}
                            value={WorkLogStore.startDate}
                            onChange={v => WorkLogStore.setStartDate(v)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'작성일'}
                            minDate={WorkLogStore.startDate.startDate}
                            value={WorkLogStore.endDate}
                            onChange={v => WorkLogStore.setEndDate(v)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'업무타입'} labelId={'workType'}
                                        placeholder={'업무타입'}
                                        options={[{name: '전체', value: ''}].concat(getCommcode('63'))}
                                        value={WorkLogStore.workType}
                                        onChange={(v) => WorkLogStore.setWorkType(v.target.value)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'고객명'}
                            value={WorkLogStore.customerName}
                            onChange={(v)=> WorkLogStore.setCustomerName(v.target.value)}
                            enterKeyHint={getWorkLogList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'작성자'}
                            value={WorkLogStore.userName}
                            onChange={(v)=> WorkLogStore.setUserName(v.target.value)}
                            onCallbackBtn={() => isAuth && openSearchUserPopup('saleUser')}
                            enterKeyHint={getWorkLogList}
                            disabled={!isAuth}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={4}>
                        <CInputGroup
                            label={'내용'}
                            value={WorkLogStore.contents}
                            onChange={(v)=> WorkLogStore.setContents(v.target.value)}
                            enterKeyHint={getWorkLogList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isComplete"
                            label="미완료건만 보기"
                            style={{marginTop: 4}}
                            checked={WorkLogStore.isComplete === 0}
                            onChange={v => WorkLogStore.setIsComplete(v.target.checked ? 0 : 1)}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getWorkLogList} />
                        <CIconBtn style={{width: 120}}
                                  title={'업무일지 작성'}
                                  icon={ICON_ADD}
                                  onClick={handleWorkLogWrite}
                        />
                    </Col>
                </Row>
            </CContainer>
            <UserSearch callbackFn={getUserInfo} />
        </>
    );
}

export default observer(WorkLogSearch);
