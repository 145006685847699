import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite"
import { CCol, CContainer, IconBtn } from "../../components/CustomContainer";
import { ICON_TRASH } from "../../common/constants";
import { Row, Col, InputGroup, Form } from "react-bootstrap";
import { GeneralStatementStore } from "../../store/accounting/GeneralStatementStore";
import AgGridContainer from "../../components/AgGridContainer";
import LUtils from "../../utils/lodashUtils";
import { callConfirm } from "../../utils";
import { showToast } from "../../common/utils";
import { AppStore } from "../../store/AppStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import AccountingCodeSearch from "../../components/searchModal/AccountingCodeSearch";
import GeneralStatementDetailTop from "./GeneralStatementDetailTop";
import { CustomerLedgerStore } from "../../store/accounting/CustomerLedgerStore";
import { AccountLedgerStore } from "../../store/accounting/AccountLedgerStore";

const GeneralStatementDetail = ({getStatementList}) => { 
    const gridRef = useRef();
    const [gridData, setGridData] = useState([]);
    const [type, setType] = useState('');
    const [inputCode, setInputCode] = useState('');
    const [isModify, setIsModify] = useState(true);
    const [statementNo, setStatementNo] = useState('');

    useEffect(() => {
        amountCalculation();
        setIsModify(GeneralStatementStore.data.billNo ? false : true);
        if(CustomerLedgerStore.statementNo !== '' && statementNo === '') {
            getGeneralStatementDetail(CustomerLedgerStore.statementNo);
            setStatementNo(CustomerLedgerStore.statementNo);
        }
        if(AccountLedgerStore.statementNo !== '' && statementNo === '') {
            getGeneralStatementDetail(AccountLedgerStore.statementNo);
            setStatementNo(AccountLedgerStore.statementNo);
        }
    }, [GeneralStatementStore.data.generalStatementList])

    /* 전표 상세보기 */
    const getGeneralStatementDetail = async (statementNo) => {
        const result = await axios.get('/statement/general/detail', {params: {statementNo: statementNo}});

        GeneralStatementStore.setGeneralStatementList(result);
        GeneralStatementStore.setData({remark: result[0].remark, resolutionDate: result[0].resolutionDate, postingDate: result[0].postingDate, 
            approvalStatus: result[0].approvalStatus, statementType: result[0].statementType, statementStatus: result[0].statementStatus,
            managementPart: result[0].managementPart, statementNo: result[0].statementNo, seq: ''});
    }

    /* 전표 생성 (저장 버튼) */
    const callBackGridData = async ({updatedList, createdList, selectedList}) => {
        const isUpdate = !LUtils.isEmpty(updatedList);
        const isCreate = !LUtils.isEmpty(createdList);
        const isSelected = !LUtils.isEmpty(selectedList);

        if(GeneralStatementStore.data.generalStatementList.length === 0) {
            showToast('전표를 입력해주세요.');
            return;
        }

        if(GeneralStatementStore.data.approvalStatus === '2') {
            showToast('승인 전표는 승인 해제 후 수정 가능합니다.');
            return;
        }

        if(!await callConfirm('저장 하시겠습니까?')) {
            return;
        }

        const requiredParams = ['accountType', 'accountCode', 'accountCodeName', 'customerNo', 'customerName', 'amount', 'summary'];
        if(createdList.some(item => !requiredParams.every(param => item[param]))) {
            showToast('필수값을 모두 입력해야 합니다.');
            return;
        }

        if (isCreate) {
            const debit = createdList.filter(statement => statement.accountType === '1');
            const credit = createdList.filter(statement => statement.accountType === '2');

            if (!debit) {
                showToast('대변을 입력해주세요.');
                return;
            } else if (!credit) {
                showToast('차변을 입력해주세요.');
                return;
            }
        }

        let result = '';

        // 전표 그리드 수정 없이 상단 input 내용만 수정했을 경우
        if (isCreate || isUpdate) {
            const resultUpdatedList = updatedList.filter(item => !createdList.some(createdItem => createdItem.statementSeq === item.statementSeq));
            isUpdate && await axios.put("/statement/general", {...GeneralStatementStore.data, statementList: resultUpdatedList})
            if (isCreate) {
                result = await axios.post('/statement/general', {...GeneralStatementStore.data, statementList: createdList});
                GeneralStatementStore.setData({statementNo: result});
            }
        }
        if (isSelected) {
            await axios.put("/statement/general/detail", GeneralStatementStore.data);
        }

        showToast('저장 되었습니다.');
        getGeneralStatementDetail(GeneralStatementStore.data.statementNo);
        if(CustomerLedgerStore.statementNo === '' && AccountLedgerStore.statementNo === '') {
            getStatementList();
        }
    }

    /* 삭제 아이콘 버튼 */
    const removeFn = async (e) => {
        const { statementNo, statementSeq, seq } = e.data;

        if (GeneralStatementStore.data.approvalStatus === '2') {
            showToast('승인 전표는 승인 해제 후 삭제가 가능합니다.');
            return;
        }

        if (!await  callConfirm(`[${seq}]번 순번 삭제 하시겠습니까?`)){
            return false;
        }

        await axios.put("/statement/general/delete", [{statementNo: statementNo, statementSeq: statementSeq}]);
        showToast('삭제가 완료되었습니다.');
        getGeneralStatementDetail(statementNo);
        if(CustomerLedgerStore.statementNo === '' && AccountLedgerStore.statementNo === '') {
            getStatementList();
        }
    }

    /* 선택 버튼 옵션 - 삭제 */
    const deleteBtnInfo = {
        isUsed: isModify,
        title: '선택 삭제',
        callbackFn: () => removeSelectedRows(),
        icon: ICON_TRASH
    };

    /* 선택항목 삭제 */
    const removeSelectedRows = async () => {
        const selectedList = GeneralStatementStore.data.generalStatementList.filter(v => v.isSelected);

        if (selectedList.length === 0) {
            showToast('선택된 전표가 없습니다.');
            return;
        }

        if (GeneralStatementStore.data.approvalStatus === '2') {
            showToast('승인 전표는 승인 해제 후 삭제가 가능합니다.');
            return;
        }

        if (!await callConfirm('선택된 전표를 삭제하시겠습니까?')) {
            return;
        };

        // 삭제 처리
        let params = {statementList: []}
        GeneralStatementStore.data.generalStatementList.forEach(v => {
            if(v.isSelected === true) params.statementList.push({statementNo: v.statementNo, statementSeq: v.statementSeq} );
        });

        await axios.put('/statement/general/delete', params.statementList);
        showToast('삭제가 완료되었습니다.');
        getGeneralStatementDetail(GeneralStatementStore.data.statementNo);
        GeneralStatementStore.data.generalStatementList.forEach(v => v.isSelected = false); // 삭제 후 체크 풀어주기
        if(CustomerLedgerStore.statementNo === '' && AccountLedgerStore.statementNo === '') {
            getStatementList();
        }
    }

    /* 그리드에서 계정과목 선택 */
    const popupAccountCode = (e, accountType) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.accountCodeName}</div>
                {isModify && (
                    <IconBtn key={e.rowIndex} onClick={() => openAccountingCode(e, accountType)} />
                )}
            </div>
        )
    }

    /* 계정 과목 조회 팝업 클릭 시 이벤트 */
    const openAccountingCode = (e, accountType) => {
        setType(accountType);
        setGridData(e);
        AppStore.toggleAccountingCodePopup();
    }

    /* 계정 과목 팝업 callback */
    const searchAccountingCode = (data) => {
        GeneralStatementStore.data.generalStatementList.map(v => {
            if(v.seq === gridData.data.seq) {
                v.accountCode = data.code;
                v.accountCodeName = data.name;
                LUtils.assign(v, {isUpdated: true});
            }
        })
        setInputCode('');
        gridRef.current.api.redrawRows();
    }

    /* 그리드에서 거래처 선택 */
    const popupCustomerInfo = (e) => {
        return (
            <>
                <div className="d-flex justify-content-between">
                    <div>{e.data.customerName}</div>
                    {isModify && (
                        <IconBtn key={e.rowIndex} onClick={() => openCustomerSearchModal(e)}/>
                    )}
                </div>
            </>
        )
    }

    /* 거래처 조회 팝업 */
    const openCustomerSearchModal = (e) => {
        AppStore.toggleCustomerSearchModal();
        setGridData(e);
    }

    /* 거래처 선택 콜백 함수 */
    const getCustomerInfo = (customerInfo) => {
        if (!customerInfo) return false;

        const result = GeneralStatementStore.data.generalStatementList.map(v => {
            if(v.seq === gridData.data.seq) {
                v.customerNo = customerInfo.customerNo;
                v.customerName = customerInfo.customerName;
                LUtils.assign(v, {isUpdated: true});
            }
        })
        gridRef.current.api.redrawRows();
    }

    /* 전표 승인 처리 */
    const approvalStatement = async (approvalStatus) => {

        if (!GeneralStatementStore.data.statementNo) {
            showToast('전표 생성 후 승인 처리가 가능합니다.');
            return;
        }

        if (approvalStatus === '2' && GeneralStatementStore.data.postingDate === null) {
            showToast('기표일자를 선택해주세요.');
            return;
        }

        let params = {statementList: []};
        params.statementList.push({statementNo: GeneralStatementStore.data.statementNo, approvalStatus: approvalStatus, postingDate: approvalStatus === '2' ? GeneralStatementStore.data.postingDate : null});
        await axios.put('/statement/general/approval/list', params);

        // 승인 취소
        if (approvalStatus === '1') {
            GeneralStatementStore.setData({approvalStatus: '1'})
            showToast('승인이 취소 되었습니다.');
        }
        // 승인 처리
        else {
            GeneralStatementStore.setData({approvalStatus: '2'});
            showToast('승인 처리 되었습니다.');
        }

        getGeneralStatementDetail(GeneralStatementStore.data.statementNo);
        if(CustomerLedgerStore.statementNo === '' && AccountLedgerStore.statementNo === '') {
            getStatementList();
        }
    }

    const addRowInfo = {statementSeq: '', accountType: '', accountCode: '', accountCodeName: '', customerNo: '', customerName: '',
                        amount: '', summary: '', seq: '' };

    /* 금액 수정 이벤트 */
    const amountChangeFn = async (e) => {
        const { field } = e.colDef;

        if (field === 'accountType') {
            amountCalculation();
            return;
        }

        const numberColumns = ['amount'];
        if (LUtils.some(LUtils.values(LUtils.pick(e.data, numberColumns)), v => isNaN(v))) {
            showToast('숫자만 입력 가능합니다.');
            e.data[field] = 0;
            gridRef.current.api.redrawRows();
            return false;
        }

        amountCalculation();
    }


    /* 차변합계, 대변합계, 분개차액 계산 */
    const amountCalculation = () => {
        const sumOfTotalDebit = GeneralStatementStore.data.generalStatementList.filter(v => v.accountType === '1').reduce((total, debitStatement) => total + Number(debitStatement.amount), 0);
        const sumOfTotalCredit = GeneralStatementStore.data.generalStatementList.filter(v => v.accountType === '2').reduce((total, creditStatement) => total + Number(creditStatement.amount), 0);
        const totalDifference = sumOfTotalDebit - sumOfTotalCredit;

        GeneralStatementStore.modalTotalDebit = isNaN(sumOfTotalDebit) ? 0 : sumOfTotalDebit.toLocaleString();
        GeneralStatementStore.modalTotalCredit = isNaN(sumOfTotalCredit) ? 0 : sumOfTotalCredit.toLocaleString();
        GeneralStatementStore.modalTotalDifferent = isNaN(totalDifference) ? 0 : totalDifference.toLocaleString();

    }

    /* 계정과목 코드 수기 입력 이벤트 */
    const cellCustomChangeValue = async (e) => {
        if(e.column.colId!=='accountCode') return;

        const result = await axios.get('statement/accounting/code', {params: {type: 'code', inputText: e.value}});

        if(result.length === 1) {
            GeneralStatementStore.data.generalStatementList.map(v => {
                if(v.seq === e.data.seq) {
                    if(e.column.colId==='accountCode'){
                        v.accountCodeName = result[0].name;
                        v.accountCode = result[0].code;
                    }
                }
            })
        }else {
            setGridData(e);
            setType(e.data.accountType);
            setInputCode(e.value);
            AppStore.toggleAccountingCodePopup();
        }
        gridRef.current.api.redrawRows();
    };

    // 전표 승인 버튼
    const approvalBtn = {
        isUsed: GeneralStatementStore.data.approvalStatus === '1',
        icon: 'fi-rr-memo-circle-check',
        title: '전표 승인',
        callbackFn: () => approvalStatement('2'),
        width: 110,
        variant: 'primary'
    }

    // 전표 승인 취소 버튼
    const approvalCancelBtn = {
        isUsed: GeneralStatementStore.data.approvalStatus === '2',
        icon: 'fi-rr-delete-document',
        title: '전표 승인 취소',
        callbackFn: () => approvalStatement('1'),
        width: 110,
        variant: 'dark'
    }

    /* 순번 만들기 */
    const addSeq = (e) => {

        for( const [ i, statement ] of GeneralStatementStore.data.generalStatementList.entries() ) {
            statement.index = i;
            GeneralStatementStore.data.generalStatementList[i].seq = i+1;
        }

        return (
            <div className="d-flex justify-content-between">
                <div>{e.data.seq}</div>
            </div>
        )
    }

    return (
        <>
            <GeneralStatementDetailTop />
            <CContainer>
                <AgGridContainer
                    gridTitle={'대체전표'}
                    gridRef={gridRef}
                    height={40}
                    rowData={GeneralStatementStore.data.generalStatementList}
                    columnDefs={
                        [
                            {field: "statementNo", headerName: "전표번호", hide: true},
                            {field: "statementSeq", headerName: "전표번호순번", width: 60, hide: true},
                            {field: "seq", headerName: "순번", cellRenderer: e => addSeq(e), width: 70},
                            {
                                field: "accountType", 
                                headerName: "구분", 
                                editable: true,
                                valueFormatter: v => v.value === '1' ? '차변' : v.value === '2' ? '대변' : v.value === '' ? '' : '기타', 
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {
                                    values: [{name: 'debit', value: '1'}, {name: 'credit', value: '2'}].map(v => v.value),
                                },
                                onCellValueChanged: amountChangeFn,
                                headerClass: 'grid-column-required',
                                width: 90, minWidth: 70
                            },
                            {field: "accountCode", headerClass: 'grid-column-required', headerName: "코드", editable: isModify, width: 90},
                            {field: "accountCodeName", headerClass: 'grid-column-required', headerName: "계정과목", cellRenderer: e => popupAccountCode(e, e.data.accountType), width: 170, minWidth: 130},
                            {field: "customerNo", headerClass: 'grid-column-required', headerName: "코드", width: 100},
                            {field: "customerName", headerClass: 'grid-column-required', headerName: "거래처명", cellRenderer: e => popupCustomerInfo(e), minWidth: 175},
                            {
                                field: "amount",
                                headerName: "금액",
                                editable: true,
                                // cellRenderer: e => getAmount(e),
                                valueFormatter: v => v.value?.toLocaleString(),
                                onCellValueChanged: amountChangeFn,
                                headerClass: 'grid-column-required',
                                cellClass: 'ag-grid-money-align',
                                minWidth: 110, width: 130
                            },
                            // {field: "credit", headerName: "대변", editable: true, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', minWidth: 110},
                            {field: "summary", headerClass: 'grid-column-required', headerName: "적요", editable: true, minWidth: 170, width: 180, flex: 1}
                        ]
                    }
                    seqColumn={'agId'}
                    // useRemoveRow={true}
                    isCheckBox={true}
                    removeCallback={removeFn}
                    callBackGridData={callBackGridData}
                    customBtnInfo={[deleteBtnInfo, approvalCancelBtn, approvalBtn]}
                    addRowInfo={addRowInfo}
                    isCellCustom={isModify}
                    cellCustomChangeValue={cellCustomChangeValue}
                    useCreated={isModify}
                    useUpdated={isModify}
                    getRowStyle={(params) => {
                            if (params.data.isDel === 1) {
                                return { background: 'lightgray', pointerEvents: 'none', color: '#bababa' };
                            }
                            return null;
                        }
                    }
                />
            </CContainer>
            <CContainer>
                <Row className="d-flex justify-content-end">
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'modalTotalDebit'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>차변합계</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'right'}}
                                            max={2}
                                            aria-describedby={'modalTotalDebit'}
                                            value={GeneralStatementStore.modalTotalDebit.toLocaleString()}
                                            readOnly={true}/>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'modalTotalCredit'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>대변합계</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'right'}}
                                            max={2}
                                            aria-describedby={'modalTotalCredit'}
                                            value={GeneralStatementStore.modalTotalCredit.toLocaleString()}
                                            readOnly={true}/>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'modalTotalDifferent'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>분개차액</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'right'}}
                                            max={2}
                                            aria-describedby={'modalTotalDifferent'}
                                            value={GeneralStatementStore.modalTotalDifferent.toLocaleString()}
                                            readOnly={true}/>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                </Row>
            </CContainer>
            <CustomerSearch callbackFn={getCustomerInfo} />
            <AccountingCodeSearch callbackFn={searchAccountingCode} inputCode={inputCode} />
        </>
    );
}

export default observer(GeneralStatementDetail);
