import { observer } from "mobx-react-lite";
import { Row, Col } from "react-bootstrap";
import axios from 'axios';

import { CCol, CContainer, CInputGroup, CIconBtn, CSearchBtn } from "../../components/CustomContainer";
import { AccountCloseStore } from "../../store/accounting/AccountCloseStore";
import { callConfirm } from "../../utils";
import { showToast } from "../../common/utils";

const AccountCloseSearch = ({getAccountCloseList, getYearCloseCheck}) => {
    const store = AccountCloseStore;

    const eventBtn = async () => {
        // 회계년 이월 확인
        if (!await callConfirm('회계년이월 하시겠습니까?')) return;

        await axios.put('/statement/close', {targetYear: store.search.targetYear, nextYear: store.search.nextYear})
                    .then(() => {
                        showToast('회계년 이월 마감이 완료되었습니다.');
                        getYearCloseCheck();
                    });
    }

    const prevYearBtn = () => {
        store.search.targetYear = String(Number(store.search.targetYear) - 1);
        store.search.nextYear = String(Number(store.search.targetYear) + 1);
        store.accountList = [];
        store.accountAmountList = [];
    }

    const nextYearBtn = () => {
        store.search.targetYear = String(Number(store.search.targetYear) + 1);
        store.search.nextYear = String(Number(store.search.targetYear) + 1);
        store.accountList = [];
        store.accountAmountList = [];
    }

    /* 회계년 마감해제 */
    const updateYearClose = async () => {
        // 회계년 마감해제 확인
        if (!await callConfirm('회계년 마감해제 하시겠습니까? 이월년도의 기초잔액이 모두 삭제됩니다.')) return;

        await axios.put('/statement/year/close', {targetYear: store.search.targetYear})
                    .then(() => {
                        showToast('회계년 마감해제가 완료되었습니다.');
                        getYearCloseCheck();
                    });
    }

    return (
        <>
            <CContainer search height={'100%'}>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'대상년도'} labelId={'targetYear'}
                                     value={store.search.targetYear}
                                     onChange={v => store.search.targetYear = v.target.value}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={1} className="text-end ">
                        <CIconBtn onClick={prevYearBtn} title={'<<'} style={{minWidth: '10px'}} className={'me-1'} />
                        <CIconBtn onClick={nextYearBtn} title={'>>'} style={{minWidth: '10px'}} className={'ms-0'} />
                    </CCol>
                    <CCol lg={1} className="mt-1 "> 년 기말잔액이 </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'이월년도'} labelId={'nextYear'}
                                     value={store.search.nextYear}
                                     onChange={v => store.search.nextYear = v.target.value}   
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2} className="mt-1 "> 년 기초잔액으로 이월됩니다. </CCol>

                    <Col className='d-flex flex-row-reverse'>
                        <CIconBtn title={'회계년 이월 마감'}
                                  onClick={eventBtn}
                                  disabled={store.isYearClose}
                        />
                        {store.isYearClose && 
                            <CIconBtn title={'마감해제'}
                                      onClick={updateYearClose}
                            />
                        }
                        <CSearchBtn onClick={getAccountCloseList} />
                    </Col>
                </Row>
            </CContainer>
        </>
    )
}

export default observer(AccountCloseSearch);
