import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import axios from 'axios';

import { CContainer, CCol, CInputGroup, Subject, CIconBtn, CSelectGroup } from "../../components/CustomContainer";
import { Col, Row } from "react-bootstrap";
import { MaintenanceStore } from "../../store/asset/MaintenanceStore";
import AgGridContainer from "../../components/AgGridContainer";
import { showToast } from "../../common/utils";
import { AppStore } from "../../store/AppStore";
import ConsumablesSearch from "../../components/searchModal/ConsumablesSearch";
import LUtils from "../../utils/lodashUtils";
import { ConsumableStore } from "../../store/search/ConsumableStore";
import { callConfirm } from "../../utils";
import AssetSearch from "../../components/searchModal/AssetSearch";
import { ICON_TRASH } from "../../common/constants";
import { getSessionUserSeq, getUserName } from "../../utils/userUtils";
import { findCommKrnm, getCommcode } from "../../utils/commcode";

const MaintenanceDetail = ({getMaintenanceList, searchUser, setSearchUser, maintenanceCodeList}) => {
    const store = MaintenanceStore;
    const consumGridRef = useRef();
    const consumRef = useRef();

    const [rowProductList, setRowProductList] = useState([]);
    const [inputConsum, setInputConsum] = useState([]);
    const [serialNo, setSerialNo] = useState([]);
    const [lgCodeList, setLgCodeList] = useState([]);
    const [mdCodeList, setMdCodeList] = useState([]);
    const [smCodeList, setSmCodeList] = useState([]);
    const [productType, setProductType] = useState('');
    const [productTypeName, setProductTypeName] = useState('');
    const [lgValue, setLgValue] = useState('');
    const [lgName, setLgName] = useState('');
    const [mdValue, setMdValue] = useState('');
    const [mdName, setMdName] = useState('');
    const [smValue, setSmValue] = useState('');
    const [smName, setSmName] = useState('');
    const [maintenanceContent, setMaintenanceContent] = useState('');

    useEffect(() => {
        if(store.status === 'detail') {
            getDetailList();
            getMaintenanceDetailList();
        }else {
            const userSeq = getSessionUserSeq();
            store.detailInput.mechanic = userSeq;
            store.detailInput.mechanicName = getUserName(userSeq);
        }
    }, []);

    // useEffect(() => {
    //     let content = (productType ? productTypeName : '') + (lgValue ? (" / " + lgName) : '') + (mdValue ? (" / " + mdName) : '') + (smValue ? (" / " + smName) : '');
    //     if ((store.detail.maintenanceStatus === 1 || !store.detail.maintenanceSeq) && productType) {
    //         store.detailInput.content = content + " / ";
    //     }

    // }, [maintenanceContent]);

    const getSelectOptionValue = (value, type='') => {
        let codeList = [];
        if (type === '1') {
            codeList = maintenanceCodeList.filter(v => v.division === value).map(v => {return {name: v.maintenanceCodeName, value: v.maintenanceCodeSeq}})
        } else if (type === '2') {
            if (value) {
                codeList = maintenanceCodeList.filter(v => v.parentSeq === Number(value)).map(v => {return {name: v.maintenanceCodeName, value: v.maintenanceCodeSeq}})
            } else {
                codeList = maintenanceCodeList.filter(v => v.productType === Number(type)).map(v => {return {name: v.maintenanceCodeName, value: v.maintenanceCodeSeq}})
            }
        }

        return codeList;
    }

    const handleConsumQtyEdit = async (e) => {
        if(store.status === 'detail' && Number(e.data.maxQty) < (Number(e.newValue) - Number(e.data.originQty))) {
            showToast('입력 수량이 재고 수량보다 클 수 없습니다.');
            e.data.qty = e.oldValue;
        }else if(Number(e.data.maxQty) < Number(e.newValue)) {
            showToast('입력 수량이 재고 수량보다 클 수 없습니다.');
            e.data.qty = e.oldValue;
        } else {
            e.data.qty = e.newValue;
        }
        consumGridRef.current.api.redrawRows();
    }

    const columnDefs = [
        {field: "maintenanceDate", headerName: "정비완료일자", width: 180},
        {field: "mechanicName", headerName: "정비자"},
        {field: "amount", headerName: "수리비", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 100},
        {field: "consumables", headerName: "사용한 소모품 내역", width: 220},
        {field: "content", headerName: "내용", width: 250, flex: 1},
    ];

    const consumableDefs = [
        {field: "maintenancConsumableSeq", headerName: "정비소모품순번", hide: true},
        {field: "productName", headerName: "소모품명", width: 120},
        {field: "model", headerName: "모델명", width: 120},
        {field: "maxQty", headerName: "최대수량"},
        {field: "qty", headerName: "수량", onCellValueChanged: handleConsumQtyEdit, editable: true, headerClass: 'grid-column-editable'},
        {field: "storageNo", headerName: "입고번호", width: 120},
        {field: "serialNo", headerName: "시리얼번호", width: 180},
        {field: "remark", headerName: "비고", width: 250, editable: true, headerClass: 'grid-column-editable'},
    ];

    /* 정비 이력 */
    const getDetailList = async () => {
        const resultList = await axios.get('/asset/maintenance/details', { params: store.detail });
        if(resultList.length === 0) {
            store.detailList = [];
            return;
        }

        store.detailList = resultList;
        store.detail.model = resultList[0].model;
        store.detail.spec = resultList[0].spec;
        store.detailInput.assetNo = resultList[0].assetNo;
    }

    /* 정비 일지 및 소모품 목록 */
    const getMaintenanceDetailList = async (e) => {
        store.detailConsumList = [];
        const params = {maintenanceSeq: store.detail.maintenanceSeq};
        const result = await axios.get('/asset/maintenance', {params});
        const resultList = await axios.get('/asset/maintenance/consumable', {params});

        setProductType(result.maintenanceProductType);
        setProductTypeName(findCommKrnm(result.maintenanceProductType, '68'));
        setLgValue(result.maintenanceLgSeq);
        (result.maintenanceLgSeq && result.maintenanceLgSeq !== '') && setLgName(maintenanceCodeList.filter(v => v.maintenanceCodeSeq === Number(result.maintenanceLgSeq))[0].maintenanceCodeName);
        setMdValue(result.maintenanceMdSeq);
        (result.maintenanceMdSeq && result.maintenanceMdSeq !== '') && setMdName(maintenanceCodeList.filter(v => v.maintenanceCodeSeq === Number(result.maintenanceMdSeq))[0].maintenanceCodeName);
        setSmValue(result.maintenanceSmSeq);
        (result.maintenanceSmSeq && result.maintenanceSmSeq !== '') && setSmName(maintenanceCodeList.filter(v => v.maintenanceCodeSeq === Number(result.maintenanceSmSeq))[0].maintenanceCodeName);

        if (result.maintenanceProductType === '1') {
            setLgCodeList(getSelectOptionValue('lg', result.maintenanceProductType));
            setMdCodeList(getSelectOptionValue('md', result.maintenanceProductType));
            setSmCodeList(getSelectOptionValue('sm', result.maintenanceProductType));
        } else if (result.maintenanceProductType === '2') {
            setLgCodeList(getSelectOptionValue(null, result.maintenanceProductType));
            setMdCodeList(getSelectOptionValue(result.maintenanceLgSeq, result.maintenanceProductType));
            setSmCodeList(getSelectOptionValue(result.maintenanceMdSeq, result.maintenanceProductType));
        }

        store.detailConsumList = resultList;
        store.detailInput.maintenanceSeq = result.maintenanceSeq;
        store.detailInput.content = result.content;
        store.detailInput.mechanic = result.mechanic;
        store.detailInput.mechanicName = result.mechanicName;
        store.detail.maintenanceStatus = result.maintenanceStatus;
        store.detailInput.assetStatus = result.assetStatus;

        // if(resultList.length === 0) return showToast('조회된 소모품이 없습니다.');
    }

    const consumableEvent = async () => {
        const list = [{productName: inputConsum}];
        setRowProductList(list);
        ConsumableStore.isMaintenanceDetail = true;
        ConsumableStore.search.productName = inputConsum;

        const params = {
            productName: inputConsum,
            available: 'stock',
            isDeleted: 0,
        };
        const result = await axios.get('/asset/consumable/list', { params });
        let seq = store.detailConsumableList.length + 1;
        if(store.status === 'detail' && store.detail.maintenanceStatus === 1) {
            seq = store.detailConsumList.length + 1;
        }

        if(store.status === 'detail' && store.detail.maintenanceStatus === 1) {
            if(result.length === 1) {
                if(!store.detailConsumList.some(v => v.serialNo === result[0].serialNo)){
                    const additionalRowInfo = {
                        productSeq: result[0].consumProductSeq
                        , addRowId: seq++
                        , productName: result[0].consumProductName
                        , model: result[0].consumModel
                        , consumProductCode: result[0].consumProductCode
                        , storageNo: result[0].storageNo
                        , storageSeq: result[0].storageSeq
                        , serialNo: result[0].serialNo
                        , qty: 1
                        , maxQty: result[0].qty
                        , isSelected: true, isCreated: true
                    }
                    store.detailConsumList.push({...additionalRowInfo});
                }
            }else {
                AppStore.toggleConsumablesSearchModal();
            }
        } else if(store.status === 'add') {
            if(result.length === 1) {
                if(!store.detailConsumableList.some(v => v.serialNo === result[0].serialNo)){
                    const additionalRowInfo = {
                        productSeq: result[0].consumProductSeq
                        , addRowId: seq++
                        , productName: result[0].consumProductName
                        , model: result[0].consumModel
                        , consumProductCode: result[0].consumProductCode
                        , storageNo: result[0].storageNo
                        , storageSeq: result[0].storageSeq
                        , serialNo: result[0].serialNo
                        , qty: 1
                        , maxQty: result[0].qty
                        , isSelected: true, isCreated: true
                    }
                    store.detailConsumableList.push({...additionalRowInfo});
                }
            }else {
                AppStore.toggleConsumablesSearchModal();
            }

        }

    }

    const consumableSerialEvent = async () => {
        const params = {
            serialNo: serialNo,
            available: 'stock',
            isDeleted: 0,
        };
        const result = await axios.get('/asset/consumable/list', { params });
        let seq = store.detailConsumableList.length + 1;
        if(store.status === 'detail' && store.detail.maintenanceStatus === 1) {
            seq = store.detailConsumList.length + 1;
        }

        if(store.status === 'detail' && store.detail.maintenanceStatus === 1) {
            if(result.length === 1) {
                if(!store.detailConsumList.some(v => v.serialNo === result[0].serialNo)){
                    const additionalRowInfo = {
                        productSeq: result[0].consumProductSeq
                        , addRowId: seq++
                        , productName: result[0].consumProductName
                        , model: result[0].consumModel
                        , consumProductCode: result[0].consumProductCode
                        , storageNo: result[0].storageNo
                        , storageSeq: result[0].storageSeq
                        , serialNo: result[0].serialNo
                        , qty: 1
                        , maxQty: result[0].qty
                        , isSelected: true, isCreated: true
                    }
                    store.detailConsumList.push({...additionalRowInfo});
                }
            }else {
                showToast('조회된 데이터가 없습니다.');
            }
        } else if(store.status === 'add') {
            if(result.length === 1) {
                if(!store.detailConsumableList.some(v => v.serialNo === result[0].serialNo)){
                    const additionalRowInfo = {
                        productSeq: result[0].consumProductSeq
                        , addRowId: seq++
                        , productName: result[0].consumProductName
                        , model: result[0].consumModel
                        , consumProductCode: result[0].consumProductCode
                        , storageNo: result[0].storageNo
                        , storageSeq: result[0].storageSeq
                        , serialNo: result[0].serialNo
                        , qty: 1
                        , maxQty: result[0].qty
                        , isSelected: true, isCreated: true
                    }
                    store.detailConsumableList.push({...additionalRowInfo});
                }
            }else {
                showToast('조회된 데이터가 없습니다.');
            }
        }
    }

    /* 소모품 팝업 콜백 */
    const callbackConsumListSearch = (info) => {
        if (LUtils.isEmpty(info)) {
            return false;
        }

        let seq = store.detailConsumableList.length + 1;
        if(store.status === 'detail' && store.detail.maintenanceStatus === 1) {
            seq = store.detailConsumList.length + 1;
        }
        for(let i in info){
            const consum = info[i];

            if(store.status === 'detail' && store.detail.maintenanceStatus === 1) {
                if(!store.detailConsumList.some(v => v.serialNo === consum.serialNo)){
                    const additionalRowInfo = {
                        addRowId: seq++
                        , productSeq: consum.consumProductSeq
                        , productName: consum.consumProductName
                        , model: consum.consumModel
                        , consumProductCode: consum.consumProductCode
                        , storageNo: consum.storageNo
                        , storageSeq: consum.storageSeq
                        , serialNo: consum.serialNo
                        , qty: 1
                        , maxQty: consum.qty
                        , amount: consum.consumAmount
                        , isSelected: true, isCreated: true
                    };
                    store.detailConsumList.push({...additionalRowInfo});
                }
            }else if(store.status === 'add') {
                if(!store.detailConsumableList.some(v => v.serialNo === consum.serialNo)){
                    const additionalRowInfo = {
                        addRowId: seq++
                        , productSeq: consum.consumProductSeq
                        , productName: consum.consumProductName
                        , model: consum.consumModel
                        , consumProductCode: consum.consumProductCode
                        , storageNo: consum.storageNo
                        , storageSeq: consum.storageSeq
                        , serialNo: consum.serialNo
                        , qty: 1
                        , maxQty: consum.qty
                        , amount: consum.consumAmount
                        , isSelected: true, isCreated: true
                    };
                    store.detailConsumableList.push({...additionalRowInfo});
                }
            }

        }
    }

    /* 자산팝업에서 관리번호 선택 */
    const callbackAssetInfo = (data) => {
        if(data){
            store.detail.managementNo = data.managementNo;
            store.detailInput.assetNo = data.assetNo;
            store.detail.model = data.model;
            store.detail.spec = data.spec;

            getDetailList();
        }
    }

    /* 등록 */
    const createMaintenance = async () => {
        if(!store.detailInput.assetNo) {
            showToast("관리번호를 확인해주세요.");
            return;
        }
        if(!store.detailInput.content) {
            showToast("정비일지를 작성해주세요.");
            return;
        }
        if(!store.detailInput.assetStatus) {
            showToast("정비구분을 선택해주세요.");
            return;
        }

        // 등록 확인
        if (!await callConfirm('정비 등록 하시겠습니까?')) return;
        const params = {
            maintenanceSeq: store.detailInput.maintenanceSeq,
            assetNo: store.detailInput.assetNo,
            assetStatus: store.detailInput.assetStatus,
            content: store.detailInput.content,
            mechanic: store.detailInput.mechanic,
            maintenanceConsumList: store.detailConsumableList,
            maintenanceProductType: productType,
            maintenanceLgSeq: lgValue,
            maintenanceMdSeq: mdValue,
            maintenanceSmSeq: smValue,
        }
        await axios.post('/asset/maintenance', params)
        .then((result) => {
            showToast('등록 되었습니다.');
            store.status = 'detail';
            getDetailList();
            getMaintenanceList();
            store.detail.maintenanceSeq = result;
        })
    }

    /* 정비 저장 */
    const saveMaintenance = async () => {
        if(!store.detailInput.content) {
            showToast("정비일지를 작성해주세요.");
            return;
        }

        // 등록 확인
        if (!await callConfirm('저장 하시겠습니까?')) return;
        const params = {
            maintenanceSeq: store.detail.maintenanceSeq,
            assetNo: store.detailInput.assetNo,
            assetStatus: store.detailInput.assetStatus,
            content: store.detailInput.content,
            mechanic: store.detailInput.mechanic,
            maintenanceProductType: productType,
            maintenanceProductTypeName: productTypeName,
            maintenanceLgSeq: lgValue,
            maintenanceLgName: lgName,
            maintenanceMdSeq: mdValue,
            maintenanceMdName: mdName,
            maintenanceSmSeq: smValue,
            maintenanceSmName: smName,
        }

        await axios.put('/asset/maintenance', params)
        .then(() => {
            showToast('저장 되었습니다.');
            getDetailList();
            getMaintenanceList();
            getMaintenanceDetailList();
        })
    }

    /* 정비 완료 */
    const completionMaintenance = async () => {
        if(!store.detailInput.content) {
            showToast("정비일지를 작성해주세요.");
            return;
        }

        if(store.detailConsumList.length < 1) {
            if(!await callConfirm('소모품이 없습니다. 완료 하시겠습니까?')) return;
        }else {
            if(!await callConfirm('완료 하시겠습니까?')) return;
        }

        const params = {
            maintenanceSeq: store.detail.maintenanceSeq,
            assetNo: store.detailInput.assetNo,
            content: store.detailInput.content,
            mechanic: store.detailInput.mechanic,
            maintenanceConsumList: store.detailConsumList,
            maintenanceStatus: 2,
            maintenanceProductType: productType,
            maintenanceProductTypeName: productTypeName,
            maintenanceLgSeq: lgValue,
            maintenanceLgName: lgName,
            maintenanceMdSeq: mdValue,
            maintenanceMdName: mdName,
            maintenanceSmSeq: smValue,
            maintenanceSmName: smName,
        }
        await axios.put('/asset/maintenance/completion', params)
            .then(() => {
                showToast('정비 완료 했습니다.');
                // getDetailList();
                getMaintenanceList();
                getMaintenanceDetailList();
            })
    }

    /* 소모품 검색 버튼 */
    const customSearchBtn = {
        isUsed: store.detail.maintenanceStatus === 1 || !store.detail.maintenanceSeq ,
        callbackFn:() => consumablesSearch(),
        icon: 'fi-br-search',
        title: '소모품검색',
        width: 120,
    }

    /* 소모품 저장 버튼 */
    const customSaveBtn = {
        isUsed: store.status === 'detail' && store.detail.maintenanceStatus === 1,
        callbackFn:() => consumablesSave(),
        icon: 'fi-rs-disk',
        title: '소모품저장',
        width: 120,
        variant: 'success'
    }

    /* 소모품 삭제 버튼 */
    const customDeleteBtn = {
        isUsed: store.status === 'detail' && store.detail.maintenanceStatus === 1,
        callbackFn:() => consumablesDelete(),
        icon: ICON_TRASH,
        title: '소모품삭제',
        width: 120,
        variant: 'danger'
    }


    /* 소모품 검색 */
    const consumablesSearch = () => {
        AppStore.toggleConsumablesSearchModal();
    }

    /* 소모품 저장 */
    const consumablesSave = async () => {
        // 저장 확인
        if (!await callConfirm('소모품 저장 하시겠습니까?')) return;
        const params = {
        maintenanceSeq: store.detailInput.maintenanceSeq,
        maintenanceConsumList: store.detailConsumList,
        maintenanceStatus: 1
        }

        await axios.put('/asset/maintenance/consumable', params)
            .then((result) => {
                showToast('저장 되었습니다.');
                getDetailList();
                getMaintenanceList();
                getMaintenanceDetailList();
            })
    }

    /* 소모품 삭제 */
    const consumablesDelete = async () => {
        // 삭제 확인
        if (!await callConfirm('소모품 삭제 하시겠습니까?')) return;
        const seqList = store.detailConsumList.filter(v => v.isSelected).map(item => item.maintenanceConsumableSeq);

        await axios.delete('/asset/maintenance/consumable', {data: seqList})
            .then((result) => {
                showToast('삭제 되었습니다.');
                getDetailList();
                getMaintenanceList();
                getMaintenanceDetailList();
            })
    }

    /*  */
    const openSearchUserPopup = () => {
        setSearchUser('maintenance');
        AppStore.toggleUserSearchModal();
    }

    const findNthIndex = (str, char, n) => {
        let index = -1;
        
        // 반복해서 char의 인덱스를 찾습니다.
        for (let i = 0; i < n; i++) {
            index = str.indexOf(char, index + 1);
            
            // 만약 indexOf가 -1을 반환하면 문자가 더 이상 없다는 뜻입니다.
            if (index === -1) {
                return -1; // 찾는 문자 발생 횟수가 부족할 경우 -1 반환
            }
        }
        
        return index;
    }

    return(
        <>
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup label={'관리번호'} labelId={'managementNo'}
                                    disabled={store.status === 'detail'}
                                    value={store.detail.managementNo}
                                    onChange={v => store.detail.managementNo = v.target.value}
                                    onCallbackBtn={() => AppStore.toggleAssetSearchModal()}
                                    labelClassName='input-required'
                                    enterKeyHint={()=> getDetailList()}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'모델'} labelId={'model'}
                                    disabled={true}
                                    value={store.detail.model}
                                    onChange={v => store.detail.model = v.target.value}
                                    enterKeyHint={()=> getDetailList()}
                        />
                    </CCol>
                    <CCol lg={6}>
                        <CInputGroup label={'스펙'} labelId={'spec'}
                                    disabled={true}
                                    value={store.detail.spec}
                                    onChange={v => store.detail.spec = v.target.value}
                                    enterKeyHint={()=> getDetailList()}
                        />
                    </CCol>
                </Row>
            </CContainer>

            <CContainer>
                <AgGridContainer
                    height={15}
                    gridTitle={'정비 내역'}
                    rowData={store.detailList}
                    columnDefs={columnDefs}
                />
            </CContainer>

                <CContainer>
                    <Subject><h5>정비일지</h5></Subject>
                    <Row>
                        <CCol lg={3}>
                            <CInputGroup label={'정비자'} labelId={'mechanic'}
                                        value={store.detailInput.mechanicName}
                                        onCallbackBtn={()=> openSearchUserPopup()}
                                        labelClassName='input-required'
                                        disabled={true}
                            />
                        </CCol>
                        {(store.status === 'add' || (store.detail.maintenanceStatus === 1 && (store.detailInput.assetStatus === '307' || store.detailInput.assetStatus === '308' || store.detailInput.assetStatus === '309'))) &&
                            <CCol lg={3}>
                                <CSelectGroup label={'정비분류'} labelId={'assetStatus'}
                                            options={[
                                                {name: '- 선택 -', value: ''},
                                                {name: '경정비', value: '307'},
                                                {name: '중정비', value: '308'},
                                                {name: '중세차', value: '309'},
                                            ]}
                                            value={store.detailInput.assetStatus}
                                            labelClassName='input-required'
                                            onChange={(v) => store.detailInput.assetStatus = v.target.value}
                                            disabled={store.status === 'detail'}
                                />
                            </CCol>
                        }
                    </Row>
                    {/* {(store.detail.maintenanceStatus === 1 || !store.detail.maintenanceSeq) && ( */}
                        <>
                        <Row>
                            <CCol lg={3}>
                                <CSelectGroup label={'장비구분'} labelId={'productType'}
                                            options={[{name: '- 장비구분 -', value: ''}].concat(getCommcode('68'))}
                                            value={productType}
                                            onChange={(v) => {
                                                setProductType(v.target.value);
                                                setProductTypeName(v.target.options[v.target.selectedIndex].text);
                                                setLgCodeList([]);
                                                setMdCodeList([]);
                                                setSmCodeList([]);
                                                setLgValue('');
                                                setLgName('');
                                                setMdValue('');
                                                setMdName('');
                                                setSmValue('');
                                                setSmName('');
                                                
                                                if (v.target.value === '1') {
                                                    setLgCodeList(getSelectOptionValue('lg', v.target.value));
                                                    setMdCodeList(getSelectOptionValue('md', v.target.value));
                                                    setSmCodeList(getSelectOptionValue('sm', v.target.value));
                                                } else {
                                                    setLgCodeList(getSelectOptionValue(null, v.target.value));
                                                    setMdCodeList([]);
                                                    setSmCodeList([]);
                                                }
                                            }}
                                            disabled={store.detail.maintenanceStatus === 2}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CSelectGroup label={'대분류'} labelId={'part'}
                                            options={[{name: '- 대분류 -', value: ''}].concat(lgCodeList)}
                                            value={lgValue}
                                            onChange={v => {
                                                setLgValue(v.target.value);
                                                setLgName(v.target.options[v.target.selectedIndex].text);
                                                if (productType === '2') {
                                                    setMdValue('');
                                                    setMdName('');
                                                    setSmValue('');
                                                    setSmName('');

                                                    setMdCodeList(getSelectOptionValue(v.target.value, productType));
                                                    setSmCodeList([]);
                                                }
                                            }}
                                            disabled={store.detail.maintenanceStatus === 2}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CSelectGroup label={'중분류'} labelId={'component'}
                                            options={[{name: '- 중분류 -', value: ''}].concat(mdCodeList)}
                                            value={mdValue}
                                            onChange={v => {
                                                setMdValue(v.target.value);
                                                setMdName(v.target.options[v.target.selectedIndex].text);
                                                if (productType === '2') {
                                                    setSmValue('');
                                                    setSmName('');
                                                    setSmCodeList(getSelectOptionValue(v.target.value, productType));
                                                }
                                            }}
                                            disabled={store.detail.maintenanceStatus === 2}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CSelectGroup label={'소분류'} labelId={'defect'}
                                            options={[{name: '- 소분류 -', value: ''}].concat(smCodeList)}
                                            value={smValue}
                                            onChange={v => {
                                                setSmValue(v.target.value);
                                                setSmName(v.target.options[v.target.selectedIndex].text);
                                            }}
                                            disabled={store.detail.maintenanceStatus === 2}
                                />
                            </CCol>
                        </Row>
                        </>
                    {/* )} */}
                    <Row>
                        <CCol lg={12}>
                            <textarea
                                style={{height: 80, width: '100%', padding: 15, borderRadius: 5, borderWidth: 1}}
                                value={store.detailInput.content}
                                onChange={v => store.detailInput.content = v.target.value}
                                placeholder={'정비 일지를 자유롭게 입력해 주세요.'}
                                disabled={store.detail.maintenanceStatus === 2}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <Col className='d-flex flex-row-reverse'>
                            {store.status === 'add' && (
                                <CIconBtn style={{width: 120, height: 30, marginBottom: 30}}
                                            title={'등록'}
                                            icon={'fi-rs-user-gear'}
                                            onClick={createMaintenance}
                                />
                            )}
                            {store.status === 'detail' &&  store.detail.maintenanceStatus === 1 &&(
                                <>
                                    <CIconBtn style={{width: 120, height: 30}}
                                            title={'완료'}
                                            icon={'fi-rs-user-gear'}
                                            onClick={completionMaintenance}
                                    />
                                    <CIconBtn style={{width: 120, height: 30}}
                                            title={'일지저장'}
                                            icon={'fi-rs-disk'}
                                            onClick={saveMaintenance}
                                            variant={'success'}
                                    />
                                </>
                            )}
                        </Col>
                    </Row>
                </CContainer>

                <CContainer>
                    <Subject><h5>정비 시 사용한 소모품</h5></Subject>
                    {(store.detail.maintenanceStatus === 1 || !store.detail.maintenanceSeq)&&
                    (
                        <Row>
                            <CCol lg={3}>
                                <CInputGroup
                                    label={'소모품명'}
                                    labelId={'consumable'}
                                    value={inputConsum}
                                    onChange={v => {setInputConsum(v.target.value)}}
                                    enterKeyHint={consumableEvent}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CInputGroup
                                    label={'시리얼번호'}
                                    labelId={'serialNo'}
                                    value={serialNo}
                                    onChange={v => {setSerialNo(v.target.value)}}
                                    enterKeyHint={consumableSerialEvent}
                                />
                            </CCol>
                        </Row>
                    )}
                    <AgGridContainer
                        gridRef={consumGridRef}
                        ref={consumRef}
                        height={15}
                        gridTitle={'사용한 소모품 목록'}
                        rowData={store.status === 'detail' ? store.detailConsumList : store.detailConsumableList}
                        columnDefs={consumableDefs}
                        isCheckBox={true}
                        customBtnInfo={[customSearchBtn, customSaveBtn, customDeleteBtn]}
                    />
                </CContainer>

            <ConsumablesSearch callbackFn={callbackConsumListSearch} selProductList={rowProductList} isMaintenanceDetail={true} />
            <AssetSearch callbackFn={callbackAssetInfo} />
        </>
    )
}

export default observer(MaintenanceDetail);
