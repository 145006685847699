import { useState, useRef } from "react";
import { observer } from "mobx-react-lite"
import { Modal, Row, Button, Col, Form } from "react-bootstrap";
import { CContainer, CCol, CInputDoubleGroup, CSearchBtn, Subject, CCancelBtn, CIconBtn, IconBtn } from "../../../components/CustomContainer";
import { AppStore } from "../../../store/AppStore";
import AccountingCodeSearch from "../../../components/searchModal/AccountingCodeSearch";
import axios from "axios";
import { PurchaseSalesStatementStore } from "../../../store/accounting/PurchaseSalesStatementStore";
import { showToast } from "../../../common/utils";
import AgGridContainer from "../../../components/AgGridContainer";
import { getCommcode } from "../../../utils/commcode";
import { ICON_ADD, ICON_TRASH } from "../../../common/constants";
import { findCommKrnm } from "../../../utils/commcode";
import CustomerSearch from "../../../components/searchModal/CustomerSearch";
import { dateFormat, today } from "../../../utils/dateUtils";
import { getUserName } from "../../../utils/userUtils";
import LUtils from "../../../utils/lodashUtils";

const CreatePurchaseSalesStatementPopup = ({getPurchaseSalesList, selView}) => {
    const purchaseCreateGridRef = useRef();
    const salesCreateGridRef = useRef();
    const [purchaseSalesCode, setPurchaseSalesCode] = useState('');
    const [purchaseSalesCodeName, setPurchaseSalesCodeName] = useState('');
    const [collectionCode, setCollectionCode] = useState('');
    const [collectionCodeName, setCollectionCodeName] = useState('');
    const [inputCode, setInputCode] = useState('');
    const [autoApproval, setAutoApproval] = useState(1);
    const [type, setType] = useState('');
    const [gridData, setGridData] = useState([]);


    /* 저장 */
    const callBackGridData = async ({updatedList, createdList}) => {
        const isUpdate = !LUtils.isEmpty(updatedList);
        const isCreate = !LUtils.isEmpty(createdList);
        const selViewText = selView === 'purchase' ? '매입장' : '매출장';

        const validUpdate = updatedList.every(v => !v.statementNo);
        if (!validUpdate) {
            showToast('전표가 생성된 ' + selViewText + '은 수정할 수 없습니다.');
            return;
        }

        const requiredParams = ['date', 'paymentType', 'taxType', 'remark', 'supplyPrice', 'totalPrice', 'customerNo', 'businessNumber'];
        if (createdList.some(item => !requiredParams.every(param => item[param])) || (!purchaseSalesCode || !purchaseSalesCodeName || !collectionCode || !collectionCodeName) ) {
            showToast('필수값을 모두 입력해야 합니다.');
            return;
        }
                        
        
        if (!await callConfirm('저장 하시겠습니까?')) {
            return;
        }
        
        const resultUpdatedList = updatedList.filter(item => !createdList.some(createdItem => createdItem.purchaseSalesSeq === item.purchaseSalesSeq));
        const type = selView === 'purchase' ? '2' : '1';
        const createdParam = createdList.map(v => {return {...v, purchaseSalesType: type}});

        isUpdate && await axios.put('/purchaseSales', resultUpdatedList)
        isCreate && await axios.post('/purchaseSales', createdParam);

        getPurchaseSalesList();
        showToast('저장이 완료되었습니다.');
    }

    /* 부가세 계산 */
    const calculationVat = (e) => {
        const { field } = e.colDef;
        const taxType = e.data.taxType;

        const numberColumns = ['qty', 'price', 'supplyPrice', 'vat', 'totalPrice'];
        if (LUtils.some(LUtils.values(LUtils.pick(e.data, numberColumns)), v => isNaN(v))) {
            showToast('숫자만 입력 가능합니다.');
            e.data[field] = 0;
            selView === 'purchase' ? purchaseCreateGridRef.current.api.redrawRows() : salesCreateGridRef.current.api.redrawRows();
            return false;
        } 

        if (taxType === 'A') {
            e.data.vat = Math.round(e.data.supplyPrice / 10);   // 소수점 반올림
        } else if (['B', 'C'].includes(taxType)) {
            e.data.vat = 0;
        } 

        e.data.totalPrice = Number(e.data.supplyPrice) + Number(e.data.vat);
        selView === 'purchase' ? purchaseCreateGridRef.current.api.redrawRows() : salesCreateGridRef.current.api.redrawRows();
    }

    /* 총합계 계산 */
    const calculationTotalPrice = (e) => {
        const { field } = e.colDef;
        
        const numberColumns = ['qty', 'price', 'supplyPrice', 'vat', 'totalPrice'];
        if (LUtils.some(LUtils.values(LUtils.pick(e.data, numberColumns)), v => isNaN(v))) {
            showToast('숫자만 입력 가능합니다.');
            e.data[field] = 0;
            selView === 'purchase' ? purchaseCreateGridRef.current.api.redrawRows() : salesCreateGridRef.current.api.redrawRows();
            return false;
        } 

        e.data.totalPrice = Number(e.data.supplyPrice) + Number(e.data.vat);
        selView === 'purchase' ? purchaseCreateGridRef.current.api.redrawRows() : salesCreateGridRef.current.api.redrawRows();
    }

    
    /* 계정과목 입력 */
    const inputChangeValue = async (type) => {
        setType(type);
        const result = await axios.get('statement/accounting/code', {params: {type: 'code', inputText: type === 'purchaseSalesCode' ? purchaseSalesCode : collectionCode}});
        if (result.length === 1) {
            if (type === 'purchaseSalesCode') {
                setPurchaseSalesCode(result[0].code);
                setPurchaseSalesCodeName(result[0].name);
            } else {
                setCollectionCode(result[0].code);
                setCollectionCodeName(result[0].name);
            }
        } else {
            setInputCode(type === 'purchaseSalesCode' ? purchaseSalesCode : collectionCode)
            AppStore.toggleAccountingCodePopup();
        }
    }

    /* 계정 과목 조회 팝업 클릭 시 이벤트 */
    const openAccountingCode = () => {
        AppStore.toggleAccountingCodePopup();
    }

    /* 계정 과목 팝업 callback */
    const searchAccountingCode = (data) => {
        if (type === 'purchaseSalesCode') {
            setPurchaseSalesCode(data.code);
            setPurchaseSalesCodeName(data.name);
        } else if (type === 'collectionCode') {
            setCollectionCode(data.code);
            setCollectionCodeName(data.name);
        }
    }

    /* 전표 생성 */
    const createStatement = async ({createdList, updatedList}) => {

        // const selectedList = selView === 'purchase' ? PurchaseSalesStatementStore.purchaseList.filter(v => v.isSelected) : PurchaseSalesStatementStore.salesList.filter(v => v.isSelected);
        // const updatedList = selView === 'purchase' ? PurchaseSalesStatementStore.purchaseList.filter(v => v.isUpdated) : PurchaseSalesStatementStore.salesList.filter(v => v.isSelected);
        // const createdList = selView === 'purchase' ? PurchaseSalesStatementStore.purchaseList.filter(v => v.isCreated) : PurchaseSalesStatementStore.salesList.filter(v => v.isSelected);
 
        if (createdList.length === 0) {
            showToast('전표를 입력해주세요.');
            return;
        }

        // 필수값 확인
        const requiredParams = ['date', 'paymentType', 'taxType', 'remark', 'supplyPrice', 'totalPrice', 'customerNo', 'businessNumber'];
        // if (createdList.some(item => {!requiredParams.every(param => item[param])}) || (!purchaseSalesCode || !purchaseSalesCodeName || !collectionCode || !collectionCodeName)) {
        if (createdList.some(item => !requiredParams.every(param => item[param])) || (!purchaseSalesCode || !purchaseSalesCodeName || !collectionCode || !collectionCodeName) ) {
        showToast('필수값을 모두 입력해야 합니다.');
            return;
        }

        let codeArray = [];
        const statementType = selView === 'purchase' ? '2' : selView === 'sales' ? '3' : '1';       // 1: 일반, 2: 매입, 3: 매출
        codeArray.push({type: 'purchaseSales', code: purchaseSalesCode, codeName: purchaseSalesCodeName});      // 매입/매출계정과목
        codeArray.push({type: 'collection', code: collectionCode, codeName: collectionCodeName});   // 수금계정과목
        codeArray.push({type: 'vat', 
                        code: selView === 'purchase' ? '13500' : '25500', // 부가세 계정과목코드
                        codeName: selView === 'purchase' ? '부가세대급금' : '부가세예수금'});    // 부가세 계정과목명
        
        await axios.post('/statement/purchaseSales', {statementType: statementType, autoApproval: autoApproval, purchaseSalesList: createdList, codeList: codeArray});

        showToast(`${createdList.length}개의 회계전표 생성을 완료했습니다.`);
        AppStore.toggleModal();
        getPurchaseSalesList();

        if (selView === 'sales') {
            PurchaseSalesStatementStore.salesList = [];
        } else {
            PurchaseSalesStatementStore.purchaseList = [];
        }
    }

    /* 선택 버튼 옵션 - 삭제 */
    const createStatementBtn = {
        isUsed: true,
        title: '전표생성',
        callbackFn: () => createStatement(),
        icon: 'fi-rr-add-document',
        variant: 'primary'
    };

    /* 선택 버튼 옵션 - 삭제 */
    const deleteBtn = {
        isUsed: true,
        title: '선택 삭제',
        callbackFn: () => removeSelectedRows(),
        icon: ICON_TRASH
    };

    /* 매입매출장 삭제 */
    const removeSelectedRows = async () => {
       
        if (selectedList.length === 0) {
            showToast('선택된 전표가 없습니다.');
            return;
        }

        const validSelectedList = selectedList.every(v => !v.statementNo);
        if (!validSelectedList) {
            showToast('전표가 생성된 매입장은 삭제할 수 없습니다.');
            return;
        }

        if (!await callConfirm('삭제하시겠습니까?')) {
            return;
        }

        await axios.put('/purchaseSales/delete', selectedList);
        showToast('삭제가 완료되었습니다.');
        getPurchaseSalesList();
    }

    /* 그리드에서 거래처 선택 */
    const popupCustomerInfo = (e) => {
        return (
            <div className="d-flex justify-content-between">
                <div>{e.data.customerName}</div>
                <IconBtn key={e.rowIndex} onClick={() => openCustomerSearchModal(e)}/>
            </div>
        )
    }

    /* 거래처 조회 팝업 */
    const openCustomerSearchModal = (e) => {
        AppStore.toggleCustomerSearchModal();
        setGridData(e);
    }

    /* 거래처 선택 콜백 함수 */
    const getCustomerInfo = (customerInfo) => {
        if (!customerInfo) return false;

        const list = selView === 'purchase' ? PurchaseSalesStatementStore.purchaseList : PurchaseSalesStatementStore.salesList;
        const result = list.map(v => {
            if(v.seq === gridData.data.seq) {
                v.customerNo = customerInfo.customerNo;
                v.customerName = customerInfo.customerName;
                v.businessNumber = customerInfo.businessNumber;
                LUtils.assign(v, {isUpdated: true});
            }
        });

        selView === 'purchase' ? purchaseCreateGridRef.current.api.redrawRows() : salesCreateGridRef.current.api.redrawRows();
    }

    /* 순번 만들기 */
    const addSeq = (e) => {
        const list = selView === 'purchase' ? PurchaseSalesStatementStore.purchaseList : PurchaseSalesStatementStore.salesList;

        if (selView === 'purchase') {
            for( const [ i, statement ] of PurchaseSalesStatementStore.purchaseList.entries() ) {
                statement.index = i;
                PurchaseSalesStatementStore.purchaseList[i].seq = i+1;
            }    
        } else if (selView === 'sales') {
            for( const [ i, statement ] of PurchaseSalesStatementStore.salesList.entries() ) {
                statement.index = i;
                PurchaseSalesStatementStore.salesList[i].seq = i+1;
            }    
        }

        return (
            <div className="d-flex justify-content-between">
                <div>{e.data.seq}</div>
            </div>
        )
    }

    /* 전자계산서 여부 체크박스 렌더링 */
    const setCheckbox = (e) => {
        return (
            <>
                <div className="ag-cell-wrapper">
                    <Form.Check
                        type="checkbox"
                        id="isElectronic"
                        checked={e.data.isElectronic == 1}
                        value={e.data.isElectronic == 1}
                        onChange={(v) => checkedHandler(e, v) }
                        className="ag-input-field-input ag-checkbox-input"
                    /> 
                </div>
            </>

        )
    }

    /* 전자계산서 여부 체크박스 핸들러 */
    const checkedHandler = (e, v) => {
        let checked = v.target.checked;
        const list = selView === 'purchase' ? PurchaseSalesStatementStore.purchaseList : PurchaseSalesStatementStore.salesList;
        list.map(item => {
            if(item.seq === e.data.seq) {
                item.isElectronic = checked ? 1 : 0;
                item.isUpdated = true;
            }
        })
        selView === 'purchase' ? purchaseCreateGridRef.current.api.redrawRows() : salesCreateGridRef.current.api.redrawRows();
    }


    const addRowInfo = {seq: '', date: today(), paymentType: '', taxType: '', summary: '', supplyPrice: '', vat: '', totalPrice: ''
                        , customerName: '', customerNo: '', businessNumber: '', addStatement: ''};

    return (
        <>
            {/* <Modal show={AppStore.isOpenCreatePurchaseSalesStatementPopup}
                   onHide={() => AppStore.toggleCreatePurchaseSalesStatementPopup()}
                //    onExit={() => inputInit}
                   centered> */}
                <CContainer search>
                    <Subject>{selView === 'purchase' ? '매입전표 분개정보' : '매출전표 분개정보'}</Subject>
                    {(selView === 'purchase') && 
                            <Row>
                                <CCol lg={4}>
                                    <CInputDoubleGroup
                                        label={'매입 계정과목'}
                                        labelId={'purchaseSalesCodeName'}
                                        value={purchaseSalesCodeName}
                                        labelId2={'purchaseSalesCode'}
                                        value2={purchaseSalesCode}
                                        onChange={v => setPurchaseSalesCodeName(v.target.value)}
                                        onChange2={v => setPurchaseSalesCode(v.target.value)}
                                        onCallbackBtn={() => {setType('purchaseSalesCode'), openAccountingCode()}}
                                        enterKeyHint2={() => inputChangeValue('purchaseSalesCode')}
                                        />
                                </CCol>
                                <CCol lg={4}>
                                    <CInputDoubleGroup
                                        label={'수금 계정과목'}
                                        labelId={'collectionCodeName'}
                                        value={collectionCodeName}
                                        labelId2={'collectionCode'}
                                        value2={collectionCode}
                                        onChange={v => setCollectionCodeName(v.target.value)}
                                        onChange2={v => setCollectionCode(v.target.value)}
                                        onCallbackBtn={() => {setType('collectionCode'), openAccountingCode()}}
                                        enterKeyHint2={() => inputChangeValue('collectionCode')}
                                        />
                                </CCol>
                                <CCol lg={4}>
                                    <CInputDoubleGroup
                                        label={'부가세대급금'}
                                        labelId={'vatCodeName'}
                                        value={'부가세대급금'}
                                        labelId2={'vatCode'}
                                        value2={'13500'}
                                        disabled={true}
                                        disabled2={true}
                                        />
                                </CCol>
                            </Row>
                        }
                        {(selView === 'sales') && 
                            <Row>
                            <CCol lg={4}>
                                <CInputDoubleGroup
                                    label={'매출 계정과목'}
                                    labelId={'purchaseSalesCodeName'}
                                    value={purchaseSalesCodeName}
                                    labelId2={'purchaseSalesCode'}
                                    value2={purchaseSalesCode}
                                    onChange={v => setPurchaseSalesCodeName(v.target.value)}
                                    onChange2={v => setPurchaseSalesCode(v.target.value)}
                                    onCallbackBtn={() => {setType('purchaseSalesCode'), openAccountingCode()}}
                                    enterKeyHint2={() => inputChangeValue('purchaseSalesCode')}
                                    />
                            </CCol>
                            <CCol lg={4}>
                                <CInputDoubleGroup
                                    label={'수금 계정과목'}
                                    labelId={'collectionCodeName'}
                                    value={collectionCodeName}
                                    labelId2={'collectionCode'}
                                    value2={collectionCode}
                                    onChange={v => setCollectionCodeName(v.target.value)}
                                    onChange2={v => setCollectionCode(v.target.value)}
                                    onCallbackBtn={() => {setType('collectionCode'), openAccountingCode()}}
                                    enterKeyHint2={() => inputChangeValue('collectionCode')}
                                    />
                            </CCol>
                            <CCol lg={4}>
                                <CInputDoubleGroup
                                    label={'부가세예수금'}
                                    labelId={'vatCodeName'}
                                    value={'부가세예수금'}
                                    labelId2={'vatCode'}
                                    value2={'25500'}
                                    disabled={true}
                                    disabled2={true}
                                    />
                            </CCol>
                        </Row>
                        }
                            <Row>
                                <Col className='d-flex flex-row-reverse'>
                                    <Form.Check
                                        type="switch"   /*type="checkbox"*/
                                        id="autoApproval"
                                        label="자동 승인"
                                        style={{marginTop: 3}}
                                        checked={autoApproval === 1}
                                        value={autoApproval === 1 ? true : false}
                                        onChange={v=> setAutoApproval(v.target.checked ? 1 : 0)}
                                    />
                                </Col>
                            </Row>
                </CContainer>
                <CContainer>
                        {/* {selView === 'sales' && */}
                        <AgGridContainer 
                            gridRef={selView === 'sales' ? salesCreateGridRef : purchaseCreateGridRef}
                            gridTitle={selView === 'sales' ? '매출전표' : '매입전표'}
                            height={50}
                            rowData={selView === 'sales' ? PurchaseSalesStatementStore.salesList : PurchaseSalesStatementStore.purchaseList}
                            columnDefs={[
                                {field: 'salesSeq', headerName: '번호', hide: true},
                                {field: 'seq', headerName: '순번', cellRenderer: e => addSeq(e), width: 59},
                                {field: 'isStatement', headerName: '생성', valueFormatter: v => v.data.statementNo ? '완료' : '', width: 60, cellClass: 'ag-grid-column-complete'},
                                {field: 'date', headerName: '날짜 (YYYY-MM-DD)', editable: true, headerClass: 'grid-column-required', width: 165},
                                {
                                    field: 'paymentType', 
                                    headerName: '결제유형',
                                    editable: true,
                                    valueFormatter: v => findCommKrnm(v.value, '17'),
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values: getCommcode('17').map(v => v.value),
                                    },
                                    headerClass: 'grid-column-required',
                                    width: 95
                                },
                                {
                                    field: 'taxType', 
                                    headerName: '부가세',
                                    editable: true,
                                    valueFormatter: v => findCommKrnm(v.value, '45'),
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values: getCommcode('45').map(v => v.value),
                                    },
                                    onCellValueChanged: calculationVat,
                                    headerClass: 'grid-column-required',
                                    width: 70
                                },
                                {field: 'remark', headerName: '적요', editable: true, headerClass: 'grid-column-required', width: 200},
                                // {field: 'qty', headerName: '수량', editable: true, width: 60},
                                // {field: 'price', headerName: '단가', editable: true, width: 90},
                                {field: 'supplyPrice', headerName: '공급가액', cellClass: 'ag-grid-money-align', editable: true, onCellValueChanged: calculationVat, valueFormatter: v => v.value?.toLocaleString(), headerClass: 'grid-column-required', width: 125},
                                {field: 'vat', headerName: '부가세액', cellClass: 'ag-grid-money-align', onCellValueChanged: calculationTotalPrice, valueFormatter: v => v.value?.toLocaleString(), editable: true, headerClass: 'grid-column-required', width: 115},
                                {field: 'totalPrice', headerName: '합계', cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), headerClass: 'grid-column-required', width: 125},
                                {field: 'customerName', headerName: '거래처명', cellRenderer: e => popupCustomerInfo(e), headerClass: 'grid-column-required', width: 170},
                                {field: 'customerNo', headerName: '거래처번호', headerClass: 'grid-column-required', width: 125},
                                {field: 'businessNumber', headerName: '사업자번호', headerClass: 'grid-column-required', width: 135},
                                {
                                    field: 'isElectronic', 
                                    headerName: '전자', 
                                    cellRenderer: v => setCheckbox(v),
                                    width: 60}, // 전자계산서인지 체크
                                {field: 'createdDate', headerName: '등록일자', valueFormatter: v => dateFormat(v.value), width: 135},
                                {field: 'creator', headerName: '등록자', valueFormatter: v => getUserName(v.value), width: 135},    
                                {field: 'accountingDate', headerName: '회계처리일자', valueFormatter: v => dateFormat(v.value), width: 135},    
                            ]}
                            seqColumn={'agId'}
                            // removeCallback={removeCallback}
                            isCheckBox={true}
                            useCreated={true}
                            useUpdated={true}
                            addRowInfo={addRowInfo}
                            callBackGridData={createStatement}
                            // customBtnInfo={[createStatementBtn]}
                            // isCellCustom={true}
                        />
                    {/* } */}
                    {/* {selView === 'purchase' &&
                        <AgGridContainer 
                            gridRef={purchaseCreateGridRef}
                            gridTitle={'매입전표'}
                            height={50}
                            rowData={PurchaseSalesStatementStore.purchaseList}
                            columnDefs={[
                                {field: 'purchaseSalesSeq', headerName: '번호', hide: true},
                                {field: 'seq', headerName: '순번', cellRenderer: e => addSeq(e), width: 59},
                                // {field: 'seq', headerName: '순번', valueGetter: 'node.rowIndex', hide: true},
                                {field: 'isStatement', headerName: '생성', valueFormatter: v => v.data.statementNo ? '완료' : '', width: 60, cellClass: 'ag-grid-column-complete'},
                                {field: 'date', headerName: '날짜 (YYYY-MM-DD)', editable: true, headerClass: 'grid-column-required', width: 165},
                                {
                                    field: 'paymentType', 
                                    headerName: '결제유형',
                                    editable: true,
                                    valueFormatter: v => findCommKrnm(v.value, '17'),
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values: getCommcode('17').map(v => v.value),
                                    },
                                    headerClass: 'grid-column-required',
                                    width: 95
                                },
                                {
                                    field: 'taxType', 
                                    headerName: '세금',
                                    editable: true,
                                    valueFormatter: v => findCommKrnm(v.value, '45'),
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values: getCommcode('45').map(v => v.value),
                                    },
                                    onCellValueChanged: calculationVat,
                                    headerClass: 'grid-column-required',
                                    width: 60
                                },
                                {field: 'remark', headerName: '적요', editable: true, headerClass: 'grid-column-required', width: 220},
                                // {field: 'qty', headerName: '수량', editable: true, width: 60},
                                // {field: 'price', headerName: '단가', editable: true, width: 90},
                                {field: 'supplyPrice', headerName: '공급가액', cellClass: 'ag-grid-money-align', editable: true, onCellValueChanged: calculationVat, valueFormatter: v => v.value?.toLocaleString(), headerClass: 'grid-column-required', width: 135},
                                {field: 'vat', headerName: '부가세액', cellClass: 'ag-grid-money-align', onCellValueChanged: calculationTotalPrice, valueFormatter: v => v.value?.toLocaleString(), editable: true, headerClass: 'grid-column-required', width: 115},
                                {field: 'totalPrice', headerName: '합계', cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), headerClass: 'grid-column-required', width: 135},
                                {field: 'customerName', headerName: '거래처명', cellRenderer: e => popupCustomerInfo(e), headerClass: 'grid-column-required', width: 170},
                                {field: 'customerNo', headerName: '거래처번호', editable: true, headerClass: 'grid-column-required', width: 125},
                                {field: 'businessNumber', headerName: '사업자번호', editable: true, headerClass: 'grid-column-required', width: 135},
                                {
                                    field: 'isElectronic', 
                                    headerName: '전자', 
                                    cellRenderer: v => setCheckbox(v),
                                    width: 60}, // 전자계산서인지 체크
                                {field: 'createdDate', headerName: '등록일자', valueFormatter: v => dateFormat(v.value), editable: true, width: 135},
                                {field: 'creator', headerName: '등록자', valueFormatter: v => getUserName(v.value), editable: true, width: 135},    
                                {field: 'resolutionDate', headerName: '결의일자', valueFormatter: v => dateFormat(v.value), editable: true, width: 135},    
                            ]}
                            seqColumn={'agId'}
                            // removeCallback={removeCallback}
                            isCheckBox={true}
                            useCreated={true}
                            useUpdated={true}
                            addRowInfo={addRowInfo}
                            callBackGridData={createStatement}
                            // callBackGridData={callBackGridData}
                            // customBtnInfo={[createStatementBtn]}
                            // isCellCustom={true}
                        />
                    } */}
                {/* <Row>
                    <Col className='d-flex flex-row-reverse mt-3'>
                        <CCancelBtn style={{width: 90}}
                                    title={'취소'}
                                    onClick={() => AppStore.toggleCreatePurchaseSalesStatementPopup()}
                        />
                        <CIconBtn style={{width: 110}}
                                  title={'전표 생성'}
                                  icon={''}
                                  variant={'primary'}
                                  onClick={createStatement}
                        />
                    </Col>
                </Row> */}
            </CContainer>
                
                
                    {/* <Button variant="secondary" onClick={() => AppStore.toggleCreatePurchaseSalesStatementPopup()}>
                        취소
                    </Button>
                    <Button variant="primary" onClick={createStatement}>
                        처리
                    </Button>
                </Modal.Footer> */}
            {/* </Modal> */}
            <AccountingCodeSearch callbackFn={searchAccountingCode} inputCode={inputCode} />
            <CustomerSearch callbackFn={getCustomerInfo} />
        </>
    )

}

export default observer(CreatePurchaseSalesStatementPopup);