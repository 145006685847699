import React, {forwardRef, useState, useEffect, useRef} from "react";
import axios from "axios";

import "./reportTemplate.css";
import { decrypt } from "../../common/utils";
import { findCommKrnm } from "../../utils/commcode";
import { ReturnAskListStore } from "../../store/return/ReturnAskListStore";

const ReportReturnAskTemplate =(props, ref)=> {
    const didMount = useRef(false);

    const data = ReturnAskListStore.data;
    const {returnAskItemList} = ReturnAskListStore;
    const [contract, setContract] = useState([]);

    useEffect(() => {
        if(props.contractNo) getContract();
    }, [props.contractNo]);

    /* 계약 조회 */
    const getContract = async () => {
        //console.log(data);
        const result = await axios.get(`/contract/${props.contractNo}`);
        setContract(result);
    }
   
    return (
        <div ref={ref} className="report-template" >
            <h2 style={{textAlign: 'center', marginBottom: 20}}>회수 및 배송의뢰서</h2>
            <div style={{display: 'flex'}}><i className="fi fi-rr-check" style={{marginRight: 5}}/><h5>진행 상태</h5></div>
            <table className="table" style={{borderWidth: 1}}>
                <tbody>
                    <tr>
                        <td className='p-1 border'>회수접수자</td>
                        <td className='p-1 border' style={{width: '35%'}}>{data.creatorName}</td>
                        <td className='p-1 border'>회수접수일시</td>
                        <td className='p-1 border' style={{width: '35%'}}>{data.returnAskDate}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border'>비고</td>
                        <td className='p-1 border' colSpan={3}>{data.returnAskRemark}</td>
                    </tr>
                </tbody>
            </table>
            <br/>
            <div style={{display: 'flex'}}><i className="fi fi-rr-check" style={{marginRight: 5}}/><h5>계약 정보</h5></div>
            <table className="table" style={{borderWidth: 1}}>
                <tbody>
                    <tr>
                        <td className='p-1 border'>계약자</td>
                        <td className='p-1 border' style={{width: '35%'}}>{data.saleUserName}</td>
                        <td className='p-1 border'>계약번호</td>
                        <td className='p-1 border' style={{width: '35%'}}>{contract.contractNo}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border'>회수의뢰번호</td>
                        <td className='p-1 border'>{data.returnAskSeq}</td>
                        <td className='p-1 border'>계약유형</td>
                        <td className='p-1 border'>{findCommKrnm(contract.contractType, '1')}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border'>계약일</td>
                        <td className='p-1 border'>{contract.contractDate}</td>
                        <td className='p-1 border'>개시일</td>
                        <td className='p-1 border'>{contract.contractStartDate}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border'>종료일</td>
                        <td className='p-1 border'>{contract.contractEndDate}</td>
                    </tr>
                </tbody>
            </table>
            <br/>
            <div style={{display: 'flex'}}><i className="fi fi-rr-check" style={{marginRight: 5}}/><h5>회수 의뢰 내역</h5></div>
            <table className="table" style={{borderWidth: 1, textAlign: 'center'}}>
                <thead>
                    <tr>
                        <th className='border' style={{width: '6%'}}>순번</th>
                        <th className='border' style={{width: '10%'}}>자산번호</th>
                        <th className='border' style={{width: '40%'}}>제품명 / 모델명</th>
                        <th className='border' style={{width: '6%'}}>수량</th>
                        <th className='border' style={{width: '18%'}}>시리얼</th>
                        <th className='border' style={{width: '10%'}}>선반</th>
                    </tr>
                </thead>
                <tbody>
                    {returnAskItemList.map(v => {
                        return(
                            <tr key={v.seq}>
                                <td className='p-1 border' rowSpan='1'>{v.seq}</td>
                                <td className='p-1 border'>{v.assetNo}</td>
                                <td className='p-1 border' style={{textAlign: 'left'}}>
                                    {v.productName} / {v.model} 
                                </td>
                                <td className='p-1 border'>{v.qty}</td>
                                <td className='p-1 border'>{v.serialNo}</td>
                                <td className='p-1 border'>{}</td>
                            </tr>
                       )
                    })}
                </tbody>
            </table>
            <br/>
            <div style={{display: 'flex'}}><i className="fi fi-rr-check" style={{marginRight: 5}}/><h5>배송 내역</h5></div>
            <table className="table" style={{borderWidth: 1}}>
                <tbody>
                    <tr>
                        <td className='p-1 border'>배송담당자</td>
                        <td className='p-1 border' style={{width: '20%'}}>{}</td>
                        <td className='p-1 border'>회수 희망일시</td>
                        <td className='p-1 border' style={{width: '20%'}}>{data.returnExpectDate}</td>
                        <td className='p-1 border'>배송방법</td>
                        <td className='p-1 border' style={{width: '20%'}}>{findCommKrnm(data.shipType, '4')}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' rowSpan={2} style={{verticalAlign: 'middle'}}>업체담당자</td>
                        <td className='p-1 border' rowSpan={2} style={{textAlign: 'center', verticalAlign: 'middle'}}>{data.receiveUserName}</td>
                        <td className='p-1 border'>전화번호</td>
                        <td className='p-1 border'>{data.telephone}</td>
                        <td className='p-1 border'>핸드폰</td>
                        <td className='p-1 border'>{decrypt(data.phoneNumber)}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border'>주소</td>
                        <td className='p-1 border' colSpan={3}>({data.postCode}) {data.address} {data.addressDetail}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border'>배송 요청사항</td>
                        <td className='p-1 border' colSpan={5}>{data.shipRemark}</td>
                    </tr>
                </tbody>
            </table>
            <br/>
        </div>
    );
};

export default forwardRef(ReportReturnAskTemplate);
