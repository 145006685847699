import { observer } from "mobx-react-lite"
import { CAddBtn, CCol, CContainer, CInputGroup, CSearchBtn } from "../../components/CustomContainer";
import axios from "axios";
import { ProductBrandStore } from "../../store/product/ProductBrandStore";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { getCommcode } from "../../utils/commcode";
import { showToast } from "../../common/utils";
import { callConfirm } from "../../utils";

const BrandCodeListSearch = ({ getBrandList, searchBrandName, setSearchBrandName, selectLgCode, setSelectLgCode }) => {
    const [lgCodeList, setLgCodeList] = useState([]);
    const [selectLgCodeName, setSelectLgCodeName] = useState('');
    const [brandName, setBrandName] = useState('');
    const [seq, setSeq] = useState('');
    const [useYn, setUseYn] = useState('Y');

    useEffect(() => {
        if(ProductBrandStore.lgCodeList.length === 0) {
            getProductGroupList();
        } 
    }, []);

    /* product group 조회 -> 대분류 배열에 담아주기 */
    const getProductGroupList = async () => {
        const result = await axios.get('/product/group/all', { params: { isUsed: 1 } });
        ProductBrandStore.setLgCodeList(result.filter(v => v.parentSeq === null && v.productGroupSeq !== 450));   // 위탁임차 제외
    }

    const insertBrandCode = async () => {

        // if (selectLgCode === null || selectLgCode === '' || selectLgCode === 0) {
        //     showToast('대분류를 선택해주세요.');
        //     return;
        // }
        if (brandName === '') {
            showToast('제조사명을 입력해주세요.');
            return;
        }
        if (seq === '') {
            showToast('순번을 입력해주세요.');
            return;
        }
        if (!await callConfirm('제조사를 추가하시겠습니까?')) {
            return;
        }

        const params = {commKrnm: brandName, seq: seq, useYn: useYn}

        await axios.post("/product/brand", params);
        showToast('추가가 완료되었습니다.');
        await getBrandList();

        setBrandName('');
        setSeq('');
        setUseYn('Y');
    }

    const searchBrandList = () => {
        getBrandList();
    }


    return (
        <>
            <CContainer search>
                {/* <Row>
                    <CCol lg={12}>
                        <div className="btn-group btn-group-toggle pt-1">
                            {ProductBrandStore.lgCodeList.map((code, i) => (
                                <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}} key={i+1}>
                                    <Form.Check
                                        type="radio" value={code.productGroupSeq} name={code.productGroupName}
                                        checked={Number(selectLgCode) === Number(code.productGroupSeq) ? true : false}
                                        onChange={v => {
                                            setSelectLgCode(Number(v.target.value) === Number(code.productGroupSeq) ? code.productGroupSeq : 0);
                                            setSelectLgCodeName(code.productGroupName);
                                            getBrandList(code.productGroupSeq);
                                        }}
                                        style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        disabled={false}
                                    />
                                    <span>{code.productGroupName}</span>
                                </label>
                            ))}
                        </div>
                    </CCol>
                </Row> */}
                <Row>
                    <Col lg={3}>
                        <CInputGroup
                            label={'제조사명 조회'}
                            labelId={'searchBrandName'}
                            value={searchBrandName}
                            onChange={v => setSearchBrandName(v.target.value)}
                            enterKeyHint={searchBrandList}
                            // disabled={isCompleted}
                            // btnDisabled={false}
                        />
                    </Col>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={searchBrandList} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer search>
                <Row>
                    {/* <Col lg={2}>
                        <CInputGroup
                            label={'대분류'}
                            labelId={'lgCodeName'}
                            value={selectLgCodeName}
                            disabled={true}
                            // btnDisabled={false}
                            // labelClassName='input-required'
                        />
                    </Col> */}
                    <Col lg={3}>
                        <CInputGroup
                            label={'제조사명'}
                            labelId={'brandName'}
                            value={brandName}
                            onChange={v => setBrandName(v.target.value)}
                            // disabled={isCompleted}
                            // btnDisabled={false}
                            enterKeyHint={insertBrandCode}
                            labelClassName='input-required'
                        />
                    </Col>
                    <Col lg={2}>
                        <CInputGroup
                            label={'순번'}
                            labelId={'seq'}
                            value={seq}
                            onChange={v => setSeq(v.target.value)}
                            // disabled={isCompleted}
                            // btnDisabled={false}
                            enterKeyHint={insertBrandCode}
                            labelClassName='input-required'
                        />
                    </Col>
                    <Col lg={2}>
                        <Form.Check
                            type="switch"
                            id="useYn"
                            label="사용여부"
                            style={{marginTop: 4}}
                            checked={useYn === 'Y'}
                            onChange={v => setUseYn(v.target.checked ? 'Y' : 'N')}
                        />
                    </Col>
                    <Col className='d-flex flex-row-reverse'>
                        <CAddBtn onClick={insertBrandCode}/>
                    </Col>
                </Row>
            </CContainer>
        </>
    );
}

export default observer(BrandCodeListSearch);