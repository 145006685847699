import { observable } from "mobx";

export const CompanyStore = observable({
    data: {
        companyCode: '',
        companyName: '',
        owner: '',
        establishmentDate: '',
        businessNumber: '',
        companyType: '',
        postcode: '',
        address: '',
        addressDetail: '',
        corporationNumber: '',
        industryType: '',
        businessConditions: '',
        signatureImageUrl: '',
        signatureImageFileName: '',
        logoImageUrl: '',
        logoImageFileName: '',
        bankbookImageUrl: '',
        bankbookImageFileName: '',
        businessLicenseUrl: '',
        businessLicenseFileName: '',
    
        startDate: '',  // company_option 테이블에 있음
        endDate: '',
        contractDocUrl: '',    // 테이블에 추가 필요
        contractDocFileName: '',
        sessionCount: 3,
        billCount: 150,
        smsCount: 1000,
        bankbookCount: 1,
        corporateCardCount: 3,
        isTaxBill: 0,
        isSendReleaseAskSms: 0,
    
        email: '',
        host: '',
        password: '',
    
        id: '',
        phoneNumber: '',
    
        lastSaveTimeAccount: '',
        lastSaveTimeCard: '',
        lastSaveTimeBill: '',

        // 법인 카드
        cardCode: '',
        cardNumber: '',
        expirationYear: '',
        expirationMonth: '',
        cardPassword: '',
    },

    init(){
        this.data.companyCode = '';
        this.data.companyName = '';
        this.data.owner = '';
        this.data.establishmentDate = '';
        this.data.businessNumber = '';
        this.data.companyType = '';
        this.data.postcode = '';
        this.data.address = '';
        this.data.addressDetail = '';
        this.data.corporationNumber = '';
        this.data.industryType = '';
        this.data.businessConditions = '';
        this.data.signatureImageUrl = '';
        this.data.signatureImageFileName = '';
        this.data.logoImageUrl = '';
        this.data.logoImageFileName = '';
        this.data.bankbookImageUrl = '';
        this.data.bankbookImageFileName = '';
        this.data.businessLicenseUrl = '';
        this.data.businessLicenseFileName = '';

        this.data.startDate = '';
        this.data.endDate = '';
        this.data.contractDocUrl = '';
        this.data.contractDocFileName = '';
        this.data.sessionCount = 5;
        this.data.billCount = 300;
        this.data.smsCount = 1000;
        this.data.bankbookCount = 2;
        this.data.corporateCardCount = 0;
        this.data.isTaxBill = 0;
        this.data.isSendReleaseAskSms = 0;
    
        this.data.email = '';
        this.data.host = '';
        this.data.password = '';
    
        this.data.id = '';
        this.data.phoneNumber = '';
    
        this.data.lastSaveTimeAccount = '';
        this.data.lastSaveTimeCard = '';
        this.data.lastSaveTimeBill = '';
    },
})
