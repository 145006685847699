import React, { useEffect } from "react";
import { observer } from "mobx-react-lite"
import { Row, Col } from "react-bootstrap";

import { CCol, CContainer, CSearchBtn, CalendarGroup, CInputGroup, CIconBtn } from "../../components/CustomContainer";
import { MaintenanceStore } from "../../store/asset/MaintenanceStore";
import { AppStore } from "../../store/AppStore";
import UserSearch from "../../components/searchModal/UserSearch";

const MaintenanceSearch = ({getMaintenanceList, searchUser, setSearchUser}) => {

    const store = MaintenanceStore;
    
    /* user 팝업 열기 */
    const openSearchUserPopup = () => {
        setSearchUser('maintenance');
        AppStore.toggleUserSearchModal();
    }

    /* user 팝업 콜백 */
    const searchUserInfo = (data) => {
        if(store.status === 'list' && data) {
            store.search.mechanic = data.userSeq;
            store.search.mechanicName = data.name;
        }else if(store.status === 'add' && data) {
            store.detailInput.mechanic = data.userSeq;
            store.detailInput.mechanicName = data.name;
        }
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <Row>
                        <CCol lg={2}>
                            <CalendarGroup label={'시작일'}
                                            asSingle={true}
                                            value={{
                                                startDate: store.search.startDate,
                                                endDate: store.search.startDate
                                            }}
                                            onChange={v => {store.search.startDate = v.startDate}}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <CalendarGroup label={'종료일'}
                                            asSingle={true}
                                            value={{
                                                startDate: store.search.endDate,
                                                endDate: store.search.endDate
                                            }}
                                            onChange={v => {store.search.endDate = v.endDate}}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <CInputGroup label={'정비자'} labelId={'mechanic'}
                                        value={store.search.mechanicName}
                                        onChange={v => {store.search.mechanic = '', store.search.mechanicName = v.target.value}}
                                        onCallbackBtn={()=> openSearchUserPopup()}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <CInputGroup label={'자산번호'} labelId={'assetNo'}
                                            value={store.search.assetNo}
                                            onChange={v => {store.search.assetNo = v.target.value}}
                                            enterKeyHint={()=> getMaintenanceList()}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <CInputGroup label={'관리번호'} labelId={'managementNo'}
                                            value={store.search.managementNo}
                                            onChange={v => {store.search.managementNo = v.target.value}}
                                            enterKeyHint={()=> getMaintenanceList()}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <CInputGroup label={'AS번호'} labelId={'asSeq'}
                                            value={store.search.asSeq}
                                            onChange={v => {store.search.asSeq = v.target.value}}
                                            enterKeyHint={()=> getMaintenanceList()}
                            />
                        </CCol>
                       
                        <Col className='d-flex flex-row-reverse'>
                            <CSearchBtn style={{width: 100}} onClick={getMaintenanceList} />
                            <CIconBtn title={'정비등록'}
                                    style={{width: 120}}
                                    icon={'fi-rs-user-gear'}
                                    onClick={() => {store.status = 'add', AppStore.toggleModal()}}
                            />
                        </Col>
                    </Row>
                </Row>
            </CContainer>
            <UserSearch callbackFn={searchUserInfo} search={searchUser}/>
        </>
    )
}

export default observer(MaintenanceSearch);
