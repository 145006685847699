import React from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";

import { CContainer, CInputGroup, CCol, CSearchBtn, CAddBtn, CSelectGroup } from '../../components/CustomContainer';
import { getCommcode } from "../../utils/commcode";
import { CardManagementStore } from "../../store/accounting/CardManagementStore";

const CardManagementSearch = (props) => {
    const {getCardList} = props;
    const store = CardManagementStore;

    /* 계좌 등록 버튼 클릭 시 작성칸 비우기  */
    const initCard = () => {
        store.dataInit();
    }

    return (
        <CContainer search>
            <Row>
                <CCol lg={2}>
                    <CSelectGroup
                        label={'카드사'} labelId={'cardCode'}
                        options={[{ value: '', name: '전체' }].concat(getCommcode('44'))}
                        value={store.search.cardCode || ''}
                        onChange={v => store.search.cardCode = v.target.value} />
                </CCol>
                <CCol lg={3}>
                    <CInputGroup
                        label={'카드번호'}
                        labelId={'cardNumber'}
                        placeholder={'-를 제외하고 입력하세요'}
                        value={store.search.cardNumber}
                        onChange={v => store.search.cardNumber = v.target.value}
                    />
                </CCol>
                <CCol lg={2}>
                    <CInputGroup
                        label={'별칭'}
                        placeholder={'별칭'}
                        value={store.search.cardAlias}
                        onChange={v => store.search.cardAlias = v.target.value}
                    />
                </CCol>
                {/* <CCol lg={2}>
                    <CSelectGroup
                        label={'사용여부'} labelId={'isUsed'}
                        options={[
                            {name: '사용', value: 1},
                            {name: '미사용', value: 0},
                        ]}
                        value={store.search.isUsed}
                        onChange={v => store.search.isUsed = v.target.value}
                    />
                </CCol> */}
            </Row>
            <Row>
                <Col className='d-flex flex-row-reverse'>
                    <CSearchBtn onClick={getCardList} />
                    <CAddBtn title={'카드등록'} style={{ width: 90 }} onClick={initCard} />
                </Col>
            </Row>
        </CContainer>
    );
};

export default observer(CardManagementSearch);
