import React, {forwardRef, useEffect, useState} from "react";

import {EstimateDetailStore} from "../../store/contract/EstimateDetailStore";
import "./reportTemplate.css";
import { decrypt } from "../../common/utils";
import { findCommKrnm } from "../../utils/commcode";
import {Image} from "react-bootstrap";
import {dateFormat} from "../../utils/dateUtils";

const ReportTemplate =(props, ref)=> {
    const { estimateDetail, estimateProductList } = EstimateDetailStore;
    const [totalRentPrice, setTotalRentPrice] = useState(0);
    const [shortTermRent, setShortTernRent] = useState(true);    //단기렌탈

    useEffect(()=> {
        const sumRentPrice = estimateProductList.reduce((total, v) => total + v.totalRentPrice, 0);
        setTotalRentPrice(sumRentPrice);

        if(estimateDetail.rentMonth > 0){
            setShortTernRent(false);
        }

    }, [estimateDetail, estimateProductList])


    return (
        <div ref={ref} className="report-template">
            <div style={{marginBottom: 30}}>
                <h1 style={{textAlign: 'center', marginBottom: 20}}>견 적 서</h1>
                {/* {estimateDetail.logoImageUrl &&  <Image style={{top: 25, right: 20, width: 120, position: 'absolute'}} src={estimateDetail.logoImageUrl} alt={"logo"}/>} */}
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <span style={{float: 'left', fontSize: 16, fontWeight: 'bold'}}>{estimateDetail.companyName}</span>
                <span style={{float: 'left', fontSize: 14, fontWeight: 'bold'}}>견적 유효기간 2주</span>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 20}}>
                <span style={{fontSize: 14, }}>{estimateDetail.companyAddress}</span>
                <span style={{fontSize: 14, fontWeight: 'bold'}}>견적일자 : {dateFormat(estimateDetail.createdDate)}</span>
            </div>
            <table className="table" style={{borderWidth: 1}}>
                <thead />
                <tbody>
                <tr>
                    <td className='p-1 border' align={'center'} valign={'middle'} colSpan={2} rowSpan={2} style={{width: 200}}><b>{estimateDetail.customerName}</b></td>
                    <td className='p-1 double-border' align={'center'} valign={'middle'} rowSpan={2} style={{width:'5%'}}>귀중</td>
                    <td className='p-1 border' align={'center'} valign={'middle'}>상호</td>
                    <td className='p-1 border' valign={'middle'}><span style={{paddingLeft: 20}}>{estimateDetail.companyName}</span></td>
                    <td className='p-1 border' rowSpan={5} style={{width: '10%'}}>
                        {estimateDetail.signatureImageUrl && <div style={{display: 'flex', justifyContent: 'center'}}><Image style={{paddingTop: 30, width: '50%'}} src={estimateDetail.signatureImageUrl} alt={"sign"}/></div>}
                    </td>
                </tr>
                <tr>
                    <td className='p-1 border' align={'center'}>대표자</td>
                    <td className='p-1 border'><span style={{paddingLeft: 20}}>{estimateDetail.owner}</span></td>
                </tr>
                <tr>
                    <td className='p-1 border' align={'center'} style={{width: 80}}>담당자</td>
                    <td className='p-1 double-border' colSpan={2}><span style={{paddingLeft: 20}}>{estimateDetail.managerName}님</span></td>
                    <td className='p-1 border' align={'center'} style={{width: 80}}>견적담당자</td>
                    <td className='p-1 border'><span style={{paddingLeft: 20}}>{estimateDetail.saleUserName}</span></td>
                </tr>
                <tr>
                    <td className='p-1 border' align={'center'} style={{width: 80}}>연락처</td>
                    <td className='p-1 double-border' colSpan={2}><span style={{paddingLeft: 20}}>{estimateDetail.billingTelephone ?? decrypt(estimateDetail.billingPhoneNumber)}</span></td>
                    <td className='p-1 border' align={'center'} style={{width: 80}}>연락처</td>
                    <td className='p-1 border'><span style={{paddingLeft: 20}}>{estimateDetail.telephone ?? decrypt(estimateDetail.phoneNumber)}</span></td>
                </tr>
                <tr>
                    <td className='p-1 border' align={'center'} style={{width: 80}}>E-mail</td>
                    <td className='p-1 double-border' colSpan={2}><span style={{paddingLeft: 20}}>{estimateDetail.billingEmail}</span></td>
                    <td className='p-1 border' align={'center'} style={{width: 80}}>E-mail</td>
                    <td className='p-1 border'><span style={{paddingLeft: 20}}>{estimateDetail.email}</span></td>
                </tr>
                </tbody>
            </table>

            <br/>
            <h6>견적 품목 내역</h6>
            <table className="table" style={{borderWidth: 1, textAlign: 'center'}}>
                <thead>
                    {props.mode === 'contract' ? (
                        <>
                            <tr>
                                <th className='border' style={{width: 50}} valign={'middle'} rowSpan={2}>항목</th>
                                <th className='border' style={{width: shortTermRent ? 150 : 130}} valign={'middle'} rowSpan={2}>품목</th>
                                <th className='border' style={{width: shortTermRent ? 180 : 150}} valign={'middle'} rowSpan={2}>설명</th>
                                <th className='border' style={{width: 160}} valign={'middle'} colSpan={2}>기간</th>
                                <th className='border' style={{width: 60}} valign={'middle'} rowSpan={2}>수량</th>
                                <th className='border' style={{width: 90}} valign={'middle'} rowSpan={2}>대당 월렌탈료</th>
                                <th className='border' style={{width: 90}} valign={'middle'} rowSpan={2}>대당 일렌탈료</th>
                                <th className='border' style={{width: 110}} valign={'middle'} rowSpan={2}>총렌탈료</th>
                            </tr>
                            <tr>
                                <th className='border' valign={'middle'}>월</th>
                                <th className='border' valign={'middle'}>일</th>
                            </tr>
                        </>
                    ) : (
                        <>
                            <tr>
                                <th className='border' style={{width: 50}} valign={'middle'} rowSpan={2}>항목</th>
                                <th className='border' style={{width: shortTermRent ? 200 : 180}} valign={'middle'} rowSpan={2}>품목</th>
                                <th className='border' style={{width: shortTermRent ? 250 : 200}} valign={'middle'} rowSpan={2}>설명</th>
                                <th className='border' style={{width: 100}} valign={'middle'} colSpan={3}>기간</th>
                                <th className='border' style={{width: 90}} valign={'middle'} rowSpan={2}>대당 월렌탈료</th>
                            </tr>
                            <tr>
                                <th className='border' valign={'middle'}>월</th>
                                <th className='border' valign={'middle'}>주</th>
                                <th className='border' valign={'middle'}>일</th>
                            </tr>
                        </>
                    )}
                </thead>
                <tbody>
                    {props.mode === 'contract' ? (
                        <>
                            {estimateProductList.map((data, index) => (
                                <tr key={index}>
                                    <td className='p-1 border' valign={'middle'}>{index + 1}</td>
                                    <td className='p-1 border' valign={'middle'}>{data.productName}</td>
                                    <td className='p-1 border' align={'left'} valign={'middle'}>{data.spec}</td>
                                    <td className='p-1 border' valign={'middle'}>{data.rentMonth}</td>
                                    <td className='p-1 border' valign={'middle'}>{data.rentDays}</td>
                                    <td className='p-1 border' valign={'middle'}>{data.productQty}</td>
                                    <td className='p-1 border' valign={'middle'} align={'right'}>{(data.monthRentPrice ?? 0).toLocaleString()}</td>
                                    <td className='p-1 border' valign={'middle'} align={'right'}>{(data.dayRentPrice ?? 0).toLocaleString()}</td>
                                    <td className='p-1 border' valign={'middle'} align={'right'}>{(data.totalRentPrice ?? 0).toLocaleString()}</td>
                                </tr>
                            ))}
                            <tr>
                                <td className='p-1 border' align={'right'} colSpan={shortTermRent ? 7 : 8}>
                                    <span style={{marginRight: 10, fontWeight: 'bold'}}>소계</span>
                                </td>
                                <td className='p-1 border' align={'right'}>{totalRentPrice.toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td className='p-1 border' align={'right'} colSpan={shortTermRent ? 7 : 8}>
                                    <span style={{marginRight: 10, fontWeight: 'bold'}}>부가세</span>
                                </td>
                                <td className='p-1 border' align={'right'}>{(totalRentPrice * 0.1).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td className='p-1 border' align={'right'} colSpan={shortTermRent ? 7 : 8}>
                                    <span style={{marginRight: 10, fontWeight: 'bold'}}>합계</span>
                                </td>
                                <td className='p-1 border' align={'right'} style={{fontWeight: 'bold'}}>{(totalRentPrice * 1.1).toLocaleString()}</td>
                            </tr>
                        </>
                    ):(
                        <>
                            {estimateProductList.map((data, index) => (
                                <tr key={index}>
                                    <td className='p-1 border' valign={'middle'}>{index + 1}</td>
                                    <td className='p-1 border' valign={'middle'}>{data.productName}</td>
                                    <td className='p-1 border' align={'left'} valign={'middle'}>{data.spec}</td>
                                    <td className='p-1 border' valign={'middle'}>{data.rentMonth}</td>
                                    <td className='p-1 border' valign={'middle'}>{data.rentWeek}</td>
                                    <td className='p-1 border' valign={'middle'}>{data.rentDays}</td>
                                    <td className='p-1 border' valign={'middle'} align={'right'}>{(data.monthRentPrice ?? 0).toLocaleString()}</td>
                                </tr>
                            ))}
                        </>
                    )}
                </tbody>
            </table>
            <br/>
            {/*
            <h6>설치 일정 및 장소</h6>
            <table className="table" style={{borderWidth: 1, textAlign: 'center'}}>
                <thead />
                <tbody>
                <tr>
                    <td className='p-1 border' style={{width: '20%'}}><span style={{fontWeight: 'bold'}}>츨고 예정일</span></td>
                    <td className='p-1 border' style={{width: '30%'}}>{dateFormat(estimateDetail.contractStartDate)}</td>
                    <td className='p-1 border' valign={'middle'} rowSpan={2} style={{width: '20%'}}><span style={{fontWeight: 'bold'}}>배송장소</span></td>
                    <td className='p-1 border' valign={'middle'} rowSpan={2} style={{width: '30%'}}>{estimateDetail.address ? estimateDetail.address + ' ' + estimateDetail.addressDetail : ''}</td>
                </tr>
                <tr>
                    <td className='p-1 border'><span style={{fontWeight: 'bold'}}>사용 시작일</span></td>
                    <td className='p-1 border'>{dateFormat(estimateDetail.contractStartDate)}</td>
                </tr>
                <tr>
                    <td className='p-1 border'><span style={{fontWeight: 'bold'}}>사용 종료일</span></td>
                    <td className='p-1 border'>{dateFormat(estimateDetail.contractEndDate)}</td>
                    <td className='p-1 border' valign={'middle'} rowSpan={2}><span style={{fontWeight: 'bold'}}>회수장소</span></td>
                    <td className='p-1 border' valign={'middle'} rowSpan={2}>{estimateDetail.address ? estimateDetail.address + ' ' + estimateDetail.addressDetail : ''}</td>
                </tr>
                <tr>
                    <td className='p-1 border'><span style={{fontWeight: 'bold'}}>회수 예정일</span></td>
                    <td className='p-1 border'>{dateFormat(estimateDetail.contractEndDate)}</td>
                </tr>
                </tbody>
            </table>
            */}
            <h6>특이사항</h6>
            <div style={{borderWidth: 2, padding: 10, textAlign: 'left'}}>
                {/*<span>아래와 같이 견적합니다.</span>*/}
                <div>
                    <span style={{fontSize: 12}}>{estimateDetail.specialRemark}</span>
                </div>
            </div>
            <br/>
            <br/>
            <h6>기타사항</h6>
            <hr/>
            <div style={{width: '100%'}}>
                <span>*렌탈료납입은 계약서 체결후 현금납입을 원칙으로합니다.</span><br/>
                <span>*위의 사양은 재고 여부에 따라 변동 될 수 있습니다. (변동시 담당자와 협의 후 변경 진행)</span><br/>
                <span>*고객의 신용평점에 따라 보증금/보증인 또는 보증보험이 필요할 수 있으며, 계약이 체결되지 않을 수 있습니다.</span><br/>
                <span>*임대기간중 취급부주의 등 과실로 인한 고장 및 도난, 파손, 분실로 인한 수리비는 실비 청구됩니다.</span>
            </div>
            <hr/>
        </div>
    );
};

export default forwardRef(ReportTemplate);

/*
<div className="report-template" style={{display: 'block', height: 1000}}>
    <h1 style={{textAlign: 'center', marginBottom: 20}}>견 적 서</h1>
    <p style={{display: 'flex', justifyContent: 'space-between'}}>
        <span style={{float: 'left', fontSize: 14, fontWeight: 'bold'}}>(주)엘리트렌탈</span>
        <span style={{float: 'left', fontSize: 14, fontWeight: 'bold'}}>견적 유효기간 2주</span>
    </p>
    <p style={{display: 'flex', justifyContent: 'space-between'}}>
        <span style={{fontSize: 14}}>주소</span>
        <span style={{fontSize: 14}}>2023.09.06</span>
    </p>
    <table className="table" style={{borderWidth: 1}}>
        <tbody>
        <tr>
            <td className='p-1 border' align={'center'} valign={'middle'} colSpan={2} rowSpan={2}><b>인쿠르트 주식회사</b></td>
            <td className='p-1 border' align={'center'} valign={'middle'} rowSpan={2} style={{width:'5%'}}>귀중</td>
            <td className='p-1 border' align={'center'} valign={'middle'}>상호</td>
            <td className='p-1 border' valign={'middle'}><span style={{paddingLeft: 20}}>(주)이룸렌탈</span></td>
            <td className='p-1 border' rowSpan={5} style={{width: '10%'}}>
                <Image style={{paddingTop: 20}} src={'/image/eloom_sign.png'} alt={"인감도장"}/>
            </td>
        </tr>
        <tr>
            <td className='p-1 border' align={'center'}>대표자</td>
            <td className='p-1 border'><span style={{paddingLeft: 20}}>김규완</span></td>
        </tr>
        <tr>
            <td className='p-1 border' align={'center'} style={{width: 80}}>담당자</td>
            <td className='p-1 border' colSpan={2}><span style={{paddingLeft: 20}}>홍길돌 매니저님</span></td>
            <td className='p-1 border' align={'center'} style={{width: 80}}>견적담당자</td>
            <td className='p-1 border'><span style={{paddingLeft: 20}}>김규완</span></td>
        </tr>
        <tr>
            <td className='p-1 border' align={'center'} style={{width: 80}}>연락처</td>
            <td className='p-1 border' colSpan={2}><span style={{paddingLeft: 20}}>010-2488-6944</span></td>
            <td className='p-1 border' align={'center'} style={{width: 80}}>연락처</td>
            <td className='p-1 border'><span style={{paddingLeft: 20}}>02-1588-5959</span></td>
        </tr>
        <tr>
            <td className='p-1 border' align={'center'} style={{width: 80}}>E-mail</td>
            <td className='p-1 border' colSpan={2}><span style={{paddingLeft: 20}}>wan@pelotonlab.it</span></td>
            <td className='p-1 border' align={'center'} style={{width: 80}}>E-mail</td>
            <td className='p-1 border'><span style={{paddingLeft: 20}}>intofall@naver.com</span></td>
        </tr>
        </tbody>
    </table>
    <div style={{borderWidth: 1, padding: 10, display: 'flex', justifyContent: 'space-between'}}>
        <span>감사합니다. 아래와 같이 견적합니다.</span>
        <div>
            <span style={{fontSize: 14, fontWeight: 'bold', marginRight: 10}}>총 금액 $132,000원</span>
            <span>(부가세 포함)</span>
        </div>
    </div>
    <br/>
    <h6>견적 품목 내역</h6>
    <table className="table" style={{borderWidth: 1, textAlign: 'center'}}>
        <thead>
        <tr>
            <th className='border' style={{width: '5%'}}>항목</th>
            <th className='border' style={{width: '25%'}}>품목</th>
            <th className='border' style={{width: '25%'}}>설명</th>
            <th className='border' style={{width: '10%'}} colSpan={2}>기간</th>
            <th className='border' style={{width: '5%'}}>수량</th>
            <th className='border' style={{width: '15%'}}>단가</th>
            <th className='border' style={{width: '15%'}}>금액</th>
            <th className='border' style={{width: '10%'}}>월렌탈료</th>
            <th className='border' style={{width: '10%'}}>총렌탈료</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td className='p-1 border'>1</td>
            <td className='p-1 border'>품목</td>
            <td className='p-1 border' align={'left'}>
                <span style={{paddingLeft: 10}}>설명</span>
            </td>
            <td className='p-1 border'>10개월</td>
            <td className='p-1 border'>33일</td>
            <td className='p-1 border'>10</td>
            <td className='p-1 border'>23123123</td>
            <td className='p-1 border'>23123123</td>
        </tr>
        <tr>
            <td className='p-1 border' align={'right'} colSpan={7}>
                <span style={{marginRight: 10, fontWeight: 'bold'}}>소계</span>
            </td>
            <td className='p-1 border'>120,000원</td>
        </tr>
        <tr>
            <td className='p-1 border' align={'right'} colSpan={7}>
                <span style={{marginRight: 10, fontWeight: 'bold'}}>부가세</span>
            </td>
            <td className='p-1 border'>12,000원</td>
        </tr>
        <tr>
            <td className='p-1 border' align={'right'} colSpan={7}>
                <span style={{marginRight: 10, fontWeight: 'bold'}}>합계</span>
            </td>
            <td className='p-1 border' style={{fontWeight: 'bold'}}>132,000원</td>
        </tr>
        </tbody>
        </table>
        <br/>
        <h6>설치 일정 및 장소</h6>
        <table className="table" style={{borderWidth: 1, textAlign: 'center'}}>
            <tbody>
            <tr>
                <td className='p-1 border'><span style={{fontWeight: 'bold'}}>츨고 예정일</span></td>
                <td className='p-1 border'>2023년 12월 11알 월요일</td>
                <td className='p-1 border' valign={'middle'} rowSpan={2}><span style={{fontWeight: 'bold'}}>배송장소</span></td>
                <td className='p-1 border' valign={'middle'} rowSpan={2}>서울시 송파구 정의로 8갈 9 13층</td>
            </tr>
            <tr>
                <td className='p-1 border'><span style={{fontWeight: 'bold'}}>사용 시작일</span></td>
                <td className='p-1 border'>2023년 12월 11알 월요일</td>
            </tr>
            <tr>
                <td className='p-1 border'><span style={{fontWeight: 'bold'}}>사용 종료일</span></td>
                <td className='p-1 border'>2023년 12월 11알 월요일</td>
                <td className='p-1 border' valign={'middle'} rowSpan={2}><span style={{fontWeight: 'bold'}}>회수장소</span></td>
                <td className='p-1 border' valign={'middle'} rowSpan={2}>서울시 송파구 정의로 8갈 9 13층</td>
            </tr>
            <tr>
                <td className='p-1 border'><span style={{fontWeight: 'bold'}}>회수 예정일</span></td>
                <td className='p-1 border'>2023년 12월 11알 월요일</td>
            </tr>
            </tbody>
        </table>
        <br/>
        <h6>기타사항</h6>
        <hr/>
        <table style={{width: '100%'}}>
            <caption>*렌탈료납입은 계약서 체결후 선금 현금납입을 원칙으로합니다.</caption>
            <caption>*위의 사양은 재고 여부에 따라 변동 될 수 있습니다. (변동시 다당자와 협위 후 변경 진행)</caption>
            <caption>*고객의 신용평점에 따라 보증금/보증인 또는 보증보험이 필요할 수 있으며, 계약이 체결되지 않을 수 있습니다.</caption>
            <caption>*임대인은 장비 대여업체로써 기본소프트웨어 O/S만 제공하며, 그 외 소프트웨어에 대한 책임은 임차인에게 귀속됩니다.</caption>
            <caption>*발주 후 귀사의 사정으로 인하여 발주를 취소할 경우 잔존렌탈료의 40%의 위약금이 발생됩니다.</caption>
            <caption>*임대기간중 취급부주의 등 과실로 인한 고장 및 도난, 파손 소프트웨어 비밀번호 분실로 인한 수리비는 실비 청구됩니다.</caption>
            <caption>*렌탈료를 3개월 이상 연체 할 경우 사용자의 동의없이 렌탈 장비를 수거 할 수 있습니다.</caption>
        </table>
        <hr/>
    </div>
 */
