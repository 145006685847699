import { observer } from "mobx-react-lite";
import { Row } from "react-bootstrap";
import axios from "axios";

import { AppStore } from "../../../store/AppStore";
import { CCol, CContainer, CIconBtn, CInputGroup, CSaveBtn } from "../../../components/CustomContainer";
import Modal from "../../../components/Modal";
import { ShipStore } from "../../../store/ship/ShipStore";
import { showToast } from "../../../common/utils";
import { useState } from "react";

const OutsourcingInfoPopup = ({ getShipList, isCheckedSetting, setIsCheckedSetting, updateShipStatus }) => {
    const store = ShipStore;
    const [isSaved, setIsSaved] = useState(false);
   
    const saveBtn = async () => {
        if(isCheckedSetting) {
            const shipSeqList = ShipStore.shipList.filter(v => v.isSelected).map(v => v.shipSeq);
            store.data.shipSeqList = shipSeqList     
            await axios.put('/ship/outsourcing/info/list', store.data);
            await getShipList().then(() => {
                ShipStore.shipList.forEach(ship => {
                    if (shipSeqList.includes(ship.shipSeq)) {
                        ship.isSelected = true;
                    }
                });
            })
        }else {
            await axios.put('/ship/outsourcing/info', store.data);
            getShipList();
        }
       
        showToast('저장했습니다.');
        setIsSaved(true);
    }

    const deliveryDeparture = async () => {
        updateShipStatus('4');
        setIsSaved(false);
        AppStore.toggleOutsourcingInfoPopup();
        setIsCheckedSetting(false);
    }

    const refresh = () => {
        store.dataInit();
        setIsSaved(false);
        setIsCheckedSetting(false);
    }

    return (
        <Modal
            title={'외주 배송 정보 입력'}
            show={AppStore.isOpenOutsourcingInfo}
            onHide={() => AppStore.toggleOutsourcingInfoPopup()}
            toggleValue={AppStore.isOpenOutsourcingInfo}
            size={'md'}
            onExit={refresh}
        >
            <CContainer>
                <Row>
                    <CCol lg={12}>
                        <CInputGroup label={'외주 운반사'} labelId={'outsourcingCompany'} value={store.data.outsourcingCompany} onChange={(v)=> store.data.outsourcingCompany = v.target.value} />
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup label={'외주 배송비'} labelId={'outsourcingAmount'} value={store.data.outsourcingAmount} onChange={(v)=> store.data.outsourcingAmount = v.target.value} />
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup label={'외주 차량톤수'} labelId={'outsourcingCarTonnage'} value={store.data.outsourcingCarTonnage} onChange={(v)=> store.data.outsourcingCarTonnage = v.target.value} />
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup label={'외주 차량번호'} labelId={'outsourcingCarNumber'} value={store.data.outsourcingCarNumber} onChange={(v)=> store.data.outsourcingCarNumber = v.target.value} />
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup label={'외주 운전자명'} labelId={'outsourcingDriver'} value={store.data.outsourcingDriver} onChange={(v)=> store.data.outsourcingDriver = v.target.value} />
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup label={'외주 휴대폰번호'} labelId={'outsourcingPhoneNumber'} value={store.data.outsourcingPhoneNumber} onChange={(v)=> store.data.outsourcingPhoneNumber = v.target.value} />
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup label={'외주 비고'} labelId={'outsourcingRemark'} value={store.data.outsourcingRemark} onChange={(v)=> store.data.outsourcingRemark = v.target.value} />
                    </CCol>
                </Row>
                <Row>
                    {isCheckedSetting ? (
                        <>
                            {!isSaved ? (
                                <CIconBtn
                                    title={'일괄저장'} 
                                    style={{width: '97%', marginTop: 20}} 
                                    variant={'success'}
                                    onClick={saveBtn} 
                                    icon={'fi fi-rs-disk'}
                                    iconPaddingTop={3}
                                />
                            ) : (
                                <CIconBtn
                                    title={'배송출발 및 문자발송'} 
                                    style={{width: '97%', marginTop: 20}} 
                                    variant={'primary'}
                                    onClick={deliveryDeparture} 
                                    icon={'fi fi-br-running'}
                                    iconPaddingTop={3}
                                />
                            )}
                        </>
                    ): (
                        <CSaveBtn style={{width: '97%', marginTop: 20}} onClick={saveBtn} />
                    )}
                </Row>
            </CContainer>
        </Modal>
    )
}

export default observer(OutsourcingInfoPopup);
