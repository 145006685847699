import React, { useRef, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import axios from 'axios';

import { CardStatementStore } from "../../store/accounting/CardStatementStore";
import AgGridContainer from "../../components/AgGridContainer";
import { getCommcode, findCommKrnm } from "../../utils/commcode";
import { CContainer } from "../../components/CustomContainer";
import { callConfirm } from "../../utils";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import { AppStore } from "../../store/AppStore";
import { IconBtn } from '../../components/CustomContainer';
import LUtils from "../../utils/lodashUtils";
import { DEBIT_CODE, CREDIT_CODE } from "../../common/constants";
import AccountingCodeSearch from "../../components/searchModal/AccountingCodeSearch";
import { showToast } from "../../common/utils";
import { today , minusDay } from "../../utils/dateUtils";
import CardStatementSearch from "./CardStatementSearch";

const CardStatementList = () => {
    const store = CardStatementStore;
    const gridRef = useRef();
    const [gridData, setGridData] = useState([]);
    const [type, setType] = useState('');
    const [accountingType, setAccountingType] = useState('');
    const [inputCode, setInputCode] = useState('');

    useEffect(() => {
        // 일주일 전 날짜 세팅
        store.search.startDate = minusDay(6);
        store.search.endDate = today();
        getAccountingCode();
    }, []);

    const columnDefs = [
        {field: 'isStatement', headerName: '생성', valueFormatter: v => v.data.referenceKey ? '완료' : '', width: 80, cellClass: 'ag-grid-column-complete'},
        {field: "cardApprovalNo", headerName: "승인번호", hide: true},
        {
            field: "cardCode",
            headerName: "카드사",
            valueFormatter: v => findCommKrnm(v.value, '44'),
            cellEditorParams: {
                values: getCommcode('44').map(v => v.value),
            },
            minWidth: 100
        },
        {field: "cardNumber", headerName: "카드번호", minWidth: 180},
        {field: "approvalDate", headerName: "승인일자", minWidth: 120},
        {field: "approvalTime", headerName: "승인시간", minWidth: 120, valueFormatter: v => v.value ? `${v.value.substring(0, 2)}:${v.value.substring(2, 4)}:${v.value.substring(4, 6)}` : ''},
        {field: "approvalAmount", headerName: "금액", minWidth: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
        {field: "customerNo", headerName: "고객코드", minWidth: 160, hide: true},
        {
            field: "isDeduction",
            headerName: "공제여부",
            valueFormatter: v => findCommKrnm(v.value, '47'),
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: getCommcode('47').map(v => v.value),
            },
            headerClass: 'grid-column-editable',
            minWidth: 90,
            editable: true
        },
        // {
        //     field: "statementType",
        //     headerName: "전표구분",
        //     minWidth: 80,
        //     cellEditor: 'agSelectCellEditor',
        //     cellEditorParams: {
        //         values: getCommcode('41').map(v => v.value),
        //     },
        //     valueFormatter: v => findCommKrnm(v.value, '41')
        // },
        // {field: "debitCustomerNo", headerName: "차변 고객코드", minWidth: 160, hide: true},
        // {field: "debitCustomerName", headerName: "차변 고객명", headerClass: 'grid-column-required', minWidth: 180, cellRenderer: e => e.value === 'pinnedTopRowData' ? null : popupCustomerSearch(e, 'debit')},
        // {field: "debitCode", headerName: "차변코드", editable: true, minWidth: 80, headerClass: 'grid-column-required'},
        // {field: "debitAccount", headerName: "차변계정", minWidth: 120, cellRenderer: e => e.value === 'pinnedTopRowData' ? null : popupAccountingCode(e, 'debit')},
        // {field: "creditCustomerNo", headerName: "대변 고객코드", minWidth: 160, hide: true},
        // {field: "creditCustomerName", headerName: "대변 고객명", headerClass: 'grid-column-required', minWidth: 180, cellRenderer: e => e.value === 'pinnedTopRowData' ? null : popupCustomerSearch(e, 'credit')},
        // {field: "creditCode", headerName: "대변코드", editable: true, minWidth: 80, headerClass: 'grid-column-required'},
        // {field: "creditAccount", headerName: "대변계정", minWidth: 120, cellRenderer: e => e.value === 'pinnedTopRowData' ? null : popupAccountingCode(e, 'credit')},
        {field: "summary", headerName: "적요", minWidth: 200, headerClass: 'grid-column-editable', hide: store.isShort},
        {field: "storeName", headerName: "가맹점명", minWidth: 200, hide: store.isShort},
        {field: "businessNumber", headerName: "가맹점 사업자번호", minWidth: 200, hide: store.isShort},
        {field: "storeNumber", headerName: "가맹점 번호", minWidth: 200, hide: true},
        {field: "storeType", headerName: "가맹점 업종", minWidth: 200, hide: store.isShort},
        {field: "storeAddress", headerName: "가맹점 주소", minWidth: 250, hide: store.isShort},
        {field: "remark", headerName: "비고", editable: true, minWidth: 180, headerClass: 'grid-column-editable'},
    ];

    /* 조회 */
    const getCardStatementList = async () => {
        let result = await axios.get("statement/card/list", {params: store.search});
        // 대변 code null 인 경우 25300 넣어주기
        result = result.map(v => {
                if(!v.creditCode) {
                    v.creditCode = '25300';
                    v.creditAccount = '미지급금';
                } 
                return v;
            });

        store.cardStatementList = result;
        if(result.length === 0) showToast('조회된 데이터가 없습니다.');
    }

    /* 고객 팝업 UI */
    const popupCustomerSearch = (e, accountingType) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{accountingType === 'debit' ? e.data.debitCustomerName : e.data.creditCustomerName}</div>
                <IconBtn key={e.rowIndex} onClick={() => openCustomerSearch(e, accountingType)} />
            </div>
        )
    }

    /* 고객 조회 팝업 클릭 시 이벤트 */
    const openCustomerSearch = (e, accountingType) => {
        setAccountingType(accountingType);
        AppStore.toggleCustomerSearchModal();
        setGridData(e);
    }

    /* 고객 팝업 callback */
    const searchCustomerInfo =(data)=> {
        store.cardStatementList.map(v => {
            if(v.cardApprovalSeq === gridData.data.cardApprovalSeq) {
                if(accountingType === 'debit') {
                    v.debitCustomerNo = data.customerNo;
                    v.debitCustomerName = data.customerName;
                }else if(accountingType === 'credit') {
                    v.creditCustomerNo = data.customerNo;
                    v.creditCustomerName = data.customerName;
                }
                LUtils.assign(v, {isUpdated: true, isSelected: true});
            }
        })
        gridRef.current.api.redrawRows();
    }

    /* 계정 과목 전체조회 */
    const getAccountingCode = async () => {
        const result = await axios.get('statement/accounting/code/all');
        store.accountingCodeList = result;
    }

    /* 계정 과목 input 이벤트 */
    const cellCustomChangeValue = async (e) => {
        if(e.column.colId !== 'debitCode' && e.column.colId !== 'creditCode') return;
        const result = store.accountingCodeList.filter(v => v.code.includes(e.value));

        if(result.length === 1) {
            store.cardStatementList.filter(v => v.cardApprovalSeq === e.data.cardApprovalSeq).map(v => {
                if(e.column.colId === 'debitCode'){
                    v.debitCode = result[0].code;
                    v.debitAccount = result[0].name;
                }else if(e.column.colId === 'creditCode'){
                    v.creditCode = result[0].code;
                    v.creditAccount = result[0].name;
                }
            })
        }else {
            setGridData(e);
            setType(e.column.colId === 'debitCode'?'debit':'credit');
            setInputCode(e.value);
            AppStore.toggleAccountingCodePopup();
        }
        gridRef.current.api.refreshCells({
            columns: ['debitCode', 'debitAccount', 'creditCode', 'creditAccount'],
            force: true,
        });
    };

    /* 계정 과목 팝업 UI */
    const popupAccountingCode = (e, accountingType) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{accountingType === 'debit' ? e.data.debitAccount : e.data.creditAccount}</div>
                <IconBtn key={e.rowIndex} onClick={() => openAccountingCode(e, accountingType)} />
            </div>
        )
    }

    /* 계정 과목 팝업 클릭 시 이벤트 */
    const openAccountingCode = (e, accountingType) => {
        setType(accountingType);
        setGridData(e);
        AppStore.toggleAccountingCodePopup();
    }

    /* 계정 과목 팝업 callback */
    const searchAccountingCode = (data) => {
        store.cardStatementList.map(v => {
            if(v.cardApprovalSeq === gridData.data.cardApprovalSeq) {
                if(type === 'debit') {
                    v.debitCode = data.code;
                    v.debitAccount = data.name;
                }else if(type === 'credit') {
                    v.creditCode = data.code;
                    v.creditAccount = data.name;
                }
                LUtils.assign(v, {isUpdated: true, isSelected: true});
            }
        })
        setInputCode('');
        gridRef.current.api.redrawRows();
    }

    /* 저장 */
    const callBackGridData = async () => {
        // 필수값 확인
        const cardStatementList = store.cardStatementList.filter(v => v.isSelected);
        if(cardStatementList.some(v => v.referenceKey)) {
            showToast("전표생성된 전표는 일반전표에서 수정해주세요.");
            return;
        }
        if(cardStatementList.some(v => v.customerNo === null ||  v.customerNo === '' || v.debitCode === null || v.debitCode === '' || v.creditCode === null || v.creditCode === '')){
            showToast("필수값을 입력해주세요.");
            return;
        }

        if(!await callConfirm('전표생성 하시겠습니까?')) return;

        await axios.post('statement/card', {cardStatementList: cardStatementList})
            .then(() => {
                showToast("전표 생성 되었습니다.");
            })
        await getCardStatementList();
    }

    /* 행 고정 */
    const pinnedTopRowData = [{
        approvalAmount: store.cardStatementList.reduce((total, v) => total + v.approvalAmount, 0), debitCustomerName: 'pinnedTopRowData', creditCustomerName: 'pinnedTopRowData', debitAccount: 'pinnedTopRowData', creditAccount: 'pinnedTopRowData',
    }];

    return (
        <>
            <CardStatementSearch getCardStatementList={getCardStatementList} />
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    gridTitle={'카드전표등록'}
                    height={65}
                    rowData={store.cardStatementList}
                    columnDefs={columnDefs}
                    // isCheckBox={true}
                    // useUpdated={true}
                    callBackGridData={callBackGridData}
                    isCellCustom={true}
                    cellCustomChangeValue={cellCustomChangeValue}
                    pinnedTopRowData={pinnedTopRowData}
                />
            </CContainer>
            <CustomerSearch callbackFn={searchCustomerInfo} />
            <AccountingCodeSearch callbackFn={searchAccountingCode} inputCode={inputCode} />
        </>
    )
}

export default observer(CardStatementList);
