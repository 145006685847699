import { forwardRef } from "react";

import { NonpaymentStore } from "../../../store/billing/NonpaymentStore";
import { dateKoreaFormat, oneMonthAfter, today } from "../../../utils/dateUtils";
import { USER_INFO } from "../../../common/constants";
import { getLocalStorage } from "../../../utils";
import LUtils from "../../../utils/lodashUtils";
import { findCommKrnm } from "../../../utils/commcode";
import "../../contract/link/signaturePopupStyle.css";

const PrintCertificationContentPopup = (props, ref) => {
    const userInfo = getLocalStorage(USER_INFO);

    const groupByContractList = LUtils.groupBy(NonpaymentStore.customerNonpaymentList, 'contractNo');
    const contractListRows = Object.keys(groupByContractList).map((v, i) => {
        return (
            <tr key={i}>
                <td align='center' className='border' style={{fontSize: 11}}>{i+1}</td>
                <td align='center' className='border' style={{fontSize: 11}}>{groupByContractList[v][0].contractDate}</td>
                <td align='left' className='border' style={{fontSize: 11}}>{groupByContractList[v][0].contractNo}</td>
                <td align='left' className='border' style={{fontSize: 11}}>{groupByContractList[v][0].productName}</td>
            </tr>
        )
    })

    return (
        <div ref={ref}>
            <div className='print-template' style={{display: 'none'}} >
                <table className="table" style={{borderWidth: 1}} height='80'>
                    <thead>
                        <tr>
                            <th className='border' valign="middle" style={{textAlign: 'center', fontSize: 22, fontWeight: 'bold'}}>
                                내 용 증 명
                            </th>
                        </tr>
                    </thead>
                </table>
                <table className="table" style={{borderWidth: 1}} height='80'>
                    <tbody>
                        <tr >
                            <td className='p-1 border bg-gray' valign="middle" style={{backgroundColor: '#e3e3e3',width: '16%', textAlign: 'center', fontSize: 13}}>발신인</td>
                            <td className='p-1 border' valign="middle" style={{padding: 10, fontSize: 13}}>{userInfo.companyName}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border bg-gray' valign="middle" style={{backgroundColor: '#e3e3e3',width: '16%', textAlign: 'center', fontSize: 13}}>수신인</td>
                            <td className='p-1 border' valign="middle" style={{padding: 10, fontSize: 13}}>{NonpaymentStore.data.customerName}</td>
                        </tr>
                    </tbody>
                </table>

                <table className="table" style={{borderWidth: 1}} height="40px">
                    <tbody>
                        <tr>
                            <td className='p-1 border bg-gray' valign="middle" style={{backgroundColor: '#e3e3e3', width: '16%', textAlign: 'center', fontSize: 13}}>제목</td>
                            <td className='p-1 border' valign="middle" style={{padding: 10, height: 20, textAlign: 'left', fontSize: 13}}>장비 임대료 청구</td>
                        </tr>
                    </tbody>
                </table>
                <table className="table" style={{borderWidth: 1}}>
                    <tbody>
                        <tr>
                            <td className='p-1 border bg-gray' style={{backgroundColor: '#e3e3e3', width: '16%', textAlign: 'center', fontSize: 13}}>내용</td>
                        </tr>
                        <tr height="600px">
                            <td className='p-1 border' style={{fontSize: 13}}>
                                1. 귀사(수신인, 이하 '귀사'라고 한다)의 무궁한 발전을 기원합니다.
                                <br /><br />
                                2. 귀사는 아래와 같이 본 본사와의 임대차계약에 따라 본 발신인에게 차임 지급 의무가 있습니다. 그러나 귀사는 본사의 수차례에 걸친 독촉에도 불구하고 여전히 차임 지급 의무를 불이행하고 있는 바, 이에 지급 이행을 촉구합니다.
                                <br />
                                &nbsp; 가. 본사는 귀사와 다음과 같은 계약에 관하여 임대차계약을 체결하였습니다.
                                <br />
                                <table className='table contract-list' align='center' style={{width: '80%', marginTop: 10}} >
                                    <tbody>
                                        <tr>
                                            <td className='border bg-gray' style={{paddingTop: 5, paddingBottom: 5, backgroundColor: '#e3e3e3', width: '7%', textAlign: 'center', fontSize: 11}}></td>
                                            <td className='border bg-gray' style={{paddingTop: 5, paddingBottom: 5, backgroundColor: '#e3e3e3', width: '17%', textAlign: 'center', fontSize: 11}}>계약일자</td>
                                            <td className='border bg-gray' style={{paddingTop: 5, paddingBottom: 5, backgroundColor: '#e3e3e3', width: '20%', textAlign: 'center', fontSize: 11}}>계약번호</td>
                                            <td className='border bg-gray' style={{paddingTop: 5, paddingBottom: 5, backgroundColor: '#e3e3e3', width: '56%', textAlign: 'center', fontSize: 11}}>장비</td>
                                        </tr>
                                        {contractListRows}
                                    </tbody>
                                </table>
                                <br />
                                &nbsp; 나. 귀사는 본 계약에 따라 임대차 장비를 사용하고 있음에도 불구하고 현재까지 합계 {NonpaymentStore.data.totalNonpaymentAmount?.toLocaleString()}원의 차임을 지급하고 있지 않습니다.
                                <br />
                                &nbsp; 다. 따라서 귀사는 본사에게 밀린 차임 및 기타 미지급 내역의 합계 {NonpaymentStore.data.totalNonpaymentAmount?.toLocaleString()}원을 지급해야 할 의무가 있는 바, {dateKoreaFormat(oneMonthAfter(today()))}자 까지 본사의 계좌로 밀린 차임을 지급할 것을 촉구합니다.
                                <br /><br />
                                3. 귀사가 위와 같은 내용을 이행하지 않을 때는 본 발신인은 귀사의 재산에 대하여 민사집행법 제276조 이하 등에 따른 가압류 등 보전 처분 및 민사소송법 등에 따른 차임 지급 청구 소송 등의 법적 조치를 할 것을 엄중히 경고합니다. 
                                이 경우, 귀사는 차임 지급 의무에 따른 원금, 이자 및 지연 손해금은 물론, 소송 비용까지 귀사가 부담하게 될 것입니다.
                                게다가 본사가 소송을 제기하면 지연 손해금은 소송 촉진 등에 관한 특례법 제3조 제1항 본문의 법정이율에 관한 규정에 따라 연 12%가 될 것입니다.
                                <br/><br/>
                                <div style={{textAlign: "center"}}>
                                    <span>
                                        • 입금 계좌 : {findCommKrnm(NonpaymentStore.bankCode, '24')} {NonpaymentStore.accountNumber} {NonpaymentStore.accountHolder}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table" align='right' style={{borderWidth: 1, width: '40%'}} height='80px'>
                    <tbody>
                        <tr>
                            <td className='p-1 border bg-gray' valign="middle" style={{backgroundColor: '#e3e3e3', textAlign: 'center', fontSize: 13}}>작성일자</td>
                            <td className='p-1 border' valign="middle" style={{fontSize: 13}}>{dateKoreaFormat(today())}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border bg-gray' valign="middle" style={{backgroundColor: '#e3e3e3', textAlign: 'center', fontSize: 13}}>발신인</td>
                            <td className='p-1 border' valign="middle" style={{fontSize: 13}}>{userInfo.name}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default forwardRef(PrintCertificationContentPopup);
