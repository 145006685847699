import { observer } from "mobx-react-lite";
import AgGridContainer from "../../../components/AgGridContainer";
import { CContainer } from "../../../components/CustomContainer";
import { findCommKrnm } from "../../../utils/commcode";
import { dateFormat } from "../../../utils/dateUtils";
import { NonpaymentStore } from "../../../store/billing/NonpaymentStore";
import { useEffect, useRef } from "react";

const ConsultationList = ({getConsultationList}) => {
    const store = NonpaymentStore;
    const gridRef = useRef();

    useEffect(() => {
        getConsultationList();
    }, [])

    return (
        <AgGridContainer
            gridRef={gridRef}
            rowData={store.consultationList}
            height={25}
            columnDefs={
                [
                    {field: "consultationSeq", headerName: "순번", valueGetter: 'node.rowIndex + 1', width: 80},
                    // {field: "contractNo", headerName: "계약번호", width: 160},
                    {field: "bondType", headerName: "채권형태", valueFormatter: v => findCommKrnm(String(v.data.bondType), '37'),width: 100},
                    {field: "bondActivity", headerName: "채권활동", width: 100, valueFormatter: v => findCommKrnm(String(v.data.bondActivity), '38')},
                    {field: "consultationDate", headerName: "상담일자", valueGetter: v => dateFormat(v.data.consultationDate), width: 110},
                    {field: "paymentPromiseDate", headerName: "납부약속일", valueGetter: v => dateFormat(v.data.paymentPromiseDate), width: 110},
                    {field: "consultationContent", headerName: "상담내용", width: 510, autoHeight: true, wrapText: true},
                ]
            }
        />
    )
}

export default observer(ConsultationList);