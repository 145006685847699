import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const EstimateListStore = observable({
    estimateList: [],
    estimateSearchCondition: {
        startDate: '',
        endDate: '',
        estimateNo: '',
        customerNo: '',
        customerName: '',
        saleUserSeq: '',
        saleUserName: '',
        isNewCustomer: 0,
        isContractComplete: 0,
        stauts: '',
    },

    setEstimateList(v) {
        this.estimateList = v;
    },
    setEstimateSearchCondition(v) {
        LUtils.assign(this.estimateSearchCondition, v);
    },
});
