import { observer } from "mobx-react-lite"
import { USER_INFO } from "../../common/constants";
import { getLocalStorage } from "../../utils";
import { useEffect, useState } from "react";
import { ReleaseAskDetailStore } from "../../store/release/ReleaseAskDetailStore";
import axios from "axios";
import Regex from "../../common/regex";
import { findCommKrnm } from "../../utils/commcode";
import { dateKoreaFormat, today } from "../../utils/dateUtils";
import { decrypt } from "../../common/utils";

const makeReleaseAskPrintTemplate = async (checkedList) => {
    let emptyList = [];
    let contract = {};
    let contractProductList = [];
    let saleUserPhoneNumber = "";

    let resultArray = [];
    const dataList = ReleaseAskDetailStore.releaseAskItemList;
    const userInfo = getLocalStorage(USER_INFO);

    if (ReleaseAskDetailStore.releaseAskDetail.contractNo) {
        contract = await axios.get(`/contract/${ReleaseAskDetailStore.releaseAskDetail.contractNo}`);
        contractProductList = await axios.get(`/contract/product/${ReleaseAskDetailStore.releaseAskDetail.contractNo}`);
    }

    if(dataList.length < 8){
        const list = [];
        for(let i = 0; i < 8 - dataList.length; i++){
            list.push(0);
        }
        emptyList = list;
    }

    const contractProductListRows = contractProductList.map((v,i) => {
        return (
            `<tr key=${i}>
                <td align='center' class='p-1 border' style="font-size: 9px">${i+1}</td>
                <td align='center' class='p-1 border' style="font-size: 9px">${v.model || ''}</td>
                <td align='center' class='p-1 border' style="font-size: 9px">${v.managementNo || ''}</td>
                <td align='center' class='p-1 border' style="font-size: 9px">${v.serialNo || ''}</td>
                <td align='center' class='p-1 border' style="font-size: 9px">${v.qty || ''}</td>
                <td align='right' class='p-1 border' style="font-size: 9px;">${(v.monthRentPrice + (v.rentDays*v.dayRentPrice))?.toLocaleString() || ''}</td>
            </tr>`
        )
    })

    const emptyListRows = emptyList.map((v,i) => {
        return (
            `<tr key=${i}>
                <td align='center' class='p-1 border' style="font-size: 9px">${i+1+(8 - emptyList.length)}</td>
                <td align='center' class='p-1 border' style="font-size: 9px"></td>
                <td align='center' class='p-1 border' style="font-size: 9px"></td>
                <td align='center' class='p-1 border' style="font-size: 9px"></td>
                <td align='center' class='p-1 border' style="font-size: 9px"></td>
                <td align='center' class='p-1 border' style="font-size: 9px"></td>
            </tr>`
        )
    })

    if(checkedList.includes('pdfType9')) {
        let content = `<!DOCTYPE html>
                            <html lang="en">
                                <head>
                                    <meta charset="UTF-8">
                                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                    <title>Document</title>
                                    <script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
                                    <style>
                                        .print-template th, .print-template td {
                                            border-color: #bababa !important;
                                        }
                                    </style>
                                </head>
                                <body>
                                    <div>
                                        <div class='print-template' style="padding-left: 22px; padding-right: 22px; padding-top: 20px;">
                                            <div style="display: flex; justify-content: space-between; border-bottom-width: 1px; margin-bottom: 10px">
                                                <h3 style="text-align: left">렌 탈 계 약 서</h3>
                                                <span style="float: left; font-size: 14px; font-weight: bold; padding-top: 10px">${userInfo.companyName || ''}</span>
                                            </div>
                                            <table class="table" style="border-width: 1px">
                                                <tbody>
                                                <tr>
                                                    <td class='p-1 border' align='center' valign='middle' style="width: 10%; background-color: #D8D8D8; font-size: 9px">계약번호</td>
                                                    <td class='p-1 border' align='center' valign='middle' style="width: 18%; font-size: 9px">${ReleaseAskDetailStore.releaseAskDetail.contractNo}</td>
                                                    <td class='p-1 border' align='center' valign='middle' style="width: 10%; background-color: #D8D8D8; font-size: 9px">계약일자</td>
                                                    <td class='p-1 border' align='center' valign='middle' style="width: 17%; font-size: 9px">${dateKoreaFormat(contract.contractDate)}</td>
                                                    <td class='p-1 border' align='center' valign='middle' style="width: 10%; background-color: #D8D8D8; font-size: 9px">계약담당자</td>
                                                    <td class='p-1 border' align='center' valign='middle' style="width: 12%; font-size: 9px">${contract.saleUserName}</td>
                                                    <td class='p-1 border' align='center' valign='middle' style="width: 9%; background-color: #D8D8D8; font-size: 9px">연락처</td>
                                                    <td class='p-1 border' align='center' valign='middle' style="width: 14%; font-size: 9px">${contract.telephone ? contract.telephone : decrypt(contract.phoneNumber)}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <table class="table" style="border-width: 1px">
                                                <tbody>
                                                    <tr>
                                                        <td class='p-1 border' align='center' valign='middle' colspan="2" style="font-size: 9px; font-weight: bold">임차인</td>
                                                        <td class='p-1 border' align='center' valign='middle' colspan="2" style="font-size: 9px; font-weight: bold">임대인</td>
                                                    </tr>
                                                    <tr>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: 10%; background-color: #D8D8D8; font-size: 9px">회 사 명</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: 40%">
                                                            <div class="d-flex justify-content-between">
                                                                <span style="font-size: 9px">${contract.customerName}</span>
                                                                <span style="margin-right: 20px; font-size: 9px">(인)</span>
                                                            </div>
                                                        </td>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: 10%; background-color: #D8D8D8; font-size: 9px">회 사 명</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: 40%">
                                                            <div class="d-flex justify-content-between">
                                                                <span style="font-size: 9px">${userInfo.companyName}</span>
                                                                <span style="margin-right: 20px; font-size: 9px">(인)</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: 10%; background-color: #D8D8D8; font-size: 9px">주소</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: 25%; font-size: 9px">${contract.customerAddress}</td>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: 10%; background-color: #D8D8D8; font-size: 9px">주소</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: 25%; font-size: 9px">${userInfo.companyAddress}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: 10%; background-color: #D8D8D8; font-size: 9px">사업자번호</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: 25%; font-size: 9px">${Regex.makeBusinessNumber(contract.businessNumber) || ''}</td>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: 10%; background-color: #D8D8D8; font-size: 9px">사업자번호</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: 25%; font-size: 9px">${Regex.makeBusinessNumber(userInfo.businessNumber) || ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: 10%; background-color: #D8D8D8; font-size: 9px">대표자명</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: 25%; font-size: 9px">${contract.owner || ''}</td>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: 10%; background-color: #D8D8D8; font-size: 9px">대표자명</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: 25%; font-size: 9px">${userInfo.owner || ''}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <h6>렌탈 품목 및 요금</h6>
                                            <table class="table" style="border-width: 1; text-align: center">
                                                <thead>
                                                    <tr>
                                                        <th align='center' class='border' style="width: 10%; font-size: 9px">순번</th>
                                                        <th align='center' class='border' style="width: 20%; font-size: 9px">모델명</th>
                                                        <th align='center' class='border' style="width: 20%; font-size: 9px">관리번호</th>
                                                        <th align='center' class='border' style="width: 20%; font-size: 9px">시리얼번호</th>
                                                        <th align='center' class='border' style="width: 10%; font-size: 9px">수량</th>
                                                        <th align='center' class='border' style="width: 20%; font-size: 9px">임대료</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    ${contractProductListRows.join('')}
                                                    ${emptyListRows.join('')}
                                                    <tr>
                                                        <td class='p-1 border' valign='middle' align='center'>
                                                            <span style="font-weight: bold; font-size: 9px">옵션</span>
                                                        </td>
                                                        <td class='p-1 border' align={'left'} colspan="4">
                                                            <span style="margin-right: 10; font-size: 9px">${contract.options || ''}</span>
                                                        </td>
                                                        <td class='p-1 border' valign='middle' align='right' style="font-size: 9px">${contract.optionAmount?.toLocaleString() || 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class='p-1 border' align='right' colspan="5">
                                                            <span style="margin-right: 10px; font-weight: bold; font-size: 9px">소계 (VAT별도)</span>
                                                        </td>
                                                        <td class='p-1 border' align='right' style="font-size: 9px">${(contractProductList.reduce((total, v) => total + v.monthRentPrice + (v.rentDays*v.dayRentPrice), 0) + contract.optionAmount)?.toLocaleString()}원</td>
                                                    </tr>
                                                    <tr>
                                                        <td class='p-1 border' align='right' colspan="5">
                                                            <span style="margin-right: 10px; font-weight: bold; font-size: 9px">합계 (VAT포함)</span>
                                                        </td>
                                                        <td class='p-1 border' align='right' style="font-weight: bold; font-size: 9px">${((contractProductList.reduce((total, v) => total + v.monthRentPrice + (v.rentDays*v.dayRentPrice), 0)  + contract.optionAmount) * 1.1)?.toLocaleString()}원</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <h6>계약정보</h6>
                                            <table class="table" style="border-width: 1">
                                                <tbody>
                                                    <tr>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: '10%'; background-color: #D8D8D8; font-size: 9px">계약개시일</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: '23%'; font-size: 9px">${dateKoreaFormat(contract.contractStartDate) || ''}</td>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: '10%'; background-color: #D8D8D8; font-size: 9px">청구방법</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: '23%'; font-size: 9px">${findCommKrnm(contract.paymentType, '17')}</td>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: '10%'; background-color: #D8D8D8; font-size: 9px">결제일</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: '23%'; font-size: 9px">${findCommKrnm(contract.dueDateType, '22')} ${contract.dueDateDay === 0 ? '말일' : contract.dueDateDay+'일'} 결제</td>
                                                    </tr>
                                                    <tr>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: '10%'; background-color: #D8D8D8; font-size: 9px">설치장소</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: '25%'; font-size: 9px" colspan="5">${contract.installPlace || ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: '10%'; background-color: #D8D8D8; font-size: 9px">반납장소</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: '25%'; font-size: 9px" colspan="5">${userInfo.returnAddress || ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: '10%'; background-color: #D8D8D8; font-size: 9px">운송비</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: '25%'; font-size: 9px" colspan="5">
                                                            <span style="font-size: 9px" >${contract.deliveryPrice ? contract.deliveryPrice.toLocaleString() : 0}원</span>
                                                            <div class="form-check form-check-inline" style="margin-left: 15px">
                                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="1" ${contract.isDeliveryPrice && contract.chargeDelivery === '1' ? "checked" : ''} />
                                                                <label class="form-check-label" for="inlineCheckbox1" style="font-size: 9px">고객부담</label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="2" ${contract.isDeliveryPrice && contract.deliveryKind === '1' ? "checked" : ''} />
                                                                <label class="form-check-label" for="inlineCheckbox2" style="font-size: 9px">왕복</label>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="3" ${contract.isDeliveryPrice && contract.deliveryKind === '2' ? "checked" : ''} />
                                                                <label class="form-check-label" for="inlineCheckbox3" style="font-size: 9px">편도</label>
                                                            </div>,
                                                            <div class="form-check form-check-inline" style="margin-left: 15px">
                                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="4" ${contract.isDeliveryPrice && contract.chargeDelivery === '2' ? "checked" : ''} />
                                                                <label class="form-check-label" for="inlineCheckbox1" style="font-size: 9px">당사부담</label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <h6>특약사항</h6>
                                            <table style="width: 100%; height: 30px">
                                                <caption style="padding: 0; font-size: 11px">${contract.specialRemark || ''}</caption>
                                            </table>
                                            <hr/>
                                            <h6>확인사항</h6>
                                            <table style="width: 100%">
                                                <caption style="padding: 0; font-size: 9px">*렌탈 물건의 소유권은 ${userInfo.companyName}에 있습니다.</caption>
                                                <caption style="padding: 0; font-size: 9px">*현장 및 사용자 요청에 따른 옵션장착으로 인한 사고는 고객이 책임집니다.</caption>
                                                <caption style="padding: 0; font-size: 9px">*렌탈 물건 인도 및 인수 시 상/하차 업무 및 그에따른 부대비용은 고객이 부담합니다.</caption>
                                                <caption style="padding: 0; font-size: 9px">*렌탈료 연체 또는 렌탈 물건의 임의처분 및 전대 등 소유권 제한, 침해가 있으면 계약은 강제 해지되고 물건은 반환될 수 있습니다.</caption>
                                                <caption style="padding: 0; font-size: 9px">*렌탈료 연체 또는 물건 반환 지연에 따른 지연손해금 등에따른 불이익을 받으실 수 있습니다.</caption>
                                                <caption style="padding: 0; font-size: 9px">*계약기간 중 물건의 현장이동이 있을 경우 고객은 회사에 고지 의무를 다하며 회사와 협의가 필요합니다.</caption>
                                            </table>
                                            <h6 style="margin-top: 20px;">장비인수자</h6>
                                            <table class="table" style="border-width: 1">
                                                <tbody>
                                                    <tr>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: 10%; background-color: #D8D8D8; font-size: 9px">인수자</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: 22%; font-size: 9px"></td>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: 10%; background-color: #D8D8D8; font-size: 9px">소속</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: 22%; font-size: 9px"></td>
                                                        <td class='p-1 border' align='center' valign='middle' style="width: 10%; background-color: #D8D8D8; font-size: 9px">연락처</td>
                                                        <td class='p-1 border' align='left' valign='middle' style="width: 22%; font-size: 9px"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <br/>
                                            <div style="margin-top: 280px">
                                                <div style="border-width: 1px; width: 100%; height: 40px; padding-top: 9px; text-align: center; background-color: #D8D8D8;">
                                                    <h5>렌탈계약규정</h5>
                                                </div>
                                                <br/>
                                                <div class="report-contract-agree d-flex justify-content-between no-line-height" style="line-height: normal;">
                                                    <div style="width: 49%;">
                                                        <label>제 1조(목적)</label>
                                                        <p>본 규정은 앞면 기재의 렌탈물건(이하 '물건'이라 함)을 임대한 자(이하 '회사'라 함)와 물건을 임차한 자(이하 '고객'이라 함) 사이에 체결된 임대차 계약상의 권리,의무에 관한 사항에 관하여 규정함을 목적으로 한다.</p>
                                                        <label>제 2조(렌탈기간)</label>
                                                        <p>1.렌탈 기간은 회사가 고객에게 물건을 인도한 날로부터 계산을 시작하고, 물건을 반환한 날을 렌탈 기간 종료일로 한다.</p>
                                                        <p>2.렌탈 기간 만료 후 고객으로부터 회사에 서면 또는 유선으로 반납통보가 없으면 렌탈 기간이 자동으로 연장되어 물건을 사용하는 것으로 간주하며 본 계약 사항을 같이 적용한다.</p>
                                                        <label>제 3조(렌탈료)</label>
                                                        <p>1.앞 기재의 계약렌탈료는 1개월 단위 또는 1단위의 렌탈료로서 고객은 앞면 기재의 계약렌탈료를 매월 지급하기로 하고 이의 지급기일 및 지급방법은 앞면에 기재된 바에 의한다.</p>
                                                        <p>2.고객이 본 조 제1항 또는 개별 계약으로 정한 렌탈료에 대하여 지급기한을 도뢰한 경우 고객이 지급기한을 도뢰한 렌탈료를 회사에게 지급전까지 회사는 고객의 발주 및 출고 요청에 대하여 거절할 수 있다.</p>
                                                        <label>제 4조(물건의 인도)</label>
                                                        <p>1.회사는 고객이 지정하는 장소에서 물건을 인도하기로 하며 고객은 물건을 인수하면 바로 현장에서 물건을 검사하고 앞면 기재의 현장 담당자 또는 인수담당자가 서명날인 함으로써 정상적인 상태에서 그 물건을 인수한 것임을 확인한다.</p>
                                                        <p>2.물건의 인도 및 인수에 필요한 운반비 및 현장에서 필요한 물건의 상/하차 업무 및 그에따른 기타 부대비용은 고객이 부담한다.</p>
                                                        <label>제 5조(물건의 담보책임)</label>
                                                        <p>1.회사는 고객에게 물건 인도 시 물건이 정상적인 성능을 갖추고 있는 것을 담보 하고 물건의 사용 목적의 적합성에 대하여는 책임지지 않는다.</p>
                                                        <p>2.렌탈 기간 동안 물건과 관련된 인명사고, 재산상의 피해 등 발생되는 모든 사고는 고객의 책임으로 한다.</p>
                                                        <p>3.물건의 인도 후 24시간 이내에 고객은 회사에 물건의 성능결함에 대해 서면 및 유선으로 통지하지 않는 경우 물건이 정상적인 성능을 갖추고 인도된 것으로 간주한다.</p>
                                                        <p>4.전한과 같은 인도 후 회사는 고객 또는 제3자에게 발생한 손해를 책임지지 않는다.</p>
                                                        <label>제 6조(물건의 유지보수)</label>
                                                        <p>1.회사는 렌탈 기간 중 고객의 요청이 있으면 물건이 정상적인 상태로 사용할 수 있도록 일반적인 사용으로 인한 마모와 고객의 사용, 설치, 보관 등으로 인하여 발생한 하자에 대한 유지보수를 제공하며 원활한 유지보수를 위해 대행업체를 선정할 수 있다.</p>
                                                        <p>2.고객은 전항의 유지보수 제공에 문제가 있는 경우 유선 또는 서면으로 회사에 통보해야 하고 통보가 없는 경우에는 정상적인 유지보수 업무가 제공되고 서비스를 받은것으로 간주한다. 또한 물건의 유지보수 완료 후 24시간 이내에 그 유지보수상의 문제를 통보하지 않는 경우 그 유지보수는 정상적으로 완료된 것으로 간주한다.</p>
                                                        <label>제 7조(물건의 사용보관)</label>
                                                        <p>1.고객은 물건의 사용, 보관함에 있어 주의의무를 다하여야 하며, 사용 및 보관에 필요한 비용을 부담하여야 한다.</p>
                                                        <p>2.고객은 물건을 양도,전대,개조하지 못함은 물론 물건을 앞면 기재의 설치장소 외 곳으로 이동시키지 못하며, 물건에 부착된 회사의 소유권표시, 교정표식 등을 제거,훼손해서는 안된다.</p>
                                                        <p>3.고객의 필요에 의하여 물건의 이동 및 개조를 할 경우 사전에 회사에게 서면으로 통보한 후 회사의 승낙을 득해야 하며 개조 시 물건에 부착된 부속품 등의 종속물은 모두 회사의 소유로 한다.</p>
                                                        <label>제 8조(물건의 멸실,훼손 및 미반환)</label>
                                                        <p>1.물건이 멸실(도난,수리불능,소유권의 침해를 포함), 훼손(화재,파손,소유권의 제한,부속품의 부족을 포함), 미반환의 경우 고객은 회사에게 대체물건의 구입비용 또는 물건의 수리비용을 손해배상금으로 지급해야 한다.</p>
                                                        <p>2.고객은 물건의 훼손에 의한 수리기간동안 물건의 사용여부에 관계없이 렌탈 기간 중에는 렌탈료를 지급해야한다. 렌탈 기간 만료 이후 물건을 수리할 경우에는 그 수리에 피요한 기간 및 물건을 재구매할 경우에는 관련 제반 업무 등에 소요되는 기간 동안의 영업손해를 청구할 수 있다.</p>
                                                        <p>3.고객은 물건반환 시 물건의 오염도에 따라 별도의 세차비용을 부담하여야 한다.</p>
                                                    </div>
                                                    <div style="width: 49%;">
                                                        <label>제 9조(계약의 중도해지)</label>
                                                        <p>1.고객은 렌탈 기간 중 이라도 고객의 서면신청으로 회사가 지정하는 장소에 정상적인 상태의 물건을 반환하고 본 계약을 해지할 수 있다.</p>
                                                        <p>2.전항의 경우 앞면 기재의 계약렌탈료를 일할하여 실사용 기간으로 계산한다.</p>
                                                        <p>3.회사 또는 고객은 물건성능의 결함으로 수리에 과대한 비용 또는 시간이 필요 할 때에는 그 내용을 상대방에게 통지하여 본 계약을 해지할 수 있다.</p>
                                                        <label>제 10조(계약의 강제해지)</label>
                                                        <p>1.고객에게 다음 각 호의 사유가 발생하는 경우 회사는 계약을 해지할 수 있다.</p>
                                                        <p>1)렌탈료의 납부를 2회 이상 연체할 경우</p>
                                                        <p>2)어음 또는 수표가 부도 되어 은행의 거래 정지 처분이 있거나 조세공과금의 체납으로 독촉 또는 체납처분을 받을 경우</p>
                                                        <p>3)사업이 휴/폐업되거나 회사가 해산한 때 또는 파산, 회생절차 개시 등을 신청하거나 기타 신용을 상실한 경우</p>
                                                        <p>4)물건을 회사의 동의 없이 제 3자에게 양도,담보제공,처분,임대 또는 점유를 이전한 경우</p>
                                                        <p>5)회사에 제공한 담보재산 및 기타 재산에 대하여 제3자로부터 압류, 경매 또는 기타 강제집행 등을 당한 경우</p>
                                                        <p>6)고객이 개인 혹은 개인 사업자일 때 사망한 경우 또는 피성년후견,피한정후견의 선고를 받은 경우</p>
                                                        <p>7)허위자료의 제출 등 정당하지 않은 방법으로 계약을 한 사실이 발견된 경우</p>
                                                        <p>8)기타 고객이 본 계약의 채무를 이행할 수없을 것으로 인정되는 상당한 사유가 있는 경우</p>
                                                        <p>2.고객은 회사가 파산또는 청산될 때에 계약을 해지할 수 있다.</p>
                                                        <label>제 11조(기한 이익 상실)</label>
                                                        <p>1.제9조,제10조에 따른 계약 해지의 경우 고객에 대한 기한의 이익은 상실되고 계약 해지로 인하여 발생하는 금원 전부를 고객은 회사에게 즉시 지급하여야 한다.</p>
                                                        <label>제 12조(물건의 반환과 지연 손해금)</label>
                                                        <p>1.고객은 렌탈 기간 만료일 18시까지 물건의 성능이 정상적인 상태로 회사가 지정하는 장소에 고객의 비용으로 회사에 반환한다. 단 본 계약이 해제 또는 해지되면 고객은 해제 또는 해지 당일 18시까지 물건을 전기의 방법으로 반환하여야 한다.</p>
                                                        <p>2.고객은 물건의 반환을 지연한 때에는 반환기한의 다음날부터 반환완료일까지의 추가 렌탈료를 지급해야 한다. 이 경우 앞면에 기재된 렌탈료를 일할 계산하여 적용한다.</p>
                                                        <p>3.렌탈 물건의 반환이 지체되고 있는 도중 렌탈 장비와 관련하여 발생하는 손해애 관하여는 고객에게 책임이 있다.</p>
                                                        <label>제 13조(지연배상금)</label>
                                                        <p>고객이 본 계약에 의한 금전 채무의 이행을 지연할 경우 회사는 연이율 20%의 지연배상금을 고객에게 청구 할 수 있다.</p>
                                                        <label>제 14조(고객의 통지의무)</label>
                                                        <p>고객은 거래에 필요한 명칭,상호,대표자,주소 등의 신고사항을 변경하거나 사업장의 이전,물건설치장소의 변동 등 기타 본 계약의 이행에 영향을 미치는 사유가 발생하였을때는 사유 발생 즉시 회사에 통지하여야 하며 고객의 통지 불이행으로 인하여 발생하는 손실에 대하여 고객이 전적으로 책임을 지기호 한다.</p>
                                                        <label>제 15조(신용정보의 제공)</label>
                                                        <p>회사는 본 계약과 관련하여 고객 및 연대보증인의 객관적 거래 사실에 대한 신용 정보를 신용정보기관이나 계약 관련 기관에 제공할 수 있다.</p>
                                                        <label>제 16조(관할법원)</label>
                                                        <p>본 계약에 관한 분쟁이 발생하여 소송할 필요가 있을 때는 소를 제기하는 자의 주소지를 관할하는 법원을 관할법원으로 한다.</p>
                                                        <label>제 17조(특약사항)</label>
                                                        <p>본 계약에 정하지 않은 사항이나 기타 본 계약의 규정과 다른 사항을 특약사항으로 약정 할 수 있으며 특약사항에 기재되지 않은 사항에 대하여는 회사와 고객이 따로 서면 합의함으로써 그 효력이 발생한다.</p>
                                                        <label>제 18조(보험)</label>
                                                        <p>보험가입은 고객의 선택적 사항이며 보험가입 요청 시 보험료는 추가로 고객이 부담한다.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </body>
                            </html>`;
        
        ReleaseAskDetailStore.contractDocument = content;
        resultArray.push({documentType: 'contractDocument', fileName: `계약서.pdf`, content: content});
    
    }

    {(checkedList.includes('pdfType1') || checkedList.includes('pdfType8')) && 
        dataList.map((data, index) => {
            if (data.isOption === 0) {
                if (checkedList.includes('pdfType1')) {
                    let content = `<!DOCTYPE html>
                                    <html lang="en">
                                        <head>
                                            <meta charset="UTF-8">
                                            <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                            <title>Document</title>
                                            <script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
                                            <style>
                                                .print-template th, .print-template td {
                                                    border-color: #a19f9f !important;
                                                }
                                            </style>
                                        </head>
                                        <body>
                                            <div style="padding: 20px;">
                                                <div class='print-template'>
                                                    <h4 style="text-align: center; margin-bottom: 10; margin-top: 15">고소작업대(T/L) 안전점검 결과서</h4>
                                                    <table class="table" style="border-width: 1">
                                                        <tbody>
                                                            <tr>
                                                                <td class='p-1 border' style="width: 16%; background: lightgray; text-align: center; font-size: 9px">사업장명</td>
                                                                <td class='p-1 border' style="width: 17%; font-size: 9px">${data?.customerName || ''}</td>
                                                                <td class='p-1 border' style="width: 16%; background: lightgray; text-align: center; font-size: 9px">형식</td>
                                                                <td class='p-1 border' style="width: 17%; font-size: 9px">${data?.mdName || ''}</td>
                                                                <td class='p-1 border' style="width: 17%; background: lightgray; text-align: center; font-size: 9px">제조사(렌탈사)</td>
                                                                <td class='p-1 border' style="width: 17%; font-size: 9px">${data?.brandName || ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class='p-1 border' style="background: lightgray; text-align: center; font-size: 9px">사용장소</td>
                                                                <td class='p-1 border' style="font-size: 9px">${data?.siteName || ''}</td>
                                                                <td class='p-1 border' style="background: lightgray; text-align: center; font-size: 9px">동력전달방식</td>
                                                                <td class='p-1 border' style="font-size: 9px">${data?.powerTransmission || ''}</td>
                                                                <td class='p-1 border' style="background: lightgray; text-align: center; font-size: 9px">모델명</td>
                                                                <td class='p-1 border' style="width: 17%; font-size: 9px">${data?.model || ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class='p-1 border' style="background: lightgray; text-align: center; font-size: 9px">운전방식</td>
                                                                <td class='p-1 border' style="font-size: 9px">${data?.drivingMethod || ''}</td>
                                                                <td class='p-1 border' style="background: lightgray; text-align: center; font-size: 9px">운행속도</td>
                                                                <td class='p-1 border' style="font-size: 9px">${data?.drivingSpeed || ''}</td>
                                                                <td class='p-1 border' style="background: lightgray; text-align: center; font-size: 9px">작업대최대높이/적재용량</td>
                                                                <td class='p-1 border' style="width: 17%; font-size: 9px">${data?.heightAndLoadCapacity || ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class='p-1 border' style="background: lightgray; text-align: center; font-size: 9px">차량번호</td>
                                                                <td class='p-1 border' style="font-size: 9px">${data?.managementNo + '(' + data?.serialNo + ')'}</td>
                                                                <td class='p-1 border' style="background: lightgray; text-align: center; font-size: 9px">연식</td>
                                                                <td class='p-1 border' style="font-size: 9px">${data?.manufacturing || ''}</td>
                                                                <td class='p-1 border' style="background: lightgray; text-align: center; font-size: 9px">안전인증년월일</td>
                                                                <td class='p-1 border' style="width: 17%; font-size: 9px">${data?.certificateCheckDate || ''}</td>
                                                            </tr>
                                                            <tr>
                                                                <td class='p-1 border' style="background: lightgray; text-align: center; font-size: 9px">안전점검일</td>
                                                                <td class='p-1 border' style="font-size: 9px">${data?.releaseDate || ''}</td>
                                                                <td class='p-1 border' style="background: lightgray; text-align: center; font-size: 9px">점검회사</td>
                                                                <td class='p-1 border' style="font-size: 9px">${userInfo.companyName || ''}</td>
                                                                <td class='p-1 border' style="background: lightgray; text-align: center; font-size: 9px">점검자</td>
                                                                <td class='p-1 border' style="width: 17%; font-size: 9px">${data?.inspectionManager || ''}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div class="mb-1" style="font-size: 9px">◼︎ 점검유효기간: 점검일로부터 1년</div>
                                                        <table class="table" style="border-width: 1">
                                                            <thead>
                                                                <tr>
                                                                    <th class='p-1 border' style="width: 18%; text-align: center; font-size: 9px">검사부분</th>
                                                                    <th class='p-1 border' style="width: 68%; text-align: center; font-size: 9px">검사항목</th>
                                                                    <th class='p-1 border' style="width: 7%; text-align: center; font-size: 9px">검사결과</th>
                                                                    <th class='p-1 border' style="width: 7%; text-align: center; font-size: 9px">조치사항</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td class='p-1 border'  colspan="4" style="font-size: 9px">1. 공통사항</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' rowspan="3" style="font-size: 9px">(1)등록번호표 등</td>
                                                                    <td class='p-1 border' style="font-size: 9px">제조일로부터 15년 이내의 장비일 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">붕대, 아웃트리거, 용접부등 비과세 검사 성적서 비치되어 있을 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">운전원은 장비의 운전 및 안전에 대한 교육을 받은 유경험자이고 보험(자차 등)에 가입되어 있을 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border'  colspan="4" style="font-size: 9px">2. 차대와 타이어(안정기)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' rowspan="2" style="font-size: 9px">(1)차체 및 타이어(안정기)</td>
                                                                    <td class='p-1 border' style="font-size: 9px">차체의 균열, 변형, 손상 및 부식이 없을 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">타이어의 이상마모 및 변형이 없고, 구동축에서 견고하게 고정되어 있고 림부의 체결볼트, 너트 등이 견고하게 체결될 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' rowspan="4" style="font-size: 9px">(2)동력원</td>
                                                                    <td class='p-1 border' style="font-size: 9px">유압펌프와 모터는 설치상태가 견고하고 작동상태에서 심한 진동과 이상음이 없을 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">유압실린더,유압호스,파이프,밸브,탱크등 연결부는 균열,손상 및 마멸이 없고 기름누출이 없을 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">작동유 탱크는 적정 유량을 유지하고 작동유의 오염이 없을 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">축전지의 단락, 손상 및 단자 부식이 없고 배선부분은 과열에 의한 열화가 없을 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border'  colspan="4" style="font-size: 9px">3. 연장구조물(마스트)</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' rowspan="2" style="font-size: 9px">(1)구조부</td>
                                                                    <td class='p-1 border' style="font-size: 9px">정비를 위해 일정 상승 위치에서 연장 구조물을 고정할 수 있는 고정받침대를 구비할 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px; line-height: 12px">구조물의 균열, 변형 및 손상이 없고 힌지부 연결핀 고정상태가 양호하고 잠금밸브(카운터밸런스밸브)는 정상 작동되고 배관 연결부 등의 누유가 없을 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border'  colspan="4" style="font-size: 9px">4. 작업대</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' rowspan="2" style="font-size: 9px">(1)낙하 및 추락 방호 조치</td>
                                                                    <td class='p-1 border' style="font-size: 9px; line-height: 12px">작업대의 난간높이 1.0m 이상, 발끝막이판 높이 0.15m 이상(출입구는 0.1m 이상)이고 상부난간대와 발끝막이판 사이 0.55m이내의 중간대 설치할 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">바닥면은 배수가 가능하고 미끄럼 방지 구조일 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">(2)접근사다리</td>
                                                                    <td class='p-1 border' style="font-size: 9px">작업대 바닥높이가 지면에서 0.4m 초과시 접근사다리를 설치할 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border'  colspan="4" style="font-size: 9px">5. 제어장치</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' rowspan="2" style="font-size: 9px">(1)제어장치</td>
                                                                    <td class='p-1 border' style="font-size: 9px">조작한 경우에만 작동하고 해제하면 자동으로 중립위치로 복귀하고 작동방향을 문자나 기호로 명확하게 표시되어 있을 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">우발 동작을 방지하기 위한 상호 연동장치를 설치할 것(조종레버의 인에이블 스위치와 발판식 인에이블 스위치)</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border'  colspan="4" style="font-size: 9px">6. 표시</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' rowspan="3" style="font-size: 9px">(1)경고 표시</td>
                                                                    <td class='p-1 border' style="font-size: 9px; line-height: 12px">명판에는 제조자명, 모델명(형식번호), 제조번호, 제조년월, 최대작업높이, 정격하중, 허용탑승인원수, 최대허용풍속, 최대허용경사, 동력원 사양, 안전인증 표시등을 표시할 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">작업대에는 정격하중, 허용탑승인원수, 최대허용풍속(옥내용인 경우 제외)을 표시할 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">비상안전장치의 위치 및 사용법을 표시할 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border'  colspan="4" style="font-size: 9px">7. 점등 및 조명장치 등</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">(1)점등 및 조명장치 등</td>
                                                                    <td class='p-1 border' style="font-size: 9px; line-height: 12px">전조등,미등,측등,계기판등,후퇴등,차폭등,번호등,방향지시등,제동등,작업등,속도표시등등 설치된점등 및 조명장치는 정위치에 견고하게 부착되어 손상이 없어야 하며 점등상태가 양호할 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">(2)계기장치</td>
                                                                    <td class='p-1 border' style="font-size: 9px">연료계,유량계,회전계,압력계등 설치된 계기장치는 작동상태가 양호할 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">(3)경음기 및 경보장치</td>
                                                                    <td class='p-1 border' style="font-size: 9px">경음기 및 경보장치의 음의 크기는  기준의 범위 이내일 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border'  colspan="4" style="font-size: 9px">8. 안전장치</td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">(1)자동안전장치</td>
                                                                    <td class='p-1 border' style="font-size: 9px">작업대가 상승한 상태로 차대 이동시 주행속도를 자동으로 제한하는 장치가 있을 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">(2)경사표시장치(전복방지장치)</td>
                                                                    <td class='p-1 border' style="font-size: 9px">차대의 경사 허용 한도(제작자 기준) 초과시 상승 및 주행이 불가능 하도록 할 것(음향 신호 발생)</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">(3)비상정지장치</td>
                                                                    <td class='p-1 border' style="font-size: 9px">비상정지용 누름버튼은 적색이며 머리부분이 돌출되고 수동으로 복귀되는 형식일 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border' style="font-size: 9px">(3)비상안전장치</td>
                                                                    <td class='p-1 border' style="font-size: 9px">동력공급이 차단되었을 때, 안전하게 작업대를 빠져 나올 수 있는 위치로 작업대를 복귀시킬 수 있는 비상 안전장치를 설치할 것</td>
                                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                                    <td class='p-1 border' style="font-size: 9px"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td class='p-1 border'  colspan="4" style="height: 40; font-size: 9px">검사자 의견</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <p style="font-size: 9px">* 검사결과 표시 : 양호 ○, 조정(보완)△, 교환□, 제작(설치)Φ, 폐기×, 해당무-</p>
                                                    </div>
                                                </div>
                                            </body>
                                        </html>`;

                    ReleaseAskDetailStore.safetyInspectionReportContent += content;
                    resultArray.push({documentType: 'safetyInspectionReport', fileName: `안전점검결과서_${data.managementNo}.pdf`, content: content});
                }

                if (checkedList.includes('pdfType8')) {
                    let content = `<!DOCTYPE html>
                                    <html lang="en">
                                        <head>
                                            <meta charset="UTF-8">
                                            <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                            <title>Document</title>
                                            <script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
                                            <style>
                                                .print-template th, .print-template td {
                                                    border-color: #cccccc !important;
                                                }
                                                td {
                                                    height: 100%
                                                }
                                            </style>
                                        </head>
                                    <body>
                                <div style="padding: 20px;">
                                    <div class='print-template'>
                                        <table class="table" style="border-width: 1">
                                            <thead>
                                                <tr height='20px'>
                                                    <th colspan="12" class='border' style="text-align: center; font-size: 20px; font-weight: bold">
                                                        모델: (${data?.model || '      '}) ▣ 반입 전 CHECK LIST (관리번호: ${data?.managementNo || '      '})
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan="12" class='border' style="text-align: left; font-size: 10px">
                                                        점검일 : ${data?.releaseDate || ''}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="1" class='p-2 border' style="width: 1%; text-align: center; font-size: 9px; font-weight: bold">NO.</td>
                                                    <td colspan="2" class='p-2 border' style="width: 33%; text-align: center; font-size: 9px; font-weight: bold">내용</td>
                                                    <td colspan="2" class='p-2 border' style="width: 10%; text-align: center; font-size: 9px; font-weight: bold">결과</td>
                                                    <td colspan="1" class='p-2 border' style="width: 6%; text-align: center; font-size: 9px; font-weight: bold">수리</td>
                                                    <td colspan="1" class='p-2 border' style="width: 1%; text-align: center; font-size: 9px; font-weight: bold">NO.</td>
                                                    <td colspan="2" class='p-2 border' style="width: 33%; text-align: center; font-size: 9px; font-weight: bold">내용</td>
                                                    <td colspan="2" class='p-2 border' style="width: 10%; text-align: center; font-size: 9px; font-weight: bold">결과</td>
                                                    <td colspan="1" class='p-2 border' style="width: 6%; text-align: center; font-size: 9px; font-weight: bold">수리</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" class='p-1 border' style="width: 20%; text-align: center; font-size: 9px; font-weight: bold">▣ 입고검사</td>
                                                    <td colspan="1" class='p-1 border' style="width: 10%; text-align: center; font-size: 9px; font-weight: bold">검사기준</td>
                                                    <td colspan="1" class='p-1 border' style="width: 6%; text-align: center; font-size: 9px; font-weight: bold">불량</td>
                                                    <td colspan="1" class='p-1 border' style="width: 6%; text-align: center; font-size: 9px; font-weight: bold">양호</td>
                                                    <td colspan="1" class='p-1 border' style="width: 8%; text-align: center; font-size: 9px; font-weight: bold">점검자</td>
                                                    <td colspan="2" class='p-1 border' style="width: 20%; text-align: center; font-size: 9px; font-weight: bold">▣ 전기검사</td>
                                                    <td colspan="1" class='p-1 border' style="width: 10%; text-align: center; font-size: 9px; font-weight: bold">검사기준</td>
                                                    <td colspan="1" class='p-1 border' style="width: 6%; text-align: center; font-size: 9px; font-weight: bold">불량</td>
                                                    <td colspan="1" class='p-1 border' style="width: 6%; text-align: center; font-size: 9px; font-weight: bold">양호</td>
                                                    <td colspan="1" class='p-1 border' style="width: 8%; text-align: center; font-size: 9px; font-weight: bold">점검자</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">1</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">장비외관상태</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                    <td rowspan='9' class='p-2 border' style="text-align: center; font-size: 9px; vertical-align: middle">${data?.inspectionManager}</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">36</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">배터리, 장비 연결잭</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                    <td rowspan='7' class='p-2 border' style="text-align: center; font-size: 9px; vertical-align: middle">${data?.inspectionManager}</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">2</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">스위치류 작동, 외관상태</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">37</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">배터리 터미널 조임</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">3</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">주행전.후진</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">38</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">배터리비중/부하시험(v)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">5.25V이상</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">4</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">리프트업 주행(주행차단)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">39</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">배터리증류수극판위10MM</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">10mm이상</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">보충</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">정상</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">5</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">고속.저속 주행</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">40</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">하부리프트작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">6</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">조향 좌.우회전</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">41</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">경광등</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">7</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">리프트업.다운</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">42</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">노면접지</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">8</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">엔진시동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td colspan="2" class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">▣ 차체</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">불량</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">양호</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">점검자</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">9</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">소음 및 보조지지대 작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">43</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">도장/세차상태</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                    <td rowspan='14' class='p-2 border' style="text-align: center; font-size: 9px; vertical-align: middle">${data?.inspectionManager}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">▣ 세차</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">불량</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">양호</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">점검자</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">44</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">바퀴조임상태</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">10</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">이물질제거</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                    <td rowspan="4" class='p-2 border' style="text-align: center; font-size: 9px; vertical-align: middle">${data?.inspectionManager}</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">45</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">엑슬킹핀</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">11</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">세차</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">46</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">허브어셈블리</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">12</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">차체파손 유,무</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">47</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">타이어 마모정도</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">13</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">에어건조</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">48</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">하부도어 잠금장치</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">▣ 도장</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">불량</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">양호</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">점검자</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">49</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">씨져핀 이상</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">14</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">도장</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                    <td rowspan="4" class='p-2 border' style="text-align: center; font-size: 9px; vertical-align: middle">${data?.inspectionManager}</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">50</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">씨져 외관</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">15</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">차체파손 유,무</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">51</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">풋홀시스템</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">16</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">관리번호(제조번호) 확인</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">52</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">확장대 작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">17</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">스티커 부착상태</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">53</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">확장대 로울러</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">▣ 유압</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">불량</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">양호</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">점검자</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">54</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">확장대 고정핀</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">18</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">유압 오일양(리프트하강후)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                    <td rowspan='9' class='p-2 border' style="text-align: center; font-size: 9px; vertical-align: middle">${data?.inspectionManager}</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">55</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">안전고리(체인/도어)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">19</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">리프트실린더(작동/누유)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">56</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">그리스 주입</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">20</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">비상하강</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td colspan="2" class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">▣ 출고정비</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">불량</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">양호</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">점검자</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">21</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">누유(블록/호스/니쁠)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">57</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">주행(전진/후진)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                    <td rowspan='8' class='p-2 border' style="text-align: center; font-size: 9px; vertical-align: middle">${data?.inspectionManager}</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">22</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">브레이크(작동/누유)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">58</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">리프트(상승/하강)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">23</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">스티어링(작동/누유)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">59</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">주행차단(현장기준)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">24</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">주행모터(작동/누유)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">60</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">감지봉작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">25</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">주행해제(프리휠링벨브)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">61</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">외관상태</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">26</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">비상펌프작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">62</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">배터리충전상태</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">▣ 전기장치</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">불량</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">양호</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">점검자</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">63</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">풋홀시스템</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">27</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">전기배선상태</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                    <td rowspan='9' class='p-2 border' style="text-align: center; font-size: 9px; vertical-align: middle">${data?.inspectionManager}</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">64</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">옵션작동(기능적)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">28</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">콘트롤박스 (작동/스티커)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td colspan="2" class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">▣ 옵션장착</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">불량</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">양호</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px; font-weight: bold">점검자</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">29</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">연결잭(감지봉/풋스위치)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">65</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">보호망(함석 외)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                    <td rowspan='7' class='p-2 border' style="text-align: center; font-size: 9px; vertical-align: middle">${data?.inspectionManager}</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">30</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">과상승방지봉</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">66</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">협착난간대</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">31</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">주행차단</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">67</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">타이어세척</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">32</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">풋스위치</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">68</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">충격흡수</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">33</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">작동알람</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">69</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">용접보호판</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">34</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">충전플러그</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">70</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">부착물(스티커)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">육안</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">35</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">충전기작동값26A이하</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">20.7A</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                        
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">71</td>
                                                    <td class='p-1 border' style="text-align: left; font-size: 9px">낙하물방지턱(현장기준)</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">작동</td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px"> </td>
                                                    <td class='p-1 border' style="text-align: center; font-size: 9px">○</td>
                                                </tr>
                                                <tr>
                                                    <td colspan='12' class='border' style="text-align: center; font-size: 14px">
                                                        ※ 주의 : 1. 기준은 출고시에 점검 체크 기준이며 배터리 충전 상태에 따라 성능이 달라질 수 있습니다.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan='12' class='border' style="text-align: center; font-size: 14px">
                                                        2. 사용중 배터리의 충전상태 장비의 노후 상태에 따라 속도의 차이가 발생할 수 있습니다.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                </body>
                                </html>`;
                    
                    ReleaseAskDetailStore.checkListContent += content;
                    resultArray.push({documentType: 'checkList', fileName: `반입전체크리스트_${data.managementNo}.pdf`, content: content});
            
                };
            }
        })
    }

    return resultArray;
}

const makeReleaseAskPrintGroupByTemplate = () => {
    return 
}


export {makeReleaseAskPrintTemplate}