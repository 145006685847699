import { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import { CContainer} from '../../components/CustomContainer';
import AgGridContainer from '../../components/AgGridContainer';
import { CustomerStore } from '../../store/customer/CustomerStore';
import CustomerDetail from './CustomerDetail';
import { AppStore } from '../../store/AppStore';
import { findCommKrnm } from '../../utils/commcode';
import { dateFormat, dateTimeFormat } from '../../utils/dateUtils';
import LUtils from '../../utils/lodashUtils';
import Regex from '../../common/regex';
import { getUserName } from '../../utils/userUtils';
import CustomerListSearch from "./CustomerListSearch";
import {ContractDetailStore} from "../../store/contract/ContractDetailStore";

const CustomerList = () => {
    const [modalInit, setModalInit] = useState({title: '', openReason:''});
    const [customerNo, setCustomerNo] = useState('');

    const gridRef = useRef();
    const columnDefs = [
        {field: "customerNo", headerName: "고객번호", width: 110},
        {field: "customerType", headerName: "고객유형", width: 110, valueGetter: v => findCommKrnm(v.data.customerType, '19')},
        {field: "customerName", headerName: "고객명", width: 160},
        {
            field: "isAvailableContract",
            headerName: "계약가능",
            width: 110,
            valueGetter: v => v.data.isAvailableContract ? '가능' : '불가능',
        },
        {field: "owner", headerName: "대표자", width: 140},
        {field: "companyType", headerName: "기업형태", width: 120},
        {field: "businessNumber", headerName: "사업자번호", width: 140, valueGetter:v => Regex.makeBusinessNumber(v.data.businessNumber)},
        {field: "corporationNumber", headerName: "법인번호", width: 140},
        {field: "establishmentDate", headerName: "설립일", valueGetter: v => dateFormat(v.data.establishmentDate), width: 120},
        {field: "companyPostcode", headerName: "우편번호", width: 110, hide: ContractDetailStore.simpleView},
        {field: "companyAddress", headerName: "주소", width: 300},
        {field: "companyAddressDetail", headerName: "상세주소", width: 250},
        {field: "employeeCount", headerName: "종업원수", width: 110, hide: ContractDetailStore.simpleView},
        {field: "mainBank", headerName: "주거래은행", width: 120, hide: ContractDetailStore.simpleView},
        {field: "industryType", headerName: "업종", width: 200, hide: ContractDetailStore.simpleView},
        {field: "businessConditions", headerName: "업태", width: 200, hide: ContractDetailStore.simpleView},
        {field: "groupCompany", headerName: "소속그룹", width: 120, hide: ContractDetailStore.simpleView},
        {field: "closedDate", headerName: "폐업일자", width: 120, hide: ContractDetailStore.simpleView},
        {field: "updater", headerName: "수정자", valueGetter: v => getUserName(v.data.updater)},
        {field: "updatedDate", headerName: "수정일", valueGetter: v => dateTimeFormat(v.data.updatedDate), width: 180},
    ];

    const getCustomerList = async () => {
        const { isAvailableContract, isClosedCustomer, businessNumber, ...params } = CustomerStore.searchCondition;
        isAvailableContract !== '' && LUtils.assign(params, { isAvailableContract: isAvailableContract ? 1 : 0 });
        isClosedCustomer !== '' && LUtils.assign(params, { isClosedCustomer: isClosedCustomer ? true : false });
        businessNumber !== '' && LUtils.assign(params, { businessNumber: businessNumber.replaceAll('-', '') });

        const customerList = await axios.get('/customer/list', { params });
        CustomerStore.setCustomerList(LUtils.isEmpty(customerList) ? [] : customerList);
    }

    const openModalForAddCustomer = (type) => {
        if (type === 'create') {
            setCustomerNo('');
            setModalInit({title: '고객등록', openReason: 'create'});
        }
        type === 'read' && setModalInit({title: '고객상세', openReason: 'read'});
        AppStore.toggleCustomerModal();
    }

    const openCustomerDetail = (e) => {
        setCustomerNo(e.data.customerNo);
        openModalForAddCustomer('read');
    }

    return (
        <>
            {/* 검색조건 */}
            <CustomerListSearch getCustomerList={getCustomerList} openModalForAddCustomer={openModalForAddCustomer} />

            {/* grid */}
            <CContainer>
                <AgGridContainer
                    gridTitle='고객목록'
                    gridRef={gridRef}
                    height={60}
                    rowData={CustomerStore.customerList}
                    columnDefs={columnDefs}
                    isCheckBox={false}
                    seqColumn={'customerNo'}
                    useCsvDownload={true}
                    //useRowSearch={true}
                    rowSearchCallback={openCustomerDetail}
                    rowDoubleClickCallback={openCustomerDetail}
                />
            </CContainer>

            {/* 고객상세 팝업 */}
            <CustomerDetail
                title={modalInit.title}
                openReason={modalInit.openReason}
                customerNo={customerNo}
                getCustomerList={getCustomerList}
                setCustomerNo={setCustomerNo}
            />
        </>
    );
};

export default observer(CustomerList);
