import { observable } from "mobx";

export const ShipStore = observable({
    shipList: [],
    search : {
        startDate : '',
        endDate : '',
        contractNo : '',
        customerNo : '',
        customerName : '',
        shipStatus : '',
        saleUserSeq : null,
        saleUserName : '',
        shipType : '',
        releaseAskStatus : '',
        releaseAskSeq : null,
        areaCode: '',
        shipHopeTime : '',
        deliveryUserSeq : null,
        deliveryUserName : '',
        invoice: '',
        shipRemark: '',
        outsourcingCompany: '',
        outsourcingDriver: '',
        division: '',
    },
    shipSeq: '',
    data: {
        shipSeq: '',
        outsourcingCompany: '', 
        outsourcingAmount: '', 
        outsourcingCarTonnage: '', 
        outsourcingCarNumber: '', 
        outsourcingDriver: '', 
        outsourcingPhoneNumber: '', 
        outsourcingRemark: '',
        shipSeqList: [],
    },  

    setShipList(v){
        this.shipList = v;
    },
    dataInit() {
        this.data.shipSeq = '';
        this.data.outsourcingCompany = ''; 
        this.data.outsourcingAmount = ''; 
        this.data.outsourcingCarTonnage = ''; 
        this.data.outsourcingCarNumber = ''; 
        this.data.outsourcingDriver = ''; 
        this.data.outsourcingPhoneNumber = ''; 
        this.data.outsourcingRemark = '';
        this.data.shipSeqList = [];
    }
})
