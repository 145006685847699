import { useEffect, useRef } from "react";
import { observer } from 'mobx-react-lite';
import { Row } from "react-bootstrap";
import axios from "axios";
import { findCommKrnm } from "../../../utils/commcode";
import AgGridContainer from "../../../components/AgGridContainer";
import { ContractTabsStore } from "../../../store/contract/ContractTabsStore";
import {getUserName} from "../../../utils/userUtils";


const ContractHistory =({ contractNo, ...props }) => {
    const gridRef = useRef();

    useEffect(() => {
        getContractHistory(contractNo);

        return () => {
            ContractTabsStore.setContractHistoryList([]);
        }
    }, []);

    const getContractHistory = async (contractNo) => {
        if (!contractNo) {
            return false;
        }

        const result = await axios.get(`/contract/${contractNo}/history`);
        ContractTabsStore.setContractHistoryList(result);
    }

    return (
        <Row>
            <AgGridContainer
                gridRef={gridRef}
                height={40}
                rowData={ContractTabsStore.contractHistoryList}
                columnDefs={[
                    {field: "contractHistorySeq", hide: true},
                    {field: "contractSeq", headerName: "회차", width: 80},
                    {field: "contractType", headerName: "계약구분", width: 120, valueGetter: v => findCommKrnm(v.data.contractType, '1')},
                    {field: "rentMonth", headerName: "계약기간(월)", width: 130},
                    {field: "rentDays", headerName: "계약기간(일)", width: 130},
                    {field: "monthRentPrice", headerName: "월 렌탈료", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                    {field: "dayRentPrice", headerName: "일 렌탈료", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                    {field: "contractDate", headerName: "계약일자", width: 130},
                    {field: "contractStartDate", headerName: "계약개시일", width: 130},
                    {field: "contractEndDate", headerName: "계약종료일", width: 130},
                    {field: "saleUserName", headerName: "영엄담당자", width: 120,},
                    /*{field: "supportUserName", headerName: "지원담당자", width: 110,},*/
                    {field: "creator", headerName: "변경자", valueFormatter: v => getUserName(v.value)},
                    {field: "createdDate", headerName: "변경일자", width: 180, flex: 1}
                ]}
                seqColumn={'contractHistorySeq'}
                useIntoTheTab={true}
                useCsvDownload={true}
            />
        </Row>
    );
}

export default observer(ContractHistory);
