import { observer } from "mobx-react-lite";
import {CContainer, CCol, CInputGroup, CSearchBtn, CalendarGroup, CSelectGroup} from "../../components/CustomContainer";
import { Row, Col } from "react-bootstrap";
import { AppStore } from "../../store/AppStore";
import {AssetMeterListStore} from "../../store/asset/AssetMeterListStore";
import {getCommcode} from "../../utils/commcode";
import {useEffect} from "react";
import {endDateForThisMonth, startDateForThisMonth} from "../../utils/dateUtils";

const AssetMeterListSearch = ({getAssetMeterList}) => {

    useEffect(()=> {
        AssetMeterListStore.setStartDate({startDate: startDateForThisMonth()})
        AssetMeterListStore.setEndDate({startDate: endDateForThisMonth()})
    }, [])
    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'시작일'}
                            value={AssetMeterListStore.startDate}
                            onChange={v => AssetMeterListStore.setStartDate(v)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'종료일'}
                            value={AssetMeterListStore.endDate}
                            onChange={v => AssetMeterListStore.setEndDate(v)}
                        />
                    </CCol>

                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getAssetMeterList} />
                    </Col>
                </Row>
            </CContainer>
            {/* popup */}
        </>
    );

}

export default observer(AssetMeterListSearch);
