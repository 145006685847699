import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { AppStore } from "../../../store/AppStore";
import { CContainer, CCol, CInputGroup, CSaveBtn } from "../../../components/CustomContainer";
import { Row, Button, Col } from "react-bootstrap";
import { BillSearchStore } from "../../../store/billing/BillSearchStore";
import AgGridContainer from "../../../components/AgGridContainer";
import Modal from "../../../components/Modal";
import { getTodayDay, getTodayMonth } from "../../../utils/dateUtils";
import { showToast } from "../../../common/utils";
import { callConfirm } from "../../../utils";
import axios from "axios";
import { escape } from "lodash";

const BillItemPopup = ({callbackFn=null, selView='', chargeTotalAmount, contractNo, billingSeq, getBillingChargeItemList, accountBillNote, getBillingTargetList}) => {
    const gridRef = useRef();
    const dataListRef = useRef();
    const [billNote, setBillNote] = useState('');
    const [realChargeTotalAmount, setRealChargeTotalAmount] = useState(0);
    const [changeRemark, setChangeRemark] = useState('');
    const [chargeAmount, setChargeAmount] = useState(0);
    const [chargeVat, setChargeVat] = useState(0);

    useEffect(() => {
        setBillNote(accountBillNote);
        setChangeRemark(BillSearchStore.targetBillItemList[0]?.changeRemark ? BillSearchStore.targetBillItemList[0].changeRemark : '');
        amountChangeFn();
    }, [AppStore.isOpenBillItemPopup]);

    const callBackGridData = async () => {
        const isChanged = BillSearchStore.targetBillItemList.some(v => v.isUpdated || v.isCreated);
        const selectedList = BillSearchStore.targetBillItemList.filter(v => v.item !== '').map(v => {return {...v, 
                                                                                                            orgBillNote: billNote,
                                                                                                            supplyAmount: Number(v.unitAmount) * Number(v.qty),
                                                                                                            taxAmount: (Number(v.unitAmount) * Number(v.qty)) * 0.1,
                                                                                                            changeChargeAmount: Number(v.unitAmount) * Number(v.qty),
                                                                                                            changeChargeVat: (Number(v.unitAmount) * Number(v.qty)) * 0.1,
                                                                                                            changeRemark: changeRemark,
                                                                                                        }}).sort((a, b) => a.seq - b.seq);
                                                                                                        
        if (!selectedList.every(v => (v.qty && Number(v.qty) > 0) && (v.unitAmount && Number(v.unitAmount) > 0) && (v.supplyAmount && Number(v.supplyAmount) > 0) && (v.taxAmount && Number(v.taxAmount) > 0))) {
            showToast('수량, 단가, 공급가, 세액을 모두 입력해주세요');
            return false;
        }

        if (chargeTotalAmount !== realChargeTotalAmount) {
            // if (!changeRemark) {
            //     showToast('변경 사유를 입력해주세요.');
            //     return false;
            // }
            if (!await callConfirm('원청구금액과 실제 청구금액이 다릅니다. 변경청구금액으로 적용하시겠습니까?')){
                return false;
            }
        }

        const data = {
            selectedList, 
            billNote: billNote,
            changeChargeAmount: chargeAmount,
            changeChargeVat: chargeVat,
            changeChargeTotalAmount: chargeTotalAmount,
            changeRemark: changeRemark,
            isChanged: isChanged,
        };

        callbackFn && callbackFn(data);
        AppStore.toggleOpenBillItemPopup();
    }


    const amountChangeFn = () => {
        let amount = 0;
        let vat = 0;
    
        amount = BillSearchStore.targetBillItemList.reduce((total, v) => total + (Number(v.qty) * Number(v.unitAmount)), 0);
        vat = BillSearchStore.targetBillItemList.reduce((total, v) => total + (Number(v.qty) * Number(v.unitAmount) * 0.1), 0);

        setChargeAmount(amount);
        setChargeVat(vat);
        setRealChargeTotalAmount(amount);
    }

    const saveBtn = {
        isUsed: selView === 'billing',
        callbackFn:() => callBackGridData(),
        title: BillSearchStore.condition.billPublishType === '1' ? '저장' : '통합발행',
        width: 90,
        variant: 'success'
    }

    const addBtn = {
        isUsed: selView === 'billing',
        callbackFn:(v) => {
            if(BillSearchStore.targetBillItemList.length < 4) {
                const addRowInfo = {   
                    billNo: '',
                    seq: BillSearchStore.targetBillItemList.length + 1,
                    month: '',
                    day: '',
                    item: '',
                    qty:  '',
                    unitAmount: '',
                    taxAmount: '',
                    supplyAmount: '',
                    billItemNote: '',
                }
                dataListRef.current.addRow(addRowInfo);
            }
            else {
                showToast('최대 4건까지만 입력 가능합니다.');
            }
        },
        title: '추가',
        width: 90,
        variant: 'danger'
    }

    const getBillItemTextBtn = {
        isUsed: BillSearchStore.condition.billPublishType === '1' && selView === 'billing',
        callbackFn: () => getBillItemText(),
        title: '전월품목적용',
        width: 90,
    }

    const getBillItemText = async(v) => {
        const result = await axios.get(`bill/item/pre/${contractNo}`, {params: {billingSeq: billingSeq}});
        if (result.length > 0) {
            const list = result.map(v => {return {...v, isUpdated: true}});
            BillSearchStore.setTargetBillItemList(list);
            amountChangeFn();
        } else {
            showToast('전월 품목이 존재하지 않습니다.');
        }
    }

    const checkByteLength = (e) => {
        // return;
        let str = e.newValue;
        let byteLength = 0;
        let slicedStr = "";

        for (let i = 0; i < str.length; i++) {
            const charCode = str.charCodeAt(i);
            const charUnicode = escape(charCode);   // 유니코드 형식으로 변환
            if (charUnicode.length > 4) {
                byteLength += 2;  // 2바이트 문자 (한글 포함)
            } else {
                byteLength += 1;  // 1바이트 문자 (ASCII)
            }

            if (byteLength > 100) {
                break;
            }

            slicedStr += str[i];
        }

        if (byteLength > 100) {
            showToast('제한된 글자수를 초과하였습니다.');
            e.data.billItemNote = slicedStr;
        } else {
            e.data.billItemNote = e.newValue;
        }
    }

    const deleteItem = async (e) => {
        const { billingMonth, contractNo, billingType, seq, agId } = e.data;
        
        if (BillSearchStore.targetBillItemList.length === 1) {
            showToast('기본 품목으로 삭제할 수 없습니다.');
            return;
        }
        
        if(!await callConfirm(`품목을 삭제하시겠습니까?`)) return;
        
        if(!billingMonth || !contractNo || !billingType || !seq) {
            const selectedData = gridRef.current.api.getSelectedRows();
            gridRef.current.api.applyTransaction({remove: selectedData});
            const itemList = BillSearchStore.targetBillItemList.filter(v => v.agId !== agId);
            BillSearchStore.setTargetBillItemList(itemList);
        } else {
            await axios.delete(`/bill/item`, {params: {billingMonth: billingMonth, contractNo: contractNo, billingType: billingType, seq: seq}});
            const itemList = await getBillingChargeItemList(billingMonth, contractNo, billingType);
            BillSearchStore.setTargetBillItemList(itemList);
        }

        showToast('삭제되었습니다.');
        getBillingTargetList();
        amountChangeFn();
    }

    return (
        <>
            <Modal title={'품목설정'}
                    show={AppStore.isOpenBillItemPopup}
                    onHide={() => AppStore.toggleOpenBillItemPopup()}
                    toggleValue={AppStore.isOpenBillItemPopup}
                    className={'modal-w9'}
                    onExit={() => {
                        if (BillSearchStore.condition.billPublishType === '2') {
                            BillSearchStore.setTargetBillItemList([]);
                        }
                        setChangeRemark('');
                    }}
            >
                <CContainer search>
                    <Row>
                        <CCol lg={12}>
                            <CInputGroup
                                label={'계산서 비고'}
                                labelId={'billNote'}
                                placeholder={'계산서 비고를 입력해주세요.'}
                                value={billNote}
                                onChange={(e)=> setBillNote(e.target.value)}
                                disabled={selView !== 'billing'}
                            />
                        </CCol>
                    </Row>
                    {selView === 'billing' && 
                    <>
                        <Row>
                            <CCol lg={6}>
                                <CInputGroup
                                    label={'원청구금액'}
                                    labelId={'chargeTotalAmount'}
                                    style={{flex: 1, textAlign: 'right'}}
                                    value={chargeTotalAmount.toLocaleString()}
                                    onChange={() => {}}
                                    align='right'
                                    disabled={true}
                                />
                            </CCol>
                            <CCol lg={6}>
                                <CInputGroup
                                    label={'실제청구금액'}
                                    labelId={'realChargeTotalAmount'}
                                    style={{flex: 1, textAlign: 'right'}}
                                    value={realChargeTotalAmount.toLocaleString()}
                                    align='right'
                                    disabled={true}
                                />
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={12}>
                                <CInputGroup
                                    label={'변경사유'}
                                    labelId={'changeRemark'}
                                    labelClassName='input-editable'
                                    placeholder={'변경사유를 입력해주세요.'}
                                    value={changeRemark}
                                    onChange={(e)=> setChangeRemark(e.target.value)}
                                />
                            </CCol>
                        </Row>
                    </>}
                </CContainer>
                <CContainer>
                  <AgGridContainer
                        ref={dataListRef}
                        gridRef={gridRef}
                        height={27}
                        rowData={BillSearchStore.targetBillItemList}
                        columnDefs={
                            [
                                {field: "billNo", headerName: "계산서번호", width: 65, hide: true},
                                {field: "seq", headerName: "순번", width: 65, hide: true},
                                {field: "month", headerName: "월", width: 65, valueFormatter: v => v.data.item? !v.value ? getTodayMonth() : v.value : ''},
                                {field: "day", headerName: "일", width: 65, valueFormatter: v => v.data.item? !v.value ? getTodayDay() : v.value : ''},
                                {field: "item", headerName: "품목", width: 330, editable: selView === 'billing', headerClass: 'grid-column-required',},
                                {field: "qty", headerName: "수량", width: 80, onCellValueChanged:amountChangeFn, editable: selView === 'billing', headerClass: 'grid-column-required',},
                                {field: "unitAmount", headerName: "단가", width: 130, onCellValueChanged:amountChangeFn, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', editable: selView === 'billing', headerClass: 'grid-column-required',},
                                {field: "supplyAmount", headerName: "공급가액", width: 130, valueFormatter: v => v.value?.toLocaleString(), valueGetter: v => v.value = v.data.unitAmount * v.data.qty, cellClass: 'ag-grid-money-align', editable: false},
                                {field: "taxAmount", headerName: "세액", width: 130, valueFormatter: v => v.value?.toLocaleString(), valueGetter: v => v.value = (v.data.unitAmount * v.data.qty) * 0.1, cellClass: 'ag-grid-money-align', editable: false},
                                {field: "billItemNote", headerName: "비고", width: 400, editable: selView === 'billing', onCellValueChanged: checkByteLength},
                            ]
                        }
                        seqColumn={'agId'}
                        // useCreated={selView === 'billing'}
                        // addRowInfo={addRowInfo}
                        btnTitles={selView === 'billing' && BillSearchStore.condition.billPublishType === '1' ? {save: '저장'} : BillSearchStore.condition.billPublishType === '2' ? {save: '통합발행'} : ''}
                        customBtnInfo={[saveBtn, addBtn, getBillItemTextBtn]}
                        useRemoveRow={selView === 'billing' ? true : false}
                        removeColumnFront={true}
                        removeCallback={deleteItem}
                        // isVisibleTotalCount={false}
                        // isCheckBox={true}
                    />
                {/* <Row className="mt-3">
                    <Col className='d-flex flex-row-reverse'>
                        <CSaveBtn onClick={() => callBackGridData()} style={{width: 150}} title={'확인'}/>
                    </Col>
                </Row> */}
                </CContainer>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={()=> AppStore.toggleBillPublishPopup()}>
                        취소
                    </Button>
                    <Button variant="primary"
                            onClick={()=> {
                                AppStore.toggleBillPublishPopup();
                                callbackFn(item, qty);
                            }}>
                        발급
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    );
}

export default observer(BillItemPopup);
