import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';

import { CContainer, IconBtn } from '../../components/CustomContainer';
import AgGridContainer from '../../components/AgGridContainer';
import { oneMonthAgo, today } from '../../utils/dateUtils';
import { MaintenanceStore } from '../../store/asset/MaintenanceStore';
import MaintenanceSearch from './MaintenanceSearch';
import { showToast } from '../../common/utils';
import Modal from "../../components/Modal";
import { AppStore } from '../../store/AppStore';
import MaintenanceDetail from './MaintenanceDetail';
import AsDetail from './AsDetail';
import { AsStore } from '../../store/asset/AsStore';
import { useLocation } from "react-router-dom";
import LUtils from "../../utils/lodashUtils";

const MaintenanceList = () => {
    const store = MaintenanceStore;
    const location = useLocation();

    const [searchUser, setSearchUser] = useState('');
    const [maintenanceCodeList, setMaintenanceCodeList] = useState([]);
    const gridRef = useRef();

    useEffect(() => {
        // 한 달 날짜 세팅
        store.search.startDate = oneMonthAgo(today());
        store.search.endDate = today();

        const fromPage = LUtils.get(location.state, 'from', null) ;
        if(fromPage === 'dashboard'){
            store.search.startDate = today();
            getMaintenanceList()
        }

        getManaitenanceCode();
    }, []);

    const getManaitenanceCode = async () => {
        const result = await axios.get("/as/maintenanceCode");
        setMaintenanceCodeList(result);
    }

    const columnDefs = [
        {field: "maintenanceSeq", headerName: "정비번호"},
        {field: "maintenanceStatusName", headerName: "상태", width: 90},
        {field: "assetNo", headerName: "자산번호", width: 120},
        {field: "managementNo", headerName: "관리번호"},
        {field: "asSeq", headerName: "AS번호", cellRenderer: e => asLink(e)},
        {field: "content", headerName: "내용", width: 350},
        {field: "amount", headerName: "수리비", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
        {field: "mechanicName", headerName: "정비자"},
        {field: "consumables", headerName: "소모품명", width: 200},
        {field: "maintenanceDate", headerName: "정비완료일자", width: 180, flex: 1},
    ];

    const getMaintenanceList = async () => {
        const resultList = await axios.get('/asset/maintenance/list', { params: store.search });
        store.maintenanceList = resultList;

        if(resultList.length === 0) return showToast('조회된 데이터가 없습니다.');
    };

    /* AS 페이지로 이동 버튼 */
    const asLink = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.asSeq}</div>
                {e.data.asSeq &&
                    <IconBtn key={e.rowIndex} onClick={() => asLinkBtn(e.data.asSeq)}/>
                }
            </div>
        )
    }

    const asLinkBtn = async (asSeq) => {
        const result = await axios.get('/as/detail', {params: {asSeq: asSeq}});
        AsStore.data = result;

        // 상세 모달 열기
        AppStore.toggleAsDetail();
    }

    /* 정비 상세 */
    const onRowDoubleClicked = (e) => {
        store.detail.maintenanceSeq = e.data.maintenanceSeq;
        store.detail.managementNo = e.data.managementNo;
        store.status = 'detail';
        AppStore.toggleModal();
    }

    const closedModal = () => {
        // 상세 초기화
        store.detailInit();
    };

    return (
        <>
            <MaintenanceSearch getMaintenanceList={getMaintenanceList} searchUser={searchUser} setSearchUser={setSearchUser}/>
           
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={60}
                    gridTitle={'정비내역'}
                    rowData={store.maintenanceList}
                    columnDefs={columnDefs}
                    useCsvDownload={true}
                    rowDoubleClickCallback={e => onRowDoubleClicked(e)}
                />
            </CContainer>

            <Modal title={'정비상세'} onExit={closedModal} size={'lg'}>
                <MaintenanceDetail getMaintenanceList={getMaintenanceList} searchUser={searchUser} setSearchUser={setSearchUser} maintenanceCodeList={maintenanceCodeList}/>
            </Modal>
            <AsDetail />
        </>
    );
};

export default observer(MaintenanceList);
