import { observable } from "mobx";
import { today } from "../../utils/dateUtils";

export const StorageConsumOptionStore = observable({
    data : {
        storageType: '',
        lgCate: '',
        productSeq: '',
        managementNo: '',
        assetNo: '',
        serialNo: '',
        productName: '',
        model: '',
        spec: '',
        assetType: '1',
        buyCustomerNo: '',
        buyCustomerName: '',
        powerTransmission: '',
        drivingSpeed: '',
        workingMaximumHeight: '',
        loadCapacity: '',
        drivingMethod: '',
        hireEndDate: '',
        manufacturing: '',
        certificateCheckDate: '',
        assetCertificateUrl: '',
        assetCertificateFileName: '',
        insurancePolicyUrl: '',
        insurancePolicyFileName: '',
        storageDate: today(),
        amount: '',
        newOrUsed: '1',
        remark: '',
        qty: 1,
        isConsumable: '',
        isOption: '',
        division: '',
        apronSeq: null,
    },
    targetList: [],


    init() {
        this.data.storageType = '';
        this.data.lgCate = '';
        this.data.productSeq = '';
        this.data.managementNo = '';
        this.data.assetNo = '';
        this.data.serialNo = '';
        this.data.productName = '';
        this.data.model = '';
        this.data.spec = '';
        this.data.assetType = '1';
        this.data.buyCustomerNo = '';
        this.data.buyCustomerName = '';
        this.data.powerTransmission = '';
        this.data.drivingSpeed = '';
        this.data.workingMaximumHeight = '';
        this.data.loadCapacity = '';
        this.data.drivingMethod = '';
        this.data.hireEndDate = '';
        this.data.manufacturing = '';
        this.data.certificateCheckDate = '';
        this.data.assetCertificateUrl = '';
        this.data.assetCertificateFileName = '';
        this.data.insurancePolicyUrl = '';
        this.data.insurancePolicyFileName = '';
        this.data.storageDate = today();
        this.data.amount = '';
        this.data.newOrUsed = '1';
        this.data.remark = '';
        this.data.qty = 1;
        this.data.isConsumable = 1;
        this.data.isOption = 1;
        this.data.division = '';
    },
    pushTargetList(v) {
        this.targetList.push(v);
    },
});
