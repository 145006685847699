import { observer } from "mobx-react-lite"
import AgGridContainer from "../../../components/AgGridContainer";
import { useEffect, useRef, useState } from "react";
import { NonpaymentStore } from "../../../store/billing/NonpaymentStore";
import { getUserName } from "../../../utils/userUtils";
import { AppStore } from "../../../store/AppStore";
import CertificationContentDetailPopup from "../popup/CertificationContentDetailPopup";
import { IconBtn } from "../../../components/CustomContainer";
import axios from "axios";

const CertificationContentHistoryList = ({getCertificateonContentHistoryList}) => {
    const gridRef = useRef();
    const [htmlString, setHtmlString] = useState('');
    const [emailHistorySeq, setEmailHistorySeq] = useState(0);

    useEffect(() => {
        getCertificateonContentHistoryList();
    }, [])

    const openCertificationContentDocument = (seq) => {
        getCertificationContentDetail(seq).then(()=>AppStore.toggleOpenCertificationContentDetailPopup());
    }

    const openDocument = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <IconBtn style={{paddingTop: 6, color:'darkblue', textAlign: 'left'}} 
                    key={e.rowIndex} 
                    iconClass={'fi fi-rs-document'}
                    onClick={() => openCertificationContentDocument(e.data.emailHistorySeq)} 
                />
            </div>
        )
    }

    const getCertificationContentDetail = async (emailHistorySeq) => {
        const result = await axios.get("/nonpayment/certificationContent/detail", {params: {emailHistorySeq: emailHistorySeq}});
        setHtmlString(result);
    }

    return (
        <>
            <AgGridContainer
                gridRef={gridRef}
                rowData={NonpaymentStore.emailHistoryList}
                height={25}
                columnDefs={
                    [
                        {field: "seq", headerName: "순번", valueGetter: 'node.rowIndex + 1', width: 80},
                        {field: "emailHistorySeq", headerName: "이메일내역seq", width: 80, hide: true},
                        // {field: "subject", headerName: "제목", width: 200},
                        // {field: "content", headerName: "내용", width: 100, valueFormatter: v => v.value.substring(0, v.value.indexOf('<br/><br/><br/><br/>')), flex: 1},
                        {field: "createdDate", headerName: "발송일자", width: 180},
                        {field: "creator", headerName: "발송인", valueFormatter: v => getUserName(v.value), width: 180},
                        {field: "receiverName", headerName: "수신인", width: 250, flex: 1},
                        {field: "html", headerName: "", width: 60, cellRenderer: (v) => openDocument(v)}
                    ]
                }
            />
            <CertificationContentDetailPopup emailHistorySeq={emailHistorySeq} htmlString={htmlString}/>
        </>
    )
}

export default observer(CertificationContentHistoryList);