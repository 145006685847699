import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";
import { anyMonthAgo, endDateForThisMonth, startDateForMonth, startDateForThisMonth, today } from "../../utils/dateUtils";

export const ContractListStore = observable({
    contractList: [],
    searchCondition: { contractNo: '', customerNo: '', customerName: '', billingAddressSeq: null, billingAddressName: '', contractType: '',
                        saleUserSeq: null, saleUserName:'', supportUserSeq: null, supportUserName: '', assetNo: '', preContractNo: '',
                        isZeroPrice: 0, managementNo: '', isTransferContract: 0, siteName: '', siteSeq: '', endDate: null, apronSeq: ''},
    contractStartDate: { startDate: startDateForMonth(anyMonthAgo(today(), 2)), endDate: '' },
    contractEndDate: { startDate: endDateForThisMonth(), endDate:'' },

    setContractList(v){
        this.contractList = v;
    },
    setSearchCondition(v) {
        LUtils.assign(this.searchCondition, v);
    },
    setStartDate(v) {
        this.contractStartDate = v;
    },
    setEndDate(v) {
        this.contractEndDate = v;
    },
});
