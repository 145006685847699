import {useState, useEffect} from "react";
import { observer } from "mobx-react-lite";
import { Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import axios from "axios";
import dayjs from "dayjs";

import {CCol, CInputGroup, CSaveBtn, CSelectGroup, Subject, IconBtn} from "../../../components/CustomContainer";
import { ContractCreateDetailStore } from "../../../store/contract/ContractCreateDetailStore";
import {getCommcode} from "../../../utils/commcode";
import { callConfirm } from "../../../utils";
import { getDays, dateFormat } from "../../../utils/dateUtils";
import LUtils from "../../../utils/lodashUtils";
import CustomerBillingAddressSearch from "../../../components/searchModal/CustomerBillingAddressSearch";
import { AppStore } from "../../../store/AppStore";
import { CommcodeSMOptions } from "../../../components";
import {ICON_TRASH, MULTIPART_HEADER,} from "../../../common/constants";
import {showToast, insertComma, removeComma} from "../../../common/utils";
import CustomerManagerSearch from "../../../components/searchModal/CustomerManagerSearch";
import SiteNameSearch from "../../../components/searchModal/SiteNameSearch";
import { useNavigate } from "react-router-dom";
import {companyContractSpecialRemark} from '../CompanyContractSpecialRemark';

const ContractCreationInfo = ({contractDate, contractStartDate, contractEndDate, isOptionCost, setIsOptionCost, optionList, setOptionList }) => {
    const navigate = useNavigate();

    const [modifyFile, setModifyFile] = useState(false);

    useEffect(()=> {
        ContractCreateDetailStore.setContractDetail(dayjs(contractDate).format("YYYY-MM-DD"), 'contractDate');
        ContractCreateDetailStore.setContractDetail(dayjs(contractStartDate).format("YYYY-MM-DD"), 'contractStartDate');
    }, [ContractCreateDetailStore.contractProductList, contractEndDate, contractDate])

    useEffect(()=> {
        //고객별 특약사항 입력
        if(companyContractSpecialRemark()){
            ContractCreateDetailStore.setContractDetail(companyContractSpecialRemark(), 'specialRemark');
        }
    }, [])

    /* 계약저장 */
    const saveContract = async () => {
        const saveColumn = ['contractType','customerNo', 'saleUserSeq', 'supportUserSeq', 'rentMonth','rentDays',
        'contractDate','contractStartDate','contractEndDate','billingAddressSeq','contractDocType','contractStartType','installPlace','isPenaltyFee','penaltyPercent',
        'isExtendRate','extendFirstRate','extendSecondRate','isRepairCost', 'isInstallPrice', 'installPrice','specialRemark','billingType','billingDay','dueDateType','dueDateDay','paymentType',
        'deposit','endDate','isReverseBill','isFreeAssignment','isVirtualRelease', 'returnExpectDate', 'contractDocUrl', 'contractDocFileName', 'isDeliveryPrice', 'deliveryPrice', 'sellType', 'estimateNo', 'siteSeq',
        'chargeDelivery', 'deliveryKind','firstBillingType','truncationUnit','dayOfCalculation','isMaintenance','maintenanceCycleMonth'];

        let mustInputColumn = ['customerNo', 'saleUserSeq', 'siteSeq', 'contractDate','contractStartDate', 'billingAddressSeq'];
        if(ContractCreateDetailStore.contractType === '3' || ContractCreateDetailStore.contractType === '4'){
            mustInputColumn = ['customerNo', 'saleUserSeq', 'contractDate','contractStartDate', 'billingAddressSeq'];
        }
        //회수예정일세팅
        // ContractCreateDetailStore.setContractDetail(dateFormat(returnExpectDate.startDate), 'returnExpectDate');

        if(!ContractCreateDetailStore.contractEndDate){
            showToast('계약 시작일을 입력해주세요.');
            return;
        }

        // 날짜 세팅
        ContractCreateDetailStore.contractDate = dateFormat(ContractCreateDetailStore.contractDate);
        ContractCreateDetailStore.contractStartDate = dateFormat(ContractCreateDetailStore.contractStartDate);
        ContractCreateDetailStore.contractEndDate = dateFormat(ContractCreateDetailStore.contractEndDate);
        //금액 숫자로 변경
        ContractCreateDetailStore.deliveryPrice = removeComma(ContractCreateDetailStore.deliveryPrice);
        ContractCreateDetailStore.installPrice = removeComma(ContractCreateDetailStore.installPrice);
        ContractCreateDetailStore.assignmentAmount = removeComma(ContractCreateDetailStore.assignmentAmount);
        ContractCreateDetailStore.deposit = removeComma(ContractCreateDetailStore.deposit);

        const params = LUtils.pick(ContractCreateDetailStore, saveColumn);

        // validate check
        if(params.contractType === '1' || params.contractType === '2'){
            if (!!params.isPenaltyFee && !params.penaltyPercent) {
                showToast('위약금을청구 하는 경우에 위약금율을 반드시 입력 해야 합니다.');
                return false;
            }

            if (!!params.isExtendRate && !LUtils.some([!!params.extendFirstRate, !!params.extendSecondRate])) {
                showToast('연장할인을 하는 경우 요율을 반드시 입력 해야 합니다.');
                return false;
            }
        }

        if (!params.rentMonth && !params.rentDays) {
            showToast('계약기간은 반드시 입럭되어야 합니다.');
            return false;
        }

        if (params.rentMonth === '0' && params.rentDays === '0') {
            showToast('계약기간이 1일 이상이어야 합니다.');
            return false;
        }

        if(ContractCreateDetailStore.contractType === '4' && !ContractCreateDetailStore.sellType){
            showToast('매각구분을 반드시 선택해야 합니다.');
            return false;
        }

        if (!LUtils.every(LUtils.values(LUtils.pick(params, mustInputColumn)))) {
            showToast('필수값은 모두 입력 해야 합니다.');
            return false;
        }

        if(!ContractCreateDetailStore.contractProductList.every(v => v.productSeq)){
            showToast('제품코드가 입력되지 않은 제품이 있습니다.');
            return;
        }

        if(isOptionCost === 1) {
            const hasEmptyOptionCode = optionList.some(option => option.optionCode === '');
            if(hasEmptyOptionCode) {
                showToast('옵션비 청구의 옵션은 필수값 입니다.');
                return false;
            }
            params.optionList = optionList.map(v => {
                return{
                    ...v,
                    amount: removeComma(v.amount)
                }
            });
        }

        if (!await callConfirm('저장 하시겠습니까?')) {
            return false;
        }

        let result = null;
        if(ContractCreateDetailStore.contractNo){
            result = await axios.put(`/contract/${ContractCreateDetailStore.contractNo}`, params);
            const updatedList = ContractCreateDetailStore.contractProductList.filter(v => v.isUpdated);

            if(updatedList.length > 0) {
                await axios.put(`/contract/product/${ContractCreateDetailStore.contractNo}`, { contractProductList: updatedList });

            }
        }else{
            result = await axios.post('/contract', params);

            navigate('/contract/detail', { state: { contractNo: result.contractNo, releaseAskStatus: result.releaseAskStatus, isFix: result.isFix, sellDate: result.sellDate } });
            //히스토리 저장
            AppStore.pushOpenPageHistory({url: '/contract/detail', name: '계약상세'});
        }

        LUtils.forOwn(result, (value, key) => {
            ContractCreateDetailStore.setContractDetail(value === 0 ? 0 : value || '', [key]);
        });

        showToast('저장되었습니다.');
    }

    /* 청구지 조회 팝업 오픈 */
    const openSearchBillingAddressPopup = () => {
        AppStore.toggleCustomerBillingAddressSearchModal()
    }

    /* 청구지 정보 콜백 함수 */
    const getBillingAddressInfo = (info) => {
        ContractCreateDetailStore.setContractDetail(info.billingAddressSeq, 'billingAddressSeq');
        ContractCreateDetailStore.setContractDetail(info.billingAddressName, 'billingAddressName');
    }

    /* 파일 업로드 하고 url 가져오기 */
    const fileUploadForS3 = async (fileData) => {
        if (LUtils.isEmpty(fileData.target.files)) {
            ContractCreateDetailStore.setContractDetail('', 'contractDocUrl');
            ContractCreateDetailStore.setContractDetail('', 'contractDocFileName');
            return false;
        }

        const file = fileData.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        const result = await axios.post('/file/upload/s3', formData, { headers: MULTIPART_HEADER });

        ContractCreateDetailStore.setContractDetail(result, 'contractDocUrl');
        ContractCreateDetailStore.setContractDetail(file.name, 'contractDocFileName');
        setModifyFile(false);
    }

    /* 파일 다운 */
    const downFile = async () => {
        if (!ContractCreateDetailStore.contractDocUrl) {
            showToast('저장된 파일이 없습니다.');
            return false;
        }

        // TODO: 안댐. cors에서 막혀서 더이상 진행 안됨. 일단 이대로 진행하고 나중에 수정하도록 함
        // const result = await s3({
        //     method: 'get',
        //     url: ContractCreateDetailStore.contractDocUrl,
        //     responseType: 'blob',
        //     headers: {'Access-Control-Allow-Origin': '*'}
        // });
        // Blob은 배열 객체 안의 모든 데이터를 합쳐 blob으로 반환하기 때문에 []안에 담는다!
        // const blob = new Blob([result.data])

        // window 객체의 createObjuctURL을 이용해서 blob:http://~~~ 식의 url을 만들어 준다.
        // const fileUrl = window.URL.createObjectURL(blob);
        const fileUrl = ContractCreateDetailStore.contractDocUrl;

        // link 안에 위에서 만든 url을 가지고 있는 a 태그를 만들고 보이지 않도록 해준다.
        const link = document.createElement('a');
        link.href = fileUrl;
        link.style.display = 'none';
        link.download = ContractCreateDetailStore.contractDocFileName;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(ContractCreateDetailStore.contractDocUrl);
    }

    /* 고객 담당자 팝업 콜백 */
    const getCustomerManager = (info) => {
        ContractCreateDetailStore.setContractDetail(info.customerManagerSeq, 'customerManagerSeq');
        ContractCreateDetailStore.setContractDetail(info.managerName, 'managerName');
    }

    const callbackSiteInfo =async(info)=> {
        if(info){
            ContractCreateDetailStore.setContractDetail(info.siteSeq, 'siteSeq');
            ContractCreateDetailStore.setContractDetail(info.siteName, 'siteName');
            ContractCreateDetailStore.setContractDetail(info.address + ' ' + info.addressDetail, 'installPlace');
            //옵션 리스트 가져오기
            ContractCreateDetailStore.setContractSiteOptionList(await axios.get(`/site/option`,{params:{siteSeq: info.siteSeq}}));
        }
    }

    // 옵션 추가
    const addOption = () => {
        setOptionList([
            ...optionList,
            { contractSpecialOptionSeq: '', optionCode: '', qty: 1, amount: 0 }
        ]);
    };

    // 옵션 삭제
    const deleteOption = (index) => {
        const updatedOptions = optionList.filter((_, i) => i !== index);
        setOptionList(updatedOptions);
    };

    // 옵션 값 업데이트
    const updateOption = (index, field, value) => {
        const updatedOptions = optionList.map((option, i) =>
            i === index ? { ...option, [field]: value } : option
        );
        setOptionList(updatedOptions);
    };

    // 초기화: 모든 옵션 지우고 하나만 남기기
    const resetOptions = () => {
        setOptionList([{ contractSpecialOptionSeq: '', optionCode: '', qty: 1, amount: 0 }]);
    };

    return (
        <>
            <Row>
                <CCol lg={2}>
                    <CInputGroup
                        isValid={true}
                        isValidCheck={!!ContractCreateDetailStore.billingAddressName}
                        label={'청구지'}
                        value={ContractCreateDetailStore.billingAddressName || ''}
                        disabled={true}
                        onCallbackBtn={openSearchBillingAddressPopup}
                    />
                </CCol>
                <CCol lg={2}>
                    <CInputGroup label={'현장명'} labelId={'managerName'} disabled={true}
                                 isValid={(ContractCreateDetailStore.contractType === '1' || ContractCreateDetailStore.contractType === '2')}
                                 isValidCheck={!!ContractCreateDetailStore.siteName}
                                 value={ContractCreateDetailStore.siteName || ''}
                                 onCallbackBtn={()=> AppStore.toggleSiteNameSearchPopup()}
                                 labelClassName={(ContractCreateDetailStore.contractType === '1' || ContractCreateDetailStore.contractType === '2') && 'input-required'}
                    />
                </CCol>
                <CCol lg={5}>
                    <CInputGroup label={'설치장소'} labelId={'siteName'} 
                                 disabled={ContractCreateDetailStore.detailMode === 'read'}
                                 value={ContractCreateDetailStore.installPlace || ''}
                                 onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.value, 'installPlace')}
                    />
                </CCol>
            </Row>
            <Row>
                <CCol lg={2}>
                    <Form.Label>계약서형태</Form.Label>
                    <InputGroup size={'sm'}>
                        <Form.Select
                            aria-label="선택"
                            value={ContractCreateDetailStore.contractDocType || ''}
                            onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.value, 'contractDocType')}
                        >
                            <CommcodeSMOptions commKind={'15'} />
                        </Form.Select>
                    </InputGroup>

                </CCol>
                {ContractCreateDetailStore.contractType == '4' &&
                    <CCol lg={2}>
                        <Form.Label style={{color: '#DF013A'}}>매각구분</Form.Label>
                        <InputGroup size={'sm'}>
                            <Form.Select
                                aria-label="선택"
                                value={ContractCreateDetailStore.sellType || ''}
                                onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.value, 'sellType')}
                            >
                                <CommcodeSMOptions commKind={'9'} />
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                }
            </Row>
            <Row>
                <Subject>특약사항</Subject>
                <Row>
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isDeliveryPrice"
                            label="운반비 청구"
                            style={{marginBottom: 5}}
                            checked={!!ContractCreateDetailStore.isDeliveryPrice}
                            onChange={(v)=> {
                                ContractCreateDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isDeliveryPrice');
                                if(!v.target.checked) {
                                    ContractCreateDetailStore.setContractDetail(0, 'deliveryPrice');
                                    ContractCreateDetailStore.setContractDetail('', 'chargeDelivery');
                                    ContractCreateDetailStore.setContractDetail('', 'deliveryKind');
                                }else{
                                    ContractCreateDetailStore.setContractDetail('1', 'chargeDelivery');
                                    ContractCreateDetailStore.setContractDetail('1', 'deliveryKind');
                                }
                            }}
                            disabled={ContractCreateDetailStore.detailMode === 'read'}
                        />
                        <InputGroup size={'sm'}>
                            <Form.Control
                                style={{flex: 1, textAlign: 'right'}}
                                max={2}
                                aria-describedby={'deliveryPrice'}
                                value={insertComma(ContractCreateDetailStore.deliveryPrice) || 0}
                                onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.value, 'deliveryPrice')}
                                disabled={ContractCreateDetailStore.detailMode === 'read' || !ContractCreateDetailStore.isDeliveryPrice}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원(부가세 별도)</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    {ContractCreateDetailStore.isDeliveryPrice === 1 &&
                        <CCol lg={2}>
                            <CSelectGroup
                                    label={'운반비 부담'}
                                    style={{marginTop: 27}}
                                    options={getCommcode('58')}
                                    value={ContractCreateDetailStore.chargeDelivery || ''}
                                    onChange={(v)=> {ContractCreateDetailStore.setContractDetail(v.target.value, 'chargeDelivery'), v.target.value === '1' ? ContractCreateDetailStore.setContractDetail('1', 'deliveryKind') : ContractCreateDetailStore.setContractDetail(null, 'deliveryKind')}}
                                    disabled={!ContractCreateDetailStore.isDeliveryPrice}
                                />
                        </CCol>
                    }
                    {Number(ContractCreateDetailStore.isDeliveryPrice) === 1 && Number(ContractCreateDetailStore.chargeDelivery) === 1 &&
                        <CCol lg={2}>
                            <CSelectGroup
                                    label={'운반구분'}
                                    style={{marginTop: 27}}
                                    options={getCommcode('57')}
                                    value={ContractCreateDetailStore.deliveryKind || ''}
                                    onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.value, 'deliveryKind')}
                                    disabled={!ContractCreateDetailStore.isDeliveryPrice}
                                />
                        </CCol>
                    }
                    {(ContractCreateDetailStore.contractType != '3' && ContractCreateDetailStore.contractType != '4') &&
                        <CCol lg={2}>
                            <Form.Check
                                type="switch"
                                id="isPenaltyFee"
                                label="위약금 청구"
                                style={{marginBottom: 8}}
                                checked={!!ContractCreateDetailStore.isPenaltyFee}
                                onChange={
                                    v => {
                                        !v.target.checked && ContractCreateDetailStore.setContractDetail(0, 'penaltyPercent');
                                        v.target.checked && ContractCreateDetailStore.setContractDetail(40, 'penaltyPercent');
                                        ContractCreateDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isPenaltyFee')
                                    }}
                                disabled={ContractCreateDetailStore.detailMode === 'read'}
                            />
                            <InputGroup size={'sm'}>
                            <CSelectGroup
                                label={'잔여 렌탈료의'}
                                options={Array.from({ length: 9 }, (_, index) => ({ value: `${(index + 1) * 10}`, name: `${(index + 1) * 10}%` }))}
                                value={ContractCreateDetailStore.penaltyPercent || '40%'}
                                onChange={(e) => {
                                    const selectedPercent = parseInt(e.target.value.replace('%', ''));
                                    ContractCreateDetailStore.penaltyPercent = selectedPercent;
                                }}
                                disabled={!ContractCreateDetailStore.isPenaltyFee}
                            />
                            </InputGroup>
                        </CCol>
                    }
                    {/*
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isExtendRate"
                            label="연장할인"
                            style={{marginBottom: 8}}
                            checked={!!ContractCreateDetailStore.isExtendRate}
                            onChange={
                                v => {
                                    if (!v.target.checked) {
                                        ContractCreateDetailStore.setContractDetail(0, 'extendFirstRate');
                                        ContractCreateDetailStore.setContractDetail(0, 'extendSecondRate');
                                    }
                                    ContractCreateDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isExtendRate')
                                }}
                            disabled={ContractCreateDetailStore.detailMode === 'read'}
                        />
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'extendFirstRate'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>1회차</InputGroup.Text>
                            <Form.Control
                                style={{flex: 1, textAlign: 'right'}}
                                aria-describedby={'extendFirstRate'}
                                value={ContractCreateDetailStore.extendFirstRate || 0}
                                onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.value, 'extendFirstRate')}
                                disabled={ContractCreateDetailStore.detailMode === 'read' || !ContractCreateDetailStore.isExtendRate}
                            />
                            <InputGroup.Text muted style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>%</InputGroup.Text>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>2회차</InputGroup.Text>
                            <Form.Control
                                style={{flex: 1, textAlign: 'right'}}
                                aria-describedby={'extendSecondRate'}
                                value={ContractCreateDetailStore.extendSecondRate || 0}
                                onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.value, 'extendSecondRate')}
                                disabled={ContractCreateDetailStore.detailMode === 'read' || !ContractCreateDetailStore.isExtendRate}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>%</InputGroup.Text>
                        </InputGroup>
                    </CCol>*/}
                
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isInstallPrice"
                            label="설치비 청구"
                            style={{marginBottom: 5}}
                            checked={!!ContractCreateDetailStore.isInstallPrice}
                            onChange={(v)=> {
                                ContractCreateDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isInstallPrice');
                                if(!v.target.checked) ContractCreateDetailStore.setContractDetail('0', 'installPrice');
                            }}
                            disabled={ContractCreateDetailStore.detailMode === 'read'}
                        />
                        <InputGroup size={'sm'}>
                            <Form.Control
                                style={{flex: 1, textAlign: 'right'}}
                                max={2}
                                aria-describedby={'installPrice'}
                                value={insertComma(ContractCreateDetailStore.installPrice) || 0}
                                onChange={(v)=> ContractCreateDetailStore.installPrice = v.target.value}
                                disabled={ContractCreateDetailStore.detailMode === 'read' || !ContractCreateDetailStore.isInstallPrice}
                                />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원(부가세 별도)</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    {ContractCreateDetailStore.contractType == '2' &&
                        <>
                            <CCol lg={2}>
                                <Form.Check
                                    type="switch"
                                    id="switch1"
                                    label="무상양도"
                                    style={{marginBottom: 5}}
                                    checked={!!ContractCreateDetailStore.isFreeAssignment}
                                    onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isFreeAssignment')}
                                    disabled={ContractCreateDetailStore.detailMode === 'read'}
                                />
                                <InputGroup size={'sm'}>
                                    <Form.Control
                                        style={{flex: 1, textAlign: 'right'}}
                                        max={2}
                                        aria-describedby={'assignmentAmount'}
                                        value={insertComma(ContractCreateDetailStore.assignmentAmount) || 0}
                                        onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.value, 'assignmentAmount')}
                                        disabled={ContractCreateDetailStore.detailMode === 'read' || ContractCreateDetailStore.isFreeAssignment}
                                    />
                                    <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원(부가세 별도)</InputGroup.Text>
                                </InputGroup>
                            </CCol>
                            <CCol lg={2}>
                                <Form.Check
                                    type="switch"
                                    id="isRepairCost"
                                    label="유상 A/S"
                                    style={{marginBottom: 5}}
                                    checked={!!ContractCreateDetailStore.isRepairCost}
                                    onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isRepairCost')}
                                    disabled={ContractCreateDetailStore.detailMode === 'read'}
                                />
                                <Form.Text id="passwordHelpBlock" muted>고객과실: 유상, 부품결함: 무상</Form.Text>
                            </CCol>
                        </>
                    }
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isOptionCost"
                            label="옵션비 청구"
                            checked={!!isOptionCost}
                            onChange={(v) => {
                                if (!v.target.checked) {
                                    if (!confirm('옵션이 모두 삭제됩니다. 삭제하시겠습니까?')) {
                                        setIsOptionCost(1);
                                    } else {
                                        resetOptions();
                                        setIsOptionCost(0);
                                    }
                                } else {
                                    resetOptions();
                                    setIsOptionCost(1);
                                }
                            }}
                            disabled={ContractCreateDetailStore.detailMode === 'read'}
                        />
                    </CCol>
                    {isOptionCost === 1 &&
                    <Col lg={2}>
                        <a onClick={resetOptions} 
                        style={{ border: '1px solid #ccc', borderRadius: 5, padding: '5px 10px', cursor: 'pointer', marginRight: 10 }}
                        >
                            초기화
                        </a>
                        <a onClick={addOption} 
                        style={{ border: '1px solid #ccc', borderRadius: 5, cursor: 'pointer', padding: '5px 10px' }}
                        >
                            옵션 추가
                        </a>
                    </Col>
                    }
                </Row>

                {/* 옵션 항목 관리 */}
                {isOptionCost === 1 ? (optionList.map((option, index) => (
                    <Row key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
                        <CCol lg={2}>
                            <CSelectGroup
                                isValid={true}
                                label={`옵션 ${index + 1}`}
                                options={[{name: '선택', value: ''}].concat(getCommcode('71'))}
                                value={option.optionCode}
                                onChange={(e) => updateOption(index, 'optionCode', e.target.value)}
                                disabled={ContractCreateDetailStore.detailMode === 'read'}
                            />
                        </CCol>

                        <CCol lg={4}>
                            <InputGroup size={'sm'}>
                                <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>수량</InputGroup.Text>

                                <Form.Control
                                    style={{flex: 1, textAlign: 'right'}}
                                    value={option.qty || 1}
                                    onChange={(e) => updateOption(index, 'qty', e.target.value)}
                                    disabled={ContractCreateDetailStore.detailMode === 'read'}
                                    type={'number'}
                                />

                                <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>단가</InputGroup.Text>
                                <Form.Control
                                    style={{flex: 2, textAlign: 'right'}}
                                    value={insertComma(option.amount) || 0}
                                    onChange={(e) => updateOption(index, 'amount', e.target.value)}
                                    disabled={ContractCreateDetailStore.detailMode === 'read'}
                                />
                                <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                            </InputGroup>
                        </CCol>
                        <Col lg={1}>
                            <IconBtn className={'h-8'} iconClass={ICON_TRASH} onClick={() => deleteOption(index)} />
                        </Col>
                    </Row>
                ))) : (
                    <Row style={{ marginTop: -10 }}>
                        <Col style={{ marginBottom: 10, color: 'red' }} lg={12}>계약별 추가로 청구해야할 옵션 비용이 있으면 등록해주세요</Col>
                    </Row>
                )}
            </Row>
            <Row>
                <Subject>결제조건</Subject>
                {(ContractCreateDetailStore.contractType === '1' || ContractCreateDetailStore.contractType === '2') &&
                    <Row>
                        <CCol lg={7}>
                        <Form.Label style={{color: '#DF013A', display: 'block'}}>첫 회차 청구 기준</Form.Label>
                            <div className="btn-group btn-group-toggle pt-1 pb-3">
                                <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                    <Form.Check
                                        type="radio" value="2" name="firstBillingType"
                                        checked={ContractCreateDetailStore.firstBillingType === '2' ? true : false}
                                        onChange={v => ContractCreateDetailStore.setContractDetail(v.target.value, 'firstBillingType')}
                                        style={{verticalAlign: 'middle', marginRight: 8}}
                                        disabled={ContractCreateDetailStore.detailMode === 'read'}/>
                                        <span>말일 기준: 개시일 부터 당월 말일까지</span>
                                </label>
                                <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                    <Form.Check
                                        type="radio" value="3" name="firstBillingType"
                                        checked={ContractCreateDetailStore.firstBillingType === '3' ? true : false}
                                        onChange={v => ContractCreateDetailStore.setContractDetail(v.target.value, 'firstBillingType')}
                                        style={{verticalAlign: 'middle', marginRight: 10}}
                                        disabled={ContractCreateDetailStore.detailMode === 'read'}/>
                                        <span>마감일 기준: 개시일 부터 마감일까지</span>
                                </label>
                                <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                    <Form.Check
                                        type="radio" value="1" name="firstBillingType"
                                        checked={ContractCreateDetailStore.firstBillingType === '1' ? true : false}
                                        onChange={v => ContractCreateDetailStore.setContractDetail(v.target.value, 'firstBillingType')}
                                        style={{verticalAlign: 'middle', marginRight: 10}}
                                        disabled={ContractCreateDetailStore.detailMode === 'read'}/>
                                        <span>개시일 기준: 개시일 부터 (익월개시일-1일)까지</span>
                                </label>
                            </div>
                        </CCol>
                        <CCol lg={2}>
                            <Form.Label style={{color: '#DF013A'}}>일할 계산 나누기 일수</Form.Label>
                            <Row>
                                <div className="btn-group btn-group-toggle pt-1 pb-3">
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="radio" value='30' name="dayOfCalculation"
                                            checked={String(ContractCreateDetailStore.dayOfCalculation) === '30' ? true : false}
                                            onChange={v => ContractCreateDetailStore.setContractDetail(v.target.value, 'dayOfCalculation')}
                                            style={{verticalAlign: 'middle', marginRight: 10}}
                                            disabled={ContractCreateDetailStore.detailMode === 'read'}/>
                                            30일
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip-30days">
                                                        2월인 경우 28일로 계산
                                                    </Tooltip>
                                                }
                                            >
                                                <i className="fi fi-rr-interrogation" style={{marginTop: 4, marginLeft: 5}}></i>
                                            </OverlayTrigger>
                                    </label>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="radio" value='0' name="dayOfCalculation"
                                            checked={String(ContractCreateDetailStore.dayOfCalculation) === '0' ? true : false}
                                            onChange={v => ContractCreateDetailStore.setContractDetail(v.target.value, 'dayOfCalculation')}
                                            style={{verticalAlign: 'middle', marginRight: 8}}
                                            disabled={ContractCreateDetailStore.detailMode === 'read'}/>
                                            해당 월 말일
                                    </label>
                                </div>
                            </Row>
                        </CCol>
                        <CCol lg={3}>
                            <Form.Label style={{color: '#DF013A'}}>일할 계산 시 절사 단위</Form.Label>
                            <Row>
                                <div className="btn-group btn-group-toggle pt-1 pb-3">
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="radio" value='10' name="truncationUnit"
                                            checked={String(ContractCreateDetailStore.truncationUnit) === '10' ? true : false}
                                            onChange={v => ContractCreateDetailStore.setContractDetail(v.target.value, 'truncationUnit')}
                                            style={{verticalAlign: 'middle', marginRight: 10}}
                                            disabled={ContractCreateDetailStore.detailMode === 'read'}/>
                                            10원 단위
                                    </label>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="radio" value='100' name="truncationUnit"
                                            checked={String(ContractCreateDetailStore.truncationUnit) === '100' ? true : false}
                                            onChange={v => ContractCreateDetailStore.setContractDetail(v.target.value, 'truncationUnit')}
                                            style={{verticalAlign: 'middle', marginRight: 8}}
                                            disabled={ContractCreateDetailStore.detailMode === 'read'}/>
                                            100원 단위
                                    </label>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="radio" value='1000' name="truncationUnit"
                                            checked={String(ContractCreateDetailStore.truncationUnit) === '1000' ? true : false}
                                            onChange={v => ContractCreateDetailStore.setContractDetail(v.target.value, 'truncationUnit')}
                                            style={{verticalAlign: 'middle', marginRight: 8}}
                                            disabled={ContractCreateDetailStore.detailMode === 'read'}/>
                                            1000원 단위
                                    </label>
                                </div>
                            </Row>
                        </CCol>
                    </Row>
                }
                <Row>
                <CCol lg={2}>
                    <Form.Label style={{color: '#DF013A'}}>청구조건</Form.Label>
                    <InputGroup size={'sm'}>
                        <Form.Select
                            aria-label="선택"
                            style={{width: '55%'}}
                            value={ContractCreateDetailStore.billingType || ''}
                            onChange={(v) => ContractCreateDetailStore.setContractDetail(v.target.value, 'billingType')}
                            disabled={ContractCreateDetailStore.detailMode === 'read'}
                        >
                            <CommcodeSMOptions commKind={'21'} />
                        </Form.Select>
                        <Form.Select
                            aria-label="선택"
                            value={ContractCreateDetailStore.billingDay || 0}
                            onChange={(v) => ContractCreateDetailStore.setContractDetail(v.target.value, 'billingDay')}
                            disabled={ContractCreateDetailStore.detailMode === 'read'}
                        >
                            {getDays().map((d,i) => {
                                return (<option key={i} value={d.value}>{d.text}</option>);
                            })}
                        </Form.Select>
                    </InputGroup>
                </CCol>
                <CCol lg={2}>
                    <Form.Label style={{color: '#DF013A'}}>납기조건</Form.Label>
                    <InputGroup size={'sm'}>
                        <Form.Select
                            aria-label="선택"
                            style={{width: '55%'}}
                            value={ContractCreateDetailStore.dueDateType || ''}
                            onChange={(v) => ContractCreateDetailStore.setContractDetail(v.target.value, 'dueDateType')}
                            disabled={ContractCreateDetailStore.detailMode === 'read'}
                        >
                            <CommcodeSMOptions commKind={'22'} />
                        </Form.Select>
                        <Form.Select
                            aria-label="선택"
                            value={ContractCreateDetailStore.dueDateDay || ''}
                            onChange={(v) => ContractCreateDetailStore.setContractDetail(v.target.value, 'dueDateDay')}
                            disabled={ContractCreateDetailStore.detailMode === 'read'}
                        >
                            {getDays().map((d,i) => {
                                return (<option key={i} value={d.value}>{d.text}</option>);
                            })}
                        </Form.Select>
                    </InputGroup>
                </CCol>
                <CCol lg={2}>
                    <Form.Label style={{color: '#DF013A'}}>결제방식</Form.Label>
                    <InputGroup size={'sm'}>
                        <Form.Select
                            aria-label="선택"
                            value={ContractCreateDetailStore.paymentType || ''}
                            onChange={(v) => ContractCreateDetailStore.setContractDetail(v.target.value, 'paymentType')}
                            disabled={ContractCreateDetailStore.detailMode === 'read'}
                        >
                            <CommcodeSMOptions commKind={'17'} />
                        </Form.Select>
                    </InputGroup>
                </CCol>
                <CCol lg={2}>
                    <Form.Label>보증금</Form.Label>
                    <InputGroup size={'sm'}>
                        <Form.Control
                            style={{flex: 1, textAlign: 'right'}}
                            max={2}
                            aria-describedby={'deposit'}
                            value={insertComma(ContractCreateDetailStore.deposit) || 0}
                            onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.value, 'deposit')}
                            disabled={ContractCreateDetailStore.detailMode === 'read'}
                        />
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                    </InputGroup>
                </CCol>
                {(ContractCreateDetailStore.contractType == '1' || ContractCreateDetailStore.contractType == '2') &&
                    <CCol lg={1}>
                        <Form.Label>마감일자</Form.Label>
                        <InputGroup size={'sm'}>
                            <Form.Select
                                aria-label="선택"
                                value={ContractCreateDetailStore.endDate || ''}
                                onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.value, 'endDate')}
                                disabled={ContractCreateDetailStore.detailMode === 'read'}
                            >
                                {getDays().map((d,i) => {
                                    return (<option key={i} value={d.value}>{d.text}</option>);
                                })}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                }
                </Row>
            </Row>
            <Row>
                <CCol lg={2}>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="계산서 역발행"
                        style={{paddingTop: 8}}
                        checked={!!ContractCreateDetailStore.isReverseBill}
                        onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isReverseBill')}
                        disabled={ContractCreateDetailStore.detailMode === 'read'}
                    />
                </CCol>
{/*                <CCol lg={5}>
                    <Form.Check
                        type="switch"
                        id="switch2"
                        label="가상출고 (렌탈중인 자산을 매각하거나 입고되지 않은 상품을 출고할 경우 사용)"
                        style={{paddingTop: 8}}
                        checked={!!ContractCreateDetailStore.isVirtualRelease}
                        onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.checked ? 1 : 0, 'isVirtualRelease')}
                        disabled={true}
                    />
                </CCol>*/}
            </Row>
            <Row>
                <Subject>기타</Subject>
                <CCol lg={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>특약사항</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            value={ContractCreateDetailStore.specialRemark}
                            onChange={(v) => ContractCreateDetailStore.setContractDetail(v.target.value, 'specialRemark')}
                            disabled={ContractCreateDetailStore.detailMode === 'read'}
                        />
                    </Form.Group>
                </CCol>
                {/*
                <CCol lg={6}>
                    <Form.Group className="mb-3">
                        <Form.Label>설치장소</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            value={ContractCreateDetailStore.installPlace || ''}
                            onChange={(v)=> ContractCreateDetailStore.setContractDetail(v.target.value, 'installPlace')}
                            disabled={ContractCreateDetailStore.detailMode === 'read'}
                        />
                    </Form.Group>
                </CCol>
                */}
            </Row>
            {/* <Row>
                {ContractCreateDetailStore.contractType == '1' &&
                    <CCol lg={2}>
                        <CalendarGroup asSingle={true}
                                       label={'회수예정일'}
                                       value={returnExpectDate}
                                       minDate={today()}
                                       onChange={(e) => setReturnExpectDate(e)}
                                       disabled={ContractCreateDetailStore.detailMode === 'read'}
                        />
                    </CCol>
                }
            </Row> */}
            <Row>
                <Subject>계약서</Subject>
                <CCol lg={4}>
                    {/* 1. 파일 수정상태이거나,  2. 등록 url로 접근했거나,  3. contractDocUrl값이 없을때 파일등록 ui 설정  */}
                    {(modifyFile || ContractCreateDetailStore.detailMode === 'create')
                    ?
                        <InputGroup className="mb-3">
                            <Form.Control
                                type="file"
                                size="sm"
                                onChange={fileUploadForS3}
                            />
                        </InputGroup>
                    :
                        <CInputGroup
                            label={'계약서 파일'}
                            value={ContractCreateDetailStore.contractDocFileName}
                            onCallbackBtn={ContractCreateDetailStore.detailMode === 'update' ? () => setModifyFile(true) : downFile}
                            buttonLabel={ContractCreateDetailStore.detailMode === 'update' ? '파일삭제' : '내려받기'}
                            disabled={ContractCreateDetailStore.detailMode === 'read'}
                        />
                    }
                </CCol>
            </Row>
            <Row>
                <Col className='d-flex flex-row-reverse'>
                    <CSaveBtn onClick={saveContract} style={{width: 140}} title={'계약저장'}/>
                </Col>
            </Row>

            {/* modal */}
            <CustomerBillingAddressSearch customerNo={ContractCreateDetailStore.customerNo} callbackFn={getBillingAddressInfo} />
            {/* 고객 담당자 팝업 */}
            <CustomerManagerSearch callbackFn={getCustomerManager} customerNo={ContractCreateDetailStore.customerNo} />
            <SiteNameSearch callbackFn={callbackSiteInfo} />
        </>
    );
}

export default observer(ContractCreationInfo);
