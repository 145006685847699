import React, { useState, useRef } from 'react';
import { observer } from "mobx-react-lite";
import axios from 'axios';
import { Row, InputGroup, Form } from "react-bootstrap";

import { AppStore } from "../../../store/AppStore";
import Modal from "../../../components/Modal";
import { CContainer, CCol } from "../../../components/CustomContainer";
import AgGridContainer from "../../../components/AgGridContainer";
import { MULTIPART_HEADER } from "../../../common/constants";

const CertificatePopup = ({callbackFn=null}) => {
    const [certificateList, setCertificateList] = useState([]);

    /* 그리드 더블 클릭 시 */
    const selectedRow = (e) => {
        callbackFn && callbackFn(e.data);
        refresh();
        AppStore.toggleCertificatePopup();
    }

    /* 초기화 */
    const refresh = () => {
        setCertificateList([]);
    }

    const inputFileRef = useRef(null);

    const handleFileSelect = async(e) => {
        const files = e.target.files;
        if (files.length !== 2) {
            alert('파일을 2개 선택해야 합니다.');
            return;
        }

        const file1 = files[0];
        const file2 = files[1];

        if (file1.name !== 'signCert.der' || file2.name !== 'signPri.key') {
            alert('signCert.der와 signPri.key 파일을 선택해야 합니다.');
            return;
        }

        // 파일 처리 로직
        try {
            const formData = new FormData();
            formData.append('signCert', file1);
            formData.append('signKey', file2);
    
            const result = await axios.post('/account/management/upload', formData, { headers: MULTIPART_HEADER });
    
            setCertificateList(result);
        } catch (error) {
            console.error('파일 업로드 실패:', error);
        }
    };

    return (
        <Modal 
            title={'인증서 찾기'}
            show={AppStore.isOpenCertificatePopup}
            onHide={() => AppStore.toggleCertificatePopup()}
            toggleValue={AppStore.isOpenCertificatePopup}
            className={'search-modal-6'}
            size={'lg'}
        >

            <CContainer search>
                <Row>
                    <CCol lg={12}>🔐 signCert.der 파일과 🔐 signPri.key 파일을 모두 선택해주세요</CCol>
                    <CCol lg={12}>
                        경로 예 : Windows > C:/Users/[계정명]/AppData/LocalLow/NPKI/[발급기관]/USER <br/>
                        &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &ensp;> C:/Program Files/NPKI/[발급기관]/USER <br/>
                        &emsp; &emsp; &emsp; Mac > /library/preferences/NPKI/[발급기관]/USER
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={12} className='d-flex justify-center'>
                        <InputGroup>
                            <Form.Control
                                type="file"
                                id="certificateFile"
                                multiple
                                size="sm"
                                onChange={handleFileSelect}
                                ref={inputFileRef}
                            />
                        </InputGroup>
                    </CCol>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    height={8}
                    rowData={certificateList}
                    columnDefs={[
                        {field: "fileName", headerName: "파일명", hide: true},
                        {field: "filePath", headerName: "경로", hide: true},
                        {field: "certName", headerName: "인증서명", width: 300},
                        {field: "validStartDate", headerName: "발급일", width: 120},
                        {field: "expirationDate", headerName: "만료일", width: 120},
                        {field: "issuerDN", headerName: "발급처", width: 120, flex: 1},
                    ]}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(CertificatePopup);
