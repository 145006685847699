import React from "react";
import { observer } from 'mobx-react-lite';
import {CContainer} from './CustomContainer'
import {Image} from "react-bootstrap";
const ContentHeader = ({title, depthList=[]}) => {

    return (
        <>
            <div style={{height: 40, display: 'flex', alignItems: 'center', marginLeft: 20, marginTop: 3}}>
                {/*<i className="fi fi-sr-angle-right"></i>*/}
                {/*<Image style={{marginRight: 8, marginTop: -2}} src='/image/elite.png' width={25} height={9}/>*/}
                {/*<i className="fi fi-ss-leaf" style={{marginRight: 8, color: 'green'}}></i>*/}
                {/*<i className="fi fi-sr-angle-right" style={{marginRight: 8, color: '#01aaeb'}} />*/}
                <i className="fi fi-ss-comment-check" style={{marginRight: 8, marginTop: 2, color: 'rgb(0 126 174)'}} />
                <div className='mt-2'>
                    <h5 style={{color: '#6e767e'}}>{title}</h5>
                </div>
            </div>
        </>
    );
};

export default observer(ContentHeader);
