import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import AgGridContainer from '../../../components/AgGridContainer';
import LUtils from '../../../utils/lodashUtils';
import { dateTimeFormat } from '../../../utils/dateUtils';
import { callAlert, callConfirm } from '../../../utils';
import { CustomerStore } from '../../../store/customer/CustomerStore';
import { getUserName } from '../../../utils/userUtils';
import {findCommKrnm, getCommcode} from "../../../utils/commcode";

const CustomerAccount = ({ customerNo, ...props }) => {
    const gridRef = useRef();
    const columnDefs = [
        {field: "customerAccountSeq", hide: true},
        {field: "accountSeq", headerName: "순번", width: 80},
        {field: "accountNumber", headerName: "계좌번호", editable: true, headerClass: 'grid-column-editable', width: 160},
        {
            field: "bank",
            headerName: "은행",
            editable: true,
            headerClass: 'grid-column-editable',
            valueFormatter: v => findCommKrnm(v.value, '24'),
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: getCommcode('24').map(v => v.value),
            },
        },
        {field: "accountName", headerName: "계좌명", editable: true, headerClass: 'grid-column-editable'},
        {field: "accountOwner", headerName: "예금주", editable: true, headerClass: 'grid-column-editable'},
        {field: "billingAddressSeq", headerName: "청구지순번", editable: true, headerClass: 'grid-column-editable', width: 130},
        {field: "accountType", headerName: "계좌 종류", editable: true, headerClass: 'grid-column-editable', width: 130},
        {
            field: "isActive",
            headerName: "사용여부",
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: { values: [0, 1] },
            valueFormatter: params => !!params.value ? '사용' : '미사용',
            headerClass: 'grid-column-editable grid-column-required',
            width: 110
        },
        {field: "updater", headerName: "변경자", valueGetter: v => getUserName(v.data.updater)},
        {field: "updatedDate", headerName: "변경일", valueGetter: v => dateTimeFormat(v.data.updatedDate), width: 180},
    ]

    useEffect(() => {
        getAccountList(customerNo);

        if(customerNo) CustomerStore.customerNo = customerNo;
        else CustomerStore.customerNo = props.customerInfo.customerNo;

    }, [props.customerInfo.customerNo]);

    const getAccountList = async (customerNo) => {
        if (!customerNo) {
            return false;
        }

        const result = await axios.get(`/customer/tabs/${customerNo}/account`);
        CustomerStore.setAccountList(result);
    }

    const saveFn = async ({ updatedList, createdList }) => {
        const isUpdate = !LUtils.isEmpty(updatedList);
        const isCreate = !LUtils.isEmpty(createdList);
        if (!isUpdate && !isCreate) {
            await callAlert('저장할 내용이 없습니다.');
            return false;
        }

        // TODO: 필수값 검사

        isUpdate && await axios.put(`/customer/tabs/${CustomerStore.customerNo}/account`, { accountList: updatedList });
        isCreate && await axios.post(`/customer/tabs/${CustomerStore.customerNo}/account`, { accountList: createdList });

        callAlert('저장 되었습니다.');
        await getAccountList(CustomerStore.customerNo);
    }
    const removeFn = async (e) => {
        const { customerAccountSeq, accountSeq } = e.data;
        if (!await callConfirm(`[${accountSeq}]번 순번 삭제 하시겠습니까?`)) {
            return false;
        }

        await axios.delete(`/customer/tabs/${CustomerStore.customerNo}/account/${customerAccountSeq}`);
        callAlert('삭제 되었습니다.');
        await getAccountList(CustomerStore.customerNo);
    }

    const addRowInfo = { accountNumber: '', accountType: '', bank: '', billingAddressSeq: '', accountName: '', accountOwner: '', isActive:1 };

    return (
        <AgGridContainer
            gridRef={gridRef}
            height={35}
            rowData={CustomerStore.accountList}
            columnDefs={columnDefs}
            isCheckBox={!!CustomerStore.customerNo}
            seqColumn={'customerAccountSeq'}
            useCsvDownload={!!CustomerStore.customerNo}
            useIntoTheTab={true}
            useCreated={!!CustomerStore.customerNo}
            useUpdated={!!CustomerStore.customerNo}
            callBackGridData={saveFn}
            addRowInfo={addRowInfo}
            useRemoveRow={true}
            removeCallback={removeFn}
        />
    );
};

export default observer(CustomerAccount);
