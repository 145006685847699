import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";
import { oneMonthAgo, today } from "../../utils/dateUtils";

export const PurchaseSalesStatementStore = observable({

    search: {
        startDate: oneMonthAgo(),
        endDate: today(),
        accountingStatement: '',
        customerName: '',
        businessNumber: '',
        taxType: '',
        isCard: false,
    },

    purchaseStatementList: [],
    salesStatementList: [],
    purchaseList: [],
    salesList: [],

    setPurchaseStatementList(v) {
        this.purchaseStatementList = v;
    },
    setSalesStatementList(v) {
        this.salesStatementList = v;
    },
    setPurchaseList(v) {
        this.purchaseList = v;
    },
    setSalesList(v) {
        this.salesList = v;
    },
    setSearch(v) {
        LUtils.assign(this.search, v);
    },

});