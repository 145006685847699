import { observer } from "mobx-react-lite"
import { Form, InputGroup, Row, Col, Stack } from "react-bootstrap";
import { CCol, CSaveBtn, CalendarGroup, Subject, CSelectGroup, CInputGroup } from "../../../components/CustomContainer";
import { useState, useRef } from "react";
import AgGridContainer from "../../../components/AgGridContainer";
import { UserTabsStore } from "../../../store/human-resources/UserTabsStore";
import { findCommKrnm, getCommcode } from "../../../utils/commcode";
import ReportCertificateTemplate from "../../../components/report/ReportCertificateTemplate";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { getUserName } from "../../../utils/userUtils";
import { showToast } from "../../../common/utils";
import { callConfirm } from "../../../utils";
import { UserStore } from "../../../store/human-resources/UserStore";

const IssueCertificate = ({userSeq, selectUserDetail}) => {
    const gridRef = useRef();
    const reportRef = useRef();
    const [certificateType, setCertificateType] = useState('');
    const [issuancePurpose, setIssuancePurpose] = useState('');
    const [personalNumberFirst, setPersonalNumberFirst] = useState('');
    const [personalNumberSecond, setPersonalNumberSecond] = useState('');
    const [isMasking, setIsMasking] = useState(false);


    const validCertificatePrint = async () => {
        if (certificateType === '') {
            showToast('발급받을 증명서를 선택해주세요.');
            return;
        } 

        if (issuancePurpose === '') {
            showToast('발급용도를 작성해주세요.');
            return;
        } 

        if (personalNumberFirst.length !== 6 || personalNumberSecond.length !== 7) {
            showToast('주민등록번호를 정확히 입력해주세요.');
            return;
        }

        if (!await callConfirm('증명서를 발급하시겠습니까?')) {
            return;
        }

        userCertificatePrint();
    }

    const userCertificatePrint = useReactToPrint({
        content: () => reportRef.current,
        documentTitle: UserStore.data.name + "_" + findCommKrnm(certificateType, '52'),
        onAfterPrint: () => afterPrintEvent(), // 발급 후 이벤트
    });

    const afterPrintEvent = async () => {
        await axios.post("/user/tabs/certificate", {userSeq: userSeq, certificateType: certificateType, issuancePurpose: issuancePurpose })
        selectUserDetail();
        setCertificateType('');
        setIssuancePurpose('');
        setPersonalNumberFirst('');
        setPersonalNumberSecond('');
    }

    const validPersonalNumber = (v, label) => {
        if (v.target.value && isNaN(v.target.value)) {
            showToast('숫자만 입력 가능합니다.');
            return;
        }

        if ((label === 'first' && v.target.value.length > 6) || (label === 'second' && v.target.value.length > 7)) {
            return;
        }

        label === 'first' ? setPersonalNumberFirst(v.target.value) : setPersonalNumberSecond(v.target.value);
    }

    return (
        <>
            <Subject>증명서 발급</Subject>
            <Row>
                <CCol lg={4}>
                    <CSelectGroup label={'증명서 종류'} labelId={'issueCertificate'}
                        options={[{name: '증명서 구분', value: ''}].concat(getCommcode('52'))}
                        value={certificateType}
                        onChange={v => setCertificateType(v.target.value)}
                        labelClassName='input-required'
                    />
                </CCol>
                <CCol lg={4}>
                    <CInputGroup label={'발급용도'}
                        labelId={'issuancePurpose'}
                        value={issuancePurpose}
                        onChange={v => setIssuancePurpose(v.target.value)}
                        labelClassName='input-required'
                    />
                </CCol>
            </Row>
            <Row>
                <CCol lg={4}>
                    <InputGroup size={'sm'}>
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}} className='input-required'>주민등록번호</InputGroup.Text>
                        <Form.Control
                            type="text"
                            value={personalNumberFirst || ''}
                            onChange={v => validPersonalNumber(v, 'first')}
                            />
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>-</InputGroup.Text>
                        <Form.Control
                            value={personalNumberSecond || ''}
                            onChange={v => validPersonalNumber(v, 'second')}
                            />
                    </InputGroup>
                </CCol>
                <CCol lg={7}>
                    <Row>
                        {/* <Form.Label style={{color: '#DF013A'}}>주민번호 뒷자리</Form.Label> */}
                        <div className="btn-group btn-group-toggle pt-1 pb-3">
                            <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                <Form.Check 
                                    type="radio" value="100" name="truncationUnit" 
                                    checked={isMasking === false} 
                                    onChange={v => setIsMasking(!isMasking)} 
                                    style={{verticalAlign: 'middle', marginRight: 8}}/> 
                                    전체 발급
                            </label>
                            <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                <Form.Check 
                                    type="radio" value="10" name="truncationUnit"
                                    checked={isMasking === true} 
                                    onChange={v => setIsMasking(!isMasking)} 
                                    style={{verticalAlign: 'middle', marginRight: 10}}/> 
                                    주민등록번호 뒷자리 일부 발급
                            </label>
                        </div>
                    </Row>
                </CCol>
            </Row>
            <Row>
                <Col>
                    <span className="text-danger">* 주민등록번호는 저장되지 않으며 인증서 발급 용도로만 사용됩니다.</span>
                </Col>
                <Col className="d-flex flex-row-reverse">
                    <CSaveBtn title={'발급'} onClick={validCertificatePrint}/>
                </Col>
            </Row>
            <Row>
                <CCol lg={12}>
                    <AgGridContainer
                        gridRef={gridRef}
                        gridTitle={'발급 내역'}
                        height={'33.5'}
                        rowData={UserTabsStore.issueCertificateHistory}
                        columnDefs={[
                            { field: "certificateHistorySeq", headerName: "증명서 발급 순번", hide: true },
                            { field: "certificateType", headerName: "증명서구분", valueGetter: v => findCommKrnm(v.data.certificateType, '52'), width: 120 },
                            { field: "issuancePurpose", headerName: "발급용도", width: 500 },
                            { field: "creator", headerName: "입력자", valueGetter: v => getUserName(v.data.creator), width: 87 },
                            { field: "createdDate", headerName: "입력일자", flex: 1 },
                        ]}
                    />
                </CCol>
            </Row>
            <ReportCertificateTemplate ref={reportRef} userSeq={userSeq} type={certificateType} issuancePurpose={issuancePurpose} personalNumberFirst={personalNumberFirst} personalNumberSecond={personalNumberSecond} isMasking={isMasking}/>
        </>
    )

}

export default observer(IssueCertificate);