import React from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import { Modal, Row } from "react-bootstrap";

import { CContainer, CInputGroup, CCol, CSaveBtn, CalendarGroup, CSelectGroup } from '../../components/CustomContainer';
import { getCommcode } from '../../utils/commcode';
import { PaybackStore } from '../../store/accounting/PaybackStore';
import { AppStore } from '../../store/AppStore';
import CustomerSearch from '../../components/searchModal/CustomerSearch';
import AccountingCodeSearch from '../../components/searchModal/AccountingCodeSearch';
import { showToast } from '../../common/utils';
import { today } from '../../utils/dateUtils';

const PaybackDetail = ({getPaybackList}) => {
    const store = PaybackStore;

    /* 고객 팝업 callback */
    const searchCustomerInfo =(data)=> {
        store.detail.customerNo = data.customerNo;
        store.detail.customerName = data.customerName;
    }

    /* 계정 과목 팝업 callback */
    const searchAccountingCode = (data) => {
        store.detail.accountCode = data.code;
        store.detail.accountName = data.name;
    }

    /* 상환 예정액 저장 */
    const createPayback = async() => {
        // 필수값 확인
        const requiredParams = ['paybackDate', 'customerNo', 'accountCode', 'amount', 'remark']
        if(!requiredParams.every(param => store.detail[param])) {
            showToast('필수값을 모두 입력해주세요.');
            return;
        }

        await axios.post('payback', store.detail);
        showToast("저장이 완료되었습니다.");
        await getPaybackList();
        store.detailInit();
    }

    return (
        <>
            <CContainer fluid style={{height: 400}}>
                <Row>
                    <CCol lg={6}>
                        <CalendarGroup label={'결제일'}
                                        asSingle={true}
                                        value={{
                                            startDate: store.detail.paybackDate,
                                            endDate: store.detail.paybackDate
                                        }}
                                        minDate={today()}
                                        onChange={v => {store.detail.paybackDate = v.startDate}}
                                        labelClassName='input-required'
                        />
                    </CCol>
                    <CCol lg={6}>
                        <CInputGroup label={'고객명'} labelId={'customerName'}
                                    placeholder={'고객명'}
                                    value={store.detail.customerName}
                                    disabled={true}
                                    labelClassName='input-required'
                                    onCallbackBtn={() => AppStore.toggleCustomerSearchModal()}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        <CInputGroup label={'금액'} labelId={'amount'} placeholder={'금액'} value={store.detail.amount} onChange={v => store.detail.amount = v.target.value} labelClassName='input-required' />
                    </CCol>
                    <CCol lg={6}>
                        <CInputGroup label={'계정과목'} labelId={'accountName'}
                                     placeholder={'계정과목'}
                                     value={store.detail.accountName}
                                     disabled={true}
                                     labelClassName='input-required'
                                     onCallbackBtn={() => AppStore.toggleAccountingCodePopup()}
                        />
                    </CCol>
                </Row>
                <Row className='mb-5'>
                    <CCol lg={12}>
                        <CInputGroup label={'비고'} labelId={'remark'} value={store.detail.remark} onChange={v => store.detail.remark = v.target.value} labelClassName='input-required' />
                    </CCol>
                </Row>
                <Row>
                    <Modal.Footer className='pb-0 me-0'>
                        <CSaveBtn onClick={createPayback} />
                    </Modal.Footer>
                </Row>
            </CContainer>
            <CustomerSearch callbackFn={searchCustomerInfo} />
            <AccountingCodeSearch callbackFn={searchAccountingCode} inputCode={null} />
        </>
    )
}

export default observer(PaybackDetail);
