import React, {useRef, useState, useEffect} from "react";
import {observer} from "mobx-react-lite";
import axios from "axios";

import {CContainer} from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import Modal from "../../components/Modal";
import {AppStore} from "../../store/AppStore";
import {findCommKrnm} from "../../utils/commcode";
import {ReturnAskListStore} from "../../store/return/ReturnAskListStore";
import ReturnAskDetail from "../return/ReturnAskDetail";
import { oneMonthAgo, today } from '../../utils/dateUtils';
import ReturnAskSearch from "./ReturnAskSearch";
import {showToast} from "../../common/utils";

const ReturnAskList =()=> {
    const gridRef = useRef();
    const [returnAskSeq, setReturnAskSeq] = useState(null);
    const [contractNo, setContractNo] = useState([]);

    useEffect(() => {
        // 한 달 전 날짜 세팅
        ReturnAskListStore.search.startDate = oneMonthAgo();
        ReturnAskListStore.search.endDate = today();
    }, []);

    const getReturnAskList = async() => {
        const result = await axios.get('/returnAsk/list', { params: ReturnAskListStore.search });
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.')
        }
        ReturnAskListStore.setReturnAskList(result);
    }

    /* 회수의뢰 상세 */
    const openReturnAskDetail = (v) => {
        setReturnAskSeq(v.data.returnAskSeq);
        setContractNo(v.data.contractNo);
        AppStore.toggleModal();
    }

    /* 팝업 닫을 때 회수의뢰 초기화 */
    const closedModal = () => {
        ReturnAskListStore.init();
        setReturnAskSeq(ReturnAskListStore.data.returnAskSeq);
        setContractNo('');
    };

    return(
        <>
            {/* 검색조건 */}
            <ReturnAskSearch getReturnAskList={getReturnAskList}/>
            <CContainer>
                <AgGridContainer
                    gridTitle={'의뢰 목록'}
                    useCsvDownload={true}
                    gridRef={gridRef}
                    height={60}
                    rowData={ReturnAskListStore.returnAskList}
                    columnDefs={
                        [
                            {field: "returnAskSeq", headerName: "회수의뢰번호", width: 120},
                            {field: "shipHopeDate", headerName: "회수 예정일", width: 120},
                            {
                                field: "returnAskStatus",
                                headerName: "회수의뢰상태",
                                valueFormatter: function(params) {
                                    return findCommKrnm(params.value, '25');
                                },
                                cellClass: v => v.value === '9' ? 'ag-grid-column-cancel' : '',
                                width: 120
                            },
                            {field: "contractNo", headerName: "계약번호", width: 160},
                            {field: "saleUserName", headerName: "영업담당", width: 120},
                            {field: "supportUserName", headerName: "지원담당", width: 120, hide: true},
                            {field: "customerName", headerName: "고객명", width: 200},
                            {field: "productName", headerName: "품목", width: 200},
                            {field: "storageQty", headerName: "입고수량", width: 200},
                            {field: "returnAskRemark", headerName: "회수의뢰비고", width: 300},
                            {field: "creatorName", headerName: "의뢰자"},
                            {field: "returnAskDate", headerName: "의뢰일자", width: 160},
                        ]
                    }
                    seqColumn={'returnAskSeq'}
                    useRowSearch={true}
                    rowSearchCallback={openReturnAskDetail}
                    rowDoubleClickCallback={openReturnAskDetail}
                />
            </CContainer>
            <Modal title={'회수의뢰'} onExit={closedModal}>
                    <ReturnAskDetail
                        returnAskSeq={returnAskSeq}
                        getReturnAskList={getReturnAskList}
                        contractNo={contractNo}
                        setContractNo={setContractNo}
                        closedModal={closedModal}
                    />
            </Modal>
        </>
    );
}

export default observer(ReturnAskList);
