import { observer } from 'mobx-react-lite';
import { Form, InputGroup, Button } from "react-bootstrap";
import { openAddressPopup } from '../utils';

/**
 * 주소(도로명주소) 검색 UI
 * @param {String} label
 * @param {String} postcode: 우편번호
 * @param {String} address: 주소 (도로명주소)
 * @param {String} addressDetail: 주소 상세
 * @param {Function} setPostcode: 우편번호 값 설정
 * @param {Function} setAddress: 주소 값 설정
 * @param {Function} setAddressDetail: 주소 상세값 설정
 */
const Address = ({label='주소', setPostcode, setAddress, setAddressDetail, postcode='', address='', addressDetail='', disabled, labelClassName, addressDisabled=true, style=null, btnStyle=''}) => {

    const findAddress = () => {
        openAddressPopup(callback);
    }

    const callback = (data) => {
        setPostcode(data.postcode)
        setAddress(data.address);
    }

    return (
        <InputGroup className="mb-3" size='sm'>
            <InputGroup.Text className={labelClassName} style={style}>{label}</InputGroup.Text>
            <Form.Control style={{maxWidth: '10%'}} value={postcode || ''} onChange={e => setPostcode(e.target.value)} disabled={addressDisabled}/>
            <Button onClick={findAddress} variant="outline-secondary" style={btnStyle === '' ? {borderColor: '#ced4da'} : btnStyle} disabled={disabled}>
                <i className="fi fi-br-search" />
            </Button>
            <Form.Control value={address || ''}  onChange={e => setAddress(e.target.value)} disabled={addressDisabled}/>
            <Form.Control value={addressDetail || ''} onChange={e => setAddressDetail(e.target.value)} disabled={disabled}/>
        </InputGroup>
    );
};

export default observer(Address);
