import React, {forwardRef, useState, useEffect, useRef} from "react";
import axios from "axios";

import "./reportTemplate.css";
import { decrypt } from "../../common/utils";
import { findCommKrnm } from "../../utils/commcode";
import { ReleaseAskDetailStore } from "../../store/release/ReleaseAskDetailStore";

const ReportTemplate =(props, ref)=> {
    const didMount = useRef(false);

    const { releaseAskDetail, releaseAskItemList, contractSiteOptionList } = ReleaseAskDetailStore;
    const [contract, setContract] = useState([]);

    useEffect(() => {
        if(props.contractNo) {
            getContract();
        }
    }, [props.contractNo]);

    /* 계약 조회 */
    const getContract = async () => {
        const result = await axios.get(`/contract/${props.contractNo}`);
        setContract(result);
        //console.log(result);
    }

    /* 소모품 조회 */
    // const getExpendables = async () => {
    //     const result = await axios.get(`/contract/${ReleaseAskDetailStore.releaseAskDetail.contractNo}`);
    //     setExpendables(result);
    // }

    return (

        <div ref={ref} className="print-template" style={{paddingLeft: 25, paddingRight: 25, display: 'none'}}>
            <h2 style={{textAlign: 'center', marginTop: 50, marginBottom: 20}}>출고 및 배송의뢰서</h2>
            <div style={{display: 'flex'}}><i className="fi fi-br-checkbox" style={{marginRight: 5, paddingTop: 2}}/><h6>출고의뢰정보</h6></div>
            <table className="table" style={{borderWidth: 1}}>
                <tbody>
                    {/*
                   <tr>
                        <td className='p-1 border'>출고접수자</td>
                        <td className='p-1 border' style={{width: '35%'}}>{releaseAskDetail.acceptUserName}</td>
                        <td className='p-1 border'>출고접수일시</td>
                        <td className='p-1 border' style={{width: '35%'}}>{releaseAskDetail.acceptDate}</td>
                    </tr>*/}
                    <tr>
                        <td className='p-1 border' style={{width: '20%', textAlign: 'center', backgroundColor: '#E6E6E6'}}>출고의뢰자</td>
                        <td className='p-1 border' style={{width: '30%', textAlign: 'center'}}>{releaseAskDetail.releaseUserName || ''}</td>
                        <td className='p-1 border' style={{width: '20%', textAlign: 'center', backgroundColor: '#E6E6E6'}}>출고의뢰일시</td>
                        <td className='p-1 border' style={{width: '30%', textAlign: 'center'}}>{releaseAskDetail.releaseDate || ''}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' style={{width: '20%', textAlign: 'center', backgroundColor: '#E6E6E6'}}>비고</td>
                        <td className='p-1 border' style={{textAlign: 'center'}} colSpan={3}>{releaseAskDetail.releaseAskRemark || ''}</td>
                    </tr>
                </tbody>
            </table>
            <br/>
            <div style={{display: 'flex'}}>
                <i className="fi fi-br-checkbox" style={{marginRight: 5, paddingTop: 2}} /><h6>계약 정보</h6>
            </div>
            <table className="table" style={{borderWidth: 1}}>
                <tbody>
                    <tr>
                        <td className='p-1 border' style={{width: '20%', textAlign: 'center', backgroundColor: '#E6E6E6'}}>계약자</td>
                        <td className='p-1 border' style={{width: '30%', textAlign: 'center'}}>{releaseAskDetail.saleUserName || ''}</td>
                        <td className='p-1 border' style={{width: '20%', textAlign: 'center', backgroundColor: '#E6E6E6'}}>계약번호</td>
                        <td className='p-1 border' style={{width: '30%', textAlign: 'center'}}>{contract.contractNo || ''}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' style={{textAlign: 'center', backgroundColor: '#E6E6E6'}}>출고의뢰번호</td>
                        <td className='p-1 border' style={{textAlign: 'center'}}>{releaseAskDetail.releaseAskSeq || ''}</td>
                        <td className='p-1 border' style={{textAlign: 'center', backgroundColor: '#E6E6E6'}}>계약유형</td>
                        <td className='p-1 border' style={{textAlign: 'center'}}>{findCommKrnm(contract.contractType, '1') || ''}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' style={{textAlign: 'center', backgroundColor: '#E6E6E6'}}>계약일</td>
                        <td className='p-1 border' style={{textAlign: 'center'}}>{contract.contractDate || ''}</td>
                        <td className='p-1 border' style={{textAlign: 'center', backgroundColor: '#E6E6E6'}}>개시일</td>
                        <td className='p-1 border' style={{textAlign: 'center'}}>{contract.contractStartDate || ''}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' style={{textAlign: 'center', backgroundColor: '#E6E6E6'}}>종료일</td>
                        <td className='p-1 border' style={{textAlign: 'center'}}>{contract.contractEndDate || ''}</td>
                        <td className='p-1 border' style={{textAlign: 'center', backgroundColor: '#E6E6E6'}}>현장명</td>
                        <td className='p-1 border' style={{textAlign: 'center'}}>{releaseAskDetail.siteName || ''}</td>
                    </tr>
                </tbody>
            </table>
            <br/>
            <div style={{display: 'flex'}}>
                <i className="fi fi-br-checkbox" style={{marginRight: 5, paddingTop: 2}} /><h6>출고 의뢰 내역</h6>
            </div>
            <table className="table" style={{borderWidth: 1, textAlign: 'center'}}>
                <thead>
                    <tr>
                        <th className='border' style={{width: '6%', backgroundColor: '#E6E6E6'}}>순번</th>
                        <th className='border' style={{width: '10%', backgroundColor: '#E6E6E6'}}>관리번호</th>
                        <th className='border' style={{width: '40%', backgroundColor: '#E6E6E6'}}>제품명 / 모델명</th>
                        <th className='border' style={{width: '6%', backgroundColor: '#E6E6E6'}}>수량</th>
                        {/*<th className='border' style={{width: '10%', backgroundColor: '#E6E6E6'}}>자산번호</th>*/}
                        <th className='border' style={{width: '18%', backgroundColor: '#E6E6E6'}}>시리얼</th>
                        {/*<th className='border' style={{width: '10%', backgroundColor: '#E6E6E6'}}>선반</th>*/}
                    </tr>
                </thead>
                <tbody>
                    {releaseAskItemList.map((data, index) => {
                        return(
                            <tr key={index} style={{height: 40, verticalAlign: 'middle'}}>
                                <td className='p-1 border' rowSpan='1'>{data.seq || ''}</td>
                                <td className='p-1 border' rowSpan='1'>{data.managementNo || ''}</td>
                                <td className='p-1 border' style={{textAlign: 'left'}}>
                                    {data.productName || ''} / {data.model || ''}
                                </td>
                                <td className='p-1 border'>{data.qty || ''}</td>
                                {/*<td className='p-1 border'>{data.assetNo}</td>*/}
                                <td className='p-1 border'>{data.serialNo || ''}</td>
                                {/* <tr key={data.seq}>
                                <td className='p-1 border' rowSpan='1'><b>소모품</b></td>
                                <td className='p-1 border'>
                                    <b>소모품명 / 수량 / 자산번호</b>
                                </td>
                                <td className='p-1 border'></td>
                                <td className='p-1 border'></td>
                                <td className='p-1 border'></td>
                                <td className='p-1 border'></td>
                            </tr>    */}
                            </tr>
                       )
                    })}


                </tbody>
            </table>
            <br/>
            <div style={{display: 'flex'}}>
                <i className="fi fi-br-checkbox" style={{marginRight: 5, paddingTop: 2}} /><h6>현장 옵션 내역</h6>
            </div>
            <table className="table" style={{borderWidth: 1}}>
                {/* 일단 하드코딩 한다. */}
                {contractSiteOptionList.length > 0 &&
                    <tbody>
                        <tr>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[0].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[0].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[1].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[1].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[2].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[2].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[3].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[3].optionValue}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[4].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[4].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[5].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[5].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[6].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[6].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[7].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[7].optionValue}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[8].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[8].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[9].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[9].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[10].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[10].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[11].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[11].optionValue}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[12].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[12].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[13].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[13].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[14].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[14].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[15].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[15].optionValue}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[16].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[16].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[17].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[17].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[18].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[18].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[19].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[19].optionValue}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[20].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[20].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[21].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[21].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[22].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[22].optionValue}</td>
                            <td className='p-1 border' style={{textAlign: 'left'}}><span style={{paddingLeft: 5}}>{contractSiteOptionList[23].optionName}</span></td>
                            <td className='p-1 border' style={{textAlign: 'center', width: 50}}>{contractSiteOptionList[23].optionValue}</td>
                        </tr>
                    </tbody>
                }
            </table>
            <br/>
            <div style={{display: 'flex'}}>
                <i className="fi fi-br-checkbox" style={{marginRight: 5, paddingTop: 2}} /><h6>배송 내역</h6>
            </div>
            <table className="table" style={{borderWidth: 1}}>
                <tbody>
                    <tr>
                        <td className='p-1 border' style={{textAlign: 'center', width: '15%', backgroundColor: '#E6E6E6'}}>배송담당자</td>
                        <td className='p-1 border' style={{width: '15%'}}>{}</td>
                        <td className='p-1 border' style={{textAlign: 'center', width: '15%', backgroundColor: '#E6E6E6'}}>배송 희망일시</td>
                        <td className='p-1 border' style={{width: '20%'}}>{releaseAskDetail.shipHopeDate || ''}</td>
                        <td className='p-1 border' style={{textAlign: 'center', width: '15%', backgroundColor: '#E6E6E6'}}>배송방법</td>
                        <td className='p-1 border' style={{width: '20%'}}>{findCommKrnm(releaseAskDetail.shipType, '4') || ''}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' style={{textAlign: 'center',verticalAlign: 'middle', backgroundColor: '#E6E6E6'}}>업체담당자</td>
                        <td className='p-1 border' style={{textAlign: 'center', verticalAlign: 'middle'}}>{releaseAskDetail.receiveUserName || ''}</td>
                        <td className='p-1 border' style={{textAlign: 'center', backgroundColor: '#E6E6E6'}}>전화번호</td>
                        <td className='p-1 border'>{decrypt(releaseAskDetail.telephone) || ''}</td>
                        <td className='p-1 border' style={{textAlign: 'center', backgroundColor: '#E6E6E6'}}>핸드폰</td>
                        <td className='p-1 border'>{decrypt(releaseAskDetail.phoneNumber) || ''}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' style={{textAlign: 'center', backgroundColor: '#E6E6E6'}}>주소</td>
                        <td className='p-1 border' colSpan={3}>({releaseAskDetail.postCode || ''}) {releaseAskDetail.address || ''} {releaseAskDetail.addressDetail || ''}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' style={{textAlign: 'center', backgroundColor: '#E6E6E6'}}>배송 요청사항</td>
                        <td className='p-1 border' colSpan={5}>{releaseAskDetail.shipRemark || ''}</td>
                    </tr>
                </tbody>
            </table>
            <br/>
        </div>
    );
};

export default forwardRef(ReportTemplate);
