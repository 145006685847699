import { observer } from "mobx-react-lite"
import { CContainer, CCol, CalendarGroup, CSelectGroup, CInputGroup } from "../../components/CustomContainer";
import { Row, Col, Badge } from "react-bootstrap";
import { GeneralStatementStore } from "../../store/accounting/GeneralStatementStore";
import { useEffect, useRef, useState } from "react";

const WAIT_TIME = 500;

const GeneralStatementDetailTop = () => {

    /* input 변경 있을 경우 grid 전체 체크 */
    const changeEvent = (v, param) => {

        if (param === 'resolutionDate' || param === 'postingDate') {
            GeneralStatementStore.setData({[param]: v.startDate});
        } else {
            GeneralStatementStore.setData({[param]: v.target.value});
        }
        const checkedAlready = GeneralStatementStore.data.generalStatementList.every(v => v.isSelected === true);
        if (!checkedAlready) {
            const list = GeneralStatementStore.data.generalStatementList.map(v =>({...v, isSelected: true}))
            GeneralStatementStore.data.generalStatementList = list;
        }
    }

    return (
            <CContainer search>
                <Row>
                    <CCol lg={4}>
                        <CalendarGroup asSingle={true} label={'결의일자'}
                                       value={{
                                           startDate: GeneralStatementStore.data.resolutionDate,
                                           endDate: GeneralStatementStore.data.resolutionDate
                                        }}
                                        onChange={v => {changeEvent(v, 'resolutionDate')}}
                                        //    onChange={v => {GeneralStatementStore.data.resolutionDate = v.startDate}}
                                        />
                    </CCol>
                    <CCol lg={4}>
                        <CalendarGroup asSingle={true} label={'기표일자'}
                                       value={{
                                           startDate: GeneralStatementStore.data.postingDate,
                                           endDate: GeneralStatementStore.data.postingDate
                                        }}
                                        onChange={v => {changeEvent(v, 'postingDate')}}
                                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={4}>
                        <CSelectGroup label={'전표유형'} labelId={'statementType'}
                            placeholder={'전표유형'}
                            options={[{name: '일반', value: '1'}]}
                            value={GeneralStatementStore.data.statementType || ''}
                            onChange={v => {changeEvent(v, 'statementType')}}
                            />
                    </CCol>
                    <CCol lg={4}>
                        <CSelectGroup label={'관리항목'} labelId={'managementPart'}
                            placeholder={'관리항목'}
                            options={[{name: '전체', value: ''}]}
                            value={GeneralStatementStore.data.managementPart || ''}
                            onChange={v => {changeEvent(v, 'managementType')}}
                        />
                    </CCol>
                            
                    {/* <CCol lg={3}>
                        <CInputGroup 
                            label={'전표상태'}
                            labelId={'approvalStatus'}
                            value={!GeneralStatementStore.data.approvalStatus || GeneralStatementStore.data.approvalStatus === '1' ? '<미결>' : '<승인>'}
                            disabled={true}
                        />
                    </CCol> */}
                </Row>
                <Row>
                    <CCol lg={8}>
                        <CInputGroup 
                            label={'전표설명'}
                            labelId={'remark'}
                            value={GeneralStatementStore.data.remark}
                            onChange={v => {changeEvent(v, 'remark')}}
                            // onChange={v => onChangeFn(v)}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        {GeneralStatementStore.data.approvalStatus === '2' && (
                            <span><Badge pill bg="primary" style={{fontSize: 13}}>승인 처리된 전표입니다.</Badge></span>
                        )}
                        
                        {/* <CSaveBtn title={'전표저장'}
                                  onClick={() => saveStatement}/> */}
                        {/* {(GeneralStatementStore.data.approvalStatus === '1' || !GeneralStatementStore.data.approvalStatus) && (
                            <CIconBtn title={'전표승인'}
                                      icon={'fi-rr-memo-circle-check'}
                                      variant={'success'}
                                      onClick={() => approvalStatement('2')}/>
                        )}
                        {GeneralStatementStore.data.approvalStatus === '2' && (
                            <CCancelBtn title={'전표승인취소'}
                                      icon={ICON_TRASH}
                                      onClick={() => approvalStatement('1')}/>
                        )} */}
                    </Col>
                    
                </Row>
                <Row>
                </Row>
            </CContainer>
    );
}

export default observer(GeneralStatementDetailTop);