import { observable } from "mobx";
import { today } from "../../utils/dateUtils";
import LUtils from "../../utils/lodashUtils";

export const NonpaymentStore = observable({

    rowData: [],
    /* tabs */
    nonpaymentList: [],
    consultationList: [],
    smsHistoryList: [],
    emailHistoryList: [],

    groupbyBillingAddressList: [],
    customerNonpaymentList: [],

    bankCode: '',
    accountNumber: '',
    accountHolder: '',
    htmlString: '',
    receiverList: [],
    billingAddressList: [],

    data: {
        contractNo: '',
        customerName: '',
        customerNo: '',
        contractType: '',
        chargeDate: null,
        dueDate: null,
        dueNonpaymentAmount: '',
        chargeTotalAmount: '',
        chargeNonpaymentDate: '',
        dueNonpaymentDate: '',
        latePaymentAmount: '',
        billingAddressName: '',
        billingAddressSeq: '',
        recentReceiveDate: null,
        bondType: '',
        lastBondActivity: '',
        lastConsultationDate: null,
        paymentPromiseDate: null,
        consultationContent: '',
        saleUserSeq: '',
        saleUserName: '',

        totalNonpaymentAmount: 0,
        maxDueNonpaymentDate: 0,
        
    },

    consultation: {
        consultationSeq: '',
        contractNo: '',
        customerNo: '',
        bondType: '',
        bondActivity: '',
        consultationDate: today(),
        paymentPromiseDate: today(),
        consultationContent: ''
    },

    search: {
        customerNo: '',
        customerName: '',
        contractNo: '',
        billingAddressName: '',
        billingAddressSeq: '',
        siteName: '',
        siteSeq: '',
        dueNonpaymentDate: '',
        saleUserSeq: '',
        saleUserName: '',
        bondType: '',
        bondActivity: '',
        minimumAmount: '',
        maximumAmount: '',
    },

    setNonpaymentList(v) {
        this.nonpaymentList = v;
    },

    setConsultationList(v) {
        this.consultationList = v;
    },

    setSmsHistoryList(v) {
        this.smsHistoryList = v;
    },

    setEmailHistoryList(v) {
        this.emailHistoryList = v;
    },

    setData(v) {
        LUtils.assign(this.data, v);
    },

    setCustomerNonpaymentList(v) {
        this.customerNonpaymentList = v;
    },

    setRowData(v, index) {
        if(index = 0) {
            this.rowData = v;
        }else {
            this.rowData.push(v);
        }
        
    },

    setReceiverList(v) {
        this.receiverList =  v;
    },

    setBillingAddressList(v) {
        this.billingAddressList = v;
    },

    addBillingAddressList(v) {
        this.billingAddressList.push(v);
    },

    consultationInit() {
        this.consultation.consultationSeq = '';
        this.consultation.bondType = '';
        this.consultation.bondActivity = '';
        this.consultation.consultationDate = today();
        this.consultation.paymentPromiseDate = today();
        this.consultation.consultationContent = '';
    },

});
