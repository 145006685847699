import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react";
import { ProductStore } from "../../store/product/ProductStore";
import { ProductBrandStore } from "../../store/product/ProductBrandStore";
import axios from "axios";
import { CCol, CContainer } from "../../components/CustomContainer";
import { Form } from "react-bootstrap";
import BrandCodeListSearch from "./BrandCodeListSearch";
import AgGridContainer from "../../components/AgGridContainer";
import { filter } from "lodash";
import { getCommcode } from "../../utils/commcode";
import LUtils from "../../utils/lodashUtils";
import { callConfirm } from "../../utils";
import { showToast } from "../../common/utils";

const BrandCodeList = () => {
    const gridRef = useRef();
    const [brandCodeList, setBrandCodeList] = useState([]);
    const [searchBrandName, setSearchBrandName] = useState('');
    const [selectLgCode, setSelectLgCode] = useState(0);

    const getBrandList = async () => {
        const brandCodeResult = await axios.get("/product/brand");
        if (searchBrandName !== '') {
            const filtered = brandCodeResult.filter(v => v.brandName.toLowerCase().includes(searchBrandName.toLowerCase()));
            setBrandCodeList(filtered);
        } else {
            setBrandCodeList(brandCodeResult);
        }
    }
    
    const saveFn = async ({updatedList}) => {
        const isUpdate = !LUtils.isEmpty(updatedList);
        
        if (!isUpdate) {
            showToast('저장할 내용이 없습니다.');
            return false;
        }

        if(!await callConfirm('수정하시겠습니까?')) {
            return;
        }
        
        await axios.put("/product/brand", updatedList);
        showToast('수정이 완료되었습니다.');
        getBrandList();
    }


    return (
        <div style={{maxWidth: 2000}}>
            <BrandCodeListSearch getBrandList={getBrandList} searchBrandName={searchBrandName} setSearchBrandName={setSearchBrandName} selectLgCode={selectLgCode} setSelectLgCode={setSelectLgCode}/>
            <CContainer>
                <AgGridContainer 
                    gridRef={gridRef}
                    rowData={brandCodeList}
                    height={65}
                    columnDefs={[
                        // {field: "lgCodeName", headerName: "대분류", width: 130},
                        {
                            field: "seq", 
                            headerName: "순번",
                            editable: true, 
                            headerClass: 'grid-column-editable',
                            width: 80
                        },
                        {
                            field: "brandName", 
                            headerName: "제조사명", 
                            width: 130, 
                            editable: true, 
                            headerClass: 'grid-column-editable',
                            flex: 1
                        },
                        {
                            field: "useYn", 
                            headerName: "사용여부", 
                            width: 110,
                            editable: true,
                            valueFormatter: v => v.value === 'Y' ? '사용' : '미사용',
                            cellEditor: 'agSelectCellEditor',
                            cellEditorParams: {
                                values: [{name: '사용', value: 'Y'}, {name: '미사용', value: 'N'}].map(v => v.value),
                            },
                            cellClass: v => v.value === 'N' ? 'ag-grid-column-cancel' : '',
                            headerClass: 'grid-column-editable'
                        }
                    ]}
                    useUpdated={true}
                    isCheckBox={true}
                    callBackGridData={saveFn}
                    getRowStyle={(params) => {
                        if (params.data.useYn === 'N') {
                            return { color: '#bababa',  };
                        }
                        return null;
                    }}
                />
            </CContainer>
        </div>
    )
}

export default observer(BrandCodeList);