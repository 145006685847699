import { observable } from "mobx";


export const InsurancePolicyStore = observable({
    insurancePolicyList: [],
    data: {startDate: '', endDate: '', lgSeq: '', remark: '', fileName: '', fileUrl: ''},

    init() {
        this.data.startDate = null;
        this.data.endDate = null;
        this.data.lgSeq = '';
        this.data.remark = '';
        this.data.fileName = '';
        this.data.fileUrl = '';
    }
})