import React, {useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Row} from "react-bootstrap";
import {CCol, CContainer, Subject} from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import {AuthorityStore} from "../../store/human-resources/AuthorityStore";
import {showToast} from "../../common/utils";
import {callConfirm} from "../../utils";
import axios from "axios";

const AuthorityManager =({getAuthList})=> {
    const lgGridRef = useRef();
    const mdGridRef = useRef();
    const smGridRef = useRef();

    const [mdParentSeq, setMdParentSeq] = useState('');
    const [smParentSeq, setSmParentSeq] = useState('');

    const getGroupAuthList =(authoritySeq, target)=> {
        const list = [];
        for(let auth of AuthorityStore.authorityList){
            if(auth.parentSeq === authoritySeq){
                list.push(auth);
            }
        }

        if(target === 'sm'){
            AuthorityStore.setSmAuthorityList(list);
            AuthorityStore.setDetailAuthorityList([]);
            //상위 코드를 저장해 둔다.
            setMdParentSeq(authoritySeq);
        }else if(target === 'detail'){
            AuthorityStore.setDetailAuthorityList(list);
            setSmParentSeq(authoritySeq);
        }
    }

    const saveCategories =async(updatedList, createdList, target)=> {
        const depth = target === 'md' ? 1 : target === 'sm' ? 2 : 3;

        //코드 중복 체크 -> 서버에서 확인
/*        for(let code of createdList){
            if(depth === 1 && AuthorityStore.orgMdAuthorityList.some(v => v.isSelected === false && v.authorityCode === code.authorityCode)){
                showToast('동일한 코드가 존재합니다.['+code.authorityCode+']');
                return;
            }else if(depth === 2 && AuthorityStore.orgSmAuthorityList.some(v => v.authorityCode === code.authorityCode)){
                showToast('동일한 코드가 존재합니다.['+code.authorityCode+']');
                return;
            }else if(depth === 3 && AuthorityStore.orgDetailAuthorityList.some(v => v.authorityCode === code.authorityCode)){
                showToast('동일한 코드가 존재합니다.['+code.authorityCode+']');
                return;
            }
        }*/

        //뎁스별로 parentSeq를 셋팅한다.
        if(createdList.length > 0){
            for(let row of createdList){
                if(!row.authorityCode && !row.authorityName){
                    showToast(`코드와 메뉴명은 필수입니다.`);
                    return;
                }
            }

            const parentSeq = depth === 2 ? mdParentSeq : depth === 3 ? smParentSeq : '';
            if(!parentSeq){
                showToast('상위 카테고리(중분류)를 선택 후 입력해 주세요.');
                return;
            }
            createdList = createdList.map(v => {
                v.parentSeq = parentSeq;
                return v;
            });
        }

        if(!await callConfirm(`저장하시겠습니까?`)) return;

        let result = "";
        if(updatedList.length > 0 && createdList.length > 0){
            result = Promise.all([await axios.put('/authority', updatedList), await axios.post('/authority', createdList)]) ;
        }else{
            result = updatedList.length > 0 && await axios.put('/authority', updatedList);
            result = createdList.length > 0 && await axios.post('/authority', createdList);
        }

        showToast('저장되었습니다.');
        await getAuthList();

        mdParentSeq && getGroupAuthList(mdParentSeq, 'sm');
        smParentSeq && getGroupAuthList(smParentSeq, 'detail');
    }

    const addRowInfo =(target)=> {
        if(target === 'md'){
            return { authorityCode: '', authorityName: '', crud: '', depth: 1, parentSeq: '' };
        }else if(target === 'sm'){
            return { authorityCode: '', authorityName: '', crud: 'C,R,U,D', depth: 2, parentSeq: mdParentSeq };
        }else if(target === 'detail'){
            return { authorityCode: '', authorityName: '', crud: 'R', depth: 3, parentSeq: smParentSeq };
        }
    }

    return (
        <CContainer>
            <Row style={{marginTop: -30}}>
                <CCol lg={3}>
                    <Subject>중분류</Subject>
                    <AgGridContainer
                        gridRef={lgGridRef}
                        height={70}
                        rowData={AuthorityStore.orgMdAuthorityList}
                        columnDefs={[
                            {
                                field: "authorityCode",
                                headerName: "코드",
                                width: 100,
                                editable: true,
                                headerClass: 'grid-column-editable grid-column-required',
                            },
                            {
                                field: 'authorityName',
                                headerName: '중분류명',
                                width: 140,
                                editable: true,
                                flex: 1,
                                headerClass: 'grid-column-editable grid-column-required',
                            },
                        ]}
                        seqColumn={'agId'}
                        rowSearchCallback={v => getGroupAuthList(v.node.data.authoritySeq, 'sm')}
                        useRowSearch={true}
                        isCheckBox={true}
                        useCreated={true}
                        noIconBtn={true}
                        callBackGridData={({updatedList, createdList}) => saveCategories(updatedList, createdList, 'md')}
                        addRowInfo={addRowInfo('md')}
                    />
                </CCol>
                <CCol lg={3}>
                    <Subject>소분류</Subject>
                    <AgGridContainer
                        gridRef={mdGridRef}
                        height={70}
                        rowData={AuthorityStore.smAuthorityList}
                        columnDefs={[
                            {
                                field: "authorityCode",
                                headerName: "코드",
                                width: 120,
                                editable: true,
                                headerClass: 'grid-column-editable grid-column-required',
                            },
                            {
                                field: 'authorityName',
                                headerName: '소분류 메뉴명',
                                width: 140,
                                editable: true,
                                flex: 1,
                                headerClass: 'grid-column-editable grid-column-required',
                            },
                        ]}
                        seqColumn={'agId'}
                        rowSearchCallback={v => getGroupAuthList(v.node.data.authoritySeq, 'detail')}
                        useRowSearch={true}
                        isCheckBox={true}
                        //useUpdated={true}
                        useCreated={true}
                        noIconBtn={true}
                        callBackGridData={({updatedList, createdList}) => saveCategories(updatedList, createdList, 'sm')}
                        addRowInfo={addRowInfo('sm')}
                    />
                </CCol>
                <CCol lg={6}>
                    <Subject>기능</Subject>
                    <AgGridContainer
                        gridRef={smGridRef}
                        height={70}
                        rowData={AuthorityStore.detailAuthorityList}
                        columnDefs={[
                            {
                                field: "authorityCode",
                                headerName: "권한코드",
                                minWidth: 120,
                                editable: true,
                                headerClass: 'grid-column-editable grid-column-required',
                            },
                            {
                                field: 'authorityName',
                                headerName: '화면 기능',
                                width: 180,
                                editable: true,
                                headerClass: 'grid-column-editable grid-column-required',
                            },
                            {
                                field: 'crud',
                                headerName: '기능',
                                width: 100,
                                flex: 1,
                                editable: true,
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: { values: ['C', 'R', 'U', 'D'] },
                                headerClass: 'grid-column-editable grid-column-required',
                                valueFormatter: params => params.value === 'C' ? '입력' : params.value === 'R' ? '조회' : params.value === 'U' ? '수정' : '삭제',
                            },
                        ]}
                        isCheckBox={true}
                        useUpdated={true}
                        useCreated={true}
                        noIconBtn={true}
                        seqColumn={'agId'}
                        callBackGridData={({updatedList, createdList}) => saveCategories(updatedList, createdList, 'detail')}
                        addRowInfo={addRowInfo('detail')}
                    />
                </CCol>
            </Row>
        </CContainer>
    )
}

export default observer(AuthorityManager);
