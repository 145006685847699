import { observer } from "mobx-react-lite";
import {CContainer, CCol, CInputGroup, CSearchBtn, CalendarGroup, CSelectGroup} from "../../components/CustomContainer";
import { Row, Col } from "react-bootstrap";
import { AppStore } from "../../store/AppStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import {SalesListStore} from "../../store/customer/SalesListStore";
import {getCommcode} from "../../utils/commcode";
import {useEffect} from "react";
import {endDateForThisMonth, startDateForThisMonth} from "../../utils/dateUtils";

const SalesListSearch = ({getSalesList}) => {

    useEffect(()=> {
        SalesListStore.setStartDate({startDate: startDateForThisMonth()})
        SalesListStore.setEndDate({startDate: endDateForThisMonth()})
    }, [])

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        SalesListStore.setSearchCondition('customerName', customerInfo.customerName);
        SalesListStore.setSearchCondition('customerNo', customerInfo.customerNo);
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = () => {
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo, column) => {
        if (!userInfo || !column) {
            return false;
        }

        SalesListStore.setSearchCondition('saleUserName', userInfo.name);
        SalesListStore.setSearchCondition('saleUserSeq'. userInfo.userSeq);
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'시작일'}
                            value={SalesListStore.startDate}
                            onChange={v => SalesListStore.setStartDate(v)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'종료일'}
                            value={SalesListStore.endDate}
                            onChange={v => SalesListStore.setEndDate(v)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'영업담당자'}
                            value={SalesListStore.searchCondition.saleUserName}
                            onChange={(v)=> SalesListStore.setSearchCondition('saleUserName', v.target.value)}
                            onCallbackBtn={() => openSearchUserPopup('saleUser')}
                            enterKeyHint={getSalesList}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getSalesList} />
                    </Col>
                </Row>
            </CContainer>
            {/* popup */}
            <CustomerSearch callbackFn={getCustomerInfo}/>
            <UserSearch callbackFn={getUserInfo} />
        </>
    );

}

export default observer(SalesListSearch);
