import React, { forwardRef, useEffect, useState } from "react";

import "./reportTemplate.css";
import { ReleaseAskDetailStore } from "../../store/release/ReleaseAskDetailStore";
import { getLocalStorage } from "../../utils";
import { USER_INFO } from "../../common/constants";
import axios from "axios";
import ReportContractTemplate from "./ReportContractTemplate";
import { TemplateStore } from "../../store/contract/TemplateStore";
import { today } from "../../utils/dateUtils";


const ReportTemplate =(props, ref)=> {
    const {mailCheckedList} = props;    // pdfType1: 안전점검결과서, pdfType2: 보험증권, pdfType3: 제원표, pdfType4: 안전인증서, pdfType5: 자산안전인증서, pdfType6: 작동방법설명서, pdfType7: 비상하강, pdfType8: 반입전 체크리스트, pdfType9: 계약서
    const dataList = ReleaseAskDetailStore.dataList;

    const [userInfo, setUserInfo] = useState({});
    const [emptyList, setEmptyList] = useState([]);
    
    useEffect(()=> {
        setUserInfo(getLocalStorage(USER_INFO));

        if (ReleaseAskDetailStore.releaseAskDetail.contractNo) {
            getContractDetail();
        }

        if(dataList.length < 8){
            const list = [];
            for(let i = 0; i < 8 - dataList.length; i++){
                list.push(0);
            }
            setEmptyList(list);
        }

    }, [ReleaseAskDetailStore.releaseAskDetail.contractNo])

    const getContractDetail = async () => {
        const listResult = await axios.get(`/contract/${ReleaseAskDetailStore.releaseAskDetail.contractNo}`);
        const productListResult = await axios.get(`/contract/product/${ReleaseAskDetailStore.releaseAskDetail.contractNo}`);

        TemplateStore.setReleaseAskTemplateData(listResult, productListResult);
    }

    return (
        <div ref={ref}>
            
            {/* 통장사본 - 회사별 1부만 필요 */}
            {(mailCheckedList.includes('pdfType11') && dataList[0].bankbookImageUrl !== null && dataList[0].bankbookImageUrl !== '') && (
                <div className="report-template">
                    <div  className="print-template">
                        <img src={dataList[0].bankbookImageUrl} />
                    </div>
                </div>
            )}

            {/* 안전인증서(개별) */}
            {(mailCheckedList.includes('pdfType5')) && (
                dataList && dataList.map((data, index) => (
                    (data.isOption === 0 && data.assetCertificateUrl !== null && data.assetCertificateUrl !== '') && 
                    <div className="report-template" key={`asset-${index}`}>
                        <div  className="print-template">
                            <img src={data.assetCertificateUrl} />
                        </div>
                    </div>
                ))
            )}

            {(mailCheckedList.includes('pdfType3')||mailCheckedList.includes('pdfType4')||mailCheckedList.includes('pdfType6')||mailCheckedList.includes('pdfType7')) && (
                dataList && dataList.filter((val, idx, callback) => 
                    idx === callback.findIndex(t => t.model === val.model)
                ).map((data, index) => (
                    data.isOption === 0 &&
                    <div key={index}>
                    {/* 제원표 */}
                    {(mailCheckedList.includes('pdfType3') && data.specificationTableUrl !== null && data.specificationTableUrl !== '') && (
                        <div className="report-template">
                            <div  className="print-template">
                                <img src={data.specificationTableUrl} />
                            </div>
                        </div>
                    )}
                    {/* 안전인증서 */}
                    {(mailCheckedList.includes('pdfType4') && data.safetyCertificateUrl !== null && data.safetyCertificateUrl !== '') && (
                        <div className="report-template">
                            <div  className="print-template">
                                <img src={data.safetyCertificateUrl} />
                            </div>
                        </div>
                    )}
                    {/* 작동방법설명서 */}
                    {(mailCheckedList.includes('pdfType6') && data.workingMethodUrl !== null && data.workingMethodUrl !== '') && (
                        <div className="report-template">
                            <div  className="print-template">
                                <img src={data.workingMethodUrl} />
                            </div>
                        </div>
                    )}
                    {/* 비상하강 */}
                    {(mailCheckedList.includes('pdfType7') && data.emergencyDownUrl !== null && data.emergencyDownUrl !== '') && (
                        <div className="report-template">
                            <div  className="print-template">
                                <img src={data.emergencyDownUrl} />
                            </div>
                        </div>
                    )}
                    </div>
                ))
            )
            }
            
            
            {/* 관리번호별 1부씩 필요 */}
            {(mailCheckedList.includes('pdfType1') || mailCheckedList.includes('pdfType8')) && (
                dataList && dataList.map((data, index) => (
                    data.isOption === 0 && 
                    <div key={index}>
                        {/* 안전점검결과서 */}
                        {(mailCheckedList.includes('pdfType1')) && (
                        <div className="report-template">
                            <div className="print-template">
                                <h4 style={{textAlign: 'center', marginBottom: 0, marginTop: 10}}>고소작업대(T/L) 안전점검 결과서</h4>
                                <table className="table" style={{borderWidth: 1}}>
                                    <tbody>
                                        <tr>
                                            <td className='p-1 border' style={{width: '13%', background: 'lightgray', textAlign: 'center', fontSize: 8}}>사업장명</td>
                                            <td className='p-1 border' style={{width: '17%', fontSize: 8}}>{data?.customerName}</td>
                                            <td className='p-1 border' style={{width: '13%', background: 'lightgray', textAlign: 'center', fontSize: 8}}>형식</td>
                                            <td className='p-1 border' style={{width: '17%', fontSize: 8}}>{data?.mdName}</td>
                                            <td className='p-1 border' style={{width: '17%', background: 'lightgray', textAlign: 'center', fontSize: 8}}>제조사(렌탈사)</td>
                                            <td className='p-1 border' style={{width: '23%', fontSize: 8}}>{data?.brandName + '(' + userInfo.companyName + ')'}</td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{background: 'lightgray', textAlign: 'center', fontSize: 8}}>사용장소</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>{data?.siteName}</td>
                                            <td className='p-1 border' style={{background: 'lightgray', textAlign: 'center', fontSize: 8}}>동력전달방식</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>{data?.powerTransmission}</td>
                                            <td className='p-1 border' style={{background: 'lightgray', textAlign: 'center', fontSize: 8}}>모델명</td>
                                           <td className='p-1 border' style={{width: '17%', fontSize: 8}}>{data?.model}</td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{background: 'lightgray', textAlign: 'center', fontSize: 8}}>운전방식</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>{data?.drivingMethod}</td>
                                            <td className='p-1 border' style={{background: 'lightgray', textAlign: 'center', fontSize: 8}}>운행속도</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>{data?.drivingSpeed}</td>
                                            <td className='p-1 border' style={{background: 'lightgray', textAlign: 'center', fontSize: 8}}>작업대최대높이/적재용량</td>
                                            <td className='p-1 border' style={{width: '17%', fontSize: 8}}>{data?.heightAndLoadCapacity}</td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{background: 'lightgray', textAlign: 'center', fontSize: 8}}>차량번호</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>{data?.managementNo + '(' + data?.serialNo + ')'}</td>
                                            <td className='p-1 border' style={{background: 'lightgray', textAlign: 'center', fontSize: 8}}>연식</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>{data?.manufacturing}</td>
                                            <td className='p-1 border' style={{background: 'lightgray', textAlign: 'center', fontSize: 8}}>{data.certificateCheckDate ? '안전인증년월일' : '안전인증여부'}</td>
                                            <td className='p-1 border' style={{width: '17%', fontSize: 8}}>{data.certificateCheckDate ? data.certificateCheckDate : '형식별 인증'}</td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{background: 'lightgray', textAlign: 'center', fontSize: 8}}>안전점검일</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>{today()}</td>
                                            <td className='p-1 border' style={{background: 'lightgray', textAlign: 'center', fontSize: 8}}>점검회사</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>{userInfo.companyName}</td>
                                            <td className='p-1 border' style={{background: 'lightgray', textAlign: 'center', fontSize: 8}}>점검자</td>
                                            <td className='p-1 border' style={{width: '17%', fontSize: 8}}>{data?.inspectionManager}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="mb-1" style={{fontSize: 8}}>◼︎ 점검유효기간: 점검일로부터 1년</div>
                                <table className="table" style={{borderWidth: 1}}>
                                    <thead>
                                        <tr>
                                            <th className='p-1 border' style={{width: '18%', textAlign: 'center', fontSize: 8}}>검사부분</th>
                                            <th className='p-1 border' style={{width: '68%', textAlign: 'center', fontSize: 8}}>검사항목</th>
                                            <th className='p-1 border' style={{width: '7%', textAlign: 'center', fontSize: 8}}>검사결과</th>
                                            <th className='p-1 border' style={{width: '7%', textAlign: 'center', fontSize: 8}}>조치사항</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='p-1 border' colSpan={4} style={{fontSize: 8}}>1. 공통사항</td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' rowSpan={3} style={{fontSize: 8}}>(1)등록번호표 등</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>제조일로부터 15년 이내의 장비일 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>붕대, 아웃트리거, 용접부등 비과세 검사 성적서 비치되어 있을 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>운전원은 장비의 운전 및 안전에 대한 교육을 받은 유경험자이고 보험(자차 등)에 가입되어 있을 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' colSpan={4} style={{fontSize: 8}}>2. 차대와 타이어(안정기)</td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' rowSpan={2} style={{fontSize: 8}}>(1)차체 및 타이어(안정기)</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>차체의 균열, 변형, 손상 및 부식이 없을 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>타이어의 이상마모 및 변형이 없고, 구동축에서 견고하게 고정되어 있고 림부의 체결볼트, 너트 등이 견고하게 체결될 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' rowSpan={4} style={{fontSize: 8}}>(2)동력원</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>유압펌프와 모터는 설치상태가 견고하고 작동상태에서 심한 진동과 이상음이 없을 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>유압실린더,유압호스,파이프,밸브,탱크등 연결부는 균열,손상 및 마멸이 없고 기름누출이 없을 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>작동유 탱크는 적정 유량을 유지하고 작동유의 오염이 없을 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>축전지의 단락, 손상 및 단자 부식이 없고 배선부분은 과열에 의한 열화가 없을 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' colSpan={4} style={{fontSize: 8}}>3. 연장구조물(마스트)</td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' rowSpan={2} style={{fontSize: 8}}>(1)구조부</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>정비를 위해 일정 상승 위치에서 연장 구조물을 고정할 수 있는 고정받침대를 구비할 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8, lineHeight: '12px'}}>구조물의 균열, 변형 및 손상이 없고 힌지부 연결핀 고정상태가 양호하고 잠금밸브(카운터밸런스밸브)는 정상 작동되고 배관 연결부 등의 누유가 없을 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' colSpan={4} style={{fontSize: 8}}>4. 작업대</td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' rowSpan={2} style={{fontSize: 8}}>(1)낙하 및 추락 방호 조치</td>
                                            <td className='p-1 border' style={{fontSize: 8, lineHeight: '12px'}}>작업대의 난간높이 1.0m 이상, 발끝막이판 높이 0.15m 이상(출입구는 0.1m 이상)이고 상부난간대와 발끝막이판 사이 0.55m이내의 중간대 설치할 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>바닥면은 배수가 가능하고 미끄럼 방지 구조일 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>(2)접근사다리</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>작업대 바닥높이가 지면에서 0.4m 초과시 접근사다리를 설치할 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' colSpan={4} style={{fontSize: 8}}>5. 제어장치</td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' rowSpan={2} style={{fontSize: 8}}>(1)제어장치</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>조작한 경우에만 작동하고 해제하면 자동으로 중립위치로 복귀하고 작동방향을 문자나 기호로 명확하게 표시되어 있을 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>우발 동작을 방지하기 위한 상호 연동장치를 설치할 것(조종레버의 인에이블 스위치와 발판식 인에이블 스위치)</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' colSpan={4} style={{fontSize: 8}}>6. 표시</td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' rowSpan={3} style={{fontSize: 8}}>(1)경고 표시</td>
                                            <td className='p-1 border' style={{fontSize: 8, lineHeight: '12px'}}>명판에는 제조자명, 모델명(형식번호), 제조번호, 제조년월, 최대작업높이, 정격하중, 허용탑승인원수, 최대허용풍속, 최대허용경사, 동력원 사양, 안전인증 표시등을 표시할 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>작업대에는 정격하중, 허용탑승인원수, 최대허용풍속(옥내용인 경우 제외)을 표시할 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>비상안전장치의 위치 및 사용법을 표시할 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' colSpan={4} style={{fontSize: 8}}>7. 점등 및 조명장치 등</td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>(1)점등 및 조명장치 등</td>
                                            <td className='p-1 border' style={{fontSize: 8, lineHeight: '12px'}}>전조등,미등,측등,계기판등,후퇴등,차폭등,번호등,방향지시등,제동등,작업등,속도표시등등 설치된점등 및 조명장치는 정위치에 견고하게 부착되어 손상이 없어야 하며 점등상태가 양호할 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>(2)계기장치</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>연료계,유량계,회전계,압력계등 설치된 계기장치는 작동상태가 양호할 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>(3)경음기 및 경보장치</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>경음기 및 경보장치의 음의 크기는  기준의 범위 이내일 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' colSpan={4} style={{fontSize: 8}}>8. 안전장치</td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>(1)자동안전장치</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>작업대가 상승한 상태로 차대 이동시 주행속도를 자동으로 제한하는 장치가 있을 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>(2)경사표시장치(전복방지장치)</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>차대의 경사 허용 한도(제작자 기준) 초과시 상승 및 주행이 불가능 하도록 할 것(음향 신호 발생)</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>(3)비상정지장치</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>비상정지용 누름버튼은 적색이며 머리부분이 돌출되고 수동으로 복귀되는 형식일 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' style={{fontSize: 8}}>(3)비상안전장치</td>
                                            <td className='p-1 border' style={{fontSize: 8}}>동력공급이 차단되었을 때, 안전하게 작업대를 빠져 나올 수 있는 위치로 작업대를 복귀시킬 수 있는 비상 안전장치를 설치할 것</td>
                                            <td className='p-1 border' style={{textAlign: 'center', fontSize: 8}}>○</td>
                                            <td className='p-1 border' style={{fontSize: 8}}></td>
                                        </tr>
                                        <tr>
                                            <td className='p-1 border' colSpan={4} style={{height: 20, fontSize: 8}}>검사자 의견</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p style={{fontSize: 8}}>* 검사결과 표시 : 양호 ○, 조정(보완)△, 교환□, 제작(설치)Φ, 폐기×, 해당무-</p>
                            </div>
                        </div>
                        )}

                        {/* 반입전 체크리스트 */}
                        {(mailCheckedList.includes('pdfType8')) && (
                            <div className="report-template">
                                <div className="print-template" id="checkList" style={{marginTop: 30}}>
                                    <table className="table" style={{borderWidth: 1}}>
                                        <thead>
                                            <tr height={20}>
                                                <th colSpan={12} className='border' style={{textAlign: 'center', fontSize: 18, fontWeight: 'bold'}}>
                                                    모델 : ({data?.model || '      '}) ▣ 반입 전 CHECK LIST (관리번호: {data?.managementNo || '      '})
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={12} className='border' style={{textAlign: 'left', fontSize: 10}}>
                                                    점검일 : {data?.releaseDate || ''}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={1} className='border' style={{width: '1%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>NO.</td>
                                                <td colSpan={2} className='border' style={{width: '33%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>내용</td>
                                                <td colSpan={2} className='border' style={{width: '10%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>결과</td>
                                                <td colSpan={1} className='border' style={{width: '6%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>수리</td>
                                                <td colSpan={1} className='border' style={{width: '1%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>NO.</td>
                                                <td colSpan={2} className='border' style={{width: '33%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>내용</td>
                                                <td colSpan={2} className='border' style={{width: '10%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>결과</td>
                                                <td colSpan={1} className='border' style={{width: '6%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>수리</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className='border' style={{width: '22%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>▣ 입고검사</td>
                                                <td colSpan={1} className='border' style={{width: '10%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>검사기준</td>
                                                <td colSpan={1} className='border' style={{width: '5%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>불량</td>
                                                <td colSpan={1} className='border' style={{width: '5%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>양호</td>
                                                <td colSpan={1} className='border' style={{width: '8%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>점검자</td>
                                                <td colSpan={2} className='border' style={{width: '22%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>▣ 전기검사</td>
                                                <td colSpan={1} className='border' style={{width: '10%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>검사기준</td>
                                                <td colSpan={1} className='border' style={{width: '5%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>불량</td>
                                                <td colSpan={1} className='border' style={{width: '5%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>양호</td>
                                                <td colSpan={1} className='border' style={{width: '8%', textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>점검자</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>1</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>장비외관상태</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                                <td rowSpan={9} className='border' style={{textAlign: 'center', fontSize: 9, verticalAlign: 'middle'}}>{data?.inspectionManager}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>36</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>배터리, 장비 연결잭</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                                <td rowSpan={7} className='border' style={{textAlign: 'center', fontSize: 9, verticalAlign: 'middle'}}>{data?.inspectionManager}</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>2</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>스위치류 작동, 외관상태</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>37</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>배터리 터미널 조임</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>3</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>주행전.후진</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>38</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>배터리비중/부하시험(v)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>5.25V이상</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>4</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>리프트업 주행(주행차단)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>39</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>배터리증류수극판위10MM</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>10mm이상</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>보충</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>정상</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>5</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>고속.저속 주행</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>40</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>하부리프트작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>6</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>조향 좌.우회전</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>41</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>경광등</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>7</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>리프트업.다운</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>42</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>노면접지</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>8</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>엔진시동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td colSpan={2} className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>▣ 차체</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}></td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>불량</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>양호</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>점검자</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>9</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>소음 및 보조지지대 작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>43</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>도장/세차상태</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                                <td rowSpan={14} className='border' style={{textAlign: 'center', fontSize: 9, verticalAlign: 'middle'}}>{data?.inspectionManager}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>▣ 세차</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>불량</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>양호</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>점검자</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>44</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>바퀴조임상태</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>10</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>이물질제거</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                                <td rowSpan={4} className='border' style={{textAlign: 'center', fontSize: 9, verticalAlign: 'middle'}}>{data?.inspectionManager}</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>45</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>엑슬킹핀</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>11</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>세차</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>46</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>허브어셈블리</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>12</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>차체파손 유,무</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>47</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>타이어 마모정도</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>13</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>에어건조</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>48</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>하부도어 잠금장치</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>▣ 도장</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>불량</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>양호</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>점검자</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>49</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>씨져핀 이상</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>14</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>도장</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                                <td rowSpan={4} className='border' style={{textAlign: 'center', fontSize: 9, verticalAlign: 'middle'}}>{data?.inspectionManager}</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>50</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>씨져 외관</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>15</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>차체파손 유,무</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>51</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>풋홀시스템</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>16</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>관리번호(제조번호) 확인</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>52</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>확장대 작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>17</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>스티커 부착상태</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>53</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>확장대 로울러</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>▣ 유압</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>불량</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>양호</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>점검자</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>54</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>확장대 고정핀</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>18</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>유압 오일양(리프트하강후)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                                <td rowSpan={9} className='border' style={{textAlign: 'center', fontSize: 9, verticalAlign: 'middle'}}>{data?.inspectionManager}</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>55</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>안전고리(체인/도어)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>19</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>리프트실린더(작동/누유)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>56</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>그리스 주입</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>20</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>비상하강</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td colSpan={2} className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>▣ 출고정비</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>불량</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>양호</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>점검자</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>21</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>누유(블록/호스/니쁠)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>57</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>주행(전진/후진)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                                <td rowSpan={8} className='border' style={{textAlign: 'center', fontSize: 9, verticalAlign: 'middle'}}>{data?.inspectionManager}</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>22</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>브레이크(작동/누유)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>58</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>리프트(상승/하강)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>23</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>스티어링(작동/누유)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>59</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>주행차단(현장기준)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>24</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>주행모터(작동/누유)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>60</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>감지봉작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>25</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>주행해제(프리휠링벨브)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>61</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>외관상태</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>26</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>비상펌프작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>62</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>배터리충전상태</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>▣ 전기장치</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>불량</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>양호</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>점검자</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>63</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>풋홀시스템</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>27</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>전기배선상태</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                                <td rowSpan={9} className='border' style={{textAlign: 'center', fontSize: 9, verticalAlign: 'middle'}}>{data?.inspectionManager}</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>64</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>옵션작동(기능적)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>28</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>콘트롤박스 (작동/스티커)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td colSpan={2} className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>▣ 옵션장착</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>불량</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>양호</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9, fontWeight: 'bold'}}>점검자</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>29</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>연결잭(감지봉/풋스위치)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>65</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>보호망(함석 외)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                                <td rowSpan={7} className='border' style={{textAlign: 'center', fontSize: 9, verticalAlign: 'middle'}}>{data?.inspectionManager}</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>30</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>과상승방지봉</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>66</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>협착난간대</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>31</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>주행차단</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>67</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>타이어세척</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>32</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>풋스위치</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>68</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>충격흡수</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>33</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>작동알람</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>69</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>용접보호판</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>34</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>충전플러그</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>70</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>부착물(스티커)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>육안</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>35</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>충전기작동값26A이하</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>20.7A</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                    
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>71</td>
                                                <td className='border' style={{textAlign: 'left', fontSize: 9}}>낙하물방지턱(현장기준)</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>작동</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>{}</td>
                                                <td className='border' style={{textAlign: 'center', fontSize: 9}}>○</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={12} className='border' style={{textAlign: 'center', fontSize: 12}}>
                                                    ※ 주의 : 1. 기준은 출고시에 점검 체크 기준이며 배터리 충전 상태에 따라 성능이 달라질 수 있습니다.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={12} className='border' style={{textAlign: 'center', fontSize: 12}}>
                                                    2. 사용중 배터리의 충전상태 장비의 노후 상태에 따라 속도의 차이가 발생할 수 있습니다.
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                ))
            )}
            {/* 계약서 - 맨 처음에 한 번만 출력 */}
            {mailCheckedList.includes('pdfType9') && (
               <ReportContractTemplate />
            )}

            {/* 보험증권 - 회사별 1부만 필요 */}
            {(mailCheckedList.includes('pdfType2') && dataList[0].insurancePolicyUrl !== null && dataList[0].insurancePolicyUrl !== '') && (
                <div className="report-template">
                    <div  className="print-template">
                        <img src={dataList[0].insurancePolicyUrl} />
                    </div>
                </div>
            )}
            {/* 사업자등록증 - 회사별 1부만 필요 */}
            {(mailCheckedList.includes('pdfType10') && dataList[0].businessLicenseUrl !== null && dataList[0].businessLicenseUrl !== '') && (
                <div className="report-template">
                    <div  className="print-template">
                        <img src={dataList[0].businessLicenseUrl} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default forwardRef(ReportTemplate);
