import { observer } from "mobx-react-lite"
import { Row, Col } from "react-bootstrap";
import { GeneralStatementStore } from "../../store/accounting/GeneralStatementStore";
import { CContainer, CCol, CalendarGroup, CInputGroup, CSelectGroup, CSearchBtn, CAddBtn, CIconBtn } from "../../components/CustomContainer";
import { getCommcode } from "../../utils/commcode";
import UserSearch from "../../components/searchModal/UserSearch";
import GeneralStatementDetail from "./GeneralStatementDetail";
import { AppStore } from "../../store/AppStore";
import Modal from "../../components/Modal";
import { dateFormat } from "../../utils/dateUtils";
import { showToast } from "../../common/utils";

const GeneralStatementListSearch = ({getStatementList}) => { 

    /* 날짜 선택 */
    const onChangeSearchValue = async (v, label) => {
        const { startDate, endDate } = v;
        const storeStartDate = new Date(GeneralStatementStore.search.startDate);
        const storeEndDate = new Date(GeneralStatementStore.search.endDate);
        const selectedDate = new Date(startDate);

        if (label === 'startDate' && storeEndDate < selectedDate) {
            showToast('종료일보다 작게 선택해주세요.');
        } else if (label === 'endDate' && endDate !== null && storeStartDate > selectedDate) {
            showToast('시작일보다 크게 선택해주세요.');
        } else {
            GeneralStatementStore.setSearch({ [label]: dateFormat(selectedDate) });
        }
    };

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = () => {
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const searchUserInfo = (data) => {
        if(data) {
            GeneralStatementStore.search.creator = data.userSeq;
            GeneralStatementStore.search.creatorName = data.name;
        }
    }

    /* 입력사원명 수정 이벤트 */
    const changeName = (v) => {
        GeneralStatementStore.search.creator = '';
        GeneralStatementStore.search.creatorName = v.target.value;
    }

    /* 전표 생성 모달창 열기 */
    const createGeneralStatement = () => {
        AppStore.toggleModal();
    }
    
    /* 전표 생성 모달창 닫기 */
    const closeModal = () => {
        GeneralStatementStore.init();
    };

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CalendarGroup asSingle={true} label={'결의일자 시작일'}
                                       value={{
                                            startDate: GeneralStatementStore.search.resolutionStartDate,
                                            endDate: GeneralStatementStore.search.resolutionStartDate
                                       }}
                                       onChange={v => onChangeSearchValue(v, 'resolutionStartDate')}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CalendarGroup asSingle={true} label={'결의일자 종료일'}
                                       value={{
                                            startDate: GeneralStatementStore.search.resolutionEndDate,
                                            endDate: GeneralStatementStore.search.resolutionEndDate
                                       }}
                                       onChange={v => onChangeSearchValue(v, 'resolutionEndDate')}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'전표구분'} labelId={'statementNoType'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('42'))}
                                      value={GeneralStatementStore.search.statementNoType}
                                      onChange={v => GeneralStatementStore.search.statementNoType = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'승인여부'} labelId={'approvalStatus'}
                                      options={[{name: '전체', value: ''}, {name: '미결', value: '1'}, {name: '승인', value: '2'}]}
                                      value={GeneralStatementStore.search.approvalStatus}
                                      onChange={v => GeneralStatementStore.search.approvalStatus = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'입력사원'}
                            labelId={'creator'}
                            onCallbackBtn={openSearchUserPopup}
                            value={GeneralStatementStore.search.creatorName}
                            onChange={v => changeName(v)}
                            enterKeyHint={getStatementList}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse mt-3'>
                        <CSearchBtn onClick={getStatementList} />
                        <CIconBtn style={{width: 110}}
                                title={'전표등록'}
                                icon={'fi-rr-add-document'}
                                onClick={createGeneralStatement}
                        />
                    </Col>
                </Row>
            </CContainer>
            <UserSearch callbackFn={searchUserInfo}/>
            <Modal title={'일반전표등록'} onExit={closeModal}>
                <GeneralStatementDetail/>
            </Modal>
        </>
    );

}

export default observer(GeneralStatementListSearch);