import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Row, Col } from "react-bootstrap";

import { CCol, CContainer, CalendarGroup, CSearchBtn, CInputDoubleGroup, CIconBtn } from "../../components/CustomContainer";
import { AppStore } from "../../store/AppStore";
import { startDateForThisYear, endDateForThisYear, startDateForThisMonth, endDateForThisMonth, startDateForLastYear, endDateForLastYear, startDateForNextYear, endDateForNextYear, startDateForLastMonth, endDateForLastMonth, startDateForNextMonth, endDateForNextMonth } from "../../utils/dateUtils";
import { AccountLedgerStore } from "../../store/accounting/AccountLedgerStore";
import AccountingCodeSearch from "../../components/searchModal/AccountingCodeSearch";
import axios from "axios";

const AccountLedgerListSearch = ({getAccountLedgerList, setType}) => {
    const store = AccountLedgerStore;
    const [inputCode, setInputCode] = useState('');
    const [division, setDivision] = useState('');

    /* 금년 */
    const thisYearBtn = () => {
        store.search.startDate = startDateForThisYear();
        store.search.endDate = endDateForThisYear();
    }

    /* 전년 */
    const prevYearBtn = () => {
        store.search.startDate = startDateForLastYear(store.search.startDate);
        store.search.endDate = endDateForLastYear(store.search.endDate);
    }

    /* 후년 */
    const nextYearBtn = () => {
        store.search.startDate = startDateForNextYear(store.search.startDate);
        store.search.endDate = endDateForNextYear(store.search.endDate);
    }

    /* 금월 */
    const thisMonthBtn = () => {
        store.search.startDate = startDateForThisMonth();
        store.search.endDate = endDateForThisMonth();
    }

    /* 전월 */
    const prevMonthBtn = () => {
        store.search.startDate = startDateForLastMonth(store.search.startDate);
        store.search.endDate = endDateForLastMonth(store.search.endDate);
    }

    /* 후월 */
    const nextMonthBtn = () => {
        store.search.startDate = startDateForNextMonth(store.search.startDate);
        store.search.endDate = endDateForNextMonth(store.search.endDate);
    }

      /* 계정코드 enter */
      const onChangeAccountCode = async () => {
        if(division === 'first') {
            const accountCode = String(store.search.firstAccountCode);
            if(store.search.firstAccountCode.length >= 3) {
                const result = await axios.get('statement/accounting/code', {params: {type: 'code', inputText: accountCode}});
                if(result.length === 1) {
                    store.search.firstAccountCode = result[0].code;
                    store.search.firstAccountName = result[0].name;
                    store.search.lastAccountCode = result[0].code;
                    store.search.lastAccountName = result[0].name;
                }else {
                    setInputCode(store.search.firstAccountCode);
                    AppStore.toggleAccountingCodePopup();
                }
            }
        }else if(division === 'last') {
            const accountCode = String(store.search.lastAccountCode);
            if(store.search.lastAccountCode.length >= 3) {
                const result = await axios.get('statement/accounting/code', {params: {type: 'code', inputText: accountCode}});
                if(result.length === 1){
                    store.search.lastAccountCode = result[0].code;
                    store.search.lastAccountName = result[0].name;
                }else {
                    setInputCode(store.search.firstAccountCode);
                    AppStore.toggleAccountingCodePopup();
                }
            }
        }
    }

    /* 계정 과목 팝업 callback */
    const searchAccountingCode = (data) => {
        if(division === 'first'){
            store.search.firstAccountCode = data.code;
            store.search.firstAccountName = data.name;
            store.search.lastAccountCode = data.code;
            store.search.lastAccountName = data.name;
        }else if(division === 'last'){
            store.search.lastAccountCode = data.code;
            store.search.lastAccountName = data.name;
        }
        setInputCode('');
    }

    return (
        <>
            <CContainer search height={'100%'}>
                <Row>
                    <CCol lg={2}>
                        <CalendarGroup label={'시작일'}
                                        asSingle={true}
                                        value={{
                                            startDate: store.search.startDate,
                                            endDate: store.search.startDate
                                        }}
                                        onChange={v => {store.search.startDate = v.startDate}}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup label={'종료일'}
                                        asSingle={true}
                                        value={{
                                            startDate: store.search.endDate,
                                            endDate: store.search.endDate
                                        }}
                                        onChange={v => {store.search.endDate}}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CIconBtn onClick={prevYearBtn} title={'<'} style={{minWidth: '10px'}} className={'me-0'} />
                        <CIconBtn onClick={thisYearBtn} title={'금년'} className={'ms-1 me-1'} />
                        <CIconBtn onClick={nextYearBtn} title={'>'} style={{minWidth: '10px'}} className={'ms-0 me-3'} />
                        <CIconBtn onClick={prevMonthBtn} title={'<'} style={{minWidth: '10px'}} className={'me-0'} />
                        <CIconBtn onClick={thisMonthBtn} title={'금월'} className={'ms-1 me-1'} />
                        <CIconBtn onClick={nextMonthBtn} title={'>'} style={{minWidth: '10px'}} className={'ms-0'} />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <CInputDoubleGroup label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                                            placeholder={'고객코드'} placeholder2={'고객명'}
                                            onCallbackBtn={() => AppStore.toggleCustomerSearchModal()}
                                            value={store.search.customerNo} value2={store.search.customerName}
                                            onChange={v => store.search.customerNo = v.target.value}
                                            onChange2={v => store.search.customerName = v.target.value}
                                            enterKeyHint1={()=> getAccountLedgerList()}
                                            enterKeyHint2={()=> getAccountLedgerList()}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputDoubleGroup label={'계정과목'} labelId={'firstAccountCode'} labelId2={'firstAccountName'}
                                            placeholder={'계정코드'} placeholder2={'계정과목명'}
                                            onCallbackBtn={() => (AppStore.toggleAccountingCodePopup(), setType('first'))}
                                            value={store.search.firstAccountCode} value2={store.search.firstAccountName}
                                            onChange={v => {store.search.firstAccountCode = v.target.value; setDivision('first')}}
                                            onChange2={v => store.search.firstAccountName = v.target.value}
                                            disabled2={true}
                                            enterKeyHint1={()=> onChangeAccountCode()}
                                            enterKeyHint2={()=> onChangeAccountCode()}
                        />
                    </CCol>
                    ~
                    <CCol lg={3}>
                        <CInputDoubleGroup label={'계정과목'} labelId={'lastAccountCode'} labelId2={'lastAccountName'}
                                            placeholder={'계정코드'} placeholder2={'계정과목명'}
                                            onCallbackBtn={() => (AppStore.toggleAccountingCodePopup(), setType('last'))}
                                            value={store.search.lastAccountCode} value2={store.search.lastAccountName}
                                            onChange={v => {store.search.lastAccountCode = v.target.value; setDivision('last')}}
                                            onChange2={v => store.search.lastAccountName = v.target.value}
                                            disabled2={true}
                                            enterKeyHint1={()=> onChangeAccountCode()}
                                            enterKeyHint2={()=> onChangeAccountCode()}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getAccountLedgerList} />
                    </Col>
                </Row>
            </CContainer>
            <AccountingCodeSearch callbackFn={searchAccountingCode} inputCode={inputCode} />
        </>
    );
}

export default observer(AccountLedgerListSearch);
