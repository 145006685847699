import {useRef, useState} from "react";
import { observer } from "mobx-react-lite";
import {CCol, CContainer,  CContainerNonBox, Subject} from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import axios from "axios";
import {showToast} from "../../common/utils";
import AssetMeterListSearch from "./AssetMeterListSearch";
import {AssetMeterListStore} from "../../store/asset/AssetMeterListStore";
import {Tab, Tabs, Row, InputGroup, Form} from "react-bootstrap";
import {Chart, Bar} from "react-chartjs-2";
import {getUserName} from "../../utils/userUtils";
import {findCommKrnm} from "../../utils/commcode";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip,} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
);

const AssetMeterList = () => {
    const gridRef = useRef();

    const getAssetMeterList =async()=> {
        const param = Object.assign(AssetMeterListStore.searchCondition, {startDate: AssetMeterListStore.startDate.startDate, endDate: AssetMeterListStore.endDate.startDate});
        const result = await axios.get('/asset/meterList', { params: param });

        AssetMeterListStore.setAssetMeterList(result);

        //차트용 데이터
        if(result.length > 0){
            const arrMeter = result.filter((v, i) => result.findIndex(x => x.meter === v.meter) === i).map(v => {return v.meter});
            //미터 데이터를 담아둔다.
            AssetMeterListStore.setChartMeterList(arrMeter);
            const operatingRateList = [];

            AssetMeterListStore.chartMeterList.forEach(v => {
                const data = result.map(x => {
                    if(v === x.meter){
                        return x;
                    }else {
                        return null;
                    }
                }).filter(e => e);

                operatingRateList.push(data.reduce((total, v) => total + v.operatingRate, 0));
            });
            AssetMeterListStore.setOperatingRateList(operatingRateList);
        }else{
            AssetMeterListStore.chartInit();
        }
    }

    const searchDateHtml =()=> {
        return <span style={{paddingTop: 5, marginRight: 10}}>기준일 : {`${AssetMeterListStore.startDate.startDate} ~ ${AssetMeterListStore.endDate.startDate}`}</span>;
    }

    return(
        <>
            <AssetMeterListSearch getAssetMeterList={getAssetMeterList}/>
            <CContainer>
                <Tabs defaultActiveKey="year" className="mb-3"
                      mountOnEnter={true}
                      unmountOnExit={true}
                      activeKey={AssetMeterListStore.detailTab}
                      onSelect={k => {
                          AssetMeterListStore.setDetailTab(k);
                      }}
                >
                    <Tab eventKey="year" title="미터별 가동율">
                        <Row>
                            <CCol lg={6}>
                                <CContainerNonBox style={{height: 360, background: 'linear-gradient(0deg, rgba(36,0,0,1) 0%, rgba(78,227,237,1) 0%, rgba(173,173,158,1) 100%)'}}>

                                    <div style={{height: 300, display: 'flex', justifyContent: 'center'}}>
                                        <Chart type={"bar"}
                                               options={
                                                   {
                                                       indexAxis: 'y',
                                                       responsive: true,
                                                       plugins: {
                                                           legend: {
                                                               position: 'top',
                                                               display: false,
                                                           },
                                                           title: {
                                                               text: "text",
                                                           }
                                                       },
                                                       color: 'rgb(95, 105, 117)',
                                                       borderColor: '#fff',
                                                       backgroundColor: '#fff'
                                                   }
                                               }
                                               data={{
                                                   labels: AssetMeterListStore.chartMeterList,
                                                   datasets: [
                                                       {
                                                           label: "가동율(%)",
                                                           data: AssetMeterListStore.operatingRateList,
                                                           borderColor: '#fff',
                                                           backgroundColor: '#2779df',
                                                           tension: 0.5,
                                                       }
                                                   ]}
                                               }
                                               style={{height: 190}}
                                        />
                                    </div>
                                </CContainerNonBox>
                            </CCol>
                        </Row>
                    </Tab>
                </Tabs>
            </CContainer>
        </>
    );

}

export default observer(AssetMeterList);