import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Row, InputGroup, Form, Col } from "react-bootstrap";
import { CSelectGroup, CCol, CalendarGroup, CIconBtn, CSearchBtn } from "../../components/CustomContainer";
import { CContainer } from "../../components/CustomContainer";
import { AttendanceStore } from "../../store/human-resources/AttendanceStore";
import { getPaymentDate } from "../../utils/dateUtils";
import { getCommcode } from "../../utils/commcode";

const AttendanceListSearch = ({getUserList, saveAttendance}) => {
    const [monthList, setMonthList] = useState([{name: '1', value: '01'}
                                                , {name: '2', value: '02'}
                                                , {name: '3', value: '03'}
                                                , {name: '4', value: '04'}
                                                , {name: '5', value: '05'}
                                                , {name: '6', value: '06'}
                                                , {name: '7', value: '07'}
                                                , {name: '8', value: '08'}
                                                , {name: '9', value: '09'}
                                                , {name: '10', value: '10'}
                                                , {name: '11', value: '11'}
                                                , {name: '12', value: '12'}]);

    return (
        <CContainer search>
            <Row>
                <CCol lg={3}>
                    <InputGroup size={'sm'}>
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>귀속년월</InputGroup.Text>
                        <Form.Control
                            style={{flex: 1, textAlign: 'right'}}
                            max={2}
                            aria-describedby={'year'}
                            value={AttendanceStore.search.year}
                            onChange={v => AttendanceStore.search.year = v.target.value}
                        />
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>년</InputGroup.Text>
                        <Form.Select
                            style={{flex: 1, textAlign: 'right'}}
                            aria-label={'month'}
                            value={AttendanceStore.search.month}
                            onChange={v => {AttendanceStore.search.month = v.target.value; 
                                            AttendanceStore.search.paymentDate = getPaymentDate(AttendanceStore.search.year+'-'+AttendanceStore.search.month+'-01');}}
                        >
                            {monthList.map((option, idx)=> {
                                return(
                                    <option key={idx} value={option.value}>{option.name}</option>
                                );
                            })}
                        </Form.Select>
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>월</InputGroup.Text>
                    </InputGroup>
                </CCol>
                <CCol lg={2}>
                    <CSelectGroup label={'급여구분'} labelId={'salaryType'}
                                    options={getCommcode('48')}
                                    value={AttendanceStore.search.salaryType}
                                    onChange={v => AttendanceStore.search.salaryType = v.target.value}
                    />
                </CCol>
                <CCol lg={2}>
                    <CalendarGroup
                            asSingle={true}
                            label={'지급일자'}
                            value={{startDate: AttendanceStore.search.paymentDate, endDate: AttendanceStore.search.paymentDate}}
                            onChange={v => AttendanceStore.search.paymentDate = v.startDate}
                    />
                </CCol>
            </Row>
            <Row>
                <Col className="d-flex flex-row-reverse">
                    {/* <CIconBtn style={{width: 100, height: 30}}
                              title={'근태적용'}
                              icon={'fi-rr-pencil'}
                    /> */}
                    <CIconBtn style={{width: 100, height: 30}}
                              title={'급여전송'}
                              icon={'fi-ss-address-card'}
                              onClick={() => saveAttendance('close')}
                    />
                    {/* <CIconBtn style={{width: 100, height: 30}}
                              title={'수당계산'}
                              icon={'fi-rr-calculator'}
                    /> */}
                    <CSearchBtn style={{width: 100, height: 30}}
                              title={'조회'}
                              onClick={getUserList}
                    />
                </Col>
            </Row>
        </CContainer>
    );

}

export default observer(AttendanceListSearch);