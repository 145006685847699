import React, {useState, useRef, useEffect} from "react";
import {Form, InputGroup, Row} from "react-bootstrap";
import {CalendarGroup, CCol, CInputDoubleGroup, CInputGroup} from "../../../components/CustomContainer";
import {ContractDetailStore} from "../../../store/contract/ContractDetailStore";
import AgGridContainer from "../../../components/AgGridContainer";
import {today, addMonthDate, addDayDate, dayAgo, oneMonthAgo} from "../../../utils/dateUtils";
import axios from "axios";
import LUtils from "../../../utils/lodashUtils";
import {callConfirm} from "../../../utils";
import {ICON_EXTEND, ICON_FINISH, ICON_TRANSFER} from "../../../common/constants";
import {observer} from "mobx-react-lite";
import {checkInteger, showToast} from "../../../common/utils";
import {ContractTabsStore} from "../../../store/contract/ContractTabsStore";
import CustomerBillingAddressSearch from "../../../components/searchModal/CustomerBillingAddressSearch";
import {AppStore} from "../../../store/AppStore";
import CustomerSearch from "../../../components/searchModal/CustomerSearch";
import contractUtils from "../contractUtils";
import {getUserName} from "../../../utils/userUtils";
import ReleaseAskDetail from "../../release/ReleaseAskDetail";
import {ReleaseAskDetailStore} from "../../../store/release/ReleaseAskDetailStore";
import Modal from "../../../components/Modal";
import SiteNameSearch from "../../../components/searchModal/SiteNameSearch";
import ChangePricePopup from "../popup/ChangePricePopup";
import ChangePriceSavePopup from "../popup/ChangePriceSavePopup";

const ContractAssetList =(props)=> {
    const gridRef = useRef();
    const gridHistoryRef = useRef();

    const [mode, setMode] = useState('extend');
    const [modeText, setModeText] = useState('');
    //연장
    //const [contractExtendStartDate, setContractExtendStartDate] = useState({});
    //const [contractExtendEndDate, setContractExtendEndDate] = useState({});
    const [extendType, setExtendType] = useState('days');
    const [extendRentMonth, setExtendRentMonth] = useState('');
    const [extendRentDays, setExtendRentDays] = useState('');
    //단축
    const [finishDate, setFinishDate] = useState({});
    //승계
    const [transferCustomerNo, setTransferCustomerNo] = useState('');
    const [transferCustomerName, setTransferCustomerName] = useState('');
    const [transferBillingAddressSeq, setTransferBillingAddressSeq] = useState('');
    const [transferStartDate, setTransferStartDate] = useState({});
    const [transferSiteName, setTransferSiteName] = useState('');
    const [transferSiteSeq, setTransferSiteSeq] = useState('');
    const [transferRentMonth, setTransferRentMonth] = useState(0);
    const [transferRentDays, setTransferRentDays] = useState(0);
    //자산
    const [assetNo, setAssetNo] = useState('');
    const [managementNo, setManagementNo] = useState('');
    const [selectAssetList, setSelectAssetList] = useState([]);

    useEffect(()=> {
        setMode(props.mode);
        setModeText(props.mode === 'extend' ? '연장' : props.mode === 'finish' ? '단축' : props.mode === 'transfer' ? '승계' : '');
        //계약자산 조회
        getContractAssetList();
    }, [props.mode, props.seq])

    const getContractAssetList =async()=> {
        if(ContractDetailStore.contractNo){
            const result = await axios.get('/contractAsset/list', { params: { contractNo: ContractDetailStore.contractNo } });
            ContractTabsStore.setContractAssetList(result);
            ContractTabsStore.setOriginContractAssetList(result);
        }
    }

    const setExtendRentMonthAndDays =(type, v)=> {
        if(checkInteger(v)){
            if(type === 'month'){
                setExtendRentMonth(v);
            }else if(type === 'days'){
                setExtendRentDays(v);
            }
        }
    }

    const extendAssetBtn = {
        isUsed: true,
        title: '연장',
        color: '#6366f1',
        icon: ICON_EXTEND
    }

    const finishAssetBtn = {
        isUsed: true,
        title: '단축',
        color: '#6366f1',
        icon: ICON_FINISH
    }

    const transferAssetBtn = {
        isUsed: true,
        title: '승계',
        color: '#6366f1',
        icon: ICON_TRANSFER
    }

    const changePriceBtn = {
        isUsed: true,
        title: '단가변경',
        color: '#6366f1',
        icon: 'fi-rr-file-edit'
    }

    const allChangePriceBtn = {
        isUsed: true,
        callbackFn: () => openChangePricePopup(),
        title: '일괄적용',
        color: 'gray',
        icon: 'fi-ss-check-circle'
    }

    const changeAssetBtn = {
        isUsed: mode === 'asset',
        title: '자산교환',
        color: '#6366f1',
        icon: 'fi-bs-refresh',
        width: 100,
    }

    const openChangePricePopup = () => {
        const selectedList = ContractTabsStore.contractAssetList.filter(v => v.isSelected);
        if(selectedList.length > 0) {
            AppStore.toggleChangePricePopup();
        }else {
            showToast('적용할 행을 선택해 주세요.');
        }
    }

    const callbackChangePrice = (monthPrice) => {
        const selectedList = ContractTabsStore.contractAssetList.filter(v => v.isSelected);
        selectedList.map(v => v.transferMonthRentPrice = monthPrice);
        gridRef.current.api.redrawRows();
    }

    const callbackChangePriceSave = async(isThisMonth) => {
        const selectedList = ContractTabsStore.contractAssetList.filter(v => v.isSelected);
        const assetList = selectedList.map(v => {
            return {
                contractNo: v.contractNo,
                assetNo: v.assetNo,
                transferMonthRentPrice: v.transferMonthRentPrice,
            }
        });
        await axios.put('/contractAsset/change/price', {assetList: assetList, contractNo: ContractDetailStore.contractNo, isThisMonth: isThisMonth});
        showToast('단가를 변경했습니다.');
        await getContractAssetList();
        props.searchContract(ContractDetailStore.contractNo);
        props.searchContractProduct(ContractDetailStore.contractNo);
    }

    const getAssetHistory =async(e)=> {
        if(mode !== 'asset') return;

        const contractNo = e.data.contractNo;
        const assetNo = e.data.assetNo;
        const result = await axios.get(`/contractAsset/${contractNo}/history/${assetNo}`, null);
        ContractTabsStore.setContractAssetHistoryList(result);

        if(result.length === 0){
            showToast('변경 내역이 없습니다.');
        }
    }

    const gridCallBackSaveHandler = async ({selectedList}) => {
        // 단가변경 | 자산교환 | 연장,단축,승계로 나뉜다.
        if(mode === 'changePrice'){
            if(selectedList.length < 1) {
                return showToast('행을 선택해주세요.');
            }
            if(selectedList.some(v => !v.transferMonthRentPrice)) {
                return showToast('변경 월렌탈료를 입력해주세요.');
            }
            AppStore.toggleChangePriceSavePopup();
        }else if(mode === 'asset'){
            for(let asset of selectedList){
                // if(asset.storageNo){
                //     showToast('입고된 자산입니다.');
                //     return;
                // }
                if(asset.assetStatus === '3'){
                    showToast('단축된 자산입니다.');
                    return;
                }
            }

            setSelectAssetList(selectedList);
            AppStore.toggleModal();
        }else{
            if (LUtils.isEmpty(selectedList)) {
                showToast(modeText+'할 항목이 없습니다.');
                return false;
            }

            if(ContractDetailStore.isFix === 0){
                showToast('계약 확정후 진행해 주세요.');
                return;
            }

            if(mode === 'extend'){
                if(extendType === 'month' && !extendRentMonth){
                    showToast('연장 개월수를 입력해 주세요.');
                    return;
                }
                if(extendType === 'days' && !extendRentDays){
                    showToast('연장 일수를 입력해 주세요.');
                    return;
                }
            }
            else if(mode === 'finish'){
                if(!finishDate.startDate){
                    showToast('계약 단축일을 입력해 주세요.');
                    return;
                }
                /*
                            for(let row of selectedList){
                                if(!row.shortRentPrice){
                                    await callAlert('단축 렌탈료를 입력해 주세요.');
                                    return;
                                }
                            }
                */

            }else if(mode === 'transfer'){
                if(!transferBillingAddressSeq){
                    showToast('승계할 청구지를 입력해 주세요.');
                    return;
                }
                if(!transferStartDate.startDate){
                    showToast('승계개시일을 입력해 주세요.');
                    return;
                }

                if (!await callConfirm('변경 렌탈료를 입력하지 않으면 기존 렌탈료로 승계됩니다. 승계처리 하시겠습니까?')) {
                    return false;
                }
            }

            for(let asset of selectedList){
                if(mode !== 'finish' && asset.storageNo){
                    showToast('입고된 자산입니다.');
                    return;
                }
            }

            if (mode !== 'transfer' && !await callConfirm(modeText+'처리 하시겠습니까?')) {
                return false;
            }
      
            const assetList = selectedList.map(v => {
                return {
                    contractNo: v.contractNo,
                    assetNo: v.assetNo,
                    addRentMonth: v.addRentMonth,
                    changeMonthRentPrice: v.changeMonthRentPrice,
                    addRentDays: v.addRentDays,
                    addDayRentPrice: v.addDayRentPrice,
                    shortRentMonth: v.shortRentMonth,
                    shortRentDays: v.shortRentDays,
                    shortContractEndDate: v.shortContractEndDate,
                    shortRentPrice: v.shortRentPrice,
                    transferMonthRentPrice: v.transferMonthRentPrice,
                    transferDayRentPrice: v.transferDayRentPrice,
                }
            });

            const params = {
                transferCustomerNo: transferCustomerNo,
                transferBillingAddressSeq: transferBillingAddressSeq,
                transferStartDate: transferStartDate.startDate,
                transferSiteSeq: transferSiteSeq,
                transferRentMonth: transferRentMonth,
                transferRentDays: transferRentDays,
                assetList: assetList,
            }

            await axios.put(`/contractAsset/${mode}/${ContractDetailStore.contractNo}`, params);

            showToast(modeText+'처리 되었습니다.');
            //재조회
            await getContractAssetList();
            //계약 재조회
            props.searchContract(ContractDetailStore.contractNo);
        }
    }

    const gridCheckboxEventHandler =(isChecked, e)=> {
        const data = e.node.data;
        if(mode === 'extend'){
            if(isChecked){
                if(extendType === 'month'){
                    if(!extendRentMonth){
                        showToast('연장 월수를 먼저 입력해 주세요.');
                        data.isSelected = false;
                        gridCheckReset();
                        return ;
                    }
                    data.addRentMonth = extendRentMonth;
                    data.changeMonthRentPrice = data.monthRentPrice;
                }else if(extendType === 'days'){
                    if(!extendRentDays){
                        showToast('연장 일수를 먼저 입력해 주세요.');
                        data.isSelected = false;
                        gridCheckReset();
                        return;
                    }
                    data.addRentDays = extendRentDays;
                    if(data.dayRentPrice){
                        data.addDayRentPrice = extendRentDays * data.dayRentPrice;
                    }
                }
                //연장되는 날짜 계산하여 보여주기
                data.shortContractEndDate = extendRentMonth ?  addMonthDate(data.contractEndDate, extendRentMonth) : addDayDate(data.contractEndDate, extendRentDays);
                gridRef.current.api.redrawRows();
            }else{
                data.addRentMonth = '';
                data.changeMonthRentPrice = '';
                data.addRentDays = '';
                data.addDayRentPrice = '';
                gridRef.current.api.redrawRows();
            }
        }else if(mode === 'finish'){
            if(isChecked){
                if(!finishDate.startDate){
                    showToast('종료일을 먼저 입력해 주세요.');
                    data.isSelected = false;
                    gridCheckReset();
                    return ;
                }
                //원개시일과 종료일자를 계산한다.
                //const {rentMonth, rentDays} = contractUtils.calcShortRentMonthAndDays(finishDate.startDate, data.contractEndDate);
                const {rentMonth, rentDays} = contractUtils.calcShortRentMonthAndDays(data.orgContractStartDate, finishDate.startDate);

                data.shortRentMonth = rentMonth;
                data.shortRentDays = rentDays;
                data.shortContractEndDate = finishDate.startDate;
                gridRef.current.api.redrawRows();
            }else{
                data.shortRentMonth = '';
                data.shortRentDays = '';
                data.shortContractEndDate = '';
                gridRef.current.api.redrawRows();
            }
        }else{
            gridRef.current.api.redrawRows();

            // 단축 rentMonth, rentDays 계산
            const {rentMonth, rentDays} = contractUtils.calcShortRentMonthAndDays(data.contractStartDate, dayAgo(transferStartDate.startDate));
            data.shortRentMonth = rentMonth;
            data.shortRentDays = rentDays;
            // 승계 rentMonth, rentDays 계산
            const transferCalcDate = contractUtils.calcShortRentMonthAndDays(transferStartDate.startDate, data.contractEndDate);
            setTransferRentMonth(transferCalcDate.rentMonth);
            setTransferRentDays(transferCalcDate.rentDays);
        }
    }

    const gridCheckReset =()=> {
        gridRef.current.api.forEachNode((node) => {
            const { isUpdated, isCreated, isSelected, ...row } = node.data;
            node.setSelected(LUtils.some([isUpdated, isCreated, isSelected]));
        });
    }

    const resetGrid =()=> {
        ContractTabsStore.contractAssetList.map(async v => {
            if(v.isSelected){
                v.isSelected = false;
                v.addRentMonth = '';
                v.changeMonthRentPrice = '';
                v.addRentDays = '';
                v.addDayRentPrice = '';
            }
            return v;
        });
        gridCheckReset();
    }

    const addInputHandler =()=> {
        ContractTabsStore.contractAssetList.map(async v => {
            if(v.isSelected){
                if(extendType === 'month'){
                    v.addRentMonth = extendRentMonth;
                }else if(extendType === 'days'){
                    v.addRentDays = extendRentDays;
                }
            }
            return v;
        });

        gridRef.current.api.forEachNode((node) => {
            const { isUpdated, isCreated, isSelected, ...row } = node.data;
            node.setSelected(LUtils.some([isUpdated, isCreated, isSelected]));
        });
    }

    /* 팝업 닫을 때 출고의뢰 초기화 */
    const closeReleaseAskDetailPopup = () => {
        ReleaseAskDetailStore.init();
    };

    const callbackSiteInfo =async(info)=> {
        if(info){
            setTransferSiteSeq(info.siteSeq);
            setTransferSiteName(info.siteName);
        }
    }

    return (
        <>
            {mode === 'extend' &&
                <Row>
                    <CCol lg={2}>
                        <Form.Check
                            inline
                            label={"일단위 연장"}
                            name="checkMonth"
                            type={'radio'}
                            id='checkMonth-2'
                            value={'days'}
                            checked={extendType === 'days'}
                            onChange={(e)=> {
                                setExtendType(e.target.value);
                                setExtendRentDays('');
                                resetGrid();
                            }}
                        />
                        <Form.Check
                            inline
                            label={"월단위 연장"}
                            name="checkMonth"
                            type={'radio'}
                            id='checkMonth-1'
                            value={'month'}
                            checked={extendType === 'month'}
                            onChange={(e)=> {
                                setExtendType(e.target.value);
                                setExtendRentMonth('');
                                resetGrid();
                            }}
                        />
                    </CCol>
                    {extendType === 'month' &&
                        <CCol lg={2}>
                            <CInputGroup
                                label={'연장'}
                                backLabel={'개월'}
                                labelId={'extendRentMonth'}
                                value={extendRentMonth}
                                maxLength={2}
                                type={'number'}
                                onChange={(v) => setExtendRentMonthAndDays(extendType, v.target.value)}
                                enterKeyHint={addInputHandler}
                            />
                        </CCol>
                    }
                    {extendType === 'days' &&
                        <CCol lg={2}>
                            <CInputGroup
                                label={'연장'}
                                backLabel={'일'}
                                labelId={'extendRentDays'}
                                value={extendRentDays}
                                maxLength={2}
                                type={'number'}
                                onChange={(v) => setExtendRentMonthAndDays(extendType, v.target.value)}
                                enterKeyHint={addInputHandler}
                            />
                        </CCol>
                    }
                </Row>
            }
            {mode === 'finish' &&
                <Row>
                    <CCol lg={2}>
                        <CalendarGroup asSingle={true}  label={'종료일'}
                                       value={finishDate}
                                       minDate={ContractDetailStore.contractStartDate}
                                       maxDate={ContractDetailStore.contractEndDate}
                                       onChange={setFinishDate}
                        />
                    </CCol>
                </Row>
            }
            {mode === 'transfer' &&
                <Row>
                    <CCol lg={3}>
                        <CInputDoubleGroup
                            label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객코드'} placeholder2={'고객명'}
                            value={transferCustomerNo}
                            value2={transferCustomerName}
                            disabled={true} disabled2={true}
                            onCallbackBtn={()=> AppStore.toggleCustomerSearchModal()}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'청구지'} labelId={'billingAddressSeq'}
                                     value={transferBillingAddressSeq}
                                     onCallbackBtn={()=> AppStore.toggleCustomerBillingAddressSearchModal()}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                       <CInputGroup label={'현장'} labelId={'siteName'} disabled={true}
                                     value={transferSiteName}
                                     onCallbackBtn={()=> AppStore.toggleSiteNameSearchPopup()}
                       />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup asSingle={true} label={'승계개시일'}
                                       value={transferStartDate}
                                       minDate={addDayDate(ContractDetailStore.contractStartDate, 1)}
                                       maxDate={addDayDate(ContractDetailStore.contractEndDate, -1)}
                                       onChange={(e)=> setTransferStartDate(e)}
                        />
                    </CCol>
                </Row>
            }
            {(mode === 'asset' || mode === 'changePrice') &&
                <Row>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'managementNo'}>관리번호</InputGroup.Text>
                            <Form.Control aria-describedby={'managementNo'}
                                          value={managementNo}
                                          onChange={(v)=> {
                                              let filterManagementNo = v.target.value;
                                              setManagementNo(filterManagementNo);

                                              if(filterManagementNo){
                                                const filterAssetList = ContractTabsStore.originContractAssetList.filter(f => f.managementNo.toLowerCase().indexOf(filterManagementNo.toLowerCase()) > -1);
                                                ContractTabsStore.setContractAssetList(filterAssetList);
                                              }else{
                                                  ContractTabsStore.setContractAssetList(ContractTabsStore.originContractAssetList);
                                              }
                                          }}
                                          placeholder={'필터'}
                            />
                        </InputGroup>
                    </CCol>
                </Row>
            }
            <Row>
                <AgGridContainer
                    gridRef={gridRef}
                    gridTitle={mode === 'asset' ? '사용중인 자산 리스트' : null}
                    height={mode !== 'asset' ? 40 : 30}
                    rowData={ContractTabsStore.contractAssetList}
                    columnDefs={[
                        {field: "contractNo", headerName: "계약번호", hide: true},
                        {field: "contractSeq", headerName: "계약회차", width: 110},
                        {field: "assetNo", headerName: "자산번호", width: 110},
                        {field: "managementNo", headerName: "관리번호", width: 110},
                        {field: "model", headerName: "모델명", width: 150},
                        {field: "assetStatus", headerName: "자산상태", hide: true}, //1:신규, 2:연장, 3:단축, 4:교환, 5:단가변경
                        {field: "assetStatusText", headerName: "계약상태", width: 110}, //1:신규, 2:연장, 3:단축, 4:교환, 5:단가변경
                        {field: "rentMonth", headerName: "대여월", width: 100},
                        {field: "rentDays", headerName: "대여일", width: 100},
                        {field: "monthRentPrice", headerName: "월렌탈료", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                        {field: "dayRentPrice", headerName: "일렌탈료", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), hide: true},
                        /*{field: "lastMonthPrice", headerName: "마지막달 요금", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},*/
                        {field: "orgContractStartDate", headerName: "원 개시일자", width: 130},
                        {field: "contractStartDate", headerName: "변경 개시일", width: 130},
                        {field: "contractEndDate", headerName: "계약종료일", width: 120},
                        {
                            field: "shortContractEndDate",
                            headerName: "변경 종료일",
                            width: 130,
                            headerClass: 'grid-column-required',
                            hide: mode === 'asset' || mode === 'transfer' || mode === 'changePrice'
                        },
                        /* 연장 */
                        {
                            field: "addRentMonth",
                            headerName: "연장 대여월",
                            headerClass: 'grid-column-required',
                            editable: true,
                            width: 130,
                            hide: mode !== 'extend' || extendType === 'days'
                        },
                        {
                            field: "addRentDays",
                            headerName: "추가 대여일",
                            headerClass: 'grid-column-required',
                            editable: true,
                            width: 130,
                            hide: mode !== 'extend' || extendType === 'month'
                        },
                        {
                            field: "changeMonthRentPrice",
                            headerName: "변경 월렌탈료",
                            cellClass: 'ag-grid-money-align',
                            headerClass: 'grid-column-required',
                            width: 140,
                            valueFormatter: v => v.value?.toLocaleString(),
                            editable: true,
                            hide: mode !== 'extend' || extendType === 'days'
                        },
                        {
                            field: "addDayRentPrice",
                            headerName: "추가 렌탈료",
                            cellClass: 'ag-grid-money-align',
                            headerClass: 'grid-column-required',
                            width: 130,
                            valueFormatter: v => v.value?.toLocaleString(),
                            editable: true,
                            hide: mode !== 'extend' || extendType === 'month'
                        },
                        /* 단축 */
                        {
                            field: "shortRentMonth",
                            headerName: "단축 대여월",
                            headerClass: 'grid-column-required',
                            width: 130,
                            hide: mode !== 'finish'
                        },
                        {
                            field: "shortRentDays",
                            headerName: "단축 대여일",
                            headerClass: 'grid-column-required',
                            width: 130,
                            hide: mode !== 'finish'
                        },
                        {
                            field: "shortRentPrice",
                            headerName: "단축 렌탈료",
                            headerClass: 'grid-column-required',
                            cellClass: 'ag-grid-money-align',
                            width: 130,
                            hide: mode !== 'finish',
                            editable: true
                        },
                        /* 승계 */
                        {
                            field: "transferMonthRentPrice",
                            headerName: "변경 월렌탈료",
                            headerClass: 'grid-column-required',
                            cellClass: 'ag-grid-money-align',
                            width: 130,
                            hide: mode !== 'transfer' && mode !== 'changePrice',
                            editable: true
                        },
                        {
                            field: "transferDayRentPrice",
                            headerName: "변경 일렌탈료",
                            headerClass: 'grid-column-required',
                            cellClass: 'ag-grid-money-align',
                            width: 130,
                            hide: mode !== 'transfer',
                            editable: true
                        },
                        /* 연장, 단축, 승계 */
                        {field: "storageNo", headerName: "입고번호", width: 120, hide: mode === 'changePrice'},
                        {field: "storageDate", headerName: "입고일자", width: 120, hide: mode === 'changePrice'},
                        {field: "remark", headerName: "비고", width: 200, hide: mode === 'changePrice'},
                        {field: "updater", headerName: "변경자", valueGetter: (params) => getUserName(params.data.updater), hide: mode === 'changePrice'},
                        {field: "updatedDate", headerName: "변경일자", width: 180, hide: mode === 'changePrice'},
                        {field: "isActive", headerName: "사용유무", width: 110, valueFormatter: v => v.value === '1' ? '사용중' : '', hide: mode === 'changePrice'},
                        {field: "reserveUserName", headerName: "예약자", hide: true},
                    ]}
                    isCheckBox={true}
                    seqColumn={'assetNo'}
                    originList={ContractTabsStore.originContractAssetList}
                    useIntoTheTab={true}
                    useCsvDownload={mode === 'asset'}
                    selectBtnInfo={mode === 'extend' ? extendAssetBtn : mode === 'finish' ? finishAssetBtn : mode === 'transfer' ? transferAssetBtn : mode === 'changePrice' ? changePriceBtn : changeAssetBtn}
                    customBtnInfo={mode === 'changePrice' ? [ allChangePriceBtn ] : ''}
                    rowDoubleClickCallback={getAssetHistory}
                    callBackGridData={gridCallBackSaveHandler}
                    getCheckboxClickEvent={gridCheckboxEventHandler}
                    getRowStyle={(params) => {
                        if (mode !== 'asset' &&
                                (params.data.isActive === 0 || params.data.assetStatus === '0' || params.data.contractAssetStatus === '3')) {
                            return { pointerEvents: 'none', color: '#bababa' };
                        }else if(mode !== 'asset' && params.data.contractAssetStatus === '3'){
                            return { pointerEvents: 'none', color: '#A4A4A4' };
                        }else if(mode === 'asset' && params.data.contractAssetStatus === '3'){
                            return { pointerEvents: 'none', color: '#A4A4A4' };
                        }
                        return null;
                    }}
                />
            </Row>
            {mode === 'asset' &&
                <Row style={{marginTop: 20}}>
                    <AgGridContainer
                        gridRef={gridHistoryRef}
                        gridTitle={'자산 변경 이력'}
                        height={30}
                        rowData={ContractTabsStore.contractAssetHistoryList}
                        columnDefs={[
                            {field: "contractSeq", headerName: "계약회차", width: 110},
                            {field: "assetNo", headerName: "자산번호", width: 110},
                            {field: "assetSeq", headerName: "변경순번", width: 110},
                            {field: "assetStatus", headerName: "자산상태", hide: true}, //1:신규, 2:연장, 3:단축, 4:교환, 5:단가변경
                            {field: "assetStatusText", headerName: "자산상태", width: 110}, //1:신규, 2:연장, 3:단축, 4:교환, 5:단가변경
                            {field: "rentMonth", headerName: "대여월"},
                            {field: "rentDays", headerName: "대여일"},
                            {field: "monthRentPrice", headerName: "월렌탈료", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                            {field: "dayRentPrice", headerName: "일렌탈료", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                            {field: "orgContractStartDate", headerName: "원 개시일자", width: 130},
                            {field: "contractStartDate", headerName: "계약개시일", width: 130},
                            {field: "contractEndDate", headerName: "계약종료일", width: 130},
                            {field: "creator", headerName: "변경자", valueGetter: (params) => getUserName(params.data.creator) },
                            {field: "createdDate", headerName: "변경일자", minWidth: 180},
                        ]}
                        seqColumn={'assetNo'}
                        useIntoTheTab={true}
                        useCsvDownload={true}
                    />
                </Row>
            }
            {/* search modal */}
            <CustomerSearch callbackFn={(info)=> {
                                setTransferCustomerNo(info.customerNo);
                                setTransferCustomerName(info.customerName);
                            }}
            />
            <CustomerBillingAddressSearch customerNo={transferCustomerNo}
                                          callbackFn={(info)=> setTransferBillingAddressSeq(info.billingAddressSeq)}
            />
            {/* 출고의뢰 */}
            <Modal title={'자산교환 출고 의뢰'}
                   onHide={()=> {
                       ReleaseAskDetailStore.init();
                       AppStore.toggleModal()
                   }}
            >
                <ReleaseAskDetail callBackFn={closeReleaseAskDetailPopup}
                                  contractNo={ContractDetailStore.contractNo}
                                  customerNo={ContractDetailStore.customerNo}
                                  billingAddressSeq={ContractDetailStore.billingAddressSeq}
                                  customerManagerSeq={ContractDetailStore.customerManagerSeq}
                                  siteSeq={ContractDetailStore.siteSeq}
                                  selectAssetList={selectAssetList}
                />
            </Modal>
            {/* 현장 */}
            <SiteNameSearch callbackFn={callbackSiteInfo} />
            <ChangePricePopup callBackFn={callbackChangePrice} />
            <ChangePriceSavePopup callBackFn={callbackChangePriceSave} />
        </>
    );
}

export default observer(ContractAssetList);
