import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {CCol, CContainer, CInputGroup} from "../../components/CustomContainer";
import { Form, Row, Col, Button } from "react-bootstrap";
import { StorageRegisterStore } from "../../store/storage/StorageRegisterStore";
import { StorageRegisterListStore } from "../../store/storage/StorageRegisterListStore";
import OrderSearch from "../../components/searchModal/OrderSearch";
import { AppStore } from "../../store/AppStore";
import { callConfirm, getLocalStorage } from "../../utils";
import LUtils from "../../utils/lodashUtils";
import AgGridContainer from "../../components/AgGridContainer";
import {getInputTime} from "../../utils/dateUtils";
import {findCommKrnm} from "../../utils/commcode";
import {showToast} from "../../common/utils";
import AssetConsumPopup from "./popup/AssetConsumPopup";
import { read, utils } from 'xlsx';
import { USER_INFO } from "../../common/constants";

const StorageGoods = (props) => {
    const goodsGridRef = useRef();
    const goodsDataRef = useRef();
    const navigate = useNavigate();

    const inputNoRef = useRef();
    const [inputNo, setInputNo] = useState('');
    const [isReader, setIsReader] = useState(true);
    const [selAssetNo, setSelAssetNo] = useState('');
    const [apronList, setApronList] = useState([]);

    const [fileName, setFileName] = useState('');

    useEffect(()=> {
        getApronList();
    }, [])

    const getApronList = async() => {
        const result = await axios.get("/user/apron");
        const resultList = result.map(v => {
            v.name = v.apronName;
            v.value = v.apronSeq;
            return v;
        })
        setApronList(resultList);
    }

    /* 상품 저장 */
    const onSaveStorageGoods = async ({createdList}) => {
        let isEmptySerialNo = false;
        createdList.forEach(v => {
            if (((v.storageType === 'sto_goods' || v.storageType === 'sto_hire') && !v.serialNo)
                || (v.storageType === 'sto_consum' && v.consumType === '1' && !v.serialNo)
            ) {
                isEmptySerialNo = true;
            }
        });

        if (isEmptySerialNo) {
            showToast('시리얼번호는 모두 입력해야 합니다.');
            return false;
        }

        if (!await callConfirm('저장 하시겠습니까?')) {
            return false;
        }

        createdList.forEach((v, i) => (v.storageSeq = i + 1))   // storageSeq 입력

        const params = {
            storageList: createdList,
            orderSeq: StorageRegisterStore.goodsInfo.orderSeq,
            remark: StorageRegisterStore.goodsInfo.remark,
        }

        await axios.post('/storage/goods', params);
        StorageRegisterStore.initGoodsInfo();
        StorageRegisterListStore.init();

        if(await callConfirm('저장되었습니다. 입고조회 화면으로 이동 하시겠습니까?')) {
            navigate('/storage/list', { state: { orderSeq: params.orderSeq } });
        }
    }

    /* 발주 제품 목록 조회 */
    const getOrderProductList = async (orderSeq) => {
        return axios.get('/orderProduct', { params: { orderSeq } });
    }

    /* 상품입고 목록 만들기 */
    const makeGoodsList = async (order) => {
        const orderProductList = await getOrderProductList(order.orderSeq);
        const apronSeq = getLocalStorage(USER_INFO).apronSeq;

        LUtils.forEach(orderProductList, (v, i) => {
            const { productSeq, orderAmount, storageCnt, orderPrice, productCode, productName, model, spec, lgSeq } = v;

            const cnt = orderAmount - storageCnt;
            // 제품별로 입고가능 수량을 검사 한다.
            if (cnt === 0) {
                return;
            }

            //시리얼을 찍어야 하는 일반, 임차 자산 발주는 한건씩 (자산 소모품은 일반 상품으로 입고처리 한다).
            if(StorageRegisterStore.goodsInfo.orderType === '1' || StorageRegisterStore.goodsInfo.orderType === '2' || StorageRegisterStore.goodsInfo.orderType === '4'){
                let index = 1;
                while (index <= cnt) {
                    const row = {
                        storageType: StorageRegisterStore.goodsInfo.orderType === '1' ? 'sto_goods' : StorageRegisterStore.goodsInfo.orderType === '2' ? 'sto_hire' : 'sto_option',  //임차자산 외에는 옵션으로 입고처리한다,
                        storageSeq: 0,
                        orderSeq: order.orderSeq,
                        productSeq,
                        qty: 1,
                        amount: orderPrice,
                        productCode,
                        productName,
                        model,
                        serialNo: '',
                        spec,
                        apronSeq,
                    };
                    goodsDataRef.current.addRow(row, 'push');

                    index++;
                }
            }else{
                const row = {
                    storageType: 'sto_consum',  //소모품 입고,
                    storageSeq: 0,
                    orderSeq: order.orderSeq,
                    productSeq,
                    qty: cnt,
                    amount: orderPrice,
                    productCode,
                    productName,
                    model,
                    serialNo: '',
                    spec,
                    apronSeq,
                };
                goodsDataRef.current.addRow(row, 'push');
            }
        });
    }

    /* 발주 콜백 함수 */
    const callbackOrder = async(order) => {
        if (LUtils.isEmpty(order)) {
            return false;
        }

        // 입고가능 수량이 있는지 검사
        if (order.orderAmount > 0 && order.orderAmount <= order.storageCnt) {
            showToast('이 발주는 입고가능 수량이 없습니다.');
            return false;
        }

        StorageRegisterListStore.setGoodsList([]);
        StorageRegisterStore.setGoodsInfo(order.orderSeq, 'orderSeq');
        StorageRegisterStore.setGoodsInfo(order.orderType,'orderType');
        StorageRegisterStore.setGoodsInfo(findCommKrnm(order.orderType, '36'), 'orderTypeName');

        await makeGoodsList(order);
    }

    /* 입고수량 모두 삭제했을 경우 초기화 */
    const getCheckboxClickEvent = (isSelected) => {
        // 선택 해제 했을때만
        if (!isSelected) {
            if (StorageRegisterListStore.goodsList.length === 0) {
                StorageRegisterStore.initGoodsInfo();
                StorageRegisterListStore.init();
            }
        }
    }

    const resetInputField =()=> {
        setInputNo('');
        StorageRegisterStore.setCheckCountSec(0);
        inputNoRef.current && inputNoRef.current.focus();
    }

    const pushInputNumber =(number)=> {
        if(!number){
            return;
        }

        if(isReader && number.length === 1){
            StorageRegisterStore.setCheckCountSec(getInputTime());
            return;
        }

        if(isReader && (getInputTime() - StorageRegisterStore.checkCountSec) < 1){
            return;
        }

        if(StorageRegisterListStore.goodsList.some(v => v.serialNo === number)){
            showToast('이미 입력된 시리얼 입니다.');
            resetInputField();
            return false;
        }

        const emptyIndex = StorageRegisterListStore.goodsList.findIndex(v => v.storageType !== 'sto_consum' && !v.serialNo);
        if(emptyIndex > -1){
            StorageRegisterListStore.goodsList[emptyIndex].serialNo = number;
            resetInputField();
            // 그리드 전체 다시 그리기
            goodsGridRef.current.api.redrawRows();
        }else{
            showToast('시리얼번호가 모두 입력되었습니다.');
        }
    }

    /* 엑셀 다운로드 */
    const handleDownload = async () => {
        fetch('https://pelotonlabbucket.s3.ap-northeast-2.amazonaws.com/awp-erp-cloud/3f2ee02f-9141-4d62-ad47-338a0fd583fd2023-12-05T17%3A05%3A35.244224model_serialNo_sample.xlsx', {
            method: 'GET',
        })
        .then((response) => response.blob())
        .then((blob) => {
            // 블롭(Blob) 객체 생성하고 URL을 생성
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');

            // 생성한 URL과 다운로드할 파일명 설정
            link.setAttribute('href', url);
            link.setAttribute('download', 'sample.xlsx');

            // 링크를 문서(body)에 추가
            document.body.appendChild(link);

            // 링크 클릭 => 파일 다운로드
            link.click();

            // 다운로드 후 링크와 URL을 정리
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url)
        });
    };

    const uploadBtn = () => {
        return (
            <div className="file-upload" style={{paddingLeft: 15}}>
                <label htmlFor="file-input" className="custom-file-upload" style={{width: 150, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <i className="fi fi-sr-arrow-small-up"></i>&nbsp;시리얼번호 업로드
                </label>
                <input
                    id="file-input"
                    type="file"
                    onChange={handleFileUpload} 
                    style={{ display: 'none' }}
                />
            </div>
          );
    }

    /* 엑셀 업로더 */
    const handleFileUpload = async (event) => {

        const file = event.target.files[0];
        const reader = new FileReader();
        let serialNoList = [];

        if (file) {
            reader.onload = (e) => {
                const addIndex = StorageRegisterListStore.goodsList.filter(v => v.isCreated).length || 0;
                const data = new Uint8Array(e.target.result);
                const workbook = read(data, { type: 'array' });
                let matchCount = 0;

                // 첫 번째 시트를 가져옴
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];

                // 셀 데이터를 파싱하여 출력
                const jsonData = utils.sheet_to_json(worksheet, { header: 1, raw: false });     // raw: false는 날짜 타입 그대로 가져오기 위함

                serialNoList = jsonData.slice(1).map(row => {
                    if (!row[0]) return null;

                    return {
                        model: row[0],
                        serialNo: row[1],
                    }
                });

                serialNoList.some(obj => {
                    if (obj === null) return false;

                    StorageRegisterListStore.goodsList.some((goods, j) => {
                        if (goods.serialNo !== '') return false;

                        if (obj.model === goods.model) {
                            StorageRegisterListStore.goodsList[j].serialNo = obj.serialNo;
                            matchCount ++;
                            return true;
                        }
                        return false;
                    })
                })

                if (matchCount === 0) {
                    showToast('일치하는 데이터가 존재하지 않습니다.');
                    StorageRegisterListStore.goodsList.forEach((goods, i) => {
                        StorageRegisterListStore.goodsList[i].serialNo = '';
                    });
                }

                goodsGridRef.current.api.redrawRows();
            };
            reader.readAsArrayBuffer(file);
            setFileName(file.name);
        }
    };

    const getApronName = (value) => {
        if(value){
            return apronList.find(v => v.value === Number(value)).name;
        }
    }

    return(
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'발주조회'}
                            value={StorageRegisterStore.goodsInfo.orderSeq}
                            onCallbackBtn={() => AppStore.toggleOrderSearchModal()}
                            disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'발주구분'}
                            value={StorageRegisterStore.goodsInfo.orderTypeName}
                            disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={1}>
                        <Form.Check
                            type="switch"
                            id="isReader"
                            label="리더사용"
                            checked={isReader}
                            style={{marginTop: 4}}
                            onChange={()=> setIsReader(!isReader)}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            ref={(ref)=> ref}
                            label={'자산번호 또는 시리얼번호'}
                            labelId={'inputNo'}
                            value={inputNo}
                            onChange={(e)=> {
                                if(e.keyCode !== 8) {
                                    let no = e.target.value;
                                    setInputNo(no);
                                    setTimeout(()=> {
                                        isReader && pushInputNumber(no);
                                    }, 500);
                                }
                            }}
                            enterKeyHint={()=> pushInputNumber(inputNo)}
                            disabled={!StorageRegisterStore.goodsInfo.orderSeq}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup
                            label={'입고비고'}
                            value={StorageRegisterStore.goodsInfo.remark}
                            onChange={v => StorageRegisterStore.setGoodsInfo(v.target.value, 'remark')}
                            disabled={!StorageRegisterStore.goodsInfo.orderSeq}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        {uploadBtn()}
                        <Button size={'sm'} style={{width: 150, paddingLeft: 15, paddingRight: 15, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={handleDownload}><i className="fi fi-rr-file-download"></i>&nbsp;엑셀 양식 받기</Button>
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridTitle='옵션 및 소모품 입고 대상 리스트'
                    gridRef={goodsGridRef}
                    ref={goodsDataRef}
                    height={50}
                    rowData={StorageRegisterListStore.goodsList}
                    columnDefs={[
                        {field: "storageType", headerName: "입고구분",  valueFormatter: (params) => findCommKrnm(params.value, '13')},
                        {field: "storageSeq", headerName: "순번", valueGetter: e => e.node.rowIndex + 1, width: 70 },
                        {field: "orderSeq", headerName: "발주번호"},
                        {field: "productSeq", headerName: "제품번호", hide: true},
                        {field: "productCode", headerName: "제품코드", width: 120, hide: true},
                        {
                            field: "apronSeq",
                            headerName: "주기장",
                            width: 100,
                            valueFormatter: (params) => getApronName(params.value),
                            cellEditor: 'agSelectCellEditor',
                            cellEditorParams: {
                                values: apronList.map(v => v.value)
                            },
                            editable: true,
                            headerClass: 'grid-column-editable',
                        },
                        {field: "model", headerName: "모델명", width: 220},
                        {field: "productName", headerName: "제품명", width: 220},
                        {
                            field: "qty",
                            headerName: "수량",
                            width: 70,
                            editable: StorageRegisterStore.goodsInfo.storageType === '3',
                            headerClass: StorageRegisterStore.goodsInfo.storageType === '3' ? 'grid-column-editable' : 0},
                        {field: "amount", headerName: "상품금액", valueFormatter: v => v.value.toLocaleString(), cellClass: 'ag-grid-money-align'},
                        {
                            field: "serialNo",
                            headerName: "시리얼번호",
                            width: 200,
                            editable: true,
                            headerClass: 'grid-column-editable grid-column-required'
                        },
                        {field: "spec", headerName: "스펙", minWidth: 250, flex:1},
                    ]}
                    seqColumn={'storageSeq'}
                    isCheckBox={true}
                    useUpdated={true}
                    callBackGridData={onSaveStorageGoods}
                    getCheckboxClickEvent={getCheckboxClickEvent}
                    //rowDoubleClickCallback={getConsumList}
                />
            </CContainer>

            {/* modal */}
            <OrderSearch callbackFn={callbackOrder} />
            <AssetConsumPopup assetNo={selAssetNo} />
        </>
    );
}

export default observer(StorageGoods);
