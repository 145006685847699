import { observer } from "mobx-react-lite";
import axios from "axios";
import AttendanceListSearch from "./AttendanceListSearch";
import { useRef } from "react";
import { CContainer } from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import { AttendanceStore } from "../../store/human-resources/AttendanceStore";
import { findCommKrnm } from "../../utils/commcode";
import { getUserName } from "../../utils/userUtils";
import { showToast } from "../../common/utils";
import { callConfirm } from "../../utils";
import LUtils from "../../utils/lodashUtils";

const AttendanceList = () => {
    const gridRef = useRef();

    // useEffect(() => {
    //     AttendanceStore.search.year = getYear();
    //     AttendanceStore.search.month = getMonthOfToday();
    //     AttendanceStore.search.paymentDate = getPaymentDate();
    // }, []);

    /* 사원 근태 목록 */
    const getUserList = async () => {
        AttendanceStore.search.salaryType = AttendanceStore.search.salaryType === '' ? '1' : AttendanceStore.search.salaryType;
        let requiredParams = ['salaryType'];
        if (!requiredParams.every(param => AttendanceStore.search[param])) {
            showToast('급여구분을 선택해주세요.');
            return;
        }

        const resultList = await axios.get('/attendance/list', {params: AttendanceStore.search});
        AttendanceStore.userList = resultList;
    }

    /* 그리드 저장버튼 커스텀 */
    const saveBtn = {
        width: 90,
        isUsed: true,
        title: '저장',
        icon: 'fi-rr-save-document',
        callbackFn: () => saveAttendance('save'),
        variant: 'success',
    }

    /* 근태 저장 */
    const saveAttendance = async(text) => {
        const validClosed = AttendanceStore.userList.every(v => !v.isClose || v.isClose === 0);

        if (!validClosed) {
            showToast('이미 마감된 건은 급여전송이 불가합니다.');
            return;
        } 

        if (!await callConfirm(text === 'close' ? '급여전송 하시겠습니까?' : '저장하시겠습니까?')) {
            return;
        }

        const params = {year: AttendanceStore.search.year
                        , month: AttendanceStore.search.month
                        , salaryType: AttendanceStore.search.salaryType === '' ? '1' : AttendanceStore.search.salaryType
                        , paymentDate: AttendanceStore.search.paymentDate
                        , attendanceList: AttendanceStore.userList
                        , isClose: text === 'close' ? 1 : 0}

        await axios.post('/attendance', params);
        showToast(text !== 'close' ? '저장이 완료되었습니다.' : '급여전송이 완료되었습니다.');
        getUserList();
    }

    /* 숫자 형식 체크 */
    const dayChangeFn = async (e) => {
        const { field } = e.colDef;

        const numberColumns = ['normalDay', 'extensionDay', 'nightDay', 'holidayDay'];
        if (LUtils.some(LUtils.values(LUtils.pick(e.data, numberColumns)), v => isNaN(v))) {
            showToast('숫자만 입력 가능합니다.');
            e.data[field] = e.oldValue;
            gridRef.current.api.redrawRows();
            return false;
        }
    }

    /* h:mm 형식 체크 */
    const timeChangeFn = async (e) => {
        const { field } = e.colDef;
        const numberColumns = ['normalHour', 'extensionHour', 'nightHour', 'holidayHour'];
        let pattern = /^\d{1,}:\d{2}$/;

        if (numberColumns.includes(field) && !pattern.test(e.data[field])) {
            showToast('형식이 일치하지 않습니다. (예시. h:mm)');
            e.data[field] = e.oldValue;
            gridRef.current.api.redrawRows();
            return false;
        }
    }

    return (<>
    {/* 검색조건 */}
        <AttendanceListSearch getUserList={getUserList} saveAttendance={saveAttendance}/>
        <CContainer>
            {/* grid */}
            <AgGridContainer 
                gridTitle={'근태관리 목록'}
                gridRef={gridRef}
                height={58}
                rowData={AttendanceStore.userList}
                columnDefs={[
                    {
                        headerName: '사원정보',
                        marryChildren: true,
                        children: [
                            {field: "seq", headerName: "순번", valueGetter: v => v.node.rowIndex + 1, width: 60, hide: true},
                            {field: "isClose", headerName: "마감", width: 70, valueFormatter: v => v.data.isClose === 1 ? '마감' : '', flex: 1},
                            {field: "userSeq", headerName: "사원번호", width: 100, flex: 1},
                            {field: "userName", headerName: "사원명", valueFormatter: v => {return getUserName(v.data.userSeq)}, width: 120, flex: 1},
                            {field: "teamName", headerName: "팀", valueFormatter: v => {return findCommKrnm(v.data.teamSeq, '33')}, width: 120, flex: 1},
                            {field: "positionName", headerName: "직책", valueFormatter: v => {return findCommKrnm(v.data.positionSeq, '31')}, width: 100, flex: 1},
                            {field: "hourlyWage", headerName: "시급", editable: true, hide: true},
                            {field: "companyCode", headerName: "회사코드", hide: true},
                        ]
                    },
                    {
                        headerName: '시간',
                        marryChildren: true,
                        children: [
                            {field: "normalHour", headerName: "정상", width: 102, editable: true, onCellValueChanged: timeChangeFn, flex: 1},
                            {field: "extensionHour", headerName: "연장", width: 102, editable: true, onCellValueChanged: timeChangeFn, flex: 1},
                            {field: "nightHour", headerName: "야간", width: 102, editable: true, onCellValueChanged: timeChangeFn, flex: 1},
                            {field: "holidayHour", headerName: "휴일", width: 102, editable: true, onCellValueChanged: timeChangeFn, flex: 1},
                        ]
                    },
                    // {
                    //     headerName: '수당',
                    //     marryChildren: true,
                    //     children: [
                    //         {field: "normalPay", headerName: "정상", width: 90, editable: true},
                    //         {field: "extensionPay", headerName: "연장", width: 90, editable: true},
                    //         {field: "nightPay", headerName: "야간", width: 90, editable: true},
                    //         {field: "holidayPay", headerName: "휴일", width: 90, editable: true},
                    //     ]
                    // },
                    {
                        headerName: '일수',
                        children: [
                            {field: "normalDay", headerName: "정상", width: 102, editable: true, onCellValueChanged: dayChangeFn, flex: 1},
                            {field: "extensionDay", headerName: "연장", width: 102, editable: true, onCellValueChanged: dayChangeFn, flex: 1},
                            {field: "nightDay", headerName: "야간", width: 102, editable: true, onCellValueChanged: dayChangeFn, flex: 1},
                            {field: "holidayDay", headerName: "휴일", width: 102, editable: true, onCellValueChanged: dayChangeFn, flex: 1},
                        ]
                    },
                ]}
                seqColumn={'agId'}
                isCheckBox={true}
                // useUpdated={true}
                useCsvDownload={true}
                customBtnInfo={[saveBtn]}
            />
        </CContainer>

    </>)
}

export default observer(AttendanceList);