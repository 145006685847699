import { observable } from "mobx";

export const PaybackStore = observable({
    paybackList: [],
    search: {
        startDate: '',
        endDate: '',
        customerNo:'',
        customerName:'',
        isPayback: 0,
        remark: '',
    },
    detail: {
        paybackDate: '',
        customerNo: '',
        customerName: '',
        amount: '',
        accountCode: '',
        accountName: '',
        remark: '',
    },
    detailInit() {
        this.detail.paybackDate = '';
        this.detail.customerNo = '';
        this.detail.customerName = '';
        this.detail.amount = '';
        this.detail.accountCode = '';
        this.detail.accountName = '';
        this.detail.remark = '';
    },
})
