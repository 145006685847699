import { observable } from "mobx";

export const AssetSearchStore = observable({
    assetSearchCondition: {
        startDate: null,
        endDate: null,
        assetNo: '',
        contractNo: '',
        assetType: '',
        assetStatus: '',
        productSeq: '',
        productCode: '',
        productName: '',
        model: '',
        serialNo: '',
        sellType: '',
        shelfId: '',
        buyCustomerNo: '',
        buyCustomerName: '',
        lgSeq: '',
        managementNo: '',
        assetNoList: [],
        managementNoList: [],
        serialNoList: [],
    },
    assetHistorySearchCondition: {
        assetNo: '',
        managementNo: '',
        serialNo: '',
    },

    isShort: true,

    remark: '',
    isVirtualStorage: false,

    setAssetSearchCondition(target, v) {
        this.assetSearchCondition[target] = v;
    },
    setIsVirtualStorage(v){
        this.isVirtualStorage = v;
    },
    setRemark(v){
        this.remark = v;
    },

    init() {}
});
