import { observer } from "mobx-react-lite";
import { CContainer, CCol, CInputGroup, CalendarGroup, CSelectGroup, CSearchBtn, CIconBtn, CInputDoubleGroup } from "../../components/CustomContainer";
import { Row, Col } from "react-bootstrap";
import { ReturnAskListStore } from "../../store/return/ReturnAskListStore";
import { getCommcode } from "../../utils/commcode";
import { dateFormat } from "../../utils/dateUtils";
import { AppStore } from "../../store/AppStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import { callAlert } from "../../utils";

const ReturnAskSearch = ({getReturnAskList}) => {

    /* 날짜 선택 */
    const onChangeSearchValue = async (v, label) => {
        const { startDate, endDate } = v;
        const storeStartDate = ReturnAskListStore.search.startDate;
        const storeEndDate = ReturnAskListStore.search.endDate;
        const selectedDate = startDate;

        if (label === 'startDate' && storeEndDate < selectedDate) {
            await callAlert('종료일보다 작게 선택해주세요.');
        } else if (label === 'endDate' && endDate !== null && storeStartDate > selectedDate) {
            await callAlert('시작일보다 크게 선택해주세요.');
        } else {
            ReturnAskListStore.search[label] = selectedDate;
        }
    };

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const searchCustomerInfo = (data, col) => {
        if(data){
            ReturnAskListStore.search.customerNo = data.customerNo;
            ReturnAskListStore.search.customerName = data.customerName;
        }
    }

     /* 유저 조회 팝업 호출 */
     const openSearchUserPopup = () => {
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const searchUserInfo = (data) => {
        if(data) {
            ReturnAskListStore.search.creator = data.userSeq;
            ReturnAskListStore.search.userName = data.name;
        }
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'계약번호'}
                            labelId={'contractNo'}
                            value={ReturnAskListStore.search.contractNo}
                            onChange={v => ReturnAskListStore.search.contractNo = v.target.value}
                            enterKeyHint={getReturnAskList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'회수의뢰번호'}
                            labelId={'returnAskSeq'}
                            value={ReturnAskListStore.search.returnAskSeq}
                            onChange={v => ReturnAskListStore.search.returnAskSeq = v.target.value}
                            enterKeyHint={getReturnAskList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup asSingle={true} label={'시작일'}
                                       value={{
                                            startDate: ReturnAskListStore.search.startDate,
                                            endDate: ReturnAskListStore.search.startDate
                                       }}
                                       onChange={v => onChangeSearchValue(v, 'startDate')}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup asSingle={true} label={'종료일'}
                                       value={{
                                            startDate: ReturnAskListStore.search.endDate,
                                            endDate: ReturnAskListStore.search.endDate
                                       }}
                                       onChange={v => onChangeSearchValue(v, 'endDate')}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputDoubleGroup
                            label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객코드'} placeholder2={'고객명'}
                            onCallbackBtn={openSearchCustomerPopup}
                            value={ReturnAskListStore.search.customerNo || ''}
                            value2={ReturnAskListStore.search.customerName || ''}
                            onChange={v => ReturnAskListStore.search.customerNo = v.target.value}
                            onChange2={v => ReturnAskListStore.search.customerName = v.target.value}
                            enterKeyHint={getReturnAskList}
                            enterKeyHint2={getReturnAskList}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CSelectGroup label={'의뢰상태'} labelId={'returnAskStatus'}
                                      placeholder={'회수의뢰상태'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('25'))}
                                      value={ReturnAskListStore.search.returnAskStatus}
                                      onChange={v => ReturnAskListStore.search.returnAskStatus = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'의뢰자'}
                            labelId={'creatorName'}
                            placeholder={'사원명'}
                            disabled={true}
                            onCallbackBtn={openSearchUserPopup}
                            value={ReturnAskListStore.search.userName}
                            onChange={v => ReturnAskListStore.search.userName = v.target.value}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getReturnAskList} />
                        <CIconBtn style={{width: 120}}
                                  title={'회수의뢰'}
                                  icon={'fi-rr-box-check'}
                                  onClick={()=> AppStore.toggleModal()}
                        />
                    </Col>
                </Row>
            </CContainer>
            <CustomerSearch callbackFn={searchCustomerInfo}/>
            <UserSearch callbackFn={searchUserInfo} />
        </>
    );
}

export default observer(ReturnAskSearch);
