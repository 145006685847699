import React, {useState, useRef, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Row} from "react-bootstrap";
import {CCol, CContainer, CSelectGroup, Subject} from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import {callConfirm} from "../../utils";
import axios from "axios";
import {showToast} from "../../common/utils";
import {AuthorityStore} from "../../store/human-resources/AuthorityStore";

const AuthorityGroup =({getAuthGroupList})=> {
    const allGridRef = useRef();
    const detailGridRef = useRef();
    const [authTotalList, setAuthTotalList] = useState([]);
    //const [groupAuthList, setGroupAuthList] = useState([]);

    useEffect(()=> {
        if (authTotalList.length === 0) {
            const totalDetailList = [];
            /*
                모든 권한을 보여줘야함. (카테고리, 메뉴명 포함)
                authTotalList
             */
            for (let detail of AuthorityStore.orgDetailAuthorityList) {
                for (let sm of AuthorityStore.orgSmAuthorityList) {
                    if (detail.parentSeq === sm.authoritySeq) {
                        detail.menuName = sm.authorityName;
                        for (let md of AuthorityStore.orgMdAuthorityList) {
                            if (sm.parentSeq === md.authoritySeq) {
                                detail.category = md.authorityName;
                                totalDetailList.push(detail);
                                break;
                            }
                        }
                    }
                }
            }
            setAuthTotalList(totalDetailList);
        }
    }, [])

    const getAuthGroupMiddleList = async(val) => {
        AuthorityStore.setSearchGroupCode(val);

        await getAuthGroupList();
        /*
            해당 그룹에 등록된 권한 리스트를 보여준다.    TODO 현재는 sm 이지만 authryties로 바꾼다
         */
        let groupAuthorities= [];
        const groupDetailList = [];

        //해당 그룹에 등록된 sm 코드들을 가져온다.
        for (let group of AuthorityStore.groupList) {
            if (group.authorityGroupSeq == val) {
                groupAuthorities = group.authorities.split(',');
            }
        }
/*
        const authSmallList = [];
        for (let groupSmCode of groupSmallCateList) {
            for (let auth of AuthorityStore.orgSmAuthorityList) {
                if (auth.authorityCode === groupSmCode) {
                    authSmallList.push(auth);
                }
            }
        }*/

        for (let detail of AuthorityStore.orgDetailAuthorityList) {
            if(groupAuthorities.some(v => v === detail.authorityCode)){
                const smCate = AuthorityStore.orgSmAuthorityList.find(v => v.authoritySeq === detail.parentSeq);
                if(smCate){
                    const mdCate = AuthorityStore.orgMdAuthorityList.find(v => v.authoritySeq === smCate.parentSeq);
                    if(mdCate){
                        const detailAuth = {};
                        detailAuth.category = mdCate.authorityName;
                        detailAuth.menuName = smCate.authorityName;
                        detailAuth.authorityCode = detail.authorityCode;
                        detailAuth.authorityName = detail.authorityName;
                        detailAuth.crud = detail.crud;
                        groupDetailList.push(detailAuth);
                    }
                }
            }
        }

        AuthorityStore.setGroupAuthDetailList(groupDetailList);
    }

    const saveAuthorityGroup =async(authorities, type)=> {
        const mdCategories = [];
        const smCategories = [];

        for(let authCode of authorities){
            for (let detail of AuthorityStore.orgDetailAuthorityList) {
                if(authCode === detail.authorityCode){
                    for(let smCate of AuthorityStore.orgSmAuthorityList){
                        if(detail.parentSeq === smCate.authoritySeq){
                            //소분류 넣기
                            smCategories.push(smCate.authorityCode);

                            for(let mdCate of AuthorityStore.orgMdAuthorityList){
                                if(smCate.parentSeq === mdCate.authoritySeq){
                                    //중분류 넣기
                                    mdCategories.push(mdCate.authorityCode);
                                }
                            }
                        }
                    }
                }
            }
        }

        const params = {
            authorityGroupSeq: AuthorityStore.searchGroupCode,
            mdCategories: mdCategories.join(','),
            smCategories: smCategories.join(','),
            authorities: authorities.join(','),
            isUse: type === '삭제' ? 0 : 1
        }

        const result = await axios.put('/authority/group',[params]);
        showToast(`${type}되었습니다`);
        await getAuthGroupMiddleList(AuthorityStore.searchGroupCode);
    }


    const addDetailAuth =async(row)=> {
        const rowData = row.node.data;

        if(AuthorityStore.groupAuthDetailList.some(v => v.authorityCode === rowData.authorityCode)){
            showToast('이미 등록된 권한 입니다.');
            return;
        }

        if(!await callConfirm(`권한을 추가 하시겠습니까?`)) return;

        const authorities = AuthorityStore.groupAuthDetailList.map(v => v.authorityCode).concat(rowData.authorityCode);
        await saveAuthorityGroup(authorities, '추가');
    }

    const deleteRowAuthCode = async(row) => {
        const rowIndex = row.rowIndex;
        if(!await callConfirm('권한을 삭제 하시겠습니까?')) return;

        const authList = AuthorityStore.groupAuthDetailList;
        authList.splice(rowIndex, 1);

        const authorities = authList.map(v => v.authorityCode);
        await saveAuthorityGroup(authorities, '삭제');
    }

    //여러건 추가
    const gridCheckAddHandler =async(callback)=> {
        if(!AuthorityStore.searchGroupCode){
            showToast('권한 그룹을 선택해주세요.');
            return;
        }

        if(!await callConfirm(`권한을 추가 하시겠습니까?`)) return;

        const authCodeDetailList = AuthorityStore.groupAuthDetailList.map(v => v.authorityCode);
        for(let selData of callback.selectedList){
            //기존에 없는것만 추가한다.
            if(!authCodeDetailList.some(v => v.authorityCode === selData.authorityCode)){
                authCodeDetailList.push(selData.authorityCode);
            }
        }

        await saveAuthorityGroup(authCodeDetailList, '추가');
    }

    //여러건 삭제
    const gridCheckDeleteHandler =async(callback)=> {
        if(!AuthorityStore.searchGroupCode){
            showToast('권한 그룹을 선택해주세요.');
            return;
        }

        if(!await callConfirm(`권한을 삭제 하시겠습니까?`)) return;

        const authCodeDetailList = AuthorityStore.groupAuthDetailList.map(v => v.authorityCode);
        const newAuthCodeList = [];
        for(let authCode of authCodeDetailList){
            if(!callback.selectedList.some(v => v.authorityCode === authCode)){
                newAuthCodeList.push(authCode)
            }
        }

        await saveAuthorityGroup(newAuthCodeList, '삭제');
    }

    return (
        <>
            <CContainer search>
                <Row lg={10}>
                    <CCol lg={3}>
                        <CSelectGroup label={'권한그룹'} labelId={'assetType'}
                                      options={[{name: '선택', value: ''}].concat(AuthorityStore.groupList)}
                                      value={AuthorityStore.searchGroupCode || ''}
                                      onChange={(v) => getAuthGroupMiddleList(v.target.value)}
                        />
                    </CCol>
                </Row>
            </CContainer>
            <CContainer>
                <Row>
                    <CCol lg={5}>
                        <Subject>모든 기능 권한 목록</Subject>
                        <AgGridContainer
                            gridRef={allGridRef}
                            height={55}
                            rowData={authTotalList}
                            columnDefs={[
                                {field: "category", headerName: "카테고리", width: 100},
                                {field: "menuName", headerName: "메뉴명", width: 140},
                                {
                                    field: "authorityCode",
                                    headerName: "권한코드",
                                    minWidth: 120,
                                },
                                {
                                    field: 'authorityName',
                                    headerName: '화면 기능',
                                    width: 170,
                                },
                                {
                                    field: 'crud',
                                    headerName: '기능',
                                    width: 120,
                                    valueFormatter: params => params.value === 'C' ? '입력' : params.value === 'R' ? '조회' : params.value === 'U' ? '수정' : '삭제',
                                },
                            ]}
                            seqColumn={'authoritySeq'}
                            isCheckBox={true}
                            selectBtnInfo={{
                                isUsed: true,
                                title: '권한 적용',
                            }}
                            callBackGridData={gridCheckAddHandler}
                            rowDoubleClickCallback={addDetailAuth}
                        />
                    </CCol>
                    <CCol lg={7}>
                        <Subject>그룹에 적용된 권한</Subject>
                        <AgGridContainer
                            gridRef={detailGridRef}
                            height={55}
                            rowData={AuthorityStore.groupAuthDetailList}
                            columnDefs={[
                                {field: "category", headerName: "카테고리", width: 120},
                                {field: "menuName", headerName: "메뉴명", width: 140},
                                {
                                    field: "authorityCode",
                                    headerName: "권한코드",
                                    minWidth: 120,
                                },
                                {
                                    field: 'authorityName',
                                    headerName: '화면 기능',
                                    width: 180,
                                },
                                {
                                    field: 'crud',
                                    headerName: '기능',
                                    width: 100,
                                    flex: 1,
                                    valueFormatter: params => params.value === 'C' ? '입력' : params.value === 'R' ? '조회' : params.value === 'U' ? '수정' : '삭제',
                                },
                            ]}
                            noIconBtn={true}
                            isCheckBox={true}
                            useRemoveRow={true}
                            seqColumn={'authoritySeq'}
                            removeCallback={(v) => deleteRowAuthCode(v)}
                            selectBtnInfo={{
                                variant: 'danger',
                                isUsed: true,
                                title: '권한 삭제',
                            }}
                            callBackGridData={gridCheckDeleteHandler}
                        />
                    </CCol>
                </Row>
            </CContainer>
        </>
    )
}

export default observer(AuthorityGroup);
