import { useState } from "react";
import { observer } from "mobx-react-lite";
import { CContainer, CCol, CSelectGroup, CSearchBtn } from "../../components/CustomContainer";
import { Row, InputGroup, Form, Col } from "react-bootstrap";
import { SalaryStatusStore } from "../../store/human-resources/SalaryStatusStore";
import { getCommcode } from "../../utils/commcode";
import axios from "axios";
import { showToast } from "../../common/utils";

const SalaryStatusListSearch = () => {
    const [monthList, setMonthList] = useState([{name: '1', value: '01'}
                                                , {name: '2', value: '02'}
                                                , {name: '3', value: '03'}
                                                , {name: '4', value: '04'}
                                                , {name: '5', value: '05'}
                                                , {name: '6', value: '06'}
                                                , {name: '7', value: '07'}
                                                , {name: '8', value: '08'}
                                                , {name: '9', value: '09'}
                                                , {name: '10', value: '10'}
                                                , {name: '11', value: '11'}
                                                , {name: '12', value: '12'}]);
    const [companyCode, setCompanyCode] = useState('');

    /* 월별 지급 현황 조회 */
    const getUserSalaryList = async () => {

        let requiredParams = ['companyCode'];
        if (!requiredParams.every(param => SalaryStatusStore.search[param])) {
            showToast('회사 구분을 선택해주세요.');
            return;
        }

        const resultList = await axios.get('/salary/status', {params: {year: SalaryStatusStore.search.year, startMonth: SalaryStatusStore.search.startMonth, endMonth: SalaryStatusStore.search.endMonth, companyCode: companyCode}});
        if (resultList.length === 0) {
            showToast('조회된 목록이 없습니다.');
        }
        SalaryStatusStore.salaryList = resultList;
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={4}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>귀속년월</InputGroup.Text>
                            <Form.Control
                                style={{flex:3, textAlign: 'right'}}
                                max={10}
                                aria-describedby={'year'}
                                value={SalaryStatusStore.search.year}
                                onChange={v => SalaryStatusStore.search.year = v.target.value}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>년</InputGroup.Text>
                            <Form.Select
                                style={{flex: 1, textAlign: 'right'}}
                                aria-label={'startMonth'}
                                value={SalaryStatusStore.search.startMonth}
                                onChange={v => SalaryStatusStore.search.startMonth = v.target.value}
                            >
                                {monthList.map((option, idx)=> {
                                    return(
                                        <option key={idx} value={option.value}>{option.name}</option>
                                    );
                                })}
                            </Form.Select>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>월부터</InputGroup.Text>
                            <Form.Select
                                style={{flex: 1, textAlign: 'right'}}
                                aria-label={'endMonth'}
                                value={SalaryStatusStore.search.endMonth}
                                onChange={v => {SalaryStatusStore.search.endMonth = v.target.value; } }
                            >
                                {monthList.map((option, idx)=> {
                                    return(
                                        <option key={idx} value={option.value}>{option.name}</option>
                                    );
                                })}
                            </Form.Select>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>월까지</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'회사구분'} labelId={'companyCode'}
                                        options={[{name: '선택', value: ''}].concat(getCommcode('20'))}
                                        value={SalaryStatusStore.search.companyCode}
                                        onChange={v => SalaryStatusStore.search.companyCode = v.target.value}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className="d-flex flex-row-reverse">

                        <CSearchBtn style={{width: 100, height: 30}}
                                title={'조회'}
                                onClick={getUserSalaryList}
                        />
                    </Col>
                </Row>
            </CContainer>
        </>
    );

}

export default observer(SalaryStatusListSearch);