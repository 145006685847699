import { observer } from "mobx-react-lite"
import { useState } from "react";
import { Col, Form } from "react-bootstrap";

import { CContainer, CIconBtn } from "../../../components/CustomContainer";
import Modal from "../../../components/Modal";
import { AppStore } from "../../../store/AppStore";

const ChangePriceSavePopup = ({callBackFn}) => {
    const [isThisMonth, setIsThisMonth] = useState('1');

    const refresh = () => {
        setIsThisMonth('1');
    }

    const saveRows = () => {
        callBackFn(isThisMonth);
        AppStore.toggleChangePriceSavePopup();
    }

    return (
        <Modal title={'단가 변경'}
            onHide={() => AppStore.toggleChangePriceSavePopup()}
            toggleValue={AppStore.isOpenChangePriceSavePopup}
            onExit={refresh}
            size={'sm'}
        >
            <CContainer paddingTop={'pt-2'} style={{padding: 0}}>
                <Form.Check
                    inline
                    label="당월부터 적용"
                    name="thisMonth"
                    type={'radio'}
                    id='thisMonth'
                    value={'1'}
                    checked={isThisMonth === '1'}
                    onChange={(e)=> setIsThisMonth(e.target.value)}
                />
                <Form.Check
                    inline
                    label="익월부터 적용"
                    name="nextMonth"
                    type={'radio'}
                    id='nextMonth'
                    value={'0'}
                    checked={isThisMonth === '0'}
                    onChange={(e)=> setIsThisMonth(e.target.value)}
                />
                <Col className="d-flex flex-row-reverse">
                    <CIconBtn style={{width: 90, height: 30, marginTop: 10}}
                            title={'저장'}
                            className={'me-0'}
                            onClick={saveRows}
                            variant={'success'}
                    />
                </Col>
            </CContainer>
        </Modal>
    );
}

export default observer(ChangePriceSavePopup);
