import { observable } from "mobx";

export const AccountStore = observable({
    accountList: [],
    search: {
        bankCode: '',
        accountNumber: '',
        loginId: '',
    },
    data: {
        accountManagementSeq: '',
        accountNumber: '',
        accountPassword: '',
        bankCode: '',
        loginId: '',
        loginPassword: '',
        accountManagementType: '1',
        certificateSeq: '',
        certName: '',
        certPassword: '',
        signcertDer: '',
        signpriKey: '',
        derPath: '',
        keyPath: '',
        expirationDate: '',
        isRepresentative: 0,
        remark: '',
    },

    dataInit(){
        this.data.accountManagementSeq = '';
        this.data.accountNumber = '';
        this.data.accountPassword = '';
        this.data.bankCode = '';
        this.data.loginId = '';
        this.data.loginPassword = '';
        this.data.accountManagementType = '1';
        this.data.certificateSeq = '';
        this.data.certName = '';
        this.data.certPassword = '';
        this.data.signcertDer = '';
        this.data.signpriKey = '';
        this.data.derPath = '';
        this.data.keyPath = '';
        this.data.expirationDate = '';
        this.data.isRepresentative = 0;
        this.data.remark = '';
    },
    loginDataInit(){
        this.data.loginId = '';
        this.data.loginPassword = '';
    },
    certificateDataInit(){
        this.data.certificateSeq = '';
        this.data.certName = '';
        this.data.certPassword = '';
        this.data.signcertDer = '';
        this.data.signpriKey = '';
        this.data.derPath = '';
        this.data.keyPath = '';
        this.data.expirationDate = '';
    }
})
