import { observer } from "mobx-react-lite"
import { CContainer, CCol, CIconBtn, CInputGroup } from "../../../components/CustomContainer";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import Modal from "../../../components/Modal";
import { AppStore } from "../../../store/AppStore";
import AgGridContainer from "../../../components/AgGridContainer";
import { ContractDetailStore } from "../../../store/contract/ContractDetailStore";
import { decrypt, encrypt } from "../../../common/utils";
import { findCommKrnm } from "../../../utils/commcode";
import { useState } from "react";
import { ContractDocumentStore } from "../../../store/contract/ContractDocumentStore";


const SendContractPopup = ({sendContract, type}) => {
    const store = ContractDetailStore;

    const refresh = () => {
        store.tabKey = 'mail';
        // store.sendEmailInit();
    }

    return (
        <>
            <Modal title={'계약서 메일 전송'}
                onHide={() => AppStore.toggleSendContractPopup()}
                toggleValue={AppStore.isOpenSendContractPopup}
                onExit={refresh}
                size={'md'}
            >
            <CContainer>
            <Tabs
                id="controlled-tab-example"
                activeKey={store.tabKey}
                onSelect={(k) => store.tabKey = k}
                className="mb-3"
                >
                <Tab eventKey="mail" title="메일 전송" />
                <Tab eventKey="sms" title="알림톡 전송" />
                {/* <Tab eventKey="contact" title="Contact " disabled>
                    Tab content for Contact
                </Tab> */}
            </Tabs>
                {type !== 'detail' &&
                    <Row>
                        <AgGridContainer
                            gridTitle={'계약목록'}
                            height={23}
                            rowData={store.sendContractList}
                            columnDefs={
                                [
                                    {field: "contractNo", headerName: '계약번호', width: 160},
                                    {field: "customerName", headerName: '고객명', width: 160},
                                    {field: "billingAddressName", headerName: '청구지명', width: 140},
                                    {field: "siteName", headerName: '현장명', width: 120},
                                ]
                            }
                            />
                    </Row>
                }
                <Row className="mt-3">
                    <CCol lg={12}>
                        <CInputGroup label={'제목'}
                                    labelId={'subject'}
                                    placeholder={'제목을 작성해주세요.'}
                                    value={store.subject}
                                    onChange={v => store.subject = v.target.value}
                        />
                    </CCol>
                    <CCol lg={12}>
                        {store.tabKey === 'mail' &&
                            <CInputGroup label={'이메일'}
                                        labelId={'receiverEmail'}
                                        placeholder={'sample@naver.com'}
                                        value={decrypt(store.receiverEmail)}
                                        onChange={v => store.receiverEmail = encrypt(v.target.value)}
                            />
                        }
                        {store.tabKey === 'sms' &&
                            <CInputGroup label={'휴대폰 번호'}
                                        labelId={'phoneNumber'}
                                        placeholder={'010-1234-1234'}
                                        value={decrypt(store.phoneNumber)}
                                        onChange={v => store.phoneNumber = encrypt(v.target.value)}
                            />
                        }
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup label={'담당자명'}
                                    labelId={'contractManager'}
                                    placeholder={'홍길동'}
                                    value={store.contractManager}
                                    onChange={v => store.contractManager = v.target.value}
                        />
                    </CCol>
                </Row>
                <Row>
                    {store.tabKey === 'mail' && 
                        <Col className="d-flex flex-row-reverse">
                            <CIconBtn style={{width: 100, height: 30}}
                                    title={'메일 전송'}
                                    icon={'fi-rr-file-invoice-dollar'}
                                    onClick={sendContract}
                                    variant={'success'}
                            />
                        </Col>
                    }
                    {store.tabKey === 'sms' &&
                        <Col className="d-flex flex-row-reverse">
                            <CIconBtn style={{width: 100, height: 30}}
                                    title={'알림톡 전송'}
                                    icon={'fi-rr-comment-sms'}
                                    onClick={sendContract}
                                    variant={'success'}
                            />
                        </Col>
                    }
                </Row>
            </CContainer>
        </Modal>
        </>
    );
}

export default observer(SendContractPopup);