import {useEffect, useRef, useState} from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import AgGridContainer from '../../../components/AgGridContainer';
import LUtils from '../../../utils/lodashUtils';
import { callAlert } from '../../../utils';
import { CustomerStore } from '../../../store/customer/CustomerStore';
import { getUserName } from '../../../utils/userUtils';
import {IconBtn} from "../../../components/CustomContainer";
import {AppStore} from "../../../store/AppStore";
import UserSearch from "../../../components/searchModal/UserSearch";

const CustomerLimit = ({ customerNo, ...props }) => {
    const gridRef = useRef();
    const [selLimitSeq, setSelLimitSeq] = useState('');
    const [totalLimitPrice, setTotalLimitPrice] = useState('');

    const columnDefs = [
        {field: "customerLimitSeq", hide: true},
        {field: "limitSeq", headerName: "순번", width: 80},
        {
            field: "limitPrice",
            headerName: "한도금액",
            editable: true,
            headerClass: 'grid-column-editable grid-column-required',
            cellClass: 'ag-grid-money-align',
            valueFormatter: v => v.value?.toLocaleString(),
            width: 130
        },
/*        {field: "userSeq", headerName: "품의자", hide: true},
        {
            field: "userName",
            headerName: "품의자",
            headerClass: 'grid-column-editable grid-column-required',
            cellRenderer: e => popupUserInfo(e)
        },*/
        {field: "remark", headerName: "비고", editable: true, width: 300, headerClass: 'grid-column-editable'},
        {field: "creator", headerName: "입력자", width: 100, valueGetter: v => getUserName(v.data.creator)},
        {field: "createdDate", headerName: "입력일자", width: 180},
        {field: "updater", headerName: "수정자", width: 100, valueGetter: v => getUserName(v.data.updater)},
        {field: "updatedDate", headerName: "수장일자", width: 180},
    ]

    useEffect(() => {
        getLimitList(customerNo);

        if(customerNo) CustomerStore.customerNo = customerNo;
        else CustomerStore.customerNo = props.customerInfo.customerNo;
    }, [props.customerInfo.customerNo]);

    const getLimitList = async (customerNo) => {
        if (!customerNo) {
            return false;
        }

        const result = await axios.get(`/customer/tabs/${customerNo}/limit`);
        CustomerStore.setLimitList(result);

        setTotalLimitPrice('총 한도 금액 : ' + CustomerStore.limitList.reduce((total, v) => total + v.limitPrice, 0).toLocaleString()+'원');
    }

    const saveFn = async ({ updatedList, createdList }) => {
        const isUpdate = !LUtils.isEmpty(updatedList);
        const isCreate = !LUtils.isEmpty(createdList);
        if (!isUpdate && !isCreate) {
            await callAlert('저장할 내용이 없습니다.');
            return false;
        }

        // TODO: 필수값 검사

        isUpdate && await axios.put(`/customer/tabs/${CustomerStore.customerNo}/limit`, { limitList: updatedList });
        isCreate && await axios.post(`/customer/tabs/${CustomerStore.customerNo}/limit`, { limitList: createdList });

        callAlert('저장 되었습니다.');
        await getLimitList(CustomerStore.customerNo);
    }

    /* 사원 팝업 UI */
    const popupUserInfo = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.userName}</div>
                <IconBtn key={e.rowIndex} onClick={() => {
                    setSelLimitSeq(e.data.limitSeq);
                    AppStore.toggleUserSearchModal();
                }} />
            </div>
        )
    }

    /* 사원 조회 팝업 */
    const openUserSearchPopup = (e) =>{
        AppStore.toggleUserSearchModal();
    }

    const getUserInfo = (userInfo) => {
        const result = CustomerStore.limitList.map(v => {
            if(v.limitSeq === selLimitSeq) {
                v.userSeq = userInfo.userSeq;
                v.userName = userInfo.name;
                LUtils.assign(v, {isUpdated: true });
            }
            return v;
        });
        CustomerStore.setLimitList(result);
    }

    const addRowInfo = { department: '', limitPrice: 0, userSeq: 0, remark:'' };

    return (
        <>
            <UserSearch callbackFn={getUserInfo} />
            <h5>{totalLimitPrice}</h5>
            <AgGridContainer
                gridRef={gridRef}
                height={30}
                rowData={CustomerStore.limitList}
                columnDefs={columnDefs}
                isCheckBox={!!CustomerStore.customerNo}
                seqColumn={'customerLimitSeq'}
                useCsvDownload={!!CustomerStore.customerNo}
                useCreated={!!CustomerStore.customerNo}
                useUpdated={!!CustomerStore.customerNo}
                callBackGridData={saveFn}
                addRowInfo={addRowInfo}
                getRowStyle={(params) => {
                    if (!params.data.isCreated) {
                        return { pointerEvents: 'none'};
                    }
                    return null;
                }}
            />
        </>
    );
};

export default observer(CustomerLimit);
