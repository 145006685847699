import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import axios from 'axios';
import { useReactToPrint } from "react-to-print";

import {CCol, CContainer, CInputGroup, CSelectGroup, CIconBtn, Subject, CCancelBtn, CSaveBtn, CalendarGroup} from "../../components/CustomContainer";
import {getCommcode} from "../../utils/commcode";
import {ReleaseAskDetailStore} from "../../store/release/ReleaseAskDetailStore";
import Address from "../../components/Address";
import AgGridContainer from "../../components/AgGridContainer";
import {encrypt, decrypt, autoHyphen, localAutoHyphen} from "../../common/utils";
import {getLocalStorage} from '../../utils';
import {MULTIPART_HEADER, USER_INFO} from '../../common/constants';
import {dateFormat, today} from '../../utils/dateUtils';
import LUtils from "../../utils/lodashUtils";
import {callConfirm} from '../../utils';
import { IconBtn } from '../../components/CustomContainer';
import AssetSearch from "../../components/searchModal/AssetSearch";
import {AppStore} from "../../store/AppStore";
import { ICON_TRASH, ICON_ADD } from "../../common/constants";
import ReportReleaseAskTemplate from "../../components/report/ReportReleaseAskTemplate";
import ReleaseAssetCheck from "../../components/searchModal/ReleaseAssetCheck";
import ContractSearch from "../../components/searchModal/ContractSearch";
import StockProductSearch from "../../components/searchModal/StockProductSearch";
import { showToast } from "../../common/utils";
import { ContractDetailStore } from "../../store/contract/ContractDetailStore";
import {getUserAuthGroup} from "../../utils/userUtils";
import ReportReleaseAskPrintTemplate from "../../components/report/ReportReleaseAskPrintTemplate";
import SiteOption from "../../components/SiteOption";
import StockAssetSearch from "../../components/searchModal/StockAssetSearch";
import { makeReleaseAskPrintTemplate } from "./makeReleaseAskPrintTemplate";
import Modal from "../../components/Modal";
import html2pdf from "html2pdf.js";
import ReportReleaseAskPrintGroupByTemplate from "../../components/report/ReportReleaseAskPrintGroupByTemplate";
import CustomerManagerSearch from "../../components/searchModal/CustomerManagerSearch";
import UploadDcoumentPopup from "./popup/UploadDcoumentPopup";
import CheckReleaseShipPopup from "./popup/CheckReleaseShipPopup";

const ReleaseAskDetail =({ contractNo, customerNo, billingAddressSeq, siteSeq, releaseAskSeq, getReleaseAskList, selectAssetList, callBackFn })=> {
    const gridRef = useRef();
    const returnGridRef = useRef();
    const componentRef = useRef();
    const componentPrintRef = useRef();
    const etcReleaseGridRef = useRef();
    const groupByPrintRef = useRef();

    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [returnPostcode, setReturnPostcode] = useState('');
    const [returnAddress, setReturnAddress] = useState('');
    const [returnAddressDetail, setReturnAddressDetail] = useState('');
    const [gridData, setGridData] = useState([]);
    const [releaseAskItemList, setReleaseAskItemList] = useState([]);
    const [productCode, setProductCode] = useState('');
    const [isEtcReleaseAsk, setIsEtcReleaseAsk] = useState(false);
    const [btnStatus, setBtnStatus] = useState('add');
    const [sendMailPopup, setSendMailPopup] = useState(false);
    const [userList, setUserList] = useState([]);
    const [emailSubject, setEmailSubject] = useState('');
    const [emailContent, setEmailContent] = useState('');
    const [receiverEmail, setReceiverEmail] = useState('');

    //입력수정가능여부
    const [isDisable, setIsDisable] = useState(false);
    const [isTechManager, setIsTechManager] = useState(false);
    const [isPrintAll, setIsPrintAll] = useState(true);
    const [isSendAll, setIsSendAll] = useState(true);
    const [isReturnAskInfo, setIsReturnAskInfo] = useState(false);


    // 1: 고소작업대(T/L) 안전점검 결과서, 2: 보험증권, 3: 제원표, 4: 안전인증서, 5: 자산안전인증서, 6: 작동방법설명서, 7: 비상하강, 8: 반입전 체크리스트, 9: 계약서, 10: 사업자등록증, 11: 통장사본
    const [checkedList, setCheckedList] = useState(['pdfType1', 'pdfType2', 'pdfType3', 'pdfType4', 'pdfType5', 'pdfType6', 'pdfType7', 'pdfType8']);
    const [mailCheckedList, setMailCheckedList] = useState(['pdfType1', 'pdfType2', 'pdfType3', 'pdfType4', 'pdfType5', 'pdfType6', 'pdfType7', 'pdfType8', 'pdfType9', 'pdfType10', 'pdfType11']);
    const [isChecked, setIsChecked] = useState(false);
    const userInfo = getLocalStorage(USER_INFO);

    /* 기본값 세팅 */
    useEffect(()=> {
        //계약에서 넘어왔는지, 출고의뢰 리스트에서 넘어왔는지로 disabled 처리 해야함
        if (releaseAskSeq) {
            Promise.all([getReleaseAsk(releaseAskSeq), getReleaseAskItemList(releaseAskSeq), getContractSiteOptionList(), getDocument(releaseAskSeq)]);
        } else if (contractNo) {
            Promise.all([getReleaseAskInfo(), getContractSiteOptionList()]);

            if(selectAssetList){
                setIsEtcReleaseAsk(true);

                setTimeout(()=> {
                    ReleaseAskDetailStore.setReleaseAskDetail({releaseAskDate: today(), releaseAskType: '3'
                        , creatorSeq: userInfo.userSeq, creatorName: userInfo.name});
                }, 500)
            }
        } else {
            setIsEtcReleaseAsk(true);
            ReleaseAskDetailStore.setReleaseAskDetail({contractType: '', releaseAskDate: today(), releaseAskType: '2'
                                                        , creator: userInfo.userSeq, creatorName: userInfo.name});
        }
        
        setTimeout(()=> {
            inputModifyDisableHandler();
        }, 500)
        // return ReleaseAskDetailStore.init();
    }, []);

    /* 계약에서 넘어온 경우 정보 초기 세팅 */
    const getReleaseAskInfo = async () => {
        const result = await axios.get('releaseAsk/info', {params: {contractNo: contractNo, customerNo: customerNo, billingAddressSeq: billingAddressSeq}});
        // 주소 세팅
        setPostcode(result.installPlace ? '' : result.postCode);
        setAddress(result.installPlace ? result.installPlace : result.address);
        setAddressDetail(result.installPlace ? '' : result.addressDetail);
        setReturnPostcode(result.returnPostcode ? result.returnPostcode : '');
        setReturnAddress(result.returnAddress ? result.returnAddress : '');
        setReturnAddressDetail(result.returnAddressDetail ? result.returnAddressDetail : '');

        // 출고의뢰상태, 출고의뢰일, 의뢰자명 세팅
        ReleaseAskDetailStore.setReleaseAskDetail({...result, releaseAskStatus: '', releaseAskType: '1', releaseAskDate: today(), creatorSeq: userInfo.userSeq, creatorName: userInfo.name});
        if(ReleaseAskDetailStore.releaseAskDetail.contractType === '1' && !releaseAskSeq) {
            ReleaseAskDetailStore.releaseAskDetail.shipHopeDate =  result.contractStartDate;
            ReleaseAskDetailStore.releaseAskDetail.isReturnAskInfo = isReturnAskInfo;
            ReleaseAskDetailStore.releaseAskDetail.returnTelephone = result.telephone;
            ReleaseAskDetailStore.releaseAskDetail.returnPhoneNumber = result.phoneNumber;
            ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserDept = result.receiveUserDept;
            ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserPosition = result.receiveUserPosition;
            ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserName = result.receiveUserName;
            ReleaseAskDetailStore.releaseAskDetail.returnShipHopeDate =  result.contractEndDate;
            setReturnPostcode(result.postCode ? result.postCode : '');
            setReturnAddress(result.address ? result.address : '');
            setReturnAddressDetail(result.addressDetail ? result.addressDetail : '');
        }

        let companyShipType = 'direct';
        //기연리프트
        if(userInfo.companyCode === '2404001'){
            companyShipType = 'outsourcing';
        }
        //배송시간 7~8, 회수시간 10~11 시 default 셋팅
        ReleaseAskDetailStore.setReleaseAskDetail({shipHopeTime: '3', shipType: companyShipType, returnShipHopeTime: '6', returnShipType: companyShipType});

    }

    /* releaseAskSeq로 출고의뢰 조회 */
    const getReleaseAsk = async (releaseAskSeq) => {
        const result = await axios.get('releaseAsk', {params: {releaseAskSeq: releaseAskSeq}});
        ReleaseAskDetailStore.setReleaseAskDetail(result);

        // 주소 세팅
        setPostcode(result.postCode ? result.postCode : '');
        setAddress(result.address ? result.address : '');
        setAddressDetail(result.addressDetail ? result.addressDetail : '');
        setReturnPostcode(result.returnPostcode ? result.returnPostcode : '');
        setReturnAddress(result.returnAddress ? result.returnAddress : '');
        setReturnAddressDetail(result.returnAddressDetail ? result.returnAddressDetail : '');
        setIsReturnAskInfo(result.returnAskSeq ? true : false);
    }

    /* 출고 제품 리스트 */
    const getReleaseAskItemList = async (releaseAskSeq) => {
        const result = await axios.get('releaseAskItem/list', {params: {releaseAskSeq: releaseAskSeq}});
        ReleaseAskDetailStore.setReleaseAskItemList(result);
        setReleaseAskItemList(result);
        //출고의뢰서에 사용하는 변수
        ReleaseAskDetailStore.dataList = result;
    }

    /* 현장 옵션 리스트 */
    const getContractSiteOptionList = async () => {
        ReleaseAskDetailStore.setContractSiteOptionList(await axios.get(`/contract/site/option/${contractNo}`));
    }

    /* 저장(작성중 상태로 변경) */
    const saveReleaseAsk = async () => {

        let returnComment = '';
        if (!ReleaseAskDetailStore.releaseAskDetail.contractNo) returnComment = '계약번호가 없습니다.';
        if (!ReleaseAskDetailStore.releaseAskDetail.phoneNumber) returnComment = '받는분 휴대폰번호를 입력해주세요';
        if (!ReleaseAskDetailStore.releaseAskDetail.receiveUserName) returnComment = '받는분 성함을 입력해주세요';
        if (!ReleaseAskDetailStore.releaseAskDetail.shipHopeDate) returnComment = '배송 희망일 선택해주세요';
        if (!ReleaseAskDetailStore.releaseAskDetail.shipHopeTime) returnComment = '배송 시간을 선택해주세요';
        if (!ReleaseAskDetailStore.releaseAskDetail.shipType) returnComment = '배송 방법을 선택해주세요';

        // 필수값 확인
        if(isReturnAskInfo) {
            if (!ReleaseAskDetailStore.releaseAskDetail.returnPhoneNumber) returnComment = '회수시 인도자 휴대폰번호를 입력해주세요';
            if (!ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserName) returnComment = '회수시 인도자 성함을 입력해주세요';
            if (!ReleaseAskDetailStore.releaseAskDetail.returnShipHopeDate) returnComment = '회수 희망일 선택해주세요';
            if (!ReleaseAskDetailStore.releaseAskDetail.returnShipHopeTime) returnComment = '회수 예정 시간을 선택해주세요';
            if (!ReleaseAskDetailStore.releaseAskDetail.returnShipType) returnComment = '회수 방법을 선택해주세요';
        }

        if(returnComment){
            showToast(returnComment);
            return;
        }

        // 기타출고일 경우, 추가한 출고 제품 리스트의 수량까지 잘 작성되어있는지 확인
        if (isEtcReleaseAsk) {
            if(selectAssetList) {
                if(!selectAssetList.every(v => v.changeProductName)) {
                    showToast('교환 제품을 추가해주세요.');
                    return false;
                }
            }else {
                if (ReleaseAskDetailStore.releaseAskItemList.length > 0) {
                    const cnt = ReleaseAskDetailStore.releaseAskItemList.filter(v => !v.qty);
                    if(cnt.length > 0) {
                        showToast('출고 제품 수량을 입력해주세요.');
                        return false;
                    }
                } else {
                    showToast('출고 제품을 추가해주세요.');
                    return false;
                }
            }
        }

        // 저장 확인
        if(!await callConfirm('저장 하시겠습니까?')) return;

        const {releaseAskSeq} = ReleaseAskDetailStore.releaseAskDetail;
        ReleaseAskDetailStore.setReleaseAskDetail({postCode: postcode, address: address, addressDetail: addressDetail, returnPostcode: returnPostcode, returnAddress: returnAddress, returnAddressDetail: returnAddressDetail});    // 주소 세팅

        if(!releaseAskSeq){ // 등록
            // 기타 출고일 경우, 기타 출고 아이템 목록도 같이 저장시켜야 함
            isEtcReleaseAsk && ReleaseAskDetailStore.setReleaseAskDetail({releaseAskItemList: ReleaseAskDetailStore.releaseAskItemList});
            if(selectAssetList){
                const assetNoList = selectAssetList.map(v => v.assetNo);
                Object.assign(ReleaseAskDetailStore.releaseAskDetail, {releaseAskReturnItemList: assetNoList}, {selectAssetList: selectAssetList});
            }

            const result = await axios.post('releaseAsk', ReleaseAskDetailStore.releaseAskDetail);

            showToast('저장되었습니다.');

            if(result){
                ContractDetailStore.isReleaseAskBtn = false;
                getReleaseAskList && getReleaseAskList();
                await Promise.all([getReleaseAsk(result.releaseAskSeq), getReleaseAskItemList(result.releaseAskSeq)]);
            }
        } else { // 수정
            await axios.put('releaseAsk', ReleaseAskDetailStore.releaseAskDetail);
            showToast('수정되었습니다.');
            await Promise.all([getReleaseAskList && getReleaseAskList(), getReleaseAsk(releaseAskSeq), getReleaseAskItemList(releaseAskSeq)]);
        }
    }

    /* 출고 의뢰 상태 저장 */
    const saveReleaseAskStatus = async (status, btnText) => {
        // 필수값 확인 => 임대 중 자산 매각이 아닌 경우만 필수값 확인(가상출고 아닌 경우)
        if(ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease !== 1) {
            if (!ReleaseAskDetailStore.releaseAskDetail.phoneNumber) {
                showToast('받는분 휴대폰번호를 입력해주세요');
                return;
            }
            if (!ReleaseAskDetailStore.releaseAskDetail.receiveUserName) {
                showToast('받는분 성함을 입력해주세요');
                return;
            }
            if (!ReleaseAskDetailStore.releaseAskDetail.shipHopeDate) {
                showToast('배송 희망일을 선택해주세요');
                return;
            }
            if (!ReleaseAskDetailStore.releaseAskDetail.shipType) {
                showToast('배송 방법을 선택해주세요');
                return;
            }
        }

        if(btnText === '출고완료'){
            if(ReleaseAskDetailStore.releaseAskItemList.some(v => v.isUpdated)){
                showToast('출고 장비 저장 후 진행해주세요.');
                return;
            }

            if(ReleaseAskDetailStore.releaseAskItemList.some(v => !v.isOption && !v.assetNo)){
                showToast('옵션을 제외한 출고 장비는 관리번호가 모두 입력되어야 합니다.');
                return;
            }

            const firstApronSeq = ReleaseAskDetailStore.releaseAskDetail.apronSeq;
    
            if(!ReleaseAskDetailStore.releaseAskItemList.filter(v => !v.isOption).every(item => item.apronSeq === firstApronSeq)){
                showToast('출고 장비와 출고의뢰의 주기장 정보가 일치해야 합니다.');
                return;
            }
        }

        // 저장 확인
        if(!await callConfirm(btnText + ' 하시겠습니까?')) return;

        const {releaseAskSeq} = ReleaseAskDetailStore.releaseAskDetail;
        ReleaseAskDetailStore.setReleaseAskDetail({postCode: postcode, address: address, addressDetail: addressDetail});    // 주소 세팅

        // 수정
        const result = await axios.put(`releaseAsk/status/${status}`, ReleaseAskDetailStore.releaseAskDetail);

        if(result && result === 'success'){
            if(status === '4'){
                showToast('출고검수를 시작합니다.');
            }else if(status === '5'){
                showToast('출고작업을 시작합니다.');
            }else if(status === '6'){
                showToast('출고가 완료되었습니다.');
                
                if(await callConfirm('배송완료 처리도 진행하시겠습니까?')){
                    AppStore.toggleIsOpenReleaseShipPopup();
                    getReleaseAskList && getReleaseAskList();
                    return;
                }

            }else{
                showToast(btnText + '가 완료되었습니다.');
            }

            AppStore.toggleModal();
            callBackFn && callBackFn();

            getReleaseAskList && getReleaseAskList();
        }else{
            showToast('출고에 실패하였습니다.');
        }
    }

    /* 자산 팝업 UI */
    const popupAssetInfo = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.managementNo|| ''}</div>
                {(ReleaseAskDetailStore.releaseAskDetail.releaseAskType !== 4 && !isDisable) && (
                    <IconBtn style={{paddingTop: 6, color:'darkblue', textAlign: 'right'}} key={e.rowIndex} onClick={() => {openAssetSearchPopup(e)}} />
                )}
            </div>
        )
    }

    /* 재고 조회 팝업 UI */
    const popupStockInfo = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.changeProductName || ''}</div>
                <IconBtn style={{paddingTop: 6, color:'darkblue', textAlign: 'right'}} key={e.rowIndex} onClick={() => {AppStore.toggleStockAssetSearchModal(), ReleaseAskDetailStore.selectItemAgId = e.data.agId}} />
            </div>
        )
    }

    /* 자산 조회 팝업 */
    const openAssetSearchPopup = (e) =>{
        AppStore.toggleStockAssetSearchModal();
        ReleaseAskDetailStore.selectItemAgId = e.data.agId
        //setGridData(e);
    }

    /* 저장버튼(수정) 클릭 이벤트 */
    const callBackGridData = async (gridData) => {
        const {releaseAskSeq} = ReleaseAskDetailStore.releaseAskDetail;

        if(!releaseAskSeq){
            showToast('출고의뢰 정보를 먼저 저장해주세요.');
            return;
        }

        const firstApronSeq = ReleaseAskDetailStore.releaseAskDetail.apronSeq;

        if(!ReleaseAskDetailStore.releaseAskItemList.filter(v => !v.isOption ).every(item => item.apronSeq === firstApronSeq)){
            showToast('출고 장비와 출고의뢰의 주기장 정보가 일치해야 합니다.');
            return;
        }

        // 저장 확인
        if(!await callConfirm('저장 하시겠습니까?')) return;


        const releaseAskItemList = ReleaseAskDetailStore.releaseAskItemList.filter(v => v.isSelected || v.isCreated || v.isUpdated);
        const params = {releaseAskItemList: releaseAskItemList, contractNo: ReleaseAskDetailStore.releaseAskDetail.contractNo};
        await axios.put('releaseAskItem', params);
        await getReleaseAskItemList(releaseAskSeq);

        showToast('저장되었습니다.');
    }

    /* 출고 제품 삭제 버튼 */
    const customCancelBtn = {
        isUsed: !['3','4'].includes(ReleaseAskDetailStore.releaseAskDetail.contractType) &&
            ((isTechManager && ['3', '4', '5'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus))
            || ['1', '2'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus))
            && ReleaseAskDetailStore.releaseAskDetail.releaseAskType !== 4,
        callbackFn:() => selectedGridCancel(),
        variant: 'danger',
        icon: ICON_TRASH,
        title: '출고제품삭제',
        width: 120,
    }

    /* 자산검수 버튼 */
    const assetCheckPopup = {
        isUsed: isTechManager && ['4', '5'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) && Number(ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease) !== 1,
        callbackFn:() => AppStore.toggleReleaseAssetCheckPopup(),
        icon: 'fi-rs-search-alt',
        title: '자산검수',
        width: 100,
    }

    /* 자산검수 초기화 */
    const resetAssetCheckBtn = {
        isUsed: isTechManager && ['4', '5'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) && Number(ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease) !== 1,
        callbackFn:() => resetCheckAsset(),
        variant: 'warning',
        icon: 'fi-rr-time-past',
        title: '검수초기화',
        width: 110,
    }

    /* 출고 제품 선택 삭제 callback */
    const selectedGridCancel = async () => {
        const selectedList = ReleaseAskDetailStore.releaseAskItemList.filter(v => v.isSelected);
        if(selectedList.length === 0){
            showToast('선택된 행이 없습니다.');
            return;
        }

        // 삭제 확인
        if(!await callConfirm('선택된 행을 삭제 하시겠습니까?')) return;

        const {releaseAskSeq} = ReleaseAskDetailStore.releaseAskDetail;
        await axios.put('releaseAskItem/delete', {releaseAskItemList: selectedList});
        getReleaseAskItemList(releaseAskSeq);

        showToast('삭제되었습니다.');
    }

    /* 계약 조회 팝업 */
    const openContractSearchPopup = () =>{
        AppStore.toggleContractSearchModal();
    }
    /* 계약 조회 팝업 콜백 */
    const callbackContractInfo = (info) => {

        ReleaseAskDetailStore.setReleaseAskDetail({contractNo: info.contractNo, contractType: info.contractType, saleUserSeq: info.saleUserSeq
            , saleUserName: info.saleUserName, supportUserSeq: info.supportUserSeq, supportUserName: info.supportUserName
            , releaseAskDate: today(), customerName: info.customerName, customerNo: info.customerNo});

        getLastReleaseAskInfo(info.contractNo)

    }

    /* 기타출고 등록에서 계약 번호로 마지막 출고 배송 정보 조회 */
    const getLastReleaseAskInfo = async (contractNo) => {
        const result = await axios.get('releaseAsk/last/info', { params: {contractNo: contractNo} });
        ReleaseAskDetailStore.setReleaseAskDetail({phoneNumber: result.phoneNumber, shipType: result.shipType, receiveUserDept: result.receiveUserDept
                                                , receiveUserName: result.receiveUserName, receiveUserPosition: result.receiveUserPosition, telephone: result.telephone});
        setPostcode(result.postCode);
        setAddress(result.address);
        setAddressDetail(result.addressDetail);
    }

    /* 출고제품 조회 팝업 버튼 커스텀 */
    const customReleaseItemBtn = {
        isUsed: Number(ReleaseAskDetailStore.releaseAskDetail.releaseAskType) > 1 && ['','1','2'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) && ReleaseAskDetailStore.releaseAskDetail.releaseAskType !== 4,
        callbackFn:() => AppStore.toggleStockProductSearchModal(),
        icon: ICON_ADD,
        title: '출고제품 조회',
        width: 120,
    }

    const addRowInfo = {releaseAskSeq: '', seq: '', productCode: '', productName: '', model: '', qty: '', assetNo: '', storageNo: '',
                        stockQty: '', serialNo: '', spec: '', remark: ''};

    /* 출고 제품 조회 팝업 콜백 */
    const callbackStockProductSearch = (info) => {
        if (LUtils.isEmpty(info)) {
            return false;
        }

        if(selectAssetList) {
            selectAssetList.filter(v => v.agId === ReleaseAskDetailStore.selectItemAgId).map(v => {
                v.changeAssetNo = info[0].assetNo,
                // v.changeProductCode = info[0].productCode,
                v.changeProductSeq = info[0].productSeq,
                v.changeProductName = info[0].productName,
                v.changeModel = info[0].model,
                v.changeStorageNo = info[0].storageNo,
                v.changeStorageSeq = info[0].storageSeq,
                v.changeSerialNo = info[0].serialNo,
                v.changeSpec = info[0].spec
                v.qty = 1
            });
            returnGridRef.current.api.redrawRows();
        }else {
            const data = info[0];
            if(ReleaseAskDetailStore.releaseAskItemList.some(v => v.assetNo === data.assetNo)){
                showToast('이미 추가된 장비입니다.');
                return;
            }
    
            const result = ReleaseAskDetailStore.releaseAskItemList.map(v => {
                if (v.agId === ReleaseAskDetailStore.selectItemAgId) {
                    v.preAssetNo = v.assetNo;
                    v.productName = data.productName;
                    v.model = data.model;
                    v.assetNo = data.assetNo;
                    v.managementNo = data.managementNo;
                    v.serialNo = data.serialNo;
                    v.spec = data.spec;
                    v.productSeq = data.productSeq;
                    v.apronSeq = data.apronSeq;
                    v.apronName = data.apronName;
                    LUtils.assign(v, {isUpdated: true });
                }
                return v;
            });

            ReleaseAskDetailStore.setReleaseAskItemList(result);
            ReleaseAskDetailStore.selectItemAgId = null;
        }
    }

    //고객 담당자 콜백
    const callbackCustomerManager =(data)=> {
        if(!data) return;

        ReleaseAskDetailStore.releaseAskDetail.telephone = data.telephone;
        ReleaseAskDetailStore.releaseAskDetail.phoneNumber = encrypt(data.phoneNumber);
        ReleaseAskDetailStore.releaseAskDetail.receiveUserDept = data.department;
        ReleaseAskDetailStore.releaseAskDetail.receiveUserPosition = data.jobPosition;
        ReleaseAskDetailStore.releaseAskDetail.receiveUserName = data.managerName;
    }

    /* 수량 변경 시 재고 > 수량 체크 */
    const handleItemQtyEdit = (e) => {

        if(e.data.availableQty < e.newValue) {
            showToast('입력 수량이 재고 수량보다 클 수 없습니다.');
            e.data.qty = e.oldValue;
        } else {
            e.data.qty = e.newValue;
        }
        gridRef.current.api.redrawRows();
    }

    /* 출고의뢰서 print */
    const releaseAskPrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: ReleaseAskDetailStore.releaseAskDetail.releaseAskSeq,
    });

    const changeReleaseAskType =(e)=> {
        const value = e.target.value;
        if(value === '1'){
            showToast('일반출고는 사용할 수 없습니다.');
            return;
        }

        ReleaseAskDetailStore.releaseAskDetail.releaseAskType = value;
    }

    const inputModifyDisableHandler =()=> {
        let isDisable = false;
        //현재 의뢰상태가 등록되었고 출고대기보다 크면 (출고접수) 수정불가
        if(['6','9'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus)){
            isDisable = true;
        }

        setIsDisable(isDisable);

        if([1,9,10].includes(getUserAuthGroup())){
            setIsTechManager(true);
        }
    }

    const resetCheckAsset =async()=> {
        const resetItems = ReleaseAskDetailStore.releaseAskItemList.filter(v => v.isSelected);
        if(resetItems.length === 0){
            showToast('초기화할 제품을 선택해 주세요.');
            return;
        }

        if(resetItems.some(v => !v.isChecked)){
            showToast(`검수 완료된 제품만 초기화 할 수 있습니다.`);
            return;
        }

        if(!await callConfirm('초기화 하시겠습니까?')) return;

        await axios.put('/releaseAskItem/check/reset', {releaseAskItemList: resetItems});
        showToast('초기화 되었습니다.');
        //재조회
        await getReleaseAskItemList(releaseAskSeq)
    }

    /* 프린트 파일 체크 */
    const checkPdf = () => {
        let validData = 0;

        if (!isPrintAll && checkedList.length === 0) {
            showToast('출력하실 문서를 선택해주세요.');
            return;
        }

        checkedList.map(v => {
            if (v === 'pdfType1' || v === 'pdfType8' || v === 'pdfType9') {
                validData ++;
            }
            ReleaseAskDetailStore.releaseAskItemList.map(item => {
                if(item.isOption === 0) {
                    // pdfType1: 안전점검결과서, pdfType2: 보험증권, pdfType3: 제원표, pdfType4: 안전인증서, pdfType5: 자산안전인증서, pdfType6: 작동방법설명서, pdfType7: 비상하강, pdfType8: 반입전 체크리스트, pdfType9: 계약서, pdfType10: 사업자등록증, pdfType11: 통장사본,
                    if (v === 'pdfType2') {
                        item.insurancePolicyUrl != null && item.insurancePolicyUrl != '' ? validData ++ : '';
                    } else if (v === 'pdfType3') {
                        item.specificationTableUrl != null && item.specificationTableUrl != '' ? validData ++ : '';
                    } else if (v === 'pdfType4') {
                        item.safetyCertificateUrl != null && item.safetyCertificateUrl != '' ? validData ++ : '';
                    } else if (v === 'pdfType5') {
                        item.assetCertificateUrl != null && item.assetCertificateUrl != '' ? validData ++ : '';
                    } else if (v === 'pdfType6') {
                        item.workingMethodUrl != null && item.workingMethodUrl != '' ? validData ++ : '';
                    } else if (v === 'pdfType7') {
                        item.emergencyDownUrl != null && item.emergencyDownUrl != '' ? validData ++ : '';
                    }
                }
            })
        })

        if (validData > 0) {
            return true;
        } else {
            showToast('출력할 문서가 존재하지 않습니다.');
            return false;
        }
    }

    /* 메일 출력 버튼 이벤트 */
    const printPdf = () => {

        if(checkPdf()){
            print();
        }
    }

    const print = useReactToPrint({
        content: () => componentPrintRef.current,
    });

    /* 문서 종류 체크 핸들러 */
    const checkedHandler = (e, value, isMail=false) => {
        if(isMail) {
            setIsChecked(!isChecked);
            checkedItemHandler(value, e.target.checked, true);
        } else {
            setIsChecked(!isChecked);
            checkedItemHandler(value, e.target.checked);
        }
    }

    /* 체크박스 핸들러 */
    const checkedItemHandler = (value, isChecked, isMail=false) => {
        if(isMail) {
            if (isChecked) {
                setMailCheckedList((prev) => [...prev, value]);
                return;
            }

            if (!isChecked && mailCheckedList.includes(value)) {
                setMailCheckedList(mailCheckedList.filter((item) => item !== value));
                return;
            }
        } else {
            if (isChecked) {
                setCheckedList((prev) => [...prev, value]);
                return;
            }

            if (!isChecked && checkedList.includes(value)) {
                setCheckedList(checkedList.filter((item) => item !== value));
                return;
            }
        }
    }

    const modifyReturnAskInfo = (e) => {
        setIsReturnAskInfo(e.target.checked);
        ReleaseAskDetailStore.setReleaseAskDetail({isReturnAskInfo: e.target.checked});
        if(e.target.checked) { // 배송 정보를 회수 정보로 세팅
            ReleaseAskDetailStore.returnSetting();
            setReturnPostcode(ReleaseAskDetailStore.releaseAskDetail.postCode);
            setReturnAddress(ReleaseAskDetailStore.releaseAskDetail.address);
            setReturnAddressDetail(ReleaseAskDetailStore.releaseAskDetail.addressDetail);
        }else {
            ReleaseAskDetailStore.setReleaseAskDetail({returnAskRemark: ''});
        }
    }

    const insertReturnDeliveryInfo = () => {
        ReleaseAskDetailStore.returnSetting();
        setReturnPostcode(ReleaseAskDetailStore.releaseAskDetail.postCode);
        setReturnAddress(ReleaseAskDetailStore.releaseAskDetail.address);
        setReturnAddressDetail(ReleaseAskDetailStore.releaseAskDetail.addressDetail);
    }

    const getFileType = (url) => {
        let fileType = '';

        if (url) {
            const match = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
            fileType = match ? '.'+match[1] : '';  // 확장자가 없으면 빈 문자열
        }
        return fileType;
    }

    /* 출력 문서 체크 */
    const checkDocument = () => {
        let list = [];

        if (!isSendAll && mailCheckedList.length === 0) {
            showToast('전송하실 문서를 선택해주세요.');
            return;
        }

        // pdf url 먼저 담아주기
        mailCheckedList.map(v => {
            if (v === "pdfType2" && ReleaseAskDetailStore.releaseAskItemList[0].insurancePolicyUrl != null && ReleaseAskDetailStore.releaseAskItemList[0].insurancePolicyUrl != '') {
                list.push({documentType: 'insurancePolicy', urlFile: ReleaseAskDetailStore.releaseAskItemList[0]?.insurancePolicyUrl, urlFileName: "보험증권"+getFileType(ReleaseAskDetailStore.releaseAskItemList[0]?.insurancePolicyUrl), model: ReleaseAskDetailStore.releaseAskItemList[0].model});
            }
            if (v === "pdfType10" && ReleaseAskDetailStore.releaseAskItemList[0].businessLicenseUrl != null && ReleaseAskDetailStore.releaseAskItemList[0].businessLicenseUrl != '') {
                list.push({documentType: 'businessLicense', urlFile: ReleaseAskDetailStore.releaseAskItemList[0]?.businessLicenseUrl, urlFileName: "사업자등록증"+getFileType(ReleaseAskDetailStore.releaseAskItemList[0]?.businessLicenseUrl), model: ReleaseAskDetailStore.releaseAskItemList[0].model});
            }
            if (v === "pdfType11" && ReleaseAskDetailStore.releaseAskItemList[0].bankbookImageUrl != null && ReleaseAskDetailStore.releaseAskItemList[0].bankbookImageUrl != '') {
                list.push({documentType: 'bankbookImage', urlFile: ReleaseAskDetailStore.releaseAskItemList[0]?.bankbookImageUrl, urlFileName: "통장사본"+getFileType(ReleaseAskDetailStore.releaseAskItemList[0]?.bankbookImageUrl), model: ReleaseAskDetailStore.releaseAskItemList[0].model});
            }
            // pdfType12부터 시작되는 동적으로 추가된 URL 파일 처리
            if (v.startsWith("pdfType") && parseInt(v.replace('pdfType', '')) >= 12) {
                const seq = parseInt(v.replace('pdfType', '')) - 12; 
                
                if (ReleaseAskDetailStore.documentList[seq]) {
                    const doc = ReleaseAskDetailStore.documentList[seq]; // 해당하는 seq에 맞는 문서만 선택
                    list.push({
                        documentType: 'etc.',
                        urlFile: doc.url,
                        urlFileName: `${doc.fileName}.${doc.fileType}`,
                        model: ReleaseAskDetailStore.releaseAskItemList[0].model
                    });
                }
            }

            {/* 자산별 */}
            if (v === 'pdfType5') {
                {ReleaseAskDetailStore.releaseAskItemList.map((data) => {
                    if (data.isOption === 0 && data.assetCertificateUrl != null && data.assetCertificateUrl != '')
                    list.push({documentType: 'assetCertificate', urlFile: data.assetCertificateUrl, urlFileName: "안전인증서_"+data.model+getFileType(data.assetCertificateUrl), model: data.model});
                })}
            }

            {/* 모델별 1부씩 필요 */}
            {ReleaseAskDetailStore.releaseAskItemList && ReleaseAskDetailStore.releaseAskItemList.filter((val, idx) => {
                return (ReleaseAskDetailStore.releaseAskItemList.findIndex((data1) => {
                    return val.model === data1.model
                }) === idx
            )
            }).map((data) => {
                if (data.isOption === 0){
                    switch (v) {
                        case "pdfType3": if(data.specificationTableUrl !== null && data.specificationTableUrl !== '') {
                            list.push({documentType: 'specificationTable', urlFile: data.specificationTableUrl, urlFileName: "제원표_"+data.model+getFileType(data.specificationTableUrl), model: data.model});
                        } break;
                        case "pdfType4": if(data.safetyCertificateUrl !== null && data.safetyCertificateUrl !== '') {
                            list.push({documentType: 'safetyCertificate', urlFile: data.safetyCertificateUrl, urlFileName: "안전인증서_"+data.model+getFileType(data.safetyCertificateUrl), model: data.model});
                        } break;
                        // case "pdfType5": if(data.assetCertificateUrl !== null && data.assetCertificateUrl !== '') {
                        //     list.push({documentType: 'assetCertificate', urlFile: data.assetCertificateUrl, urlFileName: "안전인증서_"+data.model+getFileType(data.assetCertificateUrl), model: data.model});
                        // } break;
                        case "pdfType6": if(data.workingMethodUrl !== null && data.workingMethodUrl !== '') {
                            list.push({documentType: 'workingMethod', urlFile: data.workingMethodUrl, urlFileName: "작동방법설명서_"+data.model+getFileType(data.workingMethodUrl), model: data.model});
                        } break;
                        case "pdfType7": if(data.emergencyDownUrl !== null &&  data.emergencyDownUrl !== '') {
                            list.push({documentType: 'emergencyDown', urlFile: data.emergencyDownUrl, urlFileName: "비상하강_"+data.model+getFileType(data.emergencyDownUrl), model: data.model});
                        } break;
                    }
                }
            })}
            // // pdfType12부터 시작되는 동적으로 추가된 URL 파일 처리
            // if (v.startsWith("pdfType") && parseInt(v.replace('pdfType', '')) >= 12) {
            //     ReleaseAskDetailStore.documentList.forEach((doc, index) => (
            //         list.push({ documentType: 'etc.', urlFile: doc.url, urlFileName: `${doc.fileName}.${doc.fileType}`, model: ReleaseAskDetailStore.releaseAskItemList[0].model })
            //     ))
            // }
        })

        

        if (list.length > 0 || ['pdfType1', 'pdfType8', 'pdfType9'].filter(x => mailCheckedList.includes(x)).length > 0) {
            return list;
        } else {
            showToast('전송할 문서가 존재하지 않습니다.');
            return false;
        }
    }

    /* 이메일 문서 템플릿 생성 및 전송 */
    const makeReleaseAskDocument = async () => {
        let docFileList = checkDocument();

        if (!docFileList) {
            return false;
        }

        let formData = new FormData();
        let i = 0;
        let label = [];

        if (emailSubject === '') {
            label.push('메일 제목');
        }
        if (emailContent === '') {
            label.push('메일 내용');
        }
        if (receiverEmail === '') {
            label.push('받는 사람');
        }

        if(label.length > 0) {
            showToast(label.join(", ") + "을 입력해주세요.");
            return;
        }

        if(!await callConfirm('전송하시겠습니까?')) {
            return;
        }

        // 계약서, 안전점검결과서, 반입전체크리스트 html 템플릿 생성
        const result = await makeReleaseAskPrintTemplate(mailCheckedList);
        for (const data of result) {
            const options = {
                fileName: data.fileName,
                image: {type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            }

            const body = document.querySelector('body');

            body.style.lineHeight = '0.3';

            const dd = body.getElementsByClassName('contractBody');
            dd.forEach (v => {
                v.style.lineHeight=1;
            })

            const pdfBlob = await html2pdf().set(options).from(data.content).toPdf().output('blob').then(data => {
                return data
            });
            await deleteStyle(body);

            const blob = new Blob([pdfBlob], {type: 'application/pdf'});
            const file = new File([blob], data.fileName);

            formData.append("fileList["+i+"].documentType", data.documentType);
            formData.append('fileList['+i+'].file', file);
            formData.append('fileList['+i+'].urlFileName', data.fileName);
            i += 1;
        }

        for (const data of docFileList) {
            formData.append("fileList["+i+"].documentType", data.documentType);
            formData.append("fileList["+i+"].urlFile", data.urlFile);
            formData.append("fileList["+i+"].urlFileName", data.urlFileName);
            i += 1;
        }

        formData.append("subject", emailSubject);
        formData.append("content", emailContent);
        formData.append("receiver", receiverEmail);
        const msg = await axios.post('/releaseAsk/send/document', formData, { headers: MULTIPART_HEADER });

        if (msg === 'success') {
            showToast('전송되었습니다.');
        }
    }

    const deleteStyle = async (body) => {
        body.style.lineHeight = 'normal';
    }

    const printGroupByDocument = () => {
        let docFileList = checkDocument();

        if (!docFileList) {
            return false;
        } else {
            printGroupBy();
        }
    }

    const printGroupBy = useReactToPrint({
        content: () => groupByPrintRef.current,
    });

    const refresh = () => {
        ReleaseAskDetailStore.initDocument();
        setEmailContent('');
        setEmailSubject('');
        setReceiverEmail('');
    }

    /* 출고제품 문서 가져오기 */
    const getDocument = async (releaseAskSeq) => {
        const result = await axios.get(`releaseAsk/document/${releaseAskSeq}`);
        ReleaseAskDetailStore.documentList = result;
        rows();
    }

    const generateCheckboxes = () => {
        return ReleaseAskDetailStore.documentList.map((doc, index) => (
            <CCol lg={6} key={index}>
                <label style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                    <Form.Check
                        type="checkbox"
                        id={`pdfType${index + 12}`}
                        checked={mailCheckedList.includes(`pdfType${index + 12}`)}
                        onChange={v => checkedHandler(v, `pdfType${index + 12}`, true)}
                        style={{ verticalAlign: 'middle', marginRight: 10, marginLeft: 10 }}
                    />
                    <span>{doc.fileName + '(메일용)'}</span>
                </label>
            </CCol>
        ));
    };

    const rows = () => {
        const checkboxes = generateCheckboxes();
        const rows = [];

        for (let i = 0; i < checkboxes.length; i += 6) {
            rows.push(
                <React.Fragment key={`row-${i}`}> 
                    {checkboxes.slice(i, i + 6)}
                </React.Fragment>
            );
        }
        return rows;
    };

    const releaseShipComplete =()=> {
        AppStore.toggleModal();
        callBackFn && callBackFn();
    }

    return(
        <>
            {/* 검색조건 */}
            <CContainer search>
                <Subject>의뢰정보</Subject>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'출고의뢰번호'}
                                     labelId={'releaseAskSeq'}
                                     disabled={true}
                                     value={ReleaseAskDetailStore.releaseAskDetail.releaseAskSeq || ''}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup label={'출고의뢰상태'} labelId={'releaseAskStatus'}
                                      placeholder={'출고의뢰상태'}
                                      options={[{name: '작성중', value: ''}].concat(getCommcode('5'))}
                                      value={ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus}
                                      disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup label={'출고의뢰구분'} labelId={'releaseAskType'}
                                      placeholder={'출고의뢰구분'}
                                      options={getCommcode('39')}
                                      value={ReleaseAskDetailStore.releaseAskDetail.releaseAskType || ''}
                                      onChange={changeReleaseAskType}
                                      disabled={true}
                                      labelClassName="input-required"
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CalendarGroup asSingle={true}  label={'출고의뢰일'}
                                       value={{
                                            startDate: ReleaseAskDetailStore.releaseAskDetail.releaseAskDate,
                                            endDate: ReleaseAskDetailStore.releaseAskDetail.releaseAskDate
                                        }}
                                       disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'의뢰자명'}
                                     labelId={'creatorName'}
                                     disabled={true}
                                     value={ReleaseAskDetailStore.releaseAskDetail.creatorName || ''}
                        />
                    </CCol>
                    {ReleaseAskDetailStore.releaseAskDetail.releaseAskType !== 4 && (
                        <>
                            <CCol lg={3}>
                                <CInputGroup label={'계약번호'}
                                            labelId={'contractNo'}
                                            disabled={true}
                                            value={ReleaseAskDetailStore.releaseAskDetail.contractNo || ''}
                                            onCallbackBtn={isEtcReleaseAsk
                                                && (!ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus || ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '1') ? openContractSearchPopup : false}
                                            onChange={v => ReleaseAskDetailStore.releaseAskDetail.contractNo = v.target.value}
                                            labelClassName={'input-required'}
                                />
                            </CCol>
                            <CCol lg={3}>
                                {/* <CSelectGroup label={'계약유형'} labelId={'contractType'}
                                            placeholder={'계약유형'}
                                            options={getCommcode('1')}
                                            value={ReleaseAskDetailStore.releaseAskDetail.contractType}
                                            disabled={true}
                                /> */}
                                <CSelectGroup label={'계약유형'} labelId={'contractType'}
                                            placeholder={'계약유형'}
                                            options={[{name: '전체', value: ''}].concat(getCommcode('1'))}
                                            value={ReleaseAskDetailStore.releaseAskDetail.contractType || ''}
                                            disabled={true}
                                />
                            </CCol>
                            <CCol lg={2}>
                                <CInputGroup label={'영업담당자'} labelId={'saleUserName'}
                                            value={ReleaseAskDetailStore.releaseAskDetail.saleUserName || ''}
                                            disabled={true}
                                />
                            </CCol>
                            <CCol lg={2}>
                                <CInputGroup label={'주기장'} labelId={'apronName'}
                                            value={ReleaseAskDetailStore.releaseAskDetail.apronName || ''}
                                            disabled={true}
                                />
                            </CCol>
{/*                            <CCol lg={2}>
                                <CInputGroup label={'지원담당자'} labelId={'supportUserName'}
                                            value={ReleaseAskDetailStore.releaseAskDetail.supportUserName}
                                            disabled={true}
                                />
                            </CCol>*/}
                        </>
                    )}
                </Row>
                <hr/>
                <Row>
                    <CCol lg={6}>
                        <div className="mb-3">
                            <label className="form-label">출고의뢰비고</label>
                            <textarea className="form-control" rows="3"
                                      value={ReleaseAskDetailStore.releaseAskDetail.releaseAskRemark || ''}
                                      disabled={isDisable}
                                      onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({releaseAskRemark: v.target.value})}
                            />
                        </div>
                    </CCol>
                    {ReleaseAskDetailStore.releaseAskDetail.contractType === '1' &&
                        <CCol lg={6}>
                            <div className="mb-3">
                                <label className="form-label">회수의뢰비고</label>
                                <textarea className="form-control" rows="3"
                                          value={ReleaseAskDetailStore.releaseAskDetail.returnAskRemark || ''}
                                          disabled={isDisable}
                                          onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnAskRemark: v.target.value})}
                                />
                            </div>
                        </CCol>
                    }
                </Row>
                {ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease !== 1 &&
                    <>
                        {ReleaseAskDetailStore.releaseAskDetail.siteName &&
                            <Row>
                                <Subject>현장정보</Subject>
                                <CCol lg={4}>
                                    <CInputGroup label={'현장명'}
                                                labelId={'siteName'}
                                                value={ReleaseAskDetailStore.releaseAskDetail.siteName || ''}
                                                disabled={true}
                                    />
                                </CCol>
                            </Row>
                        }
                        <Row>
                            <Subject>배송정보</Subject>
                            <CCol lg={3}>
                                <CInputGroup label={'고객명'} labelId={'customerName'}
                                                disabled={true}
                                                value={ReleaseAskDetailStore.releaseAskDetail.customerName || ''}
                                />
                            </CCol>
                            <CCol lg={4}>
                                <InputGroup size={'sm'}>
                                    <InputGroup.Text>사무실</InputGroup.Text>
                                    <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'telephone'}
                                                placeholder={'전화번호'}
                                                value={localAutoHyphen(ReleaseAskDetailStore.releaseAskDetail.telephone) || ''}
                                                disabled={isDisable}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({telephone: v.target.value})}
                                    />
                                    <InputGroup.Text className='input-required'>휴대폰</InputGroup.Text>
                                    <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'phoneNumber'}
                                                value={autoHyphen(decrypt(ReleaseAskDetailStore.releaseAskDetail.phoneNumber)) || ''}
                                                disabled={isDisable}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({phoneNumber: encrypt(v.target.value)})}
                                    />
                                </InputGroup>
                            </CCol>
                            <CCol lg={5}>
                                <InputGroup size={'sm'}>
                                    <InputGroup.Text>부서명</InputGroup.Text>
                                    <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'receiveUserDept'}
                                                value={ReleaseAskDetailStore.releaseAskDetail.receiveUserDept || ''}
                                                disabled={isDisable}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({receiveUserDept: v.target.value})}
                                    />
                                    <InputGroup.Text>직급</InputGroup.Text>
                                    <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'receiveUserPosition'}
                                                value={ReleaseAskDetailStore.releaseAskDetail.receiveUserPosition || ''}
                                                disabled={isDisable}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({receiveUserPosition: v.target.value})}
                                    />
                                    <InputGroup.Text className="input-required">받는분</InputGroup.Text>
                                    <Form.Control style={{flex: 2, textAlign: 'left'}} aria-describedby={'receiveUserName'}
                                                value={ReleaseAskDetailStore.releaseAskDetail.receiveUserName || ''}
                                                disabled={isDisable}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({receiveUserName: v.target.value})}
                                    />
                                    <Button variant="outline-secondary" style={{borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={()=> AppStore.toggleCustomerManagerSearchPopup()} disabled={isDisable}>
                                        <i className="me-1 fi fi-br-search" />
                                    </Button>
                                </InputGroup>
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={7} style={{marginTop: 12}}>
                                <Row>
                                    <CCol lg={5}>
                                        <CalendarGroup asSingle={true}  label={'배송희망일'}
                                                    value={{
                                                            startDate: ReleaseAskDetailStore.releaseAskDetail.shipHopeDate,
                                                            endDate: ReleaseAskDetailStore.releaseAskDetail.shipHopeDate
                                                        }}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipHopeDate: dateFormat(v.startDate)})}
                                                    //minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                                    labelClassName="input-required"
                                                    disabled={isDisable}
                                        />
                                    </CCol>
                                    <CCol lg={3}>
                                        <CSelectGroup label={'시간'} labelId={'shipHopeTime'}
                                                      options={[{name: '선택', value: ''}].concat(getCommcode('29'))}
                                                    value={ReleaseAskDetailStore.releaseAskDetail.shipHopeTime || '3'}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipHopeTime: v.target.value})}
                                                    disabled={isDisable}
                                                    labelClassName="input-required"
                                        />
                                    </CCol>
                                    <CCol lg={4}>
                                        <CSelectGroup label={'배송방법'} labelId={'shipType'}
                                                    placeholder={'배송방법'}
                                                    options={[{name: '선택', value: ''}].concat(getCommcode('4'))}
                                                    value={ReleaseAskDetailStore.releaseAskDetail.shipType || ''}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipType: v.target.value})}
                                                    disabled={isDisable}
                                                    labelClassName="input-required"
                                        />
                                    </CCol>
                                </Row>
                                <Row>
                                    <CCol lg={12} md={12}>
                                        <Address label='주소'
                                                setPostcode={setPostcode}
                                                setAddress={setAddress}
                                                setAddressDetail={setAddressDetail}
                                                postcode={postcode}
                                                address={address}
                                                addressDetail={addressDetail}
                                                disabled={isDisable}
                                                addressDisabled={isDisable}
                                                // labelClassName='input-required'
                                        />
                                    </CCol>
                                </Row>
                            </CCol>
                            <CCol lg={5}>
                                <div className="mb-3">
                                    <label className="form-label">배송 비고</label>
                                    <textarea className="form-control" rows="2"
                                            value={ReleaseAskDetailStore.releaseAskDetail.shipRemark || ''}
                                            disabled={isDisable}
                                            placeholder={'배송 상세 시간 / 배송 요청 사항'}
                                            onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipRemark: v.target.value})}
                                    />
                                </div>
                            </CCol>
                        </Row>
                    </>
                }
                <Form.Check
                    type="switch"
                    id="isReturnAskInfo"
                    label="회수정보입력"
                    style={{ marginTop: 4 }}
                    checked={isReturnAskInfo}
                    onChange={(e) => modifyReturnAskInfo(e)}
                    disabled={isDisable}
                />
                {ReleaseAskDetailStore.releaseAskDetail.contractType === '1' && (
                    isReturnAskInfo &&
                    <>
                        <Row className="align-items-center">
                            <CCol lg={1}>
                                <Subject>회수정보</Subject>
                            </CCol>
                            <CCol lg={3}>
                                <a className="text-decoration-none" href="#!" style={{fontSize: 16}} onClick={() => insertReturnDeliveryInfo()}>배송정보와 동일하게 적용</a>
                            </CCol>
{/* 
                            <CIconBtn style={{width: 110}}
                                title={'배송정보 동일'}
                                onClick={() => insertReturnDeliveryInfo()}
                                variant={'primary'}
                                disabled={isDisable}
                            /> */}
                        </Row>
                        <>
                            <Row>
                                <CCol lg={3}>
                                    <CInputGroup label={'고객명'} labelId={'customerName'}
                                                    disabled={true}
                                                    value={ReleaseAskDetailStore.releaseAskDetail.customerName}
                                    />
                                </CCol>
                                <CCol lg={4}>
                                    <InputGroup size={'sm'}>
                                        <InputGroup.Text>사무실</InputGroup.Text>
                                        <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'returnTelephone'}
                                                    placeholder={'전화번호'}
                                                    value={localAutoHyphen(ReleaseAskDetailStore.releaseAskDetail.returnTelephone) || ''}
                                                    disabled={isDisable}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnTelephone: v.target.value})}
                                        />
                                        <InputGroup.Text className='input-required'>휴대폰</InputGroup.Text>
                                        <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'returnPhoneNumber'}
                                                    value={autoHyphen(decrypt(ReleaseAskDetailStore.releaseAskDetail.returnPhoneNumber)) || ''}
                                                    disabled={isDisable}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnPhoneNumber: encrypt(v.target.value)})}
                                        />
                                    </InputGroup>
                                </CCol>
                                <CCol lg={5}>
                                    <InputGroup size={'sm'}>
                                        <InputGroup.Text>부서명</InputGroup.Text>
                                        <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'returnReceiveUserDept'}
                                                    value={ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserDept || ''}
                                                    disabled={isDisable}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnReceiveUserDept: v.target.value})}
                                        />
                                        <InputGroup.Text>직급</InputGroup.Text>
                                        <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'returnReceiveUserPosition'}
                                                    value={ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserPosition || ''}
                                                    disabled={isDisable}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnReceiveUserPosition: v.target.value})}
                                        />
                                        <InputGroup.Text className="input-required">받는분</InputGroup.Text>
                                        <Form.Control style={{flex: 2, textAlign: 'left'}} aria-describedby={'returnReceiveUserName'}
                                                    value={ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserName || ''}
                                                    disabled={isDisable}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnReceiveUserName: v.target.value})}
                                        />
                                    </InputGroup>
                                </CCol>
                            </Row>
                            <Row>
                                <CCol lg={7} style={{marginTop: 12}}>
                                    <Row>
                                        <CCol lg={5}>
                                            <CalendarGroup asSingle={true}  label={'회수희망일'}
                                                        value={{
                                                                startDate: ReleaseAskDetailStore.releaseAskDetail.returnShipHopeDate,
                                                                endDate: ReleaseAskDetailStore.releaseAskDetail.returnShipHopeDate
                                                            }}
                                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnShipHopeDate: dateFormat(v.startDate)})}
                                                        minDate={ReleaseAskDetailStore.releaseAskDetail.shipHopeDate}
                                                        labelClassName="input-required"
                                                        disabled={isDisable}
                                            />
                                        </CCol>
                                        <CCol lg={3}>
                                            <CSelectGroup label={'시간'} labelId={'returnShipHopeTime'}
                                                            options={[{name: '선택', value: ''}].concat(getCommcode('29'))}
                                                        value={ReleaseAskDetailStore.releaseAskDetail.returnShipHopeTime || '6'}
                                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnShipHopeTime: v.target.value})}
                                                        disabled={isDisable}
                                                        labelClassName="input-required"
                                            />
                                        </CCol>
                                        <CCol lg={4}>
                                            <CSelectGroup label={'회수방법'} labelId={'returnShipType'}
                                                        placeholder={'회수방법'}
                                                        options={[{name: '선택', value: ''}].concat(getCommcode('4'))}
                                                        value={ReleaseAskDetailStore.releaseAskDetail.returnShipType || ''}
                                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnShipType: v.target.value})}
                                                        disabled={isDisable}
                                                        labelClassName="input-required"
                                            />
                                        </CCol>
                                    </Row>
                                    <Row>
                                        <CCol lg={12} md={12}>
                                            <Address label='주소'
                                                    setPostcode={setReturnPostcode}
                                                    setAddress={setReturnAddress}
                                                    setAddressDetail={setReturnAddressDetail}
                                                    postcode={returnPostcode}
                                                    address={returnAddress}
                                                    addressDetail={returnAddressDetail}
                                                    disabled={isDisable}
                                                    addressDisabled={isDisable}
                                            />
                                        </CCol>
                                    </Row>
                                </CCol>
                                <CCol lg={5}>
                                    <div className="mb-3">
                                        <label className="form-label">회수 배송 비고</label>
                                        <textarea className="form-control" rows="2"
                                                value={ReleaseAskDetailStore.releaseAskDetail.returnShipRemark || ''}
                                                disabled={isDisable}
                                                placeholder={'배송 상세 시간 / 배송 요청 사항'}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnShipRemark: v.target.value})}
                                        />
                                    </div>
                                </CCol>
                            </Row>
                        </>
                    </>
                    
                )}
                {/* <hr style={{color: '#c2c2c2'}}/> */}
                <hr/>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        {(['1', '2', '3', '4', '5'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) && (ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease !== 1) || (ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '6' && ReleaseAskDetailStore.releaseAskDetail.shipStatus === '2')) && (
                            <CCancelBtn style={{width: 110}}
                                        title={'출고취소'}
                                        onClick={() => saveReleaseAskStatus('9', '출고취소')}
                            />
                        )}
                        {ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '1' && (
                            <CIconBtn style={{width: 110}}
                                    title={'출고완료'}
                                    icon={'fi-rr-box-check'}
                                    variant={'primary'}
                                    onClick={() => saveReleaseAskStatus('6', '출고완료')}
                            />
                        )}
                     {/*   {isTechManager && ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '4' && (
                            <CIconBtn style={{width: 110}}
                                    title={'출고작업중'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => saveReleaseAskStatus('5', '출고작업중')}
                            />
                        )}
                        {isTechManager && ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '3' && (
                            <CIconBtn style={{width: 110}}
                                    title={'출고검수중'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => saveReleaseAskStatus('4', '출고검수')}
                            />
                        )}
                        {isTechManager && ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '2' && (
                            <CIconBtn style={{width: 110}}
                                    title={'출고접수'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => saveReleaseAskStatus('3', '출고접수')}
                            />
                        )}
                        {(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '1') && (
                            <CIconBtn style={{width: 110}}
                                    title={'출고의뢰'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => saveReleaseAskStatus('2', '출고의뢰')}
                            />
                        )}*/}
                        {ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease !== 1
                            && ((['1', '2'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) || ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus === '')
                            || (!['6','9'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) && (!ReleaseAskDetailStore.releaseAskDetail.creator || ReleaseAskDetailStore.releaseAskDetail.creator === userInfo.userSeq))) && (
                            <CSaveBtn onClick={saveReleaseAsk} style={{width: 80}} title={'저장'}/>
                        )}
                        {(ReleaseAskDetailStore.releaseAskDetail.releaseAskSeq && !['9'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) && (ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease !== 1)) && (
                            <>
                                <CIconBtn style={{width: 120}}
                                        title={'출고의뢰서'}
                                        icon={'fi-rr-box-check'}
                                        onClick={() => releaseAskPrint()}
                                />
                                <ReportReleaseAskTemplate  ref={componentRef} contractNo={ReleaseAskDetailStore.releaseAskDetail.contractNo} />
                            </>
                        )}
                    </Col>
                </Row>
                <Row>
                    {(ReleaseAskDetailStore.releaseAskDetail.releaseAskSeq && !['0', '9'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus) && (ReleaseAskDetailStore.releaseAskDetail.isVirtualRelease !== 1) && ReleaseAskDetailStore.releaseAskDetail.contractType !== '3') && (
                        <>
                        <Subject>출고제품문서 출력</Subject>
                        <Row>
                            <Col lg={9}>
                                <div className="btn-group btn-group-toggle pt-1">
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="radio" value={true} name="isPrintAll"
                                            checked={isPrintAll === true ? true : false}
                                            onChange={v => {
                                                // 출고문서 체크박스 전체 체크하기
                                                [...Array(parseInt(8))].forEach((n, index) => {
                                                    checkedList.push('pdfType'+Number(index+1));
                                                })
                                                setCheckedList(['pdfType1', 'pdfType2', 'pdfType3', 'pdfType4', 'pdfType5', 'pdfType6', 'pdfType7', 'pdfType8']);
                                                setIsPrintAll(v.target.value === 'true' ? true : false)
                                            }}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            disabled={false}
                                        />
                                        <span>전체 출력</span>
                                    </label>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="radio" value={false} name="isPrintAll"
                                            checked={isPrintAll === false ? true : false}
                                            onChange={v => {
                                                setCheckedList(['pdfType1', 'pdfType2', 'pdfType3', 'pdfType4', 'pdfType5', 'pdfType8']);
                                                setIsPrintAll(v.target.value === 'false' ? false : true)
                                            }}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            disabled={false}
                                        />
                                        <span>선택 출력</span>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col lg={12}>
                                <Row className="btn-group btn-group-toggle p-3 mt-3 border" style={{width: '100%', margin: 'auto'}}>
                                    <Col lg={12}>
                                        <Row style={{marginBottom: 15}}>
                                            <Col lg={2}>
                                                <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id='pdfType1'
                                                        checked={checkedList.includes('pdfType1')}
                                                        onChange={v => checkedHandler(v, 'pdfType1')}
                                                        style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                                        disabled={isPrintAll}
                                                    />
                                                    <span>안전점검결과서</span>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id='pdfType2'
                                                        checked={checkedList.includes('pdfType2')}
                                                        onChange={v => checkedHandler(v, 'pdfType2')}
                                                        style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                                        disabled={isPrintAll}
                                                    />
                                                    <span>보험증권</span>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id='pdfType3'
                                                        checked={checkedList.includes('pdfType3')}
                                                        onChange={v => checkedHandler(v, 'pdfType3')}
                                                        style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                                        disabled={isPrintAll}
                                                    />
                                                    <span>제원표</span>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id='pdfType4'
                                                        checked={checkedList.includes('pdfType4')}
                                                        onChange={v => checkedHandler(v, 'pdfType4')}
                                                        style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                                        disabled={isPrintAll}
                                                    />
                                                    <span>안전인증서(형식)</span>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id='pdfType5'
                                                        checked={checkedList.includes('pdfType5')}
                                                        onChange={v => checkedHandler(v, 'pdfType5')}
                                                        style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                                        disabled={isPrintAll}
                                                    />
                                                    <span>안전인증서(개별)</span>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id='pdfType6'
                                                        checked={checkedList.includes('pdfType6')}
                                                        onChange={v => checkedHandler(v, 'pdfType6')}
                                                        style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                                        disabled={isPrintAll}
                                                    />
                                                    <span>작동방법 설명서</span>
                                                </label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={2}>
                                                <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id='pdfType7'
                                                        checked={checkedList.includes('pdfType7')}
                                                        onChange={v => checkedHandler(v, 'pdfType7')}
                                                        style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                                        disabled={isPrintAll}
                                                    />
                                                    <span>비상하강</span>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id='pdfType8'
                                                        checked={checkedList.includes('pdfType8')}
                                                        onChange={v => checkedHandler(v, 'pdfType8')}
                                                        style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                                        disabled={isPrintAll}
                                                    />
                                                    <span>반입전 체크리스트</span>
                                                </label>
                                            </Col>
                                            <Col lg={2}>
                                                <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id='pdfType9'
                                                        checked={checkedList.includes('pdfType9')}
                                                        onChange={v => checkedHandler(v, 'pdfType9')}
                                                        style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                                        disabled={isPrintAll}
                                                    />
                                                    <span>계약서</span>
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pb-3">
                            <Col className='d-flex flex-row-reverse'>
                                <CIconBtn style={{width: 160}} icon={'fi fi-rs-paper-plane'} title={'제품문서 메일 전송'} onClick={() => setSendMailPopup(true)} variant={'primary'}/>
                                <CIconBtn style={{width: 160}} icon={'fi fi-rs-print'} title={'출고제품문서출력'} onClick={() => printPdf()} variant={'success'}/>
                                <ReportReleaseAskPrintTemplate ref={componentPrintRef} checkedList={checkedList} />
                            </Col>
                        </Row>
                    </>
                    )}
                </Row>
            </CContainer>
            {/* {(Number(ReleaseAskDetailStore.releaseAskDetail.releaseAskType) > 1 || (Number(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus)) > 1) && !selectAssetList &&  ( */}
            {(releaseAskSeq || ReleaseAskDetailStore.releaseAskDetail.releaseAskSeq) &&  (
                <CContainer>
                    <Subject>출고 제품 리스트</Subject>
                    <AgGridContainer
                        ref={etcReleaseGridRef}
                        gridRef={gridRef}
                        height={!selectAssetList ? 30 : 20}
                        rowData={ReleaseAskDetailStore.releaseAskItemList}
                        columnDefs={
                            [
                                {field: "releaseAskSeq", headerName: "출고순번", hide: true},
                                {field: "seq", headerName: "순번", width: 80},
                                {field: "contractProductSeq", headerName: "묶음번호", width: 110,},
                                {
                                    field: "isOption",
                                    headerName: "옵션(소모품)여부",
                                    width: 150,
                                    valueGetter: v => v.data.isOption === 1 ? 'Y' : ''
                                },
                                {field: "productSeq", headerName: "제품코드", width: 100, hide: true},
                                {field: "productName", headerName: "제품명", width: 150, hide: true},
                                {field: "model", headerName: "모델명", width: 200},
                                {field: "qty", headerName: "수량", width: 80},
                                {
                                    field: "managementNo",
                                    headerName: "관리번호",
                                    width: 140,
                                    cellRenderer: (e) => e.data.isOption === 0 && popupAssetInfo(e),
                                    headerClass: 'grid-column-required',
                                    //editable: false,
                                    hide: ReleaseAskDetailStore.releaseAskDetail.contractType === '3' || ReleaseAskDetailStore.releaseAskDetail.contractType === '4'
                                },
                                {
                                    field: "orgAssetNo",
                                    headerName: "자산번호",
                                    width: 120,
                                    hide: true,
                                },
                                {
                                    field: "assetNo",
                                    headerName: "자산번호",
                                    width: 120,
                                    hide: true,
                                },
                                {field: "serialNo", headerName: "시리얼번호", headerClass: 'grid-column-editable', width: 150, editable: !isDisable},
                                {field: "apronSeq", headerName: "주기장 순번", hide: true},
                                {field: "apronName", headerName: "주기장", width: 100},
                                {field: "spec", headerName: "스펙", width: 300},
                                {field: "remark", headerName: "비고", headerClass: 'grid-column-editable', width: 300, editable: !isDisable},
                                {
                                    field: "print",
                                    headerName: "인쇄",
                                    width: 100,
                                    cellRenderer: (v) => (
                                        <div>
                                            <IconBtn iconClass={'fi fi-rs-print'} className={'mt-3'} onClick={() => printPdf()}/>
                                        </div>
                                    ),
                                    hide: true
                                },
                                // {
                                //     field: 'insurancePolicyFileName',
                                //     headerName: "보험증권",
                                //     width: 200,
                                //     cellRenderer: (v) => (
                                //         <div style={{ display: 'flex', }}>
                                //             <div>
                                //                 {/* <Card imageId={17} title="usePDF hook example" /> */}
                                //             </div>
                                //             <IconBtn iconClass={'fi fi-rr-blog-pencil'} className={'mt-0'} title={' 파일 변경'} onClick={() => modifyFile(v.data.insurancePolicyUrl)} style={{width: 100, marginRight: 20}}/>
                                //             <IconBtn iconClass={'fi fi-br-search'} className={'mt-3'} onClick={() => openFileLink(v.data.insurancePolicyUrl)} style={{width: 40}}/>
                                //         </div>
                                //     ),
                                // },
/*                                {
                                    field: "isCancel",
                                    headerName: "취소여부",
                                    cellEditorParams: {
                                        values: [0, 1],
                                    },
                                    valueFormatter: function(params) {
                                        return params.value === 0 ? '' : '취소';
                                    },
                                    width: 120
                                },
                                {field: "cancelUserName", headerName: "취소자", width: 120},
                                {field: "cancelDate", headerName: "취소일자", valueGetter: v => dateTimeFormat(v.data.cancelDate), width: 180},*/
                            ]
                        }
                        isCheckBox={true}
                        originList={releaseAskItemList}
                        seqColumn={'seq'}
                        addRowInfo={addRowInfo}
                        // useUpdated={ReleaseAskDetailStore.releaseAskDetail.releaseAskType === '2' || ReleaseAskDetailStore.releaseAskDetail.releaseAskType === '1'}
                        useUpdated={!['6', '9'].includes(ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus)}
                        customBtnInfo={ReleaseAskDetailStore.releaseAskDetail.releaseAskType === '2' && [customCancelBtn, resetAssetCheckBtn, assetCheckPopup, customReleaseItemBtn]}
                        callBackGridData={callBackGridData}
                        getRowStyle={(params) => {
                            if (params.data.isCancel === 1) {
                                return { pointerEvents: 'none', color: '#bababa' };
                            }
                            return null;
                        }}
                    />
                    {/* 현장 옵션 정보 */}
                    {(ReleaseAskDetailStore.releaseAskDetail.contractType === '1' || ReleaseAskDetailStore.releaseAskDetail.contractType === '2') &&
                        <SiteOption optionList={ReleaseAskDetailStore.contractSiteOptionList} disabled={true} small={true}/>
                    }
                </CContainer>
            )}
            {selectAssetList &&
                <CContainer>
                    <Subject>회수 제품 리스트</Subject>
                    <AgGridContainer
                        height={20}
                        rowData={selectAssetList}
                        gridRef={returnGridRef}
                        columnDefs={
                            [
                                {field: "contractNo", headerName: "계약번호", width: 120},
                                {field: "assetNo", headerName: "자산번호", width: 110},
                                {field: "model", headerName: "모델명", width: 150},
                                {field: "assetStatusText", headerName: "계약상태", width: 110}, //1:신규, 2:연장, 3:단축, 4:교환, 5:단가변경
                                {field: "orgContractStartDate", headerName: "원개시일자", width: 120},
                                {field: "contractStartDate", headerName: "변경 개시일", width: 120},
                                {field: "contractEndDate", headerName: "계약종료일", width: 120},
                                {field: "changeProductName", headerName: "교환제품명", width: 150, cellRenderer: (e) => popupStockInfo(e), headerClass: 'grid-column-editable'},
                                // {field: "changeProductCode", headerName: "교환제품코드", width: 120, headerClass: 'grid-column-editable'},
                                {field: "changeProductSeq", headerName: "교환제품시퀀스", width: 120, hide: true},
                                {
                                    field: "changeAssetNo",
                                    headerName: "교환자산번호",
                                    width: 120,
                                    headerClass: 'grid-column-editable',
                                    editable: false,
                                },
                                {field: "changeModel", headerName: "교환모델명", width: 150, headerClass: 'grid-column-editable'},
                                {field: "changeStorageNo", headerName: "교환입고번호", width: 120, headerClass: 'grid-column-editable'},
                                {field: "changeStorageSeq", headerName: "교환입고순번", width: 120, hide: ReleaseAskDetailStore.releaseAskDetail.releaseAskType === 4, headerClass: 'grid-column-editable'},
                                {field: "changeSerialNo", headerName: "교환시리얼번호", headerClass: 'grid-column-editable', width: 150},
                                {field: "changeSpec", headerName: "교환스펙", width: 200, headerClass: 'grid-column-editable'},
                            ]
                        }
                        seqColumn={'assetNo'}
                    />
                </CContainer>
            }
            {/* 검색모달
            <AssetSearch callbackFn={callbackAssetInfo} productCode={productCode} setProductCode={setProductCode}/>  */}
            {/* 자산검수 */}
            <ReleaseAssetCheck callbackFn={() => getReleaseAskItemList(ReleaseAskDetailStore.releaseAskDetail.releaseAskSeq)}
                               releaseAskItemList={ReleaseAskDetailStore.releaseAskItemList}
            />
            {/* 계약 조회 검색 모달 */}
            <ContractSearch callbackFn={callbackContractInfo} isEtcReleaseAsk={isEtcReleaseAsk}/>
            {/* 출고제품 조회 모달 (기타출고) 
            <StockProductSearch
                callbackFn={callbackStockProductSearch}
                isEtcReleaseAsk={isEtcReleaseAsk}
                releaseAskType={ReleaseAskDetailStore.releaseAskDetail.releaseAskType}
                contractType={ReleaseAskDetailStore.releaseAskDetail.contractType}
                btnStatus={btnStatus}
                setBtnStatus={setBtnStatus}
            />
            */}
            <StockAssetSearch
                callbackProduct={callbackStockProductSearch}
                // callbackOption={callbackOptionListSearch}
                // selProductList={checkRowProductList}
                searchType={'A'}
            />
            
            <CustomerManagerSearch customerNo={customerNo} callbackFn={callbackCustomerManager}/>
            <CheckReleaseShipPopup callbackFn={releaseShipComplete} 
                                releaseAskSeq={ReleaseAskDetailStore.releaseAskDetail.releaseAskSeq}
                                receiveUserName={ReleaseAskDetailStore.releaseAskDetail.receiveUserName}
                                receiveUserPhoneNumber={autoHyphen(decrypt(ReleaseAskDetailStore.releaseAskDetail.phoneNumber))}
            />

            <Modal title={'출고제품문서 메일 전송'}
                onHide={() => setSendMailPopup(false)}
                toggleValue={sendMailPopup}
                onExit={refresh}
                className={'modal-w9'}
            >
                <CContainer>
                    <Row className="mt-3">
                        <CCol lg={6}>
                            <CCol lg={12}>
                                <CInputGroup label={'제목'}
                                            labelId={'subject'}
                                            placeholder={'제목을 작성해주세요.'}
                                            value={emailSubject}
                                            onChange={v => setEmailSubject(v.target.value)}
                                />
                            </CCol>
                            <CCol lg={12}>
                                <textarea className="form-control" rows="8"
                                    value={emailContent}
                                    placeholder={'내용을 자유롭게 작성해주세요.'}
                                    readOnly={false}
                                    onChange={v => setEmailContent(v.target.value)}
                                />
                            </CCol>
                            <CCol lg={12}>
                                <CInputGroup label={'받는사람'}
                                            labelId={'receiverEmail'}
                                            placeholder={'sample@naver.com'}
                                            value={receiverEmail}
                                            onChange={v => setReceiverEmail(v.target.value)}
                                />
                            </CCol>
                            <CCol lg={12} style={{color: 'red'}}>* 메일용은 메일 발송 시에만 사용할 수 있습니다. </CCol>
                        </CCol>
                        <CCol lg={6}>
                            <CCol lg={12}>
                                <div className="btn-group btn-group-toggle pt-1">
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                    <Form.Check
                                        type="radio"
                                        value={true}
                                        name="isSendAll"
                                        checked={isSendAll === true}
                                        onChange={v => {
                                            const allChecked = [...Array(ReleaseAskDetailStore.documentList.length + 11)].map((_, index) => 
                                                'pdfType' + (index + 1)
                                            );
                                            setMailCheckedList(allChecked);
                                            setIsSendAll(true);
                                        }}
                                        style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        disabled={false}
                                    />
                                    <span>전체</span>
                                </label>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="radio" value={false} name="isSendAll"
                                            checked={isSendAll === false ? true : false}
                                            onChange={v => {
                                                setMailCheckedList([]);
                                                setIsSendAll(v.target.value === 'false' ? false : true)
                                            }}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            disabled={false}
                                        />
                                        <span>선택</span>
                                    </label>
                                </div>
                            </CCol>
                            <Row className="btn-group btn-group-toggle pt-3 pl-3 pr-3 pb-2 mt-1 border">
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType1'
                                            checked={mailCheckedList.includes('pdfType1')}
                                            onChange={v => checkedHandler(v, 'pdfType1', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>안전점검결과서</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType2'
                                            checked={mailCheckedList.includes('pdfType2')}
                                            onChange={v => checkedHandler(v, 'pdfType2', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>보험증권</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType3'
                                            checked={mailCheckedList.includes('pdfType3')}
                                            onChange={v => checkedHandler(v, 'pdfType3', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>제원표</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType4'
                                            checked={mailCheckedList.includes('pdfType4')}
                                            onChange={v => checkedHandler(v, 'pdfType4', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>안전인증서(형식)</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType5'
                                            checked={mailCheckedList.includes('pdfType5')}
                                            onChange={v => checkedHandler(v, 'pdfType5', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>안전인증서(개별)</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType6'
                                            checked={mailCheckedList.includes('pdfType6')}
                                            onChange={v => checkedHandler(v, 'pdfType6', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>작동방법 설명서</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType7'
                                            checked={mailCheckedList.includes('pdfType7')}
                                            onChange={v => checkedHandler(v, 'pdfType7', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>비상하강</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType8'
                                            checked={mailCheckedList.includes('pdfType8')}
                                            onChange={v => checkedHandler(v, 'pdfType8', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>반입전 체크리스트</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType9'
                                            checked={mailCheckedList.includes('pdfType9')}
                                            onChange={v => checkedHandler(v, 'pdfType9', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>계약서</span>
                                    </label>
                                </CCol>
                                {ReleaseAskDetailStore.releaseAskItemList[0]?.bankbookImageUrl && (
                                    <CCol lg={6}>
                                        <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                            <Form.Check
                                                type="checkbox"
                                                id='pdfType10'
                                                checked={mailCheckedList.includes('pdfType10')}
                                                onChange={v => checkedHandler(v, 'pdfType10', true)}
                                                style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                                />
                                            <span>사업자등록증</span>
                                        </label>
                                    </CCol>
                                )}
                                {ReleaseAskDetailStore.releaseAskItemList[0]?.businessLicenseUrl && (
                                    <CCol lg={6}>
                                        <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                            <Form.Check
                                                type="checkbox"
                                                id='pdfType11'
                                                checked={mailCheckedList.includes('pdfType11')}
                                                onChange={v => checkedHandler(v, 'pdfType11', true)}
                                                style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            />
                                            <span>통장사본</span>
                                        </label>
                                    </CCol>
                                )}
                                {ReleaseAskDetailStore.documentList.length > 0 && rows()}
                            </Row>
                        </CCol>
                    </Row>
                    <Row>
                        <Col lg={12} className="d-flex flex-row-reverse">
                            <CIconBtn style={{width: 100, height: 30}}
                                    title={'전송'}
                                    icon={'fi fi-rs-paper-plane'}
                                    onClick={makeReleaseAskDocument}
                                    variant={'success'}
                            />
                            <CIconBtn style={{width: 100, height: 30}}
                                    title={'출력'}
                                    icon={'fi fi-rr-print'}
                                    onClick={printGroupByDocument}
                                    variant={'primary'}
                            />
                            <CIconBtn style={{width: 160, height: 30}} icon={'fi fi-rr-upload'} title={'기타 문서 업로드'} onClick={() => AppStore.toggleUploadDcoumentPopup()} variant={'secondary'}/>
                        </Col>
                    </Row>
                    <UploadDcoumentPopup releaseAskSeq={ReleaseAskDetailStore.releaseAskDetail.releaseAskSeq} getDocument={getDocument} />
                    <ReportReleaseAskPrintGroupByTemplate mailCheckedList={mailCheckedList} ref={groupByPrintRef} />
                </CContainer>
            </Modal>
        </>
    );
}

export default observer(ReleaseAskDetail);
