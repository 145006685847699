import { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { Container, Col, Row } from "react-bootstrap";
import { CContainer } from '../../components/CustomContainer';
import { findCommKrnm } from "../../utils/commcode";
import { UserStore } from '../../store/human-resources/UserStore';
import AgGridContainer from "../../components/AgGridContainer";
import {decrypt, showToast} from "../../common/utils";
import UserListSearch from "./UserListSearch";
import UserDetail from "./UserDetail";
import { UserTabsStore } from "../../store/human-resources/UserTabsStore";
import { USER_INFO } from "../../common/constants";
import { callConfirm, getLocalStorage } from "../../utils";

const UserList = () => {
    const [userList, setUserList] = useState();
    const [viewMode, setViewMode] = useState(false);
    const gridRef = useRef();
    const userInfo = getLocalStorage(USER_INFO);
    const reportRef = useRef();

    const columnDefs = [
        { field: "userSeq", headerName: "회원번호", width: 81, hide: true },
        { field: "companyCode", headerName: "회사코드", hide: true },
        { field: "companyName", headerName: "회사명", width: 140, },
        { field: "id", headerName: "아이디" , width: 110},
        { field: "name", headerName: "이름", flex: 1 },
        {
            field: "isActive", headerName: "재직여부", valueFormatter: v => v.data.isActive === 1 ? 'Y' : '퇴사', width: 90,
            cellClass: v => v.data.isActive === 0 ? 'ag-grid-column-cancel' : '',
            hide: true
        },
        /*{ field: "headquartersSeq", headerName: "본부", valueFormatter: v => findCommKrnm(String(v.value), '34'), width: 120 },*/
        { field: "teamSeq", headerName: "부서", valueFormatter: v => findCommKrnm(String(v.value), '33'), width: 130, hide: true },
        { field: "positionSeq", headerName: "직급", valueFormatter: v => findCommKrnm(String(v.value), '31'), width: 80, hide: true },
        { field: "telephone", headerName: "전화번호", width: 130, hide: true },
        { field: "phoneNumber", headerName: "휴대폰번호", valueFormatter: v => v.data.phoneNumber ?? decrypt(v.data.phoneNumber), width: 130, hide: true },
        { field: "email", headerName: "이메일", width: 180, hide: true },
        { field: "joinDate", headerName: "입사일자", width: 120, hide: true },
        { field: "resignationDate", headerName: "퇴사일자", width: 120, hide: true },
        { field: "password", headerName: "비밀번호", hide: true },
        { field: "genderSeq", headerName: "성별", hide: true },
        { field: "birthDate", headerName: "생년월일", hide: true },
        { field: "postcode", headerName: "우편번호", hide: true },
        { field: "address", headerName: "주소", hide: true },
        { field: "addressDetail", headerName: "상세주소", hide: true },
        { field: "imageUrl", headerName: "이미지URL", hide: true },
        { field: "isReleaseAskManager", headerName: "출고의뢰담당", hide: true },
        { field: "isASMaintenanceManager", headerName: "A/S & 정비담당", hide: true },
        { field: "isShipManager", headerName: "배송담당", hide: true },

    ];

    /* 회원 목록 조회 */
    const getUserList = async () => {
        const result = await axios.get('/user/management/list', { params: UserStore.search });
        if (result.length === 0) {
            showToast('조회된 데이터가 없습니다.');
        }
        setUserList(result);
    }

    /* 회원 상세 조회 */
    const selectUserDetail = async (v) => {
        UserStore.data = v ? v.data : UserStore.data;
        // setUserSeq(v.data.userSeq);
        UserStore.isAddressInit = "false";
        const result = await axios.get("/user/tabs", {params: {userSeq: UserStore.data.userSeq}});
        UserTabsStore.setAnnualLeaveCountList(result.annualLeaveCountList);
        UserTabsStore.setAnnualLeaveHistoryList(result.annualLeaveHistoryList);
        UserTabsStore.setAnnualLeaveHistoryGridList(result.annualLeaveHistoryList);
        UserTabsStore.setSalaryDetailsList(result.salaryDetailsList);
        UserTabsStore.setPersonnelAppointmentDetailsList(result.personnelAppointmentDetailsList);
        UserTabsStore.setFamilyEventsExpensesList(result.familyEventsExpensesList);
        UserTabsStore.setWorkExperienceList(result.workExperienceList);
        UserTabsStore.setAttendanceDetailsList(result.attendanceDetailsList);
        UserTabsStore.setIssueCertificateHistory(result.issueCertificateHistory);

        setViewMode(true);
    }

    /* 새 회원 등록시 작성칸 비우기  */
    const initUser = () => {
        UserStore.data.userSeq = null;
        UserStore.data.name = '';
        UserStore.data.id = '';
        UserStore.data.password = '';
        UserStore.data.genderSeq = null;
        UserStore.data.birthDate = null;
        UserStore.data.phoneNumber = '';
        UserStore.data.telephone = '';
        UserStore.data.email = '';
        UserStore.data.postcode = '';
        UserStore.data.address = '';
        UserStore.data.addressDetail = '';
        UserStore.data.companyCode = null;
        UserStore.data.headquartersSeq = null;
        UserStore.data.partSeq = null;
        UserStore.data.partName = '';
        UserStore.data.companyName = userInfo.companyName;
        UserStore.data.teamSeq = null;
        UserStore.data.positionSeq = null;
        UserStore.data.joinDate = null;
        UserStore.data.resignationDate = null;
        UserStore.data.imageUrl = '';
        UserStore.data.authorityGroupSeq = '';
        UserStore.data.personalNumber = '';
    }

    /* 라디오 버튼 클릭 시 true -> 1 / false -> 0 변환 및 값 넣어주기 */
    const onClickIsActiveBtn = (e, target) => {
        const value = e === true ? 1 : e === '' ? '' : 0;
        if (target === 'searchIsActive') {
            UserStore.search.isActive = value;
        } else {
            UserStore.data.isActive = value;
            UserStore.data.resignationDate = null;
        }
    }

    const removeFn = async (e) => {
        const { userSeq, id, name } = e.data;
        if (!await callConfirm(`${name} (${id}) 삭제 하시겠습니까?`)) {
            return false;
        }

        await axios.delete(`/user/${userSeq}`);
        showToast('삭제가 완료되었습니다.');
        getUserList();
    }

    return (
        <>
            {/* 검색조건 */}
            <UserListSearch getUserList={getUserList} setViewMode={setViewMode} initUser={initUser} onClickIsActiveBtn={onClickIsActiveBtn}/>
            <Container fluid>
                <Row>
                    {/* 회원 리스트 */}
                    <Col lg={4}>
                        <CContainer>
                            <AgGridContainer
                                girdTitle='회원목록'
                                gridRef={gridRef}
                                height={65}
                                rowData={userList}
                                columnDefs={columnDefs}
                                isCheckBox={false}
                                seqColumn={'userSeq'}
                                rowDoubleClickCallback={selectUserDetail}
                                useRemoveRow={true}
                                removeCallback={removeFn}
                                getRowStyle={(params) => {
                                        if (params.data.isActive === 0) {
                                            return { color: '#bababa' };
                                        }
                                        return null;
                                    }
                                }
                            />
                        </CContainer>
                    </Col>
                    {/* 회원 상세 조회 */}
                    <UserDetail getUserList={getUserList} viewMode={viewMode} setViewMode={setViewMode} selectUserDetail={selectUserDetail} onClickIsActiveBtn={onClickIsActiveBtn} />
                </Row>
            </Container>
        </>
    );

};

export default observer(UserList);
