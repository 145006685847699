
import { observer } from "mobx-react-lite";
import { CIconBtn, CInputGroup, Subject } from "../../components/CustomContainer";
import { useState, useRef, useEffect } from "react";
import { CContainer, CCol, CalendarGroup, CSaveBtn, CCancelBtn, CInputDoubleGroup, CSelectGroup } from "../../components/CustomContainer";
import { Row, Col, InputGroup, Form, Button, Badge, Stack } from "react-bootstrap";
import { AsStore } from "../../store/asset/AsStore";
import { AppStore } from "../../store/AppStore";
import axios from "axios";
import SiteNameSearch from "../../components/searchModal/SiteNameSearch";
import { showToast, decrypt, encrypt } from "../../common/utils";
import UserSearch from "../../components/searchModal/UserSearch";
import AgGridContainer from "../../components/AgGridContainer";
import { ICON_ADD, ICON_SAVE, USER_INFO } from "../../common/constants";
import AssetSearch from "../../components/searchModal/AssetSearch";
import { SiteSearchStore } from "../../store/search/SiteSearchStore";
import Modal from "../../components/Modal";
import { MaintenanceStore } from "../../store/asset/MaintenanceStore";
import MaintenanceDetail from "./MaintenanceDetail";
import { callConfirm, getLocalStorage } from "../../utils";
import { ConsumableStore } from "../../store/search/ConsumableStore";
import { findCommKrnm, getCommcode } from "../../utils/commcode";
import { getUserName } from "../../utils/userUtils";
import { autoHyphen } from "../../common/utils";
import Address from "../../components/Address";
import { set } from "lodash";
import SiteManagerSearch from "../../components/searchModal/SiteManagerSearch";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import SiteCustomerManagerSearch from "../../components/searchModal/SiteCustomerManagerSearch";
import AsManagerUserSearch from "../../components/searchModal/AsManagerUserSearch";
import AlertConfirm from "react-alert-confirm";
import Address2 from "../../components/Address2";

const AsDetailPopup = ({getAsList}) => {
    const gridRef = useRef();
    const maintenanceGridRef = useRef();
    const userInfo = getLocalStorage(USER_INFO);

    const [isReadOnly, setIsReadOnly] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [inputSiteName, setInputSiteName] = useState('');
    const [managementNo, setManagementNo] = useState('');
    const [label, setLabel] = useState('list');
    const [userColumn, setUserColumn] = useState('');
    const [btnStatus, setBtnStatus] = useState('add');
    const [managerList, setManagerList] = useState([]);
    const [acceptDetail, setAcceptDetail] = useState('안녕하세요, ' + userInfo.companyName +'입니다.\nAS가 접수되었습니다.' + '\n\n업체명: -\n현장명: -\n현장주소: -\n담당자: -\n연락처: -\n관리번호: -\n장비위치: -\n불량내용: -');
    const [managementNoList, setManagementNoList] = useState([]);
    const [acceptContent, setAcceptContent] = useState('');
    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [searchUser, setSearchUser] = useState('');
    const [siteSeq, setSiteSeq] = useState(null);
    const [equipmentPart, setEquipmentPart] = useState('');
    const [inputCustomerName, setInputCustomerName] = useState('');
    const [customerData, setCustomerData] = useState([]);
    const [inputManagerName, setInputManagerName] = useState('');
    const [inputManagementNo, setInputManagementNo] = useState('');
    const [managementNoData, setManagementNoData] = useState([]);
    const [maintenanceCodeList, setMaintenanceCodeList] = useState([]);
    const [productType, setProductType] = useState('');
    const [productTypeName, setProductTypeName] = useState('');
    const [lgValue, setLgValue] = useState('');
    const [lgName, setLgName] = useState('');
    const [mdValue, setMdValue] = useState('');
    const [mdName, setMdName] = useState('');
    const [smValue, setSmValue] = useState('');
    const [smName, setSmName] = useState('');

    useEffect(()=>{
        setIsReadOnly(AsStore.data.asSeq ? true : false);
        setAcceptDetail(AsStore.data.acceptDetail ? AsStore.data.acceptDetail : acceptDetail);
        getManaitenanceCode();
        
        if (AsStore.data.asSeq) {
            const dataProductType = AsStore.data.productType;
            setProductType(dataProductType);
            // AS 담당자 배정 값 넣어주기
            // AsStore.data.asManagerList.map(v => managerList.push({userSeq: v.userSeq, name: v.name, phoneNumber: v.phoneNumber}))
            AsStore.data.asManagerName = AsStore.data.asManagerList.map(user => user.name).join(' / ');

        }

    }, [AsStore.data.asStatus, AsStore.data.asSeq]);

    useEffect(()=> {
        makeAcceptDetails();
    }, [AsStore.data.siteName, AsStore.data.customerName, postcode, address, addressDetail, acceptContent, equipmentPart]);

    /* 정비코드 가져오기 */
    const getManaitenanceCode = async () => {
        const result = await axios.get("/as/maintenanceCode");
        setMaintenanceCodeList(result);
    }

    /* 정비코드 셋팅 */
    const getSelectOptionValue = (codeType, productType='') => {
        let codeList = [];
        if (Number(productType) === 1) {
            codeList = maintenanceCodeList.filter(v => v.division === codeType).map(v => {return {name: v.maintenanceCodeName, value: v.maintenanceCodeSeq}})
        } else if (Number(productType) === 2) {
            if (codeType) {
                codeList = maintenanceCodeList.filter(v => v.parentSeq === Number(codeType)).map(v => {return {name: v.maintenanceCodeName, value: v.maintenanceCodeSeq}})
            } else {
                codeList = maintenanceCodeList.filter(v => v.productType === Number(productType)).map(v => {return {name: v.maintenanceCodeName, value: v.maintenanceCodeSeq}})
            }
        }

        return codeList;
    }

    /* 정비코드 바뀔 때 이벤트 */
    const changeMaintenanceCode = (data) => {
        const { agId, productType } = data.data;
        const colDef = data.colDef.field;
        
        if (data.oldValue !== data.newValue) {
            const store = AsStore;
            
            // 장비구분
            if (colDef === 'productType') {
                AsStore.data.managementNoList[agId].productTypeName = findCommKrnm(productType, '68');
                AsStore.data.managementNoList[agId].lgName = '';
                AsStore.data.managementNoList[agId].lgValue = '';
                AsStore.data.managementNoList[agId].mdName = '';
                AsStore.data.managementNoList[agId].mdValue = '';
                AsStore.data.managementNoList[agId].smName = '';
                AsStore.data.managementNoList[agId].smValue = '';
            }
            
            else if (colDef === 'lgName' && productType === '2') {
                AsStore.data.managementNoList[agId].mdName = '';
                AsStore.data.managementNoList[agId].mdValue = '';
                AsStore.data.managementNoList[agId].smName = '';
                AsStore.data.managementNoList[agId].smValue = '';
            }
            else if (colDef === 'mdName' && productType === '2') {
                AsStore.data.managementNoList[agId].smName = '';
                AsStore.data.managementNoList[agId].smValue = '';
            }

            gridRef.current.api.redrawRows();
        } 
    }

    const getAsDetail = async (asSeq) => {
        const result = await axios.get('/as/detail', {params: {asSeq: asSeq}});
        AsStore.data = result;
        AsStore.data.siteManagerPhone = decrypt(result.siteManagerPhone);
    }

    /* 업체명 엔터키 이벤트 */
    const customerNameEnterKeyFn = async () => {
        const result = await axios.get('/customer/list', {params: {customerName: AsStore.data.customerName}});

        if (result.length === 1) {
            AsStore.data.customerName = result[0].customerName;
            AsStore.data.customerNo = result[0].customerNo;
        } else if (result.length > 1) {
            setInputCustomerName(AsStore.data.customerName);
            setCustomerData(result);
            AsStore.data.customerNo = '';
            AppStore.toggleCustomerSearchModal();
        }
    }

    const searchCustomerInfo = (data) => {
        AsStore.data.customerName = data.customerName;
        AsStore.data.customerNo = data.customerNo;
    }

    /* 현장명 엔터키 이벤트 */
    const siteNameEnterKeyFn = async () => {
        const result = await axios.get('/site/info', {params: {siteName: AsStore.data.siteName, isDeleted: 1}});

        if (result.length === 1) {
            AsStore.data.siteName = result[0].siteName;
            AsStore.data.siteSeq = result[0].siteSeq;
            // AsStore.data.siteManagerName = result[0].managerName;
            // AsStore.data.siteManagerPhone = decrypt(result[0].managerPhoneNumber);
            setPostcode(result[0].postCode);
            setAddress(result[0].address);
            setAddressDetail(result[0].addressDetail);
            setSiteSeq(result[0].siteSeq);

            // makeAcceptDetails();
        } else if (result.length > 1) {
            setInputSiteName(AsStore.data.siteName);
            setGridData(result);
            AsStore.data.siteName = '';
            AsStore.data.siteSeq = '';
            AsStore.data.siteManagerName = '';
            AsStore.data.siteManagerPhone = '';
            setPostcode('');
            setAddress('');
            setAddressDetail('');
            setSiteSeq(null);
            AppStore.toggleSiteNameSearchPopup();

            // makeAcceptDetails();
        } 
    }

    /* 현장명 팝업 callback */
    const searchSiteInfo = (data) => {
        AsStore.data.siteName = data.siteName;
        AsStore.data.siteSeq = data.siteSeq;
        setPostcode(data.postcode);
        setAddress(data.address);
        setAddressDetail(data.addressDetail);
        setSiteSeq(data.siteSeq);
        makeAcceptDetails();
    }

    /* 유저 조회 팝업 호출 */
    const openSearchAsManagerUserPopup = (column) => {
        setUserColumn(column);
        if (column === 'asManager') {
            setSearchUser('as');
        } else {
            setSearchUser('');
        }
        AppStore.toggleOpenAsManagerUserSearch();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo, column) => {

        if (!userInfo || !column) {
            return false;
        }

        if (userInfo.constructor === Array) {
            userInfo.map(v => managerList.push({userSeq: v.userSeq, name: v.name, phoneNumber: v.phoneNumber}));
        } else {
            managerList.push({userSeq: userInfo.userSeq, name: userInfo.name, phoneNumber: userInfo.phoneNumber});
        }

        // 중복값 제거
        const uniqueArray = [...new Map(managerList.map((m) => [m.userSeq, m])).values()];
        setManagerList(uniqueArray);

        // 이름 나열 출력
        const nameString = uniqueArray.map(user => user.name).join(' / ');
        AsStore.data.asManagerName = nameString;

        setUserColumn('');
    }


    /* AS 접수 내용 만들기 */
    const makeAcceptDetails = () => {

        // 접수할 때 아니면 실행되지 않게 리턴
        if (AsStore.data.asSeq) {
            return false;
        }

        const addressContent = address !== '' ? address + ", " + addressDetail: '';

        // 관리번호 나열
        const managementNoArr = AsStore.data.managementNoList.map(v => {return v.managementNo});

        let content = '안녕하세요, ' + userInfo.companyName +'입니다.\nAS가 접수되었습니다.' + '\n\n업체명: ' + (AsStore.data.customerName || '-') + '\n현장명: ' + (AsStore.data.siteName || '-') + '\n현장주소: ' + (addressContent || '-') + '\n담당자: ' + (AsStore.data.siteManagerName || '-') + '\n연락처: ' + (autoHyphen(AsStore.data.siteManagerPhone) || '-') + '\n관리번호: ' + (managementNoArr.join(',') || '-') + '\n장비위치: ' + (equipmentPart || '-') + '\n불량내용: ' + (acceptContent || '-');
        setAcceptDetail(content);
    }

    /* AS 접수 생성 */
    const saveAsAccept = async () => {
        
        AsStore.data.acceptDetail = acceptDetail;
        
        let requiredParams = ['siteName', 'siteManagerName', 'siteManagerPhone', 'acceptDetail'];
        if (!requiredParams.every(param => AsStore.data[param]) || managerList.length === 0) {
            showToast('필수값을 모두 입력해주세요.');
            return;
        }
        
        // if (!await callConfirm('AS를 접수하시겠습니까?')) {
        //     return false;
        // };
        
        let asUrl = window.location.protocol + "//" + window.location.host + "/as/accept/detail/";
        let params = {...AsStore.data
                        , siteManagerPhone: encrypt(AsStore.data.siteManagerPhone)
                        , asManagerList: managerList
                        , asUrl: asUrl
                        , sitePostcode: postcode
                        , siteAddress: address
                        , siteAddressDetail: addressDetail
                        , equipmentPart: equipmentPart
                        , productType: productType,
                    };

        if (!await callConfirm('A/S를 접수하시겠습니까?')) {
            return false;
        };
        const result = await axios.post('/as', params);
        if(result.result === 'option_failed') {
            alert(result.message);
        } else {
            showToast('AS 접수가 완료되었습니다.');
            
            if (result.fail > 0) {
                alert('알림톡 전송 성공: ' + result.success + '건 \n전송 실패: ' + result.fail + '건');
            }
            
            // AppStore.toggleAsDetail();
            AsStore.data.asSeq = result.asSeq;
            AsStore.data.asStatus = '2';

            await getAsDetail(result.asSeq);
            getAsList();
        }
    }

    /* AS 접수 상태 변경 */
    const changeStatus = async (status) => {
        let isSendSms = 0;
        
        let msg = '';
        let resultMsg = '';
        switch (status) {
            case '2' : msg = 'A/S를 접수하시겠습니까?'; resultMsg = 'A/S를 접수하였습니다.' ; break;
            case '3' : msg = '출발 처리 하시겠습니까?'; resultMsg = '출발 처리하였습니다.' ; break;
            case '4' : msg = '도착 처리 하시겠습니까?'; resultMsg = '도착 처리하였습니다.' ; break;
            case '8' : msg = '미결 처리 하시겠습니까?'; resultMsg = '미결 처리하였습니다.' ; break;
            case '9' : msg = 'A/S를 취소하시겠습니까?'; resultMsg = 'A/S를 취소하였습니다.' ; break;
        }

        if(!await callConfirm(msg)) {
            return false;
        }

        if(status === '2' && AsStore.data.siteManagerPhone !== null && AsStore.data.siteManagerPhone !== '') {
            isSendSms = await callConfirm('담당자에게 알림톡을 전송하시겠습니까?') ? 1 : 0;
        }

        // 추가된 관리번호만 새로 저장해주기
        const createdList = AsStore.data.managementNoList.filter(v => v.isCreated);

        const params = {asSeq: AsStore.data.asSeq, asStatus: status, acceptDetail: acceptDetail, managementNoList: createdList, isSendSms: isSendSms, shortUrl: AsStore.data.shortUrl};

        await axios.put('/as', params);
        showToast(resultMsg);
        AppStore.toggleAsDetail();
        getAsList();
    }

    /* AS 처리 완료 */
    const completeAs = async () => {
        // AS 상태 변경, 정비 테이블에 추가
        AsStore.data.managementNoList = AsStore.data.managementNoList ? AsStore.data.managementNoList : [];
        AsStore.data.processDetail = AsStore.data.processDetail ? AsStore.data.processDetail : '';

        let isSendSms = 0;

        const maintenanceCodeCheck = AsStore.data.managementNoList.every(v => v.lgValue && v.mdValue && v.smValue);

        if (!maintenanceCodeCheck) {
            showToast('불량코드를 선택해주세요.');
            return false;
        }

        if (AsStore.data.managementNoList.length === 0 || AsStore.data.processDetail === '' ) {
            showToast('처리 내역과 관리번호 목록을 모두 작성해주세요.');
            return false;
        }

        if (!AsStore.data.managementNoList.every(param => param.content)) {
            showToast('정비내역을 모두 입력해주세요.');
            return false;
        }

        if (!await callConfirm('AS를 완료 처리 하시겠습니까?')) {
            return false;
        }

        if(AsStore.data.siteManagerPhone !== null && AsStore.data.siteManagerPhone !== '') {
            isSendSms = await callConfirm('담당자에게 알림톡을 전송하시겠습니까?') ? 1 : 0;
        }

        AsStore.data.acceptDetail = acceptDetail;
        const asStatus = await axios.put('/as/complete', {...AsStore.data, isSendSms});
        AsStore.data.asStatus = asStatus;

        showToast('AS가 완료되었습니다.');
        AppStore.toggleAsDetail();
        getAsList();
    }

    /* 소모품 저장 버튼 커스텀*/
    const modConsumItemBtn = {
        isUsed: AsStore.data.asSeq ? true : false,
        callbackFn:() => modConsum(),
        icon: ICON_SAVE,
        title: '저장',
        width: 110,
    }

    /* 자산 추가 버튼 */
    const customAddBtnInfo = {
        isUsed: ['1','2','3','4'].includes(AsStore.data.asStatus),
        callbackFn: () =>  AppStore.toggleAssetSearchModal(),
        title: '관리번호 추가',
        icon: ICON_ADD,
        width: 130,
    };

    /* 관리번호 엔터키 이벤트 */
    const openAssetSearchPopup = async (e, type) =>{
        setLabel(type);

        if (type === 'input') {
            const params = {managementNo: AsStore.data.managementNo, assetStatus: '302'};
            const result = await axios.get('/asset/list', { params });
            ConsumableStore.search.managementNo = AsStore.data.managementNo;
            
            if(result.length === 1) {
                AsStore.data.managementNo = result[0].managementNo;
                AsStore.data.customerName = result[0].customerName;
                AsStore.data.customerNo = result[0].customerNo;
                AsStore.data.siteSeq = result[0].siteSeq;
                AsStore.data.siteName = result[0].siteName !== null ? result[0].siteName : '';
                AsStore.data.siteManagerName = result[0].managerName !== null ? result[0].managerName : '';
                AsStore.data.siteManagerPhone = decrypt(result[0].managerPhoneNumber);
                setPostcode(result[0].sitePostcode);
                setAddress(result[0].siteAddress !== null ? result[0].siteAddress : '');
                setAddressDetail(result[0].siteAddressDetail !== null ? result[0].siteAddressDetail: '');

                let uniqueArray = AsStore.data.managementNoList;
                let seq = AsStore.data.managementNoList.length + 1;
                uniqueArray.push({addRowId: seq++, managementNo: result[0].managementNo, content: "", isSelected: true, isCreated: true});
                uniqueArray = [...new Map(uniqueArray.map((m) => [m.managementNo, m])).values()];
                AsStore.data.managementNoList = uniqueArray;

                makeAcceptDetails();
                AsStore.data.managementNo = '';
            } else {
                setInputManagementNo(AsStore.data.managementNo);
                setManagementNoData(result);
                AppStore.toggleAssetSearchModal();
                AsStore.data.managementNo = '';
            }
        } else if (type === 'list') {
            const params = {managementNo: managementNo, assetStatus: '302'};
            const result = await axios.get('/asset/list', { params });

            ConsumableStore.search.managementNo = managementNo;
            AsStore.data.managementNoList = AsStore.data.managementNoList ? AsStore.data.managementNoList : [];
    
            if(result.length === 1) {
                let seq = AsStore.data.managementNoList.length + 1;
                
                if(!AsStore.data.managementNoList.some(v => v.managementNo === result[0].managementNo)){
                    const additionalRowInfo = {
                        addRowId: seq++
                        , managementNo: result[0].managementNo
                        , content: ""
                        , isSelected: true
                        , isCreated: true
                    }
                    AsStore.data.managementNoList.push({...additionalRowInfo});
                }
            } else {
                setInputManagementNo(managementNo);
                setManagementNoData(result);
                AppStore.toggleAssetSearchModal();
                setManagementNo('');
            }

            setManagementNo('');
        }

        setLabel('list');
    }

    /* 자산 조회 콜백 함수 */
    const callbackAssetInfo = (data) => {
        if (label === 'list') {

            // AsStore.data.managementNo = data.managementNo;
            AsStore.data.customerName = data.customerName;
            AsStore.data.customerNo = data.customerNo;
            AsStore.data.siteSeq = data.siteSeq;
            AsStore.data.siteName = data.siteName !== null ? data.siteName : '';
            // AsStore.data.siteManagerName = data.managerName !== null ? data.managerName : '';
            // AsStore.data.siteManagerPhone = decrypt(data.managerPhoneNumber);
            setPostcode(data.sitePostcode);
            setAddress(data.siteAddress !== null ? data.siteAddress : '');
            setAddressDetail(data.siteAddressDetail !== null ? data.siteAddressDetail: '');

            let uniqueArray = AsStore.data.managementNoList;
            let seq = AsStore.data.managementNoList.length + 1;
            uniqueArray.push({addRowId: seq++, managementNo: data.managementNo, model: data.model, content: "", isSelected: true, isCreated: true});
            uniqueArray = [...new Map(uniqueArray.map((m) => [m.managementNo, m])).values()];
            AsStore.data.managementNoList = uniqueArray;

            if (!AsStore.data.asSeq) {
                makeAcceptDetails();
            }
        } else if (label === 'input') {
            // AsStore.data.managementNo = data.managementNo;
            AsStore.data.customerName = data.customerName;
            AsStore.data.customerNo = data.customerNo;
            AsStore.data.siteSeq = data.siteSeq;
            AsStore.data.siteName = data.siteName;
            // AsStore.data.siteManagerName = data.managerName;
            // AsStore.data.siteManagerPhone = decrypt(data.managerPhoneNumber);
            
            // 중복제거
            const uniqueArray = [...new Set([...managementNoList, data.managementNo])];

            setManagementNoList(uniqueArray);
        }
    }

    /* 정비 팝업 모달 */
    const openMaintenanceDetail = (v) => {
        MaintenanceStore.detail.maintenanceSeq = v.data.maintenanceSeq;
        MaintenanceStore.detail.managementNo = v.data.managementNo;
        MaintenanceStore.status = 'detail';
        setSearchUser('maintenance');

        AppStore.toggleModal();
    }

    /* AS 모달 닫기 */
    const closeModal = () => {
        AsStore.init();
        setAcceptDetail('안녕하세요, ' + userInfo.companyName +'입니다.\nAS가 접수되었습니다.' + '\n\n현장명: \n현장주소: \n담당자: \n연락처: \n관리번호: \n장비위치 \n불량내용: ');
        setManagementNoList([]);
        setManagerList([]);
        setManagementNo('');
        setEquipmentPart('');
        setAcceptContent('');
        setPostcode('');
        setAddress('');
        setAddressDetail('');
        setProductType('');
        setProductTypeName('');
        setLgValue('');
        setLgName('');
        setMdValue('');
        setMdName('');
        setSmValue('');
        setSmName('');
    };

    /* 정비 상세 모달 닫기 */
    const closedMaintenanceModal = () => {
        MaintenanceStore.detailInit();
        setSearchUser('as');
    };

    /* AS 담당자 비우기 버튼 */
    const onEmptyBtn = () => {
        AsStore.data.asManagerName = '';
        setManagerList([]);
    }

    /* 관리번호 뱃지 삭제 버튼 이벤트 */
    const cancelNo = (v) => {
        let resultList = [...managementNoList];

        for (let i = 0; i < managementNoList.length; i++) {
            if (managementNoList[i] === v) {
                resultList.splice(i, 1);
            }
        }
        setManagementNoList(resultList);
    }

    /* 체크박스 클릭 이벤트 */
    const getCheckboxClickEvent = () => {
        const selectedList = AsStore.data.managementNoList.filter(v => v.isSelected);
        makeAcceptDetails();
    }

    /* 접수내용 수정 */
    const changeAcceptContent = (e, column) => {
        if(column === 'acceptContent') setAcceptContent(e.target.value); 
        else if (column === 'equipmentPart') setEquipmentPart(e.target.value);
        
        makeAcceptDetails();
    }

    /* 관리번호 그리드 삭제 */
    const removeFn = async (e) => {
        const { asSeq, seq, managementNo } = e.data;
        if (!await callConfirm(`관리번호[${managementNo}] 삭제 하시겠습니까?`)) {
            return false;
        }

        await axios.delete('/as/managementNo', {params: {asSeq: asSeq, seq: seq, managementNo: managementNo}});
        showToast('삭제 되었습니다.');

        getAsManagementNoList();
    }

    /* 관리번호 불러오기 */
    const getAsManagementNoList = async() => {
        const result = await axios.get("/as/managementNo", {params: {asSeq: AsStore.data.asSeq}});
        AsStore.data.managementNoList = result;
    }

    const appendTitle = () => {
        return (
            <div className="text-danger">정비내역은 AS완료 시에만 저장됩니다.</div>
        )
    }

    const siteCustomerManagerPopupBtn = () => {
        AppStore.toggleOpenSiteCustomerManagerSearch();
    }

    /* 담당자 팝업 callback */
    const searchSiteCustomerManager = (data) => {
        AsStore.data.siteManagerName = data.managerName;
        AsStore.data.siteManagerPhone = decrypt(data.phoneNumber);
        makeAcceptDetails();
    }
    
    const deleteManagementNo = async (i) => {
        if (!await callConfirm('삭제하시겠습니까?')) {
            return false;
        }

        let copyArr = [...AsStore.data.managementNoList];
        copyArr.splice(i, 1);

        AsStore.data.managementNoList = copyArr;
        makeAcceptDetails();
    }

    return(
        <>
            <Modal
                title={'AS 접수'}
                show={AppStore.isOpenAsDetail}
                onHide={() => AppStore.toggleAsDetail()}
                toggleValue={AppStore.isOpenAsDetail}
                onExit={closeModal}
                // size={AsStore.data.asSeq ? 'xl' : ''}
                className={AsStore.data.asSeq ? 'modal-w9' : 'modal-w5'}
            >
                <Modal title={'정비상세'} onExit={closedMaintenanceModal}
                        size={'xl'}
                >
                    <MaintenanceDetail searchUser={searchUser} setSearchUser={setSearchUser} maintenanceCodeList={maintenanceCodeList}/>
                </Modal>
                <CContainer search>
                    {AsStore.data.asSeq && 
                    <>
                        <Row>
                            <CCol lg={4}>
                                <CInputGroup label={'AS번호'}
                                        labelId={'asSeq'}
                                        value={AsStore.data.asSeq || ''}
                                        disabled={true}             
                                />
                            </CCol>
                            <CCol lg={4}>
                                <CSelectGroup label={'AS상태'} labelId={'asStatus'}
                                            placeholder={'AS상태'}
                                            options={[{name: 'AS상태', value: ''}].concat(getCommcode('55'))}
                                            value={AsStore.data.asStatus || ''}
                                            disabled={true}
                                />
                            </CCol>
                            <CCol lg={4}>
                                <CInputGroup label={'AS처리자'}
                                        labelId={'asManagerSeq'}
                                        value={getUserName(AsStore.data.asManagerSeq) || ''}
                                        disabled={true}             
                                />
                            </CCol>
                        </Row>
                        {Number(AsStore.data.asStatus) < 2 &&
                            <Row>
                                <CCol lg={12}>
                                    <InputGroup size="sm">
                                        <InputGroup.Text id={'asManager'} style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>AS 담당자 배정</InputGroup.Text>
                                        <Form.Control style={{flex: 1}} aria-describedby={'asManagerName'} value={AsStore.data.asManagerName || ''} onChange={v => AsStore.data.asManagerName = v.target.value} readOnly={true} disabled={isReadOnly}/>
                                        <Button className="btn btn-outline-secondary" style={{borderLeftWidth: 0, borderRightWidth: 0, borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={onEmptyBtn} disabled={isReadOnly}>
                                            <i className="fi fi-rr-cross-small" />
                                        </Button>
                                        <Button variant="outline-secondary" style={{borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={() => openSearchAsManagerUserPopup('asManager')} disabled={isReadOnly}>
                                            <i className="me-1 fi fi-br-search" />
                                        </Button>
                                    </InputGroup>
                                </CCol>
                            </Row>
                        }
                        {/* <div style={{width: '100%', height: 1, borderBottomWidth: 1, borderBottomColor: '#e8e6e6', marginTop: -5, marginBottom: 10, marginLeft: 10, marginRight: 10}}/>             */}
                    </>
                    }
                    {!AsStore.data.asSeq && 
                    <>
                    <Row>
                        <CCol lg={12}>
                            <CInputGroup
                                label={'현장명'}
                                labelId={'siteName'}
                                value={AsStore.data.siteName || ''}
                                onChange={e => {
                                    AsStore.data.siteName = e.target.value;
                                    SiteSearchStore.search.siteName = e.target.value;
                                }}
                                enterKeyHint={siteNameEnterKeyFn}
                                onCallbackBtn={() => AppStore.toggleSiteNameSearchPopup()}
                                disabled={isReadOnly}
                                btnDisabled={isReadOnly}
                                placeholder={'현장명 입력'}
                            />
                        </CCol>
                        <CCol lg={12}><div style={{textAlign: 'center'}}>또는</div></CCol>
                        <CCol lg={12}>
                            <CInputGroup
                                label={'관리번호'}
                                labelId={'managementNo'}
                                value={AsStore.data.managementNo || ''}
                                onChange={e => {
                                    AsStore.data.managementNo = e.target.value; 
                                    // AsStore.data.siteSeq = '';
                                    // AsStore.data.siteName = '';
                                    // AsStore.data.siteManagerName = '';
                                    // AsStore.data.siteManagerPhone = '';
                                    // AsStore.data.customerName = '';
                                    // AsStore.data.customerNo = '';
                                    // setPostcode('');
                                    // setAddress('');
                                    // setAddressDetail('');
                                }}
                                enterKeyHint={e => openAssetSearchPopup(e, 'input')}
                                onCallbackBtn={() =>  AppStore.toggleAssetSearchModal()}
                                disabled={isReadOnly}
                                btnDisabled={isReadOnly}
                                placeholder={'관리번호 입력 후 엔터키를 눌러주세요.'}
                            />
                        </CCol>
                    </Row>
                    <div style={{width: '100%', height: 1, borderBottomWidth: 1, borderBottomColor: '#e3e3e3', marginTop: -5, marginBottom: 10, marginLeft: 0, marginRight: 10}}/>
                    <Row>
                        <Address2 label='주소'
                                    setPostcode={setPostcode}
                                    setAddress={setAddress}
                                    setAddressDetail={setAddressDetail}
                                    postcode={postcode}
                                    address={address}
                                    addressDetail={addressDetail}
                                    disabled={isReadOnly}
                                    style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}
                                    btnStyle={{borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}}
                        />
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <CInputGroup
                                label={'업체명'}
                                labelId={'customerName'}
                                value={AsStore.data.customerName || ''}
                                onChange={v => {
                                    AsStore.data.customerName = v.target.value; 
                                    AsStore.data.customerNo = '';
                                }}
                                enterKeyHint={customerNameEnterKeyFn}
                                onCallbackBtn={() =>  AppStore.toggleCustomerSearchModal()}
                                placeholder={'업체명 입력'}
                            />
                        </CCol>
                        <CCol lg={12}>
                            <CInputDoubleGroup label={'담당자'} labelId={'siteManagerPhone'} labelId2={'siteManagerName'}
                                                placeholder={'담당자 전화번호'} placeholder2={'담당자'} secondFlex={4}
                                                onCallbackBtn={() => {!isReadOnly && siteCustomerManagerPopupBtn()}}
                                                value={autoHyphen(AsStore.data.siteManagerPhone)} value2={AsStore.data.siteManagerName || ''}
                                                onChange={v => {
                                                                    AsStore.data.siteManagerPhone = v.target.value; 
                                                                    makeAcceptDetails();
                                                                }
                                                }
                                                onChange2={v => {
                                                                    AsStore.data.siteManagerName = v.target.value; 
                                                                    makeAcceptDetails();
                                                                }}
                                                disabled={isReadOnly} disabled2={isReadOnly}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <InputGroup size="sm">
                                <InputGroup.Text id={'asManager'} style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>AS 담당자 배정</InputGroup.Text>
                                <Form.Control style={{flex: 1}} aria-describedby={'asManagerName'} value={AsStore.data.asManagerName || ''} onChange={v => AsStore.data.asManagerName = v.target.value} readOnly={true} disabled={isReadOnly}/>
                                <Button className="btn btn-outline-secondary" style={{borderLeftWidth: 0, borderRightWidth: 0, borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={onEmptyBtn} disabled={isReadOnly}>
                                    <i className="fi fi-rr-cross-small" />
                                </Button>
                                <Button variant="outline-secondary" style={{borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={() => openSearchAsManagerUserPopup('asManager')} disabled={isReadOnly}>
                                    <i className="me-1 fi fi-br-search" />
                                </Button>
                            </InputGroup>
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <InputGroup size="sm">
                                <InputGroup.Text id={'acceptContent'} style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>장비위치</InputGroup.Text>
                                    <textarea className="form-control" rows="1"
                                        value={equipmentPart || ''}
                                        onChange={e => changeAcceptContent(e, 'equipmentPart')}
                                        disabled={isReadOnly}
                                        placeholder={'장비 위치를 입력해주세요.'}
                                    />
                            </InputGroup>
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <InputGroup size="sm">
                                <InputGroup.Text id={'acceptContent'} style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>불량내용</InputGroup.Text>
                                <textarea className="form-control" rows="2"
                                                value={acceptContent || ''}
                                                onChange={e => changeAcceptContent(e, 'acceptContent')}
                                                disabled={isReadOnly}
                                                placeholder={'불량내용을 입력해주세요.'}
                                    />
                            </InputGroup>
                        </CCol>
                    </Row>
                    <Row>
                        <Col className='d-flex flex-row-reverse'>
                            {!isReadOnly && ( <div> <span style={{color: 'blue'}}>현장명 / 업체명 / 관리번호</span> 입력 후 Enter키(엔터키)를 눌러주세요. </div> )}
                        </Col>
                    </Row>
                    </>
                    }
                </CContainer>
                <CContainer>
                    <Row>
                        <CCol lg={12}>
                            <div className="mb-3">
                                <Subject><h5>접수 내역</h5></Subject>
                                <textarea className="form-control" rows="11"
                                            value={acceptDetail || ''}
                                            onChange={v => setAcceptDetail(v.target.value)}
                                            disabled={true}
                                />
                            </div>
                        </CCol>
                    </Row>
                    {(AsStore.data.asSeq && ['2','3','4','5'].includes(AsStore.data.asStatus)) && (
                        <Row>
                        <CCol lg={12}>
                            <div className="mb-3">
                                <Subject><h5>처리 내역</h5></Subject>
                                <textarea className="form-control" rows="3"
                                            placeholder="처리 내역을 작성해주세요."
                                            value={AsStore.data.processDetail || ''}
                                            onChange={v => AsStore.data.processDetail = v.target.value}
                                            disabled={!['1','2','3','4'].includes(AsStore.data.asStatus)}
                                />
                            </div>
                        </CCol>
                    </Row>
                    )}
                    <Row>
                        
                            { ( AsStore.data.asSeq && AsStore.data.asStatus !== '5') && (
                                <>
                                {/* {(!['5'].includes(AsStore.data.asStatus)) && */}
                                    <CIconBtn style={{flex: 1}}
                                            title={'AS 접수 취소'}
                                            onClick={() => changeStatus('9')}
                                            icon={'fi-rr-circle-xmark'}
                                            variant={'danger'}
                                            disabled={['5'].includes(AsStore.data.asStatus)}
                                    />
                                    <CIconBtn style={{flex: 1}}
                                            title={'미결'}
                                            onClick={() => changeStatus('8')}
                                            icon={'fi-rr-circle-xmark'}
                                            variant={'secondary'}
                                            disabled={['5'].includes(AsStore.data.asStatus)}
                                    />
                                {/* } */}
                                {/* {(!['2','3','4','5'].includes(AsStore.data.asStatus)) && */}
                                <CIconBtn style={{flex: 1}}
                                            title={'AS접수'}
                                            onClick={() => changeStatus('2')}
                                            icon={'fi-rr-checkbox'}
                                            variant={'success'}
                                            disabled={['2','3','4','5'].includes(AsStore.data.asStatus)}
                                    />
                                {/* } */}
                                {/* {(!['3','4','5'].includes(AsStore.data.asStatus)) &&  */}
                                    <CIconBtn style={{flex: 1}}
                                            title={'출발'}
                                            onClick={() => changeStatus('3')}
                                            icon={'fi-rr-marker-time'}
                                            variant={'success'}
                                            disabled={['3','4','5'].includes(AsStore.data.asStatus) || AsStore.data.asStatus === '1'}
                                        />
                                {/* } */}
                                {/* {(!['4','5'].includes(AsStore.data.asStatus) && (!AsStore.data.asManagerSeq || AsStore.data.asManagerSeq === userInfo.userSeq)) && */}
                                {/* {(!['4','5'].includes(AsStore.data.asStatus)) && */}
                                    <CIconBtn style={{flex: 1}}
                                            title={'도착'}
                                            onClick={() => changeStatus('4')}
                                            icon={'fi-rr-marker-time'}
                                            variant={'success'}
                                            disabled={['4','5'].includes(AsStore.data.asStatus) || AsStore.data.asStatus === '1'}
                                    />
                                {/* } */}
                                {/* { (['2','3','4'].includes(AsStore.data.asStatus) && (!AsStore.data.asManagerSeq || AsStore.data.asManagerSeq === userInfo.userSeq)) && */}
                                    <CIconBtn style={{flex: 1}}
                                            title={'AS 완료'}
                                            onClick={completeAs}
                                            icon={'fi-rr-hammer-crash'}
                                            variant={'primary'}
                                            disabled={!(['2','3','4'].includes(AsStore.data.asStatus) && (!AsStore.data.asManagerSeq || AsStore.data.asManagerSeq === userInfo.userSeq))}
                                    />
                                {/* } */}
                                </>
                            )}
                        {!AsStore.data.asSeq && (
                            <Col className='d-flex flex-row-reverse'>
                                        <CSaveBtn style={{width: 110}}
                                                title={'접수'}
                                                onClick={saveAsAccept}
                                        />
                            </Col>
                        )}
                    </Row>
                </CContainer>
                {!AsStore.data.asSeq && (
                    <>
                        <CContainer>
                            <Subject><h5>AS 처리 대상 관리번호 목록</h5></Subject>
                        </CContainer>
                        <CContainer search>
                            {AsStore.data.managementNoList.length > 0 && AsStore.data.managementNoList.map((v, i) => (
                                <Button variant="info" key={i} className="my-2 mx-2">
                                    {v.managementNo}&nbsp;&nbsp;&nbsp;
                                    <Badge pill bg="light" style={{verticalAlign: 'middle'}}>
                                        <a className="text-decoration-none" style={{color: 'black', verticalAlign: 'middle'}} href="#!" onClick={() => deleteManagementNo(i)}>x</a>
                                    </Badge>
                                </Button>
                            ))}
                        </CContainer>
                    </>
                )}
                {(['1','2','3','4'].includes(AsStore.data.asStatus)) && 
                    <CContainer>
                        { AsStore.data.asSeq &&
                        <CCol lg={4}>
                        <CInputGroup
                            label={'관리번호'}
                            value={managementNo}
                            onChange={v => setManagementNo(v.target.value)}
                            enterKeyHint={e => openAssetSearchPopup(e, 'list')}
                            />
                        </CCol>
                        }
                        <AgGridContainer
                            gridRef={gridRef}
                            gridTitle={'관리번호 목록'}
                            appendTitle={appendTitle()}
                            height={20}
                            rowData={AsStore.data.managementNoList}
                            columnDefs={[
                                {field: 'id', headerName: '순번', valueGetter: 'node.rowIndex + 1', width: 75},
                                {field: 'seq', headerName: '순번', width: 80, hide:true},
                                {field: 'managementNo', headerName: '관리번호', width: 110},
                                // {field: 'assetNo', headerName: '자산번호', width: 110},
                                // {field: 'model', headerName: '모델명', width: 100},
                                // {field: 'serialNo', headerName: '시리얼번호', width: 155},
                                {
                                    field: "productType",
                                    headerName: "장비구분",
                                    width: 100,
                                    valueFormatter: v => findCommKrnm(v.value, '68'),
                                    onCellValueChanged: v => changeMaintenanceCode(v),
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: {
                                        values: getCommcode('68').map(v=> v.value),
                                    },
                                    headerClass: 'grid-column-required',
                                    editable: true
                                },
                                {
                                    field: "lgName",
                                    headerName: "대분류",
                                    width: 100,
                                    cellRenderer: params => {
                                        if (params.data.productType && params.value != '') {
                                            const optionList = getSelectOptionValue(params.data.productType === '1' ? 'lg' : null, params.data.productType);
                                            
                                            if (typeof params.value === 'string') {
                                                const foundOption = optionList.find(v => v.name === params.value);
                                                AsStore.data.managementNoList[params.data.agId].lgValue = foundOption.value;
                                                return foundOption ? foundOption.name : params.value;
                                            } else if (typeof params.value === 'object' && params.value !== null) {
                                                AsStore.data.managementNoList[params.data.agId].lgValue = params.value.value;
                                                return params.value.name || params.value;
                                            }
                                            return params.value;
                                        }
                                    },
                                    onCellValueChanged: v => changeMaintenanceCode(v),
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: params => ({
                                        values: getSelectOptionValue(params.data.productType === '1' ? 'lg' : null, params.data.productType).map(code => code.name)
                                    }),
                                    headerClass: 'grid-column-required',
                                    editable: true
                                },
                                {
                                    field: "mdName",
                                    headerName: "중분류",
                                    width: 100,
                                    cellRenderer: params => {
                                        if (params.data.productType && params.value != '') {
                                            const optionList = params.data.productType === '1' ? getSelectOptionValue('md', params.data.productType) : params.data.lgValue ? getSelectOptionValue(params.data.lgValue, params.data.productType) : []
                                            
                                            if (typeof params.value === 'string') {
                                                const foundOption = optionList.find(v => v.name === params.value);
                                                AsStore.data.managementNoList[params.data.agId].mdValue = foundOption.value;
                                                return foundOption ? foundOption.name : params.value;
                                            } else if (typeof params.value === 'object' && params.value !== null) {
                                                AsStore.data.managementNoList[params.data.agId].mdValue = params.value.value;
                                                return params.value.name || params.value;
                                            }
                                            return params.value;
                                        }
                                    },
                                    onCellValueChanged: v => changeMaintenanceCode(v),
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: params => ({
                                        values: params.data.productType === '1' ? getSelectOptionValue('md', params.data.productType).map(code => code.name) : params.data.lgValue ? getSelectOptionValue(params.data.lgValue, params.data.productType).map(code => code.name) : ""
                                    }),
                                    headerClass: 'grid-column-required',
                                    editable: true
                                },
                                {
                                    field: "smName",
                                    headerName: "소분류",
                                    width: 100,
                                    cellRenderer: params => {
                                        if (params.data.productType && params.value != '') {
                                            const optionList = params.data.productType === '1' ? getSelectOptionValue('sm', params.data.productType) : params.data.mdValue ? getSelectOptionValue(params.data.mdValue, params.data.productType) : [];
                                            
                                            if (typeof params.value === 'string') {
                                                const foundOption = optionList.find(v => v.name === params.value);
                                                AsStore.data.managementNoList[params.data.agId].smValue = foundOption.value;
                                                return foundOption ? foundOption.name : params.value;
                                            } else if (typeof params.value === 'object' && params.value !== null) {
                                                AsStore.data.managementNoList[params.data.agId].smValue = params.value.value;
                                                return params.value.name || params.value;
                                            }

                                            return params.value;
                                        }
                                    },
                                    cellEditor: 'agSelectCellEditor',
                                    cellEditorParams: params => ({
                                        values: params.data.productType === '1' ? getSelectOptionValue('sm', params.data.productType).map(code => code.name) : params.data.mdValue ? getSelectOptionValue(params.data.mdValue, params.data.productType).map(code => code.name) : ""
                                    }),
                                    headerClass: 'grid-column-required',
                                    editable: true
                                },
                                {field: 'content', headerName: '정비내역', headerClass: 'grid-column-required', editable: true, flex: 1},
                            ]}
                            isCheckBox={true}
                            getCheckboxClickEvent={getCheckboxClickEvent}
                            seqColumn={'agId'}
                            customBtnInfo={[customAddBtnInfo]}
                            useRemoveRow={true}
                            // useUpdated={true}
                            // callBackGridData={saveFn}
                            removeCallback={removeFn}
                        />
                    </CContainer>
                }
                {(AsStore.data.asSeq && AsStore.data.asStatus === '5') && (
                    <>
                        <CContainer>
                            <AgGridContainer
                                gridRef={maintenanceGridRef}
                                gridTitle={'정비 목록'}
                                height={20}
                                rowData={AsStore.data.maintenanceList}
                                columnDefs={[
                                    {field: 'seq', headerName: '순번', valueGetter: 'node.rowIndex + 1', width: 90},
                                    {field: 'maintenanceSeq', headerName: '정비번호', width: 100},
                                    {field: 'managementNo', headerName: '관리번호', width: 120},
                                    {field: 'assetNo', headerName: '자산번호', width: 120},
                                    {field: 'content', headerName: '정비일지', flex: 1},
                                ]}
                                seqColumn={'agId'}
                                useRowSearch={true}
                                rowSearchCallback={v => openMaintenanceDetail(v)}
                            />
                        </CContainer>
                    </>
                )}
                <SiteNameSearch callbackFn={searchSiteInfo} gridData={gridData}/>
                <AsManagerUserSearch callbackFn={getUserInfo} column={userColumn} btnStatus={btnStatus} search={searchUser} />
                <AssetSearch callbackFn={callbackAssetInfo} isAsManagement={true} inputManagementNo={inputManagementNo} gridData={managementNoData}/>
                {/* <SiteManagerSearch callbackFn={searchSiteManager} siteSeq={siteSeq}/> */}
                <CustomerSearch callbackFn={searchCustomerInfo} gridData={customerData} inputCustomerName={AsStore.data.customerName}/>
                <SiteCustomerManagerSearch callbackFn={searchSiteCustomerManager} siteSeq={AsStore.data.siteSeq} customerNo={AsStore.data.customerNo}  />
            </Modal>
        </>
    )

}

export default observer(AsDetailPopup);