import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { CCol, CContainer, CInputGroup, IconBtn, CalendarGroup } from "../../components/CustomContainer";
import { Row } from "react-bootstrap";
import { StorageRegisterStore } from "../../store/storage/StorageRegisterStore";
import { StorageRegisterListStore } from "../../store/storage/StorageRegisterListStore";
import { AppStore } from "../../store/AppStore";
import { callConfirm } from "../../utils";
import AgGridContainer from "../../components/AgGridContainer";
import {dateDiff, today} from "../../utils/dateUtils";
import {findCommKrnm, getCommcode} from "../../utils/commcode";
import {showToast} from "../../common/utils";
import {getUserName} from "../../utils/userUtils";
import AssetOptionPopup from "./popup/AssetOptionPopup";
import contractUtils from "../contract/contractUtils";
import { STATUS_LEVEL } from "../../common/constants";

const StorageReturn = () => {
    const optionRef = useRef();
    const [inputNo, setInputNo] = useState('');
    const returnStorageGridRef = useRef();

    const [selAssetNo, setSelAssetNo] = useState('');
    const [selContractNo, setSelContractNo] = useState('');
    const [optionList, setOptionList] = useState([]);

    useEffect(()=> {
        StorageRegisterListStore.newEndDate.startDate = today();
    }, [])

    const assetStatuses = {
        101: "입고검수중",
        200: "임대가능",
        307: "경정비",
        308: "중정비",
        309: "중세차",
    };

    const returnStorageColumnDefs = [
        {field: "seq", headerName: "순번", width: 80},
        {
            field: "storageStatus",
            headerName: "입고상태",
            width: 100,
            valueFormatter: v => dateDiff(StorageRegisterListStore.newEndDate.startDate, v.data.contractEndDate) > 1 ? '선입고' : '정상입고',
            cellClass: v => dateDiff(StorageRegisterListStore.newEndDate.startDate, v.data.contractEndDate) > 1 ? 'ag-grid-column-cancel' : ''
        },
        {
            field: "storageType",
            headerName: "입고구분",
            width: 100,
            valueFormatter: v => findCommKrnm(v.value, '13'),
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: getCommcode('13').map(v => v.value),
            },
            headerClass: 'grid-column-editable',
            editable: true
        },
        {
            field: "assetStatus",
            headerName: "자산상태",
            width: 100,
            valueFormatter: v => assetStatuses[v.value] || '',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: Object.keys(assetStatuses)
            },
            headerClass: 'grid-column-editable',
            editable: true
        },
        {field: "storageDate", headerName: "입고일자", width: 120},
        {field: "contractStartDate", headerName: "개시일자", width: 120},
        {field: "contractEndDate", headerName: "만기일자", width: 120},
        {field: "lgCate", headerName: "대분류", width: 100, hide: true},
        {field: "managementNo", headerName: "관리번호", width: 120},
        {field: "assetNo", headerName: "자산번호", width: 100},
        {field: "assetType", headerName: "자산구분", width: 100, valueFormatter: v => findCommKrnm(v.value, '7'),},
        {field: "serialNo", headerName: "시리얼번호", width: 130},
        {field: "productSeq", headerName: "제품SEQ", width: 120, hide: true},
        {field: "productName", headerName: "제품명", width: 120},
        {field: "model", headerName: "모델명", width: 120},
        {field: "customerName", headerName: "고객명", width: 140},
        {field: "siteName", headerName: "현장명", width: 150},
        {field: "contractNo", headerName: "계약번호", width: 160},

        {
            field: "newRentMonth", 
            headerName: "사용개월수",
            width: 110
        },
        {
            field: "newRentDays", 
            headerName: "당월사용일수", 
            width: 120
        },
        {
            field: "shortRentPrice", 
            headerName: "예상청구렌탈료", 
            editable: true, 
            headerClass: 'grid-column-required',
            valueFormatter: v => v.value ? v.value.toLocaleString() : '', 
            cellClass: 'ag-grid-money-align',
            width: 140
        },
        {
            headerName: '운반비',
            children: [
                {
                    field: "deliveryPrice", 
                    headerName: "운반비", 
                    editable: true, 
                   // valueFormatter: v => v.data.isDeliveryPrice === 1 ? v.value ? v.value.toLocaleString() : '' : '없음',
                    cellClass: v => v.data.isDeliveryPrice === 1 ? 'ag-grid-money-align' : '',
                    width: 90
                },
                {
                    field: "deliveryAttribute", 
                    headerName: "운반비부담", 
                    // editable: true, 
                    valueFormatter: v => v.data.isDeliveryPrice === 1 ? v.data.chargeDeliveryName + "(" + v.data.deliveryKindName + ")" : '', 
                    // cellClass: 'ag-grid-money-align',
                    width: 150
                },
                {
                    field: "billingDeliveryPrice", 
                    headerName: "운반비 청구", 
                    editable: true, 
                    tooltipValueGetter: v => '운반비 입력 시 청구가 생성됩니다.', 
                    headerClass: 'grid-column-editable',
                    valueFormatter: v => v.value ? v.value.toLocaleString() : '', 
                    cellClass: 'ag-grid-money-align',
                    width: 150
                },
            ]
        },        
        {
            field: "exteriorStatus", 
            headerName: "외관상태", 
            editable: true,
            headerClass: 'grid-column-required',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: STATUS_LEVEL.filter(v => v.value).map(v => v.value) || 'A',
            },
            width: 130
        },
        {
            field: "tireStatus", 
            headerName: "타이어상태", 
            editable: true,
            headerClass: 'grid-column-required',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: STATUS_LEVEL.filter(v => v.value).map(v => v.value) || 'A',
            },
            width: 130
        },
        {
            headerName: '지게차 속성',
            children: [
                { field: "operationTime", headerName: "가동시간", minWidth: 150, headerClass: 'grid-column-editable', editable: v => v.data.lgCate === '지게차' ? true : false },
                { field: "mileage", headerName: "주행거리", minWidth: 150, headerClass: 'grid-column-editable', editable: v => v.data.lgCate === '지게차' ? true : false }
            ]
        },
        {field: "remark", headerName: "입고비고", minWidth: 150, headerClass: 'grid-column-editable', editable: true},
        {field: "optionCount", headerName: "옵션수량", width: 120, cellRenderer: (e) => popupAssetOptionInfo(e),  headerClass: 'grid-column-editable', editable: v => v.data.storageType === 'sto_option' ? true : false},
        {field: "contractType", headerName: "계약구분", width: 100, valueFormatter: v => findCommKrnm(v.value, '1')},
        {field: "contAssetStatus", headerName: "계약자산구분", width: 120, valueFormatter: v => findCommKrnm(v.value, '2')},
        {field: "saleUserSeq", headerName: "영업담당", width: 120, valueFormatter: v => getUserName(v.value)},
        {field: "returnAskSeq", headerName: "회수의뢰번호", width: 120, hide: true},
    ];

    const getAssetInfo =async(number)=> {
        if (!number) {
            return;
        }

        if(StorageRegisterListStore.returnStorageList.some(v => v.assetNo === number || v.managementNo === number || v.serialNo === number)){
            showToast('이미 입력된 자산 입니다.');
            resetInputField();
            return false;
        }

        const assetInfo = await axios.get(`/storage/asset`, {params: {inputNo: number, newEndDate: StorageRegisterListStore.newEndDate.startDate}});

        if ( assetInfo.isFix === 0) {
            showToast("계약번호: "+assetInfo.contractNo+" 미확정 상태 입니다. 확정 먼저 해주세요.");
            return;
        }

        if (assetInfo) {
            if (assetInfo.conditionStatus === '1') {
                showToast('이미 입고된 자산 입니다.');
                return;
            }
            if (assetInfo.conditionStatus === '2' && assetInfo.assetStatus !== '302') {
                showToast(`${findCommKrnm(assetInfo.assetStatus, '8')} 자산 입니다.`);
                return;
            }
            if (assetInfo.conditionStatus === '4') {
                showToast(`매각 자산 입니다.`);
                return;
            }

            if(!assetInfo.contractType){
                showToast(`계약된 자산이 아닙니다.`);
                return;
            }

            if ( assetInfo.contractType !== '1' && assetInfo.contractType !== '2') {
                showToast(`${findCommKrnm(assetInfo.contractType, '1')} 자산으로 입고될 수 없습니다.`);
                return;
            }

            if(dateDiff(assetInfo.contractStartDate, StorageRegisterListStore.newEndDate.startDate) < 0) {
                alert('반납일자를 계약 개시일보다 크게 설정해주세요. \n개시일자 : '+assetInfo.contractStartDate);
                return;
            }

            let index = StorageRegisterListStore.returnStorageList.length + 1;
            const row = {
                addRowId: index,
                seq: index,
                storageDate: StorageRegisterListStore.newEndDate.startDate, // 입고일자
                storageType: 'sto_return',
                isCreated: true,
                isUpdated: true,
            }

            // 수리 자산일 경우
            // if(assetInfo.assetStatus === '305' && assetInfo.assetRepairNo) {
            //     row.storageType = 'sto_repair';
            // }

            // if(!assetInfo){
            //     if(confirm('등록된 자산 정보가 없습니다. 기타입고로 등록 하시겠습니까?')){
            //         row.storageType = 'sto_etc';
            //         row.serialNo = StorageRegisterStore.search.inputNo;
            //     }else{
            //         resetInputField();
            //         return false;
            //     }
            // }

            const result = await axios.get('/asset/option', {params: {assetNo: assetInfo.assetNo}});
            assetInfo.assetStatus = 200;
            result.assetStatus = 200;
            StorageRegisterListStore.returnInfo = Object.assign(row, assetInfo);
            StorageRegisterListStore.returnInfo.optionList = result;

            /* 반납일자 관련 계산 */
            const info = StorageRegisterListStore.returnInfo;
            //반납일자를 오늘로 설정한다.
            StorageRegisterListStore.returnInfo.newEndDate=StorageRegisterListStore.newEndDate.startDate;
            
            //계산로직 함수로 빼야함.
            //사용 개월수 와 마지막달 사용일수를 계산한다.
            const contractStartDate = info.contractStartDate;
            const contractEndDate = info.contractEndDate;
            const targetDate = StorageRegisterListStore.newEndDate.startDate;
            const { rentMonth, rentDays } = contractUtils.calcShortRentMonthAndDays(contractStartDate, targetDate);
    
            StorageRegisterListStore.setReturnInfo('newRentMonth', rentMonth);
            StorageRegisterListStore.setReturnInfo('newRentDays', rentDays);
    
            let newRentMonth = 0;
            //당월 청구금액을 정산한다.
            //계약 종료일 - 반납일자가 6일 보다 작으면 마지막달 렌탈료 가져오기
            //5일보다 크면 월렌탈료 
            const dayDiff = dateDiff(targetDate, contractEndDate);
            if(dayDiff < 5) {
                newRentMonth = StorageRegisterListStore.returnInfo.lastMonthBillingPrice;   // 마지막달 렌탈료
            }else {
                newRentMonth = info.monthRentPrice;   // 월렌탈료
            }
            // StorageRegisterListStore.setReturnInfo('shortRentPrice', newRentMonth);
            StorageRegisterListStore.setReturnInfo('tireStatus', 'A');
            StorageRegisterListStore.setReturnInfo('exteriorStatus', 'A');
            //종료일자 설정
            StorageRegisterListStore.setReturnInfo('shortContractEndDate', StorageRegisterListStore.newEndDate.startDate);

            //반납정보설정
            StorageRegisterListStore.pushReturnStorageList({...StorageRegisterListStore.returnInfo, isSelected: true, isCreated: true});

            resetInputField();
        }else{
            showToast('등록된 장비가 없습니다.');
        }
    }

    const setReturnInfo =(v)=> {
        if (StorageRegisterListStore.returnStorageList.length > 0) {
            const managementNo = getDateDiff(new Date(v));
            if(managementNo) {
                return showToast(managementNo + '의 계약 개시일보다 반납일자가 작습니다.');
            }
            for (let i=0; i < StorageRegisterListStore.returnStorageList.length; i++) {
                //반납일자 설정
                StorageRegisterListStore.setNewEndDate(v);
                StorageRegisterListStore.returnStorageList[i].newEndDate=v;

                //계산로직 함수로 빼야함.
                //사용 개월수 와 마지막달 사용일수를 계산한다.
                const contractStartDate = StorageRegisterListStore.returnStorageList[i].contractStartDate;
                const contractEndDate = StorageRegisterListStore.returnStorageList[i].contractEndDate;
                const targetDate = v;
                const { rentMonth, rentDays } = contractUtils.calcShortRentMonthAndDays(contractStartDate, targetDate);

                StorageRegisterListStore.returnStorageList[i].newRentMonth = rentMonth;
                StorageRegisterListStore.returnStorageList[i].newRentDays = rentDays;

                let newRentMonth = 0;
                //당월 청구금액을 정산한다.
                //계약 종료일 - 반납일자가 6일 보다 작으면 마지막달 렌탈료 가져오기
                //5일보다 크면 월렌탈료 
                const dayDiff = dateDiff(targetDate, contractEndDate);
                if(dayDiff < 5) {
                    newRentMonth = StorageRegisterListStore.returnStorageList[i].lastMonthBillingPrice;   // 마지막달 렌탈료
                }else {
                    newRentMonth = StorageRegisterListStore.returnStorageList[i].monthRentPrice;   // 월렌탈료
                }
                StorageRegisterListStore.returnStorageList[i].shortRentPrice = newRentMonth;
                // 종료일자 설정
                StorageRegisterListStore.returnStorageList[i].shortContractEndDate = StorageRegisterListStore.newEndDate.startDate;
                // 입고일자 변경
                StorageRegisterListStore.returnStorageList[i].storageDate = StorageRegisterListStore.newEndDate.startDate;
            }
        }
        StorageRegisterListStore.setNewEndDate(v);
    }

    const getDateDiff = (targetDate) => {    
        for (let item of StorageRegisterListStore.returnStorageList) {
            const startDate = new Date(item.contractStartDate);
            if (startDate > targetDate) {
                return item.managementNo; // 조건을 만족하면 바로 managementNo 반환
            }
        }
    
        return null;
    };

    /* 반납 입고 저장 */
    const onStorageReturnAsset = async ({createdList}) => {
        
        // if(!StorageRegisterListStore.returnInfo.managementNo){
        //     showToast('입고 할 정비 번호를 입력해 주세요.');
        //     return;
        // }
        const checkBillingDeliveryPrice = StorageRegisterListStore.returnStorageList.every(v => v.billingDeliveryPrice);
        if (!checkBillingDeliveryPrice) {
            if (!await callConfirm('운반비가 입력되지 않은 입고 건이 있습니다. 운반비를 청구하지 않고 입고하시겠습니까?')) {
                return false;
            }
        }

        let alertComment = '';
        if(!StorageRegisterListStore.returnStorageList.every(v => v.shortRentPrice)){
            alertComment = '당월 청구금액이 없는 자산이 있습니다. 당월 청구금액 없이 ';
        }

        if (!await callConfirm(`${alertComment}입고처리 하시겠습니까?`)) {
            return false;
        }

        //금액의 , 제거
        // if(StorageRegisterListStore.returnInfo.shortRentPrice){
        //     console.log("price: ", StorageRegisterListStore.returnInfo.shortRentPrice);
        //     StorageRegisterListStore.setReturnInfo('shortRentPrice', StorageRegisterListStore.returnInfo.shortRentPrice.replaceAll(',', ''));
        // }
        await axios.post(`/storage/asset/list/awp`, StorageRegisterListStore.returnStorageList);
        StorageRegisterListStore.setReturnAssetOptionList([]);
        StorageRegisterListStore.returnInfoInit();
        StorageRegisterListStore.returnStorageList = [];

        showToast('입고되었습니다');
    }

    /* 자산 팝업 UI */
    const popupAssetOptionInfo = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div className="mr-1">{e.data.optionCount}</div>
                {e.data.optionCount > 0 && (
                    <IconBtn key={e.rowIndex} 
                        onClick={() => {
                            setSelAssetNo(e.data.assetNo);
                            setSelContractNo(e.data.contractNo);
                            StorageRegisterStore.search.selAssetNo = e.data.assetNo;
                            setOptionList(e.data.optionList);
                            AppStore.toggleOpenAssetOptionPopup();
                        }} 
                        style={{paddingTop: 6, color:'darkblue', textAlign: 'right'}} 
                    />
                )}
            </div>
        )
    }

    const resetInputField =()=> {
        setInputNo('');
        if(StorageRegisterListStore.returnInfoInit.managementNo) {
            StorageRegisterListStore.returnInfoInit();
            StorageRegisterListStore.setReturnAssetOptionList([]);
        }
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={12}>
                        {/* 반납입고 */}
                        <Row>
                            <CCol lg={12} style={{paddingTop: 3, color: 'red'}}>
                                *관리번호 또는 시리얼 번호를 입력한 후 엔터키를 입력해 주세요.
                            </CCol>
                            <CCol lg={3}>
                                <CInputGroup
                                    ref={(ref)=> ref}
                                    label={'관리번호 또는 시리얼 번호'}
                                    labelId={'inputNo'}
                                    value={inputNo}
                                    placeholder={'장비번호 입력 후 Enter'}
                                    onChange={(e)=> setInputNo(e.target.value)}
                                    enterKeyHint={()=> getAssetInfo(inputNo)}
                                />
                            </CCol>
                            <CCol lg={2}>
                                <CalendarGroup
                                    asSingle={true}
                                    label={'반납일자'}
                                    value={StorageRegisterListStore.newEndDate}
                                    onChange={v => setReturnInfo(v.startDate)}
                                    // disabled={!StorageRegisterStore.goodsInfo.orderSeq}
                                />
                            </CCol>
                        </Row>
                    </CCol>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridRef={returnStorageGridRef}
                    gridTitle={'입고 대상'}
                    height={55}
                    rowData={StorageRegisterListStore.returnStorageList}
                    columnDefs={returnStorageColumnDefs}
                    seqColumn={'agId'}
                    originList={[]}
                    useUpdated={true}
                    isCheckBox={true}
                    callBackGridData={onStorageReturnAsset}
                    getRowStyle={(params) => {
                        if (params.data.contractType !== '1' && params.data.contractType !== '2') {
                            return {color: 'red'};
                        }
                        return null;
                    }}
                />
            </CContainer>

            {/* modal */}
            <AssetOptionPopup assetNo={StorageRegisterStore.search.selAssetNo} list={optionList} />
        </>
    )
}

export default observer(StorageReturn);