import { observable } from "mobx";

export const CardStatementStore = observable({
    cardStatementList: [],
    search: {
        startDate: '',
        endDate: '',
        accountType: 'input',
        cardCode: '',
        searchType: 'preStatement',
        cardNumber: '',
    },
    accountingCodeList: [],
})