import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Row, Button, Modal } from "react-bootstrap";

import { AppStore } from "../../../store/AppStore";
import { CContainer, CCol, CSelectGroup } from "../../../components/CustomContainer";
import { showToast } from "../../../common/utils";

const CarryoverChargePopup = ({callbackFn}) => {
    const [carryoverMonth, setCarryoverMonth] = useState('');

    const closePopup = () => {
        setCarryoverMonth('');
        AppStore.toggleCarryoverChargePopup()
    }

    return (
        <>
            <Modal show={AppStore.isOpenCarryoverChargePopup}
                   onHide={closePopup}
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title>청구 이월</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CContainer search>
                        <Row style={{marginTop: 13}}>
                            <CCol lg={12}>
                                <CSelectGroup label={'이월 할 개월수'} labelId={'carryoverMonth'}
                                    options={[
                                        {name: '선택', value: ''},
                                        {name: '1개월', value: 1},
                                        {name: '2개월', value: 2},
                                        {name: '3개월', value: 3},
                                        {name: '4개월', value: 4},
                                        {name: '5개월', value: 5},
                                        {name: '6개월', value: 6},
                                        {name: '7개월', value: 7},
                                        {name: '8개월', value: 8},
                                        {name: '9개월', value: 9},
                                        {name: '10개월', value: 10},
                                        {name: '11개월', value: 11},
                                        {name: '12개월', value: 12},
                                    ]}
                                    value={carryoverMonth}
                                    onChange={v => setCarryoverMonth(v.target.value)}
                                />
                            </CCol>
                        </Row>
                    </CContainer>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closePopup}>
                        취소
                    </Button>
                    <Button variant="primary"
                            onClick={()=> {
                                if(carryoverMonth) {
                                    closePopup();
                                    callbackFn(carryoverMonth);
                                }else {
                                    showToast('이월 할 개월수를 선택해 주세요.');
                                }
                            }}>
                        이월
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default observer(CarryoverChargePopup);
