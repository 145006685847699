import { useState, useEffect } from "react";
import axios from "axios";
import { Row, InputGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { observer } from "mobx-react-lite";

import { AppStore } from "../../store/AppStore";
import { CContainer } from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";
import Modal from "../Modal";

const AccountingCodeSearch = ({callbackFn=null, ...props}) => {
    const [type, setType] = useState('name');
    const [inputText, setInputText] = useState('');
    const [AccountingCodeList, setAccountingCodeList] = useState([]);

    useEffect(() => {
        if(props.inputCode === ''||props.inputCode === null){
            setInputText('');
        } else {
            onSearchAccountingCode('code', props.inputCode);
            setType('code');
        }
    }, [props.inputCode]);

    const onSearchAccountingCode = async (propsType, PropsInput) => {
        const result = await axios.get('statement/accounting/code', {params: {type: propsType?propsType:type, inputText: PropsInput?PropsInput:inputText}});
        setAccountingCodeList(result);
    }

    const selectedRow = (e) => {
        callbackFn && callbackFn(e.data);
        AppStore.toggleAccountingCodePopup();
    }

    const refresh = () => {
        setType('name');
        setInputText('');
        setAccountingCodeList([]);
    }

    return (
        <Modal
            title={'계정과목조회'}
            onHide={() => AppStore.toggleAccountingCodePopup()}
            toggleValue={AppStore.isOpenAccountingCodePopup}
            className={'search-modal-h5'}
            size={'sm'}
            onExit={refresh}
        >
            <CContainer>
                <Row>
                    <InputGroup className="mb-3">
                        <DropdownButton
                            variant="outline-secondary"
                            title={type === 'name' ? '계정과목명' : '계정과목코드'}
                            id="input-group-dropdown-1"
                        >
                            <Dropdown.Item onClick={() => setType('code')}>계정과목코드</Dropdown.Item>
                            <Dropdown.Item onClick={() => setType('name')}>계정과목명</Dropdown.Item>
                        </DropdownButton>
                        <input
                            className="form-control"
                            onKeyDown={event => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                    onSearchAccountingCode();
                                }
                            }}
                            onInput={v => setInputText(v.target.value)}
                        />
                    </InputGroup>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    height={26}
                    rowData={AccountingCodeList}
                    columnDefs={[
                            {field: "code", headerName: "코드", width: 77},
                            {field: "name", headerName: "계정과목", width: 110, flex: 1},
                        ]}
                    enableCellTextSelection={true}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(AccountingCodeSearch);
