import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import { Row, Col } from "react-bootstrap";
import dayjs from "dayjs";

import { CContainer, CInputGroup, CCol, CSaveBtn, CAddBtn, CalendarGroup, CSelectGroup, CInputDoubleGroup, Subject } from '../../components/CustomContainer';
import {callAlert, callConfirm} from '../../utils';
import { OrderStore } from '../../store/product/OrderStore';
import AgGridContainer from '../../components/AgGridContainer';
import { OrderProductStore } from '../../store/product/OrderProductStore';
import { getCommcode } from '../../utils/commcode';
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import { AppStore } from "../../store/AppStore";
import { ICON_ADD } from '../../common/constants';
import ProductListSearch from '../../components/searchModal/ProductListSearch';
import UserSearch from '../../components/searchModal/UserSearch';
import { getLocalStorage } from '../../utils';
import { USER_INFO } from '../../common/constants';
import {showToast} from "../../common/utils";

const OrderDetail = ({orderData}) => {
    const userInfo = getLocalStorage(USER_INFO);
    const [orderParams, setOrderParams] = useState({
        orderSeq: '',
        orderTitle: '',
        orderType: '',
        orderStatus: 0,
        orderStatusText: '작성중',
        companyCode: '',
        orderUserSeq: userInfo.userSeq,
        orderUserName: userInfo.name,
        buyCustomerNo: '',
        buyCustomerName: '',
        orderCustomerNo: '',
        orderCustomerName: '',
        approvalNo: '',
        orderDate: dayjs().format("YYYY-MM-DD"),
        approvalDate: '',
        storageDate: '',
        departmentCode: '',
        deliveryPlace: '',
        remark: '',
        exchangeRateApplyDate: '',
        exchangeRate: '',
        currencyUnit: '',
        deliveryCost: '',
        etcCost: '',
    });
    const [orderDate, setOrderDate] = useState({startDate: new Date(), endDate: new Date()});
    const [storageDate, setStorageDate] = useState({startDate: '', endDate: ''});
    const [approvalDate, setApprovalDate] = useState({startDate: '', endDate: ''});
    const [exchangeRateApplyDate, setExchangeRateApplyDate] = useState({startDate: '', endDate: ''});
    const [orderProductList, setOrderProductList] = useState([]);
    const [customerType, setCustomerType] = useState('');
    const [isCompleted, setIsCompleted] = useState(false);

    const gridRef = useRef();

    useEffect(() => {
        if(orderData.orderSeq) {
            setOrderParams({
                orderSeq: orderData.orderSeq,
                orderTitle: orderData.orderTitle,
                orderType: orderData.orderType,
                orderStatus: orderData.orderStatus,
                orderStatusText: orderData.orderStatus === 1 ? '발주완료' : orderData.orderStatus === 2 ? '입고완료' : '작성중',
                companyCode: orderData.companyCode,
                orderUserSeq: orderData.orderUserSeq,
                orderUserName: orderData.orderUserName,
                buyCustomerNo: orderData.buyCustomerNo,
                buyCustomerName: orderData.buyCustomerName,
                orderCustomerNo: orderData.orderCustomerNo,
                orderCustomerName: orderData.orderCustomerName,
                approvalNo: orderData.approvalNo,
                orderDate: orderData.orderDate,
                approvalDate: orderData.approvalDate,
                storageDate: orderData.storageDate,
                departmentCode: orderData.departmentCode,
                deliveryPlace: orderData.deliveryPlace,
                remark: orderData.remark,
                exchangeRateApplyDate: orderData.exchangeRateApplyDate,
                exchangeRate: orderData.orderStatus > 1 ? orderData.exchangeRate?.toLocaleString() : orderData.exchangeRate,
                currencyUnit: orderData.currencyUnit,
                deliveryCost: orderData.orderStatus > 1 ? orderData.deliveryCost?.toLocaleString() : orderData.deliveryCost,
                etcCost: orderData.orderStatus > 1 ? orderData.etcCost?.toLocaleString() : orderData.etcCost,
            });
            setOrderDate({startDate: orderData.orderDate, endDate: orderData.orderDate});
            setStorageDate({startDate: orderData.storageDate, endDate: orderData.storageDate});
            setApprovalDate({startDate: orderData.approvalDate, endDate: orderData.approvalDate});
            setExchangeRateApplyDate({startDate: orderData.exchangeRateApplyDate, endDate: orderData.exchangeRateApplyDate});

            getOrderProduct(orderData.orderSeq);

            if(orderData.orderStatus >= 1){
                setIsCompleted(true);
            }
        }
    }, []);

    /* 발주 조회 */
    const getOrderList = async () => {
        const result = await axios.get('order/list', { params: OrderStore.orderSearchCondition });
        OrderStore.setOrderList(result);
    }

    /* order_product 조회 */
    const getOrderProduct = async (orderSeq) => {
        const result = await axios.get('/orderProduct', { params: {orderSeq: orderSeq} });
        OrderProductStore.setOrderProductList(result);
        setOrderProductList(result);
    }

    /* input value 담아주기 */
    const onChangeValue = (v, label) => {
        setOrderParams(param => ({...param, [label]: v.target.value}));
    }

    /* 발주금액 값 담아주기 */
    const addTotalPrice = (v) => {
        const { orderAmount, orderPrice } = v.data;
        const totalPrice = orderAmount * orderPrice;
        v.data.totalPrice = totalPrice;

        return (
            <>
                <div>{totalPrice.toLocaleString()}</div>
            </>
        );
    };

    /* 날짜 선택 */
    const onChangeSearchDate = (v, target) => {
        if(target === 'orderDate'){
            setOrderDate(v);
        }else if(target === 'storageDate'){
            setStorageDate(v);
        }else if(target === 'approvalDate'){
            setApprovalDate(v);
        }else if(target === 'exchangeRateApplyDate'){
            setExchangeRateApplyDate(v);
        }
        setOrderParams(param => ({...param, [target]: v.startDate}));
    }


    /* 품의번호 링크 클릭 */
    const searchApprovalNo = v => {
        window.open(orderParams.approvalNo, '품의링크', 'width=700px,height=800px,scrollbars=yes');
    }


    /* 발주 등록 */
    const createOrder = async () => {
        // 필수값 확인
        const requiredParams = ['orderType', 'orderDate', 'buyCustomerNo', 'storageDate', 'deliveryPlace'];

        if (!requiredParams.every(param => orderParams[param])) {
            showToast('필수값을 모두 입력해야 합니다.');
            return;
        }

        if (!await callConfirm('저장 하시겠습니까?')) {
            return false;
        }

        // 파라미터 세팅
        const params = orderParams;

        // 새등록/수정 구분
        if(orderParams.orderSeq){
            const result = await axios.put('/order', params);
            showToast('수정되었습니다.');
        }else {
            const result = await axios.post('/order', params);
            showToast('저장되었습니다.');
            setOrderParams(param => ({...param, orderSeq: result[0].orderSeq}));
        }
        await Promise.all([getOrderList(), getOrderProduct(orderParams.orderSeq)]);
    }

    /* 새발주 */
    const addCreateOrder = async () => {
        // 초기화
        setOrderParams({
            orderSeq: '',
            orderTitle: '',
            orderType: '',
            orderStatus: 0,
            orderStatusText: '작성중',
            companyCode: '',
            orderUserSeq: '',
            orderUserName: '',
            buyCustomerNo: '',
            orderCustomerNo: '',
            approvalNo: '',
            orderDate: dayjs().format("YYYY-MM-DD"),
            approvalDate: null,
            storageDate: null,
            departmentCode: '',
            deliveryPlace: '',
            remark: '',
            exchangeRateApplyDate: '',
            exchangeRate: '',
            currencyUnit: '',
            deliveryCost: '',
            etcCost: '',
        });
        setOrderDate({startDate: null, endDate: null});
        setStorageDate({startDate: null, endDate: null});
        setApprovalDate({startDate: null, endDate: null});
        setExchangeRateApplyDate({startDate: null, endDate: null});
        setIsCompleted(false);
        OrderProductStore.setOrderProductList([]);

    }

    const columnDefs=[
        {field: "seq", headerName: "시퀀스", hide:true},
        {field: "productSeq", headerName: "제품 시퀀스", hide:true},
        {field: "productCode", headerName: "제품코드", width: 100},
        {field: "model", headerName: "모델명", width: 150,},
        {field: "productName", headerName: "제품명", width: 200, editable: true},
        {field: "remark", headerName: "비고", minWidth: 110, editable: true},
        {field: "orderAmount", headerName: "발주수량", headerClass: 'grid-column-required', width: 110, editable: true},
        {field: "orderPrice", headerName: "발주단가", headerClass: 'grid-column-required', width: 150, valueFormatter: v => v.data.orderPrice.toLocaleString(), cellClass: 'ag-grid-money-align',  editable: true},
        {field: "totalPrice", headerName: "발주금액", headerClass: 'grid-column-required', width: 150, cellRenderer: addTotalPrice, valueGetter: ({ data }) => data.orderAmount * data.orderPrice, cellClass: 'ag-grid-money-align'},
        {field: "duty", headerName: "관세", headerClass: 'grid-column-editable', width: 100, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', editable: true},
        {
            field: "newOrUsed", 
            headerName: "신품/중고", 
            width: 120,
            valueFormatter: v => v.value === '1' ? '신품' : v.value === '2' ? '중고' : '',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['1','2'],
            },
            headerClass: 'grid-column-required',
            editable: true,
        },
        {field: "storageCnt", headerName: "입고수량", width: 110},
        {field: "spec", headerName: "스펙", headerClass: 'grid-column-required', width: 200, editable: true},
    ];

    /* 저장버튼 클릭 이벤트 */
    const callBackGridData = async (gridData) => {
        // 필수값 담기
        const requiredParams = ['productCode', 'orderAmount', 'orderPrice', 'totalPrice', 'newOrUsed'];
        if(gridData.createdList.some(item => !requiredParams.every(param => item[param]))) {
            showToast('필수값을 모두 입력해야 합니다.');
            return;
        }
        // 수정 주석처리
        // const params = {orderProductList: gridData.updatedList};
        // await axios.put('/orderProduct', params);
        const params = {orderProductList: gridData.createdList};
        const orderSeq = await axios.post('/orderProduct', params);

        showToast('저장되었습니다.');
        await Promise.all([getOrderList(), getOrderProduct(orderSeq)]);
    }

    /* 제품 추가 버튼 */
    const customAddProduct = {
        isUsed: true,
        callbackFn:() => {
            if(!orderParams.orderSeq){
                showToast('발주 마스터 정보를 등록후 진행해주세요.');
                return;
            }
            AppStore.toggleProductListSearchModal()
        },
        icon: ICON_ADD,
        title: '제품추가',
        width: 90,
    }

    /* 제품 추가 callback */
    const callbackProductInfo = (info) => {
        const addIndex = OrderProductStore.orderProductList.filter(v => v.isCreated).length || 0; // TODO: billingList + 1 check

        const addRow = {
            productSeq: info.productSeq,
            productCode: info.productCode,
            model: info.model,
            productName: info.productName,
            orderAmount: '',
            orderPrice: '',
            totalPrice: '',
            storageCnt: '',
            spec: info.spec,
            remark: '',
            orderSeq: orderParams.orderSeq,
            isCreated: true,
            addRowId: addIndex,
        };
        OrderProductStore.setOrderProductList([...OrderProductStore.orderProductList, addRow ]);
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = () => {
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        setOrderParams(param => ({...param, orderUserSeq: userInfo.userSeq, orderUserName: userInfo.name}));
    }

    /* 고객 조회 팝업 */
    const searchCustomerPopup = (customerType) => {
        setCustomerType(customerType);
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 조회 콜백 */
    const searchCustomerInfo =(data)=> {

        if(data){
            switch(customerType){
                case 'buyCustomer' : {
                    setOrderParams(param => ({...param, buyCustomerNo: data.customerNo, buyCustomerName: data.customerName}));
                    break;
                }
                case 'orderCustomer' : {
                    setOrderParams(param => ({...param, orderCustomerNo: data.customerNo, orderCustomerName: data.customerName}));
                    break;
                }
            }
        }
    }

    const orderComplete =async()=> {
        if(!await callConfirm('완료처리 하시겠습니까?')) return;

        await axios.put(`/order`, {orderSeq: orderParams.orderSeq, orderStatus: 1});

        showToast('완료되었습니다.');
        await Promise.all([getOrderList(), getOrderProduct(orderParams.orderSeq)]);
        AppStore.toggleModal();
    }


    return (
        <div style={{marginBottom: 50}}>
            {/* 검색조건 */}
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'발주제목'}
                            labelId={'orderTitle'}
                            placeholder={'발주제목'}
                            value={orderParams.orderTitle}
                            onChange={v => onChangeValue(v, 'orderTitle')}
                            disabled={isCompleted}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CSelectGroup
                            label={'발주구분'}
                            value={orderParams.orderType}
                            onChange={v => onChangeValue(v, 'orderType')}
                            options={[{value:'', name:'선택'}].concat(getCommcode('36'))}
                            labelClassName='input-required'
                            disabled={isCompleted || orderParams.orderSeq}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'발주번호'} labelId={'orderSeq'} value={orderParams.orderSeq} disabled={true} />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'상태'}
                                     labelId={'orderStatusText'}
                                     value={orderParams.orderStatusText}
                                     disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col lg={3}>
                        <CalendarGroup
                            label='발주일'
                            asSingle={true} value={orderDate || ''}
                            onChange={v => onChangeSearchDate(v, 'orderDate')}
                            disabled={isCompleted}
                            labelClassName='input-required'
                        />
                    </Col>
                    <CCol lg={3}>
                        <CInputGroup label={'발주자'} labelId={'orderUserName'}
                                        value={orderParams.orderUserName}
                                        onCallbackBtn={openSearchUserPopup}
                                        readOnly={true}
                                        btnDisabled={isCompleted}
                                        labelClassName='input-required'
                                        onEmptyBtn={() =>setOrderParams(param => ({...param, orderUserSeq: null, orderUserName: ''}))}
                        />
                    </CCol>
                    <CCol lg={5}>
                        <CInputDoubleGroup label={'구매처'} labelId={'buyCustomerNo'} labelId2={'buyCustomerName'}
                                           placeholder={'구매처코드'} placeholder2={'구매처'}
                                           disabled={true} disabled2={true}
                                           btnDisabled={isCompleted}
                                           onCallbackBtn={() => searchCustomerPopup('buyCustomer')}
                                           value={orderParams.buyCustomerNo}
                                           value2={orderParams.buyCustomerName}
                                           labelClassName='input-required'
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'납품장소'}
                            labelId={'deliveryPlace'}
                            placeholder={'납품장소'}
                            value={orderParams.deliveryPlace}
                            onChange={v => onChangeValue(v, 'deliveryPlace')}
                            disabled={isCompleted}
                            labelClassName='input-required'
                        />
                    </CCol>
                    <Col lg={3}>
                        <CalendarGroup
                            label='입고예정일'
                            asSingle={true} value={storageDate || ''}
                            onChange={v => onChangeSearchDate(v, 'storageDate')}
                            disabled={isCompleted}
                            labelClassName='input-required'
                        />
                    </Col>
                    <CCol lg={5}>
                        <CInputGroup label={'비고'}
                                     labelId={'remark'}
                                     placeholder={'비고'}
                                     value={orderParams.remark}
                                     onChange={v => onChangeValue(v, 'remark')}
                                     disabled={isCompleted}
                        />
                    </CCol>
                </Row>
                <Subject>환율</Subject>
                <Row>
                    <Col lg={3}>
                        <CalendarGroup
                            label='환율적용일'
                            asSingle={true} 
                            value={exchangeRateApplyDate || ''}
                            onChange={v => onChangeSearchDate(v, 'exchangeRateApplyDate')}
                            disabled={isCompleted}
                        />
                    </Col>
                    <CCol lg={2}>
                        <CSelectGroup
                            label={'화폐'}
                            value={orderParams.currencyUnit || ''}
                            onChange={v => onChangeValue(v, 'currencyUnit')}
                            options={[{value:'', name:'선택'}].concat(getCommcode('59'))}
                            disabled={isCompleted}
                        />
                    </CCol>
                    <Col lg={2}>
                        <CInputGroup label={'적용환율'}
                            labelId={'exchangeRate'}
                            value={orderParams.exchangeRate}
                            onChange={(e)=> onChangeValue(e, 'exchangeRate')}
                            backLabel={'원'}
                            align={'right'}
                            disabled={isCompleted}
                        />
                    </Col>
                    <Col lg={3}>
                        <CInputGroup label={'운송비'}
                            labelId={'deliveryCost'}
                            value={orderParams.deliveryCost}
                            onChange={(e)=> onChangeValue(e, 'deliveryCost')}
                            backLabel={'원'}
                            align={'right'}
                            disabled={isCompleted}
                        />
                    </Col>
                    <Col lg={3}>
                        <CInputGroup label={'기타비용'}
                            labelId={'etcCost'}
                            value={orderParams.etcCost}
                            onChange={(e)=> onChangeValue(e, 'etcCost')}
                            backLabel={'원'}
                            align={'right'}
                            disabled={isCompleted}
                        />
                    </Col>
                </Row>
{/*
                <Row>
                    <Col lg={3}>
                        <CalendarGroup
                            label='품의결제일'
                            asSingle={true}
                            value={approvalDate || ''}
                            onChange={v => onChangeSearchDate(v, 'approvalDate')}
                            disabled={isCompleted}
                            labelClassName='input-required'
                        />
                    </Col>
                    <CCol lg={5}>
                        <CInputGroup
                            label={'품의링크'}
                            labelId={'approvalNo'}
                            value={orderParams.approvalNo}
                            onCallbackBtn={searchApprovalNo}
                            buttonLabel={'품의열기'}
                            onChange={v => onChangeValue(v, 'approvalNo')}
                            disabled={isCompleted}
                            btnDisabled={false}
                            labelClassName='input-required'
                        />
                    </CCol>
                    <CCol lg={5}>
                        <CInputDoubleGroup label={'발주처'} labelId={'orderCustomerNo'} labelId2={'orderCustomerName'}
                                           placeholder={'발주처코드'} placeholder2={'발주처'}
                                           disabled={true} disabled2={true}
                                           btnDisabled={isCompleted}
                                           onCallbackBtn={() => searchCustomerPopup('orderCustomer')}
                                           value={orderParams.orderCustomerNo}
                                           value2={orderParams.orderCustomerName}
                                           labelClassName='input-required'
                        />
                    </CCol>
                </Row>
*/}
                <Row>
                    <Col className='d-flex flex-row-reverse' style={{marginBottom: 20}}>
                        <CAddBtn title={'새발주'} onClick={addCreateOrder} />
                        {(!isCompleted && orderParams.orderSeq) &&
                            <CSaveBtn title={'발주완료'} onClick={orderComplete} />
                        }
                        {(!isCompleted && (orderParams.orderUserSeq === userInfo.userSeq || !orderParams.orderUserSeq)) &&
                            <CSaveBtn title={'저장'} onClick={createOrder} disabled={isCompleted}/>
                        }
                    </Col>
                </Row>
            </CContainer>
            <CustomerSearch callbackFn={searchCustomerInfo} customerType={customerType}/>
            <UserSearch callbackFn={getUserInfo}/>

            <CContainer>
                {/* ag grid */}
                <AgGridContainer
                    gridRef={gridRef}
                    height={35}
                    rowData={OrderProductStore.orderProductList}
                    columnDefs={columnDefs}
                    seqColumn={'agId'}
                    useUpdated={!isCompleted}
                    isCheckBox={!isCompleted}
                    originList={orderProductList}
                    callBackGridData={callBackGridData}
                    customBtnInfo={!isCompleted && [customAddProduct]}
                />
            </CContainer>
            <ProductListSearch callbackFn={callbackProductInfo} orderType={orderParams.orderType}/>
        </div>
    )
}

export default observer(OrderDetail);
