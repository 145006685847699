import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { Col } from "react-bootstrap";

import AgGridContainer from "../../components/AgGridContainer";
import { CContainer } from "../../components/CustomContainer";
import { AccountCloseStore } from "../../store/accounting/AccountCloseStore";

const AccountCloseContainer = () => {
    const store = AccountCloseStore;
    const gridRef = useRef();

    const columnDefs = [
        {field: "statementNo", headerName: "전표번호", hide: true},
        {field: "accountCode", headerName: "계정코드", minWidth: 110},
        {field: "name", headerName: "계정과목명", minWidth: 160},
        {field: "customerNo", headerName: "고객번호", minWidth: 110},
        {field: "customerName", headerName: "고객명", minWidth: 160},
        {field: "amount", headerName: "잔액", minWidth: 150, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
    ];

    /* 행 고정 */
    const pinnedTopRowData = [{
        amount: store.accountAmountList.reduce((total, v) => {
            return total + v.amount;
        }, 0),
    }];

    return (
        <>
            <Col lg={8}>
                <CContainer>
                    <AgGridContainer
                        gridRef={gridRef}
                        gridTitle={''}
                        height={60}
                        rowData={store.accountAmountList}
                        columnDefs={columnDefs}
                        useCsvDownload={true}
                        pinnedTopRowData={pinnedTopRowData}
                    />
                </CContainer>
            </Col>
        </>
    )
}

export default observer(AccountCloseContainer);
