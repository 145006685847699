import React, { useEffect, useRef, useState } from 'react';
import { CContainer } from '../../components/CustomContainer';
import { getDay, dateDiff, addDayDate, getMonth } from '../../utils/dateUtils';
import { observer } from 'mobx-react-lite';
import { AssetOperateRateStore } from '../../store/asset/AssetOperateRateStore';
import axios from 'axios';
import { showToast } from '../../common/utils';
import AgGridContainer from "../../components/AgGridContainer";
import AssetOperateRateSearch from './AssetOperateRateSearch';
import { AppStore } from '../../store/AppStore';
import { ContractDetailStore } from '../../store/contract/ContractDetailStore';
import ContractDetail from '../contract/ContractDetail';
import Modal from '../../components/Modal';

const AssetOperateRate =()=> {
    const store = AssetOperateRateStore;
    const gridRef = useRef();

    const [contractNo, setContractNo] = useState('');

    useEffect(()=> {
        async function makeColumn(){
            await monthColumn();
        }
        makeColumn();
    }, [])

    const monthColumn =async()=> {
    
        const dateDiffDays = dateDiff(store.startDate.startDate, store.endDate.startDate);

        store.setAssetList([]);
        store.setColumnList([]);
        
        store.pushColumnList(store.startDate.startDate);
        for(let i = 1; i <= dateDiffDays; i++){
            store.pushColumnList(addDayDate(store.startDate.startDate, i));
        }
    }

    const getAssetList =async()=> {
        if(!store.startDate.startDate || !store.endDate.startDate){
            showToast('날짜 지정은 필수입니다.');
            return;
        }

        const params = {
            startDate: store.startDate.startDate,
            endDate: store.endDate.startDate,
            managementNo: store.managementNo,
            model: store.model,
            searchType: store.selSearchType
        }
        const result = await axios.get(`/asset/operateRate`, {params});
        //console.log(result)   

        if(result){
            const processResult = [];
            let prevAssetNo = '';
            let index = 1;

            for(const data of result){
                //console.log(data)
                let startDate = '';
                let endDate = '';
                let storageDate = '';
                let isRent = 0;

                if(data.contractStartDate){
                    startDate = Number(data.contractStartDate.replaceAll('-',''));
                }
                if(data.contractEndDate){
                    endDate = Number(data.contractEndDate.replaceAll('-',''));
                }
                if(data.storageDate){
                    storageDate = Number(data.storageDate.replaceAll('-',''));
                }

                //순번 설정
                Object.assign(data, {seq: index++, operateRate: 0});

                for(let column of store.columnList){
                    const date = Number(column.replaceAll('-',''));
                    if(startDate && endDate){
                        if(date >= startDate && date <= endDate){
                            isRent = 1;
                        }
                        if(date > endDate){
                            isRent = 0
                        }
                    }

                    if(storageDate && storageDate < date){
                        isRent = 0
                    }

                    //Object.assign(data, {[column]: isRent === 1 ? data.customerName : '', [`${column}-contractNo`]: isRent === 1 ? data.contractNo : ''});

                    data[column] = isRent === 1 ? data.customerName : '';
                    data[`${column}-contractNo`] = isRent === 1 ? data.contractNo : ''
                }
                //console.log(data)
                //이미 같은 자산번호로 데이터가 들어가있는지 체크 (들어가 있다면 assign 한다)
                if(data.assetNo != prevAssetNo){
                    prevAssetNo = data.assetNo;
                    processResult.push(data);

                }else{
                    const tempResult = processResult[processResult.length - 1];
                    if(tempResult){
                        //최종 결과와 합치기
                        Object.assign(processResult[processResult.length - 1], data);
                    }else{
                        processResult.push(data);
                    }
                }
            }

            processResult.map(v => {
                let rentDays = 0;
                Object.keys(v).forEach((key) => {
                    if(store.columnList.includes(key) && v[key] != ''){
                        ++rentDays;
                    }
                });

                v.operateRate = rentDays > 0 ? Math.floor((rentDays * 100) / store.columnList.length) : 0;
                return v;
            })

            store.setAssetList(processResult);
        }
    }

    const makeGridColumn =()=> {
        const goToContractDetail =(contractNo)=> {
            if(contractNo){
                setContractNo(contractNo);
                AppStore.toggleOpenContractDetailPopup();
            }
        }

        const contractRenderer =(v)=> {
            return (
                <div onDoubleClick={()=> goToContractDetail(v.data[`${v.colDef.field}-contractNo`])} target='_blank'>
                    {v.value}
                </div>
            );
        }

        const columns = [
            {field: "seq", headerName: "순번", width: 80, pinned: "left"},
            {field: "assetNo", headerName: "자산번호", width: 120, pinned: "left", hide: true},
            {field: "model", headerName: "모델명", width: 120, pinned: "left"},
            {field: "managementNo", headerName: "관리번호", width: 120, pinned: "left"},
            {field: "operateRate", headerName: "가동율(%)", width: 120, pinned: "left", cellClass: v => !v.data.assetNo && 'ag-grid-column-complete'},
        ];

        store.columnList.forEach(v => {
            columns.push({
                field: v, 
                headerName: `${`${getMonth(v)}-${getDay(v)}`}`, 
                minWidth: 60, 
                tooltipValueGetter: (v) => v.value, 
                /*
                colSpan: (params) => {
                    const country = params.data[v];
                    if(country != ''){
                        return 2;
                    }
                }
                */
               cellClass: v => v.value && 'ag-grid-column-same-data-spanning',
               cellRenderer: v => v.value && contractRenderer(v)
            });
            columns.push({
                field: `${v}-contractNo`, 
                headerName: `계약번호`, 
                hide: true
            })
        })

        //console.log(columns)
        return columns;
    }

    const pinnedTopRowData = [{operateRate: store.assetList.length > 0 ? Math.floor(store.assetList.reduce((total, v) => total + v.operateRate, 0) / store.assetList.length) : 0}];

    return (
        <>
           <AssetOperateRateSearch store={store} getAssetList={getAssetList} monthColumn={monthColumn} />
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={70}
                    rowData={store.assetList}
                    gridTitle={'장비현황'}
                    columnDefs={makeGridColumn()}
                    useCsvDownload={true}
                    pinnedTopRowData={pinnedTopRowData}
                />
            </CContainer>
            <Modal title={'계약상세'}
                fullscreen={true}
                show={AppStore.isOpenContractDetailPopup}
                onHide={() => AppStore.toggleOpenContractDetailPopup()}
                toggleValue={AppStore.isOpenContractDetailPopup}
                onExit={()=> {
                    ContractDetailStore.init();
                    setContractNo('');
                }}
            >
                <ContractDetail
                    contractNo={contractNo} 
                    releaseAskStatus={6}    
                />
            </Modal>
        </>
    );
}

export default observer(AssetOperateRate);
