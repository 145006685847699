import { observer } from "mobx-react-lite"
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import { CCol, CSaveBtn, CalendarGroup, Subject, CInputGroup } from "../../../components/CustomContainer";
import { useState, useRef } from "react";
import AgGridContainer from "../../../components/AgGridContainer";
import { UserTabsStore } from "../../../store/human-resources/UserTabsStore";
import axios from "axios";
import { showToast } from "../../../common/utils";
import { getUserName } from "../../../utils/userUtils";
import { ICON_TRASH } from "../../../common/constants";
import { callConfirm } from "../../../utils";

const WorkExperienceDetails = ({userSeq, selectUserDetail}) => {

    const gridRef = useRef();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [remark, setRemark] = useState('');

    const saveWorkExperience = async () => {

        if (!startDate || !endDate || !remark) {
            showToast('필수값을 모두 입력해주세요.');
            return;
        }

        if (!await callConfirm('저장하시겠습니까?')) {
            return;
        }
        const params = {
                        userSeq: userSeq, 
                        tabCategory: 'F',
                        startDate: startDate,
                        endDate: endDate,
                        remark: remark
        }

        await axios.post('/user/tabs', params);
        showToast('저장이 완료되었습니다.');
        selectUserDetail();
        setStartDate('');
        setEndDate('');
        setRemark('');
    }

    /* 선택 버튼 옵션 - 삭제 */
    const modifyBtn = {
        isUsed: true,
        title: '수정',
        callbackFn: () => modifyFn(),
        icon: 'fi-rr-file-edit'
    };

    const modifyFn = async () => {
        const updatedList = UserTabsStore.workExperienceList.filter(v => v.isUpdated);
        if (updatedList.length === 0) {
            showToast('수정된 데이터가 없습니다.');
            return;
        }

        if (!await callConfirm('수정 하시겠습니까?')) {
            return;
        }

        await axios.put('/user/tabs', updatedList);
        showToast('수정이 완료되었습니다.');
        selectUserDetail();
    }

    /* 선택 버튼 옵션 - 삭제 */
    const deleteBtn = {
        isUsed: true,
        title: '삭제',
        callbackFn: () => deleteFn(),
        icon: ICON_TRASH,
        variant: 'dark'
    };

    const deleteFn = async () => {
        const selectedList = UserTabsStore.workExperienceList.filter(v => v.isSelected);

        if (selectedList.length === 0) {
            showToast('삭제할 데이터를 선택해주세요.');
            return;
        }

        if (!await callConfirm('삭제 하시겠습니까?')) {
            return;
        }

        await axios.delete('/user/tabs', {data: selectedList});
        showToast('삭제가 완료되었습니다.');
        selectUserDetail();
    }

    const validDateFormat = (e) => {
        const pattern = /^\d{4}-\d{2}-\d{2}$/;
        const { field } = e.colDef;

        if (!pattern.test(e.data[field])) {
            showToast('YYYY-MM-DD 형식에 맞춰 입력해주세요.');
            e.data[field] = e.oldValue;
            gridRef.current.api.redrawRows();
            return;
        }
    }

    return (
        <>
            <Subject>경력사항</Subject>
            <Row>
                <CCol lg={4}>
                    <CalendarGroup
                        asSingle={true}
                        label={'시작일'}
                        value={{startDate: startDate, endDate: startDate}}
                        onChange={v => setStartDate(v.startDate)}
                        labelClassName='input-required'
                    />
                </CCol>
                <CCol lg={4}>
                    <CalendarGroup
                        asSingle={true}
                        label={'종료일'}
                        value={{startDate: endDate, endDate: endDate}}
                        onChange={v => setEndDate(v.startDate)}
                        labelClassName='input-required'
                    />
                </CCol>
            </Row>
            <Row>
                <CCol lg={12}>
                    <InputGroup>
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}} className='input-required'>경력사항</InputGroup.Text>
                        <textarea className="form-control" rows="3"
                            value={remark}
                            placeholder={'내용을 자유롭게 작성해주세요.'}
                            readOnly={false}
                            onChange={v => setRemark(v.target.value)}
                        />
                    </InputGroup>
                </CCol>
            </Row>
            <Row>
                <Col className="d-flex flex-row-reverse">
                    <CSaveBtn title={'저장'} onClick={saveWorkExperience}/>
                </Col>
            </Row>
            <br />
            <Row>
                <CCol lg={12}>
                    <AgGridContainer
                        gridRef={gridRef}
                        gridTitle={'경력사항'}
                        height={31.7}
                        rowData={UserTabsStore.workExperienceList}
                        columnDefs={[
                            { field: "userTabDataSeq", headerName: "유저탭테이블순번", hide: true },
                            { field: "startDate", headerName: "경력시작일자", width: 130, onCellValueChanged: validDateFormat, editable: true },
                            { field: "endDate", headerName: "경력종료일자", width: 130, onCellValueChanged: validDateFormat, editable: true },
                            { field: "remark", headerName: "경력사항", width: 270, editable: true },
                            { field: "creator", headerName: "입력자", width: 98, valueGetter: v => getUserName(v.data.creator) },
                            { field: "createdDate", headerName: "입력일자", width: 180 },
                            { field: "updater", headerName: "수정자",  valueGetter: v => getUserName(v.data.creator), width: 98},
                            { field: "updatedDate", headerName: "수정일자", width: 180 },
                        ]}
                        customBtnInfo={[deleteBtn, modifyBtn]}
                        isCheckBox={true}
                    />
                </CCol>
            </Row>
        </>
    )
}

export default observer(WorkExperienceDetails);