import { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Form, InputGroup, Row, Tab, Tabs, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { CCol, CContainer, CInputGroup, CInputDoubleGroup, CSelectGroup, CIconBtn, CalendarWithLabel } from "../../components/CustomContainer";
import { getCommcode } from "../../utils/commcode";
import { ContractDetailStore, contractColumns } from "../../store/contract/ContractDetailStore";
import ContractInfo from "./tabs/ContractInfo";
import ContractHistory from "./tabs/ContractHistory";
import ContractAssetList from "./tabs/ContractAssetList";
import { AppStore } from "../../store/AppStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import LUtils from "../../utils/lodashUtils";
import { MULTIPART_HEADER, NO_CONTRACT, USER_INFO } from "../../common/constants";
import {callConfirm, getLocalStorage, groupByForTwoColumn} from "../../utils";
import { ContractTabsStore } from "../../store/contract/ContractTabsStore";
import EstimateSearch from "../../components/searchModal/EstimateSearch";
import contractUtils from "./contractUtils";
import {addDay, today} from "../../utils/dateUtils";
import ContractBillingList from "./tabs/ContractBillingList";
import {encrypt, showToast} from "../../common/utils";
import {useReactToPrint} from "react-to-print";
import ReportContractTemplate from "../../components/report/ReportContractTemplate";
import SendContractPopup from "./popup/SendContractPopup";
import { TemplateStore } from "../../store/contract/TemplateStore";

/*
    ContractDetailStore.detailMode
    create : 계약등록, 출고의뢰전
    update : 출고의뢰 후
    read : 출고완료 및 확정이 완료된 계약
 */


const ContractDetail = (props) => {
    const userInfo = getLocalStorage(USER_INFO);

    const [userColumn, setUserColumn] = useState('');
    const [contractDays, setContractDays] = useState({ rentMonth: '', rentDays: '' });
    const [contractDate, setContractDate] = useState({ startDate: new Date(today()), endDate: '' });
    const [contractStartDate, setContractStartDate] = useState({ startDate: '', endDate: '' });
    const [contractEndDate, setContractEndDate] = useState({ startDate: '', endDate: '' });
    const [optionList, setOptionList] = useState([{ contractSpecialOptionSeq: '', optionCode: '', qty: 1, amount: 0 }]);
    const [isOptionCost, setIsOptionCost] = useState(0);
    const [isRead, setIsRead] = useState(false);
    const [printRef, setPrintRef] = useState(null);

    const location = useLocation();
    const componentRef = useRef();

    useEffect(()=> {
        const contractNo = LUtils.get(location.state, 'contractNo', props.contractNo);
        const releaseAskStatus = LUtils.get(location.state, 'releaseAskStatus', props.releaseAskStatus) ;
        const sellDate = LUtils.get(location.state, 'sellDate', null) ;
        const isFix = LUtils.get(location.state, 'isFix', null) ;

        if(location.state && location.state.contractNo != null){
            setIsRead(false);
        }else{
            setIsRead(true);
        }

        /* ui setting 값 초기화 */
        if(contractNo || !ContractDetailStore.contractNo) {
            !isRead && refreshUi();
        }

        let detailMode = 'read';
        //1.계약원부로 진입 , 2.출고의뢰상태가 출고검수중 보다 크고 취소가 아닐경우 , 3.계약 확정 => 읽기모드
        if(isRead || (Number(releaseAskStatus) > 1 && Number(releaseAskStatus) < 9) || isFix === 1){
            detailMode = 'read';
        //1.계약번호 있음 , 2.출고의뢰상태가 작업중 보다 작다 => 업데이트모드 (수정가능)
        }else if(contractNo && ((releaseAskStatus && Number(releaseAskStatus) < 5) || (releaseAskStatus === null) || Number(releaseAskStatus) === 9)){
            //출고의뢰 후로 출고의뢰 안됨
            //출고제품만 수정가능
            detailMode = 'update';
        }else if(sellDate){
            //출고의뢰전
            //모두 수정 가능
            detailMode = 'read';
        }

        ContractDetailStore.setContractDetailMode(detailMode);

        if (!isRead && !!(contractNo||ContractDetailStore.contractNo)) {   // 계약원부 url이고 계약번호를 들고 있는 경우
            searchContract(contractNo||ContractDetailStore.contractNo);
            getContractSpecialOption(contractNo||ContractDetailStore.contractNo);
            searchContractProduct(contractNo||ContractDetailStore.contractNo);
            ContractDetailStore.setContainContractNo(true);
        } else {
            refreshUi(); // 계약원부 url로 진입 (계약번호 없음)
        }
    }, []);

    const refreshUi = () => {
        ContractDetailStore.init(); // 계약 등록 url로 진입한 경우에만 경우에만 초기화 한다.
        ContractTabsStore.setContractTab('contractInfo');   // 등록인 경우 tab 초기화
        setContractDays({ rentMonth: null, rentDays: null });
        setContractDate({ startDate: null, endDate: null });
        setContractStartDate({ startDate: null, endDate: null });
        setContractEndDate({ startDate: null, endDate: null });
        ContractDetailStore.setIsSearch(false);
        ContractDetailStore.setContainContractNo(false);
        ContractDetailStore.setContractProductList([]);
        ContractDetailStore.setOriginContractProductList([]);
        //ContractDetailStore.setProductOptionList([]);
        //ContractDetailStore.setOriginProductOptionList([]);
    }

    /* 계약 종료일 세팅 */
    useEffect(() => {
        const { rentMonth, rentDays } = contractDays;
        const { startDate } = contractStartDate;
        ContractDetailStore.setContractDetail(rentMonth,  'rentMonth');
        ContractDetailStore.setContractDetail(rentDays, 'rentDays');

        // 값이 있을때만 진행, 없으면 계약종료일 초기화
        if (!(startDate && (rentMonth || rentDays))) {
            setContractEndDate({ startDate: null, endDate: null });
            ContractDetailStore.setContractDetail(null, 'contractEndDate');
            return;
        }

        let endDate = null;
        //반납형, 양도형 계약이면
        if(ContractDetailStore.contractType === '1' || ContractDetailStore.contractType === '2'){
            // 종료일 계산
            endDate = contractUtils.contractEndDateFormat(startDate, rentMonth, rentDays);
        }else{
            endDate = startDate;
        }

        setContractEndDate({startDate: endDate, endDate: endDate});
        ContractDetailStore.setContractDetail(endDate, 'contractEndDate');

    }, [contractDays, contractStartDate]);

    /* 계약 조회 */
    const searchContract = async (contractNo) => {
        try {
            const result = await axios.get(`/contract/${contractNo}`);

            LUtils.forOwn(contractColumns, (v, k) => {
                k === 'contractDate' && setContractDate({startDate: result[k], endDate: result[k]});
                k === 'contractStartDate' && setContractStartDate({startDate: result[k], endDate: result[k]});
                k === 'contractEndDate' && setContractEndDate({startDate: result[k], endDate: result[k]});
                k === 'rentMonth' && setContractDays(LUtils.assign(contractDays, { rentMonth: result[k] }));
                k === 'rentDays' && setContractDays(LUtils.assign(contractDays, { rentDays: result[k] }));

                ContractDetailStore.setContractDetail(result[k] === 0 ? 0 : result[k] || '', k);
            })

            //현장코드가 있다면 조회한다.
            if(result.siteName){
                await getContractSiteOptionList(contractNo);
            }

            // 계약서 메일 보내는 부분 세팅
            ContractDetailStore.subject = '['+userInfo.companyName+'] 장비 임대 계약서';
            ContractDetailStore.content = `안녕하세요. ${ContractDetailStore.customerName} ${ContractDetailStore.billingAddressName} 담당자님.`
                                            + `\n\n${userInfo.companyName}(와)과의 장비 임대 계약서를 보내드리오니`
                                            + `\n\n검토 후 서명해 주시기 바랍니다.`
                                            + `\n\n감사합니다.`
                                            + `\n\n * 서명 버튼은 페이지 하단에 있습니다.`;

            ContractDetailStore.setIsSearch(true);
        } catch (error) {
            let msg = '예기치 못한 오류가 발생했습니다. 나중에 다시 시도해 주세요';
            const { code } = LUtils.get(error.response, 'data');
            if (code === NO_CONTRACT) {
                msg = '등록되지 않은 계약 번호 입니다.';
            }

            ContractDetailStore.setIsSearch(false);
            showToast(msg);
        }

        TemplateStore.setTemplateData(ContractDetailStore);
        setPrintRef(componentRef.current);
    }

    /* 계약 현장 옵션 조회 */
    const getContractSiteOptionList =async(contractNo)=> {
        ContractDetailStore.setContractSiteOptionList(await axios.get(`/contract/site/option/${contractNo}`));
    }

    /* 계약 제품 정보 조회 */
    const searchContractProduct = async (contractNo) => {
        const result = await axios.get(`/contract/product/${contractNo}`);
        ContractDetailStore.setContractProductList(result);
        ContractDetailStore.setOriginContractProductList(result);

        // 계약 제품을 조회 한 경우 출고가능 수량 확인을 위해 상품목록을 조회한다.
        getProductList(LUtils.map(result, 'storageNo'));
    }

    /* 계약 옵션비 청구 조회 */
    const getContractSpecialOption = async (contractNo) => {
        const result = await axios.get('/contract/special/option', {params: {contractNo: contractNo}});
        if(result.length > 0) {
            setIsOptionCost(1);
        }
        setOptionList(result);
    }

    /* 출고가능 상품 목록 조회 */
    const getProductList = async (storageNoList) => {
        const params = { storageType: 'sto_goods', storageNoList: '"' + storageNoList.join('","') + '"' };
        const result = await axios.get('/storage/list', { params });
        const groupList = groupByForTwoColumn(result, 'storageNo', 'productSeq', ['storageNo', 'productCode', 'model', 'productName', 'spec', 'productSeq']);
        ContractDetailStore.setContractProductGroupList(groupList.map(v => LUtils.assign(v, { qty: v.rowCnt, availableQty: v.rowCnt })));
    }

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = async(data) => {
        await getManager(data.customerNo);
        ContractDetailStore.setContractDetail(data.customerName, 'customerName');
        ContractDetailStore.setContractDetail(data.customerNo, 'customerNo');
        ContractDetailStore.setContractDetail(data.saleUserSeq, 'saleUserSeq');
        ContractDetailStore.setContractDetail(data.saleUserName, 'saleUserName');
        ContractDetailStore.setContractDetail(data.supportUserSeq, 'supportUserSeq');
        ContractDetailStore.setContractDetail(data.supportUserName, 'supportUserName');
        if (data.isPenaltyFee) ContractDetailStore.setContractDetail(data.isPenaltyFee, 'isPenaltyFee');
        if (data.billingType) ContractDetailStore.setContractDetail(data.billingType, 'billingType');
        if (data.billingDay) ContractDetailStore.setContractDetail(data.billingDay, 'billingDay');
        if (data.dueDateType) ContractDetailStore.setContractDetail(data.isPenaltyFee, 'isPenaltyFee');
        if (data.dueDateDay) ContractDetailStore.setContractDetail(data.dueDateDay, 'dueDateDay');
        if (data.paymentType) ContractDetailStore.setContractDetail(data.paymentType, 'paymentType');
        if (data.endDate) ContractDetailStore.setContractDetail(data.endDate, 'endDate');
    }

    /* 담당자명 조회 */
    const getManager = async (customerNo) => {
        const result = await axios.get('/customer/manager', { params: {customerNo: customerNo}});
        ContractDetailStore.setContractDetail(result.customerManagerSeq, 'customerManagerSeq');
        ContractDetailStore.setContractDetail(result.managerName, 'managerName');
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = (column) => {
        setUserColumn(column);
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo, column) => {
        if (!userInfo || !column) {
            return false;
        }

        ContractDetailStore.setContractDetail(userInfo.name, `${column}Name`);
        ContractDetailStore.setContractDetail(userInfo.userSeq, `${column}Seq`);
        setUserColumn('');
    }

    /* 계약 기간 월 = 숫자, 최대 두 자리 */
    const handleMonthsChange = (event) => {
        if(event.target.value < 0){
            showToast('마이너스(-)는 입력할 수 없습니다.');
            return false;
        }
        const rentMonth = contractUtils.handleMonthsChange(event.target.value);
        setContractDays({ ...contractDays, rentMonth });
        ContractDetailStore.setContractDetail(event.target.value, 'rentMonth')
        ContractDetailStore.contractProductList.forEach(item => {item.rentMonth = rentMonth;});
    };

    /* 계약 기간 일 = 숫자, 최대 30 */
    const handleDaysChange = (event) => {
        if(event.target.value < 0){
            showToast('마이너스는 입력할 수 없어요.');
            return false;
        }
        const rentDays = contractUtils.handleDaysChange(event.target.value);
        setContractDays({ ...contractDays, rentDays });
        ContractDetailStore.setContractDetail(event.target.value, 'rentDays')
    };

    /* 달력 선택 */
    const onChangeCalender = (target, value) => {
        /* 계약일자 */
        if (target === 'contractDate') {
            if (!contractUtils.dateDiffContractDate(value.startDate, contractStartDate.startDate)) {
                showToast('계약개시일자는 계약일자보다 커야 합니다.');
                return false;
            }

            setContractDate(value)
            ContractDetailStore.setContractDetail(value.startDate, target);
        }

        /* 계약시작일 */
        if (target === 'contractStartDate') {
            if (!contractUtils.dateDiffContractDate(contractDate.startDate, value.startDate)) {
                showToast('계약개시일자는 계약일자보다 커야 합니다.');
                return false;
            }

            setContractStartDate(value);
            ContractDetailStore.setContractDetail(value.startDate, target);

            //유통, 매각이면 청구조건을 개시일로 변경한다.
            if(ContractDetailStore.contractType === '3' || ContractDetailStore.contractType === '4'){
                const day = addDay(value.startDate, 0);
                ContractDetailStore.setContractDetail(day, 'billingDay');
            }
        }
    }

    /* 견적 정보 콜백 함수 */
    const getEstimateInfo = (info) => {
        //console.log('getEstimateInfo ', info);
        // 입력값 하나라도 입력했을때는 확인창을 보여주고 선택하도록 한다
        ContractDetailStore.contractType = info.contractType;
        ContractDetailStore.customerNo = info.customerNo;
        ContractDetailStore.customerName = info.customerName;
        ContractDetailStore.saleUserSeq = info.saleUserSeq;
        ContractDetailStore.saleUserName = info.saleUserName;
        ContractDetailStore.rentMonth = info.rentMonth;
        ContractDetailStore.rentDays = info.rentDays;
        ContractDetailStore.contractDate = info.contractDate;
        ContractDetailStore.contractStartDate = info.contractStartDate;
        ContractDetailStore.contractEndDate = info.contractEndDate;
        ContractDetailStore.isPenaltyFee = info.isPenaltyFee;
        ContractDetailStore.penaltyPercent = info.penaltyPercent;
        ContractDetailStore.isExtendRate = info.isExtendRate;
        ContractDetailStore.extendFirstRate = info.extendFirstRate;
        ContractDetailStore.extendSecondRate = info.extendSecondRate;
        ContractDetailStore.isRepairCost = info.isRepairCost;
        ContractDetailStore.specialRemark = info.specialRemark;
        ContractDetailStore.billingType = info.billingType;
        ContractDetailStore.billingDay = info.billingDay;
        ContractDetailStore.dueDateType = info.dueDateType;
        ContractDetailStore.dueDateDay = info.dueDateDay;
        ContractDetailStore.paymentType = info.paymentType;
        ContractDetailStore.deposit = info.deposit;
        ContractDetailStore.isFreeAssignment = info.isFreeAssignment;
        ContractDetailStore.billingAddressSeq = info.billingAddressSeq;
        ContractDetailStore.billingAddressName = info.billingAddressName;
    }

    const goBackContractSeq =async()=> {
        if(ContractDetailStore.contractSeq === 1){
            showToast('돌아갈 계약 회차가 없습니다.');
            return;
        }
        const preContractSeq = ContractDetailStore.contractSeq - 1;
        if (!await callConfirm('이전 '+ preContractSeq + '회차로 돌리시겠습니까?')) {
            return false;
        }

        const params = {

        }
        const result = await axios.put(`/contract/back/${ContractDetailStore.contractNo}`, params);
        showToast('저장 되었습니다.');

        //재조회
        await searchContract(ContractDetailStore.contractNo);
        await searchContractProduct(ContractDetailStore.contractNo);
    }

    const handleContractDocPrint = useReactToPrint({
        content: () => printRef,
        documentTitle: `계약서_${ContractDetailStore.contractNo}_`+today(),
    });

    /* 계약서 메일 전송 팝업 */
    const sendEmailPopup = () => {
        AppStore.toggleSendContractPopup();
    }

    /* 계약서 메일 전송 */
    const sendContract = async () => {

        if(!await callConfirm('메일 전송 하시겠습니까?')) return;

        if(!ContractDetailStore.receiverEmail) {
            showToast('받는사람 이메일을 적어주세요.');
            return;
        }

        // 이메일 체크
        const atSymbolCount = (ContractDetailStore.receiverEmail.match(/@/g) || []).length;

        if (atSymbolCount >= 2) {
            showToast('이메일을 하나만 적으세요');
            return;
        }

        const formData = new FormData();
        formData.append('subject', ContractDetailStore.subject);
        formData.append('customerName', ContractDetailStore.customerName);
        formData.append('billingAddressName', ContractDetailStore.billingAddressName);
        formData.append('companyName', userInfo.companyName);
        formData.append('contractStartDate', ContractDetailStore.contractStartDate);
        formData.append('productName', ContractDetailStore.productName);
        formData.append('receiverEmail', ContractDetailStore.receiverEmail);
        formData.append('contractNo', ContractDetailStore.contractNo)

        await axios.post('/contract/email', formData, { headers: MULTIPART_HEADER });
        showToast('전송이 완료되었습니다.');
        AppStore.toggleSendContractPopup();
    }

    return(
        <div style={{marginBottom: 50, maxWidth: 2000}}>
            {/* 검색조건 */}
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'계약번호'}
                            labelId={'contractNo'}
                            disabled={!isRead || ContractDetailStore.detailMode !== 'read'}
                            value={ContractDetailStore.contractNo}
                            onChange={v => ContractDetailStore.setContractDetail(v.target.value, 'contractNo')}
                            onCallbackBtn={() => console.log('open contract popup')}
                            enterKeyHint={() => {
                                //계약정보
                                searchContract(ContractDetailStore.contractNo);
                                //계약제품
                                searchContractProduct(ContractDetailStore.contractNo);
                            }}
                        />
                    </CCol>
                    <CCol lg={1}>
                        <CInputGroup label={'회차'} labelId={'contractSeq'} disabled={true} value={ContractDetailStore.contractSeq} />
                    </CCol>
                    {ContractDetailStore.detailMode !== 'read' &&
                        <CCol lg={1}>
                            <CInputGroup label={'상태'}
                                         labelId={'contractStatus'}
                                         disabled={true}
                                         value={ContractDetailStore.contractStatus === 0 ? '작성중' : '완료'}
                            />
                        </CCol>
                    }
                    <CCol lg={2}>
                        {ContractDetailStore.detailMode === 'create' &&
                            <CIconBtn
                                style={{width: 100}}
                                onClick={() => AppStore.toggleEstimateSearchModal()}
                                title={'견적불러오기'}
                                icon={'fi-rr-money-check-edit-alt'}
                            />
                        }
                        {((ContractDetailStore.contractType === '1' || ContractDetailStore.contractType === '2' ) && ContractDetailStore.isFix === 1 && ContractDetailStore.contractSeq > 1) &&
                            <CIconBtn
                                style={{width: 170, backgroundColor: '#FA5858'}}
                                onClick={goBackContractSeq}
                                title={'이전 회차로 돌아가기'}
                                icon={'fi-sr-refresh'}
                            />
                        }
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CSelectGroup
                            isValid={true}
                            label={'계약유형'}
                            options={getCommcode('1')}
                            value={ContractDetailStore.contractType || ''}
                            onChange={v => {
                                //유통이나 매각 계약이면 1일로 처리한다
                                if(v.target.value === '3' || v.target.value === '4'){
                                    setContractDays({rentDays: 1});
                                    ContractDetailStore.setContractDetail(1, 'rentDays');
                                }else{
                                    setContractDays({rentMonth: '', rentDays: ''});
                                    ContractDetailStore.setContractDetail('', 'rentDays');
                                }
                                ContractDetailStore.setContractDetail(v.target.value, 'contractType');
                            }}
                            disabled={ContractDetailStore.detailMode !== 'create'}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputDoubleGroup
                            isValid={true}
                            isValidCheck={!!ContractDetailStore.customerName}
                            label={'고객명'}
                            labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객코드'} placeholder2={'고객명'}
                            disabled={true} disabled2={true}
                            onCallbackBtn={openSearchCustomerPopup}
                            btnDisabled={ContractDetailStore.detailMode !== 'create'}
                            value={ContractDetailStore.customerNo}
                            value2={ContractDetailStore.customerName}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            isValid={true}
                            isValidCheck={!!ContractDetailStore.saleUserSeq}
                            label={'영업담당자1'}
                            labelId={'saleUserName'}
                            value={ContractDetailStore.saleUserName}
                            onCallbackBtn={() => openSearchUserPopup('saleUser')}
                            disabled={true}
                            btnDisabled={ContractDetailStore.detailMode === 'read'}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'영업담당자2'}
                            labelId={'supportUserName'}
                            value={ContractDetailStore.supportUserName}
                            onCallbackBtn={() => openSearchUserPopup('supportUser')}
                            disabled={true}
                            btnDisabled={ContractDetailStore.detailMode === 'read'}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <Form.Label htmlFor="rentMonth" style={{color: '#DF013A'}}>계약기간</Form.Label>
                        <InputGroup size={'sm'}>
                            <Form.Control
                                style={{flex: 1, textAlign: 'right'}}
                                value={contractDays.rentMonth || ''}
                                onChange={v => {
                                    if(ContractDetailStore.contractProductList.length > 0) {
                                        ContractDetailStore.contractProductList.map(c => {
                                            c.rentMonth = v.target.value;
                                            c.totalRentPrice = contractUtils.calculateTotalPrice(c);
                                            c.isUpdated = true;
                                        });
                                    }
                                    handleMonthsChange(v);
                                }}
                                disabled={ContractDetailStore.detailMode === 'read' && (ContractDetailStore.isFix === 1 || ContractDetailStore.contractType !== '1') || (ContractDetailStore.contractType === '3' || ContractDetailStore.contractType === '4')}
                                type={'number'}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>개월</InputGroup.Text>
                            <Form.Control
                                style={{flex: 1, textAlign: 'right'}}
                                value={contractDays.rentDays || ''}
                                onChange={v => {
                                    if(ContractDetailStore.contractProductList.length > 0) {
                                        ContractDetailStore.contractProductList.map(c => {
                                            c.rentDays = v.target.value;
                                            c.totalRentPrice = contractUtils.calculateTotalPrice(c);
                                            c.isUpdated = true;
                                        });
                                    }
                                    handleDaysChange(v);
                                }}
                                disabled={ContractDetailStore.detailMode === 'read' && (ContractDetailStore.isFix === 1  || ContractDetailStore.contractType !== '1') || (ContractDetailStore.contractType === '3' || ContractDetailStore.contractType === '4')}
                                type={'number'}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>일</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <CalendarWithLabel
                            isValid={true}
                            asSingle={true}
                            label={'계약일자'}
                            value={contractDate}
                            //minDate={today()}
                            onChange={v=> onChangeCalender('contractDate', v)}
                            disabled={ContractDetailStore.detailMode === 'read'}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarWithLabel
                            isValid={true}
                            asSingle={true}
                            label={'계약개시일'}
                            value={contractStartDate}
                            minDate={contractDate.startDate}
                            onChange={v => onChangeCalender('contractStartDate', v)}
                            disabled={ContractDetailStore.detailMode === 'read'}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarWithLabel
                            isValid={true}
                            asSingle={true}
                            label={'계약종료일'}
                            minDate={today()}
                            value={contractEndDate}
                            disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    {(ContractDetailStore.contractType === '1' || ContractDetailStore.contractType === '2') &&
                        <Col className='d-flex flex-row-reverse'>
                            <CIconBtn
                                style={{width: 100}}
                                onClick={() => handleContractDocPrint()}
                                variant={'success'}
                                title={'계약서'}
                                icon={'fi-rr-edit'}
                            />
                                <CIconBtn
                                    style={{width: 200}}
                                    onClick={sendEmailPopup}
                                    title={'전자계약 메일 발송'}
                                    icon={'fi-rr-envelope'}
                                />
                        </Col>
                    }
                </Row>
            </CContainer>
            {/* modal */}
            <CustomerSearch callbackFn={getCustomerInfo}/>
            <UserSearch callbackFn={getUserInfo} column={userColumn}/>
            <EstimateSearch callbackFn={getEstimateInfo} />
            <CContainer>
                <Tabs
                    defaultActiveKey="contractInfo"
                    className="mb-3"
                    mountOnEnter={true}
                    unmountOnExit={true}
                    activeKey={ContractTabsStore.contractTab}
                    onSelect={k => ContractTabsStore.setContractTab(k)}
                >
                    <Tab eventKey="contractInfo" title="기본정보">
                        <ContractInfo searchContract={searchContract}
                                      getContractSpecialOption={getContractSpecialOption}
                                      searchContractProduct={searchContractProduct}
                                      getContractSiteOptionList={getContractSiteOptionList}
                                      contractEndDate={contractEndDate.startDate}
                                      isOptionCost={isOptionCost}
                                      setIsOptionCost={setIsOptionCost}
                                      optionList={optionList}
                                      setOptionList={setOptionList}
                        />
                    </Tab>
                    {
                        (ContractDetailStore.detailMode === 'read' && ContractDetailStore.isFix === 1 && ContractDetailStore.isAutoSync !== 1 && (ContractDetailStore.contractType === '1' || ContractDetailStore.contractType === '2')) &&
                        <Tab eventKey="contractAssetList" title="계약자산 리스트">
                            <ContractAssetList mode={'asset'}/>
                        </Tab>
                    }
                    {
                        (ContractDetailStore.detailMode === 'read' && ContractDetailStore.isFix === 1) &&
                        <Tab eventKey="billing" title="청구스케쥴">
                            <ContractBillingList contractNo={ContractDetailStore.contractNo} contractStartDate={ContractDetailStore.contractStartDate} contractEndDate={ContractDetailStore.contractEndDate} />
                        </Tab>
                    }
                    {
                        (ContractDetailStore.detailMode === 'read' && ContractDetailStore.isFix === 1 && ContractDetailStore.isAutoSync !== 1 && (ContractDetailStore.contractType === '1' || ContractDetailStore.contractType === '2')) &&
                        <Tab eventKey="extend" title="연장">
                            <ContractAssetList mode={'extend'} seq={ContractDetailStore.contractSeq} searchContract={searchContract} />
                        </Tab>
                    }
                    {
                        (ContractDetailStore.detailMode === 'read' && ContractDetailStore.isFix === 1 && ContractDetailStore.isAutoSync !== 1 && (ContractDetailStore.contractType === '1' || ContractDetailStore.contractType === '2')) &&
                        <Tab eventKey="finish" title="단축">
                            <ContractAssetList mode={'finish'} seq={ContractDetailStore.contractSeq} searchContract={searchContract} />
                        </Tab>
                    }
                    {
                        (ContractDetailStore.detailMode === 'read' && ContractDetailStore.isFix === 1 && ContractDetailStore.isAutoSync !== 1 && (ContractDetailStore.contractType === '1' || ContractDetailStore.contractType === '2')) &&
                        <Tab eventKey="transfer" title="승계">
                            <ContractAssetList mode={'transfer'} seq={ContractDetailStore.contractSeq} searchContract={searchContract} />
                        </Tab>
                    }
                    {
                        (ContractDetailStore.detailMode === 'read' && ContractDetailStore.isFix === 1 && ContractDetailStore.isAutoSync !== 1 && (ContractDetailStore.contractType === '1' || ContractDetailStore.contractType === '2')) &&
                        <Tab eventKey="changePrice" title="단가변경">
                            <ContractAssetList mode={'changePrice'} seq={ContractDetailStore.contractSeq} searchContract={searchContract} searchContractProduct={searchContractProduct} />
                        </Tab>
                    }
                    {/* 계약번호가 넘어 왔으면 계약원부 호출이다. */
                        (ContractDetailStore.detailMode === 'read' && ContractDetailStore.isFix === 1 && ContractDetailStore.isAutoSync !== 1 && (ContractDetailStore.contractType === '1' || ContractDetailStore.contractType === '2')) &&
                        <Tab eventKey="contractHistory" title="계약변경내역">
                            <ContractHistory contractNo={ContractDetailStore.contractNo}/>
                        </Tab>

                    }
                </Tabs>
            </CContainer>
            <ReportContractTemplate ref={componentRef} />
            <SendContractPopup sendContract={sendContract} type={'detail'} />
        </div>
    );
}

export default observer(ContractDetail);
