import { useState } from "react";
import { observer } from "mobx-react-lite";
import { CContainer, CCol, CInputGroup, CalendarGroup, CInputDoubleGroup, CSelectGroup, CSearchBtn, CIconBtn } from "../../components/CustomContainer";
import { Row, Col } from "react-bootstrap";
import { ReleaseAskListStore } from "../../store/release/ReleaseAskListStore";
import { getCommcode } from "../../utils/commcode";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import { AppStore } from "../../store/AppStore";
import { dateFormat } from "../../utils/dateUtils";
import {showToast} from "../../common/utils";

const ReleaseAskListSearch = ({getReleaseAskList}) => {
    const [selSearchUserType, setSelSearchUserType] = useState(0);

    /* 날짜 선택 */
    const onChangeSearchValue = async (v, label) => {
        const { startDate, endDate } = v;
        const storeStartDate = ReleaseAskListStore.releaseAskSearchCondition.startDate;
        const storeEndDate = ReleaseAskListStore.releaseAskSearchCondition.endDate;
        const selectedDate = startDate;

        if (label === 'startDate' && storeEndDate < selectedDate) {
            showToast('종료일보다 작게 선택해주세요.');
        } else if (label === 'endDate' && endDate !== null && storeStartDate > selectedDate) {
            showToast('시작일보다 크게 선택해주세요.');
        } else {
            ReleaseAskListStore.setReleaseAskSearchCondition({ [label]: dateFormat(selectedDate) });
        }
    };

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        ReleaseAskListStore.setReleaseAskSearchCondition({customerNo: customerInfo.customerNo, customerName: customerInfo.customerName});
    }

     /* 유저 조회 팝업 호출 */
     const openSearchUserPopup = () => {
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        if(selSearchUserType === 1){    // 출고 작업자
            ReleaseAskListStore.setReleaseAskSearchCondition({creator: null, releaseUserSeq: userInfo.userSeq, userName: userInfo.name});
        }else { // 의뢰자
            ReleaseAskListStore.setReleaseAskSearchCondition({creator: userInfo.userSeq, releaseUserSeq: null, userName: userInfo.name});
        }
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CalendarGroup asSingle={true} label={'시작일'}
                                       value={{
                                           startDate: ReleaseAskListStore.releaseAskSearchCondition.startDate,
                                           endDate: ReleaseAskListStore.releaseAskSearchCondition.startDate
                                       }}
                                       onChange={v => onChangeSearchValue(v, 'startDate')}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup asSingle={true} label={'종료일'}
                                       value={{
                                           startDate: ReleaseAskListStore.releaseAskSearchCondition.endDate,
                                           endDate: ReleaseAskListStore.releaseAskSearchCondition.endDate
                                       }}
                                       onChange={v => onChangeSearchValue(v, 'endDate')}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'계약번호'}
                            labelId={'contractNo'}
                            value={ReleaseAskListStore.releaseAskSearchCondition.contractNo}
                            onChange={v => ReleaseAskListStore.setReleaseAskSearchCondition({contractNo: v.target.value})}
                            enterKeyHint={()=> getReleaseAskList()}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'출고의뢰번호'}
                            labelId={'releaseAskSeq'}
                            value={ReleaseAskListStore.releaseAskSearchCondition.releaseAskSeq}
                            onChange={v => ReleaseAskListStore.setReleaseAskSearchCondition({releaseAskSeq: v.target.value})}
                            enterKeyHint={()=> getReleaseAskList()}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputDoubleGroup
                            label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객코드'} placeholder2={'고객명'}
                            onCallbackBtn={openSearchCustomerPopup}
                            value={ReleaseAskListStore.releaseAskSearchCondition.customerNo || ''}
                            value2={ReleaseAskListStore.releaseAskSearchCondition.customerName || ''}
                            onChange={v => ReleaseAskListStore.setReleaseAskSearchCondition({customerNo: v.target.value})}
                            onChange2={v => ReleaseAskListStore.setReleaseAskSearchCondition({customerName: v.target.value})}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'현장명'}
                            labelId={'siteName'}
                            value={ReleaseAskListStore.releaseAskSearchCondition.siteName}
                            onChange={v => ReleaseAskListStore.setReleaseAskSearchCondition({siteName: v.target.value})}
                            enterKeyHint={()=> getReleaseAskList()}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'의뢰상태'} labelId={'releaseAskStatus'}
                                      placeholder={'출고의뢰상태'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('5'))}
                                      value={ReleaseAskListStore.releaseAskSearchCondition.releaseAskStatus}
                                      onChange={v => ReleaseAskListStore.setReleaseAskSearchCondition({releaseAskStatus: v.target.value})}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'의뢰구분'} labelId={'releaseAskType'}
                                      placeholder={'출고의뢰구분'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('39'))}
                                      value={ReleaseAskListStore.releaseAskSearchCondition.releaseAskType}
                                      onChange={v => ReleaseAskListStore.setReleaseAskSearchCondition({releaseAskType: v.target.value})}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            selectOptions={
                                [
                                    {name: '의뢰자', value: 0},
                                    {name: '출고작업자', value: 1},
                                ]
                            }
                            onSelect={(e)=> {setSelSearchUserType(e.target.value), ReleaseAskListStore.setReleaseAskSearchCondition({creator: null, releaseUserSeq: null, userName: ''})}}
                            selectValue={selSearchUserType}
                            placeholder={'사원명'}
                            disabled={true}
                            onCallbackBtn={openSearchUserPopup}
                            value={ReleaseAskListStore.releaseAskSearchCondition.userName}
                            onEmptyBtn={() => ReleaseAskListStore.setReleaseAskSearchCondition({creator: null, releaseUserSeq: null, userName: ''})}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getReleaseAskList} />
{/*                        <CIconBtn style={{width: 120}}
                                  title={'기타출고의뢰'}
                                  icon={'fi-rr-box-check'}
                                  onClick={()=> AppStore.toggleModal()}
                        />*/}
                    </Col>
                </Row>
            </CContainer>
            <CustomerSearch callbackFn={getCustomerInfo}/>
            <UserSearch callbackFn={getUserInfo} />
        </>
    )

}

export default observer(ReleaseAskListSearch)
