import { useEffect, useState } from "react";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CAddBtn, CCol, CContainer, CIconBtn, CInputGroup, CSearchBtn } from "../CustomContainer";
import Regex from "../../common/regex";
import AgGridContainer from "../AgGridContainer";
import {findCommKrnm} from "../../utils/commcode";
import CustomerDetail from "../../views/customer/CustomerDetail";
import { callConfirm } from "../../utils";

const CustomerSearch =({ callbackFn=null, column='', ...props })=> {
    const [customerNo, setCustomerNo] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [businessNumber, setBusinessNumber] = useState('');
    const [customerList, setCustomerList] = useState([]);

    const [modalInit, setModalInit] = useState({title: '', openReason:''});

    useEffect(() => {
        if (props.inputCustomerName !== '' && props.inputCustomerName !== null) {
            setCustomerName(props.inputCustomerName);
            setCustomerList(props.gridData);
        }
    }, [props.gridData])

    const refresh = () => {
        setCustomerNo('');
        setCustomerName('');
        setBusinessNumber('');
        setCustomerList([]);
    }

    const onSearchCustomerList = async () => {
        const result = await axios.get('/customer/list', { params: { customerNo, customerName, businessNumber: businessNumber.replaceAll('-', '') } });
        setCustomerList(result);
    }

    const selectedRow = (e) => {
        callbackFn && callbackFn(e.data, column);
        AppStore.toggleCustomerSearchModal();
    }

    /* 사업자번호 검사 */
    const checkFormBusinessNumber = (value) => {
        // 12자리 넘어가면 return false
        if (value.length >= 13) {
            return false;
        }

        // 숫자만 입력하는지 검사
        const inputNumber = value.replaceAll('-', '');
        if (isNaN(inputNumber)) {
            callAlert('숫자만 입력해야 합니다.');
            return false;
        }

        // 000-00-00000 형태로 만들기
        let valueFormat;
        if (inputNumber.length < 4) {
            valueFormat = inputNumber;
        } else if (inputNumber.length > 3 && inputNumber.length < 6) {
            valueFormat = `${inputNumber.substring(0, 3)}-${inputNumber.substring(3, 5)}`
        } else {
            valueFormat = Regex.makeBusinessNumber(inputNumber);
        }
        setBusinessNumber(valueFormat);
    }

    const openCustomerCreate = (type) => {
        setModalInit({title: '고객등록', openReason: 'create'});
        AppStore.toggleCustomerModal();
    }

    const customerCreateCallBack =async(data)=> {
        if(data.customerNo){
            if(await callConfirm(`'${data.customerName}' 을(를) 사용하시겠습니까?`)){
                callbackFn && callbackFn(data);
                AppStore.toggleCustomerSearchModal();
            }
        }
    }

    return (
        <Modal
            title={'고객조회'}
            onHide={() => AppStore.toggleCustomerSearchModal()}
            toggleValue={AppStore.isOpenCustomerSearch}
            className={'modal-w9'}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup label={'고객코드'} value={customerNo} onChange={v => setCustomerNo(v.target.value)} enterKeyHint={onSearchCustomerList} />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'고객명'} value={customerName} onChange={v => setCustomerName(v.target.value)} enterKeyHint={onSearchCustomerList} />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'사업자번호'} value={businessNumber} onChange={e => checkFormBusinessNumber(e.target.value)} enterKeyHint={onSearchCustomerList} />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearchCustomerList} />
                        <CAddBtn title={'신규고객등록'} onClick={openCustomerCreate} style={{width: 120, height: 34}} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    height={29}
                    rowData={customerList}
                    columnDefs={
                        [
                            {field: "customerNo", headerName: "고객코드", width: 100},
                            {field: "customerName", headerName: "고객명", width: 180},
                            {field: "businessNumber", headerName: "사업자번호", width: 130, valueGetter:v => Regex.makeBusinessNumber(v.data.businessNumber)},
                            {field: "owner", headerName: "대표자", width: 120},
                            {field: "companyAddress", headerName: "주소", width: 200},
                            {field: "companyAddressDetail", headerName: "상세주소", width: 200},
                            {field: "saleUserSeq", headerName: "엘리트 영업담당", hide: true},
                            {field: "saleUserSeq2", headerName: "이룸 영업담당", hide: true},
                            {field: "saleUserName", headerName: "영업담당1"},
                            {field: "supportUserName", headerName: "영업담당2"},
                            {field: "isPenaltyFee", headerName: "위약금청구", hide: true, valueGetter: v => v.data.isPenaltyFee === 1 ? 'Y' : ''},
                            {field: "penaltyPercent", headerName: "위약금율", width: 150, hide: true, valueGetter: v => v.data.penaltyPercent ? '잔여 렌탈료의 '+v.data.penaltyPercent+'%' : ''},
                            {field: "billingType", headerName: "청구조건", width: 120, hide: true, valueGetter: v => findCommKrnm(v.data.creator, '21')},
                            {field: "billingDay", headerName: "청구일", width: 80, hide: true, },
                            {field: "dueDateType", headerName: "납기조건", width: 120, hide: true, valueGetter: v => findCommKrnm(v.data.creator, '22')},
                            {field: "dueDateDay", headerName: "납기일", width: 80, hide: true},
                            {field: "paymentType", headerName: "결제방식", width: 120, hide: true, valueGetter: v => findCommKrnm(v.data.creator, '17')},
                            {field: "endDate", headerName: "마감일", hide: true},
                        ]
                    }
                    enableCellTextSelection={true}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>
            {/* 고객상세 팝업 */}
            <CustomerDetail
                title={modalInit.title}
                openReason={modalInit.openReason}
                customerNo={null}
                getCustomerList={null}
                callbackFn={customerCreateCallBack}
            />
        </Modal>
    )
}

export default observer(CustomerSearch);
