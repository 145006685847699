import { observer } from 'mobx-react-lite';
import {Row, Col} from "react-bootstrap";
import {CContainer, CInputGroup, CCol, CSearchBtn} from '../../components/CustomContainer';
import {AssetMaintenanceListStore} from '../../store/asset/AssetMaintenanceListStore';
import { AppStore } from "../../store/AppStore";
import ContractSearch from "../../components/searchModal/ContractSearch";
import ProductListSearch from "../../components/searchModal/ProductListSearch";
import CustomerSearch from '../../components/searchModal/CustomerSearch';

const AssetMaintenanceListSearch = (props) => {
    
    const {getAssetMaintenanceList} = props;


    /* 제품조회 팝업 콜백 */
    const callbackProductInfo = (info) => {
        AssetMaintenanceListStore.search.productName = info.productName;
    }

     /* 계약조회 팝업 콜백 */
     const callbackContractInfo = (info) => {
        AssetMaintenanceListStore.search.contractNo = info.contractNo;
    }

    return (
        <>
            {/* 검색조건 */}
            <CContainer search>
                <Row>
                    {/*
                    <CCol lg={2}>
                        <CInputGroup label={'자산번호'} labelId={'assetNo'}
                                     value={AssetMaintenanceListStore.search.assetNo}
                                     onChange={v=> AssetMaintenanceListStore.search.assetNo = v.target.value}
                                     enterKeyHint={getAssetMaintenanceList}
                        />
                    </CCol>
                    */}
                    <CCol>
                        <CInputGroup label={'관리번호'} labelId={'managementNo'}
                                     value={AssetMaintenanceListStore.search.managementNo}
                                     onChange={v=> AssetMaintenanceListStore.search.managementNo = v.target.value}
                                     enterKeyHint={getAssetMaintenanceList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'시리얼번호'} labelId={'serialNo'}
                                     value={AssetMaintenanceListStore.search.serialNo}
                                     onChange={v=> AssetMaintenanceListStore.search.serialNo = v.target.value}
                                     enterKeyHint={getAssetMaintenanceList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'계약번호'} labelId={'contractNo'}
                                     value={AssetMaintenanceListStore.search.contractNo}
                                     onCallbackBtn={() => AppStore.toggleContractSearchModal()}
                                     onChange={v => AssetMaintenanceListStore.search.contractNo = v.target.value}
                                     enterKeyHint={getAssetMaintenanceList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'제품명'} labelId={'productCode'}
                                     value={AssetMaintenanceListStore.search.productName}
                                     onCallbackBtn={() => AppStore.toggleProductListSearchModal()}
                                     onChange={v => AssetMaintenanceListStore.search.productName = v.target.value}
                                     enterKeyHint={getAssetMaintenanceList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'모델명'} labelId={'model'}
                                     value={AssetMaintenanceListStore.search.model}
                                     onChange={v=> AssetMaintenanceListStore.search.model = v.target.value}
                                     enterKeyHint={getAssetMaintenanceList}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getAssetMaintenanceList} />
                    </Col>
                </Row>
            </CContainer>
            <ProductListSearch callbackFn={callbackProductInfo}/>
            <ContractSearch callbackFn={callbackContractInfo}/>
        </>
    );
};

export default observer(AssetMaintenanceListSearch);
