import React from "react";
import { observer } from "mobx-react-lite";
import { Row, Col } from "react-bootstrap";

import { CCol, CContainer, CalendarGroup, CSearchBtn, CInputDoubleGroup, CIconBtn } from "../../components/CustomContainer";
import { AppStore } from "../../store/AppStore";
import { CustomerLedgerStore } from "../../store/accounting/CustomerLedgerStore";
import { startDateForThisYear, endDateForThisYear, startDateForThisMonth, endDateForThisMonth, startDateForLastYear, endDateForLastYear, startDateForNextYear, endDateForNextYear, startDateForLastMonth, endDateForLastMonth, startDateForNextMonth, endDateForNextMonth } from "../../utils/dateUtils";

const CustomerLedgerListSearch = ({getCustomerLedgerList}) => {
    const store = CustomerLedgerStore;

    /* 금년 */
    const thisYearBtn = () => {
        store.search.startDate = startDateForThisYear();
        store.search.endDate = endDateForThisYear();
    }

    /* 전년 */
    const prevYearBtn = () => {
        store.search.startDate = startDateForLastYear(store.search.startDate);
        store.search.endDate = endDateForLastYear(store.search.endDate);
    }

    /* 후년 */
    const nextYearBtn = () => {
        store.search.startDate = startDateForNextYear(store.search.startDate);
        store.search.endDate = endDateForNextYear(store.search.endDate);
    }

    /* 금월 */
    const thisMonthBtn = () => {
        store.search.startDate = startDateForThisMonth();
        store.search.endDate = endDateForThisMonth();
    }

    /* 전월 */
    const prevMonthBtn = () => {
        store.search.startDate = startDateForLastMonth(store.search.startDate);
        store.search.endDate = endDateForLastMonth(store.search.endDate);
    }

    /* 후월 */
    const nextMonthBtn = () => {
        store.search.startDate = startDateForNextMonth(store.search.startDate);
        store.search.endDate = endDateForNextMonth(store.search.endDate);
    }

    return (
        <CContainer search height={'100%'}>
            <Row>
                <CCol lg={12}>
                    <CIconBtn onClick={prevYearBtn} title={'<'} style={{minWidth: '10px'}} className={'me-0'} />
                    <CIconBtn onClick={thisYearBtn} title={'금년'} className={'ms-1 me-1'} />
                    <CIconBtn onClick={nextYearBtn} title={'>'} style={{minWidth: '10px'}} className={'ms-0 me-3'} />
                    <CIconBtn onClick={prevMonthBtn} title={'<'} style={{minWidth: '10px'}} className={'me-0'} />
                    <CIconBtn onClick={thisMonthBtn} title={'금월'} className={'ms-1 me-1'} />
                    <CIconBtn onClick={nextMonthBtn} title={'>'} style={{minWidth: '10px'}} className={'ms-0'} />
                </CCol>
                <CCol lg={12}>
                    <CalendarGroup label={'시작일'}
                                    asSingle={true}
                                    value={{
                                        startDate: store.search.startDate,
                                        endDate: store.search.startDate
                                    }}
                                    onChange={v => {store.search.startDate = v.startDate}}
                    />
                </CCol>
                <CCol lg={12}>
                    <CalendarGroup label={'종료일'}
                                    asSingle={true}
                                    value={{
                                        startDate: store.search.endDate,
                                        endDate: store.search.endDate
                                    }}
                                    onChange={v => {store.search.endDate}}
                    />
                </CCol>
            </Row>
            <Row>
                <CCol lg={12}>
                    <CInputDoubleGroup label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                                        placeholder={'고객코드'} placeholder2={'고객명'}
                                        onCallbackBtn={() => AppStore.toggleCustomerSearchModal()}
                                        value={store.search.customerNo} value2={store.search.customerName}
                                        onChange={v => store.search.customerNo = v.target.value}
                                        onChange2={v => store.search.customerName = v.target.value}
                                        enterKeyHint1={()=> getCustomerLedgerList()}
                                        enterKeyHint2={()=> getCustomerLedgerList()}
                    />
                </CCol>
                <CCol lg={12}>
                        <CInputDoubleGroup label={'계정과목'} labelId={'accountCode'} labelId2={'accountName'}
                                            placeholder={'계정코드'} placeholder2={'계정과목명'}
                                            onCallbackBtn={() => AppStore.toggleAccountingCodePopup()}
                                            value={store.search.accountCode} value2={store.search.accountName}
                                            onChange={v => store.search.accountCode = v.target.value}
                                            onChange2={v => store.search.accountName = v.target.value}
                                            disabled2={true}
                                            enterKeyHint1={()=> getCustomerLedgerList()}
                                            enterKeyHint2={()=> getCustomerLedgerList()}
                        />
                </CCol>
            </Row>
            <Row>
                <Col className='d-flex flex-row-reverse'>
                    <CSearchBtn onClick={getCustomerLedgerList} />
                </Col>
            </Row>
        </CContainer>
    );
}

export default observer(CustomerLedgerListSearch);
