import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Row, Col } from "react-bootstrap";
import axios from 'axios';

import { CContainer } from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import { AccountCloseStore } from "../../store/accounting/AccountCloseStore";
import AccountCloseContainer from "./AccountCloseContainer";
import AccountCloseSearch from "./AccountCloseSearch";
import { getYear } from "../../utils/dateUtils";
import { now } from "lodash";

const AccountCloseList = () => {
    const store = AccountCloseStore;
    const gridRef = useRef();
    const [isEnter, setIsEnter] = useState(true);

    useEffect(() => {
        if(isEnter) {
            store.search.targetYear = getYear(now());
            store.search.nextYear = String(Number(store.search.targetYear) + 1);
            setIsEnter(false);
        }
        getYearCloseCheck();
    }, [store.search.targetYear]);

    /* 회계년 마감 확인 */
    const getYearCloseCheck = async () => {
        const result = await axios.get('statement/year/close', {params: store.search});
        store.isYearClose = result;
    }

    const getAccountCloseList = async () => {
        const result = await axios.get('statement/close/list', {params: store.search});
        store.accountList = result;
    };

    const columnDefs = [
        {field: "accountCode", headerName: "계정코드", minWidth: 110},
        {field: "name", headerName: "계정과목명", minWidth: 160},
        {field: "amount", headerName: "잔액", minWidth: 150, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), flex: 1},
    ];

    const onRowDoubleClicked = async (e) => {
        const result = await axios.get('statement/close/details', {params: {targetYear: store.search.targetYear, accountCode: e.data.accountCode}});
        store.accountAmountList = result;
    }

    /* 행 고정 */
    const pinnedTopRowData = [{
        amount: store.accountList.reduce((total, v) => {
            return total + v.amount;
        }, 0),
    }];

    return (
        <>
            <AccountCloseSearch getAccountCloseList={getAccountCloseList} getYearCloseCheck={getYearCloseCheck} />
            <Row>
                <Col lg={4}>
                    <CContainer>
                        <AgGridContainer
                            gridRef={gridRef}
                            gridTitle={''}
                            height={60}
                            rowData={store.accountList}
                            columnDefs={columnDefs}
                            gridMarginTop={28}
                            rowDoubleClickCallback={e => onRowDoubleClicked(e)}
                            pinnedTopRowData={pinnedTopRowData}
                            getRowStyle={(params) => {
                                if (params.data.name === '[합계]') {
                                    return { pointerEvents: 'none'};
                                }
                                return null;
                            }}
                        />
                    </CContainer>
                </Col>
                <AccountCloseContainer />
            </Row>
        </>
    )
}

export default observer(AccountCloseList);
