import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import axios from "axios";

import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import {CCol, CContainer, CSearchBtn, CInputDoubleGroup, CInputGroup} from "../CustomContainer";
import CustomerSearch from "./CustomerSearch";
import CustomerBillingAddressSearch from "./CustomerBillingAddressSearch";
import LUtils from "../../utils/lodashUtils";
import AgGridContainer from "../AgGridContainer";
import {showToast} from "../../common/utils";

const ContractSearch =({ callbackFn=null, column='', ...props })=> {
    const [searchList, setSearchList] = useState([]);
    const [customerNo, setCustomerNo] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [billingAddressSeq, setBillingAddressSeq] = useState('');
    const [billingName, setBillingName] = useState('');
    const [contractNo, setContractNo] = useState('');
    const [managementNo, setManagementNo] = useState('');

    const onSearch = async () => {
        if (LUtils.isEmpty(customerNo) && LUtils.isEmpty(customerName) && LUtils.isEmpty(contractNo) && LUtils.isEmpty(managementNo)) {
            showToast("검색조건을 입력해주세요!");
            return false;
          }
        const result = await axios.get('/contract/list', { params: { customerNo, customerName, contractNo, billingAddressSeq, managementNo } });

        // 기타출고의뢰에서 계약 조회한 경우 contractType: 1, 2만 조회
        setSearchList(props.isEtcReleaseAsk ? result.filter(v => ['1', '2'].includes(v.contractType)) : result);
    }

    const selectedRow = (e) => {
        callbackFn && callbackFn(e.data, column);
        AppStore.toggleContractSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        if(customerInfo){
            setCustomerNo(customerInfo.customerNo);
            setCustomerName(customerInfo.customerName);
        }
    }

    /* 청구지 정보 콜백 함수 */
    const getBillingAddressInfo = (info) => {
        setBillingAddressSeq(info.billingAddressSeq);
        setBillingName(info.billingAddressName);
    }

    /* 초기화 */
    const refresh = () => {
        setCustomerNo('');
        setCustomerName('');
        setBillingAddressSeq('');
        setBillingName('');
        setContractNo('');
        setManagementNo('');
        setSearchList([]);
    }

    return (
        <Modal
            title={'계약조회'}
            show={AppStore.isOpenContractSearch}
            onHide={() => AppStore.toggleContractSearchModal()}
            toggleValue={AppStore.isOpenContractSearch}
            className={'search-modal-6'}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    <CCol lg={4}>
                        <CInputDoubleGroup
                            label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객코드'} placeholder2={'고객명'}
                            onCallbackBtn={() => AppStore.toggleCustomerSearchModal()}
                            value={customerNo || ''}
                            value2={customerName || ''}
                            onChange={(v) => setCustomerNo(v.target.value)}
                            onChange2={(v) => setCustomerName(v.target.value)}
                            enterKeyHint1={()=> onSearch()}
                            enterKeyHint2={()=> onSearch()}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputDoubleGroup label={'청구지'} labelId={'billingSeq'} labelId2={'billingName'}
                                           placeholder={'번호'}
                                           placeholder2={'청구지명'}
                                           disabled={true} disabled2={true}
                                           onCallbackBtn={() => AppStore.toggleCustomerBillingAddressSearchModal()}
                                           value={billingAddressSeq || ''}
                                           value2={billingName || ''}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup label={'계약번호'} labelId={'contractNo'}
                                     value={contractNo}
                                     onChange={(v)=> setContractNo(v.target.value)}
                                     enterKeyHint={()=> onSearch()}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'관리번호'} labelId={'managementNo'}
                                     value={managementNo}
                                     onChange={(v)=> setManagementNo(v.target.value)}
                                     enterKeyHint={()=> onSearch()}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearch} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    height={30}
                    rowData={searchList}
                    columnDefs={[
                        {field: "contractNo", headerName: "계약번호", width: 160},
                        {field: "customerNo", headerName: "고객코드", hide: true},
                        {field: "customerName", headerName: "고객명", width: 160},
                        {field: "billingAddressSeq", headerName: "청구지번호", width: 110},
                        {field: "billingAddressName", headerName: "청구지명", width: 150},
                        {field: "contractStartDate", headerName: "시작일", width: 120},
                        {field: "contractEndDate", headerName: "종료일", width: 120},
                        {field: "rentMonth", headerName: "대여월", width: 100},
                        {field: "rentDay", headerName: "대여일", width: 100},
                        {field: "isFix", headerName: "확정여부", width: 100, valueFormatter: v => v.value ? '확정' : '미확정', flex:1,},
                    ]}
                    enableCellTextSelection={true}
                    animateRows={false}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>
            <CustomerSearch callbackFn={getCustomerInfo}/>
            <CustomerBillingAddressSearch customerNo={customerNo} callbackFn={getBillingAddressInfo} />
        </Modal>
    )
}

export default observer(ContractSearch);
