import { observer } from "mobx-react-lite"
import AgGridContainer from "../../../components/AgGridContainer";
import { findCommKrnm } from "../../../utils/commcode";
import { dateFormat } from "../../../utils/dateUtils";
import { useEffect, useRef } from "react";
import axios from "axios";
import { NonpaymentStore } from "../../../store/billing/NonpaymentStore";
import { getUserName } from "../../../utils/userUtils";

const SmsHistoryList = ({getSmsHistoryList}) => {
    const gridRef = useRef();

    useEffect(() => {
        getSmsHistoryList();
    }, [])
    
    return (
        <AgGridContainer
            gridRef={gridRef}
            rowData={NonpaymentStore.smsHistoryList}
            height={25}
            columnDefs={
                [
                    {field: "seq", headerName: "순번", valueGetter: 'node.rowIndex + 1', width: 80},
                    {field: "content", headerName: "내용", width: 100, flex: 1},
                    {field: "toUser", headerName: "수신인", width: 150},
                    {field: "sendDate", headerName: "발송일자", width: 180},
                    {field: "creator", headerName: "발송인", valueFormatter: v => getUserName(v.value), width: 180},
                    {field: "completeStatus", headerName: "발송결과", valueFormatter: v => v.value === '1' ? '성공' : '실패', width: 100},
                ]
            }
        />
    )
}

export default observer(SmsHistoryList);