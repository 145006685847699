import { useState, useEffect } from "react";
import axios from "axios";
import { Row, InputGroup, Col, Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { AppStore } from "../../store/AppStore";
import { CContainer, CCol, CSearchBtn, CInputGroup } from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";
import Modal from "../Modal";
import { SiteSearchStore } from "../../store/search/SiteSearchStore";
import {autoHyphen, decrypt} from "../../common/utils";

const SiteNameSearch = ({callbackFn=null, ...props}) => {
    const [siteInfoList, setSiteInfoList] = useState([]);
    const [managerName, setManagerName] = useState('');

    useEffect(() => {
        if(props.inputSiteName === ''||props.inputSiteName === null){
            props.setInputSiteName('');
            setSiteInfoList(props.gridData);
        } else {
            onSearchSiteInfo(props.inputSiteName);
        }
    }, [props.gridData]);

    const onSearchSiteInfo = async (propsInput) => {
        const result = await axios.get('/site/info', {params: {siteName: SiteSearchStore.search.siteName, managerName: SiteSearchStore.search.managerName, isDeleted: SiteSearchStore.search.isDeleted}});
        if(result){
            result.map(v => {
                if(v.managerPhoneNumber) v.managerPhoneNumber = autoHyphen(decrypt(v.managerPhoneNumber));
                return v;
            })
            setSiteInfoList(result);
        }
    }

    const selectedRow = (e) => {
        callbackFn && callbackFn(e.data);
        AppStore.toggleSiteNameSearchPopup();
    }

    const refresh = () => {
        SiteSearchStore.init();
        onSearchSiteInfo(SiteSearchStore.search.siteName);
    }

    return (
        <>
            <Modal
                title={'현장 조회'}
                onHide={() => AppStore.toggleSiteNameSearchPopup()}
                toggleValue={AppStore.isOpenSiteNameSearchPopup}
                size={'lg'}
                onExit={refresh}
            >
                <CContainer search>
                    <Row>
                        <CCol lg={4}>
                            <CInputGroup
                                label={'현장명'}
                                value={SiteSearchStore.search.siteName}
                                onChange={v => SiteSearchStore.search.siteName = v.target.value}
                                enterKeyHint={onSearchSiteInfo}
                            />
                        </CCol>
                        <CCol lg={4}>
                            <CInputGroup
                                label={'담당자명'}
                                value={SiteSearchStore.search.managerName}
                                onChange={v => SiteSearchStore.search.managerName = v.target.value}
                                enterKeyHint={onSearchSiteInfo}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={2}>
                            <Form.Check
                                type="switch" 
                                id="exceptDel"
                                label="삭제건 제외"
                                style={{marginTop: 3}}
                                checked={SiteSearchStore.search.isDeleted === 1}
                                value={SiteSearchStore.search.isDeleted === 1}
                                onChange={v=> SiteSearchStore.search.isDeleted = v.target.checked ? 1 : 0}
                            />
                        </CCol>
                        <Col className='d-flex flex-row-reverse'>
                            <CSearchBtn onClick={onSearchSiteInfo} style={{width:100}} />
                        </Col>
                    </Row>
                </CContainer>
                <CContainer>
                    <AgGridContainer
                        height={26}
                        rowData={siteInfoList}
                        columnDefs={[
                                {field: "siteSeq", headerName: "현장번호", hide: true},
                                {field: "siteName", headerName: "현장명", width: 180},
                                {field: "address", headerName: "주소", width: 200},
                                {field: "addressDetail", headerName: "상세주소", width: 240, flex: 1},
                            ]}
                        enableCellTextSelection={true}
                        rowDoubleClickCallback={selectedRow}
                    />
                </CContainer>
            </Modal>
        </>
    );

}

export default observer(SiteNameSearch);
