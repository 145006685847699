import { useState } from "react";
import { observer } from "mobx-react-lite";
import { CContainer, CCol, CInputGroup, CSearchBtn, CAddBtn } from "../../components/CustomContainer";
import { Row, InputGroup, Form, Col } from "react-bootstrap";
import { ProductStore } from "../../store/product/ProductStore";
import { CommcodeSMOptions } from '../../components';
import { AppStore } from "../../store/AppStore";

const ProductListSearch = ({productGroupList, lgList, getProductLst}) => {
    const [used, setUsed] = useState(0);
    const [brandCode, setBrandCode] = useState('');
    const [mdList, setMDList] = useState([]);
    const [smList, setSMList] = useState([]);
    const [xsList, setXSList] = useState([]);

    /* 대분류 클릭 시 중분류 담아주기 */
    const onChangeLGCode = (e) => {
        setMDList(productGroupList.filter(v => v.parentSeq === Number(e.target.value)));
        ProductStore.setProductSearchCondition({
            lgSeq: e.target.value,
            mdSeq: '',
            smSeq: '',
            xsSeq: '',
        });
    }

    /* 중분류 클릭 시 소분류 담아주기 */
    const onChangeMDCode = (e) => {
        setSMList(productGroupList.filter(v => v.parentSeq === Number(e.target.value)));
        ProductStore.setProductSearchCondition({
            mdSeq: e.target.value,
            smSeq: '',
            xsSeq: '',
        });
    }

    /* 소분류 클릭 이벤트 */
    const onChangeSMCode = (e) => {
        setXSList(productGroupList.filter(v => v.parentSeq === Number(e.target.value)));
        ProductStore.setProductSearchCondition({
            smSeq: e.target.value,
            xsSeq: '',
        });
    }

    /* 세분류 클릭 이벤트 */
    const onChangeXSCode = (e) => {
        ProductStore.setProductSearchCondition({
            xsSeq: e.target.value,
        });
    }

    /* [공통코드] 제조사 */
    const onChangeBrand = (v) => {
        setBrandCode(v.target.value);
        ProductStore.setProductSearchCondition({brandCode: v.target.value});
    }

    /* 사용여부 select box, 클릭 시 이벤트 */
    function UsedSelectBox(props) {
        const handleChange = (e) => {
            setUsed(e.target.value);
            ProductStore.setProductSearchCondition({isDeleted: e.target.value});
        };

        return (
            <Form.Select value={props.value} onChange={handleChange}>
                <option value="">- 사용여부 -</option>
                <option value="0">사용</option>
                <option value="1">미사용</option>
            </Form.Select>
        );
    }

    /* 제품코드 input 담아주기 */
    const onChangeSearchValue = (v, label) => {
        ProductStore.setProductSearchCondition({[label]: v.target.value});
    }

    return (
        <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={ProductStore.productSearchCondition.lgSeq} onChange={onChangeLGCode}>
                                <option value={0}>- 대분류 -</option>
                                {lgList.map(lg => (
                                    <option key={lg.productGroupSeq} value={lg.productGroupSeq}>{lg.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={ProductStore.productSearchCondition.mdSeq} onChange={onChangeMDCode}>
                                <option value={0}>- 중분류 -</option>
                                {mdList.map(md => (
                                    <option key={md.productGroupSeq} value={md.productGroupSeq}>{md.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={ProductStore.productSearchCondition.smSeq} onChange={onChangeSMCode}>
                                <option value={0}>- 소분류 -</option>
                                {smList.map(sm => (
                                    <option key={sm.productGroupSeq} value={sm.productGroupSeq}>{sm.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={ProductStore.productSearchCondition.xsSeq} onChange={onChangeXSCode}>
                                <option value={0}>- 세분류 -</option>
                                {xsList.map(xs => (
                                    <option key={xs.productGroupSeq} value={xs.productGroupSeq}>{xs.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select
                                aria-label="선택"
                                value={brandCode}
                                onChange={onChangeBrand}>
                                <option value={''}>- 제조사 -</option>
                                { <CommcodeSMOptions commKind={'18'} />}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'제품명'}
                            labelId={'productName'}
                            placeholder={'제품명'}
                            value={ProductStore.productSearchCondition.productName}
                            onChange={v => onChangeSearchValue(v, 'productName')}
                            enterKeyHint={getProductLst}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'모델명'}
                            labelId={'model'}
                            placeholder={'모델명'}
                            value={ProductStore.productSearchCondition.model}
                            onChange={v => onChangeSearchValue(v, 'model')}
                            enterKeyHint={getProductLst}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'동력전달방식'}
                            value={ProductStore.productSearchCondition.powerTransmission}
                            onChange={(e) => ProductStore.productSearchCondition.powerTransmission = e.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'운행속도'}
                            value={ProductStore.productSearchCondition.drivingSpeed}
                            onChange={(e) => ProductStore.productSearchCondition.drivingSpeed = e.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'작업최대높이'}
                            value={ProductStore.productSearchCondition.workingMaximumHeight}
                            onChange={(e) => ProductStore.productSearchCondition.workingMaximumHeight = e.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'적재용량'}
                            value={ProductStore.productSearchCondition.loadCapacity}
                            onChange={(e) => ProductStore.productSearchCondition.loadCapacity = e.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'운전방식'}
                            value={ProductStore.productSearchCondition.drivingMethod}
                            onChange={(e) => ProductStore.productSearchCondition.drivingMethod = e.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <UsedSelectBox value={used} />
                        </InputGroup>
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getProductLst} />
                        <CAddBtn onClick={() => {
                                ProductStore.data.productSeq = 0;
                                ProductStore.init();
                                ProductStore.filesInit();
                                AppStore.toggleInsertProduct()
                            }}
                        />
                    </Col>
                </Row>
            </CContainer>

    );
}

export default observer(ProductListSearch);
