import { observable } from "mobx";

export const AccountLedgerStore = observable({
    accountList: [],
    orgAccountLedgerList: [],
    accountLedgerList: [],
    search: {
        startDate: '',
        endDate: '',
        customerNo:'',
        customerName:'',
        firstAccountCode: '',
        firstAccountName: '',
        lastAccountCode: '',
        lastAccountName: '',
        accountList: [],
        accountSelectedList: [],
    },
    statementNo: '',
    initStatementNo() {
        this.statementNo = '';
    },
})
