import { observable } from "mobx";
import { oneMonthAgo, today } from "../../utils/dateUtils";
import LUtils from "../../utils/lodashUtils";

export const GeneralStatementStore = observable({

    statementList: [],
    subStatementList: [],

    modalTotalDebit: 0,      // 모달 차변 총합 (gsTotalDebit)
    modalTotalCredit: 0,     // 모달 대변 총합 (gsTotalCredit)
    modalTotalDifferent: 0,  // 모달 분개 차액 (gsTotalDifference)
    totalAmount: 0,          // 메인 리스트 그리드 총 전표금액 (totalAmount) 
    difference: 0,           // 메인 리스트 그리드 총 대차차액 (difference)
    totalDebit: 0,           // 메인 서브 그리드 총 차변 (gsTotalDebit)
    totalCredit: 0,           // 메인 서브 그리드 총 대변 (gsTotalCredit)
    seq: 0,
    maxSeq: 0,
    
    data: {
        statementNo: '',
        resolutionDate: today(),   // 결의일자
        postingDate: null, // 기표일자
        statementType: '1',   // 전표유형(1: 일반)
        remark: '',    // 전표설명
        managementPart: '',  // 관리항목
        approvalStatus: '',  // 승인여부 
        billNo: '', // 계산서 번호
        generalStatementList: [],
    },

    search: {
        resolutionStartDate: oneMonthAgo(),
        resolutionEndDate: today(),
        statementNoType: '',       // 전표유형
        statementCategory: '',   // 전표구분
        departmentSeq: '',    // 입력부서
        creator: '',        // 입력사원
        creatorName: '',    // 입력사원명
        clientName: '',         // 거래처
        accountCode: '',  // 계정과목
        accountCodeName: '',  // 계정과목명
    },

    init() {
        this.data.statementNo = '';
        this.data.resolutionDate = today();   // 결의일자
        this.data.postingDate = null; // 기표일자
        this.data.statementType = '1';   // 전표유형
        this.data.remark = '';    // 전표설명
        this.data.statementStatus = '1'; // 전표상태
        this.data.approvalStatus = '1';
        this.data.managementPart = '';  // 관리항목

        this.data.generalStatementList = [];
    },

    setStatementList(v) {
        this.statementList = v;
    },
    setData(v) {
        LUtils.assign(this.data, v);
    },
    setSearch(v) {
        LUtils.assign(this.search, v);
    },
    setGeneralStatementList(v) {
        this.data.generalStatementList = v;
    },


});