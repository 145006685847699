import { observer } from "mobx-react-lite"
import { useRef, useState } from "react"
import SalaryManagerSearch from "./SalaryManagerSearch";
import { CContainer } from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import { SalaryManagerStore } from "../../store/human-resources/SalaryManagerStore";
import { findCommKrnm } from "../../utils/commcode";
import { getUserName } from "../../utils/userUtils";
import { showToast } from "../../common/utils";
import LUtils from "../../utils/lodashUtils";
import axios from "axios";
import Modal from "../../components/Modal";
import SalarySelectPopup from "./popup/SalarySelectPopup";
import { callConfirm } from "../../utils";

const SalaryManager = () => {
    const gridRef = useRef();

    /* 급여 저장 */
    const saveUserSalary = async ({updatedList}) => {
        if (!await callConfirm('저장하시겠습니까?')) {
            return;
        }

        const validClose = updatedList.every(v => v.isClose !== '1')
        if (!validClose) {
            showToast('마감된 급여내역은 수정할 수 없습니다.');
            return;
        }

        await axios.post('/salary', updatedList);
        showToast('저장이 완료되었습니다.');
        getUserSalaryList();
    }

    /* 급여 대장 조회 */
    const getUserSalaryList = async () => {      
        let requiredParams = ['salaryType'];
        if (!requiredParams.every(param => SalaryManagerStore.search[param])) {
            showToast('급여구분을 선택해주세요.');
            return;
        }

        const userSalaryList = await axios.get('/salary', {params: {...SalaryManagerStore.search, imputedDate: SalaryManagerStore.search.year + "-" + SalaryManagerStore.search.month}});
        if (userSalaryList.length === 0) {
            showToast('생성된 급여 대장이 없습니다.');
        }

        SalaryManagerStore.userSalaryList = userSalaryList;
    }

    /* 셀 수정 이벤트 */
    const amountChangeFn = async (e) => {
        const { field } = e.colDef;

        const numberColumns = ['basicSalary', 'bonus', 'mealAllowance', 'overtimeAllowance', 'selfDrivingSubsidy', 'cellPhoneAllowance', 'otherAllowance', 
                                'annualAllowance', 'teamLeaderAllowance', 'extraAllowance'];
        if (LUtils.some(LUtils.values(LUtils.pick(e.data, numberColumns)), e => isNaN(e))) {
            showToast('숫자만 입력 가능합니다.');
            e.data[field] = 0;
            gridRef.current.api.redrawRows();
            return false;
        }

        let selfDrivingSubsidyMod = 0;
        let selfDrivingSubsidyVal = 0;
        let totalNonTaxation = 0;
        let totalTaxation = 0;

        // 자가운전보조금은 최대 20만원까지만 공제됨
        if ( e.data.selfDrivingSubsidy > 200000 ) {
            selfDrivingSubsidyMod = 200000;
            selfDrivingSubsidyVal = checkUndefinedAndNull(e.data.selfDrivingSubsidy) - 200000;
        } else {
            selfDrivingSubsidyMod = checkUndefinedAndNull(e.data.selfDrivingSubsidy);
        }

        // 비과세총액 계산
        totalNonTaxation = selfDrivingSubsidyMod;
        // 과세총액 계산
        const taxationCategory = ['basicSalary', 'bonus', 'mealAllowance', 'overtimeAllowance', 'cellPhoneAllowance', 'otherAllowance', 'annualAllowance', 'teamLeaderAllowance', 'seniorityAllowance', 'extraAllowance'];
        taxationCategory.map(v => totalTaxation = totalTaxation + checkUndefinedAndNull(e.data[v]));
        totalTaxation = totalTaxation + selfDrivingSubsidyVal;

        e.data.totalTaxation = totalTaxation;
        e.data.totalNonTaxation = totalNonTaxation;
        e.data.grossSalary = totalTaxation + totalNonTaxation;  // 총급여

        // 공제항목 계산
        e.data.nationalPension = (Math.floor(e.data.totalTaxation/1000) * 1000) * (4.5 / 100);      // 국민연금(기준소득월액-천 원 미만 절사)
        e.data.healthInsurance = Math.floor(e.data.grossSalary * (3.43 / 100));                     // 건강보험
        e.data.careInsurance = Math.floor(e.data.healthInsurance * (11.52 / 100));                  // 장기요양보험
        e.data.employmentInsurance = Math.floor(e.data.grossSalary * (0.8 / 100));                  // 고용보험

        // 소득세, 지방소득세
        const params = {
            totalTaxation: Math.round(e.data.totalTaxation * 0.001), 
            year: e.data.imputedDate.substring(0, 4)
        }
        const tax = await axios.get('/salary/tax', {params: params});
        e.data.incomeTax = tax.dependentsOne;
        e.data.inhabitantsTax = Math.floor((tax.dependentsOne * 0.1) / 10) * 10;

        // 공제총합 계산
        const deductionCategory = ['nationalPension', 'healthInsurance', 'careInsurance', 'employmentInsurance', 'incomeTax', 'inhabitantsTax', 'healthInsuranceSettlement'
                                , 'careInsuranceSettlement', 'nationalPensionSettlement', 'yearEndIncomeTax', 'yearEndSettlementResidentTax', 'otherSettlements'];
        let totalDeduction = 0;
        deductionCategory.map(v => totalDeduction = totalDeduction + checkUndefinedAndNull(e.data[v]));
        e.data.totalDeduction = totalDeduction;
        // 실수령액 계산
        e.data.netPay = e.data.grossSalary - e.data.totalDeduction;
    }

    /* undefined나 null이면 숫자 0 리턴 */
    const checkUndefinedAndNull = (property) => {
        if (property === null || property == undefined) {
            return Number(0);
        } else {
            return Number(property);
        }
    }

    const onExit = () => {
        SalaryManagerStore.salaryHistoryList = [];
        SalaryManagerStore.salaryHistoryDetailList = [];
    }

    return (<>
        {/* 검색조건 */}
            <SalaryManagerSearch getUserSalaryList={getUserSalaryList}/>
            <CContainer>
                {/* grid */}
                <AgGridContainer
                    gridTitle={'급여관리 목록'}
                    gridRef={gridRef}
                    height={58}
                    rowData={SalaryManagerStore.userSalaryList}
                    columnDefs={[
                        {
                            headerName: '사원정보',
                            marryChildren: true,
                            children: [
                                // {field: "year", headerName: "연도", width: 60, hide: false, valueFormatter: v => {return (v.data.imputedDate).substring(0,3)}},
                                {field: "year", headerName: "연도", width: 60, valueFormatter: v => {return (v.data.imputedDate)}, hide: true},
                                {field: "seq", headerName: "순번", valueGetter: v => v.node.rowIndex + 1, width: 60, hide: true},
                                {field: "isClose", headerName: "마감", width: 59, valueGetter: v => v.data.isClose !== '1' ? '' : '마감'},
                                {field: "userSeq", headerName: "사원번호", width: 85},
                                {field: "userName", headerName: "사원명", valueFormatter: v => {return getUserName(v.data.userSeq)}, width: 120},
                                {field: "teamName", headerName: "소속팀", valueFormatter: v => {return findCommKrnm(v.data.teamSeq, '33')}, width: 90},
                                {field: "positionName", headerName: "직책", valueFormatter: v => {return findCommKrnm(v.data.positionSeq, '31')}, width: 110},
                                {field: "salaryType", headerName: "급여구분", hide: true},
                                {field: "email", headerName: "이메일", hide: true},
                            ]
                        },
                        {
                            headerName: '지급항목',
                            marryChildren: true,
                            children: [
                                {field: "basicSalary", headerName: "기본급", editable: true, onCellValueChanged: amountChangeFn, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "bonus", headerName: "상여금", editable: true, onCellValueChanged: amountChangeFn, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "mealAllowance", headerName: "식대", editable: true, onCellValueChanged: amountChangeFn, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "overtimeAllowance", headerName: "연장근로수당", editable: true, onCellValueChanged: amountChangeFn, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), width: 105},
                                {field: "selfDrivingSubsidy", headerName: "자가운전보조금", editable: true, onCellValueChanged: amountChangeFn, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), width: 115},
                                {field: "cellPhoneAllowance", headerName: "통신비", editable: true, onCellValueChanged: amountChangeFn, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "otherAllowance", headerName: "기타수당", editable: true, onCellValueChanged: amountChangeFn, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "annualAllowance", headerName: "연차수당", editable: true, onCellValueChanged: amountChangeFn, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "teamLeaderAllowance", headerName: "팀장수당", editable: true, onCellValueChanged: amountChangeFn, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "seniorityAllowance", headerName: "근속수당", editable: true, onCellValueChanged: amountChangeFn, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "extraAllowance", headerName: "교통비외수당", editable: true, onCellValueChanged: amountChangeFn, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), width: 105},
                                {field: "totalTaxation", headerName: "과세총액", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                {field: "totalNonTaxation", headerName: "비과세총액", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                {field: "grossSalary", headerName: "총급여", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                            ]
                        },
                        {
                            headerName: '공제항목',
                            marryChildren: true,
                            children: [
                                {field: "nationalPension", headerName: "국민연금", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "healthInsurance", headerName: "건강보험", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "careInsurance", headerName: "요양보험", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "employmentInsurance", headerName: "고용보험", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "incomeTax", headerName: "소득세", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "inhabitantsTax", headerName: "지방소득세", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "healthInsuranceSettlement", headerName: "건강보험정산", editable: true, width: 105},
                                {field: "careInsuranceSettlement", headerName: "장기요양보험정산", editable: true, width: 127},
                                {field: "nationalPensionSettlement", headerName: "국민연금정산", editable: true, width: 105},
                                {field: "yearEndIncomeTax", headerName: "연말정산소득세", editable: true, width: 115},
                                {field: "yearEndResidentTax", headerName: "연말정산주민세", editable: true, width: 115},
                                {field: "otherSettlements", headerName: "기타정산분", editable: true},
                            ]
                        },
                        {field: "totalDeduction", headerName: "공제총합", width: 110, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                        {field: "netPay", headerName: "실수령액", width: 110, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                        {field: "remark", headerName: "비고", width: 110, editable: true},
                        // 명세서 표기에 필요한 데이터
                        {field: "normalHour", headerName: "정상근로시간", width: 110, hide: true},
                        {field: "extensionHour", headerName: "연장근로시간", width: 110, hide: true},
                        {field: "nightHour", headerName: "야간근로시간", width: 110, hide: true},
                        {field: "holidayHour", headerName: "휴일근로시간", width: 110, hide: true},
                        {field: "normalDay", headerName: "정상근로일", width: 110, hide: true},
                        {field: "extensionDay", headerName: "연장근로일", width: 110, hide: true},
                        {field: "nightDay", headerName: "야간근로일", width: 110, hide: true},
                        {field: "holidayDay", headerName: "휴일근로일", width: 110, hide: true},

                    ]}
                    seqColumn={'agId'}
                    isCheckBox={true}
                    useUpdated={true}
                    useCsvDownload={true}
                    callBackGridData={saveUserSalary}
                />
            </CContainer>
            <Modal title={'불러오기'} onExit={onExit}>
                <SalarySelectPopup/>
            </Modal>
        </>)

}

export default observer(SalaryManager);