import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer, CInputGroup, CSearchBtn } from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";
import {getSessionUserCompanyCode} from "../../utils/userUtils";
import { decrypt } from "../../common/utils";
import { ICON_ADD } from "../../common/constants";

/**
 * @param {Function} callbackFn: 결과값 받을 콜백 함수
 * @param {String} column: 반환값 받을때 같이 받을 컬럼명(구분값)
*/
const AsManagerUserSearch =({ callbackFn=null, column='', search='', companyCode='', ...props })=> {
    const {btnStatus} = props;
    const [userName, setUserName] = useState('');
    const [userList, setUserList] = useState([]);
    const gridRef = useRef();

    useEffect(() => {
        onSearchUserList();
    }, [search])

    const onSearchUserList = async () => {
        const result = await axios.get('/as/accept/detail/manager', { params: {isActive: 1, companyCode: companyCode !== '' ? companyCode : getSessionUserCompanyCode(), searchType: search} });
        setUserList(result);
    }

    const selectedRow = (e) => {
        callbackFn && callbackFn(e.data, column);
        AppStore.toggleOpenAsManagerUserSearch();
    }
    
    const callBackGridData = ({ selectedList }) => {
        callbackFn && callbackFn(selectedList, column);
        AppStore.toggleOpenAsManagerUserSearch();    
    }

    const refresh = () => {
        
    }

    return (
        <Modal
            title={'AS 담당자 조회'}
            show={AppStore.isOpenAsManagerUserSearch}
            onHide={() => AppStore.toggleOpenAsManagerUserSearch()}
            toggleValue={AppStore.isOpenAsManagerUserSearch}
            className={'modal-w7'}
            onExit={refresh}
        >
            <CContainer>
                <AgGridContainer
                    height={23}
                    gridRef={gridRef}
                    rowData={userList}
                    columnDefs={
                        [
                            {field: "name", headerName: "사원명", width: 150},
                            {field: "phoneNumber", headerName: "핸드폰번호", valueGetter: v => v.data.phoneNumber ? decrypt(v.data.phoneNumber) : '', flex: 1},
                        ]
                    }
                    seqColumn={'id'}
                    enableCellTextSelection={true}
                    rowDoubleClickCallback={selectedRow}
                    callBackGridData={callBackGridData}
                    selectBtnInfo={{isUsed: true}}
                    btnTitles={{save: '선택'}}
                    isCheckBox={btnStatus === 'add' ? true : false}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(AsManagerUserSearch);
