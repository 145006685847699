import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const ReleaseListStore = observable({
    releaseList: [],
    releaseSearchCondition: {contractNo: '', assetNo: '', startDate: '', endDate: '', customerNo: '', customerName: '', releaseType: '', model: '', lgSeq: '', mdSeq: '', smSeq: '', serialNo: '', creator: null, creatorName: '', siteName: '', storageNo: ''},

    setReleaseList(v) {
        this.releaseList = v;
    },
    setReleaseSearchCondition(v) {
        LUtils.assign(this.releaseSearchCondition, v);
    },
});
