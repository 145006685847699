import { observer } from "mobx-react-lite"
import { useRef, useState } from "react"
import { CContainer } from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import { findCommKrnm } from "../../utils/commcode";
import { getUserName } from "../../utils/userUtils";
import axios from "axios";
import SalaryStatusListSearch from "./SalaryStatusListSearch";
import { getYear } from "../../utils/dateUtils";
import { SalaryStatusStore } from "../../store/human-resources/SalaryStatusStore";

const SalaryStatusList = () => {
    const gridRef = useRef();
    const subGridRef = useRef();

    const getSalaryDetail = async (v) => {
        const resultList = await axios.get("/salary/status/detail", {params: {year: SalaryStatusStore.search.year, startMonth: SalaryStatusStore.search.startMonth, endMonth: SalaryStatusStore.search.endMonth, userSeq: v.data.userSeq}});
        SalaryStatusStore.salaryDetailList = resultList;
    }

    return (<>
        {/* 검색조건 */}
            <SalaryStatusListSearch/>
            <CContainer>
                {/* grid */}
                <AgGridContainer
                    gridTitle={'급여월별지급현황'}
                    gridRef={gridRef}
                    height={35}
                    rowData={SalaryStatusStore.salaryList}
                    columnDefs={[
                        {
                            headerName: '사원정보',
                            marryChildren: true,
                            children: [
                                // {field: "year", headerName: "연도", width: 60, hide: false, valueFormatter: v => {return (v.data.imputedDate).substring(0,3)}},
                                {field: "year", headerName: "연도", width: 60, valueFormatter: v => {return (v.data.imputedDate)}, hide: true},
                                {field: "seq", headerName: "순번", valueGetter: v => v.node.rowIndex + 1, width: 60},
                                {field: "userSeq", headerName: "사원번호", width: 85, hide: true},
                                {field: "name", headerName: "사원명"},
                                {field: "teamName", headerName: "소속팀", valueFormatter: v => {return findCommKrnm(v.data.teamSeq, '33')}, width: 100},
                                {field: "positionName", headerName: "직책", valueFormatter: v => {return findCommKrnm(v.data.positionSeq, '31')}, width: 80},
                                {field: "salaryType", headerName: "급여구분", hide: true},
                                {field: "email", headerName: "이메일", hide: true},
                            ]
                        },
                        {
                            headerName: '지급항목',
                            marryChildren: true,
                            children: [
                                {field: "basicSalary", headerName: "기본급", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "bonus", headerName: "상여금", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "mealAllowance", headerName: "식대", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "overtimeAllowance", headerName: "연장근로수당", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), width: 105},
                                {field: "selfDrivingSubsidy", headerName: "자가운전보조금", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), width: 115},
                                {field: "cellPhoneAllowance", headerName: "통신비", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "otherAllowance", headerName: "기타수당", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "annualAllowance", headerName: "연차수당", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "teamLeaderAllowance", headerName: "팀장수당", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "seniorityAllowance", headerName: "근속수당", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "extraAllowance", headerName: "교통비외수당", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), width: 105},
                                {field: "totalTaxation", headerName: "과세총액", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                {field: "totalNonTaxation", headerName: "비과세총액", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                {field: "grossSalary", headerName: "총급여", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                            ]
                        },
                        {
                            headerName: '공제항목',
                            marryChildren: true,
                            children: [
                                {field: "nationalPension", headerName: "국민연금", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "healthInsurance", headerName: "건강보험", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "careInsurance", headerName: "요양보험", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "employmentInsurance", headerName: "고용보험", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "incomeTax", headerName: "소득세", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "inhabitantsTax", headerName: "지방소득세", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "healthInsuranceSettlement", headerName: "건강보험정산", editable: true, width: 105},
                                {field: "careInsuranceSettlement", headerName: "장기요양보험정산", editable: true, width: 127},
                                {field: "nationalPensionSettlement", headerName: "국민연금정산", editable: true, width: 105},
                                {field: "yearEndIncomeTax", headerName: "연말정산소득세", editable: true, width: 115},
                                {field: "yearEndResidentTax", headerName: "연말정산주민세", editable: true, width: 115},
                                {field: "otherSettlements", headerName: "기타정산분", editable: true},
                            ]
                        },
                        {field: "totalDeduction", headerName: "공제총합", width: 110, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                        {field: "netPay", headerName: "실수령액", width: 110, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                        {field: "remark", headerName: "비고", width: 110, editable: true},
                    ]}
                    seqColumn={'agId'}
                    rowDoubleClickCallback={(v) => getSalaryDetail(v)}
                />
            </CContainer>
            <CContainer>
            <AgGridContainer
                    gridTitle={'상세내역'}
                    gridRef={subGridRef}
                    height={20}
                    rowData={SalaryStatusStore.salaryDetailList}
                    columnDefs={[
                        {
                            headerName: '사원정보',
                            marryChildren: true,
                            children: [
                                {field: "seq", headerName: "순번", valueGetter: v => v.node.rowIndex + 1, width: 60},
                                {field: "imputedDate", headerName: "귀속연월", width: 100},
                                // {field: "userSeq", headerName: "사원번호", width: 85},
                                // {field: "userName", headerName: "사원명", valueFormatter: v => {return getUserName(v.data.userSeq)}, width: 120},
                                // {field: "teamName", headerName: "소속팀", valueFormatter: v => {return findCommKrnm(v.data.teamSeq, '33')}, width: 90},
                                // {field: "positionName", headerName: "직책", valueFormatter: v => {return findCommKrnm(v.data.positionSeq, '31')}, width: 110},
                                {field: "salaryType", headerName: "지급구분", valueFormatter: v => {return findCommKrnm(v.data.salaryType, '48')}, width: 120},
                                {field: "paymentDate", headerName: "지급일자"},
                                {field: "email", headerName: "이메일", hide: true},
                            ]
                        },
                        {
                            headerName: '지급항목',
                            marryChildren: true,
                            children: [
                                {field: "basicSalary", headerName: "기본급", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "bonus", headerName: "상여금", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "mealAllowance", headerName: "식대", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "overtimeAllowance", headerName: "연장근로수당", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), width: 105},
                                {field: "selfDrivingSubsidy", headerName: "자가운전보조금", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), width: 115},
                                {field: "cellPhoneAllowance", headerName: "통신비", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "otherAllowance", headerName: "기타수당", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "annualAllowance", headerName: "연차수당", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "teamLeaderAllowance", headerName: "팀장수당", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "seniorityAllowance", headerName: "근속수당", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "extraAllowance", headerName: "교통비외수당", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), width: 105},
                                {field: "totalTaxation", headerName: "과세총액", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                {field: "totalNonTaxation", headerName: "비과세총액", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                {field: "grossSalary", headerName: "총급여", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                            ]
                        },
                        {
                            headerName: '공제항목',
                            marryChildren: true,
                            children: [
                                {field: "nationalPension", headerName: "국민연금", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "healthInsurance", headerName: "건강보험", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "careInsurance", headerName: "요양보험", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "employmentInsurance", headerName: "고용보험", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "incomeTax", headerName: "소득세", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "inhabitantsTax", headerName: "지방소득세", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                                {field: "healthInsuranceSettlement", headerName: "건강보험정산", editable: true, width: 105},
                                {field: "careInsuranceSettlement", headerName: "장기요양보험정산", editable: true, width: 127},
                                {field: "nationalPensionSettlement", headerName: "국민연금정산", editable: true, width: 105},
                                {field: "yearEndIncomeTax", headerName: "연말정산소득세", editable: true, width: 115},
                                {field: "yearEndResidentTax", headerName: "연말정산주민세", editable: true, width: 115},
                                {field: "otherSettlements", headerName: "기타정산분", editable: true},
                            ]
                        },
                        {field: "totalDeduction", headerName: "공제총합", width: 110, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                        {field: "netPay", headerName: "실수령액", width: 110, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                        {field: "remark", headerName: "비고", width: 110, editable: true},
                    ]}
                    seqColumn={'agId'}
                />
            </CContainer>
        </>)

}

export default observer(SalaryStatusList);