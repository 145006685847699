import { observable } from "mobx";
import { oneMonthAgo, today } from "../../utils/dateUtils";

export const CardStore = observable({
    cardList: [],
    search: {
        startDate: oneMonthAgo(),
        endDate: today(),
        approvalNo: '',
        cardCode: '',
        cardNumber: '',
        cardAlias:'',
    },
    isShort: true,
})