import { observable } from "mobx";

export const AssetStore = observable({
    assetList: [],
    assetHistoryList: [],
    assetDetail: [],
    assetConsumableList: [],
    assetOptionList: [],
    assetRepairHistoryList: [],
    assetStatus: '',

    /* action */
    setAssetList(v) {
        this.assetList = v;
    },
    setAssetHistoryList(v) {
        this.assetHistoryList = v;
    },
    setAssetConsumableList(v){
        this.assetConsumableList = v;
    },
    setAssetRepairHistoryList(v){
        this.assetRepairHistoryList = v;
    },
    setAssetStatus(v){
        this.assetStatus = v;
    },

    init() {}
});
