import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const OrderProductStore = observable({
    orderProductList: [],
    orderProductSearchCondition: {orderSeq: ''},
    
    setOrderProductList(v) {
        this.orderProductList = v;
    },
    setOrderProductSearchCondition(v) {
        LUtils.assign(this.orderProductSearchCondition, v);
    },

    init() {
        this.orderProductList =[]
    },
});
