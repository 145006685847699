import { useState } from "react";
import { observer } from "mobx-react-lite";
import { AppStore } from "../../../store/AppStore";
import { CContainer, CCol, CInputGroup } from "../../../components/CustomContainer";
import { Row, Button, Modal } from "react-bootstrap";
import { BillSearchStore } from "../../../store/billing/BillSearchStore";

const BillPublishPopup = ({callbackFn}) => {
    const [item, setItem] = useState('건설기계장비');
    const [qty, setQty] = useState(1);
    const [note, setNote] = useState('');

    return (
        <>
            <Modal show={AppStore.isOpenBillPublishPopup}
                   onHide={()=> AppStore.toggleBillPublishPopup()}
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title>계산서 통합 발행</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CContainer search>
                        <Row style={{marginTop: 13}}>
                            <CCol lg={12}>
                                <CInputGroup
                                    label={'품목'}
                                    labelId={'item'}
                                    value={item}
                                    onChange={v => setItem(v.target.value)}
                                />
                            </CCol>
                        </Row>
                        <Row style={{marginTop: 13}}>
                            <CCol lg={12}>
                                <CInputGroup
                                    label={'품목 수량'}
                                    labelId={'qty'}
                                    value={qty}
                                    onChange={v => setQty(v.target.value)}
                                />
                            </CCol>
                        </Row>
                        <Row style={{marginTop: 13}}>
                            <CCol lg={12}>
                                <CInputGroup
                                    label={'비고'}
                                    labelId={'billNote'}
                                    value={BillSearchStore.condition.billNote}
                                    onChange={v => BillSearchStore.condition.billNote = v.target.value}
                                />
                            </CCol>
                        </Row>
                        <Row style={{marginTop: 13}}>
                            <CCol lg={12}>
                                <CInputGroup
                                    label={'품목 비고'}
                                    labelId={'billItemNote'}
                                    value={BillSearchStore.condition.billItemNote}
                                    onChange={v => BillSearchStore.condition.billItemNote = v.target.value}
                                />
                            </CCol>
                        </Row>
                    </CContainer>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=> AppStore.toggleBillPublishPopup()}>
                        취소
                    </Button>
                    <Button variant="primary"
                            onClick={()=> {
                                AppStore.toggleBillPublishPopup();
                                callbackFn(item, qty);
                            }}>
                        발급
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default observer(BillPublishPopup);
