import { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer, CInputGroup, CSearchBtn } from "../CustomContainer";
import { CommcodeSMOptions } from "../index";
import AgGridContainer from "../AgGridContainer";

const AssetSearch =({ callbackFn=null, ...props })=> {
    const [searchList, setSearchList] = useState([]);
    const [lgList, setLGList] = useState([]);
    const [managementNo, setManagementNo] = useState('');
    const [productName, setProductName] = useState('');
    const [model, setModel] = useState('');
    const [brandCode, setBrandCode] = useState('');
    const [selectedLGCode, setSelectedLGCode] = useState('');

    useEffect(() => {
        getProductGroupList();
    }, []);

    useEffect(() => {
        if (props.inputManagementNo !== null) {
            setManagementNo(props.inputManagementNo);
            setSearchList(props.gridData);
        }
    }, [props.gridData])

    /* 제품 카테고리 조회 */
    const getProductGroupList = async () => {
        const result = await axios.get('/product/group/all', { params: { isUsed: 1 } });
        if(result.length > 0){
            setLGList(result.filter(v => v.parentSeq === null));
        }
    }

    /* 대분류 클릭 시 중분류 담아주기 */
    const onChangeLGCode = (e) => {
        setSelectedLGCode(e.target.value);
    }

    /* 제조사 값 설정 */
    const onChangeBrandName = (v) => {
        setBrandCode(v.target.value);
    }

    /* 조회 */
    const onSearch = async () => {
        const {isAsManagement} = props;
        const params = {
            lgSeq: selectedLGCode,
            managementNo,
            productName,
            model,
            brandCode,
            isDeleted: 0,
            assetStatus: isAsManagement ? '302' : '200'
        };
        const result = await axios.get('/asset/list', { params });
        setSearchList(result);
    }

    const selectedRow = (e) => {
        callbackFn({
                    productSeq: e.data.productSeq,
                    assetNo: e.data.assetNo, 
                    serialNo: e.data.serialNo, 
                    managementNo: e.data.managementNo, 
                    model: e.data.model,
                    customerName: e.data.customerName,
                    customerNo: e.data.customerNo,
                    siteSeq: e.data.siteSeq,
                    siteName: e.data.siteName,
                    siteAddress: e.data.siteAddress,
                    siteAddressDetail: e.data.siteAddressDetail,
                    sitePostcode: e.data.sitePostcode,
                    managerName: e.data.managerName,
                    managerPhoneNumber: e.data.managerPhoneNumber,
                    productName: e.data.productName, 
                    spec: e.data.spec
                });

        AppStore.toggleAssetSearchModal();
    }

    /* 초기화 */
    const refresh = () => {
        setSearchList([]);
        setLGList([]);
        setSelectedLGCode('');
    }

    return (
        <Modal
            title={'자산조회'}
            show={AppStore.isOpenAssetSearch}
            onHide={() => AppStore.toggleAssetSearchModal()}
            toggleValue={AppStore.isOpenAssetSearch}
            className={'search-modal-h5'}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'관리번호'}
                            labelId={'managementNo'}
                            value={managementNo}
                            onChange={(v)=> setManagementNo(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'제품명'}
                            labelId={'productName'}
                            value={productName}
                            onChange={(v)=> setProductName(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'모델명'}
                            labelId={'model'}
                            value={model}
                            onChange={(v)=> setModel(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <Form.Select aria-label="선택" value={brandCode} onChange={onChangeBrandName} disabled={props.productCode}>
                                <option value={''}>- 제조사 -</option>
                                { <CommcodeSMOptions commKind={'18'} />}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={selectedLGCode} onChange={onChangeLGCode} disabled={props.productCode}>
                                <option value={0}>- 대분류 -</option>
                                {lgList.map(lg => (
                                    <option key={lg.productGroupSeq} value={lg.productGroupSeq}>{lg.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearch} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    height={30}
                    rowData={searchList}
                    columnDefs={[
                        {field: "productSeq", headerName: "제품순번", minWidth: 110, hide: true},
                        {field: "assetNo", headerName: "자산번호", minWidth: 110},
                        {field: "managementNo", headerName: "관리번호", minWidth: 130},
                        {field: "productSeq", headerName: "제품코드", minWidth: 110, hide: true},
                        {field: "productName", headerName: "제품명", minWidth: 180},
                        {field: "model", headerName: "모델명", minWidth: 180},
                        {field: "serialNo", headerName: "시리얼 번호", minWidth: 140, flex: 1},
                        {field: "isReserve", headerName: "예약여부", minWidth: 90, hide: true},
                        {field: "customerName", headerName: "업체명", hide: true},
                        {field: "customerNo", headerName: "업체번호", hide: true},
                        {field: "siteSeq", headerName: "현장시퀀스", hide: true},
                        {field: "siteName", headerName: "현장명", hide: true},
                        {field: "managerName", headerName: "담당자명", hide: true},
                        {field: "managerPhoneNumber", headerName: "담당자 전화번호", hide: true},
                        {field: "siteAddress", headerName: "현장 주소", hide: true},
                        {field: "siteAddressDetail", headerName: "현장 상세주소", hide: true},
                        {field: "sitePostcode", headerName: "현장 우편번호", hide: true},
                    ]}
                    enableCellTextSelection={true}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(AssetSearch);