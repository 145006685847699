import { observable } from "mobx";

export const ConfirmStore = observable({
    deliveryDriver: '',
    receiveUserName: '',
    subject: '', 
    content: '',
    receiverEmail: '',

    init() {
        this.subject = '';
        this.content = '';
        this.receiverEmail = '';
    }
});
