import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer, CInputGroup, CSearchBtn } from "../CustomContainer";
import { findCommKrnm } from "../../utils/commcode";
import UserSearch from "./UserSearch";
import AgGridContainer from "../AgGridContainer";

/**
 * @param {Function} callbackFn: 결과값 받을 콜백 함수
*/
const OrderSearch =({ callbackFn=null, ...props })=> {
    const [orderSeq, setOrderSeq] = useState('');
    const [approvalNo, setApprovalNo] = useState('');
    const [orderUserName, setOrderUserName] = useState('');
    const [orderUserSeq, setOrderUserSeq] = useState('');
    const [userColumn, setUserColumn] = useState('');
    const [orderList, setOrderList] = useState([]);

    const onSearchOrderList = async () => {
        const result = await axios.get('/order/list', { params: { orderSeq, approvalNo, orderUserName, orderUserSeq } });
        setOrderList(result);
    }

    const selectedRow = e => {
        callbackFn && callbackFn(e.data);
        AppStore.toggleOrderSearchModal();
    }

    const refresh = () => {
        setOrderList([]);
        setOrderSeq('');
        setApprovalNo('');
        setOrderUserName('');
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = (column) => {
        setUserColumn(column);
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        if (!userInfo) {
            return false;
        }

        setOrderUserSeq(userInfo.userSeq);
        setOrderUserName(userInfo.name);
        setUserColumn('');
    }

    return (
        <Modal
            title={'발주조회'}
            onHide={() => AppStore.toggleOrderSearchModal()}
            toggleValue={AppStore.isOpenOrderSearch}
            className={'modal-w9'}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'발주번호'}
                            labelId={'orderSeq'}
                            placeholder={'발주번호'}
                            value={orderSeq}
                            onChange={v => setOrderSeq(v.target.value)}
                            enterKeyHint={onSearchOrderList}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'품의번호'}
                            labelId={'approvalNo'}
                            placeholder={'품의번호'}
                            value={approvalNo}
                            onChange={v => setApprovalNo(v.target.value)}
                            enterKeyHint={onSearchOrderList}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup
                            label={'발주자 이름'}
                            labelId={'orderUserName'}
                            value={orderUserName}
                            disabled={true}
                            enterKeyHint={onSearchOrderList}
                            onCallbackBtn={() => openSearchUserPopup('saleUser')}
                            onEmptyBtn={() => {
                                setOrderUserSeq('');
                                setOrderUserName('');
                            }}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearchOrderList} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    height={30}
                    rowData={orderList}
                    columnDefs={
                        [
                            {field: "orderSeq", headerName: "발주번호", width: 110},
                            {field: "orderDate", headerName: "발주일자", width: 115},
                            {field: "orderUserName", headerName: "발주자", width: 100},
                            {field: "approvalNo", headerName: "품의번호", width: 110},
                            {field: "productName", headerName: "제품명", width: 200},
                            {field: "orderAmount", headerName: "발주수량", width: 110},
                            {field: "storageCnt", headerName: "입고수량", width: 110, valueFormatter: v => v.value ? v.value : 0},
                            {field: "orderPrice", headerName: "발주단가", width: 120, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
                            {field: "totalPrice", headerName: "발주총액", width: 120, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
                            
                            {field: "customerName", headerName: "고객명", width: 120},
                            {field: "remark", headerName: "비고", width: 150},
                            {field: "companyCode", headerName: "회사구분", valueFormatter: v => findCommKrnm(v.value, '20'), width: 120},
                        ]
                    }
                    enableCellTextSelection={true}
                    animateRows={true}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>

            {/* modal */}
            <UserSearch callbackFn={getUserInfo} column={userColumn}/>
        </Modal>
    )
}

export default observer(OrderSearch);
