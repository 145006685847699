import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Form, Button, Image } from "react-bootstrap";
import axios from "axios";

import { encrypt } from "../../../common/utils";
import { AppStore } from "../../../store/AppStore";
import { callAlert } from "../../../utils";

const ContractLinkIndex = () => {
    const params = useParams();
    const [businessNumber, setBusinessNumber] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getExpiration()
        .then(result => {
            if(result) {
                callAlert('유효기간이 만료되었습니다.');
                navigate(`/expiration/${params.contractNo}`, { replace: true });
                return;
            }
        });
    }, []);

    /* 링크 만료 조회 */
    const getExpiration = async () => {
        const result = await axios.get(`/contract/document/${params.contractNo}/${params.seq}`);
        return result;
    }

    const accessDocument = async (e) => {
        e.preventDefault(); 

        const result = await axios.get('/contract/document', {params: {contractNo: params.contractNo, businessNumber: encrypt(businessNumber)}});
        if(result.returnValue){
            AppStore.setContractLink(result);
            navigate(`/contract/document/${params.contractNo}/${params.seq}`, { replace: true });
        }else {
            alert('사업자번호를 확인해주세요.');
        }
    }

    return(
        <div className="login-body">
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" />
            <div className="d-flex justify-content-center align-items-center min-vh-100">
                    <div className="login-card">
                        <div className="row no-gutters" style={{height:'100%'}}>
                            {/* 로그인 폼 요소 중앙 정렬 */}
                            <div className="card-body d-flex justify-content-center align-items-center flex-column">
                                <div className="brand-wrapper d-flex justify-content-center align-items-center flex-row">
                                    <Image src={`${process.env.PUBLIC_URL}/image/pelotonlab.png`} alt="logo" className="logo"/>
                                </div>
                                <div>
                                    <p className="login-card-description">사업자 번호를 입력해 주세요.</p>
                                </div>
                                <div>
                                    <Form style={{width: '500px'}}>
                                        <Form.Group className='form-group' controlId="formBasicPassword">
                                            <Form.Label className='sr-only'>사업자 번호</Form.Label>
                                            <Form.Control type='password'
                                                          value={businessNumber}
                                                          onKeyDown={(e)=> {if(e.key === 'Enter') accessDocument(e)}}
                                                          onChange={v => setBusinessNumber(v.target.value)}
                                                          placeholder='사업자 번호를 입력해 주세요.'
                                            />
                                        </Form.Group>
                                        <Button className='btn btn-block login-btn mb-4' type="button" onClick={accessDocument}>확인</Button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
}

export default observer(ContractLinkIndex);
