import React, {forwardRef, useEffect, useState} from "react";
import "./reportTemplate.css";
import {getLocalStorage} from "../../utils";
import {USER_INFO} from "../../common/constants";
import {dateKoreaFormat} from "../../utils/dateUtils";
import {findCommKrnm} from "../../utils/commcode";
import Regex from "../../common/regex";
import {decrypt} from "../../common/utils";
import { TemplateStore } from "../../store/contract/TemplateStore";
import PreviewImage from "../preview/PreviewImage";
import { Image } from "react-bootstrap";

const ReportContractTemplate =(props, ref)=> {
    const userInfo = getLocalStorage(USER_INFO);
    const store = TemplateStore;

    const [emptyList, setEmptyList] = useState([]);
    const [rows, setRows] = useState(8);

    useEffect(()=> {
        if(store.contractProductList.length < 10){
            const list = [];
            for(let i = 0; i < 10 - store.contractProductList.length; i++){
                list.push(0);
            }
            setEmptyList(list);
        }else {
            setEmptyList([]);
        }

        const calculateRows = () => {
            const lines = store.specialRemark.split('\n');
            let totalRows = lines.length;
      
            lines.forEach(line => {
                const extraRows = Math.floor(line.length / 50);
                totalRows += extraRows;
            });
            return  totalRows > 8 ? totalRows : 8;
        };
    
        setRows(calculateRows());
      
    }, [store.contractProductList])

    return (
        <div ref={ref}>
            <div className="print-template" style={{paddingLeft: 22, paddingRight: 22, paddingTop: 10, display: 'none'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottomWidth: 1, marginBottom: 10}}>
                    <h3 style={{textAlign: 'left', margin: 0}}>렌 탈 계 약 서</h3>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img src={store.logoImageUrl} style={{ width: '60px', height: 'auto', marginRight: '10px' }} />
                        <span style={{fontSize: 14, fontWeight: 'bold'}}>{userInfo.companyName}</span>
                    </div>
                </div>
                <table className="table" style={{borderWidth: 1}}>
                    <tbody>
                    <tr>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>계약번호</td>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '17%', fontSize: 9}}>{store.contractNo}</td>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>계약일자</td>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '16%', fontSize: 9}}>{dateKoreaFormat(store.contractDate)}</td>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>계약담당자</td>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '14%', fontSize: 9}}>{store.saleUserName}</td>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '9%', backgroundColor: '#D8D8D8', fontSize: 9}}>연락처</td>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '14%', fontSize: 9}}>{store.telephone ? store.telephone : decrypt(store.phoneNumber)}</td>
                    </tr>
                    </tbody>
                </table>
                <table className="table" style={{borderWidth: 1}}>
                    <tbody>
                    <tr>
                        <td className='p-1 border' align={'center'} valign={'middle'} colSpan={2} style={{fontSize: 9, fontWeight: 'bold'}}>임대인</td>
                        <td className='p-1 border' align={'center'} valign={'middle'} colSpan={2} style={{fontSize: 9, fontWeight: 'bold'}}>임차인</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>회 사 명</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '40%', fontSize: 9}}>
                            <div className="d-flex justify-content-between" style={{alignItems: 'center', position: 'relative'}}>
                                <span style={{fontSize: 9}}>{userInfo.companyName}</span>
                                <span style={{ marginRight: 20 }}>
                                        {store.companySignatureUrl && 
                                            <>
                                                <div style={{ position: 'absolute', display: 'inline-block', top: '-5px'}}>
                                                    <img src={store.companySignatureUrl} style={{  top: '-8px', marginLeft: 0, width: '20px', height: 'auto' }} />
                                                </div>
                                                <span style={{ bottom: 0, right: 0, backgroundColor: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 9, color: 'gray' }}>(인)</span>
                                            </>
                                        }
                                    </span>
                            </div>
                        </td>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>회 사 명</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '40%', fontSize: 9}}>
                            <div className="d-flex justify-content-between">
                                <span style={{fontSize: 9}}>{store.customerName}</span>
                                <span style={{marginRight: 20, fontSize: 9, color: 'gray'}}>(인)</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>주소</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '25%', fontSize: 9}}>{userInfo.companyAddress}</td>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>주소</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '25%', fontSize: 9}}>{store.customerAddress}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>사업자번호</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '25%', fontSize: 9}}>{Regex.makeBusinessNumber(userInfo.businessNumber)}</td>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>사업자번호</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '25%', fontSize: 9}}>{Regex.makeBusinessNumber(store.businessNumber)}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>대표자명</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '25%', fontSize: 9}}>{userInfo.owner}</td>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>대표자명</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '25%', fontSize: 9}}>{store.owner}</td>
                    </tr>
                    </tbody>
                </table>
                <h6>렌탈 품목 및 요금</h6>
                <table className="table" style={{borderWidth: 1, textAlign: 'center'}}>
                    <thead>
                        <tr>
                            <th className='border' style={{width: '10%', fontSize: 9}}>순번</th>
                            <th className='border' style={{width: '20%', fontSize: 9}}>모델명</th>
                            <th className='border' style={{width: '20%', fontSize: 9}}>관리번호</th>
                            <th className='border' style={{width: '20%', fontSize: 9}}>시리얼번호</th>
                            {/* <th className='border' style={{width: '12%'}} colSpan={2}>렌탈기간</th> */}
                            <th className='border' style={{width: '10%', fontSize: 9}}>수량</th>
                            <th className='border' style={{width: '20%', fontSize: 9}}>임대료</th>
                            {/* <th className='border' style={{width: '13%'}}>일렌탈료</th> */}
                            {/* <th className='border' style={{width: '14%'}}>총렌탈료</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {store.contractProductList.map((v,i) => {
                            return (
                                <tr key={i}>
                                    <td className='p-1 border' style={{fontSize: 9}}>{i+1}</td>
                                    <td className='p-1 border' style={{fontSize: 9}}>{v.model}</td>
                                    <td className='p-1 border' style={{fontSize: 9}}>{v.managementNo}</td>
                                    <td className='p-1 border' style={{fontSize: 9}}>{v.serialNo}</td>
                                    {/*<td className='p-1 border' style={{fontSize: 11}}>{v.rentMonth}개월</td>
                                    <td className='p-1 border' style={{fontSize: 11}}>{v.rentDays}일</td> */}
                                    <td className='p-1 border' style={{fontSize: 9}}>{v.qty}</td>
                                    <td className='p-1 border' style={{fontSize: 9, textAlign: 'right'}}>{(v.monthRentPrice + (v.rentDays*v.dayRentPrice))?.toLocaleString()}</td>
                                    {/* <td className='p-1 border' style={{fontSize: 11}}>{v.totalRentPrice?.toLocaleString()}</td> */}
                                </tr>
                            )
                        })}
                        {emptyList.length > 0 &&
                            emptyList.map((v,i) => {
                                return (
                                    <tr key={i}>
                                        <td className='p-1 border' style={{fontSize: 9}}>{i+1+(10 - emptyList.length) }</td>
                                        <td className='p-1 border' style={{fontSize: 9}}></td> 
                                        <td className='p-1 border' style={{fontSize: 9}}></td>
                                        <td className='p-1 border' style={{fontSize: 9}}></td>
                                        <td className='p-1 border' style={{fontSize: 9}}></td>
                                        <td className='p-1 border' style={{fontSize: 9}}></td>
                                    </tr>
                                )
                            })
                        }
                        <tr>
                            <td className='p-1 border' valign={'middle'} align={'center'}>
                                <span style={{fontWeight: 'bold', fontSize: 9}}>옵션</span>
                            </td>
                            <td className='p-1 border' align={'left'} colSpan={4}>
                                <span style={{marginRight: 10, fontSize: 9}}>{store.options || ''}</span>
                            </td>
                            <td className='p-1 border' valign={'middle'} align={'right'} style={{fontSize: 9}}>{store.optionAmount?.toLocaleString() || 0}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' align={'right'} colSpan={5}>
                                <span style={{marginRight: 10, fontWeight: 'bold', fontSize: 9}}>소계 (VAT별도)</span>
                            </td>
                            <td className='p-1 border' valign={'middle'} align={'right'} style={{fontSize: 9}}>{(Number(store.contractProductList.reduce((total, v) => total + v.monthRentPrice + (v.rentDays*v.dayRentPrice), 0) + store.optionAmount))?.toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' align={'right'} colSpan={5}>
                                <span style={{marginRight: 10, fontWeight: 'bold', fontSize: 9}}>합계 (VAT포함)</span>
                            </td>
                            <td className='p-1 border' valign={'middle'} align={'right'} style={{fontWeight: 'bold', fontSize: 10}}>{((store.contractProductList.reduce((total, v) => total + v.monthRentPrice + (v.rentDays*v.dayRentPrice), 0)  + store.optionAmount) * 1.1)?.toLocaleString()}원</td>
                        </tr>
                    </tbody>
                </table>
                <h6>계약정보</h6>
                <table className="table" style={{borderWidth: 1}}>
                    <tbody>
                    {/* <tr>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8'}}>계약월/일</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '40%'}}>{`${store.rentMonth}개월 ${store.rentDays}일`}</td>
                    </tr> */}
                    <tr>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>계약개시일</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '23%', fontSize: 9}}>{`${dateKoreaFormat(store.contractStartDate || '')}`}</td>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>청구방법</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '23%', fontSize: 9}}>{findCommKrnm(store.paymentType, '17')}</td>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>결제일</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '23%', fontSize: 9}}>{`${findCommKrnm(store.dueDateType, '22')} ${store.dueDateDay === 0 ? '말일' : store.dueDateDay+'일'} 결제`}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>설치장소</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '25%', fontSize: 9}} colSpan={5}>{store.installPlace || ''}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>반납장소</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '25%', fontSize: 9}} colSpan={5}>{userInfo.returnAddress || ''}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>운송비</td>
                        <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '25%', fontSize: 9}} colSpan={5}>
                            <span style={{fontSize: 9}} >{store.deliveryPrice?.toLocaleString() || 0}원</span>
                            <div className="form-check form-check-inline" style={{marginLeft: 15}}>
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="1" onChange={() => {}} checked={store.isDeliveryPrice && store.chargeDelivery === '1' ? true : false} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1" style={{fontSize: 9}}>고객부담</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="2" onChange={() => {}} checked={store.isDeliveryPrice && store.deliveryKind === '1' ? true : false} />
                                <label className="form-check-label" htmlFor="inlineCheckbox2" style={{fontSize: 9}}>왕복</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="3" onChange={() => {}} checked={store.isDeliveryPrice && store.deliveryKind === '2' ? true : false} />
                                <label className="form-check-label" htmlFor="inlineCheckbox3" style={{fontSize: 9}}>편도</label>
                            </div>,
                            <div className="form-check form-check-inline" style={{marginLeft: 15}}>
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="4" onChange={() => {}} checked={store.isDeliveryPrice && store.chargeDelivery === '2' ? true : false} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1" style={{fontSize: 9}}>당사부담</label>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <div style={{ width: '48%', paddingRight: '10px', borderRight: '1px solid #cececf' }}>
                        <h6>특약사항</h6>
                        <textarea 
                            rows={rows} 
                            style={{ width: '100%', padding: 0, fontSize: 11, borderWidth: 0, resize: 'none',  }} 
                            readOnly={true} 
                            value={store.specialRemark||'별도 특약사항 없음'}
                        />
                    </div>
                
                    <div style={{ width: '48%' }}>
                        <h6>확인사항</h6>
                        <table style={{ width: '100%', fontSize: 9 }}>
                            <caption style={{ padding: 0, fontSize: 9 }}>*렌탈 물건의 소유권은 {userInfo.companyName}에 있습니다.</caption>
                            <caption style={{ padding: 0, fontSize: 9 }}>*현장 및 사용자 요청에 따른 옵션장착으로 인한 사고는 고객이 책임집니다.</caption>
                            <caption style={{ padding: 0, fontSize: 9 }}>*렌탈 물건 인도 및 인수 시 상/하차 업무 및 그에따른 부대비용은 고객이 부담합니다.</caption>
                            <caption style={{ padding: 0, fontSize: 9 }}>*렌탈료 연체 또는 렌탈 물건의 임의처분 및 전대 등 소유권 제한, 침해가 있으면 계약은 강제 해지되고 물건은 반환될 수 있습니다.</caption>
                            <caption style={{ padding: 0, fontSize: 9 }}>*렌탈료 연체 또는 물건 반환 지연에 따른 지연손해금 등에따른 불이익을 받으실 수 있습니다.</caption>
                            <caption style={{ padding: 0, fontSize: 9 }}>*계약기간 중 물건의 현장이동이 있을 경우 고객은 회사에 고지 의무를 다하며 회사와 협의가 필요합니다.</caption>
                        </table>
                    </div>
                </div>
                <h6 style={{marginTop: 10}}>장비인수자</h6>
                <table className="table" style={{borderWidth: 1}}>
                    <tbody>
                        <tr>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>인수자</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '22%', fontSize: 9}}></td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>소속</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '22%', fontSize: 9}}></td>
                            <td className='p-1 border' align={'center'} valign={'middle'} style={{width: '10%', backgroundColor: '#D8D8D8', fontSize: 9}}>연락처</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} style={{width: '22%', fontSize: 9}}></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {TemplateStore.isContract && 
                <div className="print-template" style={{display: 'none'}}>
                    <div>
                        <div style={{borderWidth: 1, width: '100%', height: 40, paddingTop: 9, textAlign: 'center', backgroundColor: '#D8D8D8'}}>
                            <h5>렌탈계약규정</h5>
                        </div>
                        <br/>
                        <div className={'report-contract-agree d-flex justify-content-between'}>
                            <div style={{width: '49%'}}>
                                <label>제 1조(목적)</label>
                                <p>본 규정은 앞면 기재의 렌탈물건(이하 '물건'이라 함)을 임대한 자(이하 '회사'라 함)와 물건을 임차한 자(이하 '고객'이라 함) 사이에 체결된 임대차 계약상의 권리,의무에 관한 사항에 관하여 규정함을 목적으로 한다.</p>
                                <label>제 2조(렌탈기간)</label>
                                <p>1.렌탈 기간은 회사가 고객에게 물건을 인도한 날로부터 계산을 시작하고, 물건을 반환한 날을 렌탈 기간 종료일로 한다.</p>
                                <p>2.렌탈 기간 만료 후 고객으로부터 회사에 서면 또는 유선으로 반납통보가 없으면 렌탈 기간이 자동으로 연장되어 물건을 사용하는 것으로 간주하며 본 계약 사항을 같이 적용한다.</p>
                                <label>제 3조(렌탈료)</label>
                                <p>1.앞 기재의 계약렌탈료는 1개월 단위 또는 1단위의 렌탈료로서 고객은 앞면 기재의 계약렌탈료를 매월 지급하기로 하고 이의 지급기일 및 지급방법은 앞면에 기재된 바에 의한다.</p>
                                <p>2.고객이 본 조 제1항 또는 개별 계약으로 정한 렌탈료에 대하여 지급기한을 도뢰한 경우 고객이 지급기한을 도뢰한 렌탈료를 회사에게 지급전까지 회사는 고객의 발주 및 출고 요청에 대하여 거절할 수 있다.</p>
                                <label>제 4조(물건의 인도)</label>
                                <p>1.회사는 고객이 지정하는 장소에서 물건을 인도하기로 하며 고객은 물건을 인수하면 바로 현장에서 물건을 검사하고 앞면 기재의 현장 담당자 또는 인수담당자가 서명날인 함으로써 정상적인 상태에서 그 물건을 인수한 것임을 확인한다.</p>
                                <p>2.물건의 인도 및 인수에 필요한 운반비 및 현장에서 필요한 물건의 상/하차 업무 및 그에따른 기타 부대비용은 고객이 부담한다.</p>
                                <label>제 5조(물건의 담보책임)</label>
                                <p>1.회사는 고객에게 물건 인도 시 물건이 정상적인 성능을 갖추고 있는 것을 담보 하고 물건의 사용 목적의 적합성에 대하여는 책임지지 않는다.</p>
                                <p>2.렌탈 기간 동안 물건과 관련된 인명사고, 재산상의 피해 등 발생되는 모든 사고는 고객의 책임으로 한다.</p>
                                <p>3.물건의 인도 후 24시간 이내에 고객은 회사에 물건의 성능결함에 대해 서면 및 유선으로 통지하지 않는 경우 물건이 정상적인 성능을 갖추고 인도된 것으로 간주한다.</p>
                                <p>4.전한과 같은 인도 후 회사는 고객 또는 제3자에게 발생한 손해를 책임지지 않는다.</p>
                                <label>제 6조(물건의 유지보수)</label>
                                <p>1.회사는 렌탈 기간 중 고객의 요청이 있으면 물건이 정상적인 상태로 사용할 수 있도록 일반적인 사용으로 인한 마모와 고객의 사용, 설치, 보관 등으로 인하여 발생한 하자에 대한 유지보수를 제공하며 원활한 유지보수를 위해 대행업체를 선정할 수 있다.</p>
                                <p>2.고객은 전항의 유지보수 제공에 문제가 있는 경우 유선 또는 서면으로 회사에 통보해야 하고 통보가 없는 경우에는 정상적인 유지보수 업무가 제공되고 서비스를 받은것으로 간주한다. 또한 물건의 유지보수 완료 후 24시간 이내에 그 유지보수상의 문제를 통보하지 않는 경우 그 유지보수는 정상적으로 완료된 것으로 간주한다.</p>
                                <label>제 7조(물건의 사용보관)</label>
                                <p>1.고객은 물건의 사용, 보관함에 있어 주의의무를 다하여야 하며, 사용 및 보관에 필요한 비용을 부담하여야 한다.</p>
                                <p>2.고객은 물건을 양도,전대,개조하지 못함은 물론 물건을 앞면 기재의 설치장소 외 곳으로 이동시키지 못하며, 물건에 부착된 회사의 소유권표시, 교정표식 등을 제거,훼손해서는 안된다.</p>
                                <p>3.고객의 필요에 의하여 물건의 이동 및 개조를 할 경우 사전에 회사에게 서면으로 통보한 후 회사의 승낙을 득해야 하며 개조 시 물건에 부착된 부속품 등의 종속물은 모두 회사의 소유로 한다.</p>
                                <label>제 8조(물건의 멸실,훼손 및 미반환)</label>
                                <p>1.물건이 멸실(도난,수리불능,소유권의 침해를 포함), 훼손(화재,파손,소유권의 제한,부속품의 부족을 포함), 미반환의 경우 고객은 회사에게 대체물건의 구입비용 또는 물건의 수리비용을 손해배상금으로 지급해야 한다.</p>
                                <p>2.고객은 물건의 훼손에 의한 수리기간동안 물건의 사용여부에 관계없이 렌탈 기간 중에는 렌탈료를 지급해야한다. 렌탈 기간 만료 이후 물건을 수리할 경우에는 그 수리에 피요한 기간 및 물건을 재구매할 경우에는 관련 제반 업무 등에 소요되는 기간 동안의 영업손해를 청구할 수 있다.</p>
                                <p>3.고객은 물건반환 시 물건의 오염도에 따라 별도의 세차비용을 부담하여야 한다.</p>
                            </div>
                            <div style={{width: '49%'}}>
                                <label>제 9조(계약의 중도해지)</label>
                                <p>1.고객은 렌탈 기간 중 이라도 고객의 서면신청으로 회사가 지정하는 장소에 정상적인 상태의 물건을 반환하고 본 계약을 해지할 수 있다.</p>
                                <p>2.전항의 경우 앞면 기재의 계약렌탈료를 일할하여 실사용 기간으로 계산한다.</p>
                                <p>3.회사 또는 고객은 물건성능의 결함으로 수리에 과대한 비용 또는 시간이 필요 할 때에는 그 내용을 상대방에게 통지하여 본 계약을 해지할 수 있다.</p>
                                <label>제 10조(계약의 강제해지)</label>
                                <p>1.고객에게 다음 각 호의 사유가 발생하는 경우 회사는 계약을 해지할 수 있다.</p>
                                <p>1)렌탈료의 납부를 2회 이상 연체할 경우</p>
                                <p>2)어음 또는 수표가 부도 되어 은행의 거래 정지 처분이 있거나 조세공과금의 체납으로 독촉 또는 체납처분을 받을 경우</p>
                                <p>3)사업이 휴/폐업되거나 회사가 해산한 때 또는 파산, 회생절차 개시 등을 신청하거나 기타 신용을 상실한 경우</p>
                                <p>4)물건을 회사의 동의 없이 제 3자에게 양도,담보제공,처분,임대 또는 점유를 이전한 경우</p>
                                <p>5)회사에 제공한 담보재산 및 기타 재산에 대하여 제3자로부터 압류, 경매 또는 기타 강제집행 등을 당한 경우</p>
                                <p>6)고객이 개인 혹은 개인 사업자일 때 사망한 경우 또는 피성년후견,피한정후견의 선고를 받은 경우</p>
                                <p>7)허위자료의 제출 등 정당하지 않은 방법으로 계약을 한 사실이 발견된 경우</p>
                                <p>8)기타 고객이 본 계약의 채무를 이행할 수없을 것으로 인정되는 상당한 사유가 있는 경우</p>
                                <p>2.고객은 회사가 파산또는 청산될 때에 계약을 해지할 수 있다.</p>
                                <label>제 11조(기한 이익 상실)</label>
                                <p>1.제9조,제10조에 따른 계약 해지의 경우 고객에 대한 기한의 이익은 상실되고 계약 해지로 인하여 발생하는 금원 전부를 고객은 회사에게 즉시 지급하여야 한다.</p>
                                <label>제 12조(물건의 반환과 지연 손해금)</label>
                                <p>1.고객은 렌탈 기간 만료일 18시까지 물건의 성능이 정상적인 상태로 회사가 지정하는 장소에 고객의 비용으로 회사에 반환한다. 단 본 계약이 해제 또는 해지되면 고객은 해제 또는 해지 당일 18시까지 물건을 전기의 방법으로 반환하여야 한다.</p>
                                <p>2.고객은 물건의 반환을 지연한 때에는 반환기한의 다음날부터 반환완료일까지의 추가 렌탈료를 지급해야 한다. 이 경우 앞면에 기재된 렌탈료를 일할 계산하여 적용한다.</p>
                                <p>3.렌탈 물건의 반환이 지체되고 있는 도중 렌탈 장비와 관련하여 발생하는 손해애 관하여는 고객에게 책임이 있다.</p>
                                <label>제 13조(지연배상금)</label>
                                <p>고객이 본 계약에 의한 금전 채무의 이행을 지연할 경우 회사는 연이율 20%의 지연배상금을 고객에게 청구 할 수 있다.</p>
                                <label>제 14조(고객의 통지의무)</label>
                                <p>고객은 거래에 필요한 명칭,상호,대표자,주소 등의 신고사항을 변경하거나 사업장의 이전,물건설치장소의 변동 등 기타 본 계약의 이행에 영향을 미치는 사유가 발생하였을때는 사유 발생 즉시 회사에 통지하여야 하며 고객의 통지 불이행으로 인하여 발생하는 손실에 대하여 고객이 전적으로 책임을 지기호 한다.</p>
                                <label>제 15조(신용정보의 제공)</label>
                                <p>회사는 본 계약과 관련하여 고객 및 연대보증인의 객관적 거래 사실에 대한 신용 정보를 신용정보기관이나 계약 관련 기관에 제공할 수 있다.</p>
                                <label>제 16조(관할법원)</label>
                                <p>본 계약에 관한 분쟁이 발생하여 소송할 필요가 있을 때는 소를 제기하는 자의 주소지를 관할하는 법원을 관할법원으로 한다.</p>
                                <label>제 17조(특약사항)</label>
                                <p>본 계약에 정하지 않은 사항이나 기타 본 계약의 규정과 다른 사항을 특약사항으로 약정 할 수 있으며 특약사항에 기재되지 않은 사항에 대하여는 회사와 고객이 따로 서면 합의함으로써 그 효력이 발생한다.</p>
                                <label>제 18조(보험)</label>
                                <p>보험가입은 고객의 선택적 사항이며 보험가입 요청 시 보험료는 추가로 고객이 부담한다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default forwardRef(ReportContractTemplate);
