import React, { useEffect, useRef, useState } from "react";
import { observer } from 'mobx-react-lite';
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import {CCol, CContainer, CIconBtn, CInputGroup, IconBtn, Subject} from "../../../components/CustomContainer";
import Modal from "../../../components/Modal";
import ReportReleaseAskPrintTemplate from "../../../components/report/ReportReleaseAskPrintTemplate";
import { ReleaseAskDetailStore } from "../../../store/release/ReleaseAskDetailStore";
import { showToast } from "../../../common/utils";
import { callConfirm } from "../../../utils";
import { makeReleaseAskPrintTemplate } from "../../release/makeReleaseAskPrintTemplate";
import html2pdf from "html2pdf.js";
import { AppStore } from "../../../store/AppStore";
import UploadDcoumentPopup from "../../release/popup/UploadDcoumentPopup";
import ReportReleaseAskPrintGroupByTemplate from "../../../components/report/ReportReleaseAskPrintGroupByTemplate";
import { MULTIPART_HEADER } from "../../../common/constants";
import AgGridContainer from "../../../components/AgGridContainer";

const ReleaseItemDocs =({contractNo}) => {

    const componentPrintRef = useRef();
    const groupByPrintRef = useRef();
    const gridRef = useRef();

    const [sendMailPopup, setSendMailPopup] = useState(false);
    const [emailSubject, setEmailSubject] = useState('');
    const [emailContent, setEmailContent] = useState('');
    const [receiverEmail, setReceiverEmail] = useState('');

    const [isPrintAll, setIsPrintAll] = useState(true);
    const [isSendAll, setIsSendAll] = useState(true);

    // 1: 고소작업대(T/L) 안전점검 결과서, 2: 보험증권, 3: 제원표, 4: 안전인증서, 5: 자산안전인증서, 6: 작동방법설명서, 7: 비상하강, 8: 반입전 체크리스트, 9: 계약서, 10: 사업자등록증, 11: 통장사본
    const [checkedList, setCheckedList] = useState(['pdfType1', 'pdfType2', 'pdfType3', 'pdfType4', 'pdfType5', 'pdfType6', 'pdfType7', 'pdfType8']);
    const [mailCheckedList, setMailCheckedList] = useState(['pdfType1', 'pdfType2', 'pdfType3', 'pdfType4', 'pdfType5', 'pdfType6', 'pdfType7', 'pdfType8', 'pdfType9', 'pdfType10', 'pdfType11']);
    const [isChecked, setIsChecked] = useState(false);
    
    useEffect(()=> {
        async function getReleaseAskItemList(){
            await getReleaseAskItems();
        }

        getReleaseAskItemList();
    }, [])

    const getReleaseAskItems =async()=> {
        const result = await axios.get(`releaseAskItem/list/${contractNo}`);
        ReleaseAskDetailStore.setReleaseAskItemList(result);
        ReleaseAskDetailStore.dataList = result;
    }

    /* 프린트 파일 체크 */
    const checkPdf = () => {
        let validData = 0;

        if (!isPrintAll && checkedList.length === 0) {
            showToast('출력하실 문서를 선택해주세요.');
            return;
        }

        checkedList.map(v => {
            if (v === 'pdfType1' || v === 'pdfType8' || v === 'pdfType9') {
                validData ++;
            }
            ReleaseAskDetailStore.releaseAskItemList.map(item => {
                if(item.isOption === 0) {
                    // pdfType1: 안전점검결과서, pdfType2: 보험증권, pdfType3: 제원표, pdfType4: 안전인증서, pdfType5: 자산안전인증서, pdfType6: 작동방법설명서, pdfType7: 비상하강, pdfType8: 반입전 체크리스트, pdfType9: 계약서, pdfType10: 사업자등록증, pdfType11: 통장사본,
                    if (v === 'pdfType2') {
                        item.insurancePolicyUrl != null && item.insurancePolicyUrl != '' ? validData ++ : '';
                    } else if (v === 'pdfType3') {
                        item.specificationTableUrl != null && item.specificationTableUrl != '' ? validData ++ : '';
                    } else if (v === 'pdfType4') {
                        item.safetyCertificateUrl != null && item.safetyCertificateUrl != '' ? validData ++ : '';
                    } else if (v === 'pdfType5') {
                        item.assetCertificateUrl != null && item.assetCertificateUrl != '' ? validData ++ : '';
                    } else if (v === 'pdfType6') {
                        item.workingMethodUrl != null && item.workingMethodUrl != '' ? validData ++ : '';
                    } else if (v === 'pdfType7') {
                        item.emergencyDownUrl != null && item.emergencyDownUrl != '' ? validData ++ : '';
                    }
                }
            })
        })

        if (validData > 0) {
            return true;
        } else {
            showToast('출력할 문서가 존재하지 않습니다.');
            return false;
        }
    }

    /* 메일 출력 버튼 이벤트 */
    const printPdf = () => {

        if(checkPdf()){
            print();
        }
    }

    const print = useReactToPrint({
        content: () => componentPrintRef.current,
    });

    /* 문서 종류 체크 핸들러 */
    const checkedHandler = (e, value, isMail=false) => {
        if(isMail) {
            setIsChecked(!isChecked);
            checkedItemHandler(value, e.target.checked, true);
        } else {
            setIsChecked(!isChecked);
            checkedItemHandler(value, e.target.checked);
        }
    }

    /* 체크박스 핸들러 */
    const checkedItemHandler = (value, isChecked, isMail=false) => {
        if(isMail) {
            if (isChecked) {
                setMailCheckedList((prev) => [...prev, value]);
                return;
            }

            if (!isChecked && mailCheckedList.includes(value)) {
                setMailCheckedList(mailCheckedList.filter((item) => item !== value));
                return;
            }
        } else {
            if (isChecked) {
                setCheckedList((prev) => [...prev, value]);
                return;
            }

            if (!isChecked && checkedList.includes(value)) {
                setCheckedList(checkedList.filter((item) => item !== value));
                return;
            }
        }
    }

    const getFileType = (url) => {
        let fileType = '';

        if (url) {
            const match = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
            fileType = match ? '.'+match[1] : '';  // 확장자가 없으면 빈 문자열
        }
        return fileType;
    }

    /* 출력 문서 체크 */
    const checkDocument = () => {
        let list = [];

        if (!isSendAll && mailCheckedList.length === 0) {
            showToast('전송하실 문서를 선택해주세요.');
            return;
        }

        // pdf url 먼저 담아주기
        mailCheckedList.map(v => {
            if (v === "pdfType2" && ReleaseAskDetailStore.releaseAskItemList[0].insurancePolicyUrl != null && ReleaseAskDetailStore.releaseAskItemList[0].insurancePolicyUrl != '') {
                list.push({documentType: 'insurancePolicy', urlFile: ReleaseAskDetailStore.releaseAskItemList[0]?.insurancePolicyUrl, urlFileName: "보험증권"+getFileType(ReleaseAskDetailStore.releaseAskItemList[0]?.insurancePolicyUrl), model: ReleaseAskDetailStore.releaseAskItemList[0].model});
            }
            if (v === "pdfType10" && ReleaseAskDetailStore.releaseAskItemList[0].businessLicenseUrl != null && ReleaseAskDetailStore.releaseAskItemList[0].businessLicenseUrl != '') {
                list.push({documentType: 'businessLicense', urlFile: ReleaseAskDetailStore.releaseAskItemList[0]?.businessLicenseUrl, urlFileName: "사업자등록증"+getFileType(ReleaseAskDetailStore.releaseAskItemList[0]?.businessLicenseUrl), model: ReleaseAskDetailStore.releaseAskItemList[0].model});
            }
            if (v === "pdfType11" && ReleaseAskDetailStore.releaseAskItemList[0].bankbookImageUrl != null && ReleaseAskDetailStore.releaseAskItemList[0].bankbookImageUrl != '') {
                list.push({documentType: 'bankbookImage', urlFile: ReleaseAskDetailStore.releaseAskItemList[0]?.bankbookImageUrl, urlFileName: "통장사본"+getFileType(ReleaseAskDetailStore.releaseAskItemList[0]?.bankbookImageUrl), model: ReleaseAskDetailStore.releaseAskItemList[0].model});
            }
            // pdfType12부터 시작되는 동적으로 추가된 URL 파일 처리
            if (v.startsWith("pdfType") && parseInt(v.replace('pdfType', '')) >= 12) {
                const seq = parseInt(v.replace('pdfType', '')) - 12; 
                
                if (ReleaseAskDetailStore.documentList[seq]) {
                    const doc = ReleaseAskDetailStore.documentList[seq]; // 해당하는 seq에 맞는 문서만 선택
                    list.push({
                        documentType: 'etc.',
                        urlFile: doc.url,
                        urlFileName: `${doc.fileName}.${doc.fileType}`,
                        model: ReleaseAskDetailStore.releaseAskItemList[0].model
                    });
                }
            }

            {/* 자산별 */}
            if (v === 'pdfType5') {
                {ReleaseAskDetailStore.releaseAskItemList.map((data) => {
                    if (data.isOption === 0 && data.assetCertificateUrl != null && data.assetCertificateUrl != '')
                    list.push({documentType: 'assetCertificate', urlFile: data.assetCertificateUrl, urlFileName: "안전인증서_"+data.model+getFileType(data.assetCertificateUrl), model: data.model});
                })}
            }

            {/* 모델별 1부씩 필요 */}
            {ReleaseAskDetailStore.releaseAskItemList && ReleaseAskDetailStore.releaseAskItemList.filter((val, idx) => {
                return (ReleaseAskDetailStore.releaseAskItemList.findIndex((data1) => {
                    return val.model === data1.model
                }) === idx
            )
            }).map((data) => {
                if (data.isOption === 0){
                    switch (v) {
                        case "pdfType3": if(data.specificationTableUrl !== null && data.specificationTableUrl !== '') {
                            list.push({documentType: 'specificationTable', urlFile: data.specificationTableUrl, urlFileName: "제원표_"+data.model+getFileType(data.specificationTableUrl), model: data.model});
                        } break;
                        case "pdfType4": if(data.safetyCertificateUrl !== null && data.safetyCertificateUrl !== '') {
                            list.push({documentType: 'safetyCertificate', urlFile: data.safetyCertificateUrl, urlFileName: "안전인증서_"+data.model+getFileType(data.safetyCertificateUrl), model: data.model});
                        } break;
                        // case "pdfType5": if(data.assetCertificateUrl !== null && data.assetCertificateUrl !== '') {
                        //     list.push({documentType: 'assetCertificate', urlFile: data.assetCertificateUrl, urlFileName: "안전인증서_"+data.model+getFileType(data.assetCertificateUrl), model: data.model});
                        // } break;
                        case "pdfType6": if(data.workingMethodUrl !== null && data.workingMethodUrl !== '') {
                            list.push({documentType: 'workingMethod', urlFile: data.workingMethodUrl, urlFileName: "작동방법설명서_"+data.model+getFileType(data.workingMethodUrl), model: data.model});
                        } break;
                        case "pdfType7": if(data.emergencyDownUrl !== null &&  data.emergencyDownUrl !== '') {
                            list.push({documentType: 'emergencyDown', urlFile: data.emergencyDownUrl, urlFileName: "비상하강_"+data.model+getFileType(data.emergencyDownUrl), model: data.model});
                        } break;
                    }
                }
            })}
            // // pdfType12부터 시작되는 동적으로 추가된 URL 파일 처리
            // if (v.startsWith("pdfType") && parseInt(v.replace('pdfType', '')) >= 12) {
            //     ReleaseAskDetailStore.documentList.forEach((doc, index) => (
            //         list.push({ documentType: 'etc.', urlFile: doc.url, urlFileName: `${doc.fileName}.${doc.fileType}`, model: ReleaseAskDetailStore.releaseAskItemList[0].model })
            //     ))
            // }
        })

        

        if (list.length > 0 || ['pdfType1', 'pdfType8', 'pdfType9'].filter(x => mailCheckedList.includes(x)).length > 0) {
            return list;
        } else {
            showToast('전송할 문서가 존재하지 않습니다.');
            return false;
        }
    }

    /* 이메일 문서 템플릿 생성 및 전송 */
    const makeReleaseAskDocument = async () => {
        let docFileList = checkDocument();

        if (!docFileList) {
            return false;
        }

        let formData = new FormData();
        let i = 0;
        let label = [];

        if (emailSubject === '') {
            label.push('메일 제목');
        }
        if (emailContent === '') {
            label.push('메일 내용');
        }
        if (receiverEmail === '') {
            label.push('받는 사람');
        }

        if(label.length > 0) {
            showToast(label.join(", ") + "을 입력해주세요.");
            return;
        }

        if(!await callConfirm('전송하시겠습니까?')) {
            return;
        }

        // 계약서, 안전점검결과서, 반입전체크리스트 html 템플릿 생성
        const result = await makeReleaseAskPrintTemplate(mailCheckedList);
        for (const data of result) {
            const options = {
                fileName: data.fileName,
                image: {type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
            }

            const body = document.querySelector('body');

            body.style.lineHeight = '0.3';

            const dd = body.getElementsByClassName('contractBody');
            dd.forEach (v => {
                v.style.lineHeight=1;
            })

            const pdfBlob = await html2pdf().set(options).from(data.content).toPdf().output('blob').then(data => {
                return data
            });
            await deleteStyle(body);

            const blob = new Blob([pdfBlob], {type: 'application/pdf'});
            const file = new File([blob], data.fileName);

            formData.append("fileList["+i+"].documentType", data.documentType);
            formData.append('fileList['+i+'].file', file);
            formData.append('fileList['+i+'].urlFileName', data.fileName);
            i += 1;
        }

        for (const data of docFileList) {
            formData.append("fileList["+i+"].documentType", data.documentType);
            formData.append("fileList["+i+"].urlFile", data.urlFile);
            formData.append("fileList["+i+"].urlFileName", data.urlFileName);
            i += 1;
        }

        formData.append("subject", emailSubject);
        formData.append("content", emailContent);
        formData.append("receiver", receiverEmail);
        const msg = await axios.post('/releaseAsk/send/document', formData, { headers: MULTIPART_HEADER });

        if (msg === 'success') {
            showToast('전송되었습니다.');
        }
    }

    const deleteStyle = async (body) => {
        body.style.lineHeight = 'normal';
    }

    const printGroupByDocument = () => {
        let docFileList = checkDocument();

        if (!docFileList) {
            return false;
        } else {
            printGroupBy();
        }
    }

    const printGroupBy = useReactToPrint({
        content: () => groupByPrintRef.current,
    });

    /* 출고제품 문서 가져오기 */
    const getDocument = async (releaseAskSeq) => {
        const result = await axios.get(`releaseAsk/document/${releaseAskSeq}`);
        ReleaseAskDetailStore.documentList = result;
        rows();
    }

    const generateCheckboxes = () => {
        return ReleaseAskDetailStore.documentList.map((doc, index) => (
            <CCol lg={6} key={index}>
                <label style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
                    <Form.Check
                        type="checkbox"
                        id={`pdfType${index + 12}`}
                        checked={mailCheckedList.includes(`pdfType${index + 12}`)}
                        onChange={v => checkedHandler(v, `pdfType${index + 12}`, true)}
                        style={{ verticalAlign: 'middle', marginRight: 10, marginLeft: 10 }}
                    />
                    <span>{doc.fileName + '(메일용)'}</span>
                </label>
            </CCol>
        ));
    };

    const rows = () => {
        const checkboxes = generateCheckboxes();
        const rows = [];

        for (let i = 0; i < checkboxes.length; i += 6) {
            rows.push(
                <React.Fragment key={`row-${i}`}> 
                    {checkboxes.slice(i, i + 6)}
                </React.Fragment>
            );
        }
        return rows;
    };

    const refresh = () => {
        ReleaseAskDetailStore.initDocument();
        setEmailContent('');
        setEmailSubject('');
        setReceiverEmail('');
    }

    return (
        <Row style={{maxWidth: 1100}}>
            <Row>
                <Subject>출고 제품 리스트</Subject>
                <AgGridContainer
                    gridRef={gridRef}
                    height={30}
                    rowData={ReleaseAskDetailStore.releaseAskItemList}
                    columnDefs={
                        [
                            {field: "releaseAskSeq", headerName: "출고순번", hide: true},
                            {field: "seq", headerName: "순번", width: 80},
                            {field: "contractProductSeq", headerName: "묶음번호", width: 110,},
                            {
                                field: "isOption",
                                headerName: "옵션(소모품)여부",
                                width: 150,
                                valueGetter: v => v.data.isOption === 1 ? 'Y' : ''
                            },
                            {field: "productSeq", headerName: "제품코드", width: 100, hide: true},
                            {field: "productName", headerName: "제품명", width: 150, hide: true},
                            {field: "model", headerName: "모델명", width: 200},
                            {field: "qty", headerName: "수량", width: 80},
                            {
                                field: "managementNo",
                                headerName: "관리번호",
                                width: 140,
                            },
                            {
                                field: "orgAssetNo",
                                headerName: "자산번호",
                                width: 120,
                                hide: true,
                            },
                            {
                                field: "assetNo",
                                headerName: "자산번호",
                                width: 120,
                                hide: true,
                            },
                            {field: "serialNo", headerName: "시리얼번호", width: 150},
                            {field: "apronSeq", headerName: "주기장 순번", hide: true},
                            {field: "apronName", headerName: "주기장", width: 100},
                            {field: "spec", headerName: "스펙", width: 300},
                            {field: "remark", headerName: "비고", width: 300},
                            {
                                field: "print",
                                headerName: "인쇄",
                                width: 100,
                                cellRenderer: (v) => (
                                    <div>
                                        <IconBtn iconClass={'fi fi-rs-print'} className={'mt-3'} onClick={() => printPdf()}/>
                                    </div>
                                ),
                                hide: true
                            },
                        ]
                    }
                    seqColumn={'seq'}
                />
            </Row>
            <Row style={{marginTop: 30}}>
                <Col lg={9}>
                    <div className="btn-group btn-group-toggle pt-1">
                        <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                            <Form.Check
                                type="radio" value={true} name="isPrintAll"
                                checked={isPrintAll === true ? true : false}
                                onChange={v => {
                                    // 출고문서 체크박스 전체 체크하기
                                    [...Array(parseInt(8))].forEach((n, index) => {
                                        checkedList.push('pdfType'+Number(index+1));
                                    })
                                    setCheckedList(['pdfType1', 'pdfType2', 'pdfType3', 'pdfType4', 'pdfType5', 'pdfType6', 'pdfType7', 'pdfType8']);
                                    setIsPrintAll(v.target.value === 'true' ? true : false)
                                }}
                                style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                disabled={false}
                            />
                            <span>전체 출력</span>
                        </label>
                        <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                            <Form.Check
                                type="radio" value={false} name="isPrintAll"
                                checked={isPrintAll === false ? true : false}
                                onChange={v => {
                                    setCheckedList(['pdfType1', 'pdfType2', 'pdfType3', 'pdfType4', 'pdfType5', 'pdfType8']);
                                    setIsPrintAll(v.target.value === 'false' ? false : true)
                                }}
                                style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                disabled={false}
                            />
                            <span>선택 출력</span>
                        </label>
                    </div>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col lg={12}>
                    <Row className="btn-group btn-group-toggle p-3 mt-3 border" style={{width: '100%', margin: 'auto'}}>
                        <Col lg={12}>
                            <Row style={{marginBottom: 15}}>
                                <Col lg={2}>
                                    <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType1'
                                            checked={checkedList.includes('pdfType1')}
                                            onChange={v => checkedHandler(v, 'pdfType1')}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            disabled={isPrintAll}
                                        />
                                        <span>안전점검결과서</span>
                                    </label>
                                </Col>
                                <Col lg={2}>
                                    <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType2'
                                            checked={checkedList.includes('pdfType2')}
                                            onChange={v => checkedHandler(v, 'pdfType2')}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            disabled={isPrintAll}
                                        />
                                        <span>보험증권</span>
                                    </label>
                                </Col>
                                <Col lg={2}>
                                    <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType3'
                                            checked={checkedList.includes('pdfType3')}
                                            onChange={v => checkedHandler(v, 'pdfType3')}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            disabled={isPrintAll}
                                        />
                                        <span>제원표</span>
                                    </label>
                                </Col>
                                <Col lg={2}>
                                    <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType4'
                                            checked={checkedList.includes('pdfType4')}
                                            onChange={v => checkedHandler(v, 'pdfType4')}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            disabled={isPrintAll}
                                        />
                                        <span>안전인증서(형식)</span>
                                    </label>
                                </Col>
                                <Col lg={2}>
                                    <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType5'
                                            checked={checkedList.includes('pdfType5')}
                                            onChange={v => checkedHandler(v, 'pdfType5')}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            disabled={isPrintAll}
                                        />
                                        <span>안전인증서(개별)</span>
                                    </label>
                                </Col>
                                <Col lg={2}>
                                    <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType6'
                                            checked={checkedList.includes('pdfType6')}
                                            onChange={v => checkedHandler(v, 'pdfType6')}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            disabled={isPrintAll}
                                        />
                                        <span>작동방법 설명서</span>
                                    </label>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2}>
                                    <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType7'
                                            checked={checkedList.includes('pdfType7')}
                                            onChange={v => checkedHandler(v, 'pdfType7')}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            disabled={isPrintAll}
                                        />
                                        <span>비상하강</span>
                                    </label>
                                </Col>
                                <Col lg={2}>
                                    <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType8'
                                            checked={checkedList.includes('pdfType8')}
                                            onChange={v => checkedHandler(v, 'pdfType8')}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            disabled={isPrintAll}
                                        />
                                        <span>반입전 체크리스트</span>
                                    </label>
                                </Col>
                                <Col lg={2}>
                                    <label style={{display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType9'
                                            checked={checkedList.includes('pdfType9')}
                                            onChange={v => checkedHandler(v, 'pdfType9')}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            disabled={isPrintAll}
                                        />
                                        <span>계약서</span>
                                    </label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="pb-3">
                <Col className='d-flex flex-row-reverse'>
                    <CIconBtn style={{width: 160}} icon={'fi fi-rs-paper-plane'} title={'제품문서 메일 전송'} onClick={() => setSendMailPopup(true)} variant={'primary'}/>
                    <CIconBtn style={{width: 160}} icon={'fi fi-rs-print'} title={'출고제품문서출력'} onClick={() => printPdf()} variant={'success'}/>
                    <ReportReleaseAskPrintTemplate ref={componentPrintRef} checkedList={checkedList} />
                </Col>
            </Row>
            <Modal title={'출고제품문서 메일 전송'}
                onHide={() => setSendMailPopup(false)}
                toggleValue={sendMailPopup}
                onExit={refresh}
                className={'modal-w9'}
            >
                <CContainer>
                    <Row className="mt-3">
                        <CCol lg={6}>
                            <CCol lg={12}>
                                <CInputGroup label={'제목'}
                                            labelId={'subject'}
                                            placeholder={'제목을 작성해주세요.'}
                                            value={emailSubject}
                                            onChange={v => setEmailSubject(v.target.value)}
                                />
                            </CCol>
                            <CCol lg={12}>
                                <textarea className="form-control" rows="8"
                                    value={emailContent}
                                    placeholder={'내용을 자유롭게 작성해주세요.'}
                                    readOnly={false}
                                    onChange={v => setEmailContent(v.target.value)}
                                />
                            </CCol>
                            <CCol lg={12}>
                                <CInputGroup label={'받는사람'}
                                            labelId={'receiverEmail'}
                                            placeholder={'sample@naver.com'}
                                            value={receiverEmail}
                                            onChange={v => setReceiverEmail(v.target.value)}
                                />
                            </CCol>
                            <CCol lg={12} style={{color: 'red'}}>* 메일용은 메일 발송 시에만 사용할 수 있습니다. </CCol>
                        </CCol>
                        <CCol lg={6}>
                            <CCol lg={12}>
                                <div className="btn-group btn-group-toggle pt-1">
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                    <Form.Check
                                        type="radio"
                                        value={true}
                                        name="isSendAll"
                                        checked={isSendAll === true}
                                        onChange={v => {
                                            const allChecked = [...Array(ReleaseAskDetailStore.documentList.length + 11)].map((_, index) => 
                                                'pdfType' + (index + 1)
                                            );
                                            setMailCheckedList(allChecked);
                                            setIsSendAll(true);
                                        }}
                                        style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        disabled={false}
                                    />
                                    <span>전체</span>
                                </label>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="radio" value={false} name="isSendAll"
                                            checked={isSendAll === false ? true : false}
                                            onChange={v => {
                                                setMailCheckedList([]);
                                                setIsSendAll(v.target.value === 'false' ? false : true)
                                            }}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            disabled={false}
                                        />
                                        <span>선택</span>
                                    </label>
                                </div>
                            </CCol>
                            <Row className="btn-group btn-group-toggle pt-3 pl-3 pr-3 pb-2 mt-1 border">
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType1'
                                            checked={mailCheckedList.includes('pdfType1')}
                                            onChange={v => checkedHandler(v, 'pdfType1', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>안전점검결과서</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType2'
                                            checked={mailCheckedList.includes('pdfType2')}
                                            onChange={v => checkedHandler(v, 'pdfType2', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>보험증권</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType3'
                                            checked={mailCheckedList.includes('pdfType3')}
                                            onChange={v => checkedHandler(v, 'pdfType3', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>제원표</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType4'
                                            checked={mailCheckedList.includes('pdfType4')}
                                            onChange={v => checkedHandler(v, 'pdfType4', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>안전인증서(형식)</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType5'
                                            checked={mailCheckedList.includes('pdfType5')}
                                            onChange={v => checkedHandler(v, 'pdfType5', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>안전인증서(개별)</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType6'
                                            checked={mailCheckedList.includes('pdfType6')}
                                            onChange={v => checkedHandler(v, 'pdfType6', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>작동방법 설명서</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType7'
                                            checked={mailCheckedList.includes('pdfType7')}
                                            onChange={v => checkedHandler(v, 'pdfType7', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>비상하강</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType8'
                                            checked={mailCheckedList.includes('pdfType8')}
                                            onChange={v => checkedHandler(v, 'pdfType8', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>반입전 체크리스트</span>
                                    </label>
                                </CCol>
                                <CCol lg={6}>
                                    <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                        <Form.Check
                                            type="checkbox"
                                            id='pdfType9'
                                            checked={mailCheckedList.includes('pdfType9')}
                                            onChange={v => checkedHandler(v, 'pdfType9', true)}
                                            style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                        />
                                        <span>계약서</span>
                                    </label>
                                </CCol>
                                {ReleaseAskDetailStore.releaseAskItemList[0]?.bankbookImageUrl && (
                                    <CCol lg={6}>
                                        <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                            <Form.Check
                                                type="checkbox"
                                                id='pdfType10'
                                                checked={mailCheckedList.includes('pdfType10')}
                                                onChange={v => checkedHandler(v, 'pdfType10', true)}
                                                style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                                />
                                            <span>사업자등록증</span>
                                        </label>
                                    </CCol>
                                )}
                                {ReleaseAskDetailStore.releaseAskItemList[0]?.businessLicenseUrl && (
                                    <CCol lg={6}>
                                        <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle'}}>
                                            <Form.Check
                                                type="checkbox"
                                                id='pdfType11'
                                                checked={mailCheckedList.includes('pdfType11')}
                                                onChange={v => checkedHandler(v, 'pdfType11', true)}
                                                style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10}}
                                            />
                                            <span>통장사본</span>
                                        </label>
                                    </CCol>
                                )}
                                {ReleaseAskDetailStore.documentList.length > 0 && rows()}
                            </Row>
                        </CCol>
                    </Row>
                    <Row>
                        <Col lg={12} className="d-flex flex-row-reverse">
                            <CIconBtn style={{width: 100, height: 30}}
                                    title={'전송'}
                                    icon={'fi fi-rs-paper-plane'}
                                    onClick={makeReleaseAskDocument}
                                    variant={'success'}
                            />
                            <CIconBtn style={{width: 100, height: 30}}
                                    title={'출력'}
                                    icon={'fi fi-rr-print'}
                                    onClick={printGroupByDocument}
                                    variant={'primary'}
                            />
                            <CIconBtn style={{width: 160, height: 30}} icon={'fi fi-rr-upload'} title={'기타 문서 업로드'} onClick={() => AppStore.toggleUploadDcoumentPopup()} variant={'secondary'}/>
                        </Col>
                    </Row>
                    <UploadDcoumentPopup releaseAskSeq={ReleaseAskDetailStore.releaseAskDetail.releaseAskSeq} getDocument={getDocument} />
                    <ReportReleaseAskPrintGroupByTemplate mailCheckedList={mailCheckedList} ref={groupByPrintRef} />
                </CContainer>
            </Modal>
        </Row>
    );
}

export default observer(ReleaseItemDocs);
