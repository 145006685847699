import { observer } from "mobx-react-lite"
import { useState } from "react";
import { Col } from "react-bootstrap";
import { CContainer, CCol, CIconBtn, CInputGroup } from "../../../components/CustomContainer";
import Modal from "../../../components/Modal";
import { AppStore } from "../../../store/AppStore";

const ChangePricePopup = ({callBackFn}) => {
    const [monthPrice, setMonthPrice] = useState(0);

    const refresh = () => {
        setMonthPrice(0);
    }

    const allChangeRows = () => {
        callBackFn(monthPrice);
        AppStore.toggleChangePricePopup();
    }

    return (
        <>
        <Modal title={'렌탈료 일괄 적용'}
            onHide={() => AppStore.toggleChangePricePopup()}
            toggleValue={AppStore.isOpenChangePricePopup}
            onExit={refresh}
            size={'sm'}
        >
            <CContainer>
                <CCol lg={12}>
                    <CInputGroup label={'월렌탈료'}
                                labelId={'subject'}
                                placeholder={'변경할 금액을 입력해주세요.'}
                                value={monthPrice}
                                onChange={v => setMonthPrice(v.target.value)}
                                backLabel={'원'}
                                align={'right'}
                    />
                </CCol>
                <Col className="d-flex flex-row-reverse">
                    <CIconBtn style={{width: 90, height: 30}}
                            title={'일괄 적용'}
                            className={'me-0'}
                            onClick={allChangeRows}
                            variant={'success'}
                    />
                </Col>
            </CContainer>
        </Modal>
        </>
    );
}

export default observer(ChangePricePopup);
