import { observable } from "mobx";

export const CommCodeStore = observable({
    commCodeList: [],
    mdList: [],
    smList: [],

    setCommCodeList(v) {
        this.commCodeList = v;
    },
    setMDList(v) {
        this.mdList = v;
    },
    setSMList(v) {
        this.smList = v;
    },
});