import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {CContainer, CCol, CInputGroup, CInputDoubleGroup, CSearchBtn, CalendarGroup, CSelectGroup} from "../../components/CustomContainer";
import { Row, Col } from "react-bootstrap";
import { RentalListStore } from "../../store/contract/RentalListStore";
import { AppStore } from "../../store/AppStore";
import CustomerBillingAddressSearch from "../../components/searchModal/CustomerBillingAddressSearch";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import SiteNameSearch from "../../components/searchModal/SiteNameSearch";
import { today } from "../../utils/dateUtils";
import { getCommcode } from '../../utils/commcode';

const ContractListSearch = ({getRentalList}) => {
    const [userColumn, setUserColumn] = useState('');
    const [searchDate, setSearchDate] = useState({startDate: today(), endDate: null});

    useEffect(()=> {
        RentalListStore.setSearchCondition({searchDate: today()})
    }, [])

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        RentalListStore.setSearchCondition({customerName: customerInfo.customerName});
        RentalListStore.setSearchCondition({customerNo: customerInfo.customerNo});
    }

     /* 청구지 조회 팝업 오픈 */
     const openSearchBillingAddressPopup = () => {
        AppStore.toggleCustomerBillingAddressSearchModal()
    }

    /* 청구지 정보 콜백 함수 */
    const getBillingAddressInfo = (info) => {
        RentalListStore.setSearchCondition({billingAddressSeq: info.billingAddressSeq});
        RentalListStore.setSearchCondition({billingAddressName: info.billingAddressName});
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = (column) => {
        setUserColumn(column);
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo, column) => {
        if (!userInfo || !column) {
            return false;
        }

        RentalListStore.setSearchCondition({[`${column}Name`]: userInfo.name});
        RentalListStore.setSearchCondition({[`${column}Seq`]: userInfo.userSeq});
        setUserColumn('');
    }

    /* 현장 검색 팝업 오픈 */
    const openSearchSitePopup = () => {
        AppStore.toggleSiteNameSearchPopup();
    }
    /* 현장 검색 콜백 함수 */
    const getSiteInfo = (siteInfo) => {
        RentalListStore.setSearchCondition({siteName: siteInfo.siteName, siteSeq: siteInfo.siteSeq});
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol>
                        <CalendarGroup
                            asSingle={true}
                            label={'기준일'}
                            value={searchDate}
                            onChange={v => {
                                setSearchDate(v);
                                RentalListStore.setSearchCondition({searchDate: v.startDate})
                            }}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputDoubleGroup
                            label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객코드'} placeholder2={'고객명'}
                            onCallbackBtn={openSearchCustomerPopup}
                            value={RentalListStore.searchCondition.customerNo}
                            value2={RentalListStore.searchCondition.customerName}
                            onChange={(v)=> RentalListStore.setSearchCondition({customerNo: v.target.value})}
                            onChange2={(v)=> RentalListStore.setSearchCondition({customerName: v.target.value})}
                            enterKeyHint={getRentalList}
                            enterKeyHint2={getRentalList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'영업담당자'}
                            value={RentalListStore.searchCondition.saleUserName}
                            onChange={(v)=> RentalListStore.setSearchCondition({saleUserName: v.target.value})}
                            onCallbackBtn={() => openSearchUserPopup('saleUser')}
                            enterKeyHint={getRentalList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'계약번호'}
                            value={RentalListStore.searchCondition.contractNo}
                            onChange={v => RentalListStore.setSearchCondition({ contractNo: v.target.value })}
                            enterKeyHint={getRentalList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'자산번호'}
                            value={RentalListStore.searchCondition.assetNo}
                            onChange={(v)=> RentalListStore.setSearchCondition({ assetNo: v.target.value })}
                            enterKeyHint={getRentalList}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'관리번호'}
                            value={RentalListStore.searchCondition.managementNo}
                            onChange={(v)=> RentalListStore.setSearchCondition({ managementNo: v.target.value })}
                            enterKeyHint={getRentalList}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'현장'}
                            value={RentalListStore.searchCondition.siteName}
                            onCallbackBtn={openSearchSitePopup}
                            onChange={(v)=> {
                                if(RentalListStore.searchCondition.siteSeq) {
                                    RentalListStore.setSearchCondition({siteSeq: ''})
                                }
                                RentalListStore.setSearchCondition({siteName: v.target.value})
                            }}
                            enterKeyHint={getRentalList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'설치장소'}
                            value={RentalListStore.searchCondition.installPlace}
                            onChange={(v)=> {
                                RentalListStore.setSearchCondition({installPlace: v.target.value})
                            }}
                            enterKeyHint={getRentalList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'청구지'}
                            value={RentalListStore.searchCondition.billingAddressName}
                            onCallbackBtn={openSearchBillingAddressPopup}
                            onEmptyBtn={()=> RentalListStore.setSearchCondition({billingAddressName: ''})}
                            disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                         <CSelectGroup
                            label={'장비상태'}
                            labelId={'rentType'}
                            value={RentalListStore.searchCondition.rentType}
                            options={[{name: '전체', value: ''}].concat(getCommcode('65'))}
                            onChange={v=> RentalListStore.setRentalListSearchCondition('rentType', v.target.value)}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getRentalList} />
                    </Col>
                </Row>
            </CContainer>
            {/* popup */}
            <CustomerBillingAddressSearch callbackFn={getBillingAddressInfo} />
            <CustomerSearch callbackFn={getCustomerInfo}/>
            <UserSearch callbackFn={getUserInfo} column={userColumn}/>
            <SiteNameSearch callbackFn={getSiteInfo}/>
        </>
    );

}

export default observer(ContractListSearch);
