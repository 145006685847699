import { observable } from "mobx";
import { oneMonthAfter, today } from "../../utils/dateUtils";
import LUtils from "../../utils/lodashUtils";
import { USER_INFO } from "../../common/constants";
import { getLocalStorage } from "../../utils";

export const AsStore = observable({

    asList: [],
    // consumableList: [],

    data: {
        asSeq: '',
        siteSeq: '',
        siteName: '',
        siteManagerName: '',
        siteManagerPhone: '',
        asManagerSeq: '',
        asManagerName: '',
        asManagerPhone: '',
        managementNo: '',
        acceptDetail: '',
        processDetail: '',
        acceptedDate: null,
        completedDate: null,
        cancelDate: null,
        asStatus: '',
        customerNo: '',
        customerName: '',
        sitePostcode: '',
        siteAddress: '',
        siteAddressDetail: '',
        equipmentPart: '',
        shortUrl: '',
        productType: '',
        companyCode: '',

        searchManagementNo: '',
        managementNoList: [],
        maintenanceList: [],
        asManagerList: [],
    },

    search: {
        startDateString: today(),
        endDateString: oneMonthAfter(),
        siteSeq: '',
        siteName: '',
        assetNo: '',
        managementNo: '',
        modelNo: '',
        acceptUserSeq: '',
        acceptUserName: '',
        completeUserSeq: '',
        completeUserName: '',
        asManagerSeq: '',
        asManagerName: '',
        siteSeq: '',
        siteName: '',
    },

    setData(v) {
        LUtils.assign(this.data, v);
    },
    setSearchCondition(v) {
        LUtils.assign(this.search, v);
    },

    init() {
        this.data.asSeq = '';
        this.data.siteSeq = '';
        this.data.siteName = '';
        this.data.siteManagerName = '';
        this.data.siteManagerPhone = '';
        this.data.asManagerSeq = '';
        this.data.asManagerName = '';
        this.data.asManagerPhone = '';
        this.data.managementNo = '';
        this.data.acceptDetail = '';
        this.data.processDetail = '';
        this.data.acceptedDate = null;
        this.data.completedDate = null;
        this.data.cancelDate = null;
        this.data.asStatus = '';
        this.data.customerNo = '';
        this.data.customerName = '';
        this.data.equipmentPart = '';
        this.data.shortUrl = '';
        
        this.data.managementNoList = [];
        this.data.maintenanceList = [];
        this.data.consumableList = [];

        this.data.searchManagementNo = '';
    },

})
