import { observable } from "mobx";

export const UserTabsStore = observable({
    userTab: 'userInfo',    // annualLeaveCount, annualLeaveHistory, salary, personnelAppointment, familyEvents, workExperience, attendance, certificate
    annualLeaveCountList: [],
    annualLeaveHistoryList: [],
    annualLeaveHistoryGridList: [],
    salaryDetailsList: [],
    personnelAppointmentDetailsList: [],
    familyEventsExpensesList: [],
    workExperienceList: [],
    attendanceDetailsList: [],
    issueCertificateHistory: [],

    /* action */
    setUserTab(v) {
        this.userTab = v;
    },

    setAnnualLeaveCountList(v) {
        this.annualLeaveCountList = v;
    },
    setAnnualLeaveHistoryList(v){
        this.annualLeaveHistoryList = v;
    },
    setAnnualLeaveHistoryGridList(v){
        this.annualLeaveHistoryGridList = v;
    },
    setSalaryDetailsList(v){
        this.salaryDetailsList = v;
    },
    setPersonnelAppointmentDetailsList(v){
        this.personnelAppointmentDetailsList = v;
    },
    setFamilyEventsExpensesList(v){
        this.familyEventsExpensesList = v;
    },
    setWorkExperienceList(v){
        this.workExperienceList = v
    },
    setAttendanceDetailsList(v){
        this.attendanceDetailsList = v;
    },
    setIssueCertificateHistory(v){
        this.issueCertificateHistory = v;
    }
});