import { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { CContainer, IconBtn } from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import { ContractListStore } from "../../store/contract/ContractListStore";
import { AppStore } from "../../store/AppStore";
import axios from "axios";
import { getUserName } from "../../utils/userUtils";
import {findCommKrnm} from "../../utils/commcode";
import { callAlert, callConfirm, getLocalStorage } from '../../utils';
import ContractListSearch from "./ContractListSearch";
import { ICON_TRASH, MULTIPART_HEADER, USER_INFO } from "../../common/constants";
import { showToast } from "../../common/utils";
import LUtils from "../../utils/lodashUtils";
import SendContractPopup from "./popup/SendContractPopup";
import { ContractDetailStore } from "../../store/contract/ContractDetailStore";
import { ContractCreateDetailStore } from "../../store/contract/ContractCreateDetailStore";

const ContractList = () => {
    const gridRef = useRef();
    const [isShort, setIsShort] = useState(true);
    const navigate = useNavigate();
    const userInfo = getLocalStorage(USER_INFO);

    /* 조회 */
    const getContractList = async () => {
        if ((ContractListStore.contractStartDate.startDate === null || ContractListStore.contractStartDate.startDate === '') || (ContractListStore.contractEndDate.startDate === null || ContractListStore.contractEndDate.startDate === '')) {
            showToast('시작일과 종료일을 입력해주세요.');
            return;
        }

        const { startDate: contractStartDate } = ContractListStore.contractStartDate;
        const { startDate: contractEndDate } = ContractListStore.contractEndDate;
        const params = LUtils.assign(ContractListStore.searchCondition, { contractStartDate, contractEndDate });
        const result = await axios.get('/contract/list', { params });
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.');
            return;
        }
        ContractListStore.setContractList(result);
    }

    /* 계약원부로 이동 */
    const gotoContractDetails = (v) => {
        ContractListStore.contractList.map(v => v.isSelected = false);
        ContractDetailStore.sendContractList = [];
        navigate('/contract/detail', { state: { contractNo: v.data.contractNo, releaseAskStatus: v.data.releaseAskStatus, isFix: v.data.isFix, sellDate: v.data.sellDate } });
        //히스토리 저장
        AppStore.pushOpenPageHistory({url: '/contract/detail', name: '계약상세'});
    }

    /* 계약 확정 */
    const fixContract = async() => {
        // 선택한 row
        const contractList = ContractListStore.contractList.filter(v => v.isSelected);

        // 확정인 row 제외
        if(contractList.filter(v => v.isFix).length > 0){
            showToast('미확정인 계약만 선택해 주세요.');
            return;
        }

        if(contractList.every(v => !((v.contractType === '5' && v.releaseNo) || (v.contractType !== '5' && v.releaseAskStatus === '6') ))){            showToast('출고완료되지 않은 계약은 확정할 수 없습니다.');
            return;
        }

        if(!contractList.every(v => v.isPreSendBill === 0)) {
            callAlert('선발행 계산서가 존재하는 계약은 상세 페이지에서 확정해주세요.');
            return;
        }

        // 계약확정 확인
        if(!await callConfirm('계약을 확정 하시겠습니까?')) return;

        const result = await axios.put('contract/fix', {contractList, isFix: 1});
        showToast('계약이 확정되어 청구 스케쥴이 생성되었습니다.');
        await getContractList();

    }

    /* 확정 취소 */
    const cancelFix = async() => {
        // 선택한 row
        const contractList = ContractListStore.contractList.filter(v => v.isSelected);

        // 미확정인 row 제외
        if(contractList.filter(v => !v.isFix).length > 0){
            showToast('확정인 계약만 선택해 주세요.');
            return;
        }
/*
        const list = contractList.filter(v => v.contractDate < oneMonthAgo()).map(v => v.contractNo);
        if(list.length > 0) {
            showToast('계약일 기준 30일 초과하여 계약번호 '+ list.join(', ') +'는 확정 취소가 불가능합니다.');
            return;
        }
*/
        // 확정취소 확인
        if(!await callConfirm('확정취소 하시겠습니까?')) return;

        const result = await axios.put('contract/fix', {contractList, isFix: 0});
        showToast('확정이 취소되어 청구가 삭제되었습니다.');
        await getContractList();

    }

    const contractDelete =async()=> {
        // 선택한 row
        const contractList = ContractListStore.contractList.filter(v => v.isSelected);

        if(contractList.length === 0){
            showToast('삭제할 계약을 선택해 주세요');
            return;
        }

        // 확정인 row 제외
        if(contractList.filter(v => v.isFix).length > 0){
            showToast('미확정인 계약만 선택해 주세요.');
            return;
        }

        if(contractList.every(v => v.contractType !== '3' && v.contractType !== '4') && contractList.some(v => (v.releaseAskStatus !== '1' && v.releaseAskStatus !== '2' && v.releaseAskStatus !== '9') && v.releaseAskStatus)){
            showToast('삭제할 수 있는 조건이 아닙니다.');
            return;
        }

        if(!await callConfirm('선택된 계약을 삭제하시겠습니까?')) return;

        const result = await axios.put('contract/delete', contractList);
        showToast('삭제되었습니다.');
        await getContractList();
    }

    const customButtonInfo = [
        {
            isUsed: true,
            callbackFn:() => contractDelete(),
            variant: 'danger',
            icon: ICON_TRASH,
            title: '계약삭제',
            width: 100,
        },
        {
            isUsed: true,
            callbackFn: () => sendEmailPopup(),
            title: '전자계약 메일 발송',
            icon: 'fi-rr-envelope',
            width: 150,
        },
        {
            isUsed: true,
            callbackFn: () => cancelFix(),
            title: '확정취소',
            icon: 'fi fi-rs-rectangle-xmark',
            width: 100,
            variant: 'danger',
        },
        {
            isUsed: true,
            callbackFn: () => fixContract(),
            title: '확정',
            icon: 'fi-rr-box-check',
            variant: 'dark',
            width: 80,
        },
    ];

    /* 계약서 메일 전송 팝업 */
    const sendEmailPopup = () => {
        const selectedList = ContractListStore.contractList.filter(v => v.isSelected);
        if(selectedList.length < 1) {
            showToast("메일 전송할 계약을 선택해 주세요.");
            return;
        }
        // 청구지 같은지 확인
        const allItemsEqual = selectedList.every(
            (item, _, arr) => item.customerNo === arr[0].customerNo && item.billingAddressSeq === arr[0].billingAddressSeq
        );
    
        if (!allItemsEqual) {
            showToast('고객과 청구지가 같은 계약만 선택할 수 있습니다.');
            return;
        } 

        ContractDetailStore.sendContractList = selectedList;
        // 계약서 메일 보내는 부분 세팅
        ContractDetailStore.subject = '['+userInfo.companyName+'] 장비 임대 계약서';
        ContractDetailStore.receiverEmail = selectedList[0].receiverEmail;
        AppStore.toggleSendContractPopup();
    }

    /* 계약서 메일 전송 */
    const sendContract = async () => {
        if(!await callConfirm('메일 전송 하시겠습니까?')) return;

        if(!ContractDetailStore.receiverEmail) {
            showToast('받는사람 이메일을 적어주세요.');
            return;
        }

        // 이메일 체크
        const atSymbolCount = (ContractDetailStore.receiverEmail.match(/@/g) || []).length;
        if (atSymbolCount >= 2) {
            showToast('이메일을 하나만 적으세요');
            return;
        } 

        const formData = new FormData();
        formData.append('subject', ContractDetailStore.subject);
        formData.append('receiverEmail', ContractDetailStore.receiverEmail);

        const sendMailList = ContractDetailStore.sendContractList;
        for (const v of sendMailList) {
            formData.set('customerName', v.customerName);
            formData.set('billingAddressName', v.billingAddressName);
            formData.set('companyName', userInfo.companyName);
            formData.set('contractStartDate', v.contractStartDate);
            formData.set('productName', v.productName);
            formData.set('contractNo', v.contractNo);
            
            await axios.post('/contract/email', formData, { headers: MULTIPART_HEADER });
        }
    
        showToast('전송이 완료되었습니다.');
        AppStore.toggleSendContractPopup();
    }

    const pinnedTopRowData = [{
        monthRentPrice: ContractListStore.contractList.reduce((total, v) => total + v.monthRentPrice, 0),
        totalRentPrice: ContractListStore.contractList.reduce((total, v) => total + v.totalRentPrice, 0),
        productSalePrice: ContractListStore.contractList.reduce((total, v) => total + v.productSalePrice, 0),
        assetSalePrice: ContractListStore.contractList.reduce((total, v) => total + v.assetSalePrice, 0),
        contractNo: 'pinnedTopRowData',
    }];

    const copyBtn = async(contractNo) => {
        if(!await callConfirm(`${contractNo} 계약을 복사 하시겠습니까?`)) return;

        ContractCreateDetailStore.init();
        const data = ContractListStore.contractList.filter(v => v.contractNo === contractNo)[0];

        // 데이터 세팅
        ContractCreateDetailStore.contractType = data.contractType;
        ContractCreateDetailStore.customerNo = data.customerNo;
        ContractCreateDetailStore.customerName = data.customerName;
        ContractCreateDetailStore.saleUserSeq = data.saleUserSeq;
        ContractCreateDetailStore.saleUserName = data.saleUserName;
        ContractCreateDetailStore.telephone = data.telephone;
        ContractCreateDetailStore.phoneNumber = data.phoneNumber;
        ContractCreateDetailStore.supportUserSeq = data.supportUserSeq;
        ContractCreateDetailStore.supportUserName = data.supportUserName;
        ContractCreateDetailStore.createContractDays.rentMonth = data.rentMonth;
        ContractCreateDetailStore.createContractDays.rentDays = data.rentDays;
        ContractCreateDetailStore.billingAddressSeq = data.billingAddressSeq;
        ContractCreateDetailStore.billingAddressName = data.billingAddressName;
        ContractCreateDetailStore.siteSeq = data.siteSeq;
        ContractCreateDetailStore.siteName = data.siteName;
        ContractCreateDetailStore.contractDocType = data.contractDocType;
        ContractCreateDetailStore.contractStartType = data.contractStartType;
        ContractCreateDetailStore.installPlace = data.installPlace;
        ContractCreateDetailStore.isPenaltyFee = data.isPenaltyFee;
        ContractCreateDetailStore.penaltyPercent = data.penaltyPercent;
        ContractCreateDetailStore.extendFirstRate = data.extendFirstRate;
        ContractCreateDetailStore.extendSecondRate = data.extendSecondRate;
        ContractCreateDetailStore.isDeliveryPrice = data.isDeliveryPrice;
        ContractCreateDetailStore.deliveryPrice = data.deliveryPrice;
        ContractCreateDetailStore.chargeDelivery = data.chargeDelivery;
        ContractCreateDetailStore.deliveryKind = data.deliveryKind;
        ContractCreateDetailStore.isRepairCost = data.isRepairCost;
        ContractCreateDetailStore.isInstallPrice = data.isInstallPrice;
        ContractCreateDetailStore.installPrice = data.installPrice;
        ContractCreateDetailStore.specialRemark = data.specialRemark;
        ContractCreateDetailStore.billingType = data.billingType;
        ContractCreateDetailStore.billingDay = data.billingDay;
        ContractCreateDetailStore.dueDateType = data.dueDateType;
        ContractCreateDetailStore.dueDateDay = data.dueDateDay;
        ContractCreateDetailStore.paymentType = data.paymentType;
        ContractCreateDetailStore.deposit = data.deposit;
        ContractCreateDetailStore.endDate = data.endDate;
        ContractCreateDetailStore.isReverseBill = data.isReverseBill;
        ContractCreateDetailStore.isFreeAssignment = data.isFreeAssignment;
        ContractCreateDetailStore.assignmentAmount = data.assignmentAmount;
        ContractCreateDetailStore.isMaintenance = data.isMaintenance;
        ContractCreateDetailStore.maintenanceCycleMonth = data.maintenanceCycleMonth;

        navigate('/contract/creation');
        AppStore.openPageHistory.push({ url: '/contract/creation', name: '계약등록' });
    }

    return(
        <>
            {/* 검색조건 */}
            <ContractListSearch isShort={isShort} setIsShort={setIsShort} getContractList={getContractList}/>
            <CContainer>
                {/* grid */}
                <AgGridContainer
                    gridTitle={'계약 목록'}
                    gridRef={gridRef}
                    height={58}
                    rowData={ContractListStore.contractList}
                    columnDefs={[
                        {field: "companyName", headerName: "회사", width: 150},
                        {field: "isFix", headerName: "확정여부", width: 110, valueFormatter: v => v.value === 1 ? '확정' : v.value === 0 ? '미확정' : '', cellClass: v => v.value !== 1 ? 'ag-grid-column-cancel' : 'ag-grid-column-complete'},
                        {
                            field: "contractNo", 
                            headerName: "계약번호", 
                            cellRenderer: (v) => v.value !== 'pinnedTopRowData' && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>{v.value}</span>
                                    <IconBtn iconClass="fi fi-rs-copy-alt" style={{ paddingLeft: 6 }} onClick={() => copyBtn(v.data.contractNo)} />
                                </div>
                            ), 
                            width: 180
                        },
                        {field: "releaseAskStatus", headerName: "출고의뢰상태", hide: true},
                        {field: "releaseAskStatusTitle", headerName: "출고의뢰상태", width: 130, cellClass: v => v.value === '출고취소' ? 'ag-grid-column-cancel' : '',},
                        {field: "contractSeq", headerName: "계약회차", width: 110},
                        {field: "contractType", headerName: "계약구분", width: 110, valueFormatter: v => findCommKrnm(v.value, '1')},
                        {field: "customerNo", headerName: "고객번호", hide: true},
                        {field: "siteName", headerName: "현장명", width: 160},
                        {field: "customerName", headerName: "고객명", width: 180},
                        {field: "businessNumber", headerName: "사업자번호", width: 140, hide: isShort},
                        {field: "rentMonth", headerName: "계약 월", width: 110},
                        {field: "rentDays", headerName: "계약 일", width: 110},
                        {field: "contractDate", headerName: "계약일", width: 120},
                        {field: "contractStartDate", headerName: "시작일", width: 120},
                        {field: "contractEndDate", headerName: "종료일", width: 120},
                        {
                            field: "monthRentPrice",
                            headerName: "월렌탈료",
                            valueFormatter: v => v.value?.toLocaleString(),
                            cellClass: 'ag-grid-money-align',
                            width: 130
                        },
                        {
                            field: "totalRentPrice",
                            headerName: "총렌탈료",
                            valueFormatter: v => v.value?.toLocaleString(),
                            cellClass: 'ag-grid-money-align',
                            width: 140
                        },
                        {
                            field: "productSalePrice",
                            headerName: "총판매가",
                            valueFormatter: v => v.value?.toLocaleString(),
                            cellClass: 'ag-grid-money-align',
                            width: 140
                        },
                        {
                            field: "assetSalePrice",
                            headerName: "총매각가",
                            valueFormatter: v => v.value?.toLocaleString(),
                            cellClass: 'ag-grid-money-align',
                            width: 140
                        },
                        {field: "contractProductCnt", headerName: "계약제품수량", width: 120, hide: isShort},
                        {field: "releaseQty", headerName: "출고수량", width: 120, hide: isShort},
                        {field: "releaseAskSeq", headerName: "출고의뢰번호", hide: true},
                        {field: "fixDate", headerName: "확정일", width: 180, hide: isShort},
                        {field: "managerName", headerName: "담당자", width: 100, hide: true},
                        {field: "saleUserSeq", headerName: "영업담당", width: 110, valueFormatter: v => getUserName(v.value)},
                        {field: "apronName", headerName: "주기장", width: 110},
                        {field: "shipWaitDays", headerName: "배송까지 대기일수", width: 160},
                        {field: "shipDate", headerName: "배송일자", width: 120},
                        {field: "preContractNo", headerName: "이전계약번호", width: 160},
                        {field: "isMaintenance", headerName: "정기유지보수", width: 140, valueFormatter: v => v.value ? v.data.maintenanceCycleMonth+'개월' : ''},
                        {field: "supportUserSeq", headerName: "지원담당", valueFormatter: v => getUserName(v.value), hide: true},
                        {field: "sellType", headerName: "매각구분", minWidth: 110,valueFormatter: v => findCommKrnm(v.value, '9'), hide: isShort},
                        {field: "sellDate", headerName: "매각일자", width: 120, hide: isShort},
                        {field: "updater", headerName: "수정자", valueFormatter: v => getUserName(v.value), hide: isShort},
                        {field: "updatedDate", headerName: "수정일시", width: 200, hide: isShort},
                        {field: "remark", headerName: "비고", width: 300},
                        {
                            field: "totalChargeAmount",
                            headerName: "총 청구금액", 
                            width: 180, 
                            valueFormatter: v => v.value?.toLocaleString(),
                            cellClass: 'ag-grid-money-align'
                        },
                        {
                            field: "notReceiveAmount", 
                            headerName: "미납금액", 
                            width: 150, 
                            valueFormatter: v => v.value?.toLocaleString(),
                            cellClass: 'ag-grid-money-align ag-grid-column-cancel'
                        },
                        {field: "lastBillPublishedCompany", headerName: "최근 계산서 발행 공급사", width: 200},
                        {field: "isPreSendBill", headerName: "계산서 선발행 여부", valueFormatter: v => v.value === 1 ? '선발행' : '', cellClass: 'ag-grid-column-complete', width: 150},
                    ]}
                    isCheckBox={true}
                    seqColumn={'contractNo'}
                    useCsvDownload={true}
                    pinnedTopRowData={pinnedTopRowData}
                    rowDoubleClickCallback={gotoContractDetails}
                    customBtnInfo={customButtonInfo}
                />
            </CContainer>
            <SendContractPopup sendContract={sendContract} type={'list'} />
        </>
    );
}

export default observer(ContractList);
