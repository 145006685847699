import { observer } from "mobx-react-lite";
import { useRef } from "react";
import AgGridContainer from "../../components/AgGridContainer";
import { BillingListStore } from "../../store/billing/BillingListStore";
import { findCommKrnm } from "../../utils/commcode";
import { dateFormat } from "../../utils/dateUtils";

const BillingHistoryList = () => {
    const historyGridRef = useRef();

    return(

        <AgGridContainer
            gridRef={historyGridRef}
            gridTitle={'청구 수정 내역'}
            rowBuffer={50}
            height={20}
            rowData={BillingListStore.billingHistoryList}
            columnDefs={[
                { field: "billingHistorySeq", headerName: "순번", hide: true },
                { field: "billingNo", headerName: "청구번호", hide: true },
                { field: "seq", headerName: "순번", valueGetter: 'node.rowIndex+1', width: 80},
                { field: "billingType", headerName: "청구구분", valueFormatter: (params) => findCommKrnm(params.value, '23'), width: 110},
                { field: "billingSeq", headerName: "청구회차", width: 110 },
                { field: "paymentType", headerName: "결제방식", valueFormatter: (params) => findCommKrnm(params.value, '17'), width: 120},
                { field: "assetNo", headerName: "자산번호", width: 110},
                { field: "contractNo", headerName: "계약번호", width: 160 },
                { field: "orgChargeDate", headerName: "원청구일자", width: 120 },
                { field: "chargeDate", headerName: "청구일자", width: 120 },
                { field: "dueDate", headerName: "납기일자", width: 120 },
                { field: "orgChargeAmount", headerName: "원청구금액", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 120 },
                { field: "chargeAmount", headerName: "청구금액", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 110 },
                { field: "chargeVat", headerName: "청구부가세", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 120 },
                { field: "chargeTotalAmount", headerName: "청구총액", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 110 },
                { field: "remark", headerName: "청구비고", width: 180 },
                { field: "isDel", headerName: "삭제여부", valueFormatter: v => v.value === 0 ? '' : '삭제', width: 110},
                { field: "creatorName", headerName: "등록자" },
                { field: "createdDate", headerName: "등록일자", valueFormatter: v => dateFormat(v.value), width: 120 },
                { field: "updaterName", headerName: "수정자" },
                { field: "updatedDate", headerName: "수정일자", valueFormatter: v => dateFormat(v.value), width: 120 },
            ]}
            getRowStyle={(params) => {
                if (params.data.isDel === 1) {
                    return { pointerEvents: 'none', color: '#ABABAB' };
                }
                return null;
            }}
        />
    );
}

export default observer(BillingHistoryList);
