import { Outlet } from "react-router-dom";

import GuestGuard from "../utils/route-guard/GuestGuard";
import ConfirmPage from "../views/ship/link/ConfirmPage";

const ShipConfirmRoutes = {
    path: '/',
    element: (
            <GuestGuard>
                <Outlet />
            </GuestGuard>
        ),
    children: [
        {path: '/ship/confirm/:shipSeq', element: <ConfirmPage />},
        {path: '/ship/confirm/:shipSeq/app', element: <ConfirmPage />},
    ]
}

export default ShipConfirmRoutes;
