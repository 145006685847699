import {useEffect, useState} from "react";
import { observer } from "mobx-react-lite";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import axios from "axios";
import { useLocation } from "react-router-dom";
import {CalendarGroup, CCol, CContainer, CInputDoubleGroup, CInputGroup, CSearchBtn, CSelectGroup, Subject} from "../../components/CustomContainer";
import { AppStore } from "../../store/AppStore";
import { getCommcode } from "../../utils/commcode";
import { StorageStore } from "../../store/storage/StorageStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import LUtils from "../../utils/lodashUtils";
import ProductListSearch from "../../components/searchModal/ProductListSearch";
import OrderSearch from "../../components/searchModal/OrderSearch";
import {StorageSearchStore} from "../../store/storage/StorageSearchStore";
import { getLocalStorage } from "../../utils";
import { USER_INFO } from "../../common/constants";

const StorageListSearch =({searchStorage})=> {
    //const [storageDelayDays, setStorageDelayDays] = useState('');
    const [userColumn, setUserColumn] = useState('');
    const [selectSearchUserType, setSelectSearchUserType] = useState('creator');
    const [apronList, setApronList] = useState([]);
    const location = useLocation();

    useEffect(() => {
        getApronList();
        StorageSearchStore.setSearchCondition(getLocalStorage(USER_INFO).apronSeq, 'apronSeq');

        const orderSeq = LUtils.get(location.state, 'orderSeq', null) ;
        if (orderSeq) {
            StorageSearchStore.setSearchCondition(orderSeq, 'orderSeq');
            searchStorage();
        }
    }, []);

    /* 주기장 목록 */
    const getApronList = async() => {
        const result = await axios.get("/user/apron");
        const resultList = result.map(v => {
            v.name = v.apronName;
            v.value = v.apronSeq;
            return v;
        })
        setApronList(resultList);
    }

    /* 입고구분 변경 */
    const changeStorageType = (v) => {
        StorageSearchStore.setSearchCondition(v.target.value, 'storageType');
        StorageStore.setStorageList([]);
    }

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        StorageSearchStore.setSearchCondition(customerInfo.customerName, 'customerName');
        StorageSearchStore.setSearchCondition(customerInfo.customerNo, 'customerNo');
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = (column) => {
        setUserColumn(column);
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo, column) => {
        if (!userInfo || !column) {
            return false;
        }

        StorageSearchStore.setSearchCondition(userInfo.name, `${column}Name`);
        StorageSearchStore.setSearchCondition(userInfo.userSeq, column === 'creator' ? column : `${column}Seq`);
        setUserColumn('');
    }

    /* 제품조회 팝업 콜백 */
    const callbackProductInfo = (info) => {
        StorageSearchStore.setSearchCondition(info.productCode, 'productCode');
    }

    /* 발주 콜백 함수 */
    const callbackOrder = (order) => {
        StorageSearchStore.setSearchCondition(order.orderSeq, 'orderSeq');
    }

    return(
        <>
            {/* 검색조건 */}
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CSelectGroup
                            label={'입고구분'}
                            placeholder={'입고구분'}
                            options={[{name: '전체', value: ''}].concat(getCommcode('13'))}
                            value={StorageSearchStore.searchCondition.storageType}
                            onChange={changeStorageType}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup
                            label={'시작일'}
                            asSingle={true}
                            value={{
                                startDate: StorageSearchStore.searchCondition.storageStartDate,
                                endDate: StorageSearchStore.searchCondition.storageStartDate
                            }}
                            onChange={v => StorageSearchStore.searchCondition.storageStartDate = v.startDate}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'종료일'}
                            value={{
                                startDate: StorageSearchStore.searchCondition.storageEndDate,
                                endDate: StorageSearchStore.searchCondition.storageEndDate
                            }}
                            onChange={v => StorageSearchStore.searchCondition.storageEndDate = v.endDate}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            selectOptions={[ {name: '입고자', value: 'creator'}, {name: '영업담당자', value: 'saleUser'} ]}
                            onSelect={v => {
                                LUtils.forEach(['creator', 'creatorName', 'saleUserSeq', 'saleUserName'], v => {
                                    StorageSearchStore.setSearchCondition('', v);
                                })
                                setSelectSearchUserType(v.target.value)
                            }}
                            selectValue={selectSearchUserType}
                            disabled={true}
                            onCallbackBtn={() => openSearchUserPopup(selectSearchUserType)}
                            value={StorageSearchStore.searchCondition[`${selectSearchUserType}Name`]}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'입고번호'}
                            labelId={'storageNo'}
                            value={StorageSearchStore.searchCondition.storageNo}
                            onChange={v => StorageSearchStore.setSearchCondition(v.target.value, 'storageNo')}
                            enterKeyHint={searchStorage}
                        />
                    </CCol>
                </Row>
                <Row>
                    {StorageSearchStore.searchCondition.storageType !== 'sto_goods' && 
                        <>
                            <CCol lg={2}>
                                <CInputGroup
                                    label={'자산번호'}
                                    labelId={'assetNo'}
                                    value={StorageSearchStore.searchCondition.assetNo}
                                    onChange={v => StorageSearchStore.setSearchCondition(v.target.value, 'assetNo')}
                                    enterKeyHint={searchStorage}
                                />
                            </CCol>
                            <CCol lg={2}>
                                <CInputGroup
                                    label={'관리번호'}
                                    labelId={'managementNo'}
                                    value={StorageSearchStore.searchCondition.managementNo}
                                    onChange={v => StorageSearchStore.setSearchCondition(v.target.value, 'managementNo')}
                                    enterKeyHint={searchStorage}
                                />
                            </CCol>
                        </>
                    }
                    <CCol lg={2}>
                        <CInputGroup
                            label={'계약번호'}
                            labelId={'contractNo'}
                            value={StorageSearchStore.searchCondition.contractNo}
                            onChange={v => StorageSearchStore.setSearchCondition(v.target.value, 'contractNo')}
                            enterKeyHint={searchStorage}
                            />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'제품코드'}
                            value={StorageSearchStore.searchCondition.productCode}
                            onCallbackBtn={() => AppStore.toggleProductListSearchModal()}
                            onChange={v => StorageSearchStore.setSearchCondition(v.target.value, 'productCode')}
                            enterKeyHint={searchStorage}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'시리얼번호'}
                            labelId={'serialNo'}
                            value={StorageSearchStore.searchCondition.serialNo}
                            onChange={v => StorageSearchStore.setSearchCondition(v.target.value, 'serialNo')}
                            enterKeyHint={searchStorage}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'입고지연일수'}
                            labelId={'storageDelayDays'}
                            value={StorageSearchStore.searchCondition.storageDelayDays}
                            onChange={(e)=> {
                                if(!isNaN(e.target.value)) StorageSearchStore.setSearchCondition(e.target.value, 'storageDelayDays');
                            }}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup
                            label={'입고비고'}
                            labelId={'storageRemark'}
                            value={StorageSearchStore.searchCondition.remark}
                            onChange={v => StorageSearchStore.setSearchCondition(v.target.value, 'remark')}
                            enterKeyHint={searchStorage}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup
                            label={'주기장'} labelId={'apron'}
                            options={[{ value: '', name: '전체' }].concat(apronList)}
                            value={StorageSearchStore.searchCondition.apronSeq || ''}
                            onChange={v => StorageSearchStore.setSearchCondition(v.target.value, 'apronSeq')} />
                    </CCol>
                </Row>

                {/* 상품입고 */}
                {
                    StorageSearchStore.searchCondition.storageType === 'sto_goods' &&
                    <>
                        <Subject>상품입고</Subject>
                        <Row>
                            <CCol lg={3}>
                                <CInputGroup
                                    label={'발주조회'}
                                    value={StorageSearchStore.searchCondition.orderSeq}
                                    onCallbackBtn={() => AppStore.toggleOrderSearchModal()}
                                    disabled={true}
                                    onEmptyBtn={() => StorageSearchStore.setSearchCondition('', 'orderSeq')}
                                />
                            </CCol>
                        </Row>
                    </>
                }

                {/* 반납입고 */}
                {
                    StorageSearchStore.searchCondition.storageType === 'sto_return' &&
                    <>
                        <Subject>반납입고</Subject>
                        <Row>
                            <CCol lg={2}>
                                <CInputGroup
                                    label={'자산번호'}
                                    labelId={'assetNo'}
                                    value={StorageSearchStore.searchCondition.assetNo}
                                    onChange={v => StorageSearchStore.setSearchCondition(v.target.value, 'assetNo')}
                                    enterKeyHint={searchStorage}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CInputDoubleGroup
                                    label={'고객명'}
                                    labelId={'customerNo'} labelId2={'customerName'}
                                    placeholder={'고객코드'} placeholder2={'고객명'}
                                    disabled={false} disabled2={true}
                                    onCallbackBtn={openSearchCustomerPopup}
                                    value={StorageSearchStore.searchCondition.customerNo}
                                    value2={StorageSearchStore.searchCondition.customerName}
                                    onChange={v => StorageSearchStore.setSearchCondition(v.target.value, 'customerNo')}
                                    enterKeyHint1={searchStorage}
                                />
                            </CCol>
                        </Row>
                    </>
                }

                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={() => searchStorage()} />
                    </Col>
                </Row>
            </CContainer>
            {/* modal */}
            <CustomerSearch callbackFn={getCustomerInfo}/>
            <UserSearch callbackFn={getUserInfo} column={userColumn}/>
            <ProductListSearch callbackFn={callbackProductInfo}/>
            <OrderSearch callbackFn={callbackOrder}/>
        </>
    );
}

export default observer(StorageListSearch);
