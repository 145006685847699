import dayjs from "dayjs";
import {addMonthDate, dateDiff, endDateForMonth} from "../../utils/dateUtils";

const contractUtils = {
    /* 계약종료일 계산 */
    contractEndDateFormat: (startDate, rentMonth=0, rentDays=0) => {
        return !!startDate
                ?
                    dayjs(startDate)
                        .add(rentMonth, 'month')
                        .add(rentDays - 1, 'day')
                        .format("YYYY-MM-DD")
                :
                    null
    },

    /* 계약 기간 월 = 숫자, 최대 두 자리 */
    handleMonthsChange: (value) => {
        return (!isNaN(value) && value.length <= 2) ? value : null;
    },

    /* 계약 기간 일 = 숫자, 최대 30 */
    handleDaysChange: (value) => {
        return (!isNaN(value) && value <= 30) ? value : null;
    },

    /* 계약일자, 계약개시일자 비교 */
    dateDiffContractDate: (contractDate, contractStartDate) => {
        return dateDiff(contractDate, contractStartDate) < 0 ? false : true;
    },

    /* 총렌탈료 계산 */
    calculateTotalPrice: ({ rentMonth, rentDays, monthRentPrice, dayRentPrice, lastMonthPrice, qty }) => {
        if (rentMonth && !rentDays && monthRentPrice && qty) {
            // Case 1: rentMonth에만 값이 있는 경우
            return rentMonth * Number(monthRentPrice) * qty;
        } else if (!rentMonth && rentDays && monthRentPrice && !dayRentPrice && qty) {
            // Case 2: rentDays, monthRentPrice에 값이 있는 경우
            return Number(monthRentPrice) * qty;
        } else if (!rentMonth && rentDays && !monthRentPrice && dayRentPrice && qty) {
            // Case 2: rentDays, monthRentPrice에 값이 있는 경우
            return (Number(rentDays) * Number(dayRentPrice)) * qty;
        } else if (!rentMonth && rentDays && (lastMonthPrice || dayRentPrice) && qty) {
            // Case 3: rentDays, lastMonthPrice 값이 있는 경우
            return  ((Number(lastMonthPrice) || 0) + (rentDays * (Number(dayRentPrice) || 0))) * qty;
        } else if (rentMonth && rentDays && monthRentPrice && qty) {
            // Case 4: rentMonth, rentDays 모두 값이 있는 경우
            const totalPrice = (lastMonthPrice || dayRentPrice)
                                ? (rentMonth * Number(monthRentPrice) + (Number(lastMonthPrice) || 0) + (rentDays * (Number(dayRentPrice) || 0))) * qty
                                : (rentMonth * Number(monthRentPrice)) * qty; // lastMonthPrice 값 없는 경우
            return totalPrice;
        } else if (!rentMonth && rentDays && dayRentPrice && qty) {
            // Case 5: rentDay, dayRentPrice만 값이 있는 경우
            return (Number(rentDays) * Number(dayRentPrice)) * qty;
        } else {
            return null;
        }
    },

    /* 총렌탈료 계산 */
    calculateTotalSalePrice: ({ productSalePrice, assetSalePrice, qty }) => {
        if (productSalePrice && qty) {
            //유통
            return Number(productSalePrice) * qty;
        } else if (assetSalePrice && qty) {
            // 매각
            return Number(assetSalePrice) * qty;
        } else {
            return null;
        }
    },

    /* 단축에 따른 단축월, 단축일 계산 */
    calcShortRentMonthAndDays: (originStartDate, finishDate)=> {
        let shortMonth = 0;
        let shortDays = 0;
        let addMonthStartDate = originStartDate;
        let plusMonthDate = originStartDate;

        for (let i=1; plusMonthDate <= finishDate; i++) {
            plusMonthDate = addMonthDate(originStartDate, i);

            if (plusMonthDate > finishDate) {
                shortDays = dateDiff(addMonthStartDate, finishDate) + 1;
                // 계약 기간이 1일~말일인 경우 1개월로 치환해줌
                if (shortDays === 31 || ((shortDays === 30 || shortDays === 29 || shortDays === 28) && endDateForMonth(finishDate) === finishDate)) {
                    shortMonth ++;
                    shortDays = 0; 
                }
                break;
            } else {
                shortMonth ++;
            }

            addMonthStartDate = addMonthDate(addMonthStartDate, 1);
        }

        return {rentMonth: shortMonth, rentDays: shortDays};
    }
}

export default contractUtils;
