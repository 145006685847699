import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { observer } from "mobx-react-lite";
import { useLocation, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";

import { AppStore } from "../../../store/AppStore";
import Modal from "../../../components/Modal";
import { decrypt, showToast } from "../../../common/utils";
import PreviewImage from "../../../components/preview/PreviewImage";
import { isMobile, isDetectMobile } from "../../../common/utils";
import {callConfirm} from "../../../utils";
import "./confirmPageStyle.css";
import ConfirmSignature from "./ConfirmSignature";
import SendMailPopup from "./SendMailPopup";
import { ConfirmStore } from "./ConfirmStore";
import { dateFormat, today } from "../../../utils/dateUtils";
import html2pdf from 'html2pdf.js';
import { MULTIPART_HEADER } from "../../../common/constants";

const isMobileDevice = isMobile();
const isDetectMobileDevice = isDetectMobile();

const ConfirmPage =(props)=> {
    const contentRef = useRef();
    const fileInputRef = useRef(null);
    const params = useParams();
    const location = useLocation();

    const [data, setData] = useState([]);
    const [emailList, setEmailList] = useState([]);
    const [imageList, setImageList] = useState([]);

    const isAppPath = location.pathname.endsWith('/app');

    useEffect(() => {
        getShipData();
    }, []);

    /* 데이터 조회 */
    const getShipData = async () => {
        const result = await axios.get('ship/confirm/info', { params: {shipSeq: params.shipSeq}});
        setData(result);
        ConfirmStore.deliveryDriver = result[0]?.division === '출고' ? result[0]?.deliveryDriver : result[0]?.receiveUserName;
        ConfirmStore.receiveUserName = result[0]?.division === '출고' ? result[0]?.receiveUserName : result[0]?.deliveryDriver;
        getImage();
    }

    /* 배송 사진 조회 */
    const getImage = async () => {
        const result = await axios.get(`ship/image/${params.shipSeq}`);
        setImageList(result);
    }

    const handleSignatureClick = () => {
        AppStore.toggleModal();
    };

    const reportSave = async () => {
        if(!await callConfirm('완료하시겠습니까?')) return;

        const result = await axios.put(`ship/confirm/info/${params.shipSeq}`);
        alert('저장되었습니다.'); 

        await getShipData();
    }

    const sendMail = async () => {
        // 메일 목록 담아주기
        const result = await axios.get('ship/confirm/email', { params: {shipSeq: params.shipSeq}});
        setEmailList(result);
        
        AppStore.toggleSendMailPopup();
    }

    /* 메일 발송 버튼 */
    const sendBtn = async () => {
        if(ConfirmStore.receiverEmail === null || ConfirmStore.receiverEmail === '') return alert('받는 사람 이메일을 입력해주세요.');
        if(!await callConfirm('메일을 전송 하시겠습니까?')) return;   
        
        showLoadingScreen();
        
        const productListRows = data.map((v, index) => {
            return (
                `<tr key=${index}>
                    <td class="p-1 border">${index + 1}</td>
                    <td class="p-1 border">${v.productName}</td>
                    <td class="p-1 border">${v.model}</td>
                    <td class="p-1 border">${data[0]?.contractType !== '3' ? v.managementNo||'' : v.serialNo||''}</td>
                    <td class="p-1 border">${dateFormat(v.contractStartDate)}</td>
                    <td class="p-1 border">${dateFormat(v.contractEndDate)}</td>
                </tr>`
            );
        });

        const text =    `<tr>
                            <td class="p-1 border" colspan="6">
                                1) 계약기간 종료전에 렌탈물건을 반납할 경우 기준렌탈료에 의한 금액으로 재산정한 정산렌탈료를 귀사에 납입한다. <br/>
                                2) 렌탈기간 만료후에도 렌탈물건을 반납하지 않을 경우 반환완료일까지 기준렌탈료로 산정한 손해금을 귀사에 납입한다. <br/>
                                3) 렌탈물건 자체 이상의 경우 렌탈회사에서 수리 또는 교환 책임을 부담하되, 사용 보관의 부주의로 인한 렌탈물건의고장 또는 훼손(멸실 포함) 등의 사유 발생시에는 수리비용 또는 대체물건의 구입비용을 손해배상금으로 귀사에 납입한다
                            </td>
                        </tr>`;

        const htmlDocument = `
            <div class="print-template">
                <h2 style="text-align: center; margin-bottom: 20px;">인수인도 확인서</h2>
                <div style="display: flex;"><i class="fi fi-br-apps" style="margin-right: 5px; padding-top: 5px;"></i><h5>${data[0]?.contractType === '3' ? '공급자 / 구매자' : '렌탈사 / 이용자'}</h5></div>
                <table class="table" style="border-width: 1px;">
                    <thead>
                        <tr>
                            <th class="border" style="width: 50%; text-align: center; background-color: #E7E7E7;" colspan="2">${data[0]?.contractType === '3' ? '공급자' : '렌탈사'}</th>
                            <th class="border" style="width: 50%; text-align: center; background-color: #E7E7E7;" colspan="2">${data[0]?.contractType === '3' ? '구매자' : '이용자'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="p-1 border" style="width: 50%; text-align: center;" colspan="2">${data[0]?.companyName}</td>
                            <td class="p-1 border" style="width: 50%; text-align: center;" colspan="2">${data[0]?.customerName}</td>
                        </tr>
                        <tr>
                            <td class="p-1 border" style="width: 10%; text-align: center;">담당자</td>
                            <td class="p-1 border" style="width: 30%;"><span style="margin-left: 5px;">${data[0]?.saleUserName}</span></td>
                            <td class="p-1 border" style="text-align: center;">주소</td>
                            <td class="p-1 border" style="margin-left: 10px;"><span style="margin-left: 5px;">${data[0]?.postCode + ' ' + data[0]?.address + ' ' + data[0]?.addressDetail}</span></td>
                        </tr>
                        <tr>
                            <td class="p-1 border" style="text-align: center;">연락처</td>
                            <td class="p-1 border" style="margin-left: 10px;"><span style="margin-left: 5px;">${decrypt(data[0]?.phoneNumber)}</span></td>
                            <td class="p-1 border" style="text-align: center;">인수자</td>
                            <td class="p-1 border" style="margin-left: 10px;"><span style="margin-left: 5px;">${data[0]?.receiveUserName} ${data[0]?.receiveUserDept ? '/ ' + data[0]?.receiveUserDept : ''} / ${decrypt(data[0]?.receiveUserPhoneNumber)}</span></td>
                        </tr>
                    </tbody>
                </table>
                <div class="mb-3"><b>◼︎ 계약번호 ${data[0]?.contractNo}</b></div>
                <div class="mb-3">하기 인수인이 서명한 본 인수인도확인서에 의하여 실질적인 ${data[0]?.contractType === '3' ? '유통' : '렌탈'}계약이 성립하였음을 확인합니다.</div>
                <br/><br/>
                <div style="display: flex;"><i class="fi fi-br-apps" style="margin-right: 5px; padding-top: 5px;"></i><h5>${data[0]?.contractType === '3' ? '유통' : '렌탈'}품목 세부현황</h5></div>
                <table class="table" style="border-width: 1px; text-align: center;">
                    <thead>
                        <tr>
                            <th class="border" style="width: 5%; background-color: #E7E7E7;">순번</th>
                            <th class="border" style="width: 20%; background-color: #E7E7E7;">제품명</th>
                            <th class="border" style="width: 20%; background-color: #E7E7E7;">모델명</th>
                            <th class="border" style="width: 14%; background-color: #E7E7E7;">${data[0]?.contractType !== '3' ? '관리번호' : '시리얼번호'}</th>
                            <th class="border" style="width: 12%; background-color: #E7E7E7;">개시일</th>
                            <th class="border" style="width: 12%; background-color: #E7E7E7;">종료일</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${productListRows.join('')}
                    </tbody>
                </table>
                <br/>
                <table class="table" style="border-width: 1px;">
                    <tbody>
                        <tr>
                            <td class="p-1 border" style="width: 15%; text-align: center; background-color: #E7E7E7;">설치장소</td>
                            <td class="p-1 border" colspan="5">${data[0]?.installPlace}</td>
                        </tr>
                        ${data[0]?.contractType !== '3' &&
                            text
                        }
                        <tr style="height: 80px;">
                            <td class="p-1 border" style="width: 15%; vertical-align: middle; text-align: center; background-color: #E7E7E7;">인도인</td>
                            <td class="p-1 border" style="width: 35%; vertical-align: middle;" colspan="2"><span style="padding-left: 5px;">${ConfirmStore.deliveryDriver}</span></td>
                            <td class="p-1 border" style="width: 15%; vertical-align: middle; text-align: center; background-color: #E7E7E7;">인수인</td>
                            <td class="p-1 border" style="width: 35%; vertical-align: middle;" colspan="2">
                                <div style="display: flex; justify-content: space-between; align-items: center;">
                                    <span style="width: 80px; padding-left: 5px;">${ConfirmStore.receiveUserName}</span>
                                        <div style="position: relative; margin-left: auto;">
                                            <img src="data:image/jpeg;base64,${data[0]?.signatureBase64Url}" alt="signature" style="max-width: 100px; max-height: 100px;" />
                                            <span style="position: absolute; bottom: 0; right: 0; background-color: 0; padding: 2px 4px; display: flex; justify-content: center; align-items: center; height: 100%;">(서명)</span>
                                        </div>
                                
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <p>완료일시 : ${data[0]?.completedDate}</p>
                
            </div>
        `;

        const fileName = `${today()}_인수인도확인서.pdf`
        const options = {
            filename: fileName,
            image: {type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        }

        const body = document.querySelector('body');
        body.style.lineHeight = '0.3';

        const pdfBlob = await html2pdf().set(options).from(htmlDocument).toPdf().output('blob').then(data => {
            return data
        });
        await deleteStyle(body);

        const subject = `${data[0]?.companyName} 렌탈 장비 인수/인도 확인서`;
        const content = `안녕하세요. ${data[0]?.companyName}입니다.\n\n인도자 ${ConfirmStore.deliveryDriver}님이 인수자 ${ConfirmStore.receiveUserName} 님에게\n\n렌탈 장비를 인도 하였습니다.\n\n계약번호 : ${data[0]?.contractNo}\n인도일시 : ${data[0]?.completedDate}`;

        const blob = new Blob([pdfBlob], { type: 'application/pdf' });
        // FormData를 사용하여 파일을 생성
        const formData = new FormData();
        formData.append('file', new Blob([blob]), fileName);
        formData.append('companyCode', data[0]?.companyCode);
        formData.append('receiverEmail', ConfirmStore.receiverEmail);
        formData.append('subject', subject);
        formData.append('content', content);

        await axios.put('ship/confirm/email', formData, { headers: MULTIPART_HEADER });
        alert('메일을 발송 했습니다.');
        hideLoadingScreen();
        AppStore.toggleSendMailPopup();
    }

    const deleteStyle = async (body) => {
        body.style.lineHeight = 'normal';
    }

    /* show loding screen */
    const showLoadingScreen = () => {
        document.querySelector('.overlay').style.display = 'flex';
    }

    /* hide loding screen */
    const hideLoadingScreen = () => {
        document.querySelector('.overlay').style.display = 'none';
    }

    const openCamera = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        resizeImage(file, 1080, (resizedFile) => {
            uploadImage(resizedFile); // 해상도 조정된 이미지 업로드
        });
    };

    const resizeImage = (file, maxSize, callback) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
        
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
        
                let width = img.width;
                let height = img.height;
        
                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width;
                        width = maxSize;
                    }
                } else {
                    if (height > maxSize) {
                        width *= maxSize / height;
                        height = maxSize;
                    }
                }
        
                // 캔버스에 그려서 크기를 변경
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);
        
                // 캔버스에서 새로운 이미지 파일로 변환
                canvas.toBlob((blob) => {
                const resizedFile = new File([blob], file.name, {
                    type: file.type,
                });
                callback(resizedFile);
                }, file.type);
            };
        };
        reader.readAsDataURL(file);
      };

    const uploadImage = async (file) => {
        const blob = new Blob([file], { type: file.type });
        const formData = new FormData();
        formData.append('file', new Blob([blob]), file.name);
        formData.append('shipSeq', params.shipSeq);

        await axios.post('/ship/image', formData, {headers: MULTIPART_HEADER});
        showToast('저장했습니다.');
        getImage();

    };

    return (
        <>
            <div className="overlay" style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'none', justifyContent: 'center', alignItems: 'center', zIndex: '9999'}}>
                <div style={{color: 'white', fontSize: '24px', fontWeight: 'bold'}}>메일 발송 중 입니다.</div>
            </div>
            <div ref={contentRef} className="print-template">
                <h2 style={{textAlign: 'center', marginBottom: 20}}>인수인도 확인서</h2>
                <div style={{display: 'flex'}}><i className="fi fi-br-apps" style={{marginRight: 5, paddingTop: 5}}/><h5>{data[0]?.contractType === '3' ? '공급자 / 구매자' : '렌탈사 / 이용자'}</h5></div>
                {isDetectMobileDevice ? (
                <table className="table" style={{borderWidth: 1}}>
                    <thead>
                        <tr>
                            <th className='border' style={{width: '50%', textAlign: 'center', backgroundColor: '#E7E7E7'}} colSpan={2}>{data[0]?.contractType === '3' ? '공급자' : '렌탈사'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='p-1 border' style={{textAlign: 'center'}} colSpan={2}>{data[0]?.companyName}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{width: '10%', textAlign: 'center'}}>담당자</td>
                            <td className='p-1 border' style={{width: '30%'}}><span style={{marginLeft: 5}}>{data[0]?.saleUserName}</span></td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{textAlign: 'center'}}>연락처</td>
                            <td className='p-1 border' style={{marginLeft: 10}}><span style={{marginLeft: 5}}>{decrypt(data[0]?.phoneNumber)}</span></td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th className='border' style={{width: '50%', textAlign: 'center', backgroundColor: '#E7E7E7'}} colSpan={2}>{data[0]?.contractType === '3' ? '구매자' : '이용자'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='p-1 border' style={{textAlign: 'center'}} colSpan={2}>{data[0]?.customerName}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{textAlign: 'center'}}>주소</td>
                            <td className='p-1 border' style={{marginLeft: 10}}><span style={{marginLeft: 5}}>{data[0]?.postCode + ' ' + data[0]?.address + ' '+ data[0]?.addressDetail}</span></td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{textAlign: 'center'}}>인수자</td>
                            <td className='p-1 border' style={{marginLeft: 10}}><span style={{marginLeft: 5}}>{`${data[0]?.receiveUserName} ${data[0]?.receiveUserDept ? '/ ' + data[0]?.receiveUserDept : '' } / ${decrypt(data[0]?.receiveUserPhoneNumber)}`}</span></td>
                        </tr>
                    </tbody>
                </table>
                ) : (
                <table className="table" style={{borderWidth: 1}}>
                    <thead>
                    <tr>
                        <th className='border' style={{width: '50%', textAlign: 'center', backgroundColor: '#E7E7E7'}} colSpan={2}>{data[0]?.contractType === '3' ? '공급자' : '렌탈사'}</th>
                        <th className='border' style={{width: '50%', textAlign: 'center', backgroundColor: '#E7E7E7'}} colSpan={2}>{data[0]?.contractType === '3' ? '구매자' : '이용자'}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className='p-1 border' style={{width: '50%', textAlign: 'center'}} colSpan={2}>{data[0]?.companyName}</td>
                        <td className='p-1 border' style={{width: '50%', textAlign: 'center'}} colSpan={2}>{data[0]?.customerName}</td>
                    </tr>
                    <tr>
                        <td className='p-1 border' style={{width: '10%', textAlign: 'center'}}>담당자</td>
                        <td className='p-1 border' style={{width: '30%'}}><span style={{marginLeft: 5}}>{data[0]?.saleUserName}</span></td>
                        <td className='p-1 border' style={{textAlign: 'center'}}>주소</td>
                        <td className='p-1 border' style={{marginLeft: 10}}><span style={{marginLeft: 5}}>{data[0]?.postCode + ' ' + data[0]?.address + ' '+ data[0]?.addressDetail}</span></td>
                    </tr>
                    <tr>
                        <td className='p-1 border' style={{textAlign: 'center'}}>연락처</td>
                        <td className='p-1 border' style={{marginLeft: 10}}><span style={{marginLeft: 5}}>{decrypt(data[0]?.phoneNumber)}</span></td>
                        <td className='p-1 border' style={{textAlign: 'center'}}>인수자</td>
                        <td className='p-1 border' style={{marginLeft: 10}}><span style={{marginLeft: 5}}>{`${data[0]?.receiveUserName} ${data[0]?.receiveUserDept ? '/ ' + data[0]?.receiveUserDept : '' } / ${decrypt(data[0]?.receiveUserPhoneNumber)}`}</span></td>
                    </tr>
                    </tbody>
                </table>)}
                <div className="mb-2"><b>◼︎ 계약번호 {data[0]?.contractNo}</b></div>
                <div>하기 인수인이 서명한 본 인수인도확인서에 의하여 실질적인 {data[0]?.contractType === '3' ? '유통' : '렌탈'}계약이 성립하였음을 확인합니다.</div>
                <br/>
                <br/>

                <div style={{display: 'flex'}}><i className="fi fi-br-apps" style={{marginRight: 5, paddingTop: 5}}/><h5>{data[0]?.contractType === '3' ? '유통' : '렌탈'}품목 세부현황</h5></div>
                {isDetectMobileDevice ? (
                     <table className="table" style={{borderWidth: 1, textAlign: 'center', verticalAlign: 'middle'}}>
                        <tbody>
                            {data.map((v, index) => (
                                <React.Fragment key={index}>
                                    <tr style={{borderTop: index === 0 ? '2px solid #000' : 'none'}}>
                                        <th className='border' style={{ width: '5%', backgroundColor: '#E7E7E7' }}>순번</th>
                                        <td className='p-1 border'>{index + 1}</td>
                                        <th className='border' style={{ width: '20%', backgroundColor: '#E7E7E7' }}>제품명</th>
                                        <td className='p-1 border'>{v.productName}</td>
                                    </tr>
                                    <tr>
                                        <th className='border' style={{ width: '20%', backgroundColor: '#E7E7E7' }}>모델명</th>
                                        <td className='p-1 border'>{v.model}</td>
                                        <th className='border' style={{ width: '15%', backgroundColor: '#E7E7E7' }}>{data[0]?.contractType !== '3' ? '관리번호' : '시리얼번호'}</th>
                                        <td className='p-1 border'>{data[0]?.contractType !== '3' ? v.managementNo : v.serialNo}</td>
                                    </tr>
                                    <tr style={{borderBottom: '2px solid #000'}}>
                                        <th className='border' style={{ width: '12%', backgroundColor: '#E7E7E7' }}>개시일</th>
                                        <td className='p-1 border'>{v.contractStartDate}</td>
                                        <th className='border' style={{ width: '12%', backgroundColor: '#E7E7E7' }}>종료일</th>
                                        <td className='p-1 border'>{v.contractEndDate}</td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <table className="table" style={{borderWidth: 1, textAlign: 'center'}}>
                        <thead>
                            <tr>
                                <th className='border' style={{width: '5%', backgroundColor: '#E7E7E7'}}>순번</th>
                                <th className='border' style={{width: '20%', backgroundColor: '#E7E7E7'}}>제품명</th>
                                <th className='border' style={{width: '20%', backgroundColor: '#E7E7E7'}}>모델명</th>
                                <th className='border' style={{width: '14%', backgroundColor: '#E7E7E7'}}>{data[0]?.contractType !== '3' ? '관리번호' : '시리얼번호'}</th>
                                <th className='border' style={{width: '12%', backgroundColor: '#E7E7E7'}}>개시일</th>
                                <th className='border' style={{width: '12%', backgroundColor: '#E7E7E7'}}>종료일</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((v, index) => (
                                <tr key={index}>
                                    <td className='p-1 border'>{index+1}</td>
                                    <td className='p-1 border'>{v.productName}</td>
                                    <td className='p-1 border'>{v.model}</td>
                                    <td className='p-1 border'>{data[0]?.contractType !== '3' ? v.managementNo : v.serialNo}</td>
                                    <td className='p-1 border'>{v.contractStartDate}</td>
                                    <td className='p-1 border'>{v.contractEndDate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                <br/>

                <table className="table" style={{borderWidth: 1}}>
                    <tbody>
                    <tr>
                        <td className='p-1 border' style={{width: '25%', textAlign: 'center', backgroundColor: '#E7E7E7'}} colSpan={2}>설치장소</td>
                        <td className='p-1 border' colSpan={5}>{data[0]?.installPlace}</td>
                    </tr>
                    {data[0]?.contractType !== '3' &&
                        <tr>
                            <td className='p-1 border' colSpan={7}>
                                1) 계약기간 종료전에 렌탈물건을 반납할 경우 기준렌탈료에 의한 금액으로 재산정한 정산렌탈료를 귀사에 납입한다. <br/>
                                2) 렌탈기간 만료후에도 렌탈물건을 반납하지 않을 경우 반환완료일까지 기준렌탈료로 산정한 손해금을 귀사에납입한다. <br/>
                                3) 렌탈물건 자체 이상의 경우 렌탈회사에서 수리 또는 교환 책임을 부담하되, 사용 보관의 부주의로 인한 렌탈물건의고장 또는 훼손(멸실 포함) 등의 사유 발생시에는 수리비용 또는 대체물건의 구입비용을 손해배상금으로 귀사에 납입한다
                            </td>
                        </tr>
                    }
                    {isDetectMobileDevice ? (
                        <>
                            <tr >
                                <td className='p-1 border' style={{width: '15%', verticalAlign: 'middle', textAlign: 'center', backgroundColor: '#E7E7E7'}}>인도인</td>
                                <td className='p-1 border' style={{width: '35%', verticalAlign: 'middle'}} colSpan={3}><span style={{paddingLeft: 5}}>{ConfirmStore.deliveryDriver}</span></td>
                            </tr>    
                            <tr>    
                                <td className='p-1 border' style={{width: '15%', verticalAlign: 'middle', textAlign: 'center', backgroundColor: '#E7E7E7'}}>인수인</td>
                                <td className='p-1 border' style={{width: '35%', verticalAlign: 'middle'}} colSpan={3}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span style={{width: 80, paddingLeft: 5}}>{ConfirmStore.receiveUserName}</span>
                                        {data[0]?.signatureUrl ? (
                                            <div style={{ position: 'relative', marginLeft: 'auto' }}>
                                                <PreviewImage imageUrl={data[0]?.signatureUrl} />
                                                <span style={{ position: 'absolute', bottom: 0, right: 0, backgroundColor: 0, padding: '2px 4px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>(서명)</span>
                                            </div>
                                        ) : (
                                            <div style={{ position: 'relative', marginLeft: 'auto' }}>
                                                <Button className="signatureBtn" style={{ marginLeft: 'auto' }} onClick={handleSignatureClick}>서명</Button>
                                                <span style={{ marginLeft: 'auto' }}>(서명)</span>
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        </>
                    ) : (
                        <>
                            <tr >
                                <td className='p-1 border' style={{width: '15%', verticalAlign: 'middle', textAlign: 'center', backgroundColor: '#E7E7E7'}}>인도인</td>
                                <td className='p-1 border' style={{width: '35%', verticalAlign: 'middle'}} colSpan={2}><span style={{paddingLeft: 5}}>{ConfirmStore.deliveryDriver}</span></td>  
                                <td className='p-1 border' style={{width: '15%', verticalAlign: 'middle', textAlign: 'center', backgroundColor: '#E7E7E7'}}>인수인</td>
                                <td className='p-1 border' style={{width: '35%', verticalAlign: 'middle'}} colSpan={2}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span style={{width: 80, paddingLeft: 5}}>{ConfirmStore.receiveUserName}</span>
                                        {data[0]?.signatureUrl ? (
                                            <div style={{ position: 'relative', marginLeft: 'auto' }}>
                                                <PreviewImage imageUrl={data[0]?.signatureUrl} />
                                                <span style={{ position: 'absolute', bottom: 0, right: 0, backgroundColor: 0, padding: '2px 4px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>(서명)</span>
                                            </div>
                                        ) : (
                                            <div style={{ position: 'relative', marginLeft: 'auto' }}>
                                                <Button className="signatureBtn" style={{ marginLeft: 'auto' }} onClick={handleSignatureClick}>서명</Button>
                                                <span style={{ marginLeft: 'auto' }}>(서명)</span>
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        </>
                    )}
                    </tbody>
                </table>
                <br/>
                {data[0]?.completedDate &&
                    <p>완료일시 : {data[0]?.completedDate}</p>
                }

                {!isAppPath && imageList.length > 0 &&
                    <>
                        <div className="header">
                            <i className="fi fi-br-apps" />
                            <h5>배송 사진</h5>
                        </div>
                        <div className="image-container">
                            {imageList.map((imageUrl, index) => (
                                <div className="image-wrapper" key={index} onClick={() => window.open(imageUrl, '_blank')}>
                                    <img src={imageUrl} alt={`배송 사진 ${index + 1}`} className="image" />
                                </div>
                            ))}
                        </div>
                    </>
                }
                
                {isDetectMobileDevice ? (
                    <>
                        <Button variant={'primary'} style={!data[0]?.deliverUserSeq ? ({width: '100%', marginBottom: 10}) : ({width: '100%', marginBottom: 30})}  onClick={sendMail}>메일전송</Button>
                        {data[0]?.shipStatus !== '5' && 
                                <Button variant={'success'} style={{width: '100%',marginBottom: 30}} disabled={data[0]?.deliverUserSeq} onClick={() => reportSave()}>완료</Button>
                        }
                        {!isAppPath && data[0]?.shipStatus === '5' && 
                            <>
                                <Button variant={'success'} style={{width: '100%',marginBottom: 30}} onClick={openCamera}>사진촬영</Button>
                                <input
                                    type="file"
                                    accept="image/*"
                                    capture="camera"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                            </>
                        }
                    </>
                ) : (
                    <>
                        <br/>
                       
                            {data[0]?.shipStatus !== '5' ? (
                                <div style={{marginBottom: 30, display: 'flex', justifyContent: 'space-between'}} className="button-group">
                                    <Button variant={'primary'} style={{flex: 1, marginRight: 5}}  onClick={sendMail}>메일전송</Button>
                                    <Button variant={'success'} style={{flex: 1, marginLeft: 5}} disabled={data[0]?.deliverUserSeq} onClick={() => reportSave()}>완료</Button>
                                </div>
                            ) : (
                                <div style={{marginBottom: 30, display: 'flex', justifyContent: 'space-between'}} className="button-group">
                                    <Button variant={'primary'} style={{flex: 1, marginRight: 5, marginBottom: 30}} onClick={sendMail}>메일전송</Button>
                                    {!isAppPath && 
                                        <>
                                            <Button variant={'success'} style={{flex: 1, marginRight: 5, marginBottom: 30}} onClick={openCamera}>사진촬영</Button>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                capture="camera"
                                                ref={fileInputRef}
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                            />
                                        </>
                                    }
                                </div>
                            )}
                        
                    </>
                )}
            </div>

            <Modal title={'서명'} size={isMobileDevice ? 'xl' : 'lg'} >
                <ConfirmSignature shipSeq={params.shipSeq} getShipData={getShipData} isDetectMobileDevice={isDetectMobileDevice} />
            </Modal>

            <SendMailPopup emailList={emailList} isDetectMobileDevice={isDetectMobileDevice} sendBtn={sendBtn} />
        </>
    )
}

export default observer(ConfirmPage);
