import { observable } from "mobx";

export const CustomerLedgerStore = observable({
    customerLedgerList: [],
    search: {
        startDate: '',
        endDate: '',
        customerNo:'',
        customerName:'',
        accountCode: '',
        accountName: ''
    },
    detailList: [],
    detailSearch: {
        startDate: '',
        endDate: '',
        customerNo:'',
        customerName:'',
        accountCode: '',
        accountName: ''
    },
    openDetail: false,
    statementNo: '',
    initDetail() {
        this.detailSearch.startDate = '';
        this.detailSearch.endDate = '';
        this.detailSearch.customerNo = '';
        this.detailSearch.customerName = '';
        this.detailSearch.accountCode = '';
        this.detailSearch.accountName = '';

        this.detailList = [];
        this.openDetail = false;
    },
    initStatementNo() {
        this.statementNo = '';
    }
})
