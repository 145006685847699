import { observable } from "mobx";

export const StockStore = observable({
    lgCode: '',
    mdCode: '',
    smCode: '',
    productGroupList: '',
    selectedLGCode: '',
    selectedMDCode: '',
    selectedSMCode: '',
    brandCode: '',
    productSeq: '',
    productName: '',
    model: '',
    spec: '',
    isAvailableStorage: true,
    startDate: '',
    endDate: '',
    apronSeq: null,
    stockList: [],

    /* action */
    setLGCode(v){
        this.lgCode = v;
    },
    setMDCode(v){
        this.mdCode = v;
    },
    setSMCode(v){
        this.smCode = v;
    },
    setProductGroupList(v){
        this.productGroupList = v;
    },
    setStockList(v) {
        this.stockList = v;
    },
    setSelectedLgCode(v){
        this.selectedLGCode = v;
    },
    setSelectedMDCode(v){
        this.selectedMDCode = v;
    },
    setSelectedSMCode(v){
        this.selectedSMCode = v;
    },
    setBrandCode(v){
        this.brandCode = v;
    },
    setProductSeq(v){
        this.productSeq = v;
    },
    setProductName(v){
        this.productName = v;
    },
    setModel(v){
        this.model = v;
    },
    setSpec(v){
        this.spec = v;
    },
    setIsAvailableStorage(v){
        this.isAvailableStorage = v;
    },
    setStartDate(v){
        this.startDate = v;
    },
    setEndDate(v){
        this.endDate = v;
    },
    setApronSeq(v){
        this.apronSeq = v;
    }
});
