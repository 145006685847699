import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

const goodsColumn = { storageType: '',  orderSeq: '', orderType: '', orderTypeName: '', remark: '' };

export const StorageStore = observable({
    /* list */
    storageList: [],
    searchCondition: {storageType: '', storageStartDate: '', storageEndDate:'', assetNo: '', contractNo: '', storageSeq: '', productCode: '',
                        customerNo: '', customerName: '', remark: '', creator: '', creatorName: '', saleUserSeq:'',
                        saleUserName: '', model: '', orderSeq: '', serialNo: '', storageDelayDays: '' },


    /* register storage */
    storageType: 'sto_return',
    goodsInfo: goodsColumn,
    returnStorageList: [],
    goodsList:[],

    //입고 리더기 사용시 사용
    checkCountSec: 0,

    /* action */
    setStorageList(v) {
        this.storageList = v;
    },
    setSearchCondition(v, k) {
        this.searchCondition[k] = v === 0 ? 0 : v || '';
    },
    setStorageType(v) {
        this.storageType = v;
    },
    setGoodsInfo(v, k) {
        this.goodsInfo[k] = v;
    },
    setReturnStorageList(v) {
        this.returnStorageList = v;
    },
    pushReturnStorageList(v){
        this.returnStorageList.push(v);
    },
    setGoodsList(v) {
        this.goodsList = v;
    },
    setCheckCountSec(v){
        this.checkCountSec = v;
    },

    /* unmount */
    initGoodsInfo() {
        this.goodsInfo = goodsColumn;
        this.goodsList = [];
    },
});
