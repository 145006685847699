import { observable } from "mobx";

export const ConsumableStore = observable({
    isMaintenanceDetail: false,
    search: {
        productName: '',
        serialNo: '',
    },
    init() {
        this.isMaintenanceDetail = false;
        this.search.productName = '';
        this.search.serialNo = '';
    }
});
