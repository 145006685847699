import { getCommcode } from '../utils/commcode'

/* 공통코드 중분류 options */
const CommcodeMDOptions = () => {
    return (
        <>
            {getCommcode('0', true).map((v, i) => (<option key={i} value={v.commCode}>{v.commKrnm}</option>))}
        </>
    )
}
/* 공통코드 소분류 options */
const CommcodeSMOptions = ({commKind}) => {
    return (
        <>
            {getCommcode(commKind, true).map((v, i) => (<option key={i} value={v.commCode}>{v.commKrnm}</option>))}
        </>
    )
}

export {
    CommcodeMDOptions,
    CommcodeSMOptions,
}