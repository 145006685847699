import { useState, useEffect } from "react";
import axios from "axios";
import { Row, InputGroup, Col, Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { AppStore } from "../../store/AppStore";
import { CContainer, CCol, CSearchBtn, CInputGroup } from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";
import Modal from "../Modal";
import { ApronSearchStore } from "../../store/storage/ApronSearchStore";

const ApronSearch = ({callbackFn=null, ...props}) => {
    const [apronList, setApronList] = useState([]);

    const onSearchApronInfo = async () => {
        const result = await axios.get('/storage/apron/list', {params : {apronName: ApronSearchStore.search.apronName}});
        setApronList(result);
    }

    const selectedRow = (e) => {
        callbackFn(e.data);
        AppStore.toggleApronSearchPopup();
    }

    const refresh = () => {
        ApronSearchStore.search.apronName = '';
    }

    return (

        <>
            <Modal
                title={'주기장 조회'}
                onHide={() => AppStore.toggleApronSearchPopup()}
                toggleValue={AppStore.isApronSearchPopup}
                size={'lg'}
                onExit={refresh}
            >
                <CContainer search>
                    <Row>
                        <CCol lg={4}>
                            <CInputGroup
                                label={'주기장명'}
                                value={ApronSearchStore.search.apronName}
                                onChange={v => ApronSearchStore.search.apronName = v.target.value}
                                enterKeyHint={onSearchApronInfo}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <Col className='d-flex flex-row-reverse'>
                            <CSearchBtn onClick={onSearchApronInfo} style={{width:100}} />
                        </Col>
                    </Row>
                </CContainer>
                <CContainer>
                    <AgGridContainer
                        height={26}
                        rowData={apronList}
                        columnDefs={[
                                {field: "apronSeq", headerName: "주기장순번", hide: true},
                                {field: "apronName", headerName: "주기장명", width: 180},
                                {field: "postcode", headerName: "우편번호", width: 200},
                                {field: "address", headerName: "주소", width: 200},
                                {field: "addressDetail", headerName: "상세주소", width: 240, flex: 1},
                            ]}
                        enableCellTextSelection={true}
                        rowDoubleClickCallback={selectedRow}
                    />
                </CContainer>
            </Modal>
        </>

    )
}

export default observer(ApronSearch);