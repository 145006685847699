import { observable } from "mobx";

export const CardManagementStore = observable({
    cardList: [],
    search: {
        cardCode: '',
        cardNumber: '',
        cardAlias: '',
        isUsed: 1,
    },
    data: {
        cardManagementSeq: '',
        cardNumber: '',
        cardAlias: '',
        cardCode: '',
        loginId: '',
        loginPassword: '',
        useLimit: null,
        isUsed: 1,
        remark: '',
    },

    dataInit(){
        this.data.cardManagementSeq = '';
        this.data.cardNumber = '';
        this.data.cardAlias = '';
        this.data.cardCode = '';
        this.data.loginId = '';
        this.data.loginPassword = '';
        this.data.useLimit = null;
        this.data.isUsed = 1;
        this.data.remark = '';
    },
})
