import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const CustomerStore = observable({
    customerNo: '',

    customerList: [],
    searchCondition: { customerNo:'', customerType: '', isAvailableContract:'', customerName: '', owner: '', businessNumber:'', corporationNumber:'',
                        customerDiv: '', managerName: '', managerPhoneNumber: '', isClosedCustomer: true},
    fileName: '',

    /* tabs */
    billingAddressList: [],
    managerList: [],
    accountList: [],
    limitList: [],
    paymentTermsList: [],

    /* action */
    setCustomerList(v) {
        this.customerList = v;
    },
    setSearchCondition(v) {
        LUtils.assign(this.searchCondition, v);
    },
    setBillingAddressList(v) {
        this.billingAddressList = v;
    },
    setManagerList(v) {
        this.managerList = v;
    },
    setAccountList(v) {
        this.accountList = v;
    },
    setLimitList(v) {
        this.limitList = v;
    },
    setPaymentTermsList(v) {
        this.paymentTermsList = v;
    },

    /* init */
    initTabsList() {
        this.billingAddressList = [];
        this.managerList = [];
        this.accountList = [];
        this.limitList = [];
        this.paymentTermsList = [];
    }
});
