import AuthGuard from '../utils/route-guard/AuthGuard';
import MainLayout from '../components/layout/MainLayout';
import Dashboard from '../views/Dashboard';
import DataGrid from '../views/sample/DataGrid';
import CommCodeList from '../views/commCode/CommCodeList';
import Authority from '../views/human-resources/Authority';
import AssetList from '../views/asset/AssetList'
import AssetHistory from '../views/asset/AssetHistory';
import ProductGroupList from '../views/product/ProductGroupList';
import ProductList from '../views/product/ProductList';
import OrderList from '../views/product/OrderList';
import ContractDetail from "../views/contract/ContractDetail";
import ContractCreation from "../views/contract/ContractCreation";
import ContractList from "../views/contract/ContractList";
import Estimate from "../views/contract/Estimate";
import CustomerList from '../views/customer/CustomerList';
import ReleaseAskList from '../views/release/ReleaseAskList';
import ReleaseAskDetail from '../views/release/ReleaseAskDetail';
import ReleaseList from "../views/release/ReleaseList";
import StorageList from "../views/storage/StorageList";
import StorageReturn from "../views/storage/StorageReturn";
import StorageAcquisition from "../views/storage/StorageAssetAcquisition";
import StorageGoods from "../views/storage/StorageGoods";
import StockList from "../views/asset/StockList";
import BillingList from "../views/billing/BillingList";
import BillManager from "../views/billing/BillManager";
import ReceiveManager from "../views/billing/ReceiveManager";
import NonPaymentManager from "../views/billing/NonPaymentManager";
import AccountList from "../views/billing/AccountList";
import AssetReservationList from "../views/asset/AssetReservationList";
import AssetExpirationList from "../views/asset/AssetExpirationList";
import ReturnAskList from "../views/return/ReturnAskList";
import ShipList from "../views/ship/ShipList";
import UserList from "../views/human-resources/UserList";
import AccountManagementList from "../views/accounting/AccountManagementList";
import GeneralStatementList from '../views/accounting/GeneralStatementList';
import StatementList from '../views/accounting/StatementList';
import CardManagementList from '../views/accounting/CardManagementList';
import CardList from '../views/accounting/CardList';
import CertificateList from '../views/accounting/CertificateList';
import CardStatementList from '../views/accounting/CardStatementList'
import RentalList from "../views/contract/RentalList";
import SalesList from "../views/customer/SalesList";
import WorkLog from "../views/customer/WorkLog";
import PaybackList from '../views/accounting/PaybackList';
import NonBill from "../views/billing/NonBill";
import CustomerLedgerList from '../views/accounting/CustomerLedgerList';
// import CertificateList from '../views/commCode/CertificateList';
import PurchaseSalesStatementList from '../views/accounting/PurchaseSalesStatementList';
import AccountLedgerList from '../views/accounting/AccountLedgerList';
import SiteInfo from "../views/customer/SiteInfo";
import AccountCloseList from '../views/accounting/AccountCloseList';
import MaintenanceList from '../views/asset/MaintenanceList';
import AsList from '../views/asset/AsList';
import AttendanceList from '../views/human-resources/AttendanceList';
import SalaryManager from '../views/human-resources/SalaryManager';
import SalaryStatusList from '../views/human-resources/SalaryStatusList';
import CompanyInfo from '../views/commCode/CompanyInfo';
import InsurancePolicyList from '../views/product/InsurancePolicyList';
import TaxBillList from '../views/billing/TaxBillList';
import MyPage from '../views/MyPage';
import ShipSchedule from '../views/ship/ShipSchedule';
import AssetOperateRate from '../views/asset/AssetOperateRate';
import StorageConsumOption from '../views/storage/StorageConsumOption';
import AssetMaintenanceList from '../views/asset/AssetMaintenanceList';
import BrandCodeList from '../views/product/BrandCodeList';

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        { path: '/dashboard', element: <Dashboard /> },
        { path: '/contract/estimate', element: <Estimate /> },
        { path: '/contract/list', element: <ContractList /> },
        { path: '/contract/detail', element: <ContractDetail /> },
        { path: '/contract/creation', element: <ContractCreation /> },
        { path: '/contract/rentalList', element: <RentalList /> },
        { path: '/asset/list', element: <AssetList /> },
        { path: '/asset/histories', element: <AssetHistory /> },
        { path: '/hr/authority', element: <Authority /> },
        { path: '/sample/grid', element: <DataGrid /> },
        { path: '/customer/list', element: <CustomerList /> },
        { path: '/workLog', element: <WorkLog /> },
        { path: '/customer/siteInfo', element: <SiteInfo /> },
        { path: '/sales', element: <SalesList /> },
        { path: '/commcode/list', element: <CommCodeList /> },
        { path: '/product/productGroup', element: <ProductGroupList /> },
        { path: '/product/list', element: <ProductList /> },
        { path: '/order/list', element: <OrderList /> },
        { path: '/release/ask/list', element: <ReleaseAskList /> },
        { path: '/release/ask/detail', element: <ReleaseAskDetail /> },
        { path: '/release/list', element: <ReleaseList /> },
        { path: '/storage/list', element: <StorageList /> },
        { path: '/storage/return', element: <StorageReturn /> },
        { path: '/storage/acquisition', element: <StorageAcquisition/> },
        { path: '/storage/goods', element: <StorageGoods /> },
        { path: '/product/stock', element: <StockList /> },
        { path: '/asset/reservation/list', element: <AssetReservationList /> },
        { path: '/billing/list', element: <BillingList /> },
        { path: '/billing/manager', element: <BillManager /> },
        { path: '/billing/receive', element: <ReceiveManager /> },
        { path: '/billing/account', element: <AccountList /> },
        { path: '/billing/nonPayment', element: <NonPaymentManager /> },
        { path: '/billing/nonBill', element: <NonBill /> },
        { path: '/asset/expiration/list', element: <AssetExpirationList /> },
        { path: '/return/ask/list', element: <ReturnAskList /> },
        { path: '/ship/list', element: <ShipList /> },
        { path: '/hr/list', element: <UserList />},
        { path: '/accounting/account', element: <AccountManagementList /> },
        { path: '/accounting/generalStetement/list', element: <GeneralStatementList /> },
        { path: '/accounting/statement/list', element: <StatementList /> },
        { path: '/accounting/card/management', element: <CardManagementList /> },
        { path: '/accounting/card/list', element: <CardList /> },
        { path: '/accounting/certificate', element: <CertificateList /> },
        { path: '/accounting/card/statement', element: <CardStatementList /> },
        { path: '/accounting/payback', element: <PaybackList /> },
        { path: '/accounting/customer/ledger', element: <CustomerLedgerList /> },
        { path: '/commcode/certificate', element: <CertificateList /> },
        { path: '/accounting/purchaseSales', element: <PurchaseSalesStatementList /> },
        { path: '/accounting/account/ledger', element: <AccountLedgerList /> },
        { path: '/accounting/yearClose', element: <AccountCloseList /> },
        { path: '/asset/maintenance/list', element: <MaintenanceList /> },
        { path: '/asset/as/list', element: <AsList /> },
        { path: '/hr/attendance', element: <AttendanceList />},
        { path: '/hr/salary', element: <SalaryManager /> },
        { path: '/hr/salary/status', element: <SalaryStatusList /> },
        { path: '/company/management', element: <CompanyInfo /> },
        { path: '/product/insurancePolicy', element: <InsurancePolicyList /> },
        { path: '/billing/taxbill', element: <TaxBillList /> },
        { path: '/mypage', element: <MyPage /> },
        { path: '/ship/schedule', element: <ShipSchedule /> },
        { path: '/asset/operateRate', element: <AssetOperateRate /> },
        { path: '/storage/consum/option', element: <StorageConsumOption /> },
        { path: '/asset/maintenance/regular', element: <AssetMaintenanceList /> },
        { path: '/product/brandCode', element: <BrandCodeList /> },
    ]
};

export default MainRoutes;
