import React from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { Col, Row } from "react-bootstrap";

import { CContainer, CInputGroup, CCol, CSelectGroup, Subject, CSaveBtn, CRemoveBtn } from '../../components/CustomContainer';
import { getCommcode } from "../../utils/commcode";
import { callConfirm } from "../../utils";
import { showToast } from "../../common/utils";
import { CardManagementStore } from "../../store/accounting/CardManagementStore";

const CardManagementInfo = ({getCardList}) => {
    const store = CardManagementStore;

    /* 새로 등록 or 수정 후 저장 */
    const saveCard = async () => {
        // 필수값 확인
        const requiredParams = ['cardNumber', 'cardCode', 'loginId', 'loginPassword'];
        if (!requiredParams.every(param => store.data[param])) {
            showToast('필수값을 모두 입력해주세요.');
            return;
        }

        if (!store.data.cardManagementSeq) {
            if (!await callConfirm('등록하시겠습니까?')) return;
            const result = await axios.post('card/management', store.data);
            if(result.result === 'option_failed') {
                alert(result.message);
            }else {
                store.data = result;
                showToast("등록이 완료되었습니다.");
            }
        } else {
            if(!await callConfirm('수정하시겠습니까?')) return;
            const result = await axios.put('card/management', store.data);
            store.data = result;

            showToast("수정이 완료되었습니다.");
        }
        getCardList();
    }

    /* 카드 미사용 */
    const removeCard = async () => {
        const result = await axios.delete(`card/management/${store.data.cardManagementSeq}`);
        showToast("삭제되었습니다.");
        store.dataInit();
        getCardList();
    }

    return (
        <Col>
            <CContainer>
                <Subject>카드정보</Subject>
                <Row>
                    <CCol lg={6}>
                        <CSelectGroup label={'카드사'} labelId={'cardCode'}
                                    options={[{ name: '전체', value: '' }].concat(getCommcode('44'))}
                                    value={store.data.cardCode || ''}
                                    onChange={v => store.data.cardCode = v.target.value}
                                    labelClassName='input-required'
                        />
                    </CCol>
                    <CCol lg={6}>
                        <CInputGroup label={'카드번호'} labelId={'cardNumber'}
                                        value={store.data.cardNumber}
                                        onChange={v => store.data.cardNumber = v.target.value}
                                        labelClassName='input-required'
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        <CInputGroup label={'로그인아이디'} labelId={'loginId'}
                                    value={store.data.loginId}
                                    onChange={v => store.data.loginId = v.target.value}
                                    labelClassName='input-required'
                        />
                    </CCol>
                    <CCol lg={6}>
                        <CInputGroup label={'로그인패스워드'} labelId={'loginPassword'}
                                    value={store.data.loginPassword}
                                    onChange={v => store.data.loginPassword = v.target.value}
                                    type={'password'}
                                    labelClassName='input-required'
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        <CInputGroup label={'카드별칭'} labelId={'cardAlias'}
ㄴ                                    value={store.data.cardAlias}
                                    onChange={v => store.data.cardAlias = v.target.value}
                        />
                    </CCol>
                    <CCol lg={6}>
                        <CInputGroup label={'사용한도'} labelId={'useLimit'}
                                    value={store.data.useLimit}
                                    onChange={v => store.data.useLimit = v.target.value}
                        />
                    </CCol>
                    {/* <CCol lg={6}>
                        <CSelectGroup
                            label={'사용여부'} labelId={'isUsed'}
                            options={[
                                {name: '사용', value: 1},
                                {name: '미사용', value: 0},
                            ]}
                            value={store.data.isUsed}
                            onChange={v => store.data.isUsed = v.target.value}
                        />
                    </CCol> */}
                </Row>
                <Row>
                    <CCol lg={12}>
                        <CInputGroup label={'비고'} labelId={'remark'}
                                    placeholder={'비고를 입력해 주세요.'}
                                    value={store.data.remark}
                                    onChange={v => store.data.remark = v.target.value}
                        />
                    </CCol>
                </Row>
            </CContainer>
            <CContainer>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSaveBtn style={{ width: 80 }}
                            title={'저장'}
                            onClick={saveCard}
                        />
                        {store.data.cardManagementSeq && <CRemoveBtn title={'카드미사용'} style={{ width: 100 }} onClick={removeCard} />}
                    </Col>
                </Row>
            </CContainer>
        </Col>
    );
};

export default observer(CardManagementInfo);
