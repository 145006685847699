import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import { AppStore } from '../../store/AppStore';
import {ToastContainer} from "react-toastify";
import Loading from '../Loding';
import {useProSidebar} from "react-pro-sidebar";

const MainLayout = () => {
    const { collapsed } = useProSidebar();

    return (
        <div style={{display:'flex', minHeight:'100vh'}}>
            {AppStore.isRunningSpinner && <Loading />}
            <Sidebar />
            <div style={{width: '100%', overflowX: 'hidden', zIndex: 1, backgroundColor: '#f7f9fc'}}>
                <Header />
                <div className='p-3' style={{marginLeft: collapsed ? 80 : 250, marginTop: 78}}>
                    <Outlet />
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    );
};

export default observer(MainLayout);
