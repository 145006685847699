import { observable } from "mobx";

export const AssetOperateRateStore = observable({
    startDate: { startDate:'', endDate: '' },
    endDate: { startDate:'', endDate: '' },
    managementNo: '',
    model: '',
    selSearchType: '',
    columnList: [],
    assetList: [],

    setStartDate(v){
        this.startDate = v;
    },
    setEndDate(v){
        this.endDate = v;
    },
    setSelSearchType(v){
        this.selSearchType = v;
    },
    setManagementNo(v){
        this.managementNo = v;
    },
    setModel(v){
        this.model = v;
    },
    pushColumnList(v){
        this.columnList.push(v);
    },
    setColumnList(v){
        this.columnList = v;
    },
    setAssetList(v) {
        this.assetList = v;
    },

    init() {}
});
