import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const RentalListStore = observable({
    rentalList: [],
    searchCondition: { searchDate: '', contractNo: '', customerNo: '', customerName: '', billingAddressSeq: null, billingAddressName: '', contractType: '', rentType: '',
                        saleUserSeq: null, saleUserName:'', supportUserSeq: null, supportUserName: '', assetNo: '', managementNo: '', siteSeq: null, siteName: '', installPlace: ''},
    setRentalListSearchCondition(target, v) {
        this.searchCondition[target] = v;
    },
    setRentalList(v){
        this.rentalList = v;
    },
    setSearchCondition(v) {
        LUtils.assign(this.searchCondition, v);
    },
});
