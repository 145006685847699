import { observable } from "mobx";

export const ProductGroupStore = observable({
    totalMDList: [],
    totalSMList: [],
    totalXSList: [],
    lgList: [],
    mdList: [],
    smList: [],
    xsList: [],
    originLgList: [],
    originMdList: [],
    originSmList: [],
    originXsList: [],
    selectLGSeq: '',
    selectMDSeq: '',
    selectSMSeq: '',
    selectLgParams: '',
    selectMdParams: '',
    selectSmParams: '',

    setTotalList(column, v) {
        this[column] = v;
    },
    setLGList(v) {
        this.lgList = v;
    },
    setMDList(v) {
        this.mdList = v;
    },
    setSMList(v) {
        this.smList = v;
    },
    setXSList(v) {
        this.xsList = v;
    },
    setOriginLGList(v) {
        this.originLgList = v;
    },
    setOriginMDList(v) {
        this.originMdList = v;
    },
    setOriginSMList(v) {
        this.originSmList = v;
    },
    setOriginXSList(v) {
        this.originXsList = v;
    },
    setSelectLGSeq(v) {
        this.selectLGSeq = v;
    },
    setSelectMDSeq(v) {
        this.selectMDSeq = v;
    },
    setSelectSMSeq(v) {
        this.selectSMSeq = v;
    },
});