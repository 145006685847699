 import { useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import {CContainer} from '../../components/CustomContainer';
import AgGridContainer from '../../components/AgGridContainer';
import {AssetMaintenanceListStore} from '../../store/asset/AssetMaintenanceListStore';
import { AppStore } from "../../store/AppStore";
import AssetDetail from './AssetDetail';
import { showToast } from '../../common/utils';
import AssetMaintenanceListSearch from './AssetMaintenanceListSearch';
import Modal from '../../components/Modal';

const AssetMaintenanceList = () => {
    const gridRef = useRef();
    const gridModalRef = useRef();

    const getAssetMaintenanceList = async () => {
        const result = await axios.get("/asset/maintenance/regular/list", { params: AssetMaintenanceListStore.search });
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.');
        }

        AssetMaintenanceListStore.setAssetList(result);
    }

    const columnDefs = [
        {field: "productGroupName", headerName: "대분류", width: 100},
        {field: "productName", headerName: "제품명", width: 140},
        {field: "model", headerName: "모델명", width: 140},
        {field: "assetNo", headerName: "자산번호", width: 110, hide: true},
        {field: "managementNo", headerName: "관리번호", width: 130},
        {field: "serialNo", headerName: "시리얼번호", width: 160},
        {field: "maintenanceRound", headerName: "정비회차", width: 120},
        {field: "maintenanceCycleMonth", headerName: "정비주기", width: 120},
        {field: "lastMaintenanceDate", headerName: "마지막 정비일자", width: 160},
        {field: "nextMaintenanceDate", headerName: "다음 정비일자", width: 160},
        {field: "maintenanceDiffDay", headerName: "다음 정비까지 남은일수", width: 70},
        {field: "applyMaintenanceDay", headerName: "다음 정비일까지", width: 160, cellClass: v => v.data.maintenanceDiffDay > 0 ? 'ag-grid-column-cancel' : 'ag-grid-column-complete'},
        {field: "content", headerName: "최근정비 내역", width: 160},
        {field: "amount", headerName: "최근정비 비용", width: 160},
        {field: "name", headerName: "최근 정비자", width: 160},
        {field: "contractNo", headerName: "최근계약번호", width: 160},
        {field: "customerName", headerName: "고객명", width: 160},
        {field: "siteName", headerName: "현장명", width: 160},
        {field: "contractStartDate", headerName: "계약시자일", width: 160},
        {field: "contractEndDate", headerName: "계약종료일", width: 160},
        {field: "assetStatusName", headerName: "자산상태", width: 120},
    ];

    /* 자산 상세 */
    const openAssetDetail = async(v) => {
        const result = await axios.get('/asset/maintenance/details', {params: {assetNo: v.data.assetNo}});
        AssetMaintenanceListStore.setDetailList(result);

        AppStore.toggleModal();
    }

    return (
        <>
            {/* 검색조건 */}
            <AssetMaintenanceListSearch getAssetMaintenanceList={getAssetMaintenanceList}/>
            <CContainer>
                {/* grid */}
                <AgGridContainer
                    gridTitle={'유지보수 대상 목록'}
                    gridRef={gridRef}
                    height={60}
                    rowData={AssetMaintenanceListStore.assetList}
                    columnDefs={columnDefs}
                    seqColumn={'assetNo'}
                    useCsvDownload={true}
                    rowDoubleClickCallback={openAssetDetail}
                />
            </CContainer>
            <Modal
                title={'정비상세내역'}
            >
                <AgGridContainer
                    gridRef={gridModalRef}
                    height={50}
                    rowData={AssetMaintenanceListStore.detailList}
                    columnDefs={[
                        {field: "maintenanceRound", headerName: "회차", width: 80},
                        {field: "maintenanceDate", headerName: "정비완료일", width: 180},
                        {field: "mechanicName", headerName: "정비자"},
                        {field: "amount", headerName: "수리비", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', width: 120},
                        {field: "content", headerName: "내용", width: 350, flex: 1},
                    ]}
                    useCsvDownload={true}
                />
            </Modal>
        </>
    );
};

export default observer(AssetMaintenanceList);
