import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";

import Modal from "../../../components/Modal";
import AgGridContainer from "../../../components/AgGridContainer";
import { getSessionUserCompanyCode } from "../../../utils/userUtils";
import { decrypt } from "../../../common/utils";
import { AppStore } from "../../../store/AppStore";
import { CContainer } from "../../../components/CustomContainer";

const ShipManagerPopup =({callBackFn})=> {
    const [userList, setUserList] = useState([]);

    const gridRef = useRef();

    useEffect(() => {
        getShipManagerList();
    }, [])

    const getShipManagerList = async () => {
        const result = await axios.get('/user/list', { params: {isActive: 1, companyCode: getSessionUserCompanyCode(), searchType: 'ship'} });
        setUserList(result);
    }

    const selectedRow =(e)=> {
        callBackFn(e.data);
        AppStore.toggleShipManagerPopup();
    }

    return (
        <Modal
            title={'배송 담당자'}
            show={AppStore.isShipManagerPopup}
            onHide={() => AppStore.toggleShipManagerPopup()}
            toggleValue={AppStore.isShipManagerPopup}
            className={'modal-w9'}
        >
  
            <CContainer>
                <p>배송 담당자를 더블클릭하여 지정해주세요</p>
                <AgGridContainer
                    height={30}
                    gridRef={gridRef}
                    rowData={userList}
                    columnDefs={
                        [
                            {field: "id", headerName: "사용자ID", flex: 1},
                            {field: "name", headerName: "사원명", flex: 1},
                            {field: "deptName", headerName: "부서명", width: 130},
                            {field: "position", headerName: "직급", width: 110},
                            {field: "phoneNumber", headerName: "휴대폰번호", valueGetter: v => v.data.phoneNumber ? decrypt(v.data.phoneNumber) : '', flex: 1},
                            {field: "isActive", headerName: "퇴사여부", valueGetter:v => v.data.isActive ? '근속' : '퇴사', hide: true},
                            {field: "userSeq", headerName: "사용자 시퀀스", hide: true},
                        ]
                    }
                    seqColumn={'id'}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(ShipManagerPopup);
