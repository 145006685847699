import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";

import { CContainer, IconBtn } from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import { showToast } from "../../common/utils";
import { TaxBillStore } from "../../store/billing/TaxBillStore";
import { findCommKrnm } from "../../utils/commcode";
import TaxBillSearch from "./TaxBillSearch";
import { Row } from "react-bootstrap";
import { callConfirm } from "../../utils";
import TaxBillDetailPopup from "./popup/TaxBillDetailPopup";
import { AppStore } from "../../store/AppStore";

const TaxBillList =()=> {
    const gridRef = useRef();
    const store = TaxBillStore;
    const [taxBillData, setTaxBillData] = useState([]);

    const pinnedTopRowData = [{
        totalAmount: store.taxBillList.reduce((total, v) => total + v.totalAmount, 0),
        supplyAmount: store.taxBillList.reduce((total, v) => total + v.supplyAmount, 0),
        taxAmount: store.taxBillList.reduce((total, v) => total + v.taxAmount, 0),
        removeIcon: 'pinnedTopRowData',
        searchIcon: 'pinnedTopRowData',
        billDocument: 'pinnedTopRowData', 
    }];

    const columnDefs=[
        {field: "taxBillSeq", headerName: "시퀀스", hide: true},
        {field: "taxBillType", headerName: "구분", valueFormatter: v => findCommKrnm(String(v.value), '60'), cellClass: v => v.value === '1' ? 'ag-grid-column-complete' : 'ag-grid-column-cancel'},
        {
            field: 'billDocument',
            headerName: "",
            width: 60,
            cellRenderer: (v) => v.value !== 'pinnedTopRowData' && (
                <IconBtn iconClass={'fi fi-rr-document'} style={{paddingTop: 6}} onClick={() => openDetail(v)}/>
            ),
            cellClassRules: {
                'auto-pointer-events': (v) => v.data.shipStatus!=='9',
            },
        },
        {field: "issueDate", headerName: "발행일", width: 120},
        {field: "approvalNo", headerName: "승인번호", width: 120, hide: true},
        {
            headerName: "공급자",
            children: [
                {field: "supplyBusinessNumber", headerName: "공급자 사업자번호", width: 180},
                {field: "supplyCompanyName", headerName: "공급자", width: 200},
                {field: "supplyOwner", headerName: "공급자 대표", width: 200, hide: true},
                {field: "supplyCompanyAddress", headerName: "공급자 주소", width: 250},
            ]
        },
        {
            headerName: "공급받는자",
            children: [
                {field: "customerBusinessNumber", headerName: "공급받는자 사업자번호", width: 180},
                {field: "customerCompanyName", headerName: "공급받는자", width: 200},
                {field: "customerOwner", headerName: "공급받는자 대표", width: 200, hide: true},
                {field: "customerCompanyAddress", headerName: "공급받는자 주소", width: 250},
            ]
        },
        {field: "itemName", headerName: "품목", width: 350},
        {field: "itemQty", headerName: "품목수량"},
        {field: "totalAmount", headerName: "총금액", valueFormatter: v => v.value?.toLocaleString(), width: 140},
        {field: "supplyAmount", headerName: "공급가액", valueFormatter: v => v.value?.toLocaleString(), width: 140},
        {field: "taxAmount", headerName: "세액", valueFormatter: v => v.value?.toLocaleString(), width: 140},
    ]

    const getTaxBillList = async() => {
        const result = await axios.get('/taxbill', {params: store.search});
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.');
        }
        store.taxBillList = result;
    }

    const removeCallback = async(e) => {
        if(!await callConfirm('삭제 하시겠습니까?')) {
            return;
        }
        await axios.delete(`/taxbill/${e.data.taxBillSeq}`);
        getTaxBillList();
        showToast('삭제했습니다.');
    }

    const openDetail = (v) => {
        setTaxBillData(v.data);
        AppStore.toggleTaxBillDetailPopup();
    }

    return (
        <>
            <TaxBillSearch getTaxBillList={getTaxBillList}/>
            <CContainer>
                <Row style={{marginTop: 20}}>
                    <AgGridContainer
                        gridTitle={'매입매출'}
                        gridRef={gridRef}
                        height={63}
                        rowData={store.taxBillList}
                        columnDefs={columnDefs}
                        useCsvDownload={true}
                        pinnedTopRowData={pinnedTopRowData}
                        isDeleteExcelHide={true}
                        // useRemoveRow={true}
                        removeCallback={removeCallback}
                    />
                </Row>
            </CContainer>
            <TaxBillDetailPopup taxBillData={taxBillData} />
        </>
    );
}

export default observer(TaxBillList);
