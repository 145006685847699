import { observer } from "mobx-react-lite";
import { CContainer, CCol, CalendarGroup, CInputGroup, CSelectGroup, CIconBtn, CSearchBtn } from "../../components/CustomContainer";
import { Row, Col, Form } from "react-bootstrap";
import { getCommcode } from "../../utils/commcode";
import { AppStore } from "../../store/AppStore";
import axios from 'axios';
import { CardStore } from "../../store/accounting/CardStore";

const CardListSearch = ({getCardList}) => {
    const store = CardStore;

    /* 카드 승인 내역 가져오기 */
    const handleCardUpload = async () => {
        AppStore.isAxiosSetting = true;
        const result = await axios.post('/card/approval/data', store.search);
        alert('실패 : ' + result.length + '개 \n' + result.join(""));
    };

    return (
        <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CalendarGroup label={'승인일자'}
                                       value={{
                                            startDate: store.search.startDate,
                                            endDate: store.search.endDate
                                        }}
                                       onChange={v => {store.search.startDate = v.startDate, store.search.endDate = v.endDate}}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'승인번호'} labelId={'approvalNo'}
                            value={store.search.approvalNo}
                            onChange={v => store.search.approvalNo = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'카드사'} labelId={'cardCode'}
                                      placeholder={'카드사'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('24'))}
                                      value={store.search.cardCode}
                                      onChange={v => store.search.cardCode = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'카드번호'} labelId={'cardNumber'}
                            value={store.search.cardNumber}
                            onChange={v => store.search.cardNumber = v.target.value}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'별칭'} labelId={'cardAlias'}
                            value={store.search.cardAlias}
                            onChange={v => store.search.cardAlias = v.target.value}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isShort"
                            label="간략히 보기"
                            style={{marginBottom: 8}}
                            checked={!!store.isShort}
                            onChange={v => store.isShort=v.target.checked ? true : false}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CIconBtn title={'카드승인내역 가져오기'}
                                  icon={'fi-rr-download'}
                                  onClick={handleCardUpload}
                                  isKeyEvent={true}
                        />
                        <CSearchBtn onClick={getCardList} />
                    </Col>
                </Row>
            </CContainer>
    );
}

export default observer(CardListSearch);