import { observable } from "mobx";

export const StatementStore = observable({
    inputStatementList: [],
    outputStatementList: [],
    inputSearch: {
        startDate: '',
        endDate: '',
        accountType: 'input',
        bankCode: '',
        searchType: 'preStatement',
        accountNumber: '',
        companyCode: '',
    },
    outputSearch: {
        startDate: '',
        endDate: '',
        accountType: 'input',
        bankCode: '',
        searchType: 'preStatement',
        accountNumber: '',
        companyCode: '',
    },
    popup: {
        isClicked: false,
        type: 'H',
        summary: '',
    },
})