const Regex = {
    makeBusinessNumber: str => {
        // str이 undefined 또는 null인 경우 빈 문자열로 설정
        str = str || '';
        
        return str.replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, "$1-$2-$3");
    },
}

export default Regex;
