import React from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";

import { CContainer, CInputGroup, CCol, CSearchBtn, CAddBtn, CSelectGroup } from '../../components/CustomContainer';
import { getCommcode } from "../../utils/commcode";
import { AccountStore } from '../../store/commCode/AccountStore';

const AccountManagementSearch = (props) => {
    const {getAccountList} = props;
    const store = AccountStore;

    /* 계좌 등록 버튼 클릭 시 작성칸 비우기  */
    const initAccount = () => {
        store.dataInit();
    }

    return (
        <CContainer search>
            <Row>
                <CCol lg={2}>
                    <CSelectGroup
                        label={'은행'} labelId={'bankCode'}
                        options={[{ value: '', name: '전체' }].concat(getCommcode('24'))}
                        value={store.search.bankCode || ''}
                        onChange={v => store.search.bankCode = v.target.value} />
                </CCol>
                <CCol lg={3}>
                    <CInputGroup
                        label={'계좌번호'}
                        labelId={'accountNumber'}
                        placeholder={'-를 제외하고 입력하세요'}
                        value={store.search.accountNumber}
                        onChange={v => store.search.accountNumber = v.target.value}
                    />
                </CCol>
                <CCol lg={2}>
                    <CInputGroup
                        label={'로그인아이디'}
                        placeholder={'로그인아이디'}
                        value={store.search.loginId}
                        onChange={v => store.search.loginId = v.target.value}
                    />
                </CCol>
            </Row>
            <Row>
                <Col className='d-flex flex-row-reverse'>
                    <CSearchBtn onClick={getAccountList} />
                    <CAddBtn title={'계좌등록'} style={{ width: 90 }} onClick={initAccount} />
                </Col>
            </Row>
        </CContainer>

    );
};

export default observer(AccountManagementSearch);
