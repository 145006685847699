import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {CContainer, IconBtn} from "../../components/CustomContainer";
import {findCommKrnm} from "../../utils/commcode";
import AgGridContainer from "../../components/AgGridContainer";
import {BillSearchStore} from "../../store/billing/BillSearchStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import CustomerBillingAddressSearch from "../../components/searchModal/CustomerBillingAddressSearch";
import {dateFormatCheck, endDateForThisMonth, startDateForThisMonth, today} from "../../utils/dateUtils";
import axios from "axios";
import {getSessionUserCompanyCode, getUserName} from "../../utils/userUtils";
import {callAlert, callConfirm} from "../../utils";
import {decrypt, showToast} from "../../common/utils";
import {toJS} from "mobx";
import {ICON_TRASH, MULTIPART_HEADER} from "../../common/constants";
import BillManagerSearch from "./BillManagerSearch";
import Modal from "../../components/Modal";
import TradingStatementPopup from "./popup/TradingStatementPopup";
import { AppStore } from "../../store/AppStore";
import SendTradingStatementPopup from "./popup/SendTradingStatementPopup";
import html2pdf from 'html2pdf.js';
import BillPublishPopup from "./popup/BillPublishPopup";
import SendTaxBillPopup from "./popup/SendTaxBillPopup";
import BillItemPopup from "./popup/BillItemPopup";
import LUtils from "../../utils/lodashUtils";
import TaxBillDetailPopup from "./popup/TaxBillDetailPopup";
import SendTaxBillSmsPopup from "./popup/SendTaxBillSmsPopup";
import { TaxBillStore } from "../../store/billing/TaxBillStore";

const BillManager =()=> {
    const gridRef = useRef();
    const billTargetRef = useRef();
    const billGridRef = useRef();
    const userCompanyCode = getSessionUserCompanyCode();

    const [selView, setSelView] = useState('billing');
    const [selSearchUserType, setSelSearchUserType] = useState(0);
    const [billingSearchDate, setBillingSearchDate] = useState({startDate: startDateForThisMonth(), endDate: endDateForThisMonth()});
    const [billPublishDate, setBillPublishDate] = useState({ startDate: today(), endDate: '' });
    const [selectAmount, setSelectAmount] = useState(0);
    const [selectVat, setSelectVat] = useState(0);
    const [companyCode, setCompanyCode] = useState('');
    const [publishedCompanyCode, setPublishedCompanyCode] = useState('');
    const [row, setRow] = useState([]);

    const [gridSeq, setGridSeq] = useState(0);
    const [chargeTotalAmount, setChargeTotalAmount] = useState(0);
    const [billNo, setBillNo] = useState('');
    const [isOneRow, setIsOneRow] = useState(false);
    const [contractNo, setContractNo] = useState('');
    const [billingSeq, setBillingSeq] = useState('');
    const [accountBillNote, setAccountBillNote] = useState('');

    const [taxBillData, setTaxBillData] = useState([]);

    const [sendSmsTargetBillList, setSendSmsTargetBillList] = useState([]);

    const getBillingTargetList =async()=> {
        BillSearchStore.setCondition(billingSearchDate.startDate, 'searchStartDate');
        BillSearchStore.setCondition(billingSearchDate.endDate, 'searchEndDate');

        const result = await axios.get('/bill/billing/list', { params: BillSearchStore.condition });
        let resultList = result.map(v => {
            //setAccountBillNote(v.billNote);
            return {...v, billItemList: v.billItemList === null ? [
                {
                    seq: 0,
                    item: v.billItem,
                    qty:  1,
                    unitAmount: v.chargeAmount,
                    taxAmount: v.chargeVat,
                    supplyAmount: v.chargeAmount,
                    billItemNote: v.billItemNote,
                    billNote: v.billNote,
                    orgBillNote: v.billNote,
                },
            ] 
            :
            v.billItemList.map(item => {return {...item, billingType: item.billingType, seq: item.seq, billingMonth: item.billingMonth}})
        }})

        BillSearchStore.setBillingList(resultList);

        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.')
        }
    }

    const getBillPublishedList =async()=> {
        if(!billingSearchDate.startDate){
            showToast('계산서 발행일은 필수 입니다.');
            return;
        }
        BillSearchStore.setCondition(billingSearchDate.startDate, 'searchStartDate');
        BillSearchStore.setCondition(billingSearchDate.endDate, 'searchEndDate');
        BillSearchStore.setCondition(publishedCompanyCode, 'companyCode');

        const result = await axios.get('/bill/published/list', { params: BillSearchStore.condition });
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.')
        }
        BillSearchStore.setBillPublishedList(result);
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        BillSearchStore.setCondition(customerInfo.customerName, 'customerName');
        BillSearchStore.setCondition(customerInfo.customerNo, 'customerNo');
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        let title = selSearchUserType === 0 ? 'sale' : 'support';
        BillSearchStore.setCondition(userInfo.name, `userName`);
        BillSearchStore.setCondition(userInfo.userSeq, `${title}UserSeq`);
    }

    /* 청구지 정보 콜백 함수 */
    const getBillingAddressInfo = (info) => {
        BillSearchStore.setCondition(info.billingAddressSeq, 'billingAddressSeq');
        BillSearchStore.setCondition(info.billingAddressName, 'billingAddressName');
    }

    const getCheckboxClickEvent = () => {
        const selectedList = BillSearchStore.billingList.filter(v => v.isSelected);
        const sumChargeAmount = selectedList.reduce((total, billing) => total + billing.orgChargeAmount, 0);
        const sumChargeVat = selectedList.reduce((total, billing) => total + (billing.orgChargeAmount * 0.1), 0);

        setSelectAmount(sumChargeAmount);
        setSelectVat(sumChargeVat);
    }

    const getCellChange =(e)=> {
        const event = toJS(e);
        const {chargeAmount, changeChargeAmount, changeChargeVat, contractType} = event.data;

        //매각/유통계약이면 금액변경을 막는다.
        if(contractType !== '1' && contractType !== '2'){
            showToast('유통/매각 계약은 금액변경이 불가능 합니다.');
            e.data['changeChargeAmount'] = '';
            e.data['changeChargeVat'] = '';
            gridRef.current.api.redrawRows();
            return;
        }

        if(event.column.colId === 'changeChargeAmount'){
            if(Number(changeChargeAmount) > Number(chargeAmount)){
                showToast('원 청구 금액보다 높게 입력하였습니다.');
            }
            e.data['changeChargeVat'] = Math.floor(Number(changeChargeAmount) * 0.1);

        }else if(event.column.colId === 'changeChargeVat'){
            if(event.oldValue){
                if(event.oldValue - event.value > 90){
                    showToast('부가세는 10원단위 까지만 수정이 가능합니다.');
                    event.data['changeChargeVat'] = event.oldValue;
                }
            }
        }

        gridRef.current.api.redrawRows();
    }

    //계산서발행일 수정
    const handlePublishDateChanged = async(e) => {
        let newDate = e.newValue;
        let oldDate = e.data.orgBillDate;

        if(!dateFormatCheck(newDate)){
            e.data.billDate = oldDate;
            billGridRef.current.api.redrawRows();
            return;
        }
/*

        if(newDate < yearMonthFormat(addMonthDate(oldDate, 1)) + '-10')
*/
        e.data.billDate = newDate;
        billGridRef.current.api.redrawRows();
    }

    /* 저장버튼 클릭 이벤트 */
    const callBackGridData = async (gridData) => {
        const updatedList = gridData.updatedList;
        const selectedList = gridData.selectedList;

        if(updatedList && updatedList.length > 0) {
            if (updatedList.some(item => item.billDate === '')) {
                await callAlert('계산서 발행일을 입력해야 합니다.');
                return;
            }
        }

        const title = selectedList ? '삭제' : '수정';

        if(title === '삭제'){
            if(selectedList.some(v => v.isSendBill === 1)){
                showToast('발송된 계산서는 삭제할 수 없습니다.');
                return;
            }
        }

        if (!await callConfirm(title+' 하시겠습니까?')) return;
        await axios.put('bill/', {isCancel: selectedList ? 1 : 0, billUpdateList: updatedList ? updatedList : selectedList});
        showToast(title+'되었습니다.');

        //재조회
        await getBillPublishedList();
    }

    /* 계산서 삭제 */
    const deleteBillBtn = {
        isUsed: true,
        title: '삭제',
        icon: ICON_TRASH,
        variant: 'danger'
    };

    /* 청구지, 현장명으로 메일 주소 담기 */
    const getEmailList = async(customerNo, billingAddressSeq, siteSeqGroup) => {
        const result = await axios.get(`/bill/email/${customerNo}/${billingAddressSeq}/${siteSeqGroup}`); 
        BillSearchStore.emailList = result.filter(v => decrypt(v.email) !== '');
    }

     /* row 더블 클릭 */
     const rowDoubleClickCallback = (e) => {
        if(e.data.billAmount < 0) return showToast("마이너스 계산서는 선택할 수 없습니다.");

        if (e.data.isSendBill === 1) {
            const data = {...e.data, supplyEmail: decrypt(e.data.supplyEmail), customerEmail: decrypt(e.data.customerEmail), billingAddressPhoneNumber: decrypt(e.data.billingAddressPhoneNumber)};
            setTaxBillData(data);
            TaxBillStore.setSendTargetList([data]);
            AppStore.toggleTaxBillDetailPopup();
        } else {
            BillSearchStore.sendTradingStatementList = [e.data];
            BillSearchStore.amount = e.data.billAmount;
            BillSearchStore.vat = e.data.billVat;
            BillSearchStore.totalAmount = e.data.billTotalAmount;
            BillSearchStore.sendTradingStatementList.subject = `[${e.data.customerName}] ${e.data.billDate.slice(0, -3)} ${e.data.companyName} 거래 명세서`;
            BillSearchStore.sendTradingStatementList.content = `안녕하세요 ${e.data.customerName}님\n\n${e.data.billDate.slice(0, -3)} 거래 명세서를 전달 드리오니\n검토해 주시기 바랍니다.\n\n감사합니다.`;     
            setRow([e.data]);
            setBillNo(e.data.billNo);
    
            AppStore.toggleModal();
        }
    }

    /* 거래명세서 버튼 클릭 */
    const tradingStatementBtn = () => {
        // 필수값 체크
        const selectedList = BillSearchStore.billPublishedList.filter(v => v.isSelected);
        
        if(selectedList.length < 1) return showToast('행을 선택해주세요.');
        if(selectedList.length > 1) return showToast('한 행만 선택가능합니다.');
        if(selectedList[0].billAmount < 0) return showToast("마이너스 계산서는 선택할 수 없습니다.");
        BillSearchStore.sendTradingStatementList = selectedList;
        BillSearchStore.amount = selectedList[0].billAmount;
        BillSearchStore.vat = selectedList[0].billVat;
        BillSearchStore.totalAmount = selectedList[0].billTotalAmount;
        BillSearchStore.sendTradingStatementList[0].subject = `[${selectedList[0].customerName}] ${selectedList[0].billDate.slice(0, -3)} ${selectedList[0].companyName} 거래 명세서`;
        BillSearchStore.sendTradingStatementList[0].content = `안녕하세요 ${selectedList[0].customerName}님\n\n${selectedList[0].billDate.slice(0, -3)} 거래 명세서를 전달 드리오니\n검토해 주시기 바랍니다.\n\n감사합니다.`;     
        setBillNo('');

        AppStore.toggleModal();
    }

     /* 거래명세서 메일 전송 팝업 */
    const sendEmailPopup = () => {
        const selectedList = BillSearchStore.billPublishedList.filter(v => v.isSelected).map(v => {
            v.subject = '';
            v.content = '';
            return v;
        });
        // // 메일 주소 담기
        // selectedList.forEach(v => {
        //     getEmailList(v.customerNo, v.billingAddressSeq, v.siteSeq);
        // });
       
        if(selectedList.length < 1) {
            callAlert("메일 전송할 청구서를 선택해 주세요.");
            return;
        }
        BillSearchStore.sendTradingStatementList = selectedList;
        setBillNo('');
        if(selectedList.length === 1) return AppStore.toggleModal();

        AppStore.toggleSendTradingStatementPopup();
    }

    /* 거래명세서 메일 전송 */
    const sendTradingStatement = async () => {
        if(!await callConfirm('메일 전송 하시겠습니까?')) return;

        for (const [index, item] of BillSearchStore.sendTradingStatementList.entries()) {       
            const htmlCode = item.code;
        
            // 새로운 div 요소 생성
            const wrapperDiv = document.createElement('div');
            wrapperDiv.innerHTML = htmlCode;

            wrapperDiv.style.fontSize = '10px';
        
            // 테이블 내부의 th와 td에 대한 스타일 변경
            const tableCells = wrapperDiv.querySelectorAll('th, tr, thead tr th, td, tr td input, input');
            tableCells.forEach(cell => {
                cell.style.paddingTop = '-20px';
                cell.style.fontSize = '16px'; 
                cell.style.verticalAlign = 'top'; // 세로 정렬 중앙으로 설정
            });

            const inputCells = wrapperDiv.querySelectorAll('td input, input');
            inputCells.forEach(cell => {
                cell.style.height = '24px';
                cell.style.verticalAlign = 'top';
            });

            const inputDateCells = wrapperDiv.getElementsByClassName('input-date');
            inputDateCells.forEach(cell => {
                cell.style.fontSize = '10px';
                cell.style.width = '80px';
                cell.style.verticalAlign = 'top';
            });

            const fileName = `${item.customerName}_${today()}_${item.billNo}_거래명세서.pdf`;
        
            const options = {
                filename: fileName,
                margin: [0, 10, 0, 10], 
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' }
            };

            const pdfBlob = await html2pdf().set(options).from(wrapperDiv).toPdf().output('blob');
            const blob = new Blob([pdfBlob], { type: 'application/pdf' });
            // FormData를 사용하여 파일을 생성
            const formData = new FormData();

            formData.append('file', new Blob([blob]), fileName);
            formData.append('subject', item.subject);
            formData.append('content', item.content);
            formData.append('emailList', BillSearchStore.emailList[item.billNo].filter(v => v.isSelected).map(v => v.email));
            formData.append('siteSeq', Number(item.siteSeqGroup));
            formData.append('billNo', item.billNo);

            await axios.post('/bill/email', formData, { headers: MULTIPART_HEADER });
        }

        showToast('전송이 완료되었습니다.');
        AppStore.toggleSendTradingStatementPopup();
    }

    const closeModal = () => {
        BillSearchStore.billNo = '';
    }

    const pinnedTopBillData = [{
        billAmount: BillSearchStore.billPublishedList.reduce((total, v) => total + v.billAmount, 0),
        billVat: BillSearchStore.billPublishedList.reduce((total, v) => total + v.billVat, 0),
        billTotalAmount: BillSearchStore.billPublishedList.reduce((total, v) => total + v.billTotalAmount, 0),
        isCard: 'pinnedTopRowData',
    }];

    const pinnedTopBillingData = [{
        chargeAmount: BillSearchStore.billingList.reduce((total, v) => total + v.chargeAmount, 0),
        chargeVat: BillSearchStore.billingList.reduce((total, v) => total + v.chargeVat, 0),
        chargeTotalAmount: BillSearchStore.billingList.reduce((total, v) => total + v.chargeTotalAmount, 0),
        changeChargeAmount: BillSearchStore.billingList.reduce((total, v) => Number(total) + Number(v.changeChargeAmount), 0),
        changeChargeVat: BillSearchStore.billingList.reduce((total, v) => total + v.changeChargeVat, 0),
        revisedIsseu: 'pinnedTopRowData',
    }];

    const publishBill =async(data)=> {

        const billingPublishList = BillSearchStore.billingList.filter(v => v.isSelected || v.isUpdated);
        let sumChangePublishAmount = 0;
        let sumChangePublishVat = 0;
        let diffVat = false;

        if (BillSearchStore.condition.billPublishType === '1') {
            //총 청구액
            billingPublishList.forEach(v => {
                sumChangePublishAmount += Number(v.changeChargeAmount ? v.changeChargeAmount : v.chargeAmount);
            });
            //총 부가세
            billingPublishList.forEach(v => {
                sumChangePublishVat += Number(v.changeChargeVat ? v.changeChargeVat : v.chargeVat);
            })
    
            //부가세 체크
            if((sumChangePublishAmount * 0.1) !== Number(sumChangePublishVat)){
                diffVat = true;
            }
        } else {
            //총 청구액
            data.selectedList.forEach(v => {
                sumChangePublishAmount += v.changeChargeAmount ? Number(v.changeChargeAmount) : Number(v.chargeAmount);
            })
            //총 부가세
            data.selectedList.forEach(v => {
                sumChangePublishVat += v.changeChargeVat ? Number(v.changeChargeVat) : Number(v.chargeVat);
            })
            //부가세 체크
            if((sumChangePublishAmount * 0.1) !== Number(sumChangePublishVat)){
                diffVat = true;
            }
        }

        if(!await callConfirm(`총 발행 금액 ${sumChangePublishAmount.toLocaleString()}원, ${diffVat ? '부가세 계산이 10%가 아닙니다. 그래도 ' : ''}계산서를 발행 하시겠습니까? (${BillSearchStore.condition.publishSendType === '1' ? '정발행' : '역발행'})`)){
            return;
        }
        
        const param = {
            billPublishCompany: companyCode,
            billPublishDate: billPublishDate.startDate,
            billPublishType: BillSearchStore.condition.billPublishType,
            taxType: BillSearchStore.condition.taxType,
            // billItem: item,
            // billItemQty: qty,
            // billNote: data.billNote,
            // billItemNote: BillSearchStore.condition.billItemNote,
            isAutoSendBill: BillSearchStore.condition.isAutoSendBill,
            publishSendType: BillSearchStore.condition.publishSendType,
            billingPublishList,
            // billItemList: data.selectedList
        }
        
        if (BillSearchStore.condition.billPublishType === '2') {
            LUtils.assign(param, {billNote: data.billNote, billItemList: data.selectedList, chargeAmount: sumChangePublishAmount, chargeVat: sumChangePublishVat});
        } 


        const result = await axios.post('/bill', param);
        showToast('발행되었습니다.');
        //재조회
        await getBillingTargetList();
    }


    const billItemPopup = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.billItem}</div>
                {/* {e.data.sendBill !== 1 &&  */}
                    <IconBtn key={e.rowIndex} 
                            style={{paddingTop: 6, color:'darkblue', textAlign: 'right'}} 
                            onClick={async () => {
                                setContractNo(e.data.contractNo);
                                setBillingSeq(e.data.billingSeq);
                                if (selView === 'search') {
                                    const billItemList = await axios.get(`/bill/item/${e.data.billNo}`);
                                    if (billItemList.length === 0) {
                                        showToast('조회된 데이터가 없습니다.');
                                        return;
                                    }
                                    setChargeTotalAmount(Number(e.data.orgChargeAmount));
                                    BillSearchStore.setTargetBillItemList(billItemList);
                                } else if (selView === 'billing') {
                                    let billItemList = await getBillingChargeItemList(e.data.billingMonth, e.data.contractNo, e.data.billingType);
                                    if (billItemList.length !== 0){
                                        BillSearchStore.setTargetBillItemList(billItemList);
                                    } else {
                                        BillSearchStore.setTargetBillItemList(e.data.billItemList);
                                    }
                                    setChargeTotalAmount(Number(e.data.orgChargeAmount));
                                }
                                setGridSeq(e.data.agId); 
                                setAccountBillNote(e.data.billNote);
                                AppStore.toggleOpenBillItemPopup();
                            }} />
                {/* } */}
            </div>
        )
    }

    const getBillingChargeItemList = async (billingMonth, contractNo, billingType) => {
        return await axios.get(`/bill/charge/item`, {params: {billingMonth: billingMonth, contractNo: contractNo, billingType: billingType}});
    }

    const callbackFn = async (data) => {

        if (BillSearchStore.condition.billPublishType === '1') {
            BillSearchStore.billingList[gridSeq].billItemList = data.selectedList;
            BillSearchStore.billingList[gridSeq].billNote = data.billNote;
            BillSearchStore.billingList[gridSeq].changeChargeAmount = data.changeChargeAmount;
            BillSearchStore.billingList[gridSeq].changeChargeVat = data.changeChargeVat;
            BillSearchStore.billingList[gridSeq].remark = data.changeRemark;
            BillSearchStore.billingList[gridSeq].isSelected = true;

            if (data.isChanged) {
                const param = {billingMonth: BillSearchStore.billingList[gridSeq].billingMonth, 
                                contractNo: BillSearchStore.billingList[gridSeq].contractNo,
                                billingType: BillSearchStore.billingList[gridSeq].billingType,
                                billingChargeItemList: data.selectedList};
                await axios.post('bill/item', param);
            }

            BillSearchStore.billingList[gridSeq].billItem = data.selectedList.length > 1 ? data.selectedList[0].item + " (외)" : data.selectedList[0].item; 
            BillSearchStore.billingList[gridSeq].chargeAmount = data.selectedList.length > 1 ? data.selectedList.reduce((total, v) => total + v.supplyAmount, 0) : data.selectedList[0].supplyAmount;
            BillSearchStore.billingList[gridSeq].chargeVat = data.selectedList.length > 1 ? data.selectedList.reduce((total, v) => total + v.taxAmount, 0) : data.selectedList[0].taxAmount;
            BillSearchStore.billingList[gridSeq].chargeTotalAmount = data.selectedList.length > 1 ? data.selectedList.reduce((total, v) => total + v.supplyAmount + v.taxAmount , 0) : data.selectedList[0].supplyAmount + data.selectedList[0].taxAmount;

            gridRef.current.api.redrawRows();
            showToast('저장되었습니다.');
        } else if (BillSearchStore.condition.billPublishType === '2') {
            publishBill(data);
        }
    }

    const sendSmsPopup = () => {
        const selectedList = BillSearchStore.billPublishedList.filter(v => v.isSelected).map(v => {return {...v, billingAddressPhoneNumber: decrypt(v.billingAddressPhoneNumber)}});

        if(selectedList.length === 0) {
            showToast('계산서를 선택해주세요.');
            return;
        }

        if(!selectedList.every(v => v.isSendBill === 1)) {
            showToast('국세청 발송이 완료된 세금계산서만 선택해주세요.');
            return;
        }
    
        TaxBillStore.setSendTargetList(selectedList);
        AppStore.toggleSendTaxBillSmsPopup();
    }

    return(
        <>
            {/* 검색조건 */}
            <BillManagerSearch getBillPublishedList={getBillPublishedList} getBillingTargetList={getBillingTargetList} selView={selView} setSelView={setSelView} selSearchUserType={selSearchUserType} setSelSearchUserType={setSelSearchUserType}
                                billingSearchDate={billingSearchDate} setBillingSearchDate={setBillingSearchDate} billPublishDate={billPublishDate} setBillPublishDate={setBillPublishDate} selectAmount={selectAmount} selectVat={selectVat} 
                                companyCode={companyCode} setCompanyCode={setCompanyCode} publishBill={publishBill} gridRef={gridRef} chargeTotalAmount={chargeTotalAmount} setChargeTotalAmount={setChargeTotalAmount}
                                publishedCompanyCode={publishedCompanyCode} setPublishedCompanyCode={setPublishedCompanyCode}                    
            />
            <CContainer>
                {selView === 'billing' &&
                    <AgGridContainer
                        gridRef={gridRef}
                        ref={billTargetRef}
                        gridTitle={'계산서 발행 대상'}
                        height={60}
                        rowData={BillSearchStore.billingList}
                        columnDefs={[
                            {field: "companyName", headerName: "회사", width: 150},
                            {field: "billingMonth", headerName: "청구월"},
                            {field: "billingDay", headerName: "청구일"},
                            {field: "groupBillingSeq", headerName: "청구회차"},
                            {
                                field: "contractType",
                                headerName: "렌탈유형",
                                width: 110,
                                valueFormatter: v => findCommKrnm(v.value, '1')
                            },
                            {field: "customerName", headerName: "고객명", width: 180},
                            {field: "siteName", headerName: "현장명", width: 180},
                            {field: "billingAddressSeq", headerName: "청구지", width: 100},
                            {field: "billingAddressName", headerName: "청구지명", width: 140},
                            {field: "customerSubNo", headerName: "종사업장번호", width: 130},
                            {field: "contractNo", headerName: "계약번호", width: 160},
                            {field: "paymentType", headerName: "결제방법", width: 130, valueFormatter: v => findCommKrnm(v.value, '17')},
                            {field: "billingType", headerName: "청구구분", width: 120, valueFormatter: v => findCommKrnm(v.value, '23')},
                            {
                                field: "orgChargeAmount",
                                headerName: "원청구금액",
                                width: 130,
                                cellClass: 'ag-grid-money-align',
                                valueFormatter: v => v.value?.toLocaleString()
                            },
                            {
                                field: "chargeAmount",
                                headerName: "청구금액",
                                width: 130,
                                cellClass: 'ag-grid-money-align',
                                valueFormatter: v => v.value?.toLocaleString()
                            },
                            {
                                field: "chargeVat",
                                headerName: "청구 부가세",
                                cellClass: 'ag-grid-money-align',
                                valueFormatter: v => v.value?.toLocaleString(),
                                width: 130
                            },
                            {
                                field: "chargeTotalAmount",
                                headerName: "청구총액",
                                cellClass: 'ag-grid-money-align',
                                valueFormatter: v => v.value?.toLocaleString(),
                                width: 130
                            },
                            {
                                field: "isReverseBill",
                                headerName: "역발행",
                                width: 100,
                                valueFormatter: v => v.value === 1 ? 'Y' : ''
                            },
                            {
                                field: "billItem", 
                                headerName: "품목", 
                                width: 320,
                                cellRenderer: e => billItemPopup(e),
                                hide: BillSearchStore.condition.billPublishType === '2'
                            },
                            {
                                field: "billItemQty",
                                headerName: '수량',
                                width: 80,
                                editable: true,
                                cellClass: 'ag-grid-money-align',
                                headerClass: 'grid-column-required',
                                hide: true,
                            },
                            {
                                field: "billNote",
                                headerName: '계산서 비고',
                                width: 160,
                                editable: true,
                                headerClass: 'grid-column-editable',
                                valueFormatter: v => v.data.isSelected ? v.value : '',
                                hide: BillSearchStore.condition.billPublishType === '2'
                            },
                            {
                                field: "billItemNote",
                                headerName: '품목 비고',
                                width: 160,
                                hide: true,
                                // editable: true,
                                // headerClass: 'grid-column-editable',
                            },
                            {
                                field: "changeChargeAmount",
                                headerName: "변경 청구금액",
                                minWidth: 140,
                                // editable: true,
                                cellClass: 'ag-grid-money-align',
                                // headerClass: 'grid-column-editable',
                                valueFormatter: v => v.value?.toLocaleString(),
                                hide: true,
                            },
                            {
                                field: "changeChargeVat",
                                headerName: "변경 청구 부가세",
                                minWidth: 150,
                                // editable: true,
                                cellClass: 'ag-grid-money-align',
                                // headerClass: 'grid-column-editable',
                                valueFormatter: v => v.value?.toLocaleString(),
                                hide: true,
                            },
                            {
                                field: "remark",
                                headerName: '변경 사유',
                                minWidth: 200,
                                // editable: true,
                                // headerClass: 'grid-column-editable',
                                hide: true,
                            },
                            {
                                field: "saleUserSeq",
                                headerName: "담당 영업사원",
                                minWidth: 140,
                                valueFormatter: v => getUserName(v.value)
                            },
                            // {field: "revisedIsseu", headerName: "수정발행", width: 100, cellRenderer: e => popupRevisedIssue(e)},
                        ]}
                        isCheckBox={true}
                        originList={[]}
                        getCheckboxClickEvent={getCheckboxClickEvent}
                        getCellChange={getCellChange}
                        pinnedTopRowData={pinnedTopBillingData}
                        seqColumn={'agId'}
                    />
                }
                {selView === 'search' &&
                    <AgGridContainer
                        gridRef={billGridRef}
                        gridTitle={'계산서 목록'}
                        height={60}
                        rowData={BillSearchStore.billPublishedList}
                        columnDefs={[
                            {field: "companyName", headerName: "발행 공급사", width: 150},
                            {field: "isCard", headerName: "결제구분", valueFormatter: v => v.value === 'pinnedTopRowData' ? '' : v.value ? '카드' : '세금계산서', width: 120, hide: userCompanyCode !== '2301001'},
                            {field: "isSendBill", headerName: "발송여부", width: 110,
                                valueFormatter: v => v.data.isCancel === 1 ? '삭제' : v.value === 0 ? '미발송' : v.value === 1 ? '발송' : '실패',
                                cellClass: v => v.data.isCancel === 1 ? 'ag-grid-column-cancel' : v.value === 1 ? 'ag-grid-column-complete' : 'ag-grid-column-wait',
                            },
                            {field: "billNo", headerName: "계산서번호", width: 120},
                            {field: "orgBillDate", headerName: "계산서발행일", hide:true},
                            {
                                field: "billDate",
                                headerName: "계산서발행일",
                                width: 120,
                                //headerClass: 'grid-column-required',
                                //onCellValueChanged: handlePublishDateChanged,
                                //editable: true
                            },
                            {field: "customerName", headerName: "고객명", width: 160},
                            {field: "billingAddressSeq", headerName: "청구지", width: 100},
                            {field: "billingAddressName", headerName: "청구지명", width: 140},
                            {field: "customerSubNo", headerName: "종사업장번호", width: 130},
                            {field: "billAmount", headerName: "발행단가", width: 130, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                            {field: "billVat", headerName: "부가세", width: 110, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                            {field: "billTotalAmount", headerName: "총 발행 금액", width: 130, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
/*
                            {field: "orgMonthTotalAmount", headerName: "원 청구 총액", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                            {field: "nonBillAmount", headerName: "미발행 금액", width: 110,
                                cellClass: v => v.value !== 0 ? 'ag-grid-column-cancel ag-grid-money-align' : 'ag-grid-money-align',
                                valueFormatter: v => v.value?.toLocaleString()
                            },
*/
                            {
                                field: "billItem", 
                                headerName: "품목", 
                                width: 320,
                                cellRenderer: e => billItemPopup(e),
                            },
                            {field: "billItemQty", headerName: "수량", width: 80},
                            {field: "billNote", headerName: '계산서 비고', width: 160},
                            {field: "groupContractNo", headerName: '계산서 대상 계약번호', width: 200},
                            {field: "isReverseBill", headerName: "역발행", width: 100, valueFormatter: v => v.value === 1 ? 'Y' : ''},
                            {field: "taxType", headerName: "과세유형", width: 110, valueFormatter: v => findCommKrnm('45', v.value)},
                            {field: "taxNo", headerName: "국세청 승인 번호", width: 150},
                            {field: "sendBillDate", headerName: "발송일", width: 170, hide: true},
                            {field: "sendErrCode", headerName: "발송완료코드", width: 130},
                            {field: "sendErrMsg", headerName: "발송완료메세지", width: 200},
                            {
                                field: "smsCnt", 
                                headerName: "알림톡 전송 여부",
                                valueFormatter: v => v.value > 0 ? '전송' : '', 
                                cellClass: v => v.data.smsCnt > 0 ? 'ag-grid-column-complete' : '',
                                width: 140
                            },
                            {
                                field: "emailCnt", 
                                headerName: "이메일 전송 여부", 
                                valueFormatter: v => v.value > 0 ? '전송' : '',
                                cellClass: v => v.data.emailCnt > 0 ? 'ag-grid-column-complete' : '',
                                width: 140
                            },
                            {field: "isMinusBill", headerName: "(-)계산서", width: 110, valueFormatter: v => v.value === 1 ? 'Y' : ''},
                            {field: "minusTargetBillNo", headerName: "대상 계산서번호", width: 150},
                            {field: "creator", headerName: "발행자", width: 100, valueFormatter: v => getUserName(v.value)},
                            {field: "createdDate", headerName: "발행일자", width: 180},
                            {field: "updater", headerName: "수정자", width: 100, valueFormatter: v => getUserName(v.value)},
                            {field: "updatedDate", headerName: "수정일자", width: 180},
                        ]}
                        isCheckBox={true}
                        originList={[]}
                        callBackGridData={callBackGridData}
                        selectBtnInfo={deleteBillBtn}
                        getRowStyle={(params) => {
                            if(params.data.isCancel === 1){
                                return {pointerEvents: 'none', color: '#bababa' }
                            }
                            return null;
                        }}
                        customBtnInfo={[
                            {
                                isUsed: true,
                                callbackFn: () => tradingStatementBtn(),
                                title: '거래명세서',
                                icon: 'fi-rs-receipt',
                                width: 130,
                            },
                            {
                                isUsed: true,
                                callbackFn: () => sendEmailPopup(),
                                title: '메일 전송',
                                icon: 'fi-rr-envelope',
                                width: 120,
                            },
                            {
                                isUsed: true,
                                callbackFn: () => sendSmsPopup(),
                                title: '계산서 알림톡 전송',
                                icon: 'fi-rr-envelope',
                                width: 160,
                            }
                        ]}
                        useCsvDownload={true}
                        pinnedTopRowData={pinnedTopBillData}
                        seqColumn={'billNo'}
                        rowDoubleClickCallback={rowDoubleClickCallback}
                    />
                }

            </CContainer>

            <CustomerSearch callbackFn={getCustomerInfo}/>
            <UserSearch callbackFn={getUserInfo} />
            <CustomerBillingAddressSearch customerNo={BillSearchStore.condition.customerNo} callbackFn={getBillingAddressInfo} />
            <Modal title={'거래명세서'} onExit={closeModal}>
                <TradingStatementPopup sendTradingStatement={sendTradingStatement} billNo={billNo} row={row} setIsOneRow={setIsOneRow} />
            </Modal>
            <SendTradingStatementPopup sendTradingStatement={sendTradingStatement} isOneRow={isOneRow} setIsOneRow={setIsOneRow} />
            <BillPublishPopup callbackFn={publishBill}/>
            <SendTaxBillPopup />
            <BillItemPopup callbackFn={callbackFn} 
                gridSeq={gridSeq} 
                selView={selView} 
                chargeTotalAmount={chargeTotalAmount}
                contractNo={contractNo} 
                billingSeq={billingSeq} 
                getBillingChargeItemList={getBillingChargeItemList} 
                getBillingTargetList={getBillingTargetList} 
                accountBillNote={accountBillNote}
                billPublishDate={billPublishDate}
                setBillPublishDate={setBillPublishDate}
            />
            <TaxBillDetailPopup taxBillData={taxBillData} isSendSmsBtn={true}/>
            <SendTaxBillSmsPopup getBillPublishedList={getBillPublishedList}/>
        </>
    );
}

export default observer(BillManager);
