import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { observer } from "mobx-react-lite";
import { useReactToPrint } from "react-to-print";
import { Col, Row } from "react-bootstrap";

import { decrypt, showToast, businessNumberFormat } from "../../../common/utils";
import { BillSearchStore } from "../../../store/billing/BillSearchStore";
import { CContainer, CIconBtn, CSaveBtn } from "../../../components/CustomContainer";
import ReportTradingStatementTemplate from "../../../components/report/ReportTradingStatementTemplate";
import { callConfirm } from "../../../utils";
import { AppStore } from "../../../store/AppStore";
import { findCommKrnm } from "../../../utils/commcode";

const TradingStatementPopup =(props, ref)=> {
    const divRef = useRef(null);
    const excelRef = useRef(null);
    const printRef = useRef();
    const [htmlCode, setHtmlCode] = useState('');

    const store = BillSearchStore;

    useEffect(() => {
        store.emptyInputList = [];
        getTradingStatement();
    }, []);

    /* 거래명세서 조회 */
    const getTradingStatement = async () => {
        const billNo = props.billNo ? [props.billNo] : store.billPublishedList.filter(v => v.isSelected).map(v => v.billNo);
        const params = {
            billNo: store.billNo ? store.billNo : billNo[0],
            searchStartDate: store.condition.searchStartDate,
            searchEndDate: store.condition.searchEndDate,
        }
        const result = await axios.get('/bill/trading/statement', { params: params });
        store.tradingStatementList = result;
        const addRow = {month: '', day: '', model: '', managementNo: '', startDate: '', from: '', endDate: '', standard: '', qty: '', chargeAmount: '', chargeTotalAmount: '', chargeVat: '', sumChargeAmount: '', totlaAmount: '', remark: '', customerNo: result[0].customerNo, billingAddressSeq: result[0].billingAddressSeq, companyCode: result[0].companyCode};
        for(let i=1; i <3; i++) {   // 2행 추가
            store.emptyInputList.push({...addRow, seq: i, billNo: store.billNo ? store.billNo : billNo[0]});
        }

        store.tradingStatementList.forEach(item => {
            item.startDate = item.startDate === null ? '' : item.startDate;
            item.endDate = item.endDate === null ? '' : item.endDate;
            if(item.startDate && item.endDate) item.from = '~';
        });

        // TODO: setHtmlCode 하는 부분 확인
        if(result[0] && result[0].code != null && result[0].code) setHtmlCode(result[0].code);
        if(!diffAmount()) return showToast('계산서 발행금액과 단가가 일치하지 않습니다. 단가를 수정해주세요.');
    }

    // 합계 비교
    const diffAmount = () => {
        
        const sumAmount = store.tradingStatementList.reduce((acc, item) => {
            const amount = typeof item.chargeTotalAmount === 'string' 
                ? item.chargeTotalAmount.replace(/,/g, '')
                : item.chargeTotalAmount;
        
            return Number(acc) + Number(amount);
        }, 0);
        const sumInputAmount = store.emptyInputList.reduce((acc, item) => {
            const amount = isNaN(item.chargeTotalAmount) ? 0 : Number(item.chargeTotalAmount);
            return acc + amount;
        }, 0);
  
        return sumAmount + sumInputAmount === store.amount;
    }

    // 거래명세서 저장
    const saveHtml = async () => {
        if(!diffAmount()) return showToast('단가의 합계를 확인해주세요.');

        const emptyInputList = store.emptyInputList.filter(item => item.model);
        const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

        if((store.tradingStatementList.every(item => item.startDate !== '' || item.endDate !== '') && (!store.tradingStatementList.every(item => dateFormatRegex.test(item.startDate) && dateFormatRegex.test(item.endDate))) 
            || (emptyInputList.length > 0 && emptyInputList.every(item => (item.startDate !== '' || item.endDate !== '') && (!emptyInputList.every(item => dateFormatRegex.test(item.startDate) && dateFormatRegex.test(item.endDate))))))) {
            return showToast("품목의 시작일 종료일을 YYYY-MM-DD 형식에 맞게 작성해주세요.");
        };

        if(!await callConfirm('저장 하시겠습니까?')) return;
        store.tradingStatementList.map(v => {
            if (typeof v.chargeTotalAmount === 'string') {
                v.chargeTotalAmount = v.chargeTotalAmount.replace(/,/g, '');
            }
        });
        axios.put('bill/trading/statement', {specificationList: store.tradingStatementList, emptyInputList: emptyInputList});

        if (divRef.current) {
            const htmlString = divRef.current.outerHTML;
            const list = props.billNo ? props.row : store.billPublishedList.filter(v => v.isSelected);
            const params = {
                billNo: props.billNo ? props.billNo : store.billNo ? store.billNo : list[0].billNo,
                customerNo: list[0].customerNo,
                billingAddressSeq: list[0].billingAddressSeq,
                code: htmlString.replace('”', '"'),
            }
            axios.post('bill/trading/statement',  params)
                .then((result) => {
                    showToast('저장했습니다.');
                    if(store.billNo) {
                        const updatedList = store.sendTradingStatementList.map(item => {
                            if (item.billNo === store.billNo) {
                              item.code = result;
                            }
                            return item;
                        });
                        store.sendTradingStatementList = [updatedList];
                        store.billNo = '';
                        AppStore.toggleModal();
                    }else {
                        const billNo = props.billNo ? [props.billNo] : store.billPublishedList.filter(v => v.isSelected).map(v => v.billNo);

                        const updatedList = store.sendTradingStatementList.map(item => {
                            if (item.billNo === billNo[0]) {
                              item.code = result;
                            }
                            return item;
                        });
                        // store.sendTradingStatementList = updatedList;
                        // store.emptyInputList = [];
                        setHtmlCode(result);
                    }
                });
        }
        // showToast("저장했습니다.");
    }

    const printBtn = () => {
        if(!diffAmount()) return showToast('단가의 합계를 확인해주세요.');
        alert('페이지 레이아웃을 가로방향으로 설정해주세요.');

        print();
    }

    const print = useReactToPrint({
        content: () => printRef.current,
    });

    const inputFrom = (data) => {
        if(data.startDate && data.endDate) data.from = '~';
        else data.from = '';
    }

    /* 청구지, 현장명으로 메일 주소 담기 */
    const getEmailList = async() => {
        const list = props.billNo ? props.row : store.billPublishedList.filter(v => v.isSelected);
        store.emailBillNo = list[0]?.billNo;
        const customerNo = list[0]?.customerNo;
        const billingAddressSeq = list[0]?.billingAddressSeq;
        const siteSeqGroup = list[0]?.siteSeqGroup;
        BillSearchStore.sendTradingStatementList[0].subject = `[${list[0].customerName}] ${list[0].billDate.slice(0, -3)} ${list[0].companyName} 거래 명세서`;
        BillSearchStore.sendTradingStatementList[0].content = `안녕하세요 ${list[0].customerName}님\n\n${list[0].billDate.slice(0, -3)} 거래 명세서를 전달 드리오니\n검토해 주시기 바랍니다.\n\n감사합니다.`;     

        const result = await axios.get(`/bill/email`, {params: {customerNo, billingAddressSeq, siteSeqGroup}});
        store.emailList[store.emailBillNo] = result.filter(v => decrypt(v.email) !== '').map(v => {return {...v, isSelected: true}});

        props.setIsOneRow(true);
        AppStore.toggleSendTradingStatementPopup();
    }

    return (
        <>
            <div className="trading-statement" ref={divRef}>
                <h3 style={{textAlign: 'center', marginBottom: 20}}>거래명세표</h3>
                <table className="table trading-statement-table" style={{borderWidth: 1, borderTop: 'solid 2px', borderBottom: 'solid 2px', borderLeft: 'dashed 1px', borderRight: 'dashed 1px'}}>
                    <thead style={{borderBottom: 'solid 2px'}}>
                        <tr>
                            <th className='border' style={{width: '50%', textAlign: 'center'}} colSpan={4}>공급자</th>
                            <th className='border' style={{width: '50%', textAlign: 'center'}} colSpan={4}>공급받는자</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{textAlign: 'center', verticalAlign: 'middle'}}>
                            <td className='p-1 border' >등록번호</td>
                            <td className='p-1 border data-text' colSpan={3}>{businessNumberFormat(store.tradingStatementList[0]?.companyBusinessNumber) || ''}</td>
                            <td className='p-1 border'>등록번호</td>
                            <td className='p-1 border data-text' colSpan={3}>{businessNumberFormat(store.tradingStatementList[0]?.customerBusinessNumber) || ''}</td>
                        </tr>
                        <tr style={{verticalAlign: 'middle'}}>
                            <td className='p-1 border' style={{width: '7%', textAlign: 'center'}}>상 호</td>
                            <td className='p-2 border data-text' style={{width: '18%'}}>{store.tradingStatementList[0]?.companyName || ''}</td>
                            <td className='p-1 border' style={{width: '7%', textAlign: 'center'}}>대 표</td>
                            {store.tradingStatementList[0]?.signatureImageUrl ? (
                                <td className='p-2 border data-text' style={{width: '18%', verticalAlign: 'bottom'}}>
                                    {store.tradingStatementList[0]?.companyOwner || ''}
                                    <img src={store.tradingStatementList[0]?.signatureImageUrl} style={{ float: 'right', height: '20px' }} alt="signature image" />
                                    
                                </td>
                            ) : (
                                <td className='p-2 border data-text' style={{width: '18%'}}>
                                    {store.tradingStatementList[0]?.companyOwner || ''}                                    
                                </td>
                            )}
                            <td className='p-1 border' style={{width: '7%', textAlign: 'center'}}>상 호</td>
                            <td className='p-2 border data-text' style={{width: '18%'}}>{store.tradingStatementList[0]?.customerName || ''}</td>
                            <td className='p-1 border' style={{width: '7%', textAlign: 'center'}}>대 표</td>
                            <td className='p-2 border data-text' style={{width: '18%'}}>{store.tradingStatementList[0]?.customerOwner || ''}</td>
                        </tr>
                        <tr style={{verticalAlign: 'middle'}}>
                            <td className='p-1 border' style={{textAlign: 'center'}}>주 소</td>
                            <td className='p-2 border data-text' colSpan={3}>{store.tradingStatementList[0]?.companyAddress || ''}</td>
                            <td className='p-1 border' style={{textAlign: 'center'}}>주 소</td>
                            <td className='p-2 border data-text' colSpan={3}>{store.tradingStatementList[0]?.customerAddress || ''}</td>
                        </tr>
                        <tr style={{verticalAlign: 'middle'}}>
                            <td className='p-1 border' style={{textAlign: 'center'}}>업 태</td>
                            <td className='p-2 border data-text' style={{width: '18%'}}>{store.tradingStatementList[0]?.companyBusinessConditions || ''}</td>
                            <td className='p-1 border' style={{textAlign: 'center'}}>종 목</td>
                            <td className='p-2 border data-text' style={{width: '18%'}}>{store.tradingStatementList[0]?.companyIndustryType || ''}</td>
                            <td className='p-1 border' style={{textAlign: 'center'}}>업 태</td>
                            <td className='p-2 border data-text' style={{width: '18%'}}>{store.tradingStatementList[0]?.customerBusinessConditions || ''}</td>
                            <td className='p-1 border' style={{textAlign: 'center'}}>종 목</td>
                            <td className='p-2 border data-text' style={{width: '18%'}}>{store.tradingStatementList[0]?.customerIndustryType || ''}</td>
                        </tr>
                    </tbody>
                </table>
                <br/>

                <table ref={excelRef} className="table" style={{borderWidth: 1, borderTop: 'solid 2px', borderBottom: 'solid 2px', borderLeft: 'dashed 1px', borderRight: 'dashed 1px', tableLayout: 'fixed'}}>
                    <thead style={{borderBottom: 'solid 2px'}}>
                        <tr>
                            <th className='border' style={{textAlign: 'center', width: '3%'}}>월</th>
                            <th className='border' style={{textAlign: 'center', width: '3%'}}>일</th>
                            <th className='border' style={{textAlign: 'center', width: '11%'}}>모델</th>
                            <th className='border' style={{textAlign: 'center', width: '11%'}}>관리번호</th>
                            <th className='border' style={{textAlign: 'center', width: '10%'}}>사용시작일</th>
                            <th className='border' style={{textAlign: 'center', width: '10%'}}>사용종료일</th>
                            <th className='border' style={{textAlign: 'center', width: '5%'}}>수량</th>
                            <th className='border' style={{textAlign: 'center', width: '9%'}}>단가(월)</th>
                            <th className='border' style={{textAlign: 'center', width: '9%'}}>공급가액</th>
                            <th className='border' style={{textAlign: 'center', width: '9%'}}>세액</th>
                            <th className='border' style={{textAlign: 'center'}}>비고</th>
                        </tr>
                    </thead>
                    <tbody className="tbody-class">
                        {store.tradingStatementList?.map((data, index) => {
                            return(
                                <tr key={index}>
                                    <td className='p-1 border data-text' style={{width: '3%'}}>
                                        {
                                            <input value={data.month || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.month = v.target.value} />
                                        }
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '3%'}}>
                                        {
                                            <input value={data.day || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.day = v.target.value} />
                                        }
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '8%'}}>
                                        {
                                            <input className="pl-2" value={data.model || ''} style={{width: '100%'}} onChange={(v)=> data.model = v.target.value} />
                                        }
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '11%'}}>
                                        {
                                            <input className="pl-2" value={data.managementNo || ''} style={{width: '100%'}} onChange={(v)=> data.managementNo = v.target.value} />
                                        }
                                    </td>
                                    <td className='p-1 border data-text five-td' style={{width: '3%'}}>
                                        {
                                            <input value={data.startDate || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> {data.startDate = v.target.value, inputFrom(data)}} />
                                        }
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '11%'}}>
                                        {
                                            <input value={data.endDate || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> {data.endDate = v.target.value, inputFrom(data)}} />
                                        }
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '5%'}}>
                                        {
                                            <input 
                                                value={data.qty} 
                                                style={{width: '100%', textAlign: 'center'}} 
                                                onChange={(v)=> {
                                                    // const chargeAmount = typeof data.chargeAmount === 'string' ? Number(data.chargeAmount.replace(/,/g, '')) : data.chargeAmount;
                                                    data.qty = Number(v.target.value) || 0;
                                                    // data.chargeTotalAmount = Number(chargeAmount || 0) * data.qty;
                                                    // data.chargeVat = (data.chargeTotalAmount || 0) * 0.1;
                                                }} 
                                            />
                                        }
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '10%'}}>
                                        {
                                            <input 
                                                className="ag-grid-money-align" 
                                                value={data.unitPrice?.toLocaleString() || ''} 
                                                style={{width: '100%'}} 
                                                onChange={(v)=> {
                                                    const unitPrice = typeof v.target.value === 'string' ? Number(v.target.value.replace(/,/g, '')) : v.target.value;
                                                    data.unitPrice = unitPrice;
                                                }}
                                            />
                                        }
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '10%'}}>
                                        {
                                            <input 
                                                className="ag-grid-money-align" 
                                                value={data.chargeTotalAmount?.toLocaleString() || ''} 
                                                style={{width: '100%'}} 
                                                onChange={(v)=> {
                                                    const chargeTotalAmount = typeof v.target.value === 'string' ? Number(v.target.value.replace(/,/g, '')) : v.target.value;
                                                    data.chargeTotalAmount = chargeTotalAmount;
                                                    data.chargeVat = chargeTotalAmount * 0.1;
                                                }}
                                            />
                                        }
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '8%'}}>
                                        {
                                            <input 
                                                className="ag-grid-money-align" 
                                                value={data.chargeVat?.toLocaleString() || ''} 
                                                style={{width: '100%'}} 
                                                onChange={(v)=> {
                                                    const chargeVat = typeof v.target.value === 'string' ? Number(v.target.value.replace(/,/g, '')) : v.target.value;
                                                    data.chargeVat = chargeVat;
                                                }} 
                                            />
                                        }
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '28%'}}>
                                        {
                                            <input value={data.remark || ''} style={{width: '100%', textAlign: 'left'}} onChange={(v)=> data.remark = v.target.value} />
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                        {store.emptyInputList.map(data => {
                            return (
                                <tr key={data.seq}>
                                    <td className='p-1 border data-text' style={{width: '3%'}}>
                                        {<input value={data.month || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.month = v.target.value} />}
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '3%'}}>
                                        {<input value={data.day || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.day = v.target.value} />}
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '20%'}}>
                                        {<input className="pl-2" value={data.model || ''} style={{width: '100%'}} onChange={(v)=> data.model = v.target.value} />}
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '11%'}}>
                                        {<input value={data.managementNo || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.managementNo = v.target.value} />}
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '9%'}}>
                                        {<input value={data.startDate || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> {data.startDate = v.target.value, inputFrom(data)}} />}
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '9%'}}>
                                        {<input value={data.endDate || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> {data.endDate = v.target.value, inputFrom(data)}} />}
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '5%'}}>
                                        {
                                           <input 
                                                value={data.qty || ''} 
                                                style={{width: '100%', textAlign: 'center'}} 
                                                onChange={(v)=> {
                                                    data.qty = v.target.value;
                                                    // const chargeAmount = data.chargeAmount !== null && typeof data.chargeAmount === 'string' ? Number(data.chargeAmount.replace(/,/g, '')) : data.chargeAmount;
                                                    // data.qty = Number(v.target.value) || 0;
                                                    // data.chargeTotalAmount = Number(chargeAmount || 0) * data.qty;
                                                    // data.chargeVat = (data.chargeTotalAmount || 0) * 0.1;
                                                }} 
                                            />
                                        }
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '12%'}}>
                                        {
                                            <input 
                                                className="ag-grid-money-align" 
                                                value={data.unitPrice?.toLocaleString() || ''} 
                                                style={{width: '100%'}} 
                                                onChange={(v)=> {
                                                    data.unitPrice = typeof v.target.value !== 'string' ? Number(v.target.value.replace(/,/g, '')) : v.target.value;
                                                    // const chargeAmount = typeof v.target.value === 'string' ? Number(v.target.value.replace(/,/g, '')) : v.target.value;
                                                    // data.chargeAmount = v.target.value;
                                                    // data.chargeTotalAmount = Number(chargeAmount || 0) * data.qty;
                                                    // data.chargeVat = (data.chargeTotalAmount || 0) * 0.1;
                                                }} 
                                            />
                                        }
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '12%'}}>
                                        {
                                            <input 
                                                className="ag-grid-money-align" 
                                                value={data.chargeTotalAmount?.toLocaleString() || ''} 
                                                style={{width: '100%'}} 
                                                onChange={(v)=> {
                                                    data.chargeTotalAmount = typeof v.target.value !== 'string' ? Number(v.target.value.replace(/,/g, '')) : v.target.value
                                                    // const chargeTotalAmount = typeof v.target.value === 'string' ? Number(v.target.value.replace(/,/g, '')) : v.target.value;
                                                    // data.chargeTotalAmount = v.target.value;
                                                    // data.chargeVat = chargeTotalAmount * 0.1;
                                                }}
                                            />
                                        }
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '8%'}}>
                                        {<input className="ag-grid-money-align" value={data.chargeVat || ''} style={{width: '100%'}} onChange={(v)=> data.chargeVat = v.target.value} />}
                                    </td>
                                    <td className='p-1 border data-text' style={{width: '9%'}}>
                                        {<input value={data.remark || ''} style={{width: '100%', textAlign: 'center'}} onChange={(v)=> data.remark = v.target.value} />}
                                    </td>
                                </tr>
                            )
                        })}     
                    </tbody>
                </table>

                <table className="table" style={{borderWidth: 1, borderTop: 'solid 2px', borderBottom: 'solid 2px', borderLeft: 'dashed 1px', borderRight: 'dashed 1px', height: 30}}>
                    <tbody>
                        <tr style={{textAlign: 'center', verticalAlign: 'middle'}}>
                            <td className='p-1 border'>계</td>
                            <td className='p-1 border data-text ag-grid-money-align' style={{width: '20%'}}>₩ {store.amount?.toLocaleString()}</td>
                            <td className='p-1 border'>세액</td>
                            <td className='p-1 border data-text ag-grid-money-align' style={{width: '20%'}}>₩ {store.vat?.toLocaleString()}</td>
                            <td className='p-1 border'>합계</td>
                            <td className='p-1 border data-text ag-grid-money-align' style={{width: '20%'}}>₩ {store.totalAmount?.toLocaleString()} </td>
                            <td className='p-2 border data-text' style={{width: '20%', textAlign: 'left'}}>
                                {'인수자'}
                                <span style={{float: 'right', paddingRight: 10}}>(인)</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='data-text' style={{fontSize: 13, textAlign: 'left', marginRight: 20}}>입금계좌: {findCommKrnm(store.tradingStatementList[0]?.bankCode || '', '24')} {store.tradingStatementList[0]?.accountNumber || ''} , 예금주: {store.tradingStatementList[0]?.accountHolder || ''}</div>
                <br/>
            </div>

            <CContainer>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CIconBtn title={'프린트'} style={{width: 120}} variant={'primary'} icon={'fi-rr-print'} onClick={printBtn} />
                        {htmlCode && <CIconBtn style={{width: 120}} title={'메일전송'} onClick={getEmailList} icon={'fi-rr-envelope'} variant={'secondary'} />}
                        <CSaveBtn title={'저장'} variant={'success'} onClick={saveHtml} />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <a href='https://www.ilovepdf.com/ko/pdf_to_excel' target="_blank">PDF를 엑셀로 변환하기</a>
                    </Col>
                </Row>
            </CContainer>
            <ReportTradingStatementTemplate ref={printRef} />
        </>  
    );
};

export default observer(TradingStatementPopup);
