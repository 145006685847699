import CryptoJS from 'crypto-js';
import {AES_SALT} from "./constants";
import {callAlert} from "../utils";
import { toast } from 'react-toastify';
import Regex from './regex';

/* Salt 비밀 키 */
const salt = AES_SALT;

/* 암호화 */
export const encrypt = (text) => {
    if(!text) return ''; // 값이 없을 경우 빈 문자열 반환
    return CryptoJS.AES.encrypt(text, salt).toString();
}

/* 복호화 */
export const decrypt = (text) => {
    if(!text) return '';

    try {
        const bytes  = CryptoJS.AES.decrypt(text, salt); // 복호화 시도
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        console.error('Decryption error:', error); // 에러 로깅
        return ''; // 에러 발생 시 빈 문자열 반환
    }
}

export const checkInteger =(num)=> {
    if(num < 0){
        callAlert('정수만 입력해 주세요');
        return false;
    }else{
        return true;
    }
}

/* mobile 구분 */
export const isAndroid = () => {
    return /android/i.test(navigator.userAgent);
}

export const isiOS = () => {
    return /iphone|ipad/i.test(navigator.userAgent);
}

export const isiPhone = () => {
    return /iphone/i.test(navigator.userAgent);
}

export const isiPad = () => {
    return /ipad/i.test(navigator.userAgent);
}

export const isMobile = () => {
    return isAndroid() || isiOS();
}

export const isDetectMobile = () => {
    const minWidth = 600
    //console.log(window.innerWidth);
    return window.innerWidth <= minWidth;
}

export const showToast =(text)=> {
    return toast(text);
}

export const autoHyphen = (value) => {
    if(value){
        return value
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    }
}

export const localAutoHyphen = (value) => {
    if(value){
        if(value.length > 9 && value.substring(0, 2) === '02'){
            return value
                .replace(/[^0-9]/g, '')
                .replace(/^(\d{0,2})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
        }else{
            return value
                .replace(/[^0-9]/g, '')
                .replace(/^(\d{0,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
        }
    }
}

export const insertComma =(value)=> {
    if(value){
        let strValue = value.toString();

        if(strValue.indexOf(',') > -1){
            strValue = strValue.replaceAll(',', '');
        }
    
        return strValue.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
}

export const removeComma =(value)=> {
    if(value){
        let strValue = value.toString();
        strValue = strValue.replaceAll(',', '');
        return Number(strValue);
    }
}

export const businessNumberFormat =(value)=> {
    // 000-00-00000 형태로 만들기
    if(!value) return;
    
    let valueFormat;
    if (value.length < 4) {
        valueFormat = value;
    } else if (value.length > 3 && value.length < 6) {
        valueFormat = `${value.substring(0, 3)}-${value.substring(3, 5)}`
    } else {
        valueFormat = Regex.makeBusinessNumber(value);
    }

    return valueFormat;
}