import { observer } from "mobx-react-lite"
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import { CCol, CInputGroup, CSaveBtn, CalendarGroup, Subject, CSelectGroup, CSearchBtn } from "../../../components/CustomContainer";
import { useEffect, useRef, useState } from "react";
import AgGridContainer from "../../../components/AgGridContainer";
import { findCommKrnm, getCommcode } from "../../../utils/commcode";
import { UserTabsStore } from "../../../store/human-resources/UserTabsStore";
import axios from "axios";
import { dateFormat, endDateForThisYear, getYear, startDateForThisYear } from "../../../utils/dateUtils";
import { showToast } from "../../../common/utils";
import { getUserName } from "../../../utils/userUtils";
import { ICON_TRASH } from "../../../common/constants";
import { callConfirm } from "../../../utils";

const AnnualLeaveHistory = ({userSeq, selectUserDetail}) => {
    const gridRef = useRef();
    const [startDate, setStartDate] = useState('');
    const [searchStartDate, setSearchStartDate] = useState(startDateForThisYear());
    const [searchEndDate, setSearchEndDate] = useState(endDateForThisYear());
    const [annualLeaveCount, setAnnualLeaveCount] = useState('');
    const [annualLeaveType, setAnnualLeaveType] = useState('');
    const [viewMode, setViewMode] = useState(false);
    const [remark, setRemark] = useState('');
    const [annualLeaveTotalCount, setAnnualLeaveTotalCount] = useState('');
    const [annualLeaveUsedCount, setAnnualLeaveUsedCount] = useState('');

    useEffect(() => {
        annualLeaveStatusSetting();
    }, [UserTabsStore.annualLeaveHistoryGridList]);

    /* 기준년도, 지급일수, 사용일수 계산 */
    const annualLeaveStatusSetting = () => {
        if (UserTabsStore.annualLeaveCountList.length === 0) {
            return;
        }
        // 지급일수 
        const annualLeaveCountList = UserTabsStore.annualLeaveCountList.filter(v => v.startDate.substring(0,4) === getYear());
        if (annualLeaveCountList.length !== 1) {
            // showToast('올해 지급된 휴가가 존재하지 않습니다.');
            setAnnualLeaveTotalCount(0);
            setAnnualLeaveUsedCount(0);
            return;
        } else {
            setAnnualLeaveTotalCount(annualLeaveCountList[0].annualLeaveCount);
        }

        // 사용일수
        const annualLeaveUseList = UserTabsStore.annualLeaveHistoryList.filter(v => v.startDate.substring(0,4) === getYear());
        const sumUseDays = annualLeaveUseList.reduce((useDays, v) => useDays + v.annualLeaveCount, 0);
        setAnnualLeaveUsedCount(sumUseDays);
    }

    /* 저장 */
    const saveAnnualLeaveHistory = async () => {

        if (!startDate || (!annualLeaveCount || annualLeaveCount === 0) || !annualLeaveType || !remark) {
            showToast('필수값을 모두 입력해주세요.');
            return;
        }

        if (!await callConfirm('저장 하시겠습니까?')) {
            return;
        }

        let newDate = new Date(startDate);
        let endDate = newDate;
        endDate.setDate(endDate.getDate() + (annualLeaveCount > 0 ? annualLeaveCount - 1 : annualLeaveCount));

        const params = {
                        userSeq: userSeq, 
                        tabCategory: 'B',
                        startDate: startDate,
                        endDate: dateFormat(endDate),
                        annualLeaveCount: annualLeaveCount, 
                        annualLeaveType: annualLeaveType, 
                        remark: remark
                    };
        await axios.post("/user/tabs", params);

        showToast('저장이 완료되었습니다.');
        selectUserDetail();

        setStartDate(null);
        setAnnualLeaveCount('');
        setAnnualLeaveType('');
        setRemark('');
        setViewMode(false);
    }

    /* 선택 버튼 옵션 - 수정 */
    const modifyBtn = {
        isUsed: true,
        title: '수정',
        callbackFn: () => modifyFn(),
        icon: 'fi-rr-file-edit'
    };

    /* 수정 */
    const modifyFn = async () => {
        const updatedList = UserTabsStore.annualLeaveHistoryGridList.filter(v => v.isUpdated);
        if (updatedList.length === 0) {
            showToast('수정된 데이터가 없습니다.');
            return;
        }

        if (!await callConfirm('수정 하시겠습니까?')) {
            return;
        }

        await axios.put('/user/tabs', updatedList);
        showToast('수정이 완료되었습니다.');
        selectUserDetail();
    }

    /* 선택 버튼 옵션 - 삭제 */
    const deleteBtn = {
        isUsed: true,
        title: '삭제',
        callbackFn: () => deleteFn(),
        icon: ICON_TRASH,
        variant: 'dark'
    };

    /* 삭제 */
    const deleteFn = async () => {
        const selectedList = UserTabsStore.annualLeaveHistoryGridList.filter(v => v.isSelected);

        if (selectedList.length === 0) {
            showToast('삭제할 데이터를 선택해주세요.');
            return;
        }

        if (!await callConfirm('삭제 하시겠습니까?')) {
            return;
        }

        await axios.delete('/user/tabs', {data: selectedList});
        showToast('삭제가 완료되었습니다.');
        selectUserDetail();
    }

    /* 날짜 입력 검증 */
    const validDateFormat = (e) => {
        const pattern = /^\d{4}-\d{2}-\d{2}$/;

        if (!pattern.test(e.data.startDate)) {
            showToast('YYYY-MM-DD 형식에 맞춰 입력해주세요.');
            e.data.startDate = e.oldValue;
            gridRef.current.api.redrawRows();
            return;
        }

        let newDate = new Date(startDate);
        let endDate = newDate;
        endDate.setDate(endDate.getDate() + (annualLeaveCount >= 1 ? annualLeaveCount - 1 : annualLeaveCount));
        e.data.endDate = endDate;
    }

    /* 숫자 입력 검증 */
    const validNumber = (v) => {
        if (isNaN(v.target.value)) {
            showToast('숫자만 입력해야 합니다.');
            return false;
        }

        setAnnualLeaveCount(v.target.value)
    }

    /* 날짜 선택 */
    const onChangeSearchValue = async (v, label) => {
        const { startDate, endDate } = v;
        const selectedDate = new Date(startDate);

        if (label === 'searchStartDate' && searchEndDate < selectedDate) {
            showToast('종료일보다 작게 선택해주세요.');
        } else if (label === 'searchEndDate' && endDate !== null && searchStartDate > selectedDate) {
            showToast('시작일보다 크게 선택해주세요.');
        } else {
            label === 'searchStartDate' ? setSearchStartDate(selectedDate) : setSearchEndDate(selectedDate);
        }
    };

    /* 기간 조회 */
    const searchPeriod = () => {
        const annualLeaveList = UserTabsStore.annualLeaveHistoryList.filter(data => new Date(data.startDate) >= new Date(searchStartDate) && new Date(data.startDate) <= new Date(searchEndDate));
        UserTabsStore.annualLeaveHistoryGridList = annualLeaveList;
    }

    /* 휴가 구분 선택 */
    const selectAnnualLeaveType = (v) => {
        if (v.target.value === '5' || v.target.value === '6') { // 오전반차 or 오후반차
            setAnnualLeaveCount(0.5);
            setViewMode(true);
        } else {
            setViewMode(false);
        }
        setAnnualLeaveType(v.target.value);
    }

    /* 행 고정 */
    const pinnedTopRowData = [{
        userTabDataSeq: '', startDate: '', annualLeaveCount: UserTabsStore.annualLeaveHistoryGridList.reduce((total, v) => total + v.annualLeaveCount, 0), annualLeaveType: '', remark: '', creator: '', createdDate: ''
    }];

    return (
        <>
            <Subject>휴가장부</Subject>
            <Row>
                <CCol lg={3}>
                    <CInputGroup 
                        label={'기준년도'}
                        labelId={'baseYear'}
                        value={getYear()}
                        // onChange={v => setAnnualLeaveTotalCount(v.target.value)}
                        disabled={true}
                    />
                </CCol>
                <CCol lg={3}>
                    <CInputGroup 
                        label={'지급일수'}
                        labelId={'annualLeaveTotalCount'}
                        value={annualLeaveTotalCount}
                        onChange={v => setAnnualLeaveTotalCount(v.target.value)}
                        disabled={true}
                    />
                </CCol>
                <CCol lg={3}>
                    <CInputGroup 
                        label={'사용일수'}
                        labelId={'annualLeaveUsedCount'}
                        value={annualLeaveUsedCount}
                        onChange={v => setAnnualLeaveUsedCount(v.target.value)}
                        disabled={true}
                    />
                </CCol>
                <CCol lg={3}>
                    <CInputGroup 
                        label={'남은휴가'}
                        labelId={'remainingAnnualLeave'}
                        value={annualLeaveTotalCount - annualLeaveUsedCount}
                        // onChange={v => setRemainingAnnualLeave(v.target.value)}
                        disabled={true}
                    />
                </CCol>
            </Row>
            <Row>
                <CCol lg={4}>
                    <CalendarGroup
                        asSingle={true}
                        label={'사용일자'}
                        labelId={'date'}
                        value={{startDate: startDate, endDate: startDate}}
                        onChange={v => setStartDate(v.startDate)}
                        labelClassName='input-required'
                    />
                </CCol>
                <CCol lg={4}>
                    <CInputGroup 
                        label={'사용 개수'}
                        labelId={'annualLeaveCount'}
                        value={annualLeaveCount}
                        onChange={v => validNumber(v)}
                        readOnly={viewMode}
                        labelClassName='input-required'
                    />
                </CCol>
                <CCol lg={4}>
                    <CSelectGroup label={'휴가 종류'} labelId={'annualLeaveType'}
                        options={[{name: '휴가 구분', value: ''}].concat(getCommcode('51'))}
                        value={annualLeaveType}
                        onChange={v => selectAnnualLeaveType(v)}
                        labelClassName='input-required'
                    />
                </CCol>
            </Row>
            <Row>    
                <CCol lg={12}>
                    <InputGroup>
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}} className='input-required'>사유</InputGroup.Text>
                        <textarea className="form-control" rows="3"
                            value={remark}
                            placeholder={'내용을 자유롭게 작성해주세요.'}
                            readOnly={false}
                            onChange={v => setRemark(v.target.value)}
                        />
                    </InputGroup>
                </CCol>
            </Row>
            <Row>
                <Col className="d-flex flex-row-reverse">
                    <CSaveBtn title={'저장'}
                              onClick={saveAnnualLeaveHistory}
                    />
                </Col>
            </Row>
            <br />
            <Row className="mb-3">
                <Col lg={4}>
                    <CalendarGroup asSingle={true} label={'조회 시작일'}
                                    value={{
                                        startDate: searchStartDate,
                                        endDate: searchStartDate
                                    }}
                                    onChange={v => onChangeSearchValue(v, 'searchStartDate')}
                    />
                </Col>
                <Col lg={4}>
                    <CalendarGroup asSingle={true} label={'조회 종료일'}
                                    value={{
                                        startDate: searchEndDate,
                                        endDate: searchEndDate
                                    }}
                                    onChange={v => onChangeSearchValue(v, 'searchEndDate')}
                    />
                </Col>
                <Col lg={4} className="d-flex flex-row-reverse">
                    <CSearchBtn title={'조회'}
                              onClick={searchPeriod}
                    />
                </Col>
            </Row>
            <Row>
                <CCol lg={12}>
                    <AgGridContainer
                        gridRef={gridRef}
                        gridTitle={'휴가사용내역'}
                        height={22}
                        rowData={UserTabsStore.annualLeaveHistoryGridList}
                        columnDefs={[
                            { field: "userTabDataSeq", headerName: "유저탭테이블순번", hide: true },
                            { field: "startDate", headerName: "사용일자", width: 120, editable: true, onCellValueChanged: validDateFormat },
                            { field: "annualLeaveType", 
                              headerName: "휴가종류", 
                              valueFormatter: v => findCommKrnm(v.value, '51'),
                              editable: true,
                              cellEditor: 'agSelectCellEditor',
                              cellEditorParams: {
                                values: getCommcode('51').map(v => v.value),
                              },
                              width: 110
                            },
                            { field: "annualLeaveCount", headerName: "휴가일수", width: 110, editable: true },
                            { field: "remark", headerName: "사유", width: 263, editable: true },
                            { field: "creator", headerName: "입력자", valueGetter: v => getUserName(v.data.creator), width: 98 },
                            { field: "createdDate", headerName: "입력일자", width: 190 },
                            { field: "updater", headerName: "수정자",  valueGetter: v => getUserName(v.data.creator), width: 98},
                            { field: "updatedDate", headerName: "수정일자", width: 190 },
                        ]}
                        pinnedTopRowData={pinnedTopRowData}
                        customBtnInfo={[deleteBtn, modifyBtn]}
                        isCheckBox={true}
                    />
                </CCol>
            </Row>
        </>
    )

}

export default observer(AnnualLeaveHistory);