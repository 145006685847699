import { observable } from "mobx";

export const AccountCloseStore = observable({
    search: {
        targetYear: '',
        nextYear: '',
    },
    isYearClose: false,
    accountList: [],
    accountAmountList: [],
})
