import { observer } from "mobx-react-lite";
import { CContainer, CCol, CalendarGroup, CInputGroup, CInputDoubleGroup, CIconBtn, CSearchBtn } from "../../components/CustomContainer";
import { Row, Form, Col } from "react-bootstrap";
import { AssetReservationStore } from "../../store/asset/assetReservationStore";
import { AppStore } from "../../store/AppStore";

const AssetReservationListSearch = ({searchReservation}) => {

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CalendarGroup label={'예약일자'}
                                       value={{startDate: AssetReservationStore.search.startDate, endDate: AssetReservationStore.search.endDate}}
                                       onChange={(v)=> {
                                            AssetReservationStore.search.startDate = v.startDate;
                                            AssetReservationStore.search.endDate = v.endDate;
                                       }}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'예약자'} labelId={'reserveUser'}
                                     value={AssetReservationStore.search.userName}
                                     onCallbackBtn={() => AppStore.toggleUserSearchModal()}
                                     readOnly={true}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <Form.Check
                            inline
                            label="전체"
                            name="reservation"
                            type={'radio'}
                            id='reservationAll'
                            value={''}
                            checked={AssetReservationStore.search.reservationStatus === ''}
                            onChange={(e)=> AssetReservationStore.search.reservationStatus = e.target.value}
                        />
                        <Form.Check
                            inline
                            label="예약중"
                            name="reservation"
                            type={'radio'}
                            id='reservationIng'
                            value={'1'}
                            checked={AssetReservationStore.search.reservationStatus === '1'}
                            onChange={(e)=> AssetReservationStore.search.reservationStatus = e.target.value}
                        />
                        <Form.Check
                            inline
                            label="완료"
                            name="reservation"
                            type={'radio'}
                            id='reservationComplete'
                            value={'2'}
                            checked={AssetReservationStore.search.reservationStatus === '2'}
                            onChange={(e)=> AssetReservationStore.search.reservationStatus = e.target.value}
                        />
                        <Form.Check
                            inline
                            label="예약취소"
                            name="reservation"
                            type={'radio'}
                            id='reservationCancel'
                            value={'9'}
                            checked={AssetReservationStore.search.reservationStatus === '9'}
                            onChange={(e)=> AssetReservationStore.search.reservationStatus = e.target.value}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <CInputDoubleGroup
                            label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객번호'}
                            placeholder2={'고객명'}
                            value={AssetReservationStore.search.customerNo}
                            value2={AssetReservationStore.search.customerName}
                            disabled={true}
                            disabled2={true}
                            onEmptyAction={()=> {
                                AssetReservationStore.search.customerNo = '';
                                AssetReservationStore.search.customerName = '';
                            }}
                            onCallbackBtn={()=>  AppStore.toggleCustomerSearchModal()}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'자산번호'}
                                     value={AssetReservationStore.search.assetNo}
                                     onChange={(e)=> AssetReservationStore.search.assetNo = e.target.value}
                                     enterKeyHint={searchReservation}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'제품명'}
                                     value={AssetReservationStore.search.productName}
                                     onCallbackBtn={()=> AppStore.toggleProductListSearchModal()}
                                     onChange={(e)=> AssetReservationStore.search.productName = e.target.value}
                                     enterKeyHint={searchReservation}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'모델명'}
                            labelId={'model'}
                            value={AssetReservationStore.search.model}
                            onChange={(e)=> AssetReservationStore.search.model = e.target.value}
                            enterKeyHint={searchReservation}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'스펙'}
                            labelId={'spec'}
                            value={AssetReservationStore.search.spec}
                            onChange={(e)=> AssetReservationStore.search.spec = e.target.value}
                            enterKeyHint={searchReservation}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CIconBtn style={{width: 100}}
                                  title={'재고예약'}
                                  icon={'fi-rr-calendar-check'}
                                  onClick={()=> {
                                    AppStore.toggleModal();
                                    AssetReservationStore.init();
                                }}
                        />
                        <CSearchBtn onClick={searchReservation} />
                    </Col>
                </Row>
            </CContainer>
        </>
    );
}

export default observer(AssetReservationListSearch);
