import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";
import axios from "axios";

import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer, CInputGroup, CSearchBtn, CSelectGroup, CalendarGroup } from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";
import { getCommcode, findCommKrnm } from "../../utils/commcode";
import UserSearch from "./UserSearch";
import { showToast } from "../../common/utils";
import { StatementStore } from "../../store/accounting/StatementStore";
import { callConfirm } from "../../utils";

const StatementPopup =({ callbackFn=null, column='', ...props })=> {
    const store = StatementStore;
    const [statementList, setStatementList] = useState([]);
    const gridRef = useRef();
    const [accountingDate, setAccountingDate] = useState({startDate: '', endDate: ''});
    const [department, setDepartment] = useState('');
    const [creator, setCreator] = useState('');
    const [creatorName, setCreatorName] = useState('');
    const [createdDate, setCreatedDate] = useState({startDate: '', endDate: ''});
    const [statementType, setStatementType] = useState(1);

    useEffect(() => {
        if(store.popup.isClicked){
            getStatementList();
            store.popup.isClicked=false;
        }
    }, [store.popup.isClicked])

    const getStatementList = async () => {
        const params = {
            type: store.popup.type,
            summary: store.popup.summary,
            accountingStartDate: accountingDate.startDate,
            accountingEndDate: accountingDate.endDate,
            department: department,
            creator: creator,
            createdStartDate: createdDate.startDate,
            createdEndDate: createdDate.endDate,
            statementType: statementType
        };
        const result = await axios.get('/statement/popup/list', {params});
        setStatementList(result);
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.')
        }
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        setCreator(userInfo.userSeq);
        setCreatorName(userInfo.name);
    }

    /* 전표 연동 저장 버튼 */
    const updateStatementBtn = {
        isUsed: true,
        callbackFn:() => updateStatement(),
        icon: 'fi-bs-gears',
        title: '전표 연동',
        width: 90,
    }

    const updateStatement = async () => {
        if (!await callConfirm('출금 전표 연동 하시겠습니까?')) {
            return false;
        }
        const selectedList = statementList.filter(v => v.isSelected);
        axios.put('/statement/reference', {referenceKey: props.referenceKey, statementList: selectedList})
            .then(() => {
                refresh();
                props.getInputStatementList();
            })
            .then(() => AppStore.toggleStatementPopup());
    }

    const refresh = () => {
        store.popup.summary='';
        store.popup.isClicked=false;
        setStatementList('');
        setAccountingDate({startDate: '', endDate: ''});
        setDepartment('');
        setCreator('');
        setCreatorName('');
        setCreatedDate({startDate: '', endDate: ''});
        setStatementType('');
    }

    return (
        <Modal
            title={'전표조회'}
            show={AppStore.isOpenStatementPopup}
            onHide={() => AppStore.toggleStatementPopup()}
            toggleValue={AppStore.isOpenStatementPopup}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CSelectGroup label={'전표유형'} labelId={'type'}
                                        placeholder={'전표유형'}
                                        options={[{name: '전체', value: ''}].concat(getCommcode('42'))}
                                        value={store.popup.type}
                                        onChange={v => store.popup.type=v.target.value}
                                        disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'적요'} labelId={'summary'} value={store.popup.summary} onChange={(v)=> store.popup.summary=v.target.value} enterKeyHint={getStatementList} />
                    </CCol>
                    <CCol lg={4}>
                        <CalendarGroup label={'회계일'}
                                       value={{startDate: accountingDate.startDate, endDate: accountingDate.endDate}}
                                       onChange={(v)=> setAccountingDate({startDate: v.startDate, endDate: v.endDate})}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'전표구분'} labelId={'statementType'}
                                        placeholder={'전표유형'}
                                        options={[{name: '전체', value: ''}].concat(getCommcode('41'))}
                                        value={statementType}
                                        onChange={v => setStatementType(v.target.value)}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CSelectGroup label={'작성부서'}
                            labelId={'department'}
                            options={[{ name: '전체', value: '' }].concat(getCommcode('32'))}
                            value={department}
                            onChange={v => setDepartment(v.target.value)} />
                    </CCol>
                    <CCol lg={4}>
                        <CalendarGroup label={'작성일'}
                                       value={{startDate: createdDate.startDate, endDate: createdDate.endDate}}
                                       onChange={(v)=> setCreatedDate({startDate: v.startDate, endDate: v.endDate})}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                                label={'작성자'} labelId={'creatorName'}
                                value={creatorName}
                                onCallbackBtn={() => AppStore.toggleUserSearchModal()}
                                disabled={true}
                                onEmptyBtn={() => {setCreator(''), setCreatorName('')}}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getStatementList} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={25}
                    rowData={statementList}
                    columnDefs={
                        [
                            {field: "statementNo", headerName: "전표번호", hide:true},
                            {field: "statementSeq", headerName: "시퀀스", hide:true},
                            {
                                field: "bankCode", headerName: "은행", 
                                valueFormatter: v => findCommKrnm(v.value, '24'),
                                cellEditorParams: {
                                    values: getCommcode('24').map(v => v.value),
                                },
                            },
                            {field: "accountingDate", headerName: "회계일자", minWidth: 140},
                            {field: "accountNumber", headerName: "계좌번호", minWidth: 180},
                            {field: "summary", headerName: "적요", minWidth: 140},
                            {field: "amount", headerName: "금액", valueFormatter: v => v.value?.toLocaleString()},
                            {field: "customerName", headerName: "고객명"},
                            {field: "accountCode", headerName: "계정코드", },
                            {field: "accountName", headerName: "계정과목"},
                            {field: "departmentName", headerName: "부서", minWidth: 100},
                            {field: "createdDate", headerName: "작성일", minWidth: 180},
                            {field: "creatorName", headerName: "작성자", minWidth: 100},
                            {field: "remark", headerName: "비고", minWidth: 140},
                        ]
                    }
                    isCheckBox={true}
                    customBtnInfo={[updateStatementBtn]}
                />
            </CContainer>
            <UserSearch callbackFn={getUserInfo}/>
        </Modal>
    )
}

export default observer(StatementPopup);
