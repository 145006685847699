import { observer } from "mobx-react-lite"
import { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";

import { CContainer, CCol, CIconBtn, CInputGroup } from "../../../components/CustomContainer";
import Modal from "../../../components/Modal";
import { AppStore } from "../../../store/AppStore";
import { decrypt } from "../../../common/utils";
import { ConfirmStore } from "./ConfirmStore";


const SendTaxBillPopup = ({ emailList, isDetectMobileDevice, sendBtn }) => {
    const store = ConfirmStore;
    const [isDesabled, setIsDesabled] = useState(false);

    const refresh = () => {
        store.init();
    }

    const allCheck = () => {
        const checkboxes = document.querySelectorAll('.emailCheck');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = true;
        });
        setIsDesabled(true);
        store.receiverEmail = emailList.map(v => decrypt(v.email)).join(', ');
    }

    const cancelCheck = () => {
        const checkboxes = document.querySelectorAll('.emailCheck');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
        store.receiverEmail = '';
        setIsDesabled(false);
    }

    const checkedEvent = (e) => {
        const checkboxes = document.querySelectorAll('.emailCheck');
        const isCheckBox = Array.from(checkboxes).some(checkbox => checkbox.checked);
        const checkedEmails = Array.from(checkboxes).filter(v => v.checked).map(v => decrypt(v.attributes.value.value)).join(', ');
        setIsDesabled(isCheckBox);
        store.receiverEmail = checkedEmails;
    }

    const inputEmail = (v) => {
        const atSymbolCount = (store.receiverEmail.match(/@/g) || []).length;
        const commaSymbolCount = (store.receiverEmail.match(/,/g) || []).length;
        if (atSymbolCount >= 2 || commaSymbolCount >= 1) {
            alert('이메일을 하나만 적으세요');
            store.receiverEmail = '';
            return;
        }else {
            store.receiverEmail = v.target.value;
        }
    }

    return (
        <>
            <Modal title={'메일 발송'}
                onHide={() => AppStore.toggleSendMailPopup()}
                toggleValue={AppStore.isOpenSendMailPopup}
                onExit={refresh}
                size={'lg'}
            >
                <CContainer paddingTop={'pt-0'} style={{paddingTop: 0}}>
                
                    <h5 style={{color: '#5c5f63', marginBottom: 15}}><i className="fi fi-rs-comment-alt" style={{paddingTop: '5px', marginRight: '8px'}} />현장 담당자 목록</h5>
                    {emailList[0] !== null ? (
                        <>
                           {emailList.map((data, index) => (
                                <Row key={index} className="align-items-center">
                                    <CCol mb='mb-1' style={{ width: '10%' }}><input className="emailCheck" type="checkbox" value={data.email} onClick={checkedEvent} /></CCol>
                                    <CCol mb='mb-1' style={{ width: '30%' }}>{data.managerName}</CCol>
                                    <CCol mb='mb-1' style={{ width: '60%' }}>{decrypt(data.email)}</CCol>
                                </Row>
                            ))}
                            <div style={{marginBottom: 15, marginTop: 15, display: 'flex', justifyContent: 'space-between'}} className="button-group">
                                <Button variant={'primary'} style={{flex: 1, marginRight: 5}}  onClick={allCheck}>전체선택</Button>
                                <Button variant={'success'} style={{flex: 1, marginLeft: 5}} onClick={cancelCheck}>선택해제</Button>
                            </div>
                        </>
                    ) : (
                        <Row><CCol lg={12}>조회된 메일이 없습니다.</CCol></Row>
                    )}

                    <h5 style={{color: '#5c5f63'}}><i className="fi fi-rs-comment-alt" style={{paddingTop: '5px', marginRight: '8px'}} />메일 주소</h5>
                    <CCol lg={12}>
                        <textarea className="form-control" rows="8"
                                    placeholder={'sample@naver.com'}
                                    value={store.receiverEmail}
                                    onChange={inputEmail}
                                    disabled={isDesabled}
                        />
                    </CCol> 

                    <Row>
                        <Col className="d-flex flex-row-reverse">
                            <CIconBtn style={{width: isDetectMobileDevice ? '100%' : 100, height: 35}}
                                    title={'메일 보내기'}
                                    icon={'fi-rr-file-invoice-dollar'}
                                    onClick={sendBtn}
                                    variant={'success'}
                            />
                        </Col>
                    </Row>
                </CContainer>
            </Modal>
        </>
    );
}

export default observer(SendTaxBillPopup);
