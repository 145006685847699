import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { AppStore } from "../../../store/AppStore";
import { CContainer, CCol, CInputGroup, CSaveBtn, CalendarGroup } from "../../../components/CustomContainer";
import { Row, Button, Col } from "react-bootstrap";
import AgGridContainer from "../../../components/AgGridContainer";
import Modal from "../../../components/Modal";
import { getDay, getMonth, today  } from "../../../utils/dateUtils";
import { showToast } from "../../../common/utils";
import { callConfirm } from "../../../utils";
import axios from "axios";
import { escape } from "lodash";
import { ContractDetailStore } from "../../../store/contract/ContractDetailStore";
import { BillSearchStore } from "../../../store/billing/BillSearchStore";
import SendTaxBillSmsPopup from "../../billing/popup/SendTaxBillSmsPopup";
import { TaxBillStore } from "../../../store/billing/TaxBillStore";

const PreSendBillItemPopup = ({callbackFn=null, chargeTotalAmount, accountBillNote}) => {
    const gridRef = useRef();
    const dataListRef = useRef();
    const [billNote, setBillNote] = useState('');
    const [realChargeTotalAmount, setRealChargeTotalAmount] = useState(0);
    const [changeRemark, setChangeRemark] = useState('');
    const [chargeAmount, setChargeAmount] = useState(0);
    const [chargeVat, setChargeVat] = useState(0);
    const [preBillDate, setPreBillDate] = useState(null);

    useEffect(() => {
        setBillNote(accountBillNote);
        setPreBillDate(ContractDetailStore.contractStartDate);
        setChangeRemark(ContractDetailStore.targetBillItemList[0]?.changeRemark ? ContractDetailStore.targetBillItemList[0].changeRemark : '');
        amountChangeFn();
    }, [AppStore.isOpenPreSendBillItemPopup]);

    const amountChangeFn = () => {
        
        const amount = ContractDetailStore.targetBillItemList.reduce((total, v) => total + (Number(v.qty) * Number(v.unitAmount)), 0);
        const vat = ContractDetailStore.targetBillItemList.reduce((total, v) => total + (Number(v.qty) * Number(v.unitAmount) * 0.1), 0);

        setChargeAmount(amount);
        setChargeVat(vat);
        setRealChargeTotalAmount(amount);
    }

    const preSendBillBtn = {
        isUsed: true,
        callbackFn:() => preSendBill(),
        title: '선발행',
        width: 100,
        variant: 'primary'
    }

    const addBtn = {
        isUsed: false,
        callbackFn:(v) => {
            if(ContractDetailStore.targetBillItemList.length < 16) {
                const addRowInfo = {
                    billNo: '',
                    seq: ContractDetailStore.targetBillItemList.length + 1,
                    month: '',
                    day: '',
                    item: '',
                    qty:  '',
                    unitAmount: '',
                    taxAmount: '',
                    supplyAmount: '',
                    billItemNote: '',
                }
                dataListRef.current.addRow(addRowInfo);
            }
            else {
                showToast('최대 16건까지만 입력 가능합니다.');
            }
        },
        title: '추가',
        width: 90,
        variant: 'danger'
    }

    const checkByteLength = (e) => {
        // return;
        let str = e.newValue;
        let byteLength = 0;
        let slicedStr = "";

        for (let i = 0; i < str.length; i++) {
            const charCode = str.charCodeAt(i);
            const charUnicode = escape(charCode);   // 유니코드 형식으로 변환
            if (charUnicode.length > 4) {
                byteLength += 2;  // 2바이트 문자 (한글 포함)
            } else {
                byteLength += 1;  // 1바이트 문자 (ASCII)
            }

            if (byteLength > 100) {
                break;
            }

            slicedStr += str[i];
        }

        if (byteLength > 100) {
            showToast('제한된 글자수를 초과하였습니다.');
            e.data.billItemNote = slicedStr;
        } else {
            e.data.billItemNote = e.newValue;
        }
    }

    const deleteItem = async (e) => {
        const { billingMonth, contractNo, billingType, seq, agId } = e.data;
        
        if (ContractDetailStore.targetBillItemList.length === 1) {
            showToast('기본 품목으로 삭제할 수 없습니다.');
            return;
        }
        
        if(!await callConfirm(`품목을 삭제하시겠습니까?`)) return;
        
        const selectedData = gridRef.current.api.getSelectedRows();
        gridRef.current.api.applyTransaction({remove: selectedData});
        const itemList = ContractDetailStore.targetBillItemList.filter(v => v.agId !== agId);
        ContractDetailStore.setTargetBillItemList(itemList);
        

        showToast('삭제되었습니다.');
        // getBillingTargetList();
        amountChangeFn();
    }

    const preSendBill = async () => {
        if (!await callConfirm(`총 발행금액 ${realChargeTotalAmount?.toLocaleString()}원(VAT 별도)으로 계산서를 선발행하시겠습니까?`)) {
            return;
        }

        const selectedList = ContractDetailStore.targetBillItemList.map(v => {return {...v, 
                                                                                    orgBillNote: billNote,
                                                                                    supplyAmount: Number(v.unitAmount) * Number(v.qty),
                                                                                    taxAmount: (Number(v.unitAmount) * Number(v.qty)) * 0.1,
                                                                                    changeChargeAmount: Number(v.unitAmount) * Number(v.qty),
                                                                                    changeChargeVat: (Number(v.unitAmount) * Number(v.qty)) * 0.1,
                                                                                    changeRemark: changeRemark,
                                                                                }}).sort((a, b) => a.seq - b.seq);

        // 계산서 발행 로직
        const result = await axios.post("/contract/bill", {
                                                            contractNo: ContractDetailStore.contractNo,
                                                            billDate: preBillDate,
                                                            billNote: billNote,
                                                            billItemList: selectedList
                                                        })
        showToast('계산서가 발행되었습니다.');

        // 국세청 발송
        const checkBillList = [result];

        for (const [index, item] of checkBillList.entries()) {    
            //console.log(store.addEmailList[item.billNo]);
            checkBillList[index].emailList = BillSearchStore.addEmailList[item.billNo] && BillSearchStore.addEmailList[item.billNo].map(v => v.email);
        }
        const msg = await axios.post('/bill/send', checkBillList);
        if (msg.result === 'option_failed') {
            alert(result.message);
        } else if (msg.result === 'success'){
            showToast('세금계산서 국세청 전송 완료');
        } else {
            showToast('세금계산서 국세청 전송 실패하였습니다.(결과 메세지 확인)');
        }

        AppStore.togglePreSendBillItemPopup();

        TaxBillStore.setSendTargetList([result]);
        await sendEmailCallback();
        callbackFn();
    }

    const sendEmailCallback = async () => {

        if (!await callConfirm('계산서 발행 알림톡을 전송하시겠습니까?')) {
            return;
        }

        AppStore.toggleSendTaxBillSmsPopup();
    }

    return (
        <>
            <Modal title={'품목설정'}
                    show={AppStore.isOpenPreSendBillItemPopup}
                    onHide={() => AppStore.togglePreSendBillItemPopup()}
                    toggleValue={AppStore.isOpenPreSendBillItemPopup}
                    className={'modal-w9'}
                    onExit={() => {
                        ContractDetailStore.setTargetBillItemList([]);
                        setChangeRemark('');
                    }}
            >
                <CContainer search>
                    <Row>
                        <CCol lg={4}>
                            <CalendarGroup
                                asSingle={true}
                                label={'계산서 발행일'}
                                value={{startDate: preBillDate, endDate: preBillDate}}
                                disabled={true}
                            />
                        </CCol>
                        <CCol lg={8}>
                            <CInputGroup
                                label={'청구금액'}
                                labelId={'realChargeTotalAmount'}
                                style={{flex: 1, textAlign: 'right'}}
                                value={realChargeTotalAmount?.toLocaleString()}
                                align='right'
                                disabled={true}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <CInputGroup
                                label={'계산서 비고'}
                                labelId={'billNote'}
                                placeholder={'계산서 비고를 입력해주세요.'}
                                value={billNote}
                                onChange={(e)=> setBillNote(e.target.value)}
                            />
                        </CCol>
                    </Row>
                </CContainer>
                <CContainer>
                  <AgGridContainer
                        ref={dataListRef}
                        gridRef={gridRef}
                        height={27}
                        rowData={ContractDetailStore.targetBillItemList}
                        columnDefs={
                            [
                                {field: "billNo", headerName: "계산서번호", width: 65, hide: true},
                                {field: "seq", headerName: "순번", width: 65, hide: true},
                                {field: "month", headerName: "월", width: 65, valueFormatter: v => getMonth(preBillDate)},
                                {field: "day", headerName: "일", width: 65, valueFormatter: v => getDay(preBillDate)},
                                {field: "item", headerName: "품목", width: 330, editable: true, headerClass: 'grid-column-editable',},
                                {field: "qty", headerName: "수량", width: 80, editable: true, onCellValueChanged: amountChangeFn, headerClass: 'grid-column-editable',},
                                {field: "unitAmount", headerName: "단가", width: 130, onCellValueChanged: amountChangeFn, valueFormatter: v => v.value?.toLocaleString() || 0, cellClass: 'ag-grid-money-align', editable: true, headerClass: 'grid-column-editable',},
                                {field: "supplyAmount", headerName: "공급가액", width: 130, valueFormatter: v => v.value?.toLocaleString(), valueGetter: v => v.value = v.data.unitAmount * v.data.qty, cellClass: 'ag-grid-money-align', editable: false},
                                {field: "taxAmount", headerName: "세액", width: 130, valueFormatter: v => v.value?.toLocaleString(), valueGetter: v => v.value = (v.data.unitAmount * v.data.qty) * 0.1, cellClass: 'ag-grid-money-align', editable: false},
                                {field: "billItemNote", headerName: "비고", width: 400, editable: true, onCellValueChanged: checkByteLength},
                                {field: "billingType", headerName: "청구방식", width: 130, hide: true},
                                {field: "productSeq", headerName: "제품순번", hide: true},
                                {field: "model", headerName: "모델명", hide: true},
                                {field: "managementNo", headerName: "관리번호", hide: true},
                                {field: "assetNo", headerName: "자산번호", hide: true},
                            ]
                        }
                        seqColumn={'agId'}
                        customBtnInfo={[preSendBillBtn, addBtn]}
                        useRemoveRow={true}
                        removeColumnFront={true}
                        removeCallback={deleteItem}
                        // isCheckBox={true}
                    />
                </CContainer>
            </Modal>
            <SendTaxBillSmsPopup/>
        </>
    );
}

export default observer(PreSendBillItemPopup);
