import { useState } from "react";
import { observer } from "mobx-react-lite"
import {CContainer, CCol, CalendarGroup, CInputGroup, CInputDoubleGroup, CIconBtn, CSearchBtn, CSelectGroup} from "../../components/CustomContainer";
import {Row, Col, Form} from "react-bootstrap";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import { EstimateListStore } from "../../store/contract/EstimateListStore";
import { AppStore } from "../../store/AppStore";
import { dateFormat } from "../../utils/dateUtils";
import { callAlert } from "../../utils";

const EstimateSearch = ({ getEstimateList, setEstimateNo }) => {
    const [isViewMode, setIsViewMode] = useState(false);

    /* 날짜 선택 */
    const onChangeSearchValue = async (v, label) => {
        const { startDate, endDate } = v;
        const storeStartDate = new Date(EstimateListStore.estimateSearchCondition.startDate);
        const storeEndDate = new Date(EstimateListStore.estimateSearchCondition.endDate);
        const selectedDate = new Date(startDate);

        if (label === 'startDate' && storeEndDate < selectedDate) {
            await callAlert('종료일보다 작게 선택해주세요.');
        } else if (label === 'endDate' && endDate !== null && storeStartDate > selectedDate) {
            await callAlert('시작일보다 크게 선택해주세요.');
        } else {
            EstimateListStore.setEstimateSearchCondition({ [label]: dateFormat(selectedDate) });
        }
    };

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        EstimateListStore.setEstimateSearchCondition({customerNo: customerInfo.customerNo});
        EstimateListStore.setEstimateSearchCondition({customerName: customerInfo.customerName});
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = () => {
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        EstimateListStore.setEstimateSearchCondition({saleUserSeq: userInfo.userSeq});
        EstimateListStore.setEstimateSearchCondition({saleUserName: userInfo.name});
    }


    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CalendarGroup asSingle={true}  label={'시작일'}
                                       value={{
                                           startDate: EstimateListStore.estimateSearchCondition.startDate,
                                           endDate: EstimateListStore.estimateSearchCondition.startDate
                                        }}
                                        onChange={v => onChangeSearchValue(v, 'startDate')}
                                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup asSingle={true}  label={'종료일'}
                                       value={{
                                           startDate: EstimateListStore.estimateSearchCondition.endDate,
                                           endDate: EstimateListStore.estimateSearchCondition.endDate
                                        }}
                                        onChange={v => onChangeSearchValue(v, 'endDate')}
                                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'견적번호'} labelId={'estimateNo'}
                                     value={EstimateListStore.estimateSearchCondition.estimateNo}
                                     onChange={v => EstimateListStore.estimateSearchCondition.estimateNo = v.target.value}
                                     enterKeyHint={()=> getEstimateList()}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputDoubleGroup label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                           placeholder={'고객코드'} placeholder2={'고객명'}
                           disabled={isViewMode} disabled2={isViewMode}
                           onCallbackBtn={(v) => {openSearchCustomerPopup('customer')}}
                           btnDisabled={isViewMode}
                           value={EstimateListStore.estimateSearchCondition.customerNo}
                           value2={EstimateListStore.estimateSearchCondition.customerName}
                           onChange={v => EstimateListStore.estimateSearchCondition.customerNo = v.target.value}
                           onChange2={v => EstimateListStore.estimateSearchCondition.customerName = v.target.value}
                           enterKeyHint1={()=> getEstimateList()}
                           enterKeyHint2={()=> getEstimateList()}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'영업담당자'} labelId={'saleUserName'}
                                    value={EstimateListStore.estimateSearchCondition.saleUserName}
                                    onCallbackBtn={openSearchUserPopup}
                                    onChange={(e)=> EstimateListStore.estimateSearchCondition.saleUserName = e.target.value}
                                    enterKeyHint={()=> getEstimateList()}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup
                            label={'견적상태'} labelId={'contractType'}
                            options={[{ value: '', name: '전체' },{ value: 1, name: '진행중' },{ value: 2, name: '완료' },{ value: 9, name: '취소' }]}
                            value={EstimateListStore.estimateSearchCondition.status || ''}
                            onChange={v => EstimateListStore.setEstimateSearchCondition({ status: v.target.value })}
                        />
                    </CCol>
                    <CCol lg={1}>
                        <Form.Check
                            type="switch"
                            id="isNewCustomer"
                            label="신규고객"
                            style={{marginTop: 4}}
                            checked={!!EstimateListStore.estimateSearchCondition.isNewCustomer}
                            onChange={v => EstimateListStore.setEstimateSearchCondition({isNewCustomer : v.target.checked})}
                        />
                    </CCol>
                    <CCol lg={1}>
                        <Form.Check
                            type="switch"
                            id="isContracted"
                            label="계약완료"
                            style={{marginTop: 4}}
                            checked={!!EstimateListStore.estimateSearchCondition.isContractComplete}
                            onChange={v => EstimateListStore.setEstimateSearchCondition({isContractComplete : v.target.checked})}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CIconBtn style={{width: 100}}
                                  title={'견적생성'}
                                  icon={'fi-rr-box-check'}
                                  onClick={()=> {setEstimateNo(''), AppStore.toggleModal()}}
                        />
                        <CSearchBtn onClick={getEstimateList} />
                    </Col>
                </Row>
            </CContainer>
            <CustomerSearch callbackFn={getCustomerInfo}/>
            <UserSearch callbackFn={getUserInfo} />
        </>
    );

}

export default observer(EstimateSearch);
