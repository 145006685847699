import { useEffect } from "react";
import { observer } from "mobx-react-lite"
import InsurancePolicyListSearch from "./InsurancePolicyListSearch";
import { CContainer } from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import { useRef } from "react";
import { InsurancePolicyStore } from "../../store/product/InsurancePolicyStore";
import axios from "axios";

const InsurancePolicyList = () => {
    const gridRef = useRef();
    
    useEffect(()=> {
        getInsurancePolicyList();
    }, [])


    const getInsurancePolicyList = async () => {
        const result = await axios.get('/product/insurancePolicy');
        InsurancePolicyStore.insurancePolicyList = result;
    }

    const fileUrl = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <a href={e.data.fileUrl} target="_blank">{e.data.fileName}</a>
            </div>
        )
    }

    return(
        <>
            <InsurancePolicyListSearch getInsurancePolicyList={getInsurancePolicyList}/>
            <CContainer>
                <AgGridContainer 
                    gridTitle={'보험증권 목록'}
                    useDownload={true}
                    gridRef={gridRef}
                    height={60}
                    rowData={InsurancePolicyStore.insurancePolicyList}
                    columnDefs={[
                        {field: "lgSeq", headerName: "대분류", width: 150, hide: true},
                        {field: "lgCate", headerName: "대분류", width: 130},
                        {field: "startDate", headerName: "시작일", width: 150},
                        {field: "endDate", headerName: "종료일", width: 150},
                        {field: "fileName", headerName: "파일", width: 230, cellRenderer: e => fileUrl(e)},
                        // {field: "fileUrl", headerName: "파일URL", width: 200},
                        {field: "remark", headerName: "비고", width: 280},
                        {field: "creator", headerName: "입력자", width: 180, hide: true},
                        {field: "creatorName", headerName: "입력자", width: 150},
                        {field: "createdDate", headerName: "입력일자", width: 200},
                        {field: "isActiveText", headerName: "사용여부"},
                        {field: "isActive", headerName: "사용여부", hide: true},
                    ]}
                />
            </CContainer>
        </>
    )

}

export default observer(InsurancePolicyList);