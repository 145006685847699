import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { AppStore } from '../../store/AppStore';
import { AsStore } from '../../store/asset/AsStore';
import { callAlert } from '..';

const GuestGuard = ({ children }) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        // if (!params.contractNo && !params.shipSeq) {
        //     if (AsStore.data.asSeq) {
        //         navigate(`/as/accept/detail/${AsStore.data.asSeq}`, { replace: true });
        //     } else {
        //         navigate('/dashboard', { replace: true });
        //     }
        // }

        if(location.pathname.indexOf('policy') > -1){
            navigate('/policy');
        }

    }, [navigate]);

    return children;
};

export default GuestGuard;
