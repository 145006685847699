import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import "./custom-datepicker.css"; 
import { subDays, subMonths } from 'date-fns';

import { AppStore } from "../../../store/AppStore";
import { CContainer, CCol, CSearchBtn, CInputGroup } from "../../../components/CustomContainer";
import { getStringFormat } from "../../../utils/dateUtils";
import Modal from "../../../components/Modal";

const PeriodSearchPopup = ({searchMethod}) => {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const [startDate, setStartDate] = useState(oneMonthAgo);
    const [endDate, setEndDate] = useState(today);

    const refresh = () => {
        setStartDate(oneMonthAgo);
        setEndDate(today);
    }

    const setDateRange = (days, months) => {
        const today = new Date();
        setEndDate(today);
        
        if (months) {
            setStartDate(subMonths(today, months)); // n개월 전 설정
        } else if (days) {
            setStartDate(subDays(today, days)); // n일 전 설정
        }
    };

    const handleChange = (value) => {
        // 숫자만 필터링하고, 8자 이내로 제한
        if (/^\d*$/.test(value) && value.length <= 8) {
          return value;
        }
        return null;
    };

    return (
        <Modal  title={'조회 기간'}
                onHide={()=> AppStore.togglePeriodSearchPopup()}
                toggleValue={AppStore.isOpenPeriodSearchPopup}
                size={'lg'}
                onExit={refresh}
        >
            <CContainer  >
                <div style={{ display: "flex", justifyContent: "center", gap: "10px", marginBottom: "20px" }}>
                    <Button onClick={() => setDateRange(7, null)}>1주일</Button>
                    <Button onClick={() => setDateRange(null, 1)}>1개월</Button>
                    <Button onClick={() => setDateRange(null, 3)}>3개월</Button>
                    <Button onClick={() => setDateRange(null, 6)}>6개월</Button>
                </div>
                <div style={{ display: "flex", gap: "20px", justifyContent: "center", alignItems: "center", }}>
                    {/* 시작일 캘린더 */}
                    <div style={{ textAlign: "center" }}>
                        <div style={{ marginBottom: "10px", fontSize: 16 }}>시작일</div>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="yyyy-MM-dd"
                            placeholderText="시작일 선택"
                            locale={ko}
                            inline
                        />
                    </div>

                    {/* 종료일 캘린더 */}
                    <div style={{ textAlign: "center", marginLeft: 30 }}>
                        <div style={{ marginBottom: "10px", fontSize: 16 }}>종료일</div>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            dateFormat="yyyy-MM-dd"
                            placeholderText="종료일 선택"
                            locale={ko}
                            inline
                        />
                    </div>
                </div>

                <Row style={{marginTop: 33}}>
                    <CCol lg={6}>
                        <CInputGroup label={'시작일'}
                                    placeholder="yyyyMMdd"
                                    value={getStringFormat(startDate)}
                                    readOnly
                        />
                    </CCol>
                    <CCol lg={6}>
                        <CInputGroup label={'종료일'}
                                    placeholder="yyyyMMdd"
                                    value={getStringFormat(endDate)}
                                    readOnly
                        />
                    </CCol>
                </Row>
            </CContainer>
            <Row style={{ marginTop: 17 }}>
                <Col className='d-flex justify-content-between'>
                    <CSearchBtn width={'96%'} onClick={() => searchMethod(startDate, endDate)} />
                </Col>
            </Row>   
        </Modal>
    );
}

export default observer(PeriodSearchPopup);
