import React, { useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import axios from 'axios';
import { Row, Col } from "react-bootstrap";

import AgGridContainer from "../../components/AgGridContainer";
import { CContainer } from "../../components/CustomContainer";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import { AppStore } from "../../store/AppStore";
import AccountingCodeSearch from "../../components/searchModal/AccountingCodeSearch";
import { showToast } from "../../common/utils";
import { today , oneMonthAfter } from "../../utils/dateUtils";
import CustomerLedgerListSearch from "./CustomerLedgerListSearch";
import { CustomerLedgerStore } from "../../store/accounting/CustomerLedgerStore";
import CustomerLedgerDetail from "./CustomerLedgerDetail";

const CustomerLedgerList = () => {
    const store = CustomerLedgerStore;
    const gridRef = useRef();

    useEffect(() => {
        // 한달 날짜 세팅
        store.search.startDate = today();
        store.search.endDate = oneMonthAfter(today());
    }, []);

    const columnDefs = [
        {
            headerName: '순번', 
            valueGetter: params => (params.node.rowIndex > 0 ? params.node.rowIndex : ''),
            width: 70},
        {field: "statementNo", headerName: "전표번호", hide: true},
        {field: "customerNo", headerName: "고객코드", minWidth: 110},
        {field: "customerName", headerName: "고객명", minWidth: 160},
        {field: "businessNumber", headerName: "사업자번호", minWidth: 140},
        {field: "priorYearRetained", headerName: "전기이월", minWidth: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
        {field: "increment", headerName: "증가", minWidth: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
        {field: "decrement", headerName: "감소", minWidth: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
        {field: "difference", headerName: "차액", minWidth: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), flex: 1},
    ];

    /* 조회 */
    const getCustomerLedgerList = async () => {
        // 조회 필수값 확인
        if(!store.search.startDate && !!store.search.endDate) return showToast('시작일, 종료일을 설정해주세요.');
        if(!store.search.accountCode) return showToast('계정코드를 입력해주세요.');

        const result = await axios.get("statement/ledger/list", {params: store.search});
        store.customerLedgerList = result;
        getPreSum();
        // if(result.length === 0) showToast('조회된 데이터가 없습니다.');
    }

    /* 이월잔액 조회 */
    const getPreSum = async () => { 
        const result = await axios.get("statement/ledger/pre", {params: store.search});
        store.customerLedgerList = [result, ...store.customerLedgerList];
    }

    /* 고객 팝업 callback */
    const searchCustomerInfo =(data)=> {
        store.search.customerNo = data.customerNo;
        store.search.customerName = data.customerName;
    }

    /* 계정 과목 팝업 callback */
    const searchAccountingCode = (data) => {
        store.search.accountCode = data.code;
        store.search.accountName = data.name;
    }

    /* 거래처원장 상세 */
    const onRowDoubleClicked = (e) => {
        store.detailSearch.startDate = store.search.startDate;
        store.detailSearch.endDate = store.search.endDate;
        store.detailSearch.accountCode = store.search.accountCode;
        store.detailSearch.accountName = store.search.accountName;
        store.detailSearch.customerNo = e.data.customerNo;
        store.detailSearch.customerName = e.data.customerName;
        store.openDetail = true;
        AppStore.toggleLedgerDetail();
    }

    /* 행 고정 */
    const pinnedTopRowData = [{
        increment: store.customerLedgerList.filter(v => v.customerNo !== '[이월잔액]').reduce((total, v) => total + v.increment, 0),
        decrement: store.customerLedgerList.filter(v => v.customerNo !== '[이월잔액]').reduce((total, v) => total + v.decrement, 0),
        difference: store.customerLedgerList.filter(v => v.customerNo !== '[이월잔액]').reduce((total, v) => total + v.difference, 0),
    }];

    return (
        <>
            <Row>
                <Col lg={4} className="mt-5">
                    <CustomerSearch callbackFn={searchCustomerInfo} />
                    <AccountingCodeSearch callbackFn={searchAccountingCode} inputCode={null} />
                    <CustomerLedgerListSearch getCustomerLedgerList={getCustomerLedgerList} />
                </Col>
                <Col lg={8}>
                    <CContainer>
                        <AgGridContainer
                            gridRef={gridRef}
                            gridTitle={'거래처원장'}
                            height={80}
                            rowData={store.customerLedgerList}
                            columnDefs={columnDefs}
                            useCsvDownload={true}
                            rowDoubleClickCallback={e => onRowDoubleClicked(e)}
                            getRowStyle={(params) => {
                                if (params.data.customerNo === '[이월잔액]') {
                                    return { pointerEvents: 'none', background: '#bababa' };
                                }
                                return null;
                            }}
                            pinnedTopRowData={pinnedTopRowData}
                        />
                    </CContainer>
                </Col>
            </Row>
            <CustomerLedgerDetail getCustomerLedgerList={getCustomerLedgerList} />
        </>
    )
}

export default observer(CustomerLedgerList);
