import { observer } from 'mobx-react-lite';
import {Row, Col, Form} from "react-bootstrap";
import { CContainer, CInputGroup, CCol, CSearchBtn, CAddBtn, RadioButtonGroupTrueFalse, CSelectGroup } from '../../components/CustomContainer';
import { CustomerStore } from '../../store/customer/CustomerStore';
import { getCommcode } from '../../utils/commcode';
import Regex from '../../common/regex';
import {ContractDetailStore} from "../../store/contract/ContractDetailStore";
import {showToast} from "../../common/utils";

const CustomerListSearch = ({getCustomerList, openModalForAddCustomer}) => {

    const onChangeSearchCondition = (e, target) => {
        const value = ['isAvailableContract', 'isClosedCustomer'].includes(target) ? e : e.target.value;
        CustomerStore.setSearchCondition({[target]: value});
    }

    /* 사업자번호 검사 */
    const checkFormBusinessNumber = (value) => {
        // 12자리 넘어가면 return false
        if (value.length >= 13) {
            return false;
        }

        // 숫자만 입력하는지 검사
        const inputNumber = value.replaceAll('-', '');
        if (isNaN(inputNumber)) {
            showToast('숫자만 입력해야 합니다.');
            return false;
        }

        // 000-00-00000 형태로 만들기
        let valueFormat;
        if (inputNumber.length < 4) {
            valueFormat = inputNumber;
        } else if (inputNumber.length > 3 && inputNumber.length < 6) {
            valueFormat = `${inputNumber.substring(0, 3)}-${inputNumber.substring(3, 5)}`
        } else {
            valueFormat = Regex.makeBusinessNumber(inputNumber);
        }

        CustomerStore.setSearchCondition({businessNumber: valueFormat});
    }

    return (
        <CContainer search>
            <Row>
                <CCol>
                    <CInputGroup
                        label={'고객번호'}
                        placeholder={'고객번호'}
                        value={CustomerStore.searchCondition.customerNo}
                        onChange={e => onChangeSearchCondition(e, 'customerNo')}
                        enterKeyHint={getCustomerList}
                    />
                </CCol>
                <CCol lg={2}>
                    <CInputGroup
                        label={'고객명'}
                        placeholder={'고객명'}
                        value={CustomerStore.searchCondition.customerName}
                        onChange={e => onChangeSearchCondition(e, 'customerName')}
                        enterKeyHint={getCustomerList}
                    />
                </CCol>
                <CCol lg={2}>
                    <CSelectGroup label={'고객유형'}
                                  value={CustomerStore.searchCondition.customerType}
                                  options={[{value:'', name:'전체'}, ...getCommcode('19')]}
                                  onChange={e => onChangeSearchCondition(e, 'customerType')}
                    />
                </CCol>
                <CCol lg={2}>
                    <CInputGroup
                        label={'대표자'}
                        placeholder={'대표자'}
                        value={CustomerStore.searchCondition.owner}
                        onChange={e => onChangeSearchCondition(e, 'owner')}
                        enterKeyHint={getCustomerList}
                    />
                </CCol>
            </Row>
            <Row>
                <CCol lg={2}>
                    <CInputGroup
                        label={'사업자번호'}
                        placeholder={'사업자번호'}
                        value={CustomerStore.searchCondition.businessNumber}
                        onChange={e => checkFormBusinessNumber(e.target.value)}
                        enterKeyHint={getCustomerList}
                    />
                </CCol>
                <CCol lg={2}>
                    <CInputGroup
                        label={'법인번호'}
                        placeholder={'법인번호'}
                        value={CustomerStore.searchCondition.corporationNumber}
                        onChange={e => onChangeSearchCondition(e, 'corporationNumber')}
                        enterKeyHint={getCustomerList}
                    />
                </CCol>
                <CCol lg={2}>
                    <CSelectGroup label={'고객구분'}
                                  value={CustomerStore.searchCondition.customerDiv}
                                  options={[{value:'', name:'전체'}, ...getCommcode('49')]}
                                  onChange={e => onChangeSearchCondition(e, 'customerDiv')}
                    />
                </CCol>
                <CCol lg={2}>
                    <RadioButtonGroupTrueFalse name={'isClosedCustomer'} useTotal={false} label='폐업여부' btnText={['영업','폐업']} value={CustomerStore.searchCondition.isClosedCustomer} onClick={e => onChangeSearchCondition(e, 'isClosedCustomer')} />
                </CCol>
                {/*
                <CCol lg={2}>
                    <Form.Check
                        type="switch"
                        id="switch"
                        label="간략히 보기"
                        style={{paddingTop:3}}
                        checked={!!ContractDetailStore.simpleView}
                        onChange={()=> ContractDetailStore.setSimpleView(!ContractDetailStore.simpleView)}
                    />
                </CCol>
                */}
            </Row>
            <Row>
                <CCol lg={2}>
                    <CInputGroup
                        label={'담당자명'}
                        value={CustomerStore.searchCondition.managerName}
                        onChange={e => onChangeSearchCondition(e, 'managerName')}
                        enterKeyHint={getCustomerList}
                    />
                </CCol>
                <CCol lg={2}>
                    <CInputGroup
                        label={'담당자연락처'}
                        placeholder={'-없이'}
                        value={CustomerStore.searchCondition.managerPhoneNumber}
                        onChange={e => onChangeSearchCondition(e, 'managerPhoneNumber')}
                        enterKeyHint={getCustomerList}
                    />
                </CCol>
                <CCol lg={3}>
                    <RadioButtonGroupTrueFalse name={'isAvailableContract'} label='계약가능여부' value={CustomerStore.searchCondition.isAvailableContract} onClick={e => onChangeSearchCondition(e, 'isAvailableContract')} />
                </CCol>
                <Col className='d-flex flex-row-reverse'>
                    <CSearchBtn onClick={getCustomerList} />
                    <CAddBtn title={'고객등록'} onClick={() => openModalForAddCustomer('create')} style={{width: 100, height: 30}} />
                </Col>
            </Row>
        </CContainer>
    );
};

export default observer(CustomerListSearch);
