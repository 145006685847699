import { Outlet } from "react-router-dom";

import GuestGuard from "../utils/route-guard/GuestGuard";
import ContractLinkIndex from "../views/contract/link/ContractLinkIndex";
import ContractLinkPage from "../views/contract/link/ContractLinkPage";
import ExpirationPage from "../views/contract/link/ExpirationPage";

const ContractDocumentRoutes = {
    path: '/',
    element: (
            <GuestGuard>
                <Outlet />
            </GuestGuard>
        ),
    children: [
        {path: '/contract/document/:contractNo/:seq/index', element: <ContractLinkIndex />},
        {path: '/contract/document/:contractNo/:seq', element: <ContractLinkPage />},
        {path: '/expiration/:contractNo', element: <ExpirationPage />},
    ]
}

export default ContractDocumentRoutes;
