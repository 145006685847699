

import React from "react";
import { observer } from "mobx-react-lite";
import { Image } from "react-bootstrap";

import "./signaturePopupStyle.css";

const ExpirationPage = () => {
    return(
        <div align={'center'} valign={'middle'} style={{width: '100%', height: '100%', marginTop: 150}} >
            <Image src={`${process.env.PUBLIC_URL}/image/expired.png`} alt="로그인 이미지" style={{paddingTop: 60, width: 300, verticalAlign: 'middle', marginBottom: 50}}/>
            <h1>해당 링크의 이용 기간이 만료되었습니다.</h1>
            <h2 style={{color: '#999', marginTop: 20}}>자세한 내용은 담당자에게 문의해 주세요.</h2>
        </div>
    );
}

export default observer(ExpirationPage);
