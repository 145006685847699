import axios from "axios";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import PurchaseSalesStatementSearch from "./PurchaseSalesStatementSearch";
import AgGridContainer from "../../components/AgGridContainer";
import { CContainer, IconBtn } from "../../components/CustomContainer";
import { PurchaseSalesStatementStore } from "../../store/accounting/PurchaseSalesStatementStore";
import { findCommKrnm, getCommcode } from "../../utils/commcode";
import { callConfirm } from "../../utils";
import { showToast } from "../../common/utils";
import { Form } from "react-bootstrap";
import { dateFormat } from "../../utils/dateUtils";
import { getUserName } from "../../utils/userUtils";

const PurchaseSalesStatementList = () => {
    const purchaseGridRef = useRef();
    const salesGridRef = useRef();
    const [selView, setSelView] = useState('purchase');

    /* 목록 가져오기 */
    const getPurchaseSalesList = async () => {
        const result = await axios.get('/statement/purchaseSales', {params: {statementType: selView === 'purchase' ? '2' : selView === 'sales' ? '3' : '1'
                                                                            , startDate: PurchaseSalesStatementStore.search.startDate
                                                                            , endDate: PurchaseSalesStatementStore.search.endDate
                                                                            , createStatus: PurchaseSalesStatementStore.search.createStatus
                                                                            , customerName: PurchaseSalesStatementStore.search.customerName
                                                                            , businessNumber: PurchaseSalesStatementStore.search.businessNumber
                                                                            , taxType: PurchaseSalesStatementStore.search.taxType
                                                                            , isCard: PurchaseSalesStatementStore.search.isCard}});

        selView === 'purchase' ? PurchaseSalesStatementStore.setPurchaseStatementList(result) : PurchaseSalesStatementStore.setSalesStatementList(result);
    }

    /* 선택 버튼 옵션 - 삭제 */
    const deleteBtn = {
        width: 120,
        isUsed: true,
        title: '선택전표삭제',
        icon: 'fi-rr-delete-document',
        callbackFn: () => removeSelectedRows(),
        variant: 'dark'
    };

    /* 매입매출장 삭제 */
    const removeSelectedRows = async () => {
        const selectedList = selView === 'purchase' ? PurchaseSalesStatementStore.purchaseStatementList.filter(v => v.isSelected) : PurchaseSalesStatementStore.salesStatementList.filter(v => v.isSelected);

        if (selectedList.length === 0) {
            showToast('선택된 전표가 없습니다.');
            return;
        }

        const validApproval = selectedList.every(v => v.approvalStatus !== '2');
        if (!validApproval) {
            showToast('이미 승인된 전표는 삭제할 수 없습니다.');
            return;
        }

        const validBillNo = selectedList.every(v => !v.billNo);
        if (!validBillNo) {
            showToast('계산서 발행으로 생성된 전표는 삭제할 수 없습니다.');
            return;
        }

        if (!await callConfirm('삭제하시겠습니까?')) {
            return;
        }

        await axios.put('/statement/purchaseSales/delete', selectedList);
        showToast('삭제가 완료되었습니다.');
        getPurchaseSalesList();
    }

    /* 전자계산서 여부 체크박스 렌더링 */
    const setCheckbox = (e) => {
        return (
            <>
                <div className="ag-cell-wrapper">
                    <Form.Check
                        type="checkbox"
                        id="isElectronic"
                        checked={e.data.isElectronic == 1}
                        value={e.data.isElectronic == 1}
                        readOnly={true}
                        // onChange={(v) => checkedHandler(e, v) }
                        className="ag-input-field-input ag-checkbox-input"
                    />
                </div>
            </>
        )
    }

    /* 행 고정 */
    const pinnedTopRowData = [{
        supplyPrice: selView === 'sales' ? PurchaseSalesStatementStore.salesStatementList.reduce((total, v) => total + v.supplyPrice, 0) : PurchaseSalesStatementStore.purchaseStatementList.reduce((total, v) => total + v.supplyPrice, 0),
        vat: selView === 'sales' ? PurchaseSalesStatementStore.salesStatementList.reduce((total, v) => total + v.vat, 0) : PurchaseSalesStatementStore.purchaseStatementList.reduce((total, v) => total + v.vat, 0),
        totalPrice: selView === 'sales' ? PurchaseSalesStatementStore.salesStatementList.reduce((total, v) => total + v.totalPrice, 0) : PurchaseSalesStatementStore.purchaseStatementList.reduce((total, v) => total + v.totalPrice, 0),
        isElectronic: 'pinnedTopRowData',
    }];

    return(
        <>
            <PurchaseSalesStatementSearch selView={selView} setSelView={setSelView} getPurchaseSalesList={getPurchaseSalesList} />
            <CContainer>
                    <AgGridContainer
                        gridRef={salesGridRef}
                        gridTitle={'매출전표'}
                        height={50}
                        rowData={selView === 'sales' ? PurchaseSalesStatementStore.salesStatementList : PurchaseSalesStatementStore.purchaseStatementList}
                        columnDefs={[
                            {field: 'salesSeq', headerName: '번호', hide: true},
                            // {field: 'seq', headerName: '순번', cellRenderer: e => addSeq(e), width: 59},
                            {field: 'isStatement', headerName: '생성', valueFormatter: v => v.data.statementNo ? '완료' : '', width: 60, cellClass: 'ag-grid-column-complete'},
                            {field: 'resolutionDate', headerName: '날짜 (YYYY-MM-DD)', headerClass: 'grid-column-required', width: 165},
                            {
                                field: 'paymentType',
                                headerName: '결제유형',
                                valueFormatter: v => findCommKrnm(v.value, '17'),
                                headerClass: 'grid-column-required',
                                width: 95
                            },
                            {
                                field: 'taxType',
                                headerName: '부가세',
                                valueFormatter: v => findCommKrnm(v.value, '45'),
                                headerClass: 'grid-column-required',
                                width: 70
                            },
                            {field: 'remark', headerName: '적요', headerClass: 'grid-column-required', width: 200},
                            {
                                field: 'supplyPrice',
                                headerName: '공급가액',
                                cellClass: 'ag-grid-money-align',
                                valueFormatter: v => v.value?.toLocaleString(),
                                headerClass: 'grid-column-required',
                                width: 125},
                            {field: 'vat', headerName: '부가세액', cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), headerClass: 'grid-column-required', width: 115},
                            {field: 'totalPrice', headerName: '합계', cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), headerClass: 'grid-column-required', width: 125},
                            {field: 'customerName', headerName: '거래처명', headerClass: 'grid-column-required', width: 170},
                            {field: 'customerNo', headerName: '거래처번호', headerClass: 'grid-column-required', width: 125},
                            {field: 'businessNumber', headerName: '사업자번호', headerClass: 'grid-column-required', width: 135},
                            {
                                field: 'isElectronic',
                                headerName: '전자',
                                cellRenderer: v => v.value === 'pinnedTopRowData' ? null : setCheckbox(v),
                                width: 60}, // 전자계산서인지 체크
                            {field: 'createdDate', headerName: '등록일자', valueFormatter: v => dateFormat(v.value), width: 135},
                            {field: 'creator', headerName: '등록자', valueFormatter: v => getUserName(v.value), width: 135},
                            {field: 'postingDate', headerName: '회계처리일자', valueFormatter: v => dateFormat(v.value), width: 135},
                        ]}
                        seqColumn={'agId'}
                        isCheckBox={true}
                        customBtnInfo={[deleteBtn]}
                        useCsvDownload={true}
                        pinnedTopRowData={pinnedTopRowData}
                    />
            </CContainer>
        </>
    );
}

export default observer(PurchaseSalesStatementList);
