import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const ReleaseAskListStore = observable({
    releaseAskList: [],
    releaseAskSearchCondition: {contractNo: '', releaseAskSeq: null, startDate: '', endDate: '', customerNo: '', customerName: '', releaseAskStatus: '', releaseAskType: '', creator: null, releaseUserSeq: null, userName: '', siteName: '', apronName: ''},

    setReleaseAskList(v) {
        this.releaseAskList = v;
    },
    setReleaseAskSearchCondition(v) {
        LUtils.assign(this.releaseAskSearchCondition, v);
    },
});
