import { observer } from "mobx-react-lite"
import Modal from "../../../components/Modal";
import { useRef, useState } from "react";
import { AppStore } from "../../../store/AppStore";
import { CContainer, CCol } from "../../../components/CustomContainer";
import { Row, InputGroup, Form } from "react-bootstrap";
import AgGridContainer from "../../../components/AgGridContainer";
import { findCommKrnm } from "../../../utils/commcode";

const StockDetailPopup = ({stockDetailList, setStockDetailList, selSearchItem, title, originStockDetailList}) => {
    const gridRef = useRef();
    const [assetNo, setAssetNo] = useState('');
    const [managementNo, setManagementNo] = useState('');

    const assetColumnDefs = [
        {field: "lgCate", headerName: "대분류", width: 120},
        {field: "productName", headerName: "제품명", width: 120},
        {field: "model", headerName: "모델", width: 120},
        {field: "managementNo", headerName: "관리번호", width: 120},
        {field: "assetNo", headerName: "자산번호", width: 120, hide: true},
        {field: "assetStatus", headerName: "자산상태", width: 120, valueFormatter: v => findCommKrnm(v.value, '8')},
        {field: "shelfId", headerName: "선반번호", width: 120},
        {field: "serialNo", headerName: "시리얼번호", width: 120},
        {field: "spec", headerName: "스펙", width: 150, flex: 1},
    ];
    
    const goodsColumnDefs = [
        {field: "lgCate", headerName: "대분류", width: 120},
        {field: "mdCate", headerName: "중분류", width: 120},
        {field: "smCate", headerName: "소분류", width: 120},
        {field: "productName", headerName: "제품명", width: 120},
        {field: "model", headerName: "모델", width: 120},
        {field: "serialNo", headerName: "시리얼번호", width: 120},
        {field: "qty", headerName: "수량", width: 120},
        {field: "storageNo", headerName: "입고번호", width: 120},
        {field: "storageSeq", headerName: "입고순번", valueFormatter: v => v.data.storageSeq === 0 ? '' : v.data.storageSeq, width: 120},
        {field: "spec", headerName: "스펙", width: 120},
        {field: "storageDate", headerName: "입고일자", width: 120},
    ];

    const refresh = () => {

    }


    return (
        <Modal 
            title={title}
            show={AppStore.isOpenExtendAssetPopup}
            onHide={() => AppStore.toggleStockDetailPopup()}
            toggleValue={AppStore.isOpenStockDetailPopup}
            onExit={refresh}
        >
            <CContainer>
                {/* <Tabs
                    defaultActiveKey={tab === 'all' || tab === 'goods' ? 'goods' : 'asset'}
                    className="mb-3"
                    mountOnEnter={true}
                    unmountOnExit={true}
                    activeKey={stockTab}
                    onSelect={k => setStockTab(k)}
                >
                    <Tab eventKey='goods' title="상품">
                        <>
                            <AgGridContainer
                                gridRef={goodsGridRef}
                                gridTitle={'상품목록'}
                                height={51}
                                rowData={goodsList}
                                columnDefs={goodsColumnDefs}
                            />
                        </>
                    </Tab>
                    <Tab eventKey='asset' title="자산"> */}
                        <>
                        {selSearchItem === 'asset' && 
                            <Row>
                                {/* <CCol lg={3}>
                                    <InputGroup size={'sm'} className="mt-2">
                                        <InputGroup.Text id={'assetNo'}>자산번호</InputGroup.Text>
                                        <Form.Control aria-describedby={'assetNo'}
                                                    value={assetNo}
                                                    onChange={(v)=> {
                                                        let filterAssetNo = v.target.value;
                                                        setAssetNo(filterAssetNo);
                                                        
                                                        if(filterAssetNo){
                                                            const filterAssetList = originStockDetailList.filter(f => f.assetNo.indexOf(filterAssetNo) > -1);
                                                            setStockDetailList(filterAssetList);
                                                        }else{
                                                            setStockDetailList(originStockDetailList);
                                                        }
                                                    }}
                                                    placeholder={'필터'}
                                        />
                                    </InputGroup>
                                </CCol> */}
                                <CCol lg={3}>
                                    <InputGroup size={'sm'} className="mt-2">
                                        <InputGroup.Text id={'managementNo'}>관리번호</InputGroup.Text>
                                        <Form.Control aria-describedby={'managementNo'}
                                                    value={managementNo}
                                                    onChange={(v)=> {
                                                        let filterManagementNo = v.target.value;
                                                        setManagementNo(filterManagementNo);
                                                        
                                                        if(filterManagementNo){
                                                            const filterAssetList = originStockDetailList.filter(f => f.managementNo.indexOf(filterManagementNo) > -1);
                                                            setStockDetailList(filterAssetList);
                                                        }else{
                                                            setStockDetailList(originStockDetailList);
                                                        }
                                                    }}
                                                    placeholder={'필터'}
                                        />
                                    </InputGroup>
                                </CCol>
                            </Row>
                        }
                            <AgGridContainer
                                gridRef={gridRef}
                                gridTitle={selSearchItem === 'asset' ? '자산목록' : '상품목록'}
                                height={51}
                                rowData={stockDetailList}
                                columnDefs={selSearchItem === 'asset' ? assetColumnDefs: goodsColumnDefs}
                            />
                        </>
                    {/* </Tab>
                </Tabs> */}
            </CContainer>
        </Modal>
    );
}

export default observer(StockDetailPopup);