import {useEffect, useRef, useState} from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer, CInputGroup, CSearchBtn } from "../CustomContainer";
import { CommcodeSMOptions } from "../index";
import AgGridContainer from "../AgGridContainer";
import {ContractDetailStore} from "../../store/contract/ContractDetailStore";
import {showToast} from "../../common/utils";
import {findCommKrnm} from "../../utils/commcode";
import { getLocalStorage } from "../../utils";
import { USER_INFO } from "../../common/constants";

const StockAssetSearch =({ callbackProduct=null, callbackOption=null, ...props })=> {
    const gridRef = useRef();

    const [searchList, setSearchList] = useState([]);
    const [managementNo, setManagementNo] = useState('');
    const [assetNo, setAssetNo] = useState('');
    const [productName, setProductName] = useState('');
    const [model, setModel] = useState('');
    const [brandCode, setBrandCode] = useState('');
    const [apronSeq, setApronSeq] = useState(null);
    const [apronList, setApronList] = useState([]);

    useEffect(() => {
            getApronList();
            setApronSeq(getLocalStorage(USER_INFO).apronSeq);
    }, [AppStore.isStockAssetSearch])

    const getApronList = async() => {
        const result = await axios.get("/user/apron");
        const resultList = result.map(v => {
            v.name = v.apronName;
            v.value = v.apronSeq;
            return v;
        })
        setApronList(resultList);
    }

    /* 제조사 값 설정 */
    const onChangeBrandName = (v) => {
        setBrandCode(v.target.value);
    }

    /* 조회 */
    const onSearch = async () => {
        const params = {
            managementNo,
            productName,
            assetNo,
            model,
            brandCode,
            isDeleted: 0,
            apronSeq,
        };
        const result = await axios.get(`/asset/awp/stock/list/A`, { params });
        const selectedItems = searchList.filter(v => v.isSelected);
        const uniqueList = [...selectedItems];

        for (const item of result) {
            const isDuplicate = selectedItems.some(v =>
                v.serialNo === item.serialNo && v.managementNo === item.managementNo
            );

            if (!isDuplicate) {
                uniqueList.push(item);
            }
        }

        setSearchList(uniqueList);
    }

    const selectedRow = (e) => {
        if (ContractDetailStore.contractProductList.length > 0 && ContractDetailStore.contractProductList.findIndex(v => v.assetNo === e.data.assetNo) > -1) {
            showToast(`이미 추가된 제품 입니다. [${e.data.productName}]`);
            return;
        }

        callbackProduct([e.data]);

        AppStore.toggleStockAssetSearchModal();
    }

    const callBackGridData = ({ selectedList }) => {
        // 동일한 제품코드가 있는지 체크
        if(ContractDetailStore.contractProductList.length > 0){
            for(let data of selectedList){
                for(let row of ContractDetailStore.contractProductList){
                    if(data.assetNo === row.assetNo){
                        showToast(`이미 추가된 제품 입니다. [${data.managementNo}]`);
                        return false;
                    }
                }
            }
        } else {
            for (let data of selectedList) {
                if(selectedList[0].apronSeq !== data.apronSeq) {
                    showToast('같은 주기장의 장비만 선택할 수 있습니다.');
                    return;
                }
            }
        }

        callbackProduct(selectedList);
        AppStore.toggleStockAssetSearchModal();
    }

    /* 초기화 */
    const refresh = () => {
        setSearchList([]);
    }

    const onChangeApronName = () => {

    }

    return (
        <Modal
            title={`재고장비조회`}
            show={AppStore.isStockAssetSearch}
            onHide={() => AppStore.toggleStockAssetSearchModal()}
            toggleValue={AppStore.isStockAssetSearch}
            className={'modal-w12'}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'관리번호'}
                            labelId={'managementNo'}
                            value={managementNo}
                            onChange={(v)=> setManagementNo(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'자산번호'}
                            labelId={'assetNo'}
                            value={assetNo}
                            onChange={(v)=> setAssetNo(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'제품명'}
                            labelId={'productName'}
                            value={productName}
                            onChange={(v)=> setProductName(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'모델명'}
                            labelId={'model'}
                            value={model}
                            onChange={(v)=> setModel(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <Form.Select aria-label="선택" value={brandCode} onChange={onChangeBrandName} disabled={props.productCode}>
                                <option value={''}>- 제조사 -</option>
                                { <CommcodeSMOptions commKind={'18'} />}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <InputGroup size={'sm'}>
                            <Form.Select aria-label="선택" value={apronSeq} onChange={(v) => setApronSeq(v.target.value)}>
                                <option value={''}>- 주기장 -</option>
                                {apronList.map((option, idx)=> {
                                    return(
                                        <option key={idx} value={option.value}>{option.name}</option>
                                    );
                                })}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={3} style={{paddingTop: 3}}>
                        <Form.Check
                            inline
                            label="임대가능 장비만 보기"
                            name="availableStock"
                            type={'radio'}
                            id='stock'
                            value={'stock'}
                            defaultChecked={true}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearch} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={45}
                    rowData={searchList}
                    columnDefs={[
                        {field: "productGroupName", headerName: "분류", minWidth: 110},
                        {field: "assetType", headerName: "자산구분", minWidth: 110, valueFormatter: v => findCommKrnm(v.value, '7')},
                        {field: "assetNo", headerName: "자산번호", minWidth: 110, hide: true},
                        {field: "managementNo", headerName: "관리번호", minWidth: 110},
                        {field: "productCode", headerName: "제품코드", minWidth: 110, hide: true},
                        {field: "productName", headerName: "제품명", minWidth: 120},
                        {field: "model", headerName: "모델명", minWidth: 160},
                        {field: "serialNo", headerName: "시리얼 번호", minWidth: 160},
                        {field: "monthUnitPrice", headerName: "표준 월단가", minWidth: 140, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
                        {field: "apronName", headerName: "주기장", minWidth: 100},
                        {field: "exteriorStatus", headerName: "외관상태", minWidth: 120},
                        {field: "tireStatus", headerName: "타이어상태", minWidth: 120},
                        {field: "isReserve", headerName: "예약여부", minWidth: 90, hide: true},
                        {field: "spec", headerName: "스펙", minWidth: 500},
                        {field: "apronSeq", headerName: "주기장 순번", minWidth: 500, hide: true},
                    ]}
                    callBackGridData={callBackGridData}
                    selectBtnInfo={{isUsed: true}}
                    btnTitles={{save: '선택'}}
                    isCheckBox={true}
                    enableCellTextSelection={true}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(StockAssetSearch);
