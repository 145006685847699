import { observer } from "mobx-react-lite"
import { AppStore } from "../../../store/AppStore";
import Modal from "../../../components/Modal";
import { CContainer, CIconBtn } from "../../../components/CustomContainer";
import { Button, Col, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

const CertificationContentDetailPopup = ({htmlString}) => {

    const refresh = () => {

    }

    return (
        <Modal toggleValue={AppStore.isOpenCertificationContentDetailPopup}
                onHide={()=> AppStore.toggleOpenCertificationContentDetailPopup()}
                title={'내용 증명'}
                className='modal-w9'
                onExit={refresh}>
            <CContainer>
                <Row>
                    <Col>
                        <div dangerouslySetInnerHTML={{__html: htmlString}}></div>
                    </Col>
                </Row>
            </CContainer>
        </Modal>
    )
}

export default observer(CertificationContentDetailPopup);