import { observer } from 'mobx-react-lite';
import { AsStore } from '../../store/asset/AsStore';
import { useState, useEffect } from 'react';
import { AppStore } from '../../store/AppStore';
import { CContainer, CCol, CalendarGroup, CInputGroup, CSearchBtn, CIconBtn } from '../../components/CustomContainer';
import { Row, Col } from 'react-bootstrap';
import UserSearch from '../../components/searchModal/UserSearch';
import SiteNameSearch from '../../components/searchModal/SiteNameSearch';
import { useLocation } from "react-router-dom";
import LUtils from "../../utils/lodashUtils";
import {today} from '../../utils/dateUtils';

const AsListSearch = ({getAsList}) => {

    const location = useLocation();

    const [userColumn, setUserColumn] = useState('');
    const [searchUser, setSearchUser] = useState('');

    useEffect(() => {
        const fromPage = LUtils.get(location.state, 'from', null) ;
        if(fromPage === 'dashboard'){
            AsStore.search.startDateString = today();
            AsStore.search.endDateString = today();
            getAsList()
        }
    }, []);

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = (column) => {
        setUserColumn(column);
        if (column === 'asManager') {
            setSearchUser('as');
        } else {
            setSearchUser('');
        }
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo, column) => {
        if (!userInfo || !column) {
            return false;
        }
        
        AsStore.setSearchCondition({[`${column}Name`]: userInfo.name});
        AsStore.setSearchCondition({[`${column}Seq`]: userInfo.userSeq});
        setUserColumn('');
    }

    /* 현장 검색 팝업 오픈 */
    const openSearchSitePopup = () => {
        AppStore.toggleSiteNameSearchPopup();
    }
    /* 현장 검색 콜백 함수 */
    const getSiteInfo = (siteInfo) => {
        AsStore.setSearchCondition({siteName: siteInfo.siteName, siteSeq: siteInfo.siteSeq});
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CalendarGroup label={'접수일자'}
                                           value={{
                                                startDate: AsStore.search.startDateString,
                                                endDate: AsStore.search.endDateString
                                           }}
                                           onChange={v => {AsStore.search.startDateString = v.startDate, AsStore.search.endDateString = v.endDate}}
                            />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'자산번호'}
                            value={AsStore.search.assetNo}
                            onChange={v => AsStore.search.assetNo = v.target.value}
                            enterKeyHint={getAsList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'관리번호'}
                            value={AsStore.search.managementNo}
                            onChange={v => AsStore.search.managementNo = v.target.value}
                            enterKeyHint={getAsList}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'현장'}
                            value={AsStore.search.siteName}
                            onCallbackBtn={openSearchSitePopup}
                            onChange={(v)=> {
                                if(AsStore.search.siteSeq) {
                                    AsStore.setSearchCondition({siteSeq: ''})
                                }
                                AsStore.setSearchCondition({siteName: v.target.value})
                            }}
                            enterKeyHint={getAsList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'접수자'}
                            value={AsStore.search.acceptUserName}
                            onCallbackBtn={() => openSearchUserPopup('acceptUser')}
                            onChange={v => {AsStore.search.acceptUserName = v.target.value; AsStore.search.acceptUserSeq = '';}}
                            enterKeyHint={getAsList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'완료자'}
                            value={AsStore.search.completeUserName}
                            onChange={v => {AsStore.search.completeUserName = v.target.value; AsStore.search.completeUserSeq = '';}}
                            onCallbackBtn={() => openSearchUserPopup('completeUser')}
                            enterKeyHint={getAsList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'AS담당자'}
                            value={AsStore.search.asManagerName}
                            onCallbackBtn={() => openSearchUserPopup('asManager')}
                            onChange={v => {AsStore.search.asManagerName = v.target.value; AsStore.search.asManagerSeq = '';}}
                            enterKeyHint={getAsList}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getAsList} style={{width:100}} />
                        <CIconBtn title={'AS 접수'}
                                  icon={'fi-rr-download'}
                                  onClick={()=> AppStore.toggleAsDetail()}
                                  style={{width:100}}
                        />
                    </Col>
                </Row>
            </CContainer>
            {/* 팝업 */}
            <UserSearch callbackFn={getUserInfo} column={userColumn} search={searchUser}/>
            <SiteNameSearch callbackFn={getSiteInfo}/>
        </>
    );
}

export default observer(AsListSearch);