import { observable } from "mobx";

export const MaintenanceStore = observable({
    maintenanceList: [],
    search: {
        startDate: '',
        endDate: '',
        assetNo: '',
        managementNo: '',
        asSeq: '',
        mechanic: '',
        mechanicName: '',
    },
    status: 'list',
    detail: {
        maintenanceSeq: '',
        managementNo: '',
        model: '',
        spec: '',
        maintenanceStatus: null,
    },
    detailList: [],
    detailConsumList: [],
    detailConsumableList: [],
    detailInput: {
        managementNo: '',
        assetNo: '',
        storageNo: '',
        storageSeq: '',
        serialNo: '',
        content: '',
        mechanic: '',
        mechanicName: '',
        amount: '', // 소모품 금액 sum
        assetStatus: '',
    },
    isConsumableDetail: true,
    detailInit() {
        this.status = 'list';
        this.detail.maintenanceSeq = '';
        this.detail.managementNo = '';
        this.detail.model = '';
        this.detail.spec = '';
        this.detail.maintenanceStatus = null;

        this.detailInput.managementNo = '';
        this.detailInput.assetNo = '';
        this.detailInput.assetStatus = '';
        this.detailInput.storageNo = '';
        this.detailInput.storageSeq = '';
        this.detailInput.storageSeq = '';
        this.detailInput.content = '';
        this.detailInput.mechanic = '';
        this.detailInput.mechanicName = '';
        this.detailInput.amount = '';

        this.detailList = [];
        this.detailConsumList = [];
        this.detailConsumableList = [];
    }
});
