import React, {forwardRef, useEffect, useState} from "react";
import axios from "axios";
import "./reportTemplate.css";
import {dateDiffDetailed, getTodayDay, getTodayMonth, getYear, today} from "../../utils/dateUtils";
import { findCommKrnm } from "../../utils/commcode";
import { decrypt } from "../../common/utils";


const ReportCertificateTemplate =(props, ref)=> {
    const {type} = props;
    const [userInfo, setUserInfo] = useState({});
    const [years, setYears] = useState('');
    const [months, setMonths] = useState('');
    const [days, setDays] = useState('');
    const [personalNumberFirst, setPersonalNumberFirst] = useState('');
    const [personalNumberSecond, setPersonalNumberSecond] = useState('');

    useEffect(()=> {
        //TODO : userSeq받아서 개인정보 가져와야 함
        if (props.userSeq) {
            getUserInfo();
        }

    }, [props.userSeq])

    const getUserInfo = async () => {
        const result = await axios.get("/user/detail", {params: {userSeq: props.userSeq}});
        setUserInfo(result);

        // 재직 기간 계산
        const periodResult = dateDiffDetailed(result.joinDate, result.resignationDate ? result.resignationDate : today());
        setYears(periodResult.years);
        setMonths(periodResult.months);
        setDays(periodResult.days);
    
    }


    return (
        <div ref={ref} className="report-template" style={{padding: 20, width: '100%'}}>
            <div style={{borderWidth: 2, borderColor: '#1c1c1c', height: '100%'}}>
                <h1 style={{textAlign: 'center', marginTop: 30, marginBottom: 30}}>
                    {type === 'A' && '위 임 장'}
                    {type === 'B' && '재 직 증 명 서'}
                    {type === 'C' && '경 력 증 명 서'}
                    {type === 'D' && '퇴 직 증 명 서'}
                </h1>
                <table className="table border-color" >
                    <tbody>
                    {type === 'A' ?
                        <>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} width={'10%'} align={'center'} valign={'middle'} rowSpan={5}>수임자</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} width={'15%'} height={50} align={'center'} valign={'middle'}>성 명</td>
                            <td className='p-1 border' style={{fontSize: 16}} width={'30%'} height={50} align={'center'} valign={'middle'}>{userInfo.name}</td>
                            <td className='p-1 border' style={{fontSize: 16}} width={'15%'} height={50} align={'center'} valign={'middle'}>주민등록번호</td>
                            <td className='p-1 border' style={{fontSize: 16}} width={'30%'} height={50} align={'center'} valign={'middle'}>{props.personalNumberFirst + '-' + (props.isMasking && props.personalNumberSecond.length > 0 ? props.personalNumberSecond[0] + '*'.repeat(props.personalNumberSecond.length - 1) : props.personalNumberSecond)}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} align={'center'} valign={'middle'} height={50}>주소</td>
                            <td className='p-1 border' style={{fontSize: 16}} align={'left'} valign={'middle'} height={50} colSpan={3}><span style={{paddingLeft: 20, fontSize: 16}}>{userInfo.address}, {userInfo.addressDetail}</span></td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} align={'center'} valign={'middle'} height={50}>소 속</td>
                            <td className='p-1 border' style={{fontSize: 16}} align={'left'} valign={'middle'} height={50} colSpan={3}><span style={{paddingLeft: 20, fontSize: 16}}>{userInfo.partName}</span></td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} align={'center'} valign={'middle'} height={50}>직위 또는 직급</td>
                            <td className='p-1 border' style={{fontSize: 16}} align={'left'} valign={'middle'} height={50} colSpan={3}><span style={{paddingLeft: 20, fontSize: 16}}>{findCommKrnm(userInfo.positionSeq, '31')}</span></td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} width={'10%'} align={'center'} valign={'middle'} rowSpan={4}>위임자</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} align={'center'} valign={'middle'} height={50}>상 호</td>
                            <td className='p-1 border' style={{fontSize: 16}} align={'left'} valign={'middle'} height={50} colSpan={3}><span style={{paddingLeft: 20, fontSize: 16}}>{userInfo.companyName}</span></td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} align={'center'} valign={'middle'} height={50}>사업자등록번호</td>
                            <td className='p-1 border' style={{fontSize: 16}} align={'left'} valign={'middle'} height={50} colSpan={3}><span style={{paddingLeft: 20, fontSize: 16}}>{userInfo.companyBusinessNumber}</span></td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} align={'center'} valign={'middle'} height={50}>주소</td>
                            <td className='p-1 border' style={{fontSize: 16}} align={'left'} valign={'middle'} height={50} colSpan={3}><span style={{paddingLeft: 20, fontSize: 16}}>{userInfo.companyAddress}, {userInfo.companyAddressDetail}</span></td>
                        </tr>
                        </>
                    :
                        <>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} width={'10%'} align={'center'} valign={'middle'} rowSpan={3}>인적사항</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} width={'15%'} height={50} align={'center'} valign={'middle'}>성 명</td>
                            <td className='p-1 border' style={{fontSize: 16}} width={'30%'} height={50} align={'center'} valign={'middle'}>{userInfo.name}</td>
                            <td className='p-1 border' style={{fontSize: 16}} width={'15%'} height={50} align={'center'} valign={'middle'}>주민등록번호</td>
                            <td className='p-1 border' style={{fontSize: 16}} width={'30%'} height={50} align={'center'} valign={'middle'}>{props.personalNumberFirst + '-' + (props.isMasking && props.personalNumberSecond.length > 0 ? props.personalNumberSecond[0] + '*'.repeat(props.personalNumberSecond.length - 1) : props.personalNumberSecond)}</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} align={'center'} valign={'middle'} height={50}>주소</td>
                            <td className='p-1 border' style={{fontSize: 16}} align={'left'} valign={'middle'} height={50} colSpan={3}><span style={{paddingLeft: 20, fontSize: 16}}>{userInfo.address}, {userInfo.addressDetail}</span></td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} width={'10%'} align={'center'} valign={'middle'} rowSpan={6}>재직사항</td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} align={'center'} valign={'middle'} height={50}>소 속</td>
                            <td className='p-1 border' style={{fontSize: 16}} align={'left'} valign={'middle'} height={50} colSpan={3}><span style={{paddingLeft: 20, fontSize: 16}}>{userInfo.partName}</span></td>
                        </tr>
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} align={'center'} valign={'middle'} height={50}>직위 또는 직급</td>
                            <td className='p-1 border' style={{fontSize: 16}} align={'left'} valign={'middle'} height={50} colSpan={3}><span style={{paddingLeft: 20, fontSize: 16}}>{findCommKrnm(userInfo.positionSeq, '31')}</span></td>
                        </tr>
                        {type === 'B' || type === 'C' ?
                            <>
                                <tr>
                                    <td className='p-1 border' style={{fontSize: 16}} align={'center'} valign={'middle'} height={50} rowSpan={3}>기간</td>
                                    <td className='p-1 border' style={{fontSize: 16}} align={'left'} valign={'middle'} height={50} colSpan={3}>
                                        <span style={{paddingLeft: 20, fontSize: 16}}>{userInfo.joinDate} 부터</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='p-1 border' style={{fontSize: 16}} align={'left'} valign={'middle'} height={50} colSpan={3}>
                                        {type === 'B' && <span style={{paddingLeft: 20, fontSize: 16}}>{years+'년 '+months+"개월 "+days+"일"}</span>}
                                        {type === 'C' && <span style={{paddingLeft: 20, fontSize: 16}}>{today()} 까지</span>}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='p-1 border' style={{fontSize: 16}} align={'left'} valign={'middle'} height={50} colSpan={3}>
                                        {type === 'B' && <span style={{paddingLeft: 20, fontSize: 16}}>{years > 0 ? '근속기간 1년 이상' : '근속기간 ' +months+'개월 이상'}</span>}
                                        {type === 'C' && <span style={{paddingLeft: 20, fontSize: 16}}>{years+'년 '+months+"개월 "+days+"일"}</span>}
                                    </td>
                                </tr>
                            </>
                        :
                            <>
                                <tr>
                                    <td className='p-1 border' style={{fontSize: 16}} align={'center'} valign={'middle'} height={50} rowSpan={2}>기간</td>
                                    <td className='p-1 border' style={{fontSize: 16}} align={'left'} valign={'middle'} height={50} colSpan={3}>
                                        <span style={{paddingLeft: 20, fontSize: 16}}>{userInfo.resignationDate} 에</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='p-1 border' style={{fontSize: 16}} align={'left'} valign={'middle'} height={50} colSpan={3}>
                                        <span style={{paddingLeft: 20, fontSize: 16}}>이미 퇴직했습니다.</span>
                                    </td>
                                </tr>
                            </>
                        }
                        </>
                    }
                        <tr>
                            <td className='p-1 border' style={{fontSize: 16}} align={'center'} valign={'middle'} height={50} colSpan={2}>용도</td>
                            <td className='p-1 border' align={'left'} valign={'middle'} height={50} colSpan={3}>
                                <span style={{paddingLeft: 20, fontSize: 16}}>{props.issuancePurpose}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <div style={{width: '100%', marginTop: 100}}>
                    {type === 'B' && <p style={{fontSize: 22, textAlign: 'center', marginBottom: 70}}>위 와 같 이  재 직 을  증 명 합 니 다.</p>}
                    {type === 'C' && <p style={{fontSize: 22, textAlign: 'center', marginBottom: 70}}>위 와 같 이  경 력 을  증 명 합 니 다.</p>}
                    {type === 'D' && <p style={{fontSize: 22, textAlign: 'center', marginBottom: 70}}>위 와 같 이  퇴 직 을  증 명 합 니 다.</p>}
                </div>
                <div style={{position: 'absolute', bottom: 100, width: '100%'}}>
                    <p style={{fontSize: 24, textAlign: 'center', marginBottom: 70}}>{`${getYear()}년 ${getTodayMonth()}월 ${getTodayDay()}일`}</p>
                    <p style={{fontSize: 24, textAlign: 'center'}}>{userInfo.companyName}<span style={{fontSize: 24, marginLeft: 20}}>(인)</span></p>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(ReportCertificateTemplate);