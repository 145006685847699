import { observer } from "mobx-react-lite"
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import { CCol, CSaveBtn, CalendarGroup, Subject, CInputGroup } from "../../../components/CustomContainer";
import { useState, useRef } from "react";
import AgGridContainer from "../../../components/AgGridContainer";
import { UserTabsStore } from "../../../store/human-resources/UserTabsStore";
import { getUserName } from "../../../utils/userUtils";

const AttendanceDetails = ({userSeq}) => {

    const gridRef = useRef();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [remark, setRemark] = useState('');


    return (
        <>
            <Row>
                <CCol lg={12}>
                    <AgGridContainer
                        gridRef={gridRef}
                        gridTitle={'근태관리'}
                        height={59}
                        rowData={UserTabsStore.attendanceDetailsList}
                        columnDefs={[
                            { field: "imputedDate", headerName: "귀속연월", width: 110 },
                            {   
                                headerName: '시간',
                                marryChildren: true,
                                children: [
                                    {field: "normalHour", headerName: "정상", width: 102, flex: 1},
                                    {field: "extensionHour", headerName: "연장", width: 102, flex: 1},
                                    {field: "nightHour", headerName: "야간", width: 102, flex: 1},
                                    {field: "holidayHour", headerName: "휴일", width: 102, flex: 1},
                                ]
                            },
                            {
                                headerName: '일수',
                                children: [
                                    {field: "normalDay", headerName: "정상", width: 102, flex: 1},
                                    {field: "extensionDay", headerName: "연장", width: 102, flex: 1},
                                    {field: "nightDay", headerName: "야간", width: 102, flex: 1},
                                    {field: "holidayDay", headerName: "휴일", width: 102, flex: 1},
                                ]
                            },
                        ]}
                        useCsvDownload={true}
                    />
                </CCol>
            </Row>
        </>
    )

}

export default observer(AttendanceDetails)