import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Col, Tabs, Tab } from "react-bootstrap";
import { CContainer} from '../../components/CustomContainer';
import { UserStore } from "../../store/human-resources/UserStore";
import { callConfirm } from "../../utils";
import { showToast, encrypt } from "../../common/utils";
import LUtils from "../../utils/lodashUtils";
import { MULTIPART_HEADER } from "../../common/constants";
import { UserTabsStore } from "../../store/human-resources/UserTabsStore";
import UserInfo from "./tabs/UserInfo";
import AnnualLeaveCount from "./tabs/AnnualLeaveCount";
import AnnualLeaveHistory from "./tabs/AnnualLeaveHistory";
import SalaryDetails from "./tabs/SalaryDetails";
import PersonnelAppointmentDetails from "./tabs/PersonnelAppointmentDetails";
import FamilyEventsExpenses from "./tabs/FamilyEventsExpenses";
import WorkExperience from "./tabs/WorkExperienceDetails";
import AttendanceDetails from "./tabs/AttendanceDetails";
import IssueCertificate from "./tabs/IssueCertificate";

const UserDetail = ({getUserList, viewMode, setViewMode, selectUserDetail}) => {
    const [password, setPassword] = useState('');
    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [userSeq, setUserSeq] = useState('');
    const imgRef = useRef();

    useEffect(() => {
        if(UserStore.data.userSeq) {
            setPostcode(UserStore.data.postcode);
            setAddress(UserStore.data.address);
            setAddressDetail(UserStore.data.addressDetail);
            setUserSeq(UserStore.data.userSeq);
            setViewMode(true);
        } else {
            setPostcode("");
            setAddress("");
            setAddressDetail("");
            setUserSeq('');
            setPassword('');
            imgRef.current.value = "";
        }
    }, [UserStore.data.userSeq]);

    /* 파일 업로드 하고 url 가져오기 */
    const fileUploadForS3 = async (fileData) => {

        if (LUtils.isEmpty(fileData.target.files)) {
            UserStore.data.imageUrl = '';
            return false;
        }

        const file = fileData.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        const result = await axios.post('/file/upload/s3', formData, { headers: MULTIPART_HEADER });

        UserStore.data.imageUrl = result;
    }

    // 새로 등록 or 수정 후 저장
    const saveUser = async () => {

        // 필수값 확인
        const requiredParams = ['id', 'name', 'genderSeq', 'birthDate', 'phoneNumber', 'authorityGroupSeq'];
        if (!UserStore.data.userSeq) {
            requiredParams.push('password');
            UserStore.data.password = password;
        }
        if (!requiredParams.every(param => UserStore.data[param])) {
            showToast('필수값을 모두 입력해주세요.');
            return;
        }

        if (UserStore.data.isActive !== 1) {
            if (!UserStore.data.resignationDate) {
                showToast('퇴사일을 선택해주세요.');
                return;
            }
        }

        if(!UserStore.data.authorityGroupSeq){
            showToast('권한 그룹을 선택해주세요.');
            return;
        }

        // 주소 세팅
        UserStore.data.postcode = postcode;
        UserStore.data.address = address;
        UserStore.data.addressDetail = addressDetail;
        UserStore.data.password = password ? encrypt(password) : '';

        if (!UserStore.data.userSeq) {
            if (!await callConfirm('등록하시겠습니까?')) return;
            const getUserSeq = await axios.post('user/join', {...UserStore.data, 
                                                              isReleaseAskManager: UserStore.data.isReleaseAskManager === true ? 1 : 0,
                                                              isAsMaintenanceManager: UserStore.data.isAsMaintenanceManager === true ? 1 : 0,
                                                              isShipManager: UserStore.data.isShipManager === true ? 1 : 0
                                                            });
            UserStore.data.userSeq = getUserSeq;
            // setUserSeq(getUserSeq);

            showToast("등록이 완료되었습니다.");
            // setPassword('');
        } else {
            if(!await callConfirm('수정하시겠습니까?')) return;
            if(!password) {
                UserStore.data.password = '';
            }
            await axios.put('user', {...UserStore.data, 
                                        isReleaseAskManager: UserStore.data.isReleaseAskManager === true ? 1 : 0,
                                        isAsMaintenanceManager: UserStore.data.isAsMaintenanceManager === true ? 1 : 0,
                                        isShipManager: UserStore.data.isShipManager === true ? 1 : 0
                                    });
            showToast("수정이 완료되었습니다.");
            // setPassword('');
        }
        getUserList();
        selectUserDetail();
    }

    return (
        <Col>
            <CContainer>
            {viewMode && (
                    <Tabs
                        defaultActiveKey="userInfo"
                        className="mb-3"
                        mountOnEnter={true}
                        unmountOnExit={true}
                        activeKey={UserTabsStore.userTab}
                        onSelect={k => UserTabsStore.setUserTab(k)}
                    >
                        <Tab eventKey="userInfo" title="기본정보">
                            <UserInfo
                                fileUploadForS3={fileUploadForS3}
                                saveUser={saveUser}
                                password={password}
                                setPassword={setPassword}
                                postcode={postcode}
                                setPostcode={setPostcode}
                                address={address}
                                setAddress={setAddress}
                                addressDetail={addressDetail}
                                setAddressDetail={setAddressDetail}
                                imgRef={imgRef}
                            />
                        </Tab>
                        <Tab eventKey="annualLeaveCount" title="지급휴가수량">
                            <AnnualLeaveCount userSeq={userSeq} selectUserDetail={selectUserDetail}/>
                        </Tab>
                        <Tab eventKey="annualLeaveHistory" title="휴가장부">
                            <AnnualLeaveHistory userSeq={userSeq} selectUserDetail={selectUserDetail}/>
                        </Tab>
                        <Tab eventKey="salary" title="급여">
                            <SalaryDetails userSeq={userSeq} selectUserDetail={selectUserDetail}/>
                        </Tab>
                        <Tab eventKey="personnelAppointment" title="인사발령">
                            <PersonnelAppointmentDetails userSeq={userSeq} selectUserDetail={selectUserDetail}/>
                        </Tab>
                        <Tab eventKey="familyEvents" title="경조사">
                            <FamilyEventsExpenses userSeq={userSeq} selectUserDetail={selectUserDetail}/>
                        </Tab>
                        <Tab eventKey="workExperience" title="경력사항">
                            <WorkExperience userSeq={userSeq} selectUserDetail={selectUserDetail}/>
                        </Tab>
                        <Tab eventKey="attendance" title="근태관리">
                            <AttendanceDetails userSeq={userSeq} selectUserDetail={selectUserDetail}/>
                        </Tab>
                        <Tab eventKey="certificate" title="증명서 발급">
                            <IssueCertificate userSeq={userSeq} selectUserDetail={selectUserDetail}/>
                        </Tab>
                    </Tabs>
                )}
                {!viewMode && (
                <Tabs
                    defaultActiveKey="userInfo"
                    className="mb-3"
                    mountOnEnter={true}
                    unmountOnExit={true}
                    activeKey={UserTabsStore.userTab}
                    onSelect={k => UserTabsStore.setUserTab(k)}
                >
                    <Tab eventKey="userInfo" title="기본정보">
                        <UserInfo
                            fileUploadForS3={fileUploadForS3}
                            saveUser={saveUser}
                            password={password}
                            setPassword={setPassword}
                            postcode={postcode}
                            setPostcode={setPostcode}
                            address={address}
                            setAddress={setAddress}
                            addressDetail={addressDetail}
                            setAddressDetail={setAddressDetail}
                            imgRef={imgRef}
                        />
                    </Tab>
                </Tabs>
                )}
            </CContainer>
        </Col>
    )
}

export default observer(UserDetail);
