import {Container, Form, Row, InputGroup, Col, Button, ToggleButtonGroup, ToggleButton, DropdownButton, Dropdown} from "react-bootstrap";
import Datepicker from "react-tailwindcss-datepicker";
import LUtils from '../utils/lodashUtils'
import {addDayDate, oneMonthAgo, today} from "../utils/dateUtils";
import {forwardRef} from "react";
import {autoHyphen} from "../common/utils";

const CContainer = ({children, style, search, paddingTop}) => {
    // rgb(191 210 237 / 25%)
    let formStyle = LUtils.assign({marginTop: '20px !important', backgroundColor: 'rgb(191 210 237 / 15%)', borderRadius: 8, borderWidth: 1, borderColor: 'rgb(191 210 237 / 40%)'}, style);
    let formClass = 'm-2 p-4';
    if(!search){
        //formStyle = LUtils.assign({backgroundSize: '80% 300%', backgroundPosition: 'right', backgroundRepeat: 'no-repeat', backgroundImage: 'url(/image/search-bg.png)'}, formStyle);
        formStyle = LUtils.assign({paddingTop: 20}, style);
        formClass = paddingTop ? 'm-2 '+paddingTop : 'm-2 pt-4'
    }

    return (
        <Container fluid>
            <Form className={formClass} style={formStyle} >
                {children}
            </Form>
        </Container>
    );
};

const CContainerBox = ({children, style}) => {
    let formStyle = LUtils.assign({backgroundColor: '#ffffff', borderRadius: 5, boxShadow: '0 0 0.875rem 0 rgba(41,48,66,.05)'}, style);
    return (
        <Container fluid>
            <Form className='m-2 p-3' style={formStyle} >
                {children}
            </Form>
        </Container>
    );
};

const CContainerNonBox = ({children}) => {
    return (
        <Container>
            {children}
        </Container>
    );
};

const CAgGridContainer = ({children, height, formStyle, rowStyle}) => {
    const fStyle = LUtils.assign({backgroundColor: '#ffffff', borderRadius: 8, boxShadow: '0 7px 14px 0 rgba(65,69,88,0.1),0 3px 6px 0 rgba(0,0,0,0.07)'}, formStyle);
    const rStyle = LUtils.assign({height: height+'vh'}, rowStyle);
    return (
        <Container fluid>
            <Form className='m-2 p-3' style={fStyle}>
                <Row className='ag-theme-alpine' style={rStyle}>
                    {children}
                </Row>
            </Form>
        </Container>
    );
};


const CInputGroup = forwardRef(({...props}, ref) => {
    let {label, labelId, placeholder, value, onChange=null, disabled = false, className = '', style, size='sm', readOnly,
        onCallbackBtn=false, onSaveCallbackBtn=false, buttonLabel=false, isValid=false, enterKeyHint=null, btnDisabled = false, require,
        selectOptions, onSelect, selectValue, onEmptyBtn, type='input', labelClassName='', backLabel='', maxLength, isValidCheck=false, as,
        isTel=false, align=''} = props;

    onChange = onChange === null ? ()=>{} : onChange;
    const keyDown = e => {
        if (e.key === 'Enter') {
            if(e.nativeEvent.isComposing){
                return;
            }
            e.preventDefault();
            if(enterKeyHint){
                enterKeyHint();
            }
        }
    }

    return (
        <InputGroup className={className} style={style} size={size}>
            {selectOptions ?
                <Form.Select aria-label="선택" onChange={onSelect} value={selectValue}>
                    {selectOptions.map((option, idx)=> {
                        return(
                            <option key={idx} value={option.value}>{option.name}</option>
                        );
                    })}
                </Form.Select>
                :
                label && <InputGroup.Text className={labelClassName} id={labelId} style={{color: isValid ? '#DF013A' : '#212529', backgroundColor: 'rgb(191 210 237 / 40%)', minWidth: 60, display: 'flex', justifyContent: 'center'}}>{label || ''}</InputGroup.Text>
            }

            <Form.Control ref={ref} style={{borderRightWidth: onEmptyBtn ? 0 : 1, textAlign: align}} placeholder={placeholder || ''} aria-describedby={labelId} value={value === 0 ? 0 : isTel ? autoHyphen(value) : value || ''}
                          onChange={onChange} disabled={disabled} readOnly={readOnly} isValid={isValidCheck} onKeyDown={keyDown} type={type} maxLength={maxLength} as={as}

            />
            {onEmptyBtn &&
                <button className="btn btn-outline-secondary" style={{borderLeftWidth: 0, borderRightWidth: 0, borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} type="button" onClick={onEmptyBtn}>
                    <i className="fi fi-rr-cross-small" />
                </button>
            }
            {onCallbackBtn &&
                <Button variant="outline-secondary" style={{borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={onCallbackBtn} disabled={btnDisabled}>
                    {buttonLabel ? buttonLabel : <i className="me-1 fi fi-br-search" />}
                </Button>
            }
            {onSaveCallbackBtn &&
                <Button variant="outline-secondary" style={{borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={onSaveCallbackBtn}>
                    {buttonLabel ? buttonLabel : <i className="me-1 fi fi-br-save" />}
                </Button>
            }
            {backLabel &&
                <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>{backLabel || ''}</InputGroup.Text>
            }
        </InputGroup>
    );
});

const CLabelInputGroup = forwardRef(({...props}, ref) => {
    let {label, labelId, placeholder, value, onChange=null, disabled = false, style, size='sm', readOnly, isValid,
        enterKeyHint=null, btnDisabled = false, backLabel='', maxLength, isValidCheck=false, align} = props;

    onChange = onChange === null ? ()=>{} : onChange;
    const keyDown = e => {
        if (e.key === 'Enter') {
            if(e.nativeEvent.isComposing){
                return;
            }
            e.preventDefault();
            if(enterKeyHint){
                enterKeyHint();
            }
        }
    }

    return (
        <>
            <Form.Label style={{color: isValid ? '#DF013A' : '#212529', minWidth: 60, display: 'flex', justifyContent: 'flex-start'}}>{label}</Form.Label>
            <InputGroup size={'sm'}>
                <Form.Control
                    style={{flex: 1, textAlign: align}}
                    max={maxLength}
                    aria-describedby={labelId}
                    value={value || ''}
                    onChange={onChange}
                    disabled={disabled}
                    onKeyDown={keyDown}
                    placeholder={placeholder}
                    readOnly={readOnly}
                />
                {backLabel &&
                    <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>{backLabel}</InputGroup.Text>
                }
            </InputGroup>
        </>
    );
});

const CInputDoubleGroup = (
    {label, labelId, labelId2, placeholder, placeholder2, value, value2, onChange=null, onChange2=null,
        disabled = false, disabled2 = false, className = '', style, size='sm', secondFlex = 1,
        onCallbackBtn, enterKeyHint1=null, enterKeyHint2=null, btnDisabled=false, isValid=false, onEmptyAction, labelClassName, isValidCheck=false}) => {

    const keyDown1 = e => {
        if (e.key === 'Enter') {
            enterKeyHint1 && enterKeyHint1()
        }
    }
    const keyDown2 = e => {
        if (e.key === 'Enter') {
            enterKeyHint2 && enterKeyHint2()
        }
    }
    return (
        <InputGroup className={className} style={style} size={size}>
            <InputGroup.Text className={labelClassName} id={labelId} style={{color: isValid ? '#DF013A' : '##212529', backgroundColor: 'rgb(191 210 237 / 40%)', minWidth: 60, display: 'flex', justifyContent: 'center'}}>{label || ''}</InputGroup.Text>
            <Form.Control style={{flex: 2}} placeholder={placeholder2 || ''} aria-describedby={labelId2} value={value2 || ''} isValid={isValidCheck} onChange={onChange2} disabled={disabled2} onKeyDown={keyDown2}/>
            <Form.Control style={{flex: secondFlex}} placeholder={placeholder || ''} aria-describedby={labelId} value={value || ''} onChange={onChange} disabled={disabled} onKeyDown={keyDown1}/>
            {onEmptyAction &&
                <button className="btn btn-outline-secondary" style={{borderLeftWidth: 0, borderRightWidth: 0, borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}}
                        type="button" onClick={onEmptyAction}>
                    <i className="fi fi-rr-cross-small" />
                </button>
            }
            {onCallbackBtn &&
                <Button variant="outline-secondary" style={{borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={onCallbackBtn} disabled={btnDisabled}>
                    <i className="me-1 fi fi-br-search" />
                </Button>
            }
        </InputGroup>
    );
};

const CSelectGroup = ({label, labelId, value, options, onChange, disabled = false, className = '', style, size='sm',labelClassName='', isValid=false, useLabel=true}) => {
    const colorStyle = isValid ? {color: '#DF013A'} : {color: '#212529'};

    return (
        <InputGroup className={className} style={style} size={size}>
            {useLabel && 
                <InputGroup.Text id={labelId} className={labelClassName} style={{color: isValid ? '#DF013A' : '##212529', backgroundColor: 'rgb(191 210 237 / 40%)', minWidth: 60, display: 'flex', justifyContent: 'center'}}>{label || ''}</InputGroup.Text>
            }
            <Form.Select aria-label="선택" onChange={onChange} disabled={disabled} value={value || ''}>
                {options.map((option, idx)=> {
                    return(
                        <option key={idx} value={option.value}>{option.name}</option>
                    );
                })}
            </Form.Select>
        </InputGroup>
    );
};

const CCol = ({children, className = '', style, sm=12, md=6, lg=2, mb='mb-3 '}) => {
    className = mb + className;
    return (
        <Col className={className} sm={sm} md={md} lg={lg} style={style}>
            {children}
        </Col>
    );
};

const CColBtn = ({children, className = '', style, sm=12, md=6, lg=2}) => {
    className = className +  'mb-3';
    return (
        <Col className={className} sm={sm} md={md} lg={1} style={style}>
            {children}
        </Col>
    );
};

const CSaveBtn = ({style, onClick, disabled, className, title=null, noIcon=false}) => {
    const btnStyle = LUtils.assign({minWidth: !noIcon ? '70px' : 60, height: 33, width: 100, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: 6}, style);
    const btnClassName = className || 'ms-2 me-2' ;
    return (
        <Button size="sm" className={btnClassName} variant="success" onClick={onClick} style={btnStyle} disabled={disabled}>
            {!noIcon && <i className="me-1 fi fi-rs-disk" />}
            {title || '저장'}
        </Button>
    );
};

const CExcelBtn = ({style, onClick, disabled, className, title=null, noIcon=false}) => {
    const btnStyle = LUtils.assign({minWidth: 120, width: 120, height:33, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: 6}, style);
    const btnClassName = className || 'ms-2 me-2' ;
    return (
        <Button size="sm" className={btnClassName} variant="success" onClick={onClick} style={btnStyle} disabled={disabled}>
            {!noIcon && <i className="me-1 fi fi-bs-file-spreadsheet" />}
            {title || '데이터내리기'}
        </Button>
    );
};

const CSearchBtn = ({style, onClick, disabled, className, title=null, width=null}) => {
    const btnStyle = LUtils.assign({minWidth: 80,  width: width ? width : 100, height: 33, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: 6}, style);
    const btnClassName = className || 'ms-2 me-2' ;
    return (
        <>
        <Button size="sm" className={btnClassName} variant="primary" onClick={onClick} style={btnStyle} disabled={disabled}>
            <i className="me-1 fi fi-br-search" />
            {title || '조회'}
        </Button>
        </>
    );
};

const CUpdateBtn = ({style, onClick, disabled, className, title=null}) => {
    const btnStyle = LUtils.assign({minWidth: '70px',  width: 100, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: 6}, style);
    const btnClassName = className || 'ms-2 me-2' ;
    return (
        <>
        <Button size="sm" className={btnClassName} variant="warning" style={btnStyle} onClick={onClick} disabled={disabled}>
            <i className="me-1 fi fi-br-pencil" />
            {title || '수정'}
        </Button>
        </>
    );
};

const CCancelBtn = ({style, onClick, disabled, className, title=null, noIcon=false, size}) => {
    const btnStyle = LUtils.assign({minWidth: !noIcon ? '70px' : 60, height: 33, width: 100, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: 6}, style);
    const btnClassName = className || 'ms-2 me-2' ;
    return (
        <>
        <Button size={size || "sm"} className={btnClassName} variant="dark" onClick={onClick} style={btnStyle} disabled={disabled}>
            {!noIcon && <i className="me-1 fi fi-br-cross-circle" />}
            {title || '취소'}
        </Button>
        </>
    );
};

const CRemoveBtn = ({style, onClick, disabled, className, title=null}) => {
    const btnStyle = LUtils.assign({minWidth: '70px', height: 33,  width: 100, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: 6}, style);
    const btnClassName = className || 'ms-2 me-2' ;
    return (
        <>
        <Button size="sm" className={btnClassName} variant="danger" onClick={onClick} style={btnStyle} disabled={disabled}>
            <i className="me-1 fi fi-br-cross-circle" />
            {title || '삭제'}
        </Button>
        </>
    );
};

const CAddBtn = ({style, onClick, disabled, className, title=null, noIcon=false}) => {
    const btnStyle = LUtils.assign({minWidth: !noIcon ? '70px': 60, height: 33,  width: 100, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: 6}, style);
    const btnClassName = className || 'ms-2 me-2' ;
    return (
        <>
        <Button size="sm" className={btnClassName} variant="outline-dark" onClick={onClick} style={btnStyle} disabled={disabled}>
            {!noIcon && <i className="me-1 fi fi-rr-add" style={{marginTop: 3}} />}
            {title || '추가'}
        </Button>
        </>
    );
};

const IconBtn = ({onClick, style, iconClass, className, title}) => {
    const cName = `mt-2 ${className}`;
    const cStyle = LUtils.assign({cursor:'pointer', width: 110, marginBottom: 5}, style);
    return (
        <div style={cStyle} className={cName} onClick={onClick}>
            <i className={`fi ${iconClass || 'fi-br-search'}`} />{title}
        </div>
    );
}

const CIconBtn = ({style, onClick, disabled, className, title=null, icon, variant, color, isKeyEvent=false}) => {
    const btnStyle = LUtils.assign({height: 34, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', backgroundColor: color, borderRadius: 6}, style);
    const btnClassName = className || 'ms-2 me-2' ;
    const iconClass = 'me-1 fi '+icon;
    const handleKeyDown = (e, isKeyEvent)  => {
        if (isKeyEvent && (e.key === 'Enter' || e.key === ' ')) {   // 엔터키 & 스페이스 이벤트를 막음
            e.preventDefault();
        }
    }
    return (
        <Button size={'sm'} className={btnClassName} variant={variant || "secondary"} onClick={onClick} style={btnStyle} disabled={disabled} onKeyDown={e => handleKeyDown(e, isKeyEvent)}>
            <i style={{color: variant ? '#fff' : '#fff'}} className={iconClass} />
            <span>{title}</span>
            {/*<span style={{fontWeight: '600', color: '#fff'}}></span>*/}
        </Button>
    );
};

const CDropBtn = ({style, onClick, disabled, className, title=null, icon, variant, color, isKeyEvent=false, options=[]})=> {
    const btnStyle = LUtils.assign({minWidth: 120, height: 30, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', backgroundColor: color, marginTop: 2, right: -17}, style);
    const btnClassName = className || 'ms-2 me-2' ;
    const iconClass = 'me-1 fi '+icon;
    const handleKeyDown = (e, isKeyEvent)  => {
        if (isKeyEvent && (e.key === 'Enter' || e.key === ' ')) {   // 엔터키 & 스페이스 이벤트를 막음
            e.preventDefault();
        }
    }
    return (
        <DropdownButton className={btnClassName}  style={btnStyle} title={title} id="bg-nested-dropdown" >
            {options.length > 0 && options.map((v,i) => {
                return(
                    <Dropdown.Item key={i} onClick={()=> onClick(v.value)}>{v.name}</Dropdown.Item>
                )
            })}
        </DropdownButton>
    )
}

/**
 * @param {boolean} useTotal: '전체' 버튼 사용 유무
 * @param {Array} btnText: true/false 버튼에 사용할 이름. 배열로 첫번째값이 true, 두번째 값이 false
 */
const RadioButtonGroupTrueFalse = ({size='sm', label='', onClick, value='', name='options', useTotal=true, btnText=['가능', '불가능'], isActive=true}) => {
    let btnValue = useTotal ? value : value !== '' ? value : true;

    const clickBtn = v => {
        btnValue = v;
        onClick(v);
    }
    return (
        <InputGroup size={size}>
            <InputGroup.Text >{label}</InputGroup.Text>
            <ToggleButtonGroup size={size} type="radio" name={name} defaultValue={btnValue} className={'rounded-button-group'}>
                {useTotal &&
                    <ToggleButton id={`${name}-default`} onClick={v=>clickBtn('')} variant="outline-primary" value={''} className={'inner-button'}>전체</ToggleButton>
                }
                <ToggleButton id={`${name}-true`} onClick={v=>clickBtn(true)} variant="outline-success" value={isActive} className={'inner-button'}>{btnText[0]}</ToggleButton>
                <ToggleButton id={`${name}-false`} onClick={v=>clickBtn(false)} variant="outline-danger" value={!isActive}>{btnText[1]}</ToggleButton>
            </ToggleButtonGroup>
        </InputGroup>
    )
}

/**
 * @param {Array} options: [{value: 버튼클릭시 반환 받을 값, btnClass: 버튼 색, text: 버튼 이름}] , 첫번째 row에 value가 defaultValue다.
 */
const RadioButtonGroup = ({size='sm', label='', onClick, options=[], value=''}) => {
    let defaultValue = LUtils.isEmpty(value) ? LUtils.get(options.find((v,i) => i===0), 'value', '') : value;
    const clickBtn = v => {
        defaultValue = v;
        onClick(v);
    }
    return (
        <InputGroup size={size}>
            <InputGroup.Text >{label}</InputGroup.Text>
            <ToggleButtonGroup size={size} type="radio" name="options1" defaultValue={defaultValue} className={'rounded-button-group'}>
                {options.map((v, i) => {
                    const roundedClass = i === 0 ? 'inner-button' : '';
                    return  (
                        <ToggleButton id={`btn-id-${i}`} key={`btn-group-${i}`} onClick={()=>clickBtn(v.value)} variant={`outline-${v.btnClass || 'secondary'}`} value={v.value} className={roundedClass}>{v.text}</ToggleButton>
                    )
                }
                )}
            </ToggleButtonGroup>
        </InputGroup>
    )
}

const Calendar = ({value, onChange, asSingle=false, sm=12, md=12, lg=4, disabled=false, readOnly=false}) => {
    return (
        <Col lg={lg} md={md} sm={sm} className={'d-flex'}>
            <div className='mt-2 mb-2 ms-2 me-2'>
                <i className={`fi fi-rr-calendar-day`} />
            </div>
            <Datepicker
                useRange={false}
                asSingle={asSingle}
                inputClassName='h-8 border'
                primaryColor={"blue"}
                value={value}
                onChange={onChange}
                disabled={disabled}
                readOnly={readOnly}
            />
        </Col>
    );
}

const CalendarWithLabel = ({value, onChange, asSingle=false, label, sm=12, md=12, disabled=false, readOnly=false,
                               minDate, maxDate, isValid}) => {

    const handleValue =(value)=> {
        const returnValue = value;
        if(returnValue){
            if(!returnValue.endDate){
                returnValue.endDate = value.startDate;
            }
            return returnValue;
        }else{
            return '';
        }
    }

    return (
        <Col md={md} sm={sm}>
            <Form.Label style={{color: isValid ? '#DF013A' : '##212529', minWidth: 60, display: 'flex', justifyContent: 'flex-start'}}>{label}</Form.Label>
            <Datepicker
                i18n={'ko'}
                useRange={false}
                asSingle={asSingle}
                inputClassName='h-8 border rounded-calendar'
                primaryColor={"blue"}
                value={handleValue(value)}
                onChange={onChange}
                disabled={disabled}
                readOnly={readOnly}
                minDate={minDate}
                maxDate={maxDate}
                containerClassName={'container_class_name'}
            />
        </Col>
    );
}

const CalendarGroup = ({value, onChange, asSingle=false, sm=12, md=12, lg=4, size='sm',
                           label='', labelId='', disabled=false, labelClassName='', readOnly=false, minDate, maxDate,
                           isShortcut, isValid=false}) => {

    const shortCuts ={
        oneWeek: {text: '1주일', period: {start: addDayDate(today(), -7), end: today() }},
        oneMonth: {text: '1달', period: {start: oneMonthAgo(), end: today()}},
        threeMonth: {text: '3달', period: {start: addDayDate(today(), -90), end: today()}},
        oneYear: {text: '1년', period: {start: addDayDate(today(), -365), end: today()}}
    }

    const handleValue =(value)=> {
        const returnValue = value;
        if(returnValue){
            if(!returnValue.endDate){
                returnValue.endDate = value.startDate;
            }
            return returnValue;
        }else{
            return '';
        }
    }

    return (
        <InputGroup size={size} lg={lg} md={md} sm={sm}>
            <InputGroup.Text id={labelId} className={labelClassName} style={{color: isValid ? '#DF013A' : '##212529', backgroundColor: 'rgb(191 210 237 / 40%)', minWidth: 60, display: 'flex', justifyContent: 'center'}}>{label}</InputGroup.Text>
            <Col>
                <Datepicker
                    i18n={'ko'}
                    useRange={asSingle ? false : true}
                    asSingle={asSingle}
                    inputClassName='h-8 border rounded-calendar'
                    primaryColor={"blue"}
                    value={handleValue(value)}
                    onChange={onChange}
                    disabled={disabled}
                    readOnly={readOnly}
                    minDate={minDate}
                    maxDate={maxDate}
                    showShortcuts={isShortcut}
                    configs={{
                        shortcuts: shortCuts
                    }}
                />
            </Col>
        </InputGroup>
    );
}

const Subject = ({style, children, isBorder=true})=> {
    return (
        <>
            <Form.Label style={{fontSize: 16, marginTop: 14, color: '#5f6975', ...style}}>{children}</Form.Label>
            <div style={{width: '100%', height: 1, borderBottomWidth: !isBorder ? 0 : 1, borderBottomColor: '#efefef', marginTop: -5, marginBottom: 10, marginLeft: 10, marginRight: 10}}/>
        </>
    );
}

const CheckInlineGroup =({buttons, name})=> {
    buttons.map((v,i) => {
        return (
            <Form.Check
                inline
                key={i}
                label={v.label}
                name={name}
                type={'radio'}
                value={v.value}
                checked={v.checked}
                onChange={v.onChange}
            />
        )
    });
}

export {
    CContainer, CContainerBox, CContainerNonBox,
    CAgGridContainer,
    CInputGroup, CInputDoubleGroup,
    CSelectGroup,
    CCol, CColBtn,
    CSaveBtn, CExcelBtn, CSearchBtn, CUpdateBtn, CCancelBtn, CRemoveBtn, CAddBtn, CIconBtn, IconBtn, RadioButtonGroup, RadioButtonGroupTrueFalse,
    Calendar, CalendarWithLabel, CalendarGroup,
    Subject, CheckInlineGroup, CLabelInputGroup, CDropBtn
}
