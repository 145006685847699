import {observer} from 'mobx-react-lite';
import {CCol, CContainerBox, CIconBtn, CInputGroup, CalendarGroup, IconBtn} from "../components/CustomContainer";
import { Container, Pagination, Row, Image, Button, Overlay, Stack } from "react-bootstrap";
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { AppStore } from '../store/AppStore';
import Modal from '../components/Modal';
import BoardDetail from '../views/board/BoardDetail';
import { useState, useEffect, useRef } from 'react';
import { BoardStore } from '../store/board/BoardStore';
import axios from 'axios';
import {showToast} from "../common/utils";
import {DashboardStore} from "../store/dashboard/DashboardStore";
import {getSessionUserSeq, getUserName} from "../utils/userUtils";
import {getLocalStorage} from "../utils";
import {USER_INFO} from "../common/constants";
import {getMonth, getYear, today} from "../utils/dateUtils";
import { useNavigate } from 'react-router-dom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Dashboard = () => {
    const store = BoardStore;
    const navigate = useNavigate();
    const popoverTarget = useRef(null);
    const blackPopoverTarget = useRef(null);

    const userInfo = getLocalStorage(USER_INFO);

    const [noticeTitle, setNoticeTitle] = useState('');
    const [boardSeq, setBoardSeq] = useState('');
    const [boardType, setBoardType] = useState('');
    const [noticeCurrentPage, setNoticeCurrentPage] = useState(1);
    const [noticeOrgTotalPage, setNoticeOrgTotalPage] = useState(0);
    const [noticeTotalPage, setNoticeTotalPage] = useState(0);
    const [blackCurrentPage, setBlackCurrentPage] = useState(1);
    const [blackOrgTotalPage, setBlackOrgTotalPage] = useState(0);
    const [blackTotalPage, setBlackTotalPage] = useState(0);
    const [userName, setUserName] = useState('');
    const [showNoticePopover, setShowNoticePopover] = useState(false);
    const [showBlackPopover, setShowBlackPopover] = useState(false);
    const [noticeSubject, setNoticeSubject] = useState('');
    const [noticeCreator, setNoticeCreator] = useState('');
    const [noticeContents, setNoticeContents] = useState('');
    const [noticeDate, setNoticeDate] = useState({startDate: '', endDate: ''});
    const [blackSubject, setBlackSubject] = useState('');
    const [blackCreator, setBlackCreator] = useState('');
    const [blackContents, setBlackContents] = useState('');
    const [blackDate, setBlackDate] = useState({startDate: '', endDate: ''});
    


    /* dashboard 페이지 들어왔을 때 공지사항 리스트 읽어오기 */
    useEffect(()=> {
        (async () => {
            await getCompanyDashboardData();
            await getBoardList();
            setUserName(getUserName(getSessionUserSeq()));
        })();
    }, []);

    useEffect(() => {
        getBoardList(boardType);
    }, [noticeCurrentPage, blackCurrentPage])

    const getCompanyDashboardData =async()=> {
        const {contract, release, site, monthOperatingRate, nonReceiveAmount, assetStatus, notReceiveSiteList, asStatus, repairStatus, billCount, smsCount, accountCount, cardCount, assetDetailStatus, deliveryCount } = await axios.get(`/dashboard/all`);

        DashboardStore.setContract(contract);
        DashboardStore.setRelease(release);
        //현장
        DashboardStore.setSite(site);
        //월별 가동율
        const rateList = [];
        for(const value of Object.entries(monthOperatingRate)){
            rateList.push(value[1]);
        }
        DashboardStore.setMonthOperatingRate(rateList);
        //당월 가동율
        let nowMonth = getMonth(today());
        DashboardStore.setNowOperatingRate(rateList.length > 0 ? rateList[Number(nowMonth) - 1] : 0);
        //당월 미납금액
        //DashboardStore.setNonReceiveAmount(nonReceiveAmount);
        //장비현황
        DashboardStore.setAssetStatus(assetStatus);
        //미납 현장 리스트
        //DashboardStore.setNotReceiveSiteList(notReceiveSiteList);
        //AS현황
        DashboardStore.setAsStatus(asStatus);
        //정비현황
        DashboardStore.setRepairStatus(repairStatus);
        //계산서발행현황
        DashboardStore.setBillCount(billCount);
        //SMS발송현황
        DashboardStore.setSmsCount(smsCount);
        //등록계좌
        DashboardStore.setAccountCount(accountCount);
        //등록카드
        DashboardStore.setCardCount(cardCount);
        //배송건수
        DashboardStore.setDeliveryCount(deliveryCount)
        //장비상세현황
        DashboardStore.setAssetDetailStatus(assetDetailStatus);
    }

    /* 공지사항 모달 닫을 때 공지사항 초기화 */
    const closedModal = async () => {
        store.boardInit();
        setBoardSeq(store.data.boardSeq);
        await getBoardList();
    };

    const getBoardList = async(boardType = '') => {
        
        if (boardType === null || boardType == '') {
            Promise.all([await axios.get(`/board/list?pageNo=${noticeCurrentPage}&boardType=1`), await axios.get(`/board/list?pageNo=${blackCurrentPage}&boardType=2`)])
                .then(r => {
                    const [notice, black] = r;
                    BoardStore.setBoardList(notice.list);
                    BoardStore.setBlackList(black.list);
                    BoardStore.setOrgBoardList(notice.list);
                    BoardStore.setOrgBlackList(black.list);
                    setNoticeTotalPage(notice.totalPage);
                    setNoticeOrgTotalPage(notice.totalPage);
                    setBlackTotalPage(black.totalPage);
                    setBlackOrgTotalPage(black.totalPage);
                });
        } else {
            const params = {
                subject: boardType === '1' ? noticeSubject : blackSubject,
                creator: boardType === '1' ? noticeCreator : blackCreator,
                contents: boardType === '1' ? noticeContents : blackContents,
                startDate: boardType === '1' ? noticeDate.startDate : blackDate.startDate,
                endDate: boardType === '1' ? noticeDate.endDate : blackDate.endDate,
            }
            
            if (boardType === '1') {
                const result = await axios.get(`/board/list?pageNo=${noticeCurrentPage}&boardType=${boardType}`, {params: params});
                const isSearch = (noticeSubject || noticeCreator|| noticeContents || noticeDate.startDate || noticeDate.endDate) ? true : false; 
                BoardStore.setBoardList(result.list);
                setNoticeTotalPage(result.totalPage);
                isSearch && setNoticeCurrentPage(1);
            } else {
                const result = await axios.get(`/board/list?pageNo=${blackCurrentPage}&boardType=${boardType}`, {params: params});
                const isSearch = (blackSubject || blackCreator || blackContents || blackDate.startDate || blackDate.endDate) ? true : false; 
                BoardStore.setBlackList(result.list);
                setBlackTotalPage(result.totalPage);
                isSearch && setBlackCurrentPage(1);
            }
        }
    }

    const resetBoardList = (boardType) => {
        if (boardType === '1') {
            BoardStore.setBoardList(BoardStore.orgBoardList); 
            setNoticeTotalPage(noticeOrgTotalPage); 
            setNoticeCurrentPage(1);

            setNoticeSubject('');
            setNoticeCreator('');
            setNoticeContents('');
            setNoticeDate({startDate: '', endDate: ''});
        } else {
            BoardStore.setBlackList(BoardStore.orgBlackList);
            setBlackTotalPage(blackOrgTotalPage);
            setBlackCurrentPage(1);

            setBlackSubject('');
            setBlackCreator('');
            setBlackContents('');
            setBlackDate({startDate: '', endDate: ''});
        }
    }

    const getBoardDetail = async(boardNo, type) => {
        setBoardSeq(boardNo);
        if(type === '1'){
            setNoticeTitle('공지사항');
        }else if(type === '2'){
            setNoticeTitle('공유게시판');
        }
        setBoardType(type);

        AppStore.toggleModal();
    }

    const callAlertFn = async(text) => {
        showToast(text);
    }

    const gridDataHandler =(type)=> {
        let percent = 0;

        if (type === 'contract') {
            if (DashboardStore.contract.preCount > 0) {
                percent = Math.floor((DashboardStore.contract.nowCount * 100) / DashboardStore.contract.preCount)
            } else {
                percent = DashboardStore.contract.nowCount > 0 ? DashboardStore.contract.nowCount + 100 : 0;
            }

        } else if (type === 'release') {
            if (DashboardStore.release.preCount > 0) {
                percent = Math.floor((DashboardStore.release.nowCount * 100) / DashboardStore.release.preCount);
            } else {
                percent = DashboardStore.release.nowCount > 0 ? DashboardStore.release.nowCount + 100 : 0;
            }
        } else if (type === 'site') {
            if (DashboardStore.site.preCount > 0) {
                percent = Math.floor((DashboardStore.site.nowCount * 100) / DashboardStore.site.preCount);
            } else {
                percent = DashboardStore.site.nowCount > 0 ? DashboardStore.site.nowCount + 100 : 0;
            }
        }
        return percent;
    }

    const goToPage =(url, name)=> {
        //히스토리 저장
        AppStore.pushOpenPageHistory({url: url, name: name});
        navigate(url, { state: {from: 'dashboard'}});
    }

    return (
    <>
        <Container style={{marginTop: 20, paddingLeft: 25, paddingRight: 25, width: '100%', maxWidth: 2000}}>
            <Row lg={3}>
                <CCol lg={3}>
                    <div className={'dash-status-box'} style={{paddingLeft: 10, backgroundColor: '#e0eafc', boxShadow: '0 0 0.875rem 0 rgb(41 48 66 / 10%)'}}>
                        <div className="card-body d-flex flex-fill">
                            <div className="row g-0">
                                <div className="col-12">
                                    <div className="illustration-text p-3 m-1">
                                        <h4 style={{color: '#3f80ea'}}>환영합니다.</h4>
                                        <h4 style={{color: '#366dc7'}}>{userName}님!</h4>
                                        <p className="mb-0" style={{color: '#424242'}}>{userInfo.companyName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CCol>
                <CCol lg={3}>
                    <div className="dash-status-box" onClick={()=> goToPage('/contract/list','계약조회')}>
                        <div className="card-body py-4">
                            <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                    <h2 className="mb-2" style={{color: '#086A87'}}>{DashboardStore.contract.nowCount}<span style={{fontSize: 14, marginLeft: 5}}>건</span></h2>
                                    <p className="mb-2" style={{fontSize: 16, color: '#495057'}}>당월 계약 건수</p>
                                    <div className="mb-0" style={{display: 'flex'}}>
                                        <span className="text-muted">전월 대비</span>
                                        <div style={{width: 60, backgroundColor: 'rgba(75,191,115,.175)', borderRadius: 5, marginLeft: 5, paddingLeft: 5, textAlign: 'center'}}>
                                            <span className="me-2" style={{color: '#4bbf73'}}> {gridDataHandler('contract')}% </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-inline-block ms-3">
                                    <div style={{background: '#0e2338', opacity: 0.8, borderRadius: '50%', height: 48, padding: '0.75rem', width: 48}}>
                                        <Image src={`${process.env.PUBLIC_URL}/image/dashboard/contract.png`} alt="logo" className="logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CCol>
                <CCol lg={3}>
                    <div className="dash-status-box" onClick={()=> goToPage('/release/list','출고조회')}>
                        <div className="card-body py-4">
                            <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                    <h2 className="mb-2" style={{color: '#086A87'}}>{DashboardStore.release.nowCount}<span style={{fontSize: 14, marginLeft: 5}}>대</span></h2>
                                    <p className="mb-2" style={{fontSize: 16, color: '#495057'}}>당월 출고 장비 수</p>
                                    <div className="mb-0" style={{display: 'flex'}}>
                                        <span className="text-muted">전월 대비</span>
                                        <div style={{width: 60, backgroundColor: 'rgba(217,83,79,.175)', borderRadius: 5, marginLeft: 5, paddingLeft: 5, textAlign: 'center'}}>
                                            <span className="me-2" style={{color: '#d9534f'}}> {gridDataHandler('release')}% </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-inline-block ms-3">
                                    <div style={{background: '#0e2338', opacity: 0.8, borderRadius: '50%', height: 48, padding: '0.75rem', width: 48}}>
                                        <Image src={`${process.env.PUBLIC_URL}/image/dashboard/release.png`} alt="logo" className="logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CCol>
                <CCol lg={3}>
                    <div className="dash-status-box" onClick={()=> goToPage('/customer/siteInfo','현장관리')}>
                        <div className="card-body py-4">
                            <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                    <h2 className="mb-2" style={{color: '#086A87'}}>{DashboardStore.site.nowCount}<span style={{fontSize: 14, marginLeft: 5}}>건</span></h2>
                                    <p className="mb-2" style={{fontSize: 16, color: '#495057'}}>당월 신규 현장</p>
                                    <div className="mb-0" style={{display: 'flex'}}>
                                        <span className="text-muted">전월 대비</span>
                                        <div style={{backgroundColor: 'rgba(217,83,79,.175)', borderRadius: 5, marginLeft: 5, paddingLeft: 5, textAlign: 'center'}}>
                                            <span className="me-2" style={{color: '#d9534f'}}> {gridDataHandler('site')}% </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-inline-block ms-3">
                                    <div style={{background: '#0e2338', opacity: 0.8, borderRadius: '50%', height: 48, padding: '0.75rem', width: 48}}>
                                        <Image src={`${process.env.PUBLIC_URL}/image/dashboard/site.png`} alt="logo" className="logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CCol>
            </Row>
            <Row>
                <CCol lg={6}>
                    <div style={{height: 268, display: 'flex', justifyContent: 'center', paddingLeft: 20, paddingRight: 20, backgroundColor: '#fff', borderRadius: 5,boxShadow: '0 0 0.875rem 0 rgb(41 48 66 / 10%)', flex: '1 1 auto!important'}}>
                        <Bar style={{width: '100%'}}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        position: 'top',
                                    },
                                    title: {
                                        display: true,
                                        text: `${getYear()}년 월별 장비 가동률`,
                                    },
                                },
                            }}
                            data={{
                                labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                                datasets: [
                                    {
                                        label: '가동률',
                                        data: DashboardStore.monthOperatingRate,
                                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                    },
                                ],
                            }} />
                    </div>
                </CCol>
                <CCol lg={6}>
                    <Row>
                        <CCol lg={6}>
                            <div className="dash-status-box" onClick={()=> goToPage('/asset/operateRate','장비별가동월')}>
                                <div className="card-body py-4">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h4 className="mb-2" style={{color: '#495057'}}>당월 가동율</h4>
                                            
                                            <div className='d-flex justify-content-between'>
                                                <i style={{color: '#75818E', fontSize: 24, paddingTop: 20}} className={'fi-rr-chart-histogram'} />
                                                <p style={{fontSize: 30, paddingTop: 10, color: '#086A87', textAlign: 'right'}}>{DashboardStore.nowOperatingRate || 0}%</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                        <CCol lg={6}>
                            {/*
                            <div className="dash-status-box" style={{boxShadow: '0 0 0.875rem 0 rgb(41 48 66 / 10%)'}}>
                                <div className="card-body py-4">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h4 className="mb-2" style={{color: '#495057'}}>미납금액</h4>
                                            <p style={{fontSize: 20, color: '#B45F04', textAlign: 'right', paddingTop: 20}}>{DashboardStore.nonReceiveAmount?.toLocaleString()}원</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */}
                            <div className="dash-status-box" onClick={()=> goToPage('/ship/schedule','배송스케쥴조회(캘린더)')}>
                                <div className="card-body py-4">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h4 className="mb-2" style={{color: '#495057'}}>금일 배송 건수</h4>
                                            <div className='d-flex justify-content-between'>
                                                <i style={{color: '#75818E', fontSize: 24, paddingTop: 20}} className={'fi-rs-truck-moving'} />
                                                <p style={{fontSize: 30, paddingTop: 10, color: '#B45F04', textAlign: 'right'}}>{DashboardStore.deliveryCount}건</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={6}>
                            <div className="dash-status-box" onClick={()=> goToPage('/asset/list','자산조회')}>
                                <div className="card-body py-4">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h4 className="mb-2" style={{color: '#495057'}}>총 장비 현황</h4>
                                            <div className='d-flex justify-content-between'>
                                                <i style={{color: '#75818E', fontSize: 24, paddingTop: 20}} className={'fi-rr-chart-pie-alt'} />
                                                <div>
                                                    <div className="d-flex justify-content-end">
                                                        <h6 className="text-muted" style={{paddingTop: 7, textAlign: 'right'}}>당사자산</h6>
                                                        <div style={{minWidth: 90, fontSize: 20, color: '#495057', textAlign: 'right'}}>{DashboardStore.assetStatus.ownAsset}대</div>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <h6 className="text-muted" style={{paddingTop: 7, textAlign: 'right'}}>임차자산</h6>
                                                        <div style={{minWidth: 90, fontSize: 20, color: '#495057', textAlign: 'right'}}>{DashboardStore.assetStatus.hireAsset}대</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                        <CCol lg={6}>
                            {/*
                            <div className="dash-status-box" style={{boxShadow: '0 0 0.875rem 0 rgb(41 48 66 / 10%)'}}>
                                <div className="card-body py-4">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h4 className="mb-2" style={{color: '#495057'}}>미납 상위 현장</h4>
                                                {DashboardStore.notReceiveSiteList.map((v,i) => {
                                                    return (
                                                        <div key={i} className="d-flex justify-content-end" style={{height: 22, marginTop: i === 0 ? -5 : 0}}>
                                                            <div className="text-muted" style={{width: '60%', paddingTop: 7, fontSize: 12, textAlign: 'left'}}>{i+1}.{v.title}</div>
                                                            <div style={{width: '40%', fontSize: 12, color: '#495057', paddingTop: 7, textAlign: 'right'}}>{v.count?.toLocaleString()}원</div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */}
                            <div className="dash-status-box" onClick={()=> goToPage('/product/stock','재고조회')}>
                                <div className="card-body py-4">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <h4 className="mb-2" style={{color: '#495057'}}>장비 상세 현황</h4>
                                            <div className="d-flex justify-content-around" style={{textAlign: 'center'}}>
                                                <div style={{width: '30%', paddingTop: 5}}>
                                                    <h6 className="text-muted">임대중</h6>
                                                    <h4>{DashboardStore.assetDetailStatus ? DashboardStore.assetDetailStatus.rent : 0}</h4>
                                                </div>
                                                <div style={{borderLeftWidth: 1, borderRightWidth: 1, width: '30%', paddingTop: 5}}>
                                                    <h6 className="text-muted">임대가능</h6>
                                                    <h4 style={{color: '#21610B'}}>{DashboardStore.assetDetailStatus ? DashboardStore.assetDetailStatus.wait : 0}</h4>
                                                </div>
                                                <div style={{width: '30%', paddingTop: 5}}>
                                                    <h6 className="text-muted">기타</h6>
                                                    <h4 style={{color: '#8A0808'}}>{DashboardStore.assetDetailStatus ? DashboardStore.assetDetailStatus.etc : 0}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    </Row>
                </CCol>
            </Row>
            <Row>
                <CCol lg={4}>
                    <Row>
                        <CCol lg={12}>
                            <div className="dash-status-box" onClick={()=> goToPage('/asset/as/list', 'AS관리')}>
                                <div className="card-body py-4">
                                    <div className="d-flex align-items-start" style={{marginBottom: 6, borderBottomWidth: 1}}>
                                        <div className="flex-grow-1" style={{textAlign: 'center'}}>
                                            <h5 className="mb-2" style={{color: '#495057'}}>금일 A/S 처리 현황</h5>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-around" style={{textAlign: 'center'}}>
                                        <div style={{width: '30%', paddingTop: 5}}>
                                            <h6 className="text-muted">총 접수 건</h6>
                                            <h4>{DashboardStore.asStatus.allCount}</h4>
                                        </div>
                                        <div style={{borderLeftWidth: 1, borderRightWidth: 1, width: '30%', paddingTop: 5}}>
                                            <h6 className="text-muted">처리중</h6>
                                            <h4 style={{color: '#21610B'}}>{DashboardStore.asStatus.acceptCount}</h4>
                                        </div>
                                        <div style={{width: '30%', paddingTop: 5}}>
                                            <h6 className="text-muted">완료</h6>
                                            <h4 style={{color: '#086A87'}}>{DashboardStore.asStatus.completeCount}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <div className="dash-status-box" onClick={()=> goToPage('/asset/maintenance/list', '정비관리')}>
                                <div className="card-body py-4">
                                    <div className="d-flex align-items-start" style={{marginBottom: 6, borderBottomWidth: 1}}>
                                        <div className="flex-grow-1" style={{textAlign: 'center'}}>
                                            <h5 className="mb-2" style={{color: '#495057'}}>금일 정비 현황</h5>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-around" style={{textAlign: 'center'}}>
                                        <div style={{width: '30%', paddingTop: 5}}>
                                            <h6 className="text-muted">총 정비 건</h6>
                                            <h4>{DashboardStore.repairStatus.allCount}</h4>
                                        </div>
                                        <div style={{borderLeftWidth: 1, borderRightWidth: 1, width: '30%', paddingTop: 5}}>
                                            <h6 className="text-muted">처리중</h6>
                                            <h4 style={{color: '#21610B'}}>{DashboardStore.repairStatus.acceptCount}</h4>
                                        </div>
                                        <div style={{width: '30%', paddingTop: 5}}>
                                            <h6 className="text-muted">완료</h6>
                                            <h4 style={{color: '#086A87'}}>{DashboardStore.repairStatus.completeCount}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <div className="dash-status-box-no-cursor">
                                <div className="card-body py-4">
                                    <div className="d-flex justify-content-around" style={{textAlign: 'center', paddingTop: 20}}>
                                        <div style={{width: '50%'}}>
                                            <h6 className="text-muted">당월 계산서 발행 건</h6>
                                            <h4><span style={{fontSize: 21, color: '#21610B'}}>{DashboardStore.billCount.nowCount}</span> / {DashboardStore.billCount.totalCount}</h4>
                                        </div>
                                        <div style={{borderLeftWidth: 1, width: '50%'}}>
                                            <h6 className="text-muted">알림톡(SMS) 발송 건</h6>
                                            <h4><span style={{fontSize: 21, color: '#21610B'}}>{DashboardStore.smsCount.nowCount}</span> / {DashboardStore.smsCount.totalCount}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <div className="dash-status-box-no-cursor">
                                <div className="card-body py-4">
                                    <div className="d-flex justify-content-around" style={{textAlign: 'center', paddingTop: 20}}>
                                        <div style={{width: '50%'}}>
                                            <h6 className="text-muted">등록된 법인 계좌 수</h6>
                                            <h4 style={{color: '#21610B'}}>{DashboardStore.accountCount}</h4>
                                        </div>
                                        <div style={{borderLeftWidth: 1, width: '50%'}}>
                                            <h6 className="text-muted">등록된 법인 카드 수</h6>
                                            <h4 style={{color: '#21610B'}}>{DashboardStore.cardCount}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    </Row>
                </CCol>
                <CCol lg={4}>
                    <div style={{padding: 20, backgroundColor: '#fff', borderRadius: 5, boxShadow: '0 0 0.875rem 0 rgb(41 48 66 / 10%)'}}>
                        <div className="position-relative" style={{height: 512}}>
                            <div style={{paddingLeft: 5}}>
                                <div className="border-bottom" style={{height:40, display: 'flex'}}>
                                    <i className="fi fi-rr-comment-alt" style={{fontSize: 15,marginTop: 5}}/>
                                    <label style={{color: '#495057', fontSize: 16, marginLeft: 10}}>공지사항</label>
                                </div>
                                { BoardStore.boardList.length > 0 ?
                                    BoardStore.boardList.map(board => (
                                        <a key={board.boardSeq} href='#'
                                           className='text-dark text-decoration-none'
                                           onClick={(e) => getBoardDetail(board.boardSeq, '1')}>
                                            <div className="d-flex text-body-secondary pt-3">
                                                <div style={{height: 38, width: 39, overflow: "hidden"}}>
                                                    {board.imageUrl ?
                                                        <Image src={board.imageUrl} style={{
                                                            height: 38,
                                                            objectFit: "cover",
                                                            borderRadius: "20px"
                                                        }} alt="프로필 이미지"/>
                                                        :
                                                        <i className="fi fi-rs-circle-user"
                                                           style={{fontSize: 34}}/>
                                                    }
                                                </div>
                                                <div className="pb-3 mb-0 small lh-sm border-bottom w-100 ml-3">
                                                    <div className="d-flex justify-content-between">
                                                        <strong
                                                            className="text-gray-dark">{board.subject}</strong>
                                                        <span className="d-block">{board.creatorName}</span>
                                                    </div>
                                                    <span style={{
                                                        fontSize: 10,
                                                        marginTop: 2,
                                                        color: '#A4A4A4'
                                                    }}>{board.createdDate}</span>
                                                </div>
                                            </div>
                                        </a>
                                    ))
                                :
                                    <div style={{textAlign: 'center', paddingTop: 200}}>
                                        <p>등록된 게시글이 없습니다.</p>
                                    </div>
                                }
                            </div>
                            <div>
                                <div className="position-absolute d-flex justify-content-center" style={{width: '100%', bottom: -20}}>
                                    <Pagination style={{zIndex: 1}}>
                                        <Pagination.First onClick={() => {setNoticeCurrentPage(1); setBoardType('1');}}/>
                                        <Pagination.Prev  onClick={() => {noticeCurrentPage > 1 ? setNoticeCurrentPage(noticeCurrentPage-1 ) : callAlertFn('첫 페이지입니다.'); setBoardType('1');}}/>
                                        <Pagination.Next  onClick={() => {noticeCurrentPage < noticeTotalPage ? setNoticeCurrentPage(noticeCurrentPage+1) : callAlertFn('마지막 페이지입니다.'); setBoardType('1');}}/>
                                        <Pagination.Last  onClick={() => {setNoticeCurrentPage(noticeTotalPage); setBoardType('1')}}/>
                                    </Pagination>
                                </div>
                                <div className='position-absolute d-flex justify-content-end' style={{width: '100%', bottom: 0, right: -10}}>      
                                    <Overlay target={popoverTarget.current} show={showNoticePopover} placement="top">
                                        {({
                                            placement: _placement,
                                            arrowProps: _arrowProps,
                                            show: _show,
                                            popper: _popper,
                                            hasDoneInitialMeasure: _hasDoneInitialMeasure, // 제거
                                            ...props // 나머지만 전달
                                        }) => (
                                        <div
                                            {...props}
                                            style={{
                                            position: 'absolute',
                                            backgroundColor: 'white',
                                            color: 'white',
                                            borderTopLeftRadius: 5, 
                                            borderTopRightRadius: 5,
                                            borderBottomLeftRadius: 5,
                                            borderBottomRightRadius: 5,
                                            borderWidth: 1,
                                            zIndex: 1050,
                                            marginLeft: -150,
                                            marginBottom: 5, 
                                            width: 500,
                                            backgroundColor: '#fff',
                                            boxShadow: '0 0 0.875rem 0 rgb(41 48 66 / 10%)',
                                            ...props.style,
                                            }}
                                        >
                                            <div className='bg-secondary d-flex justify-content-between' style={{width: '100%', padding: 10, borderTopLeftRadius: 5, borderTopRightRadius: 5, verticalAlign: 'middle'}}>
                                                <span style={{fontSize: 16}}>
                                                    공지사항 검색
                                                </span>
                                                <IconBtn style={{color:'white', width: '20px'}}
                                                    iconClass={'fi fi-br-cross'}
                                                    onClick={() => setShowNoticePopover(false)}
                                                />
                                            </div>
                                            <div style={{padding: 15}}>
                                                <Stack direction="horizontal">
                                                    <CInputGroup
                                                        className="p-2"
                                                        label={'제목'}
                                                        labelId={'subject'}
                                                        value={noticeSubject}
                                                        onChange={v => setNoticeSubject(v.target.value)}
                                                        enterKeyHint={() => getBoardList('1')}
                                                    />
                                                    <CInputGroup
                                                        className="p-2"
                                                        label={'작성자'}
                                                        labelId={'creator'}
                                                        value={noticeCreator}
                                                        onChange={v => setNoticeCreator(v.target.value)}
                                                        enterKeyHint={() => getBoardList('1')}
                                                    />
                                                </Stack>
                                                <Stack direction='horizontal'>
                                                    <CInputGroup
                                                        className="p-2"
                                                        label={'내용'}
                                                        labelId={'content'}
                                                        value={noticeContents}
                                                        onChange={v => setNoticeContents(v.target.value)}
                                                        enterKeyHint={() => getBoardList('1')}
                                                    />
                                                </Stack>
                                                <Stack direction='horizontal' gap={2}>
                                                    <CalendarGroup className="p-2" asSingle={true} label={'시작일'}
                                                                value={{
                                                                    startDate: noticeDate.startDate,
                                                                    endDate: noticeDate.startDate
                                                                }}
                                                                onChange={v => setNoticeDate({startDate: v.startDate, endDate: noticeDate.endDate})}
                                                    />
                                                    <CalendarGroup className="p-2" asSingle={true} label={'종료일'}
                                                                value={{
                                                                    startDate: noticeDate.endDate,
                                                                    endDate: noticeDate.endDate
                                                                }}
                                                                onChange={v => setNoticeDate({startDate: noticeDate.startDate, endDate: v.endDate})}
                                                    />
                                                </Stack>
                                                <Stack direction='horizontal' className="mx-auto mt-2 justify-content-center" gap={2}>
                                                    <Button variant='primary' style={{width: 80}} onClick={() => {getBoardList('1');}}>조회</Button>
                                                    <Button variant='secondary' style={{width: 80}} onClick={() => resetBoardList('1')}>초기화</Button>
                                                    {/* <Button variant='secondary' onClick={() => setShowNoticePopover(false)}>닫기</Button> */}
                                                </Stack>
                                            </div>
                                        </div>
                                        )}
                                    </Overlay>
                                     <Button size={'sm'} variant={"secondary"} className='ms-2'
                                        ref={popoverTarget} onClick={() => setShowNoticePopover((prev) => !prev)} 
                                        style={{height: 30, width: 30, borderRadius: 20, verticalAlign: 'middle', textAlign: 'center', paddingTop: 4}}>
                                        <i className={'me-1 fi fi-br-search'} />
                                    </Button>
                                    <Button size={'sm'} variant={"secondary"} className='ms-2 me-2'
                                            onClick={() => {setNoticeTitle('공지사항');
                                                            setBoardType('1')
                                                            setBoardSeq('');
                                                            AppStore.toggleModal();}} 
                                            style={{height: 30, width: 30, borderRadius: 20, verticalAlign: 'middle', textAlign: 'center', paddingTop: 2, paddingLeft: 8}}>
                                        <i className={'me-1 fi fi-br-plus'} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </CCol>
                <CCol lg={4}>
                    <div style={{padding: 20, backgroundColor: 'rgb(239 239 239)', borderRadius: 5, boxShadow: '0 0 0.875rem 0 rgb(41 48 66 / 10%)', height: '552px'}}>
                        <div className="position-relative" style={{height: 512}}>
                            <div style={{paddingLeft: 5}}>
                                <div className="border-bottom" style={{height:40, display: 'flex'}}>
                                    <i className="fi fi-rr-comment-alt" style={{fontSize: 15,marginTop: 5}}/>
                                    <label style={{color: '#495057', fontSize: 16, marginLeft: 10}}>공유게시판</label>
                                </div>
                                { BoardStore.blackList.length > 0 ?
                                    BoardStore.blackList.map(board => (
                                        <a key={board.boardSeq} href='#' className='text-dark text-decoration-none'
                                           onClick={(e) => getBoardDetail(board.boardSeq, '2')}>
                                            <div className="d-flex text-body-secondary pt-3">
                                                <div style={{height: 38, width: 39, overflow: "hidden"}}>
                                                    {board.imageUrl ?
                                                        <Image src={board.imageUrl} style={{
                                                            height: 38,
                                                            objectFit: "cover",
                                                            borderRadius: "20px"
                                                        }} alt="프로필 이미지"/>
                                                        :
                                                        <i className="fi fi-rs-circle-user" style={{fontSize: 34}}/>
                                                    }
                                                </div>
                                                <div className="pb-3 mb-0 small lh-sm border-bottom w-100 ml-3">
                                                    <div className="d-flex justify-content-between">
                                                        <strong className="text-gray-dark">{board.subject}</strong>
                                                        <span className="d-block">{board.creatorName}</span>
                                                    </div>
                                                    <span style={{
                                                        fontSize: 10,
                                                        marginTop: 2,
                                                        color: '#A4A4A4'
                                                    }}>{board.createdDate}</span>
                                                </div>
                                            </div>
                                        </a>
                                    ))
                                :
                                    <div style={{textAlign: 'center', paddingTop: 200}}>
                                        <p>등록된 게시글이 없습니다.</p>
                                    </div>
                                }
                            </div>
                            <div>
                                <div className="position-absolute d-flex justify-content-center" style={{width: '100%', bottom: -20}}>
                                    <Pagination style={{zIndex: 1}}>
                                        <Pagination.First onClick={() => {setBlackCurrentPage(1); setBoardType('1');}}/>
                                        <Pagination.Prev  onClick={() => {blackCurrentPage > 1 ? setBlackCurrentPage(blackCurrentPage-1 ) : callAlertFn('첫 페이지입니다.'); setBoardType('2');}}/>
                                        <Pagination.Next  onClick={() => {blackCurrentPage < blackTotalPage ? setBlackCurrentPage(blackCurrentPage+1) : callAlertFn('마지막 페이지입니다.'); setBoardType('2');}}/>
                                        <Pagination.Last  onClick={() => {setBlackCurrentPage(blackTotalPage); setBoardType('2')}}/>
                                    </Pagination>
                                </div>
                                <div className='position-absolute d-flex justify-content-end' style={{width: '100%', bottom: 0, right: -10}}>
                                    <Overlay target={blackPopoverTarget.current} show={showBlackPopover} placement="top">
                                        {({
                                            placement: _placement,
                                            arrowProps: _arrowProps,
                                            show: _show,
                                            popper: _popper,
                                            hasDoneInitialMeasure: _hasDoneInitialMeasure, // 제거
                                            ...props // 나머지만 전달
                                        }) => (
                                        <div
                                            {...props}
                                            style={{
                                            position: 'absolute',
                                            backgroundColor: 'white',
                                            color: 'white',
                                            borderTopLeftRadius: 5, 
                                            borderTopRightRadius: 5,
                                            borderBottomLeftRadius: 5,
                                            borderBottomRightRadius: 5,
                                            borderWidth: 1,
                                            zIndex: 1050,
                                            // marginLeft: -55,
                                            marginBottom: 5, 
                                            width: 500,
                                            backgroundColor: '#fff',
                                            boxShadow: '0 0 0.875rem 0 rgb(41 48 66 / 10%)',
                                            ...props.style,
                                            }}
                                        >
                                            <div className='bg-secondary d-flex justify-content-between' style={{width: '100%', padding: 10, borderTopLeftRadius: 5, borderTopRightRadius: 5, verticalAlign: 'middle'}}>
                                                <span style={{fontSize: 16}}>
                                                    공유게시판 검색
                                                </span>
                                                <IconBtn style={{color:'white', width: '20px'}}
                                                    iconClass={'fi fi-br-cross'}
                                                    onClick={() => setShowBlackPopover(false)}
                                                />
                                            </div>
                                            <div style={{padding: 15}}>
                                                <Stack direction="horizontal">
                                                    <CInputGroup
                                                        className="p-2"
                                                        label={'제목'}
                                                        labelId={'subject'}
                                                        value={blackSubject}
                                                        onChange={v => setBlackSubject(v.target.value)}
                                                        enterKeyHint={() => getBoardList('2')}
                                                    />
                                                    <CInputGroup
                                                        className="p-2"
                                                        label={'작성자'}
                                                        labelId={'creator'}
                                                        value={blackCreator}
                                                        onChange={v => setBlackCreator(v.target.value)}
                                                        enterKeyHint={() => getBoardList('2')}
                                                    />
                                                </Stack>
                                                <Stack direction='horizontal'>
                                                    <CInputGroup
                                                        className="p-2"
                                                        label={'내용'}
                                                        labelId={'content'}
                                                        value={blackContents}
                                                        onChange={v => setBlackContents(v.target.value)}
                                                        enterKeyHint={() => getBoardList('2')}
                                                    />
                                                </Stack>
                                                <Stack direction='horizontal' gap={2}>
                                                    <CalendarGroup className="p-2" asSingle={true} label={'시작일'}
                                                                value={{
                                                                    startDate: blackDate.startDate,
                                                                    endDate: blackDate.startDate
                                                                }}
                                                                onChange={v => setBlackDate({startDate: v.startDate, endDate: blackDate.endDate})}
                                                    />
                                                    <CalendarGroup className="p-2" asSingle={true} label={'종료일'}
                                                                value={{
                                                                    startDate: blackDate.endDate,
                                                                    endDate: blackDate.endDate
                                                                }}
                                                                onChange={v => setBlackDate({startDate: blackDate.startDate, endDate: v.endDate})}
                                                    />
                                                </Stack>
                                                <Stack direction='horizontal' className="mx-auto mt-2 justify-content-center" gap={2}>
                                                    <Button variant='primary' style={{width: 80}} onClick={() => {getBoardList('2');}}>조회</Button>
                                                    <Button variant='secondary' style={{width: 80}} onClick={() => resetBoardList('2')}>초기화</Button>
                                                </Stack>
                                            </div>
                                        </div>
                                        )}
                                    </Overlay>
                                     <Button size={'sm'} variant={"secondary"} className='ms-2'
                                        ref={blackPopoverTarget} onClick={() => setShowBlackPopover((prev) => !prev)} 
                                        style={{height: 30, width: 30, borderRadius: 20, verticalAlign: 'middle', textAlign: 'center', paddingTop: 4}}>
                                        <i className={'me-1 fi fi-br-search'} />
                                    </Button>
                                    <Button size={'sm'} variant={"secondary"} className='ms-2 me-2'
                                            onClick={() => {
                                                setNoticeTitle('공유게시판');
                                                setBoardType('2');
                                                setBoardSeq('');
                                                AppStore.toggleModal();
                                            }} 
                                            style={{height: 30, width: 30, borderRadius: 20, verticalAlign: 'middle', textAlign: 'center', paddingTop: 4, paddingLeft: 8}}>
                                        <i className={'me-1 fi fi-br-plus'} />
                                    </Button>
                                </div>

                                        

                            </div>
                        </div>
                    </div>
                </CCol>
            </Row>
        </Container>
        <Modal size={'lg'} title={noticeTitle} onExit={closedModal}>
            <BoardDetail
                boardSeq={boardSeq}
                boardType={boardType}
                getBoardList={getBoardList}/>
        </Modal>
    </>
    );
};

export default observer(Dashboard);
