import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { CContainer, CIconBtn } from "../../../components/CustomContainer";
import Modal from "../../../components/Modal";
import { AppStore } from "../../../store/AppStore";
import { getLocalStorage } from "../../../utils";
import { USER_INFO } from "../../../common/constants";

const ApronSettingPopup = ({callbackFn, apronList}) => {
    const [apronSeq, setApronSeq] = useState();

    useEffect(() => {
        if(AppStore.isApronSettingPopup) {
            const seq = getLocalStorage(USER_INFO).apronSeq;
            setApronSeq(seq);
        }

    }, [AppStore.isApronSettingPopup])

    const saveApronSeq = () => {
        callbackFn(apronSeq);
        AppStore.toggleApronSettingPopup();
    }

    return (
        <>
        <Modal title={'주기장 설정'}
            onHide={() => AppStore.toggleApronSettingPopup()}
            toggleValue={AppStore.isApronSettingPopup}
            // onExit={}
            size={'sm'}
        >
            <CContainer search>
                <Row>
                    <div className="btn-group btn-group-toggle pt-1 pb-3" style={{justifyContent: 'space-around'}}>
                        {apronList?.map((v, i) => (
                            <label style={{marginRight: 20, display: 'inline-flex', verticalAlign: 'middle', fontSize: 14}} key={i}>
                                <Form.Check
                                    type="radio" value={apronSeq} name="apronSeq"
                                    checked={Number(apronSeq) === v.apronSeq ? true : false}
                                    onChange={() => {setApronSeq(v.apronSeq)}}
                                    style={{verticalAlign: 'middle', marginRight: 10}}/>
                                    {v.apronName}
                            </label>
                        ))}
                    </div>
                </Row>
            </CContainer>
            <div style={{textAlign: 'center'}}>
                <CIconBtn style={{width: 90, height: 30}}
                        title={'저장'}
                        className={'me-0'}
                        onClick={saveApronSeq}
                        variant={'success'}
                />
            </div>
        </Modal>
        </>
    );
}

export default observer(ApronSettingPopup);
