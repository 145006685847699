import { useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";
import axios from "axios";

import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer, CSearchBtn, CSelectGroup } from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";
import { findCommKrnm } from "../../utils/commcode";
import { showToast } from "../../common/utils";

const CertificateListPopup =({ callbackFn=null, column='' })=> {
    const gridRef = useRef();
    const [certificateList, setCertificateList] = useState([]);
    const [expiration, setExpiration] = useState('available');

    const getCertificateList = async () => {
        const result = await axios.get('certificate/list', {params: {expiration: expiration}});
        setCertificateList(result);
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.')
        }
    }

    const selectedRow = e => {
        callbackFn && callbackFn(e.data);
        AppStore.toggleCertificateListPopup();
    }

    const refresh = () => {
        setCertificateList([]);
        setExpiration('available');
    }

    return (
        <Modal
            title={'인증서조회'}
            show={AppStore.isOpenCertificateListPopup}
            onHide={() => AppStore.toggleCertificateListPopup()}
            toggleValue={AppStore.isOpenCertificateListPopup}
            size={'lg'}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CSelectGroup label={'만료여부'}
                                      options={[
                                            {name: '사용가능', value: 'available'},
                                            {name: '전체', value: ''},
                                      ]}
                                      value={expiration}
                                      onChange={v => setExpiration(v.target.value)}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getCertificateList} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={25}
                    rowData={certificateList}
                    columnDefs={
                        [
                            {field: "certificateSeq", headerName: "인증서번호"},
                            {field: "certUsage", headerName: "인증서용도", valueFormatter: v => findCommKrnm(v.value, '46')},
                            {field: "certName", headerName: "인증서이름", minWidth: 240},
                            {field: "companyCode", headerName: "회사구분", valueFormatter: v => findCommKrnm(v.value, '20'), minWidth: 120},
                            {field: "expirationDate", headerName: "만료일", minWidth: 140},
                            {field: "remark", headerName: "비고", minWidth: 200},
                        ]
                    }
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(CertificateListPopup);
