import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { AppStore } from '../../store/AppStore';
import {callAlert} from '../../utils';
import LUtils from '../../utils/lodashUtils';
import { useLocation, useNavigate } from 'react-router-dom';

const SidebarLayout = (props) => {
    const { collapseSidebar, collapsed } = useProSidebar();
    const [mdCateList, setMDCateList] = useState([]);
    const [smCateList, setSMCateList] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            await getCategoryList()
        })();
    }, []);

    /* 사용중인 카테고리 조회 */
    const getCategoryList = async () => {
        const params = {isActive: 1};
        const result = await axios.get('/category', { params });

        if(result == null){
            await callAlert('연결 세션이 종료 되었습니다.');
            navigate('/login');
            return;
        }else{
            // 중분류
            const mdCateList = await result.filter(v => v.parentCateSeq === 0).map(v => {
                v.authorityCode = `CM-${v.alias}`
                return v;
            });
            setMDCateList(mdCateList);

            // 소분류
            const smCateList = await result.filter(v => v.parentCateSeq !== 0).map(v => {
                v.authorityCode = `CS-${v.alias}`
                return v;
            });
            setSMCateList(smCateList);
        }
    }

    const goToPage =(url, name)=> {
        //히스토리 저장
        AppStore.pushOpenPageHistory({url: url, name: name});
        navigate(url);
    }

    const smCateListUi = (v, i) => {
        return (
            <MenuItem key={i} onClick={()=> goToPage(v.url, v.cateName)}/*component={<Link key={i} to={v.url}  />}*/>
                {(v.url && location.pathname === v.url) &&
                    <div style={{position: 'absolute', top: 23, borderRadius: 5, width:5, height: 5, backgroundColor: '#01aaeb'}} />
                }
                <span className='m-lg-3'>{v.cateName}</span>
            </MenuItem>
        )
    }

    /* 카테고리 list UI */
    const categoryListUI = () => {
        return mdCateList.filter(v => LUtils.includes(AppStore.user.mdAuthList, v.authorityCode)).map((v, i) => {
            const smCate = smCateList.filter(value => {
                return value.parentCateSeq === v.cateSeq
            });
            return (
                <SubMenu key={i}
                         className={'sideMenuItem'}
                         style={{paddingLeft: 5}}
                         icon={<i style={{color: '#9fa9b5'}}
                         className={`fi ${v.iconClass}`} />} label={v.cateName}
                >
                    {smCate.filter(v => LUtils.includes(AppStore.user.smAuthList, v.authorityCode)).map((v, index) => smCateListUi(v, index))}
                </SubMenu>
            )
        });
    }

    return (
        <Sidebar backgroundColor={'#0e2338'} style={{position: 'fixed', width: collapsed ? 80 : 250, height: '100%', zIndex: 10, borderRightWidth: 0, top: 70}}>
            <Menu
                menuItemStyles={{
                    button: ({ level, active, disabled, isSubmenu, open }) => {
                        // only apply styles on first level elements of the tree
                        if (level === 0)
                            return {
                                fontWeight: '600',
                                color: '#f2f2f2',
                                backgroundColor: open ? '#0f3649' : '#0e2338',
                                borderWidth: open ? 1 : 0,
                                borderColor: '#0f3649',
                                borderRadius: 10,
                                marginLeft: 5,
                                marginRight: 5,
                                '&:hover': {
                                    backgroundColor: '#0f3649', //01aaeb
                                    color: '#00bac7',
                                },
                            }
                        else if(level === 1)
                            return {
                                //455884
                                color: '#f2f2f2',
                                backgroundColor: '#0e2338',
                                '&:hover': {
                                    backgroundColor: '#0e2338',
                                    color: '#9fa9b5',
                                }
                            }
                    },
                    root : {backgroundColor: '#0e2338'},
                }}
            >
                <MenuItem style={{paddingLeft: 5, marginTop: 20}} icon={<i className="fi fi-rr-layout-fluid" style={{color: '#9fa9b5'}}/>}
                          onClick={()=> goToPage('/dashboard', 'Dashboard')} > Dashboard </MenuItem>

                {categoryListUI()}
                <div style={{marginBottom: 100}}/>
            </Menu>
        </Sidebar>
    );
};

export default observer(SidebarLayout);
