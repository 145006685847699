import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { CContainer, CCol, CalendarGroup, CInputGroup, CSelectGroup, CInputDoubleGroup, CSearchBtn } from "../../components/CustomContainer";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { ContractListStore } from "../../store/contract/ContractListStore";
import { getCommcode } from "../../utils/commcode";
import { AppStore } from "../../store/AppStore";
import CustomerBillingAddressSearch from "../../components/searchModal/CustomerBillingAddressSearch";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import SiteNameSearch from "../../components/searchModal/SiteNameSearch";
import { useLocation } from "react-router-dom";
import LUtils from "../../utils/lodashUtils";
import axios from "axios";
import { getLocalStorage } from "../../utils";
import { USER_INFO } from "../../common/constants";

const ContractListSearch = ({isShort, setIsShort, getContractList}) => {
    
    const location = useLocation();
    const [userColumn, setUserColumn] = useState('');
    const [apronList, setApronList] = useState([]);

    useEffect(() => {
        const fromPage = LUtils.get(location.state, 'from', null) ;
        if(fromPage === 'dashboard'){
            getContractList()
        }
        getApronList();
        const apronSeq = getLocalStorage(USER_INFO).apronSeq;
        ContractListStore.setSearchCondition({apronSeq: apronSeq});
    }, []);

    const getApronList = async() => {
        const result = await axios.get("/user/apron");
        const resultList = result.map(v => {
            v.name = v.apronName;
            v.value = v.apronSeq;
            return v;
        })
        setApronList(resultList);
    }

    const getApronName = (value) => {
        if(value){
            return apronList.find(v => v.value === Number(value)).name;
        }
    }

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        ContractListStore.setSearchCondition({customerName: customerInfo.customerName});
        ContractListStore.setSearchCondition({customerNo: customerInfo.customerNo});
    }

     /* 청구지 조회 팝업 오픈 */
     const openSearchBillingAddressPopup = () => {
        AppStore.toggleCustomerBillingAddressSearchModal()
    }

    /* 청구지 정보 콜백 함수 */
    const getBillingAddressInfo = (info) => {
        ContractListStore.setSearchCondition({billingAddressSeq: info.billingAddressSeq});
        ContractListStore.setSearchCondition({billingAddressName: info.billingAddressName});
    }

     /* 현장 조회 팝업 오픈 */
     const openSearchSitePopup = () => {
        AppStore.toggleSiteNameSearchPopup()
    }

    /* 현장 정보 콜백 함수 */
    const getSiteInfo = (info) => {
        ContractListStore.setSearchCondition({siteSeq: info.siteSeq});
        ContractListStore.setSearchCondition({siteName: info.siteName});
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = (column) => {
        setUserColumn(column);
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo, column) => {
        if (!userInfo || !column) {
            return false;
        }

        ContractListStore.setSearchCondition({[`${column}Name`]: userInfo.name});
        ContractListStore.setSearchCondition({[`${column}Seq`]: userInfo.userSeq});
        setUserColumn('');
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'시작일'}
                            value={ContractListStore.contractStartDate}
                            onChange={v => ContractListStore.setStartDate(v)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'종료일'}
                            value={ContractListStore.contractEndDate}
                            onChange={v => ContractListStore.setEndDate(v)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup
                            label={'계약유형'} labelId={'contractType'}
                            options={[{ value: '', name: '전체' }].concat(getCommcode('1'))}
                            value={ContractListStore.searchCondition.contractType || ''}
                            onChange={v => ContractListStore.setSearchCondition({ contractType: v.target.value })} />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'계약번호'}
                            value={ContractListStore.searchCondition.contractNo}
                            onChange={v => ContractListStore.setSearchCondition({ contractNo: v.target.value })}
                            enterKeyHint={getContractList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'자산번호'}
                            value={ContractListStore.searchCondition.assetNo}
                            onChange={(v)=> ContractListStore.setSearchCondition({ assetNo: v.target.value })}
                            enterKeyHint={getContractList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'관리번호'}
                            value={ContractListStore.searchCondition.managementNo}
                            onChange={(v)=> ContractListStore.setSearchCondition({ managementNo: v.target.value })}
                            enterKeyHint={getContractList}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={4}>
                        <CInputDoubleGroup
                            label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객코드'} placeholder2={'고객명'}
                            onCallbackBtn={openSearchCustomerPopup}
                            value={ContractListStore.searchCondition.customerNo}
                            value2={ContractListStore.searchCondition.customerName}
                            onChange={(v)=> ContractListStore.setSearchCondition({customerNo: v.target.value})}
                            onChange2={(v)=> ContractListStore.setSearchCondition({customerName: v.target.value})}
                            enterKeyHint={getContractList}
                            enterKeyHint2={getContractList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'현장'}
                            value={ContractListStore.searchCondition.siteName}
                            onCallbackBtn={openSearchSitePopup}
                            onChange={(v)=> {
                                if(ContractListStore.searchCondition.siteSeq) {
                                    ContractListStore.setSearchCondition({siteSeq: ''})
                                }
                                ContractListStore.setSearchCondition({siteName: v.target.value})
                            }}
                            enterKeyHint={getContractList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'청구지'}
                            value={ContractListStore.searchCondition.billingAddressName}
                            onCallbackBtn={openSearchBillingAddressPopup}
                            onEmptyBtn={()=> ContractListStore.setSearchCondition({billingAddressName: ''})}
                            disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'영업담당자'}
                            value={ContractListStore.searchCondition.saleUserName}
                            onCallbackBtn={() => openSearchUserPopup('saleUser')}
                            onEmptyBtn={()=> {
                                ContractListStore.setSearchCondition({saleUserSeq: ''})
                                ContractListStore.setSearchCondition({saleUserName: ''})
                            }}
                            disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'이전계약번호'}
                            value={ContractListStore.searchCondition.preContractNo}
                            onChange={(v)=> ContractListStore.setSearchCondition({ preContractNo: v.target.value })}
                            enterKeyHint={getContractList}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>마감일</InputGroup.Text>
                            <Form.Control
                                style={{flex: 1, textAlign: 'right'}}
                                max={2}
                                aria-describedby={'endDate'}
                                value={ContractListStore.searchCondition.endDate || ''}
                                onChange={(v)=> ContractListStore.setSearchCondition({ endDate: v.target.value })}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>일</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup
                            label={'주기장'} labelId={'apron'}
                            options={[{ value: '', name: '전체' }].concat(apronList)}
                            value={ContractListStore.searchCondition.apronSeq || ''}
                            onChange={v => ContractListStore.setSearchCondition({ apronSeq: v.target.value })} />
                    </CCol>
                    <CCol lg={1}>
                        <Form.Check
                            type="switch"
                            id="isTransfer"
                            label="승계 계약"
                            style={{marginTop: 4}}
                            checked={ContractListStore.searchCondition.isTransferContract === 1}
                            onChange={v => ContractListStore.setSearchCondition({isTransferContract : v.target.checked ? 1 : 0})}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isZeroPrice"
                            label="스페어 장비 출고 계약"
                            style={{marginTop: 4}}
                            checked={ContractListStore.searchCondition.isZeroPrice === 1}
                            onChange={v => ContractListStore.setSearchCondition({isZeroPrice : v.target.checked ? 1 : 0})}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isShort"
                            label="간략히 보기"
                            style={{marginTop: 4}}
                            checked={!!isShort}
                            onChange={v => setIsShort(v.target.checked ? true : false)}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getContractList} />
                    </Col>
                </Row>
            </CContainer>
            {/* popup */}
            <CustomerBillingAddressSearch callbackFn={getBillingAddressInfo} />
            <CustomerSearch callbackFn={getCustomerInfo}/>
            <UserSearch callbackFn={getUserInfo} column={userColumn}/>
            <SiteNameSearch callbackFn={getSiteInfo} />
        </>
    );

}

export default observer(ContractListSearch);
