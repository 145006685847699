import React from "react";
import { observer } from "mobx-react-lite";
import { Row, Col } from "react-bootstrap";
import axios from "axios";

import { CardStatementStore } from "../../store/accounting/CardStatementStore";
import { CCol, CContainer, CalendarGroup, CInputGroup, CSelectGroup, CSearchBtn, CIconBtn } from "../../components/CustomContainer";
import { getCommcode } from "../../utils/commcode";
import { AppStore } from "../../store/AppStore";

const CardStatementSearch = ({getCardStatementList}) => {
    const store = CardStatementStore;

    /* 카드 승인 내역 가져오기 */
    const handleCardUpload = async () => {
        AppStore.isAxiosSetting = true;
        const result = await axios.post('/card/approval/data', store.search);
        await getCardStatementList();
        alert('실패 : ' + result.length + '개 \n' + result.join(""));
    };

    return (
        <CContainer search>
            <Row>
                <CCol lg={2}>
                    <CalendarGroup label={'시작일'}
                                    asSingle={true}
                                    value={{
                                        startDate: store.search.startDate,
                                        endDate: store.search.startDate
                                    }}
                                    onChange={v => {store.search.startDate = v.startDate}}
                    />
                </CCol>
                <CCol lg={2}>
                    <CalendarGroup label={'종료일'}
                                    asSingle={true}
                                    value={{
                                        startDate: store.search.endDate,
                                        endDate: store.search.endDate
                                    }}
                                    onChange={v => {store.search.endDate = v.startDate}}
                    />
                </CCol>
                <CCol lg={2}>
                    <CSelectGroup label={'카드사'} labelId={'cardCode'}
                                    placeholder={'카드사'}
                                    options={[{name: '전체', value: ''}].concat(getCommcode('44'))}
                                    value={store.search.cardCode}
                                    onChange={v => store.inputSearch.bankCode = v.target.value}
                    />
                </CCol>
                <CCol lg={2}>
                    <CSelectGroup
                        label={'조회구분'} labelId={'searchType'}
                        options={[
                            {name: '전체', value: ''},
                            {name: '미전표생성', value: 'preStatement'},
                            {name: '전표생성완료', value: 'completeStatement'},
                        ]}
                        value={store.search.searchType}
                        onChange={v => store.search.searchType = v.target.value}
                    />
                </CCol>
                <CCol lg={2}>
                    <CInputGroup
                        label={'카드번호'} labelId={'cardNumber'}
                        value={store.search.cardNumber}
                        onChange={v => store.search.cardNumber = v.target.value}
                    />
                </CCol>
            </Row>
            <Row>
                <Col className='d-flex flex-row-reverse'>
                    <CIconBtn title={'카드승인내역 가져오기'}
                                icon={'fi-rr-download'}
                                onClick={handleCardUpload}
                                isKeyEvent={true}
                    />
                    <CSearchBtn onClick={getCardStatementList} />
                </Col>
            </Row>
        </CContainer>
    );
}

export default observer(CardStatementSearch);
