import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { Container, Col, Row, Form } from "react-bootstrap";

import { CContainer, CCol } from '../../components/CustomContainer';
import AgGridContainer from "../../components/AgGridContainer";
import { showToast } from "../../common/utils";
import CertificatePopup from "./popup/CertificatePopup";
import CertificateInfo from "./CertificateInfo";
import { CertificateStore } from "../../store/commCode/CertificateStore";

const CertificateList = () => {
    const store = CertificateStore;
    const gridRef = useRef();
    const [certificateList, setCertificateList] = useState([]);

    useEffect(() => {
        getCertificateList();
    }, []);

    const columnDefs = [
        { field: "certificateSeq", headerName: "인증서 seq", hide: true },
        { field: "certName", headerName: "인증서이름", width: 200 },
        { field: "certPassword", headerName: "인증서비밀번호", hide: true },
        { field: "expirationDate", headerName: "만료일", width: 120},
        { field: "businessNumber", headerName: "사업자 번호", width: 120 },
        { field: "companyName", headerName: "회사구분", width: 120 },
        { field: "createdDate", headerName: "등록일", minWidth: 180, flex: 1 },
    ];

    /* 계좌 목록 조회 */
    const getCertificateList = async () => {
        const result = await axios.get('certificate/list', {params: {expiration: store.expiration}});
        if (result.length === 0) {
            showToast('조회된 데이터가 없습니다.');
        }
        store.certificateList = result;
        setCertificateList(result);
    }

    /* 계좌 상세 조회 */
    const selectCertificateDetail = async (v) => {
        store.data = v.data;
    }

    /* 인증서 팝업 callback */
    const callbackCertificatePopup = (data) => {
        store.data.certName = data.certName;
        // store.data.certPassword = null;
        store.data.derPath = data.derPath;
        store.data.keyPath = data.keyPath;
        store.data.expirationDate = data.expirationDate;
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <Form.Check
                            type="switch"
                            id="isCertificate"
                            label="인증서 사용가능 여부"
                            style={{marginTop: 15}}
                            checked={store.expiration === 'available'}
                            onChange={v => {store.expiration = v.target.checked ? 'available' : '', getCertificateList()}}
                        />
                    </CCol>
                </Row>
            </CContainer>
            <Container fluid>
                <Row>
                    <Col lg={6}>
                        <CContainer>
                            <AgGridContainer
                                girdTitle='인증서목록'
                                gridRef={gridRef}
                                height={60}
                                rowData={certificateList}
                                columnDefs={columnDefs}
                                isCheckBox={false}
                                rowSearchCallback={selectCertificateDetail}
                                rowDoubleClickCallback={selectCertificateDetail}
                            />
                        </CContainer>
                    </Col>

                    {/* 인증서 상세 조회 */}
                   <CertificateInfo getCertificateList={getCertificateList} />
                </Row>
            </Container>
            <CertificatePopup callbackFn={callbackCertificatePopup}/>
        </>
    );
};

export default observer(CertificateList);
