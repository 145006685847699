import React, {useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Button, Row, InputGroup, Form } from "react-bootstrap";
import { CContainer, CCol, CInputGroup } from '../../components/CustomContainer';
import { dateTimeFormat } from '../../utils/dateUtils';

const Details = ({info}) => {

    const fnOnChange = (e) => {
        console.log(e.target.value);
    }

    return (
        <CContainer>
            <Row>
                <CCol>
                    <CInputGroup label={'이름!!'} labelId={'name1'} placeholder={'이름'} value={info.name} disabled={true} onChange={fnOnChange}/>
                </CCol>
                <CCol>
                    <CInputGroup label={'이름!!'} labelId={'name2'} placeholder={'이름'} value={info.name} disabled={true} onChange={fnOnChange}/>
                </CCol>
                <CCol>
                    <CInputGroup label={'이름!!'} labelId={'name3'} placeholder={'이름'} value={info.name} disabled={true} onChange={fnOnChange}/>
                </CCol>
                <CCol>
                    <CInputGroup label={'이름!!'} labelId={'name4'} placeholder={'이름'} value={info.name} disabled={true} onChange={fnOnChange}/>
                </CCol>
            </Row>
            <hr />
            <Row>
                <CCol>
                    <CInputGroup
                        label={'아이디!!'}
                        labelId={'id1'}
                        placeholder={'아이디'}
                        value={info.id}
                        disabled={false}
                        onChange={fnOnChange}
                        labelClassName='input-required'
                    />
                </CCol>
                <CCol>
                    <CInputGroup label={'아이디!!'} labelId={'id2'} placeholder={'아이디'} value={info.id} disabled={false} onChange={fnOnChange}/>
                </CCol>
                <CCol>
                    <CInputGroup label={'아이디!!'} labelId={'id3'} placeholder={'아이디'} value={info.id} disabled={false} onChange={fnOnChange}/>
                </CCol>
                <CCol>
                    <CInputGroup label={'아이디!!'} labelId={'id4'} placeholder={'아이디'} value={info.id} disabled={false} onChange={fnOnChange}/>
                </CCol>
            </Row>
            <hr />
            <Row>
                <CCol>
                    <CInputGroup label={'날자!!'} labelId={'today1'} placeholder={'날자'} value={dateTimeFormat(info.today)} disabled={true} onChange={fnOnChange}/>
                </CCol>
                <CCol>
                    <CInputGroup label={'날자!!'} labelId={'today2'} placeholder={'날자'} value={dateTimeFormat(info.today)} disabled={true} onChange={fnOnChange}/>
                </CCol>
                <CCol>
                    <CInputGroup label={'날자!!'} labelId={'today3'} placeholder={'날자'} value={dateTimeFormat(info.today)} disabled={true} onChange={fnOnChange}/>
                </CCol>
                <CCol>
                    <CInputGroup label={'날자!!'} labelId={'today4'} placeholder={'날자'} value={dateTimeFormat(info.today)} disabled={true} onChange={fnOnChange}/>
                </CCol>
            </Row>
            <hr />
            <Row>
                <Col className='d-flex flex-row-reverse'>
                    <Button variant="primary">저장</Button>{' '}
                    <Button className='me-2' variant="secondary">취소</Button>{' '}
                </Col>
            </Row>
        </CContainer>
    );
};

export default observer(Details);
