import React, { useRef, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import axios from 'axios';

import { StatementStore } from "../../store/accounting/StatementStore";
import StatementSearch from "./StatementSearch";
import AgGridContainer from "../../components/AgGridContainer";
import { getCommcode, findCommKrnm } from "../../utils/commcode";
import { CContainer } from "../../components/CustomContainer";
import { callConfirm } from "../../utils";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import { AppStore } from "../../store/AppStore";
import { IconBtn } from '../../components/CustomContainer';
import LUtils from "../../utils/lodashUtils";
import { DEBIT_CODE, CREDIT_CODE } from "../../common/constants";
import AccountingCodeSearch from "../../components/searchModal/AccountingCodeSearch";
import StatementPopup from "../../components/searchModal/StatementPopup";
import { showToast } from "../../common/utils";
import { today , minusDay } from "../../utils/dateUtils";

const StatementList = () => {
    const store = StatementStore;
    const gridRef = useRef();
    const [gridData, setGridData] = useState([]);
    const [type, setType] = useState('');
    const [inputCode, setInputCode] = useState('');
    const [viewMode, setViewMode] = useState('input');
    const [referenceKey, setReferenceKey] = useState('');
    const [isStatemnetJoin, setIsStatementJoin] = useState(false);

    useEffect(() => {
        // 일주일 전 날짜 세팅
        store.inputSearch.startDate = minusDay(6);
        store.inputSearch.endDate = today();
        store.outputSearch.startDate = minusDay(6);
        store.outputSearch.endDate = today();
    }, []);

    const inputColumnDefs = [
        {field: "accountNo", headerName: "거래번호", hide: true},
        {
            field: "bankCode",
            headerName: "은행",
            valueFormatter: v => findCommKrnm(v.value, '24'),
            cellEditorParams: {
                values: getCommcode('24').map(v => v.value),
            },
            minWidth: 100
        },
        {field: "accountNumber", headerName: "계좌번호", minWidth: 160},
        {field: "accountDate", headerName: "거래일자", minWidth: 120},
        {field: "accountTime", headerName: "거래시간", minWidth: 120, valueFormatter: v =>  v.value ? `${v.value.substring(0, 2)}:${v.value.substring(2, 4)}:${v.value.substring(4, 6)}` : ''},
        {field: "summary", headerName: "적요", minWidth: 200},
        {field: "accountType", headerName: "구분", minWidth: 120, valueFormatter: v => v.value === '1' ? '입금' : v.value === '2' ? '출금' : ''},
        {field: "inputAmount", headerName: "입금금액", minWidth: 120, valueFormatter: v => v.value?.toLocaleString()},
        {field: "outputAmount", headerName: "출금금액", minWidth: 120, valueFormatter: v => v.value?.toLocaleString()},
        {field: "accountAmount", headerName: "잔액", minWidth: 120, valueFormatter: v => v.value?.toLocaleString()},
        {field: "customerNo", headerName: "고객코드", minWidth: 160, hide: true},
        {field: "customerName", headerName: "고객명", headerClass: 'grid-column-required', minWidth: 160, cellRenderer: e => e.value === 'pinnedTopRowData' ? null : popupCustomerSearch(e)},
        {
            field: "statementType",
            headerName: "전표구분",
            minWidth: 80,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: getCommcode('41').map(v => v.value),
            },
            valueFormatter: v => v.value === 'pinnedTopRowData' ? '' : findCommKrnm('1', '41')   // '1': 일반
        },
        {field: "debitCode", headerName: "차변코드", editable: true, minWidth: 80, headerClass: 'grid-column-required'},
        {field: "debitAccount", headerName: "차변계정", minWidth: 120, cellRenderer: e => e.value === 'pinnedTopRowData' ? null : popupAccountingCode(e, 'debit')},
        {field: "creditCode", headerName: "대변코드", editable: true, minWidth: 80, headerClass: 'grid-column-required'},
        {field: "creditAccount", headerName: "대변계정", minWidth: 120, cellRenderer: e => e.value === 'pinnedTopRowData' ? null : popupAccountingCode(e, 'credit')},
        {field: "remark", headerName: "비고", editable: true, minWidth: 180},
    ];

    // const outputColumnDefs = [
    //     {field: "accountNo", headerName: "거래번호", hide: true},
    //     {
    //         field: 'outputStatementPopup',
    //         headerName: "전표연동",
    //         minWidth: 80,
    //         cellRenderer: (v) => (
    //             <IconBtn iconClass={'fi fi-br-arrows-repeat'} onClick={() => {outputStatementPopup(v), store.popup.isClicked=true}}/>
    //         ),
    //         hide: isStatemnetJoin
    //     },
    //     {field: "companyCode", headerName: "회사명", valueFormatter: v => findCommKrnm(String(v.value), '20'), width: 120},
    //     {
    //         field: "bankCode",
    //         headerName: "은행",
    //         valueFormatter: v => findCommKrnm(v.value, '24'),
    //         cellEditorParams: {
    //             values: getCommcode('24').map(v => v.value),
    //         },
    //         minWidth: 100
    //     },
    //     {field: "accountNumber", headerName: "계좌번호", minWidth: 160},
    //     {field: "accountDate", headerName: "거래일자", minWidth: 120},
    //     {field: "accountTime", headerName: "거래시간", minWidth: 120, valueFormatter: v => `${v.value.substring(0, 2)}:${v.value.substring(2, 4)}:${v.value.substring(4, 6)}`},
    //     {field: "summary", headerName: "적요", minWidth: 200},
    //     {field: "amount", headerName: "금액", minWidth: 120, valueFormatter: v => v.value?.toLocaleString()},
    //     {field: "accountAmount", headerName: "잔액", minWidth: 120, valueFormatter: v => v.value?.toLocaleString(), flex: 1},
    // ];

    /* 조회 */
    const getInputStatementList = async () => {
        if(viewMode === 'input'){
            const result = await axios.get("statement/input/list", {params: store.inputSearch});
            store.inputStatementList = result;
            if(result.length === 0) showToast('조회된 데이터가 없습니다.');
        }else if(viewMode === 'output'){
            const result = await axios.get("statement/output/list", {params: store.outputSearch});
            store.outputStatementList = result;
            if(result.length === 0) showToast('조회된 데이터가 없습니다.');
            if(store.outputSearch.searchType === 'completeStatement'){
                setIsStatementJoin(true);
            }else {
                setIsStatementJoin(false);
            }
        }
    }

    /* 고객 팝업 UI */
    const popupCustomerSearch = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.customerName}</div>
                <IconBtn key={e.rowIndex} onClick={() => openCustomerSearch(e)} />
            </div>
        )
    }

    /* 고객 조회 팝업 클릭 시 이벤트 */
    const openCustomerSearch = (e) => {
        AppStore.toggleCustomerSearchModal();
        setGridData(e);
    }

    /* 고객 팝업 callback */
    const searchCustomerInfo =(data)=> {
        store.inputStatementList.map(v => {
            if(v.accountSeq === gridData.data.accountSeq) {
                v.customerNo = data.customerNo;
                v.customerName = data.customerName;
                v.debitCode = DEBIT_CODE;
                v.debitAccount = '외상매출금';
                v.creditCode = CREDIT_CODE;
                v.creditAccount = '보통예금';
                LUtils.assign(v, {isUpdated: true, isSelected: true});
            }
        })
        gridRef.current.api.redrawRows();
    }

    /* 계정 과목 팝업 UI */
    const popupAccountingCode = (e, accountingType) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{accountingType === 'debit' ? e.data.debitAccount : e.data.creditAccount}</div>
                <IconBtn key={e.rowIndex} onClick={() => openAccountingCode(e, accountingType)} />
            </div>
        )
    }

    /* 고객 조회 팝업 클릭 시 이벤트 */
    const openAccountingCode = (e, accountingType) => {
        setType(accountingType);
        setGridData(e);
        AppStore.toggleAccountingCodePopup();
    }

    /* 계정 과목 팝업 callback */
    const searchAccountingCode = (data) => {
        store.inputStatementList.map(v => {
            if(v.accountSeq === gridData.data.accountSeq) {
                if(type === 'debit') {
                    v.debitCode = data.code;
                    v.debitAccount = data.name;
                }else if(type === 'credit') {
                    v.creditCode = data.code;
                    v.creditAccount = data.name;
                }
                LUtils.assign(v, {isUpdated: true, isSelected: true});
            }
        })
        setInputCode('');
        gridRef.current.api.redrawRows();
    }

    /* 자동 설정 버튼 */
    const autoSettingBtn = {
        isUsed: true,
        callbackFn:() => autoSetting(),
        icon: 'fi-bs-gears',
        title: '자동설정',
        width: 90,
    }

    /* 자동 설정 적용 */
    const autoSetting = async () => {
        const selectedList = store.inputStatementList.filter(v => v.isSelected).map(v => v.summary);
        if (selectedList.length < 1) return;

        const result = await axios.get('statement/auto', {params: {summaryList: selectedList.join(',')}});

        // 고객 번호, 고객명 각 row 에 담아주기
        store.inputStatementList.filter(v => v.isSelected).forEach(selectedItem => {
            const matchedResult = result.find(v => v.summary === selectedItem.summary );
            if (matchedResult) {
                selectedItem.customerNo = matchedResult.customerNo;
                selectedItem.customerName = matchedResult.customerName;
                selectedItem.debitCode = DEBIT_CODE;
                selectedItem.debitAccount = '외상매출금';
                selectedItem.creditCode = CREDIT_CODE;
                selectedItem.creditAccount = '보통예금';
            }
        });
        gridRef.current.api.redrawRows();
    }

    /* 저장 */
    const callBackGridData = async () => {
        // 필수값 확인
        const inputStatementList = store.inputStatementList.filter(v => v.isSelected);
        if(inputStatementList.some(v => v.customerNo === null ||  v.customerNo === '' || v.debitAccount === null || v.debitAccount === '' || v.creditAccount === null || v.creditAccount === '')){
            showToast("필수값을 입력해주세요.");
            return;
        }

        if (!await callConfirm('전표생성 하시겠습니까?')) return;

        await axios.post('statement', {statementList: inputStatementList});
        await getInputStatementList();
    }

    const cellCustomChangeValue = async (e) => {
        if(e.column.colId!=='debitCode' && e.column.colId!=='creditCode') return;
        const result = await axios.get('statement/accounting/code', {params: {type: 'code', inputText: e.value}});

        if(result.length === 1) {
            store.inputStatementList.filter(v => v.accountSeq === e.data.accountSeq).map(v => {
                if(e.column.colId==='debitCode'){
                    v.debitCode = result[0].code;
                    v.debitAccount = result[0].name;
                }else if(e.column.colId==='creditCode'){
                    v.creditCode = result[0].code;
                    v.creditAccount = result[0].name;
                }
            })
        }else {
            setGridData(e);
            setType(e.column.colId==='debitCode'?'debit':'credit');
            setInputCode(e.value);
            AppStore.toggleAccountingCodePopup();
        }
        gridRef.current.api.refreshCells({
            columns: ['debitCode', 'debitAccount', 'creditCode', 'creditAccount'],
            force: true,
        });
    };

    /* 출금 전표 연동 버튼 */
    // const outputStatementPopup = (v) => {
    //     store.popup.summary = v.data.summary;
    //     setReferenceKey(v.data.accountNo);
    //     AppStore.toggleStatementPopup();
    // }

    /* 행 고정 */
    const pinnedTopRowData = [{
        inputAmount: store.inputStatementList.reduce((total, v) => total + v.inputAmount, 0),
        outputAmount: store.inputStatementList.reduce((total, v) => total + v.outputAmount, 0),
        accountAmount: store.inputStatementList.reduce((total, v) => total + v.accountAmount, 0),
        customerName: 'pinnedTopRowData', debitAccount: 'pinnedTopRowData', creditAccount: 'pinnedTopRowData', statementType: 'pinnedTopRowData',
    }];

    return (
        <>
            <StatementSearch getInputStatementList={getInputStatementList} viewMode={viewMode} setViewMode={setViewMode} />
            <CContainer>
            {/* {viewMode === 'input' && */}
                <AgGridContainer
                    gridRef={gridRef}
                    gridTitle={'입금전표등록'}
                    height={58}
                    rowData={store.inputStatementList}
                    columnDefs={inputColumnDefs}
                    isCheckBox={true}
                    useUpdated={true}
                    callBackGridData={callBackGridData}
                    customBtnInfo={[autoSettingBtn]}
                    isCellCustom={true}
                    cellCustomChangeValue={cellCustomChangeValue}
                    pinnedTopRowData={pinnedTopRowData}
                />
            {/* } */}
            {/* {viewMode === 'output' &&
                 <AgGridContainer
                    gridRef={gridRef}
                    gridTitle={'출금전표연동'}
                    height={59}
                    rowData={store.outputStatementList}
                    columnDefs={outputColumnDefs}
                    callBackGridData={callBackGridData}
                />
            } */}
            </CContainer>
            <CustomerSearch callbackFn={searchCustomerInfo} />
            <AccountingCodeSearch callbackFn={searchAccountingCode} inputCode={inputCode} />
            <StatementPopup referenceKey={referenceKey} getInputStatementList={getInputStatementList} />
        </>
    )
}

export default observer(StatementList);
