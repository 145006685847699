 import { useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import {CContainer} from '../../components/CustomContainer';
import AgGridContainer from '../../components/AgGridContainer';
import {AssetStore} from '../../store/asset/AssetStore';
import {AssetSearchStore} from '../../store/asset/AssetSearchStore';
import { dateTimeFormat } from '../../utils/dateUtils';
import LUtils from '../../utils/lodashUtils';
import { getUserName } from '../../utils/userUtils';
import {findCommKrnm} from "../../utils/commcode";
import { AppStore } from "../../store/AppStore";
import AssetDetail from './AssetDetail';
import Modal from '../../components/Modal';
import RepairAssetPopup from './popup/RepairAssetPopup';
import { showToast } from '../../common/utils';
import AssetListSearch from "./AssetListSearch";
import ReleaseAskPopup from './popup/ReleaseAskPopup';

const AssetList = () => {
    const gridRef = useRef();
    const [assetNo, setAssetNo] = useState([]);
    const [checkedAsset, setCheckedAsset] = useState('');
    const [buyCustomerNo, setBuyCustomerNo] = useState('');
    const [assetStatus, setAssetStatus] = useState('');
    const [contractNo, setContractNo] = useState('');

    const getAssetList = async () => {
/*
        const conditionList = Object.keys(AssetSearchStore.assetSearchCondition).map((key) => AssetSearchStore.assetSearchCondition[key]);
        const isNonConditionInput = conditionList.every(v => v === null || v === '' || v === '0');
        if(isNonConditionInput){
            showToast('조회 조건이 입력되지 않아 대분류 선택을 하셔야합니다.');
            return;
        }
*/
        // 자산 번호 리스트
        if(AssetSearchStore.assetSearchCondition.assetNo.indexOf(' ') > -1 || AssetSearchStore.assetSearchCondition.assetNo.indexOf(',') > -1) {
            AssetSearchStore.assetSearchCondition.assetNoList = AssetSearchStore.assetSearchCondition.assetNo.replaceAll(' ', ',');
        }
        // 관리 번호 리스트
        if(AssetSearchStore.assetSearchCondition.managementNo.indexOf(' ') > -1 || AssetSearchStore.assetSearchCondition.managementNo.indexOf(',') > -1) {
            AssetSearchStore.assetSearchCondition.managementNoList = AssetSearchStore.assetSearchCondition.managementNo.replaceAll(' ', ',');
        }
        // 시리얼 번호 리스트
        if(AssetSearchStore.assetSearchCondition.serialNo.indexOf(' ') > -1 || AssetSearchStore.assetSearchCondition.serialNo.indexOf(',') > -1) {
            AssetSearchStore.assetSearchCondition.serialNoList = AssetSearchStore.assetSearchCondition.serialNo.replaceAll(' ', ',');
        }

        const result = await axios.get("/asset/list", { params: AssetSearchStore.assetSearchCondition });
        const originData = result?.map(v => LUtils.assign(v, { isChecked: false }));  // isChecked값을 추가
        AssetStore.setAssetList(originData);
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.');
        }
        // 파라미터 list 초기화
        AssetSearchStore.assetSearchCondition.assetNoList = [];
        AssetSearchStore.assetSearchCondition.managementNoList = [];
        AssetSearchStore.assetSearchCondition.serialNoList = [];
        AssetSearchStore.assetSearchCondition.assetStatusList = [];
    }

    const columnDefs = [
        {field: "productGroupName", headerName: "대분류", minWidth: 100},
        {field: "assetNo", headerName: "자산번호", minWidth: 110},
        {field: "managementNo", headerName: "관리번호", minWidth: 110},
        {field: "assetType", headerName: "자산구분", minWidth: 110, width: 110, valueFormatter: v => findCommKrnm(String(v.value), '7'), hide: true},
        {field: "assetTypeName", headerName: "자산구분", hide: false},
        {field: "assetStatus", headerName: "자산상태", width: 110, valueFormatter: v => findCommKrnm(String(v.value), '8'), hide: true},
        {field: "assetStatusName", headerName: "자산상태", hide: false},
        {field: "productName", headerName: "제품명", minWidth: 140},
        {field: "model", headerName: "모델명", minWidth: 140},
        {field: "serialNo", headerName: "시리얼번호", minWidth: 160},
        {field: "newOrUsed", headerName: "신품/중고", valueFormatter: v => v.value === 'topRow' ? '' : v.value === '2' ? '중고' : '신품', minWidth: 160},
        {field: "assetCertificateFileName", headerName: "안전인증서", minWidth: 120, valueFormatter: v => v.value ? 'Y' : ''},
        {field: "totalRentAmount", headerName: "매출총액", valueFormatter: v => v.value?.toLocaleString(), width: 160, cellClass: 'ag-grid-money-align'},
        {field: "returnRate", headerName: "회수율", valueFormatter: v => v.value ? v.value+'%' : '', cellClass: v => v.value >= 100 ?  'ag-grid-column-complete' : v.value < 50 ?  'ag-grid-column-cancel' :  'ag-grid-column-wait'},
        {field: "operatingRate", headerName: "가동율", valueFormatter: v => v.value ? v.value+'%' : '', cellClass: v => v.value >= 100 ?  'ag-grid-column-complete' : v.value < 50 ?  'ag-grid-column-cancel' :  'ag-grid-column-wait'},
        {field: "contractNo", headerName: "최근계약번호", minWidth: 160},
        {field: "contractStartDate", headerName: "계약개시일", minWidth: 120},
        {field: "lastReleaseDate", headerName: "출고완료일", minWidth: 120},
        {field: "customerName", headerName: "최근 고객명", width: 180},
        {field: "booksPrice", headerName: "장부가", valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', hide: true},
        {field: "totalRetentionDay", headerName: "총 보유일수", width:130},
        {field: "stockRetentionDay", headerName: "미출고일수", minWidth: 140, cellClass: 'ag-grid-column-cancel'},
        {field: "exteriorStatus", headerName: "외관상태", minWidth: 110},
        {field: "tireStatus", headerName: "타이어상태", minWidth: 120},
        {field: "acquAmount", headerName: "취득가", width: 150, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
        {field: "acquDate", headerName: "취득일", minWidth: 120},
        {field: "ifrsCode", headerName: "상각코드", hide: true},
        {field: "sellAmount", headerName: "매각금액", width: 120, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align', hide: AssetSearchStore.isShort},
        {field: "sellDate", headerName: "매각일자", width: 120, hide: AssetSearchStore.isShort},
        {field: "sellType", headerName: "매각구분", width: 120, valueFormatter: v=> findCommKrnm(v.value, '9'), hide: AssetSearchStore.isShort},
        {field: "shelfId", headerName: "선반번호", width: 110, hide: true},
        {field: "maintenanceCycleMonth", headerName: "유지보수주기(월)", valueFormatter: v => v.value ? v.value+'개월' : '', width: 160},
        {field: "howToBuy", headerName: "구입방법", width: 110, hide: AssetSearchStore.isShort},
        {field: "buyCustomerName", headerName: "구입처", width: 140},
        {field: "updater", headerName: "수정자", valueGetter: v => getUserName(v.data.updater), hide: AssetSearchStore.isShort},
        {field: "updatedDate", headerName: "등록일", minWidth: 200, flex:2, valueGetter: v => dateTimeFormat(v.data.updatedDate), hide: AssetSearchStore.isShort},
        {field: "remark", headerName: "비고", width: 200, hide: AssetSearchStore.isShort},
        // {field: "assetNo", headerName: "삭제", minWidth: 90, flex:1, cellRenderer: (e) => (<IconBtn iconClass={ICON_TRASH} onClick={() => removeAsset(e)} />)},
    ];

    /* 바코드 출력 버튼 */
    const customBarcodeBtn = {
        isUsed: true,
        callbackFn:() => printBarcode(),
        icon: 'fi-ss-barcode-read',
        title: '바코드 출력',
        width: 110,
    }

    /* 수리 버튼 */
    const repairAssetBtn = {
        isUsed: true,
        callbackFn:() => openRepairAssetPopup(),
        icon: 'fi-rs-wrench-simple',
        title: '자산 수리',
        width: 100,
    }

    /* 가상입고 버튼 */
    const virtualStorageBtn = {
        isUsed: true,
        callbackFn:() => AssetSearchStore.setIsVirtualStorage(!AssetSearchStore.isVirtualStorage),
        icon: 'fi-rr-screen',
        title: '가상 입고',
        width: 100,
    }

    /* 임차자산 출고 버튼 */
    const releaseReturnOnLeaseBtn = {
        isUsed: true,
        callbackFn:() => releaseReturnOnLease(),
        icon: 'fi-rr-undo',
        title: '임차자산 반납출고',
        width: 160,
    }

    /* 임차자산 반납출고 버튼 callback */
    const releaseReturnOnLease = async () => {
        const selectedList = AssetStore.assetList.filter(v => v.isSelected);
        const filteredTypeList = AssetStore.assetList.filter(v => v.isSelected && String(v.assetType) === '2');
        const filteredLeaseableList = AssetStore.assetList.filter(v => v.isSelected && String(v.assetType) === '2' && v.assetStatus === '200');
        const filteredOnLeaseList = AssetStore.assetList.filter(v => v.isSelected && String(v.assetType) === '2' && v.assetStatus === '302');
        const filteredReleaseCompleteList = AssetStore.assetList.filter(v => v.isSelected && String(v.assetType) === '2' && v.assetStatus === '202');
        const filteredContractList = AssetStore.assetList.filter(v => v.isSelected && String(v.assetType) === '2' && v.assetStatus === '302' && v.contractNo === filteredOnLeaseList[0].contractNo);

        // 선택한 데이터 유, 무 확인
        if(selectedList.length < 1) return showToast('자산을 선택해 주세요.');
        // 선택한 데이터 임차자산 확인
        if(selectedList.length !== filteredTypeList.length) return showToast('임차자산만 가능합니다.');
        // 선택한 데이터 모두 임대가능이거나 모두 임대중인지 확인 + 출고완료
        if(selectedList.length !== filteredLeaseableList.length && selectedList.length !== filteredOnLeaseList.length && selectedList.length !== filteredReleaseCompleteList.length) return showToast('자산 상태가 모두 임대가능이거나 임대중 또는 출과완료여야 합니다.');
        // 선택한 데이터 구매처가 같은지 확인
        if(!selectedList[0].buyCustomerNo || selectedList.every(v => v.buyCustomerNo !== selectedList[0].buyCustomerNo)) return showToast('구매처가 있어야하고, 구매처가 같아야합니다.');
        // 선택한 데이터 구매처가 같은지 확인
        if(selectedList.length === filteredOnLeaseList.length && selectedList.length !== filteredContractList.length) return showToast('계약번호가 같아야합니다.');
        setAssetStatus(selectedList[0].assetStatus ? selectedList[0].assetStatus : '');
        setBuyCustomerNo(selectedList[0].buyCustomerNo ? selectedList[0].buyCustomerNo : '');
        if(selectedList.length === filteredOnLeaseList.length) setContractNo(filteredOnLeaseList[0].contractNo ? filteredOnLeaseList[0].contractNo : '');
        // 계약번호로 청구지 순번, 회수의뢰 조회
        AppStore.toggleReleaseAskPopup();

    }

    const openRepairAssetPopup = (data) => {
        const status = ['100', '101', '102', '103', '200'];
        const assetList = AssetStore.assetList.filter(v => v.isSelected && status.includes(v.assetStatus)).map(v => {return {assetNo : v.assetNo}});
        setCheckedAsset(assetList);

        if(assetList.length === 0) { showToast('수리 가능 자산이 없습니다.'); return; }
        AppStore.toggleRepairAssetPopup();
    }

    const printBarcode = async() => {

    }

    /* 자산 상세 */
    const openAssetDetail = (v) => {
        setAssetNo(v.data.assetNo);
        AppStore.toggleModal();
    }

    /* 팝업 닫을 때 자산상세 초기화 */
    const closedModal = () => {
        setAssetNo('');
        AssetStore.init();
    };

    const assetRepairClose =async()=> {
        AppStore.toggleRepairAssetPopup();
        await getAssetList();
    }

    /* 행 고정 */
    const pinnedTopRowData = [{
        assetNo: '', assetType: '', conditionStatus: '', workStatus: '', assetStatus: '', companyCode: '', teamCode: '', contractNo: '', productSeq: '', serialNo: '',
        totalRentAmount: AssetStore.assetList.reduce((total, v) => total + v.totalRentAmount, 0),
        returnRate: Math.floor((AssetStore.assetList.reduce((total, v) => total + v.totalRentAmount, 0) / AssetStore.assetList.reduce((total, v) => total + v.acquAmount, 0)) * 100),
        operatingRate: Math.floor(AssetStore.assetList.reduce((total, v) => total + v.operatingRate, 0) / AssetStore.assetList.filter(v => v.operatingRate).length),
        lastReleaseDate: '', booksPrice: '', totalRetentionDay: '', stockRetentionDay: '', acquAmount: AssetStore.assetList.reduce((total, v) => total + v.acquAmount, 0),
        acquDate: '', ifrsCode: '', sellAmount: '', sellDate: '', sellType: '', shelfId: '', howToBuy: '', buyCustomerName: '', updater: '', updatedDate: '', remark: '', newOrUsed: 'topRow',}];

    return (
        <>
            {/* 검색조건 */}
            <AssetListSearch getAssetList={getAssetList}/>
            <CContainer>
                {/* grid */}
                <AgGridContainer
                    gridTitle={'자산 목록'}
                    gridRef={gridRef}
                    height={60}
                    rowData={AssetStore.assetList}
                    columnDefs={columnDefs}
                    seqColumn={'assetNo'}
                    useCsvDownload={true}
                    pinnedTopRowData={pinnedTopRowData}
                    rowDoubleClickCallback={openAssetDetail}
                    isCheckBox={true}
                    customBtnInfo={[virtualStorageBtn, releaseReturnOnLeaseBtn]}
                />
            </CContainer>
            <Modal title={'자산상세'} onExit={closedModal}>
                    <AssetDetail assetNo={assetNo} />
            </Modal>
            <RepairAssetPopup checkedAsset={checkedAsset} callBackFn={assetRepairClose}/>

            <ReleaseAskPopup callBackFn={()=> getAssetList()} customerNo={buyCustomerNo} setCustomerNo={setBuyCustomerNo} assetStatus={assetStatus} contractNo={contractNo} />

        </>
    );
};

export default observer(AssetList);
