import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const OrderStore = observable({
    orderList: [],
    orderSearchCondition: {orderSeq: '', orderType: '', startDate: '', endDate: '', approvalNo: '', orderUserSeq: null, orderUserName: '', isAvailableStorage: true},

    setOrderList(v) {
        this.orderList = v;
    },
    setOrderSearchCondition(v) {
        LUtils.assign(this.orderSearchCondition, v);
    },
});
