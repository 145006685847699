import { observable } from "mobx";

export const AuthorityStore = observable({
    mode: 'auth',
    //mdCategoryList: [],
    //smCategoryList: [],
    authorityList: [],
    mdAuthorityList: [],
    smAuthorityList: [],
    detailAuthorityList: [],
    orgMdAuthorityList: [],
    orgSmAuthorityList: [],
    orgDetailAuthorityList: [],
    authorityGroupList: [],
    groupList: [],
    groupAuthDetailList: [],
    searchGroupCode: '',
    searchGroupName: '',

    setMode(v){
        this.mode = v;
    },
    setMdAuthorityList(v) {
        this.mdAuthorityList = v;
    },
    setSmAuthorityList(v) {
        this.smAuthorityList = v;
    },
    setDetailAuthorityList(v){
        this.detailAuthorityList = v;
    },
    setAuthorityList(v) {
        this.authorityList = v;
    },
    setAuthorityGroupList(v) {
        this.authorityGroupList = v;
    },
    setGroupList(v) {
        this.groupList = v;
    },
    setSearchGroupCode(v){
        this.searchGroupCode = v;
    },
    setSearchGroupName(v){
        this.searchGroupName = v;
    },
    setOrgMdAuthorityList(v){
        this.orgMdAuthorityList = v;
    },
    setOrgSmAuthorityList(v){
        this.orgSmAuthorityList = v;
    },
    setOrgDetailAuthorityList(v){
        this.orgDetailAuthorityList = v;
    },
    setGroupAuthDetailList(v){
        this.groupAuthDetailList = v;
    },
    setAuthorityGroupPush(v){
        this.authorityGroupList.push(v);
    }
});
