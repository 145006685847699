import { RouterProvider } from "react-router-dom";
import IndexRoutes from './routes';
import axiosSetup from './utils/axiosSetup';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { initDayjs } from "./utils/dateUtils";
import { configure } from "mobx"
import AsyncErrorBoundary from './components/errorHandler/AsyncErrorBoundary';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'react-alert-confirm/lib/style.css'; // alert && confirm styles
import 'react-toastify/dist/ReactToastify.css';

const routes = IndexRoutes()

function App() {
  /* axios setup */
  axiosSetup();

  /* dayjs setup */
  initDayjs();

  /* mobx 데이터 변경을 action으로 강제하는 경고 끔 */
  configure({
    enforceActions: "never",
  })

  return (
      <ProSidebarProvider>
        <RouterProvider router={routes} />
      </ProSidebarProvider>
  );
}

export default App;
