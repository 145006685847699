import {useEffect} from 'react';
import { observer } from "mobx-react-lite";
import {CContainer, CCol, CInputGroup, CSearchBtn, CalendarGroup, CIconBtn} from "../../components/CustomContainer";
import {Row, Col, Form} from "react-bootstrap";
import { AppStore } from "../../store/AppStore";
import UserSearch from "../../components/searchModal/UserSearch";
import {ICON_ADD} from "../../common/constants";
import {SiteInfoStore} from "../../store/customer/SiteInfoStore";
import { useLocation } from "react-router-dom";
import LUtils from "../../utils/lodashUtils";
import {startDateForMonth, endDateForMonth} from '../../utils/dateUtils';

const SiteInfoSearch = ({getSiteInfoList}) => {
    
    const location = useLocation();

    useEffect(() => {
        const fromPage = LUtils.get(location.state, 'from', null) ;
        if(fromPage === 'dashboard'){
            SiteInfoStore.setStartDate({startDate: startDateForMonth(), endDate: null});
            SiteInfoStore.setEndDate({startDate: endDateForMonth(), endDate: null});

            getSiteInfoList()
        }
    }, []);

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = () => {
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        if (!userInfo) {
            return false;
        }

        SiteInfoStore.setSearchCondition(userInfo.name, 'userName');
        SiteInfoStore.setSearchCondition(userInfo.userSeq, 'userSeq');
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'현장명'}
                            value={SiteInfoStore.searchCondition.siteName}
                            onChange={(v)=> SiteInfoStore.setSearchCondition(v.target.value, 'siteName')}
                            enterKeyHint={getSiteInfoList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'담당자명'}
                            value={SiteInfoStore.searchCondition.managerName}
                            onChange={(v)=> SiteInfoStore.setSearchCondition(v.target.value, 'managerName')}
                            enterKeyHint={getSiteInfoList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'담당자 전화번호'}
                            value={SiteInfoStore.searchCondition.managerPhoneNumber}
                            onChange={(v)=> SiteInfoStore.setSearchCondition(v.target.value, 'managerPhoneNumber')}
                            enterKeyHint={getSiteInfoList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'작성일'}
                            value={SiteInfoStore.startDate}
                            onChange={v => SiteInfoStore.setStartDate(v)}
                        />
                    </CCol>
                    ~
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'작성일'}
                            minDate={SiteInfoStore.startDate.startDate}
                            value={SiteInfoStore.endDate}
                            onChange={v => SiteInfoStore.setEndDate(v)}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'작성자'}
                            value={SiteInfoStore.searchCondition.userName}
                            onChange={(v)=> SiteInfoStore.setSearchCondition(v.target.value, 'userName')}
                            onCallbackBtn={() => openSearchUserPopup('saleUser')}
                            enterKeyHint={getSiteInfoList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"   /*type="checkbox"*/
                            id="exceptDel"
                            label="삭제건 제외"
                            style={{marginTop: 3}}
                            checked={SiteInfoStore.searchCondition.isDeleted === 1}
                            value={SiteInfoStore.searchCondition.isDeleted === 1}
                            onChange={v=> SiteInfoStore.setSearchCondition(v.target.checked ? 1 : 0, 'isDeleted')}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getSiteInfoList} />
                        <CIconBtn style={{width: 120}}
                                  title={'신규 현장 등록'}
                                  icon={ICON_ADD}
                                  onClick={()=> AppStore.toggleSiteDetail()}
                        />
                    </Col>
                </Row>
            </CContainer>
            <UserSearch callbackFn={getUserInfo} />
        </>
    );
}

export default observer(SiteInfoSearch);
