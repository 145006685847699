import { observer } from "mobx-react-lite"
import { useRef } from "react";

import { CContainer, CCol } from "../../../components/CustomContainer";
import Modal from "../../../components/Modal";
import { AppStore } from "../../../store/AppStore";
import AgGridContainer from "../../../components/AgGridContainer";
import { BillSearchStore } from "../../../store/billing/BillSearchStore";
import { decrypt } from "../../../common/utils";
import { ICON_ADD } from "../../../common/constants";

const SelectEmailPopup = ({gridRef}) => {
    const mailGridRef = useRef();
    const store = BillSearchStore;

    const refresh = () => {
        store.emailList[store.emailBillNo].filter(v => v.isSelected).map(v => v.isSelected=false);
    }

    const onRowDoubleClicked = (e) => {
        const email = decrypt(e.data.email);
        const { title, name, agId } = e.data; 
        const rowData = { title, name, email, seq: agId };

        if (!Array.isArray(store.addEmailList[store.emailBillNo])) {
            store.addEmailList[store.emailBillNo] = [];
        }
        const isDuplicate = store.addEmailList[store.emailBillNo] && store.addEmailList[store.emailBillNo].some(v => v.seq === agId);
    
        // 중복되지 않으면 추가
        if (!isDuplicate) {
            store.addEmailList[store.emailBillNo].push(rowData);
        }
        gridRef.current.api.redrawRows();
        AppStore.toggleSelectEmailPopup();
    }

    const addBtn = {
        isUsed: true,
        title: '선택 추가',
        callbackFn: (e) => addEmailBtn(e),
        icon: ICON_ADD,
    };

    const addEmailBtn = (e) => {
        if(store.emailBillNo) {
            if (!Array.isArray(store.addEmailList[store.emailBillNo])) {
                store.addEmailList[store.emailBillNo] = [];
            }
            const filteredRows = store.emailList[store.emailBillNo].filter(row => {
                if (row.isSelected) {
                    const seqList = store.addEmailList[store.emailBillNo].map(v => v.seq);
                    return !seqList.includes(row.agId);
                }
                return false;
            });
            
            store.addEmailList[store.emailBillNo].push(...filteredRows.map(item => ({...item, seq: item.agId, email: decrypt(item.email)})));
        }
        gridRef.current.api.redrawRows();
        AppStore.toggleSelectEmailPopup();
    }

    return (
        <>
            <Modal title={'추가 메일 목록'}
                onHide={() => AppStore.toggleSelectEmailPopup()}
                toggleValue={AppStore.isOpenSelectEmailPopup}
                onExit={refresh}
                size={'md'}
            >
                <CContainer paddingTop={'p-0'}>
                    <CCol lg={12}>
                        <AgGridContainer
                            gridRef={mailGridRef}
                            rowData={store.emailList[store.emailBillNo]}
                            height={14}
                            columnDefs={[
                                {field: "title", headerName: "구분", width: 120},
                                {field: "name", headerName: "담당자명", width: 100},
                                {field: "email", headerName: "메일주소", valueFormatter: v => v.value ? decrypt(v.value) : '', width: 180},
                            ]}
                            isCheckBox={true}
                            rowDoubleClickCallback={e => onRowDoubleClicked(e)}
                            customBtnInfo={[addBtn]}
                        />
                    </CCol>
                </CContainer>
            </Modal>
        </>
    );
}

export default observer(SelectEmailPopup);
