import { observer } from "mobx-react-lite"
import Modal from "../../../components/Modal";
import { CCol, CContainer, CIconBtn, CInputGroup, Subject } from "../../../components/CustomContainer";
import { Col, Form, Row } from "react-bootstrap";
import { AppStore } from "../../../store/AppStore";
import { useEffect, useRef, useState } from "react";
import { NonpaymentStore } from "../../../store/billing/NonpaymentStore";
import { findCommKrnm } from "../../../utils/commcode";
import axios from "axios";
import { decrypt, encrypt, showToast } from "../../../common/utils";
import LUtils from "../../../utils/lodashUtils";
import { callConfirm } from "../../../utils";
import AgGridContainer from "../../../components/AgGridContainer";

const SendNonPaymentSmsPopup = ({getSmsHistoryList}) => {
    const gridRef = useRef();
    const receiverGridRef = useRef();
    const [templateType, setTemplateType] = useState(1);
    const [receiverName, setReceiverName] = useState('');
    const [receiverPhoneNumber, setReceiverPhoneNumber] = useState('');

    useEffect(() => {
        getBillingAddressList();
    }, [])
    

    const getBillingAddressList = async() => {
        const result = await axios.get("/nonpayment/billingAddress/list", {params: {customerNo: NonpaymentStore.data.customerNo,
                                                                                isActive: 1}});
        NonpaymentStore.setBillingAddressList(result.map(v => {return {...v, isCreated: false}}));
    }

    const refresh = () => {
        setTemplateType(1);
        setReceiverName('');
        setReceiverPhoneNumber('');
        NonpaymentStore.setBillingAddressList(NonpaymentStore.billingAddressList.filter(v => !v.isCreated).map(v => {return {...v, isSelected: false}}));
    }

    const sendNonpaymentSms = async () => {

        if (!await callConfirm('문자를 전송하시겠습니까?')) {
            return;
        }

        let phoneNumberList = [];
        let phoneNumberStatus = true;

        NonpaymentStore.billingAddressList.filter(v => v.isSelected).map((v, i) => {
            phoneNumberList.push({name: v.managerName, phoneNumber: v.phoneNumber});
            if(v.phoneNumber !== null && v.phoneNumber !== '') {
                phoneNumberStatus = true;
            } else {
                phoneNumberStatus = false;
            }
        })

        if (!phoneNumberStatus) {
            showToast('청구지의 핸드폰주소가 존재하지 않습니다.');
            return;
        }

        const result = await axios.post(`/nonpayment/send/sms/${templateType}`, {customerNo: NonpaymentStore.data.customerNo,
                                                                                 customerName: NonpaymentStore.data.customerName,
                                                                                 dueNonpaymentDate: NonpaymentStore.data.maxDueNonpaymentDate,
                                                                                 amount: NonpaymentStore.data.totalNonpaymentAmount.toLocaleString(),
                                                                                 bankName: findCommKrnm(NonpaymentStore.bankCode, '24'),
                                                                                 accountNumber: NonpaymentStore.accountNumber,
                                                                                 phoneNumberList: phoneNumberList});

        showToast('문자가 전송되었습니다.');
        AppStore.toggleSendNonPaymentSmsPopup();
        getSmsHistoryList();
    }

    const getCheckboxClickEvent = (e) => {
        NonpaymentStore.setReceiverList([]);
        const list = NonpaymentStore.billingAddressList.filter(v => v.isSelected).map(v => {return {...v, isCreated: true}});
        NonpaymentStore.setReceiverList(list);
        receiverGridRef.current.api.redrawRows();
    }

    const addReceiver = () => {
        if (receiverName === '' || receiverPhoneNumber === '') {
            showToast('수신자명과 핸드폰 번호를 모두 작성해주세요.');
            return;
        }

        NonpaymentStore.addBillingAddressList({addRowId: NonpaymentStore.billingAddressList.length + 1, billingAddressSeq: '추가', managerName: receiverName, phoneNumber: encrypt(receiverPhoneNumber), isSelected: true, isCreated: true});
        setReceiverName('');
        setReceiverPhoneNumber('');
    }


    return (
        <Modal toggleValue={AppStore.isOpenSendNonPaymentSmsPopup}
                onHide={()=> AppStore.toggleSendNonPaymentSmsPopup()}
                title={'문자 발송'}
                onExit={refresh}>
            <div style={{textAlign: 'center'}}>
                {/* <span style={{fontWeight: 'bold', color: 'blue'}}>전송하실 알림톡 내용을 선택해주세요.</span> */}
            </div>
            <CContainer search >
            {/* <div style={{backgroundColor: '#A3BAD1', width: '80%', paddingTop: 30, paddingBottom: 30, paddingLeft: 20, paddingRight: 20, margin: 'auto'}}>
                <div style={{backgroundColor: 'white'}}>
                    <h4 style={{backgroundColor: '#FDE60B', padding: 10, textAlign: 'center'}}>미납 문자 - 1</h4>
                    <div style={{padding: 10}}>
                        <p className="fs-6">
                        {templateType === 1 && 
                            <>
                                {NonpaymentStore.data.customerName}님 렌탈료가 납기일 기준 OO일 연체 되었습니다.
                                <br/>
                                조속히 납부 바랍니다.<br/><br/>
                                미납금액 : {NonpaymentStore.data.totalNonpaymentAmount?.toLocaleString()}원
                                <br/>
                                입금계좌 : OO은행 xxx-xxx-xxxxxxx
                            </>
                        }
                        </p>
                        {templateType === 2 && 
                            <>
                                {NonpaymentStore.data.customerName}님 렌탈료가 납기일 기준 OO일 연체 되었습니다.
                                <br /><br />
                                빠른시일 내 납부되지 않을 경우, 신용정보공통관리규약에 의거하여 채무불이행정보(신용불량)로 등록되어 금융거래 등 불이익을 받을 수 있습니다.
                                <br/><br />
                                조속히 납부 바랍니다.
                                <br /><br />
                                미납금액 : {NonpaymentStore.data.totalNonpaymentAmount?.toLocaleString()}원
                                <br />
                                입금계좌 : OO은행 xxx-xxx-xxxxxxx
                            </>
                        }
                        {templateType === 3 && 
                            <>
                                {NonpaymentStore.data.customerName}님 렌탈료가 납기일 기준 OO일 연체 되었습니다.
                                <br /><br />
                                납부되지 않을 경우 정해진 법적절차에 따라(형사고소,민사소송 등) 진행되오며 신용정보공통관리규약에 의거하여 채무불이행정보(신용불량)로 등록되어 금융거래 등 불이익을 받을 수 있습니다.
                                <br/><br />
                                조속히 납부 바랍니다.
                                <br /><br />
                                미납금액 : {NonpaymentStore.data.totalNonpaymentAmount?.toLocaleString()}원
                                <br />
                                입금계좌 : OO은행 xxx-xxx-xxxxxxx
                            </>
                        }
                    </div>
                </div>
            </div> */}
            <Row>
                <CCol lg={4}>
                    <div className="btn-group btn-group-toggle mb-4" style={{display: 'flex', justifyContent: 'center'}} >
                        <label style={{margin: 'auto-flex', textAlign: 'center', verticalAlign: 'middle'}}>
                            <Form.Check
                                type="radio" value={true}
                                checked={templateType === 1}
                                onChange={v => setTemplateType(1)}
                                style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10, marginBottom: 10}}
                                disabled={false}
                            />
                            <div className="" style={{backgroundColor: '#A3BAD1', textAlign: 'left', width: '90%', marginTop: 10, paddingTop: 30, paddingBottom: 30, paddingLeft: 20, paddingRight: 20, margin: 'auto', marginBottom: 10}}>
                                <div style={{backgroundColor: 'white'}}>
                                    <h4 style={{backgroundColor: '#FDE60B', padding: 10, textAlign: 'center'}}>미납 문자 - 1</h4>
                                    <div style={{padding: 10}}>
                                        <p className="fs-6">
                                            {NonpaymentStore.data.customerName}님 렌탈료가 납기일 기준 {NonpaymentStore.data.maxDueNonpaymentDate}일 연체 되었습니다.
                                            <br/>
                                            조속히 납부 바랍니다.<br/><br/>
                                            미납금액 : {NonpaymentStore.data.totalNonpaymentAmount?.toLocaleString()}원
                                            <br/>
                                            입금계좌 : {findCommKrnm(NonpaymentStore.bankCode, '24')} {NonpaymentStore.accountNumber}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                </CCol>
                <CCol lg={4}>
                    <div className="btn-group btn-group-toggle mb-4" style={{display: 'flex', justifyContent: 'center'}} >
                        <label style={{margin: 'auto-flex', textAlign: 'center', verticalAlign: 'middle'}}>
                            <Form.Check
                                type="radio" value={true}
                                checked={templateType === 2}
                                onChange={v => setTemplateType(2)}
                                style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10, marginBottom: 10}}
                                disabled={false}
                            />
                            <div style={{backgroundColor: '#A3BAD1', textAlign: 'left', width: '90%', marginTop: 10, paddingTop: 30, paddingBottom: 30, paddingLeft: 20, paddingRight: 20, margin: 'auto', marginBottom: 10}}>
                                <div style={{backgroundColor: 'white'}}>
                                    <h4 style={{backgroundColor: '#FDE60B', padding: 10, textAlign: 'center'}}>미납 문자 - 2</h4>
                                    <div style={{padding: 10}}>
                                        <p className="fs-6">
                                            {NonpaymentStore.data.customerName}님 렌탈료가 납기일 기준 {NonpaymentStore.data.maxDueNonpaymentDate}일 연체 되었습니다.
                                            <br /><br />
                                            빠른시일 내 납부되지 않을 경우, 신용정보공통관리규약에 의거하여 채무불이행정보(신용불량)로 등록되어 금융거래 등 불이익을 받을 수 있습니다.
                                            <br/><br />
                                            조속히 납부 바랍니다.
                                            <br /><br />
                                            미납금액 : {NonpaymentStore.data.totalNonpaymentAmount?.toLocaleString()}원
                                            <br />
                                            입금계좌 : {findCommKrnm(NonpaymentStore.bankCode, '24')} {NonpaymentStore.accountNumber}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                </CCol>
                <CCol lg={4}>
                    <div className="btn-group btn-group-toggle mb-4" style={{display: 'flex', justifyContent: 'center'}} >
                        <label style={{margin: 'auto-flex', textAlign: 'center', verticalAlign: 'middle'}}>
                            <Form.Check
                                type="radio" value={true}
                                checked={templateType === 3}
                                onChange={v => setTemplateType(3)}
                                style={{verticalAlign: 'middle', marginRight: 10, marginLeft: 10, marginBottom: 10}}
                                disabled={false}
                            />
                            <div style={{backgroundColor: '#A3BAD1', textAlign: 'left', width: '90%', marginTop: 10, paddingTop: 30, paddingBottom: 30, paddingLeft: 20, paddingRight: 20, margin: 'auto', marginBottom: 10}}>
                                <div style={{backgroundColor: 'white'}}>
                                    <h4 style={{backgroundColor: '#FDE60B', padding: 10, textAlign: 'center'}}>미납 문자 - 3</h4>
                                    <div style={{padding: 10}}>
                                        <p className="fs-6">
                                            {NonpaymentStore.data.customerName}님 렌탈료가 납기일 기준 {NonpaymentStore.data.maxDueNonpaymentDate}일 연체 되었습니다.
                                            <br /><br />
                                            납부되지 않을 경우 정해진 법적절차에 따라(형사고소,민사소송 등) 진행되오며 신용정보공통관리규약에 의거하여 채무불이행정보(신용불량)로 등록되어 금융거래 등 불이익을 받을 수 있습니다.
                                            <br/><br />
                                            조속히 납부 바랍니다.
                                            <br /><br />
                                            미납금액 : {NonpaymentStore.data.totalNonpaymentAmount?.toLocaleString()}원
                                            <br />
                                            입금계좌 : {findCommKrnm(NonpaymentStore.bankCode, '24')} {NonpaymentStore.accountNumber}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                </CCol>
            </Row>
        </CContainer>
        <CContainer>
            <Row>
                <CCol lg={12}>
                    <AgGridContainer
                        gridRef={gridRef} 
                        gridTitle={'고객 청구지 목록'}
                        rowData={NonpaymentStore.billingAddressList}
                        height={20}
                        columnDefs={[
                            {field: "billingAddressSeq", headerName: "순번", width: 75},
                            {field: "billingAddressName", headerName: "청구지명", width: 140},
                            {field: "managerName", headerName: "담당자명", width: 130},
                            {field: "phoneNumber", headerName: "핸드폰번호", flex:1,  width: 140, valueFormatter: v => v.value ? decrypt(v.value) : ""},
                            {field: "email", headerName: "이메일", width: 140, valueFormatter: v => v.value ? decrypt(v.value) : "", hide: true},
                        ]}
                        isCheckBox={true}
                    />
                </CCol>
                {/* <CCol lg={6}>
                    <AgGridContainer
                        gridRef={receiverGridRef}
                        gridTitle={'수신자 목록'}
                        rowData={NonpaymentStore.receiverList}
                        height={15}
                        columnDefs={[
                            {field: "billingAddressSeq", headerName: "순번", width: 75},
                            {field: "billingAddressName", headerName: "청구지명", width: 140},
                            {field: "managerName", headerName: "수신자", width: 120},
                            {field: "phoneNumber", headerName: "핸드폰번호", width: 140, valueFormatter: v => v.value ? decrypt(v.value) : ""},
                            {field: "email", headerName: "이메일", flex:1,  width: 140, valueFormatter: v => v.value ? decrypt(v.value) : "", hide: true},
                        ]}
                        isCheckBox={true}
                    />
                    <Row style={{marginTop: 10}}>
                        <CCol lg={4}>
                            <CInputGroup
                                label={'수신자명'}
                                labelId={'receiverName'}
                                value={receiverName}
                                onChange={v => setReceiverName(v.target.value)}
                                placeholder={'ex. 홍길동'}
                            />
                        </CCol>
                        <CCol lg={5}>
                            <CInputGroup
                                label={'수신자번호'}
                                labelId={'receiverPhoneNumber'}
                                value={receiverPhoneNumber}
                                onChange={v => setReceiverPhoneNumber(v.target.value)}
                                placeholder={'ex. 01012345678'}
                            />
                        </CCol>
                        <CCol lg={3} style={{textAlign: 'left'}}>
                            <CIconBtn style={{width: 108}}
                                title={'추가'}
                                onClick={addReceiver}
                                variant={'primary'}
                            />
                        </CCol>
                    </Row>
                </CCol> */}
            </Row>
            <Subject>수신인 추가</Subject>
            <Row>
                <CCol lg={5}>
                    <CInputGroup
                        label={'수신자명'}
                        labelId={'receiverName'}
                        value={receiverName}
                        onChange={v => setReceiverName(v.target.value)}
                        placeholder={'ex. 홍길동'}
                        enterKeyHint={addReceiver}
                        labelClassName={'input-required'}
                    />
                </CCol>
                <CCol lg={5}>
                    <CInputGroup
                        label={'휴대폰번호'}
                        labelId={'receiverPhoneNumber'}
                        value={receiverPhoneNumber}
                        onChange={v => setReceiverPhoneNumber(v.target.value)}
                        placeholder={'ex. 01012345678'}
                        enterKeyHint={addReceiver}
                        labelClassName={'input-required'}
                    />
                </CCol>
                <CCol lg={2} style={{textAlign: 'center', verticalAlign: 'middle'}}>
                    <CIconBtn style={{width: 110, height: 30}}
                        title={'추가'}
                        onClick={addReceiver}
                        variant={'success'}
                    />
                </CCol>
            </Row>
            <Row>
                <Col lg={12} className='d-flex mt-3' style={{justifyContent: 'center'}}>
                    <CIconBtn style={{width: 120}}
                            title={'알림톡 전송'}
                            onClick={sendNonpaymentSms}
                            variant={'primary'}
                    />
                </Col>
            </Row>
            </CContainer>
        </Modal>
    )

}

export default observer(SendNonPaymentSmsPopup);