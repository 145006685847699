import { observer } from "mobx-react-lite"
import { CContainer, CCol, CalendarGroup, CSearchBtn, CInputGroup, CIconBtn, CSelectGroup } from "../../components/CustomContainer";
import { Row, Col, Form } from "react-bootstrap";
import { PurchaseSalesStatementStore } from "../../store/accounting/PurchaseSalesStatementStore";
import { AppStore } from "../../store/AppStore";
import { showToast } from "../../common/utils";
import CreatePurchaseSalesStatementPopup from "./popup/CreatePurchaseSalesStatementPopup";
import Modal from "../../components/Modal";
import { dateFormat } from "../../utils/dateUtils";
import { getCommcode } from "../../utils/commcode";

const PurchaseSalesStatementSearch = ({selView, setSelView, getPurchaseSalesList}) => {

    /* 날짜 선택 */
    const onChangeSearchValue = async (v, label) => {
        const { startDate, endDate } = v;
        const storeStartDate = new Date(PurchaseSalesStatementStore.search.startDate);
        const storeEndDate = new Date(PurchaseSalesStatementStore.search.endDate);
        const selectedDate = new Date(startDate);

        if (label === 'startDate' && storeEndDate < selectedDate) {
            showToast('종료일보다 작게 선택해주세요.');
        } else if (label === 'endDate' && endDate !== null && storeStartDate > selectedDate) {
            showToast('시작일보다 크게 선택해주세요.');
        } else {
            PurchaseSalesStatementStore.setSearch({ [label]: dateFormat(selectedDate) });
        }
    };

    /* 전표 생성 팝업 */
    const openCreatePurchaseSalesStatementPopup = () => {
        AppStore.toggleModal();
    }

    const closeModal = () => {
        if (selView === 'sales') {
            PurchaseSalesStatementStore.salesList = [];
        } else {
            PurchaseSalesStatementStore.purchaseList = [];
        }
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={12}>
                        <ul className="nav nav-pills pt-3 pb-3" style={{borderTopWidth: 0, borderBottomWidth: 1, borderColor: '#f2f2f2'}}>
                            <li className="nav-item" style={{borderRadius: 4, boxShadow: 'rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px'}}>
                                <a className={selView === 'purchase' ? "nav-link active" : "nav-link"}
                                    style={{cursor: "pointer"}}
                                    onClick={()=> setSelView('purchase')}>매입장</a>
                            </li>
                            <li className="nav-item" style={{marginLeft: 10, borderRadius: 4, boxShadow: 'rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px'}}>
                                <a className={selView === 'sales' ? "nav-link active" : "nav-link"}
                                    style={{cursor: "pointer"}}
                                    onClick={()=> setSelView('sales')}>매출장</a>
                            </li>
                        </ul>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <CalendarGroup asSingle={true} label={'결의일자 시작일'}
                                    value={{
                                            startDate: PurchaseSalesStatementStore.search.startDate,
                                            endDate: PurchaseSalesStatementStore.search.startDate
                                    }}
                                    onChange={v => onChangeSearchValue(v, 'startDate')}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CalendarGroup asSingle={true} label={'결의일자 종료일'}
                                    value={{
                                            startDate: PurchaseSalesStatementStore.search.endDate,
                                            endDate: PurchaseSalesStatementStore.search.endDate
                                    }}
                                    onChange={v => onChangeSearchValue(v, 'endDate')}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'거래처명'} value={PurchaseSalesStatementStore.search.customerName} onChange={(v)=> PurchaseSalesStatementStore.search.customerName = v.target.value} enterKeyHint={getPurchaseSalesList} />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'사업자번호'} value={PurchaseSalesStatementStore.search.businessNumber} onChange={e => PurchaseSalesStatementStore.search.businessNumber = e.target.value} enterKeyHint={getPurchaseSalesList} />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup label={'과세유형'} labelId={'taxType'}
                                    placeholder={'과세유형'}
                                    options={[{name: '전체', value: ''}].concat(getCommcode('45'))}
                                    value={PurchaseSalesStatementStore.search.taxType}
                                    onChange={v => PurchaseSalesStatementStore.search.taxType = v.target.value}
                        />
                </CCol>
                </Row>
                {/* <Row>
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isCard"
                            label={selView === 'purchase' ? '카드매입' : '카드매출'}
                            style={{marginBottom: 8}}
                            checked={!!PurchaseSalesStatementStore.search.isCard}
                            onChange={v => PurchaseSalesStatementStore.search.isCard = v.target.checked ? true : false}
                        />
                    </CCol>
                </Row> */}
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getPurchaseSalesList} />
                        <CIconBtn style={{width: 100}}
                            title={'전표생성'}
                            icon={'fi-rr-add-document'}
                            onClick={() => openCreatePurchaseSalesStatementPopup()}
                            variant={'secondary'}
                        />                            
                    </Col>
                </Row>
            </CContainer>
            <Modal title={'전표등록'} onExit={closeModal}>
                <CreatePurchaseSalesStatementPopup 
                    selView={selView} getPurchaseSalesList={getPurchaseSalesList}
                />
            </Modal>
        </>
    )
}

export default observer(PurchaseSalesStatementSearch);
