import React, {useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {CContainer} from "../../components/CustomContainer";
import Modal from "../../components/Modal";
import AgGridContainer from "../../components/AgGridContainer";
import AssetReservation from "./AssetReservation";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import ProductListSearch from "../../components/searchModal/ProductListSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import axios from "axios";
import {callConfirm} from "../../utils";
import {AssetReservationStore} from "../../store/asset/assetReservationStore";
import AssetReservationListSearch from "./AssetReservationListSearch";
import {showToast} from "../../common/utils";
import {AppStore} from "../../store/AppStore";
import ReserveDetailPopup from "./popup/ReserveDetailPopup";

const AssetReservationList =()=> {
    const gridRef = useRef();
    const [reserveNo, setReserveNo] = useState('');

    const searchCustomerInfo =(data,col)=> {
        if(data){
            AssetReservationStore.search.customerNo = data.customerNo;
            AssetReservationStore.search.customerName = data.customerName;
        }
    }

    const searchProductInfo =(data,col)=> {
        if(data){
            AssetReservationStore.search.productSeq = data.productSeq;
            AssetReservationStore.search.productName = data.productName;
        }
    }

    const searchUserInfo =(data,col)=> {
        if(data){
            AssetReservationStore.search.userSeq = data.userSeq;
            AssetReservationStore.search.userName = data.name;
        }
    }

    const searchReservation =async()=> {
        const result = await axios.get('/asset/reservation/list', {params: AssetReservationStore.search});
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.');
        }
        AssetReservationStore.setReservationList(result);
    }

    const cancelReservation =async()=> {
        const reservationList = AssetReservationStore.reservationList.filter(v => v.isSelected);
        if(reservationList.length === 0){
            showToast('예약을 선택해 주세요.');
            return;
        }

        if(reservationList.some(v => v.reserveStatus === '취소')){
            showToast('취소된 예약이 선택되었습니다.');
            return;
        }

        //선택한 예약번호 가져오기
        const reserveNoList = reservationList.map(v => {return v.reserveNo});

        if(!await callConfirm('취소하시겠습니까?')){
            return;
        }

        const success = await axios.put('/asset/reservation/cancel', reserveNoList);
        showToast('취소되었습니다.');
        //재조회
        await searchReservation();
    }

    const openReserveDetail = (v) => {
        setReserveNo(v.data.reserveNo);
        AppStore.toggleReserveDetailPopup();
    }

    return(
        <>
            {/* content header */}
            {/* 검색조건 */}
            <AssetReservationListSearch searchReservation={searchReservation}/>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={50}
                    rowData={AssetReservationStore.reservationList}
                    columnDefs={[
                        {field: "reserveNo", headerName: "예약번호", width: 170},
                        {field: "reserveStatus", headerName: "예약상태", width: 110,
                            cellStyle: params => {
                                if(params.node.data.reserveStatus === '취소'){
                                    return { color: 'red' };
                                }else{
                                    return null;
                                }
                            },
                        },   /* 1:예약중, 2:계약완료, 8:예약자동취소, 9:예약취소 */
                        {field: "reserveStartDate", headerName: "예약시작일", width: 120},
                        {field: "reserveEndDate", headerName: "예약종료일", width: 120},
                        {field: "productName", headerName: "예약 제품", width: 350},
                        {field: "customerName", headerName: "고객명", width: 200},
                        {field: "reserveUser", headerName: "예약자", width:100},
                        {field: "reserveDate", headerName: "예약일자", width:140},
                    ]}
                    seqColumn={'reserveNo'}
                    isCheckBox={true}
                    useIntoTheTab={true}
                    useCsvDownload={true}
                    gridTitle={'예약 리스트'}
                    customBtnInfo={[
                        {
                            isUsed: true,
                            callbackFn: () => cancelReservation(),
                            title: '예약취소',
                            icon: 'fi-br-trash',
                            color: 'red',
                            width: 90,
                        }
                    ]}
                    getRowStyle={(params) => {
                        if (params.data.reserveStatus === '취소') {
                            return { pointerEvents: 'none' };
                        }
                        return null;
                    }}
                    rowDoubleClickCallback={openReserveDetail}
                />
            </CContainer>
            <UserSearch callbackFn={searchUserInfo} />
            <CustomerSearch callbackFn={searchCustomerInfo}/>
            <ProductListSearch callbackFn={searchProductInfo}/>
            <ReserveDetailPopup reserveNo={reserveNo} searchReservation={searchReservation} />
            <Modal title={'재고예약'}>
                <AssetReservation searchReservation={searchReservation}/>
            </Modal>
        </>
    );
}

export default observer(AssetReservationList);
