import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";
import {getSessionUserCompanyCode} from "../../utils/userUtils";

export const AssetMeterListStore = observable({
    mode: 'total',
    detailTab: 'year',
    assetMeterList: [],
    chartMeterList: [],
    operatingRateList: [],
    searchCondition: { companyCode: getSessionUserCompanyCode() ? getSessionUserCompanyCode() : '' /*customerNo: '', customerName: '', contractType: '', saleUserSeq: null, saleUserName:''*/},
    startDate: { startDate:'', endDate: '' },
    endDate: { startDate:'', endDate:'' },
    setMode(v){
        this.mode = v;
    },
    setDetailTab(v){
        this.detailTab = v;
    },
    setAssetMeterList(v){
        this.assetMeterList = v;
    },

    setChartMeterList(v){
        this.chartMeterList = v;
    },

    setOperatingRateList(v){
        this.operatingRateList = v;
    },

    setSearchCondition(target, v) {
        this.searchCondition[target] = v;
    },
    setStartDate(v) {
        this.startDate = v;
    },
    setEndDate(v) {
        this.endDate = v;
    },
    chartInit(){
        this.chartMonthList = [];
        this.operatingRateList = [];
    }
});
