import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {
    CCol,
    CContainer,
    CInputGroup,
    CInputDoubleGroup,
    Subject, CSaveBtn, CalendarGroup, CSearchBtn
} from "../../components/CustomContainer";
import {Col, Form, InputGroup, Row, Tab, Tabs} from "react-bootstrap";
import AgGridContainer from "../../components/AgGridContainer";
import {AppStore} from "../../store/AppStore";
import {AssetReservationStore} from "../../store/asset/assetReservationStore";
import CustomerBillingAddressSearch from "../../components/searchModal/CustomerBillingAddressSearch";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import axios from "axios";
import {callConfirm} from "../../utils";
import {SUCCESS} from "../../common/constants";
import ProductListSearch from "../../components/searchModal/ProductListSearch";
import {addDayDate, dateTimeFormat, today} from "../../utils/dateUtils";
import {showToast} from "../../common/utils";

const AssetReservation =(props)=> {
    const store = AssetReservationStore.data;
    const gridRef = useRef();
    const [searchList, setSearchList] = useState([]);
    const [productSeq, setProductSeq] = useState('');
    const [productName, setProductName] = useState('');
    const [model, setModel] = useState('');
    const [availableReservation, setAvailableReservation] = useState('available');
    const [selectAssetCount, setSelectAssetCount] = useState(0);

    useEffect(()=> {
        setSearchList([]);
    }, [store.startDate])

    const onSearch =async()=> {
        const params = {
            productSeq: productSeq,
            productName: productName,
            model: model,
            available: availableReservation,
            startDate: store.startDate,
            endDate: store.endDate
        }

        const result = await axios.get('/asset/reservation/available/list', {params: params});
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.');
        }
        setSearchList(result);
    }

    const modalSearchProductInfo =(data)=> {
        if(data){
            setProductSeq(data.productSeq);
            setProductName(data.productName);
        }
    }

    const modalSearchCustomerInfo =(data)=> {
        if(data){
            store.customerNo = data.customerNo;
            store.customerName = data.customerName;
        }
    }

    const modelSearchCustomerBillingAddress =(data)=> {
        if(data){
            store.billingAddressSeq = data.billingAddressSeq;
            store.billingAddressName = data.billingAddressName;
            store.managerName = data.managerName;
            store.telNo = data.phoneNumber ? data.phoneNumber : data.telephone;
        }
    }

    /* 저장버튼 클릭 이벤트 */
    const callBackGridData = async (gridData) => {
        if(gridData.updatedList.length > 0) {
 /*           // 필수값 체크
            if (gridData.updatedList.some(item => !requiredParams.every(param => item[param]))) {
                await callAlert('필수값을 모두 입력해야 합니다.');
                return;
            }

            await axios.put('/estimateProduct', {estimateProductList: gridData.updatedList});*/
        }
    }

    const saveAssetReservation =async()=> {
        //선택한 자산번호 가져오기
        const assetList = searchList.filter(v => v.isSelected).map(v => {return v.assetNo});
        //console.log(assetList)

        if(assetList.length === 0){
            showToast('제품을 선택해 주세요.');
            return;
        }
        if(!store.startDate || !store.endDate){
            showToast('예약 시작/종료일은 필수 입니다.');
            return;
        }
        if(!store.customerNo){
            showToast('고객을 선택해 주세요.');
            return;
        }

        if(!await callConfirm(assetList.length + '건의 자산을 예약 하시겠습니까?')){
            return false;
        }

        const params = {
            startDate: new Date(dateTimeFormat(store.startDate + store.startHour + store.startMinute + '00')),
            endDate: new Date(dateTimeFormat(store.endDate + store.endHour + store.endMinute + '00')),
            customerNo: store.customerNo,
            billingAddressSeq: store.billingAddressSeq,
            managerName: store.managerName,
            telNo: store.telNo,
            remark: store.remark,
            assetList: assetList
        }

        const success = await axios.post('/asset/reservation', params);
        if(success == SUCCESS){
            showToast('예약되었습니다.');
            AppStore.toggleModal();
            props.searchReservation();
        }
    }

    const checkHour =(hh)=> {
        if(hh > 23){
            showToast('23보다 클 수 없습니다.');
            return false;
        }else{
            return true;
        }
    }

    const checkMinute =(mm)=> {
        if(mm > 59){
            showToast('59보다 클 수 없습니다.');
            return false;
        }else{
            return true;
        }
    }

    const checkAsset =()=> {
        const selectedList = searchList.filter(v => v.isSelected);
        setSelectAssetCount(selectedList.length);
    }

    return(
        <div style={{marginBottom: 10}}>
            {/* 검색조건 */}
            <CContainer>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'제품명'}
                            labelId={'productName'}
                            value={productName}
                            enterKeyHint={onSearch}
                            onCallbackBtn={()=> AppStore.toggleProductListSearchModal()}
                            onChange={(v)=> setProductName(v.target.value)}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'모델명'}
                            labelId={'model'}
                            value={model}
                            onChange={(v)=> setModel(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={3} style={{paddingTop: 4}}>
                        <Form.Check
                            inline
                            label="전체"
                            name="availableReservation"
                            type={'radio'}
                            id='all'
                            value={''}
                            checked={availableReservation === ''}
                            onChange={(e)=> setAvailableReservation(e.target.value)}
                        />
                        <Form.Check
                            inline
                            label="예약가능"
                            name="availableReservation"
                            type={'radio'}
                            id='available'
                            value={'available'}
                            checked={availableReservation === 'available'}
                            onChange={(e)=> setAvailableReservation(e.target.value)}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <CalendarGroup label={'예약시작일*'}
                                       asSingle={true}
                                       minDate={today()}
                                       isValid={true}
                                       value={{startDate: store.startDate}}
                                       onChange={(v)=> store.startDate = v.startDate}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Control style={{flex: 1, textAlign: 'right'}} aria-describedby={'startHour'}
                                          value={store.startHour}
                                          maxLength={2}
                                          onChange={v=> {
                                              if(checkHour(v.target.value)){
                                                  store.startHour = v.target.value
                                              }
                                          }}
                            />
                            <InputGroup.Text>시</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'right'}} aria-describedby={'startMinute'}
                                          value={store.startMinute}
                                          maxLength={2}
                                          onChange={v=> {
                                              if(checkMinute(v.target.value)){
                                                  store.startMinute = v.target.value
                                              }
                                          }}
                            />
                            <InputGroup.Text>분</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={3}>
                        <CalendarGroup label={'예약종료일*'}
                                       asSingle={true}
                                       minDate={addDayDate(store.startDate, 1)}
                                       maxDate={addDayDate(today(), 30)}
                                       isValid={true}
                                       value={{startDate: store.endDate}}
                                       onChange={(v)=> store.endDate = v.endDate}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Control style={{flex: 1, textAlign: 'right'}} aria-describedby={'endHour'}
                                          value={store.endHour}
                                          maxLength={2}
                                          onChange={v=> {
                                              if(checkHour(v.target.value)){
                                                  store.endHour = v.target.value
                                              }
                                          }}
                            />
                            <InputGroup.Text>시</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'right'}} aria-describedby={'endMinute'}
                                          value={store.endMinute}
                                          maxLength={2}
                                          onChange={v=> {
                                              if(checkMinute(v.target.value)){
                                                  store.endMinute = v.target.value
                                              }
                                          }}
                            />
                            <InputGroup.Text>분</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                </Row>
                <Row>
                    <span>*예약 시간은 필수 입력이 아닙니다.</span>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn title="재고조회" onClick={onSearch} />
                    </Col>
                </Row>
                <Row>
                    <AgGridContainer
                        gridRef={gridRef}
                        height={30}
                        rowData={searchList}
                        gridTitle={selectAssetCount}
                        columnDefs={[
                            {field: "assetNo", headerName: "자산번호", width: 120},
                            {field: "productCode", headerName: "제품코드", width: 110},
                            {field: "assetStatus", headerName: "자산상태"},
                            {field: "productName", headerName: "제품명", width: 200},
                            {field: "model", headerName: "모델명", width: 150},
                            {field: "storageExpectDate", headerName: "입고예정일", width: 120},
                            {field: "isReserve", headerName: "예약여부"},
                            {field: "reserveStatus", headerName: "예약상태"},
                            {field: "reserveEndDate", headerName: "예약종료일", valueGetter: v=> v.data.isReserve === 'N' ? '' : v.data.reserveEndDate, width: 120},
                            {field: "reserveUser", headerName: "예약자", valueGetter: v=> v.data.isReserve === 'N' ? '' : v.data.reserveEndDate},
                            {field: "spec", headerName: "스펙", width: 200},
                            {field: "companyName", headerName: "본부", width: 150},
                        ]}
                        seqColumn={'assetNo'}
                        isCheckBox={true}
                        useIntoTheTab={true}
                        callBackGridData={callBackGridData}
                        getRowStyle={(params) => {
                            if (params.data.isReserve === 'Y' && params.data.reserveEndDate > store.startDate ) {
                                return { pointerEvents: 'none', color: '#FA5858' };
                            }
                            return null;
                        }}
                        getCheckboxClickEvent={checkAsset}
                    />
                </Row>
                <Row>
                    <Subject>고객</Subject>
                    <CCol lg={4}>
                        <CInputDoubleGroup
                            label={'고객명*'} labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객코드'} placeholder2={'고객명'}
                            disabled={true} disabled2={true}
                            value={store.customerNo}
                            value2={store.customerName}
                            isValid={true}
                            onEmptyAction={()=> {
                                store.customerNo = '';
                                store.customerName = '';
                            }}
                            onCallbackBtn={()=> AppStore.toggleCustomerSearchModal()}

                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputDoubleGroup label={'청구지'} labelId={'billingAddressSeq'} labelId2={'billingAddressName'}
                                           placeholder={'번호'}
                                           placeholder2={'청구지명'}
                                           disabled={true} disabled2={true}
                                           value={store.billingAddressSeq}
                                           value2={store.billingAddressName}
                                           onEmptyAction={()=> {
                                               store.billingAddressSeq = '';
                                               store.billingAddressName = '';
                                           }}
                                           onCallbackBtn={()=> AppStore.toggleCustomerBillingAddressSearchModal()}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={4}>
                        <CInputGroup
                            label={'예약비고사항'}
                            labelId={'remark'}
                            value={store.remark}
                            onChange={(e)=> store.remark = e.target.value}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'담당자명'}
                            labelId={'managerName'}
                            value={store.managerName}
                            onChange={(e)=> store.managerName = e.target.value}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'전화번호'}
                            labelId={'telNo'}
                            value={store.telNo}
                            onChange={(e)=> store.telNo = e.target.value}
                        />
                    </CCol>
                </Row>
                <Row style={{marginBottom: 10}}>
                    <Col className='d-flex flex-row-reverse'>
                        <CSaveBtn style={{width: 90, marginTop: 20}} title={'예약'}
                                  onClick={saveAssetReservation}
                        />
                    </Col>
                </Row>
            </CContainer>
            <ProductListSearch callbackFn={modalSearchProductInfo}/>
            <CustomerSearch callbackFn={modalSearchCustomerInfo}/>
            <CustomerBillingAddressSearch customerNo={store.customerNo} callbackFn={modelSearchCustomerBillingAddress} />
        </div>
    );
}

export default observer(AssetReservation);
