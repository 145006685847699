import { useEffect, useRef, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer, CInputGroup, CSearchBtn } from "../CustomContainer";
import { CommcodeSMOptions } from "../index";
import AgGridContainer from "../AgGridContainer";
import {showToast} from "../../common/utils";

const StockGoodsSearch =({ callbackFn=null })=> {

    const gridRef = useRef();
    const [searchList, setSearchList] = useState([]);
    const [productName, setProductName] = useState('');
    const [model, setModel] = useState('');
    const [spec, setSpec] = useState('');
    const [brandCode, setBrandCode] = useState('');

    const [stockType, setStockType] = useState('');
    const [available, setAvailable] = useState('stock');

    /* 조회 */
    const onSearch = async () => {
        const params = {
            productName: productName,
            model: model,
            spec: spec,
            brandCode: brandCode,
            isAvailableStorage: available && true,
            isDeleted: 0,
        };

        let url = [];
        if(!stockType){
            url = [await axios.get('/stock/goods', { params }), await axios.get('/stock/option', { params }), await axios.get('/stock/consumable', { params })]
        }else{
            url = [await axios.get(`/stock/${stockType}`, { params })];
        }

        Promise.all(url)
        .then(r => {
            const list = [];

            for(let i in r){
                for(const data of r[i]){
                    list.push(data);
                }
            }

            if(list.length === 0){
                showToast('검색된 데이터가 없습니다.');
            }
            setSearchList(list);
        })
        

    }

    /* 제조사 값 설정 */
    const onChangeBrandName = (v) => {
        setBrandCode(v.target.value);
    }


    const callBackGridData = ({ selectedList }) => {
        if(selectedList.some(v => v.stockQty <= 0)){
            showToast('재고가 없는 소모품이 선택되었습니다.');
            return;
        }

        callbackFn && callbackFn(selectedList);
        AppStore.toggleStockGoodsSearchModal();
    }

    const onRowDoubleClicked = (e) => {
        const selectedList = [];
        selectedList.push({...e.data});

        callbackFn(selectedList);
        AppStore.toggleStockGoodsSearchModal();
    }

    /* 초기화 */
    const refresh = () => {
        setSearchList([]);
    }

    return (
        <Modal
            title={'상품 조회'}
            show={AppStore.isStockGoodsSearch}
            onHide={() => AppStore.toggleStockGoodsSearchModal()}
            toggleValue={AppStore.isStockGoodsSearch}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <>
                            <Form.Check
                                inline
                                label="전체"
                                name="stockType"
                                type={'radio'}
                                id="all"
                                value={''}
                                checked={stockType === ''}
                                onChange={(e)=> setStockType(e.target.value)}
                            />
                            <Form.Check
                                inline
                                label="상품"
                                name="stockType"
                                type={'radio'}
                                id='goods'
                                value={'goods'}
                                checked={stockType === 'goods'}
                                onChange={(e)=> setStockType(e.target.value)}
                            />
                            <Form.Check
                                inline
                                label="옵션"
                                name="stockType"
                                type={'radio'}
                                id='option'
                                value={'option'}
                                checked={stockType === 'option'}
                                onChange={(e)=> setStockType(e.target.value)}
                            />
                            <Form.Check
                                inline
                                label="소모품"
                                name="stockType"
                                type={'radio'}
                                id='consumable'
                                value={'consumable'}
                                checked={stockType === 'consumable'}
                                onChange={(e)=> setStockType(e.target.value)}
                            />
                        </>
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'제품명'}
                            labelId={'productName'}
                            value={productName}
                            onChange={(v)=> setProductName(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'모델명'}
                            labelId={'model'}
                            value={model}
                            onChange={(v)=> setModel(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select aria-label="선택" value={brandCode} onChange={onChangeBrandName}>
                                <option value={''}>- 제조사 -</option>
                                { <CommcodeSMOptions commKind={'18'} />}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <>
                            <Form.Check
                                inline
                                label="전체"
                                name="availableStock"
                                type={'radio'}
                                id="all"
                                value={''}
                                checked={available === ''}
                                onChange={(e)=> setAvailable(e.target.value)}
                            />
                            <Form.Check
                                inline
                                label="재고있음"
                                name="availableStock"
                                type={'radio'}
                                id='stock'
                                value={'stock'}
                                checked={available === 'stock'}
                                onChange={(e)=> setAvailable(e.target.value)}
                            />
                        </>
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'스펙'}
                            labelId={'spec'}
                            value={spec}
                            onChange={(v)=> setSpec(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearch} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={40}
                    rowData={searchList}
                    columnDefs={[
                        {field: "stockType", headerName: "재고구분", width: 120},
                        {field: "productSeq", headerName: "제품SEQ", width: 120, hide: true},
                        {field: "productName", headerName: "제품명", width: 200},
                        {field: "model", headerName: "모델명", width: 150},
                        {field: "spec", headerName: "스펙", minWidth: 240},
                        {field: "brandName", headerName: "제조사"},
                        {field: "stockQty", headerName: "재고수량", width: 110, cellClass: 'ag-grid-column-complete'},
                        {field: "storageQty", headerName: "입고수량", width: 110, hide: true},
                        {field: "releaseQty", headerName: "출고수량", width: 110, cellClass: 'ag-grid-column-wait'},
                    ]}
                    isCheckBox={true}
                    useIntoTheTab={true}
                    callBackGridData={callBackGridData}
                    selectBtnInfo={{isUsed: true}}
                    btnTitles={{save: '선택'}}
                    getRowStyle={(params) => {
                        if (params.data.stockQty <= 0) {
                            return { pointerEvents: 'none', color: '#848484' };
                        }
                        return null;
                    }}
                    rowDoubleClickCallback={e => onRowDoubleClicked(e)}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(StockGoodsSearch);
