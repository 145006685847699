import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';

import { CContainer } from '../../components/CustomContainer';
import { OrderStore } from '../../store/product/OrderStore';
import { OrderProductStore } from '../../store/product/OrderProductStore';
import Modal from '../../components/Modal';
import { AppStore } from '../../store/AppStore';
import AgGridContainer from '../../components/AgGridContainer';
import { dateFormat } from '../../utils/dateUtils';
import { findCommKrnm } from '../../utils/commcode';
import OrderDetail from './OrderDetail';
import { getUserName } from '../../utils/userUtils';
import { ICON_TRASH } from '../../common/constants';
import { callConfirm } from '../../utils';
import {showToast} from "../../common/utils";
import OrderListSearch from './OrderListSearch';

const OrderList = () => {
    const gridRef = useRef();
    const [modalState, setModalState] = useState('');
    const [getOrderData, setOrderData] = useState([]);

    /* 발주 조회 */
    const getOrderList = async () => {
        const result = await axios.get('/order/list', { params: OrderStore.orderSearchCondition });
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.');
        }
        OrderStore.setOrderList(result);
    }

    const columnDefs=[
        {field: "orderSeq", headerName: "발주번호", width: 110},
        {field: "orderTitle", headerName: "발주제목", width: 110},
        {field: "orderType", headerName: "발주구분", width: 110, valueGetter: v => findCommKrnm( v.data.orderType,'36')},
        {
            field: "orderStatus",
            headerName: "상태",
            width: 90,
            valueFormatter: v => v.value === 0 ? '작성중' :  v.value === 1 ? '발주완료' : '입고완료',
            cellClass: v => v.value === 1 ? 'ag-grid-column-wait' : v.value === 2 ? 'ag-grid-column-complete' : ''
        },
        {field: "orderDate", headerName: "발주일자", width: 120, valueGetter: v => dateFormat(v.data.orderDate)},
        {field: "orderUserName", headerName: "발주자", width: 100},
        {field: "orderCustomerName", headerName: "고객", width: 200},
        {field: "orderAmount", headerName: "발주수량", width: 110},
        {field: "storageCnt", headerName: "입고수량", width: 110},
        {field: "totalPrice", headerName: "발주금액", width: 110, valueFormatter: v => v.data.totalPrice === null ? '' : v.data.totalPrice.toLocaleString()},
        {field: "buyCustomerName", headerName: "구매처", width: 200},
        {field: "remark", headerName: "비고", width: 250},
        {
            field: "companyCode",
            headerName: "회사구분",
            width: 150,
            valueFormatter: function(params) {
                return findCommKrnm(params.value, '20');
            }
        },
        {field: "createdDate", headerName: "입력일자", width: 120, valueGetter: v => dateFormat(v.data.createdDate)},
        {field: "updater", headerName: "수정자", width: 100, valueGetter: v => getUserName(v.data.updater)},
        {field: "updatedDate", headerName: "수정일자", width: 120, valueGetter: v => !v.data.updatedDate?'':dateFormat(v.data.updatedDate)},
        {field: "approvalDate", hide: true },
        {field: "storageDate", hide: true },
        {field: "approvalNo", hide: true },
        {field: "deliveryPlace", hide: true },
        {field: "departmentCode", hide: true },
        {field: "orderUserSeq", hide: true },
    ];

    const openOrderDetail = (state) => {
        if(state === 'create'){
            setModalState('발주등록');
            setOrderData([]);
        }else {
            setModalState('발주관리');
            setOrderData(state.data);
        }
        AppStore.toggleModal();
    }

    /* 팝업 닫을 때 출고의뢰 초기화 */
    const closedModal = () => {
        OrderProductStore.init();
    };

    /* 취소 버튼 */
    const customCancelBtn = {
        isUsed: true,
        callbackFn:() => deleteOrder(),
        icon: ICON_TRASH,
        title: '발주삭제',
        width: 90,
    }

    /* 발주 삭제 callback */
    const deleteOrder = async() => {
        // 확인
        if(!await callConfirm('선택된 행을 삭제 하시겠습니까?')) return;

        let params = {orderSeqList: []};

        OrderStore.orderList.forEach(v => {
            if(v.isSelected === true) params.orderSeqList.push(v.orderSeq);
        });
        await axios.put('order/delete', params);
        await getOrderList();

        showToast('삭제 되었습니다.');
    }

    return (
        <>
            {/* 검색조건 */}
            <OrderListSearch getOrderList={getOrderList} setModalState={setModalState} setOrderData={setOrderData}/>

            {/* ag grid */}
            <CContainer>
                <AgGridContainer
                    gridTitle='발주목록'
                    gridRef={gridRef}
                    height={65}
                    rowData={OrderStore.orderList}
                    columnDefs={columnDefs}
                    isCheckBox={true}
                    seqColumn={'orderSeq'}
                    useCsvDownload={true}
                    useRowSearch={true}
                    rowSearchCallback={(e) => openOrderDetail(e)}
                    rowDoubleClickCallback={(e) => openOrderDetail(e)}
                    customBtnInfo={[customCancelBtn]}
                />
            </CContainer>
            {/* 추가 modal */}
            <Modal title={modalState} onExit={closedModal}>
                <OrderDetail orderData={getOrderData} />
            </Modal>
        </>
    );
};

export default observer(OrderList);
