import { useState, useEffect } from "react";
import axios from "axios";
import { Row, InputGroup, Col, Form } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { AppStore } from "../../store/AppStore";
import { CContainer, CCol, CSearchBtn, CInputGroup } from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";
import Modal from "../Modal";
import { SiteSearchStore } from "../../store/search/SiteSearchStore";
import {autoHyphen, decrypt, showToast} from "../../common/utils";

const SiteCustomerManagerSearch = ({callbackFn=null, ...props}) => {
    const [customerName, setCustomerName] = useState('');
    const [siteName, setSiteName] = useState('');
    const [managerName, setManagerName] = useState('');
    const [siteCustomerManagerList, setSiteCustomerManagerList] = useState([]);

    // useEffect(() => {
    //     getSiteCustomerManager();
    // }, [props.siteSeq, props.customerNo]);

    const getSiteCustomerManager = async () => {

        if (!customerName && !siteName && !managerName) {
            showToast('조회 조건을 한 개 이상 입력해주세요.');
            return;
        }

        const result = await axios.get("/as/manager", {params: {
                                                                // customerNo: props.customerNo, 
                                                                // siteSeq: props.siteSeq, 
                                                                customerNo: '', 
                                                                siteSeq: '', 
                                                                customerName: customerName, 
                                                                siteName: siteName, 
                                                                managerName: managerName
                                                        }});
        
        setSiteCustomerManagerList(result);
    }

    const selectedRow = (e) => {
        callbackFn && callbackFn(e.data);
        AppStore.toggleOpenSiteCustomerManagerSearch();
    }

    const refresh = () => {
        setCustomerName('');
        setSiteName('');
        setManagerName('');
        setSiteCustomerManagerList([]);
    }

    return (
        <>
            <Modal
                title={'담당자 조회'}
                onHide={() => AppStore.toggleOpenSiteCustomerManagerSearch()}
                toggleValue={AppStore.isOpenSiteCustomerManagerSearch}
                size={'lg'}
                onExit={refresh}
            >
                <CContainer search>
                    <Row>
                        <CCol lg={4}>
                            <CInputGroup
                                label={'고객명'}
                                value={customerName}
                                onChange={v => setCustomerName(v.target.value)}
                                enterKeyHint={getSiteCustomerManager}
                            />
                        </CCol>
                        <CCol lg={4}>
                            <CInputGroup
                                label={'현장명'}
                                value={siteName}
                                onChange={v => setSiteName(v.target.value)}
                                enterKeyHint={getSiteCustomerManager}
                            />
                        </CCol>
                        <CCol lg={4}>
                            <CInputGroup
                                label={'담당자명'}
                                value={managerName}
                                onChange={v => setManagerName(v.target.value)}
                                enterKeyHint={getSiteCustomerManager}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <Col className='d-flex flex-row-reverse'>
                            <CSearchBtn onClick={getSiteCustomerManager} style={{width:100}} />
                        </Col>
                    </Row>
                </CContainer>
                <CContainer>
                    <AgGridContainer
                        height={26}
                        rowData={siteCustomerManagerList}
                        columnDefs={[
                                {field: "type", headerName: "구분", width: 120},
                                {field: "managerName", headerName: "담당자명", width: 140},
                                {field: "phoneNumber", headerName: "핸드폰", width: 170, flex:1, valueFormatter: v => v.value? autoHyphen(decrypt(v.value)) : ''},
                                {field: "email", headerName: "이메일", width: 200, valueFormatter: v => v.value? decrypt(v.value) : '', hide: true},
                                {field: "siteName", headerName: "현장명", width: 140},
                                {field: "customerName", headerName: "고객명", width: 140},
                                {field: "seq", headerName: "순번", width: 80, hide: true},
                            ]}
                        enableCellTextSelection={true}
                        rowDoubleClickCallback={selectedRow}
                    />
                </CContainer>
            </Modal>
        </>
    );

}

export default observer(SiteCustomerManagerSearch);
