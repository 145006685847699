import { useState } from "react";
import { observer } from "mobx-react-lite";
import { AppStore } from "../../../store/AppStore";
import { CCol, CContainer, Subject } from "../../../components/CustomContainer";
import { Form, Button, Modal, FormLabel, InputGroup, Row, Col } from "react-bootstrap";
import { CalendarGroup } from "../../../components/CustomContainer";
import { today } from "../../../utils/dateUtils";
import { callConfirm } from "../../../utils";
import axios from "axios";
import { showToast } from "../../../common/utils";
import { GeneralStatementStore } from "../../../store/accounting/GeneralStatementStore";

const ApprovalPostingDatePopup = ({getStatementList}) => {
    const [selType, setSelType] = useState(1);
    const [date, setDate] = useState(today());

    const calendarLabel = () => {
        return <Form.Label style={{marginTop: -4}}>
                    <CalendarGroup asSingle={true} label={'기표일자'} 
                        value={{
                            startDate: date,
                            endDate: date
                        }}
                        onChange={v => setDate(v.startDate)}
                    />
                </Form.Label>;
    }

    const approvalFn = async () => {
        
        
        if (!await callConfirm('승인 처리 하시겠습니까?')) {
            return;
        }
        if (selType === 2 && !date) {
            showToast('기표일자를 선택해주세요.');
            return;
        }
        
        
        let params = {statementList: []};
        GeneralStatementStore.statementList.forEach(v => {
            if(v.isSelected === true)   params.statementList.push({statementNo: v.statementNo, approvalStatus: '2', resolutionDate: v.resolutionDate, postingDate: selType === 1 ? v.resolutionDate : date} );
        });
        // 승인 처리
        await axios.put('/statement/general/approval/list', params);

        // 승인 완료 알림
        showToast('일괄 승인 완료했습니다.');
        AppStore.toggleApprovalPostingDatePopup();
        // 리스트 다시 불러오기
        getStatementList();
    };

    return (
        <>
            <Modal show={AppStore.isOpenApprovalPostingDatePopup}
                   onHide={()=> AppStore.toggleApprovalPostingDatePopup()}
                   backdrop="static"
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title>전표승인</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CContainer search>
                    <h5 style={{color: '#5f6975'}}>✓ 기표일자 선택</h5>
                    <div style={{width: '100%', height: 1, borderBottomWidth: 1, borderBottomColor: '#efefef', marginLeft: 10, marginRight: 10}}/>
                    <Row style={{marginTop: 35}}>
                        <Col lg={8}>
                            <InputGroup>
                                <Form.Check
                                    label={'기표일자와 결의일자를 동일하게 설정'}
                                    name="selPostingDate"
                                    type={'radio'}
                                    value={1}
                                    id={'selType1'}
                                    checked={selType === 1}
                                    onChange={()=> setSelType(1)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Check
                                label={'기표일자 선택'}
                                name="selPostingDate"
                                type={'radio'}
                                id={'selType2'}
                                value={2}
                                checked={selType === 2}
                                onChange={()=> setSelType(2)}
                                style={{marginTop: '13px'}}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={8} style={{marginTop: '10px'}}>
                                <CalendarGroup asSingle={true} label={'기표일자'} 
                                    value={{
                                        startDate: date,
                                        endDate: date
                                    }}
                                    onChange={v => {setDate(v.startDate), setSelType(2)}}
                                />
                        </Col>
                    </Row>
                    </CContainer>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=> AppStore.toggleApprovalPostingDatePopup()}>
                        취소
                    </Button>
                    <Button variant="primary" onClick={approvalFn}>
                        처리
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default observer(ApprovalPostingDatePopup);