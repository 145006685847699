import { observer } from "mobx-react-lite";
import axios from 'axios';
import LUtils from '../../utils/lodashUtils';
import { CContainer, CCol, CInputGroup, CInputDoubleGroup, CalendarGroup, CSearchBtn } from "../../components/CustomContainer";
import { Row, Col } from "react-bootstrap";
import { AssetExpirationStore } from "../../store/asset/AssetExpirationStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import ContractSearch from "../../components/searchModal/ContractSearch";
import { AppStore } from "../../store/AppStore";
import {showToast} from "../../common/utils";
import SiteNameSearch from "../../components/searchModal/SiteNameSearch";
import UserSearch from "../../components/searchModal/UserSearch";

const AssetExpirationListSearch = ({setOriginList}) => {

    const getAssetList = async () => {
        const {assetNo, contractNo, customerNo, expirationDate, startDate, endDate } = AssetExpirationStore.search;
        // 검색조건 필수값 확인
        if (!(assetNo || contractNo || customerNo || expirationDate || startDate || endDate)) {
            showToast("검색조건 중 하나 이상 입력해주세요!");
            return false;
        }

        const result = await axios.get("/asset/expiration/list", { params: AssetExpirationStore.search });
        const originData = result?.map(v => LUtils.assign(v, { isChecked: false }));  // isChecked값을 추가
        AssetExpirationStore.setExpirationList(originData);
        setOriginList(originData);
        if(result.length === 0){
            showToast('조회된 데이터가 없습니다.');
        }
    }

    const searchCustomerInfo =(data)=> {
        if(data){
            AssetExpirationStore.search.customerNo = data.customerNo;
            AssetExpirationStore.search.customerName = data.customerName;
        }
    }

    /* 계약조회 팝업 콜백 */
    const callbackContractInfo = (data) => {
        AssetExpirationStore.search.contractNo = data.contractNo;
    }

    /* 현장 검색 팝업 오픈 */
    const openSearchSitePopup = () => {
        AppStore.toggleSiteNameSearchPopup();
    }

    /* 현장 검색 콜백 함수 */
    const getSiteInfo = (siteInfo) => {
        AssetExpirationStore.search.siteName = siteInfo.siteName;
        AssetExpirationStore.search.siteSeq = siteInfo.siteSeq;
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        if (!userInfo) return false;

        AssetExpirationStore.search.saleUserName = userInfo.name;
        AssetExpirationStore.search.saleUserSeq = userInfo.userSeq;
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'자산번호'} labelId={'assetNo'}
                                     value={AssetExpirationStore.search.assetNo}
                                     onChange={v => AssetExpirationStore.search.assetNo = v.target.value}
                                     enterKeyHint={getAssetList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'계약번호'} labelId={'contractNo'}
                                     value={AssetExpirationStore.search.contractNo}
                                     onCallbackBtn={() => AppStore.toggleContractSearchModal()}
                                     onChange={v => AssetExpirationStore.search.contractNo = v.target.value}
                                     enterKeyHint={getAssetList}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputDoubleGroup label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                                           placeholder={'고객코드'} placeholder2={'고객명'}
                                           onCallbackBtn={() => AppStore.toggleCustomerSearchModal()}
                                           value={AssetExpirationStore.search.customerNo}
                                           value2={AssetExpirationStore.search.customerName}
                                           onChange={v => AssetExpirationStore.search.customerNo = v.target.value}
                                           onChange2={v => AssetExpirationStore.search.customerName = v.target.value}
                                           enterKeyHint={getAssetList}
                                           enterKeyHint2={getAssetList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup label={'시작일'} asSingle={true}
                                       value={{startDate: AssetExpirationStore.search.startDate, endDate: AssetExpirationStore.search.startDate}}
                                       onChange={v => AssetExpirationStore.search.startDate = v.startDate}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup label={'종료일'} asSingle={true}
                                       value={{startDate: AssetExpirationStore.search.endDate, endDate: AssetExpirationStore.search.endDate}}
                                       onChange={v => AssetExpirationStore.search.endDate = v.startDate}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'만기지연일수'} labelId={'expirationDays'}
                                    value={AssetExpirationStore.search.expirationDays}
                                    onChange={v => {
                                        if (isNaN(v.target.value)) {
                                            showToast('숫자만 입력해야 합니다.');
                                            return false;
                                        }
                                        AssetExpirationStore.search.expirationDays = v.target.value;
                                    }}
                                    type={'number'}
                                    enterKeyHint={getAssetList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'현장'}
                                    value={AssetExpirationStore.search.siteName}
                                    onCallbackBtn={openSearchSitePopup}
                                    onChange={(v)=> {
                                        if(AssetExpirationStore.search.siteSeq) {
                                            AssetExpirationStore.search.siteSeq = '';
                                        }
                                        AssetExpirationStore.search.siteName = v.target.value;
                                    }}
                                    enterKeyHint={getAssetList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup   label={'영업담당자'}
                                        value={AssetExpirationStore.search.saleUserName}
                                        onChange={(v)=> {AssetExpirationStore.search.saleUserName = v.target.value, AssetExpirationStore.search.saleUserSeq = ''}}
                                        onCallbackBtn={() => AppStore.toggleUserSearchModal()}
                                        enterKeyHint={getAssetList}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getAssetList} />
                    </Col>
                </Row>
            </CContainer>
            <CustomerSearch callbackFn={searchCustomerInfo}/>
            <ContractSearch callbackFn={callbackContractInfo}/>
            <SiteNameSearch callbackFn={getSiteInfo}/>
            <UserSearch callbackFn={getUserInfo} />
        </>
    );

}

export default observer(AssetExpirationListSearch);
