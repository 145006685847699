import { useState } from "react";
import { observer } from "mobx-react-lite";
import { AppStore } from "../../../store/AppStore";
import { CContainer, CCol, CInputGroup } from "../../../components/CustomContainer";
import { Row, Button, Modal } from "react-bootstrap";
import axios from "axios";
import {encrypt} from "../../../common/utils";

const PasswordCheckPopup = ({callbackFn}) => {
    const [password, setPassword] = useState('');
    const [result, setResult] = useState('');

    const passwordValidation = async() => {
        const result = await axios.post('/statement/password/check', {password: encrypt(password)});
        callbackFn(result);
        AppStore.togglePasswordCheckPopup();
    }

    return (
        <>
            <Modal show={AppStore.isOpenPasswordCheckPopup}
                   onHide={()=> AppStore.togglePasswordCheckPopup()}
                   onExit={()=> setPassword('')}
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title>비밀번호 확인</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CContainer search>
                        <Row style={{marginTop: 13}}>
                            <CCol lg={12}>
                                <CInputGroup
                                    label={'비밀번호'}
                                    labelId={'password'}
                                    value={password}
                                    type={'password'}
                                    onChange={v => setPassword(v.target.value)}
                                    />
                            </CCol>
                        </Row>
                    </CContainer>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=> AppStore.togglePasswordCheckPopup()}>
                        취소
                    </Button>
                    <Button variant="primary" onClick={passwordValidation}>
                        처리
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default observer(PasswordCheckPopup);