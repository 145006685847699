import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { AppStore } from "../../../store/AppStore";
import Modal from "../../../components/Modal";
import { CCol, CContainer, CIconBtn, CSelectGroup, Subject } from "../../../components/CustomContainer";
import { Row, InputGroup, Form, Col } from "react-bootstrap";
import { CompanyStore } from "../../../store/commCode/CompanyStore";
import axios from "axios";
import { showToast } from "../../../common/utils";
import { callConfirm } from "../../../utils";

const ModifyCompanyContractPopup = ({getCompanyInfo}) => {
    const [sessionCount, setSessionCount] = useState(0);
    const [billCount, setBillCount] = useState(0);
    const [smsCount, setSmsCount] = useState(0);
    const [bankbookCount, setBankbookCount] = useState(0);
    const [corporateCardCount, setCorporateCardCount] = useState(0);
    const [isTaxBill, setIsTaxBill] = useState(0);
    const [isSendReleaseAskSms, setIsSendReleaseAskSms] = useState(0);

    useEffect(()=> {
        setSessionCount(CompanyStore.data.sessionCount);
        setBillCount(CompanyStore.data.billCount);
        setSmsCount(CompanyStore.data.smsCount);
        setBankbookCount(CompanyStore.data.bankbookCount);
        setCorporateCardCount(CompanyStore.data.corporateCardCount);
        setIsTaxBill(CompanyStore.data.isTaxBill);
        setIsSendReleaseAskSms(CompanyStore.data.isSendReleaseAskSms);
    }, [CompanyStore.data.companyCode])

    /* 계약 정보 변경 */
    const modifyContractOption = async () => {
        if(!await callConfirm('계약정보를 변경하시겠습니까?')) {
            return false;
        }

        await axios.post('/company/option', {companyCode: CompanyStore.data.companyCode,
                                             sessionCount: sessionCount, 
                                             billCount: billCount, 
                                             smsCount: smsCount, 
                                             bankbookCount: bankbookCount, 
                                             corporateCardCount: corporateCardCount, 
                                             isTaxBill: isTaxBill,
                                             isSendReleaseAskSms : isSendReleaseAskSms,
                                            });
        showToast('계약 정보가 변경되었습니다.');
        CompanyStore.data.sessionCount = sessionCount;
        CompanyStore.data.billCount = billCount;
        CompanyStore.data.smsCount = smsCount;
        CompanyStore.data.bankbookCount = bankbookCount;
        CompanyStore.data.corporateCardCount = corporateCardCount;
        CompanyStore.data.isTaxBill = isTaxBill;
        CompanyStore.data.isSendReleaseAskSms = isSendReleaseAskSms;

        getCompanyInfo();
        init();
        AppStore.toggleModifyCompanyContractPopup();
    }

    const init = () => {
        setSessionCount(CompanyStore.data.sessionCount);
        setBillCount(CompanyStore.data.billCount);
        setSmsCount(CompanyStore.data.smsCount);
        setBankbookCount(CompanyStore.data.bankbookCount);
        setCorporateCardCount(CompanyStore.data.corporateCardCount);
        setIsTaxBill(CompanyStore.data.isTaxBill);
        setIsSendReleaseAskSms(CompanyStore.data.isSendReleaseAskSms);
    }

    return (
        <Modal title={'계약 정보 변경'}
            onHide={() => AppStore.toggleModifyCompanyContractPopup()}
            toggleValue={AppStore.isOpenModifyCompanyContractPopup}
            className='modal-w5'
            onExit={init}
        >
            <CContainer>
                <Row>
                    <CCol lg={12}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'sessionCount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>동시 접속자 수</InputGroup.Text>
                            <Form.Control
                                type="Number"
                                style={{flex: 1, textAlign: 'right'}}
                                aria-describedby={'sessionCount'}
                                value={sessionCount || ''}
                                onChange={v => setSessionCount(v.target.value)}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>명</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={12}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'billCount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>월별 국세청 계산서 발송 건 수</InputGroup.Text>
                            <Form.Control
                                type="Number"
                                style={{flex: 1, textAlign: 'right'}}
                                aria-describedby={'billCount'}
                                value={billCount || ''}
                                onChange={v => setBillCount(v.target.value)}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>건</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={12}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'smsCount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>월별 알림톡 발송 건 수</InputGroup.Text>
                            <Form.Control
                                type="Number"
                                style={{flex: 1, textAlign: 'right'}}
                                aria-describedby={'smsCount'}
                                value={smsCount || ''}
                                onChange={v => setSmsCount(v.target.value)}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>건</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={12}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'bankbookCount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>법인통장 등록 건 수</InputGroup.Text>
                            <Form.Control
                                type="Number"
                                style={{flex: 1, textAlign: 'right'}}
                                aria-describedby={'bankbookCount'}
                                value={bankbookCount || ''}
                                onChange={v => setBankbookCount(v.target.value)}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>건</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={12}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'corporateCardCount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>법인카드 등록 건 수</InputGroup.Text>
                            <Form.Control
                                type="Number"        
                                aria-describedby={'corporateCardCount'}
                                value={corporateCardCount || ''}
                                onChange={v => setCorporateCardCount(v.target.value)}
                            />
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>건</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={12}>
                        <CSelectGroup
                            label={'국세청 매입/매출 세금 계산서 조회'}
                            options={[{name: '미사용', value: 0}, {name: '사용', value: 1}]}
                            value={isTaxBill}
                            onChange={v => setIsTaxBill(v.target.value)}
                        />
                    </CCol>
                    <CCol lg={12}>
                        <CSelectGroup
                            label={'출고의뢰 시 알림톡 발송'}
                            options={[{name: '미사용', value: 0}, {name: '사용', value: 1}]}
                            value={isSendReleaseAskSms}
                            onChange={v => setIsSendReleaseAskSms(v.target.value)}
                        />
                    </CCol>
                </Row>
            </CContainer>

            <div style={{height: 1, borderBottomWidth: 1, borderBottomColor: '#efefef', marginTop: -5, marginBottom: 10}}/>
            <Form.Label style={{fontSize: 16, marginTop: 14, marginLeft: 20, color: '#5f6975'}}>단가표</Form.Label>
            <CContainer search>
                <Row>
                    <CCol lg={6}>
                        접속자 5명 추가 
                    </CCol>
                    <CCol lg={6}>
                        20,000원
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        계산서 발송 
                    </CCol>
                    <CCol lg={6}>
                        건당 100원
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        알림톡 발송 
                    </CCol>
                    <CCol lg={6}>
                        건당 10원
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        법인통장
                    </CCol>
                    <CCol lg={6}>
                        건당 2,000원
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        법인카드
                    </CCol>
                    <CCol lg={6}>
                        건당 2,000원
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6}>
                        매입/매출 세금 계산서 조회 사용 
                    </CCol>
                    <CCol lg={6}>
                        월 35,000원
                    </CCol>
                </Row>
            </CContainer>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <CIconBtn style={{width: 160}}
                        title={'계약정보 변경 요청'}
                        icon={'fi-rr-box-check'}
                        onClick={() => modifyContractOption()}
                        variant={'success'}
                />
            </div>
        </Modal>
    )
}

export default observer(ModifyCompanyContractPopup);