import {getLocalStorage} from "../../utils";
import {USER_INFO} from "../../common/constants";

export const companyContractSpecialRemark =()=> {
    const userInfo = getLocalStorage(USER_INFO);
    let remark = '';

    //기연리프트
    if(userInfo.companyCode === '2404001'){
        remark = '2개월이하 왕복운반비 임차인 부담, 4개월이하 편도운반비 임차인부담,4개월초과 왕복 운반비 임대인부담';
    }

    return remark;
};