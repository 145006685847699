import { observable } from "mobx";
import { getYear } from "../../utils/dateUtils";

export const SalaryStatusStore = observable({

    salaryList: [],
    salaryDetailList: [],

    search: {
        year: getYear(),
        startMonth: '01',
        endMonth: '06',
        companyCode: '',
    },

});