import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import axios from 'axios';
import {useReactToPrint} from 'react-to-print';
import {Col, Form, InputGroup, Row, Button, Tabs, Tab} from "react-bootstrap";
import {CCol, CContainer, CInputGroup, CInputDoubleGroup, CSelectGroup, Subject, CalendarWithLabel, CCancelBtn, CSaveBtn, CIconBtn} from "../../components/CustomContainer";
import {getCommcode} from "../../utils/commcode";
import {dateFormat, getDays, today} from "../../utils/dateUtils";
import {AppStore} from "../../store/AppStore";
import AgGridContainer from "../../components/AgGridContainer";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import {EstimateDetailStore} from "../../store/contract/EstimateDetailStore";
import CustomerBillingAddressSearch from "../../components/searchModal/CustomerBillingAddressSearch";
import EstimateSearch from "../../components/searchModal/EstimateSearch";
import {callConfirm} from '../../utils';
import {encrypt, decrypt, showToast} from "../../common/utils";
import ProductListSearch from "../../components/searchModal/ProductListSearch";
import ReportEstimateTemplate from "../../components/report/ReportEstimateTemplate";
import PDFEstimateTemplate from "../../components/report/PDFEstimateTemplate";
import { IS_UPDATED, MULTIPART_HEADER } from '../../common/constants';
import LUtils from '../../utils/lodashUtils';
import contractUtils from "./contractUtils";
import CustomerManagerSearch from "../../components/searchModal/CustomerManagerSearch";
import SendEstimatePopup from "./popup/SendEstimatePopup";
import html2pdf from 'html2pdf.js';
import { getCompanyList } from "../../utils/userUtils";

const EstimateDetail =({estimateNo, getEstimateList})=> {
    const gridRef = useRef();
    const agGridContainerRef = useRef();
    const componentRef = useRef();
    const contentRef = useRef();

    const [days, setDays] = useState(getDays());
    const [isViewMode, setIsViewMode] = useState(false);
    const [contractDays, setContractDays] = useState({ rentMonth: '', rentDays: '' });
    const [contractDate, setContractDate] = useState({ startDate: today(), endDate: today() });
    const [contractStartDate, setContractStartDate] = useState({ startDate: null, endDate: null });
    const [contractEndDate, setContractEndDate] = useState({ startDate: null, endDate: null });
    const [userColumn, setUserColumn] = useState('');
    const [estimateProductList, setEstimateProductList] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
    const [activeKey, setActiveKey] = useState('item');
    const [companyList, setCompanyList] = useState([]);

    /* 기본값 세팅 */
    useEffect(() => {
        EstimateDetailStore.init();
        EstimateDetailStore.estimateDetail.contractDate = today();
        if(estimateNo) {
            Promise.all([getEstimate(estimateNo), getEstimateProductList()]);
        }
        else {
            // 영업담당자 value 담아주기
            EstimateDetailStore.estimateDetail.saleUserName = userInfo.name;
            EstimateDetailStore.estimateDetail.saleUserSeq = userInfo.userSeq;
        }
    }, [estimateNo]);

    useEffect(()=> {
        async function getCompanyInfo(){
            //자회사정보담아두기
            setCompanyList(await getCompanyList());
        }
        getCompanyInfo();
    }, [])

    /* 계약 종료일 세팅 */
    useEffect(() => {
        const { rentMonth, rentDays } = contractDays;
        const { startDate } = contractStartDate;
        EstimateDetailStore.setEstimateDetail({ rentMonth, rentDays });

        // 값이 있을때만 진행, 없으면 계약종료일 초기화
        if (!(startDate && (rentMonth || rentDays))) {
            setContractEndDate({ startDate: null, endDate: null });
            EstimateDetailStore.setEstimateDetail({ contractEndDate: null });
            return;
        }

        // 종료일 계산
        const endDate = contractUtils.contractEndDateFormat(startDate, rentMonth, rentDays);

        setContractEndDate({startDate: endDate, endDate: endDate});
        EstimateDetailStore.setEstimateDetail({contractEndDate: endDate});

    }, [contractDays, contractStartDate]);

    /* 담당자명 조회 */
    const getManager = async (customerNo) => {
        const result = await axios.get('/customer/manager', { params: {customerNo: customerNo}});
        EstimateDetailStore.estimateDetail.customerManagerSeq = result.customerManagerSeq;
        EstimateDetailStore.estimateDetail.managerName = result.managerName;
    }

    /* estimateNo 로 견적 조회 */
    const getEstimate = async (value) => {
        const result = await axios.get('/estimate', { params: {estimateNo: value}});
        if(result != null){
            result.contractStartDate ? setActiveKey('contract') : setActiveKey('item');
            
            // 핸드폰, 이메일 복호화
            result.phoneNumber = decrypt(result.phoneNumber);
            result.email = decrypt(result.email);
            EstimateDetailStore.setEstimateDetail(result);
            setContractDays({rentMonth: result.rentMonth, rentDays: result.rentDays});
            setContractDate({startDate: dateFormat(result.contractDate), endDate: dateFormat(result.contractDate)});
            setContractStartDate({startDate: dateFormat(result.contractStartDate), endDate: dateFormat(result.contractStartDate)});
            setContractEndDate({startDate: dateFormat(result.contractEndDate), endDate: dateFormat(result.contractEndDate)});
            setIsViewMode(result.status !== 1);
        }
    }

     /* 견적 제품 조회 */
    const getEstimateProductList = async () => {
        const result = await axios.get('/estimateProduct/list', { params: {estimateNo: estimateNo || EstimateDetailStore.estimateDetail.estimateNo} });

        if(result) {
            let updatedResult = result;
            if(activeKey === 'item') {
                updatedResult = result.map(row => {
                    const newRow = { ...row };
                
                    if (newRow.rentMonth) {
                        newRow.rentNumber = newRow.rentMonth;
                        newRow.monthWeekDay = 'month';
                    } else if (newRow.rentWeek) {
                        newRow.rentNumber = newRow.rentWeek;
                        newRow.monthWeekDay = 'week';
                    } else if (newRow.rentDays) {
                        newRow.rentNumber = newRow.rentDays;
                        newRow.monthWeekDay = 'day';
                    }
                
                    return newRow;
                });
            }
            EstimateDetailStore.setEstimateProductList(updatedResult);

            setEstimateProductList(updatedResult);
            EstimateDetailStore.setSeq(updatedResult.length === 0 ? 0 : Math.max(...updatedResult.map(v => v.seq)));  // 가장 큰 seq 값 찾기
        }
    }

    /* 계약 기간 월 = 숫자, 최대 두 자리 */
    const handleMonthsChange = (event) => {
        const rentMonth = contractUtils.handleMonthsChange(event.target.value);
        setContractDays({ ...contractDays, rentMonth });
    };

    /* 계약 기간 일 = 숫자, 최대 30 */
    const handleDaysChange = (event) => {
        const rentDays = contractUtils.handleDaysChange(event.target.value);
        setContractDays({ ...contractDays, rentDays });
    };

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        getManager(customerInfo.customerNo);
        EstimateDetailStore.setEstimateDetail({customerNo: customerInfo.customerNo, customerName: customerInfo.customerName});
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = (column) => {
        setUserColumn(column);
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo, column) => {
        if (!userInfo || !column) {
            return false;
        }
        EstimateDetailStore.setEstimateDetail({[`${column}Name`]: userInfo.name, [`${column}Seq`]: userInfo.userSeq});
        setUserColumn('');
    }

    /* 고객 담당자 호출 */
    const openSearchCustomerManagerPopup = () => {
        AppStore.toggleCustomerManagerSearchPopup();
    }

    /* 고객 담당자 팝업 콜백 */
    const getCustomerManager = (info) => {
        EstimateDetailStore.setEstimateDetail({
            customerManagerSeq: info.customerManagerSeq,
            managerName: info.managerName,
            billingTelephone: info.telephone,
            billingPhoneNumber: info.phoneNumber,
            billingEmail: info.email
        });
    }

    /* 청구지 조회 팝업 오픈 */
    const openSearchBillingAddressPopup = () => {
        AppStore.toggleCustomerBillingAddressSearchModal()
    }

    /* 청구지 정보 콜백 함수 */
    const getBillingAddressInfo = (info) => {
        EstimateDetailStore.setEstimateDetail({billingAddressSeq: info.billingAddressSeq, billingName: info.billingAddressName});
    }

     /* 견적 조회 팝업 오픈 */
    const openSearchEstimatePopup = () => {
        AppStore.toggleEstimateSearchModal();
    }

    /* 견적 정보 콜백 함수 */
    const getEstimateInfo = async (info) => {
        await getEstimate(info.estimateNo);
        EstimateDetailStore.setEstimateDetail({estimateNo: '', status: 1});
        setIsViewMode(false);
    }

    /* 숫자만 입력 가능한 input */
    const numberInputChange = (v, column) => {
        const value = v.target.value;
        if (!isNaN(value) && (column !== 'penaltyPercent' || value <= 100)) {
            EstimateDetailStore.setEstimateDetail({[column]: value});
        }
    }

    /* 번호 암호화 input */
    const encryptChange = (v, column) => {
        const value = v.target.value;
        if(column === 'billingPhoneNumber' && isNaN(value)) return;
        EstimateDetailStore.setEstimateDetail({[column]: encrypt(value)});
    }

    /* 계약일자, 계약개시일 클릭 */
    const onChangeDate = (v, type) => {
        if (type === 'contractDate') {
            if (!contractUtils.dateDiffContractDate(v.startDate, contractStartDate.startDate)) {
                showToast('계약개시일자는 계약일자보다 커야 합니다.');
                return false;
            }

            setContractDate(v);
            EstimateDetailStore.setEstimateDetail({contractDate: v.startDate});
        }

        if (type === 'contractStartDate') {
            if (!contractUtils.dateDiffContractDate(contractDate.startDate, v.startDate)) {
                showToast('계약개시일자는 계약일자보다 커야 합니다.');
                return false;
            }

            setContractStartDate(v);
            EstimateDetailStore.setEstimateDetail({contractStartDate: dateFormat(v.startDate)});
        }
    }

    /* 견적 상태 클릭 */
    const onChangeStatus = async (status) => {
        // 견적제품 연결 안되어있으면 견적발송완료 처리 불가
        if(estimateProductList.length === 0) {
            showToast('제품을 먼저 등록해 주세요.');
            return;
        }

        // 견적 완료 확인
        if (!await callConfirm(`견적을 ${status === 2 ? '완료' : '취소'} 하시겠습니까?`)) {
            return;
        }

        // 견적발송 미완료 상태인 경우만 상태값 변경
        if(EstimateDetailStore.estimateDetail.status === 1) {
            EstimateDetailStore.setEstimateDetail({status: status});
            await axios.put('/estimate/status', EstimateDetailStore.estimateDetail);

            showToast(`${status === 2 ? '완료' : '취소'}되었습니다`);
            setIsViewMode(true);
        }
    }

    /* 견적 저장 */
    const saveEstimate = async () => {
        // 필수값 확인
        if(!EstimateDetailStore.estimateDetail.customerName) {
            showToast('고객명을 입력해주세요.');
            return;
        }else if(activeKey === 'contract' && !EstimateDetailStore.estimateDetail.contractDate && !contractDate) {
            showToast('계약일자를 입력해주세요.');
            return;
        }else if(activeKey === 'contract' && !EstimateDetailStore.estimateDetail.contractStartDate) {
            showToast('개약개시일을 입력해주세요.');
            return;
        }else if(activeKey === 'contract' && !EstimateDetailStore.estimateDetail.contractEndDate) {
            showToast('계약기간을 입력해주세요.');
            return;
        }else if(!EstimateDetailStore.estimateDetail.managerName) {
            showToast('담당자명을 입력해주세요.');
            return;
        }

        // 저장 확인
        if (!await callConfirm('저장 하시겠습니까?')) {
            return;
        }

        let estimateNo = '';
        // 등록/수정 구분
        if(EstimateDetailStore.estimateDetail.estimateNo){
            estimateNo = EstimateDetailStore.estimateDetail.estimateNo;
            await axios.put('/estimate', EstimateDetailStore.estimateDetail);
            showToast('수정되었습니다.');
            getEstimateProductList();
        }else {
            estimateNo = await axios.post('/estimate', EstimateDetailStore.estimateDetail);
            EstimateDetailStore.setEstimateDetail({estimateNo: estimateNo});
            showToast('저장되었습니다.');
        }

        getEstimateList();
        getEstimate(estimateNo);
    }

    /* 견적 초기화 */
    const clearEstimate = async () => {
        if (!await callConfirm('초기화 하시겠습니까?')) return;
        EstimateDetailStore.clear();
        setContractDays({rentMonth: '', rentDays: ''});
        setContractDate({startDate: null, endDate: null});
        setContractStartDate({startDate: null, endDate: null});
        setContractEndDate({startDate: null, endDate: null});
    }

    /* 제품 추가 버튼 */
    const customAddBtnInfo = {
        isUsed: !!EstimateDetailStore.estimateDetail.estimateNo && !isViewMode,
        callbackFn: () => AppStore.toggleProductListSearchModal(),
        title: '제품조회추가',
        width: 100,
    };

    /* 제품 추가 콜백 함수 */
    const callbackProductInfo = (info) => {
        const addRow = {productSeq: info.productSeq, productName: info.productName, model: info.model,
                        estimateNo: EstimateDetailStore.estimateDetail.estimateNo, rentMonth: EstimateDetailStore.estimateDetail.rentMonth,
                        rentDays: EstimateDetailStore.estimateDetail.rentDays, spec: info.spec};
        agGridContainerRef.current.addRow(addRow);

    }

    const addRowInfo = {estimateNo: EstimateDetailStore.estimateDetail.estimateNo, seq: '', productName: '', model: '', productQty: '',
                        rentMonth: EstimateDetailStore.estimateDetail.rentMonth, rentDays: EstimateDetailStore.estimateDetail.rentDays,
                        monthRentPrice: '', dayRentPrice: '', lastMonthRentPrice: '', totalRentPrice: '', spec: '', remark: ''};

    /* 저장버튼 클릭 이벤트 */
    const callBackGridData = async (gridData) => {
        const { updatedList, createdList } = gridData;

        // 필수값 체크
        if(updatedList.some(item => !item.productName) || createdList.some(item => !item.productName)) {
            showToast('제품명을 입력해야 합니다.');
            return;
        }else if(activeKey === 'contract' && (updatedList.some(item => !item.productQty) || createdList.some(item => !item.productQty))) {
            showToast('수량을 입력해야 합니다.');
            return;
        }else if(activeKey === 'contract' && (updatedList.some(item => !item.totalRentPrice) || createdList.some(item => !item.totalRentPrice))) {
            showToast('대당 월렌탈료 혹은 마지막달 요금을 입력해야 합니다.');
            return;
        }else if(activeKey === 'item' && (updatedList.some(item => !item.rentNumber) || createdList.some(item => !item.rentNumber))) {
            showToast('기간을 입력해야 합니다.');
            return;
        }else if(activeKey === 'item' && (updatedList.some(item => !item.monthWeekDay) || createdList.some(item => !item.monthWeekDay))) {
            showToast('월/주/일을 선택해야 합니다.');
            return;
        }else if(activeKey === 'item' && (updatedList.some(item => !item.monthRentPrice) || createdList.some(item => !item.monthRentPrice))) {
            showToast('대당 월렌탈료를 입력해야 합니다.');
            return;
        }

        // 저장 확인
        if (!await callConfirm('저장 하시겠습니까?')) return;

        // 필수값 담기
        const requiredParams = ['productName', 'productQty', 'totalRentPrice'];
        const requiredItemParams = ['productName', 'rentNumber', 'monthWeekDay', 'monthRentPrice'];

        let seq = EstimateDetailStore.seq;

        if (activeKey === 'item') {
            gridData.createdList = updateListData(gridData.createdList);
            gridData.updatedList = updateListData(gridData.updatedList);
        }

        if(gridData.updatedList.length > 0) {
            // 필수값 체크
            if (activeKey === 'contract' && gridData.updatedList.some(item => !requiredParams.every(param => item[param]))) {
                showToast('필수값을 모두 입력해야 합니다.');
                return;
            }else if(activeKey === 'item' && gridData.updatedList.some(item => !requiredItemParams.every(param => item[param]))) {
                showToast('필수값을 모두 입력해야 합니다.');
                return;
            }

            await axios.put('/estimateProduct', {estimateProductList: gridData.updatedList});
        }else if(gridData.createdList.length > 0) {
            // 필수값 체크
            if (activeKey === 'contract' && gridData.createdList.some(item => !requiredParams.every(param => item[param]))) {
                showToast('필수값을 모두 입력해야 합니다.');
                return;
            }else if(activeKey === 'item' && gridData.createdList.some(item => !requiredItemParams.every(param => item[param]))) {
                showToast('필수값을 모두 입력해야 합니다.');
                return;
            }

            const createdList = gridData.createdList.map(v => {v.seq = ++seq; return v});   // seq + 1 해주고 리스트에 담기
            await axios.post('/estimateProduct', {estimateProductList: createdList});
        }

        showToast('저장되었습니다.');
        Promise.all([getEstimateList(), getEstimateProductList()]);
    }

    const updateListData = (list) => {
        return list.map(row => {
            let updatedRow = { ...row };
            
            switch (row.monthWeekDay) {
                case 'day':
                    updatedRow.rentMonth = null;
                    updatedRow.rentWeek = null;
                    updatedRow.rentDays = row.rentNumber;
                    updatedRow.totalRentPrice = null;
                    break;
                case 'week':
                    updatedRow.rentMonth = null;
                    updatedRow.rentWeek = row.rentNumber;
                    updatedRow.rentDays = null;
                    updatedRow.totalRentPrice = null;
                    break;
                case 'month':
                    updatedRow.rentMonth = row.rentNumber;
                    updatedRow.rentWeek = null;
                    updatedRow.rentDays = null;
                    updatedRow.totalRentPrice = null;
                    break;
                default:
                    updatedRow.rentMonth = null;
                    updatedRow.rentWeek = null;
                    updatedRow.rentDays = null;
                    updatedRow.totalRentPrice = null;
            }
    
            return updatedRow;
        });
    }

    /* print */
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: EstimateDetailStore.estimateDetail.estimateNo+'_'+EstimateDetailStore.estimateDetail.customerName+'_견적서_'+today(), // 파일명 지정(견적번호_고객명_견적서_오늘날짜)
    });

    /* totalRentPrice 값 담아주기 */
    const totalRentPrice = (v) => {
        const totalPrice = v.value;
        v.setValue(totalPrice);

        return (
            <>
                {totalPrice&&totalPrice.toLocaleString()}
            </>
        )
    }

    /* 월 렌탈료, 일 렌탈료 이벤트 */
    const getCellChange = (e) => {
        const { field } = e.colDef;
        const { rentDays, rentMonth, monthRentPrice, dayRentPrice, lastMonthPrice } = e.data;

        if(field === "monthRentPrice" || field === "lastMonthPrice"  || field === "dayRentPrice" || !LUtils.isEmpty(e.value)) {
            if (rentMonth && !rentDays) {
                if (field === "lastMonthPrice" || field === "dayRentPrice") {
                    // row가 isUpdated = true && 기존 update 이력이 없으면 isUpdated = false
                    if(e.data[IS_UPDATED] && !isUpdated) e.data[IS_UPDATED] = false;

                    e.data[field] = e.oldValue; // 값 원래대로 돌리기
                    e.data.c.y = null;    // ag grid에서 사용하는 내부 컬럼값. 컬럼 변경 여부 계산 기준이 되는 컬럼
                    gridRef.current.api.redrawRows();
                    showToast('월 렌탈료만 입력 가능합니다.');

                    return;
                }
            } else if (!rentMonth && rentDays) {
                if (field === "monthRentPrice" && (lastMonthPrice || dayRentPrice)) {
                    // row가 isUpdated = true && 기존 update 이력이 없으면 isUpdated = false
                    if(e.data[IS_UPDATED] && !isUpdated) e.data[IS_UPDATED] = false;

                    e.data[field] = e.oldValue; // 값 원래대로 돌리기
                    e.data.c.y = null;    // ag grid에서 사용하는 내부 컬럼값. 컬럼 변경 여부 계산 기준이 되는 컬럼
                    gridRef.current.api.redrawRows();
                    showToast('월과 일 렌탈료 둘중 하나만 입력 가능합니다.');

                    return;
                } else if ((field === "lastMonthPrice" || field === "dayRentPrice") && monthRentPrice) {
                    // row가 isUpdated = true && 기존 update 이력이 없으면 isUpdated = false
                    if(e.data[IS_UPDATED] && !isUpdated) e.data[IS_UPDATED] = false;

                    e.data[field] = e.oldValue; // 값 원래대로 돌리기
                    e.data.c.y = null;    // ag grid에서 사용하는 내부 컬럼값. 컬럼 변경 여부 계산 기준이 되는 컬럼
                    gridRef.current.api.redrawRows();
                    showToast('월과 일 렌탈료 둘중 하나만 입력 가능합니다.');

                    return;
                }
            }
        }

        if(e.data[IS_UPDATED]) setIsUpdated(true);

        agGridContainerRef.current.allRowCheckBoxChecker();
    }

    /* 견적서 메일 전송 팝업 */
    const sendEstimatePopup = () => {
        AppStore.toggleSendEstimatePopup();
    }

    /* 견적서 메일 전송 */
    const sendEstimate = async () => {
        if(!await callConfirm('메일 전송 하시겠습니까?')) return;
        const input = contentRef.current;
        const fileName = `${EstimateDetailStore.estimateDetail.customerName}_${today()}_${EstimateDetailStore.estimateDetail.estimateNo}_견적서.pdf`

        if (!input) {
            console.error('Ref not found');
            return;
        }
        if(!EstimateDetailStore.receiverEmail) {
            showToast('받는사람 이메일을 적어주세요.');
            return;
        }
        input.style.display = 'block';

        const options = {
            filename: fileName,
            image: {type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        }
        
        const body = document.querySelector('body');
        body.style.lineHeight = '0.3';

        const estimateBody = body.getElementsByClassName('estimateBody');
        estimateBody.forEach (v => {
            v.style.lineHeight=1.5;
        })

        const pdfBlob = await html2pdf().set(options).from(input).toPdf().output('blob').then(data => {
            return data
        });

        await deleteStyle(body);

        const blob = new Blob([pdfBlob], { type: 'application/pdf' });
        // FormData를 사용하여 파일을 생성
        const formData = new FormData();
        
        formData.append('file', new Blob([blob]), fileName);
        formData.append('subject', EstimateDetailStore.subject);
        formData.append('content', EstimateDetailStore.content);
        formData.append('receiverEmail', encrypt(EstimateDetailStore.receiverEmail));

        await axios.post('/estimate/email', formData, { headers: MULTIPART_HEADER });
        input.style.display = 'none';
        showToast('전송이 완료되었습니다.');
        AppStore.toggleSendEstimatePopup();
    }

    const deleteStyle = async (body) => {
        body.style.lineHeight = 'normal';
    }

    const clickTab = (v) => {
        setActiveKey(v);
        // 계약 기간 초기화
        if(v === 'item') {
            setContractDays({ rentMonth: '', rentDays: '' });
            setContractDate({ startDate: today(), endDate: today() });
            setContractStartDate({ startDate: null, endDate: null });
            setContractEndDate({ startDate: null, endDate: null });
            EstimateDetailStore.setEstimateDetail({rentMonth: null, rentDays: null, contractDate: today(), contractStartDate: null, contractEndDate: null});
        }
    }

    return(
        <>
            <div style={{marginBottom: 50, maxWidth: 2000}}>
                {!EstimateDetailStore.estimateDetail.estimateNo &&
                    <CContainer>
                        <Row>
                            <Tabs activeKey={activeKey} onSelect={clickTab} className="mb-3" mountOnEnter={true} unmountOnExit={true} >
                                <Tab eventKey="item" title="제품 기간에 따른 견적 작성">

                                </Tab>
                                <Tab eventKey="contract" title="계약 기간에 따른 견적 작성">
                                
                                </Tab>
                            </Tabs>
                        </Row>
                    </CContainer>
                }

                <CContainer search>
                    <Row>
                        <CCol lg={4}>
                            <CSelectGroup label={'회사'} labelId={'companyName'} 
                                        options={companyList}
                                        disabled={companyList.length === 1 || isViewMode}
                                        value={EstimateDetailStore.estimateDetail.companyCode}
                                        onChange={(e) => {EstimateDetailStore.setEstimateDetail({companyCode: e.target.value})}}
                            />
                        </CCol>
                        <CCol lg={2}>
                            <CInputGroup label={'견적번호'} labelId={'estimateNo'} disabled={true}
                                        value={EstimateDetailStore.estimateDetail.estimateNo}
                            />
                        </CCol>
                        {!EstimateDetailStore.estimateDetail.estimateNo && (
                            <CCol lg={2}>
                                <Button size="sm" className="btn-outline-primary" variant="none" onClick={openSearchEstimatePopup}>
                                    {'이전 견적 불러오기'}
                                </Button>
                            </CCol>
                        )}
                    </Row>
                    <Row>
                        <CCol lg={4}>
                            <CSelectGroup label={'계약유형'} labelId={'contractType'}
                                        placeholder={'계약유형'}
                                        options={getCommcode('1')}
                                        value={EstimateDetailStore.estimateDetail.contractType || ''}
                                        onChange={(e) => {EstimateDetailStore.setEstimateDetail({contractType: e.target.value})}}
                                        disabled={isViewMode}
                                        labelClassName='input-required'
                            />
                        </CCol>
                        <CCol lg={4}>
                            <CInputDoubleGroup label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                                placeholder={'고객코드'} placeholder2={'고객명'}
                                disabled={true} disabled2={isViewMode}
                                onCallbackBtn={openSearchCustomerPopup}
                                btnDisabled={isViewMode}
                                value={EstimateDetailStore.estimateDetail.customerNo || ''}
                                value2={EstimateDetailStore.estimateDetail.customerName || ''}
                                onChange2={(e) => {EstimateDetailStore.setEstimateDetail({customerName: e.target.value})}}
                                isValid='input-required'
                            />
                        </CCol>
                        <CCol lg={3}>
                        <CInputGroup label={'영업담당자'} labelId={'saleUserName'}
                                    value={EstimateDetailStore.estimateDetail.saleUserName}
                                    onCallbackBtn={() => openSearchUserPopup('saleUser')}
                                    disabled={true}
                                    btnDisabled={isViewMode}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        {activeKey === 'contract' &&
                            <>
                                <CCol lg={2}>
                                    <Form.Label className="input-required" htmlFor="rentMonth">계약기간</Form.Label>
                                    <InputGroup size={'sm'}>
                                        <Form.Control
                                            style={{ flex: 1, textAlign: 'right' }}
                                            aria-describedby={'rentMonth'}
                                            value={contractDays.rentMonth || ''}
                                            onChange={handleMonthsChange}
                                            disabled={isViewMode}
                                        />
                                        <InputGroup.Text>개월</InputGroup.Text>
                                        <Form.Control
                                            style={{ flex: 1, textAlign: 'right' }}
                                            aria-describedby={'rentDays'}
                                            value={contractDays.rentDays || ''}
                                            onChange={handleDaysChange}
                                            disabled={isViewMode}
                                        />
                                        <InputGroup.Text>일</InputGroup.Text>
                                    </InputGroup>
                                </CCol>
                                <CCol lg={2}>
                                    <CalendarWithLabel asSingle={true} label={'계약일자'}
                                                    value={contractDate}
                                                    onChange={(e) => {onChangeDate(e, 'contractDate')}}
                                                    disabled={isViewMode}
                                                    isValid='input-required'
                                    />
                                </CCol>
                                <CCol lg={2}>
                                    <CalendarWithLabel asSingle={true}  label={'계약개시일'}
                                                    value={contractStartDate}
                                                    onChange={(e) => {onChangeDate(e, 'contractStartDate')}}
                                                    disabled={isViewMode}
                                                    isValid='input-required'
                                    />
                                </CCol>
                                <CCol lg={2}>
                                    <CalendarWithLabel asSingle={true}  label={'계약종료일'}
                                                    value={contractEndDate}
                                                    disabled={true}
                                                    isValid='input-required'
                                    />
                                </CCol>
                            </>
                        }
                        <CCol lg={4}>
                            <Form.Label htmlFor="billingSeqName">청구지</Form.Label>
                            <CInputDoubleGroup label={'청구지'} labelId={'billingAddressSeq'} labelId2={'billingName'}
                                            value={EstimateDetailStore.estimateDetail.billingAddressSeq || ''}
                                            value2={EstimateDetailStore.estimateDetail.billingName || ''}
                                            disabled={true} disabled2={true}
                                            btnDisabled={isViewMode}
                                            onCallbackBtn={openSearchBillingAddressPopup}
                                            onChange2={(e) => {EstimateDetailStore.setEstimateDetail({billingName: e.target.value})}}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <Subject>담당자 정보</Subject>
                        <CCol lg={3}>
                            <CInputGroup label={'담당자명'} labelId={'managerName'} disabled={isViewMode}
                                        value={EstimateDetailStore.estimateDetail.managerName || ''}
                                        onCallbackBtn={openSearchCustomerManagerPopup}
                                        onChange={(e) => {EstimateDetailStore.setEstimateDetail({managerName: e.target.value, customerManagerSeq: ''})}}
                                        labelClassName='input-required'
                            />
                        </CCol>
                        <CCol lg={3}>
                            <CInputGroup label={'전화번호'} labelId={'telephone'} disabled={isViewMode}
                                        value={EstimateDetailStore.estimateDetail.billingTelephone || ''}
                                        onChange={(e) => {numberInputChange(e, 'billingTelephone')}}
                            />
                        </CCol>
                        <CCol lg={3}>
                            <CInputGroup label={'휴대폰'} labelId={'phoneNumber'} disabled={isViewMode}
                                        value={EstimateDetailStore.estimateDetail.billingPhoneNumber}
                                        onChange={(e) => EstimateDetailStore.setEstimateDetail({billingPhoneNumber: e.target.value})}
                            />
                        </CCol>
                        <CCol lg={3}>
                            <CInputGroup label={'이메일'} labelId={'email'} disabled={isViewMode}
                                        value={EstimateDetailStore.estimateDetail.billingEmail}
                                        onChange={(e) => EstimateDetailStore.setEstimateDetail({billingEmail: e.target.value})}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <Subject>특약사항</Subject>
                        <CCol lg={3} >
                            <Form.Check
                                type="switch"
                                id="isPenaltyFee"
                                label="위약금청구"
                                style={{marginBottom: 8}}
                                checked={EstimateDetailStore.estimateDetail.isPenaltyFee == '1' ? true : false}
                                onChange={(e) => {
                                    EstimateDetailStore.setEstimateDetail({isPenaltyFee: e.target.checked ? '1' : '0'});
                                    if(!e.target.checked) EstimateDetailStore.setEstimateDetail({penaltyPercent: ''});
                                }}
                                disabled={isViewMode}
                            />
                            <InputGroup size={'sm'}>
                            <CSelectGroup
                                label={'잔여 렌탈료의'}
                                options={Array.from({ length: 9 }, (_, index) => ({ value: `${(index + 1) * 10}`, name: `${(index + 1) * 10}%` }))}
                                value={EstimateDetailStore.estimateDetail.penaltyPercent || '40'}
                                onChange={(e) => {
                                    const selectedPercent = parseInt(e.target.value.replace('%', ''));
                                    EstimateDetailStore.estimateDetail.penaltyPercent = selectedPercent;
                                }}
                                disabled={isViewMode || EstimateDetailStore.estimateDetail.isPenaltyFee == '0'}
                            />
                            </InputGroup>
                        </CCol>
                        <CCol lg={3}>
                            <Form.Check
                                type="switch"
                                id="isDeliveryPrice"
                                label="운반비 청구"
                                style={{marginBottom: 5}}
                                checked={EstimateDetailStore.estimateDetail.isDeliveryPrice == '1' ? true : false}
                                onChange={(e)=> {
                                    EstimateDetailStore.setEstimateDetail({isDeliveryPrice: e.target.checked ? '1' : '0'});
                                    if(!e.target.checked) EstimateDetailStore.setEstimateDetail({deliveryPrice: ''});
                                }}
                                disabled={isViewMode}
                            />
                            <InputGroup size={'sm'}>
                                <Form.Control
                                    style={{flex: 1, textAlign: 'right'}}
                                    max={2}
                                    aria-describedby={'deliveryPrice'}
                                    value={EstimateDetailStore.estimateDetail.deliveryPrice || 0}
                                    onChange={(v)=> EstimateDetailStore.estimateDetail.deliveryPrice = v.target.value}
                                    disabled={isViewMode || EstimateDetailStore.estimateDetail.isDeliveryPrice == '0'}
                                    />
                                <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원(부가세 별도)</InputGroup.Text>
                            </InputGroup>
                        </CCol>
                        <CCol lg={3}>
                            <Form.Check
                                type="switch"
                                id="isInstallPrice"
                                label="설치비 청구"
                                style={{marginBottom: 5}}
                                checked={EstimateDetailStore.estimateDetail.isInstallPrice == '1' ? true : false}
                                onChange={(e)=> {
                                    EstimateDetailStore.setEstimateDetail({isInstallPrice: e.target.checked ? '1' : '0'});
                                    if(!e.target.checked) EstimateDetailStore.setEstimateDetail({installPrice: ''});
                                }}
                                disabled={isViewMode}
                            />
                            <InputGroup size={'sm'}>
                                <Form.Control
                                    style={{flex: 1, textAlign: 'right'}}
                                    max={2}
                                    aria-describedby={'installPrice'}
                                    value={EstimateDetailStore.estimateDetail.installPrice || 0}
                                    onChange={(v)=> EstimateDetailStore.estimateDetail.installPrice = v.target.value}
                                    disabled={isViewMode || EstimateDetailStore.estimateDetail.isInstallPrice == '0'}
                                    />
                                <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원(부가세 별도)</InputGroup.Text>
                            </InputGroup>
                        </CCol>
                        {EstimateDetailStore.estimateDetail.contractType == '2' &&
                            <>
                                <CCol lg={2}>
                                    <Form.Check
                                        type="switch"
                                        id="isFreeAssignment"
                                        label="무상양도"
                                        style={{marginBottom: 8}}
                                        checked={EstimateDetailStore.estimateDetail.isFreeAssignment == '1' ? true : false}
                                        onChange={(e) => {EstimateDetailStore.setEstimateDetail({isFreeAssignment: e.target.checked ? '1' : '0'})}}
                                        disabled={isViewMode}
                                    />
                                    <InputGroup size={'sm'}>
                                        <Form.Control
                                            style={{flex: 1, textAlign: 'right'}}
                                            max={2}
                                            aria-describedby={'assignmentAmount'}
                                            value={EstimateDetailStore.estimateDetail.assignmentAmount || 0}
                                            onChange={(v)=> EstimateDetailStore.setEstimateDetail(v.target.value, 'assignmentAmount')}
                                            disabled={isViewMode}
                                        />
                                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원(부가세 별도)</InputGroup.Text>
                                    </InputGroup>
                                </CCol>
                                <CCol lg={2}>
                                    <Form.Check
                                        type="switch"
                                        id="isRepairCost"
                                        label="유상 A/S"
                                        style={{marginBottom: 8}}
                                        checked={EstimateDetailStore.estimateDetail.isRepairCost == '1' ? true : false}
                                        onChange={(e) => {EstimateDetailStore.setEstimateDetail({isRepairCost: e.target.checked ? '1' : '0'})}}
                                        disabled={isViewMode}
                                    />
                                    <Form.Text id="passwordHelpBlock" muted>고객과실: 유상, 부품결함: 무상</Form.Text>
                                </CCol>
                            </>
                        }
                        <CCol lg={9}>
                            <div className="mb-3">
                            <label className="form-label">특이사항</label>
                            <textarea
                                className="form-control"
                                rows="2"
                                value={EstimateDetailStore.estimateDetail.specialRemark || ''}
                                onChange={(e) => {EstimateDetailStore.setEstimateDetail({specialRemark: e.target.value})}}
                                disabled={isViewMode}
                            />
                            </div>
                        </CCol>
                    </Row>
                    <Row>
                        <Subject>결제조건</Subject>
                        <CCol lg={3}>
                            <Form.Label className="input-required">청구조건</Form.Label>
                            <InputGroup size={'sm'}>
                                <Form.Select aria-label="선택" style={{width: '55%'}}
                                            value={EstimateDetailStore.estimateDetail.billingType}
                                            onChange={(e) => {EstimateDetailStore.setEstimateDetail({billingType: e.target.value})}}
                                            disabled={isViewMode}

                                >
                                    {getCommcode('21').map(option => (
                                        <option key={option.value} value={option.value}>{option.name}</option>
                                    ))}
                                </Form.Select>
                                <Form.Select aria-label="선택"
                                            value={EstimateDetailStore.estimateDetail.billingDay}
                                            onChange={(e) => {EstimateDetailStore.setEstimateDetail({billingDay: e.target.value})}}
                                            disabled={isViewMode}
                                >
                                    {days.map((d,i) => {
                                        return (<option key={i} value={d.value}>{d.text}</option>);
                                    })}
                                </Form.Select>
                            </InputGroup>
                        </CCol>
                        <CCol lg={3}>
                            <Form.Label className="input-required">납기조건</Form.Label>
                            <InputGroup size={'sm'}>
                                <Form.Select aria-label="선택" style={{width: '55%'}}
                                            value={EstimateDetailStore.estimateDetail.dueDateType}
                                            onChange={(e) => {EstimateDetailStore.setEstimateDetail({dueDateType: e.target.value})}}
                                            disabled={isViewMode}
                                >
                                    {getCommcode('22').map(option => (
                                        <option key={option.value} value={option.value}>{option.name}</option>
                                    ))}
                                </Form.Select>
                                <Form.Select aria-label="선택"
                                            value={EstimateDetailStore.estimateDetail.dueDateDay}
                                            onChange={(e) => {EstimateDetailStore.setEstimateDetail({dueDateDay: e.target.value})}}
                                            disabled={isViewMode}
                                >
                                    {days.map((d,i) => {
                                        return (<option key={i} value={d.value}>{d.text}</option>);
                                    })}
                                </Form.Select>
                            </InputGroup>
                        </CCol>
                        <CCol lg={2}>
                            <Form.Label className="input-required">결제방식</Form.Label>
                            <InputGroup size={'sm'}>
                                <Form.Select aria-label="선택"
                                            value={EstimateDetailStore.estimateDetail.paymentType}
                                            onChange={(e) => {EstimateDetailStore.setEstimateDetail({paymentType: e.target.value})}}
                                            disabled={isViewMode}
                                >
                                    {getCommcode('17').map(option => (
                                        <option key={option.value} value={option.value}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </CCol>
                        <CCol lg={3}>
                            <Form.Label>보증금</Form.Label>
                            <InputGroup size={'sm'}>
                                <Form.Control style={{flex: 1, textAlign: 'right'}} max={2} aria-describedby={'deposit'}
                                            value={EstimateDetailStore.estimateDetail.deposit || ''}
                                            onChange={(e) => {numberInputChange(e, 'deposit')}}
                                            disabled={isViewMode}
                                />
                                <InputGroup.Text>원</InputGroup.Text>
                            </InputGroup>
                        </CCol>
                    </Row>
                    {/* 계약유형 양도형인 경우만 선택 가능 */}
                    {/* {EstimateDetailStore.estimateDetail.contractType === '2' &&(
                        <Row>
                            <CCol lg={2}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="무상양도"
                                    style={{paddingTop: 8}}
                                    checked={EstimateDetailStore.estimateDetail.isFreeAssignment == '1' ? true : false}
                                    onChange={(e) => {EstimateDetailStore.setEstimateDetail({isFreeAssignment: e.target.checked ? '1' : '0'})}}
                                    disabled={isViewMode}
                                />
                            </CCol>
                        </Row>
                    )} */}
                    <Row>
                        <CCol lg={6}>
                            <div className="mb-3">
                                <label className="form-label">비고</label>
                                <textarea
                                    className="form-control"
                                    rows="2"
                                    value={EstimateDetailStore.estimateDetail.remark || ''}
                                    onChange={(e) => {EstimateDetailStore.setEstimateDetail({remark: e.target.value})}}
                                    disabled={isViewMode}
                                />
                            </div>
                        </CCol>
                    </Row>
                    <Row>
                        <Col className='d-flex flex-row-reverse'>
                            {/* TODO: 추후 추가 */}
                            {/* <CSaveBtn style={{width: 100}} title={'계약생성'}/> */}
                            {(!isViewMode && (EstimateDetailStore.estimateDetail.creator === userInfo.userSeq || !EstimateDetailStore.estimateDetail.creator)) && (
                                <>
                                    <CCancelBtn onClick={clearEstimate} title={'초기화'}/>
                                    <CSaveBtn onClick={saveEstimate} title={'견적저장'}/>
                                </>
                            )}
                            {EstimateDetailStore.estimateDetail.estimateNo !== '' && EstimateDetailStore.estimateDetail.status === 1 && (
                                <>
                                    <CIconBtn
                                        icon={'fi-rs-receipt'}
                                        style={{width: 110}}
                                        title={'견적 완료'}
                                        variant={'primary'}
                                        onClick={()=> onChangeStatus(2)}
                                    />
                                    <CIconBtn
                                        icon={'fi-br-cross-circle'}
                                        style={{width: 110}}
                                        title={'견적 취소'}
                                        variant={'danger'}
                                        onClick={()=> onChangeStatus(9)}
                                    />
                                </>
                            )}
                            {EstimateDetailStore.estimateDetail.estimateNo !== '' && (
                                <>
                                    <Button size="sm" className="btn-outline-primary mr-2" variant="none" style={{height: 30}} onClick={handlePrint}>{'견적서출력하기'}</Button>
                                    <ReportEstimateTemplate ref={componentRef} mode={activeKey} />
                                    <Button size="sm" className="btn-outline-primary" variant="none" style={{marginRight: 12, height: 30}} onClick={()=> sendEstimatePopup()}>
                                        {'이메일 전송'}
                                    </Button>
                                    
                                </>
                            )}
                        </Col>
                    </Row>
                </CContainer>
                <CContainer>
                    {/* grid */}
                    <Row>
                        <Subject>견적제품목록</Subject>
                        <AgGridContainer
                            ref={agGridContainerRef}
                            gridRef={gridRef}
                            height={30}
                            rowData={EstimateDetailStore.estimateProductList}
                            columnDefs={[
                                {field: "estimateNo", headerName: "견적번호", hide: true},
                                {field: "seq", headerName: "시퀀스", hide: true},
                                {field: "productSeq", headerName: "제품 시퀀스", hide: true},
                                {
                                    field: "productName",
                                    headerName: "제품명",
                                    minWidth: 300,
                                    editable: true,
                                    headerClass: 'grid-column-required',
                                },
                                {field: "model", headerName: "모델명", minWidth: 150, editable: true},
                                {
                                    field: "productQty",
                                    headerName: "수량",
                                    minWidth: 50,
                                    editable: true,
                                    headerClass: 'grid-column-required',
                                    hide: activeKey === 'item'
                                },
                                {field: "rentNumber", headerName: "기간", minWidth: 50, hide: activeKey === 'contract', headerClass: 'grid-column-editable', editable: true},
                                {
                                    field: "monthWeekDay",
                                    headerName: "월/주/일",
                                    minWidth: 150,
                                    headerClass: 'grid-column-editable',
                                    cellRenderer: (params) => {
                                        return (
                                          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            <label style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                              <input
                                                type="radio"
                                                name={`monthWeekDay_${params.node.id}`}
                                                value="month"
                                                checked={params.value === 'month'}
                                                onChange={() => params.setValue('month')}
                                              /> 월
                                            </label>
                                            <label style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                              <input
                                                type="radio"
                                                name={`monthWeekDay_${params.node.id}`}
                                                value="week"
                                                checked={params.value === 'week'}
                                                onChange={() => params.setValue('week')}
                                              /> 주
                                            </label>
                                            <label style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                              <input
                                                type="radio"
                                                name={`monthWeekDay_${params.node.id}`}
                                                value="day"
                                                checked={params.value === 'day'}
                                                onChange={() => params.setValue('day')}
                                              /> 일
                                            </label>
                                          </div>
                                        );
                                    },
                                    hide: activeKey === 'contract'
                                },
                                {field: "rentMonth", headerName: "대여월", minWidth: 50, hide: activeKey === 'item'},
                                {field: "rentDays", headerName: "대여일", minWidth: 50, hide: activeKey === 'item'},
                                {
                                    field: "monthRentPrice",
                                    headerName: "대당 월렌탈료",
                                    editable: true,
                                    minWidth: 130,
                                    cellClass: 'ag-grid-money-align',
                                    headerClass: 'grid-column-editable',
                                    valueFormatter: v => v.value?.toLocaleString()
                                },
                                {
                                    field: "dayRentPrice",
                                    headerName: "대당 일렌탈료",
                                    editable: true,
                                    minWidth: 130,
                                    cellClass: 'ag-grid-money-align',
                                    headerClass: 'grid-column-editable',
                                    valueFormatter: v => v.value?.toLocaleString(),
                                    hide: activeKey === 'item'
                                },
                                {
                                    field: "lastMonthPrice",
                                    headerName: "마지막달 요금",
                                    editable: true,
                                    minWidth: 130,
                                    cellClass: 'ag-grid-money-align',
                                    headerClass: 'grid-column-editable',
                                    hide: true,
                                    valueFormatter: v => v.value?.toLocaleString()
                                },
                                {
                                    field: "totalRentPrice",
                                    headerName: "총 렌탈료",
                                    valueGetter: (e) => {
                                        const params = {
                                            rentMonth: e.data.rentMonth,
                                            rentDays: e.data.rentDays,
                                            monthRentPrice: e.data.monthRentPrice,
                                            dayRentPrice: e.data.dayRentPrice,
                                            lastMonthPrice: e.data.lastMonthPrice,
                                            qty: e.data.productQty,
                                        };

                                        return contractUtils.calculateTotalPrice(params);
                                    },
                                    cellRenderer: totalRentPrice,
                                    minWidth: 130,
                                    cellClass: 'ag-grid-money-align',
                                    hide: activeKey === 'item'
                                },
                                {field: "spec", headerName: "스펙", editable: true, minWidth: 200},
                                {field: "remark", headerName: "비고", editable: true, minWidth: 200, flex: 1},
                            ]}
                            addRowInfo={addRowInfo}
                            isCheckBox={true}
                            originList={estimateProductList}
                            useCreated={!!EstimateDetailStore.estimateDetail.estimateNo && !isViewMode}
                            useUpdated={!!EstimateDetailStore.estimateDetail.estimateNo && !isViewMode}
                            useIntoTheTab={true}
                            callBackGridData={callBackGridData}
                            customBtnInfo={[customAddBtnInfo]}
                            btnTitles={{add: '수기입력'}}
                            getCellChange={getCellChange}
                            rowStyle={{marginTop: EstimateDetailStore.estimateDetail.estimateNo && isViewMode ? 22 : 0}}
                        />
                    </Row>
                </CContainer>

                {/* 검색모달 */}
                <CustomerSearch callbackFn={getCustomerInfo}/>
                <UserSearch callbackFn={getUserInfo} column={userColumn}/>
                <CustomerBillingAddressSearch customerNo={EstimateDetailStore.estimateDetail.customerNo} callbackFn={getBillingAddressInfo} />
                <EstimateSearch callbackFn={getEstimateInfo} />
                <ProductListSearch callbackFn={callbackProductInfo}/>
                <CustomerManagerSearch callbackFn={getCustomerManager} customerNo={EstimateDetailStore.estimateDetail.customerNo} />
            </div>
            <SendEstimatePopup sendEstimate={sendEstimate} />
            <PDFEstimateTemplate ref={contentRef} mode={activeKey}/>
        </>
    );
}

export default observer(EstimateDetail);
