import { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';

import { CContainer } from '../../components/CustomContainer';
import AgGridContainer from '../../components/AgGridContainer';
import { ShipStore } from '../../store/ship/ShipStore';
import { yesterday, oneMonthAfter } from '../../utils/dateUtils';
import LUtils from '../../utils/lodashUtils';
import { callConfirm } from '../../utils';
import { findCommKrnm, getCommcode } from "../../utils/commcode";
import { AppStore } from "../../store/AppStore";
import {decrypt, showToast} from '../../common/utils';
import { ICON_TRASH } from '../../common/constants';
import { IconBtn } from '../../components/CustomContainer';
import ConfirmPopup from './popup/ConfirmPopup';
import ShipListSearch from './ShipListSearch';
import OutsourcingInfoPopup from './popup/OutsourcingInfoPopup';
import ShipManagerPopup from './popup/ShipManagerPopup';

const ShipList = () => {
    const gridRef = useRef();

    const [originList, setOriginList] = useState([]);
    const [userKind, setUserKind] = useState('');
    const [gridData, setGridData] = useState([]);
    const [shipSeq, setShipSeq] = useState('');
    const [isCheckedSetting, setIsCheckedSetting] = useState(false);

    useEffect(() => {
        ShipStore.search.startDate = yesterday();
        ShipStore.search.endDate = oneMonthAfter(yesterday());
    }, []);

    const getShipList = async () => {
        const result = await axios.get("ship/list", { params: ShipStore.search });
        const originData = result?.map(v => LUtils.assign(v, { isChecked: false }));  // isChecked값을 추가
        ShipStore.setShipList(originData);
        setOriginList(originData);
        if(result.length === 0) showToast('조회된 데이터가 없습니다.');
    }

    /* 사원 팝업 UI */
    const popupUserInfo = (e) => {
        return (
            <>
                {e.value !== 'pinnedTopRowData' && 
                    <div className='d-flex justify-content-between'>
                        <div>{e.data.deliveryUserName}</div>
                        <IconBtn key={e.rowIndex} style={{paddingTop: 6}} onClick={() => {setUserKind('delivery'), openUserSearchPopup(e)}} />
                    </div>
                }
            </>
        )
    }

    /* 사원 조회 팝업 */
    const openUserSearchPopup = (e) =>{
        AppStore.toggleUserSearchModal();
        setGridData(e);
    }

    /* 배송출발 버튼 */
    const customDepartBtn = {
        isUsed: true,
        callbackFn:() => updateShipStatus('4'),
        icon: 'fi fi-br-running',
        title: '배송출발 및 문자발송',
        width: 160,
        color: 'black',
    }

    /* 배송완료 버튼 */
    const customCompleteBtn = {
        isUsed: true,
        callbackFn:() => updateShipStatus('5'),
        icon: 'fi fi-br-box-check',
        title: '배송완료',
        width: 110,
        color: 'black',
    }

    /* 취소 버튼 */
    const customCancelBtn = {
        isUsed: true,
        callbackFn:() => updateShipStatus('9'),
        icon: ICON_TRASH,
        title: '배송취소',
        variant: 'danger',
        width: 110,
    }

    /* 배송 담당자 지정 버튼 */
    const customShipManagerBtn = {
        isUsed: true,
        callbackFn:() => updateShipManager(),
        icon: 'fi fi-ss-user',
        title: '배송 담당자 지정',
        width: 150,
    }

    /* 배송 담당자 지정 */
    const updateShipManager = () => {
        // 배송 방법이 같은지 확인 아니면 return && 배송 상태가 배송 출발 전 상태인지 확인 아니면 return
        const selectedList = ShipStore.shipList.filter(v => v.isSelected);

        if(selectedList.length < 1) {
            return showToast('행을 선택해 주세요.');
        }
        if(selectedList.some(item => Number(item.shipStatus) >= 4)) {
            return showToast('배송 출발 전인 행만 선택이 가능합니다.');
        }
        if(!selectedList.every(item => item.shipType === 'direct') && !selectedList.every(item => item.shipType === 'outsourcing')) {
            return showToast('배송 방법이 같은 행을 선택해주세요.');
        }

        const isDirect = selectedList[0].shipType === 'direct';      
        if(!isDirect){  // 외주 배송이면 외주 배송 팝업 (일괄 적용 > 배송 출발)
            setIsCheckedSetting(true);
            AppStore.toggleOutsourcingInfoPopup();
        }else {         // 직배송이면 배송담당 사원 리스트 (일괄 적용 > 배송 출발)
            setIsCheckedSetting(true);
            AppStore.toggleShipManagerPopup();
        }
    }

    /* 배송출발, 배송완료, 취소 callback */
    const updateShipStatus = async (status) => {
        let result = (status === '9') ? '취소' : (status === '5') ? '배송완료' : (status === '4') ? '배송출발 및 문자발송' : '';

        const requiredCheck = ShipStore.shipList.some(v => {
            if ((v.isSelected || v.isUpdated) && v.shipType === 'outsourcing') {
                return originList.some(item => item.shipSeq === v.shipSeq && (item.outsourcingCompany === null || item.outsourcingCompany === '' || item.outsourcingAmount === null || item.outsourcingAmount === ''));
            }
            return false;
        });

        // 외주배송 필수값 확인
        if(status !== '9' && requiredCheck) {
            return showToast('외주배송인 경우 운반사, 운임비를 입력해주세요.');
        }

        //배송출발
        if(status === '4' || status === '5'){
            const isValid = ShipStore.shipList.some(v => v.isSelected && v.shipStatus === '1');
            if(isValid) {
                showToast(`${result} 가능 상태가 아닌 배송건이 선택되었습니다.`);
                return;
            }
        }

        // 확인
        if(!await callConfirm('선택된 행을 '+result+' 하시겠습니까?')) return;

        const updatedList = ShipStore.shipList.filter(v => v.isSelected || v.isUpdated);
        let params = { status: status,  shipList : updatedList};
        await axios.put('ship/status', params);
        await getShipList();

        showToast(result+' 되었습니다.');
    }

    /* 인수인도확인서 버튼 클릭 */
    const confirmBtn = (shipSeq) => {
        setShipSeq(shipSeq);
        ShipStore.shipSeq = shipSeq;
        AppStore.toggleConfirmPopup();
    }

    /* 저장 버튼 클릭 이벤트 */
    const callBackGridData = async(data) => {
        // 저장 확인
        if(!await callConfirm('저장 하시겠습니까?')) return;

        const params = {shipList : data.updatedList};
        await axios.put('ship', params);
        await getShipList();

        showToast('저장되었습니다.');
    }

    /* 외주 정보 팝업 UI */
    const popupOutsourcingInfo = (e) => {
        return (
            <>
                {e.value !== 'pinnedTopRowData' && e.data.shipType === 'outsourcing' && 
                    <div className='d-flex justify-content-between'>
                        <div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: 120}}>{e.data.outsourcingInfo}</div>
                        <IconBtn 
                            key={e.rowIndex} 
                            style={{paddingTop: 6, color:'darkblue', textAlign: 'right', width: 50}} 
                            onClick={() => openOutsourcingInfoPopup(e.data)} 
                        />
                    </div>
                }
            </>
        )
    }

    /* 외주 정보 팝업 */
    const openOutsourcingInfoPopup = (e) =>{
        ShipStore.data.shipSeq = e.shipSeq;
        ShipStore.data.outsourcingCompany = e.outsourcingCompany;
        ShipStore.data.outsourcingAmount = e.outsourcingAmount;
        ShipStore.data.outsourcingCarTonnage = e.outsourcingCarTonnage;
        ShipStore.data.outsourcingCarNumber = e.outsourcingCarNumber;
        ShipStore.data.outsourcingDriver = e.outsourcingDriver;
        ShipStore.data.outsourcingPhoneNumber = e.outsourcingPhoneNumber;
        ShipStore.data.outsourcingRemark = e.outsourcingRemark;
        AppStore.toggleOutsourcingInfoPopup();
    }

    const pinnedTopRowData = [{
        acquisitionDelivery: 'pinnedTopRowData',
        outsourcingAmount: ShipStore.shipList.reduce((total, v) => total + Number(v.outsourcingAmount), 0), 
        deliveryUserName: 'pinnedTopRowData',
        outsourcingInfo: 'pinnedTopRowData',
    }];

    const columnCustom = [
        {field: "shipSeq", hide: true},
        {field: "division", headerName: "구분", width: 90},
        {field: "releaseAskStatus", headerName: "의뢰상태", valueFormatter: v => v.data.division === "출고" ? findCommKrnm(v.value, '5') : findCommKrnm(v.value, '25'), width: 120, hide: false},
        {
            field: 'acquisitionDelivery',
            headerName: "",
            width: 60,
            cellRenderer: (v) => v.value !== 'pinnedTopRowData' && (
                <IconBtn iconClass={'fi fi-rr-blog-pencil'} style={{paddingTop: 6}} onClick={() => confirmBtn(v.data.shipSeq)}/>
            ),
            cellClassRules: {
                'auto-pointer-events': (v) => v.data.shipStatus!=='9',
            }
        },
        {field: "shipStatus", headerName: "배송상태", width: 110, valueFormatter: v => findCommKrnm(v.value, '27'), cellClass: v => v.value === '4' ? 'ag-grid-column-wait' : v.value === '5' ? 'ag-grid-column-complete' : v.value === '9' ? 'ag-grid-column-cancel' : ''},
        {
            field: "shipType",
            headerName: "배송방법",
            valueFormatter: v => findCommKrnm(v.value, '4'),
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: getCommcode('4').map(v => v.value),
            },
            headerClass: 'grid-column-editable',
            editable: true,
            width: 110
        },
        {field: "customerName", headerName: "고객명", width: 150},
        {field: "customerAddress", headerName: "고객주소", width: 320},
        {field: "productName", headerName: "제품명", width: 300},
        {field: "receiveUserName", headerName: "받는사람", width: 110},
        {field: "telephone", headerName: "전화번호", width: 140},
        {field: "phoneNumber", headerName: "핸드폰", valueFormatter: v => v.value ? decrypt(v.value) : '', width: 140},
        {field: "askRemark", headerName: "출고/회수의뢰 비고", width: 200},
        {field: "shipRemark", headerName: "비고", width: 200, headerClass: 'grid-column-editable', editable: true},
        {field: "outsourcingInfo", headerName: "외주 운송", width: 180, headerClass: 'grid-column-editable', cellRenderer: e => popupOutsourcingInfo(e)},
        {field: "outsourcingAmount", headerName: "외주 운송비", width: 120, valueFormatter: v => v.value?.toLocaleString(), cellClass: 'ag-grid-money-align'},
        {field: "saleUserName", headerName: "영업담당", minWidth: 120},
        {field: "deliveryUserSeq", hide:true},
        {field: "deliveryUserName", headerName: "배송담당", width: 120, headerClass: 'grid-column-editable', cellRenderer: e => popupUserInfo(e)},
        {
            field: "areaCode",
            headerName: "권역설정",
            valueFormatter: v => findCommKrnm(v.value, '28'),
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: getCommcode('28').map(v => v.value),
            },
            headerClass: 'grid-column-editable',
            width: 110,
            editable: true
        },
        {field: "contractNo", headerName: "계약번호", width: 160},
        {field: "shipHopeDate", headerName: "배송예정일자", width: 130},
        {
            field: "shipHopeTime",
            headerName: "배송시간",
            valueFormatter: v => findCommKrnm(v.value, '29'),
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: getCommcode('29').map(v => v.value),
            },
            width: 120,
            headerClass: 'grid-column-editable',
            editable: true
        },
        {field: "deliverySeq", headerName: "배송순번", width: 110, headerClass: 'grid-column-editable', editable: true},
        {field: "shipDate", headerName: "배송완료일자", width: 130},
        {field: "returnItemRemark", headerName: "회수제품정보", width: 300},
        {field: "deliveryPrice", headerName: "배송운임", width: 130},
        {field: "additionalCost", headerName: "추가운임", width: 110},
        {field: "isSendSms", headerName: "알림톡전송", width: 110, cellClass: v => v.value === '성공' ? 'ag-grid-column-complete' : v.value === '실패' ? 'ag-grid-column-fail' : ''},
    ];

    return (
        <>
            {/* 검색조건 */}
            <ShipListSearch getShipList={getShipList} userKind={userKind} setUserKind={setUserKind} gridData={gridData}/>
            <ConfirmPopup shipSeq={shipSeq} getShipList={getShipList} />

            <CContainer>
                {/* grid */}
                <AgGridContainer
                    gridTitle={'배송 목록'}
                    gridRef={gridRef}
                    height={57}
                    rowData={ShipStore.shipList}
                    columnDefs={columnCustom}
                    useCsvDownload={true}
                    originList={originList}
                    isCheckBox={true}
                    useUpdated={true}
                    callBackGridData={callBackGridData}
                    customBtnInfo={[customCancelBtn, customCompleteBtn, customDepartBtn, customShipManagerBtn]}
                    getRowStyle={(params) => {
                        if (params.data.shipStatus === '5' || params.data.shipStatus === '9') {
                            return { color: '#bababa', pointerEvents: 'none', };
                        }
                        return null;
                    }}
                    pinnedTopRowData={pinnedTopRowData}
                />
            </CContainer>
            <OutsourcingInfoPopup getShipList={getShipList} isCheckedSetting={isCheckedSetting} setIsCheckedSetting={setIsCheckedSetting} updateShipStatus={updateShipStatus} />
            <ShipManagerPopup updateShipStatus={updateShipStatus} getShipList={getShipList} isCheckedSetting={isCheckedSetting} setIsCheckedSetting={setIsCheckedSetting} />
        </>
    );
};

export default observer(ShipList);
