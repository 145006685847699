import React, {useEffect, useRef, useState} from "react";
import { observer } from "mobx-react-lite";
import { AppStore } from "../../../store/AppStore";
import Modal from '../../../components/Modal'
import {CCol, CContainer, CInputGroup, CSaveBtn, CSelectGroup, Subject, IconBtn} from "../../../components/CustomContainer";
import {Col, Form, Row} from "react-bootstrap";
import {SiteInfoStore} from "../../../store/customer/SiteInfoStore";
import Address from "../../../components/Address";
import CustomerSearch from "../../../components/searchModal/CustomerSearch";
import {autoHyphen, decrypt, encrypt, showToast} from "../../../common/utils";
import axios from "axios";
import {callAlert, callConfirm} from "../../../utils";
import {getCommcode} from "../../../utils/commcode";
import SiteOption from "../../../components/SiteOption";
import AgGridContainer from "../../../components/AgGridContainer";
import {getUserName} from "../../../utils/userUtils";
import {SiteOptionStore} from "../../../store/customer/SiteOptionStore";
import {ICON_ADD, ICON_TRASH} from "../../../common/constants";
import {EstimateDetailStore} from "../../../store/contract/EstimateDetailStore";
import AccountListSearch from "../../billing/AccountListSearch";
import LUtils from '../../../utils/lodashUtils';


const SiteDetailPopup =({ callbackFn=null, ...props })=> {
    const gridRef = useRef();
    const gridDataRef = useRef();
    const {siteSeq, setSiteSeq, getSiteInfoList} = props;
    const [gridData, setGridData] = useState([]);
    const [customerKind, setCustomerKind] = useState('');

    useEffect(()=> {
        if(siteSeq) {
            Promise.all([getSiteInfo(), getSiteManagerList()]);
        }
        if(AppStore.isOpenSiteDetailPopup){
            getSiteOptionList();
        }

    }, [siteSeq, AppStore.isOpenSiteDetailPopup])

    const getSiteInfo =async()=> {
        const result = await axios.get(`/site/info`, {params:{siteSeq}});
        const detailData = result[0];

        if(detailData.managerPhoneNumber) detailData.managerPhoneNumber = autoHyphen(decrypt(detailData.managerPhoneNumber));
        if(detailData.managerEmail) detailData.managerEmail = decrypt(detailData.managerEmail);

        SiteInfoStore.setSiteDetail({...detailData});
    }

    const getSiteManagerList =async()=> {
    const paramSiteSeq = SiteInfoStore.detail.siteSeq ? SiteInfoStore.detail.siteSeq : siteSeq;
        const result = await axios.get(`/site/manager`,{params:{siteSeq: paramSiteSeq}});
        result.map(v => {
                if(v.phoneNumber) v.phoneNumber = autoHyphen(decrypt(v.phoneNumber));
                if(v.email) v.email = decrypt(v.email);
                return v;
            });

        SiteInfoStore.setSiteManagerList(result);
    }

    const searchCustomerInfo =(data)=> {
        const result = SiteInfoStore.siteManagerList.map(v => {
            if((v.addRowId !== undefined && (v.addRowId === gridData.data.addRowId)) || (v.addRowId === undefined && (v.seq === gridData.data.seq))){
                v.customerNo = data.customerNo;
                v.customerName = data.customerName;
                LUtils.assign(v, {isUpdated: true });
            }
            return v;
        });
        SiteInfoStore.setSiteManagerList(result);
    }

    const getSiteOptionList =async()=> {
        const result = await axios.get(`/site/option`,{params:{siteSeq}});
        SiteInfoStore.setSiteOptionList(result);
        SiteOptionStore.setSiteOptionChangeList(result.filter(v => v.optionValue));
    }

    /* 고객 팝업 UI */
    const popupCustomerInfo = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.customerName}</div>
                <IconBtn key={e.rowIndex} onClick={() => {setGridData(e), setCustomerKind('grid'), AppStore.toggleCustomerSearchModal()}} />
            </div>
        )
    }

    const saveSite = async () => {
        if(!SiteInfoStore.detail.siteName){
            showToast('현장명을 입력해주세요.');
            return;
        }
        if(!SiteInfoStore.detail.siteStatus){
            showToast('현장 상태를 선택해주세요.');
            return;
        }

        if(!await callConfirm(`${siteSeq ? '수정' : '저장'}하시겠습니까?`)) return;

        //암호화
        SiteInfoStore.setSiteDetail({managerPhoneNumber: encrypt(SiteInfoStore.detail.managerPhoneNumber), managerEmail: encrypt(SiteInfoStore.detail.managerEmail)});
        if(SiteInfoStore.siteManagerList.length > 0){
            SiteInfoStore.siteManagerList.map(v => {
               if(v.phoneNumber) v.phoneNumber = encrypt(v.phoneNumber);
               if(v.email) v.email = encrypt(v.email);
            });
        }

        const promiseArray = [];
        if(siteSeq){
            //현장 담당자
            SiteInfoStore.siteManagerList && promiseArray.push(await axios.put(`/site/manager/${siteSeq}`, SiteInfoStore.siteManagerList));
            //현장 옵션
            SiteOptionStore.siteOptionChangeList && promiseArray.push(await axios.post(`/site/option/${siteSeq}`, SiteOptionStore.siteOptionChangeList));

            await Promise.all([await axios.put(`/site/info/${siteSeq}`, {...SiteInfoStore.detail})].concat(promiseArray));
        }else{
            //마스터 저장
            const result = await axios.post('/site/info', {...SiteInfoStore.detail});
            SiteInfoStore.setDetail(result, 'siteSeq');
            //현장 담당자
            if(SiteInfoStore.siteManagerList.filter(v => v.isSelected).length > 0){
                promiseArray.push(await axios.post(`/site/manager/${result}`, SiteInfoStore.siteManagerList));
            }
            //햔장 옵션
            promiseArray.push(await axios.post(`/site/option/${result}`, SiteOptionStore.siteOptionChangeList));

            if(promiseArray.length > 0){
                await Promise.all(promiseArray);
            }
        }

        showToast('저장되었습니다.');
        await getSiteInfo();
        await getSiteManagerList();
        await getSiteOptionList();
        await getSiteInfoList();
    }

    const siteManagerGridCallBack =async (gridData) => {
        const createdList = gridData.createdList;
        const updatedList = gridData.updatedList;

        if (!await callConfirm('저장하시겠습니까?')) return;

        createdList && await axios.post(`/site/manager/${siteSeq}`, createdList);
        updatedList && await axios.put(`/site/manager/${siteSeq}`, updatedList);
        showToast('저장되었습니다.');

        //재조회
        await getSiteManagerList();
    }

    const removeSiteManager =async(e)=> {
        const { siteSeq, seq, managerName } = e.data;

        if(!await callConfirm(`${managerName}를 삭제하시겠습니까?`)) return;

        const result = await axios.delete(`/site/manager/delete`, {params: {siteSeq, seq}});
        showToast('삭제되었습니다.');

        await getSiteManagerList();
    }

    const addRowInfo = {siteSeq: siteSeq, managerName: '', phoneNumber: '', email: '', isAutoMailSend: 0, remark: '', isCreated: true, isSelected: true}

    const addManagerBtn = {
        isUsed: true,
        callbackFn:() => {
            gridDataRef.current.addRow(addRowInfo, 'push');
        },
        icon: ICON_ADD,
        title: '현장 담당자 추가',
        width: 'auto'
    }

    const setCheckbox = (e) => {
        return (
            <>
                <div className="ag-cell-wrapper" style={{paddingLeft: 50}}>
                    <Form.Check
                        type="checkbox"
                        id="isAutoMailSend"
                        checked={e.data.isAutoMailSend === 1}
                        value={e.data.isAutoMailSend === 1}
                        onChange={(v) => checkedHandler(e,v)}
                        className="ag-input-field-input ag-checkbox-input"
                    />
                </div>
            </>
        )
    }

    const checkedHandler = (e, v) => {
        let checked = v.target.checked;
        SiteInfoStore.siteManagerList.map(item => {
            if(item.seq === e.data.seq) {
                item.isAutoMailSend = checked ? 1 : 0;
                item.isUpdated = true;
            }
        })
        gridRef.current.api.redrawRows();
    }

    const refresh =()=> {
        SiteInfoStore.resetSiteDetail();
        setSiteSeq('');
    }

    return (
        <Modal
            title={'현장 상세'}
            show={AppStore.isOpenSiteDetailPopup}
            onHide={() => AppStore.toggleSiteDetail()}
            toggleValue={AppStore.isOpenSiteDetailPopup}
            className={'search-modal-h3'}
            onExit={refresh}
            setCustomerKind={setCustomerKind}
        >
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup label={'현장번호'} labelId={'siteSeq'}
                                     value={SiteInfoStore.detail.siteSeq}
                                     disabled={true}
                        />
                    </CCol>
                    <CCol lg={5}>
                        <CInputGroup label={'현장명'} labelId={'siteName'}
                                     value={SiteInfoStore.detail.siteName}
                                     onChange={(e)=> SiteInfoStore.setDetail(e.target.value, 'siteName')}
                                     isValid={true}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CSelectGroup
                            label={'현장상태'}
                            options={[{value:'', name:'선택'}, ...getCommcode('54')]}
                            value={SiteInfoStore.detail.siteStatus || ''}
                            onChange={(e)=> SiteInfoStore.setDetail(e.target.value, 'siteStatus')}
                            isValid={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={12} md={12}>
                        <label className="form-label">현장 주소</label>
                        <Address label='주소'
                                 setPostcode={v => SiteInfoStore.setDetail(v, 'postCode')}
                                 setAddress={v => SiteInfoStore.setDetail(v, 'address')}
                                 setAddressDetail={v => SiteInfoStore.setDetail(v, 'addressDetail')}
                                 postcode={SiteInfoStore.detail.postCode || ''}
                                 address={SiteInfoStore.detail.address || ''}
                                 addressDetail={SiteInfoStore.detail.addressDetail || ''}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={12}>
                            <label className="form-label">현장 특징</label>
                            <textarea className="form-control" rows="2"
                                      value={SiteInfoStore.detail.characterRemark || ''}
                                      onChange={e => SiteInfoStore.setDetail(e.target.value, 'characterRemark')}
                            />
                    </CCol>
                </Row>
                {/* <Row style={{marginBottom: -15, justifyContent: 'space-between'}}>
                    <CCol lg={2}>
                        <label className="form-label">
                            <span>*대표 담당자</span>
                        </label>
                    </CCol>
                    <CCol lg={5}>
                        <Form.Check
                            type="switch"
                            id="isAutoMailSend"
                            label="대표 담당자에게 명세서 자동 발송"
                            style={{paddingLeft: !AppStore.isMobile ? 100 : 0}}
                            checked={SiteInfoStore.detail.isAutoMailSend === 1}
                            onChange={(e) => SiteInfoStore.setDetail(e.target.checked ? 1 : 0, 'isAutoMailSend') }
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup label={'담당자명'} labelId={'managerName'}
                                     value={SiteInfoStore.detail.managerName}
                                     onChange={(e)=> SiteInfoStore.setDetail(e.target.value, 'managerName')}
                        />
                    </CCol>
                    <CCol lg={5}>
                        <CInputGroup label={'담당자 전화번호'} labelId={'managerPhoneNumber'}
                                     value={SiteInfoStore.detail.managerPhoneNumber}
                                     onChange={(e)=> SiteInfoStore.setDetail(e.target.value, 'managerPhoneNumber')}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <CInputGroup label={'담당자 이메일'} labelId={'managerEmail'}
                                     value={SiteInfoStore.detail.managerEmail}
                                     onChange={(e)=> SiteInfoStore.setDetail(e.target.value, 'managerEmail')}
                        />
                    </CCol>
                </Row> */}
                <Row>
                    <CustomerSearch callbackFn={searchCustomerInfo}/>
                    <AgGridContainer
                        gridRef={gridRef}
                        ref={gridDataRef}
                        height={20}
                        isVisibleTotalCount={false}
                        rowData={SiteInfoStore.siteManagerList}
                        columnDefs={[
                            {field: "seq", headerName: "순번", width: 80},
                            {field: "managerName", headerName: "이름", width: 100, headerClass: 'grid-column-editable', editable: true},
                            {field: "phoneNumber", headerName: "전화번호", width: 140, headerClass: 'grid-column-editable', editable: true},
                            {field: "email", headerName: "이메일", width: 160, headerClass: 'grid-column-editable', editable: true},
                            // {
                            //     field: "isAutoMailSend",
                            //     headerName: "명세서 자동 발송",
                            //     cellRenderer: v => setCheckbox(v),
                            //     headerClass: 'grid-column-editable',
                            //     editable: true,
                            //     width: 150
                            // },
                           // {field: "customerNo", headerName: "고객번호", width: 120},
                           // {field: "customerName", headerName: "고객명", width: 120},
                            {field: "customerNo", headerName: "고객코드", headerClass: 'grid-column-editable', hide: true, minWidth: 120},
                            {field: "customerName", headerName: "고객명", minWidth: 160, headerClass: 'grid-column-editable', cellRenderer: e => popupCustomerInfo(e)},
                            {field: "remark", headerName: "비고", width: 180, headerClass: 'grid-column-editable', editable: true},
                            {field: "creator", headerName: "작성자", width: 110,  valueGetter: v => getUserName(v.data.creator)},
                            {field: "createdDate", headerName: "작성일자", width: 180},
                        ]}
                        isCheckBox={true}
                        //useUpdated={siteSeq}
                        callBackGridData={siteManagerGridCallBack}
                        customBtnInfo={[addManagerBtn]}
                        useRemoveRow={true}
                        removeCallback={removeSiteManager}

                    />
                </Row>
                <br />
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSaveBtn onClick={saveSite} style={{width: 150}} title={'현장정보저장'}/>
                    </Col>
                </Row>
                {/* 현장 옵션 정보 */}
                <SiteOption optionList={SiteInfoStore.siteOptionList} />
            </CContainer>
            <CContainer>

            </CContainer>
            {/*<CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    gridTitle={'AWP 옵션 리스트'}
                    height={30}
                    rowData={SiteInfoStore.siteOptionList}
                    columnDefs={[
                        {field: "productSeq", headerName: "제품순번", width: 80, hide: true},
                        {field: "seq", headerName: "순번", width: 70},
                        {field: "model", headerName: "옵션명", width: 170},
                        {field: "spec", headerName: "스펙", width: 200},
                        {
                            field: "unitPrice",
                            headerName: "단가",
                            width: 120,
                            valueFormatter: v => v.value?.toLocaleString(),
                            headerClass: 'grid-column-editable',
                            cellClass: 'ag-grid-money-align',
                            editable: true
                        },
                        {field: "creator", headerName: "작성자", width: 110,  valueGetter: v => getUserName(v.data.creator)},
                        {field: "createdDate", headerName: "작성일자", width: 180},
                        {field: "updater", headerName: "수정자", width: 110,  valueGetter: v => getUserName(v.data.updater)},
                        {field: "updatedDate", headerName: "수정일자", width: 180}
                    ]}
                    isCheckBox={true}
                    useUpdated={true}
                    callBackGridData={optionGridDataCallBack}
                    customBtnInfo={[addOptionBtn]}
                    useRemoveRow={true}
                    removeCallback={removeSiteOption}
                />
            </CContainer>*/}
        </Modal>
    )
}

export default observer(SiteDetailPopup);
