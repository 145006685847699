import { observable } from "mobx";

export const TemplateStore = observable({
    contractNo: '',
    contractDate: '',
    saleUserName: '',
    telephone: '',
    phoneNumber: '',
    customerName: '',
    companyName: '',
    customerAddress: '',
    companyAddress: '',
    businessNumber: '',
    owner: '',
    contractProductList: [],
    options: '',
    optionAmount: '',
    contractStartDate: '',
    paymentType: '',
    dueDateType: '',
    dueDateDay: '',
    installPlace: '',
    returnAddress: '',
    deliveryPrice: '',
    isDeliveryPrice: '',
    chargeDelivery: '',
    deliveryKind: '',
    specialRemark: '',
    companySignatureUrl: '',
    logoImageUrl: '',
    isContract: true,

    setTemplateData(store) {
            this.contractNo = store.contractNo;
            this.contractDate = store.contractDate;
            this.saleUserName = store.saleUserName;
            this.telephone = store.telephone;
            this.phoenNumber = store.phoenNumber;
            this.customerName = store.customerName;
            this.companyName = store.companyName;
            this.customerAddress = store.customerAddress;
            this.companyAddress = store.companyAddress;
            this.businessNumber = store.businessNumber;
            this.owner = store.owner;
            this.contractProductList = store.contractProductList.map(v => ({ ...v }));
            this.options = store.options;
            this.optionAmount = store.optionAmount;
            this.contractStartDate = store.contractStartDate;
            this.paymentType = store.paymentType;
            this.dueDateType = store.dueDateType;
            this.dueDateDay = store.dueDateDay;
            this.installPlace = store.installPlace;
            this.returnAddress = store.returnAddress;
            this.deliveryPrice = store.deliveryPrice;
            this.isDeliveryPrice = store.isDeliveryPrice;
            this.chargeDelivery = store.chargeDelivery;
            this.deliveryKind = store.deliveryKind;
            this.specialRemark = store.specialRemark;
            this.companySignatureUrl = store.companySignatureUrl;
            this.logoImageUrl = store.logoImageUrl;
            this.isContract = true;
    },
    setReleaseAskTemplateData(contractInfo, contractProductList) {
            this.contractNo = contractInfo.contractNo;
            this.contractDate = contractInfo.contractDate;
            this.saleUserName = contractInfo.saleUserName;
            this.telephone = contractInfo.telephone;
            this.phoenNumber = contractInfo.phoenNumber;
            this.customerName = contractInfo.customerName;
            this.companyName = contractInfo.companyName;
            this.customerAddress = contractInfo.customerAddress;
            this.companyAddress = contractInfo.companyAddress;
            this.businessNumber = contractInfo.businessNumber;
            this.owner = contractInfo.owner;
            this.contractProductList = contractProductList.map(v => ({ ...v }));
            this.options = contractInfo.options;
            this.optionAmount = contractInfo.optionAmount;
            this.contractStartDate = contractInfo.contractStartDate;
            this.paymentType = contractInfo.paymentType;
            this.dueDateType = contractInfo.dueDateType;
            this.dueDateDay = contractInfo.dueDateDay;
            this.installPlace = contractInfo.installPlace;
            this.returnAddress = contractInfo.returnAddress;
            this.deliveryPrice = contractInfo.deliveryPrice;
            this.isDeliveryPrice = contractInfo.isDeliveryPrice;
            this.chargeDelivery = contractInfo.chargeDelivery;
            this.deliveryKind = contractInfo.deliveryKind;
            this.specialRemark = contractInfo.specialRemark;
            this.companySignatureUrl = contractInfo.companySignatureUrl;
            this.logoImageUrl = contractInfo.logoImageUrl;
            this.isContract = false;
    },
    setContractProductList(productList) {
        this.contractProductList = productList.map(v => ({ ...v }));
    },
    
    init(){
        this.contractNo = '';
        this.contractDate = '';
        this.saleUserName = '';
        this.telephone = '';
        this.phoenNumber = '';
        this.customerName = '';
        this.companyName = '';
        this.customerAddress = '';
        this.companyAddress = '';
        this.businessNumber = '';
        this.owner = '';
        this.contractProductList = [];
        this.options = '';
        this.optionAmount = '';
        this.contractStartDate = '';
        this.paymentType = '';
        this.dueDateType = '';
        this.dueDateDay = '';
        this.installPlace = '';
        this.returnAddress = '';
        this.deliveryPrice = '';
        this.isDeliveryPrice = '';
        this.chargeDelivery = '';
        this.deliveryKind = '';
        this.specialRemark = '';
        this.companySignatureUrl = '';
        this.logoImageUrl = '';
    }
});