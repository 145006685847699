import {useEffect, useState} from "react";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import {CCol, CContainer, CInputGroup} from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";
import {decrypt} from "../../common/utils";
import {observer} from "mobx-react-lite";
import {Row} from "react-bootstrap";

const CustomerManagerSearch =({ callbackFn=null, ...props })=> {
    const {customerNo} = props;
    const [customerManagerList, setCustomerManagerList] = useState([]);
    const [orgCustomerManagerList, setOrgCustomerManagerList] = useState([]);
    const [managerName, setManagerName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(()=> {
        if(customerNo){
            getManagerList(customerNo);
        }
    }, [AppStore.isOpenCustomerManagerSearchPopup])

    const getManagerList = async (customerNo) => {
        if (!customerNo) {
            return false;
        }

        const result = await axios.get(`/customer/tabs/${customerNo}/manager`);
        if(result.length > 0){
            result.map(v => {v.phoneNumber = decrypt(v.phoneNumber); v.email = decrypt(v.email); return v;});   // 핸드폰, 이메일 복호화
            setCustomerManagerList(result);
            setOrgCustomerManagerList(result);
        }
    }

    const selectedRow = e => {
        callbackFn && callbackFn(e.data);
        AppStore.toggleCustomerManagerSearchPopup();
    }

    const textFilterList =(v, target)=> {
        if(target === 'manager'){
            setManagerName(v);
        }else{
            setPhoneNumber(v);
        }

        if(v){
            const filterList = customerManagerList.filter(f => f.managerName.indexOf(v) > -1 || f.telephone.indexOf(v) > -1 || f.phoneNumber.indexOf(v) > -1);

            if(filterList.length > 0){
                setCustomerManagerList(filterList);
            }
        }else{
            setCustomerManagerList(orgCustomerManagerList);
        }
    }

    return (
        <Modal
            title={'고객 담당자 조회'}
            onHide={() => AppStore.toggleCustomerManagerSearchPopup()}
            toggleValue={AppStore.isOpenCustomerManagerSearchPopup}
            className={'modal-w9'}
        >
            <CContainer>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup label={'담당자명'} labelId={'saleUserName'}
                                     value={managerName}
                                     onChange={(v)=> textFilterList(v.target.value, 'manager')}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'전화번호'} labelId={'saleUserName'}
                                     value={phoneNumber}
                                     onChange={(v)=> textFilterList(v.target.value, 'phone')}
                        />
                    </CCol>
                </Row>
                <AgGridContainer
                    height={31}
                    rowData={customerManagerList}
                    columnDefs={
                        [
                            {field: "customerManagerSeq", hide: true},
                            {field: "managerSeq", headerName: "순번", width: 70},
                            {field: "managerName", headerName: "담당자명", width: 110},
                            {field: "department", headerName: "부서", width: 140},
                            {field: "jobPosition", headerName: "직위"},
                            {field: "telephone", headerName: "전화번호", width: 140},
                            {field: "phoneNumber", headerName: "휴대전화", valueFormatter: v => v.data.phoneNumber ?? decrypt(v.data.phoneNumber), width: 140},
                            {field: "email", headerName: "이메일", valueFormatter: v => v.data.email ?? decrypt(v.data.email), width: 180},
                            {field: "role", headerName: "역할", width: 110},
                        ]
                    }
                    enableCellTextSelection={true}
                    rowDoubleClickCallback={selectedRow}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(CustomerManagerSearch);
