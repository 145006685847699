import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import axios from 'axios';
import { useReactToPrint } from "react-to-print";

import {CCol, CContainer, CInputGroup, CSelectGroup, CIconBtn, Subject, CCancelBtn, CSaveBtn, CalendarGroup} from "../../components/CustomContainer";
import {getCommcode} from "../../utils/commcode";
import {ReturnAskListStore} from "../../store/return/ReturnAskListStore";
import Address from "../../components/Address";
import AgGridContainer from "../../components/AgGridContainer";
import {encrypt, decrypt, autoHyphen, showToast, localAutoHyphen} from "../../common/utils";
import {dateTimeFormat, dateFormat, today, dateDiff} from '../../utils/dateUtils';
import {callAlert, callConfirm} from '../../utils';
import {AppStore} from "../../store/AppStore";
import { ICON_ADD } from "../../common/constants";
import ContractSearch from "../../components/searchModal/ContractSearch";
import ReturnItemPopup from "./popup/ReturnItemPopup";
import ReportReturnAskTemplate from "../../components/report/ReportReturnAskTemplate";
import { getLocalStorage } from "../../utils";
import { USER_INFO } from "../../common/constants";

const ReturnAskDetail =({ returnAskSeq, getReturnAskList, contractNo, setContractNo, assetList, isExpirationAsset=false, closedModal})=> {
    const gridRef = useRef();
    const componentRef = useRef();

    const [isViewMode, setIsViewMode] = useState(false);
    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [contractAssetList, setContractAssetList] = useState([]);
    const userInfo = getLocalStorage(USER_INFO);

    /* 기본값 세팅 */
    useEffect(()=> {
        if(returnAskSeq){
            setIsViewMode(true);
            Promise.all([getReturnAsk(returnAskSeq), getReturnAskItemList(returnAskSeq), getContractAssetList(contractNo)]);
        } else if (isExpirationAsset || ReturnAskListStore.data.returnAskStatus === '1') {
            getLastReleaseAskInfo(contractNo);
        }
        else {
            setIsViewMode(false);
        }
        // return ReturnAskListStore.init();
    }, []);

    /* 상세 조회 */
    const getReturnAsk = async (returnAskSeq) => {
        const result = await axios.get('returnAsk', {params: {returnAskSeq: returnAskSeq}});
        ReturnAskListStore.data = result;

        // 주소 세팅
        setPostcode(result.postCode ? result.postCode : '');
        setAddress(result.address ? result.address : '');
        setAddressDetail(result.addressDetail ? result.addressDetail : '');

        //if(['1','2'].includes(ReturnAskListStore.data.returnAskStatus) && dateDiff(today(), ReturnAskListStore.data.returnExpectDate) > 0){
        if(['1','2'].includes(ReturnAskListStore.data.returnAskStatus)){
            setIsViewMode(false);
        }else{
            setIsViewMode(true);
        }

        getContractAssetList(result.contractNo);
    }

    /* 회수 의뢰 새 등록일 경우, 계약 번호로 상세 조회 */
    const getLastReleaseAskInfo = async (contractNo) => {
        const result = await axios.get('returnAsk/info', { params: {contractNo: contractNo} });
        ReturnAskListStore.data = result;
        setPostcode(result.postCode);
        setAddress(result.address);
        setAddressDetail(result.addressDetail);
        ReturnAskListStore.data.returnAskDate = today();
        ReturnAskListStore.data.creatorName = userInfo.name;
        ReturnAskListStore.data.creator = userInfo.userSeq;

        getContractAssetList(contractNo);
    }

    const getContractAssetList = async (contractNo) => {
        // 계약 자산 목록
        // const contractAssetList = await axios.get('returnAskItem/targetList', { params: {contractNo: contractNo} });
        // setContractAssetList(contractAssetList);
        if (!isExpirationAsset) {
            const list = await axios.get('returnAskItem/targetList', { params: {contractNo: contractNo} });
            setContractAssetList(list);
        } else {
            const returnItemList = assetList.map((item, idx) => {
                return {
                    returnAskSeq: null, 
                    productSeq: item.productSeq, 
                    seq: idx + 1, 
                    assetNo: item.assetNo, 
                    managementNo: item.managementNo,
                    productName: item.productName, 
                    model: item.model, 
                    qty: 1, 
                    serialNo: item.serialNo, 
                    spec: item.spec, 
                    remark: '', 
                    isCancel: 0
                }
            })
            ReturnAskListStore.setReturnAskItemList(returnItemList);
        }
    }

    /* 회수 제품 리스트 */
    const getReturnAskItemList = async (returnAskSeq) => {
        const result = await axios.get('returnAskItem/list', {params: {returnAskSeq: returnAskSeq}});
        ReturnAskListStore.setReturnAskItemList(result);
    }

    /* 저장(작성중 상태로 변경) */
    const saveReturnAsk = async () => {
        // 필수값 확인
        const requiredParams = ['phoneNumber', 'receiveUserName', 'returnExpectDate', 'shipHopeTime', 'shipType'];
        if (!requiredParams.every(param => ReturnAskListStore.data[param]) || !ReturnAskListStore.returnAskItemList) {
            showToast('필수값을 모두 입력해야 합니다.');
            return;
        }

        if(!address){
            showToast('주소를 입력해 주세요. (우편번호 생략 가능)');
            return;
        }

        // 저장 확인
        if(!await callConfirm('저장 하시겠습니까?')) return;

        const {returnAskSeq} = ReturnAskListStore.data;

        // 주소 세팅
        ReturnAskListStore.data.postCode = postcode;
        ReturnAskListStore.data.address = address;
        ReturnAskListStore.data.addressDetail = addressDetail;

        // 회수제품목록
        if (ReturnAskListStore.returnAskItemList.length > 0) {
            // 회수제품 확인
            const duplicateList = await axios.post("returnAskItem/check", ReturnAskListStore.returnAskItemList);
            if (duplicateList.length > 0) {
                showToast('해당 관리번호로 생성된 회수의뢰가 이미 존재합니다.');
                return;
            }
        }

        const createdList = ReturnAskListStore.returnAskItemList.filter(v => v.isCreated);
        ReturnAskListStore.data.returnAskItemList = createdList;

        // 등록 or 수정
        if(!returnAskSeq){  // 등록
            const result = await axios.post('returnAsk', ReturnAskListStore.data );
            // 등록 후 의뢰번호/상태 업데이트
            ReturnAskListStore.data.returnAskSeq = result.returnAskSeq;
            ReturnAskListStore.data.returnAskStatus = result.returnAskStatus;
        } else {    // 수정
            await axios.put('returnAsk', ReturnAskListStore.data);
        }
        showToast('저장되었습니다.');
        getReturnAskList && getReturnAskList();
        getReturnAskItemList(ReturnAskListStore.data.returnAskSeq);
    }

    /* 상태변경 */
    const saveReturnAskStatus = async(status, btnText) => {
        if(!await callConfirm(btnText + ' 하시겠습니까?')) return;

        ReturnAskListStore.data.returnAskStatus = status;
        const result = await axios.put('returnAsk/status', ReturnAskListStore.data);
        if(status === '2') {
            showToast('회수의뢰가 완료되었습니다.');
            getReturnAskList();
            AppStore.toggleModal();
        } else if (status === '3') {
            showToast('회수진행중입니다.');
        } else if(status === '4') {
            showToast('회수가 완료되었습니다.')
            getReturnAskList && getReturnAskList();
        } else {
            showToast(btnText + '가 완료되었습니다.');
        }
        ReturnAskListStore.data.returnAskStatus = result.toString();
        // getReturnAskList && getReturnAskList();
    }

    /* 계약 조회 팝업 */
    const openContractSearchPopup = () =>{
        //AppStore.toggleContractSearchModal();
        AppStore.toggleReturnItemPopup();
    }
    /* 계약 조회 팝업 콜백 */
    const callbackContractInfo = (info) => {
        ReturnAskListStore.data = info;
        setContractNo(info.contractNo);
        getLastReleaseAskInfo(ReturnAskListStore.data.contractNo);
    }
    /* 계약 자산 조회 팝업 */
    const openContractAssetSearchPopup = () =>{
        AppStore.toggleReturnItemPopup();
    }
    /* 계약 자산 조회 팝업 버튼 */
    const returnItemBtn = () => {
        openContractAssetSearchPopup();
    }
    /* 계약 자산 목록 팝업 버튼 커스텀*/
    const customReturnItemBtn = {
        isUsed: ReturnAskListStore.data.returnAskSeq && ReturnAskListStore.data.contractNo && ReturnAskListStore.data.returnAskStatus === '1',
        callbackFn:() => returnItemBtn(),
        icon: ICON_ADD,
        title: '회수 장비 추가',
        width: 130,
    }

    /* 회수의뢰서 print */
    const returnAskPrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "회수의뢰서-회수의뢰번호_" + ReturnAskListStore.data.releaseAskSeq,
    });

    const resetBtn = () => {
        setPostcode('');
        setAddress('');
        setAddressDetail('');
        closedModal && closedModal();
    }

    return(
        <div style={{marginBottom: 50}}>
            {/* content header */}

            <CContainer search>
                <Subject>의뢰정보</Subject>
                {returnAskSeq ? 
                    <>
                        <Row>
                            <CCol lg={2}>
                                <CInputGroup label={'회수의뢰번호'}
                                                labelId={'returnAskSeq'}
                                                disabled={true}
                                                value={ReturnAskListStore.data.returnAskSeq || ''}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CSelectGroup label={'회수의뢰상태'} labelId={'returnAskStatus'}
                                                placeholder={'회수의뢰상태'}
                                                options={[{name: '의뢰상태', value: ''}].concat(getCommcode('25'))}
                                                value={ReturnAskListStore.data.returnAskStatus || ''}
                                                disabled={true}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CalendarGroup asSingle={true}  label={'회수의뢰일'}
                                                value={{
                                                    startDate: ReturnAskListStore.data.returnAskDate,
                                                    endDate: ReturnAskListStore.data.returnAskDate
                                                }}
                                                disabled={true}
                                />
                            </CCol>
                            <CCol lg={2}>
                                <CInputGroup label={'의뢰자명'}
                                                labelId={'creatorName'}
                                                disabled={true}
                                                value={ReturnAskListStore.data.creatorName || ''}
                                />
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={3}>
                                <CInputGroup label={'계약번호'}
                                            labelId={'contractNo'}
                                            disabled={true}
                                            //  isValid={true}
                                            //  btnDisabled={ReturnAskListStore.data.returnAskStatus && ReturnAskListStore.data.returnAskStatus !== '1'}
                                            value={ReturnAskListStore.data.contractNo}
                                            //  onCallbackBtn={!isViewMode && openContractSearchPopup}
                                            onChange={v => ReturnAskListStore.data.contractNo = v.target.value}
                                />
                            </CCol>
                            <CCol lg={2}>
                                <CSelectGroup label={'계약유형'} labelId={'contractType'}
                                            placeholder={'계약유형'}
                                            options={getCommcode('1')}
                                            value={ReturnAskListStore.data.contractType}
                                            disabled={true}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CInputGroup label={'영업담당자'} labelId={'saleUserName'}
                                            value={ReturnAskListStore.data.saleUserName}
                                            disabled={true}
                                />
                            </CCol>
                            {/*
                            <CCol lg={2}>
                                <CInputGroup label={'지원담당자'} labelId={'supportUserName'}
                                            value={ReturnAskListStore.data.supportUserName}
                                            disabled={true}
                                />
                            </CCol>
                            */}
                        </Row>
                    </>
                :
                    <>
                        <Row>
                            <CCol lg={12}>
                                <Subject isBorder={false} style={{fontSize: 14}}>회수할 장비의 계약번호를 검색하여 장비를 선택해주세요.</Subject>
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={3}>
                                <CInputGroup label={'계약번호'}
                                            labelId={'contractNo'}
                                            isValid={true}
                                            value={ReturnAskListStore.data.contractNo}
                                            onCallbackBtn={!isViewMode && openContractSearchPopup}
                                />
                            </CCol>
                            <CCol lg={1}>
                            <CIconBtn style={{width: 80, height: 28}}
                                        title={'초기화'}
                                        variant={'danger'}
                                        onClick={resetBtn}
                                />
                            </CCol>
                        </Row>
                    </>
                }
                <hr/>
                <Row>
                    <CCol lg={6}>
                        <div className="mb-3">
                            <label className="form-label">출고의뢰비고</label>
                            <textarea className="form-control" rows="3"
                                        value={ReturnAskListStore.data.releaseAskRemark || ''}
                                        disabled={true}
                                        onChange={v => ReturnAskListStore.data.releaseAskRemark = v.target.value}
                                        />
                        </div>
                    </CCol>
                    <CCol lg={6}>
                        <div className="mb-3">
                            <label className="form-label">회수의뢰비고</label>
                            <textarea className="form-control" rows="3"
                                        value={ReturnAskListStore.data.returnAskRemark || ''}
                                        disabled={isViewMode}
                                        onChange={v => ReturnAskListStore.data.returnAskRemark = v.target.value}
                            />
                        </div>
                    </CCol>
                </Row>
                <Row>
                    <Subject>배송정보</Subject>
                    <CCol lg={3}>
                        <CInputGroup label={'고객명'} labelId={'customerName'}
                                           disabled={true}
                                           value={ReturnAskListStore.data.customerName}
                        />
                    </CCol>
                    <CCol lg={4}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text>사무실</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'telephone'}
                                          placeholder={'전화번호'}
                                          value={localAutoHyphen(ReturnAskListStore.data.telephone) || ''}
                                          disabled={isViewMode}
                                          onChange={v => ReturnAskListStore.data.telephone = v.target.value}
                            />
                            <InputGroup.Text className='input-required'>휴대폰</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'phoneNumber'}
                                          placeholder={'휴대폰'}
                                          value={autoHyphen(decrypt(ReturnAskListStore.data.phoneNumber)) || ''}
                                          disabled={isViewMode}
                                          onChange={v => ReturnAskListStore.data.phoneNumber = encrypt(v.target.value)}
                            />
                        </InputGroup>
                    </CCol>
                    <CCol lg={5}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text>부서명</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'receiveUserDept'}
                                          value={ReturnAskListStore.data.receiveUserDept || ''}
                                          disabled={isViewMode}
                                          onChange={v => ReturnAskListStore.data.receiveUserDept = v.target.value}
                            />
                            <InputGroup.Text>직급</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'receiveUserPosition'}
                                          value={ReturnAskListStore.data.receiveUserPosition || ''}
                                          disabled={isViewMode}
                                          onChange={v => ReturnAskListStore.data.receiveUserPosition = v.target.value}
                            />
                            <InputGroup.Text className='input-required'>받는분</InputGroup.Text>
                            <Form.Control style={{flex: 2, textAlign: 'left'}} aria-describedby={'receiveUserName'}
                                          value={ReturnAskListStore.data.receiveUserName || ''}
                                          disabled={isViewMode}
                                          onChange={v => ReturnAskListStore.data.receiveUserName = v.target.value}
                            />
                        </InputGroup>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={7} style={{marginTop: 12}}>
                        <Row>
                            <CCol lg={5}>
                                {/* isChangeTip &&
                                    <div style={{width: 230, height: 40, alignContent: 'center', textAlign: 'center', backgroundColor: '#000', color: '#fff', borderRadius: 10, position: 'absolute', marginTop: -50}}>
                                        <label>회수 희망일을 선택하세요.</label>
                                    </div>
                                */}
                                <CalendarGroup asSingle={true}  label={'회수희망일'} labelClassName='input-required'
                                               value={{
                                                    startDate: ReturnAskListStore.data.returnExpectDate,
                                                    endDate: ReturnAskListStore.data.returnExpectDate
                                                }}
                                               onChange={v => ReturnAskListStore.data.returnExpectDate = dateFormat(v.startDate)}
                                               minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                               disabled={isViewMode}
                                />
                            </CCol>
                            <CCol lg={3}>
                                <CSelectGroup label={'시간'} labelId={'shipHopeTime'}
                                              options={[{name: '선택', value: ''}].concat(getCommcode('29'))}
                                              value={ReturnAskListStore.data.shipHopeTime || ''}
                                              onChange={v => ReturnAskListStore.data.shipHopeTime = v.target.value}
                                              disabled={isViewMode}
                                              labelClassName="input-required"
                                />
                            </CCol>
                            <CCol lg={4}>
                                <CSelectGroup label={'배송방법'} labelId={'shipType'} labelClassName='input-required'
                                              placeholder={'배송방법'}
                                              options={[{name: '선택', value: ''}].concat(getCommcode('4'))}
                                              value={ReturnAskListStore.data.shipType || ''}
                                              onChange={v => ReturnAskListStore.data.shipType = v.target.value}
                                              disabled={isViewMode}
                                />
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={12} md={12}>
                                <Address label='주소'
                                         setPostcode={setPostcode}
                                         setAddress={setAddress}
                                         setAddressDetail={setAddressDetail}
                                         postcode={postcode}
                                         address={address}
                                         addressDetail={addressDetail}
                                         addressDisabled={isViewMode}
                                         disabled={isViewMode}
                                />
                            </CCol>
                        </Row>
                    </CCol>
                    <CCol lg={5}>
                        <div className="mb-3">
                            <label className="form-label">배송비고</label>
                            <textarea className="form-control" rows="2"
                                      value={ReturnAskListStore.data.shipRemark || ''}
                                      disabled={isViewMode}
                                      onChange={v => ReturnAskListStore.data.shipRemark = v.target.value}
                                      placeholder={'회수 상세 시간 / 회수 비고 사항'}
                            />
                        </div>
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        {!isViewMode &&
                            <CSaveBtn style={{width: 80}}
                                    title={'저장'}
                                    onClick={saveReturnAsk}
                            />
                        }
                        {ReturnAskListStore.data.returnAskStatus === '1' &&
                            <CIconBtn style={{width: 120}}
                                    title={'회수의뢰'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => saveReturnAskStatus('2', '회수의뢰')}
                            />
                        }
                        {/*
                        {ReturnAskListStore.data.returnAskStatus === '2' && (
                            <CIconBtn style={{width: 80}}
                                    title={'회수진행'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => saveReturnAskStatus('3', '회수진행')}
                            />
                        )}
                        {ReturnAskListStore.data.returnAskStatus === '3' && (
                            <CIconBtn style={{width: 80}}
                                    title={'회수완료'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => saveReturnAskStatus('4', '회수완료')}
                            />
                        )} */}
                        {(['1', '2', '3'].includes(ReturnAskListStore.data.returnAskStatus)) && (
                            <CCancelBtn style={{width: 120}}
                                    title={'회수취소'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => saveReturnAskStatus('9', '회수취소')}
                            />
                        )}
                        {![null, '', '1', '9'].includes(ReturnAskListStore.data.returnAskStatus) && (
                            <>
                                <CIconBtn style={{width: 130}}
                                        title={'회수의뢰서'}
                                        icon={'fi-rr-box-check'}
                                        onClick={() => returnAskPrint()}
                                />
                                <ReportReturnAskTemplate
                                        ref={componentRef}
                                        contractNo={contractNo}
                                />
                            </>
                        )}
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <Subject>회수 제품 리스트</Subject>
                <AgGridContainer
                    gridRef={gridRef}
                    height={40}
                    rowData={ReturnAskListStore.returnAskItemList}
                    columnDefs={
                        [
                            {field: "returnAskSeq", headerName: "회수의뢰번호", hide: true},
                            {field: "productSeq", headerName: "제품 시퀀스", hide: true},
                            {field: "seq", headerName: "순번", width: 80},
                            {field: "assetNo", headerName: "자산번호", width: 120, hide: true},
                            {field: "managementNo", headerName: "관리번호", width: 120},
                            {field: "productName", headerName: "제품명", width: 150},
                            {field: "model", headerName: "모델명", width: 150},
                            {field: "qty", headerName: "수량", width: 80},
                            {field: "serialNo", headerName: "시리얼번호", headerClass: 'grid-column-editable', width: 150},
                            {field: "spec", headerName: "스펙", width: 200},
                            {field: "remark", headerName: "비고", headerClass: 'grid-column-editable', width: 150, editable: true},
                            {
                                field: "isCancel",
                                headerName: "취소여부",
                                cellEditorParams: {
                                    values: [0, 1],
                                },
                                valueFormatter: v => (v.data.isCancel === 0 || v.data.isCancel === '') || v.data.isCancel === null ? '' : '취소',
                                width: 120
                            },
                            {field: "storageNo", headerName: "입고번호", width: 120},
                            {field: "cancelUserName", headerName: "취소자", width: 120},
                            {field: "cancelDate", headerName: "취소일자", valueGetter: v => dateTimeFormat(v.data.cancelDate), width: 180},
                        ]
                    }
                    seqColumn={'seq'}
                    useUpdated={ReturnAskListStore.data.returnAskSeq && ReturnAskListStore.data.contractNo && ReturnAskListStore.data.returnAskStatus === '1' ? true: false}
                    customBtnInfo={[customReturnItemBtn]}
                    callBackGridData={saveReturnAsk}
                    getRowStyle={(params) => {
                        if (params.data.isCancel === 1) {
                            return { pointerEvents: 'none', color: '#bababa' };
                        }
                        return null;
                    }
                }
                />
            </CContainer>
            {/* 계약 조회 검색 모달 */}
            <ContractSearch callbackFn={callbackContractInfo}/>
            <ReturnItemPopup 
                contractAssetList={contractAssetList} 
                detailContractNo={contractNo} 
                setDetailContractNo={setContractNo} 
                getLastReleaseAskInfo={getLastReleaseAskInfo}
            />
        </div>
    );
}

export default observer(ReturnAskDetail);
