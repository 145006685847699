import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Form, Button, Image, Container } from "react-bootstrap";
import axios from "axios";
import { refreshCommcode } from "../utils/commcode";
import { refreshUserInfo } from "../utils/userUtils";
import { callAlert, callConfirm } from "../utils";
import { AppStore } from "../store/AppStore";
import LUtils from "../utils/lodashUtils";
import { encrypt } from "../common/utils";

{/* <link rel="stylesheet" type="text/css"  href="%PUBLIC_URL%/asset/css/login.css"> */}

{/* <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"></script> */}

const Login = () => {

    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [idValid, setIdValid] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [checkRememberId, setCheckRememberId] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        if (localStorage.getItem('rememberId')) {
            setUserId(localStorage.getItem('rememberId'));
            setCheckRememberId(true);
        } else {
            setCheckRememberId(false);
        };
    }, []);

    const processLogin = async () => {
        if(!userId){
            await callAlert('아이디를 입력해주세요');
            return;
        }
        if(!password){
            await callAlert('비밀번호를 입력해주세요');
            return;
        }

        try {
            const params = {id: userId, password: encrypt(password)};
            const result = await axios.post('/user/login', params);
            if(result.result === 'login_failed') {
                alert(result.message);
            }else if(result.result === 'option_failed') {
                alert(result.message);
            }else {
                AppStore.setUser(result);

                /* 로그인 성공 후 실행 */
                await axios.post('/user/login/success', {userSeq: result.userSeq})  // 로그인 성공 시 로그 저장
                .then(() => {
                    refreshCommcode();  // 공통코드 조회 & localStorage저장
                    refreshUserInfo()   // user 정보 조회 & localStorage저장
                    .then(() => {
                        navigate('/dashboard', { replace: true });
                    }); 
                });

                // 아이디 저장 확인
                const checkbox = document.getElementById('rememberMe');
                if (checkbox.checked) {
                    localStorage.setItem('rememberId', userId);
                } else {
                    localStorage.removeItem('rememberId');
                }
            }
        } catch (error) {
            // console.log(error.response.status);
            const {code, message, status} = LUtils.get(error.response, 'data');
            if (code === 'NO_LOGIN_CODE') {
                callAlert('외부 접속 : 접속이 불가합니다.');
                // if(await callConfirm("외부 접속 : 인증번호를 발급 받으시겠습니까?")) {
                //     sendSms();
                // }
            }
        }
    }

    /* 안증번호 알림톡 발송 */
    const sendSms = async () => {
        // 인증번호 생성 및 발송
        const result = await axios.post('/sms/send/loginCode', {id : userId});

        if(result.result === 'login_failed') {
            alert(result.message);
        }else if(result.result === 'option_failed') {
            alert(result.message);
        }else if (result.code === 0) {
            await callAlert("인증번호가 발급되었습니다. 인증번호로 로그인해주세요.");
            setPassword('');
            setPasswordValid(true);
        }else {
            await callAlert("["+result.code+"] : " + result.message);
        }
    }

    return (
        <div className="login-body">
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" />
            {/* <div className="d-flex align-items-center min-vh-100 py-3 py-md-0"> */}
            <div className="d-flex justify-content-center align-items-center min-vh-100"
                 style={{backgroundSize: 'cover', backgroundImage: `url(${process.env.PUBLIC_URL}/image/2.jpg)`}}>
                <div style={{width: '900px'}}>
                    <div className="login-card" style={{boxShadow: 'rgb(28 33 47 / 60%) 0px 0px 10rem 0px'}}>
                        <div className="row no-gutters" style={{height:'100%'}}>
                            <div style={{height: 60, zIndex: 1000, backgroundColor: '#fff', width: '100%', paddingTop: 12, paddingLeft: 25, borderBottomWidth: 0}}>
                                <div className="brand-wrapper d-flex justify-content-lg-start align-items-center flex-row">
                                    <Image src={`${process.env.PUBLIC_URL}/image/pelotonlab_ci.png`} alt="logo" className="logo"/>
                                </div>
                            </div>
                            <div className="col-md-5" style={{backgroundColor: '#fff'}}>
                            {/* style={{borderRight: '1px solid #D8D8D8'}} */}
                                <img src={`${process.env.PUBLIC_URL}/image/awp_login.png`} alt="로그인 이미지" style={{paddingTop: 20}}/>
                            </div>
                            <div className="col-md-7">
                                {/* 로그인 폼 요소 중앙 정렬 */}
                                {/* style={{background: '#7cd7e9'}}*/}
                                <div className="card-body d-flex justify-content-center align-items-center flex-column" style={{paddingBottom: 40, paddingTop: 40}}>
                                {/* 로그인 폼 요소 왼쪽 정렬 */}
                                {/* <div className="card-body"> */}
                                    <div>
                                        <h6 style={{color: '#6E6E6E'}}>렌탈 비즈니스 최고의 파트너!</h6>
                                    </div>
                                    <div>
                                        <label className="login-card-description" style={{color: '#0B2161'}}>건설 장비 <b style={{fontSize: 25, color: '#084B8A'}}>Rent Plan.</b></label>
                                    </div>
                                    <div>
                                        <Form style={{width: '400px'}}>
                                            <Form.Group className='form-group' controlId="formBasicId">
                                                <Form.Label className='sr-only'>ID</Form.Label>
                                                <Form.Control type='text' value={userId} onChange={v => setUserId(v.target.value)} placeholder='아이디를 입력해 주세요.'/>
                                            </Form.Group>
                                            <Form.Group className='form-group' controlId="formBasicPassword">
                                                <Form.Label className='sr-only'>비밀번호</Form.Label>
                                                <Form.Control type='password'
                                                              value={password}
                                                              onKeyPress={(e)=> e.code === 'Enter' && processLogin()}
                                                              onChange={v => setPassword(v.target.value)}
                                                              autoComplete="on"
                                                              placeholder='비밀번호를 입력해 주세요.'
                                                />
                                            </Form.Group>
                                            <Button className='btn btn-block login-btn mb-4' type="button" variant={'primary'} onClick={processLogin}>
                                                로그인
                                            </Button>
                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                <Form.Check className="small" type='checkbox' id='rememberMe' label='아이디 기억하기'
                                                    checked={checkRememberId} onChange={(e) => setCheckRememberId(prevState => !prevState)}/>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                            <div style={{height: 120, zIndex: 1000, backgroundColor: '#f6f6f6', width: '100%', color: '#000', paddingTop: 20, paddingLeft: 20, paddingBottom: 15, alignItems: 'center'}}>
                                <span>
                                    <b style={{color: '#000'}}>(주)펠로톤랩</b>
                                </span>
                                <div>
                                    대표자 : 김규완&nbsp;&nbsp;&nbsp;사업자 등록번호: 395-88-02597&nbsp;&nbsp;&nbsp;통신판매업신고 : 제2024-서울금천-1931호&nbsp;&nbsp;&nbsp;개인정보관리책임자: 김기준&nbsp;&nbsp;&nbsp;이메일 : jun@pelotonlab.it
                                    <br/>전화번호 : 02-6958-8911&nbsp;&nbsp;&nbsp;주소 : 서울특별시 금천구 벚꽃로36길 30, 가산KS타워 11층
                                </div>
                                Copyright © 2023 Peloton Lab All rights reservered.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(Login);
