import { Outlet } from "react-router-dom";

import GuestGuard from "../utils/route-guard/GuestGuard";
import Policy from "../views/Policy";

const PolicyRoutes = {
    path: '/',
    element: (
            <GuestGuard>
                <Outlet />
            </GuestGuard>
        ),
    children: [
        {path: '/policy', element: <Policy />},
    ]
}

export default PolicyRoutes;
