import React from "react";
import { observer } from "mobx-react-lite";
import axios from 'axios';
import { Row, Col } from "react-bootstrap";

import { StatementStore } from "../../store/accounting/StatementStore";
import { CCol, CContainer, CalendarGroup, CInputGroup, CSelectGroup, CSearchBtn, CIconBtn } from "../../components/CustomContainer";
import { getCommcode } from "../../utils/commcode";
import { callConfirm } from "../../utils";
import {showToast} from "../../common/utils";

const StatementSearch = ({getInputStatementList, viewMode, setViewMode}) => {
    const store = StatementStore;

    const saveSettings = async () => {
        const inputStatementList = store.inputStatementList.filter(v => v.isSelected);
        if (inputStatementList.length < 1 || !await callConfirm('설정저장 하시겠습니까?')) return;

        axios.put('statement/auto', {autoStatementList: inputStatementList}).then(showToast('저장 되었습니다.'));
    }

    return (
        <CContainer search>
            {/* <Row>
                <CCol lg={12}>
                    <ul className="nav nav-pills pt-3 pb-3" style={{borderTopWidth: 0, borderBottomWidth: 1, borderColor: '#f2f2f2'}}>
                        <li className="nav-item" style={{borderRadius: 4, boxShadow: 'rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px'}}>
                            <a className={viewMode === 'input' ? "nav-link active" : "nav-link"}
                                style={{cursor: "pointer"}}
                                onClick={()=> setViewMode('input')}>입금내역</a>
                        </li>
                        <li className="nav-item" style={{marginLeft: 10, borderRadius: 4, boxShadow: 'rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px'}}>
                            <a className={viewMode === 'output' ? "nav-link active" : "nav-link"}
                                style={{cursor: "pointer"}}
                                onClick={()=> setViewMode('output')}>출금내역</a>
                        </li>
                    </ul>
                </CCol>
            </Row> */}
            <Row>
                <CCol lg={2}>
                    <CalendarGroup label={'시작일'}
                                    asSingle={true} 
                                    value={{
                                        startDate: viewMode === 'input' ? store.inputSearch.startDate : store.outputSearch.startDate,
                                        endDate: viewMode === 'input' ? store.inputSearch.startDate : store.outputSearch.startDate
                                    }}
                                    onChange={v => {viewMode === 'input' ? store.inputSearch.startDate = v.startDate : store.outputSearch.startDate = v.startDate}}
                    />
                </CCol>
                <CCol lg={2}>
                    <CalendarGroup label={'종료일'}
                                    asSingle={true} 
                                    value={{
                                        startDate: viewMode === 'input' ? store.inputSearch.endDate : store.outputSearch.endDate,
                                        endDate: viewMode === 'input' ? store.inputSearch.endDate : store.outputSearch.endDate
                                    }}
                                    onChange={v => {viewMode === 'input' ? store.inputSearch.endDate = v.startDate : store.outputSearch.endDate = v.startDate }}
                    />
                </CCol>
                <CCol lg={2}>
                    <CSelectGroup label={'은행'} labelId={'bankCode'}
                                    placeholder={'은행'}
                                    options={[{name: '전체', value: ''}].concat(getCommcode('24'))}
                                    value={viewMode === 'input' ? store.inputSearch.bankCode : store.outputSearch.bankCode}
                                    onChange={v => viewMode === 'input' ? store.inputSearch.bankCode = v.target.value : store.outputSearch.bankCode = v.target.value}
                    />
                </CCol>
                <CCol lg={2}>
                    <CSelectGroup
                        label={'조회구분'} labelId={'searchType'}
                        options={[
                            {name: '전체', value: ''},
                            {name: '미전표생성', value: 'preStatement'},
                            {name: '전표생성완료', value: 'completeStatement'},
                        ]}
                        value={viewMode === 'input' ? store.inputSearch.searchType : store.outputSearch.searchType}
                        onChange={v => viewMode === 'input' ? store.inputSearch.searchType = v.target.value : store.outputSearch.searchType = v.target.value}
                    />
                </CCol>
                <CCol lg={2}>
                    <CInputGroup
                        label={'계좌번호'} labelId={'accountNumber'}
                        value={viewMode === 'input' ? store.inputSearch.accountNumber : store.outputSearch.accountNumber}
                        onChange={v => viewMode === 'input' ? store.inputSearch.accountNumber = v.target.value : store.outputSearch.accountNumber = v.target.value}
                    />
                </CCol>
            </Row>
            <Row>
                <Col className='d-flex flex-row-reverse'>
                    <CSearchBtn onClick={getInputStatementList} />
                    <CIconBtn style={{width: 100, height: 30}}
                            title={'설정저장'}
                            icon={'fi-sr-settings'}
                            onClick={saveSettings}
                    />
                </Col>
            </Row>
        </CContainer>
    );
}

export default observer(StatementSearch);