import { observer } from 'mobx-react-lite';
import React, {useEffect, useRef, useState} from 'react';
import {CCol, CContainer, CInputGroup, CSelectGroup, CalendarGroup, CSaveBtn} from "../components/CustomContainer";
import {Row, InputGroup, Form, Tabs, Tab, Image} from "react-bootstrap";
import { UserStore } from "../store/human-resources/UserStore";
import Address from "../components/Address";
import { getCommcode } from "../utils/commcode";
import { encrypt, decrypt, showToast, autoHyphen } from "../common/utils";
import { callConfirm } from "../utils";
import { getSessionUserSeq } from '../utils/userUtils';
import axios from "axios";
import {MULTIPART_HEADER} from '../common/constants';

const MyPage =()=> {

    const imgRef = useRef();
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [gender, setGender] = useState('');
    const [telephone, setTelephone] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [imgUrl, setImgUrl] = useState('');


    useEffect(()=> {
        getUserInfo();
    }, []);

    const getUserInfo = async () => {
        const result = await axios.get('/user/detail', { params: {userSeq: getSessionUserSeq()} });
        if (result.length === 0) {
            showToast('조회된 데이터가 없습니다.');
        }

        setUserId(result.id);
        setUserName(result.name)
        setBirthDate(result.birthDate);
        setGender(result.genderSeq);
        setTelephone(result.telephone);
        setPhoneNumber(decrypt(result.phoneNumber));
        setEmail(decrypt(result.email));
        setPostcode(result.postcode);
        setAddress(result.address);
        setAddressDetail(result.addressDetail);
        setImgUrl({uri: result.imageUrl});

        UserStore.userDetail = result;
    }

    /* 파일 업로드 하고 url 가져오기 */
    const fileUploadForS3 = async (fileData) => {

        if (fileData.target.files === '') {
            UserStore.userDetail.imageUrl = '';
            return false;
        }

        const file = fileData.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        const result = await axios.post('/file/upload/s3', formData, { headers: MULTIPART_HEADER });

        UserStore.userDetail.imageUrl = result;
    }

// 새로 등록 or 수정 후 저장
    const updateUser = async () => {
        // 필수값 확인
        if(!UserStore.userDetail.name){
            showToast('이름을 입력해주세요.');
            return;
        }
        if(!phoneNumber){
            showToast('휴대폰 번호를 입력해주세요.');
            return;
        }
        if(!email){
            showToast('이메일 주소를 입력해주세요.');
            return;
        }

        // 주소 세팅
        const params = {
            userSeq: getSessionUserSeq(),
            isUpdatePassword: password && true,
            password: password && encrypt(password),
            userName: userName,
            telephone: telephone,
            phoneNumber: encrypt(phoneNumber),
            email: encrypt(email),
            birthDate: birthDate,
            genderSeq: gender,
            postcode: postcode,
            address: address,
            addressDetail: addressDetail,   
        }

        if(!await callConfirm('수정하시겠습니까?')) return;
        await axios.put('user', params);
        showToast("수정이 완료되었습니다.");
        // setPassword('');

        getUserInfo();
    }

    return (
        <Tabs
            defaultActiveKey="userInfo"
            className="mb-3"
            mountOnEnter={true}
            unmountOnExit={true}
            activeKey={'userInfo'}
            style={{width: 540}}
        >
            <Tab eventKey="userInfo" title="마이 페이지">
                <CContainer>
                    <Row>
                        <CCol>
                            {/* 이미지 첨부 */}
                            {imgUrl && <div style={{ height: 120, overflow: "hidden"}}>
                                <Image src={UserStore.userDetail.imageUrl} style={{ height: 120, objectFit: "cover" }} alt="프로필 이미지" thumbnail /></div>}
                            {!imgUrl && <i className="fi fi-rr-user" style={{ fontSize: 60 }} />}
                            <p></p>
                            <InputGroup>
                                <Form.Control
                                    type="file"
                                    id="profileImg"
                                    size="sm"
                                    onChange={fileUploadForS3}
                                    ref={imgRef}
                                />
                            </InputGroup>
                        </CCol>
                    </Row>
                    <Row>
                        <CCol>
                            <CInputGroup label={'ID'}
                                labelId={'ID'}
                                value={userId}
                                disabled={true}/>
                        </CCol>
                        <CCol>
                            <CInputGroup label={'비밀번호'}
                                labelId={'password'}
                                placeholder={'비밀번호는 표시되지 않습니다.'}
                                value={password}
                                onChange={v => setPassword(v.target.value)}
                                type={'password'}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <CCol>
                            <CInputGroup label={'이름'}
                                labelId={'name'}
                                value={userName}
                                onChange={v => setUserName(v.target.value)}/>
                        </CCol>
                        <CCol>
                            <CalendarGroup
                                asSingle={true}
                                label={'생년월일'}
                                value={{
                                    startDate: birthDate,
                                    endDate: birthDate
                                }}
                                onChange={v => setBirthDate(dateFormat(v.startDate))}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <CCol log={3}>
                            <CSelectGroup label={'성별'}
                                labelId={'genderSeq'}
                                options={[{ name: '전체', value: '' }].concat(getCommcode('35'))}
                                value={gender || ''}
                                onChange={v => setGender(v.target.value)}
                            />
                        </CCol>
                        <CCol>
                            <CInputGroup label={'전화번호'}
                                labelId={'telephone'}
                                value={telephone}
                                onChange={v => setTelephone(v.target.value)} />
                        </CCol>
                    </Row>
                    <Row>
                        <CCol>
                            <CInputGroup label={'휴대폰'}
                                labelId={'phoneNumber'}
                                value={autoHyphen(phoneNumber)}
                                onChange={v => setPhoneNumber(autoHyphen(v.target.value))}
                            />
                        </CCol>
                        <CCol>
                            <CInputGroup label={'이메일'}
                                labelId={'email'}
                                type={'email'}
                                value={email}
                                onChange={v => setEmail(v.target.value)}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={4}>
                            <Address label='주소'
                                setPostcode={setPostcode}
                                setAddress={setAddress}
                                setAddressDetail={setAddressDetail}
                                postcode={postcode}
                                address={address}
                                addressDetail={addressDetail}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={4}>
                            <CSaveBtn style={{ width: '100%' }}
                                title={'저장'}
                                onClick={updateUser}
                            />
                        </CCol>
                    </Row>
                </CContainer>
            </Tab>
        </Tabs>
    );
}

export default observer(MyPage);