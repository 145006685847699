import {useRef, useState} from "react";
import { observer } from "mobx-react-lite";
import {CContainer} from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import {autoHyphen, decrypt, showToast} from "../../common/utils";
import {getUserName} from "../../utils/userUtils";
import {AppStore} from "../../store/AppStore";
import {ICON_TRASH} from "../../common/constants";
import {SiteInfoStore} from "../../store/customer/SiteInfoStore";
import axios from "axios";
import {callConfirm} from "../../utils";
import SiteInfoSearch from "./SiteInfoSearch";
import SiteDetailPopup from "./popup/SiteDetailPopup";
import {findCommKrnm} from "../../utils/commcode";

const SiteInfo = () => {
    const gridRef = useRef();
    const [siteSeq, setSiteSeq] = useState('');

    /* 조회 */
    const getSiteInfoList = async () => {
        const params = {
            ...SiteInfoStore.searchCondition,
            startDate: SiteInfoStore.startDate.startDate,
            endDate: SiteInfoStore.endDate.startDate
        };
        let result = await axios.get('/site/info', { params });

        if(result.length === 0){
            showToast('검색된 데이터가 없습니다.');
            return;
        }

        const arrPhoneNumberDecrypt =(number)=> {
            const arrNumber = number.split(',');
            let decryptNumbers = '';

            for(let num of arrNumber){
                decryptNumbers += decrypt(num).replaceAll('-','') + ',';
            }

            return decryptNumbers;
        }

        result.map(v => {
            v.managerPhoneNumber = v.managerPhoneNumber ?  arrPhoneNumberDecrypt(v.managerPhoneNumber) : '';
            return v;
        });   // 핸드폰 복호화

        if(SiteInfoStore.searchCondition.managerPhoneNumber){
            result = result.filter(v =>  v.managerPhoneNumber && v.managerPhoneNumber.includes(SiteInfoStore.searchCondition.managerPhoneNumber.replaceAll('-','')));
        }

        if(SiteInfoStore.searchCondition.managerName){
            result = result.filter(v =>  v.managerName && v.managerName.includes(SiteInfoStore.searchCondition.managerName));
        }

        SiteInfoStore.setSiteList(result);
    }

    /* 삭제 */
    const deleteSite =async()=> {
        // 선택한 row
        const siteList = SiteInfoStore.siteList.filter(v => v.isSelected);

        if(siteList.length === 0){
            showToast('삭제건을 선택해 주세요');
            return;
        }

        if(!await callConfirm(`삭제하시겠습니까?`)) return;

        const result = await axios.put(`/site/info/delete`, siteList);
        showToast('삭제되었습니다.');

        await getSiteInfoList();
    }

    const customButtonInfo = [
        {
            isUsed: true,
            callbackFn:() => deleteSite(),
            variant: 'danger',
            icon: ICON_TRASH,
            title: '삭제',
            width: 100
        }
    ];

    return(
        <>
            <SiteInfoSearch getSiteInfoList={getSiteInfoList}/>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    gridTitle={'현장 목록'}
                    height={60}
                    rowData={SiteInfoStore.siteList}
                    columnDefs={[
                        {field: "siteSeq", headerName: "번호", width: 90},
                        {field: "siteName", headerName: "현장명", width: 180},
                        {field: "managerName", headerName: "현장 담당자", width: 180},
                        {field: "siteStatus", headerName: "현장상태", width: 120, valueFormatter: v => findCommKrnm(v.value,'54')},
                        {field: "address", headerName: "주소", width: 200},
                        {field: "addressDetail", headerName: "상세주소", width: 200},
                        {field: "characterRemark", headerName: "현장특성", width: 200},
                        {field: "creator", headerName: "작성자", width: 120, valueFormatter: v => getUserName(v.value)},
                        {field: "createdDate", headerName: "작성일자", width: 180},
                        {field: "updater", headerName: "수정자", width: 120, valueFormatter: v => getUserName(v.value)},
                        {field: "updatedDate", headerName: "수정일자", width: 180},
                    ]}
                    isCheckBox={true}
                    seqColumn={'siteSeq'}
                    useCsvDownload={true}
                    rowDoubleClickCallback={(param)=> {
                        setSiteSeq(param.data.siteSeq);
                        AppStore.toggleSiteDetail();
                    }}
                    customBtnInfo={customButtonInfo}
                />
            </CContainer>
            <SiteDetailPopup siteSeq={siteSeq} setSiteSeq={setSiteSeq} getSiteInfoList={getSiteInfoList}/>
        </>
    );
}

export default observer(SiteInfo);
