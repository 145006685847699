import React from 'react';
import {observer} from "mobx-react-lite";

import AgGridContainer from '../../../components/AgGridContainer';

const GridEstimateList = ({
    gridTitle,
    gridRef,
    height,
    rowData,
    columnDefs,
    seqColumn,
    useCsvDownload,
    useRowSearch,
    rowSearchCallback,
    rowDoubleClickCallback,
}) => {
  return (
      <AgGridContainer
            gridTitle={gridTitle}
            ref={gridRef}
            height={height}
            rowData={rowData}
            columnDefs={columnDefs}
            seqColumn={seqColumn}
            useCsvDownload={useCsvDownload}
            useRowSearch={useRowSearch}
            rowSearchCallback={rowSearchCallback}
            rowDoubleClickCallback={rowDoubleClickCallback}
        />
  );
};

export default observer(GridEstimateList);
