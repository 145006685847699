import axios from "axios";
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react";
import { AppStore } from "../../../store/AppStore";
import Modal from "../../../components/Modal";
import { CContainer } from "../../../components/CustomContainer";
import AgGridContainer from "../../../components/AgGridContainer";

const AssetOptionPopup = ({assetNo, list=null}) => {
    const [optionList, setOptionList] = useState([]);

    useEffect(() => {
        if (list === null) {
            getAssetOptionList();
        } else {
            setOptionList(list);
        }
    }, [AppStore.isOpenAssetOptionPopup]);

    const getAssetOptionList = async () => {
        const result = await axios.get('/asset/option', {params: {assetNo: assetNo}});
        setOptionList(result);
    }


    return (
        <Modal
            title={'자산에 연결된 옵션 내역'}
            show={AppStore.isOpenAssetOptionPopup}
            onHide={() => AppStore.toggleOpenAssetOptionPopup()}
            toggleValue={AppStore.isOpenAssetOptionPopup}
            className={'search-modal-h5'}
            //onExit={refresh}
        >
            <CContainer>
                <AgGridContainer
                    height={23}
                    rowData={optionList}
                    columnDefs={[
                        {field: "seq", headerName: "순번", width: 80},
                        {field: "optionAssetNo", headerName: "구분", width: 100, valueFormatter: v => v.value !== '' ? '자산옵션' : '일반옵션'},
                        {field: "optionProductName", headerName: "제품명", width: 180},
                        {field: "optionModel", headerName: "모델명", width: 180},
                        {field: "storageNo", headerName: "입고번호", width: 110, hide:true},
                        {field: "isUse", headerName: "연결여부", width: 100, valueFormatter: v => v.value === 0 ? 'N' : 'Y'},
                        {field: "createdDate", headerName: "연결일자", width: 200},
                    ]}
                    getRowStyle={(v) => {
                        if (v.data.isUse !== 1) {
                            return { color: '#bababa', pointerEvents: 'none', };
                        }
                        return null;
                    }}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(AssetOptionPopup);