import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const BillingListStore = observable({
    billingList: [],
    billingChargeList: [],
    billingSearchCondition: {
        contractType: '', startDate: '', endDate: '', strStartDate: '', strEndDate: '', customerNo: '', 
        customerName: '', billingAddressSeq: '', billingName: '', billingType: '', companyCode: '', siteName: '',
        saleUserSeq: '', supportUserSeq: '', userName: '', isBillIssue: '', isReceiveComplete: '', exceptDel: 1,
        contractNo: '', billNo: '', billingChargeNo: '', paymentType: '', managementNo: '', serialNo: '',
    },
    billingHistoryList: [],

    setBillingList(v) {
        this.billingList = v;
    },
    setBillingSearchCondition(v) {
        LUtils.assign(this.billingSearchCondition, v);
    },
    setBillingHistoryList(v) {
        this.billingHistoryList = v;
    }
});
