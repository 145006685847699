import _ from 'lodash';

export default {
    /* --------- OBJECT --------- */
    /**
     * [Object] object에서 값 가져오기
     * @param value: Object
     * @param path: 찾으려는 값 또는 경로
     * @param defaultValue: (생략가능) path 값이 없는 경우(null 또는 undefined) 반환 할 값
     */
    get: (value, path, defaultValue = null) => _.get(value, path, defaultValue),

    /**
     * [Object] object 합치기
     * @param targetObject: 합쳐지는 Object(기준이 되는 Object)
     * @param value: 합치려는 Object (targetObject와 중복되는 값이 있을땐 이 value의 값으로 합쳐진다.)
     */
    assign: (targetObject, value) => _.assign(targetObject, value),

    /**
     * [Object] Array|Object|string 안에 있는값이 있으면 true 반환
     * @param value: Object|Array|string
     * @param targetValue: string
     */
    includes: (value, targetValue) => _.includes(value, targetValue),

    /**
     * [Object] object안에 값 뽑아서 object로 반환
     * @param value: Object
     * @param targetValue: Array
    */
    pick: (value, targetValue) => _.pick(value, targetValue),

    /**
     * [Object] object안에 조건식과 일치하는 key값 반환
     * @param {Object} value 
     * @param {Function} findObject 
     * @returns {String} key
     */ 
    findKey: (value, findObject) => _.findKey(value, findObject),

    /**
     * [Object] object안에 컬럼삭제 후 새로운 object 반환
     * @param {Object} value 
     * @param {Array} targetColumns 삭제할 컬럼 key 값 
     */
    omit: (value, targetColumns) => _.omit(value, targetColumns),

    /**
     * [Object] object로 for문 실행
     * @param {Object} value
     * @param {Function} iterationFn
     */
    forOwn: (value, iterationFn) => _.forOwn(value, iterationFn),

    /**
     * [Object] object에 값을 배열로 반환
     * @param {Object} value 
     */
    values: (value) => _.values(value),



    /* --------- ARRAY --------- */
    /**
     * [Array] 배열에 안에 일치하는 변수값 찾기
     * @param value: Array
     * @parma function or object or string: 찾으려는 변수
     * @returns object
     */
    find: (value, findObject) => _.find(value, findObject),

    /**
     * [Array] 배열에 안에 일치하는 변수값 찾기
     * @param value: Array
     * @parma function or object or string: 찾으려는 변수
     * @returns array
     */
    filter: (value, filterObject) => _.filter(value, filterObject),

    /**
     * [Array] 새 배열 만들기
     * @param value: Array
     * @parma function or string: 반환 하려는 배열값 정의
     * @returns array
     */
    map: (value, fn) => _.map(value, fn),

    /**
     * [Array] 배열합치기
     * @param value: Array
     * @param array: Array
     */
    concat: (value, array) => _.concat(value, array),

    /**
     * [Array] 배열에 중복제거
     * @param value: Array
     * @param array: (생략가능) Array
     */
    unionBy: (value, array) => _.unionBy(value, array),

    /**
     * [Array] 배열에 값 삭제
     * @param value: Array
     * @param function: 표현식
     */
    remove: (value, fn) => _.remove(value, fn),

    /**
     * [Array] 배열 또는 문자열 자름
     * @param value: Array or String
     * @param startIndex: Number
     * @param endIndex: Number
     */
    slice: (value, startIndex, endIndex) => _.slice(value, startIndex, endIndex),

    /**
     * [Array] 배열에 해당하는 인덱스를 반환
     * @param {Array} value: Array
     * @param {any} target: 찾으려는 조건식
     * @param {Number} endIndex: 검색 범위 (생략가능)
     */
    findIndex: (value, target, fromIndex) => _.findIndex(value, target, fromIndex),


    /* --------- STRING --------- */
    /**
     * [String] 특정 문자열로 잘라서 배열로 반환
     * @param value: String
     * @param separator: 분할할 구분자 패턴
     * @param limit: (생략가능) 반환될 배열 제한
     */
    split: (value, separator, limit = null) => {
        return !!limit ? _.split(value, separator, limit) : _.split(value, separator);
    },

    /**
     * [String] 첫글자 비교 (원하는 자릿수 설정가능)
     * @param value: String
     * @param target: String 비교하려는 문자
     * @param position: (생략가능) Number 비교하려는 위치
     */
    startsWith: (value, target, position = 0) => _.startsWith(value, target, position),

    /**
     * [String] 문자열에 특정 문자열 변경
     * @param {String} value: 전체 문자열
     * @param {String} pattern: 변경할 문자열
     * @param {String} replacement: 변경될 문자열
     */
    replace: (value, pattern, replacement) => _.replace(value, pattern, replacement),


    /* --------- Collection --------- */
    /**
     * [Collection] 한개라도 true인지 확인 - 배열로 받은 값이 1개라도 true면 true 반환, 모두 false인 경우 false 반환
     * @param value: Array 검사할 배열
     * @param fn: (생략가능) 검사할 조건
     */
    some: (value, fn) => _.some(value, fn),

    /**
     * [Collection] 모두 true인지 확인 - 한개라도 false인 경우 false 반환, 모두 true인 경우 true 반환
     * @param value: Array 검사할 배열
     * @param fn: (생략가능) 검사할 조건
     */
    every: (value, fn) => _.every(value, fn),

    /**
     * [Collection] 반복문 돌리기. 
     * @param {Object or Array or String} value: Object or Array or String 3가지 형태 모두 반복문 가능
     * @param {Function} iterationFn: 반복문 돌릴 함수
     */
    forEach: (value, iterationFn) => _.forEach(value, iterationFn),

    /**
     * [Collection] 그룹핑 
     * @param {Array} value: 그룹핑하려는 배열
     * @param {Function or String} groupByColumn: 그룹핑 하려는 대상컬럼 (하나의 컬럼만 가능)
     */
    groupBy: (value, groupByColumn) => _.groupBy(value, groupByColumn),

    /**
     * [Collection] 정렬 
     * @param {Array} value: 정렬하려는 배열
     * @param {any} target: 정렬 하려는 컬럼 또는 함수, 배열
     */
    sortBy: (value, target) => _.sortBy(value, target),


    /* --------- Lang --------- */
    /**
     * [Lang] 빈값 여부 확인 
     * @param {String} value: String
     */
    isEmpty: (value) => _.isEmpty(value),

    /**
     * [Lang] object의 값이 모두 동일한지 여부 검사
     * @param {Object} value1: String
     * @param {Object} value2: String
     */
    isMatch: (value1, value2) => _.isMatch(value1, value2),
}
