import {observable, toJS} from "mobx";

export const SiteOptionStore = observable({
    //siteOptionList: [],
    siteOptionChangeList: [],

/*    setSiteOptionList(v){
        this.siteOptionList = v;
    },*/
    setSiteOptionChangeList(v){
        this.siteOptionChangeList = v;
    },
    updateSiteOptionChangeList(v){
        if(this.siteOptionChangeList.length > 0){
            this.deleteSiteOption(v.optionSeq);
        }
        this.siteOptionChangeList.push(v);
    },
    deleteSiteOption(v){
        const optionIndex = this.siteOptionChangeList.findIndex(d => d.optionSeq === v);
        if(optionIndex > -1){
            this.siteOptionChangeList.splice(optionIndex, 1);
        }
    },
});
