import GuestGuard from "../utils/route-guard/GuestGuard";
import Login from "../views/Login";
import Policy from "../views/Policy";

const LoginRoutes = {
    path: '/',
    element: (
            <GuestGuard>
                <Login />
            </GuestGuard>
        ),
    children: [
        {path: '/login',element: <Login />},
    ]
}

export default LoginRoutes;
