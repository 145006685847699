import { observable } from "mobx";
import { getMonthOfToday, getYear, getPaymentDate, today } from "../../utils/dateUtils";

export const AttendanceStore = observable({

    userList: [],

    search: {
        year: getYear(),
        month: getMonthOfToday(),
        salaryType: '2',
        paymentDate: getPaymentDate(today()),
        companyCode: '',
    },

});