import { observable } from "mobx";
import {getDays} from "../../utils/dateUtils";

export const NonBillStore = observable({
    nonBillList: [],
    calendar: {startDate: '', endDate: ''},
    search: {
        searchDate: '',
        customerNo: '',
        customerName: '',
        contractNo: '',
        billingAddressName: '',
        billingAddressSeq: '',
        dueNonpaymentDate: '',
        saleUserSeq: '',
        saleUserName: '',
        siteSeq: '',
        siteName: '',
    },

    setNonBillList(v) {
        this.nonBillList = v;
    },
    setCalendar(v){
        this.calendar = v;
    },
    setSearch(target, v) {
        this.search[target] = v;
    }
});
