import { useState } from "react";
import { observer } from "mobx-react-lite";
import { CContainer, CCol, CSelectGroup, CalendarGroup, CInputDoubleGroup, CInputGroup, CSearchBtn } from "../../components/CustomContainer";
import { Row, Col, Form } from "react-bootstrap";
import { getCommcode } from "../../utils/commcode";
import { BillingListStore } from "../../store/billing/BillingListStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import CustomerBillingAddressSearch from "../../components/searchModal/CustomerBillingAddressSearch";
import { AppStore } from "../../store/AppStore";

const BillingListSearch = ({selView, setSelView, getContractList}) => {

    const [selSearchUserType, setSelSearchUserType] = useState(0);

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        BillingListStore.setBillingSearchCondition({customerNo: customerInfo.customerNo, customerName: customerInfo.customerName, billingSeq: null, billingName: null});
    }

    /* 청구지 조회 팝업 오픈 */
    const openSearchBillingAddressPopup = () => {
        AppStore.toggleCustomerBillingAddressSearchModal()
    }

    /* 청구지 정보 콜백 함수 */
    const getBillingAddressInfo = (info) => {
        BillingListStore.setBillingSearchCondition({
            billingAddressSeq: info.billingAddressSeq,
            billingName: info.billingAddressName,
        });
    }

     /* 유저 조회 팝업 호출 */
     const openSearchUserPopup = () => {
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        if(selSearchUserType === 1){    // 지원담당자
            BillingListStore.setBillingSearchCondition({saleUserSeq: null, supportUserSeq: userInfo.userSeq, userName: userInfo.name});
        }else {     // 영업담당자
            BillingListStore.setBillingSearchCondition({saleUserSeq: userInfo.userSeq, supportUserSeq: null, userName: userInfo.name});
        }
    }

    const handleRadioChange = (event) => {
        setSelView(event.target.value);
    };

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CSelectGroup label={'계약유형'} labelId={'contractType'}
                                      placeholder={'계약유형'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('1'))}
                                      value={BillingListStore.billingSearchCondition.contractType || ''}
                                      onChange={v => BillingListStore.setBillingSearchCondition({contractType: v.target.value})}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CalendarGroup label={'청구일자'} labelId={'chargeDate'}
                                  value={{
                                    startDate: BillingListStore.billingSearchCondition.startDate,
                                    endDate: BillingListStore.billingSearchCondition.endDate
                                  }}
                                //   onChange={v => onchangeValue(v)}
                                  onChange={v => {BillingListStore.setBillingSearchCondition(v), BillingListStore.setBillingSearchCondition({strStartDate: v.startDate, strEndDate: v.endDate})}}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputDoubleGroup
                            label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객코드'} placeholder2={'고객명'}
                            disabled={false}
                            disabled2={true}
                            onCallbackBtn={openSearchCustomerPopup}
                            value={BillingListStore.billingSearchCondition.customerNo || ''}
                            value2={BillingListStore.billingSearchCondition.customerName || ''}
                            onChange={v => BillingListStore.setBillingSearchCondition({customerNo: v.target.value})}
                            onChange2={v => BillingListStore.setBillingSearchCondition({customerName: v.target.value})}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputDoubleGroup label={'청구지'} labelId={'billingSeq'} labelId2={'billingName'}
                                           placeholder={'번호'}
                                           placeholder2={'청구지명'}
                                           disabled={true} disabled2={true}
                                           value={BillingListStore.billingSearchCondition.billingAddressSeq || ''}
                                           value2={BillingListStore.billingSearchCondition.billingName || ''}
                                           onCallbackBtn={openSearchBillingAddressPopup}
                                           onChange={v => {BillingListStore.setBillingSearchCondition({billingAddressSeq: v.target.value})}}
                                           onChange2={v => {BillingListStore.setBillingSearchCondition({billingName: v.target.value})}}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CSelectGroup label={'청구구분'} labelId={'billingType'}
                                      placeholder={'청구구분'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('23'))}
                                      value={BillingListStore.billingSearchCondition.billingType || ''}
                                      onChange={v => BillingListStore.setBillingSearchCondition({billingType: v.target.value})}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            selectOptions={
                                [
                                    {name: '영업담당자', value: 0},
                                    {name: '지원담당자', value: 1},
                                ]
                            }
                            onSelect={(e)=> {setSelSearchUserType(e.target.value), BillingListStore.setBillingSearchCondition({saleUserSeq: null, supportUserSeq: null, userName: ''})}}
                            selectValue={selSearchUserType}
                            placeholder={'사원명'}
                            disabled={true}
                            onCallbackBtn={openSearchUserPopup}
                            value={BillingListStore.billingSearchCondition.userName}
                            onEmptyBtn={() => BillingListStore.setBillingSearchCondition({saleUserSeq: null, supportUserSeq: null, userName: ''})}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'계약번호'}
                            labelId={'contractNo'}
                            value={BillingListStore.billingSearchCondition.contractNo}
                            onChange={v => BillingListStore.setBillingSearchCondition({contractNo: v.target.value})}
                            enterKeyHint={getContractList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'관리번호'}
                            value={BillingListStore.billingSearchCondition.managementNo}
                            onChange={v => BillingListStore.billingSearchCondition.managementNo = v.target.value}
                            enterKeyHint={getContractList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'시리얼번호'}
                            labelId={'serialNo'}
                            value={BillingListStore.billingSearchCondition.serialNo}
                            onChange={v => BillingListStore.billingSearchCondition.serialNo = v.target.value}
                            enterKeyHint={getContractList}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'현장명'}
                            value={BillingListStore.billingSearchCondition.siteName}
                            onChange={(v)=> BillingListStore.billingSearchCondition.siteName = v.target.value}
                            enterKeyHint={getContractList}
                        />
                    </CCol>
                    {selView === 'contract' && (
                        <>
                            <CCol lg={2}>
                                <CInputGroup
                                    label={'계산서번호'}
                                    labelId={'billNo'}
                                    value={BillingListStore.billingSearchCondition.billNo}
                                    onChange={v => BillingListStore.setBillingSearchCondition({billNo: v.target.value})}
                                    enterKeyHint={getContractList}
                                />
                            </CCol>
                            <CCol lg={2}>
                                <CSelectGroup label={'계산서발행'} labelId={'isBillIssue'}
                                              options={[
                                                    { name: '전체', value: '' },
                                                    { name: '발행', value: '1' },
                                                    { name: '미발행', value: '2' }
                                              ]}
                                              value={BillingListStore.billingSearchCondition.isBillIssue || ''}
                                              onChange={v => BillingListStore.setBillingSearchCondition({isBillIssue: v.target.value})}
                                />
                            </CCol>
                            <CCol lg={1}>
                                <Form.Check
                                    type="switch"   /*type="checkbox"*/
                                    id="isReceiveComplete"
                                    label="수납완료"
                                    style={{marginTop: 3}}
                                    checked={BillingListStore.billingSearchCondition.isReceiveComplete === 1}
                                    value={BillingListStore.billingSearchCondition.isReceiveComplete === 0 ? false : true}
                                    onChange={v=> BillingListStore.setBillingSearchCondition({isReceiveComplete: v.target.checked ? 1 : 0})}
                                />
                            </CCol>
                        </>
                    )}
                    {selView === 'asset' && (
                        <CCol lg={2}>
                            <Form.Check
                                type="switch"   /*type="checkbox"*/
                                id="exceptDel"
                                label="삭제건 제외"
                                style={{marginTop: 3}}
                                checked={BillingListStore.billingSearchCondition.exceptDel === 1}
                                value={BillingListStore.billingSearchCondition.exceptDel === 1 ? true : false}
                                onChange={v=> BillingListStore.setBillingSearchCondition({exceptDel: v.target.checked ? 1 : 0})}
                            />
                        </CCol>
                    )}
                    {/* <CCol lg={2}>
                            <CInputGroup
                                label={'청구번호'}
                                labelId={'billingChargeNo'}
                                value={BillingListStore.billingSearchCondition.billingChargeNo}
                                onChange={v => BillingListStore.setBillingSearchCondition({billingChargeNo: v.target.value})}
                            />
                        </CCol> */}
                </Row>
                <Row>
                    <Col >
                        <div className="btn-group btn-group-toggle pt-3 pb-3" style={{borderTopWidth: 0, borderBottomWidth: 1, borderColor: '#f2f2f2'}}>
                            <label style={{marginRight: 15}}>
                            <input type="radio" value="contract" checked={selView === 'contract'} onChange={handleRadioChange} style={{verticalAlign: 'middle'}}/> 계약별 청구 조회
                            </label>
                            <label style={{marginRight: 15}}>
                            <input type="radio" value="asset" checked={selView === 'asset'} onChange={handleRadioChange} style={{verticalAlign: 'middle'}}/> 자산별 청구 스케줄
                            </label>
                        </div>
                    </Col>
                    <Col className='d-flex flex-row-reverse pt-3'>
                        {/* <CIconBtn style={{width: 90}}
                                  title={'청구통합'}
                                  icon={'fi-br-layers'}
                                  onClick={()=> AppStore.toggleModal()}
                        /> */}
                        <CSearchBtn onClick={getContractList} />
                    </Col>
                </Row>
            </CContainer>
            <CustomerSearch callbackFn={getCustomerInfo}/>
            <CustomerBillingAddressSearch customerNo={BillingListStore.billingSearchCondition.customerNo} callbackFn={getBillingAddressInfo} />
            <UserSearch callbackFn={getUserInfo} />
        </>
    )

}

export default observer(BillingListSearch);
