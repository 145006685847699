import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";

import { AppStore } from "../../../store/AppStore";
import Modal from "../../../components/Modal";
import { CAgGridContainer, CCol, CContainer, CSelectGroup, CSaveBtn } from "../../../components/CustomContainer";

const DelayPopup = (props) => {
    const [searchList, setSearchList] = useState([]);
    const [rentDay, setRentDay] = useState('');

    const onSave = async () => {
    }

    /* 초기화 */
    const refresh = () => {
        setSearchList([]);
    }

    return (
        <Modal 
            title={'연장'}
            show={AppStore.isOpenDelayPopup}
            onHide={() => AppStore.toggleDelayPopup()}
            toggleValue={AppStore.isOpenDelayPopup}
            className={'search-modal-6'}
            onExit={refresh}
        >
            <CContainer>
                <Row>
                    <CCol lg={3}>
                        {/* TODO: 추후 라디오 버튼으로 변경 */}
                        <CSelectGroup label={'렌탈월/일'} labelId={'rentDay'}
                                      options={[
                                            {name: '렌탈월', value: '1'},
                                            {name: '렌탈일', value: '2'}
                                      ]}
                                      value={rentDay}
                                      onChange={v => setRentDay(v.target.value)}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSaveBtn onClick={onSave} />
                    </Col>
                </Row>
            </CContainer>
            <CAgGridContainer>
                <div style={{height: 310}}>
                    <AgGridReact
                        rowHeight={30}
                        headerHeight={30}
                        rowData={props.assetList}
                        columnDefs={[
                            {field: "assetNo", headerName: "자산번호", minWidth: 200},
                            {field: "contractNo", headerName: "계약번호", minWidth: 200},
                            {field: "monthRentPrice", headerName: "월렌탈료", minWidth: 200, editable: true},
                            {field: "dayRentPrice", headerName: "일렌탈료", flex:1, editable: true},
                        ]}
                        enableCellTextSelection={true}
                        animateRows={true}
                        isCheckBox={true}
                        useUpdated={true}
                    />
                </div>
            </CAgGridContainer>
        </Modal>
    )
}

export default observer(DelayPopup);
