import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import axios from 'axios';

import { CCol, CContainer, CInputGroup, CSelectGroup, CIconBtn, Subject, CalendarGroup } from "../../../components/CustomContainer";
import {ReleaseAskDetailStore} from "../../../store/release/ReleaseAskDetailStore";
import Address from "../../../components/Address";
import AgGridContainer from "../../../components/AgGridContainer";
import {encrypt, decrypt, autoHyphen, localAutoHyphen} from "../../../common/utils";
import {getLocalStorage} from '../../../utils';
import {USER_INFO} from '../../../common/constants';
import {dateFormat, today} from '../../../utils/dateUtils';
import {callConfirm} from '../../../utils';
import {AppStore} from "../../../store/AppStore";
import { showToast } from "../../../common/utils";
import { getCommcode } from "../../../utils/commcode";
import Modal from "../../../components/Modal";
import { AssetStore } from "../../../store/asset/AssetStore";

const ReleaseAskDetail =({customerNo, setCustomerNo, assetStatus, contractNo, setReleaseAskSeq, callBackFn })=> {
    const gridRef = useRef();
    const etcReleaseGridRef = useRef();

    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [returnPostcode, setReturnPostcode] = useState('');
    const [returnAddress, setReturnAddress] = useState('');
    const [returnAddressDetail, setReturnAddressDetail] = useState('');

    const userInfo = getLocalStorage(USER_INFO);

    /* 기본값 세팅 */
    useEffect(()=> {
        if(customerNo) {
            ReleaseAskDetailStore.releaseAskDetail.releaseAskStatus = '2';
            ReleaseAskDetailStore.releaseAskDetail.releaseAskType = '4';
            ReleaseAskDetailStore.releaseAskDetail.releaseAskDate = today();
            ReleaseAskDetailStore.releaseAskDetail.creator = userInfo.userSeq;
            ReleaseAskDetailStore.releaseAskDetail.creatorName = userInfo.name;
            ReleaseAskDetailStore.releaseAskDetail.releaseAskRemark = "임차 자산 반납 출고 의뢰";
            customerSearch();
            if(contractNo) shipAndReturnSearch();

            assetSearch();
        }
    }, [customerNo]);

    const customerSearch = async () => {
        const result = await axios.get(`/customer/${customerNo}`);
        ReleaseAskDetailStore.releaseAskDetail.customerName = result.customerName;
        // ReleaseAskDetailStore.releaseAskDetail.telephone = result.telephone;
        // ReleaseAskDetailStore.releaseAskDetail.phoneNumber = result.phoneNumber;
        // ReleaseAskDetailStore.releaseAskDetail.receiveUserDept = result.receiveUserDept;
        // ReleaseAskDetailStore.releaseAskDetail.receiveUserPosition = result.receiveUserPosition;
        // ReleaseAskDetailStore.releaseAskDetail.receiveUserName = result.receiveUserName;
        // ReleaseAskDetailStore.releaseAskDetail.shipRemark = result.shipRemark;
        // setPostcode(result.companyPostcode);
        // setAddress(result.companyAddress);
        // setAddressDetail(result.companyAddressDetail);
    }

    /* 구매처 조회 & 회수처 조회 */
    const shipAndReturnSearch = async () => {
        // 계약번호의 마지막 출고 배송정보를 회수/출고 배송 정보에 채워주기
        const result = await axios.get('/ship/return', { params: {contractNo: contractNo} });

        ReleaseAskDetailStore.releaseAskDetail.telephone = result.returnTelephone;
        ReleaseAskDetailStore.releaseAskDetail.phoneNumber = result.returnPhoneNumber;
        ReleaseAskDetailStore.releaseAskDetail.receiveUserDept = result.returnReceiveUserDept;
        ReleaseAskDetailStore.releaseAskDetail.receiveUserPosition = result.returnReceiveUserPosition;
        ReleaseAskDetailStore.releaseAskDetail.receiveUserName = result.returnReceiveUserName;
        ReleaseAskDetailStore.releaseAskDetail.shipRemark = "임차 자산 반납 출고 배송";
        setPostcode(result.returnPostcode);
        setAddress(result.returnAddress);
        setAddressDetail(result.returnAddressDetail);

        ReleaseAskDetailStore.releaseAskDetail.returnTelephone = result.returnTelephone;
        ReleaseAskDetailStore.releaseAskDetail.returnPhoneNumber = result.returnPhoneNumber;
        ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserDept = result.returnReceiveUserDept;
        ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserPosition = result.returnReceiveUserPosition;
        ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserName = result.returnReceiveUserName;
        ReleaseAskDetailStore.releaseAskDetail.returnAskRemark = "임차 자산 반납 회수 의뢰";
        ReleaseAskDetailStore.releaseAskDetail.returnShipRemark = "임차 자산 반납 회수 배송";

        setReturnPostcode(result.returnPostcode)
        setReturnAddress(result.returnAddress)
        setReturnAddressDetail(result.returnAddressDetail);
    }

    /* 출고 제품 목록 조회 */
    const assetSearch = async () => {
        const selectedList = AssetStore.assetList.filter(v => v.isSelected).map(v => v.assetNo);
        const result = await axios.get('/asset/numbers/list', {params: {assetNoList: selectedList.join(','), contractNo: contractNo}});
        ReleaseAskDetailStore.releaseAskItemList = result;
    }

    /* 출고 의뢰 */
    const saveReleaseAskStatus = async () => {
        // 필수값 확인
        let requiredParams = [];
        if(assetStatus === '200') {
            requiredParams = ['phoneNumber', 'receiveUserName', 'shipHopeDate', 'shipType'];
            if (!requiredParams.every(param => ReleaseAskDetailStore.releaseAskDetail[param]) || !postcode || !address || !addressDetail) {
                showToast('필수값을 모두 입력해야 합니다.');
                return;
            }
        }else {
            requiredParams = ['returnPhoneNumber', 'returnReceiveUserName', 'returnShipHopeDate', 'returnShipType'];
            if (!requiredParams.every(param => ReleaseAskDetailStore.releaseAskDetail[param]) || !returnPostcode || !returnAddress || !returnAddressDetail) {
                showToast('필수값을 모두 입력해야 합니다.');
                return;
            }

        }

        // 저장 확인
        if(assetStatus === '200') {
            if(!await callConfirm('출고의뢰 하시겠습니까?')) return;
        }else if(assetStatus === '302' || assetStatus === '202') {
            if(!await callConfirm('출고완료 또는 임대중 자산의 경우 가상입고 후 임대불가 자산으로 변경됩니다. 반납회수 하시겠습니까?')) return;
        }

        ReleaseAskDetailStore.releaseAskDetail.contractNo = contractNo;
        ReleaseAskDetailStore.releaseAskDetail.assetStatus = assetStatus;
        ReleaseAskDetailStore.releaseAskDetail.releaseAskItemList = ReleaseAskDetailStore.releaseAskItemList;
        ReleaseAskDetailStore.setReleaseAskDetail({postCode: postcode, address: address, addressDetail: addressDetail, returnPostcode: returnPostcode, returnAddress: returnAddress, returnAddressDetail: returnAddressDetail});    // 주소 세팅

        // 출고의뢰 생성 || 회수의뢰 생성
        await axios.post('/releaseAsk/return/lease', ReleaseAskDetailStore.releaseAskDetail);

        if(assetStatus === '200'){
            AppStore.toggleReleaseAskPopup();
            showToast('완료되었습니다.');
            callBackFn && callBackFn();
        }else{
            AppStore.toggleReleaseAskPopup();
            showToast('완료되었습니다.');
            callBackFn && callBackFn();
        }
    }

    /* 초기화 */
    const refresh = () => {
        ReleaseAskDetailStore.init();
        if(typeof setReleaseAskSeq !== 'undefined') setReleaseAskSeq('');
        setCustomerNo('');
        setPostcode('');
        setAddress('');
        setAddressDetail('');
        setReturnPostcode('');
        setReturnAddress('');
        setReturnAddressDetail('');
    }

    return(
        <Modal
            title={'임차자산 반납출고의뢰'}
            show={AppStore.isOpenReleaseAskPopup}
            onHide={() => AppStore.toggleReleaseAskPopup()}
            toggleValue={AppStore.isOpenReleaseAskPopup}
            onExit={refresh}
        >
            {/* 검색조건 */}
            <CContainer search>
                <Subject>의뢰정보</Subject>
                <Row>
                    <CCol lg={3}>
                        <CSelectGroup label={'출고의뢰구분'} labelId={'releaseAskType'}
                                      placeholder={'출고의뢰구분'}
                                      options={getCommcode('39')}
                                      value={ReleaseAskDetailStore.releaseAskDetail.releaseAskType || ''}
                                      disabled={true}
                                      labelClassName="input-required"
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CalendarGroup asSingle={true}  label={'출고의뢰일'}
                                       value={{
                                            startDate: ReleaseAskDetailStore.releaseAskDetail.releaseAskDate,
                                            endDate: ReleaseAskDetailStore.releaseAskDetail.releaseAskDate
                                        }}
                                       disabled={true}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'의뢰자명'}
                                     labelId={'creatorName'}
                                     disabled={true}
                                     value={ReleaseAskDetailStore.releaseAskDetail.creatorName || ''}
                        />
                    </CCol>
                </Row>

                <hr/>
                <Row>
                    {(assetStatus === '302' || assetStatus === '202') && (
                        <CCol lg={6}>
                                <div className="mb-3">
                                    <label className="form-label">회수의뢰비고</label>
                                    <textarea className="form-control" rows="3"
                                                value={ReleaseAskDetailStore.releaseAskDetail.returnAskRemark || ''}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnAskRemark: v.target.value})}
                                    />
                                </div>
                            </CCol>
                    )}

                    <CCol lg={assetStatus === '200' ? 12 : 6}>
                        <div className="mb-3">
                            <label className="form-label">출고의뢰비고</label>
                            <textarea className="form-control" rows="3"
                                        value={ReleaseAskDetailStore.releaseAskDetail.releaseAskRemark || ''}
                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({releaseAskRemark: v.target.value})}
                            />
                        </div>
                    </CCol>
                    </Row>

                    <Row>
                    {(assetStatus === '302' || assetStatus === '202') && (
                        <>
                            <Row>
                                <Subject>회수 정보</Subject>
                                <CCol lg={3}>
                                    <CInputGroup label={'고객명'} labelId={'customerName'}
                                                    disabled={true}
                                                    value={ReleaseAskDetailStore.releaseAskDetail.customerName}
                                    />
                                </CCol>
                                <CCol lg={4}>
                                    <InputGroup size={'sm'}>
                                        <InputGroup.Text>사무실</InputGroup.Text>
                                        <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'returnTelephone'}
                                                    placeholder={'전화번호'}
                                                    value={localAutoHyphen(ReleaseAskDetailStore.releaseAskDetail.returnTelephone) || ''}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnTelephone: v.target.value})}
                                        />
                                        <InputGroup.Text className='input-required'>휴대폰</InputGroup.Text>
                                        <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'returnPhoneNumber'}
                                                    value={autoHyphen(decrypt(ReleaseAskDetailStore.releaseAskDetail.returnPhoneNumber)) || ''}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnPhoneNumber: encrypt(v.target.value)})}
                                        />
                                    </InputGroup>
                                </CCol>
                                <CCol lg={5}>
                                    <InputGroup size={'sm'}>
                                        <InputGroup.Text>부서명</InputGroup.Text>
                                        <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'returnReceiveUserDept'}
                                                    value={ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserDept || ''}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnReceiveUserDept: v.target.value})}
                                        />
                                        <InputGroup.Text>직급</InputGroup.Text>
                                        <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'returnReceiveUserPosition'}
                                                    value={ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserPosition || ''}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnReceiveUserPosition: v.target.value})}
                                        />
                                        <InputGroup.Text className="input-required">받는분</InputGroup.Text>
                                        <Form.Control style={{flex: 2, textAlign: 'left'}} aria-describedby={'returnReceiveUserName'}
                                                    value={ReleaseAskDetailStore.releaseAskDetail.returnReceiveUserName || ''}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnReceiveUserName: v.target.value})}
                                        />
                                    </InputGroup>
                                </CCol>
                            </Row>
                            <Row>
                                <CCol lg={7} style={{marginTop: 12}}>
                                    <Row>
                                        <CCol lg={5}>
                                            <CalendarGroup asSingle={true}  label={'회수희망일'}
                                                        value={{
                                                                startDate: ReleaseAskDetailStore.releaseAskDetail.returnShipHopeDate,
                                                                endDate: ReleaseAskDetailStore.releaseAskDetail.returnShipHopeDate
                                                            }}
                                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnShipHopeDate: dateFormat(v.startDate)})}
                                                        minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                                        labelClassName="input-required"
                                            />
                                        </CCol>
                                        <CCol lg={3}>
                                            <CSelectGroup label={'시간'} labelId={'returnShipHopeTime'}
                                                            options={[{name: '선택', value: ''}].concat(getCommcode('29'))}
                                                        value={ReleaseAskDetailStore.releaseAskDetail.returnShipHopeTime || '1'}
                                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnShipHopeTime: v.target.value})}
                                                        labelClassName="input-required"
                                            />
                                        </CCol>
                                        <CCol lg={4}>
                                            <CSelectGroup label={'회수방법'} labelId={'returnShipType'}
                                                        placeholder={'회수방법'}
                                                        options={[{name: '선택', value: ''}].concat(getCommcode('4'))}
                                                        value={ReleaseAskDetailStore.releaseAskDetail.returnShipType || ''}
                                                        onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnShipType: v.target.value})}
                                                        labelClassName="input-required"
                                            />
                                        </CCol>
                                    </Row>
                                    <Row>
                                        <CCol lg={12} md={12}>
                                            <Address label='주소'
                                                    setPostcode={setReturnPostcode}
                                                    setAddress={setReturnAddress}
                                                    setAddressDetail={setReturnAddressDetail}
                                                    postcode={returnPostcode}
                                                    address={returnAddress}
                                                    addressDetail={returnAddressDetail}
                                                    labelClassName='input-required'
                                            />
                                        </CCol>
                                    </Row>
                                </CCol>
                                <CCol lg={5}>
                                    <div className="mb-3">
                                        <label className="form-label">회수 배송 비고</label>
                                        <textarea className="form-control" rows="2"
                                                value={ReleaseAskDetailStore.releaseAskDetail.returnShipRemark || ''}
                                                placeholder={'배송 상세 시간 / 배송 요청 사항'}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({returnShipRemark: v.target.value})}
                                        />
                                    </div>
                                </CCol>
                            </Row>
                        </>
                    )}
                </Row>
                {/* {assetStatus === '200' && ( */}
                    <>
                        <Row>
                            <Subject>배송정보</Subject>
                            <CCol lg={3}>
                                <CInputGroup label={'고객명'} labelId={'customerName'}
                                                disabled={true}
                                                value={ReleaseAskDetailStore.releaseAskDetail.customerName}
                                />
                            </CCol>
                            <CCol lg={4}>
                                <InputGroup size={'sm'}>
                                    <InputGroup.Text>사무실</InputGroup.Text>
                                    <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'telephone'}
                                                placeholder={'전화번호'}
                                                value={localAutoHyphen(ReleaseAskDetailStore.releaseAskDetail.telephone) || ''}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({telephone: v.target.value})}
                                    />
                                    <InputGroup.Text className='input-required'>휴대폰</InputGroup.Text>
                                    <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'phoneNumber'}
                                                value={autoHyphen(decrypt(ReleaseAskDetailStore.releaseAskDetail.phoneNumber)) || ''}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({phoneNumber: encrypt(v.target.value)})}
                                    />
                                </InputGroup>
                            </CCol>
                            <CCol lg={5}>
                                <InputGroup size={'sm'}>
                                    <InputGroup.Text>부서명</InputGroup.Text>
                                    <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'receiveUserDept'}
                                                value={ReleaseAskDetailStore.releaseAskDetail.receiveUserDept || ''}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({receiveUserDept: v.target.value})}
                                    />
                                    <InputGroup.Text>직급</InputGroup.Text>
                                    <Form.Control style={{flex: 1, textAlign: 'left'}} aria-describedby={'receiveUserPosition'}
                                                value={ReleaseAskDetailStore.releaseAskDetail.receiveUserPosition || ''}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({receiveUserPosition: v.target.value})}
                                    />
                                    <InputGroup.Text className="input-required">받는분</InputGroup.Text>
                                    <Form.Control style={{flex: 2, textAlign: 'left'}} aria-describedby={'receiveUserName'}
                                                value={ReleaseAskDetailStore.releaseAskDetail.receiveUserName || ''}
                                                onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({receiveUserName: v.target.value})}
                                    />
                                </InputGroup>
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={7} style={{marginTop: 12}}>
                                <Row>
                                    <CCol lg={5}>
                                        <CalendarGroup asSingle={true}  label={'배송희망일'}
                                                    value={{
                                                            startDate: ReleaseAskDetailStore.releaseAskDetail.shipHopeDate,
                                                            endDate: ReleaseAskDetailStore.releaseAskDetail.shipHopeDate
                                                        }}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipHopeDate: dateFormat(v.startDate)})}
                                                    minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                                    labelClassName="input-required"
                                        />
                                    </CCol>
                                    <CCol lg={3}>
                                        <CSelectGroup label={'시간'} labelId={'shipHopeTime'}
                                                        options={[{name: '선택', value: ''}].concat(getCommcode('29'))}
                                                    value={ReleaseAskDetailStore.releaseAskDetail.shipHopeTime || '1'}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipHopeTime: v.target.value})}
                                                    labelClassName="input-required"
                                        />
                                    </CCol>
                                    <CCol lg={4}>
                                        <CSelectGroup label={'배송방법'} labelId={'shipType'}
                                                    placeholder={'배송방법'}
                                                    options={[{name: '선택', value: ''}].concat(getCommcode('4'))}
                                                    value={ReleaseAskDetailStore.releaseAskDetail.shipType || ''}
                                                    onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipType: v.target.value})}
                                                    labelClassName="input-required"
                                        />
                                    </CCol>
                                </Row>
                                <Row>
                                    <CCol lg={12} md={12}>
                                        <Address label='주소'
                                                setPostcode={setPostcode}
                                                setAddress={setAddress}
                                                setAddressDetail={setAddressDetail}
                                                postcode={postcode}
                                                address={address}
                                                addressDetail={addressDetail}
                                                labelClassName='input-required'
                                        />
                                    </CCol>
                                </Row>
                            </CCol>
                            <CCol lg={5}>
                                <div className="mb-3">
                                    <label className="form-label">배송 비고</label>
                                    <textarea className="form-control" rows="2"
                                            value={ReleaseAskDetailStore.releaseAskDetail.shipRemark || ''}
                                            placeholder={'배송 상세 시간 / 배송 요청 사항'}
                                            onChange={v => ReleaseAskDetailStore.setReleaseAskDetail({shipRemark: v.target.value})}
                                    />
                                </div>
                            </CCol>
                        </Row>
                    </>
                {/* )} */}
                
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CIconBtn style={{width: assetStatus === '302' || assetStatus === '202' ? 180 : 110}}
                                title={(assetStatus === '302' || assetStatus === '202') ? '반납회수 및 출고의뢰' : '출고의뢰'}
                                icon={'fi-rr-box-check'}
                                onClick={() => saveReleaseAskStatus()}
                        />
                    </Col>
                </Row>

            </CContainer>

            <CContainer>
                <Subject>출고 제품 리스트</Subject>
                <AgGridContainer
                    ref={etcReleaseGridRef}
                    gridRef={gridRef}
                    height={20}
                    rowData={ReleaseAskDetailStore.releaseAskItemList}
                    columnDefs={
                        [
                            {field: "releaseAskSeq", headerName: "출고순번", hide: true},
                            {field: "productCode", headerName: "제품코드", width: 120},
                            {field: "productSeq", headerName: "제품시퀀스", width: 120, hide: true},
                            {field: "productName", headerName: "제품명", width: 150},
                            {field: "model", headerName: "모델명", width: 150},
                            {
                                field: "qty",
                                headerName: "수량",
                                width: 70,
                            },
                            {
                                field: "assetNo",
                                headerName: "자산번호",
                                width: 120,
                                editable: false,
                            },
                            {field: "serialNo", headerName: "시리얼번호", width: 150},
                            {field: "spec", headerName: "스펙", width: 200},
                            {field: "remark", headerName: "비고", headerClass: 'grid-column-editable', width: 150, editable: true},
                        ]
                    }
                />
            </CContainer>
        </Modal>
    );
}

export default observer(ReleaseAskDetail);
