import React from 'react';
import MoonLoader from "react-spinners/MoonLoader";
import { AppStore } from '../store/AppStore';

const Loading = () => {
  return (
    <div 
      className="spinner" 
      style={{width: '100%', height: '100%', position: 'fixed',zIndex: '10200', left: '0',top: '0',right: '0',bottom: '0',backgroundColor: 'rgba(0,0,0,0.2)',display: 'block'}}
    >
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100%'}}>
        <MoonLoader
            color="#116efd"
            loading={AppStore.isRunningSpinner}
            size={30}
        />
      </div>
    </div>
  );
};

export default Loading;