import { observable } from "mobx";

export const ContractDocumentStore = observable({
    data: {
        contractNo: '',         // 계약번호
        contractStatus: 0,     // 계약상태 (0:작성중, 1:완료(작성,출고의뢰))
        contractSeq: 1,         // 계약회차
        contractType: '1',       // 계약구분 (반납형, 양도형, 유통, 매각)
        customerNo: '',         // 고객번호
        customerName: '',       // 고객명
        customerManagerSeq: '', // 고객담당자 시퀀스
        managerName: '',        // 고객담당자명
        siteSeq: '',            // 현장번호
        siteName: '',           // 현장명
        saleUserSeq: '',        // 영업담당자 seq
        saleUserName: '',       // 영업담당자명
        telephone: '',          // 영업담당자 전화번호
        phoneNumber: '',        // 영업담당자 휴대폰번호
        supportUserSeq: '',     // 지원담당자 seq
        supportUserName: '',    // 지원담당자명
        rentMonth: 0,          // 계약기간(월)
        rentDays: 0,           // 계약기간(일)
        contractDate: '',       // 계약일자
        contractStartDate: '',  // 계약시작일
        contractEndDate: '',    // 계약종료일
        contractDocType: '1',    // 계약서 구분 (수기, 전자)
        contractStartType: '1',  // 계약발생경로
        installPlace: '',       // 설치장소
        isPenaltyFee: 0,        // 위약금청구여부
        penaltyPercent: 0,      // 위약금율
        isExtendRate: 0,        // 연장할인여부
        extendFirstRate: 0,     // 1차연장할인율
        extendSecondRate: 0,    // 2차연장할인율
        isDeliveryPrice: 0,     // 운반비청구여부
        deliveryPrice: 0,       // 운반비
        chargeDelivery: '',     // 운반비 부담
        deliveryKind: '',       // 운반구분
        isRepairCost: 0,        // 수리비청구여부
        isInstallPrice: 0,      // 설치비청구여부
        installPrice: 0,        // 설치비
        specialRemark: '',      // 특이사항
        billingType: '1',       // 청구조건
        billingDay: 0,       // 청구일자
        dueDateType: '2',       // 납기조건
        dueDateDay: 0,        // 납기일자
        paymentType: '1',       // 결제방식 (현금,카드,어음등)
        deposit: 0,             // 보증금
        endDate: 1,           // 마감일자
        isReverseBill: 0,       // 계산서역발행
        isFreeAssignment: 1,      // 무상양도여부
        assignmentAmount: 0,      // 양도금액
        isVirtualRelease: 0,    // 가상출고여부
        contractDocUrl: '',     // 계약서URL
        contractDocFileName: '',     //  업로드 파일이름
        billingAddressSeq: '',  // 청구지 seq
        billingAddressName: '', // 청구지 명
        returnExpectDate: '', //회수예정일
        isFix: 0,
        releaseAskSeq: '',
        releaseAskStatus: '',   //출고의뢰상태
        isResident: 0,  //상주여부
        sellType: '',   //매각구분
        sellDate: null, //매각일자
        updater: '',
        estimateNo: '', //견적번호
        businessNumber: '',    //사업자번호
        customerAddress: '',    //고객주소
        companyName: '', // 회사명
        receiverEmail: '', // 고객 이메일
        firstBillingType: '2',   // 첫 회차 청구 기준
        truncationUnit: 10,     // 일할 계산 시 절사 단위
        dayOfCalculation: 30,     // 일할 계산 일자
        owner: '',
        companyCode: '',
        companyAddress: '',
        companyBusinessNumber: '',
        companyOwner: '',
        customerSignatureUrl: '',
        customerSignatureBase64Url: '',
        companySignatureUrl: '',
        companySignatureBase64Url: '',
        contractManager: '',
        contractManagerBelong: '',
        contractManagerPhone: '',
        documentCreatedDate: '',
        companyReturnAddress: '',
        options: '',
        optionAmount: 0,
        logoImageUrl: '',
        logoImageBase64Url: '',
        mailContractManager: '',
    },
    contractProductList: [],
    productOptionList: []
})
