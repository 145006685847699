import { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import { CCol, CContainer, CInputGroup, CSelectGroup, CalendarGroup, CSaveBtn, CIconBtn, Subject } from "../../../components/CustomContainer";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { NonpaymentStore } from "../../../store/billing/NonpaymentStore";
import AgGridContainer from "../../../components/AgGridContainer";
import { findCommKrnm, getCommcode } from '../../../utils/commcode';
import axios from 'axios';
import { dateFormat, today } from '../../../utils/dateUtils';
import { showToast } from '../../../common/utils';
import NonPaymentList from '../tabs/NonPaymentList';
import ConsultationList from '../tabs/ConsultationList';
import SmsHistoryList from '../tabs/SmsHistoryList';
import CertificationContentHistoryList from '../tabs/CertificationContentHistoryList';
import { AppStore } from '../../../store/AppStore';
import SendCertificationContentPopup from './SendCertificationContentPopup';
import SendNonPaymentSmsPopup from './SendNonPaymentSmsPopup';
import Modal from '../../../components/Modal';
import { callConfirm } from '../../../utils';
import PreviewCertificationContentPopup from './PreviewCertificationContentPopup';

const NonPaymentConsultationPopup = ({getNonpaymentList, getCustomerNonpaymentList}) => {
    /* 상담내역 조회 */
    const getConsultationList = async () => {
        const result = await axios.get('/nonpayment/consultation', {params: {customerNo: NonpaymentStore.data.customerNo}});
        NonpaymentStore.setConsultationList(result);
    }

    /* 상담 내용 저장 */
    const saveConsultation = async () => {
        NonpaymentStore.consultation.contractNo = NonpaymentStore.data.contractNo;
        NonpaymentStore.consultation.customerNo = NonpaymentStore.data.customerNo;

        if (!await callConfirm('저장하시겠습니까?')) {
            return;
        }

        // 필수값 확인
        const requiredParams = ['contractNo', 'customerNo', 'bondType', 'bondActivity', 'consultationDate', 'paymentPromiseDate', 'consultationContent'];
        if(!requiredParams.every(param => NonpaymentStore.consultation[param])) {
            showToast('필수값을 모두 입력해주세요.');
            return;
        }

        await axios.post('/nonpayment/consultation', NonpaymentStore.consultation);
        showToast('저장이 완료되었습니다.');
        getConsultationList();
        getNonpaymentList();
        NonpaymentStore.consultationInit();
    }

    const openCertificationContentPreview = () => {
        AppStore.togglePreviewCertificationContentPopup();
    }

    const sendSms = () => {
        AppStore.toggleSendNonPaymentSmsPopup();
    }

    /* 미납 독촉 문자 발송 내역 */
    const getSmsHistoryList = async () => {
        const result = await axios.get("/nonpayment/sms/history", {params: {customerNo: NonpaymentStore.data.customerNo}});
        NonpaymentStore.setSmsHistoryList(result);
    }

    /* 내용 증명 메일 발송 내역 */
    const getCertificateonContentHistoryList = async() => {
        const result = await axios.get("/nonpayment/certificationContent/history", {params: {customerNo: NonpaymentStore.data.customerNo}});
        NonpaymentStore.setEmailHistoryList(result);
    }

    const closeModal = () => {
        // NonpaymentStore.consultationInit();
    };

    return (
        <Modal toggleValue={AppStore.isOpenNonpaymentDetailPopup}
                onHide={()=> AppStore.toggleOpenNonpaymentDetailPopup()}
                title={'미납상담'}
                onExit={closeModal}>
            <CContainer search>
                <Row>
                    {/* <CCol lg={3}>
                        <CInputGroup
                            label={'계약번호'}
                            labelId={'contractNo'}
                            value={NonpaymentStore.data.contractNo}
                            disabled={true}
                            />
                    </CCol> */}
                    <CCol lg={3}>
                        <CInputGroup
                            label={'고객명'}
                            labelId={'customerName'}
                            value={NonpaymentStore.data.customerName}
                            disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'고객번호'}
                            labelId={'customerNo'}
                            value={NonpaymentStore.data.customerNo}
                            disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'미납액'}
                            labelId={'dueNonpaymentAmount'}
                            value={NonpaymentStore.data.totalNonpaymentAmount?.toLocaleString()}
                            disabled={true}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'납기미납일수'}
                            labelId={'maxDueNonpaymentDate'}
                            value={NonpaymentStore.data.maxDueNonpaymentDate}
                            disabled={true}
                        />
                    </CCol>
                </Row>
                <Row>
                    {/* <CCol lg={3}>
                        <CInputGroup
                            label={'청구지명'}
                            labelId={'billingAddressName'}
                            value={NonpaymentStore.data.billingAddressName}
                            disabled={true}
                            />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'청구지순번'}
                            labelId={'billingAddressSeq'}
                            value={NonpaymentStore.data.billingAddressSeq}
                            disabled={true}
                        />
                    </CCol> */}
                    <CCol lg={3}>
                        <CSelectGroup label={'채권형태'} labelId={'bondType'}
                            placeholder={'채권형태'}
                            options={[{name: '선택', value: ''}].concat(getCommcode('37'))}
                            value={NonpaymentStore.consultation.bondType || ''}
                            onChange={v => NonpaymentStore.consultation.bondType = v.target.value}
                            labelClassName={'input-required'}
                        />
                    </CCol>
                </Row>
                <hr />
                <Row>
                    <CCol lg={12}>
                        <div className="mb-3">
                            <label className="form-label">상담내용</label>
                            <Row>
                                <CCol lg={4}>
                                    <CSelectGroup label={'채권활동'} labelId={'bondActivity'}
                                        placeholder={'채권활동'}
                                        options={[{name: '선택', value: ''}].concat(getCommcode('38'))}
                                        value={NonpaymentStore.consultation.bondActivity || ''}
                                        onChange={v => NonpaymentStore.consultation.bondActivity = v.target.value}
                                        labelClassName={'input-required'}
                                    />
                                </CCol>
                                <CCol lg={4}>
                                    <CalendarGroup asSingle={true}  label={'상담일'} 
                                                value={{
                                                        startDate: NonpaymentStore.consultation.consultationDate,
                                                        endDate: NonpaymentStore.consultation.consultationDate
                                                    }}
                                                    onChange={v => NonpaymentStore.consultation.consultationDate = v.startDate}
                                                    labelClassName={'input-required'}
                                    />
                                </CCol>
                                <CCol lg={4}>
                                    <CalendarGroup asSingle={true}  label={'납부약속일'}
                                                value={{
                                                        startDate: NonpaymentStore.consultation.paymentPromiseDate,
                                                        endDate: NonpaymentStore.consultation.paymentPromiseDate
                                                    }}
                                                    onChange={v => NonpaymentStore.consultation.paymentPromiseDate = v.startDate}
                                                    labelClassName={'input-required'}
                                    />
                                </CCol>
                            </Row>
                            <textarea className="form-control" rows="4"
                                        value={NonpaymentStore.consultation.consultationContent}
                                        onChange={v => NonpaymentStore.consultation.consultationContent = v.target.value}
                                        />
                        </div>
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CIconBtn style={{width: 120, backgroundColor: 'red', border: 'none'}}
                                  title={'내용증명'}
                                //   onClick={sendCertificationContent}
                                  onClick={openCertificationContentPreview}
                        />
                        <CIconBtn style={{width: 100}}
                                  title={'문자 발송'}
                                  onClick={sendSms}
                        />
                        <CSaveBtn style={{width: 80}}
                                  title={'저장'}
                                  onClick={saveConsultation}
                        />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <Row>
                    <Tabs defaultActiveKey="nonpaymentList" className='mb-3' >
                        <Tab eventKey="nonpaymentList" title="미납내역">
                            <NonPaymentList getCustomerNonpaymentList={getCustomerNonpaymentList}/>
                        </Tab>
                        <Tab eventKey="consultationList" title="상담내역">
                            <ConsultationList getConsultationList={getConsultationList}/>
                        </Tab>
                        <Tab eventKey="smsHistoryList" title="문자발송내역">
                            <SmsHistoryList getSmsHistoryList={getSmsHistoryList}/>
                        </Tab>
                        <Tab eventKey="certificationContentHistoryList" title="내용증명발송내역">
                            <CertificationContentHistoryList getCertificateonContentHistoryList={getCertificateonContentHistoryList}/>
                        </Tab>
                    </Tabs>
                </Row>
            </CContainer>
            <PreviewCertificationContentPopup getCertificateonContentHistoryList={getCertificateonContentHistoryList}/>
            <SendNonPaymentSmsPopup getSmsHistoryList={getSmsHistoryList}/>
            <SendCertificationContentPopup getCertificateonContentHistoryList={getCertificateonContentHistoryList}/>
        </Modal>
    )

}

export default observer(NonPaymentConsultationPopup);