import { observer } from "mobx-react-lite"
import { InsurancePolicyStore } from "../../store/product/InsurancePolicyStore";
import { CCol, CContainer, CInputGroup, CSaveBtn, CalendarGroup } from "../../components/CustomContainer";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { showToast } from "../../common/utils";
import axios from "axios";
import { MULTIPART_HEADER } from "../../common/constants";
import { callConfirm } from "../../utils";

const InsurancePolicyListSearch = ({getInsurancePolicyList}) => {
    const fileRef = useRef();
    const [productGroupList, setProductGroupList] = useState([]);

    useEffect(() => {
        getProductGroupList();
    }, []);

    const getProductGroupList = async () => {
        const result = await axios.get('/product/group/all', { params: { isUsed: 1 } });
        if(result.length > 0){
            setProductGroupList(result);
        }
    }

    /* 날짜 선택 */
    const onChangeSearchValue = async (v, label) => {
        const { startDate, endDate } = v;
        const storeStartDate = InsurancePolicyStore.data.startDate;
        const storeEndDate = InsurancePolicyStore.data.endDate;
        const selectedDate = startDate;

        InsurancePolicyStore.data[label] = selectedDate;
    };

    /* 파일 업로드 하고 url 가져오기 */
    const fileUploadForS3 = async (fileData, type) => {
        const typeCheck = fileData.target.files[0].type  === 'application/pdf';
        InsurancePolicyStore.data.fileName = fileData.target.files[0].name;

        let result ='';
        const file = fileData.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('typeCheck', typeCheck);
        formData.append('column', type);

        if(typeCheck) {
            result = await axios.post('/file/upload/s3/pdf', formData, { headers: MULTIPART_HEADER });
        }else {
            result = await axios.post('/file/upload/s3', formData, { headers: MULTIPART_HEADER });
        }

        InsurancePolicyStore.data.fileName = file.name;
        InsurancePolicyStore.data.fileUrl = result;
    }

    const saveInsurancePolicy = async () => {
        const requiredParams = ['startDate', 'endDate', 'fileName', 'fileUrl', 'remark'];
        if (!requiredParams.every(param => InsurancePolicyStore.data[param])) {
            showToast('필수값을 모두 입력해야 합니다.');
            return;
        }

        if(!await callConfirm('보험증권을 등록하시겠습니까?')) {
            return;
        }

        await axios.post('/product/insurancePolicy', InsurancePolicyStore.data);
        showToast('보험증권이 등록되었습니다.');

        InsurancePolicyStore.init();
        fileRef.current.value = "";
        getInsurancePolicyList();
    }

    const getLgSeq = () => {
        let options = [];
        //productGroupList.filter(v => v.parentSeq === null).forEach(v => console.log(v));
        productGroupList.filter(v => v.parentSeq === null).forEach((lg,idx) => {
            options.push(<option key={idx} value={lg.productGroupSeq}>{lg.productGroupName}</option>);
        });
        return options;
    }

    return(
        <CContainer search>
            <Row>
                <CCol lg={2}>
                     <InputGroup size={'sm'}>
                        <Form.Select value={InsurancePolicyStore.data.lgSeq} onChange={v => InsurancePolicyStore.data.lgSeq = v.target.value}>
                            <option value={0}>-대분류-</option>
                            {getLgSeq()}
                        </Form.Select>
                    </InputGroup>
                </CCol>
            </Row>
            <Row>
                <CCol lg={2}>
                    <CalendarGroup asSingle={true} label={'시작일'}
                                    value={{
                                        startDate: InsurancePolicyStore.data.startDate,
                                        endDate: InsurancePolicyStore.data.startDate
                                    }}
                                    onChange={v => InsurancePolicyStore.data.startDate = v.startDate}
                                    />
                </CCol>
                <CCol lg={2}>
                    <CalendarGroup asSingle={true} label={'종료일'}
                                    value={{
                                        startDate: InsurancePolicyStore.data.endDate,
                                        endDate: InsurancePolicyStore.data.endDate
                                    }}
                                    onChange={v => InsurancePolicyStore.data.endDate = v.startDate}
                                    />
                </CCol>
                <CCol lg={3}>
                    <InputGroup>
                        <label style={{paddingTop: 5, marginRight: 10}}>보험증권 업로드</label>
                        <Form.Control
                            type="file"
                            id="insurancePolicyFile"
                            size="sm"
                            placeholder={'보험증권 업로드'}
                            onChange={(v) => fileUploadForS3(v, 'insurancePolicy')}
                            ref={fileRef}
                            />
                    </InputGroup>
                </CCol>
            </Row>
            <Row>
                <CCol lg={7}>
                    <CInputGroup
                        label={'비고'}
                        labelId={'remark'}
                        placeholder={'비고'}
                        disabled={false}
                        value={InsurancePolicyStore.data.remark}
                        onChange={v => InsurancePolicyStore.data.remark = v.target.value}
                    />
                </CCol>
            </Row>
            <Row>
                <Col className='d-flex flex-row-reverse'>
                    <CSaveBtn onClick={saveInsurancePolicy} />
                </Col>
            </Row>
            {/* <Row>
                <CCol lg={3}>
                    <CalendarGroup asSingle={true} label={'시작일'}
                                    value={{
                                        startDate: InsurancePolicyStore.data.startDate,
                                        endDate: InsurancePolicyStore.data.startDate
                                    }}
                                    onChange={v => onChangeSearchValue(v, 'startDate')}
                    />
                </CCol>
                <CCol lg={3}>
                    <CalendarGroup asSingle={true} label={'종료일'}
                                    value={{
                                        startDate: InsurancePolicyStore.data.endDate,
                                        endDate: InsurancePolicyStore.data.endDate
                                    }}
                                    onChange={v => onChangeSearchValue(v, 'endDate')}
                    />
                </CCol>
            </Row> */}
            {/* <Subject>결제조건</Subject> */}
            {/* <Row>
                <Col className='d-flex flex-row-reverse'>
                    <CSaveBtn onClick={saveInsurancePolicy} />
                </Col>
            </Row> */}
        </CContainer>
    );
}

export default observer(InsurancePolicyListSearch);