import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react";
import { CContainer, CCol, CIconBtn, CInputGroup } from "../../../components/CustomContainer";
import { Row, Col } from "react-bootstrap";
import Modal from "../../../components/Modal";
import { AppStore } from "../../../store/AppStore";
import { EstimateDetailStore } from "../../../store/contract/EstimateDetailStore";


const SendEstimatePopup = ({sendEstimate}) => {
    const store = EstimateDetailStore;

    const refresh = () => {
        store.sendPopupInit();
    }

    return (
        <>
        <Modal title={'견적서 메일 전송'}
            onHide={() => AppStore.toggleSendEstimatePopup()}
            toggleValue={AppStore.isOpenSendEstimatePopup}
            onExit={refresh}
            size={'md'}
        >
            <CContainer>
                <Row className="mt-3">
                    <CCol lg={12}>
                        <CInputGroup label={'제목'}
                                    labelId={'subject'}
                                    placeholder={'제목을 작성해주세요.'}
                                    value={store.subject}
                                    onChange={v => store.subject = v.target.value}
                        />
                    </CCol>
                    <CCol lg={12}>
                        <textarea className="form-control" rows="6"
                            value={store.content}
                            placeholder={'내용을 자유롭게 작성해주세요.'}
                            readOnly={false}
                            onChange={v => store.content = v.target.value}
                        />
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup label={'받는사람'}
                                    labelId={'receiverEmail'}
                                    placeholder={'sample@naver.com'}
                                    value={store.receiverEmail}
                                    onChange={v => store.receiverEmail =v.target.value}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className="d-flex flex-row-reverse">
                        <CIconBtn style={{width: 100, height: 30}}
                                title={'메일 전송'}
                                icon={'fi-rr-file-invoice-dollar'}
                                onClick={sendEstimate}
                                variant={'success'}
                        />
                    </Col>
                </Row>
            </CContainer>
        </Modal>
        </>
    );
}

export default observer(SendEstimatePopup);