import {useEffect} from "react";
import { observer } from "mobx-react-lite";
import {CContainer, CCol, CInputDoubleGroup, CInputGroup, CSearchBtn, CalendarGroup} from "../../components/CustomContainer";
import { Row, Col } from "react-bootstrap";
import { NonpaymentStore } from "../../store/billing/NonpaymentStore";
import CustomerBillingAddressSearch from "../../components/searchModal/CustomerBillingAddressSearch";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import { AppStore } from "../../store/AppStore";
import {NonBillStore} from "../../store/billing/NonBillStore";
import {today} from "../../utils/dateUtils";
import {ContractListStore} from "../../store/contract/ContractListStore";
import SiteNameSearch from "../../components/searchModal/SiteNameSearch";

const NonBillSearch = ({getNonBillList}) => {

    useEffect(()=> {
        NonBillStore.setCalendar({startDate: today(), endDate: ''});
    }, [])

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        NonBillStore.setSearch('customerName', customerInfo.customerName);
        NonBillStore.setSearch('customerNo',customerInfo.customerNo);
    }

    /* 청구지 조회 팝업 오픈 */
    const openSearchBillingAddressPopup = () => {
        AppStore.toggleCustomerBillingAddressSearchModal()
    }

    /* 청구지 정보 콜백 함수 */
    const getBillingAddressInfo = (info) => {
        NonBillStore.setSearch('billingAddressSeq', info.billingAddressSeq);
        NonBillStore.setSearch('billingAddressName', info.billingAddressName);
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = () => {
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        if (!userInfo) {
            return false;
        }

        NonBillStore.setSearch('saleUserSeq', userInfo.userSeq);
        NonBillStore.setSearch('saleUserName', userInfo.name);
    }

    /* 현장 조회 팝업 오픈 */
    const openSearchSitePopup = () => {
        AppStore.toggleSiteNameSearchPopup()
    }

    /* 현장 정보 콜백 함수 */
    const getSiteInfo = (info) => {
        NonBillStore.setSearch('siteSeq', info.siteSeq);
        NonBillStore.setSearch('siteName', info.siteName);
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'기준일'}
                            value={NonBillStore.calendar}
                            onChange={(v) => NonBillStore.setCalendar(v)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'현장'}
                            value={NonBillStore.search.siteName}
                            onCallbackBtn={openSearchSitePopup}
                            onChange={(v)=> {
                                if(NonBillStore.search.siteSeq) {
                                    NonBillStore.setSearch('siteSeq', '');
                                }
                                NonBillStore.setSearch('siteName', v.target.value);
                            }}
                            enterKeyHint={getNonBillList}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputDoubleGroup
                            label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객코드'}
                            placeholder2={'고객명'}
                            onCallbackBtn={openSearchCustomerPopup}
                            value={NonBillStore.search.customerNo}
                            value2={NonBillStore.search.customerName}
                            onChange={(e)=> NonBillStore.setSearch('customerNo', e.target.value)}
                            onChange2={(e)=> NonBillStore.setSearch('customerName', e.target.value)}
                            enterKeyHint1={()=> getNonBillList()}
                            enterKeyHint2={()=> getNonBillList()}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'계약번호'}
                            value={NonBillStore.search.contractNo}
                            onChange={v => NonBillStore.setSearch('contractNo', v.target.value)}
                            enterKeyHint={()=> getNonBillList()}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'영업담당자'}
                            value={NonBillStore.search.saleUserName}
                            onCallbackBtn={openSearchUserPopup}
                            onChange={(e) => NonBillStore.setSearch('saleUserName', e.target.value)}
                            enterKeyHint={()=> getNonBillList()}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getNonBillList} />
                    </Col>
                </Row>
            </CContainer>
            {/* 팝업 */}
            <CustomerBillingAddressSearch customerNo={NonpaymentStore.search.customerNo} callbackFn={getBillingAddressInfo} />
            <CustomerSearch callbackFn={getCustomerInfo}/>
            <UserSearch callbackFn={getUserInfo}/>
            <SiteNameSearch callbackFn={getSiteInfo} />
        </>
    );

}

export default observer(NonBillSearch);
