import {CCol, Subject} from "./CustomContainer";
import {Form, InputGroup, Row} from "react-bootstrap";
import {SiteOptionStore} from "../store/customer/SiteOptionStore";
import {observer} from "mobx-react-lite";

const SiteOption =({optionList, disabled=false, small=false})=> {
    return (
        <Row>
            <Subject>현장 옵션 정보</Subject>
            <Row>
                {
                    optionList.map((v,i) => {
                        return(
                            <CCol lg={small ? 3 : 4} key={i}>
                                <InputGroup className="mb-1">
                                    <Form.Control
                                        style={{flex: 2}}
                                        readOnly={true}
                                        value={v.optionName}
                                    />
                                    {v.optionUnitCode === '1' &&
                                        <InputGroup.Checkbox
                                            checked={v.optionValue === 'Y'}
                                            onChange={e => {
                                                if(e.target.checked){
                                                    v.optionValue = 'Y';
                                                    SiteOptionStore.updateSiteOptionChangeList({optionSeq: v.optionSeq, optionValue: 'Y'});
                                                }else{
                                                    v.optionValue = '';
                                                    SiteOptionStore.deleteSiteOption(v.optionSeq);
                                                }
                                            }}
                                            disabled={disabled}
                                        />
                                    }
                                    {v.optionUnitCode === '2' &&
                                        <>
                                            <Form.Control
                                                style={{textAlign: 'right', flex: 1, color: v.optionValue ? 'red' : ''}}
                                                value={v.optionValue || ''}
                                                onChange={e => v.optionValue = e.target.value}
                                                onBlur={(e)=> {
                                                    if(e.target.value){
                                                        SiteOptionStore.updateSiteOptionChangeList({optionSeq: v.optionSeq, optionValue: v.optionValue});
                                                    }else{
                                                        SiteOptionStore.deleteSiteOption(v.optionSeq);
                                                    }
                                                }}
                                                disabled={disabled}
                                            />
                                            <InputGroup.Text>개</InputGroup.Text>
                                        </>
                                    }
                                    {v.optionUnitCode === '3' &&
                                        <Form.Select
                                            style={{color: v.optionValue ? 'red' : ''}}
                                            value={v.optionValue || ''}
                                            onChange={e => {
                                                if(e.target.value){
                                                    v.optionValue = e.target.value;
                                                    SiteOptionStore.updateSiteOptionChangeList({optionSeq: v.optionSeq, optionValue: v.optionValue});
                                                }else{
                                                    v.optionValue = '';
                                                    SiteOptionStore.deleteSiteOption(v.optionSeq);
                                                }
                                            }}
                                            disabled={disabled}
                                        >
                                            <option value="">선택</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                        </Form.Select>
                                    }
                                </InputGroup>
                            </CCol>
                        )
                    })
                }
            </Row>
        </Row>
    )
}

export default observer(SiteOption);

