import { observer } from "mobx-react-lite"
import Modal from "../../../components/Modal";
import { AppStore } from "../../../store/AppStore";
import { CCol, CContainer, CIconBtn, CInputGroup } from "../../../components/CustomContainer";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import AgGridContainer from "../../../components/AgGridContainer";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ContractDetailStore } from "../../../store/contract/ContractDetailStore";
import { showToast } from "../../../common/utils";
import { useNavigate } from "react-router-dom";
import { callConfirm } from "../../../utils";

const MatchBillItemPopup = ({searchContract}) => {
    const gridRef = useRef();
    const [contractAssetList, setContractAssetList] = useState([]);
    const [chargeAmount, setChargeAmount] = useState(0);
    const [chargeTotalAmount, setChargeTotalAmount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        getContractAssetList();
        getContractFirstBillingList();
    }, [AppStore.IsOpenContractFixMatchBillItemPopup])

    const getContractAssetList = async() => {
        if(ContractDetailStore.contractNo){
            const result = await axios.get('/contractAsset/list', { params: { contractNo: ContractDetailStore.contractNo } });
            setContractAssetList(result);
        }
    }

    const getContractFirstBillingList = async() => {
        if(ContractDetailStore.contractNo){
            const result = await axios.get(`/billing/first/${ContractDetailStore.contractNo}`);
            const amount = result.filter(v => v.billingType === '1' || v.billingType === '2' || v.billingType === '3').reduce((total, value) => total + value.chargeAmount, 0);
            setChargeAmount(amount);
        }
    }

    const contractFix = async () => {
        if (chargeTotalAmount !== chargeAmount) {
            showToast('계산서 금액과 렌탈료 총합이 일치하도록 작성해주세요.');
            return;
        }

        if (!await callConfirm('계약을 확정하시겠습니까?')) {
            return;
        }

        await axios.put('contract/detail/fix', {contractDto: ContractDetailStore, isFix: 1, billItemList: contractAssetList});
        showToast('계약이 확정되어 청구 스케쥴이 생성되었습니다.');

        ContractDetailStore.setContractDetail(1, 'isFix');
        searchContract(ContractDetailStore.contractNo);
        AppStore.toggleContractFixMatchBillItem();
    }

    const amountChangeFn = () => {
        const amount = contractAssetList.reduce((total, v) => total + Number(v.chargeAmount), 0);
        setChargeTotalAmount(amount);
    }

    const appendTitle = () => {
        return <>
                    <Col lg={3}>
                        <CIconBtn
                                title={'일괄분할적용'}
                                onClick={() => {
                                    const parts = contractAssetList.length;
                                    const baseValue = Math.floor(chargeAmount / parts); // 기본 몫
                                    const remainder = chargeAmount % parts; // 나머지

                                      // 배분된 금액 리스트 생성
                                    let distributedAmounts = Array.from({ length: parts }, (_, i) => baseValue + (i < remainder ? 1 : 0));
                                    
                                    // 가장 큰 값을 첫 번째 dto의 amount에 할당
                                    contractAssetList[0].chargeAmount = Math.max(...distributedAmounts);
                                    
                                    // 나머지 값을 각각의 dto의 amount에 할당
                                    let index = 1;
                                    for (let value of distributedAmounts) {
                                        if (index < parts) {
                                        contractAssetList[index].chargeAmount = value;
                                        index++;
                                        }
                                    }
                                    gridRef.current.api.redrawRows();
                                    amountChangeFn();
                                }}
                                variant={'primary'}
                        />
                    </Col>
                </>
    }

    return (
        <>
            <Modal title={'계약자산 연결'}
                show={AppStore.IsOpenContractFixMatchBillItemPopup}
                onHide={() => AppStore.toggleContractFixMatchBillItem()}
                toggleValue={AppStore.IsOpenContractFixMatchBillItemPopup}
                className={'modal-w7'}
                onExit={() => {
                    setChargeTotalAmount(0);
                }}
            >
                <CContainer search>
                    <Row>
                        <CCol lg={12}>
                            <CInputGroup
                                label={'선발행 계산서 금액'}
                                labelId={'rentalChargeAmount'}
                                style={{flex: 1, textAlign: 'right'}}
                                value={chargeAmount?.toLocaleString()}
                                align='right'
                                disabled={true}
                            />
                        </CCol>
                    </Row>
                </CContainer>
                <CContainer>
                  <AgGridContainer
                        gridRef={gridRef}
                        height={27}
                        rowData={contractAssetList}
                        appendTitle={appendTitle()}
                        columnDefs={
                            [
                                {field: "assetNo", headerName: "자산번호", width: 180, hide: true},
                                {field: "managementNo", headerName: "관리번호", width: 180},
                                {field: "model", headerName: "모델명", width: 220},
                                {field: "chargeAmount", headerName: "렌탈료", width: 180, flex: 1, onCellValueChanged: amountChangeFn, valueFormatter: v => v.value?.toLocaleString() || 0, cellClass: 'ag-grid-money-align', editable: true, headerClass: 'grid-column-editable',},
                            ]
                        }
                        seqColumn={'agId'}
                        isCheckBox={true}
                    />
                </CContainer>
                <Row>
                    <Col lg={5}></Col>
                    <Col lg={4}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'chargeTotalAmount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>렌탈료 총합</InputGroup.Text>
                            <Form.Control style={{textAlign: 'right', width: 100}}
                                            max={1}
                                            aria-describedby={'chargeTotalAmount'}
                                            value={chargeTotalAmount?.toLocaleString()}
                                            readOnly={true}/>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                        </InputGroup>
                    </Col>
                    <Col lg={3} style={{textAlign: 'center'}}>
                        <CIconBtn style={{width: 100, height: 30}}
                                title={'확정'}
                                onClick={contractFix}
                                variant={'success'}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default observer(MatchBillItemPopup);