import React from 'react';
import ImageGallery from 'react-image-gallery';

const PreviewImage = ({ imageUrl, height='80px' }) => {
  const images = [
    {
      original: imageUrl,
    //   thumbnail: imageUrl,
    },
  ];

  const renderImageItem = (item) => {
    return (
      <div className="image-gallery-image">
        <img
          src={item.original}
          alt="signature image"
          style={{ width: '100%', height: height, objectFit: 'contain' }}
        />
      </div>
    );
  };

  return (
    <ImageGallery
      items={images}
      renderItem={renderImageItem}
      showPlayButton={false}
    />
  );
};


export default PreviewImage;