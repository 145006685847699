import { observer } from "mobx-react-lite"
import { AppStore } from "../../../store/AppStore";
import Modal from "../../../components/Modal";
import { CContainer, CCol, CInputGroup } from "../../../components/CustomContainer";
import { Row } from "react-bootstrap";
import AgGridContainer from "../../../components/AgGridContainer";
import { useRef } from "react";

const CalculationFormulaPopup = ({formulaList}) => {
    const gridRef = useRef();

    // const formulaList = [
    //     {no: 1, allowanceDeduction: '연장근로수당', formula: '시급*시간*150%'},
    //     {no: 2, allowanceDeduction: '야간근로수당', formula: '시급*시간*200%'},
    //     {no: 3, allowanceDeduction: '휴일근로수당', formula: '시급*시간*150%'},
    //     {no: 4, allowanceDeduction: '근로소득세', formula: '근로세액기준표에 의거함'},
    //     {no: 5, allowanceDeduction: '국민연금', formula: '기준 월 소득액의 4.5%'},
    //     {no: 6, allowanceDeduction: '고용보험', formula: '총 급여액의 0.8%'},
    //     {no: 7, allowanceDeduction: '건강보험', formula: '총 급여액의 3.43%'},
    //     {no: 8, allowanceDeduction: '장기요양보험', formula: '건강보험료의 11.52%'},
    // ];

    return (
        <>
            <Modal title={'공통계산식'}
                onHide={() => AppStore.toggleCalculationFormulaPopup()}
                toggleValue={AppStore.isOpenCalculationFormulaPopup}
                className={'search-modal-h5'}
            >
                <CContainer>
                    <AgGridContainer
                        gridRef={gridRef}
                        // gridTitle={'공통계산식'}
                        height={38}
                        rowData={formulaList}
                        columnDefs={
                            [
                                {field: "seq", headerName: 'No', valueGetter: v => v.node.rowIndex + 1, width: 100},
                                {field: "salaryCalculationFormulaSeq", headerName: 'No', width: 120, hide: true},
                                {field: "allowanceDeduction", headerName: "수당/공제항목", width: 140},
                                {field: "calculationFormula", headerName: '계산방법 및 계산식', flex: 1},
                            ]
                        }
                        />
                </CContainer>
            </Modal>
        </>
    );
}

export default observer(CalculationFormulaPopup);