import React, { useRef, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import axios from 'axios';

import AgGridContainer from "../../components/AgGridContainer";
import { findCommKrnm, getCommcode } from "../../utils/commcode";
import { CContainer } from "../../components/CustomContainer";
import { callConfirm } from "../../utils";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import { AppStore } from "../../store/AppStore";
import { IconBtn } from '../../components/CustomContainer';
import LUtils from "../../utils/lodashUtils";
import { ICON_TRASH, ICON_EXTEND } from "../../common/constants";
import AccountingCodeSearch from "../../components/searchModal/AccountingCodeSearch";
import { showToast } from "../../common/utils";
import { today , oneMonthAfter } from "../../utils/dateUtils";
import PaybackListSearch from "./PaybackListSearch";
import { PaybackStore } from "../../store/accounting/PaybackStore";
import Modal from "../../components/Modal";
import PaybackDetail from "./PaybackDetail";

const PaybackList = () => {
    const store = PaybackStore;
    const gridRef = useRef();
    const [gridData, setGridData] = useState([]);
    const [customerKind, setCustomerKind] = useState('');

    useEffect(() => {
        // 한달 날짜 세팅
        store.search.startDate = today();
        store.search.endDate = oneMonthAfter(today());
    }, []);

    const columnDefs = [
        {field: "paybackSeq", headerName: "상환번호", hide: true},
        {field: "paybackDate", headerName: "결제일자", minWidth: 120, editable: true, headerClass: 'grid-column-editable'},
        {field: "customerNo", headerName: "고객코드", minWidth: 160, hide: true},
        {field: "customerName", headerName: "고객명", minWidth: 160, cellRenderer: e => e.value === 'pinnedTopRowData' ? null : popupCustomerSearch(e), headerClass: 'grid-column-editable'},
        {field: "amount", headerName: "금액", minWidth: 120, editable: true, valueFormatter: v => v.value?.toLocaleString(), headerClass: 'grid-column-editable'},
        {field: "remark", headerName: "비고", editable: true, minWidth: 500, headerClass: 'grid-column-editable'},
        {field: "accountName", headerName: "계정과목", editable: true, minWidth: 120, cellRenderer: e => e.value === 'pinnedTopRowData' ? null : popupAccountingCode(e), headerClass: 'grid-column-editable'},
        {
            field: "isPayback",
            headerName: "상환여부",
            minWidth: 80,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: { values: [0, 1] },
            valueFormatter: v => v.value === 'pinnedTopRowData' ? '' : !!v.value ? '상환' : '상환예정',
        },
        {
            field: "companyCode",
            headerName: "회사구분",
            hide: true,
            width: 120
        },
        {field: "creator", headerName: "작성자", minWidth: 180},
        {field: "createdDate", headerName: "작성일자", minWidth: 180, flex: 1},
    ];

    /* 조회 */
    const getPaybackList = async () => {
        const result = await axios.get("payback/list", {params: store.search});
        store.paybackList = result;
        if(result.length === 0) showToast('조회된 데이터가 없습니다.');
    }

    /* 고객 팝업 UI */
    const popupCustomerSearch = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.customerName}</div>
                <IconBtn key={e.rowIndex} onClick={() => {openCustomerSearch(e), setCustomerKind('grid')}} />
            </div>
        )
    }

    /* 고객 조회 팝업 클릭 시 이벤트 */
    const openCustomerSearch = (e) => {
        AppStore.toggleCustomerSearchModal();
        setGridData(e);
    }

    /* 고객 팝업 callback */
    const searchCustomerInfo =(data)=> {
        if(data && customerKind === 'grid') {
            const result = store.paybackList.map(v => {
                if(v.paybackSeq === gridData.data.paybackSeq){
                    v.customerNo = data.customerNo;
                    v.customerName = data.customerName;
                    LUtils.assign(v, {isUpdated: true });
                }
                return v;
            });
            store.paybackList = result;
        }else if(data && customerKind === 'search'){
            store.search.customerNo = data.customerNo;
            store.search.customerName = data.customerName;
        }
    }

    /* 계정 과목 팝업 UI */
    const popupAccountingCode = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.accountName}</div>
                <IconBtn key={e.rowIndex} onClick={() => openAccountingCode(e)} />
            </div>
        )
    }

    /* 계정 과목 팝업 클릭 시 이벤트 */
    const openAccountingCode = (e) => {
        setGridData(e);
        AppStore.toggleAccountingCodePopup();
    }

    /* 계정 과목 팝업 callback */
    const searchAccountingCode = (data) => {
        store.paybackList.map(v => {
            if(v.paybackSeq === gridData.data.paybackSeq) {
                v.accountCode = data.code;
                v.accountName = data.name;
                LUtils.assign(v, {isUpdated: true, isSelected: true});
            }
        })
        gridRef.current.api.redrawRows();
    }

    /* 저장 */
    const callBackGridData = async () => {
        // 필수값 확인
        const paybackList = store.paybackList.filter(v => v.isUpdated);
        if(paybackList.some(v => v.paybackDate === null ||  v.paybackDate === '' || v.customerNo === null || v.customerNo === '' || v.amount === null || v.amount === '' || v.remark === null || v.remark === '' || v.accountCode === null || v.accountCode === '' || v.companyCode === null || v.companyCode === '')){
            showToast("필수값을 입력해주세요.");
            return;
        }

        if(!await callConfirm('저장 하시겠습니까?')) return;
        await axios.put('payback', {paybackList: paybackList})
            .then(() => {
                showToast("저장 되었습니다.");
            })
        await getPaybackList();
    }

    /* 취소 버튼 */
    const deletedBtn = {
        isUsed: true,
        callbackFn:() => deletedPayback(),
        icon: ICON_TRASH,
        title: '삭제',
        width: 70
    }

    /* 삭제 버튼 이벤트 */
    const deletedPayback = async() => {
        if (!await callConfirm('삭제하시겠습니까?')) return;

        const seqList = store.paybackList.filter(v => v.isSelected).map(v => v.paybackSeq);
        await axios.put('payback/delete', {seqList: seqList});
        showToast("삭제 되었습니다.");
        await getPaybackList();
    }

    /* 상환 버튼 */
    const paybackBtn = {
        isUsed: true,
        callbackFn:() => completePayback(),
        icon: ICON_EXTEND,
        title: '상환',
        width: 70
    }

    /* 상환 버튼 이벤트 */
    const completePayback = async() => {
        if (!await callConfirm('상환하시겠습니까?')) return;

        const seqList = store.paybackList.filter(v => v.isSelected).map(v => v.paybackSeq);
        await axios.put('payback/complete', {seqList: seqList});
        showToast("완료 되었습니다.");
        await getPaybackList();
    }

    /* cell change event */
    const cellCustomChangeValue = async (e) => {
        if(e.column.colId!=='paybackDate' && e.column.colId!=='amount' && e.column.colId!=='remark' && e.column.colId!=='companyCode') return;

        const updatedList = store.paybackList.map(v => {
            if(v.paybackSeq === e.data.paybackSeq) {
                return {
                    ...v,
                    [e.column.colId]: e.value,
                    isUpdated: true,
                    isSelected: true
                  };
                }
                return v;
        });
        store.paybackList = updatedList;
        gridRef.current.api.redrawRows();
    }

    /* 행 고정 */
    const pinnedTopRowData = [{
        amount: store.paybackList.reduce((total, v) => total + v.amount, 0), customerName: 'pinnedTopRowData', accountName: 'pinnedTopRowData', isPayback: 'pinnedTopRowData',
    }];

    return (
        <>
            <PaybackListSearch getPaybackList={getPaybackList} setCustomerKind={setCustomerKind}/>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    gridTitle={'상환예정액관리'}
                    height={60}
                    rowData={store.paybackList}
                    columnDefs={columnDefs}
                    isCheckBox={true}
                    useUpdated={true}
                    callBackGridData={callBackGridData}
                    isCellCustom={true}
                    customBtnInfo={[deletedBtn, paybackBtn]}
                    cellCustomChangeValue={cellCustomChangeValue}
                    getRowStyle={(params) => {
                        if (params.data.isPayback === 1) {
                            return { pointerEvents: 'none' };
                        }
                        return null;
                    }}
                    useCsvDownload={true}
                    pinnedTopRowData={pinnedTopRowData}
                />
            </CContainer>
            <CustomerSearch callbackFn={searchCustomerInfo} />
            <AccountingCodeSearch callbackFn={searchAccountingCode} inputCode={null} />
            <Modal title={'상환예정액 추가'} onExit={()=> {store.detailInit()}} size={'md'}>
                <PaybackDetail getPaybackList={getPaybackList} />
            </Modal>
        </>
    )
}

export default observer(PaybackList);
