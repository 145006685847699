import { observer } from "mobx-react-lite";
import { Row } from "react-bootstrap";
import axios from "axios";

import { AppStore } from "../../../store/AppStore";
import { CCol, CContainer, CInputGroup, CSaveBtn } from "../../../components/CustomContainer";
import Modal from "../../../components/Modal";
import { ShipStore } from "../../../store/ship/ShipStore";
import { showToast } from "../../../common/utils";

const OutsourcingInfoPopup = ({ getShipList }) => {
    const store = ShipStore;
   
    const saveBtn = async () => {
        await axios.put('/ship/outsourcing/info', store.data);
        showToast('저장했습니다.');
        getShipList();
    }

    return (
        <Modal
            title={'외주 배송 정보 입력'}
            show={AppStore.isOpenOutsourcingInfo}
            onHide={() => AppStore.toggleOutsourcingInfoPopup()}
            toggleValue={AppStore.isOpenOutsourcingInfo}
            size={'md'}
        >
            <CContainer>
                <Row>
                    <CCol lg={12}>
                        <CInputGroup label={'외주 운반사'} labelId={'outsourcingCompany'} value={store.data.outsourcingCompany} onChange={(v)=> store.data.outsourcingCompany = v.target.value} />
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup label={'외주 배송비'} labelId={'outsourcingAmount'} value={store.data.outsourcingAmount} onChange={(v)=> store.data.outsourcingAmount = v.target.value} />
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup label={'외주 차량톤수'} labelId={'outsourcingCarTonnage'} value={store.data.outsourcingCarTonnage} onChange={(v)=> store.data.outsourcingCarTonnage = v.target.value} />
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup label={'외주 차량번호'} labelId={'outsourcingCarNumber'} value={store.data.outsourcingCarNumber} onChange={(v)=> store.data.outsourcingCarNumber = v.target.value} />
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup label={'외주 운전자명'} labelId={'outsourcingDriver'} value={store.data.outsourcingDriver} onChange={(v)=> store.data.outsourcingDriver = v.target.value} />
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup label={'외주 휴대폰번호'} labelId={'outsourcingPhoneNumber'} value={store.data.outsourcingPhoneNumber} onChange={(v)=> store.data.outsourcingPhoneNumber = v.target.value} />
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup label={'외주 비고'} labelId={'outsourcingRemark'} value={store.data.outsourcingRemark} onChange={(v)=> store.data.outsourcingRemark = v.target.value} />
                    </CCol>
                </Row>
                <Row>
                    <CSaveBtn style={{width: '97%', marginTop: 20}} onClick={saveBtn} />
                </Row>
            </CContainer>
        </Modal>
    )
}

export default observer(OutsourcingInfoPopup);
