import {useEffect, useRef, useState} from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";

import { AppStore } from "../../../store/AppStore";
import Modal from "../../../components/Modal";
import AgGridContainer from "../../../components/AgGridContainer";
import {CCol, CContainer, CIconBtn, CSearchBtn} from "../../../components/CustomContainer";
import {Col, Row} from "react-bootstrap";
import {showToast} from "../../../common/utils";
import {callConfirm} from "../../../utils";

const ReserveDetailPopup = (props) => {
    const gridRef = useRef();
    const [assetList, setAssetList] = useState([]);

    useEffect(()=> {
        if(props.reserveNo){
            getReserveDetailList(props.reserveNo);
        }
    }, [props.reserveNo])

    const getReserveDetailList =async()=> {
        setAssetList(await axios.get(`/asset/reservation/list/${props.reserveNo}`));
    }

    const cancelAssetReservation =async()=> {
        const assetReservationList = assetList.filter(v => v.isSelected);
        if(assetReservationList.length === 0){
            showToast('예약 자산을 선택해 주세요.');
            return;
        }
        if(assetReservationList.some(v => v.reserveStatus === '취소')){
            showToast('취소된 자산이 선택되었습니다.');
            return;
        }

        const assetReservationSeqList = assetReservationList.map(v => {return v.assetReservationSeq});

        if(!await callConfirm('취소하시겠습니까?')){
            return;
        }

        const success = await axios.put('/asset/reservation/cancel/asset', assetReservationSeqList);
        showToast('취소되었습니다.');
        //재조회
        await getReserveDetailList();
        await props.searchReservation();
    }

    return (
        <Modal
            title={'예약상세'}
            show={AppStore.isOpenReserveDetailPopup}
            onHide={() => AppStore.toggleReserveDetailPopup()}
            toggleValue={AppStore.isOpenReserveDetailPopup}
            className={'search-modal-6'}
        >
            <CContainer>
                <Row>
                    <CCol lg={4}>
                        <span>예약번호 : {props.reserveNo}</span>
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CIconBtn style={{width: 100}}
                                  title={'예약취소'}
                                  icon={'fi-rr-calendar-check'}
                                  onClick={()=> cancelAssetReservation()}
                        />
                    </Col>
                </Row>
                <AgGridContainer
                    gridRef={gridRef}
                    height={40}
                    rowData={assetList}
                    columnDefs={[
                        {field: "assetReservationSeq", headerName: "예약순번", hide: true},
                        {field: "assetNo", headerName: "자산번호", width: 120},
                        {field: "reserveStatus", headerName: "상태", width: 100},
                        {field: "productName", headerName: "제품명", width: 180},
                        {field: "model", headerName: "모델명", width: 180},
                        {field: "spec", headerName: "스펙", width: 250, flex:1},
                    ]}
                    isCheckBox={true}
                    getRowStyle={(params) => {
                        if (params.data.reserveStatus === '취소') {
                            return { pointerEvents: 'none', color: '#848484' };
                        }
                        return null;
                    }}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(ReserveDetailPopup);
