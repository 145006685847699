import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";


export const ProductStore = observable({
    data : {
        productSeq: 0,
        ccSM: '',
        isDeleted: 0,
        powerTransmission: '',
        drivingSpeed: 0,
        workingMaximumHeight: 0,
        loadCapacity: 0,
        drivingMethod: 0,
        productName: '',
        model: '',
        spec: '',
        insurancePolicyUrl: '',
        insurancePolicyFileName: '',
        specificationTableUrl: '',
        specificationTableFileName: '',
        safetyCertificateUrl: '',
        safetyCertificateFileName: '',
        workingMethodUrl: '',
        workingMethodFileName: '',
        emergencyDownUrl: '',
        emergencyDownFileName: '',
        remark: '',
        monthUnitPrice: '',
        ton: null,
        mastCount: null,
        mastHeight: null,
    },

        
    productList: [],
    productGroupList: [],
    productSearchCondition: {productCode: '', lgSeq: '', model: '', productName: '', brandCode: '', isDeleted: 0, powerTransmission: '', drivingSpeed: null, workingMaximumHeight: null, loadCapacity: null, drivingMethod: ''},
    productFiles: {insurancePolicyUrl: '', insurancePolicyName: '',
                    specificationTableUrl: '', specificationTableName: '',
                    safetyCertificateUrl: '', safetyCertificateName: '',
                    workingMethodUrl: '', workingMethodName: '',
                    emergencyDownUrl: '', emergencyDownFileName: '',
                    },
    setData(v) {
        LUtils.assign(this.data, v);
    },
    setProductList(v) {
        this.productList = v;
    },
    setProductGroupList(v){
        this.productGroupList = v;
    },
    setProductSearchCondition(v) {
        LUtils.assign(this.productSearchCondition, v);
    },

    init() {
        this.data.productSeq = 0;
        this.data.ccSM = '';
        this.data.isDeleted = 0;
        this.data.powerTransmission = '';
        this.data.drivingSpeed = 0;
        this.data.workingMaximumHeight = 0;
        this.data.loadCapacity = 0;
        this.data.drivingMethod = 0;
        this.data.productName = '';
        this.data.model = '';
        this.data.spec = '';
        this.data.insurancePolicyUrl = '';
        this.data.insurancePolicyFileName = '';
        this.data.specificationTableUrl = '';
        this.data.specificationTableFileName = '';
        this.data.safetyCertificateUrl = '';
        this.data.safetyCertificateFileName = '';
        this.data.workingMethodUrl = '';
        this.data.workingMethodFileName = '';
        this.data.emergencyDownUrl = '';
        this.data.emergencyDownFileName = '';
        this.data.remark = '';
    },

    filesInit() {
        this.productFiles.insurancePolicyUrl = '';
        this.productFiles.insurancePolicyName = '';
        this.productFiles.specificationTableUrl = '';
        this.productFiles.specificationTableName = '';
        this.productFiles.safetyCertificateUrl = '';
        this.productFiles.safetyCertificateName = '';
        this.productFiles.workingMethodUrl = '';
        this.productFiles.workingMethodName = '';
        this.productFiles.emergencyDownUrl = '';
        this.productFiles.emergencyDownFileName = '';
    }
});
