import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';

import { AppStore } from '../../../store/AppStore';
import { CCol } from '../../../components/CustomContainer';

const ConfirmMailModal = ({callbackFn}) => {
    const [value, setValue] = useState(false);

    const agreeHandler = () => {
        callbackFn(value);
        AppStore.toggleConfirmMailPopup();
    }

    return (
        <Modal show={AppStore.isOpenConfirmMailPopup}
               onHide={()=> AppStore.toggleConfirmMailPopup()}>
            <Modal.Header closeButton>
                <Modal.Title>서명을 저장하고, 계약을 완료 하시겠습니까?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CCol lg={12}>
                    <Form.Check
                        inline
                        label="이메일로 계약서 받기"
                        name="isSendMail"
                        type={'checkbox'}
                        id={'isSendMail'}
                        checked={value}
                        onChange={v => setValue(v.target.checked)}
                    />
                </CCol>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=> AppStore.toggleConfirmMailPopup()}>
                    취소
                </Button>
                <Button variant="primary" onClick={agreeHandler}>
                    확인
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default observer(ConfirmMailModal);