import { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer, CInputGroup, CSearchBtn } from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";
import {getSessionUserCompanyCode} from "../../utils/userUtils";
import { decrypt } from "../../common/utils";
import { ICON_ADD } from "../../common/constants";

/**
 * @param {Function} callbackFn: 결과값 받을 콜백 함수
 * @param {String} column: 반환값 받을때 같이 받을 컬럼명(구분값)
*/
const UserSearch =({ callbackFn=null, column='', search='', ...props })=> {
    const {btnStatus} = props;
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [userList, setUserList] = useState([]);
    const gridRef = useRef();

    const onSearchUserList = async () => {
        const result = await axios.get('/user/list', { params: {id: userId, name: userName, isActive: 1, companyCode: getSessionUserCompanyCode(), searchType: search} });
        setUserList(result);
    }

    const selectedRow = (e) => {
        callbackFn && callbackFn(e.data, column);
        AppStore.toggleUserSearchModal();
    }
    
    const callBackGridData = ({ selectedList }) => {
        callbackFn && callbackFn(selectedList, column);
        AppStore.toggleUserSearchModal();
    }

    const refresh = () => {
        setUserId('');
        setUserName('');
        setUserList([]);
    }

    return (
        <Modal
            title={'사원조회'}
            show={AppStore.isOpenUserSearch}
            onHide={() => AppStore.toggleUserSearchModal()}
            toggleValue={AppStore.isOpenUserSearch}
            className={'modal-w9'}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <CInputGroup label={'사용자ID'} labelId={'customerNo'} value={userId} onChange={(v)=> setUserId(v.target.value)} enterKeyHint={onSearchUserList}/>
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'사원명'} labelId={'customerName'} value={userName} onChange={(v)=> setUserName(v.target.value)} enterKeyHint={onSearchUserList} />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearchUserList} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    height={30}
                    gridRef={gridRef}
                    rowData={userList}
                    columnDefs={
                        [
                            {field: "id", headerName: "사용자ID", flex: 1},
                            {field: "name", headerName: "사원명", flex: 1},
                            {field: "deptName", headerName: "부서명", width: 130},
                            {field: "position", headerName: "직급", width: 110},
                            {field: "phoneNumber", headerName: "핸드폰번호", valueGetter: v => v.data.phoneNumber ? decrypt(v.data.phoneNumber) : '', flex: 1},
                            {field: "isActive", headerName: "퇴사여부", valueGetter:v => v.data.isActive ? '근속' : '퇴사', hide: true},
                            {field: "userSeq", headerName: "사용자 시퀀스", hide: true},
                        ]
                    }
                    seqColumn={'id'}
                    enableCellTextSelection={true}
                    rowDoubleClickCallback={selectedRow}
                    callBackGridData={callBackGridData}
                    selectBtnInfo={{isUsed: true}}
                    btnTitles={{save: '선택'}}
                    isCheckBox={btnStatus === 'add' ? true : false}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(UserSearch);
