import { observable } from "mobx";

export const ApronSearchStore = observable({
    search: {
        apronSeq: null,
        apronName: '',
    },
    init() {
        this.search.apronSeq = null;
        this.search.apronName = '';
    }
});