import {useEffect, useState, useRef} from "react";
import { observer } from "mobx-react-lite";
import {Col, Form, Row} from "react-bootstrap";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import {CCol, CContainer, CInputGroup, CSaveBtn} from "../CustomContainer";
import AgGridContainer from "../AgGridContainer";
import {showToast} from "../../common/utils";
import {ReleaseAskDetailStore} from "../../store/release/ReleaseAskDetailStore";
import {callConfirm} from "../../utils";
import {getInputTime} from "../../utils/dateUtils";

const ReleaseAssetCheck =({ callbackFn=null, column='', ...props })=> {
    const inputNoRef = useRef();
    const gridRef = useRef();
    const {releaseAskItemList} = props;

    const [isReader, setIsReader] = useState(true);
    const [checkList, setCheckList] = useState([]);
    const [inputNo, setInputNo] = useState('');
    const [totalCnt, setTotalCnt] = useState(0);
    const [checkCnt, setCheckCnt] = useState(0);

    useEffect(()=> {
        let productList = releaseAskItemList.map(v => {
            if(v.isCancel === 0){
                if(v.isConsum === 1 && v.assetNo === ''){
                    v.isChecked = 1;
                    setCheckCnt(checkCnt + 1);
                }
                return v;
            }
        });
        //취소건은 제거
        productList = productList.filter(v => v);

        setTotalCnt(productList.length);
        setCheckList(productList);

        setCheckCnt(productList.reduce((total, v) => total + v.isChecked, 0));
    }, [AppStore.isOpenReleaseAssetCheckPopup]);


    const assetCheckComplete =async()=> {
/*
        if(releaseAskItemList.reduce((total, v) => total + v.isChecked, 0) === totalCnt){
            showToast('이미 검수가 완료되었습니다.');
            return;
        }
*/
        if(checkCnt > 0){
            const paramCheckList = checkList.filter(v => v.isChecked && (v.assetNo || v.serialNo));

            // 저장 확인
            if(!await callConfirm('검수 하시겠습니까?')) return;
            // 수정
            const result = await axios.put('releaseAskItem/check', {releaseAskItemList: paramCheckList});
            showToast(`저장되었습니다.`);

            onExit();
            callbackFn && callbackFn();
            AppStore.toggleReleaseAssetCheckPopup();
        }
    }

    const checkNumberList =async(no)=> {
        if(!no){
            return;
        }

        if(isReader && no.length === 1){
            ReleaseAskDetailStore.setCheckCountSec(getInputTime());
            return;
        }

        if(isReader && (getInputTime() - ReleaseAskDetailStore.checkCountSec) < 1){
            return;
        }

        const index = releaseAskItemList.findIndex(v => v.isChecked === 1 && (v.assetNo === no || v.serialNo === no));
        if (index > -1) {
            showToast('이미 입력된 제품 입니다.');
        } else {
            const findInfo = checkList.find(v => !v.isChecked && v.storageNo !== '');

            let params = {
                inputNo: no,
                //storageNo: findInfo ? findInfo.storageNo : '',
                //productCode: findInfo ? findInfo.productCode : '',
            }
            //자산 또는 상품에서 제품을 조회해본다.
            const result = await axios.get(`releaseAskItem/check`, {params: params});
            if(result){
                //리스트에 검수가 안된 제품을 찾는다.
                const productIdx = checkList.findIndex(v =>
                    !v.isChecked && v.productCode === result.productCode);

                if(productIdx > -1){
                    releaseAskItemList[productIdx].isChecked = 1;
                    releaseAskItemList[productIdx].storageSeq = result.storageSeq;
                    releaseAskItemList[productIdx].assetNo = result.assetNo;
                    releaseAskItemList[productIdx].serialNo = result.serialNo;
                    setCheckList(releaseAskItemList);
                    setCheckCnt(checkCnt + 1)
                    // 그리드 전체 다시 그리기
                    gridRef.current.api.redrawRows();
                }else{
                    showToast('같은 제품 코드를 찾을 수 없습니다.');
                }
            }else{
                showToast('정상적인 제품이 아닙니다.(계약된 제품일 수 있습니다)')
            }
        }

        resetInputField();
    }

    const resetInputField =()=> {
        setInputNo('');
        inputNoRef.current && inputNoRef.current.focus();
    }

    const onExit =()=> {
        setCheckCnt(0);
        setCheckList([]);
    }

    return (
        <Modal
            title={'자산검수'}
            show={AppStore.isOpenReleaseAssetCheckPopup}
            onHide={() => AppStore.toggleReleaseAssetCheckPopup()}
            toggleValue={AppStore.isOpenReleaseAssetCheckPopup}
            className={'search-modal-h5'}
            onExit={onExit}
        >
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isReader"
                            label="리더사용"
                            checked={isReader}
                            style={{marginTop: 4}}
                            onChange={()=> setIsReader(!isReader)}
                        />
                    </CCol>
                </Row>
                <Row style={{marginBottom: 0}}>
                    <CCol lg={5}>
                        <CInputGroup ref={(ref)=> ref}
                            label={'자산번호 또는 시리얼번호'}
                            labelId={'inputNo'}
                            value={inputNo}
                            onChange={(e)=> {
                                if(e.keyCode !== 8){
                                    let no = e.target.value;
                                    setInputNo(no);
                                    setTimeout(()=> {
                                        //자산 또는 시리얼 번호 체크
                                        isReader && checkNumberList(no);
                                    }, 500);
                                }
                            }}
                            enterKeyHint={()=> checkNumberList(inputNo)}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <p style={{paddingTop: 4}}><label>총 <b>{totalCnt}</b>건 중</label><label style={{marginLeft: 10}}><b>{checkCnt}</b>건 검수 완료</label></p>
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSaveBtn title={'검수완료'}
                                  onClick={assetCheckComplete}
                                  disabled={checkCnt === 0}
                        />

                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={25}
                    rowData={checkList}
                    columnDefs={
                        [
                            {field: "seq", headerName: "순번", width: 70},
                            {field: "contractProductSeq", headerName: "묶음번호", width: 90, hide:true},
                            {
                                field: "isChecked",
                                headerName: "확인",
                                headerClass: 'grid-column-editable',
                                cellClass: v => v.value !== '' ? 'ag-grid-column-cancel' : '',
                                valueFormatter: v => v.value === 1 ? '완료' : '',
                                width: 70
                            },
                            {
                                field: "isConsum",
                                headerName: "소모품",
                                width: 70,
                                valueGetter: v => v.data.isConsum === 1 ? 'Y' : '',
                                hide: true
                            },
                            {field: "productCode", headerName: "제품코드", width: 120},
                            {field: "productName", headerName: "제품명", width: 150},
                            {field: "model", headerName: "모델명", width: 150},
                            {field: "qty", headerName: "수량", width: 70, hide: true},
                            {field: "assetNo", headerName: "자산번호", width: 120},
                            {field: "serialNo", headerName: "시리얼번호", width: 200},
                            {field: "storageNo", headerName: "입고번호", hide: true},
                            {field: "storageSeq", headerName: "입고순번", hide: true},
                        ]
                    }
                    isRowSelectable={false}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(ReleaseAssetCheck);
