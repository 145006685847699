import {useState} from 'react'
import { observer } from "mobx-react-lite"
import { AppStore } from "../../../store/AppStore";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import Modal from "../../../components/Modal";
import { CCol, CContainer, CIconBtn, CInputGroup } from "../../../components/CustomContainer";
import ShipManagerPopup from './ShipManagerPopup';
import { autoHyphen, decrypt, encrypt, showToast } from '../../../common/utils';
import { callConfirm } from '../../../utils';
import axios from 'axios';

const CheckReleaseShipPopup = ({releaseAskSeq, receiveUserName, receiveUserPhoneNumber, callbackFn}) => {
    const [checkShipUser, setCheckShipUser] = useState(true);
    const [checkReceiveUser, setCeckReceiveUser] = useState(true);
    const [shipUserSeq, setShipUserSeq] = useState('');
    const [shipUserName, setShipUserName] = useState('');
    const [shipUserPhoneNumber, setShipUserPhoneNumber] = useState('');

    const onClickFn = async() => {
        if(checkShipUser){
            if(!shipUserSeq){
                showToast('배송 담당자를 지정해주세요.');
                return;
            }
            if(!shipUserPhoneNumber){
                showToast('배송 담당자 전화번호를 입력해주세요.');
                return;
            }else{
                if(shipUserPhoneNumber.indexOf('010') < 0){
                    showToast('전화번호 형식이 올바르지 않습니다.');
                    return;
                }
            }
        }

        let confirmText = '';
        if(checkShipUser || checkReceiveUser){
            confirmText = '배송완료 처리 및 알림톡 발송을';
        }else{
            confirmText = '배송완료 처리만 진행'
        }

        if(!await callConfirm(`${confirmText} 하시겠습니까?`)) return;

        const params = {
            releaseAskSeq,
            checkShipUser,
            checkReceiveUser,
            shipUserSeq,
            shipUserName,
            shipUserPhoneNumber: encrypt(shipUserPhoneNumber),
            receiveUserName, 
            receiveUserPhoneNumber: encrypt(receiveUserPhoneNumber)
        }

        const result = await axios.put('ship/release/complete', params);
        if(result){
            showToast('배송완료 처리되었습니다.');
        }

        callbackFn();
        AppStore.toggleIsOpenReleaseShipPopup();
    }

    const setShipUserInfo =(data)=> {
        setShipUserSeq(data.userSeq);
        setShipUserName(data.name);
        setShipUserPhoneNumber(autoHyphen(decrypt(data.phoneNumber)));
    }
    
    return (
        <>
            <Modal title={'배송 문자 발송'}
                show={AppStore.isOpenReleaseShipPopup}
                onHide={() => AppStore.toggleIsOpenReleaseShipPopup()}
                toggleValue={AppStore.isOpenReleaseShipPopup}
                className='modal-w4'
            >
                <CContainer search>
                    <p style={{color: '#6E6E6E'}}>배송 담당자 및 인수자에게 배송 정보 문자를 발송 하거나 문자 발송 없이 배송완료 처리만 진행할 수 있습니다.</p>
                    <br/>
                    <Row>
                        <CCol lg={12}>
                            <Form.Check
                                type="switch"
                                id="checkShipUser"
                                label="배송 담당자에게 배송정보 발송"
                                style={{ marginTop: 4 }}
                                checked={checkShipUser}
                                onChange={(e)=> {
                                    setCheckShipUser(e.target.checked);
                                    setShipUserSeq('');
                                    setShipUserName('');
                                    setShipUserPhoneNumber('');
                                }}
                            />
                        </CCol>
                    </Row>
                    {checkShipUser &&
                        <Row>
                            <CCol lg={12}>
                                <CInputGroup label={'배송 담당자'}
                                    labelId={'shipUser'}
                                    value={shipUserName}
                                    disabled={true}
                                    isValid={true}
                                    placeholder={'우측 돋보기를 클릭하여 담당자 조회'}
                                    onCallbackBtn={()=> AppStore.toggleShipManagerPopup()}
                                />
                            </CCol>
                            <CCol lg={12}>
                                <CInputGroup label={'배송 담당자 휴대폰 번호'}
                                    isValid={true}
                                    labelId={'shipUserPhoneNumber'}
                                    value={shipUserPhoneNumber}
                                    placeholder={'(-)없이 입력해 주세요'}
                                    onChange={(e)=> setShipUserPhoneNumber(e.target.value)}
                                />
                            </CCol>
                        </Row>
                    }
                    <hr/>
                    <Row>
                        <CCol lg={12}>
                            <Form.Check
                                type="switch"
                                id="checkReceiveUser"
                                label="받는분에게 배송알림 발송"
                                style={{ marginTop: 4 }}
                                checked={checkReceiveUser}
                                onChange={(e)=> setCeckReceiveUser(e.target.checked)}
                            />
                        </CCol>
                    </Row>
                    {checkReceiveUser &&
                        <Row>
                            <CCol lg={12}>
                                <CInputGroup label={'받는분 성함'}
                                    labelId={'shipUser'}
                                    value={receiveUserName}
                                    disabled={true}
                                />
                            </CCol>
                            <CCol lg={12}>
                                <CInputGroup label={'받는분 휴대폰 번호'}
                                    labelId={'receiveUserPhoneNumber'}
                                    value={receiveUserPhoneNumber}
                                    disabled={true}
                                />
                            </CCol>
                        </Row>
                    }
                </CContainer>
                <br/>
                <Row>
                    <Col lg={12} className="d-flex justify-content-center">
                    {/* <Col lg={12} className="d-flex flex-row-reverse"> */}
                        <CIconBtn style={{width: 100, height: 30}}
                                title={'배송완료'}
                                onClick={onClickFn}
                                variant={'primary'}
                        />
                    </Col>
                </Row>
                <ShipManagerPopup callBackFn={setShipUserInfo} />
            </Modal>
        </>
    )
}

export default observer(CheckReleaseShipPopup);