import AlertConfirm from 'react-alert-confirm';
import _ from 'lodash';

const setLocalStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));  /* localStorage 저장 */
const getLocalStorage = key => JSON.parse(localStorage.getItem(key));  /* localStorage 조회 */

/* alert - https://react-alert-confirm-doc.web.app/ -> TODO: Render JSX로 변경 */
const callAlert = async (title) => {
    const onOk = () => true;
    const [isTrue] = await AlertConfirm.alert({title, onOk, zIndex: 2000});
    return isTrue;
}

/* confirm - https://react-alert-confirm-doc.web.app/ -> TODO: Render JSX로 변경 */
const callConfirm = async (title) => {
    const onOk = () => true;
    const onCancel = () => false;
    const [isTrue] = await AlertConfirm({title, onOk, onCancel, zIndex: 2000});
    return isTrue;
}

/* 주소팝업 - daum */
const openAddressPopup = (fn, e) => {
    new daum.Postcode({
        oncomplete: (data) => {
            fn({postcode: data.zonecode, address: data.roadAddress}, e);
        }}).open();
}

/**
 * grouping
 * @param {Array} list: 그룹핑 하려는 대상 데이터 목록
 * @param {String} groupByColumn: 그룹핑하려는 컬럼 (하나의 컬럼만 가능하다)
 * @param {Array} columns: 반환될 목록에 포함될 컬럼들
 */
const groupBy = (list, groupByColumn, columns) => {
    return _.map(_.values(_.groupBy(list, groupByColumn)), v => _.assign(_.pick(_.head(v), columns), { rowCnt: v.length }));
}

/**
 * grouping 컬럼두개로 그룹핑
 * @param {Array} list: 그룹핑 하려는 대상 데이터 목록
 * @param {String} groupByColumn1: 그룹핑하려는 컬럼 (먼저 묶을 컬럼)
 * @param {String} groupByColumn2: 그룹핑하려는 컬럼
 * @param {Array} columns: 반환될 목록에 포함될 컬럼들
 */
const groupByForTwoColumn = (list, groupByColumn1, groupByColumn2, columns) => {
    const firstResult = _.map(_.values(_.groupBy(list, groupByColumn1)), v => _.values(_.groupBy(v, groupByColumn2)))
    return _.map(_.flatten(firstResult), v => _.assign(_.pick(_.head(v), columns), { rowCnt: v.length}));
}

export {
    setLocalStorage,
    getLocalStorage,
    callAlert,
    callConfirm,
    openAddressPopup,
    groupBy,
    groupByForTwoColumn,
}
