import { useRef, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import GeneralStatementListSearch from "./GeneralStatementListSearch";
import { CContainer, CCol } from "../../components/CustomContainer";
import AgGridContainer from "../../components/AgGridContainer";
import { GeneralStatementStore } from "../../store/accounting/GeneralStatementStore";
import { Row, InputGroup, Form } from "react-bootstrap";
import { AppStore } from "../../store/AppStore";
import ApprovalPostingDatePopup from "./popup/ApprovalPostingDatePopup";
import { showToast } from "../../common/utils";
import GeneralStatementDetail from "./GeneralStatementDetail";
import PasswordCheckPopup from "./popup/PasswordCheckPopup";
import axios from "axios";
import { findCommKrnm } from "../../utils/commcode";
import { callConfirm } from "../../utils";
import Modal from "../../components/Modal";


const GeneralStatementList = () => {
    const gridRef = useRef();
    const subGridRef = useRef();

    useEffect(() => {
        const sumOfGsTotalDebit = GeneralStatementStore.subStatementList.filter(v => v.accountType === '1').reduce((total, statement) => total + statement.amount, 0);
        const sumOfGsTotalCredit = GeneralStatementStore.subStatementList.filter(v => v.accountType === '2').reduce((total, statement) => total + statement.amount, 0)

        GeneralStatementStore.totalDebit = sumOfGsTotalDebit;
        GeneralStatementStore.totalCredit = sumOfGsTotalCredit;
    }, [GeneralStatementStore.subStatementList])

    /* 전표 목록 불러오기 */
    const getStatementList = async () => {
        const result = await axios.get("/statement", {params: GeneralStatementStore.search}); 
        GeneralStatementStore.setStatementList(result);

        const sumOfTotalAmount = GeneralStatementStore.statementList.reduce((total, statement) => total + statement.amount, 0);
        const sumOfTotalDifference = GeneralStatementStore.statementList.reduce((total, statement) => total + statement.difference, 0);
        GeneralStatementStore.totalAmount = sumOfTotalAmount;
        GeneralStatementStore.difference = sumOfTotalDifference;
    }

    /* 미결전표 - 총 전표금액, 대차차액 */
    const sumStatementInputForm = () => {
        return <Row style={{marginTop: -3, marginLeft: '3rem'}} >
                    <CCol lg={6}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'totalAmount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>총 전표금액</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'right'}}
                                            max={2}
                                            aria-describedby={'totalAmount'}
                                            value={GeneralStatementStore.totalAmount.toLocaleString()}
                                            readOnly={true}/>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={6}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'difference'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>대차차액</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'right'}}
                                            max={2}
                                            aria-describedby={'difference'}
                                            value={GeneralStatementStore.difference.toLocaleString()}
                                            readOnly={true}/>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                </Row>
    }

    /* 일반전표 - 총 전표금액, 대차차액 */
    const generalStatementInputForm = () => {
        return <Row style={{marginTop: -3, marginLeft: '3rem'}}>
                    <CCol lg={6}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'totalDebit'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>&nbsp;&nbsp;차변&nbsp;&nbsp;</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'right'}}
                                            max={2}
                                            aria-describedby={'totalDebit'}
                                            value={GeneralStatementStore.totalDebit.toLocaleString()}
                                            readOnly={true}/>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                    <CCol lg={6}>
                        <InputGroup size={'sm'}>
                            <InputGroup.Text id={'totalCredit'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>&nbsp;&nbsp;대변&nbsp;&nbsp;</InputGroup.Text>
                            <Form.Control style={{flex: 1, textAlign: 'right'}}
                                            max={2}
                                            aria-describedby={'totalCredit'}
                                            value={GeneralStatementStore.totalCredit.toLocaleString()}
                                            readOnly={true}/>
                            <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                        </InputGroup>
                    </CCol>
                </Row>
    }

    /* 선택전표승인 커스텀 버튼*/
    const approvalBtn = {
        isUsed: true,
        icon: 'fi-rr-memo-circle-check',
        title: '선택전표승인',
        callbackFn: () => checkedStatementApproval(),
        width: 120,
        variant: 'success'
    }

    /* 선택전표삭제 커스텀 버튼*/
    const deleteBtn = {
        isUsed: true,
        icon: 'fi-rr-delete-document',
        title: '선택전표삭제',
        callbackFn: () => checkedStatementDelete(),
        width: 120,
        variant: 'dark'
    }

    /* 선택전표승인 */
    const checkedStatementApproval = async () => {

        const selectedList = GeneralStatementStore.statementList.filter(v => v.isSelected);
        if (selectedList.length === 0) {
            showToast('선택된 전표가 없습니다.');
            return;
        }

        const isValid = selectedList.every(statement => statement.approvalStatus === '1' || !statement.approvalStatus);
        if (!isValid) {
            showToast('이미 승인 처리된 전표가 포함 되어있습니다.');
            return;
        }

        AppStore.toggleApprovalPostingDatePopup();
    }

    /* 선택전표삭제 */
    const checkedStatementDelete = async() => {
        const selectedList = GeneralStatementStore.statementList.filter(v => v.isSelected);

        if (selectedList.length === 0) {
            showToast('선택된 전표가 없습니다.');
            return;
        }

        const isValid = selectedList.every(statement => statement.approvalStatus === '1');
        if (!isValid) {
            showToast('승인 전표는 승인 해제 후 삭제가 가능합니다.');
            return;
        }

        if (!await callConfirm('선택된 전표를 삭제하시겠습니까?')) {
            return;
        };

        AppStore.togglePasswordCheckPopup();
    }

    const callbackFn = async (result) => {

        if (!result) {
            showToast('비밀번호가 일치하지 않습니다.');
            return;
        }

        // 삭제 처리
        let params = {statementList: []}
        GeneralStatementStore.statementList.forEach(v => {
            if(v.isSelected === true) params.statementList.push({statementNo: v.statementNo} );
        });

        await axios.put('/statement/general/delete', params.statementList);
        showToast('삭제가 완료되었습니다.');
        getStatementList();
    }

    /* 셀 클릭 이벤트 */
    const onRowClickedEvent = (e) => {
        getGeneralStatementDetail(e.data.statementNo, 'subGridList');
        const sumOfGsTotalDebit = GeneralStatementStore.subStatementList.filter(v => v.accountType === '1').reduce((total, statement) => total + statement.amount, 0);
        const sumOfGsTotalCredit = GeneralStatementStore.subStatementList.filter(v => v.accountType === '2').reduce((total, statement) => total + statement.amount, 0)

        GeneralStatementStore.totalDebit = sumOfGsTotalDebit;
        GeneralStatementStore.totalCredit = sumOfGsTotalCredit;
    }

    /* 행 더블 클릭 이벤트 */
    const onRowDoubleClicked = (e) => {
        getGeneralStatementDetail(e.data.statementNo, 'modalGridList');
        AppStore.toggleModal();
    }

    /* 전표 상세보기 */
    const getGeneralStatementDetail = async (statementNo, gridText) => {

        const result = await axios.get('/statement/general/detail', {params: {statementNo: statementNo}});

        if (gridText === 'subGridList') {
            GeneralStatementStore.subStatementList = result;
        } else if (gridText === 'modalGridList'){
            GeneralStatementStore.setGeneralStatementList(result);
            GeneralStatementStore.setData({remark: result[0].remark, resolutionDate: result[0].resolutionDate, postingDate: result[0].postingDate,
                approvalStatus: result[0].approvalStatus, statementType: result[0].statementType, statementStatus: result[0].statementStatus,
                managementPart: result[0].managementPart, statementNo: result[0].statementNo, seq: '', billNo: result[0].billNo});
        }
    }

    return (
        <>
            <GeneralStatementListSearch getStatementList={getStatementList}/>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={40}
                    gridTitle={'일반전표'}
                    appendTitle={sumStatementInputForm()}   // 전표금액, 대차차액
                    rowData={GeneralStatementStore.statementList}
                    columnDefs={
                        [
                            // {field: "statementSeq", headerName: "순번", valueGetter: 'node.rowIndex + 1', width: 60, minWidth: 60},
                            {field: "companyCode", headerName: "회사명", width: 130, valueFormatter: v => findCommKrnm(v.value, '20')},
                            {field: "statementNo", headerName: "전표번호", minWidth: 135},
                            {field: "approvalStatus", headerName: "결재", valueFormatter: v => !v.value || v.value === '1' ? '미결' : '승인', width: 70, minWidth: 60, cellClass: v => v.value === '2' ? 'ag-grid-column-complete' : ''},
                            {field: "statementType", headerName: "전표유형", valueFormatter: v => findCommKrnm(v.value, '41'), minWidth: 90},
                            {field: "statementCategory", headerName: "구분", valueFormatter: v => v.value === '1' ? '대체' : '', minWidth: 60},
                            {field: "resolutionDate", headerName: "결의일자", minWidth: 120},
                            {field: "postingDate", headerName: "기표일자", minWidth: 120},
                            {field: "amount", headerName: "전표금액", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(), minWidth: 120},
                            {field: "difference", headerName: "대차차액", cellClass: 'ag-grid-money-align', valueFormatter: v => v.value ? ( v.value === 0 ? '' : v.value.toLocaleString() ) : '', minWidth: 90},
                            {field: "remark", headerName: "전표설명", minWidth: 200},
                            {field: "creator", headerName: "입력사원seq", minWidth: 200, hide: true},
                            {field: "creatorName", headerName: "입력사원", minWidth: 90},
                            // {field: "departmentSeq", headerName: "입력부서", valueFormatter: v => findCommKrnm(String(v.value), '33'), minWidth: 100},
                            {field: "managementItem", headerName: "관리항목", flex: 1},
                        ]
                    }
                    isCheckBox={true}
                    customBtnInfo={[approvalBtn, deleteBtn]}
                    onRowClicked={e => onRowClickedEvent(e)}
                    useRowSearch={true}
                    rowSearchCallback={e => onRowDoubleClicked(e)}
                    rowDoubleClickCallback={e => onRowDoubleClicked(e)}

                />
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridRef={subGridRef}
                    gridTitle={'전표상세'}
                    appendTitle={generalStatementInputForm()}   // 대변, 차변
                    height={30}
                    rowData={GeneralStatementStore.subStatementList}
                    columnDefs={[
                        {field: "statementNo", headerName: "전표번호", minWidth: 60, hide: true},
                        // {field: "statementSeq", headerName: "순번", width: 60, minWidth: 60},
                        {field: "seq", headerName: "순번", valueGetter: 'node.rowIndex + 1', width: 70},
                        {
                            field: "accountType",
                            headerName: "구분",
                            valueFormatter: v => v.value === '1' ? '차변' : v.value === '2' ? '대변' : v.value === '' ? '' : '기타',
                            width: 70, minWidth: 70
                        },
                        {field: "accountCode", headerName: "계정코드", minWidth: 90},
                        {field: "accountCodeName", headerName: "계정과목", minWidth: 130, width: 170},
                        {field: "customerNo", headerName: "거래처코드", minWidth: 90, width: 130},
                        {field: "customerName", headerName: "거래처명", width: 160, minWidth: 160},
                        {field: "amount", headerName: "차변", cellClass: 'ag-grid-money-align', valueFormatter: v => v.data.accountType === '1' ? v.value?.toLocaleString() : '', minWidth: 110, width: 150},
                        {field: "amount", headerName: "대변", cellClass: 'ag-grid-money-align', valueFormatter: v => v.data.accountType === '2' ? v.value?.toLocaleString(): '', minWidth: 110, width: 150},
                        {field: "summary", headerName: "적요", minWidth: 335, flex: 1},
                    ]}
                    getRowStyle={(params) => {
                        if (params.data.isDel === 1) {
                            return { background: 'lightgray', pointerEvents: 'none', color: '#bababa' };
                        }
                        return null;
                    }}
                />
            </CContainer>
            <ApprovalPostingDatePopup getStatementList={getStatementList}/>
            <PasswordCheckPopup callbackFn={callbackFn}/>
            <Modal title={'전표상세'}>
                <GeneralStatementDetail 
                    getStatementList={getStatementList} 
                />
            </Modal>
        </>
    );
}

export default observer(GeneralStatementList);
