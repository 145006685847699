import { useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { CContainer } from '../../components/CustomContainer';
import AgGridContainer from '../../components/AgGridContainer';
import { AssetExpirationStore } from '../../store/asset/AssetExpirationStore';
import { today, dateDiff } from '../../utils/dateUtils';
import { callAlert } from '../../utils';
import { findCommKrnm } from "../../utils/commcode";
import { AppStore } from "../../store/AppStore";
import DelayPopup from './popup/DelayPopup';
import ShortenPopup from './popup/ShortenPopup';
import {ICON_EXTEND, ICON_FINISH} from "../../common/constants";
import AssetExpirationListSearch from './AssetExpirationListSearch';
import {getUserName} from "../../utils/userUtils";
import Modal from '../../components/Modal';
import ReturnAskDetail from '../return/ReturnAskDetail';
import { ReturnAskListStore } from '../../store/return/ReturnAskListStore';
import { showToast } from '../../common/utils';
import SendSmsPopup from './popup/SendSmsPopup';

const AssetExpirationList = () => {
    const gridRef = useRef();
    const [contractNo, setContractNo] = useState('');
    const [originList, setOriginList] = useState([]);
    const [assetList, setAssetList] = useState([]);
    const [receiverName, setReceiverName] = useState('');
    const [receiverPhoneNumber, setReceiverPhoneNumber] = useState('');
    const [sendAssetList, setSendAssetList] = useState([]);

    const columnDefs = [
        {field: "customerName", headerName: "고객명", width: 150},
        {field: "billingAddressName", headerName: "청구지명", width: 150},
        {field: "siteName", headerName: "현장명", width: 150},
        {field: "assetNo", headerName: "자산번호", minWidth: 120, hide: true},
        {field: "managementNo", headerName: "관리번호", minWidth: 120},
        {field: "contractEndDate", headerName: "만기일자", width: 130},
        {
            field: "durationDay", 
            headerName: "만기지연일수",  
            width: 130, 
            valueGetter: v => v.data.contractEndDate ? dateDiff(v.data.contractEndDate, today()) > 0 ? dateDiff(v.data.contractEndDate, today()) : 0 : null,
            cellClass: v =>  v.data.contractEndDate ? dateDiff(v.data.contractEndDate, today()) > 0 ? 'ag-grid-column-cancel' : 0 : null
        },
        {field: "contractNo", headerName: "계약번호", minWidth: 160},
        {field: "contractSeq", headerName: "계약회차", width: 110},
        {field: "assetStatus", headerName: "자산상태", width: 110, valueFormatter: v => findCommKrnm(v.value, '8')},
        {field: "contractAssetStatus", headerName: "계약상태", width: 110, valueFormatter: v => findCommKrnm(v.value, '2')},
        {field: "model", headerName: "모델명", minWidth: 180},
        {field: "productName", headerName: "제품명", minWidth: 200, hide: true},
        {field: "rentMonth", headerName: "대여월"},
        {field: "rentDay", headerName: "대여일"},
        {field: "monthRentPrice", headerName: "월렌탈료", width: 130, valueFormatter: v => v.value?.toLocaleString()},
        {field: "dayRentPrice", headerName: "일렌탈료", width: 110, valueFormatter: v => v.value?.toLocaleString(), hide: true},
        {field: "lastMonthPrice", headerName: "마지막달 요금", valueFormatter: v => v.value?.toLocaleString(), minWidth: 120, hide: true},
        {field: "contractDate", headerName: "계약일자", width: 140},
        {field: "orgContractStartDate", headerName: "원 계약개시일", width: 140},
        {field: "contractStartDate", headerName: "계약개시일", width: 130},
        {field: "contractEndDate", headerName: "계약종료일", width: 130},
        {field: "returnAskSeq", headerName: "회수번호", minWidth: 80},
        {field: "returnAskStatus", headerName: "회수상태", valueFormatter: v => findCommKrnm(v.value, '25')},
        {field: "returnExpectDate", headerName: "회수예정일자", minWidth: 120},
        {field: "updater", headerName: "변경자"},
        {field: "updatedDate", headerName: "변경일자", width: 180},
        {field: "reserveStatus", headerName: "예약상태", minWidth: 110},
        {field: "reserveEndDate", headerName: "예약종료일", minWidth: 120},
        {field: "reserveUserSeq", headerName: "예약자", minWidth: 100, valueFormatter: v => getUserName(v.value)},
        {field: "productSeq", headerName: "제품시퀀스", width: 180, hide: true},
        {field: "spec", headerName: "스펙", width: 180, hide: true},
        {field: "serialNo", headerName: "시리얼번호", width: 180, hide: true},
        {field: "customerNo", headerName: "고객번호", width: 180, hide: true},
        {field: "billingAddressSeq", headerName: "고객번호", width: 180, hide: true},
    ];

    /* 회수 버튼 */
    const customReturnBtn = {
        isUsed: true,
        callbackFn: () => selectedReturnBtn(),
        icon: 'fi-br-rotate-left',
        variant: 'dark',
        width: 90,
        title: '회수',
    }

    /* 알림톡 전송 버튼 */
    const customSendSmsBtn = {
        isUsed: true,
        callbackFn: () => sendSmsBtn(),
        icon: 'fi-rr-box-check',
        width: 130,
        title: '만기 알림 발송',
        variant: 'primary'
    }

    /* 연장 버튼 */
    const customDelayBtn = {
        isUsed: true,
        callbackFn: () => selectedDelayBtn(),
        icon: ICON_EXTEND,
        title: '연장',
    }

    /* 단축 버튼 */
    const customShortenBtn = {
        isUsed: true,
        callbackFn: () => selectedShortenBtn(),
        icon: ICON_FINISH,
        title: '단축',
    }

    const sendSmsBtn = () => {
        const selectedList = AssetExpirationStore.expirationList.filter(v => v.isSelected);
        if(selectedList.length === 0) { showToast('자산을 선택해주세요.'); return; }

        const validBillingAddress = selectedList.every(v => v.customerNo === selectedList[0].customerNo && v.billingAddressSeq === selectedList[0].billingAddressSeq);
        if (!validBillingAddress) {
            showToast('청구지가 같은 자산만 선택해주세요.'); 
            return;
        }
        setReceiverName(selectedList[0].managerName);
        setReceiverPhoneNumber(selectedList[0].phoneNumber);
        const result = selectedList.reduce((acc, curr) => {
            const { contractEndDate, assetNo, model } = curr;
            
            // 이미 해당 date가 존재하는지 확인
            const existing = acc.find(item => item.contractEndDate === contractEndDate);
            
            if (existing) {
              // 중복된 assetNo가 아닌 경우만 추가
              if (!existing.assetNos.includes(assetNo)) {
                existing.assetNos.push(assetNo);
                existing.models.push(model);
              }
            } else {
              acc.push({ contractEndDate, assetNos: [assetNo], models: [model] });
            }
            
            return acc;
        }, []);
        setSendAssetList(result);
        AppStore.toggleExpirationAssetSmsPopup();
    }

    const selectedReturnBtn = () => {
        const selectedList = AssetExpirationStore.expirationList.filter(v => v.isSelected);

        if(selectedList.length === 0) { showToast('자산을 선택해주세요.'); return; }

        const validContractNo = selectedList.every(v => v.contractNo === selectedList[0].contractNo);

        if(!validContractNo) { showToast('계약번호가 같은 자산만 선택해주세요.'); return; }

        setContractNo(selectedList[0].contractNo);
        setAssetList(selectedList);

        AppStore.toggleModal();
    }

    const selectedDelayBtn = async() => {
        const params = {
            list: AssetExpirationStore.expirationList.filter(v => v.isSelected === true).map(v => v.assetNo)
        };

        const contractNos = new Set();
        AssetExpirationStore.expirationList.forEach(v => {
            if(v.isSelected === true) contractNos.add(v.contractNo);
        });

        const list = AssetExpirationStore.expirationList
                    .filter(v => v.isSelected === true)
                    .map(v => ({ ...v }));
        setAssetList(list);

        if(contractNos.size > 1) await callAlert("계약번호가 같은 자산만 선택해주세요.");
        else AppStore.toggleDelayPopup();
    }

    const selectedShortenBtn = async() => {
        const contractNos = new Set();
        AssetExpirationStore.expirationList.forEach(v => {
            if(v.isSelected === true) contractNos.add(v.contractNo);
        });

        const list = AssetExpirationStore.expirationList
                    .filter(v => v.isSelected === true)
                    .map(v => ({ ...v }));
        setAssetList(list);

        if(contractNos.size > 1) await callAlert("계약번호가 같은 자산만 선택해주세요.");
        else AppStore.toggleShortenPopup();
    }

    /* 팝업 닫을 때 회수의뢰 초기화 */
    const closedModal = () => {
        ReturnAskListStore.init();
    };

    const pinnedTopRowData = [{
        durationDay: '',
        monthRentPrice: AssetExpirationStore.expirationList.reduce((total, v) => total + v.monthRentPrice, 0)
    }];

    return (
        <>
            {/* 검색조건 */}
            <AssetExpirationListSearch setOriginList={setOriginList} />
            <DelayPopup assetList={assetList}/>
            <ShortenPopup assetList={assetList}/>

            <CContainer>
                {/* grid */}
                <AgGridContainer
                    gridTitle={'만기자산 목록'}
                    gridRef={gridRef}
                    height={60}
                    rowData={AssetExpirationStore.expirationList}
                    columnDefs={columnDefs}
                    useCsvDownload={true}
                    originList={originList}
                    isCheckBox={true}
                    customBtnInfo={[customReturnBtn, customSendSmsBtn]}
                    pinnedTopRowData={pinnedTopRowData}
                    //customBtnInfo={[customDelayBtn, customShortenBtn]}
                />
                <Modal title={'회수의뢰'} onExit={closedModal}>
                    <ReturnAskDetail
                        assetList={assetList}
                        contractNo={contractNo}
                        setContractNo={setContractNo}
                        isExpirationAsset={true}
                    />
                </Modal>
                <SendSmsPopup receiverName={receiverName} setReceiverName={setReceiverName} receiverPhoneNumber={receiverPhoneNumber} setReceiverPhoneNumber={setReceiverPhoneNumber} sendAssetList={sendAssetList}/>
            </CContainer>
        </>
    );
};

export default observer(AssetExpirationList);
