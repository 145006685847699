import { useRef } from "react";
import { observer } from "mobx-react-lite";
import SignatureCanvas from 'react-signature-canvas';
import { Row, Col } from "react-bootstrap";
import axios from 'axios';

import { CContainer, CSaveBtn, CCancelBtn } from '../../../components/CustomContainer';
import { MULTIPART_HEADER } from "../../../common/constants";
import { AppStore } from "../../../store/AppStore";
import {showToast} from "../../../common/utils";
import "./signaturePopupStyle.css";

const SignaturePopup = ({ shipSeq, getShipList, getShipData }) => {
  const signatureRef = useRef();

  const clearSignature = () => {
    signatureRef.current.clear();
  }

  const handleSave = async () => {
    const canvas = signatureRef.current.getTrimmedCanvas();
    const image = canvas.toDataURL('image/png');

    const formData = new FormData();
    const file = dataURLtoFile(image, 'sign_image.png');
    formData.append('file', file);
    formData.append('shipSeq', shipSeq);

    const result = await axios.put('ship/signature/upload', formData, { headers: MULTIPART_HEADER });
    showToast('저장 되었습니다.');
    Promise.all([getShipList(), getShipData()]);

    AppStore.toggleModal();
  };

  function dataURLtoFile(dataURL, filename) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  return (
    <CContainer>
      <div className="popup">
        <Row style={{ border: '1px solid black', backgroundColor: '#fff' }}>
          <div className="signatureContainer">
            <div className="canvasContainer">
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{className: 'sigCanvas canvasStyle'}}
                clearOnResize={false}
                throttle={10}
              />
            </div>
          </div>
        </Row>
        <Row>
          <Col className='d-flex flex-row-reverse mt-4'>
            <CSaveBtn onClick={handleSave} />
            <CCancelBtn onClick={clearSignature} title={'초기화'}/>
          </Col>
        </Row>
      </div>
    </CContainer>
  );
};

export default observer(SignaturePopup);
