import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const WorkLogStore = observable({
    startDate: { startDate:'', endDate: '' },
    endDate: { startDate:'', endDate: '' },
    userSeq: '',
    userName: '',
    customerName: '',
    contents: '',
    workType: '',
    isComplete: 0,
    workLogList: [],

    setStartDate(v){
        this.startDate = v;
    },
    setEndDate(v){
        this.endDate = v;
    },
    setUserSeq(v){
        this.userSeq = v;
    },
    setUserName(v){
        this.userName = v;
    },
    setCustomerName(v){
        this.customerName = v;
    },
    setContents(v){
        this.contents = v;
    },
    setWorkType(v) {
        this.workType = v;
    },
    setIsComplete(v){
        this.isComplete = v;
    },
    setWorkLogList(v){
        this.workLogList = v;
    }
});
