import { observable } from "mobx";
import { oneMonthAgo, today } from "../../utils/dateUtils";


export const  AccountStore = observable({

    isMiscProfitBtnText: '처리',
    accountNumberList: [],
    accountList: [],
    search: {
        startDate: oneMonthAgo(),
        endDate: today(),
        accountSeq: '',
        companyCode: '',
        customerNo: '',
        customerName: '',
        bankCode: '',
        accountNumber: '',
        minimumAmount: '',
        maximumAmount: '',
        summary: '',
        accountType: '1',
        isMiscProfit: 0,
    },
    accountGroupList: [],

    setAccountList(v) {
        this.accountList = v;
    },
});