import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { Container, Col, Row } from "react-bootstrap";

import { CContainer } from '../../components/CustomContainer';
import AgGridContainer from "../../components/AgGridContainer";
import { showToast } from "../../common/utils";
import {findCommKrnm} from "../../utils/commcode";
import CardManagementSearch from "./CardManagementSearch";
import CardManagementInfo from "./CardManagementInfo";
import { CardManagementStore } from "../../store/accounting/CardManagementStore";

const CardManagementList = () => {
    const store = CardManagementStore;
    const gridRef = useRef();
    const [cardList, setCardList] = useState([]);

    useEffect(() => {
        setCardList(store.cardList);
    }, []);

    const columnDefs = [
        { field: "cardManagementSeq", headerName: "카드관리 seq", hide: true },
        { field: "companyCode", headerName: "회사구분", hide:true},
        { field: "cardCode", headerName: "카드사", width: 120, valueFormatter: v => findCommKrnm(v.value, '44')},
        { field: "cardNumber", headerName: "카드번호", width: 180  },
        { field: "cardAlias", headerName: "별칭", width: 120 },
        { field: "companyCode", headerName: "회사코드", hide: true },
        { field: "remark", headerName: "비고", minWidth: 180, flex: 1},
    ];

    /* 카드 목록 조회 */
    const getCardList = async () => {
        const result = await axios.get('/card/management/list', { params: store.search });
        if (result.length === 0) {
            showToast('조회된 데이터가 없습니다.');
        }
        store.cardList = result;
        setCardList(result);
    }

    /* 카드 상세 조회 */
    const selectCardDetail = async (v) => {
        store.data = v.data;
    }

    return (
        <>
            {/* 검색조건 */}
            <CardManagementSearch getCardList={getCardList} />
            <Container fluid>
                <Row>
                    {/* 회원 리스트 */}
                    <Col lg={6}>
                        <CContainer>
                            <AgGridContainer
                                girdTitle='회원목록'
                                gridRef={gridRef}
                                height={60}
                                rowData={cardList}
                                columnDefs={columnDefs}
                                isCheckBox={false}
                                rowSearchCallback={selectCardDetail}
                                rowDoubleClickCallback={selectCardDetail}
                            />
                        </CContainer>
                    </Col>

                    {/* 카드 상세 조회 */}
                   <CardManagementInfo getCardList={getCardList} />
                </Row>
            </Container>
        </>
    );
};

export default observer(CardManagementList);
