import { observable } from "mobx";

export const ProductBrandStore = observable({
    lgCodeList: [],
    brandList: [],

    setLgCodeList(v) {
        this.lgCodeList = v;
    },
    
    setBrandList(v) {
        this.brandList = v;
    },
});