import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {Row, Col, Form} from "react-bootstrap";

import { CContainer, CInputGroup, CCol, CSearchBtn, CIconBtn, CSelectGroup, CalendarGroup } from '../../components/CustomContainer';
import { OrderStore } from '../../store/product/OrderStore';
import { AppStore } from '../../store/AppStore';
import { startDateForThisMonth, endDateForThisMonth } from '../../utils/dateUtils';
import { getCommcode } from '../../utils/commcode';
import UserSearch from '../../components/searchModal/UserSearch';

const OrderList = ({getOrderList, setModalState, setOrderData}) => {

    useEffect(() => {
        const initSearchCondition = {
            startDate: startDateForThisMonth(),
            endDate: endDateForThisMonth(),
        }
        OrderStore.setOrderSearchCondition(initSearchCondition);

        // 발주자 value 담아주기
        const userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
        OrderStore.orderSearchCondition.orderUserName = userInfo.name;  // 발주자명
        OrderStore.orderSearchCondition.orderUserSeq = userInfo.userSeq;  // 발주자 시퀀스
    }, []);

    /* 발주자 value 담아주기 */
    const changeInput = v => {
        OrderStore.orderSearchCondition.orderUserSeq =  null;
        OrderStore.orderSearchCondition.orderUserName =  v.target.value;
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = () => {
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        OrderStore.setOrderSearchCondition({orderUserSeq: userInfo.userSeq, orderUserName: userInfo.name});
    }

    /* input value 담아주기 */
    const onChangeValue = (v, label) => {
        OrderStore.setOrderSearchCondition({[label]: v.target.value});
    }

    const openOrderDetail = (state) => {
        if(state === 'create'){
            setModalState('발주등록');
            setOrderData([]);
        }else {
            setModalState('발주관리');
            setOrderData(state.data);
        }
        AppStore.toggleModal();
    }

    return (
        <>
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'발주번호'} labelId={'orderSeq'} placeholder={'발주번호'} value={OrderStore.orderSearchCondition.orderSeq} onChange={v => onChangeValue(v, 'orderSeq')} />
                    </CCol>
                    <CCol lg={2}>
                        <CSelectGroup
                            label={'발주구분'}
                            value={OrderStore.orderSearchCondition.orderType || ''}
                            onChange={v => onChangeValue(v, 'orderType')}
                            options={[{value:'', name:'전체'}].concat(getCommcode('36'))}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'발주일'}
                            value={{startDate: OrderStore.orderSearchCondition.startDate, endDate: null}}
                            onChange={v => OrderStore.orderSearchCondition.startDate = v.startDate}
                        />
                    </CCol>
                    ~
                    <CCol lg={2}>
                        <CalendarGroup
                            asSingle={true}
                            label={'발주일'}
                            value={{startDate: OrderStore.orderSearchCondition.endDate, endDate: null}}
                            onChange={v => OrderStore.orderSearchCondition.endDate = v.startDate}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup label={'품의번호'} labelId={'approvalNo'} placeholder={'품의번호'} value={OrderStore.orderSearchCondition.approvalNo} onChange={v => onChangeValue(v, 'approvalNo')} />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'발주자'} labelId={'orderUserName'}
                                    value={OrderStore.orderSearchCondition.orderUserName}
                                    onCallbackBtn={openSearchUserPopup}
                                    onChange={changeInput}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <Form.Check
                            type="switch"
                            id="isAvailableStorage"
                            label="입고 미완료"
                            checked={OrderStore.orderSearchCondition.isAvailableStorage}
                            style={{marginTop: 4}}
                            onChange={() => OrderStore.setOrderSearchCondition({isAvailableStorage: !OrderStore.orderSearchCondition.isAvailableStorage})}
                        />
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getOrderList} />
                        <CIconBtn style={{width: 100, height: 30}}
                                    title={'발주등록'}
                                    icon={'fi-rr-box-check'}
                                    onClick={() => openOrderDetail('create')}
                        />
                    </Col>
                </Row>
            </CContainer>
            <UserSearch callbackFn={getUserInfo} />
        </>

    );
};

export default observer(OrderList);
