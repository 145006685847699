import React from "react";
import { observer } from "mobx-react-lite";
import { Row, Col } from "react-bootstrap";
import { PaybackStore } from "../../store/accounting/PaybackStore";
import { CCol, CContainer, CalendarGroup, CInputGroup, CSearchBtn, CInputDoubleGroup, RadioButtonGroupTrueFalse, CAddBtn } from "../../components/CustomContainer";
import { AppStore } from "../../store/AppStore";

const PaybackListSearch = ({getPaybackList, setCustomerKind}) => {
    const store = PaybackStore;

    return (
        <CContainer search>
            <Row>
                <CCol lg={2}>
                    <CalendarGroup label={'시작일'}
                                   asSingle={true}
                                   value={{
                                       startDate: store.search.startDate,
                                       endDate: store.search.startDate
                                   }}
                                   onChange={v => {store.search.startDate = v.startDate}}
                    />
                </CCol>
                <CCol lg={2}>
                    <CalendarGroup label={'종료일'}
                                   asSingle={true}
                                   value={{
                                       startDate: store.search.endDate,
                                       endDate: store.search.endDate
                                   }}
                                   onChange={v => {store.search.endDate}}
                    />
                </CCol>
                <CCol lg={3}>
                        <CInputDoubleGroup label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                                            placeholder={'고객코드'} placeholder2={'고객명'}
                                            onCallbackBtn={() => {setCustomerKind('search'), AppStore.toggleCustomerSearchModal()}}
                                            value={store.search.customerNo} value2={store.search.customerName}
                                            onChange={v => store.search.customerNo = v.target.value}
                                            onChange2={v => store.search.customerName = v.target.value}
                                            enterKeyHint1={()=> getPaybackList()}
                                            enterKeyHint2={()=> getPaybackList()}
                        />
                </CCol>
                <CCol lg={3}>
                    <RadioButtonGroupTrueFalse
                                    name={'isPayback'}
                                    label={'상환여부'}
                                    btnText={['상환예정', '상환']}
                                    value={store.search.isPayback === null ? '' : store.search.isPayback === 0 ? true : false}
                                    onClick={e => e === '' ?  store.search.isPayback = null : e === true ? store.search.isPayback = 0 : store.search.isPayback = 1}
                    />
                </CCol>
            </Row>
            <Row>
                <CCol lg={3}>
                    <CInputGroup
                        label={'비고'} labelId={'remark'}
                        value={store.search.remark}
                        onChange={v => store.search.remark = v.target.value}
                        enterKeyHint={()=> getPaybackList()}
                    />
                </CCol>
            </Row>
            <Row>
                <Col className='d-flex flex-row-reverse'>
                    <CSearchBtn onClick={getPaybackList} />
                    <CAddBtn onClick={() => AppStore.toggleModal()}/>
                </Col>
            </Row>
        </CContainer>
    );
}

export default observer(PaybackListSearch);
