import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration.js';
import {showToast} from "../common/utils";

const initDayjs = () => {
    dayjs.extend(duration);
};

const today = () => dayjs().format("YYYY-MM-DD");
const todayForDateTimes = () => dayjs().format("YYYY-MM-DD HH:mm:ss");
const getTime = () => dayjs().format("HH:mm:ss");
const getYear = () => dayjs().format("YYYY");
const getTodayMonth = () => dayjs().format("MM");
const getTodayDay = () => dayjs().format("DD");
const getMonthOfToday = () => dayjs().format("MM");
const dateFormat = (date) => !!date ? dayjs(date).format("YYYY-MM-DD") : null;
const dateTimeFormat = (date) => !!date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : null;
const startDateForThisMonth = () => dayjs().startOf('month').format("YYYY-MM-DD");                             /* 현재 월의 시작일 */
const endDateForThisMonth = () => dayjs().endOf('month').format("YYYY-MM-DD");                                 /* 현재 월의 종료 */
const oneMonthAgo = () => dayjs().subtract(1, 'month').add(1, 'day').format('YYYY-MM-DD');                     /* 한 달 전 */
const oneMonthAfter = (date) => dayjs(date).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD');           /* 한 달 후 */
const endOfMonth = (month) => dayjs().month(month - 1).endOf('month').format("DD");                            /* 해당 월의 마지막날 */
const addMonth = (date, month) => !!date ? dayjs(date).add(month, 'month').format("MM") : null;                /* month 더하기 */
const addMonthYear = (date, month) => !!date ? dayjs(date).add(month, 'month').format("YYYY-MM") : null;       /* month 더하기 (YYYY-MM) */
const addMonthDate = (date, month) => !!date ? dayjs(date).add(month, 'month').format("YYYY-MM-DD") : null;       /* month 더하기 (YYYY-MM-DD) */
const addDay = (date, day) => !!date ? dayjs(date).add(day, 'day').format("DD") : null;                         /* day 더하기 */
const addDayDate = (date, day) => !!date ? dayjs(date).add(day, 'day').format("YYYY-MM-DD") : null;                /* day 더하기 */
const anyMonthAgo = (date, month) => dayjs(date).subtract(month, 'month').add(1, 'day').format('YYYY-MM-DD');  /* date 기준 month 달 전 */
const dateDiff = (standardDate, targetDate) => {return dayjs.duration(dayjs(targetDate).diff(dayjs(standardDate))).asDays();}           /* 날짜 비교 (일) */
const dateDiffMonth = (standardDate, targetDate) => {return dayjs.duration(dayjs(targetDate).diff(dayjs(standardDate))).asMonths();}    /* 날짜 비교 (월) */
const endOfAddMonth = (date, month) => dayjs(date).add(month, 'month').endOf('month').format("YYYY-MM-DD");    /* 추가한 월의 마지막날 */
const daysFromToday = (days) => dayjs().add(days, 'day').format("YYYY-MM-DD");                                 /* 오늘로부터 days일 후 */
const yesterday = () => dayjs().subtract(1, 'day').format('YYYY-MM-DD');                                        /* 어제 */
const yearMonthFormat = (date) => !!date ? dayjs(date).format("YYYY-MM") : null;                                /* YYYY-MM format */
const dayAgo = (date) => dayjs(date).subtract(1, 'day').format("YYYY-MM-DD");                                   /* 하루 전 */
const minusDay = (day) => dayjs().subtract(day, 'day').format("YYYY-MM-DD");                                    /* day 빼기 */
const startDateForThisYear = () => dayjs().startOf('year').format("YYYY-MM-DD");                                /* 현재 년의 시작일 */
const endDateForThisYear = () => dayjs().endOf('year').format("YYYY-MM-DD");                                    /* 현재 년의 종료일 */
const startDateForLastYear = (date) => dayjs(date).subtract(1, 'year').startOf('year').format("YYYY-MM-DD");    /* 현재 년의 전년시작일 */
const endDateForLastYear = (date) => dayjs(date).subtract(1, 'year').endOf('year').format("YYYY-MM-DD");        /* 현재 년의 전년종료일 */
const startDateForNextYear = (date) => dayjs(date).add(1, 'year').startOf('year').format("YYYY-MM-DD");         /* 현재 년의 후년시작일 */
const endDateForNextYear = (date) => dayjs(date).add(1, 'year').endOf('year').format("YYYY-MM-DD");             /* 현재 년의 후년종료일 */
const startDateForLastMonth = (date) => dayjs(date).subtract(1, 'month').startOf('month').format("YYYY-MM-DD"); /* 현재 월의 전월시작일 */
const endDateForLastMonth = (date) => dayjs(date).subtract(1, 'month').endOf('month').format("YYYY-MM-DD");     /* 현재 월의 전월종료일 */
const startDateForNextMonth = (date) => dayjs(date).add(1, 'month').startOf('month').format("YYYY-MM-DD");      /* 현재 월의 후월시작일 */
const endDateForNextMonth = (date) => dayjs(date).add(1, 'month').endOf('month').format("YYYY-MM-DD");          /* 현재 월의 후월종료일 */
const getYearAndMonth = (date) => dayjs(date).format("YYYY-MM");
const startDateForMonth = (date) => dayjs(date).startOf('month').format("YYYY-MM-DD");                              /* 해당 월의 시작일 */
const endDateForMonth = (date) => dayjs(date).endOf('month').format("YYYY-MM-DD");                                  /* 해당 월의 종료일 */
const getMonth = (date) => dayjs(date).format("MM");
const getDay = (date) => dayjs(date).format("DD");
const getPaymentDate = (date) => dayjs(date).add(1, 'month').startOf('month').add(9, 'day').format("YYYY-MM-DD");   /* date 다음 급여 지급일자 */
const getStringFormat = (date) => dayjs(date).format("YYYYMMDD");

const calculateMonthAndDaysDifference = (startDate, endDate) => {
    startDate = dayjs(startDate);
    endDate = dayjs(endDate);

    // 개월 수 계산
    let months = endDate.diff(startDate, 'month');
    // 남은 일 수 계산
    let days = endDate.diff(startDate.add(months, 'month'), 'day');

    // startDate의 월과 endDate의 월이 같은 경우에 대한 처리
    if (startDate.format('YYYY-MM') === endDate.format('YYYY-MM')) {
        months = 0;
    }

    // endDate의 날짜가 startDate보다 작으면 남은 일수를 음수로 표시
    if (endDate.date() < startDate.date()) {
        days = endDate.date() + (startDate.endOf('month').date() - startDate.date()) + 1;
    }

    return { months, days };
};

const dateDiffDetailed = (standardDate, targetDate) => {        /* 날짜 차이 계산 */
    const diffDuration = dayjs.duration(dayjs(targetDate).diff(dayjs(standardDate)));
    const years = diffDuration.years();
    const months = diffDuration.months();
    const days = diffDuration.days();

    return { years, months, days };
};

const getDays =()=> {
    const days = [];
    for(let i = 0; i <= 30; i++){
        days.push({text: i === 0 ? '말일' : i+'일', value: i});
    }
    return days;
}

const getHour =()=> {
    return dayjs().format("HH").toString();
}

const getInputTime =()=> {
    let date = new Date();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    return hour + minute + second;
}

const dateFormatCheck =(date)=> {
    let datetimeRegexp = /[0-9]{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])/;

    if (!datetimeRegexp.test(date)) {
        showToast("날짜는 yyyy-mm-dd 형식으로 입력해주세요.");
        return false;
    }
    return true;
}

const dateKoreaFormat =(date)=> {
    if(!date) return;
    const dateSplit = date.split('-');
    return `${dateSplit[0]}년 ${dateSplit[1]}월 ${dateSplit[2]}일`;
}

export {
    initDayjs,
    today,
    todayForDateTimes,
    getYear,
    getTodayMonth,
    getTodayDay,
    getMonthOfToday,
    dateFormat,
    dateTimeFormat,
    startDateForThisMonth,
    endDateForThisMonth,
    oneMonthAgo,
    oneMonthAfter,
    getDays,
    getTime,
    endOfMonth,
    addMonth,
    addMonthYear,
    addMonthDate,
    addDay,
    addDayDate,
    anyMonthAgo,
    endOfAddMonth,
    dateDiff,
    getHour,
    daysFromToday,
    yesterday,
    yearMonthFormat,
    getInputTime,
    dayAgo,
    minusDay,
    dateFormatCheck,
    startDateForThisYear,
    endDateForThisYear,
    startDateForLastYear,
    endDateForLastYear,
    startDateForNextYear,
    endDateForNextYear,
    startDateForLastMonth,
    endDateForLastMonth,
    startDateForNextMonth,
    endDateForNextMonth,
    getYearAndMonth,
    getMonth,
    getDay,
    dateDiffMonth,
    getPaymentDate,
    dateDiffDetailed,
    dateKoreaFormat,
    startDateForMonth,
    endDateForMonth,
    calculateMonthAndDaysDifference,
    getStringFormat,
}
