import React, {useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {Row} from "react-bootstrap";
import axios from 'axios';

import { CContainer } from "../../components/CustomContainer";
import { getCommcode, findCommKrnm } from "../../utils/commcode";
import AgGridContainer from "../../components/AgGridContainer";
import {showToast} from "../../common/utils";
import CardListSearch from "./CardListSearch";
import { CardStore } from "../../store/accounting/CardStore";
import { callConfirm } from "../../utils";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import { AppStore } from "../../store/AppStore";
import { IconBtn } from '../../components/CustomContainer';
import LUtils from '../../utils/lodashUtils';

const CardList =()=> {
    const gridRef = useRef();
    const store = CardStore;
    const [gridData, setGridData] = useState([]);
    const [customerKind, setCustomerKind] = useState('');

    const getCardList = async ()=> {
        const result = await axios.get("card/approval/list", {params: store.search});
        store.cardList = result;
        if(result.length === 0) showToast('조회된 데이터가 없습니다.');
    }

    /* 저장버튼 클릭 이벤트 */
    const callBackGridData = async (gridData) => {
        // 저장 확인
        if (!await callConfirm('저장 하시겠습니까?')) return;

        // 수정
        if(gridData.updatedList.length > 0) {
            await axios.post('/card/approval', {cardApprovalList: gridData.updatedList});
        }
        showToast('저장되었습니다.');
        await getCardList();
    }

    /* 고객 팝업 UI */
    const popupCustomerInfo = (e) => {
        return (
            <div className='d-flex justify-content-between'>
                <div>{e.data.customerName}</div>
                <IconBtn key={e.rowIndex} onClick={() => {setGridData(e), setCustomerKind('grid'), AppStore.toggleCustomerSearchModal()}} />
            </div>
        )
    }

    /* 고객 팝업 callback */
    const searchCustomerInfo =(data)=> {
        if(data && customerKind === 'grid') {
            const result = store.cardList.map(v => {
                if(v.cardApprovalSeq === gridData.data.cardApprovalSeq){
                    v.customerNo = data.customerNo;
                    v.customerName = data.customerName;
                    LUtils.assign(v, {isUpdated: true});
                }
                return v;
            });
            store.cardList = result;
        }else if(data && customerKind === 'search'){
            store.search.customerNo = data.customerNo;
            store.search.customerName = data.customerName;
        }
    }

    return(
        <>
            {/* 검색조건 */}
            <CardListSearch getCardList={getCardList}/>
            <CContainer>
                <Row>
                    <AgGridContainer
                        gridRef={gridRef}
                        gridTitle={'카드 승인 내역'}
                        height={64}
                        rowData={store.cardList}
                        columnDefs={[
                            {field: "cardNumber", headerName: "카드번호", width: 180},
                            {field: "cardAlias", headerName: "별칭", width: 120},
                            {field: "approvalDate", headerName: "승인일자", width: 120},
                            {field: "approvalTime", headerName: "승인시간", width: 120, valueFormatter: v => `${v.value.substring(0, 2)}:${v.value.substring(2, 4)}:${v.value.substring(4, 6)}`},
                            {
                                field: "cardCode",
                                headerName: "카드사",
                                valueFormatter: v => findCommKrnm(v.value, '44'),
                                cellEditorParams: {
                                    values: getCommcode('44').map(v => v.value),
                                },
                                minWidth: 120
                            },
                            {field: "approvalAmount", headerName: "승인금액", minWidth: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                            {field: "paymentType", headerName: "일시불/할부", minWidth: 110, valueFormatter: v => v.value === 0 ? '일시불' : '할부'},
                            {field: "installmentMonth", headerName: "할부개월", minWidth: 90},
                            {
                                field: "isDeduction",
                                headerName: "공제여부",
                                valueFormatter: v => findCommKrnm(v.value, '47'),
                                cellEditor: 'agSelectCellEditor',
                                cellEditorParams: {
                                    values: getCommcode('47').map(v => v.value),
                                },
                                headerClass: 'grid-column-editable',
                                minWidth: 90,
                                editable: true
                            },
                            {field: "customerName", headerName: "고객명", minWidth: 160, headerClass: 'grid-column-editable', cellRenderer: e => popupCustomerInfo(e)},
                            {field: "storeName", headerName: "가맹점명", minWidth: 200, hide: store.isShort},
                            {field: "storeBusinessNumber", headerName: "가맹점 사업자번호", minWidth: 200, hide: store.isShort},
                            {field: "storeNumber", headerName: "가맹점 번호", minWidth: 200, hide: store.isShort},
                            {field: "storeType", headerName: "가맹점 업종", minWidth: 200, hide: store.isShort},
                            {field: "storeAddress", headerName: "가맹점 주소", minWidth: 200, hide: store.isShort},
                            {field: "remark", headerName: "비고", minWidth: 200, editable: true},
                        ]}
                        useCsvDownload={true}
                        isCheckBox={true}
                        useUpdated={true}
                        callBackGridData={callBackGridData}
                        isCellCustom={true}
                        getRowStyle={(params) => {
                            if (params.data.referenceKey) {
                                return { pointerEvents: 'none' };
                            }
                            return null;
                        }}
                    />
                </Row>
            </CContainer>
            <CustomerSearch callbackFn={searchCustomerInfo}/>
        </>
    );
}

export default observer(CardList);
