import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";

import Modal from "../../../components/Modal";
import AgGridContainer from "../../../components/AgGridContainer";
import { getSessionUserCompanyCode } from "../../../utils/userUtils";
import { decrypt } from "../../../common/utils";
import { AppStore } from "../../../store/AppStore";
import { callConfirm } from "../../../utils";
import { CContainer } from "../../../components/CustomContainer";
import { ShipStore } from "../../../store/ship/ShipStore";

const ShipManagerPopup =({ updateShipStatus, getShipList, isCheckedSetting, setIsCheckedSetting })=> {
    const [userList, setUserList] = useState([]);
    const [isSaved, setIsSaved] = useState(false);

    const gridRef = useRef();

    useEffect(() => {
        getShipManagerList();
    }, [isCheckedSetting])

    const getShipManagerList = async () => {
        const result = await axios.get('/user/list', { params: {isActive: 1, companyCode: getSessionUserCompanyCode(), searchType: 'ship'} });
        setUserList(result);
    }

    const selectedRow = async (e) => {
        if(!await callConfirm('일괄 저장 하시겠습니까?')) return;

        const selectedList = ShipStore.shipList.filter(v => v.isSelected);
        const shipSeqList = selectedList.map(v => v.shipSeq);
        const params = {shipList : selectedList, deliveryUserSeq: e.data.userSeq};
        await axios.put('/ship/list', params);
        await getShipList().then(() => {
            ShipStore.shipList.forEach(ship => {
                if (shipSeqList.includes(ship.shipSeq)) {
                    ship.isSelected = true;
                }
            });
        });
        setIsSaved(true);
        setIsCheckedSetting(false);
    }

    const refresh = () => {
        setUserList([]);
        setIsSaved(false);
        setIsCheckedSetting(false);
    }

    const deliveryDepartureBtn = {
        isUsed: isSaved,
        callbackFn:() => updateShip(), 
        icon: 'fi fi-br-running',
        title: '배송출발 및 문자발송',
        width: 150,
        variant: 'primary'
    }

    const updateShip = () => {
        updateShipStatus('4');
        AppStore.toggleShipManagerPopup();
    }

    return (
        <Modal
            title={'배송 담당자'}
            show={AppStore.isShipManagerPopup}
            onHide={() => AppStore.toggleShipManagerPopup()}
            toggleValue={AppStore.isShipManagerPopup}
            className={'modal-w9'}
            onExit={refresh}
        >
  
            <CContainer>
                <AgGridContainer
                    height={30}
                    gridRef={gridRef}
                    rowData={userList}
                    columnDefs={
                        [
                            {field: "id", headerName: "사용자ID", flex: 1},
                            {field: "name", headerName: "사원명", flex: 1},
                            {field: "deptName", headerName: "부서명", width: 130},
                            {field: "position", headerName: "직급", width: 110},
                            {field: "phoneNumber", headerName: "핸드폰번호", valueGetter: v => v.data.phoneNumber ? decrypt(v.data.phoneNumber) : '', flex: 1},
                            {field: "isActive", headerName: "퇴사여부", valueGetter:v => v.data.isActive ? '근속' : '퇴사', hide: true},
                            {field: "userSeq", headerName: "사용자 시퀀스", hide: true},
                        ]
                    }
                    seqColumn={'id'}
                    rowDoubleClickCallback={selectedRow}
                    customBtnInfo={[deliveryDepartureBtn]}
                />
            </CContainer>
        </Modal>
    )
}

export default observer(ShipManagerPopup);
