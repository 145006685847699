import { observer } from "mobx-react-lite";
import { CContainer, CCol, CalendarGroup, CInputGroup, CInputDoubleGroup, CSelectGroup, CIconBtn, CSearchBtn } from "../../components/CustomContainer";
import { Row, InputGroup, Form, Col } from "react-bootstrap";
import { AccountStore } from "../../store/billing/AccountStore";
import { getCommcode } from "../../utils/commcode";
import { AppStore } from "../../store/AppStore";
import axios from 'axios';
import { today } from "../../utils/dateUtils";

const AccountListSearch = ({getAccountList, setCustomerKind}) => {

    /* 거래 내역 업로드 */
    const handleAccountUpload = async () => {
        AppStore.isAxiosSetting = true;
        const result = await axios.post('/account/upload', AccountStore.search);  
        alert('실패 : ' + result.length + '개 \n' + result.join(", "));
    };

    return (
        <CContainer search>
            <Row>
                <CCol lg={2}>
                    <CalendarGroup label={'거래시작일'}
                                    asSingle={true}
                                    value={{
                                        startDate: AccountStore.search.startDate,
                                        endDate: AccountStore.search.startDate
                                    }}
                                    onChange={v => {AccountStore.search.startDate = v.startDate}}
                    />
                </CCol>
                <CCol lg={2}>
                    <CalendarGroup label={'거래종료일'}
                                    asSingle={true}
                                    value={{
                                        startDate: AccountStore.search.endDate,
                                        endDate: AccountStore.search.endDate
                                    }}
                                    maxDate={today()}
                                    onChange={v => {AccountStore.search.endDate = v.endDate}}
                    />
                </CCol>
                <CCol lg={2}>
                    <CInputGroup
                        label={'거래번호'} labelId={'accountSeq'}
                        value={AccountStore.search.accountSeq}
                        onChange={v => AccountStore.search.accountSeq = v.target.value}
                    />
                </CCol>
                <CCol lg={3}>
                    <CInputDoubleGroup
                        label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                        placeholder={'고객코드'} placeholder2={'고객명'}
                        value={AccountStore.search.customerNo} value2={AccountStore.search.customerName}
                        onCallbackBtn={() => {setCustomerKind('search'), AppStore.toggleCustomerSearchModal()}}
                        onChange={v => {AccountStore.search.customerNo = v.target.value, AccountStore.search.customerName = ''}}
                        onChange2={v => {AccountStore.search.customerName = v.target.value, AccountStore.search.customerNo = ''}}

                    />
                </CCol>
                <CCol lg={2}>
                    <CSelectGroup label={'은행'} labelId={'bankCode'}
                                    placeholder={'은행'}
                                    options={[{name: '전체', value: ''}].concat(getCommcode('24'))}
                                    value={AccountStore.search.bankCode}
                                    onChange={v => AccountStore.search.bankCode = v.target.value}
                    />
                </CCol>
            </Row>
            <Row>
                <CCol lg={2}>
                    <CSelectGroup label={'계좌번호'} labelId={'accountNumber'}
                                    options={[
                                        { name: '전체', value: '' },
                                        ...AccountStore.accountNumberList.map(v => ({ name: v, value: v })),
                                    ]}
                                    value={AccountStore.search.accountNumber}
                                    onChange={v => AccountStore.search.accountNumber = v.target.value}
                    />
                </CCol>
                <CCol lg={2}>
                    <InputGroup size={'sm'}>
                        <InputGroup.Text id={'minimumAmount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>최소금액</InputGroup.Text>
                        <Form.Control
                            style={{flex: 1, textAlign: 'right'}}
                            max={2}
                            aria-describedby={'minimumAmount'}
                            value={AccountStore.search.minimumAmount}
                            onChange={v => AccountStore.search.minimumAmount = v.target.value}
                        />
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                    </InputGroup>
                </CCol>
                <CCol lg={2}>
                    <InputGroup size={'sm'}>
                        <InputGroup.Text id={'maximumAmount'} style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>최대금액</InputGroup.Text>
                        <Form.Control
                            style={{flex: 1, textAlign: 'right'}}
                            max={2}
                            aria-describedby={'maximumAmount'}
                            value={AccountStore.search.maximumAmount}
                            onChange={v => AccountStore.search.maximumAmount = v.target.value}
                        />
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}}>원</InputGroup.Text>
                    </InputGroup>
                </CCol>
                <CCol lg={3}>
                    <CInputGroup
                        label={'적요'} labelId={'summary'}
                        value={AccountStore.search.summary}
                        onChange={(v => AccountStore.search.summary = v.target.value)}
                    />
                </CCol>
                <CCol lg={2}>
                    <CSelectGroup label={'구분'} labelId={'accountType'}
                                    options={[{name: '전체', value: ''}].concat(getCommcode('43'))}
                                    value={AccountStore.search.accountType}
                                    onChange={v => AccountStore.search.accountType = v.target.value}
                    />
                </CCol>
                <CCol lg={1}>
                    <Form.Check
                        type="switch"
                        id="isMiscProfit"
                        label="잡이익 조회"
                        style={{marginTop: 3}}
                        checked={AccountStore.search.isMiscProfit === 1}
                        value={AccountStore.search.isMiscProfit === 1 ? true : false}
                        onChange={v=> AccountStore.search.isMiscProfit = v.target.checked ? 1 : 0}
                    />
                </CCol>
            </Row>
            <Row>
                <Col className='d-flex flex-row-reverse'>
                    <CIconBtn title={'거래내역 가져오기'}
                                icon={'fi-rr-download'}
                                onClick={handleAccountUpload}
                                isKeyEvent={true}
                    />
                    <CSearchBtn onClick={getAccountList} />
                </Col>
            </Row>
        </CContainer>
    );
}

export default observer(AccountListSearch);