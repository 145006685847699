
import { observer } from "mobx-react-lite";
import { CInputGroup, Subject } from "../../../components/CustomContainer";
import { useState, useRef, useEffect } from "react";
import { CContainer, CCol, CInputDoubleGroup, CSelectGroup } from "../../../components/CustomContainer";
import { Row, Col, InputGroup, Form, Button, Badge } from "react-bootstrap";
import { AsStore } from "../../../store/asset/AsStore";
import { AppStore } from "../../../store/AppStore";
import axios from "axios";
import { showToast, decrypt } from "../../../common/utils";
import AgGridContainer from "../../../components/AgGridContainer";
import { USER_INFO } from "../../../common/constants";
import { SiteSearchStore } from "../../../store/search/SiteSearchStore";
import Modal from "../../../components/Modal";
import { MaintenanceStore } from "../../../store/asset/MaintenanceStore";
import MaintenanceDetail from "../../asset/MaintenanceDetail";
import { callConfirm, getLocalStorage } from "../../../utils";
import { ConsumableStore } from "../../../store/search/ConsumableStore";
import { findCommKrnm, getCommcode } from "../../../utils/commcode";
import { getUserName } from "../../../utils/userUtils";
import { autoHyphen } from "../../../common/utils";
import Address2 from "../../../components/Address2";

const AsSchedulePopup = ({getAsList=null}) => {
    const gridRef = useRef();
    const maintenanceGridRef = useRef();
    const userInfo = getLocalStorage(USER_INFO);

    const [isReadOnly, setIsReadOnly] = useState(false);
    const [managementNo, setManagementNo] = useState('');
    const [label, setLabel] = useState('list');
    const [userColumn, setUserColumn] = useState('');
    const [managerList, setManagerList] = useState([]);
    const [acceptDetail, setAcceptDetail] = useState('안녕하세요, ' + userInfo.companyName +'입니다.\nAS가 접수되었습니다.' + '\n\n업체명: -\n현장명: -\n현장주소: -\n담당자: -\n연락처: -\n관리번호: -\n장비위치: -\n불량내용: -');
    const [managementNoList, setManagementNoList] = useState([]);
    const [acceptContent, setAcceptContent] = useState('');
    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [addressDetail, setAddressDetail] = useState('');
    const [searchUser, setSearchUser] = useState('');
    const [equipmentPart, setEquipmentPart] = useState('');
    const [inputCustomerName, setInputCustomerName] = useState('');
    const [customerData, setCustomerData] = useState([]);
    const [inputManagementNo, setInputManagementNo] = useState('');
    const [managementNoData, setManagementNoData] = useState([]);
    const [maintenanceCodeList, setMaintenanceCodeList] = useState([]);
    const [lgCodeList, setLgCodeList] = useState([]);
    const [mdCodeList, setMdCodeList] = useState([]);
    const [smCodeList, setSmCodeList] = useState([]);
    const [productType, setProductType] = useState('');
    const [productTypeName, setProductTypeName] = useState('');
    const [lgValue, setLgValue] = useState('');
    const [lgName, setLgName] = useState('');
    const [mdValue, setMdValue] = useState('');
    const [mdName, setMdName] = useState('');
    const [smValue, setSmValue] = useState('');
    const [smName, setSmName] = useState('');
    const [maintenanceContent, setMaintenanceContent] = useState('');

    useEffect(()=>{
        setIsReadOnly(AsStore.data.asSeq ? true : false);
        setAcceptDetail(AsStore.data.acceptDetail ? AsStore.data.acceptDetail : acceptDetail);
        getManaitenanceCode();
        
        if (AsStore.data.asSeq) {
            const dataProductType = AsStore.data.productType;
            setProductType(dataProductType);
            // AS 담당자 배정 값 넣어주기
            AsStore.data.asManagerList.map(v => managerList.push({userSeq: v.userSeq, name: v.name, phoneNumber: v.phoneNumber}))
            AsStore.data.asManagerName = managerList.map(user => user.name).join(' / ');

            if (['1','2','3','4'].includes(AsStore.data.asStatus)) {
                setProductTypeName(findCommKrnm(dataProductType, '68'));
                setProductType(dataProductType);

                if (AsStore.data.maintenanceList.length > 0) {
                    setLgValue(AsStore.data.maintenanceList[0].maintenanceLgSeq);
                    setMdValue(AsStore.data.maintenanceList[0].maintenanceMdSeq);
                    setSmValue(AsStore.data.maintenanceList[0].maintenanceSmSeq);
    
                    if (Number(dataProductType) === 1) {
                        setLgCodeList(getSelectOptionValue('lg', dataProductType));
                        setMdCodeList(getSelectOptionValue('md', dataProductType));
                        setSmCodeList(getSelectOptionValue('sm', dataProductType));
                    } else if (Number(dataProductType) === 2) {
                        setLgCodeList(getSelectOptionValue(null, dataProductType));
                        setMdCodeList(getSelectOptionValue(AsStore.data.maintenanceList[0].maintenanceLgSeq, dataProductType));
                        setSmCodeList(getSelectOptionValue(AsStore.data.maintenanceList[0].maintenanceSmSeq, dataProductType));
                    }
    
                } else {
                    if (Number(dataProductType) === 1) {
                        setLgCodeList(getSelectOptionValue('lg', dataProductType));
                        setMdCodeList(getSelectOptionValue('md', dataProductType));
                        setSmCodeList(getSelectOptionValue('sm', dataProductType));
                    } else if (Number(dataProductType) === 2) {
                        setLgCodeList(getSelectOptionValue(null, dataProductType));
                    }
                }
            }

        }
    }, [AsStore.data.asStatus, AsStore.data.asSeq]);

    useEffect(()=> {
        makeAcceptDetails();
    }, [AsStore.data.siteName, AsStore.data.customerName, postcode, address, addressDetail, acceptContent, equipmentPart]);

    useEffect(() => {
        let content = "";

        if (lgValue || mdValue || smValue) {
            content = (lgValue ? lgName : '') + (mdValue ? (" / " + mdName) : '') + (smValue ? (" / " + smName) : '');
        } 
        setMaintenanceContent(content);

        if (['1','2','3','4'].includes(AsStore.data.asStatus) && productType) {
            let processDetail = AsStore.data.processDetail ? AsStore.data.processDetail.substring(AsStore.data.processDetail.indexOf('\n') + 1) : '';
            AsStore.data.processDetail = content + '\n' + (processDetail || '');
    
            AsStore.data.managementNoList.forEach(v => {
                v.content = content + " / ";
            })
        }
    }, [lgName, mdName, smName])

    const getManaitenanceCode = async () => {
        const result = await axios.get("/as/maintenanceCode");
        setMaintenanceCodeList(result);
    }

    const getSelectOptionValue = (value, type='') => {
        let codeList = [];
        if (Number(type) === 1) {
            codeList = maintenanceCodeList.filter(v => v.division === value).map(v => {return {name: v.maintenanceCodeName, value: v.maintenanceCodeSeq}})
        } else if (Number(type) === 2) {
            if (value) {
                codeList = maintenanceCodeList.filter(v => v.parentSeq === Number(value)).map(v => {return {name: v.maintenanceCodeName, value: v.maintenanceCodeSeq}})
            } else {
                codeList = maintenanceCodeList.filter(v => v.productType === Number(type)).map(v => {return {name: v.maintenanceCodeName, value: v.maintenanceCodeSeq}})
            }
        }

        return codeList;
    }

    /* 업체명 엔터키 이벤트 */
    const customerNameEnterKeyFn = async () => {
        const result = await axios.get('/customer/list', {params: {customerName: AsStore.data.customerName}});

        if (result.length === 1) {
            AsStore.data.customerName = result[0].customerName;
            AsStore.data.customerNo = result[0].customerNo;
        } else if (result.length > 1) {
            setInputCustomerName(AsStore.data.customerName);
            setCustomerData(result);
            AsStore.data.customerNo = '';
            AppStore.toggleCustomerSearchModal();
        }
    }

    /* 유저 조회 팝업 호출 */
    const openSearchAsManagerUserPopup = (column) => {
        setUserColumn(column);
        if (column === 'asManager') {
            setSearchUser('as');
        } else {
            setSearchUser('');
        }
        AppStore.toggleOpenAsManagerUserSearch();
    }

    /* AS 접수 내용 만들기 */
    const makeAcceptDetails = () => {

        // 접수할 때 아니면 실행되지 않게 리턴
        if (AsStore.data.asSeq) {
            return false;
        }

        const addressContent = address !== '' ? address + ", " + addressDetail: '';

        // 관리번호 나열
        const managementNoArr = AsStore.data.managementNoList.map(v => {return v.managementNo});

        let content = '안녕하세요, ' + userInfo.companyName +'입니다.\nAS가 접수되었습니다.' + '\n\n업체명: ' + (AsStore.data.customerName || '-') + '\n현장명: ' + (AsStore.data.siteName || '-') + '\n현장주소: ' + (addressContent || '-') + '\n담당자: ' + (AsStore.data.siteManagerName || '-') + '\n연락처: ' + (autoHyphen(AsStore.data.siteManagerPhone) || '-') + '\n관리번호: ' + (managementNoArr.join(',') || '-') + '\n장비위치: ' + (equipmentPart || '-') + '\n불량내용: ' + (acceptContent || '-');
        setAcceptDetail(content);
    }

    /* 관리번호 엔터키 이벤트 */
    const openAssetSearchPopup = async (e, type) =>{
        setLabel(type);

        if (type === 'input') {
            const params = {managementNo: AsStore.data.managementNo, assetStatus: '302'};
            const result = await axios.get('/asset/list', { params });
            ConsumableStore.search.managementNo = AsStore.data.managementNo;
            
            if(result.length === 1) {
                AsStore.data.managementNo = result[0].managementNo;
                AsStore.data.customerName = result[0].customerName;
                AsStore.data.customerNo = result[0].customerNo;
                AsStore.data.siteSeq = result[0].siteSeq;
                AsStore.data.siteName = result[0].siteName !== null ? result[0].siteName : '';
                AsStore.data.siteManagerName = result[0].managerName !== null ? result[0].managerName : '';
                AsStore.data.siteManagerPhone = decrypt(result[0].managerPhoneNumber);
                setPostcode(result[0].sitePostcode);
                setAddress(result[0].siteAddress !== null ? result[0].siteAddress : '');
                setAddressDetail(result[0].siteAddressDetail !== null ? result[0].siteAddressDetail: '');

                let uniqueArray = AsStore.data.managementNoList;
                let seq = AsStore.data.managementNoList.length + 1;
                uniqueArray.push({addRowId: seq++, managementNo: result[0].managementNo, content: maintenanceContent !== '' ? maintenanceContent + " / " : "", isSelected: true, isCreated: true});
                uniqueArray = [...new Map(uniqueArray.map((m) => [m.managementNo, m])).values()];
                AsStore.data.managementNoList = uniqueArray;

                makeAcceptDetails();
                AsStore.data.managementNo = '';
            } else {
                setInputManagementNo(AsStore.data.managementNo);
                setManagementNoData(result);
                AppStore.toggleAssetSearchModal();
                AsStore.data.managementNo = '';
            }
        } else if (type === 'list') {
            const params = {managementNo: managementNo, assetStatus: '302'};
            const result = await axios.get('/asset/list', { params });

            ConsumableStore.search.managementNo = managementNo;
            AsStore.data.managementNoList = AsStore.data.managementNoList ? AsStore.data.managementNoList : [];
    
            if(result.length === 1) {
                let seq = AsStore.data.managementNoList.length + 1;
                
                if(!AsStore.data.managementNoList.some(v => v.managementNo === result[0].managementNo)){
                    const additionalRowInfo = {
                        addRowId: seq++
                        , managementNo: result[0].managementNo
                        , content: maintenanceContent !== '' ? maintenanceContent + " / " : ""
                        , isSelected: true
                        , isCreated: true
                    }
                    AsStore.data.managementNoList.push({...additionalRowInfo});
                }
            } else {
                setInputManagementNo(managementNo);
                setManagementNoData(result);
                AppStore.toggleAssetSearchModal();
                setManagementNo('');
            }

            setManagementNo('');
        }

        setLabel('list');
    }

    /* AS 모달 닫기 */
    const closeModal = () => {
        AsStore.init();
        setAcceptDetail('안녕하세요, ' + userInfo.companyName +'입니다.\nAS가 접수되었습니다.' + '\n\n현장명: \n현장주소: \n담당자: \n연락처: \n관리번호: \n장비위치 \n불량내용: ');
        setManagementNoList([]);
        setManagerList([]);
        setManagementNo('');
        setEquipmentPart('');
        setAcceptContent('');
        setPostcode('');
        setAddress('');
        setAddressDetail('');
        setProductType('');
        setProductTypeName('');
        setLgValue('');
        setLgName('');
        setMdValue('');
        setMdName('');
        setSmValue('');
        setSmName('');
        setMaintenanceContent('');
    };

    /* 정비 상세 모달 닫기 */
    const closedMaintenanceModal = () => {
        MaintenanceStore.detailInit();
        setSearchUser('as');
    };

    /* AS 담당자 비우기 버튼 */
    const onEmptyBtn = () => {
        AsStore.data.asManagerName = '';
        setManagerList([]);
    }

    /* 접수내용 수정 */
    const changeAcceptContent = (e, column) => {
        if(column === 'acceptContent') setAcceptContent(e.target.value); 
        else if (column === 'equipmentPart') setEquipmentPart(e.target.value);
        
        makeAcceptDetails();
    }

    /* 관리번호 불러오기 */
    const getAsManagementNoList = async() => {
        const result = await axios.get("/as/managementNo", {params: {asSeq: AsStore.data.asSeq}});
        AsStore.data.managementNoList = result;
    }

    const siteCustomerManagerPopupBtn = () => {
        AppStore.toggleOpenSiteCustomerManagerSearch();
    }
    
    const deleteManagementNo = async (i) => {
        if (!await callConfirm('삭제하시겠습니까?')) {
            return false;
        }

        let copyArr = [...AsStore.data.managementNoList];
        copyArr.splice(i, 1);

        AsStore.data.managementNoList = copyArr;
        makeAcceptDetails();
    }

    return(
        <>
            <Modal
                title={'AS 접수'}
                show={AppStore.isOpenAsSchedulePopup}
                onHide={() => AppStore.toggleAsSchedulePopup()}
                toggleValue={AppStore.isOpenAsSchedulePopup}
                onExit={closeModal}
                size={AsStore.data.asSeq ? 'lg' : ''}
                className={AsStore.data.asSeq ? '' : 'modal-w5'}
            >
                <Modal title={'정비상세'} onExit={closedMaintenanceModal}
                        size={'xl'}
                >
                    <MaintenanceDetail searchUser={searchUser} setSearchUser={setSearchUser}/>
                </Modal>
                <CContainer search>
                    {AsStore.data.asSeq && 
                    <>
                        <Row>
                            <CCol lg={12}>
                                <CSelectGroup label={'장비구분'} labelId={'productType'}
                                            options={[{name: '- 장비구분 -', value: ''}].concat(getCommcode('68'))}
                                            value={AsStore.data.productType}
                                            onChange={(v) => {
                                                setProductType(v.target.value);
                                                setProductTypeName(v.target.options[v.target.selectedIndex].text);
                                                setLgCodeList([]);
                                                setMdCodeList([]);
                                                setSmCodeList([]);
                                                setLgValue('');
                                                setLgName('');
                                                setMdValue('');
                                                setMdName('');
                                                setSmValue('');
                                                setSmName('');
                                                
                                                if (v.target.value === '1') {
                                                    setLgCodeList(getSelectOptionValue('lg', v.target.value));
                                                    setMdCodeList(getSelectOptionValue('md', v.target.value));
                                                    setSmCodeList(getSelectOptionValue('sm', v.target.value));
                                                } else {
                                                    setLgCodeList(getSelectOptionValue(null, v.target.value));
                                                }
                                            }}
                                            disabled={true}
                                />
                            </CCol>
                        </Row>  
                        <div style={{width: '100%', height: 1, borderBottomWidth: 1, borderBottomColor: '#e3e3e3', marginTop: -5, marginBottom: 10, marginLeft: 0, marginRight: 10}}/>
                        <Row>
                            <CCol lg={4}>
                                <CInputGroup label={'AS번호'}
                                        labelId={'asSeq'}
                                        value={AsStore.data.asSeq || ''}
                                        disabled={true}             
                                />
                            </CCol>
                            <CCol lg={4}>
                                <CSelectGroup label={'AS상태'} labelId={'asStatus'}
                                            placeholder={'AS상태'}
                                            options={[{name: 'AS상태', value: ''}].concat(getCommcode('55'))}
                                            value={AsStore.data.asStatus || ''}
                                            disabled={true}
                                />
                            </CCol>
                            <CCol lg={4}>
                                <CInputGroup label={'AS처리자'}
                                        labelId={'asManagerSeq'}
                                        value={getUserName(AsStore.data.asManagerSeq) || ''}
                                        disabled={true}             
                                />
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={12}>
                                <InputGroup size="sm">
                                    <InputGroup.Text id={'asManager'} style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>AS 담당자 배정</InputGroup.Text>
                                    <Form.Control style={{flex: 1}} aria-describedby={'asManagerName'} value={AsStore.data.asManagerName || ''} onChange={v => AsStore.data.asManagerName = v.target.value} readOnly={true} disabled={isReadOnly}/>
                                    <Button className="btn btn-outline-secondary" style={{borderLeftWidth: 0, borderRightWidth: 0, borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={onEmptyBtn} disabled={isReadOnly}>
                                        <i className="fi fi-rr-cross-small" />
                                    </Button>
                                    <Button variant="outline-secondary" style={{borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={() => openSearchAsManagerUserPopup('asManager')} disabled={isReadOnly}>
                                        <i className="me-1 fi fi-br-search" />
                                    </Button>
                                </InputGroup>
                            </CCol>
                        </Row>
                    </>
                    }
                    {!AsStore.data.asSeq && 
                    <>
                    <Row>
                        <CCol lg={12}>
                            <CSelectGroup label={'장비구분'} labelId={'productType'}
                                        options={[{name: '- 장비구분 -', value: ''}].concat(getCommcode('68'))}
                                        value={AsStore.data.productType}
                                        onChange={(v) => {
                                            setProductType(v.target.value);
                                            setProductTypeName(v.target.options[v.target.selectedIndex].text);
                                            setLgCodeList([]);
                                            setMdCodeList([]);
                                            setSmCodeList([]);
                                            setLgValue('');
                                            setLgName('');
                                            setMdValue('');
                                            setMdName('');
                                            setSmValue('');
                                            setSmName('');
                                            
                                            if (v.target.value === '1') {
                                                setLgCodeList(getSelectOptionValue('lg', v.target.value));
                                                setMdCodeList(getSelectOptionValue('md', v.target.value));
                                                setSmCodeList(getSelectOptionValue('sm', v.target.value));
                                            } else {
                                                setLgCodeList(getSelectOptionValue(null, v.target.value));
                                            }
                                        }}
                                        labelClassName="input-required"
                            />
                        </CCol>
                    </Row>  
                    <div style={{width: '100%', height: 1, borderBottomWidth: 1, borderBottomColor: '#e3e3e3', marginTop: -5, marginBottom: 10, marginLeft: 0, marginRight: 10}}/>
                    <Row>
                        <CCol lg={12}>
                            <CInputGroup
                                label={'현장명'}
                                labelId={'siteName'}
                                value={AsStore.data.siteName || ''}
                                onChange={e => {
                                    AsStore.data.siteName = e.target.value;
                                    SiteSearchStore.search.siteName = e.target.value;
                                }}
                                onCallbackBtn={() => AppStore.toggleSiteNameSearchPopup()}
                                disabled={isReadOnly}
                                btnDisabled={isReadOnly}
                                placeholder={'현장명 입력'}
                            />
                        </CCol>
                        <CCol lg={12}><div style={{textAlign: 'center'}}>또는</div></CCol>
                        <CCol lg={12}>
                            <CInputGroup
                                label={'관리번호'}
                                labelId={'managementNo'}
                                value={AsStore.data.managementNo || ''}
                                onChange={e => {
                                    AsStore.data.managementNo = e.target.value;
                                }}
                                enterKeyHint={e => openAssetSearchPopup(e, 'input')}
                                onCallbackBtn={() =>  AppStore.toggleAssetSearchModal()}
                                disabled={isReadOnly}
                                btnDisabled={isReadOnly}
                                placeholder={'관리번호 입력 후 엔터키를 눌러주세요.'}
                            />
                        </CCol>
                    </Row>
                    <div style={{width: '100%', height: 1, borderBottomWidth: 1, borderBottomColor: '#e3e3e3', marginTop: -5, marginBottom: 10, marginLeft: 0, marginRight: 10}}/>
                    <Row>
                        <Address2 label='주소'
                                    setPostcode={setPostcode}
                                    setAddress={setAddress}
                                    setAddressDetail={setAddressDetail}
                                    postcode={postcode}
                                    address={address}
                                    addressDetail={addressDetail}
                                    disabled={isReadOnly}
                                    style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}
                                    btnStyle={{borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}}
                        />
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <CInputGroup
                                label={'업체명'}
                                labelId={'customerName'}
                                value={AsStore.data.customerName || ''}
                                onChange={v => {
                                    AsStore.data.customerName = v.target.value; 
                                    AsStore.data.customerNo = '';
                                }}
                                enterKeyHint={customerNameEnterKeyFn}
                                onCallbackBtn={() =>  AppStore.toggleCustomerSearchModal()}
                                placeholder={'업체명 입력'}
                            />
                        </CCol>
                        <CCol lg={12}>
                            <CInputDoubleGroup label={'담당자'} labelId={'siteManagerPhone'} labelId2={'siteManagerName'}
                                                placeholder={'담당자 전화번호'} placeholder2={'담당자'} secondFlex={4}
                                                onCallbackBtn={() => {!isReadOnly && siteCustomerManagerPopupBtn()}}
                                                value={autoHyphen(AsStore.data.siteManagerPhone)} value2={AsStore.data.siteManagerName || ''}
                                                onChange={v => {
                                                                    AsStore.data.siteManagerPhone = v.target.value; 
                                                                    makeAcceptDetails();
                                                                }
                                                }
                                                onChange2={v => {
                                                                    AsStore.data.siteManagerName = v.target.value; 
                                                                    makeAcceptDetails();
                                                                }}
                                                disabled={isReadOnly} disabled2={isReadOnly}
                            />
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <InputGroup size="sm">
                                <InputGroup.Text id={'asManager'} style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>AS 담당자 배정</InputGroup.Text>
                                <Form.Control style={{flex: 1}} aria-describedby={'asManagerName'} value={AsStore.data.asManagerName || ''} onChange={v => AsStore.data.asManagerName = v.target.value} readOnly={true} disabled={isReadOnly}/>
                                <Button className="btn btn-outline-secondary" style={{borderLeftWidth: 0, borderRightWidth: 0, borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={onEmptyBtn} disabled={isReadOnly}>
                                    <i className="fi fi-rr-cross-small" />
                                </Button>
                                <Button variant="outline-secondary" style={{borderColor: '#ced4da', backgroundColor: 'rgb(191 210 237 / 40%)'}} onClick={() => openSearchAsManagerUserPopup('asManager')} disabled={isReadOnly}>
                                    <i className="me-1 fi fi-br-search" />
                                </Button>
                            </InputGroup>
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <InputGroup size="sm">
                                <InputGroup.Text id={'acceptContent'} style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>장비위치</InputGroup.Text>
                                    <textarea className="form-control" rows="1"
                                        value={equipmentPart || ''}
                                        onChange={e => changeAcceptContent(e, 'equipmentPart')}
                                        disabled={isReadOnly}
                                        placeholder={'장비 위치를 입력해주세요.'}
                                    />
                            </InputGroup>
                        </CCol>
                    </Row>
                    <Row>
                        <CCol lg={12}>
                            <InputGroup size="sm">
                                <InputGroup.Text id={'acceptContent'} style={{color: '##212529', backgroundColor: 'rgb(191 210 237 / 40%)'}}>불량내용</InputGroup.Text>
                                <textarea className="form-control" rows="2"
                                                value={acceptContent || ''}
                                                onChange={e => changeAcceptContent(e, 'acceptContent')}
                                                disabled={isReadOnly}
                                                placeholder={'불량내용을 입력해주세요.'}
                                    />
                            </InputGroup>
                        </CCol>
                    </Row>
                    <Row>
                        <Col className='d-flex flex-row-reverse'>
                            {!isReadOnly && ( <div> <span style={{color: 'blue'}}>현장명 / 업체명 / 관리번호</span> 입력 후 Enter키(엔터키)를 눌러주세요. </div> )}
                        </Col>
                    </Row>
                    </>
                    }
                </CContainer>
                <CContainer>
                    <Row>
                        <CCol lg={12}>
                            <div className="mb-3">
                                <Subject><h5>접수 내역</h5></Subject>
                                <textarea className="form-control" rows="11"
                                            value={acceptDetail || ''}
                                            onChange={v => setAcceptDetail(v.target.value)}
                                            disabled={true}
                                />
                            </div>
                        </CCol>
                    </Row>
                    {AsStore.data.asStatus === '5' &&
                        <Row>
                            <div>
                                <textarea className="form-control" rows="3"
                                            placeholder="처리 내역을 작성해주세요."
                                            value={AsStore.data.processDetail || ''}
                                            onChange={v => AsStore.data.processDetail = v.target.value}
                                            disabled={!['1','2','3','4'].includes(AsStore.data.asStatus)}
                                />
                            </div>
                        </Row>
                    }
                </CContainer>
                {!AsStore.data.asSeq && (
                    <>
                        <CContainer>
                            <Subject><h5>AS 처리 대상 관리번호 목록</h5></Subject>
                        </CContainer>
                        <CContainer search>
                            {AsStore.data.managementNoList.length > 0 && AsStore.data.managementNoList.map((v, i) => (
                                <Button variant="info" key={i} className="my-2 mx-2">
                                    {v.managementNo}&nbsp;&nbsp;&nbsp;
                                    <Badge pill bg="light" style={{verticalAlign: 'middle'}}>
                                        <a className="text-decoration-none" style={{color: 'black', verticalAlign: 'middle'}} href="#!" onClick={() => deleteManagementNo(i)}>x</a>
                                    </Badge>
                                </Button>
                            ))}
                        </CContainer>
                    </>
                )}
                {(['1','2','3','4'].includes(AsStore.data.asStatus)) && 
                    <CContainer>
                        <AgGridContainer
                            gridRef={gridRef}
                            gridTitle={'관리번호 목록'}
                            height={20}
                            rowData={AsStore.data.managementNoList}
                            columnDefs={[
                                {field: 'id', headerName: '순번', valueGetter: 'node.rowIndex + 1', width: 80},
                                {field: 'seq', headerName: '순번', width: 80, hide:true},
                                {field: 'managementNo', headerName: '관리번호', width: 110},
                                {field: 'content', headerName: '정비내역', flex: 1},
                            ]}
                            seqColumn={'agId'}
                        />
                    </CContainer>
                }
                {(AsStore.data.asSeq && AsStore.data.asStatus === '5') && (
                    <>
                        <CContainer>
                            <AgGridContainer
                                gridRef={maintenanceGridRef}
                                gridTitle={'정비 목록'}
                                height={20}
                                rowData={AsStore.data.maintenanceList}
                                columnDefs={[
                                    {field: 'seq', headerName: '순번', valueGetter: 'node.rowIndex + 1', width: 90},
                                    {field: 'maintenanceSeq', headerName: '정비번호', width: 100},
                                    {field: 'managementNo', headerName: '관리번호', width: 120},
                                    {field: 'assetNo', headerName: '자산번호', width: 120},
                                    {field: 'content', headerName: '정비일지', flex: 1},
                                ]}
                                seqColumn={'agId'}
                            />
                        </CContainer>
                    </>
                )}
            </Modal>
        </>
    )
}

export default observer(AsSchedulePopup);