import React, {useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {Col, Row} from "react-bootstrap";
import axios from 'axios';
import {CCol,CContainer,CInputGroup} from "../../components/CustomContainer";
import { getCommcode, findCommKrnm } from "../../utils/commcode";
import AgGridContainer from "../../components/AgGridContainer";
import { ReceiveStore } from "../../store/billing/ReceiveStore";
import { AppStore } from "../../store/AppStore";
import {callConfirm} from '../../utils';
import ReceiveManagerSearch from "./ReceiveManagerSearch";
import {ICON_TRASH} from "../../common/constants";
import {showToast} from "../../common/utils";
import {getUserName} from "../../utils/userUtils";

const ReceiveManager =()=> {
    const gridRef = useRef();
    const [selView, setSelView] = useState('receive');
    const [totalAccountAmount, setTotalAccountAmount] = useState(0);
    const [totalReceiveAmount, setTotalReceiveAmount] = useState(0);
    const [possibleAmount, setPossibleAmount] = useState(0);
    const [isBillingSearch, setIsBillingSearch] = useState(false);
    const [nonReceiveAmount, setNonReceiveAmount] = useState(0);
    const [receiveAmount, setReceiveAmount] = useState(0);

    const getResultList = async ()=> {
        if(selView === 'receive') { // 입금 내역 조회
            const result = await axios.get("account/receive", {params: ReceiveStore.accountSearch});
            ReceiveStore.accountList = result;

            const sumOfAccountAmount = result.reduce((total, account) => total + account.accountAmount, 0);
            const sumOfReceiveAmount = result.reduce((total, receive) => total + receive.receiveAmount, 0);

            setTotalAccountAmount(sumOfAccountAmount);
            setTotalReceiveAmount(sumOfReceiveAmount);
            setPossibleAmount(sumOfAccountAmount - sumOfReceiveAmount);
            setNonReceiveAmount(0);
            //ReceiveStore.billingInit();

            if(result.length === 0) showToast('조회된 데이터가 없습니다.');
        }else if(selView === 'search'){ // 수납 내역 조회
            const result = await axios.get("receive/list", {params: ReceiveStore.search});
            ReceiveStore.receiveList = result;

            // 총 수납 금액
            const sumOfReceiveAmount = result.reduce((total, receive) => total + receive.receiveAmount, 0);
            setReceiveAmount(sumOfReceiveAmount);

            if(result.length === 0) showToast('조회된 데이터가 없습니다.');
        }
    }

    /* 입금 내역 목록 더블 클릭 시 이벤트 */
    const rowDoubleClickCallback =async(grid) => {
        const {accountAmount, receiveAmount, customerNo, accountSeq} = grid.data;
        setTotalAccountAmount((Number)(accountAmount));
        setTotalReceiveAmount((Number)(receiveAmount));
        setPossibleAmount((Number)(accountAmount - receiveAmount));
        ReceiveStore.billingSearch.customerNo = grid.data.customerNo;
        ReceiveStore.billingSearch.customerName = grid.data.customerName;

        ReceiveStore.setAccountSeq(accountSeq);
        //청구건 조회
        await getBillingList();
    }

    /* 미수납 청구 내역 조회 */
    const getBillingList = async() => {
        const result = await axios.get("billing/incomplete", {params: ReceiveStore.billingSearch});
        ReceiveStore.setBillingList(result);
        if(nonReceiveAmount > 0){
            setNonReceiveAmount(0);
        }
    }

    /* 미수납 총액 */
    const onSelectionChanged = () => {
        const filteredBilling = ReceiveStore.billingList.filter(v => v.isSelected || v.isUpdated);
        const sumOfNonReceiveAmount = filteredBilling.reduce((total, nonReceive) => total + Number(nonReceive.receiveTargetAmount), 0);
        setNonReceiveAmount(sumOfNonReceiveAmount);
    }

    /* 수납 버튼 */
    const customReceiveBtn = {
        isUsed: true,
        callbackFn:() => selectedGridReceive(),
        icon: 'fi-rr-money-check-edit',
        title: '수납처리',
        variant: 'dark',
        width: 120,
        style: {marginTop: -26}
    }

    /* 수납 취소 버튼 */
    const customReceiveCancelBtn = {
        isUsed: true,
        callbackFn:() => selectReceiveCancel(),
        icon: ICON_TRASH,
        title: '수납취소',
        variant: 'danger',
        width: 100,
    }

    const selectedGridReceive = async () => {
        if(!ReceiveStore.accountSeq){
            showToast('입금 내역이 선택되지 않았습니다.');
            return;
        }

        // 저장 확인
        if(!await callConfirm('수납 하시겠습니까?')) return;

        const selectedBilling = ReceiveStore.billingList.filter(v => v.isSelected || v.isUpdated);
        await axios.put('receive/publish', {billingList: selectedBilling, accountSeq: ReceiveStore.accountSeq, possibleAmount: possibleAmount, receiveAmount: totalReceiveAmount});
        showToast('수납되었습니다.');
        ReceiveStore.accountSeq = '';

        await getBillingList();
        await getResultList();
    }

    const selectReceiveCancel =async()=> {
        const selectedReceiveList = ReceiveStore.receiveList.filter(v => v.isSelected);
        if(selectedReceiveList === 0){
            showToast('취소할 수납건을 선택해주세요.')
        }

        const checkRemark = selectedReceiveList.some(v => !v.remark);
        if(checkRemark){
            showToast('수납을 취소하려면 취소 사유를 비고에 입력해주세요.');
            return;
        }

        if(!await callConfirm('선택건을 수납 취소 하시겠습니까?')) return;

        await axios.put('receive/cancel', selectedReceiveList);
        showToast('취소되었습니다.');

        await getResultList();
    }

    /* 총 수납 금액 */
    const receiveSelectionChanged = () => {
        const filteredReceive = ReceiveStore.receiveList.filter(v => v.isSelected || v.isUpdated);
        const sumOfReceiveAmount = filteredReceive.reduce((total, receive) => total + receive.receiveAmount, 0);
        setReceiveAmount(sumOfReceiveAmount);
    }

    /* 수납 목록 update */
    const callBackGridData = async (gridData) => {
        // 저장 확인
        if (!await callConfirm('저장 하시겠습니까?')) return;

        await axios.put('receive', {receiveList: gridData.updatedList});
        showToast('저장되었습니다.');
        await getResultList();
    }

    const cellCustomChangeValue = async (e) => {
        if(e.data.receiveAmount > 0 && e.data.nonReceiveAmount < e.data.receiveAmount){
            showToast('청구 금액보다 클 수 없습니다.');
            e.data.receiveAmount = e.data.nonReceiveAmount;
            gridRef.current.api.redrawRows();
            return;
        }
    }

    return(
        <>
            {/* 검색조건 */}
            <ReceiveManagerSearch selView={selView} setSelView={setSelView} getResultList={getResultList}
                                  isBillingSearch={isBillingSearch} setIsBillingSearch={setIsBillingSearch}
                                  getBillingList={getBillingList}                      
            />
            <CContainer>
                {selView === 'receive' &&
                    <Row>
                        <CCol lg={6}>
                            <Row style={{paddingLeft: 12, paddingRight: 12}}>
                                <CCol lg={4}>
                                    <CInputGroup label={'총 입금 금액'} labelId={'totalAccountAmount'}
                                                 value={totalAccountAmount.toLocaleString()}
                                                 readOnly={true}
                                    />
                                </CCol>
                                <CCol lg={4}>
                                    <CInputGroup label={'총 수납 금액'} labelId={'totalReceiveAmount'}
                                                 value={totalReceiveAmount.toLocaleString()}
                                                 readOnly={true}
                                    />
                                </CCol>
                                <CCol lg={4}>
                                    <CInputGroup label={'수납 가능 금액'} labelId={'possibleAmount'}
                                                 value={possibleAmount.toLocaleString()}
                                                 readOnly={true}
                                    />
                                </CCol>
                            </Row>
                            <br/>
                            <AgGridContainer
                                gridRef={gridRef}
                                height={58}
                                rowData={ReceiveStore.accountList}
                                columnDefs={[
                                    {field: "companyName", headerName: "회사", width: 140},
                                    {field: "accountSeq", headerName: "입금번호", width: 110},
                                    {field: "accountDate", headerName: "입금일자", width: 140},
                                    {field: "customerNo", headerName: "고객코드", hide:true},
                                    {field: "summary", headerName: "적요", width: 140},
                                    {
                                        field: "accountAmount",
                                        headerName: "입금금액",
                                        cellClass: 'ag-grid-money-align',
                                        valueFormatter: v => v.value?.toLocaleString(),
                                        width: 130},
                                    {
                                        field: "receiveAmount",
                                        headerName: "수납금액",
                                        cellClass: 'ag-grid-money-align-complete',
                                        valueFormatter: v => v.value?.toLocaleString(),
                                        width: 130
                                    },
                                    {field: "customerName", headerName: "고객명", width: 150},
                                    {field: "isDeposit", headerName: "적립금여부", valueFormatter: v=> v.value === 1 ? 'Y' : '', width: 120},
                                    {
                                        field: "bankCode",
                                        headerName: "은행",
                                        valueFormatter: v => findCommKrnm(v.value, '24'),
                                        cellEditorParams: {
                                            values: getCommcode('24').map(v => v.value),
                                        },
                                        width: 110
                                    },
                                    {field: "accountNumber", headerName: "계좌번호", width: 170},
                                ]}
                                rowDoubleClickCallback={rowDoubleClickCallback}
                                getRowStyle={(v => {
                                    if(v.data.accountSeq === ReceiveStore.accountSeq) return {backgroundColor: '#bcdefa'}
                                })}
                            />
                        </CCol>
                        <CCol lg={6}>
                            <Row style={{paddingLeft: 12, paddingRight: 12, marginBottom: 6}}>
                                <CCol lg={4}>
                                    <CInputGroup label={'미수납금액'}
                                                 value={nonReceiveAmount.toLocaleString()}
                                                 readOnly={true}
                                    />
                                </CCol>
                            </Row>
                            <AgGridContainer
                                gridRef={gridRef}
                                height={58}
                                rowData={ReceiveStore.billingList}
                                columnDefs={[
                                    {field: "publishedCompany", headerName: "회사", width: 140},
                                    {field: "billingChargeNo", headerName: "청구발행번호", width: 120, hide: true},
                                    {field: "contractNo", headerName: "계약번호", width: 110},
                                    {field: "customerName", headerName: "고객명", minWidth: 150},
                                    {field: "billingAddressName", headerName: "청구지", width: 100},
                                    {field: "siteName", headerName: "현장명", width: 150},
                                    {
                                        field: "billingType",
                                        headerName: "청구구분",
                                        valueFormatter: v => findCommKrnm(v.value, '23'),
                                        width: 110
                                    },
                                    {field: "chargeDate", headerName: "청구일자", width: 130},
                                    {
                                        field: "chargeTotalAmount",
                                        headerName: "청구총액",
                                        cellClass: 'ag-grid-money-align',
                                        valueFormatter: v => v.value?.toLocaleString(),
                                        width: 140
                                    },
                                    {
                                        field: "receiveTargetAmount",
                                        headerName: "수납대상금액",
                                        cellClass: 'ag-grid-money-align ',
                                         headerClass: 'grid-column-required',
                                        valueFormatter: v => v.value?.toLocaleString(),
                                        width: 140,
                                        editable: true
                                    },
                                    {
                                        field: "nonReceiveAmount",
                                        headerName: "미수납금액",
                                        cellClass: 'ag-grid-money-align-cancel',
                                        valueFormatter: v => v.value?.toLocaleString(),
                                        width: 140
                                    },
                                    {field: "creatorName", headerName: "발행자", width: 100},
                                    {field: "billNo", headerName: "계산서번호", width: 140},
                                    {field: "customerNo", headerName: "고객번호", hide: true},
                                ]}
                                isCheckBox={true}
                                customBtnInfo={[customReceiveBtn]}
                                addRowStyle={{height: 14}}
                                onSelectionChanged={onSelectionChanged}
                                isCellCustom={true}
                                cellCustomChangeValue={cellCustomChangeValue}
                            />
                        </CCol>
                    </Row>
                }
                {selView === 'search' &&
                    <Row>
                        <Row style={{paddingLeft: 12, paddingRight: 12}}>
                            <CCol lg={3}>
                                <CInputGroup
                                    label={'총 수납 금액'}
                                    labelId={'totalReceiveAmount'}
                                    value={receiveAmount.toLocaleString()}
                                    style={{textAlign: 'right'}}
                                    readOnly={true}
                                />
                            </CCol>
                        </Row>
                        <AgGridContainer
                            gridRef={gridRef}
                            gridTitle={'수납 내역'}
                            height={60}
                            rowData={ReceiveStore.receiveList}
                            columnDefs={[
                                {field: "companyName", headerName: "회사", width: 140},
                                {field: "receiveNo", headerName: "수납번호", width: 120},
                                {field: "receiveDate", headerName: "수납일자", width: 120},
                                {field: "billDate", headerName: "계산서발행일", width: 120},
                                {field: "dueDate", headerName: "납기일자", width: 120},
                                {field: "accountDate", headerName: "입금일자", width: 120},
                                {
                                    field: "billingType",
                                    headerName: "청구구분",
                                    valueFormatter: v => findCommKrnm(v.value, '23'),
                                    cellEditorParams: {
                                        values: getCommcode('23').map(v => v.value),
                                    },
                                    width: 90
                                },
                                {
                                    field: "receiveAmount",
                                    headerName: "수납금액",
                                    cellClass: 'ag-grid-money-align',
                                    width: 120,
                                    valueFormatter: v => v.value?.toLocaleString()
                                },
                                {field: "customerName", headerName: "고객명", width: 140},
                                {field: "siteName", headerName: "현장명", width: 140},
                                {field: "billNo", headerName: "계산서번호", width: 120},
                                {field: "contractNo", headerName: "계약번호", width: 120},
                                {field: "accountSeq", headerName: "입금번호"},
                                {
                                    field: "bankCode",
                                    headerName: "은행명",
                                    valueFormatter: v => findCommKrnm(v.value, '24'),
                                    cellEditorParams: {
                                        values: getCommcode('24').map(v => v.value),
                                    },
                                    minWidth: 80
                                },
                                {field: "accountNumber", headerName: "계좌번호", width: 150},
                                {field: "receiverName", headerName: "수납자"},
                                {field: "remark", headerName: "비고", width: 300, editable: true, headerClass: 'grid-column-editable'},
                                {field: "isCancel", headerName: "취소", hide: true},
                                {field: "updater", headerName: "수정자", width: 90, valueFormatter: v => getUserName(v.value)},
                                {field: "updatedDate", headerName: "수정일자", width: 180},
                            ]}
                            isCheckBox={true}
                            useUpdated={true}
                            useCsvDownload={true}
                            callBackGridData={callBackGridData}
                            onSelectionChanged={receiveSelectionChanged}
                            customBtnInfo={[customReceiveCancelBtn]}
                            getRowStyle={(params) => {
                                if (params.data.isCancel === 1) {
                                    return { pointerEvents: 'none', color: 'red' };
                                }
                                return null;
                            }}
                        />
                    </Row>
                }
            </CContainer>
        </>
    );
}

export default observer(ReceiveManager);
