import {useEffect, useState} from "react";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { AppStore } from "../../../store/AppStore";
import Modal from "../../../components/Modal";
import {CAgGridContainer, CContainer, Subject} from "../../../components/CustomContainer";
import AgGridContainer from "../../../components/AgGridContainer";
import {AssetStore} from "../../../store/asset/AssetStore";

const AssetConsumPopup = ({assetNo}) => {
    const [searchList, setSearchList] = useState([]);

    useEffect(()=> {
        if(assetNo){
            getAssetConsumableList();
        }
    }, [assetNo, AppStore.isOpenAssetConsumPopup]);

    const getAssetConsumableList = async () => {
        const result = await axios.get('asset/consumable', {params: {assetNo: assetNo}});
        setSearchList(result);
    }

    return (
        <Modal
            title={'자산에 연결된 소모품 내역'}
            show={AppStore.isOpenAssetConsumPopup}
            onHide={() => AppStore.toggleAssetConsumPopup()}
            toggleValue={AppStore.isOpenAssetConsumPopup}
            className={'search-modal-h5'}
            //onExit={refresh}
        >
            <CContainer>
                <AgGridContainer
                    height={40}
                    rowData={searchList}
                    columnDefs={[
                        {field: "seq", headerName: "순번", width: 70},
                        {field: "consumAssetNo", headerName: "구분", width: 100, valueFormatter: v => v.value !== '' ? '자산소모품' : '일반소모품'},
                        {field: "consumProductName", headerName: "제품명", width: 180},
                        {field: "consumModel", headerName: "모델명", width: 180},
                        {field: "storageNo", headerName: "입고번호", width: 110, hide:true},
                        {field: "isUse", headerName: "연결여부", width: 90, valueFormatter: v => v.value === 0 ? 'N' : 'Y'},
                        {field: "createdDate", headerName: "연결일자", width: 200},
                    ]}
                    useCsvDownload={true}
                    getRowStyle={(v) => {
                        if (v.data.isUse !== 1) {
                            return { color: '#bababa', pointerEvents: 'none', };
                        }
                        return null;
                    }}
                />
            </CContainer>
{/*            <CAgGridContainer>
                <div style={{height: 310}}>
                    <AgGridReact
                        rowHeight={30}
                        headerHeight={30}
                        rowData={searchList}
                        columnDefs={[
                            {field: "seq", headerName: "순번", width: 70},
                            {field: "consumAssetNo", headerName: "자산번호", width: 100},
                            {field: "consumProductName", headerName: "제품명", width: 180},
                            {field: "consumModel", headerName: "모델명", width: 180},
                            {field: "storageNo", headerName: "입고번호"},
                            {field: "isUse", headerName: "연결여부"},
                            {field: "createdDate", headerName: "연결일자"},
                        ]}
                        enableCellTextSelection={true}
                        animateRows={true}
                        isCheckBox={true}
                        useUpdated={true}
                    />
                </div>
            </CAgGridContainer>*/}
        </Modal>
    )
}

export default observer(AssetConsumPopup);
