import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";

export const ReturnAskListStore = observable({
    returnAskList: [],
    returnAskItemList: [],

    search : {
        contractNo: '',
        returnAskSeq: null,
        startDate: '',
        endDate: '',
        customerNo: '',
        customerName: '',
        returnAskStatus: '',
        creator: null,
        userName: ''
    },

    data : {
        returnAskSeq: null,
        releaseAskSeq: null,
        returnAskStatus: '',
        contractNo: '',
        contractType: '',
        saleUserName: '',
        supportUserName: '',
        releaseAskRemark: '',
        returnAskRemark: '',
        creatorName: '',
        creator: '',
        returnAskDate: '',
        returnExpectDate: '',
        customerName: '',
        telephone: '',
        phoneNumber: '',
        receiveUserDept: '',
        receiveUserPosition: '',
        receiveUserName: '',
        shipHopeTime: '',
        postCode: '',
        address: '',
        addressDetail: '',
        shipRemark: '',
        mouse: 0,
        multiTab: 0,
        returnAskItemList: [],
    },

    setReturnAskList(v) {
        this.returnAskList = v;
    },
    setReturnAskItemList(v) {
        this.returnAskItemList = v;
    },
    setSearch(v) {
        LUtils.assign(this.search, v);
    },

    init() {
        this.data.returnAskSeq = null,
        this.data.returnAskStatus = '',
        this.data.contractNo = '',
        this.data.contractType = '',
        this.data.saleUserName = '',
        this.data.supportUserName = '',
        this.data.releaseAskRemark = '',
        this.data.returnAskRemark = '',
        this.data.creator = '',
        this.data.creatorName = '',
        this.data.returnAskDate = '',
        this.data.returnExpectDate = '',
        this.data.customerName = '',
        this.data.telephone = '',
        this.data.phoneNumber = '',
        this.data.receiveUserDept = '',
        this.data.receiveUserPosition = '',
        this.data.receiveUserName = '',
        this.data.postCode = '',
        this.data.address = '',
        this.data.addressDetail = '',
        this.data.shipRemark = '',
        this.data.shipType = '',
        this.data.mouse = 0,
        this.data.multiTab = 0,
        this.data.returnAskItemList = [],
        this.returnAskItemList = []
    },
});
