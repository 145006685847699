import { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import AgGridContainer from '../../../components/AgGridContainer';
import {findCommKrnm, getCommcode} from "../../../utils/commcode";
import { dateFormat, dateTimeFormat } from '../../../utils/dateUtils';
import { getUserName } from '../../../utils/userUtils';

const CustomerHistory = ({ customerNo }) => {
    const [historyList, setHistoryList] = useState([]);
    const gridRef = useRef();
    const columnDefs = [
        {field: "customerHistorySeq", headerName: "순번", width: 80},
        {field: "customerNo", headerName: "고객번호", width: 110, hide: true},
        {field: "customerType", headerName: "고객유형", width: 110, valueGetter: v => findCommKrnm(v.data.customerType, '19')},
        {field: "customerName", headerName: "고객명", width: 150},
        {field: "isAvailableContract", headerName: "계약가능", width: 110, valueGetter: v => v.data.isAvailableContract ? '가능' : '불가능'},
        {field: "owner", headerName: "대표자", width: 120},
        {field: "companyType", headerName: "기업형태", width: 110},
        {field: "businessNumber", headerName: "사업자번호", width: 140},
        {field: "corporationNumber", headerName: "법인번호", width: 120},
        {field: "establishmentDate", headerName: "설립일", valueGetter: v => dateFormat(v.data.establishmentDate), width: 120},
        {field: "employeeCount", headerName: "종업원수", width: 110},
        {field: "mainBank", headerName: "주거래은행", width: 120},
        {field: "industryType", headerName: "업종", width: 150},
        {field: "businessConditions", headerName: "업태", width: 150},
        {field: "groupCompany", headerName: "소속그룹", width: 110},
        {field: "companyPostcode", headerName: "우편번호", width: 110},
        {field: "companyAddress", headerName: "주소", width: 200},
        {field: "companyAddressDetail", headerName: "상세주소", width: 150},
        {field: "closedDate", headerName: "폐업일자", width: 120},

        {field: "inflowType", headerName: "유입경로", valueGetter: v => findCommKrnm(v.data.creator, '16'), width: 120},
        {field: "saleUserSeq", headerName: "영업담당", valueGetter: v => getUserName(v.data.saleUserSeq), width: 140},
        {field: "isPenaltyFee", headerName: "위약금청구", valueGetter: v => v.data.isPenaltyFee === 1 ? 'Y' : '', width: 120},
        {field: "penaltyPercent", headerName: "위약금율", width: 150, valueGetter: v => v.data.penaltyPercent ? '잔여 렌탈료의 '+v.data.penaltyPercent+'%' : ''},
        {field: "billingType", headerName: "청구조건", width: 120, valueGetter: v => findCommKrnm(v.data.creator, '21')},
        {field: "billingDay", headerName: "청구일", width: 120},
        {field: "dueDateType", headerName: "납기조건", width: 120, valueGetter: v => findCommKrnm(v.data.creator, '22')},
        {field: "dueDateDay", headerName: "납기일", width: 120},
        {field: "paymentType", headerName: "결제방식", width: 120, valueGetter: v => findCommKrnm(v.data.creator, '17')},
        {field: "endDate", headerName: "마감일"},

        {field: "creator", headerName: "변경자", valueGetter: v => getUserName(v.data.creator)},
        {field: "createdDate", headerName: "변경일", valueGetter: v => dateTimeFormat(v.data.createdDate), width: 170},
    ];

    useEffect(() => {
        getCustomerHistory(customerNo);
    }, []);

    /* 고객 이력 조회 */
    const getCustomerHistory = async (customerNo) => {
        if (!customerNo) {
            return false;
        }

        const result = await axios.get(`/customer/tabs/${customerNo}/history`);
        setHistoryList(result);
    }

    return (
        <AgGridContainer
            gridRef={gridRef}
            height={33}
            rowData={historyList}
            columnDefs={columnDefs}
            isCheckBox={false}
            seqColumn={'customerHistorySeq'}
            useCsvDownload={true}
            useIntoTheTab={true}
        />
    );
};

export default observer(CustomerHistory);
