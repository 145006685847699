import { observable } from "mobx";
import LUtils from "../../utils/lodashUtils";
import {getSessionUserCompanyCode} from "../../utils/userUtils";

export const UserStore = observable({

    userList: [],

    search: {
        userSeq: null,
        name: '',
        id: '',
        companyCode: getSessionUserCompanyCode() ? getSessionUserCompanyCode() : '',
        isActive: 1,
        genderSeq: null,
    },

    data: {
        userSeq: null,
        id: '',
        password: '',
        name: '',
        companyCode: null,
        companySeq: null,
        companyName: '',
        headquartersSeq: null,
        teamSeq: null,
        partSeq: null,
        partName: '',
        positionSeq: null,
        genderSeq: null,
        telephone: '',
        phoneNumber: '',
        personalNumber: '',
        email: '',
        joinDate: '',
        resignationDate: '',
        birthDate: '',
        isActive: 1,
        creator: null,
        createdDate: '',
        imageUrl: '',
        authorityGroupSeq: '',
        isUpdatePassword: false,
        isReleaseAskManager: false,
        isAsMaintenanceManager: false,
        isShipManager: false,
        isPaymentManager: false,
        apronSeq: null,
    },

    userDetail: {},

    setUserList(v) {
        this.userList = v;
    },

    setSearch(v) {
        LUtils.assign(this.search, v);
    },

});
