import { useEffect, useRef, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { AppStore } from "../../store/AppStore";
import Modal from "../Modal";
import { CCol, CContainer, CInputGroup, CSearchBtn } from "../CustomContainer";
import { CommcodeSMOptions } from "../index";
import AgGridContainer from "../AgGridContainer";
import ProductListSearch from "./ProductListSearch";
import {findCommKrnm} from "../../utils/commcode";
import {showToast} from "../../common/utils";

const StockProductSearch =({ callbackFn=null, ...props })=> {
    const {contractType, btnStatus, setBtnStatus} = props;
    const gridRef = useRef();
    const [searchList, setSearchList] = useState([]);
    const [productGroupList, setProductGroupList] = useState([]);
    const [lgList, setLGList] = useState([]);
    const [mdList, setMDList] = useState([]);
    const [smList, setSMList] = useState([]);
    const [xsList, setXSList] = useState([]);
    const [productCode, setProductCode] = useState('');
    const [productName, setProductName] = useState('');
    const [model, setModel] = useState('');
    const [brandCode, setBrandCode] = useState('');
    const [selectedLGCode, setSelectedLGCode] = useState('');
    const [selectedMDCode, setSelectedMDCode] = useState('');
    const [selectedSMCode, setSelectedSMCode] = useState('');
    const [selectedXSCode, setSelectedXSCode] = useState('');
    const [searchProductType, setSearchProductType] = useState('asset');
    const [availableStock, setAvailableStock] = useState('stock');
    const [guideText, setGuideText] = useState('');

    const searchProductTypeInfo = {
        goods: {
            columnDefs: [
                {field: "rowSeq", headerName: "순번", hide: true},
                {field: "storageNo", headerName: "입고번호", width: 120},
                {field: "lg", headerName: "분류", width: 120},
                {field: "productCode", headerName: "제품코드", width: 120},
                {field: "brandName", headerName: "제조사", width: 90},
                {field: "productName", headerName: "제품명", width: 200},
                {field: "model", headerName: "모델명", width: 200},
                {field: "stockQty", headerName: "재고수량", valueFormatter: v => v.value?.toLocaleString()},
                {field: "spec", headerName: "스펙", minWidth: 200, flex: 1},
                /*{field: "companyName", headerName: "회사"},*/
                {field: "productSeq", headerName: "제품 시퀀스", hide: true},
            ],
            url: '/storage/stock/list'
        },
        asset: {
            columnDefs: [
                {field: "productSeq", headerName: "제품 시퀀스", hide: true},
                {field: "assetType", headerName: "자산구분", width: 100, valueGetter: v => findCommKrnm(v.data.assetType, '7')},
                {field: "lgCate", headerName: "대분류"},
                {field: "productCode", headerName: "제품코드", width: 120, hide: true},
                {field: "brandName", headerName: "제조사", width: 90},
                {field: "productName", headerName: "제품명", width: 200},
                {field: "model", headerName: "모델명", width: 170},
                {field: "totalRentAvailableCnt", headerName: "총가용수량", width: 110},
                {field: "rentAvailableCnt", headerName: "대여가능수량", width: 120},
                {field: "reservationCnt", headerName: "나의 예약수량", width: 130},
                {field: "totalReservationCnt", headerName: "총 예약수량", width: 130},
                {field: "releaseAskWaitCount", headerName: "출고대기수량", width: 130},
                {field: "spec", headerName: "스펙", minWidth: 250},
                /*{field: "companyCode", headerName: "회사", width: 120, valueGetter: v => findCommKrnm(v.data.companyCode, '20')},*/
            ],
            url: '/asset/stock/list',
        }
    }
    const searchItemHandle = (item) => {
        return searchProductTypeInfo[item].columnDefs;
    }

    useEffect(() => {
        getProductGroupList();

        if(props.releaseAskType === '2'){
            setSearchProductType('goods');
            setGuideText('기타 출고는 자산이 아닌 소모품만 출고가 가능합니다.');
        }

    }, [props.releaseAskType, AppStore.isStockProductSearch]);

    /* 제품 카테고리 조회 */
    const getProductGroupList = async () => {
        const result = await axios.get('/product/group/all', { params: { isUsed: 1 } });
        if(result.length > 0){
            setProductGroupList(result);
            setLGList(result.filter(v => v.parentSeq === null));
        }
    }

    /* 대분류 클릭 시 중분류 담아주기 */
    const onChangeLGCode = (e) => {
        setSelectedLGCode(e.target.value);
        setMDList(productGroupList.filter(v => v.parentSeq === Number(e.target.value)));
        setSMList([]);
        setXSList([]);
    }

    /* 중분류 클릭 시 소분류 담아주기 */
    const onChangeMDCode = (e) => {
        setSelectedMDCode(e.target.value);
        setSMList(productGroupList.filter(v => v.parentSeq === Number(e.target.value)));
        setXSList([]);
    }

    /* 소분류 클릭 이벤트 */
    const onChangeSMCode = (e) => {
        setSelectedSMCode(e.target.value);
        setXSList(productGroupList.filter(v => v.parentSeq === Number(e.target.value)));
    }

    /* 세분류 클릭 이벤트 */
    const onChangeXSCode = (e) => {
        setSelectedXSCode(e.target.value);
    }

    /* 제조사 값 설정 */
    const onChangeBrandName = (v) => {
        setBrandCode(v.target.value);
    }

    /* 조회 */
    const onSearch = async () => {
        const params = {
            lgSeq: props.releaseAskType === '2' ? '14' : selectedLGCode,
            mdSeq: selectedMDCode,
            smSeq: selectedSMCode,
            xsSeq: selectedXSCode,
            productCode,
            productName,
            model,
            brandCode,
            isDeleted: 0,
            available: availableStock,
            etcReleaseAsk: props.isEtcReleaseAsk ? true : false,    // 기타출고의뢰등록일 경우 매퍼에서 조건처리할 변수
            ...searchProductTypeInfo[searchProductType].params,
        };
        const result = await axios.get(searchProductTypeInfo[searchProductType].url, { params });

        setSearchList(result);
    }
    const callBackGridData = ({ selectedList }) => {
        if(selectedList.some(v => v.rentAvailableCnt <= 0)){
            showToast('재고가 없는 제품이 선택되었습니다.');
            return;
        }

        callbackFn && callbackFn(selectedList);
        AppStore.toggleStockProductSearchModal();
    }

    const selectedRow = (e) => {
        callbackFn({assetNo: e.data.assetNo, storageNo: e.data.storageNo});

        AppStore.toggleStockProductSearchModal();
    }

    /* 초기화 */
    const refresh = () => {
        setSearchList([]);
        setSearchProductType('asset');    // 기타출고의뢰 등록에서 넘어왔을 경우 goods로 고정
        setAvailableStock('stock');
        setBtnStatus('add');
        setGuideText('');
/*        setLGList([]);
        setMDList([]);
        setSMList([]);
        setXSList([]);
        setSelectedLGCode('');
        setSelectedMDCode('');
        setSelectedSMCode('');
        setSelectedXSCode('');*/
    }

    /* 제품조회 팝업 콜백 */
    const callbackProductInfo = (info) => {
        setProductCode(info.productCode);
        setProductName(info.productName);
    }

    const largeCateOptionsHandler =()=> {
        let options = [];
        lgList.forEach((lg,idx) => {
            options.push(<option key={idx} value={lg.productGroupSeq}>{lg.productGroupName}</option>);
        });

        /* 기타출고의뢰 등록일 경우 일반 소모품만 보이게 */
        if(props.isEtcReleaseAsk && props.releaseAskType === '2'){
            options = lgList.filter(lg => [14].includes(lg.productGroupSeq)).map((lg,idx) => {
                return <option key={idx} value={lg.productGroupSeq}>{lg.productGroupName}</option>
            });
        }

        return options;
    }

    return (
        <Modal
            title={'재고조회'}
            show={AppStore.isStockProductSearch}
            onHide={() => AppStore.toggleStockProductSearchModal()}
            toggleValue={AppStore.isStockProductSearch}
            onExit={refresh}
        >
            <CContainer search>
                <Row>
                    <CCol lg={3}>
                        <Form.Check
                            inline
                            label={"자산"}
                            name="productType"
                            type={'radio'}
                            id='searchProductType-2'
                            value={'asset'}
                            checked={searchProductType === 'asset'}
                            onChange={(e)=> {
                                setSearchProductType(e.target.value);
                                setSearchList([]);
                            }}
                            disabled={(props.isEtcReleaseAsk && props.releaseAskType === '2') || contractType === '3'}
                        />
                        <Form.Check
                            inline
                            label={"상품"}
                            name="productType"
                            type={'radio'}
                            id='searchProductType-1'
                            value={'goods'}
                            checked={searchProductType === 'goods' || contractType === '3'}
                            onChange={(e)=> {
                                setSearchProductType(e.target.value);
                                setSearchList([]);
                            }}
                            disabled={contractType === '4'}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup label={'제품명'}
                                     labelId={'productName'}
                                     value={productName}
                                     onCallbackBtn={() => AppStore.toggleProductListSearchModal()}
                                     onEmptyBtn={()=> {
                                         setProductCode('');
                                         setProductName('');
                                     }}
                                     onChange={(v)=> setProductName(v.target.value)}
                                     enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={3}>
                        <CInputGroup
                            label={'모델명'}
                            labelId={'model'}
                            value={model}
                            onChange={(v)=> setModel(v.target.value)}
                            enterKeyHint={onSearch}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select aria-label="선택" value={brandCode} onChange={onChangeBrandName}>
                                <option value={''}>- 제조사 -</option>
                                { <CommcodeSMOptions commKind={'18'} />}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={3}>
                        <>
                        <Form.Check
                            inline
                            label="전체"
                            name="availableStock"
                            type={'radio'}
                            id="all"
                            value={''}
                            checked={availableStock === ''}
                            onChange={(e)=> setAvailableStock(e.target.value)}
                            disabled={contractType === '3' || contractType === '4'}
                        />
                        <Form.Check
                            inline
                            label="임대가능"
                            name="availableStock"
                            type={'radio'}
                            id='stock'
                            value={'stock'}
                            checked={availableStock === 'stock'}
                            onChange={(e)=> setAvailableStock(e.target.value)}
                        />
                        </>
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={selectedLGCode} onChange={onChangeLGCode}>
                                <option value={0}>-대분류-</option>
                                {largeCateOptionsHandler()}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={selectedMDCode} onChange={onChangeMDCode}>
                                <option value={0}>- 중분류 -</option>
                                {mdList.map(md => (
                                    <option key={md.productGroupSeq} value={md.productGroupSeq}>{md.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={selectedSMCode} onChange={onChangeSMCode}>
                                <option value={0}>- 소분류 -</option>
                                {smList.map(sm => (
                                    <option key={sm.productGroupSeq} value={sm.productGroupSeq}>{sm.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={selectedXSCode} onChange={onChangeXSCode}>
                                <option value={0}>- 세분류 -</option>
                                {xsList.map(xs => (
                                    <option key={xs.productGroupSeq} value={xs.productGroupSeq}>{xs.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={6} style={{color: 'red'}}>
                        {guideText}
                    </CCol>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={onSearch} />
                    </Col>
                </Row>
            </CContainer>
            <CContainer>
                <AgGridContainer
                    gridRef={gridRef}
                    height={40}
                    rowData={searchList}
                    columnDefs={searchItemHandle(searchProductType)}
                    isCheckBox={btnStatus === 'add' ? true : false}
                    useIntoTheTab={true}
                    callBackGridData={callBackGridData}
                    selectBtnInfo={{isUsed: true}}
                    btnTitles={{save: '선택'}}
                    seqColumn={searchProductType === 'goods' ? 'rowSeq' : 'assetNo'}
                    // rowDoubleClickCallback={ContractDetailStore.contractNo ? '' :selectedRow}
                    rowDoubleClickCallback={btnStatus === 'row' ? selectedRow : ''}
                    getRowStyle={(params) => {
                        if (searchProductType === 'asset' && (params.data.rentAvailableCnt <= 0)) {
                            return { pointerEvents: 'none', color: '#848484' };
                        } else if (searchProductType === 'goods' && (params.data.stockQty <= 0)) {
                            return { pointerEvents: 'none', color: '#848484' };
                        }
                        return null;
                    }}
                />
            </CContainer>
            <ProductListSearch callbackFn={callbackProductInfo}/>
        </Modal>
    )
}

export default observer(StockProductSearch);
