import { observer } from 'mobx-react-lite';
import { Modal, Button } from "react-bootstrap";
import { AppStore } from '../store/AppStore';

/**
 * @param {String} title: 모달 제목
 * @param {Function} onExit: 창닫을때 실행될 함수
 */
const ModalComponent = ({title, children, size='xl', fullscreen=false, onHide=null, onExit, toggleValue=null, onShow, className}) => {
    const closeToggle = onHide || (() => AppStore.toggleModal());
    const showValue = toggleValue === null ? AppStore.isOpenForModal : toggleValue;
    return (
        <Modal
            show={showValue}
            onHide={closeToggle}
            backdrop='static'
            aria-labelledby='contained-modal-title-vcenter'
            size={size}
            fullscreen={fullscreen}
            centered
            animation={true}
            scrollable={true}
            restoreFocus={true}
            onExited={onExit}
            onShow={onShow}
            keyboard={false}
            contentClassName={className}
        >
            <Modal.Header closeButton closeVariant={'white'} style={{height: 50, backgroundColor: '#1c2436'}}>
                <i className="fi fi-rr-apps" style={{marginRight: 10, color: '#01aaeb'}} />
                <Modal.Title style={{color: '#fff', fontSize: 18}}>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor: 'rgba(245, 247, 251, 0.6)'}}>{children}</Modal.Body>
        </Modal>
    );
};

export default observer(ModalComponent);
