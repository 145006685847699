import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import koLocale from '@fullcalendar/core/locales/ko';
import axios from 'axios';

import { CContainer } from '../../components/CustomContainer';
import { dateFormat, startDateForMonth, endDateForMonth } from '../../utils/dateUtils';
import ShipSchedulePopup from './popup/ShipSchedulePopup';
import Modal from '../../components/Modal';
import { AppStore } from '../../store/AppStore';
import AsSchedulePopup from './popup/AsSchedulePopup.js';
import { AsStore } from '../../store/asset/AsStore.js';
import { decrypt } from '../../common/utils.js';

const ShipSchedule = () => {
    const [height, setHeight] = useState(window.innerHeight);
    const [eventsData, setEventsData] = useState([]);
    const [shipSeq, setShipSeq] = useState(null);
    const [division, setDivision] = useState('');
    const [shipHopeTime, setShipHopeTime] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [siteName, setSiteName] = useState('');
    const [contractNo, setContractNo] = useState('');
    const [notReceiveAmount, setNotReceiveAmount] = useState('');
    const [shipType, setShipType] = useState('');
    const [outsourcingInfo, setOutsourcingInfo] = useState('');
    const [outsourcingAmount, setOutsourcingAmount] = useState('');

    const getShipSchedule = async (startDate, endDate) => {
        const result = await axios.get('/ship/schedule', { params: { startDate: startDate, endDate: endDate }});
        settingData(result);
    };

    const getAsSchedule = async (startDate, endDate) => {
        const result = await axios.get('/as/schedule', { params: { startDate: startDate, endDate: endDate }});
        settingAsData(result);
    }

    const handleDatesSet = (dateInfo) => {
        const date = dateFormat(dateInfo.view.currentStart);
        const startDate = startDateForMonth(date);
        const endDate = endDateForMonth(date);

        getShipSchedule(startDate, endDate);
        getAsSchedule(startDate, endDate);
    };

    const handleEventClick = (info) => {
        if(info.event._def.extendedProps.divisionTitle === 'AS') {
            getAsDetail(info.event.id);
        }else {
            setShipSeq(info.event.id);
            setDivision(info.event._def.extendedProps.divisionTitle);
            setShipHopeTime(info.event._def.extendedProps.shipHopeTime);
            setCustomerName(info.event._def.extendedProps.customerName);
            setSiteName(info.event._def.extendedProps.siteName);
            setContractNo(info.event._def.extendedProps.contractNo);
            setNotReceiveAmount(info.event._def.extendedProps.notReceiveAmount);
            setShipType(info.event._def.extendedProps.shipType);
            setOutsourcingInfo(info.event._def.extendedProps.outsourcingInfo);
            setOutsourcingAmount(info.event._def.extendedProps.outsourcingAmount);
            AppStore.toggleModal();
        }
    }

    const settingData = (list) => {
        const newList = list.map(item => ({
            id: item.shipSeq,
            divisionTitle: item.division,
            title: getTitle(item.divisionTitle, item.siteName, item.qty, item.shipHopeTime), 
            start: `${item.shipHopeDate}`, 
            textColor: item.asStatusText === '완료' ? 'lightgray' : 'white',
            borderColor: item.division !== '출고/회수' ? item.division === '완료' ? 'gray' : item.division === '회수' ? 'green' : 'blue' : 'orange', 
            backgroundColor: item.division !== '출고/회수' ? item.division === '완료' ? 'gray' : item.division === '회수' ? 'green' : 'blue' : 'orange', 
            division: item.division,
            shipHopeTime: item.shipHopeTime,
            customerName: item.customerName,
            siteName: item.siteName,
            contractNo: item.contractNo,
            notReceiveAmount: item.notReceiveAmount?.toLocaleString(),
            shipType: item.shipType,
            outsourcingInfo: item.outsourcingInfo,
            outsourcingAmount: item.outsourcingAmount,
        }));

        setEventsData(v => [...v, ...newList]);
    };

    const settingAsData = (list) => {
        const newList = list.map(item => ({
            id: item.asSeq,
            divisionTitle: 'AS',
            start: `${item.acceptedDateFormat}`, 
            title: getAsTitle(item.asStatusText, item.siteName, item.acceptUserName, item.modelNames, item.processDetail),
            textColor: item.asStatusText === '완료' ? 'lightgray' : 'white',
            borderColor: item.asStatusText === '완료' ? 'gray' : 'rgb(214, 50, 50)',
            backgroundColor: item.asStatusText === '완료' ? 'gray' : 'rgb(214, 50, 50)',
        }));

        setEventsData(v => [...v, ...newList]);
    }

    const getTitle = (divisionTitle, siteName, qty, time) => {
        if(!siteName) return '';
        const customTitle = [divisionTitle ? '['+divisionTitle+']' : ''];
        const customTime = time ? time.includes('시') ? '('+time+')' : '('+time+'시)' : '';
        return `${customTitle} ${customTime} ${siteName} ${qty}대`;
    }

    const getAsTitle = (asStatusText, siteName, acceptUserName, modelNames, processDetail) => {
        return `[AS - ${asStatusText}] ${siteName} / ${acceptUserName} ${modelNames ? '/ '+modelNames : ''} ${processDetail ? '/ '+processDetail : ''}`;
    }

    const getAsDetail = async (asSeq) => {
        const result = await axios.get('/as/detail', {params: {asSeq: asSeq}});
        AsStore.data = result;
        AsStore.data.siteManagerPhone = decrypt(result.siteManagerPhone);

        // 상세 모달 열기
        AppStore.toggleAsSchedulePopup();
    }

    const closedModal = () => {
        setShipSeq(null);
    }

    const colorInfo = () => {
        return (
            <div className="fc-toolbar" style={{marginBottom: 20}}>
                <div className="fc-left">
                    <span className="circle blue"></span> 출고&nbsp;&nbsp;&nbsp;
                    <span className="circle green"></span> 회수&nbsp;&nbsp;&nbsp;
                    <span className="circle orange"></span> 교환&nbsp;&nbsp;&nbsp;
                    <span className="circle red"></span> AS&nbsp;&nbsp;&nbsp;
                    <span className="circle gray"></span> 완료
                </div>
            </div>
        );
    };

    return (
        <>
            <CContainer>
                <div style={{textDecoration: 'none', color: 'inherit'}}>
                    {colorInfo()}
                    <FullCalendar
                        plugins={[dayGridPlugin]}
                        headerToolbar={{
                            left: 'dayGridMonth,dayGridWeek,dayGridDay',
                            center: 'title',
                        }}
                        height={height}
                        dayMaxEvents={true}
                        events={eventsData}
                        locale={koLocale}
                        eventClick={handleEventClick}
                        datesSet={handleDatesSet}
                    />
                </div>
            </CContainer>

            <Modal title={'배송 스케쥴 상세 정보'} onExit={closedModal} size={'md'}>
                <ShipSchedulePopup shipSeq={shipSeq} division={division} shipHopeTime={shipHopeTime} customerName={customerName} siteName={siteName} 
                                    contractNo={contractNo} notReceiveAmount={notReceiveAmount} shipType={shipType} outsourcingInfo={outsourcingInfo} outsourcingAmount={outsourcingAmount} />
            </Modal>
            <AsSchedulePopup />
        </>
      );

};

export default observer(ShipSchedule);
