import { observer } from "mobx-react-lite"
import Modal from "../../../components/Modal";
import { CCol, CContainer, CIconBtn, CInputGroup } from "../../../components/CustomContainer";
import { AppStore } from "../../../store/AppStore";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { decrypt, encrypt, showToast } from "../../../common/utils";
import { getLocalStorage } from "../../../utils";
import { USER_INFO } from "../../../common/constants";
import axios from "axios";
const SendSmsPopup = ({receiverName, setReceiverName, receiverPhoneNumber, setReceiverPhoneNumber, sendAssetList}) => {
    const userInfo = getLocalStorage(USER_INFO);
    const refresh = () => {
    }
    const sendSms = async() => {
        const result = await axios.post("/asset/expiration/sms", {receiverName: receiverName, receiverPhoneNumber: receiverPhoneNumber, assetList: sendAssetList});
        if(result.result === 'option_failed') {
            alert(result.message);
        }else if(result.fail > 0){
            showToast('실패하였습니다.(결과 메세지 확인)');
        }else{
            showToast('전송되었습니다.');
            AppStore.toggleExpirationAssetSmsPopup();
        }
    }
    return (
        <Modal toggleValue={AppStore.isOpenExpirationAssetSms}
                onHide={()=> AppStore.toggleExpirationAssetSmsPopup()}
                className='modal-w5'
                title={'문자 발송'}
                onExit={refresh}>
            <CContainer search>
                <Row>
                    <CCol lg={12}>
                        <div className="btn-group btn-group-toggle mb-4" style={{display: 'flex', justifyContent: 'center'}} >
                            {/* <label style={{margin: 'auto-flex', textAlign: 'center', verticalAlign: 'middle'}}> */}
                                <div style={{backgroundColor: '#A3BAD1', textAlign: 'left', width: '90%', marginTop: 10, paddingTop: 30, paddingBottom: 30, paddingLeft: 20, paddingRight: 20, margin: 'auto', marginBottom: 10}}>
                                    <div style={{backgroundColor: 'white'}}>
                                        <h4 style={{backgroundColor: '#FDE60B', padding: 10, textAlign: 'center'}}>알림톡 미리보기</h4>
                                        <div style={{padding: 10}}>
                                            <p className="fs-6">
                                                안녕하세요. {userInfo.companyName}입니다. <br/>
                                                고객님께서 대여하신 장비의 만기일자가 도래되어 안내 문자를 발송합니다. <br/><br/>
                                                
                                                {sendAssetList.map((v, i) => {
                                                    return (
                                                        <span key={i}>
                                                            {i+1}. {v.assetNos.length > 1 ? (v.models[0] + " 외 " + (v.assetNos.length-1) + "대") : v.models[0]} {"(" + v.contractEndDate + " 만기)"} <br/>
                                                        </span>
                                                    )
                                                })}
                                                <br/>
                                                고객님은 계약만료일에 맞추어 반납 및 연장을 하실 수 있습니다.<br/>
                                                반납을 희망하실 경우 담당자에게 회신을 주시면 회수를 진행하며, <br/>
                                                만약 회신이 없으실 경우에는 자동연장으로 처리됨을 알려 드립니다.<br/>
                                                감사합니다.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            {/* </label> */}
                        </div>
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={12}>
                        <CInputGroup
                            label={'수신자명'}
                            labelId={'receiverName'}
                            value={receiverName}
                            onChange={v => setReceiverName(v.target.value)}
                            placeholder={'ex. 홍길동'}
                            labelClassName={'input-required'}
                        />
                    </CCol>
                    <CCol lg={12}>
                        <CInputGroup
                            label={'휴대폰번호'}
                            labelId={'receiverPhoneNumber'}
                            value={decrypt(receiverPhoneNumber)}
                            onChange={v => setReceiverPhoneNumber(encrypt(v.target.value))}
                            placeholder={'ex. 01012345678'}
                            labelClassName={'input-required'}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col lg={12} className='d-flex mt-3' style={{justifyContent: 'center'}}>
                        <CIconBtn style={{width: 120}}
                                title={'알림톡 전송'}
                                onClick={sendSms}
                                variant={'primary'}
                        />
                    </Col>
                </Row>
            </CContainer>
        </Modal>
    )
}
export default observer(SendSmsPopup);