import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { AgGridReact } from 'ag-grid-react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';

import ContentHeader from '../../components/ContentHeader';
import { refreshCommcode } from '../../utils/commcode';
import { CInputGroup, CCol, CUpdateBtn, CCancelBtn } from '../../components/CustomContainer';
import { callAlert } from '../../utils';
import LUtils from '../../utils/lodashUtils';
import { CommCodeStore } from '../../store/commCode/CommCodeStore';
import { getUserName } from '../../utils/userUtils';

const CommCodeList = () => {
    const [ccMD, setCcMD] = useState([]);
    const [ccSM, setCcSM] = useState([]);
    const [ccMDList, setCcMDList] = useState({commCodeSeq: 0, commKind: '0', commCode: '', commKrnm: '', useYn: false});
    const [ccSMList, setCcSMList] = useState({commCodeSeq: 0, commKind: '', seq: 1, commCode: '', commKrnm: '', useYn: false});
    const [isEnableUpdate, setIsEnableUpdate] = useState({isMD: false, isSM: false});
    const [isEnableInsert, setIsEnableInsert] = useState({isMD: false, isSM: false});
    const [isDisabled, setIsDesabled] = useState({isMD: true, isSM: true});

    useEffect(() => {
        getCommCodeList();  // 화면 진입시 commCode 리스트 조회
    }, []);

    const getCommCodeList = async () => {
        const commCodeList = await axios.get("/commCode");
        CommCodeStore.setCommCodeList(commCodeList);
        CommCodeStore.setMDList(commCodeList.filter(v => v.commKind === '0'));  // store에 중분류 list 저장
        setCcMD(CommCodeStore.mdList);  // 중분류 리스트에 값 담기
    }

    const getCommCodeSMList = async () => {
        const commCodeList = await axios.get("/commCode");
        CommCodeStore.setCommCodeList(commCodeList);
        CommCodeStore.setSMList(commCodeList.filter(v => v.commKind === ccMDList.commCode));  // store에 중분류 list 저장
        setCcSM(CommCodeStore.smList);  // 중분류 리스트에 값 담기
    }

    // 사용여부 스위치 클릭 이벤트
    const handleSwitchChange = (isMD, e) => {
        const { checked } = e.target;
        if (isMD) {
            setCcMDList(v => {return {...v, useYn: checked}});  // 중분류 input useYn 값 담아주기
        } else {
            setCcSMList(v => {return {...v, useYn: checked}});  // 소분류 input useYn 값 담아주기
        }
    };

    // 중분류 리스트 row 클릭 이벤트
    const onMDRowClick = (e) => {
        setCcSM(CommCodeStore.commCodeList.filter(v => v.commKind === e.data.commCode));    // 소분류 리스트에 값 담기
        // 클릭한 row -> 중분류 input 값 담기
        setCcMDList({
            commCodeSeq: e.data.commCodeSeq,
            commKind: '0',
            commCode: e.data.commCode,
            commKrnm: e.data.commKrnm,
            useYn: e.data.useYn === 'Y' ? true : false,
        });
        setCcSMList({commCodeSeq: 0, commKind: '', seq: 1, commCode: '', commKrnm: '', useYn: false});  // 소분류 input 값 초기화
        setIsEnableUpdate({isMD: false, isSM: false});  // 중분류/소분류 수정 버튼 초기화
        setIsEnableInsert({isMD: false, isSM: false});  // 중분류/소분류 저장 버튼 초기화
        setIsDesabled({isMD: true, isSM: true});        // 중분류/소분류 input 비활성화
    }

    // 소분류 리스트 row 클릭 이벤트
    const onSMRowClick = (e) => {
        // 클릭한 row -> 소분류 input 값 담기
        setCcSMList({
            commCodeSeq: e.data.commCodeSeq,
            commKind: e.data.commKind,
            seq: e.data.seq,
            commCode: e.data.commCode,
            commKrnm: e.data.commKrnm,
            useYn: e.data.useYn === 'Y' ? true : false,
        });
        setIsEnableUpdate({isMD: false, isSM: false});  // 중분류/소분류 수정 버튼 초기화
        setIsEnableInsert({isMD: false, isSM: false});  // 중분류/소분류 저장 버튼 초기화
        setIsDesabled({isMD: true, isSM: true});        // 중분류/소분류 input 비활성화
    }

    // 수정 버튼 활성화 이벤트
    const handleUpdateClick = (isMD, isUpdate) => {
        // 중분류 && 선택된 행이 있는 경우
        if(isMD && ccMDList.commCode){
            if(isUpdate){   // 수정 버튼
                setIsEnableUpdate({isMD: isUpdate, isSM: !isUpdate});   // 중분류만 수정 버튼 활성화
                setIsDesabled({isMD: !isUpdate, isSM: isUpdate});       // 중분류만 input 활성화
            }else { // 취소 버튼
                setIsEnableUpdate({isMD: isUpdate, isSM: isUpdate});    // 중분류 수정 버튼 비활성화
                setIsDesabled(v => {return {...v, isMD: !isUpdate}});   // 중분류 input 비활성화
            }
        // 소분류 && 선택된 행이 있는 경우
        }else if(!isMD && ccSMList.commCode){
            if(isUpdate){   // 수정 버튼
                setIsEnableUpdate({isMD: !isUpdate, isSM: isUpdate});   // 소분류만 수정 버튼 활성화
                setIsDesabled({isMD: isUpdate, isSM: !isUpdate});       // 소분류만 input 활성화
            }else { // 취소 버튼
                setIsEnableUpdate({isMD: isUpdate, isSM: isUpdate});    // 소분류 수정 버튼 비활성화
                setIsDesabled(v => {return {...v, isSM: !isUpdate}});   // 소분류 input 비활성화
            }
        }
    }

    // 추가 버튼 활성화 이벤트
    const handleInsertClick = (isMD, isInsert) => {
        if(isMD){   // 중분류
            setIsEnableInsert(v => {return {...v, isMD: isInsert}});    // 중분류만 추가 버튼 활성화
            setIsDesabled(v => {return {...v, isMD: !isInsert}});       // 중분류만 input 활성화
            setCcSM([]);    // 소분류 리스트 초기화
            setCcSMList({commCodeSeq: 0, commKind: '', seq: 1, commCode: '', commKrnm: '', useYn: false});  // 소분류 input 초기화
            if(isInsert){   // 추가 버튼
                // 중분류 input 값 세팅
                setCcMDList({
                    commCodeSeq: 0,
                    commKind: '0',
                    commCode: Number(ccMD[ccMD.length - 1].commCode) + 1,   // commCode 값 + 1
                    commKrnm: '',
                    useYn: true
                });
                setIsEnableInsert(v => {return {...v, isSM: !isInsert}});   // 소분류 추가 버튼 비활성화
                setIsDesabled(v => {return {...v,isSM: isInsert}});         // 소분류 input 비활성화
            }else { // 취소 버튼
                setCcMDList({commCodeSeq: 0, commKind: '0', commCode: '', commKrnm: '', useYn: false}); // 중분류 input 값 초기화
            }
        // 소분류 && 중분류 row 가 클릭된 경우(선택된 중분류가 있는 경우)
        }else if(!isMD && !LUtils.isEmpty(ccMD.filter(v => v.commCode === ccMDList.commCode))){
            setIsEnableInsert(v => {return {...v, isSM: isInsert}});    // 소분류만 추가 버튼 활성화
            setIsDesabled(v => {return {...v,isSM: !isInsert}});        // 소분류만 input 활성화
            if(isInsert){   //추가
                // 소분류 input 값 세팅
                setCcSMList({
                    commCodeSeq: 0,
                    commKind: ccMDList.commCode,
                    seq: _.isEmpty(ccSM) ? 1 : ccSM.map(v => v.seq).sort((a, b) => b-a)[0] + 1,
                    commCode: '',
                    commKrnm: '',
                    useYn: true
                });
            }else { // 삭제
                setCcSMList({commCodeSeq: 0, commKind: '', seq: 1, commCode: '', commKrnm: '', useYn: false});  // 소분류 input 값 초기화
            }
        }
    }

    // 추가(저장)
    const insertCommCode = async (isMD) => {
        const params = isMD ? ccMDList : ccSMList;
        const commCode = isMD ? ccMDList.commCode : ccSMList.commCode;
        const commKrnm = isMD ? ccMDList.commKrnm : ccSMList.commKrnm;

        // 중분류/소분류 input 값 존재 여부 확인
        if (LUtils.isEmpty(commCode) && LUtils.isEmpty(commKrnm)) {
            await callAlert('입력값을 모두 입력해야 합니다.');
            return;
        }

        await axios.post('/commCode', params);
        await refreshCommcode();    // localstorage commCode 다시 세팅
        if (isMD) { // 중분류
            getCommCodeList();  // 중분류 list 다시 가져오기
        } else {    // 소분류
            getCommCodeSMList();
        }
        await callAlert('추가되었습니다.');
        handleInsertClick(isMD, false); // 추가 완료 후 추가 버튼 비활성화
        // location.reload();
    }

    // 수정
    const updateCommCode = async (isMD) => {
        const params = isMD ? ccMDList : ccSMList;
        const commCode = isMD ? ccMDList.commCode : ccSMList.commCode;
        const commKrnm = isMD ? ccMDList.commKrnm : ccSMList.commKrnm;

        // 중분류/소분류 input 값 존재 여부 확인
        if (LUtils.isEmpty(commCode) && LUtils.isEmpty(commKrnm)) {
            await callAlert('입력값을 모두 입력해야 합니다.');
            return;
        }

        await axios.put('/commCode/'+params.commCodeSeq, params);
        await refreshCommcode();    // localstorage commCode 다시 세팅
        if (isMD) { // 중분류
            getCommCodeList();  // 중분류 list 다시 가져오기
        } else {    // 소분류
            getCommCodeSMList();
        }
        await callAlert('수정되었습니다.');
    }

    return(
        <>
        <Container fluid>
            <Row>
                <Col lg={6}>
                    <Form className='rounded m-2 p-3' style={{backgroundColor: '#ffffff'}} >
                        <Row>
                            <Col className='d-flex justify-content-between mb-4'>
                                <h4 className='float-start'>중분류</h4>
                                <div>
                                {isEnableInsert.isMD && !isEnableUpdate.isMD ? (
                                    <>
                                    <CCancelBtn onClick={(e) => handleInsertClick(true, false)} size={'md'} />
                                    <Button className='ms-3' variant="success" style={{width: '70px'}} onClick={() => insertCommCode(true)}>
                                        <i className="me-1 fi fi-rs-disk" />
                                        추가
                                    </Button>
                                    </>
                                ) : (
                                    !isEnableInsert.isMD && !isEnableUpdate.isMD && (
                                    <Button className='me-4' variant="outline-success" style={{width: '70px'}} onClick={(e) => handleInsertClick(true, true)}>
                                        <i className="me-1 fi fi-rs-disk" />
                                        추가
                                    </Button>
                                    )
                                )}
                                {isEnableUpdate.isMD && !isEnableInsert.isMD ? (
                                    <>
                                    <CCancelBtn onClick={() => handleUpdateClick(true, false)} size={'md'} />
                                    <CUpdateBtn className={'me-0 ms-3'} onClick={() => updateCommCode(true)} />
                                    </>
                                ) : (
                                    !isEnableInsert.isMD && !isEnableUpdate.isMD && (
                                    <Button variant="outline-warning" style={{width: '70px'}} onClick={() => handleUpdateClick(true, true)} >
                                        <i className="me-1 fi fi-br-pencil" />
                                        수정
                                    </Button>
                                    )
                                )}
                                </div>
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <CCol lg={6}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="사용여부"
                                    disabled={isDisabled.isMD}
                                    checked={ccMDList.useYn}
                                    onChange={(e) => handleSwitchChange(true, e)}
                                />
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={6}>
                                <CInputGroup disabled label={'공통코드'} labelId={'commCode'} placeholder={ccMDList.commCode} />
                            </CCol>
                            <CCol lg={6}>
                                <CInputGroup
                                    disabled={isDisabled.isMD}
                                    label={'코드명'}
                                    labelId={'commKrnm'}
                                    value={ccMDList.commKrnm}
                                    onChange={(e) => setCcMDList({ ...ccMDList, commKrnm: e.target.value })}
                                />
                            </CCol>
                        </Row>
                    </Form>
                    <Form className='rounded m-2 p-3' style={{backgroundColor: '#ffffff'}}>
                        <Row className='ag-theme-alpine' style={{height: '55vh'}}>
                            <AgGridReact
                                rowData={ccMD}
                                suppressCellSelection={true}
                                columnDefs={[
                                    {field: "commCode", headerName: "공통코드", sortable: true, minWidth: 90, flex:1},
                                    {field: "commKrnm", headerName: "코드명", sortable: true, minWidth: 100, flex:1},
                                    {field: "useYn", valueFormatter: v => v.data.useYn === 'Y' ? '사용' : '미사용', headerName: "사용여부", sortable: true, minWidth: 90, flex:1},
                                    {field: "updater", valueGetter: (params) => !!params.data.updater ? getUserName(params.data.updater) : getUserName(params.data.creator) , headerName: "수정자", sortable: true, minWidth: 80, flex:1},
                                    {field: "updatedDate", valueGetter: (params) => params.data.updatedDate ?? params.data.createdDate, valueFormatter: (params) => {
                                        const [year, month, day] = params.value;
                                        return new Intl.DateTimeFormat("ko-KR", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                        }).format(new Date(year, month - 1, day));
                                      }, headerName: "수정일", sortable: true, minWidth: 120, flex:1},
                                ]}
                                onRowClicked={onMDRowClick}
                            />
                        </Row>
                    </Form>
                </Col>
                <Col lg={6}>
                    <Form className='rounded m-2 p-3' style={{backgroundColor: '#ffffff'}} >
                        <Row>
                            <Col className='d-flex justify-content-between mb-4'>
                                <h4 className='float-start'>소분류</h4>
                                <div>

                                {isEnableInsert.isSM && !isEnableUpdate.isSM ? (
                                    <>
                                    <CCancelBtn onClick={(e) => handleInsertClick(false, false)} size={'md'} />
                                    <Button className='ms-3' variant="success" style={{width: '70px'}} onClick={() => insertCommCode(false)}>
                                        <i className="me-1 fi fi-rs-disk" />
                                        추가
                                    </Button>
                                    </>
                                ) : (
                                    !isEnableInsert.isSM && !isEnableUpdate.isSM && (
                                    <Button className='me-4' variant="outline-success" style={{width: '70px'}} onClick={() => handleInsertClick(false, true)}>
                                        <i className="me-1 fi fi-rs-disk" />
                                        추가
                                    </Button>
                                    )
                                )}
                                {isEnableUpdate.isSM && !isEnableInsert.isSM ? (
                                    <>
                                    <CCancelBtn onClick={(e) => handleUpdateClick(false, false)} size={'md'} />
                                    <CUpdateBtn className={'me-0 ms-3'} onClick={() => updateCommCode(false)} />
                                    </>
                                ) : (
                                    !isEnableInsert.isSM && !isEnableUpdate.isSM && (
                                    <Button variant="outline-warning" style={{width: '70px'}} onClick={(e) => handleUpdateClick(false, true)} >
                                        <i className="me-1 fi fi-br-pencil" />
                                        수정
                                    </Button>
                                    )
                                )}
                                </div>
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <CCol lg={6}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="사용여부"
                                    disabled={isDisabled.isSM}
                                    checked={ccSMList.useYn}
                                    onChange={e => handleSwitchChange(false, e)}
                                />
                            </CCol>
                        </Row>
                        <Row>
                            <CCol lg={6}>
                                <CInputGroup
                                    disabled={isDisabled.isSM}
                                    label={'공통코드'}
                                    labelId={'commCode'}
                                    value={ccSMList.commCode}
                                    onChange={(e) => setCcSMList({ ...ccSMList, commCode: e.target.value })}
                                />
                            </CCol>
                            <CCol lg={6}>
                                <CInputGroup
                                    disabled={isDisabled.isSM}
                                    label={'코드명'}
                                    labelId={'commKrnm'}
                                    value={ccSMList.commKrnm}
                                    onChange={(e) => setCcSMList({ ...ccSMList, commKrnm: e.target.value })}
                                />
                            </CCol>
                        </Row>
                    </Form>
                    <Form className='rounded m-2 p-3' style={{backgroundColor: '#ffffff'}}>
                        <Row className='ag-theme-alpine' style={{height: '55vh'}}>
                            <AgGridReact
                                rowData={ccSM}
                                suppressCellSelection={true}
                                columnDefs={[
                                    {field: "commCode", headerName: "공통코드", sortable: true, minWidth: 90, flex:1},
                                    {field: "commKrnm", headerName: "코드명", sortable: true, minWidth: 100, flex:1},
                                    {field: "useYn", valueFormatter: v => v.data.useYn === 'Y' ? '사용' : '미사용', headerName: "사용여부", sortable: true, minWidth: 90, flex:1},
                                    {field: "updater", valueGetter: (params) => !!params.data.updater ? getUserName(params.data.updater) : getUserName(params.data.creator), headerName: "수정자", sortable: true, minWidth: 80, flex:1},
                                    {field: "updatedDate", valueGetter: (params) => params.data.updatedDate ?? params.data.createdDate, valueFormatter: (params) => {
                                        const [year, month, day] = params.value;
                                        return new Intl.DateTimeFormat("ko-KR", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                        }).format(new Date(year, month - 1, day));
                                      }, headerName: "수정일", sortable: true, minWidth: 120, flex:1},
                                ]}
                                onRowClicked={onSMRowClick}
                                enableCellTextSelection={true}
                            />
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>

        </>
    )
}

export default observer(CommCodeList);
