import AsDetailLinkPage from "../views/asset/AsDetailLinkPage";
import GuestGuard from "../utils/route-guard/GuestGuard";
import { Outlet } from "react-router-dom";

const AsMobileRoutes = {
    path: '/',
    element: (
            <GuestGuard>
                <Outlet />
            </GuestGuard>
        ),
    children: [
        // {path: '/as/accept/detail/:asSeq/login', element: <AsDetailLinkLogin />},
        {path: '/as/accept/detail/:asSeq', element: <AsDetailLinkPage />}
    ]
}

export default AsMobileRoutes;
