import { observable } from "mobx";
import { oneMonthAgo, today } from "../../utils/dateUtils";

export const TaxBillStore = observable({
    search: {
        taxBillType: '',
        startDate: oneMonthAgo(),
        endDate: today(),
        supplyCompanyName: '',
        customerCompanyName: '',
    },
    taxBillList: [],
});
