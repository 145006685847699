import React, { useEffect, useRef } from 'react';
import {Row, Col, Form, Table} from "react-bootstrap";
import { CContainer, CCol, CalendarGroup, CSearchBtn, CInputGroup } from '../../components/CustomContainer';
import { endDateForMonth, getDay, startDateForMonth, dateDiff, addDayDate, getMonth } from '../../utils/dateUtils';
import { observer } from 'mobx-react-lite';
import { useLocation } from "react-router-dom";
import LUtils from "../../utils/lodashUtils";

const AssetOperateRateSearch =({store, getAssetList, monthColumn})=> {

    const location = useLocation();

    useEffect(()=> {
        store.setStartDate({startDate: startDateForMonth(), endDate: null})
        store.setEndDate({startDate: endDateForMonth(), endDate: null})
        store.setSelSearchType('');

        const fromPage = LUtils.get(location.state, 'from', null) ;
        if(fromPage === 'dashboard'){
            getAssetList()
        }
    }, [])

    const dateChangeHandle =async(value, type)=> {
        if(type === 's'){
            store.setStartDate(value);
        }else{
            store.setEndDate(value);
        }
        await monthColumn();
    }

    return (
        <CContainer search>
            <Row>
                <CCol lg={2}>
                    <CalendarGroup label={'시작일'}
                        asSingle={true}
                        value={store.startDate}
                        onChange={async(v)=> await dateChangeHandle(v,'s')}
                    />
                </CCol>
                <CCol lg={2}>
                    <CalendarGroup label={'종료일'}
                        asSingle={true}
                        value={store.endDate}
                        onChange={async(v)=> await dateChangeHandle(v,'e')}
                    />
                </CCol>
                <CCol lg={2}>
                    <CInputGroup label={'관리번호'}
                        value={store.managementNo}
                        onChange={e => store.setManagementNo(e.target.value)}
                        enterKeyHint={getAssetList}
                    />
                </CCol>
                <CCol lg={2}>
                    <CInputGroup label={'모델'}
                        value={store.model}
                        onChange={e => store.setModel(e.target.value)}
                        enterKeyHint={getAssetList}
                    />
                </CCol>
                <CCol lg={4} style={{marginTop: 5}}>
                    <Form.Check
                        inline
                        label="전체"
                        name="selSearchType"
                        type={'radio'}
                        value={''}
                        id={`all`}
                        checked={!store.selSearchType}
                        onChange={() => store.setSelSearchType('')}
                    />
                    <Form.Check
                        inline
                        label="임대중"
                        name="selSearchType"
                        type={'radio'}
                        id={`rentAsset`}
                        value={'1'}
                        checked={store.selSearchType === '1'}
                        onChange={() => store.setSelSearchType('1')}
                    />
                    <Form.Check
                        inline
                        label="임대가능"
                        name="selSearchType"
                        type={'radio'}
                        value={'2'}
                        id={`avaiableAsset`}
                        checked={store.selSearchType === '2'}
                        onChange={() => store.setSelSearchType('2')}
                    />
                </CCol>
            </Row>
            <Row>
                <Col className='d-flex flex-row-reverse'>
                    <CSearchBtn onClick={getAssetList} />
                </Col>
            </Row>
        </CContainer>
    );
}

export default observer(AssetOperateRateSearch);