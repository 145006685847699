import { useEffect } from "react";
import LUtils from "../../utils/lodashUtils";
import { callAlert } from "../../utils";

const ErrorBoundary = ({ children }) => {
  const captureReject = (e) => {
    e.preventDefault();

    const response = LUtils.get(e.reason, 'response.data');
    if (!e.reason instanceof Error || LUtils.isEmpty(response)) {
      callAlert('[서버 에러] 관리자에게 문의 바랍니다.[B]');
      return;
    }

    const { code, message } = response;
    console.error(`[${code}] ${message}`);
    callAlert(message);

    return;
  };

  useEffect(() => {
    window.onunhandledrejection = captureReject;
    return () => {
      window.onunhandledrejection = captureReject;
    }
  }, []);

  return children;
}

export default ErrorBoundary;
