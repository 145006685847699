import { observer } from "mobx-react-lite"
import Modal from "../../../components/Modal"
import { CCol, CContainer, CIconBtn, CInputGroup } from "../../../components/CustomContainer";
import AgGridContainer from "../../../components/AgGridContainer";
import { Col, Row } from "react-bootstrap";
import { AppStore } from "../../../store/AppStore";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { TaxBillStore } from "../../../store/billing/TaxBillStore";
import { decrypt, encrypt, showToast } from "../../../common/utils";
import { callConfirm } from "../../../utils";

const SendTaxBillSmsPopup = ({getBillPublishedList=null}) => {
    const gridRef = useRef();
    const [selectedData, setSelectedData] = useState({});

    useEffect(() => {
        if(AppStore.isOpenSendTaxBillSmsPopup) {
            TaxBillStore.sendTargetList.forEach(v => v.isSelected = false);
            setSelectedData(TaxBillStore.sendTargetList[0]);
        }
    }, [AppStore.isOpenSendTaxBillSmsPopup])
    
    const sendTaxBillSms = async () => {
        
        let selectedList = [];
        if (TaxBillStore.sendTargetList.length === 1) {
            selectedList = TaxBillStore.sendTargetList;
        } else if (TaxBillStore.sendTargetList.length > 1){
            selectedList = TaxBillStore.sendTargetList.filter(v => v.isSelected || v.isUpdated);

            if (selectedList.length === 0) {
                showToast('알림톡을 전송할 대상을 선택해주세요.');
                return;
            }
        }

        if (!selectedList.every(v => v.billingAddressPhoneNumber)) {
            showToast('휴대폰 번호를 모두 입력해주세요.');
            return;
        }

        if (!await callConfirm('알림톡을 전송하시겠습니까?')) {
            return;
        }

        const list = selectedList.map(v => {return {supplyCompanyName: v.supplyCompanyName,
                                                    customerCompanyName: v.customerCompanyName,
                                                    companyCode: v.companyCode,
                                                    billDate: v.billDate,
                                                    receiverPhoneNumber: encrypt(v.billingAddressPhoneNumber),
                                                    billNo: v.billNo,
                                                    billItem: v.billItem}});
        await axios.post('/bill/sms', list);
    
        showToast('알림톡을 전송하였습니다.');
        if (getBillPublishedList != null) {
            await getBillPublishedList();
        }
        AppStore.toggleSendTaxBillSmsPopup();
    }

    const refresh = () => {
        TaxBillStore.sendTargetListInit();
    }

    return (
        <Modal title={'세금계산서 알림톡 전송'}
            onHide={() => {AppStore.toggleSendTaxBillSmsPopup(); }}
            toggleValue={AppStore.isOpenSendTaxBillSmsPopup}
            onExit={refresh}
            className={TaxBillStore.sendTargetList.length === 1 ? 'modal-w5' : 'modal-w12'}
        >
            <CContainer search>
                <>
                    <Row>
                        <CCol lg={TaxBillStore.sendTargetList.length === 1 ? 12 : 5}>
                            <div className="" style={{backgroundColor: '#A3BAD1', textAlign: 'left', width: '90%', marginTop: 10, paddingTop: 30, paddingBottom: 30, paddingLeft: 25, paddingRight: 25, margin: 'auto', marginBottom: 10}}>
                                <div style={{backgroundColor: 'white'}}>
                                    <h4 style={{backgroundColor: '#FDE60B', padding: 10, textAlign: 'center'}}>세금계산서 알림 문자</h4>
                                    <div style={{padding: 15}}>
                                        <p className="fs-6">
                                            안녕하세요, {selectedData.supplyCompanyName} 입니다. <br/>
                                            세금계산서를 발행하였으니 확인해 주시기 바랍니다. <br/>
                                            감사합니다.
                                            <br/><br/>
                                            ▣ 품목 <br/>
                                            - {selectedData.billItem} <br/>
                                            ▣ 발행일자 <br/>
                                            - {selectedData.billDate}
                                            <br/><br/>
                                            ▶계산서 확인 <br/>
                                            (계산서 확인 링크)
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                        {TaxBillStore.sendTargetList.length > 1 &&
                            <CCol lg={7}>
                                <AgGridContainer 
                                    gridRef={gridRef} 
                                    gridTitle={'고객 청구지 목록'}
                                    rowData={TaxBillStore.sendTargetList}
                                    height={20}
                                    columnDefs={[
                                        {field: "seq", headerName: "순번", valueGetter: 'node.rowIndex+1', width: 80},
                                        {field: "customerCompanyName", headerName: "업체명", width: 180},
                                        {field: "billingAddressName", headerName: "청구지명", width: 140},
                                        {
                                            field: "billingAddressPhoneNumber", 
                                            headerName: "휴대폰번호", 
                                            editable: true,
                                            flex:1,  
                                            width: 140, 
                                            headerClass: 'grid-column-required',
                                        },
                                    ]}
                                    isCheckBox={true}
                                    onRowClicked={(e) => setSelectedData(e.data)}
                                />
                            </CCol>
                        }
                    </Row>
                    {TaxBillStore.sendTargetList.length === 1 &&
                        <Row>
                            <CCol lg={12}>
                                <CInputGroup
                                    label={'수신자 번호'}
                                    labelId={'phoneNumber'}
                                    value={TaxBillStore.sendTargetList[0].billingAddressPhoneNumber}
                                    onChange={v => TaxBillStore.sendTargetList[0].billingAddressPhoneNumber = v.target.value}
                                    placeholder={'수신자 번호를 입력해주세요.'}
                                    labelClassName={'input-required'}
                                    style={{width: '90%', margin: 'auto'}}
                                />
                            </CCol>
                        </Row>
                    }
                    <Row>
                        <Col lg={12} className='d-flex mt-3' style={{justifyContent: 'center'}}>
                            <CIconBtn style={{width: 120}}
                                    title={'알림톡 전송'}
                                    onClick={sendTaxBillSms}
                                    variant={'primary'}
                            />
                        </Col>
                    </Row>
                </>
            </CContainer>
        </Modal>
    )
}

export default observer(SendTaxBillSmsPopup);