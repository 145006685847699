import { observer } from "mobx-react-lite"
import { Form, InputGroup, Row, Col } from "react-bootstrap";
import { CCol, CContainer, CInputGroup, CSaveBtn, Subject } from "../../../components/CustomContainer";
import { useRef, useState } from "react";
import AgGridContainer from "../../../components/AgGridContainer";
import { UserTabsStore } from "../../../store/human-resources/UserTabsStore";
import axios from "axios";
import { showToast } from "../../../common/utils";
import { getUserName } from "../../../utils/userUtils";
import { ICON_TRASH } from "../../../common/constants";
import { callConfirm } from "../../../utils";

const AnnualLeaveCount = ({userSeq, selectUserDetail}) => {
    const gridRef = useRef();
    const [startDate, setStartDate] = useState('');
    const [annualLeaveCount, setAnnualLeaveCount] = useState('');
    const [remark, setRemark] = useState('');

    const saveAnnualLeaveCount = async () => {
        const modDate = startDate + "-01-01";   // 입력된 연도의 1월 1일로 날짜형식 맞춰주기
        const params = {
                        userSeq: userSeq, 
                        tabCategory: 'A', 
                        startDate: modDate, 
                        endDate: modDate, 
                        annualLeaveCount: annualLeaveCount, 
                        remark: remark
                    };

        //TODO: UserTabsStore.annualLeaveCountList에 해당연도 데이터가 있으면 리턴시켜야 함! (수정하도록 처리)
        const validList = UserTabsStore.annualLeaveCountList.every(data => startDate !== data.startDate.substring(0, 4));
        if (!validList) {
            showToast(`이미 ${startDate}년도 데이터가 존재합니다.`);
            return;
        }

        if (!startDate || !annualLeaveCount || !remark) {
            showToast('필수값을 모두 입력해주세요.');
            return;
        }

        if (!await callConfirm('저장 하시겠습니까?')) {
            return;
        }

        await axios.post("/user/tabs", params);
        showToast('저장이 완료되었습니다.');
        selectUserDetail();

        setStartDate('');
        setAnnualLeaveCount('');
        setRemark('');
    }


    /* 선택 버튼 옵션 - 삭제 */
    const modifyBtn = {
        isUsed: true,
        title: '수정',
        callbackFn: () => modifyFn(),
        icon: 'fi-rr-file-edit'
    };

    const modifyFn = async () => {
        const updatedList = UserTabsStore.annualLeaveCountList.filter(v => v.isUpdated);
        if (updatedList.length === 0) {
            showToast('수정된 데이터가 없습니다.');
            return;
        }

        if (!await callConfirm('수정 하시겠습니까?')) {
            return;
        }

        await axios.put('/user/tabs', updatedList);
        showToast('수정이 완료되었습니다.');
        selectUserDetail();
    }

    /* 선택 버튼 옵션 - 삭제 */
    const deleteBtn = {
        isUsed: true,
        title: '삭제',
        callbackFn: () => deleteFn(),
        icon: ICON_TRASH,
        variant: 'dark'
    };

    const deleteFn = async () => {
        const selectedList = UserTabsStore.annualLeaveCountList.filter(v => v.isSelected);

        if (selectedList.length === 0) {
            showToast('삭제할 데이터를 선택해주세요.');
            return;
        }

        if (!await callConfirm('삭제 하시겠습니까?')) {
            return;
        }

        await axios.delete('/user/tabs', {data: selectedList});
        showToast('삭제가 완료되었습니다.');
        selectUserDetail();
    }

    const validNumber = (v) => {
        if (isNaN(v.target.value)) {
            showToast('숫자만 입력해야 합니다.');
            return false;
        }

        setAnnualLeaveCount(v.target.value);
    }

    return (
        <>
            <Subject>지급휴가수량</Subject>
            <Row>
                <CCol lg={4}>
                    <CInputGroup 
                        label={'연도'}
                        labelId={'startDate'}
                        value={startDate}
                        onChange={v => setStartDate(v.target.value)}
                        placeholder={'ex) YYYY'}
                        labelClassName='input-required'
                        />
                </CCol>
                <CCol lg={4}>
                    <CInputGroup 
                        label={'휴가개수'}
                        labelId={'annualLeaveCount'}
                        value={annualLeaveCount}
                        onChange={v => validNumber(v)}
                        labelClassName='input-required'
                    />
                </CCol>
                <CCol lg={12}>
                    <InputGroup>
                        <InputGroup.Text style={{backgroundColor: 'rgb(191 210 237 / 40%)'}} className='input-required'>사유</InputGroup.Text>
                        <textarea className="form-control" rows="3"
                            value={remark}
                            placeholder={'내용을 자유롭게 작성해주세요.'}
                            readOnly={false}
                            onChange={v => setRemark(v.target.value)}
                        />
                    </InputGroup>
                </CCol>
            </Row>
            <Row>
                <Col className="d-flex flex-row-reverse">
                    <CSaveBtn title={'저장'}
                                onClick={saveAnnualLeaveCount}/>
                </Col>
            </Row>
            <br />
            <Row>
                <CCol lg={12}>
                    <AgGridContainer
                        gridRef={gridRef}
                        gridTitle={'지급휴가'}
                        height={31.7}
                        rowData={UserTabsStore.annualLeaveCountList}
                        columnDefs={[
                            { field: "userTabDataSeq", headerName: "유저탭테이블순번", hide: true },
                            { field: "startDate", headerName: "연도", valueGetter: v => v.data.startDate.substring(0, 4), width: 85 },
                            { field: "annualLeaveCount", headerName: "휴가일수", width: 108, editable: true },
                            { field: "remark", headerName: "사유", width: 300, editable: true },
                            { field: "creator", headerName: "입력자", valueGetter: v => getUserName(v.data.creator), width: 98 },
                            { field: "createdDate", headerName: "입력일자", width: 190},
                            { field: "updater", headerName: "수정자",  valueGetter: v => getUserName(v.data.creator), width: 98},
                            { field: "updatedDate", headerName: "수정일자", width: 190 },
                        ]}
                        customBtnInfo={[deleteBtn, modifyBtn]}
                        isCheckBox={true}
                        />
                </CCol>
            </Row>
        </>
    )

}

export default observer(AnnualLeaveCount);