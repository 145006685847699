import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import { Col, Button, Row, InputGroup, Form, Breadcrumb } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';
import { CContainer, CAddBtn, CInputGroup, CCol, CSaveBtn, CSearchBtn, CUpdateBtn, CCancelBtn } from '../../components/CustomContainer';
import { AppStore } from '../../store/AppStore';
import { dateTimeFormat } from '../../utils/dateUtils';
import Modal from '../../components/Modal';
import Details from './Details';
import { CommcodeMDOptions, CommcodeSMOptions } from '../../components';
import { findCommKrnm } from '../../utils/commcode';
import { callAlert, callConfirm } from '../../utils';
import ContentHeader from '../../components/ContentHeader';
import AgGridContainer from '../../components/AgGridContainer';
import { SampleStore } from '../../store/sampleStore';
import LUtils from '../../utils/lodashUtils';
import CustomerSearch from '../../components/searchModal/CustomerSearch';
import { IS_UPDATED } from '../../common/constants';

const DataGrid = () => {
    const gridRef = useRef();
    const agGridContainerRef = useRef();
    const [detailInfo, setDetailInfo] = useState({});
    const [sampleList, setSampleList] = useState([]);
    const [ccMD, setCcMD] = useState('0');
    const [ccSM, setCcSM] = useState('');

    const testFn = (md, sm) => {
        console.log('중분류 -> ', findCommKrnm(md));
        console.log('소분류 -> ', findCommKrnm(sm, md));
    }

    const openModalForDetail = (v) => {
        setDetailInfo(v.data);
        AppStore.toggleModal();
    }

    const onChangeCcMD = (v) => {
        setCcMD(v.target.value)
        testFn(v.target.value);
    }
    const onChangeCcSM = (v) => {
        setCcSM(v.target.value)
        testFn(ccMD, v.target.value);
    }

    const onAlert = async () => {
        if (await callAlert('입력값을 모두 입력해야 합니다.')) {
            console.log('1');
        }
        console.log('2');
    }

    const onConfirm = async () => {
        if (!await callConfirm('진행 하시겠습니까?')) {
            console.log('1');
        }
        console.log('2');
    }

    const getSampleList = async () => {
        const result = await axios.get('/sample/list');
        const originDataList = result.map(v => {
            v.today = new Date();
            return v;
        });
        
        SampleStore.setSampleList(originDataList);
        setSampleList(originDataList);
    }
    
    const callBackGridData = async (gridData) => {
        alert(`${gridData.updatedList.length}개 수정, ${gridData.createdList.length}개 추가, ${gridData.selectedList.length}개 선택`)
        console.log('callBackGridData', gridData);
    }

    const columnDefs=[
        {field: "sampleSeq", headerName: "seq" },
        {field: "name", headerName: "이름", editable: true, headerClass: 'grid-column-editable'},
        {field: "content", headerName: "내용", editable: true, headerClass: 'grid-column-editable grid-column-required'},
        {field: "today", headerName: "날자", valueGetter: v => dateTimeFormat(LUtils.get(v.data, 'today', new Date()))},
    ];

    const openPopupDetail = () => {
        AppStore.toggleModal()
        // AppStore.toggleCustomerSearchModal();
    }
    const openPopupDetail2 = () => {
        // AppStore.toggleModal()
        AppStore.toggleCustomerSearchModal();
    }

    const getCellChange = (e) => {
        const { field } = e.colDef

        if (field === "content") {
            callAlert('안타깝지만 내용 변경 할 수 없습니다...');
            e.data[field] = e.oldValue; // 값 원래대로 돌리기
            e.data[IS_UPDATED] = false; // 수정 사항이 되돌아 갔기 때문에 false로 변경
            e.data.c.y = null;    // ag grid에서 사용하는 내부 컬럼값. 컬럼 변경 여부 계산 기준이 되는 컬럼
        }

        agGridContainerRef.current.allRowCheckBoxChecker();
    }

    const addRowInfo = {sampleSeq: '', name: '', content: '', today: new Date()};

    return (
        <>
        {/* content header */}
        <ContentHeader title={'Data Grid'} depthList={['Sample', 'test']} />

        {/* 검색조건 */}
        <CContainer>
            <Row>
                <CCol>
                    <Form.Select value={ccMD} onChange={onChangeCcMD}>
                        <option>- 중분류 -</option>
                        <CommcodeMDOptions />
                    </Form.Select>
                </CCol>
                <CCol>
                    <Form.Select value={ccSM} onChange={onChangeCcSM}>
                        <option>- 소분류 -</option>
                        { ccMD !== '0' && <CommcodeSMOptions commKind={ccMD} />}
                    </Form.Select>
                </CCol>
                <CCol>
                    <CInputGroup label={'공통인풋'} labelId={'common1'} placeholder={'InputGroup'} />
                </CCol>
                <CCol>
                    <CInputGroup label={'아이디1'} labelId={'inputId1'} placeholder={'inputId1'} />
                </CCol>
            </Row>
            <hr />
            <Row>
                <CCol>
                    <CInputGroup label={'아이디2'} labelId={'inputId2'} placeholder={'inputId2'} />
                </CCol>
                <CCol>
                    <CInputGroup label={'아이디3'} labelId={'inputId3'} placeholder={'inputId3'} />
                </CCol>
                <CCol>
                    <CInputGroup label={'아이디4'} labelId={'inputId4'} placeholder={'inputId4'} />
                </CCol>
                <CCol>
                    <CInputGroup label={'아이디5'} labelId={'inputId5'} placeholder={'inputId5'} />
                </CCol>
            </Row>
            <hr />
            <Row>
                <Col className='d-flex flex-row-reverse'>
                    <CAddBtn onClick={openPopupDetail}/>
                    <CAddBtn onClick={openPopupDetail2}/>
                    <CSearchBtn onClick={getSampleList} />
                </Col>
            </Row>
        </CContainer>

        {/* ag grid */}
        <AgGridContainer 
            gridRef={gridRef}
            ref={agGridContainerRef}
            height={55}
            rowData={SampleStore.sampleList}
            columnDefs={columnDefs}
            isCheckBox={true}
            seqColumn={'sampleSeq'}
            originList={sampleList}
            addRowInfo={addRowInfo}
            useUpdated={true}
            useCreated={true}
            callBackGridData={callBackGridData}
            getCellChange={getCellChange}
        />
        <Modal title={'Sample Details'}>
            <Details info={detailInfo}/>
        </Modal>
        <CustomerSearch />
        </>
    );
};

export default observer(DataGrid);
