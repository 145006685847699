import { observer } from "mobx-react-lite"
import { useRef } from "react";
import axios from "axios";
import { Row, Col, Form, Badge } from "react-bootstrap";

import { CContainer, CIconBtn } from "../../../components/CustomContainer";
import Modal from "../../../components/Modal";
import { AppStore } from "../../../store/AppStore";
import AgGridContainer from "../../../components/AgGridContainer";
import { BillSearchStore } from "../../../store/billing/BillSearchStore";
import { decrypt, showToast } from "../../../common/utils";
import { callConfirm } from "../../../utils";
import { ICON_ADD } from "../../../common/constants";
import SelectEmailPopup from "./SelectEmailPopup";

const SendTaxBillPopup = () => {
    const gridRef = useRef();
    const store = BillSearchStore;

    const refresh = () => {
        store.sendEmailInit();
    }

    /* 국세청 발송 버튼 */
    const sendBtn = async () => {
        const checkBillList = store.selectBillList;

        if(!await callConfirm(`${checkBillList.length}건을 전송 하시겠습니까?`)) return;

        for (const [index, item] of checkBillList.entries()) {    
            //console.log(store.addEmailList[item.billNo]);
            checkBillList[index].emailList = store.addEmailList[item.billNo] && store.addEmailList[item.billNo].map(v => v.email);
        }
            const result = await axios.post('/bill/send', checkBillList);
            if(result.result === 'option_failed') {
                alert(result.message);
            }else if(result.result === 'success'){
                showToast('전송되었습니다.');
            }else{
                showToast('실패하였습니다.(결과 메세지 확인)');
            }
        //재조회
        await getBillPublishedList();
        AppStore.toggleSendTaxBillPopup()
    }

    /* 청구지, 현장명으로 메일 주소 담기 */
    const getEmailList = async(billNo, customerNo, billingAddressSeq, siteSeqGroup) => {
        store.emailBillNo = billNo;
        if(!store.emailList[billNo] || store.emailList[billNo].length < 1) {
            const result = await axios.get(`/bill/email`, {params: {customerNo, billingAddressSeq, siteSeqGroup}});

            const uniqueEmails = new Set();

            // 중복을 제외한 결과 리스트
            const uniqueResultList = result.filter(item => {
                const email = decrypt(item.email);
                if (uniqueEmails.has(email) || email === '') {
                    return false;
                } else {
                    uniqueEmails.add(email);
                    return true;
                }
            });

            store.emailList[billNo] = uniqueResultList.filter(v => v.title !== '청구지명');
        }
    }

    const addEmailBtn = (e) => {
        // 한 행만 선택 가능하도록 설정
        const selectedBills = store.selectBillList.filter(v => v.isSelected);
        if(selectedBills.length !== 1) return showToast('한 행을 선택해주세요.');

        const { billNo, customerNo, billingAddressSeq, siteSeqGroup } = selectedBills[0];
        getEmailList(billNo, customerNo, billingAddressSeq, siteSeqGroup);

        AppStore.toggleSelectEmailPopup();
    }

    const addBtn = {
        isUsed: true,
        title: '이메일 추가 발송 선택',
        callbackFn: (e) => addEmailBtn(e),
        icon: ICON_ADD,
        width: 180
    };

    // 이메일 삭제 함수
    const removeEmail = (seq) => {
        store.addEmailList[store.emailBillNo] = store.addEmailList[store.emailBillNo].filter(v => v.seq !== seq);
        gridRef.current.api.redrawRows();
    };

    const addEmailField = (e) => {
        return (
            <>
               {store.addEmailList[e.data.billNo] && store.addEmailList[e.data.billNo]?.map((data, index) => (
                        <Badge
                            key={index}
                            pill
                            className="mail-badge"
                            onClick={() => removeEmail(data.seq)}
                            style={{marginTop:6}}
                        >
                            {data.email} x
                        </Badge>
                    ))
                }
            </>
        )
    }

    return (
        <>
            <Modal title={'국세청 발송'}
                onHide={() => AppStore.toggleSendTaxBillPopup()}
                toggleValue={AppStore.isOpenSendTaxBillPopup}
                onExit={refresh}
                size={'lg'}
            >
                <CContainer>
                    <p>청구지외에 추가로 이메일을 발송하려면 "이메일 추가 발송" 버튼을 선택하여 추가해주세요.<br/>
                    추가 메일 전송이 없으면 "국세청 발송" 버튼을 클릭해주세요.</p>
                    
                    <AgGridContainer
                        gridRef={gridRef}
                        gridTitle={'계산서 목록'}
                        height={23}
                        rowData={store.selectBillList}
                        columnDefs={
                            [
                                {field: "siteNameGroup", headerName: "현장명", width: 140},
                                {field: "billDate", headerName: "계산서발행일", width: 120},
                                {field: "customerName", headerName: "고객명", width: 140},
                                {field: "billingAddressName", headerName: "청구지명", width: 120},
                                {field: "receiverEmail", headerName: "청구지 메일", width: 180, valueFormatter: v => v.value ? decrypt(v.value) : ''},
                                {field: "billTotalAmount", headerName: "총 발행 금액", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString()},
                                {field: "billNo", headerName: '계산서번호', width: 140},
                                {
                                    field: "addEmail", 
                                    headerName: '추가 메일 전송', 
                                    width: 700, 
                                    resizable: true,
                                    cellRenderer: (e) => addEmailField(e),
                                },
                            ]
                        }
                        isCheckBox={true}
                        customBtnInfo={[addBtn]}
                    />
                    <Row>
                        <Col className="d-flex flex-row-reverse">
                            <CIconBtn style={{width: 100, height: 30, marginTop: 30}}
                                    title={'국세청 발송'}
                                    icon={'fi-rr-file-invoice-dollar'}
                                    onClick={sendBtn}
                                    variant={'success'}
                            />
                        </Col>
                    </Row>
                </CContainer>
            </Modal>
            <SelectEmailPopup gridRef={gridRef} />
        </>
    );
}

export default observer(SendTaxBillPopup);
