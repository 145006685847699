import { createBrowserRouter } from 'react-router-dom';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import AsMobileRoutes from './AsMobileRoutes';
import ContractDocumentRoutes from './ContractDocumentRoutes';
import ShipConfirmRoutes from './ShipConfirmRoutes';
import PolicyRoutes from './PolicyRoutes';


export default function IndexRoutes() {
    return createBrowserRouter([
        LoginRoutes, 
        MainRoutes, 
        AsMobileRoutes, 
        ContractDocumentRoutes, 
        ShipConfirmRoutes, 
        PolicyRoutes
    ]);
}
