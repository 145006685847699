import React, {useRef, useState, useEffect} from "react";
import {observer} from "mobx-react-lite";
import axios from "axios";
import {Col, Form, InputGroup, Row} from "react-bootstrap";

import { CalendarGroup, CCol,CContainer, CInputDoubleGroup, CInputGroup, CSearchBtn, CSelectGroup} from "../../components/CustomContainer";
import {AppStore} from "../../store/AppStore";
import {findCommKrnm, getCommcode} from "../../utils/commcode";
import {ReleaseListStore} from "../../store/release/ReleaseListStore";
import CustomerSearch from "../../components/searchModal/CustomerSearch";
import UserSearch from "../../components/searchModal/UserSearch";
import {oneMonthAgo, today, dateFormat, addDayDate, startDateForMonth, endDateForMonth} from '../../utils/dateUtils';
import AgGridContainer from "../../components/AgGridContainer";
import {showToast} from "../../common/utils";
import { useLocation } from "react-router-dom";
import LUtils from "../../utils/lodashUtils";

const ReleaseList =observer(()=> {
    
    const location = useLocation();
    const [lgList, setLGList] = useState([]);
    const [mdList, setMDList] = useState([]);
    const [smList, setSMList] = useState([]);
    const [productGroupList, setProductGroupList] = useState([]);

    useEffect(() => {
        getProductGroupList();

        // 한 달 전 날짜 세팅
        let initSearchCondition = {
            startDate: oneMonthAgo(),
            endDate: addDayDate(today(), 1),
        }
        ReleaseListStore.setReleaseSearchCondition(initSearchCondition);

        const fromPage = LUtils.get(location.state, 'from', null) ;
        if(fromPage === 'dashboard'){
            initSearchCondition = {
                startDate: startDateForMonth(),
                endDate: endDateForMonth()
            }
            ReleaseListStore.setReleaseSearchCondition(initSearchCondition);
            ReleaseListStore.setReleaseSearchCondition({releaseType: 'rental'})

            getReleaseList()
        }
    }, []);

    /* 고객 조회 팝업 호출 */
    const openSearchCustomerPopup = ()=> {
        AppStore.toggleCustomerSearchModal();
    }

    /* 고객 검색 팝업 콜백 */
    const getCustomerInfo = (customerInfo) => {
        ReleaseListStore.setReleaseSearchCondition({customerNo: customerInfo.customerNo, customerName: customerInfo.customerName});
    }

    /* 유저 조회 팝업 호출 */
    const openSearchUserPopup = () => {
        AppStore.toggleUserSearchModal();
    }

    /* 유저 검색 팝업 콜백 */
    const getUserInfo = (userInfo) => {
        ReleaseListStore.setReleaseSearchCondition({creator: userInfo.userSeq, creatorName: userInfo.name});
    }

    /* 날짜 선택 */
    const onChangeSearchValue = async (v, label) => {
        const { startDate, endDate } = v;
        const storeStartDate = ReleaseListStore.releaseSearchCondition.startDate;
        const storeEndDate = ReleaseListStore.releaseSearchCondition.endDate;
        const selectedDate = startDate;

        if (label === 'startDate' && storeEndDate < selectedDate) {
            showToast('종료일보다 작게 선택해주세요.');
        } else if (label === 'endDate' && endDate !== null && storeStartDate > selectedDate) {
            showToast('시작일보다 크게 선택해주세요.');
        } else {
            ReleaseListStore.setReleaseSearchCondition({ [label]: selectedDate });
        }
    };

    /* product group 조회 -> 대분류 배열에 담아주기 */
    const getProductGroupList = async () => {
        const result = await axios.get('/product/group/all', { params: { isUsed: 1 } });
        setProductGroupList(result);
        setLGList(result.filter(v => v.parentSeq === null));
    }


    /* 대분류 클릭 시 중분류 담아주기 */
    const onChangeLGCode = (e) => {
        setMDList(productGroupList.filter(v => v.parentSeq === Number(e.target.value)));
        ReleaseListStore.setReleaseSearchCondition({
            lgSeq: e.target.value,
            mdSeq: '',
            smSeq: '',
        });
    }

    /* 중분류 클릭 시 소분류 담아주기 */
    const onChangeMDCode = (e) => {
        setSMList(productGroupList.filter(v => v.parentSeq === Number(e.target.value)));
        ReleaseListStore.setReleaseSearchCondition({
            mdSeq: e.target.value,
            smSeq: '',
        });
    }

    /* 소분류 클릭 이벤트 */
    const onChangeSMCode = (e) => {
        ReleaseListStore.setReleaseSearchCondition({
            smSeq: e.target.value,
        });
    }

    /* 출고 조회 */
    const getReleaseList = async() => {
        const result = await axios.get('/release/list', {params: ReleaseListStore.releaseSearchCondition});
        ReleaseListStore.setReleaseList(result);
    }

    const changeInput =(label, v)=> {
        ReleaseListStore.setReleaseSearchCondition({[label]: v});
    }

    return(
        <>
            {/* 검색조건 */}
            <CContainer search>
                <Row>
                    <CCol lg={2}>
                        <CSelectGroup label={'출고구분'} labelId={'releaseType'}
                                      placeholder={'출고구분'}
                                      options={[{name: '전체', value: ''}].concat(getCommcode('12'))}
                                      value={ReleaseListStore.releaseSearchCondition.releaseType}
                                      onChange={v => changeInput('releaseType', v.target.value)}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup asSingle={true} label={'시작일'}
                                       value={{
                                           startDate: ReleaseListStore.releaseSearchCondition.startDate,
                                           endDate: ReleaseListStore.releaseSearchCondition.startDate
                                       }}
                                       onChange={v => onChangeSearchValue(v, 'startDate')}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CalendarGroup asSingle={true} label={'종료일'}
                                       value={{
                                           startDate: ReleaseListStore.releaseSearchCondition.endDate,
                                           endDate: ReleaseListStore.releaseSearchCondition.endDate
                                       }}
                                       onChange={v => onChangeSearchValue(v, 'endDate')}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'계약번호'}
                            labelId={'contractNo'}
                            value={ReleaseListStore.releaseSearchCondition.contractNo}
                            onChange={v => changeInput('contractNo', v.target.value)}
                            enterKeyHint={getReleaseList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'자산번호'}
                            labelId={'assetNo'}
                            value={ReleaseListStore.releaseSearchCondition.assetNo}
                            onChange={v => changeInput('assetNo', v.target.value)}
                            enterKeyHint={getReleaseList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'관리번호'}
                            labelId={'managementNo'}
                            value={ReleaseListStore.releaseSearchCondition.managementNo}
                            onChange={v => changeInput('managementNo', v.target.value)}
                            enterKeyHint={getReleaseList}
                        />
                    </CCol>

                    <CCol lg={4}>
                        <CInputDoubleGroup
                            label={'고객명'} labelId={'customerNo'} labelId2={'customerName'}
                            placeholder={'고객코드'} placeholder2={'고객명'}
                            onCallbackBtn={openSearchCustomerPopup}
                            value={ReleaseListStore.releaseSearchCondition.customerNo || ''}
                            value2={ReleaseListStore.releaseSearchCondition.customerName || ''}
                            onChange={v => changeInput('customerNo', v.target.value)}
                            onChange2={v => changeInput('customerName', v.target.value)}
                            enterKeyHint={getReleaseList}
                            enterKeyHint2={getReleaseList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={ReleaseListStore.releaseSearchCondition.lgSeq} onChange={onChangeLGCode}>
                                <option value={0}>- 대분류 -</option>
                                {lgList.map(lg => (
                                    <option key={lg.productGroupSeq} value={lg.productGroupSeq}>{lg.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={ReleaseListStore.releaseSearchCondition.mdSeq} onChange={onChangeMDCode}>
                                <option value={0}>- 중분류 -</option>
                                {mdList.map(md => (
                                    <option key={md.productGroupSeq} value={md.productGroupSeq}>{md.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <InputGroup size={'sm'}>
                            <Form.Select value={ReleaseListStore.releaseSearchCondition.smSeq} onChange={onChangeSMCode}>
                                <option value={0}>- 소분류 -</option>
                                {smList.map(sm => (
                                    <option key={sm.productGroupSeq} value={sm.productGroupSeq}>{sm.productGroupName}</option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'현장명'} labelId={'siteName'}
                                     value={ReleaseListStore.releaseSearchCondition.siteName}
                                     onChange={v => changeInput('siteName', v.target.value)}
                                     enterKeyHint={getReleaseList}
                        />
                    </CCol>
                </Row>
                <Row>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'모델명'}
                            labelId={'model'}
                            value={ReleaseListStore.releaseSearchCondition.model}
                            onChange={v => changeInput('model', v.target.value)}
                            enterKeyHint={getReleaseList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'시리얼번호'}
                            labelId={'serialNo'}
                            value={ReleaseListStore.releaseSearchCondition.serialNo}
                            onChange={v => changeInput('serialNo', v.target.value)}
                            enterKeyHint={getReleaseList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup
                            label={'입고번호'}
                            labelId={'storageNo'}
                            value={ReleaseListStore.releaseSearchCondition.storageNo}
                            onChange={v => changeInput('storageNo', v.target.value)}
                            enterKeyHint={getReleaseList}
                        />
                    </CCol>
                    <CCol lg={2}>
                        <CInputGroup label={'출고자'} labelId={'creatorName'}
                                     value={ReleaseListStore.releaseSearchCondition.creatorName}
                                     onCallbackBtn={openSearchUserPopup}
                                     onChange={v => changeInput('creatorName', v.target.value)}
                                     enterKeyHint={getReleaseList}
                        />
                    </CCol>
                </Row>
                <Row>
                    <Col className='d-flex flex-row-reverse'>
                        <CSearchBtn onClick={getReleaseList} />
                    </Col>
                </Row>
            </CContainer>
            <CustomerSearch callbackFn={getCustomerInfo}/>
            <UserSearch callbackFn={getUserInfo} />

            <GridList />
        </>
    );
});

const GridList =observer(()=> {
    const gridRef = useRef();

    const pinnedTopData = [{
        qty: ReleaseListStore.releaseList.reduce((total, v) => total + v.qty, 0),
        amount: ReleaseListStore.releaseList.reduce((total, v) => total + v.amount, 0)
    }];

    return (
        <CContainer>
            <AgGridContainer
                gridRef={gridRef}
                height={58}
                rowData={ReleaseListStore.releaseList}
                columnDefs={
                    [
                        {field: "releaseDate", headerName: "출고일자", valueGetter: v => dateFormat(v.data.releaseDate), width: 140},
                        {field: "releaseNo", headerName: "출고번호", width: 110},
                        {field: "releaseSeq", headerName: "순번", width: 80},
                        {
                            field: "releaseType",
                            headerName: "출고구분",
                            valueFormatter: function(params) {
                                return findCommKrnm(params.value, '12');
                            },
                            width: 130
                        },
                        {field: "assetNo", headerName: "자산번호", width: 120, hide: true},
                        {field: "siteName", headerName: "현장명", width: 120},
                        {field: "managementNo", headerName: "관리번호", width: 120},
                        {field: "serialNo", headerName: "시리얼번호", width: 140},
                        {field: "productName", headerName: "제품명", width: 200},
                        {field: "model", headerName: "모델명", width: 180},
                        {field: "lgCate", headerName: "대분류", width: 130},
                        {field: "mdCate", headerName: "중분류", width: 130},
                        {field: "smCate", headerName: "소분류", width: 160},
                        {field: "qty", headerName: "출고수량", width: 120},
                        {field: "amount", headerName: "출고금액", width: 120, cellClass: 'ag-grid-money-align', valueFormatter: v => v.value?.toLocaleString(),},
                        {field: "creatorName", headerName: "출고작업자", width: 120},
                        {field: "contractNo", headerName: "계약번호", width: 120},
                        {field: "saleUserName", headerName: "영업담당", width: 120},
                        {field: "storageNo", headerName: "입고번호", width: 120},
                    ]
                }
                useCsvDownload={true}
                seqColumn={'releaseSeq'}
                pinnedTopRowData={pinnedTopData}
            />
        </CContainer>
    )
});


export default ReleaseList;
